import React from 'react';

const Chain = () => {
    return (
        <>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 800.000000 800.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M4014 7918 c-37 -63 -77 -162 -110 -272 -28 -93 -82 -353 -91 -437
-3 -31 -8 -61 -12 -66 -3 -5 -310 -140 -681 -300 l-674 -290 -12 -71 c-17
-103 -38 -162 -110 -307 -77 -158 -136 -233 -251 -319 -82 -62 -284 -171 -328
-178 -18 -3 -22 12 -50 192 -16 107 -34 201 -38 208 -12 18 -83 15 -125 -7
-128 -66 -216 -246 -373 -766 -66 -218 -88 -285 -94 -285 -1 0 -29 12 -61 27
-146 67 -232 13 -270 -169 -21 -97 -13 -415 15 -618 12 -80 21 -148 21 -152 0
-5 -16 -8 -36 -8 -27 0 -44 -8 -69 -33 -91 -91 -73 -286 65 -716 l50 -154 -26
-23 c-131 -123 -14 -426 303 -780 40 -45 95 -99 122 -119 46 -35 48 -39 40
-69 -15 -52 -10 -152 9 -204 26 -68 58 -111 173 -234 l101 -108 -26 -75 c-14
-41 -26 -83 -26 -93 0 -90 309 -346 580 -482 227 -114 544 -218 778 -255 23
-4 42 -10 42 -13 0 -4 -50 -38 -111 -77 -116 -74 -199 -153 -199 -192 0 -41
30 -47 179 -33 145 14 169 12 271 -15 54 -14 74 -15 108 -6 58 16 89 45 188
179 147 198 162 213 256 257 75 36 92 40 179 43 l96 4 5 -78 c4 -67 29 -162
61 -234 6 -13 -5 -15 -70 -16 -196 -2 -250 -100 -159 -290 63 -133 149 -203
299 -244 l77 -21 79 20 c155 39 245 105 304 223 105 207 58 308 -144 312 -66
1 -79 4 -75 16 3 8 16 46 31 84 16 42 28 101 32 149 l6 79 64 -5 c49 -4 86
-15 156 -50 163 -80 213 -120 304 -242 139 -186 181 -212 300 -180 102 27 137
29 273 15 185 -18 215 1 146 92 -32 42 -150 130 -258 192 -27 16 -48 31 -48
35 0 4 33 15 73 25 249 63 551 187 792 324 167 95 428 279 459 324 20 28 20
87 1 158 -8 30 -15 60 -15 67 0 6 48 63 108 125 67 71 120 138 142 178 31 59
35 73 35 145 0 44 -3 91 -7 105 -6 22 2 33 60 85 177 158 353 399 422 578 54
137 51 223 -7 288 l-34 38 46 143 c135 418 157 638 70 725 -25 25 -42 33 -69
33 -25 0 -36 5 -36 14 0 8 10 88 22 178 30 221 32 534 5 623 -27 88 -53 125
-105 147 -38 17 -50 18 -89 8 -25 -6 -64 -20 -86 -31 -21 -11 -43 -17 -47 -12
-5 4 -40 109 -79 233 -104 335 -136 423 -201 556 -92 190 -188 278 -284 260
-30 -6 -36 -12 -41 -39 -3 -18 -17 -106 -31 -197 -14 -90 -26 -165 -27 -166
-1 -1 -31 11 -66 27 -106 48 -222 132 -311 226 -133 139 -177 226 -236 466
l-36 143 -136 61 c-559 250 -1168 526 -1175 533 -5 4 -19 72 -33 151 -32 195
-78 374 -125 494 -38 98 -91 195 -107 195 -4 0 -21 -24 -38 -52z m26 -342 l0
-333 -105 -46 c-58 -25 -105 -41 -105 -35 0 59 66 376 106 508 24 77 92 240
100 240 2 0 4 -150 4 -334z m59 277 c39 -78 76 -184 106 -302 28 -110 79 -391
72 -397 -5 -5 -186 73 -204 88 -10 8 -13 88 -13 339 0 181 2 329 5 329 3 0 18
-26 34 -57z m721 -984 c415 -187 762 -343 770 -346 11 -3 26 -47 48 -137 54
-225 108 -336 227 -464 124 -133 285 -237 456 -293 l67 -21 8 -102 c26 -354
-9 -834 -96 -1286 -62 -322 -136 -596 -207 -760 -300 -694 -761 -1311 -1259
-1688 -169 -127 -594 -390 -766 -473 l-46 -22 -54 22 c-29 13 -107 51 -173 85
-705 366 -1360 1073 -1720 1856 -317 689 -457 1440 -419 2242 l7 136 86 32
c180 68 326 158 435 266 119 120 239 361 275 554 l13 65 781 337 c430 186 789
338 797 338 8 0 355 -153 770 -341z m-3182 -798 c14 -8 77 -389 67 -400 -3 -3
-20 -10 -38 -16 -21 -6 -33 -17 -34 -30 -1 -11 -5 -59 -8 -108 -4 -48 -10 -85
-15 -82 -4 2 -54 -36 -111 -87 -57 -50 -105 -86 -107 -80 -2 7 13 92 32 189
37 180 54 249 55 225 1 -8 5 -12 10 -10 12 4 141 160 141 170 0 21 -22 2 -81
-68 -35 -42 -65 -72 -67 -66 -1 5 16 80 38 167 22 87 39 160 36 162 -12 13
-24 -13 -41 -89 l-19 -83 -12 54 c-7 30 -17 57 -23 61 -15 9 45 65 89 84 39
17 69 20 88 7z m4827 -27 c18 -8 42 -25 53 -37 18 -20 19 -27 9 -79 -6 -32
-14 -58 -18 -58 -4 0 -15 34 -25 75 -19 80 -29 103 -40 92 -4 -4 9 -69 28
-144 54 -207 52 -194 20 -158 -15 17 -43 50 -63 74 -20 24 -42 42 -49 39 -11
-3 99 -144 135 -174 8 -5 17 -23 20 -40 4 -16 9 -38 12 -48 3 -11 -32 22 -78
72 -81 89 -99 104 -99 81 0 -15 162 -189 176 -189 10 0 28 -69 20 -77 -2 -2
-37 37 -80 85 -60 70 -82 89 -103 90 -49 1 -83 19 -83 44 0 13 12 95 25 183
14 88 25 166 25 173 0 17 75 15 115 -4z m-5001 -154 c14 -68 11 -139 -7 -156
-3 -3 -11 27 -18 66 -11 66 -23 90 -36 77 -5 -6 25 -154 37 -177 5 -8 2 -40
-4 -70 l-13 -55 -11 40 c-6 22 -17 66 -26 98 -15 62 -22 74 -32 64 -10 -10 37
-191 53 -203 12 -10 10 -32 -13 -150 -15 -77 -31 -143 -34 -147 -4 -4 -25 33
-48 83 -23 49 -46 90 -52 90 -5 0 -10 -4 -10 -9 0 -14 89 -194 98 -200 5 -4
-2 -78 -15 -166 -27 -190 -53 -469 -53 -579 l0 -78 -37 45 c-35 42 -62 59 -55
35 5 -20 67 -88 80 -88 7 0 12 -14 12 -36 0 -21 4 -33 10 -29 5 3 10 41 10 84
0 95 10 171 22 171 5 0 43 29 85 65 l77 65 28 -27 c28 -26 35 -56 20 -81 -6
-9 -16 -4 -37 18 -27 28 -55 40 -55 23 0 -4 18 -25 39 -48 l40 -40 -16 -70
-15 -70 -43 42 c-23 24 -47 43 -53 43 -12 0 -82 -93 -82 -109 0 -23 18 -9 50
38 l33 48 44 -46 c41 -43 44 -48 37 -86 -10 -60 -15 -62 -34 -15 -16 40 -40
65 -40 42 0 -19 38 -100 49 -103 6 -3 8 -22 4 -48 l-6 -44 -28 56 c-28 55 -45
74 -56 64 -6 -6 55 -118 70 -128 4 -3 7 -24 5 -45 l-3 -39 -33 57 c-33 58 -49
75 -59 65 -6 -6 66 -130 79 -135 11 -5 10 -72 -1 -72 -5 0 -26 22 -47 50 -37
49 -54 61 -54 39 0 -18 81 -110 91 -103 10 6 12 -72 2 -129 l-6 -38 -38 37
c-21 21 -41 35 -45 31 -8 -8 58 -87 74 -87 9 0 12 -31 12 -120 0 -66 -2 -120
-4 -120 -2 0 -26 27 -53 60 -47 59 -63 71 -63 49 0 -18 101 -130 111 -123 5 3
9 -38 9 -94 0 -107 7 -162 21 -162 5 0 9 11 9 25 0 14 6 25 13 25 17 0 70 107
57 115 -5 3 -22 -19 -37 -50 l-28 -57 -7 43 c-8 55 -10 366 -2 405 4 22 25 43
80 83 41 29 71 57 68 62 -6 11 -12 8 -81 -43 -29 -21 -55 -38 -59 -38 -16 0
20 301 62 522 20 105 29 135 40 131 7 -3 32 11 56 31 47 41 58 44 58 17 0 -11
-23 -34 -60 -60 -54 -38 -74 -61 -52 -61 4 0 29 16 56 35 27 19 53 32 57 29
23 -14 3 -43 -57 -83 -69 -48 -90 -68 -81 -77 3 -3 34 14 69 38 88 62 88 62
88 39 0 -15 -23 -37 -76 -73 -107 -74 -119 -84 -107 -91 6 -4 49 21 97 54 48
34 90 59 93 56 5 -5 -71 -80 -159 -156 -38 -33 -48 -51 -28 -51 6 0 49 36 96
80 48 44 90 80 94 80 4 0 10 -19 14 -42 4 -24 10 -54 13 -67 4 -16 2 -22 -5
-17 -21 13 -21 -13 0 -65 12 -30 25 -58 30 -63 10 -12 11 -50 1 -60 -4 -4 -28
17 -53 45 -31 35 -49 49 -54 41 -9 -14 82 -114 96 -106 7 5 2 -63 -6 -73 -1
-1 -30 21 -64 49 -35 28 -66 48 -70 44 -7 -7 24 -39 95 -98 l33 -26 -17 -115
-16 -115 -59 49 c-32 27 -63 49 -69 49 -22 0 -4 -23 59 -72 64 -51 67 -55 64
-91 l-3 -39 -54 51 c-52 49 -75 62 -75 42 0 -6 28 -34 63 -63 60 -51 62 -54
57 -92 l-5 -38 -40 50 c-35 45 -65 66 -65 46 0 -4 25 -35 55 -69 42 -48 55
-71 55 -95 l0 -32 -62 56 c-62 55 -78 65 -78 47 0 -16 122 -121 133 -114 6 4
8 0 4 -11 -4 -10 -7 -48 -7 -84 l0 -66 -63 62 c-56 56 -87 75 -87 55 0 -5 36
-39 79 -78 l80 -70 0 -73 c1 -84 5 -84 -85 -5 -63 55 -78 63 -72 40 4 -16 143
-137 157 -137 6 0 11 -21 11 -47 l0 -47 -51 57 c-50 56 -69 69 -69 45 0 -17
101 -118 117 -118 8 0 13 -12 13 -30 l0 -30 -41 45 c-42 46 -59 55 -59 33 0
-20 81 -97 96 -91 9 3 14 -2 14 -16 0 -28 -5 -27 -50 9 -42 33 -50 36 -50 18
1 -7 25 -31 55 -53 37 -27 55 -48 57 -67 4 -22 1 -25 -11 -18 -9 4 -29 22 -45
39 -28 29 -56 41 -56 23 0 -4 29 -32 64 -62 54 -46 65 -61 71 -95 3 -22 10
-47 15 -55 18 -30 22 1 7 66 l-16 67 26 34 c20 26 22 33 10 33 -9 0 -19 -4
-22 -10 -11 -18 -26 -10 -23 11 7 50 30 45 144 -32 60 -41 115 -76 122 -79 7
-3 32 -64 56 -135 24 -72 62 -172 85 -224 47 -107 55 -104 -101 -38 -58 25
-109 43 -112 40 -3 -3 -2 -13 3 -22 12 -24 34 -121 28 -127 -2 -3 -16 0 -30 7
-27 12 -26 10 -43 162 -3 31 -11 57 -17 57 -6 0 -7 -32 -3 -95 l7 -95 -24 11
c-23 10 -25 18 -31 108 -8 111 -14 141 -28 141 -10 0 -7 -65 9 -178 l6 -44
-30 15 c-28 15 -29 16 -41 153 -6 75 -14 139 -17 142 -15 16 -17 -20 -6 -129
6 -67 10 -124 7 -126 -2 -2 -32 14 -66 35 -61 39 -62 40 -65 88 -4 83 -37 274
-46 274 -13 0 -11 -40 10 -175 11 -66 18 -122 16 -123 -2 -2 -20 7 -39 20 -20
14 -33 27 -30 30 3 3 -1 74 -7 157 -9 113 -16 151 -26 151 -7 0 -13 -3 -13 -7
1 -5 7 -71 14 -148 8 -77 13 -141 11 -143 -1 -1 -13 7 -27 17 -24 19 -25 26
-32 168 -4 81 -10 151 -13 156 -16 26 -20 -23 -12 -142 5 -73 8 -135 6 -137
-2 -2 -32 20 -68 49 -44 37 -65 61 -66 78 -5 69 -36 265 -44 273 -18 19 -18
-3 2 -133 10 -74 18 -135 16 -137 -1 -2 -57 46 -122 106 -78 72 -119 116 -117
127 2 18 -71 269 -82 280 -19 19 -14 -18 14 -114 18 -59 36 -121 41 -138 6
-24 -13 -8 -100 83 -96 100 -131 128 -131 105 0 -4 35 -43 78 -86 l77 -78 -67
25 c-37 14 -70 21 -73 16 -7 -11 -5 -12 95 -50 47 -18 98 -45 115 -61 l29 -29
-84 26 c-47 14 -88 27 -91 27 -4 1 -8 -2 -9 -8 -1 -5 48 -26 109 -46 83 -27
120 -44 150 -72 23 -20 39 -37 36 -37 -2 0 -51 14 -108 30 -112 32 -127 35
-127 21 0 -9 243 -83 258 -79 4 2 25 -12 46 -30 31 -27 34 -32 14 -27 -74 18
-283 54 -287 50 -2 -3 0 -9 5 -14 10 -9 269 -61 307 -61 27 -1 101 -59 75 -60
-9 0 -80 9 -158 21 -129 18 -164 19 -148 4 8 -8 330 -56 343 -51 6 2 24 -7 40
-21 l30 -24 -40 5 c-91 12 -295 18 -295 8 0 -11 65 -19 240 -28 99 -6 130 -11
149 -25 23 -19 22 -19 -133 -19 -101 0 -156 -4 -156 -10 0 -7 58 -10 170 -10
134 1 174 -1 190 -13 20 -14 13 -15 -82 -21 -151 -10 -199 -17 -192 -28 7 -10
221 -2 285 12 25 5 41 3 54 -6 23 -17 33 -15 -144 -23 -101 -4 -146 -10 -148
-18 -4 -10 30 -11 154 -6 87 3 161 10 164 16 7 9 59 -19 59 -33 0 -4 -38 -12
-85 -19 -62 -10 -85 -17 -85 -28 0 -9 7 -12 18 -9 9 2 49 10 89 16 44 7 73 17
75 25 2 10 15 7 47 -13 l44 -26 -58 -13 c-32 -7 -60 -15 -63 -17 -2 -2 11 -33
29 -67 17 -35 42 -91 54 -124 13 -32 28 -61 35 -63 19 -7 5 39 -45 146 -30 62
-40 94 -32 97 56 16 117 21 139 9 22 -12 21 -13 -20 -20 -24 -4 -46 -13 -49
-20 -7 -18 99 -253 115 -254 6 0 12 1 12 3 0 2 -23 54 -50 115 -27 61 -50 116
-50 121 0 17 96 24 126 8 l27 -14 -35 -7 c-58 -11 -62 -24 -29 -97 16 -35 35
-83 41 -106 7 -24 18 -43 23 -43 13 0 2 51 -29 123 -41 99 -42 93 13 102 26 5
53 7 58 5 6 -1 24 -4 40 -6 17 -2 77 -16 135 -33 57 -16 107 -28 111 -26 3 3
11 -7 17 -20 7 -14 10 -25 8 -25 -2 0 -55 13 -117 30 -63 16 -115 30 -116 30
-6 0 6 -62 28 -147 13 -51 27 -93 31 -93 13 0 9 39 -14 121 -11 44 -19 81 -17
83 2 2 56 -10 120 -27 114 -30 117 -31 143 -73 30 -47 53 -70 62 -61 4 3 -14
34 -38 69 -24 35 -58 86 -75 114 l-31 52 24 48 c13 26 26 49 28 51 2 2 56 -17
121 -42 106 -42 145 -50 119 -26 -15 15 -245 101 -254 96 -5 -3 -16 -25 -26
-50 -9 -25 -19 -45 -23 -45 -8 0 -38 52 -77 138 l-34 74 48 50 47 49 76 -35
c79 -37 99 -42 99 -27 0 4 -41 27 -92 50 l-92 42 -46 -52 c-46 -52 -47 -53
-59 -31 -10 19 -6 29 37 86 l49 64 69 -44 c38 -24 76 -44 85 -44 10 0 47 -51
95 -131 108 -180 247 -362 377 -496 l110 -112 -163 -3 c-120 -2 -164 -6 -168
-15 -3 -10 36 -13 171 -13 116 0 177 4 179 11 2 6 11 2 21 -9 18 -19 17 -20
-68 -32 -54 -8 -88 -17 -93 -27 -8 -13 -31 -10 -163 21 -85 20 -157 36 -160
36 -3 0 -6 6 -6 14 0 25 -24 101 -39 120 -21 29 -30 10 -12 -27 8 -17 19 -47
23 -66 6 -29 5 -32 -11 -27 -11 3 -75 22 -144 41 -296 85 -618 204 -828 307
-67 34 -126 58 -129 54 -8 -7 -7 -9 37 -35 l35 -21 -67 0 c-37 0 -69 -4 -72
-9 -7 -10 66 -21 148 -21 34 -1 72 -7 86 -15 24 -14 23 -14 -30 -15 -71 0
-130 -10 -130 -21 0 -10 90 -10 171 0 42 5 65 3 93 -10 35 -15 36 -17 14 -23
-13 -3 -69 -9 -125 -13 -57 -3 -103 -10 -103 -15 0 -11 164 -10 214 2 21 4 42
13 45 18 3 6 48 -7 106 -29 87 -34 391 -136 540 -181 l50 -15 -52 -33 c-29
-18 -53 -37 -53 -43 0 -33 191 -250 206 -234 4 4 -22 37 -57 75 -35 37 -79 89
-96 114 l-33 46 31 12 c16 7 38 24 49 37 14 18 24 22 44 17 25 -6 25 -7 11
-35 -8 -15 -22 -32 -32 -37 -15 -9 -14 -13 7 -43 44 -64 101 -128 141 -160 67
-53 57 -23 -17 54 -39 40 -80 85 -91 101 l-20 29 26 39 c14 21 30 37 35 35 6
-2 63 -17 127 -32 64 -16 116 -33 116 -40 0 -15 173 -245 184 -245 18 0 7 19
-76 130 -47 62 -83 115 -81 117 2 3 20 1 40 -3 32 -6 44 -16 91 -88 64 -97
120 -164 130 -154 4 4 -20 44 -54 88 -34 45 -68 94 -75 109 l-14 27 34 -4 c19
-2 34 -9 34 -14 0 -13 160 -208 171 -208 18 0 8 17 -66 109 -41 51 -75 95 -75
96 0 1 19 -1 42 -5 44 -8 52 -15 169 -150 30 -35 58 -59 62 -54 3 6 -28 49
-69 97 -67 78 -72 87 -51 87 58 0 83 -12 103 -47 21 -40 128 -153 144 -153 21
0 9 21 -36 62 -25 23 -59 61 -74 83 l-28 41 44 -6 c41 -5 46 -9 109 -95 l65
-90 -77 -8 c-105 -10 -510 8 -763 33 l-205 21 -68 67 c-38 37 -85 88 -105 115
-20 26 -40 47 -44 47 -25 0 2 -40 84 -126 l92 -96 -49 7 c-28 4 -53 12 -56 18
-4 5 -33 41 -65 80 -33 38 -69 81 -81 96 -12 14 -25 23 -29 19 -8 -8 24 -53
100 -142 l51 -59 -40 7 c-33 6 -56 24 -129 98 -48 49 -90 87 -93 84 -10 -9 49
-86 97 -127 46 -40 42 -46 -21 -29 -24 7 -59 34 -108 85 -40 41 -75 75 -77 75
-16 0 4 -35 48 -85 l53 -58 -43 7 c-86 13 -142 28 -142 37 0 19 -91 150 -100
144 -6 -4 -7 -12 -3 -18 4 -7 22 -36 41 -65 18 -30 32 -56 29 -58 -5 -5 -120
21 -135 30 -6 3 -18 30 -27 59 -19 56 -31 75 -41 64 -4 -4 3 -32 14 -62 l22
-56 -23 3 c-12 2 -23 -1 -25 -6 -2 -5 47 -22 108 -36 61 -15 113 -30 116 -33
3 -3 -11 -10 -30 -17 -20 -6 -36 -18 -36 -26 0 -18 0 -18 64 12 l54 26 84 -17
c132 -26 130 -25 73 -51 -27 -13 -66 -24 -85 -24 -40 0 -68 -14 -51 -24 29
-18 180 26 198 57 6 13 17 14 57 6 27 -5 51 -11 53 -12 6 -7 -132 -66 -170
-72 -36 -7 -55 -21 -43 -32 9 -10 169 45 208 72 36 24 92 34 102 17 7 -12 -93
-70 -163 -94 -65 -22 -84 -45 -28 -34 48 10 173 71 202 100 22 22 30 24 67 18
l41 -7 -49 -41 c-55 -46 -101 -72 -158 -89 -51 -15 -63 -40 -15 -30 60 13 138
55 193 106 54 49 91 61 124 40 30 -18 -151 -134 -230 -147 -23 -4 -38 -12 -38
-20 0 -17 -4 -18 72 6 75 23 171 82 211 130 27 31 30 32 70 24 l42 -9 -66 -55
c-58 -48 -170 -122 -203 -133 -6 -3 -8 -8 -4 -12 23 -24 288 156 288 195 0 7
85 4 97 -4 1 -1 -61 -55 -137 -121 -134 -115 -153 -135 -129 -135 10 0 284
234 291 248 2 4 25 5 53 2 l49 -5 -91 -77 c-51 -42 -119 -95 -152 -117 -33
-23 -62 -45 -66 -50 -32 -53 155 73 266 179 66 62 70 65 110 60 l41 -5 -94
-83 c-51 -46 -132 -109 -180 -140 -84 -56 -102 -72 -75 -72 41 0 330 228 352
278 8 18 18 22 52 22 24 0 43 -4 43 -9 0 -5 -74 -76 -165 -157 -91 -82 -165
-155 -165 -163 0 -11 3 -11 19 0 49 35 330 295 330 307 1 8 17 12 49 12 l47
-1 -111 -107 c-61 -59 -143 -129 -182 -157 -71 -50 -84 -65 -58 -65 35 0 366
290 366 320 0 6 59 10 145 10 l146 0 -32 -47 c-18 -27 -80 -96 -138 -154 -85
-84 -134 -123 -246 -193 l-139 -88 -71 12 c-118 21 -323 76 -465 125 -331 115
-643 310 -827 518 -51 57 -54 81 -18 182 l24 70 -27 30 c-16 16 -68 73 -117
125 -135 143 -175 246 -145 370 l12 52 -38 30 c-161 128 -383 426 -453 608
-53 138 -49 220 13 277 l35 32 -19 57 c-13 38 -16 54 -7 51 182 -78 326 -125
346 -113 5 3 34 -28 65 -69 31 -42 50 -75 43 -75 -7 0 -51 11 -97 25 -89 27
-110 30 -110 17 0 -10 160 -60 210 -65 24 -3 41 -14 59 -37 l26 -33 -45 8
c-25 4 -65 14 -90 21 -28 8 -48 10 -54 4 -6 -6 -4 -11 5 -14 39 -14 138 -36
169 -37 36 -3 60 -15 60 -30 0 -5 -30 -9 -66 -9 -48 0 -65 -3 -62 -12 6 -16
128 -24 138 -9 3 6 39 -23 80 -64 l74 -75 -47 0 c-27 0 -47 -5 -47 -11 0 -6
21 -9 54 -8 48 2 58 -1 95 -30 81 -64 53 -20 -55 86 -330 326 -557 664 -759
1128 -57 130 -82 174 -73 125 3 -14 15 -50 27 -80 l22 -55 -43 43 c-46 44 -58
50 -58 30 1 -7 27 -35 59 -63 52 -45 66 -66 144 -223 68 -136 81 -170 64 -161
-12 7 -68 41 -125 76 -57 36 -106 62 -110 58 -4 -3 4 -15 18 -26 50 -38 212
-132 231 -134 13 -1 38 -32 78 -98 l59 -96 -62 23 c-33 13 -99 43 -146 67 -47
24 -88 45 -92 47 -5 2 -8 -2 -8 -10 0 -25 242 -138 295 -138 15 0 31 -13 48
-39 25 -38 26 -39 4 -34 -69 16 -168 48 -238 79 -45 18 -86 34 -93 34 -10 0
-80 199 -73 207 1 1 34 -19 72 -44 75 -48 198 -113 215 -113 25 0 5 17 -53 48
-35 17 -99 55 -142 82 -44 28 -84 50 -90 50 -6 0 -19 35 -29 78 -10 42 -20 88
-23 100 -5 20 9 12 76 -42 86 -70 139 -102 149 -91 4 4 -4 15 -18 24 -13 9
-49 35 -80 59 -30 24 -74 57 -97 75 -36 25 -43 37 -43 64 0 18 2 33 6 33 3 0
36 -27 75 -60 66 -57 89 -70 89 -52 0 12 -156 142 -170 142 -8 0 -9 16 -5 48
15 111 45 151 119 159 40 5 45 8 41 27 -23 103 -48 385 -49 541 -1 193 12 272
55 342 27 43 54 59 56 33 1 -10 16 -71 33 -135 17 -64 29 -119 27 -122 -3 -2
-29 41 -57 96 -46 90 -70 121 -70 91 0 -17 111 -220 120 -220 5 0 10 5 12 10
2 6 67 -234 145 -532 77 -299 140 -544 139 -545 -2 -2 -23 4 -49 13 -43 15
-47 14 -65 -3 -19 -18 -68 -201 -57 -211 12 -12 24 18 45 111 12 53 24 97 26
97 3 0 21 -7 41 -15 19 -8 44 -15 54 -15 11 0 19 -7 19 -15 0 -8 5 -15 10 -15
6 0 10 6 10 13 0 14 -356 1385 -367 1414 -10 26 87 12 155 -24 l57 -31 31 91
30 91 77 -117 c43 -65 82 -115 87 -112 12 7 15 2 -71 132 -38 55 -73 103 -80
105 -7 2 6 60 41 173 28 94 55 176 59 183 6 10 13 3 24 -24 45 -112 79 -176
88 -167 8 8 -51 156 -90 224 -15 26 91 287 151 372 11 16 22 27 24 25 1 -2 10
-37 18 -78z m5152 -18 c52 -90 86 -172 72 -169 -12 2 -70 -231 -61 -246 10
-17 20 9 43 116 13 59 27 107 31 107 4 0 15 -22 23 -48 l17 -49 -51 -111 c-51
-114 -57 -132 -41 -132 4 0 28 44 51 98 24 53 46 100 50 105 3 4 30 -70 59
-165 30 -95 56 -179 58 -185 3 -7 -1 -13 -8 -13 -8 0 -44 -46 -80 -103 -82
-127 -81 -124 -70 -131 5 -3 43 46 84 110 41 63 77 112 80 107 2 -4 16 -45 31
-90 l27 -82 54 30 c68 38 142 48 182 24 31 -18 72 -75 54 -75 -10 0 -121 -200
-121 -219 0 -31 22 0 75 101 l58 112 14 -40 c40 -112 28 -510 -23 -801 -6 -32
-5 -33 25 -33 76 0 131 -67 130 -160 0 -44 -2 -47 -70 -100 -89 -70 -118 -100
-97 -100 8 0 49 29 91 65 75 64 77 65 77 38 0 -40 -11 -74 -21 -67 -5 2 -38
-20 -74 -50 -36 -31 -83 -67 -105 -81 -37 -23 -52 -45 -32 -45 10 0 130 85
186 132 31 25 37 28 33 13 -3 -11 -9 -37 -12 -57 -5 -25 -11 -35 -20 -32 -8 3
-47 -17 -87 -44 -40 -27 -92 -59 -115 -70 -42 -22 -59 -39 -50 -49 7 -6 107
49 192 106 32 22 60 39 62 37 2 -1 0 -17 -3 -34 -4 -20 -16 -37 -33 -46 -14
-7 -75 -43 -136 -80 -60 -36 -115 -66 -121 -66 -6 0 -17 -7 -24 -15 -35 -42
63 -1 199 84 105 65 105 65 99 55 -3 -5 -20 -55 -37 -112 -18 -56 -36 -100
-41 -97 -5 3 -53 -13 -107 -35 -54 -23 -132 -49 -172 -59 -41 -10 -77 -23 -79
-29 -2 -7 3 -12 11 -12 28 0 182 49 265 83 45 19 82 32 82 28 0 -4 -7 -29 -16
-54 l-16 -48 26 -20 c101 -80 67 -260 -98 -514 -88 -134 -294 -365 -358 -401
-15 -8 -15 -14 -2 -64 35 -130 -7 -228 -173 -401 -73 -77 -112 -126 -109 -135
3 -8 13 -52 23 -98 21 -104 20 -107 -111 -205 -257 -192 -515 -335 -805 -445
-128 -49 -381 -126 -414 -126 -20 0 -137 68 -137 80 0 4 -38 37 -84 74 -47 36
-134 119 -195 183 -61 64 -114 114 -117 110 -9 -9 49 -78 163 -193 54 -54 87
-91 73 -83 -14 9 -56 34 -95 56 -66 39 -142 117 -199 205 l-18 28 146 0 c119
0 146 -3 146 -14 0 -32 328 -316 366 -316 8 0 14 2 14 5 0 2 -35 30 -77 61
-43 32 -125 103 -182 158 l-103 101 48 7 c73 9 156 9 150 -1 -12 -19 204 -206
306 -265 86 -50 74 -21 -16 40 -49 32 -132 97 -184 143 -81 72 -92 86 -76 92
31 12 72 10 76 -4 8 -35 301 -263 321 -250 7 4 0 15 -18 27 -106 77 -193 145
-238 186 l-51 47 43 7 c42 6 45 5 95 -41 72 -68 235 -207 247 -211 33 -12 -8
32 -126 134 -136 118 -146 133 -84 134 17 0 42 -16 75 -46 97 -91 265 -190
264 -156 0 4 -36 26 -79 50 -42 24 -106 69 -141 100 l-63 58 99 12 99 13 58
-56 c58 -56 130 -97 196 -110 56 -12 32 15 -31 35 -57 17 -132 66 -176 113
l-23 24 53 7 c161 21 694 138 712 156 20 20 -3 17 -134 -14 -74 -19 -152 -36
-174 -40 l-38 -7 41 48 c36 44 51 73 36 73 -3 0 -35 -31 -72 -69 -59 -61 -74
-71 -113 -76 l-45 -7 35 34 c49 45 94 107 86 115 -4 4 -32 -21 -62 -55 -66
-73 -104 -102 -150 -112 l-35 -8 25 31 c71 87 116 146 116 151 0 21 -32 -4
-69 -54 -68 -91 -120 -137 -164 -143 l-39 -6 71 74 c78 82 107 122 97 132 -4
4 -26 -15 -48 -42 -23 -28 -69 -77 -103 -111 l-62 -61 -179 -22 c-302 -36
-730 -61 -807 -47 l-35 7 34 56 c19 31 34 64 34 74 0 28 33 57 73 62 20 3 37
3 37 1 0 -15 -65 -97 -101 -127 -23 -20 -39 -42 -37 -49 6 -17 113 85 145 139
20 34 32 44 61 49 20 3 46 8 57 11 23 7 26 11 -72 -106 -35 -41 -63 -78 -63
-83 0 -22 25 1 105 96 80 95 91 104 128 109 23 3 43 5 44 3 1 -1 -31 -44 -72
-95 -72 -90 -85 -111 -67 -111 8 0 147 170 170 206 4 6 22 15 41 19 l33 7 -18
-31 c-10 -16 -41 -60 -68 -97 -86 -114 -80 -104 -63 -104 16 0 118 128 157
198 19 34 29 41 70 49 l48 10 -95 -126 c-52 -69 -89 -127 -83 -129 6 -2 13 -2
14 -1 34 37 176 235 176 245 0 7 17 18 38 23 106 28 201 51 210 51 6 0 20 -19
31 -41 l22 -42 -28 -41 c-15 -22 -56 -69 -90 -103 -35 -35 -63 -66 -63 -68 0
-32 107 67 172 159 40 58 46 71 33 78 -8 5 -22 22 -30 38 l-15 29 72 21 c156
44 480 154 617 208 135 54 147 57 200 51 81 -10 181 -10 181 0 0 11 -60 21
-140 21 l-65 1 34 18 c24 13 47 16 82 13 58 -5 159 6 159 18 0 5 -33 10 -72
12 l-73 3 38 19 c35 19 48 36 27 36 -6 0 -29 -9 -50 -20 -22 -11 -40 -17 -40
-12 0 4 20 41 45 81 43 70 52 91 39 91 -19 0 -108 -155 -101 -174 3 -8 -11
-21 -38 -34 l-43 -21 7 27 c5 15 20 51 35 79 28 56 32 73 17 73 -15 0 -74
-126 -75 -160 -1 -29 -5 -31 -176 -99 -150 -59 -517 -184 -602 -205 -37 -8
-36 6 2 79 33 63 31 86 -4 43 -25 -32 -58 -116 -51 -128 6 -10 -54 -25 -62
-16 -3 3 4 31 15 63 12 31 19 60 16 63 -10 10 -35 -35 -51 -91 l-15 -55 -142
-33 c-77 -18 -151 -35 -164 -38 -16 -4 -22 -1 -20 8 2 9 -24 18 -95 29 l-98
15 56 44 c102 82 266 268 387 441 101 143 328 544 328 578 0 29 -19 7 -50 -58
-31 -65 -50 -81 -50 -42 0 10 21 48 46 86 92 137 190 306 290 501 112 216 113
216 92 49 l-11 -94 -28 32 c-18 21 -28 28 -29 18 0 -16 26 -60 43 -71 5 -3 5
-28 1 -54 l-7 -49 -24 47 c-13 26 -28 47 -34 47 -13 0 -4 -30 27 -92 17 -34
22 -56 17 -78 l-6 -32 -25 46 c-24 44 -42 59 -42 34 0 -6 13 -35 29 -63 27
-48 29 -55 19 -100 l-10 -49 -22 55 c-12 29 -26 53 -32 51 -12 -4 -7 -27 22
-88 14 -29 21 -59 18 -69 -5 -13 -13 -7 -37 29 -26 42 -47 58 -47 39 0 -4 16
-33 35 -65 27 -42 34 -64 29 -83 -3 -14 -9 -25 -13 -25 -4 0 -23 27 -42 60
-18 33 -40 60 -46 60 -21 0 -93 -32 -93 -41 0 -13 15 -11 55 5 l34 14 40 -68
c45 -75 46 -77 31 -116 l-11 -28 -45 67 c-24 37 -49 67 -54 67 -12 0 -150
-121 -150 -131 0 -19 21 -7 82 46 34 30 65 55 68 55 3 0 25 -31 48 -69 38 -62
41 -72 31 -95 l-12 -26 -45 61 c-42 56 -46 59 -66 46 -11 -8 -49 -32 -83 -53
-59 -37 -76 -54 -53 -54 6 0 39 18 74 40 35 22 68 40 73 40 6 0 29 -25 52 -55
37 -48 40 -58 30 -77 -12 -22 -13 -21 -56 27 l-44 48 -90 -43 c-49 -24 -89
-48 -89 -52 0 -14 14 -9 95 28 l77 36 25 -24 c14 -13 33 -34 43 -48 16 -24 16
-27 1 -64 l-17 -39 -33 54 c-18 30 -35 56 -37 58 -8 9 -204 -93 -204 -106 0
-8 33 3 90 32 49 25 93 45 97 45 17 -1 73 -103 68 -121 -9 -27 -21 -23 -34 9
-29 68 -26 67 -160 16 -66 -26 -121 -51 -121 -55 0 -15 15 -11 126 31 59 23
108 40 109 38 1 -2 14 -22 29 -46 24 -38 25 -45 13 -68 l-14 -26 -22 51 c-12
28 -24 51 -26 51 -3 0 -64 -23 -136 -51 -80 -31 -127 -55 -122 -60 6 -6 56 8
128 36 65 25 119 44 121 43 1 -2 12 -24 23 -50 l19 -46 -45 -74 c-25 -40 -60
-92 -78 -115 l-32 -42 -13 24 c-16 30 -27 31 -189 5 -122 -19 -140 -24 -128
-36 5 -6 169 15 258 33 21 4 30 -2 52 -36 15 -23 32 -39 38 -36 5 4 7 11 4 16
-4 5 12 35 35 66 l41 58 122 32 c66 18 123 31 124 29 2 -2 -8 -47 -22 -101
-24 -93 -26 -121 -6 -99 16 17 63 222 52 228 -3 2 -61 -10 -128 -28 l-122 -33
16 27 c9 16 25 28 37 28 19 0 177 39 249 62 34 10 140 -9 133 -25 -12 -25 -64
-162 -71 -182 -5 -17 -2 -23 9 -23 9 0 16 8 16 18 0 9 18 59 39 109 43 103 41
109 -23 120 l-37 6 29 17 c24 15 36 16 80 7 29 -6 52 -14 52 -18 0 -3 -23 -57
-51 -118 -45 -100 -57 -143 -30 -115 19 19 110 236 105 249 -3 7 -26 16 -52
20 -46 7 -46 7 -22 20 33 17 146 -3 143 -26 -1 -8 -17 -50 -37 -94 -19 -44
-38 -92 -41 -108 -11 -42 12 -33 26 10 6 21 31 78 56 127 24 49 42 90 40 92
-2 2 -32 10 -68 17 -62 13 -63 14 -39 27 14 7 61 37 106 66 75 49 83 52 120
44 64 -13 284 -23 284 -12 0 13 -49 22 -124 23 -33 1 -87 4 -119 8 l-59 6 60
42 61 43 128 5 c160 6 243 15 243 26 0 11 -50 11 -210 -1 -68 -5 -126 -8 -127
-6 -8 7 145 120 166 122 14 1 90 15 170 30 137 25 172 36 159 49 -4 5 -213
-32 -298 -52 -19 -5 -5 13 54 68 94 89 93 88 229 130 59 18 107 38 107 45 0 8
-30 3 -92 -17 -51 -15 -93 -27 -95 -25 -11 11 68 58 145 86 50 19 92 38 92 42
0 13 -26 9 -80 -12 -28 -11 -54 -20 -58 -20 -3 0 16 24 44 53 80 84 98 107 90
114 -3 4 -52 -38 -106 -92 -55 -55 -100 -97 -100 -95 0 3 16 59 35 124 33 111
37 143 16 120 -10 -11 -81 -246 -81 -268 0 -14 -230 -239 -237 -232 -2 2 4 60
14 129 15 110 14 152 -1 136 -2 -2 -14 -69 -25 -148 -21 -144 -31 -169 -72
-192 -13 -7 -16 -5 -13 9 1 9 10 62 19 117 8 55 18 112 21 128 4 19 2 27 -8
27 -10 0 -20 -40 -36 -145 -13 -80 -20 -149 -18 -154 3 -5 -6 -16 -20 -25
l-25 -17 7 143 c9 192 -11 175 -25 -22 -9 -131 -12 -146 -32 -163 -12 -9 -23
-16 -25 -14 -2 2 2 65 9 141 11 118 9 161 -8 144 -2 -2 -9 -72 -16 -156 l-12
-152 -35 -27 -35 -26 6 34 c34 195 42 287 22 267 -8 -7 -45 -244 -45 -283 0
-20 -7 -35 -21 -43 -12 -7 -23 -12 -25 -10 -2 2 1 57 6 123 7 75 7 122 1 128
-15 15 -21 -25 -24 -155 -2 -116 -2 -116 -30 -133 -16 -9 -31 -15 -33 -12 -3
3 0 58 6 122 11 105 10 138 -5 123 -8 -7 -34 -251 -29 -260 6 -9 -45 -43 -52
-35 -4 3 -1 50 5 103 6 53 9 99 7 101 -14 14 -24 -15 -30 -84 -12 -131 -16
-150 -40 -161 -22 -10 -22 -9 -14 57 10 91 10 121 -1 121 -10 0 -16 -32 -27
-138 -5 -56 -9 -64 -35 -78 -27 -13 -29 -13 -29 3 0 10 7 43 15 73 8 30 15 56
15 58 0 12 -232 -77 -254 -99 -26 -24 11 -16 100 21 55 23 104 44 110 46 7 3
6 -18 -4 -65 -16 -72 -21 -79 -75 -88 -16 -2 -72 -17 -125 -32 l-97 -27 71
141 c107 211 189 461 249 760 18 87 32 133 43 141 28 19 83 102 73 109 -6 3
-27 -17 -48 -44 -20 -28 -38 -49 -39 -48 -7 10 11 77 21 77 7 0 28 19 46 43
l33 43 58 -60 c71 -75 83 -124 13 -56 -24 24 -48 41 -52 37 -4 -4 17 -31 45
-60 29 -29 52 -60 52 -70 0 -23 -9 -21 -49 13 -19 16 -39 26 -44 23 -6 -3 11
-24 36 -46 35 -29 47 -46 47 -66 l-1 -26 -27 33 c-26 31 -42 40 -42 24 0 -5
16 -30 36 -56 39 -48 41 -66 5 -45 -33 21 -37 1 -6 -31 19 -20 25 -34 21 -47
-6 -14 -9 -15 -15 -4 -6 10 -40 -9 -135 -74 -69 -47 -126 -91 -126 -97 0 -16
24 -3 136 74 96 66 106 71 120 56 20 -20 11 -38 -16 -31 -25 7 -26 -9 -1 -31
17 -15 17 -20 3 -91 -8 -42 -12 -78 -8 -82 11 -11 16 2 28 64 8 45 15 59 28
59 15 -1 120 87 120 99 0 15 -28 0 -75 -40 l-53 -46 2 33 c1 17 8 34 14 36 19
6 112 83 112 93 0 15 -9 11 -57 -26 -44 -34 -71 -45 -58 -26 13 22 35 330 35
499 0 108 4 181 9 178 13 -8 113 113 104 127 -6 10 -14 3 -80 -77 l-32 -39 -5
34 c-12 83 -15 159 -5 159 13 0 139 101 139 112 0 18 -25 5 -82 -42 -33 -28
-62 -49 -65 -47 -2 2 -12 63 -23 134 -23 155 -19 177 46 248 25 28 43 45 40
39 -3 -6 37 -46 89 -89 73 -59 95 -83 95 -102 0 -23 -2 -23 -64 22 -36 26 -67
41 -71 36 -3 -6 26 -33 65 -61 53 -39 70 -57 70 -76 0 -13 -2 -24 -4 -24 -2 0
-23 16 -47 35 -24 19 -50 35 -56 35 -16 0 -87 -63 -114 -102 -17 -23 -18 -28
-5 -28 8 0 37 26 63 58 l48 58 57 -45 c41 -33 58 -53 58 -69 0 -23 -3 -21 -50
18 -71 60 -77 39 -7 -25 45 -41 57 -58 57 -81 l-1 -29 -20 27 c-43 58 -58 73
-65 66 -7 -8 30 -67 66 -106 11 -12 20 -29 20 -39 0 -13 -9 -7 -31 20 -17 20
-35 37 -40 37 -20 0 -7 -26 32 -66 29 -30 40 -48 37 -65 -3 -22 -6 -20 -28 18
-14 24 -31 43 -37 43 -7 0 -13 -2 -13 -4 0 -11 64 -106 71 -106 5 0 9 -15 9
-32 l-1 -33 -25 48 c-14 26 -29 45 -34 42 -10 -6 34 -108 50 -113 5 -2 10 -15
10 -28 0 -13 4 -24 9 -24 15 0 24 242 18 445 -10 271 -39 514 -88 726 l-20 87
47 44 c30 29 44 49 39 57 -5 9 -20 1 -50 -29 -40 -39 -43 -41 -49 -21 -3 12
-9 31 -12 42 -4 13 -2 19 3 16 6 -4 23 7 38 23 l28 29 81 -66 c45 -37 82 -68
84 -69 2 -1 2 -13 0 -25 -3 -23 -4 -22 -44 14 -39 35 -64 46 -64 29 0 -4 24
-28 54 -52 48 -40 54 -50 58 -88 4 -48 5 -48 -52 26 -16 23 -21 25 -30 12 -5
-8 -10 -11 -10 -5 0 5 -18 -9 -40 -30 -22 -21 -40 -43 -40 -47 0 -19 25 -5 56
30 l34 37 20 -25 c12 -14 32 -38 46 -54 13 -15 24 -39 24 -53 0 -16 4 -22 11
-17 9 5 10 51 5 168 -13 309 -55 634 -127 981 -21 105 -35 191 -30 195 5 3 17
37 25 75 9 39 18 80 21 93 4 14 1 22 -8 22 -9 0 -20 -32 -32 -92 l-19 -93 -14
60 c-9 39 -10 65 -4 75 5 9 13 39 17 68 4 33 11 52 19 49 6 -2 30 -36 52 -75z
m-99 -385 c18 -20 38 -36 45 -35 8 2 18 -30 30 -87 9 -49 16 -93 15 -97 -1 -3
-40 28 -87 70 -76 68 -85 80 -88 115 l-3 40 65 -69 c45 -48 68 -66 75 -59 8 8
-13 35 -69 90 -65 65 -80 85 -80 110 l1 30 31 -35 c17 -19 46 -52 65 -73z
m-4988 -203 c-131 -126 -151 -148 -138 -153 6 -2 60 45 120 105 l109 109 0
-55 -1 -55 -124 -104 c-69 -58 -125 -108 -125 -113 0 -20 29 -1 133 89 92 79
113 93 116 77 3 -14 -34 -54 -134 -150 -76 -72 -134 -134 -128 -137 5 -3 65
46 132 109 67 62 124 114 127 114 2 0 4 -19 4 -43 l0 -43 -125 -98 c-135 -107
-156 -126 -134 -126 8 0 68 43 134 95 65 52 121 93 122 91 8 -8 4 -95 -6 -120
l-12 -29 -23 22 c-19 18 -28 20 -47 12 -39 -17 -204 -176 -191 -185 8 -5 47
26 106 83 52 50 96 91 98 91 2 0 14 -13 26 -29 15 -21 20 -36 14 -51 l-8 -21
-18 20 c-19 21 -46 28 -46 13 0 -5 13 -18 29 -29 22 -17 27 -28 24 -47 -7 -32
-13 -32 -43 -1 -13 14 -30 25 -37 25 -8 0 -48 -29 -90 -65 -42 -36 -78 -63
-81 -61 -7 7 17 251 43 433 14 94 25 177 25 184 0 10 3 10 11 2 9 -9 39 12
118 82 94 83 106 91 109 71 2 -17 -19 -43 -89 -112z m5010 6 c35 -30 67 -54
71 -53 11 3 52 -307 74 -564 2 -24 2 -43 -1 -43 -2 0 -36 27 -75 60 -40 33
-77 60 -84 60 -6 0 -26 -13 -44 -30 -18 -16 -33 -30 -35 -30 -1 0 -5 12 -8 26
-4 20 3 33 32 60 40 37 26 46 -20 13 l-29 -20 0 23 c0 13 10 36 23 51 l22 28
97 -91 c53 -49 99 -90 102 -90 20 0 -4 31 -80 103 -100 94 -119 105 -146 80
-19 -17 -19 -16 -12 37 4 30 7 56 8 57 0 0 37 -28 81 -63 116 -94 133 -106
140 -95 3 6 -46 51 -110 101 -113 90 -115 92 -115 132 0 49 -3 50 116 -64 75
-72 104 -91 104 -70 0 5 -47 52 -105 106 -79 73 -105 104 -105 122 0 23 8 19
89 -50 81 -69 119 -92 109 -67 -1 5 -47 46 -100 91 l-98 82 0 51 0 52 85 -85
c46 -46 88 -81 92 -77 4 4 -34 49 -85 100 -64 64 -92 100 -92 116 0 27 -3 29
99 -59z m-4899 -523 c0 -5 -11 -18 -25 -29 -29 -22 -24 -44 6 -28 29 16 25 -8
-8 -42 -33 -34 -22 -50 12 -18 22 21 25 22 25 5 0 -16 -59 -76 -98 -99 -18
-10 -16 1 30 153 28 91 33 101 45 85 7 -10 13 -22 13 -27z m4799 -146 l21 -74
-30 28 c-32 31 -38 51 -17 58 9 3 9 7 -1 20 -9 10 -11 25 -6 39 4 13 9 19 10
13 2 -5 12 -43 23 -84z m8 -88 c17 -14 27 -43 43 -122 11 -57 19 -106 16 -108
-2 -3 -36 17 -74 44 -53 37 -67 53 -63 66 6 16 12 14 56 -17 26 -19 53 -32 58
-29 11 6 -27 41 -75 71 -20 12 -28 24 -28 45 l0 29 36 -27 c23 -17 39 -24 45
-18 6 6 -5 20 -31 38 -23 16 -40 36 -40 47 0 20 14 16 57 -19z m-10 -218 c35
-25 68 -43 72 -40 8 4 48 -292 40 -299 -2 -3 -44 29 -94 69 l-90 74 -34 -32
c-26 -25 -32 -28 -28 -12 3 11 11 49 18 85 6 36 15 69 19 73 4 5 46 -26 93
-67 46 -42 88 -76 91 -76 21 0 -9 35 -90 107 -67 58 -89 84 -85 96 5 14 20 5
78 -48 39 -36 74 -65 77 -65 20 0 -3 30 -69 92 -67 63 -87 88 -69 88 3 0 35
-20 71 -45z m-4672 -383 c-17 -16 -17 -15 -11 17 3 18 6 41 7 50 1 9 6 1 12
-17 8 -28 7 -36 -8 -50z m35 -36 c0 -6 -16 -27 -35 -46 l-35 -34 6 34 c9 52
13 62 23 55 5 -3 12 -1 16 5 8 13 25 3 25 -14z m4457 -58 c-3 -7 -5 -2 -5 12
0 14 2 19 5 13 2 -7 2 -19 0 -25z m-4434 -17 c3 -14 -11 -34 -49 -72 l-54 -53
0 29 c0 44 11 75 24 75 7 0 23 11 36 25 25 27 35 26 43 -4z m4452 -76 l7 -40
-27 27 c-22 21 -26 32 -21 54 6 23 9 25 21 13 7 -8 17 -32 20 -54z m-4435 9
c0 -9 -25 -45 -55 -79 -51 -58 -66 -86 -47 -85 4 1 30 27 57 60 28 32 53 59
56 59 4 1 9 -7 11 -17 4 -13 -20 -45 -78 -108 -94 -100 -91 -102 -77 39 5 53
9 67 22 67 10 0 35 18 56 40 42 43 55 49 55 24z m4444 -89 c3 -11 8 -51 11
-90 l6 -70 -57 57 c-50 49 -56 60 -48 79 10 21 11 20 37 -15 16 -20 32 -36 38
-36 19 0 8 25 -27 61 -27 28 -34 42 -29 58 l6 21 29 -22 c16 -13 32 -32 34
-43z m-4249 -415 c101 -225 236 -457 404 -694 58 -83 105 -153 104 -157 -10
-31 -14 -169 -6 -169 11 0 23 68 23 128 l0 35 29 -36 28 -37 -18 -67 c-21 -74
-24 -103 -10 -103 5 0 17 34 28 76 l18 77 26 -34 26 -34 -27 -90 -27 -90 -74
112 -73 112 3 80 c3 84 -3 121 -20 121 -7 0 -9 -31 -7 -80 2 -43 0 -76 -3 -72
-21 25 -49 82 -40 82 7 0 11 25 11 65 0 50 -3 65 -14 65 -11 0 -16 -15 -18
-52 l-3 -53 -17 31 c-14 25 -15 39 -8 65 13 43 13 69 0 69 -5 0 -12 -16 -16
-36 l-7 -36 -39 74 c-43 79 -55 95 -63 86 -4 -3 12 -42 34 -87 23 -45 39 -81
35 -81 -3 0 -31 23 -62 50 -31 28 -61 50 -66 50 -6 0 -31 -31 -56 -70 -25 -38
-48 -66 -51 -62 -12 14 -28 72 -20 72 5 0 27 31 50 69 l41 70 35 -15 c41 -17
45 -17 45 -3 0 10 -60 39 -83 39 -7 0 -30 -29 -51 -65 -21 -36 -41 -65 -46
-65 -4 0 -11 11 -14 25 -5 19 4 41 34 89 43 67 46 76 30 76 -6 0 -25 -23 -42
-50 -17 -28 -33 -48 -35 -46 -3 2 -13 37 -24 78 l-19 73 35 66 c38 73 40 79
25 79 -5 0 -23 -25 -38 -57 l-29 -56 -6 38 c-5 30 0 50 28 106 38 75 40 79 25
79 -5 0 -23 -25 -38 -57 l-29 -56 -16 104 c-9 57 -21 154 -27 214 -13 125 -17
142 -29 129 -8 -8 1 -137 14 -212 l6 -32 -41 42 -40 43 -65 -68 -65 -67 0 40
c0 25 5 40 13 40 19 0 142 116 131 123 -6 3 -39 -19 -75 -50 l-64 -56 -3 29
c-4 25 6 39 78 105 46 42 81 81 79 88 -3 8 -35 -15 -82 -59 l-77 -72 0 81 c0
60 3 79 13 76 6 -2 48 35 92 83 l80 88 56 -145 c31 -80 78 -193 104 -251z
m4207 278 c32 -37 57 -77 61 -96 l7 -33 -62 55 c-60 54 -78 65 -78 47 0 -9 63
-69 113 -108 15 -11 27 -31 27 -43 0 -18 -11 -12 -74 45 -41 36 -77 62 -80 57
-3 -5 30 -44 74 -86 66 -63 80 -82 80 -107 l0 -31 -64 57 c-35 31 -67 52 -70
46 -6 -9 110 -121 126 -121 4 0 8 -17 8 -37 l0 -38 -59 65 -59 65 -47 -45 -48
-45 6 47 c4 25 9 84 13 130 4 59 15 106 37 161 17 42 32 77 33 77 1 0 26 -28
56 -62z m-4374 -349 c23 -29 42 -61 42 -72 0 -17 -7 -12 -31 21 -17 23 -35 39
-40 36 -5 -3 -6 -11 -2 -17 10 -18 66 -91 74 -97 6 -5 29 -110 29 -134 0 -6
-31 20 -68 57 -37 37 -71 64 -75 61 -11 -11 115 -139 136 -138 9 0 17 -7 17
-15 0 -9 11 -63 25 -120 14 -57 23 -106 21 -108 -2 -3 -57 32 -121 76 l-117
80 -19 -22 -18 -22 -1 26 c0 15 12 36 30 54 17 16 30 32 30 37 0 15 -24 8 -47
-14 -38 -36 -27 0 18 59 48 61 43 87 -5 30 -17 -21 -35 -35 -39 -31 -17 17 -4
42 43 79 28 22 50 46 50 54 0 15 1 15 -69 -37 -28 -21 -31 -22 -31 -5 0 9 26
43 57 75 31 32 54 60 50 64 -3 3 -30 -15 -59 -40 -31 -27 -54 -41 -56 -34 -2
12 116 148 129 148 3 0 24 -23 47 -51z m389 -644 c33 -30 68 -55 75 -55 12 0
38 -38 38 -56 0 -2 -36 18 -80 46 l-79 50 -50 -65 -49 -65 -10 29 c-9 25 -5
36 36 100 26 39 49 71 52 71 3 0 33 -25 67 -55z m3365 -58 l-21 -42 0 43 c-1
24 4 53 11 65 l11 22 10 -23 c8 -18 6 -32 -11 -65z m-57 -106 c-8 -17 -18 -31
-22 -31 -10 0 6 110 20 134 11 19 12 17 15 -26 2 -29 -3 -59 -13 -77z m-63
-103 l-19 -30 -7 56 c-7 49 -5 60 16 88 l23 33 3 -58 c2 -44 -2 -66 -16 -89z
m-33 -37 c5 -3 -1 -21 -14 -39 l-22 -33 -15 60 c-15 57 -15 60 6 80 l21 22 7
-42 c4 -24 12 -45 17 -48z m-71 -123 c-23 -33 -30 -29 -47 21 -16 49 -14 72
11 98 l22 23 17 -57 c18 -55 18 -57 -3 -85z m-3284 58 c32 -14 62 -24 67 -21
8 6 34 -33 27 -40 -2 -2 -46 9 -98 25 -104 33 -110 34 -110 20 0 -9 178 -70
212 -73 13 -1 58 -57 46 -57 -2 0 -51 13 -110 29 -59 16 -109 27 -111 24 -3
-2 -14 0 -25 6 -14 8 -24 29 -32 68 -7 32 -15 63 -17 71 -6 13 34 0 151 -52z
m613 25 c9 -14 7 -39 -11 -116 l-22 -98 -27 34 c-14 18 -22 36 -17 39 4 3 18
40 31 83 12 42 25 77 28 77 3 0 11 -8 18 -19z m2608 -86 c17 -63 17 -65 -3
-90 -23 -29 -21 -32 -42 63 -11 47 -10 55 5 72 10 11 19 20 20 20 1 0 10 -29
20 -65z m-2562 -89 l-29 -97 -23 25 c-12 13 -18 27 -13 30 5 3 17 38 27 78 25
109 23 105 46 81 l21 -20 -29 -97z m2511 -16 c4 -24 2 -42 -8 -55 -13 -18 -16
-14 -36 53 -22 71 -22 73 -4 94 19 20 19 20 30 -18 6 -22 14 -55 18 -74z
m-2423 54 l24 -26 -27 7 c-23 6 -27 3 -32 -22 -13 -70 -18 -163 -8 -163 11 0
15 18 27 113 4 26 10 47 15 47 16 0 55 -19 80 -39 l25 -21 -31 6 -32 6 -22
-76 c-13 -42 -26 -79 -30 -81 -4 -3 -26 17 -48 44 l-41 48 25 79 c31 102 32
104 42 104 5 0 19 -12 33 -26z m2359 -75 c20 -66 20 -72 5 -96 -9 -13 -18 -23
-21 -21 -7 8 -25 160 -20 174 9 24 15 16 36 -57z m-51 -74 c0 -23 6 -46 11
-51 7 -7 4 -16 -9 -28 -19 -17 -20 -16 -40 49 -26 84 -26 90 2 110 21 15 22
14 28 -12 4 -16 7 -46 8 -68z m-2150 -10 l26 -25 -36 0 c-35 0 -37 -2 -64 -65
-15 -36 -31 -65 -35 -65 -4 0 -14 8 -23 18 -15 16 -15 23 4 82 10 36 22 71 24
79 8 21 74 6 104 -24z m2092 -36 l22 -71 -21 -21 c-12 -11 -23 -19 -24 -16 -2
2 -16 30 -31 62 -15 31 -32 57 -39 57 -12 0 51 59 64 60 4 0 17 -32 29 -71z
m-2022 -23 c9 -11 8 -15 -6 -18 -14 -4 -23 -26 -36 -88 -18 -89 -21 -95 -35
-86 -6 3 -4 37 4 83 14 79 10 130 -6 87 -5 -11 -11 -46 -15 -77 -4 -32 -10
-57 -14 -57 -3 0 -16 9 -29 18 l-24 18 33 67 c33 67 34 67 75 67 23 0 47 -6
53 -14z m1955 -48 l26 -62 -23 -24 -24 -23 -11 67 c-15 93 -37 112 -27 24 11
-91 11 -104 1 -110 -6 -3 -18 31 -27 75 l-18 80 22 17 c38 30 52 22 81 -44z
m-1883 -18 l24 -19 -27 -1 c-54 0 -58 -6 -58 -87 0 -47 4 -72 10 -68 6 3 10
33 10 65 l0 59 45 7 c31 4 50 2 61 -7 14 -12 12 -14 -17 -24 -35 -12 -34 -10
-53 -112 -4 -24 -2 -33 8 -33 9 0 17 20 22 57 8 53 11 58 42 67 23 6 40 5 57
-4 35 -19 31 -25 -22 -36 -27 -5 -48 -13 -49 -19 -3 -36 -19 -92 -31 -103 -10
-10 -27 -12 -66 -7 -83 12 -98 20 -52 30 22 5 46 7 53 6 6 -1 12 2 12 7 0 8
-20 25 -78 68 -2 1 4 41 14 88 17 82 19 86 45 86 15 0 37 -9 50 -20z m1780
-65 c16 -78 16 -79 -5 -96 l-21 -17 -3 74 c-3 69 -4 74 -27 80 l-25 6 23 19
c12 10 26 17 31 16 6 -2 17 -39 27 -82z m-54 11 c1 -1 3 -32 4 -70 2 -47 -1
-71 -9 -74 -7 -2 -12 -11 -12 -19 0 -9 6 -13 14 -10 8 3 33 0 57 -7 l44 -13
-74 -13 c-61 -10 -75 -10 -82 1 -8 12 -12 11 -22 -2 -17 -22 -93 -31 -111 -13
-8 8 -29 14 -47 14 -17 0 -49 5 -70 12 -35 10 -37 12 -21 24 24 19 50 17 117
-6 63 -22 85 -25 85 -12 0 4 -32 18 -71 31 l-72 23 31 20 c25 15 40 18 76 13
47 -7 48 -9 61 -73 5 -23 10 -31 17 -24 7 7 7 26 -2 60 -11 48 -13 50 -52 56
l-40 7 25 20 c18 13 34 18 51 13 27 -6 31 -14 41 -81 10 -65 29 -54 21 11 -8
68 -14 86 -32 86 -17 0 -18 12 -3 27 9 9 62 8 76 -1z m-1561 -155 c-42 -13
-76 -27 -76 -32 0 -14 21 -11 100 16 70 24 75 24 99 8 l25 -16 -34 -13 c-19
-7 -45 -13 -57 -13 -17 -1 -20 -6 -15 -23 4 -18 2 -20 -14 -14 -10 4 -46 10
-78 12 -68 5 -81 11 -67 28 6 7 14 30 19 52 8 37 10 39 62 49 49 10 58 9 83
-9 l29 -21 -76 -24z m-297 -13 c-47 -16 -63 -16 -122 -6 -80 13 -72 21 37 37
44 6 82 13 84 16 2 2 16 -3 29 -12 l25 -17 -53 -18z m2131 12 c3 -1 -24 -8
-60 -15 -55 -11 -72 -11 -108 1 -23 8 -50 14 -60 14 -15 1 -14 3 4 18 20 17
26 17 120 1 55 -10 101 -18 104 -19z m-1653 -46 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m118 -70 c44 -26 152 -82 240 -125 l159 -78 76 41
c41 23 138 78 215 122 l140 81 100 0 c94 0 100 -1 97 -20 -13 -100 -68 -182
-152 -229 l-55 -31 -401 -3 c-386 -3 -403 -2 -457 18 -63 24 -126 80 -156 139
-23 43 -42 117 -33 126 3 3 37 5 76 5 66 0 76 -3 151 -46z m-255 -3 c0 -5 3
-18 6 -28 6 -16 5 -16 -9 2 -19 23 -21 35 -7 35 6 0 10 -4 10 -9z m128 -204
c17 -20 -113 -175 -177 -211 l-53 -28 81 95 c44 53 87 110 96 126 16 33 36 39
53 18z m1062 -15 c0 -4 23 -36 51 -71 28 -35 49 -64 47 -66 -11 -11 -78 33
-105 70 -36 48 -40 61 -20 68 19 8 27 8 27 -1z m-1000 -22 c12 -7 12 -12 0
-35 -14 -26 -42 -45 -95 -65 -30 -11 -29 -10 24 63 35 48 45 53 71 37z m958
-38 l15 -23 -36 17 c-41 19 -49 37 -16 32 12 -2 29 -13 37 -26z m-658 1 c-2
-28 -82 -93 -230 -186 -132 -83 -203 -153 -347 -344 -62 -84 -129 -161 -147
-172 -43 -27 -99 -27 -189 1 -67 20 -78 21 -167 10 -119 -15 -220 -16 -220 -1
0 5 23 35 51 65 35 36 116 95 248 178 321 203 426 264 621 362 l185 93 98 0
c53 1 97 -2 97 -6z m300 -3 c0 -6 -49 -10 -126 -10 -81 0 -123 4 -119 10 4 6
57 10 126 10 73 0 119 -4 119 -10z m417 -74 c314 -156 794 -452 886 -546 31
-32 57 -63 57 -69 0 -14 -104 -14 -224 0 -86 11 -99 10 -164 -10 -90 -27 -145
-27 -189 0 -20 12 -79 81 -136 158 -147 198 -209 259 -372 366 -140 91 -215
152 -215 174 0 7 31 11 95 11 l94 0 168 -84z m-383 8 c3 -9 6 -20 6 -25 0 -5
-70 -9 -160 -9 -90 0 -160 4 -160 9 0 39 9 41 160 41 126 0 149 -2 154 -16z
m-342 -11 c-8 -15 -21 -68 -37 -148 -4 -18 -12 -20 -92 -21 -66 0 -102 -6
-143 -22 l-55 -22 30 22 c16 13 74 51 128 85 54 35 115 77 135 93 42 35 46 36
34 13z m563 -118 c77 -51 163 -117 190 -145 l50 -52 -69 42 c-101 61 -172 90
-252 103 l-72 11 -6 48 c-4 26 -13 66 -21 88 l-15 41 28 -21 c15 -11 90 -63
167 -115z m-195 35 c0 -19 -7 -20 -184 -20 -179 0 -184 1 -179 20 5 19 14 20
184 20 172 0 179 -1 179 -20z m14 -76 c3 -9 6 -18 6 -20 0 -2 -90 -4 -200 -4
-193 0 -200 1 -200 20 0 19 7 20 194 20 168 0 195 -2 200 -16z m4 -91 c-3 -16
-22 -18 -201 -21 -192 -2 -197 -2 -197 18 0 19 6 20 201 20 186 0 200 -1 197
-17z m-8 -70 c-1 -31 -19 -33 -196 -31 -161 3 -179 5 -182 21 -3 16 11 17 187
17 105 0 191 -3 191 -7z m-32 -100 c-3 -16 -20 -18 -155 -21 -144 -2 -153 -1
-163 18 -11 20 -8 20 155 20 153 0 166 -1 163 -17z m-34 -78 c-3 -8 -15 -17
-26 -21 -72 -22 -78 -206 -9 -244 25 -13 87 -13 112 0 23 13 49 61 49 93 0 60
-102 89 -130 36 -24 -43 39 -82 70 -44 17 21 7 29 -19 16 -13 -7 -22 -8 -26
-1 -10 16 4 30 28 30 34 0 50 -15 50 -46 0 -33 -43 -74 -76 -74 -81 0 -118
147 -50 200 21 16 40 20 107 20 44 0 97 -5 117 -10 93 -26 107 -107 41 -244
-59 -123 -120 -175 -256 -221 -41 -14 -82 -25 -90 -25 -14 0 -16 27 -16 230 0
147 -4 230 -10 230 -6 0 -10 -83 -10 -230 0 -177 -3 -230 -12 -230 -34 0 -172
57 -215 89 -82 63 -154 197 -157 293 -2 85 53 118 197 118 69 0 81 -3 102 -24
31 -31 40 -81 23 -131 -24 -72 -96 -84 -141 -23 -17 23 -19 31 -9 53 10 22 18
26 45 23 20 -2 32 -8 32 -18 0 -15 -7 -16 -37 -5 -13 5 -18 2 -18 -8 0 -19 33
-31 60 -22 28 9 27 60 -1 75 -47 25 -109 -13 -109 -67 0 -52 45 -93 102 -93
72 0 108 47 108 140 0 43 -5 55 -40 95 -22 25 -40 48 -40 51 0 2 58 4 130 4
109 0 129 -2 124 -15z"/>
<path d="M3325 6759 c-396 -171 -726 -315 -732 -321 -7 -6 -13 -18 -13 -28 0
-71 -132 -369 -216 -486 -92 -130 -247 -249 -452 -347 l-125 -59 6 -208 c14
-449 66 -830 168 -1233 178 -704 601 -1411 1148 -1915 272 -250 501 -412 792
-557 60 -30 113 -55 118 -55 15 0 212 114 339 197 262 169 459 337 685 579
211 228 284 316 406 489 311 444 478 785 611 1255 105 372 176 839 196 1286
l7 149 -99 45 c-134 60 -262 148 -360 245 -152 151 -242 322 -295 558 -8 39
-20 64 -33 72 -26 17 -1422 646 -1430 645 0 0 -325 -140 -721 -311z m745 -263
c347 -53 663 -283 822 -601 133 -264 140 -580 18 -875 -17 -41 -29 -79 -26
-86 2 -7 50 -47 105 -89 56 -43 101 -80 101 -82 0 -3 -189 -251 -210 -276 -4
-4 -55 31 -113 77 l-106 86 -77 -56 c-140 -100 -273 -164 -429 -207 -68 -18
-106 -21 -250 -21 -152 0 -180 3 -267 26 -54 15 -141 45 -194 68 -111 47 -114
46 -114 -41 0 -134 -130 -208 -238 -136 -48 31 -78 97 -68 149 13 72 79 138
137 138 15 0 31 6 37 13 9 10 -5 29 -59 83 -39 38 -75 77 -80 87 -8 14 3 35
52 102 38 53 65 82 73 77 6 -4 44 -29 84 -56 92 -60 193 -107 308 -143 82 -25
102 -27 264 -27 173 -1 176 0 270 32 91 32 189 81 239 120 l23 19 -398 311
c-220 172 -401 312 -404 312 -3 -1 -40 -51 -84 -111 -45 -64 -83 -108 -91
-106 -6 3 -80 58 -164 122 -105 81 -149 121 -143 129 5 6 104 138 220 294
l210 283 78 2 c88 3 148 25 215 78 l39 31 85 -66 84 -66 -116 -151 c-65 -84
-112 -154 -107 -159 5 -4 185 -146 399 -314 l390 -306 14 33 c7 17 22 75 33
127 88 421 -167 898 -597 1113 -38 19 -89 40 -113 48 -29 8 -39 15 -30 21 19
11 71 10 178 -6z m-528 -2444 c3 -25 7 -27 53 -30 28 -2 244 -1 482 3 l432 7
3 27 c2 15 10 27 20 29 13 3 18 -7 23 -45 13 -103 47 -141 137 -152 53 -6 53
-6 23 -18 -16 -6 -38 -14 -47 -17 -11 -3 -18 -15 -18 -31 0 -32 40 -51 124
-61 65 -8 63 -21 -10 -51 -41 -17 -44 -21 -44 -55 l0 -38 369 0 368 0 51 -147
c28 -81 50 -148 49 -149 -1 -1 -156 -4 -344 -7 -299 -4 -343 -3 -343 10 0 8
-14 28 -30 45 -26 25 -36 29 -63 24 -33 -6 -77 -47 -77 -72 0 -11 -16 -14 -70
-14 -64 0 -70 2 -70 21 0 11 -13 34 -29 50 -53 53 -141 34 -159 -34 l-8 -32
-91 -3 -91 -3 -7 33 c-18 81 -60 118 -135 118 -75 0 -120 -38 -134 -112 l-7
-38 -104 0 -104 0 -10 36 c-6 20 -18 45 -27 55 -17 19 -66 25 -99 13 -19 -8
-45 -55 -45 -84 0 -18 -6 -20 -79 -20 -78 0 -79 0 -86 28 -15 63 -61 98 -111
86 -26 -7 -64 -60 -64 -91 0 -19 -9 -20 -137 -26 -239 -12 -506 -9 -501 6 3 6
22 75 43 153 l38 141 195 6 c107 4 255 7 329 7 l133 0 0 44 c0 42 -2 46 -45
70 -25 15 -45 31 -45 36 0 6 17 10 38 10 21 0 62 7 90 15 43 13 52 20 52 39 0
13 -7 29 -16 37 -13 11 -7 15 45 31 72 22 101 54 101 110 0 48 15 79 35 72 7
-3 15 -18 17 -32z m258 -1277 c0 -23 3 -25 50 -25 47 0 50 2 50 25 0 14 5 25
10 25 6 0 10 -11 10 -25 0 -22 4 -25 40 -25 36 0 40 3 40 25 0 16 6 25 15 25
9 0 15 -9 15 -25 0 -22 4 -25 40 -25 36 0 40 3 40 25 0 35 17 31 22 -5 l5 -30
-169 0 -168 0 0 -35 c0 -35 0 -35 48 -37 l47 -2 -47 -3 c-42 -4 -48 -7 -48
-27 0 -23 1 -23 190 -30 105 -3 195 -4 200 -1 6 4 10 15 10 26 0 17 -8 19 -62
21 l-63 2 62 3 63 4 -2 59 c-1 33 1 63 5 67 4 3 7 -10 7 -30 0 -25 5 -37 14
-37 8 0 21 -3 30 -6 12 -4 16 0 16 15 0 12 5 21 11 21 7 0 9 -10 5 -25 -5 -21
-2 -27 19 -35 35 -13 33 -14 38 18 4 25 4 25 6 -5 0 -21 8 -36 21 -43 21 -11
24 -9 33 35 3 11 5 -35 6 -102 0 -67 5 -125 11 -128 5 -3 29 -1 52 6 51 14
183 17 256 6 l52 -8 0 91 c0 53 4 90 10 90 6 0 10 -4 10 -10 0 -5 16 -10 35
-10 19 0 35 5 35 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 11 -10 25 -10 14 0
25 5 25 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -15 48 -12 53 3 3 8 11 4 26
-12 18 -21 21 -36 21 -120 0 -95 -1 -96 -35 -136 l-34 -40 -2 110 c0 61 0 123
1 138 l2 28 -104 -3 -103 -3 0 -162 0 -163 -40 0 c-22 0 -50 -3 -62 -6 -18 -5
-23 -2 -23 14 0 11 6 25 14 31 8 7 24 33 36 59 l22 46 -20 17 c-46 36 -179 31
-279 -12 -42 -18 -43 -19 -43 -67 0 -45 3 -50 37 -73 20 -13 61 -53 91 -89 29
-36 74 -89 99 -118 44 -51 45 -54 28 -73 -10 -10 -22 -19 -27 -19 -5 0 -48 26
-96 58 -48 32 -125 73 -172 92 -112 44 -160 68 -187 93 -29 26 -30 67 -3 74
19 5 20 14 20 144 l0 139 -108 0 c-60 0 -150 3 -200 6 l-92 7 0 -137 c0 -75 3
-136 8 -136 4 0 17 7 28 15 17 11 23 29 28 84 8 74 21 94 69 106 33 9 70 0 91
-21 12 -11 16 -36 16 -99 l0 -83 -29 -7 c-37 -8 -81 -51 -81 -79 0 -47 27 -61
150 -75 106 -13 167 -38 204 -82 33 -38 38 -70 34 -213 -3 -110 -5 -120 -25
-134 l-23 -14 7 62 c7 70 11 70 -67 14 -70 -49 -156 -89 -258 -118 -89 -26
-93 -26 -125 -9 l-32 17 35 9 c236 62 309 92 382 157 53 47 73 93 65 157 -9
79 -42 99 -216 127 -137 23 -174 51 -157 121 9 37 54 83 86 90 29 6 30 8 30
70 0 71 -11 85 -69 85 -38 0 -49 -20 -57 -98 -6 -54 -9 -60 -41 -78 -54 -28
-64 -52 -61 -130 l3 -68 65 -22 c36 -12 97 -28 135 -34 75 -13 139 -47 150
-79 10 -34 -23 -89 -72 -118 -55 -32 -189 -74 -310 -98 l-92 -17 -38 23 -38
24 30 5 c25 4 30 10 33 38 2 24 8 32 23 32 15 0 19 -7 19 -31 0 -24 4 -29 18
-25 9 3 29 8 45 12 23 5 27 11 27 40 0 27 4 34 20 34 16 0 20 -7 20 -31 0 -24
3 -30 16 -25 9 3 22 6 30 6 9 0 14 11 14 35 0 28 4 35 20 35 15 0 20 -7 20
-25 0 -28 6 -30 44 -15 20 7 26 16 26 40 0 23 4 30 20 30 15 0 20 -7 20 -25 0
-29 10 -31 38 -7 27 23 35 54 20 75 -8 10 -54 28 -108 42 -236 63 -226 49
-216 296 6 158 5 177 -9 183 -53 20 -93 -2 -160 -89 -83 -109 -206 -221 -278
-256 -68 -32 -149 -39 -182 -16 -20 16 -17 19 40 37 62 20 163 91 245 175 61
61 72 77 60 85 -35 22 -54 15 -167 -66 -127 -91 -234 -135 -287 -118 -16 6 -6
11 44 21 39 8 92 30 135 55 91 53 105 66 105 96 0 25 0 25 -75 19 -59 -4 -94
-15 -175 -51 -56 -25 -109 -46 -118 -46 -8 0 -26 10 -39 22 -40 38 -28 158 16
158 10 0 25 -7 32 -17 13 -15 12 -15 -9 -2 -21 13 -25 12 -38 -6 -10 -13 -14
-37 -12 -70 3 -48 4 -50 35 -53 17 -2 50 2 72 8 l41 11 0 105 0 105 -107 -3
-108 -3 -6 -70 c-3 -38 -7 -63 -8 -55 -4 19 -4 32 4 153 6 84 9 97 25 97 10 0
20 -9 23 -20 7 -29 57 -28 65 2 l5 22 8 -22 c11 -30 59 -31 59 -2 0 11 5 20
10 20 6 0 10 -11 10 -25 0 -25 -1 -25 -91 -25 l-90 0 3 -27 3 -28 98 -3 97 -3
0 31 c0 19 5 30 14 30 7 0 16 9 19 20 3 11 10 20 16 20 7 0 11 -46 11 -141 0
-131 1 -141 18 -134 77 34 119 43 162 35 61 -12 127 -12 150 0 10 6 61 13 112
16 74 5 107 13 161 37 l67 30 0 103 c0 63 4 104 10 104 6 0 10 -11 10 -25z
m380 -75 c0 -19 -7 -20 -103 -20 -157 0 -257 10 -257 26 0 11 35 14 180 14
173 0 180 -1 180 -20z m-1150 -20 c0 -6 -33 -10 -80 -10 -47 0 -80 4 -80 10 0
6 33 10 80 10 47 0 80 -4 80 -10z m1085 -50 c39 -4 -10 -5 -110 -2 -99 3 -182
6 -184 8 -7 7 221 2 294 -6z m-1087 -97 c-3 -74 -6 -88 -20 -91 -15 -3 -18 4
-18 45 0 56 -21 93 -54 93 -38 0 -58 -23 -63 -73 -8 -71 -23 -46 -23 39 l0 74
90 0 91 0 -3 -87z m1962 47 c0 -6 -35 -10 -85 -10 -50 0 -85 4 -85 10 0 6 35
10 85 10 50 0 85 -4 85 -10z m-913 -10 l103 0 0 -115 c0 -102 -2 -117 -20
-135 -57 -57 -1 -117 169 -185 69 -27 155 -71 205 -105 l88 -58 -32 -22 c-30
-21 -34 -22 -59 -8 -14 9 -51 19 -82 22 -79 10 -87 -4 -49 -76 l29 -56 -39
-26 c-22 -14 -42 -26 -45 -26 -3 0 -5 55 -5 123 0 97 -4 130 -19 163 -33 72
-101 107 -237 124 -49 6 -97 13 -106 16 -22 7 -32 38 -19 62 12 23 65 47 86
38 13 -5 15 10 15 98 0 95 -2 104 -24 125 -32 30 -94 38 -134 17 -40 -21 -49
-38 -58 -114 -11 -102 -24 -81 -24 39 l0 112 77 -7 c42 -3 123 -6 180 -6z
m-1127 -96 c0 -32 -4 -44 -15 -44 -16 0 -21 52 -9 84 11 28 24 6 24 -40z
m2040 -53 c0 -63 -4 -112 -10 -116 -6 -4 -10 25 -10 79 0 76 -2 86 -22 100
-31 22 -58 20 -85 -6 -20 -19 -23 -31 -23 -100 0 -45 -4 -78 -10 -78 -6 0 -10
45 -10 115 l0 115 85 0 85 0 0 -109z m-1239 -77 c-5 -247 -3 -249 227 -305
104 -26 120 -37 101 -70 l-14 -24 -3 23 c-3 18 -10 22 -43 22 -38 0 -39 -1
-39 -34 0 -21 -6 -36 -15 -40 -11 -4 -15 1 -15 19 0 22 -4 25 -40 25 -39 0
-40 -1 -40 -35 0 -19 -4 -35 -10 -35 -5 0 -10 11 -10 25 0 22 -4 25 -39 25
-39 0 -40 -1 -43 -37 -2 -31 -7 -39 -25 -41 -19 -3 -23 1 -23 22 0 24 -4 26
-40 26 -39 0 -40 -1 -40 -35 0 -28 -4 -35 -20 -35 -15 0 -20 7 -20 25 0 22 -4
25 -39 25 -22 0 -41 -4 -43 -9 -1 -5 -28 8 -58 30 l-55 39 40 -1 c22 0 61 -4
88 -8 26 -4 47 -5 47 -1 0 14 -79 79 -122 102 -32 16 -62 23 -122 24 -76 2
-80 3 -115 38 l-36 36 44 0 c100 0 257 116 387 286 59 77 82 95 119 91 19 -2
20 -8 16 -173z m233 140 c12 -4 16 -20 16 -55 0 -46 -2 -50 -34 -63 -45 -19
-78 -68 -84 -126 -4 -43 -2 -49 24 -67 40 -28 54 -32 177 -53 59 -10 119 -25
133 -35 33 -21 47 -60 41 -113 -10 -90 -145 -178 -347 -227 -115 -27 -151 -30
-181 -15 -25 14 -24 30 2 30 42 0 235 53 299 81 76 35 107 61 127 108 30 70
-22 122 -144 147 -43 8 -106 24 -140 35 l-63 21 0 60 c0 54 3 63 31 88 16 16
38 32 48 35 14 4 20 20 25 72 4 36 9 70 12 75 6 9 36 11 58 2z m949 -17 c15
-10 17 -27 15 -107 l-3 -95 -33 1 c-57 3 -57 3 -52 103 4 67 9 94 20 101 20
13 33 12 53 -3z m-1633 -15 c0 -15 -33 -39 -111 -81 -94 -51 -183 -65 -211
-34 -17 18 -13 20 90 65 59 26 116 49 127 51 37 8 105 7 105 -1z m160 0 c0
-15 -144 -149 -195 -181 -28 -18 -74 -39 -103 -47 -53 -16 -54 -15 -78 7 l-24
23 77 26 c83 28 130 54 232 132 60 45 91 59 91 40z m1252 -18 c16 -5 17 -8 2
-42 -8 -20 -24 -46 -34 -58 -22 -23 -26 -59 -8 -70 7 -4 58 -8 115 -8 109 -1
138 -11 113 -41 -11 -13 -25 -15 -74 -10 -45 5 -66 3 -80 -8 -18 -13 -17 -15
13 -49 l31 -36 -43 -35 -44 -36 -40 42 c-21 23 -65 74 -96 114 -31 40 -73 84
-92 99 -29 22 -35 33 -35 65 0 44 9 51 92 74 49 13 132 13 180 -1z m132 -253
c18 -7 18 -8 -5 -24 -13 -9 -28 -17 -33 -17 -12 0 -46 31 -46 42 0 10 58 9 84
-1z m-1422 -146 c24 -9 60 -29 79 -46 32 -28 32 -30 9 -25 -14 3 -50 6 -80 6
-49 1 -60 5 -100 40 l-45 39 46 1 c25 0 66 -7 91 -15z m1097 -110 c23 -12 23
-12 -20 -43 -24 -18 -46 -32 -49 -32 -8 0 -50 74 -50 89 0 13 84 4 119 -14z
m-939 -75 c0 -18 -3 -19 -20 -10 -11 6 -20 15 -20 20 0 6 9 10 20 10 13 0 20
-7 20 -20z m673 -92 l-5 -46 -115 -66 -115 -65 -65 32 -65 32 99 31 c103 32
150 54 223 103 24 17 45 29 46 28 1 -1 0 -23 -3 -49z"/>
<path d="M3445 3856 c-11 -8 -16 -19 -12 -30 6 -15 55 -16 557 -16 482 0 551
2 556 15 4 8 1 22 -6 30 -11 13 -86 15 -544 15 -432 0 -536 -3 -551 -14z"/>
<path d="M3322 3673 c3 -47 4 -48 39 -51 44 -4 62 15 57 60 -3 32 -5 33 -51
36 l-48 3 3 -48z"/>
<path d="M3442 3673 c3 -42 6 -48 27 -51 12 -2 34 -1 48 3 21 5 24 10 21 48
l-3 42 -48 3 -48 3 3 -48z"/>
<path d="M3562 3673 l3 -48 45 0 45 0 3 48 3 47 -51 0 -51 0 3 -47z"/>
<path d="M3687 3713 c-13 -12 -7 -70 7 -82 9 -7 40 -11 78 -9 l63 3 3 48 3 47
-74 0 c-40 0 -77 -3 -80 -7z"/>
<path d="M3867 3714 c-11 -12 -8 -69 5 -82 14 -14 215 -17 236 -3 8 5 12 23
10 47 l-3 39 -121 3 c-66 1 -123 -1 -127 -4z"/>
<path d="M4136 3705 c-10 -26 -7 -60 6 -73 17 -17 133 -16 147 2 7 8 11 30 9
48 l-3 33 -76 3 c-63 2 -78 0 -83 -13z"/>
<path d="M4312 3673 c3 -47 4 -48 39 -51 44 -4 62 15 57 60 -3 32 -5 33 -51
36 l-48 3 3 -48z"/>
<path d="M4432 3673 l3 -48 40 0 40 0 3 48 3 47 -46 0 -46 0 3 -47z"/>
<path d="M4567 3713 c-4 -3 -7 -23 -7 -44 0 -32 4 -40 24 -45 13 -3 35 -4 47
-2 21 3 24 9 27 51 l3 47 -44 0 c-24 0 -47 -3 -50 -7z"/>
<path d="M4211 2514 c-1 -136 2 -200 10 -210 12 -15 113 -19 157 -8 21 6 22
11 22 103 l0 97 -32 18 c-29 15 -18 14 20 -1 8 -3 12 5 12 24 0 25 -6 33 -40
48 -22 10 -38 20 -36 22 2 3 18 -3 35 -11 40 -21 41 -21 41 12 0 26 -7 31 -87
65 -49 21 -91 37 -94 37 -3 0 -7 -88 -8 -196z m105 136 c37 -17 65 -33 62 -35
-3 -3 -38 10 -79 29 -41 19 -69 35 -62 35 6 1 41 -13 79 -29z m-36 -20 c19
-11 31 -19 25 -19 -5 0 -26 8 -45 19 -19 11 -30 19 -25 19 6 0 26 -8 45 -19z
m33 -44 c37 -16 67 -33 67 -39 0 -10 1 -10 -87 28 -35 15 -63 32 -63 37 0 5 4
8 8 6 4 -1 38 -16 75 -32z m-23 -31 c14 -8 21 -14 15 -14 -5 0 -21 6 -35 14
-14 8 -20 14 -15 14 6 0 21 -6 35 -14z m53 -53 l37 -15 0 -83 c0 -96 -3 -98
-96 -92 l-49 3 -3 118 -3 118 38 -17 c21 -9 55 -23 76 -32z"/>
<path d="M4754 2382 c-13 -8 -44 -66 -44 -82 0 -5 16 -10 35 -10 l35 0 0 50
c0 49 -4 57 -26 42z m6 -47 c0 -15 -6 -25 -15 -25 -12 0 -13 4 -3 25 6 14 13
25 15 25 1 0 3 -11 3 -25z"/>
<path d="M3544 2283 c-6 -22 0 -33 39 -70 46 -43 82 -54 105 -31 15 15 1 41
-46 91 -45 47 -88 51 -98 10z m66 -6 c21 -16 60 -68 60 -78 0 -5 -8 -9 -17 -9
-20 0 -93 68 -93 87 0 16 30 16 50 0z"/>
<path d="M3450 2183 c-96 -31 -77 -74 49 -113 69 -22 146 -26 157 -9 8 14 -39
64 -95 100 -42 27 -76 34 -111 22z m106 -42 c21 -15 48 -38 59 -51 l20 -23
-52 6 c-59 7 -165 46 -170 64 -5 13 39 31 78 32 14 1 44 -12 65 -28z"/>
<path d="M4882 2438 c-7 -7 -12 -42 -12 -85 l0 -73 35 0 35 0 0 66 c0 90 -23
127 -58 92z m36 -76 c2 -51 0 -62 -13 -62 -12 0 -15 13 -15 66 0 48 3 65 13
62 7 -3 13 -28 15 -66z"/>
<path d="M1562 5884 c-40 -71 -47 -95 -23 -80 7 5 76 118 89 149 2 4 -2 7 -10
7 -7 0 -32 -34 -56 -76z"/>
<path d="M1542 5660 c-73 -79 -95 -110 -76 -110 7 0 124 124 156 165 10 13 14
26 10 29 -5 3 -46 -35 -90 -84z"/>
<path d="M1521 5558 c-79 -90 -91 -108 -72 -108 9 0 163 173 169 191 11 29
-26 -2 -97 -83z"/>
<path d="M1515 5460 c-49 -49 -86 -93 -82 -97 4 -4 49 34 99 84 49 50 86 93
81 96 -5 3 -49 -34 -98 -83z"/>
<path d="M6380 5955 c0 -18 87 -155 98 -155 12 0 -40 108 -71 148 -9 12 -27
17 -27 7z"/>
<path d="M1310 5697 c0 -12 52 -232 56 -239 3 -5 9 -6 13 -3 12 7 -43 238 -57
243 -7 2 -12 2 -12 -1z"/>
<path d="M1210 5339 c0 -18 100 -189 111 -189 15 0 12 8 -43 106 -47 87 -68
111 -68 83z"/>
<path d="M1170 5223 c0 -10 118 -147 131 -151 24 -9 4 26 -52 89 -57 64 -79
81 -79 62z"/>
<path d="M1120 5091 c0 -15 130 -201 141 -201 17 0 10 14 -58 113 -61 89 -83
112 -83 88z"/>
<path d="M1110 4961 c0 -22 157 -158 168 -146 7 7 -146 154 -160 155 -5 0 -8
-4 -8 -9z"/>
<path d="M1016 4923 c-13 -44 -36 -156 -36 -176 0 -42 19 -1 39 86 12 49 21
93 21 98 0 16 -18 10 -24 -8z"/>
<path d="M1090 4853 c0 -12 155 -143 170 -143 21 0 7 16 -65 78 -75 63 -105
82 -105 65z"/>
<path d="M780 4833 c0 -13 143 -223 151 -223 19 0 6 25 -63 126 -64 93 -88
119 -88 97z"/>
<path d="M1031 4711 c-18 -44 -31 -86 -28 -93 5 -14 77 133 77 158 0 33 -19 9
-49 -65z"/>
<path d="M1140 4734 c0 -13 113 -123 121 -118 12 7 -90 124 -107 124 -8 0 -14
-3 -14 -6z"/>
<path d="M766 4711 c-7 -11 155 -210 172 -210 6 -1 12 2 12 5 0 6 -171 214
-176 214 -1 0 -5 -4 -8 -9z"/>
<path d="M1080 4618 c-48 -110 -50 -117 -40 -123 9 -6 64 104 76 152 11 43
-13 23 -36 -29z"/>
<path d="M1140 4634 c0 -12 105 -124 117 -124 23 0 14 15 -45 73 -53 52 -72
66 -72 51z"/>
<path d="M1356 4576 c-32 -27 -45 -45 -38 -50 7 -4 30 10 55 34 47 43 57 48
57 27 0 -19 56 -82 63 -71 7 12 -62 103 -78 103 -5 0 -32 -19 -59 -43z"/>
<path d="M760 4571 c0 -11 199 -181 212 -181 22 0 -1 25 -94 104 -100 84 -118
96 -118 77z"/>
<path d="M1110 4423 c-18 -43 -35 -84 -37 -90 -3 -7 0 -13 6 -13 12 0 65 109
77 156 13 53 -15 19 -46 -53z"/>
<path d="M780 4476 c0 -10 183 -181 197 -184 27 -6 10 14 -84 102 -77 73 -113
99 -113 82z"/>
<path d="M1139 4324 c-16 -37 -32 -78 -35 -93 -12 -48 22 -2 55 78 35 83 35
81 21 81 -6 0 -24 -30 -41 -66z"/>
<path d="M790 4374 c0 -8 190 -208 202 -212 27 -11 -4 32 -84 119 -81 86 -118
115 -118 93z"/>
<path d="M1180 4253 c-36 -80 -46 -113 -32 -113 8 0 22 28 68 137 9 20 10 34
4 38 -5 3 -23 -25 -40 -62z"/>
<path d="M1575 4199 c-108 -95 -109 -96 -97 -103 10 -6 192 145 200 166 10 25
-32 -1 -103 -63z"/>
<path d="M800 4251 c0 -12 218 -191 232 -191 21 0 -1 22 -107 110 -108 88
-125 99 -125 81z"/>
<path d="M1201 4178 c-5 -13 -21 -49 -35 -82 -30 -68 -30 -66 -13 -66 10 0 77
140 77 163 0 16 -21 5 -29 -15z"/>
<path d="M1553 4104 c-76 -60 -105 -95 -68 -80 21 8 174 129 174 139 2 19 -31
1 -106 -59z"/>
<path d="M815 4150 c-3 -5 47 -45 112 -88 140 -94 160 -105 167 -93 3 5 -20
26 -52 47 -161 107 -222 143 -227 134z"/>
<path d="M1225 4068 c-7 -18 -23 -55 -34 -81 -24 -56 -24 -57 -11 -57 10 0 58
96 74 148 12 35 -13 26 -29 -10z"/>
<path d="M1533 4029 c-73 -62 -103 -98 -74 -87 22 7 170 134 171 146 0 21 -13
13 -97 -59z"/>
<path d="M816 3993 c-9 -56 -7 -163 3 -163 8 0 21 94 21 161 0 50 -15 50 -24
2z"/>
<path d="M1310 4020 c0 -20 73 -120 88 -120 12 0 11 5 -3 28 -59 91 -85 119
-85 92z"/>
<path d="M880 4011 c0 -12 193 -141 223 -149 42 -11 9 18 -94 83 -125 79 -129
81 -129 66z"/>
<path d="M1255 3978 c-7 -18 -23 -55 -35 -82 -14 -33 -17 -53 -11 -59 7 -7 13
-2 19 14 5 13 21 52 36 86 29 71 30 73 15 73 -5 0 -16 -15 -24 -32z"/>
<path d="M887 3934 c-12 -43 -29 -185 -23 -191 12 -12 17 5 32 97 10 59 12 96
6 102 -7 7 -12 3 -15 -8z"/>
<path d="M1265 3865 c-35 -75 -42 -105 -27 -105 12 0 81 169 71 176 -5 3 -25
-29 -44 -71z"/>
<path d="M1581 3928 c0 -19 103 -101 108 -87 3 11 -85 98 -101 99 -5 0 -8 -6
-7 -12z"/>
<path d="M946 3843 c-35 -173 -40 -230 -19 -210 7 8 42 181 42 215 1 31 -16
28 -23 -5z"/>
<path d="M1491 3829 c-46 -37 -63 -59 -44 -59 11 0 113 82 113 91 0 17 -18 9
-69 -32z"/>
<path d="M1570 3847 c0 -17 98 -113 106 -104 8 7 -83 117 -97 117 -5 0 -9 -6
-9 -13z"/>
<path d="M1300 3836 c0 -2 -16 -40 -35 -85 -19 -45 -30 -83 -25 -86 6 -3 18
11 27 32 10 21 18 40 20 42 1 2 23 -14 48 -34 25 -21 49 -34 53 -30 9 9 -77
87 -89 79 -9 -5 9 44 22 59 4 5 19 -12 34 -37 25 -44 45 -59 45 -35 0 23 -62
99 -80 99 -11 0 -20 -2 -20 -4z"/>
<path d="M1030 3802 c0 -12 115 -56 132 -49 7 3 -7 14 -32 25 -59 27 -100 37
-100 24z"/>
<path d="M1489 3751 c-30 -27 -51 -52 -47 -56 8 -9 118 81 118 96 0 18 -17 8
-71 -40z"/>
<path d="M1005 3704 c-41 -95 -55 -137 -50 -142 9 -10 22 11 54 88 19 45 27
78 22 83 -6 6 -16 -5 -26 -29z"/>
<path d="M1480 3667 c-42 -56 -47 -67 -30 -67 9 0 90 101 90 113 0 20 -24 1
-60 -46z"/>
<path d="M1287 3633 c-7 -11 81 -153 94 -153 16 0 10 15 -32 81 -49 76 -55 83
-62 72z"/>
<path d="M1476 3590 c-36 -40 -45 -60 -27 -60 10 0 81 82 81 94 0 16 -21 3
-54 -34z"/>
<path d="M1127 3584 c-10 -10 -41 -194 -34 -201 12 -12 17 2 36 101 16 86 16
117 -2 100z"/>
<path d="M1491 3538 c-47 -74 -53 -88 -39 -88 13 0 68 81 68 100 0 17 -15 11
-29 -12z"/>
<path d="M1187 3543 c-10 -17 -50 -234 -43 -240 3 -3 8 -4 11 -1 10 10 52 239
44 243 -4 3 -10 2 -12 -2z"/>
<path d="M1260 3514 c0 -10 122 -144 131 -144 23 0 4 31 -54 88 -58 57 -77 71
-77 56z"/>
<path d="M1241 3437 c-18 -47 -52 -189 -46 -195 11 -12 23 16 45 105 22 92 23
144 1 90z"/>
<path d="M1298 3393 c-13 -23 -60 -214 -55 -220 12 -11 17 1 47 108 17 59 26
107 21 112 -5 5 -11 5 -13 0z"/>
<path d="M1530 3389 c0 -12 99 -119 111 -119 22 0 5 30 -44 78 -53 52 -67 61
-67 41z"/>
<path d="M1477 3325 c-30 -62 -31 -65 -17 -65 10 0 60 91 60 110 0 25 -18 6
-43 -45z"/>
<path d="M1316 3215 c-28 -112 -31 -135 -17 -135 8 0 61 194 61 225 0 8 -4 15
-9 15 -5 0 -20 -47 -35 -105z"/>
<path d="M1361 3178 c-7 -46 -15 -100 -18 -120 -4 -25 -2 -38 6 -38 6 0 11 1
12 3 0 1 8 55 18 120 13 88 15 117 5 117 -7 0 -16 -31 -23 -82z"/>
<path d="M1510 3222 c0 -13 124 -132 138 -132 24 0 11 19 -54 79 -63 58 -84
71 -84 53z"/>
<path d="M810 3211 c0 -22 328 -119 353 -104 16 10 -8 20 -71 32 -34 7 -109
28 -165 47 -111 37 -117 38 -117 25z"/>
<path d="M1435 3189 c-17 -49 -51 -220 -44 -225 11 -6 12 -4 43 121 15 61 22
103 16 109 -6 6 -11 4 -15 -5z"/>
<path d="M1487 3083 c-9 -15 -47 -150 -47 -167 0 -9 4 -16 8 -16 12 0 61 179
51 185 -4 3 -10 2 -12 -2z"/>
<path d="M1010 3043 c0 -27 65 -293 72 -293 4 0 8 8 8 19 0 17 -44 222 -56
264 -5 17 -24 24 -24 10z"/>
<path d="M1507 2973 c-2 -5 -7 -26 -11 -48 -4 -22 -9 -48 -12 -57 -3 -11 0
-18 9 -18 9 0 17 15 21 43 4 23 9 52 13 65 5 20 -10 32 -20 15z"/>
<path d="M1556 2879 c-9 -45 -7 -99 3 -99 8 0 21 69 21 111 0 31 -17 22 -24
-12z"/>
<path d="M1147 2893 c-8 -14 5 -213 14 -213 5 0 9 50 9 110 0 96 -7 129 -23
103z"/>
<path d="M1330 2805 c0 -12 98 -30 109 -20 10 11 -16 23 -61 28 -34 3 -48 1
-48 -8z"/>
<path d="M1293 2783 c3 -16 13 -80 22 -143 16 -106 27 -141 40 -129 4 5 -27
218 -40 277 -8 33 -29 28 -22 -5z"/>
<path d="M1780 2693 c1 -10 216 -153 230 -153 25 0 2 20 -99 88 -103 68 -131
82 -131 65z"/>
<path d="M1780 2600 c0 -14 252 -190 264 -183 11 8 -19 32 -151 123 -104 72
-113 77 -113 60z"/>
<path d="M1624 2448 c8 -125 15 -160 27 -152 17 10 -5 254 -23 254 -7 0 -8
-34 -4 -102z"/>
<path d="M1810 2488 c1 -12 239 -169 267 -176 7 -2 13 1 13 6 0 8 -262 182
-275 182 -3 0 -5 -6 -5 -12z"/>
<path d="M1902 2359 c6 -19 210 -131 220 -121 6 6 -35 34 -101 71 -115 64
-125 68 -119 50z"/>
<path d="M2231 2279 c-18 -29 -30 -59 -28 -68 2 -10 14 2 36 37 18 28 37 52
43 52 6 0 48 -20 94 -45 90 -49 94 -51 94 -32 0 11 -178 107 -199 107 -4 0
-22 -23 -40 -51z"/>
<path d="M2256 2206 c-14 -24 -26 -49 -26 -55 0 -23 17 -10 39 29 12 22 24 40
26 40 2 0 52 -18 110 -41 59 -23 109 -38 113 -35 10 11 -11 23 -108 59 -52 19
-102 38 -111 42 -12 4 -23 -6 -43 -39z"/>
<path d="M1380 2182 c0 -11 26 -58 89 -162 5 -8 12 -11 17 -7 5 5 -5 33 -21
63 -34 63 -84 126 -85 106z"/>
<path d="M1560 2144 c0 -4 15 -30 34 -58 19 -28 46 -77 62 -109 30 -61 61 -81
43 -27 -25 69 -139 230 -139 194z"/>
<path d="M1490 2136 c0 -2 13 -28 28 -57 16 -30 33 -69 40 -86 12 -37 37 -46
27 -10 -17 55 -69 157 -81 157 -8 0 -14 -2 -14 -4z"/>
<path d="M2382 2019 c-15 -25 -20 -45 -15 -50 6 -6 17 4 29 26 10 19 24 35 30
35 6 0 56 -16 109 -35 90 -33 123 -38 99 -16 -10 9 -205 81 -220 81 -5 0 -20
-19 -32 -41z"/>
<path d="M1950 2033 c0 -14 92 -228 102 -238 7 -7 9 1 4 24 -8 45 -86 221 -97
221 -5 0 -9 -3 -9 -7z"/>
<path d="M2419 1937 c-10 -23 -19 -48 -19 -55 0 -24 23 -7 34 25 6 18 13 34
16 37 3 3 63 -13 134 -34 145 -45 136 -43 136 -30 0 13 -15 18 -163 63 l-119
35 -19 -41z"/>
<path d="M2160 1918 c1 -32 49 -173 60 -176 9 -3 10 5 5 29 -15 70 -46 159
-55 159 -6 0 -10 -6 -10 -12z"/>
<path d="M2255 1868 c4 -13 14 -51 23 -85 9 -35 20 -63 25 -63 13 0 2 78 -19
129 -17 43 -42 59 -29 19z"/>
<path d="M2436 1830 c-14 -22 -26 -44 -26 -50 0 -21 18 -8 45 31 23 35 31 40
53 36 170 -33 281 -42 260 -22 -6 7 -250 45 -283 45 -15 0 -31 -13 -49 -40z"/>
<path d="M1690 1824 c-30 -7 -90 -16 -132 -20 -91 -8 -104 -24 -20 -24 81 0
222 29 222 46 0 16 2 16 -70 -2z"/>
<path d="M2330 1809 c0 -6 8 -23 19 -37 10 -15 24 -44 32 -64 16 -46 34 -50
25 -6 -12 59 -76 149 -76 107z"/>
<path d="M1775 1765 c-44 -13 -119 -26 -167 -30 -121 -10 -116 -30 5 -22 99 7
248 44 255 65 5 15 6 15 -93 -13z"/>
<path d="M2515 1770 c-3 -5 -1 -10 5 -10 6 0 8 -5 5 -10 -8 -12 35 -120 47
-120 14 0 9 29 -12 72 -11 23 -20 43 -20 45 0 2 65 3 145 3 139 0 187 10 108
24 -62 10 -271 7 -278 -4z"/>
<path d="M1887 1725 c-37 -13 -111 -33 -163 -44 -96 -20 -113 -26 -101 -37 15
-15 313 61 352 90 l20 15 -20 0 c-11 0 -51 -11 -88 -24z"/>
<path d="M1977 1679 c-42 -16 -119 -37 -169 -47 -51 -11 -94 -24 -96 -31 -10
-31 352 66 374 100 9 16 -29 8 -109 -22z"/>
<path d="M2590 1691 c0 -9 298 -27 307 -18 3 3 2 10 -2 16 -8 13 -305 15 -305
2z"/>
<path d="M2088 1644 c-42 -19 -112 -42 -155 -51 -87 -18 -115 -33 -62 -33 74
0 319 86 319 111 0 14 -21 9 -102 -27z"/>
<path d="M2255 1640 c-16 -11 -49 -22 -72 -26 -54 -8 -66 -24 -18 -24 45 0
135 38 135 56 0 18 -8 17 -45 -6z"/>
<path d="M2500 1546 c0 -37 173 -226 207 -226 24 0 13 16 -37 54 -44 34 -134
140 -147 174 -7 16 -23 15 -23 -2z"/>
<path d="M2570 1552 c0 -12 238 -252 251 -252 20 0 5 18 -115 140 -110 111
-136 132 -136 112z"/>
<path d="M3160 1138 c-95 -102 -201 -190 -273 -228 -26 -14 -47 -31 -47 -37 0
-21 12 -16 109 48 132 87 357 315 304 308 -5 -1 -46 -42 -93 -91z"/>
<path d="M3285 1169 c-65 -78 -190 -196 -271 -257 -35 -26 -64 -50 -64 -54 0
-20 33 -3 107 57 80 64 303 289 303 306 0 23 -30 2 -75 -52z"/>
<path d="M6637 5773 c-11 -20 -49 -195 -43 -200 11 -11 17 4 42 99 27 106 27
108 15 108 -6 0 -12 -3 -14 -7z"/>
<path d="M6695 5357 c-46 -97 -52 -117 -35 -117 6 0 10 2 10 4 0 2 20 47 45
99 25 56 40 99 34 102 -5 4 -30 -36 -54 -88z"/>
<path d="M6731 5260 c-50 -88 -59 -110 -43 -110 8 0 112 176 112 191 0 27 -23
-1 -69 -81z"/>
<path d="M6758 5160 c-68 -77 -77 -90 -56 -89 12 0 138 139 138 153 0 18 -25
-1 -82 -64z"/>
<path d="M6806 5004 c-64 -94 -75 -114 -58 -114 9 0 142 190 142 203 0 24 -26
-4 -84 -89z"/>
<path d="M7103 4938 c-16 -62 -97 -372 -179 -689 l-149 -577 -35 84 c-42 100
-60 108 -102 40 -28 -43 -35 -66 -20 -66 4 0 20 21 36 46 29 46 29 46 42 23 7
-13 13 -26 14 -29 0 -3 -22 -23 -50 -45 -30 -24 -46 -43 -40 -49 5 -5 25 5 51
27 23 20 45 34 50 32 4 -3 13 -21 19 -40 6 -19 16 -35 21 -35 5 0 9 -9 9 -20
0 -11 4 -20 8 -20 7 0 372 1390 372 1418 0 6 -4 12 -9 12 -4 0 -22 -51 -38
-112z"/>
<path d="M6807 4899 c-70 -65 -90 -89 -69 -89 16 0 165 147 155 153 -5 3 -44
-25 -86 -64z"/>
<path d="M6970 4926 c0 -33 42 -196 51 -196 13 0 11 18 -12 119 -11 50 -25 91
-30 91 -5 0 -9 -6 -9 -14z"/>
<path d="M6820 4794 c-76 -66 -91 -84 -70 -84 12 0 162 127 168 142 10 24 -31
0 -98 -58z"/>
<path d="M7140 4735 c-66 -99 -80 -125 -62 -125 8 1 152 211 152 223 0 25 -28
-6 -90 -98z"/>
<path d="M6928 4785 c-6 -6 55 -161 68 -174 27 -28 -18 120 -51 165 -6 8 -13
12 -17 9z"/>
<path d="M6791 4683 c-50 -56 -60 -73 -42 -73 10 0 111 111 111 123 0 19 -23
2 -69 -50z"/>
<path d="M7146 4618 c-47 -56 -86 -106 -86 -110 0 -27 25 -1 178 184 8 9 9 18
4 22 -6 3 -49 -40 -96 -96z"/>
<path d="M6890 4656 c0 -25 72 -172 77 -158 6 15 -57 172 -69 172 -4 0 -8 -7
-8 -14z"/>
<path d="M6797 4582 c-56 -57 -66 -72 -48 -72 10 0 113 105 119 121 9 23 -18
4 -71 -49z"/>
<path d="M6533 4558 c-31 -44 -16 -58 18 -17 31 38 35 49 17 49 -7 0 -23 -15
-35 -32z"/>
<path d="M7130 4494 c-90 -77 -114 -104 -92 -104 10 0 206 168 210 180 9 25
-25 3 -118 -76z"/>
<path d="M6771 4471 c-23 -27 -41 -52 -41 -55 0 -16 24 -3 48 26 44 51 53 66
43 72 -5 3 -28 -16 -50 -43z"/>
<path d="M6850 4488 c0 -21 63 -168 72 -168 15 0 8 29 -26 104 -34 73 -46 90
-46 64z"/>
<path d="M7112 4392 c-51 -48 -92 -90 -92 -94 1 -23 35 2 120 87 52 52 88 95
80 95 -8 -1 -57 -40 -108 -88z"/>
<path d="M6567 4351 c-20 -57 -20 -61 -7 -61 9 0 29 39 44 88 5 15 3 22 -6 22
-7 0 -21 -22 -31 -49z"/>
<path d="M6820 4386 c0 -4 12 -35 26 -69 15 -34 31 -73 36 -86 6 -16 12 -21
19 -14 11 11 -50 164 -69 171 -7 2 -12 1 -12 -2z"/>
<path d="M7096 4276 c-53 -57 -96 -107 -96 -110 0 -3 6 -6 13 -5 13 1 188 187
195 207 10 31 -24 3 -112 -92z"/>
<path d="M6595 4263 c-39 -73 -39 -73 -23 -73 13 0 68 98 68 120 0 24 -17 6
-45 -47z"/>
<path d="M6787 4314 c-8 -13 57 -167 72 -172 18 -6 11 20 -28 103 -20 44 -40
75 -44 69z"/>
<path d="M7081 4169 c-100 -83 -125 -109 -103 -109 10 0 226 178 230 190 8 24
-22 4 -127 -81z"/>
<path d="M6607 4164 c-36 -61 -42 -85 -19 -70 15 9 81 124 74 130 -11 12 -18
5 -55 -60z"/>
<path d="M6780 4190 c0 -20 63 -160 71 -160 15 0 10 18 -22 96 -27 65 -49 94
-49 64z"/>
<path d="M7047 4070 c-76 -50 -135 -95 -132 -100 4 -6 12 -7 18 -3 110 66 266
176 262 183 -4 6 -7 10 -8 10 -1 0 -64 -41 -140 -90z"/>
<path d="M6631 4067 c-42 -55 -48 -67 -32 -67 11 0 91 98 91 111 0 21 -20 5
-59 -44z"/>
<path d="M6750 4100 c0 -22 62 -170 72 -170 14 0 9 27 -19 92 -29 68 -53 104
-53 78z"/>
<path d="M7250 3938 c-112 -267 -272 -557 -418 -758 -107 -146 -174 -223 -330
-382 -75 -76 -133 -141 -129 -144 4 -4 23 8 43 27 34 33 37 34 102 31 52 -3
64 -1 55 8 -6 7 -30 11 -56 9 l-44 -4 97 105 c269 289 443 545 607 888 35 72
69 132 76 132 18 0 121 106 109 113 -5 4 -20 -5 -33 -19 -13 -14 -36 -35 -51
-45 l-28 -20 30 70 c31 73 36 91 22 91 -5 0 -28 -46 -52 -102z"/>
<path d="M6342 3979 c-58 -48 -74 -69 -53 -69 13 0 131 100 131 111 0 17 -15
9 -78 -42z"/>
<path d="M6642 3971 c-46 -64 -48 -71 -29 -71 18 1 90 111 79 122 -5 5 -26
-16 -50 -51z"/>
<path d="M7172 3935 c4 -89 10 -116 24 -102 4 3 3 49 -1 102 -9 127 -29 127
-23 0z"/>
<path d="M7005 3949 c-103 -65 -131 -89 -106 -89 11 0 198 115 219 135 8 7 10
15 5 18 -5 3 -58 -26 -118 -64z"/>
<path d="M6720 4001 c0 -12 60 -154 70 -164 22 -24 14 18 -17 91 -31 71 -53
102 -53 73z"/>
<path d="M6361 3892 c-30 -27 -50 -51 -45 -55 11 -6 114 78 114 94 0 17 -15 9
-69 -39z"/>
<path d="M6697 3933 c-7 -11 63 -173 74 -173 14 0 11 13 -22 91 -33 79 -44 96
-52 82z"/>
<path d="M7101 3918 c2 -58 26 -172 37 -176 15 -5 15 -1 2 74 -6 32 -13 74
-16 92 -6 36 -24 44 -23 10z"/>
<path d="M6631 3869 c-18 -22 -30 -43 -27 -46 7 -7 86 66 86 79 0 20 -28 4
-59 -33z"/>
<path d="M7038 3865 c-7 -7 32 -218 43 -229 18 -20 18 4 -2 110 -19 104 -29
131 -41 119z"/>
<path d="M6893 3788 c-50 -20 -69 -38 -39 -38 23 0 126 42 126 51 0 14 -30 9
-87 -13z"/>
<path d="M6970 3730 c0 -18 65 -170 72 -170 14 0 9 24 -23 103 -28 68 -49 97
-49 67z"/>
<path d="M6651 3638 c-51 -63 -59 -78 -42 -78 11 0 111 118 111 131 0 21 -21
5 -69 -53z"/>
<path d="M6853 3683 c-24 -8 -43 -19 -43 -24 0 -12 11 -11 60 6 24 8 44 14 45
13 2 -2 13 -48 25 -103 25 -110 25 -110 38 -101 15 9 -33 195 -54 211 -23 17
-18 17 -71 -2z"/>
<path d="M6380 3615 c-29 -30 -51 -58 -47 -61 8 -9 114 86 116 104 2 22 -14
12 -69 -43z"/>
<path d="M6661 3565 c-28 -44 -46 -82 -40 -84 12 -4 24 12 69 86 53 86 27 84
-29 -2z"/>
<path d="M6860 3578 c1 -25 38 -189 44 -195 16 -16 16 12 1 89 -9 45 -19 91
-21 101 -6 19 -24 23 -24 5z"/>
<path d="M6807 3542 c-8 -11 32 -223 44 -236 20 -22 20 9 -1 111 -24 116 -33
142 -43 125z"/>
<path d="M6662 3450 c-37 -40 -59 -71 -53 -74 9 -6 130 112 139 135 11 27 -31
-2 -86 -61z"/>
<path d="M6399 3424 c-32 -31 -56 -61 -53 -66 6 -9 134 99 134 113 0 20 -25 6
-81 -47z"/>
<path d="M6748 3455 c-11 -11 46 -215 60 -215 8 0 11 7 8 18 -3 9 -15 55 -26
102 -21 83 -31 106 -42 95z"/>
<path d="M7165 3384 c-44 -24 -108 -53 -142 -65 -74 -24 -96 -45 -37 -36 41 7
248 103 265 123 22 27 -10 19 -86 -22z"/>
<path d="M6412 3347 c-29 -29 -52 -58 -52 -66 0 -7 3 -11 8 -9 11 5 112 114
112 121 0 16 -19 3 -68 -46z"/>
<path d="M6696 3391 c-7 -11 42 -200 55 -215 21 -24 18 11 -10 109 -32 114
-35 122 -45 106z"/>
<path d="M6480 3376 c0 -4 14 -34 31 -68 21 -42 34 -57 41 -50 12 12 -39 113
-59 120 -7 2 -13 1 -13 -2z"/>
<path d="M6405 3250 c-38 -38 -66 -73 -62 -77 4 -4 42 28 85 70 42 42 70 77
62 77 -9 0 -47 -31 -85 -70z"/>
<path d="M6648 3315 c-6 -6 46 -223 56 -232 18 -19 15 19 -10 121 -26 109 -33
124 -46 111z"/>
<path d="M6615 3223 c4 -21 12 -74 18 -117 8 -50 17 -81 26 -84 10 -3 12 2 8
19 -3 13 -11 68 -18 122 -9 68 -16 97 -26 97 -10 0 -12 -10 -8 -37z"/>
<path d="M6412 3167 c-87 -87 -78 -103 13 -21 65 58 82 84 57 84 -4 0 -36 -28
-70 -63z"/>
<path d="M7075 3185 c-49 -19 -123 -39 -162 -46 -48 -8 -73 -16 -73 -25 0 -16
24 -13 155 21 109 28 188 58 193 74 5 16 -12 12 -113 -24z"/>
<path d="M6556 3192 c-8 -13 45 -232 56 -232 12 0 10 25 -13 124 -24 101 -33
124 -43 108z"/>
<path d="M6408 3074 c-72 -61 -91 -84 -70 -84 17 0 162 131 157 141 -8 12 1
18 -87 -57z"/>
<path d="M6134 3053 c-56 -71 -56 -71 -45 -77 9 -6 101 100 101 117 0 18 -21
3 -56 -40z"/>
<path d="M6507 3083 c-6 -9 37 -171 48 -181 17 -17 15 18 -5 90 -24 85 -34
106 -43 91z"/>
<path d="M6920 3079 c-14 -6 -56 -19 -93 -28 -37 -10 -67 -22 -65 -27 2 -5 12
-8 23 -6 47 6 175 45 192 58 17 12 16 13 -7 13 -14 0 -36 -5 -50 -10z"/>
<path d="M6975 3038 c-13 -36 -61 -274 -57 -281 10 -18 21 13 47 128 14 66 29
130 32 143 5 23 -14 32 -22 10z"/>
<path d="M6135 2970 c-38 -38 -66 -73 -62 -77 10 -9 141 121 136 136 -2 6 -35
-20 -74 -59z"/>
<path d="M6377 2952 c-59 -60 -67 -72 -43 -72 14 0 116 107 116 122 0 18 -18
5 -73 -50z"/>
<path d="M6777 2966 c-48 -12 -88 -26 -87 -32 2 -19 190 23 190 43 0 15 7 16
-103 -11z"/>
<path d="M6480 2972 c0 -30 25 -122 33 -122 9 0 1 105 -10 123 -6 10 -23 9
-23 -1z"/>
<path d="M6125 2874 c-41 -36 -73 -70 -69 -75 6 -10 19 -1 107 75 31 28 57 53
57 58 0 19 -24 4 -95 -58z"/>
<path d="M6362 2867 c-44 -45 -50 -57 -29 -57 12 0 87 82 87 94 0 15 -19 2
-58 -37z"/>
<path d="M6433 2849 c3 -33 8 -63 11 -66 14 -14 18 10 11 66 -9 81 -30 81 -22
0z"/>
<path d="M6840 2790 c0 -67 4 -110 10 -110 6 0 10 43 10 110 0 67 -4 110 -10
110 -6 0 -10 -43 -10 -110z"/>
<path d="M6635 2880 c-10 -16 17 -21 80 -18 36 2 60 8 63 16 5 15 -133 17
-143 2z"/>
<path d="M6116 2796 c-49 -45 -85 -85 -80 -89 11 -6 177 139 182 160 7 26 -14
11 -102 -71z"/>
<path d="M6617 2811 c-46 -6 -68 -27 -27 -25 80 3 90 5 90 19 0 8 -3 14 -7 13
-5 0 -29 -4 -56 -7z"/>
<path d="M7000 2698 c-58 -16 -109 -34 -114 -39 -17 -16 2 -21 36 -11 18 6 72
23 121 37 48 14 87 30 87 36 0 13 -13 11 -130 -23z"/>
<path d="M6100 2630 c-100 -67 -126 -90 -100 -90 12 0 230 148 230 156 0 15
-38 -4 -130 -66z"/>
<path d="M6092 2524 c-123 -86 -138 -99 -125 -107 7 -4 231 147 256 172 5 5 4
11 -1 15 -5 3 -64 -33 -130 -80z"/>
<path d="M6833 2516 c-79 -12 -146 -25 -149 -29 -18 -18 15 -17 148 3 185 27
198 30 198 41 0 12 -33 10 -197 -15z"/>
<path d="M6053 2414 c-117 -76 -151 -104 -125 -104 14 0 266 166 270 178 7 22
-12 12 -145 -74z"/>
<path d="M5988 2310 c-60 -34 -108 -66 -108 -71 0 -15 17 -8 132 57 54 30 96
60 93 65 -8 12 5 17 -117 -51z"/>
<path d="M6530 2360 c0 -6 60 -10 160 -10 100 0 160 4 160 10 0 6 -60 10 -160
10 -100 0 -160 -4 -160 -10z"/>
<path d="M6394 2268 c-8 -13 12 -16 174 -22 101 -4 152 -2 152 4 0 6 -6 12
-12 15 -27 8 -309 12 -314 3z"/>
<path d="M6324 2219 c-7 -12 7 -17 99 -33 43 -7 80 -9 83 -5 7 12 -4 15 -99
32 -44 8 -80 11 -83 6z"/>
<path d="M5887 2127 c-55 -18 -97 -36 -95 -42 1 -5 5 -9 8 -9 135 41 210 67
210 74 0 15 -17 12 -123 -23z"/>
<path d="M6399 2108 c-28 -39 -99 -170 -99 -183 0 -3 4 -5 10 -5 5 0 19 19 31
43 12 23 41 73 65 110 47 73 49 77 34 77 -5 0 -24 -19 -41 -42z"/>
<path d="M5480 2024 c-52 -20 -99 -39 -104 -44 -24 -23 9 -18 107 18 l105 39
22 -39 c14 -26 26 -36 32 -30 12 12 -36 92 -55 91 -6 0 -55 -16 -107 -35z"/>
<path d="M6014 1983 c-49 -110 -74 -182 -64 -188 6 -4 13 2 17 12 3 10 24 59
45 108 49 112 53 125 39 125 -6 0 -23 -26 -37 -57z"/>
<path d="M5555 1974 c-158 -46 -276 -87 -268 -95 5 -5 60 7 138 31 71 22 130
38 131 37 1 -1 9 -19 18 -40 22 -54 39 -38 19 18 -16 47 -21 54 -38 49z"/>
<path d="M5823 1908 c-30 -69 -51 -189 -28 -167 4 5 55 174 55 184 0 14 -18 2
-27 -17z"/>
<path d="M5732 1873 c-9 -17 -21 -57 -36 -125 -5 -21 -3 -28 9 -28 8 0 15 9
15 19 0 11 9 47 19 80 18 58 13 92 -7 54z"/>
<path d="M6330 1872 c0 -18 33 -25 148 -30 143 -6 152 12 10 22 -57 3 -115 9
-130 12 -16 4 -28 2 -28 -4z"/>
<path d="M6246 1831 c-9 -14 0 -17 86 -35 87 -18 198 -21 198 -6 0 6 -20 10
-44 10 -40 0 -174 22 -217 35 -9 3 -20 1 -23 -4z"/>
<path d="M6136 1782 c-14 -23 191 -72 296 -72 88 0 69 16 -28 24 -75 6 -196
31 -245 51 -10 3 -20 2 -23 -3z"/>
<path d="M5208 1773 c-16 -2 -28 -9 -28 -14 0 -5 62 -9 145 -9 l145 0 -19 -37
c-29 -58 -34 -83 -19 -83 13 0 50 86 54 128 3 22 2 22 -124 21 -70 -1 -139 -4
-154 -6z"/>
<path d="M6020 1740 c0 -11 164 -63 268 -85 88 -18 95 -18 90 -3 -2 6 -44 19
-93 29 -49 10 -123 29 -165 43 -84 28 -100 31 -100 16z"/>
<path d="M5925 1701 c17 -28 355 -125 372 -107 13 12 -13 23 -82 35 -38 7
-116 28 -173 47 -110 36 -127 40 -117 25z"/>
<path d="M5115 1689 c-4 -6 -5 -13 -2 -16 9 -9 297 9 297 18 0 13 -287 11
-295 -2z"/>
<path d="M5820 1670 c0 -16 123 -69 209 -91 82 -21 131 -24 131 -9 0 6 -8 10
-18 10 -38 0 -179 41 -241 70 -69 33 -81 35 -81 20z"/>
<path d="M5040 1645 c0 -13 22 -15 170 -13 228 4 228 22 0 26 -148 2 -170 0
-170 -13z"/>
<path d="M5706 1651 c-12 -19 83 -61 135 -61 43 0 35 17 -12 24 -22 4 -58 15
-79 26 -22 11 -41 16 -44 11z"/>
<path d="M5630 1631 c0 -8 37 -39 79 -68 6 -4 -79 -113 -133 -171 -61 -65 -53
-82 10 -21 51 48 154 182 154 198 0 6 -18 20 -39 31 -22 11 -42 24 -46 30 -8
13 -25 13 -25 1z"/>
<path d="M6313 1545 c-46 -58 -50 -65 -30 -65 14 1 87 92 87 109 0 21 -15 9
-57 -44z"/>
<path d="M5296 1434 c-65 -69 -116 -128 -112 -131 7 -7 231 210 247 240 7 12
6 17 -3 17 -7 0 -66 -57 -132 -126z"/>
<path d="M5460 1510 c-18 -28 -64 -79 -103 -115 -75 -70 -84 -96 -16 -49 56
38 169 174 169 203 0 24 -19 9 -50 -39z"/>
<path d="M6285 1403 c-91 -5 -138 -14 -127 -25 11 -11 192 0 192 13 0 12 -11
14 -65 12z"/>
<path d="M5992 1348 c6 -21 88 -56 147 -63 43 -6 53 -4 49 6 -3 8 -26 17 -56
20 -28 4 -70 16 -92 28 -47 24 -53 25 -48 9z"/>
<path d="M5850 1331 c0 -27 225 -110 244 -91 6 6 4 11 -5 13 -99 29 -133 41
-173 62 -50 27 -66 30 -66 16z"/>
<path d="M5750 1313 c0 -25 197 -123 245 -123 31 0 14 20 -27 31 -47 12 -128
51 -170 80 -27 20 -48 25 -48 12z"/>
<path d="M5579 1239 c57 -54 104 -81 190 -110 62 -22 74 -23 69 -8 -2 5 -34
21 -71 34 -74 25 -150 70 -187 110 -13 14 -31 25 -39 25 -10 -1 5 -20 38 -51z"/>
<path d="M4976 1232 c-5 -8 336 -322 349 -322 22 0 -19 43 -168 178 -92 84
-169 152 -172 152 -2 0 -6 -4 -9 -8z"/>
<path d="M4740 1220 c0 -16 209 -223 270 -267 85 -61 147 -95 154 -84 4 6 -6
18 -21 27 -89 54 -115 73 -183 130 -41 35 -103 95 -138 133 -60 67 -82 84 -82
61z"/>
<path d="M6352 4161 c5 -14 165 -141 178 -141 23 0 6 20 -75 83 -79 62 -112
80 -103 58z"/>
<path d="M1757 3451 c-34 -33 -59 -63 -55 -66 7 -8 138 103 138 117 0 20 -26
4 -83 -51z"/>
<path d="M1961 3145 c-13 -20 -21 -39 -17 -43 9 -8 56 47 56 66 0 21 -14 13
-39 -23z"/>
<path d="M2037 2817 c-29 -63 -33 -81 -16 -75 15 5 51 97 46 117 -2 9 -15 -8
-30 -42z"/>
<path d="M1837 3062 c67 -90 83 -106 83 -82 0 19 -82 120 -97 120 -11 0 -7
-11 14 -38z"/>
<path d="M1790 2931 c0 -8 122 -120 148 -135 7 -4 12 -2 12 7 0 13 -137 137
-152 137 -4 0 -8 -4 -8 -9z"/>
<path d="M1786 2872 c-3 -5 36 -46 87 -91 53 -48 93 -77 96 -70 2 7 -30 43
-70 81 -82 76 -105 92 -113 80z"/>
</g>
</svg>

        </>
    );
}

export default Chain;
