import React from 'react'

export default function Seal() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 1024.000000 1024.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M4740 10109 c-952 -76 -1849 -412 -2606 -977 -372 -277 -732 -634
-1006 -997 -532 -706 -860 -1515 -972 -2400 -53 -417 -46 -931 19 -1365 215
-1451 1090 -2757 2360 -3525 588 -356 1267 -593 1945 -679 451 -58 954 -52
1399 15 803 121 1587 449 2236 935 377 283 732 635 1005 999 200 266 345 504
496 809 464 939 617 2007 438 3056 -213 1248 -912 2383 -1939 3150 -765 571
-1667 908 -2630 979 -161 12 -590 12 -745 0z m860 -689 l0 -300 -95 0 -95 0 0
130 0 130 -70 0 -70 0 0 65 0 65 70 0 70 0 0 105 0 105 95 0 95 0 0 -300z
m-330 210 l0 -60 -101 0 -102 0 7 -46 c14 -105 82 -193 165 -218 l46 -13 3
-62 3 -62 -38 7 c-105 19 -200 68 -256 135 l-33 38 -49 -51 c-29 -30 -74 -63
-110 -79 -60 -28 -152 -52 -167 -43 -5 3 -8 30 -8 59 0 51 2 55 28 61 105 25
177 111 197 232 l7 42 -101 0 -101 0 0 60 0 60 305 0 305 0 0 -60z m-1885
-383 l67 -127 61 34 62 35 33 -59 32 -59 -60 -31 c-33 -17 -60 -34 -60 -38 0
-4 63 -127 140 -272 78 -145 139 -269 136 -275 -2 -7 -39 -30 -83 -53 l-78
-40 -240 451 c-132 248 -240 457 -240 465 0 11 155 107 161 99 1 -1 32 -59 69
-130z m3759 -23 c18 -11 17 -16 -28 -94 -26 -45 -46 -83 -44 -84 15 -12 403
-226 409 -226 4 0 26 34 49 75 23 41 44 77 48 79 4 3 44 -17 89 -42 l81 -47
-149 -265 c-82 -146 -150 -269 -152 -273 -1 -5 -69 28 -150 74 -81 45 -149 80
-150 78 -28 -37 -106 -194 -100 -199 4 -4 82 -49 173 -100 91 -51 168 -95 172
-99 4 -3 -7 -30 -24 -59 -28 -50 -31 -52 -52 -39 -11 7 -213 121 -448 252
l-428 239 26 45 c15 25 30 49 33 53 4 4 87 -37 185 -92 98 -55 180 -100 182
-100 1 0 21 35 45 78 23 42 48 87 56 98 13 21 9 24 -98 84 -61 35 -120 72
-130 83 -37 40 -25 71 117 324 l136 243 66 -38 c37 -20 76 -42 86 -48z m-4089
-115 c20 -39 52 -119 72 -177 32 -95 37 -121 41 -232 5 -145 -8 -211 -64 -329
-36 -78 -127 -200 -170 -227 -13 -9 -26 -15 -28 -13 -2 2 -15 25 -30 51 l-28
48 35 42 c111 136 141 311 86 508 -17 61 -64 188 -74 198 -2 2 -73 -33 -158
-77 -85 -45 -158 -81 -163 -81 -5 0 -21 23 -36 50 -21 37 -24 51 -15 57 40 26
474 252 484 253 7 0 29 -32 48 -71z m2315 2 c90 -28 168 -78 205 -129 61 -87
32 -192 -71 -260 -131 -88 -318 -112 -498 -66 -105 27 -198 90 -232 157 -20
38 -20 106 0 144 38 74 149 142 273 168 89 19 238 12 323 -14z m145 -796 c731
-96 1362 -403 1876 -915 503 -501 812 -1114 919 -1825 28 -183 37 -520 21
-716 -54 -632 -280 -1206 -677 -1719 -103 -133 -391 -426 -512 -520 -488 -381
-973 -591 -1567 -680 -183 -28 -520 -37 -716 -21 -1081 92 -2038 714 -2563
1668 -193 351 -321 754 -372 1173 -22 183 -22 538 0 725 108 901 567 1690
1298 2231 467 346 1040 560 1643 614 141 12 507 4 650 -15z m-3776 -425 l135
-89 21 29 22 30 34 -26 c19 -14 40 -29 48 -33 11 -7 -17 -55 -120 -206 l-133
-197 25 -19 c32 -24 68 -43 72 -38 2 2 43 63 91 134 49 72 95 136 104 143 9 6
32 12 51 12 28 0 64 -19 173 -92 75 -51 137 -97 138 -101 0 -4 -23 -42 -51
-83 l-50 -75 -27 17 c-15 9 -68 45 -119 80 l-92 63 -207 -305 c-231 -341 -198
-310 -279 -258 -14 9 -25 20 -25 25 0 4 41 67 90 140 l90 133 -42 28 c-24 15
-46 28 -50 28 -4 0 -57 -74 -119 -165 -61 -91 -116 -165 -121 -165 -13 0 -88
53 -88 62 0 4 101 156 225 338 124 182 225 334 225 338 0 4 -49 39 -109 79
l-108 71 -139 -206 c-76 -113 -169 -250 -207 -304 l-68 -98 -49 33 c-27 17
-48 37 -46 42 6 17 457 679 479 703 40 43 78 32 226 -68z m7058 -36 c149 -71
265 -285 233 -430 -43 -190 -243 -215 -405 -50 -103 106 -153 259 -121 369 38
132 155 176 293 111z m-251 -434 c115 -176 212 -320 215 -320 4 0 92 56 197
125 106 69 193 125 195 125 4 0 34 -44 91 -131 l17 -26 -168 -110 c-92 -61
-255 -167 -361 -237 l-193 -126 -49 76 c-28 42 -50 80 -50 84 0 5 16 18 35 30
19 12 35 26 35 30 0 5 -18 37 -40 71 -22 34 -40 65 -40 68 0 8 84 61 96 61 5
0 28 -30 52 -65 42 -64 55 -75 67 -54 7 11 -124 219 -138 219 -4 0 -35 -18
-67 -40 -32 -22 -61 -40 -63 -40 -7 0 -110 160 -106 164 2 2 31 21 64 41 33
21 62 39 64 41 2 2 -33 60 -78 129 -44 69 -81 129 -81 134 0 6 85 69 96 71 0
0 95 -144 210 -320z m-319 11 l52 -79 -79 -52 -79 -52 53 -81 c29 -45 110
-169 180 -276 70 -107 130 -200 132 -206 3 -7 -17 -26 -43 -43 -37 -24 -50
-29 -59 -19 -7 7 -108 158 -225 337 -220 337 -233 363 -198 406 13 16 198 143
209 143 3 1 28 -35 57 -78z m1237 -1943 c3 -18 8 -90 11 -160 6 -127 6 -127
-17 -132 -37 -8 -548 -30 -554 -24 -9 10 -23 303 -15 311 5 5 26 7 47 5 l39
-3 3 -92 3 -93 70 0 70 0 -3 89 -3 89 40 5 c22 2 43 2 48 0 4 -2 7 -37 7 -76
0 -40 4 -78 9 -85 5 -9 29 -12 82 -10 l74 3 -3 103 -2 102 45 0 c42 0 44 -1
49 -32z m-8311 -14 l207 -6 0 -78 c0 -50 -4 -81 -12 -87 -7 -6 -97 -39 -201
-74 l-187 -64 142 -6 c79 -4 167 -7 196 -8 l52 -1 0 -54 c0 -30 -3 -57 -7 -59
-7 -4 -575 12 -580 17 -1 0 1 37 4 82 l6 80 206 69 206 70 -205 8 c-113 5
-206 9 -207 11 -1 1 1 27 4 59 l6 57 81 -5 c45 -3 174 -8 289 -11z m7933 -440
c-3 -9 -6 -36 -6 -60 l0 -44 -38 0 c-51 0 -75 -18 -70 -55 5 -52 55 -67 228
-73 140 -4 148 -4 169 17 41 41 19 86 -50 97 l-39 7 0 60 0 60 44 -5 c99 -11
156 -70 156 -158 0 -99 -48 -180 -118 -200 -87 -24 -331 -4 -396 33 -62 35
-80 67 -84 150 -4 85 14 129 67 164 43 29 147 34 137 7z m-8002 -198 c147 -24
272 -48 277 -53 4 -4 9 -41 11 -82 l3 -75 -275 -108 c-150 -59 -276 -106 -278
-104 -2 2 -4 32 -5 66 l-2 62 58 21 58 21 -6 56 c-13 112 -9 107 -76 115 -32
4 -61 10 -64 15 -2 4 -7 32 -11 62 -6 54 -6 56 17 52 13 -3 145 -24 293 -48z
m8066 -306 c146 -23 268 -45 273 -49 8 -8 -1 -92 -12 -109 -4 -7 -100 5 -289
35 l-283 46 7 56 c8 65 10 71 27 66 7 -2 131 -22 277 -45z m-7710 -225 c20
-89 40 -173 43 -187 7 -25 4 -28 -30 -37 -58 -17 -61 -15 -76 52 -9 42 -18 61
-28 60 -18 -3 -418 -93 -441 -99 -13 -4 -19 7 -29 51 -7 34 -9 59 -3 64 5 5
104 30 221 57 117 26 217 51 224 55 9 5 9 19 -1 58 -15 62 -10 78 27 84 56 9
55 10 93 -158z m7651 -92 c184 -105 257 -152 255 -163 -3 -8 -11 -40 -20 -70
l-15 -55 -298 6 c-164 4 -299 7 -299 8 -1 0 5 28 14 61 l16 60 51 0 c27 0 119
-5 204 -10 85 -5 156 -8 158 -6 2 1 -78 47 -177 100 -103 56 -180 103 -180
112 0 19 23 104 28 104 3 0 121 -66 263 -147z m-7549 -273 c10 -29 16 -55 14
-57 -2 -2 -126 -44 -276 -92 l-272 -89 -13 32 c-8 18 -17 44 -20 58 -7 26 -6
26 262 116 147 50 272 89 277 88 5 -1 18 -26 28 -56z m7304 -130 c44 -22 69
-50 80 -94 l6 -26 25 20 c35 27 52 26 170 -12 112 -36 110 -32 74 -108 -20
-42 -34 -42 -133 -4 -78 31 -111 27 -133 -16 -8 -16 -15 -33 -15 -38 0 -5 49
-28 109 -52 l110 -43 -20 -50 c-11 -28 -22 -54 -25 -58 -8 -13 -539 209 -536
223 2 7 24 61 49 120 54 129 82 158 151 158 26 0 64 -9 88 -20z m-7269 -105
c50 -21 71 -41 99 -97 27 -53 39 -116 30 -152 -11 -45 -67 -96 -150 -138 -231
-116 -335 -118 -399 -6 -58 99 -59 172 -4 235 48 55 295 168 375 172 7 1 29
-6 49 -14z m6974 -282 c16 -10 29 -21 29 -26 0 -4 -18 -41 -41 -82 -22 -41
-38 -79 -34 -85 6 -9 98 -60 109 -60 3 0 23 34 46 75 l41 75 40 -20 c21 -11
39 -23 39 -26 0 -2 -18 -37 -40 -76 -46 -81 -48 -77 42 -126 l57 -31 19 32
c11 18 32 56 48 84 16 29 33 53 38 53 5 0 24 -9 42 -21 l33 -20 -35 -62 c-19
-34 -53 -97 -77 -140 -30 -55 -46 -76 -57 -71 -43 16 -490 269 -490 277 0 17
142 267 152 267 5 0 23 -8 39 -17z m-6702 -178 c23 -35 41 -69 41 -75 0 -6
-54 -82 -120 -170 -66 -88 -118 -160 -116 -160 3 0 71 42 153 93 81 51 153 96
160 100 7 4 23 -12 42 -42 l30 -50 -240 -150 c-132 -83 -244 -151 -249 -151
-4 0 -27 29 -49 65 l-41 65 129 168 c71 92 127 169 125 171 -1 2 -75 -42 -164
-98 -89 -55 -165 -101 -169 -101 -9 0 -61 75 -61 87 0 6 474 310 487 313 0 0
20 -29 42 -65z m6527 -234 c23 -10 44 -23 47 -28 4 -6 -5 -25 -19 -44 -14 -19
-29 -40 -33 -48 -7 -11 -13 -10 -40 3 -35 19 -63 10 -82 -25 -9 -17 -7 -27 8
-48 29 -38 64 -37 161 5 105 45 152 48 213 12 47 -28 89 -89 89 -130 0 -41
-59 -145 -102 -183 -34 -29 -50 -35 -86 -35 -42 0 -122 34 -122 52 0 10 62 98
69 98 3 0 15 -7 25 -15 62 -47 141 30 89 87 -24 26 -74 22 -156 -13 -98 -42
-160 -47 -212 -17 -77 45 -104 123 -71 202 28 68 116 145 166 145 8 0 34 -8
56 -18z m-6136 -317 c25 -30 46 -59 48 -64 3 -7 -273 -418 -333 -498 -1 -1
-21 20 -44 48 l-42 50 37 51 c42 58 41 62 -26 137 l-39 43 -50 -25 c-28 -15
-56 -26 -63 -26 -19 0 -82 80 -72 90 9 8 524 249 534 249 3 0 25 -25 50 -55z
m275 -294 l38 -39 -105 -113 c-58 -62 -131 -141 -163 -176 l-58 -63 67 -62 68
-62 -36 -34 -35 -34 -48 43 c-26 24 -76 70 -111 103 l-62 59 57 62 c260 282
329 355 338 356 6 0 28 -18 50 -40z m5255 -85 c0 -6 -31 -37 -70 -70 -77 -65
-76 -58 -11 -127 l34 -37 63 54 c34 30 66 55 71 54 4 0 19 -14 33 -31 l24 -31
-62 -56 c-34 -31 -62 -60 -62 -64 0 -10 91 -117 100 -117 3 0 39 28 79 62 l73
62 31 -34 30 -34 -36 -30 c-19 -17 -75 -65 -123 -106 l-87 -74 -41 45 c-22 25
-108 123 -190 218 -146 169 -149 173 -130 191 10 10 64 57 119 104 l99 86 28
-27 c15 -15 28 -32 28 -38z m-342 -282 c14 -10 36 -33 49 -50 l24 -31 -52 -32
-51 -33 -25 31 c-44 57 -112 29 -99 -40 7 -39 106 -196 158 -250 53 -57 119
-50 118 11 -1 15 -7 37 -15 51 -8 14 -14 28 -15 32 0 7 84 68 95 68 3 0 18
-25 32 -56 33 -73 28 -122 -19 -171 -68 -71 -165 -101 -227 -69 -64 34 -216
241 -241 330 -17 61 -2 113 47 162 72 72 162 91 221 47z m-4258 -134 l50 -31
-22 -37 c-13 -20 -81 -132 -152 -248 l-128 -211 -53 32 c-29 17 -52 33 -50 36
19 36 298 490 301 490 2 0 26 -14 54 -31z m3953 -314 c70 -140 127 -257 127
-260 0 -3 -32 -21 -71 -40 l-71 -36 -124 118 c-217 204 -210 205 -105 -5 l91
-182 -51 -26 c-28 -14 -53 -24 -55 -22 -5 6 -254 508 -254 513 0 3 32 21 71
41 70 35 72 35 93 17 11 -11 78 -72 148 -136 70 -64 130 -117 133 -117 3 0
-33 76 -80 170 l-85 170 48 24 c26 14 49 25 52 26 3 0 63 -115 133 -255z
m-3723 184 l70 -32 -1 -41 c0 -22 -6 -115 -13 -206 -8 -91 -13 -166 -11 -168
1 -1 20 37 43 85 22 49 59 127 82 175 l42 86 49 -21 c26 -12 49 -26 49 -32 0
-13 -228 -497 -241 -509 -5 -6 -35 3 -79 24 l-71 34 6 56 c11 102 33 375 31
377 -1 2 -40 -79 -86 -178 -47 -99 -87 -182 -90 -184 -9 -5 -100 37 -100 46 0
4 37 86 81 181 45 95 99 210 120 256 21 45 41 82 43 82 3 0 37 -14 76 -31z
m3123 -196 c8 -21 13 -41 11 -45 -3 -4 -43 -19 -89 -35 -47 -15 -85 -32 -85
-39 0 -10 12 -49 35 -111 3 -9 28 -4 89 17 l85 28 14 -43 15 -43 -73 -26 c-40
-14 -79 -26 -85 -26 -7 0 -10 -6 -8 -12 3 -7 15 -43 27 -80 13 -38 26 -68 30
-68 4 0 44 14 89 30 46 17 86 30 91 30 9 0 35 -79 28 -86 -6 -6 -300 -104
-302 -101 -12 15 -185 540 -180 545 7 7 262 98 281 101 7 0 19 -16 27 -36z
m-2473 -63 c58 -18 108 -36 113 -39 7 -7 -9 -69 -23 -84 -4 -5 -33 -1 -64 9
-31 10 -58 15 -62 12 -3 -4 -36 -104 -74 -223 -38 -119 -71 -219 -74 -222 -4
-4 -104 24 -114 32 -2 1 29 102 68 223 38 121 70 225 70 230 0 5 -27 17 -61
26 -60 17 -60 17 -53 49 3 18 10 40 15 49 10 18 -14 24 259 -62z m400 -95
l115 -21 -3 -40 c-2 -21 -6 -42 -10 -46 -4 -3 -48 2 -99 11 l-92 18 -11 -65
c-6 -35 -9 -66 -7 -68 2 -2 41 -12 86 -21 l82 -16 -7 -41 c-3 -23 -8 -43 -10
-45 -1 -2 -40 4 -86 12 -46 8 -85 14 -87 11 -9 -9 -31 -148 -24 -154 5 -4 42
-13 83 -20 105 -18 103 -17 97 -62 -3 -22 -7 -42 -10 -44 -5 -6 -306 52 -314
59 -4 4 91 518 103 555 3 9 14 11 41 6 21 -4 90 -17 153 -29z m1600 13 c44
-30 70 -67 73 -103 2 -27 -1 -29 -54 -40 l-56 -12 -12 34 c-31 85 -131 60
-131 -33 0 -44 40 -227 60 -271 24 -54 48 -67 100 -53 44 12 45 15 29 89 -10
49 -11 49 -44 44 -32 -5 -33 -4 -40 37 -4 23 -5 43 -3 45 2 2 43 12 91 23 l87
19 5 -21 c15 -65 55 -257 55 -265 0 -18 -164 -63 -235 -65 -119 -2 -158 43
-201 233 -38 168 -30 255 28 308 58 54 190 70 248 31z m-1252 -64 c2 -1 -5
-108 -16 -236 -11 -128 -18 -235 -17 -236 2 -2 32 -6 67 -8 108 -9 108 -9 108
-58 0 -23 -2 -45 -5 -47 -5 -5 -295 18 -301 24 -4 4 31 455 42 535 l5 43 57
-7 c31 -3 59 -8 60 -10z m826 -256 c15 -150 29 -280 29 -288 2 -11 -13 -17
-58 -21 -33 -4 -61 -6 -61 -6 -1 1 -14 115 -28 252 -15 138 -29 267 -32 287
-6 40 0 44 81 47 l40 1 29 -272z m-424 7 l5 -240 90 0 90 0 3 -46 3 -46 -38
-7 c-21 -3 -91 -6 -155 -6 l-118 0 0 238 c0 130 -3 260 -6 289 -7 58 -3 62 76
60 l45 -2 5 -240z"/>
<path d="M7003 8928 c-18 -23 -85 -149 -81 -153 9 -9 400 -225 408 -225 12 0
92 149 85 159 -3 5 -74 48 -158 94 -83 47 -173 97 -198 111 -36 21 -48 23 -56
14z"/>
<path d="M5080 9001 c-80 -25 -120 -64 -120 -119 0 -75 106 -127 244 -120 123
7 200 54 200 123 0 73 -93 125 -219 124 -44 0 -91 -4 -105 -8z"/>
<path d="M4785 8204 c-248 -28 -510 -91 -755 -180 -128 -47 -432 -198 -558
-278 -407 -257 -761 -618 -1009 -1031 -444 -737 -558 -1621 -318 -2456 107
-371 311 -763 560 -1074 106 -134 345 -371 478 -477 479 -380 1019 -601 1632
-668 146 -16 487 -13 649 5 1248 139 2294 1019 2640 2223 134 464 155 976 61
1460 -90 462 -302 921 -606 1312 -87 113 -298 331 -425 441 -436 375 -974 619
-1569 709 -187 29 -589 36 -780 14z m571 -1111 c120 -579 222 -1053 226 -1053
3 0 271 164 594 364 324 201 590 363 592 362 2 -3 -723 -1175 -731 -1182 -1
-1 -87 16 -191 38 l-189 40 -101 -100 -101 -101 -30 26 c-53 45 -169 94 -235
100 l-60 6 0 1280 c0 704 2 1278 4 1276 1 -2 102 -478 222 -1056z m-1293 -684
c320 -198 586 -365 590 -369 5 -4 -8 -92 -29 -194 l-38 -186 101 -103 102
-102 -31 -35 c-44 -51 -87 -148 -100 -224 l-11 -66 -1281 1 c-1270 1 -1280 1
-1196 19 97 21 1113 233 1660 346 201 42 368 79 372 82 3 4 -64 120 -149 257
-392 628 -581 935 -577 935 2 0 266 -162 587 -361z m1224 -918 c161 -76 257
-244 238 -414 l-8 -62 -14 54 c-17 67 -52 115 -101 140 -55 28 -95 34 -144 21
-58 -16 -101 -50 -143 -115 -44 -69 -97 -99 -175 -99 -61 1 -102 18 -142 61
-61 63 -73 146 -34 228 90 191 330 276 523 186z m1831 -595 c-587 -122 -1070
-225 -1074 -228 -4 -5 328 -547 689 -1123 40 -63 45 -76 26 -64 -13 9 -283
176 -600 372 l-576 357 39 191 40 191 -100 102 -100 101 39 50 c44 56 77 136
85 204 3 25 7 52 10 59 3 9 270 12 1296 11 l1293 0 -1067 -223z m-2192 -198
c34 -16 92 -34 128 -40 l66 -11 -1 -1291 -1 -1291 -223 1068 c-123 588 -225
1070 -228 1073 -3 3 -651 -395 -1136 -698 -35 -22 -60 -34 -55 -26 22 38 727
1174 731 1178 2 2 89 -13 194 -35 l190 -38 102 101 101 101 35 -31 c19 -17 63
-44 97 -60z"/>
<path d="M8683 7700 c-122 -74 5 -309 143 -263 101 34 84 205 -26 261 -54 27
-76 28 -117 2z"/>
<path d="M1010 4708 c0 -14 3 -39 6 -56 l6 -31 102 36 c55 20 105 39 109 43 4
4 -23 10 -60 14 -37 4 -89 9 -115 13 -46 5 -48 5 -48 -19z"/>
<path d="M8703 3665 c-20 -42 -18 -45 65 -79 l63 -25 9 25 c25 66 -9 114 -82
114 -34 0 -40 -4 -55 -35z"/>
<path d="M1425 3557 c-112 -45 -187 -90 -205 -121 -13 -23 -13 -29 1 -53 23
-42 88 -38 201 11 168 73 211 115 174 171 -23 35 -71 33 -171 -8z"/>
<path d="M2035 2559 c-49 -27 -94 -50 -100 -52 -9 -3 53 -85 65 -86 3 0 21 23
42 52 20 29 51 71 68 95 17 23 28 42 23 41 -4 0 -48 -23 -98 -50z"/>
</g>
</svg>
    </>
  )
}
