import React from 'react'

export default function Arabia() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 808.000000 1001.000000"
 preserveAspectRatio="xMidYMid meet" className='md:mt-3'>

<g transform="translate(0.000000,1001.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M3820 9999 c-834 -60 -1593 -422 -2240 -1069 -850 -848 -1360 -2001
-1491 -3370 -29 -304 -26 -839 5 -1160 117 -1180 540 -2228 1230 -3050 111
-133 410 -429 536 -531 714 -582 1535 -863 2362 -810 494 32 897 145 1346 376
330 171 634 396 928 690 480 479 841 1037 1107 1710 248 628 378 1263 407
1991 57 1459 -396 2861 -1254 3884 -111 133 -410 429 -536 531 -565 461 -1178
726 -1840 798 -144 15 -420 21 -560 10z m635 -174 c449 -62 874 -220 1285
-478 409 -256 818 -652 1137 -1099 476 -668 794 -1485 922 -2368 47 -321 55
-459 56 -870 0 -412 -10 -570 -55 -875 -127 -859 -413 -1618 -863 -2289 -277
-414 -681 -829 -1068 -1098 -461 -321 -957 -511 -1490 -574 -158 -18 -475 -20
-634 -4 -374 37 -703 132 -1055 301 -392 190 -680 398 -1015 734 -738 740
-1225 1763 -1394 2935 -45 308 -55 470 -56 860 0 536 37 879 146 1356 146 641
408 1259 755 1781 265 399 613 773 972 1042 705 530 1542 760 2357 646z"/>
<path d="M2948 9364 c-11 -25 -20 -26 -59 -10 -12 5 -19 -1 -27 -22 -9 -27 -8
-31 16 -42 22 -10 28 -9 39 6 7 9 14 21 16 27 2 6 11 6 24 -2 32 -17 40 -14
51 18 10 27 8 30 -19 40 -27 9 -31 8 -41 -15z"/>
<path d="M4040 9355 c-23 -24 -23 -28 -10 -73 7 -26 14 -60 14 -75 l1 -29
-156 -2 -156 -1 -28 80 c-43 124 -42 123 -70 85 l-24 -33 29 -58 c48 -93 56
-86 -135 -110 -49 -6 -121 -20 -160 -30 -118 -32 -125 -31 -245 69 -95 78
-151 44 -181 -112 -9 -50 -3 -91 26 -159 l14 -35 158 60 c180 68 296 103 368
112 28 3 102 13 165 22 76 11 174 16 288 15 l172 -1 0 78 c0 74 -27 222 -40
222 -4 0 -17 -11 -30 -25z m-942 -278 c32 -30 27 -45 -19 -57 -23 -6 -53 -16
-67 -21 l-25 -9 5 54 c3 30 10 62 18 71 12 14 15 14 39 -1 14 -9 36 -26 49
-37z"/>
<path d="M5155 9144 c-54 -118 -102 -217 -107 -220 -4 -3 -40 12 -80 32 -40
20 -95 45 -123 55 -40 14 -44 18 -23 18 33 1 72 51 63 80 -8 26 -89 77 -160
102 -41 14 -76 19 -128 17 -69 -3 -72 -4 -95 -38 l-23 -35 52 -24 c61 -29 50
-39 -18 -17 -27 8 -71 18 -99 21 l-51 6 -18 57 c-9 31 -23 67 -29 80 -12 23
-12 23 -38 -16 -26 -38 -26 -40 -12 -98 19 -85 10 -131 -38 -176 -26 -26 -52
-40 -79 -44 -35 -6 -40 -10 -35 -28 3 -12 6 -24 6 -28 0 -13 80 -9 108 6 37
20 76 63 101 114 l22 43 47 -6 c102 -15 435 -126 555 -187 66 -32 120 -58 122
-56 3 3 234 504 242 524 3 9 -36 34 -55 34 -5 0 -53 -97 -107 -216z m-426 -3
c20 -11 43 -26 49 -34 10 -12 8 -16 -10 -21 -48 -13 -73 -5 -107 35 l-33 39
31 0 c18 0 49 -9 70 -19z"/>
<path d="M5278 9033 c-73 -136 -134 -253 -136 -259 -2 -7 10 -19 25 -28 l29
-14 137 253 c75 139 137 257 137 261 0 10 -32 33 -47 34 -6 0 -71 -111 -145
-247z"/>
<path d="M2311 9191 l-24 -18 163 -247 162 -246 29 17 c16 10 27 23 25 29 -8
22 -319 484 -325 484 -3 0 -17 -9 -30 -19z"/>
<path d="M2172 9094 c-13 -9 -21 -22 -18 -30 3 -7 66 -94 140 -193 75 -99 137
-185 138 -191 2 -5 -19 -25 -46 -44 l-50 -34 -80 52 c-45 29 -87 55 -93 60
-10 5 -13 -3 -13 -33 0 -39 2 -41 63 -73 34 -18 62 -39 62 -47 0 -8 -12 -23
-27 -34 l-26 -20 -58 34 c-93 54 -94 54 -94 11 0 -35 3 -40 45 -60 25 -12 45
-26 45 -30 0 -5 -11 -18 -23 -30 l-24 -22 -77 35 c-42 19 -79 35 -82 35 -2 0
-4 -15 -4 -33 0 -30 5 -35 56 -60 l57 -28 -59 -59 c-33 -33 -62 -59 -67 -60
-4 0 -1 15 7 34 13 32 13 36 -13 67 -27 32 -29 32 -61 19 -54 -23 -146 -120
-180 -191 -36 -77 -37 -99 -6 -136 l24 -27 41 37 c23 20 44 37 47 37 6 0 -19
-36 -58 -83 l-23 -28 -45 20 c-64 29 -134 28 -165 -4 -43 -42 -32 -106 31
-175 44 -49 88 -73 114 -65 11 3 37 28 59 56 l39 49 21 -26 c25 -31 27 -75 6
-133 l-14 -41 34 0 c40 0 60 23 70 81 7 39 -15 102 -54 158 l-21 29 96 113
c53 63 131 149 173 193 95 98 328 299 421 363 38 26 70 52 70 57 0 9 -338 460
-349 464 -3 1 -17 -5 -29 -14z m-295 -841 c-3 -10 -8 -29 -11 -43 -4 -19 -18
-31 -56 -47 -28 -12 -50 -17 -50 -11 0 22 44 81 74 99 39 24 49 24 43 2z
m-220 -337 c10 -9 8 -19 -12 -49 -14 -20 -30 -37 -35 -37 -15 0 -60 54 -60 72
0 36 74 46 107 14z"/>
<path d="M3965 9010 c-18 -20 -18 -21 3 -43 l22 -21 22 21 c21 22 21 23 3 43
-10 11 -21 20 -25 20 -4 0 -15 -9 -25 -20z"/>
<path d="M3686 8984 l-18 -26 -23 22 -23 22 -18 -27 c-17 -25 -16 -27 5 -48
l23 -22 15 23 c18 26 35 28 53 7 12 -14 15 -14 31 1 24 21 24 36 1 57 -23 21
-23 21 -46 -9z"/>
<path d="M5803 8853 c-7 -2 -13 -18 -13 -34 0 -26 3 -29 35 -29 33 0 35 -2 35
-31 0 -30 1 -31 32 -25 39 8 37 6 31 39 -4 22 -11 27 -33 27 -23 0 -29 5 -33
30 -6 29 -23 36 -54 23z"/>
<path d="M5625 8682 c-35 -11 -66 -32 -102 -69 -29 -30 -53 -57 -52 -61 0 -4
65 -61 144 -127 361 -302 699 -759 951 -1287 65 -137 122 -248 126 -248 5 0
125 59 268 130 l260 130 -15 30 -15 29 -122 -58 c-68 -32 -169 -81 -225 -110
-57 -28 -104 -51 -106 -51 -2 0 -12 17 -21 39 l-18 39 61 76 c64 81 77 110 67
151 -11 42 -51 59 -150 66 -89 5 -90 5 -123 44 -45 53 -43 84 12 169 54 83 58
123 19 163 -25 25 -27 25 -132 19 -59 -4 -112 -3 -118 1 -22 17 -65 83 -60 92
3 5 88 73 189 152 100 78 184 143 186 145 6 5 -29 54 -38 54 -5 0 -93 -65
-195 -145 -103 -80 -190 -145 -194 -145 -4 0 -20 19 -37 43 -16 23 -53 70 -82
104 l-53 62 31 49 c31 51 59 137 59 186 l0 28 113 -3 c106 -4 114 -3 146 20
35 25 35 25 10 31 -13 4 -79 10 -146 14 -172 10 -208 -10 -215 -124 -2 -36
-13 -84 -24 -108 l-19 -43 -108 120 c-64 72 -110 132 -113 148 -3 16 1 56 10
90 33 127 14 173 -71 172 -27 0 -70 -8 -98 -17z m93 -68 c9 -16 2 -66 -15
-111 -8 -20 -11 -18 -60 27 -58 53 -57 60 14 86 49 17 50 17 61 -2z m796 -940
c14 -14 14 -18 0 -47 -29 -56 -50 -78 -62 -64 -11 14 -62 114 -62 122 0 13
110 4 124 -11z m207 -394 c10 -6 19 -16 19 -23 0 -19 -61 -98 -72 -95 -7 3
-58 112 -58 126 0 7 94 0 111 -8z"/>
<path d="M3755 8434 c-172 -23 -329 -60 -465 -111 -268 -100 -553 -287 -783
-516 -461 -459 -799 -1125 -952 -1881 -205 -1012 -74 -2083 360 -2941 377
-747 940 -1252 1570 -1408 190 -47 232 -52 450 -52 218 0 260 5 450 52 487
120 965 475 1315 978 373 534 602 1186 686 1950 25 221 25 726 1 945 -75 679
-261 1265 -556 1752 -411 679 -957 1096 -1591 1214 -81 15 -415 28 -485 18z
m500 -284 c299 -63 594 -208 841 -413 527 -438 908 -1149 1058 -1977 51 -280
61 -401 61 -770 0 -373 -7 -473 -56 -755 -50 -297 -36 -266 -169 -362 -162
-117 -204 -136 -295 -136 -63 0 -84 4 -142 33 -60 29 -99 64 -328 294 -293
295 -404 385 -578 471 -150 74 -263 105 -404 112 -223 12 -423 -46 -636 -183
-152 -99 -196 -144 -244 -252 -18 -42 -52 -84 -123 -158 -79 -80 -106 -116
-134 -175 -76 -159 -67 -316 25 -490 57 -107 195 -198 299 -199 52 0 97 20 91
41 -2 8 -6 33 -8 55 -5 37 -2 44 20 58 46 31 71 25 140 -33 l65 -55 22 -135
c12 -75 40 -228 61 -341 43 -220 43 -250 7 -348 -39 -105 -147 -207 -292 -277
-105 -49 -417 -157 -441 -151 -30 8 -231 144 -321 217 -109 89 -256 238 -354
361 -427 529 -692 1240 -760 2033 -13 155 -13 575 0 730 68 793 334 1505 759
2032 102 127 321 341 436 426 273 201 577 329 877 367 122 15 408 4 523 -20z
m111 -3730 c43 -11 120 -40 169 -64 167 -82 251 -151 525 -426 264 -266 305
-302 379 -340 152 -77 306 -85 455 -25 33 13 62 23 64 21 9 -9 -117 -316 -193
-471 -358 -730 -855 -1139 -1525 -1257 -113 -20 -471 -17 -585 5 -110 21 -169
37 -161 44 2 3 39 20 81 38 249 109 418 281 470 481 23 90 19 211 -14 375 -16
79 -43 230 -60 334 -18 105 -34 200 -36 211 -3 12 -62 71 -131 132 l-126 109
-91 -4 c-162 -8 -201 -27 -222 -108 l-12 -50 -22 35 c-66 108 -83 233 -43 322
13 31 55 81 119 145 84 83 105 111 140 183 38 80 46 89 119 142 123 87 268
154 385 177 83 17 231 12 315 -9z m704 -2471 c-25 -20 -111 -68 -99 -55 9 10
113 75 119 76 3 0 -6 -9 -20 -21z"/>
<path d="M3730 8080 c-765 -105 -1427 -735 -1781 -1695 -83 -224 -171 -590
-203 -850 -90 -706 -9 -1407 236 -2045 53 -137 181 -400 254 -520 231 -379
547 -701 854 -869 l52 -29 152 53 c83 29 172 62 198 73 120 53 230 149 273
237 41 86 40 128 -4 355 -21 113 -47 256 -57 318 l-18 114 -50 44 c-55 50 -78
47 -56 -5 10 -25 10 -37 0 -61 -18 -43 -55 -62 -129 -68 -57 -4 -77 0 -149 28
-46 18 -86 38 -89 46 -3 7 -20 23 -38 34 -35 22 -79 84 -120 168 -64 132 -76
304 -32 431 35 98 84 171 179 261 65 62 87 90 120 159 45 94 88 141 199 218
171 117 368 199 538 224 180 26 399 -9 575 -90 195 -90 335 -202 648 -519 268
-272 302 -296 412 -296 77 1 121 21 251 116 l100 74 26 129 c53 261 76 467 85
755 19 621 -104 1251 -350 1790 -346 757 -908 1264 -1551 1400 -114 24 -413
35 -525 20z m265 -570 c10 -71 9 -70 61 -44 55 28 93 30 140 8 43 -21 43 -31
0 -26 -39 5 -99 -12 -116 -32 -9 -12 -4 -12 27 0 52 18 91 17 138 -5 39 -17
115 -91 115 -111 0 -5 -24 2 -52 15 -42 20 -68 25 -123 24 l-70 -2 75 -7 c133
-13 208 -62 235 -154 24 -80 19 -97 -13 -45 -58 91 -134 134 -225 127 l-42 -3
73 -23 c85 -27 154 -82 173 -139 14 -43 7 -150 -12 -184 -13 -23 -14 -22 -20
31 -7 68 -36 124 -86 171 -60 55 -81 68 -46 26 67 -77 83 -138 59 -220 -9 -30
-25 -65 -36 -78 l-19 -24 -1 35 c0 58 -29 151 -62 203 -17 26 -32 46 -33 45
-2 -2 4 -34 12 -73 18 -85 11 -152 -23 -221 -41 -86 -49 -84 -49 9 0 91 -12
177 -24 177 -4 0 -13 -18 -20 -40 -9 -30 -9 -107 -1 -327 6 -159 15 -302 19
-318 4 -17 4 -39 0 -50 -15 -39 -9 -63 26 -105 28 -34 33 -45 25 -61 -10 -17
-23 -19 -130 -19 -112 0 -119 1 -125 21 -5 16 1 29 24 53 29 30 32 38 30 97 0
35 5 202 12 371 10 257 10 314 -1 348 -20 61 -32 29 -38 -107 -5 -108 -6 -113
-22 -98 -10 9 -28 40 -40 68 -20 45 -22 64 -17 142 2 49 8 99 12 110 5 14 -1
11 -19 -10 -37 -43 -66 -127 -66 -190 0 -30 -4 -55 -8 -55 -18 0 -54 83 -59
135 -7 69 13 126 64 179 28 28 31 36 15 27 -78 -41 -142 -137 -150 -224 l-5
-50 -14 27 c-41 78 -22 200 40 252 39 33 124 74 154 74 12 0 25 5 29 12 10 16
-105 4 -143 -16 -41 -21 -89 -70 -113 -115 -17 -33 -19 -34 -20 -11 -1 82 66
176 142 200 24 7 75 17 113 21 l70 7 -80 -1 c-59 -2 -91 -8 -122 -23 -48 -24
-55 -14 -20 27 66 77 146 103 226 75 34 -13 41 -13 32 -2 -15 18 -94 40 -126
34 -36 -7 -31 7 9 26 47 22 85 20 140 -9 25 -13 46 -23 46 -22 0 13 23 124 27
131 9 14 22 -16 28 -64z m-567 -1376 c142 -41 305 -133 465 -261 43 -35 81
-63 85 -63 4 0 39 25 77 56 164 132 372 246 515 280 85 20 234 23 268 5 41
-22 26 -39 -40 -46 -194 -22 -467 -152 -690 -327 l-57 -45 108 -95 107 -95 20
36 c12 22 28 37 42 39 29 4 29 -20 1 -59 -26 -37 -20 -53 30 -79 56 -28 78
-25 135 15 27 19 57 35 66 35 48 0 55 -73 12 -117 -32 -31 -111 -63 -157 -63
-33 0 -101 28 -145 60 -37 27 -44 25 -57 -10 -9 -23 -18 -30 -37 -30 -14 0
-27 5 -27 10 -5 29 -2 35 21 40 52 12 37 42 -70 141 -56 52 -107 97 -114 101
-8 5 -52 -30 -120 -94 -80 -75 -106 -106 -104 -122 2 -13 12 -22 26 -24 32 -5
27 -46 -6 -50 -18 -3 -26 3 -35 27 -14 37 -17 38 -66 7 -100 -63 -159 -70
-242 -28 -58 29 -96 84 -84 121 13 41 55 36 124 -14 19 -14 45 -25 58 -25 22
0 113 46 113 57 0 2 -12 24 -26 49 -23 39 -24 45 -10 50 20 8 54 -21 67 -55 9
-23 11 -23 72 35 34 33 82 77 106 98 l45 39 -35 30 c-19 16 -87 64 -152 107
-197 132 -377 209 -534 231 -86 12 -102 19 -78 39 43 36 188 33 323 -6z"/>
<path d="M3964 3967 l-37 -33 56 -21 c30 -11 60 -17 66 -14 15 10 43 80 36 91
-4 6 -24 10 -46 10 -31 0 -47 -7 -75 -33z"/>
<path d="M1273 7740 c-43 -26 -45 -68 -5 -105 l27 -25 5 22 c8 32 50 49 126
50 80 2 91 -7 60 -52 -12 -19 -35 -56 -50 -82 l-28 -47 34 -21 c18 -11 36 -20
40 -20 6 0 138 206 138 215 0 14 -99 58 -156 70 -96 20 -153 19 -191 -5z"/>
<path d="M1180 7506 c0 -1 5 -17 11 -34 11 -33 18 -37 89 -47 77 -12 77 -14 4
-154 -87 -168 -90 -170 -205 -185 -52 -7 -104 -18 -116 -25 -32 -16 -38 -54
-19 -105 28 -73 75 -124 144 -156 34 -17 65 -30 70 -30 4 0 36 71 71 158 35
86 102 230 148 320 47 90 83 165 81 167 -40 35 -278 114 -278 91z m-27 -502
c7 -8 -51 -124 -63 -124 -5 0 -23 17 -40 38 -38 46 -36 74 5 83 41 9 90 10 98
3z"/>
<path d="M1508 7323 c-14 -3 -15 -9 -8 -29 14 -36 12 -44 -7 -44 -35 0 -45
-14 -32 -43 13 -29 14 -30 47 -21 19 6 22 11 16 39 -5 30 -3 34 24 40 28 6 29
8 19 36 -11 28 -22 32 -59 22z"/>
<path d="M807 7133 c-18 -5 -19 -15 -3 -54 5 -12 -1 -19 -23 -27 -29 -10 -30
-12 -19 -39 7 -15 13 -29 14 -31 3 -6 54 22 54 30 0 5 -5 18 -10 29 -9 16 -6
21 17 31 15 7 29 13 31 14 1 1 -3 13 -9 28 -12 26 -18 28 -52 19z"/>
<path d="M6985 6935 c-137 -57 -256 -107 -264 -112 -12 -8 -13 -15 -2 -40 l12
-30 265 108 c146 60 269 111 274 114 12 7 -9 65 -23 64 -7 -1 -124 -48 -262
-104z"/>
<path d="M713 5179 c-65 -12 -127 -60 -157 -121 -33 -66 -34 -126 -2 -193 28
-61 56 -87 120 -116 143 -63 307 47 307 206 0 146 -126 251 -268 224z"/>
<path d="M7242 5179 c-174 -33 -240 -265 -110 -386 125 -117 331 -57 374 109
42 161 -99 309 -264 277z"/>
<path d="M1431 3283 c-7 -16 -9 -29 -3 -31 5 -1 17 -9 27 -16 15 -11 16 -16 5
-36 -11 -20 -10 -25 6 -37 26 -19 39 -16 52 12 15 33 16 32 -13 39 -28 7 -31
17 -14 48 9 18 8 24 -7 35 -27 19 -39 16 -53 -14z"/>
<path d="M1082 3253 c-30 -9 -56 -21 -58 -28 -9 -23 108 -363 198 -575 50
-118 92 -216 93 -218 1 -1 22 6 46 17 27 11 63 41 94 77 72 83 85 105 85 145
0 57 -24 72 -136 79 -114 8 -136 21 -171 104 -33 79 -26 102 58 193 53 57 69
81 69 104 0 78 -162 137 -278 102z m147 -74 c21 -7 31 -17 31 -32 0 -21 -77
-100 -91 -92 -4 3 -12 27 -18 53 -5 26 -13 55 -16 65 -6 14 -1 17 27 17 19 0
49 -5 67 -11z m202 -499 c10 -6 19 -19 19 -29 0 -25 -58 -103 -73 -98 -10 3
-54 105 -56 130 -1 11 88 9 110 -3z"/>
<path d="M6852 2902 c-38 -8 -40 -17 -12 -54 19 -26 25 -28 100 -28 71 0 82
-3 111 -27 38 -33 59 -79 59 -133 0 -37 2 -40 29 -40 21 0 30 6 35 23 13 50 6
94 -26 147 -27 44 -45 60 -91 84 -61 31 -138 41 -205 28z"/>
<path d="M6498 2728 c-4 -41 -32 -45 -36 -5 -2 20 -9 27 -24 27 -18 0 -20 -4
-14 -32 7 -40 41 -71 67 -63 11 4 19 2 19 -3 0 -5 9 -12 21 -15 18 -5 20 -2
15 16 -3 12 -6 36 -6 54 0 22 -6 36 -19 43 -17 9 -20 6 -23 -22z"/>
<path d="M6660 2695 c0 -2 7 -19 15 -38 13 -32 20 -36 62 -42 67 -9 103 -16
103 -20 0 -2 -22 -52 -49 -110 l-50 -106 -220 111 c-122 60 -223 110 -225 110
-6 0 -29 -60 -25 -64 20 -19 514 -255 520 -249 9 9 119 230 142 286 l16 37
-37 19 c-52 26 -128 49 -194 59 -32 5 -58 8 -58 7z"/>
<path d="M1642 2471 c-134 -76 -247 -142 -253 -148 -6 -6 4 -34 29 -79 75
-135 254 -417 309 -488 31 -39 58 -73 59 -75 4 -5 464 360 464 368 -1 3 -9 17
-20 29 l-20 24 -190 -151 c-104 -83 -194 -151 -199 -151 -9 0 -74 81 -107 135
l-19 30 25 -18 c41 -29 50 -30 91 -18 38 12 40 14 34 44 -21 108 -83 211 -159
266 -48 34 -71 36 -110 8 -18 -13 -18 -15 3 -56 42 -82 15 -70 -36 16 -29 49
-49 91 -45 95 4 4 99 59 212 124 113 64 206 118 208 119 4 3 -27 66 -31 64 -1
0 -111 -62 -245 -138z m80 -367 c15 -21 31 -54 34 -72 l7 -34 -42 21 c-37 20
-41 25 -41 60 0 22 -3 47 -7 57 -11 30 20 10 49 -32z"/>
<path d="M6454 2265 c3 -9 9 -27 12 -39 5 -19 17 -25 75 -36 39 -7 72 -16 74
-20 2 -3 -6 -21 -17 -39 -16 -25 -26 -31 -42 -27 -57 16 -136 29 -141 25 -3
-3 0 -20 6 -36 8 -25 18 -32 47 -38 73 -12 73 -12 54 -45 -19 -33 -33 -36
-126 -20 -33 5 -61 8 -63 6 -2 -1 2 -17 8 -34 9 -27 16 -32 57 -37 26 -4 51
-7 55 -8 4 -2 -43 -70 -105 -152 -134 -177 -138 -180 -252 -146 -134 38 -176
22 -176 -67 0 -80 26 -149 77 -204 26 -27 51 -47 57 -46 26 7 267 274 369 408
86 114 227 323 301 447 15 27 -116 89 -248 118 -24 5 -27 3 -22 -10z m-359
-729 c19 -8 35 -19 35 -23 0 -4 -22 -27 -50 -51 l-49 -42 -14 27 c-19 37 -29
82 -22 93 9 15 61 12 100 -4z"/>
<path d="M2086 1790 c-119 -105 -216 -197 -216 -204 0 -7 9 -21 19 -31 19 -17
26 -12 238 176 120 106 220 194 222 196 3 2 -7 15 -21 29 l-25 26 -217 -192z"/>
<path d="M5830 1730 c0 -27 -3 -30 -30 -30 -28 0 -30 -3 -30 -35 0 -32 2 -35
29 -35 26 0 30 4 33 33 3 28 7 32 36 35 25 3 32 7 28 19 -3 8 -6 21 -6 29 0 9
-11 14 -30 14 -27 0 -30 -3 -30 -30z"/>
<path d="M5339 1471 l-23 -19 177 -236 177 -236 29 21 29 21 -175 234 c-97
129 -179 234 -183 234 -4 0 -18 -8 -31 -19z"/>
<path d="M2612 1411 c-17 -10 -5 -61 13 -61 31 0 45 13 45 41 0 23 -4 29 -22
29 -13 0 -29 -4 -36 -9z"/>
<path d="M2379 1389 c-21 -10 -54 -38 -74 -61 -35 -41 -37 -46 -33 -101 5 -70
-2 -62 220 -230 l166 -126 41 54 c40 53 92 148 106 193 6 22 5 23 -32 16 -21
-3 -41 -9 -44 -13 -4 -3 -17 -34 -29 -69 -13 -34 -26 -64 -29 -67 -9 -5 -248
177 -283 215 -40 43 -38 89 5 127 25 22 31 34 27 55 l-5 26 -36 -19z"/>
<path d="M5213 1388 c-13 -6 -23 -15 -23 -19 0 -5 24 -46 53 -91 30 -46 59
-93 65 -105 12 -21 12 -21 -31 2 -39 22 -148 52 -251 70 l-38 6 22 -38 c13
-21 26 -41 29 -45 3 -4 39 -14 80 -22 180 -38 224 -102 171 -255 l-21 -59 22
-36 21 -37 42 24 c22 13 76 43 119 67 42 24 77 46 77 49 -1 14 -296 495 -305
497 -6 1 -20 -2 -32 -8z m201 -394 c14 -25 23 -50 20 -55 -7 -11 -65 -42 -70
-37 -3 4 17 138 22 138 1 0 14 -21 28 -46z"/>
<path d="M2692 1363 c-19 -4 -23 -9 -18 -26 3 -12 6 -27 6 -33 0 -6 13 -9 30
-6 27 4 30 8 30 38 0 35 -2 36 -48 27z"/>
<path d="M2955 1063 c-71 -141 -131 -259 -133 -262 -1 -4 11 -14 28 -24 l29
-18 27 53 c14 29 76 148 136 265 l109 212 -30 15 c-16 9 -31 16 -33 16 -2 0
-61 -116 -133 -257z"/>
<path d="M3376 874 c-47 -166 -74 -280 -69 -285 12 -12 162 -50 323 -82 92
-18 188 -28 365 -37 239 -12 241 -12 425 15 262 38 382 66 565 134 147 54 160
61 157 82 -5 42 -48 123 -97 183 l-48 59 -15 -37 -14 -36 46 -56 c25 -31 46
-60 46 -63 0 -4 -17 -13 -37 -19 -36 -12 -39 -11 -58 15 -11 15 -36 45 -56 67
l-36 40 -13 -38 c-12 -36 -11 -39 19 -70 38 -40 39 -50 2 -65 -27 -12 -31 -10
-87 49 -53 56 -59 60 -71 44 -23 -31 -15 -61 24 -93 20 -17 35 -32 32 -34 -8
-7 -253 -57 -257 -52 -2 2 -17 43 -34 92 -16 48 -33 92 -37 96 -5 5 -18 -5
-31 -22 l-21 -31 32 -70 c18 -39 30 -72 28 -74 -10 -10 -235 -37 -264 -32 -41
8 -88 53 -153 149 -56 82 -85 104 -126 93 -29 -7 -41 -26 -51 -80 -7 -37 -7
-38 14 -26 13 6 28 9 35 7 14 -5 106 -122 106 -133 0 -7 -170 1 -267 12 l-53
6 0 109 c0 60 -2 109 -5 109 -3 0 -19 -10 -36 -22 l-30 -22 7 -78 c6 -72 5
-78 -12 -77 -41 2 -229 55 -228 64 1 6 30 112 64 238 35 125 61 229 59 231 -2
2 -18 7 -35 10 l-30 7 -78 -277z"/>
<path d="M3927 942 c-22 -24 -21 -35 1 -55 17 -16 20 -15 40 6 l22 23 -23 22
c-23 22 -24 22 -40 4z"/>
<path d="M4373 938 c-22 -21 -23 -21 -43 -3 -21 19 -22 19 -40 -5 -19 -25 -18
-25 3 -48 l22 -23 22 27 c21 27 23 27 41 11 24 -22 34 -21 50 4 11 18 11 24
-4 40 -22 24 -25 24 -51 -3z"/>
<path d="M3148 854 c-38 -27 -38 -28 -38 -98 0 -113 -49 -166 -154 -166 -44 0
-46 -1 -46 -30 0 -25 5 -31 40 -41 33 -10 47 -9 86 6 124 47 183 168 156 318
l-7 37 -37 -26z"/>
<path d="M3583 419 c-20 -20 -20 -24 -6 -45 15 -23 16 -23 44 -7 27 16 28 17
11 45 -20 34 -22 34 -49 7z"/>
</g>
</svg>
    </>
  )
}
