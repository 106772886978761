import React from 'react';

const Ind = () => {
    return (
        <>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 1000.000000 951.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,951.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M4445 9229 c-73 -18 -80 -29 -77 -119 4 -123 -41 -301 -90 -356 -21
-24 -22 -37 -5 -64 10 -17 9 -22 -2 -30 -22 -13 -63 13 -85 54 -25 48 -52 63
-130 76 -42 7 -83 21 -117 43 -46 28 -60 32 -123 32 -134 -1 -279 -74 -326
-165 -25 -48 -74 -93 -167 -154 -75 -49 -80 -55 -123 -141 -25 -49 -66 -121
-93 -158 -26 -38 -47 -75 -47 -83 0 -45 97 -162 162 -195 26 -13 43 -32 57
-64 22 -49 95 -133 155 -177 54 -39 88 -37 122 8 34 44 73 45 89 0 8 -23 5
-36 -17 -76 -14 -26 -30 -69 -34 -96 -4 -27 -15 -60 -26 -74 -28 -38 -48 -153
-48 -278 0 -98 3 -120 25 -172 14 -33 25 -66 25 -74 0 -16 104 -176 145 -221
14 -16 40 -47 58 -67 33 -39 67 -50 67 -22 0 26 24 10 32 -22 3 -16 11 -108
17 -204 6 -96 20 -218 32 -270 11 -52 25 -122 30 -155 21 -116 -9 -452 -46
-524 -18 -36 -57 -61 -94 -61 -48 0 -61 -9 -61 -44 -1 -41 -30 -104 -65 -138
-56 -56 -74 -25 -27 45 33 48 45 77 33 77 -5 0 -28 -30 -52 -67 -40 -61 -44
-73 -44 -129 0 -45 5 -68 18 -81 18 -21 14 -33 -13 -33 -23 0 -95 -53 -116
-85 -15 -23 -15 -34 0 -142 9 -65 17 -131 19 -148 3 -24 4 -21 6 11 1 39 17
141 27 177 11 35 31 16 43 -40 13 -69 15 -363 1 -395 -13 -32 -39 -63 -46 -56
-3 4 -11 39 -18 80 l-13 73 -11 -40 c-7 -22 -12 -48 -12 -57 0 -28 -17 -21
-24 10 -10 40 -15 338 -7 405 4 31 5 57 3 57 -14 0 -31 -40 -32 -71 0 -39 -14
-77 -44 -122 -30 -44 -25 -110 11 -155 37 -47 44 -65 54 -132 23 -170 80 -279
171 -326 18 -9 69 -20 115 -23 69 -5 86 -10 104 -30 22 -24 23 -24 30 -3 7 18
15 20 71 19 61 -2 63 -3 66 -30 4 -32 -18 -45 -52 -29 -15 7 -28 7 -40 0 -13
-7 -21 -7 -26 2 -10 17 -16 3 -19 -47 -2 -26 2 -43 8 -43 6 0 11 5 11 10 0 13
47 13 55 0 4 -6 13 -5 25 2 14 9 22 9 34 -1 13 -11 19 -11 32 0 12 10 20 10
35 1 15 -9 23 -9 38 0 15 9 23 9 36 -2 11 -9 19 -10 23 -3 6 8 117 11 117 3 0
-1 21 -3 48 -4 26 -1 47 2 47 6 0 12 34 10 47 -3 8 -8 13 -8 17 0 9 14 56 14
56 1 0 -6 8 -5 20 2 16 10 24 10 40 1 10 -7 21 -9 24 -4 6 10 181 9 191 -1 4
-4 15 -3 24 1 22 12 103 11 118 -1 7 -6 14 -5 18 2 9 14 52 13 58 -2 4 -10 7
-10 18 0 19 17 49 15 49 -5 0 -14 2 -14 21 3 17 15 23 16 40 6 15 -10 23 -10
38 0 16 10 23 9 39 -5 17 -16 20 -16 25 -2 7 17 42 20 52 5 4 -7 11 -8 18 -2
14 11 96 10 114 -1 7 -4 21 -2 33 5 15 10 24 10 39 1 14 -9 21 -9 30 0 9 9 16
8 31 -1 16 -9 24 -10 39 0 15 9 23 9 34 -1 12 -10 19 -10 35 0 16 10 23 10 36
0 9 -7 21 -9 28 -5 18 12 107 14 113 3 3 -5 15 -3 25 4 16 9 24 9 40 0 12 -8
21 -9 25 -3 8 14 42 13 47 -1 3 -9 10 -8 25 2 17 10 24 10 36 0 10 -8 17 -9
22 -1 9 14 42 13 48 -2 3 -10 7 -10 20 0 28 24 57 6 58 -36 l2 -37 7 35 c4 19
8 29 9 23 0 -7 14 -13 31 -13 23 0 28 -4 24 -17 -4 -12 4 -8 20 10 14 15 26
38 26 50 0 12 7 30 15 40 16 22 9 53 -9 38 -7 -6 -17 -6 -26 -1 -10 6 -21 4
-32 -7 -14 -12 -23 -14 -41 -6 -15 7 -27 7 -39 -1 -14 -8 -23 -7 -40 3 -16 10
-23 10 -26 2 -4 -13 -39 -15 -47 -1 -4 6 -15 5 -30 -2 -17 -8 -28 -8 -37 0 -9
7 -18 6 -32 -3 -14 -11 -24 -11 -38 -4 -11 6 -31 8 -45 4 -14 -3 -31 -1 -40 7
-12 10 -17 9 -24 -5 -9 -15 -13 -16 -39 -2 -17 9 -31 11 -35 5 -3 -5 -38 -7
-78 -5 -40 2 -77 -1 -83 -6 -8 -6 -17 -4 -28 6 -13 13 -19 14 -36 3 -15 -10
-24 -10 -38 -1 -12 7 -24 8 -35 2 -11 -7 -24 -6 -38 2 -16 8 -23 8 -29 -1 -4
-6 -18 -10 -31 -8 -18 2 -25 10 -27 30 -3 22 2 29 23 34 15 4 31 2 37 -4 7 -7
16 -7 29 1 15 9 23 9 38 0 14 -9 21 -9 30 0 8 8 19 8 40 0 21 -7 33 -7 40 0 7
7 18 7 35 -1 16 -7 28 -8 31 -2 8 13 45 13 45 0 0 -6 10 -5 26 2 20 9 30 9 45
0 15 -10 23 -9 39 0 15 10 25 10 38 2 12 -8 24 -8 40 -1 16 7 26 7 33 0 8 -8
18 -8 34 -1 15 7 29 7 42 -1 14 -7 23 -7 31 1 8 8 18 8 34 1 16 -7 28 -7 40 1
13 8 23 8 36 0 12 -8 22 -8 35 0 12 8 22 8 35 -1 13 -7 24 -8 37 -1 13 8 24 7
38 -2 12 -7 22 -8 26 -2 3 5 40 13 81 17 41 4 77 11 80 15 3 3 30 23 60 42 74
48 158 131 186 185 25 46 30 68 15 58 -35 -21 -54 301 -27 453 15 82 22 95 41
69 25 -35 32 -365 10 -492 -3 -16 -2 -23 1 -15 3 8 9 24 14 35 5 11 20 45 33
75 13 30 36 73 50 94 36 52 34 80 -13 156 -22 36 -43 81 -46 100 -14 84 -47
205 -66 236 -18 31 -63 54 -104 54 -14 0 -24 32 -24 75 0 54 -55 132 -122 176
-34 21 -69 39 -79 39 -10 0 -20 4 -23 9 -3 4 -27 14 -53 21 -79 21 -89 37
-134 218 -12 50 -26 92 -31 92 -11 0 -10 -20 3 -115 8 -60 8 -91 0 -122 -14
-50 -22 -53 -49 -18 -38 48 -58 20 -25 -35 9 -16 38 -40 64 -55 52 -28 121
-100 150 -156 21 -41 17 -49 -22 -49 -32 0 -40 10 -54 65 -15 60 -42 59 -50
-2 -4 -26 -8 -54 -10 -60 -6 -19 -39 -16 -62 5 -53 46 -157 231 -195 345 -10
32 -48 116 -83 186 -71 140 -89 206 -81 291 3 30 8 188 11 350 3 162 10 296
14 298 12 4 160 -160 199 -221 18 -29 42 -83 54 -121 19 -64 20 -84 14 -244
-5 -137 -3 -173 6 -170 20 7 28 69 36 295 8 211 15 250 41 219 23 -28 31 -142
24 -386 -5 -216 -4 -244 9 -233 11 9 15 37 15 110 1 88 24 334 39 423 27 156
96 326 188 464 57 85 80 137 140 324 47 145 40 164 -38 92 -24 -22 -52 -40
-63 -40 -27 0 -93 74 -93 104 0 42 81 136 118 136 29 0 23 -32 -18 -85 -64
-83 -42 -107 39 -44 13 11 35 19 49 19 24 0 24 1 17 57 -9 77 -59 227 -93 283
-63 103 -73 143 -37 156 20 8 74 -34 106 -80 29 -43 59 -54 91 -32 42 29 130
121 145 151 8 17 68 82 133 145 74 71 122 125 126 142 4 19 -1 42 -16 72 -27
57 -103 140 -121 134 -10 -4 1 -19 33 -51 56 -55 68 -76 68 -117 0 -45 -23
-48 -78 -10 -70 50 -102 82 -102 103 0 11 13 41 29 66 40 63 49 113 25 149
-33 51 -89 86 -154 97 -51 9 -48 -10 3 -20 17 -4 48 -16 68 -26 52 -26 64 -69
19 -69 -21 0 -90 -56 -90 -72 0 -7 15 -8 39 -4 45 7 62 -7 37 -32 -10 -11 -34
-16 -71 -16 -30 -1 -55 -4 -55 -9 0 -4 17 -23 39 -42 21 -19 44 -49 51 -65 7
-17 23 -33 36 -36 12 -3 30 -14 40 -25 9 -10 27 -19 39 -19 23 0 55 -28 55
-48 0 -30 -119 -2 -190 45 -60 40 -93 44 -231 29 -78 -9 -78 -8 -70 94 7 79
26 94 107 84 36 -4 54 -2 59 7 12 19 -21 37 -84 45 -56 6 -91 26 -91 50 0 23
62 85 117 116 35 20 53 36 53 49 0 57 -81 164 -152 200 -45 23 -58 24 -58 4 0
-8 6 -15 14 -15 7 0 21 -16 30 -35 12 -25 23 -35 40 -35 30 0 45 -19 53 -64 7
-47 -13 -70 -75 -86 -34 -8 -61 -25 -98 -61 -27 -27 -70 -60 -95 -73 -24 -13
-54 -39 -66 -57 -47 -72 -50 -75 -70 -63 -31 19 -28 74 6 133 39 65 39 77 1
85 -17 3 -30 12 -30 21 0 8 -11 22 -24 30 -22 15 -28 14 -88 -8 -35 -14 -83
-34 -106 -46 -47 -24 -82 -27 -82 -8 0 21 80 98 116 113 18 8 63 17 101 20 70
7 103 24 103 54 0 9 10 26 23 38 26 25 104 61 132 62 11 0 27 5 35 10 11 7 -3
10 -45 10 -56 0 -64 -3 -120 -46 -86 -66 -156 -96 -236 -102 -53 -4 -74 -1
-93 12 -46 30 -147 281 -161 399 -4 38 -14 66 -29 83 l-22 26 -519 3 c-313 2
-536 0 -560 -6z m825 -61 c0 -39 14 -42 66 -12 55 32 124 47 159 34 21 -8 25
-16 25 -53 0 -24 11 -76 25 -115 29 -83 31 -114 9 -137 -20 -19 -35 -15 -50
13 -19 38 -48 64 -105 93 -76 38 -92 54 -77 79 7 11 25 25 40 31 16 6 28 15
28 20 0 16 -38 10 -72 -11 -32 -19 -90 -26 -122 -14 -27 10 -19 60 15 99 29
33 32 34 45 18 7 -10 14 -30 14 -45z m-655 -4 c28 -9 64 -27 82 -40 40 -31 43
-30 43 6 0 24 5 31 27 36 42 11 70 -41 41 -78 -19 -24 -82 -38 -170 -38 -89 0
-108 -5 -108 -31 0 -19 8 -23 46 -20 11 1 27 -5 34 -13 39 -48 205 21 233 97
3 9 8 9 18 0 7 -7 22 -13 32 -13 16 0 18 5 12 28 -13 51 -11 87 5 87 11 0 16
-15 18 -63 3 -62 3 -63 30 -60 21 2 26 8 24 23 -14 79 -14 105 2 105 25 0 38
-36 27 -75 -5 -19 -6 -37 -2 -39 4 -3 30 -6 57 -7 27 -1 58 -6 69 -10 11 -5
45 -15 76 -24 101 -28 207 -102 256 -178 21 -34 27 -37 56 -32 24 5 41 0 67
-17 47 -32 60 -61 60 -143 0 -38 7 -115 14 -169 19 -132 8 -170 -56 -190 -12
-4 -18 -16 -18 -38 -1 -18 -7 -44 -14 -58 -12 -21 -12 -28 4 -50 27 -39 29
-52 10 -90 -24 -47 -134 -165 -146 -157 -6 3 -16 30 -23 59 -17 73 0 128 45
140 19 5 35 18 42 33 14 31 15 105 2 163 -14 57 4 165 36 219 13 23 24 57 24
77 0 39 -25 120 -47 151 -12 18 -13 14 -7 -38 l7 -58 -47 -44 c-25 -25 -54
-45 -64 -45 -19 0 -62 57 -62 84 0 9 13 36 29 60 44 64 44 100 1 149 -28 33
-84 57 -131 57 -38 0 -102 -37 -127 -74 -30 -43 -31 -85 -3 -131 27 -46 26
-57 -1 -82 -29 -26 -74 -34 -124 -23 -67 15 -88 64 -48 111 67 77 -137 228
-222 164 -18 -13 -16 -14 24 -15 60 0 107 -25 126 -65 22 -46 21 -53 -16 -75
-44 -26 -103 -25 -153 3 -76 43 -33 85 73 71 26 -3 42 -1 42 5 0 18 -41 33
-83 29 -35 -2 -42 0 -41 14 1 14 -2 15 -16 8 -29 -15 -91 -91 -88 -106 2 -8
29 -26 61 -39 106 -43 110 -58 33 -134 l-54 -53 -31 23 c-38 28 -77 77 -91
113 -17 45 -56 26 -44 -21 3 -11 -2 -25 -11 -33 -13 -11 -20 -10 -43 7 -38 27
-62 80 -62 135 0 39 5 54 30 80 l31 33 16 -22 c21 -31 43 -29 43 4 0 15 5 42
12 60 12 33 11 35 -20 59 -38 30 -40 49 -10 73 l21 18 -21 31 c-26 36 -28 77
-4 92 21 13 101 6 167 -16z m465 -5 c0 -11 -5 -30 -12 -42 -11 -21 -11 -21
-19 -2 -15 38 -10 65 11 65 14 0 20 -7 20 -21z m68 -17 c2 -11 -2 -31 -8 -43
-10 -17 -14 -19 -21 -8 -10 17 -12 63 -2 73 12 11 28 0 31 -22z m-288 14 c0
-3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m458
-268 c18 -18 15 -41 -4 -34 -21 8 -104 -40 -104 -59 0 -19 9 -19 49 1 33 16
105 12 117 -7 9 -15 -17 -40 -61 -60 -75 -34 -155 -18 -155 31 0 35 36 88 79
117 38 26 61 29 79 11z m-1424 -80 c35 -15 65 -83 73 -162 5 -56 3 -69 -13
-87 -16 -18 -19 -37 -19 -123 0 -108 -5 -126 -35 -126 -29 0 -32 -15 -10 -43
29 -37 36 -82 16 -112 -16 -25 -46 -25 -46 0 0 20 -28 44 -58 51 -40 10 -75
44 -61 61 6 7 20 13 31 13 34 0 34 31 -1 62 -26 24 -30 32 -21 44 7 7 17 14
22 14 6 0 23 9 39 20 39 28 47 78 22 127 -29 55 -44 54 -37 -1 8 -54 -8 -98
-41 -116 -33 -18 -45 -5 -45 49 0 60 -12 75 -80 101 -61 24 -100 55 -100 80 0
27 37 67 67 75 16 3 38 16 50 27 14 13 28 18 41 14 12 -4 38 3 67 19 52 27 99
32 139 13z m184 -64 c12 -8 22 -24 22 -35 0 -15 -7 -19 -34 -19 -47 0 -83 39
-59 63 11 12 48 7 71 -9z m128 -120 c61 -29 95 -67 75 -87 -9 -9 -16 -8 -32 1
-42 26 -37 7 43 -167 26 -58 28 -66 14 -80 -15 -14 -19 -13 -42 11 -18 18 -28
40 -31 73 -5 38 -10 48 -31 56 -14 6 -32 19 -39 29 -8 11 -45 36 -83 56 -50
26 -72 44 -76 60 -18 70 98 97 202 48z m994 -2 c0 -4 -13 -23 -30 -42 -16 -19
-29 -38 -30 -42 0 -15 46 -8 85 12 21 11 41 17 44 15 7 -8 -31 -35 -59 -41
-32 -7 -63 -30 -59 -43 3 -7 14 -5 33 4 15 8 33 15 38 15 6 0 31 14 55 31 33
21 49 27 57 19 16 -16 -23 -49 -85 -71 l-54 -19 50 4 c28 2 58 7 68 11 12 5
17 2 17 -9 0 -20 -50 -46 -92 -47 -18 -1 -44 -4 -58 -8 l-25 -7 22 -12 c41
-22 115 -15 164 16 24 16 53 39 63 50 21 25 56 29 56 8 0 -7 -7 -19 -15 -26
-19 -16 -19 -39 0 -64 22 -28 18 -57 -11 -101 -33 -52 -83 -88 -101 -73 -21
17 -16 35 22 77 38 42 58 101 33 101 -8 0 -29 -11 -48 -25 -56 -42 -134 -30
-239 37 -56 35 -95 35 -152 0 -90 -56 -132 -65 -167 -34 -20 18 -48 79 -40 87
3 3 24 -2 48 -10 48 -17 180 -21 180 -5 0 6 -10 10 -22 10 -29 0 -95 23 -110
38 -7 7 10 6 48 -3 69 -17 133 -20 118 -5 -5 5 -33 15 -62 23 -29 7 -49 17
-45 21 12 12 10 56 -2 56 -5 0 -16 9 -23 20 -16 25 -6 25 49 -2 31 -15 64 -22
110 -22 60 -1 71 2 110 31 45 33 59 39 59 25z m-812 -52 c19 -25 29 -30 66
-30 28 0 46 -5 49 -13 9 -24 -28 -90 -72 -130 -34 -31 -45 -36 -57 -26 -8 6
-14 22 -14 34 0 12 -7 55 -16 94 -9 39 -14 78 -10 86 9 24 30 18 54 -15z
m1592 4 c0 -35 -44 -76 -124 -115 -90 -44 -121 -47 -121 -10 0 17 13 33 49 58
27 18 60 36 75 39 14 2 42 13 61 23 44 24 60 25 60 5z m-2475 -5 c50 -14 165
-69 165 -80 0 -15 -29 -37 -74 -55 -47 -20 -61 -44 -26 -44 32 0 74 -26 87
-52 22 -46 24 -65 24 -226 -1 -131 -4 -170 -18 -195 -22 -44 -132 -150 -172
-167 -31 -12 -35 -12 -48 6 -14 19 -10 58 8 80 5 6 26 16 48 23 58 17 91 61
91 120 0 39 -5 53 -26 75 -47 47 -61 27 -19 -28 28 -37 31 -52 11 -59 -32 -13
-116 117 -97 151 12 23 54 52 76 52 23 0 75 29 75 42 0 6 -11 8 -30 4 -20 -3
-50 2 -83 14 -62 24 -86 25 -112 6 -19 -14 -19 -15 -1 -34 11 -13 15 -28 11
-41 -8 -27 -93 -101 -116 -101 -56 0 -64 32 -19 75 21 20 30 38 30 60 0 23 6
35 24 45 19 10 26 22 28 54 3 35 8 43 37 58 50 25 56 41 25 77 -34 41 -71 41
-109 1 -35 -36 -45 -38 -45 -6 0 15 11 33 28 46 24 20 170 106 182 109 3 0 23
-4 45 -10z m582 -113 c32 -15 57 -35 67 -53 8 -15 24 -36 35 -45 21 -18 27
-51 15 -82 -9 -22 -31 -20 -46 4 -7 12 -34 35 -60 51 -59 38 -98 83 -98 113 0
43 15 45 87 12z m571 -7 c2 -14 -4 -31 -12 -39 -22 -18 -20 -72 4 -102 11 -14
42 -40 70 -58 51 -32 59 -47 39 -67 -22 -22 -148 34 -171 76 -20 37 -17 161 5
195 24 35 61 33 65 -5z m1640 -16 c-2 -19 -16 -36 -45 -56 -31 -20 -46 -39
-55 -69 -6 -22 -18 -42 -25 -45 -20 -8 -53 26 -53 54 0 40 113 142 158 143 20
0 23 -4 20 -27z m-318 -23 c0 -36 -135 -120 -193 -120 -23 0 -48 22 -60 54 -6
16 -3 18 24 12 24 -4 38 0 64 20 42 32 122 63 147 56 10 -2 18 -12 18 -22z
m-1486 -66 c9 -8 16 -28 16 -44 0 -16 8 -35 18 -42 10 -7 21 -18 25 -25 8 -13
-48 -83 -67 -83 -21 0 -90 66 -111 104 -19 36 -19 36 0 56 11 10 38 26 60 34
22 8 41 15 42 15 1 1 9 -6 17 -15z m-1196 -27 c2 -16 -6 -43 -19 -64 -32 -51
-118 -153 -129 -153 -5 0 -17 11 -26 25 -27 41 -5 96 67 167 67 67 101 75 107
25z m1820 -32 c50 -58 19 -95 -80 -95 -44 0 -54 4 -84 34 -19 19 -34 38 -34
43 0 18 36 42 76 51 50 11 93 -1 122 -33z m1180 -9 c4 -58 -10 -77 -89 -116
-64 -33 -83 -52 -76 -79 9 -34 -8 -47 -37 -28 -44 29 -59 61 -47 99 14 43 45
61 102 59 50 -1 72 14 83 61 7 31 34 60 50 55 6 -2 12 -25 14 -51z m-2212 -12
c57 -42 104 -99 104 -126 0 -28 -28 -33 -53 -10 -12 11 -41 29 -64 41 -49 24
-73 55 -73 92 0 51 21 51 86 3z m1933 8 c16 -30 -11 -56 -81 -82 -35 -12 -74
-33 -89 -46 -31 -29 -35 -30 -49 -3 -22 40 15 88 105 138 34 18 103 14 114 -7z
m-1133 -33 c54 -58 113 -89 168 -89 60 0 96 19 153 81 26 28 45 40 56 36 14
-5 13 -12 -3 -64 -9 -32 -15 -73 -13 -90 5 -29 2 -34 -23 -44 -17 -6 -55 -9
-89 -5 -33 3 -91 8 -130 12 -62 5 -73 4 -94 -15 -31 -27 -33 -27 -69 10 -39
40 -56 106 -42 159 14 51 44 54 86 9z m845 10 c9 -17 8 -25 -4 -36 -19 -19
-37 -9 -37 21 0 40 23 49 41 15z m-1456 -25 c20 -21 47 -83 39 -95 -10 -17
-33 -9 -63 24 -41 43 -41 81 1 86 4 1 14 -6 23 -15z m-506 -68 c35 -18 104
-107 131 -170 17 -38 19 -50 9 -62 -22 -26 -52 -13 -62 27 -5 20 -26 56 -48
80 -58 66 -92 117 -84 128 8 15 21 14 54 -3z m1961 -42 c0 -18 -9 -48 -20 -66
-21 -34 -26 -68 -11 -68 4 0 20 15 35 33 26 30 28 31 41 14 10 -15 11 -27 1
-65 -15 -57 -39 -104 -61 -122 -15 -13 -20 -12 -35 8 -46 56 -36 282 14 314
18 12 36 -13 36 -48z m-1650 -13 c83 -47 99 -51 132 -34 26 12 30 11 67 -22
50 -45 65 -82 59 -149 -6 -68 -46 -151 -73 -151 -26 0 -59 66 -51 100 5 20 13
25 36 25 21 0 30 5 30 17 0 19 -46 83 -60 83 -23 0 -50 -46 -50 -84 -1 -42
-18 -76 -39 -76 -7 0 -23 13 -37 29 -14 17 -35 33 -47 37 -85 25 -151 200 -96
255 24 24 39 21 129 -30z m503 4 c20 -25 36 -35 56 -35 48 0 55 -21 21 -67
-31 -42 -91 -93 -109 -93 -6 0 -17 15 -25 34 -8 19 -32 54 -54 78 -44 48 -78
108 -62 108 6 0 25 -7 41 -16 28 -14 32 -14 62 5 18 11 34 21 37 21 3 0 18
-16 33 -35z m1903 20 c8 -20 -29 -78 -60 -95 -43 -22 -43 -49 -2 -87 20 -19
46 -33 61 -33 28 0 93 -53 106 -87 13 -36 1 -53 -38 -53 -28 0 -42 8 -71 40
-20 23 -57 54 -84 70 -55 34 -62 53 -56 145 4 73 18 93 69 105 52 12 69 11 75
-5z m-517 -14 c23 -14 4 -62 -41 -103 -49 -45 -78 -50 -78 -12 0 31 15 61 48
97 27 28 47 33 71 18z m329 -19 c5 -29 -19 -71 -78 -135 -27 -30 -50 -59 -50
-64 0 -5 -6 -18 -14 -28 -14 -19 -15 -19 -35 -1 -12 11 -21 24 -21 30 0 18 84
137 130 184 44 45 63 49 68 14z m462 -44 c0 -19 -21 -48 -35 -48 -8 0 -15 2
-15 4 0 2 -3 11 -6 19 -7 19 22 48 41 41 8 -4 15 -10 15 -16z m-152 -59 c41
-11 54 -19 59 -37 7 -31 -12 -72 -35 -72 -38 0 -178 103 -165 122 7 12 69 6
141 -13z m-2792 -65 c56 -58 66 -65 91 -60 35 8 102 -14 147 -49 51 -39 69
-144 30 -176 -21 -17 -39 -5 -63 43 -24 46 -69 98 -84 98 -6 0 -25 -18 -44
-40 -19 -22 -43 -40 -54 -40 -17 0 -19 7 -19 60 0 58 -1 61 -55 121 -65 72
-71 104 -21 108 5 1 38 -29 72 -65z m2238 43 c13 -20 -16 -63 -60 -87 -16 -10
-29 -25 -29 -36 0 -16 9 -19 58 -22 49 -3 57 -6 57 -23 0 -12 -18 -32 -45 -51
-59 -42 -60 -63 -2 -54 118 19 127 19 127 3 0 -9 -19 -33 -41 -54 -23 -21 -48
-53 -55 -70 -8 -18 -21 -33 -28 -33 -18 0 -56 47 -56 69 0 9 -8 30 -18 46 -11
17 -25 54 -32 83 -7 31 -20 58 -31 65 -26 16 -24 42 6 93 26 44 65 78 100 87
28 6 37 3 49 -16z m-2702 -4 c10 -9 28 -21 40 -27 31 -15 34 -40 9 -65 -27
-27 -37 -26 -66 4 -18 18 -25 35 -25 65 0 44 12 50 42 23z m2018 2 c26 -32
-13 -157 -55 -177 -40 -18 -55 -4 -55 48 0 50 26 103 63 129 27 19 31 19 47 0z
m-600 -23 c30 -11 74 -26 97 -32 50 -15 136 -79 141 -104 13 -64 -108 -22
-147 52 -18 33 -30 27 -33 -19 -6 -77 -68 -76 -111 1 -11 19 -30 45 -43 57
-24 22 -31 53 -17 67 10 10 44 3 113 -22z m413 -43 c61 -28 52 -101 -15 -121
-59 -17 -88 0 -88 52 0 73 39 98 103 69z m1087 -36 c-1 -16 -6 -57 -13 -93 -9
-48 -17 -66 -30 -68 -28 -6 -39 39 -22 93 24 83 66 125 65 68z m-1772 -34 c5
-25 -47 -81 -72 -77 -53 8 -11 102 44 96 13 -2 26 -10 28 -19z m1345 15 c3 -3
-10 -35 -28 -70 -46 -86 -35 -117 25 -72 53 39 53 -2 1 -84 -39 -61 -66 -64
-76 -10 -3 20 -17 57 -31 81 -13 24 -24 51 -24 60 1 46 106 121 133 95z m-220
-36 c16 -24 -8 -51 -34 -37 -19 10 -25 39 -12 53 11 11 35 3 46 -16z m-1860
-42 c-2 -31 26 -94 52 -116 9 -7 22 -25 30 -40 13 -24 11 -30 -16 -71 -24 -36
-29 -55 -29 -103 0 -50 -4 -62 -25 -82 -21 -19 -25 -32 -25 -78 0 -45 -4 -57
-20 -66 -10 -6 -25 -28 -31 -50 -7 -22 -15 -40 -19 -40 -9 0 -74 71 -93 102
-18 29 -23 88 -8 88 4 0 25 -13 46 -30 73 -58 86 -32 25 50 -48 65 -66 118
-46 138 17 17 46 4 70 -33 9 -14 23 -25 32 -25 18 0 13 22 -22 88 -13 25 -24
53 -24 63 0 25 32 24 55 -1 23 -26 65 -27 65 -2 0 10 -15 36 -33 58 -45 52
-57 76 -57 113 0 35 33 74 58 69 12 -3 16 -12 15 -32z m877 -22 c0 -34 -47
-79 -99 -95 -36 -11 -44 -10 -51 2 -12 19 7 46 59 87 53 40 91 43 91 6z m855
5 c5 -15 1 -28 -14 -42 -19 -20 -22 -20 -38 -5 -15 16 -15 19 1 42 20 32 42
33 51 5z m-1125 -34 c0 -8 -6 -15 -12 -18 -21 -7 -38 4 -38 23 0 10 6 20 13
23 15 5 37 -11 37 -28z m-145 -12 c27 -41 31 -72 14 -105 -14 -26 -32 -105
-49 -208 -18 -111 -38 -128 -70 -57 -26 55 -25 81 2 99 21 14 21 17 9 72 -13
57 -12 58 13 85 23 24 25 33 20 74 -12 84 17 103 61 40z m593 -7 c2 -10 -9
-32 -25 -50 -41 -43 -73 -91 -73 -109 0 -38 -56 -31 -66 9 -12 49 93 178 141
172 11 -2 21 -11 23 -22z m534 -13 c18 -15 35 -30 38 -34 3 -3 24 -18 48 -33
30 -19 42 -33 42 -50 0 -34 -27 -40 -55 -12 -13 13 -39 29 -58 35 -54 18 -107
87 -85 109 18 18 33 15 70 -15z m302 2 c25 -23 34 -54 20 -68 -9 -9 -46 11
-66 37 -42 51 -4 77 46 31z m701 -8 c7 -35 -8 -87 -25 -87 -17 0 -32 52 -25
87 4 24 11 33 25 33 14 0 21 -9 25 -33z m-2344 -42 c64 -117 66 -151 7 -181
-26 -13 -27 -16 -13 -30 26 -26 19 -80 -15 -116 -34 -35 -34 -34 -19 -77 10
-28 8 -36 -14 -70 -26 -39 -26 -39 -7 -62 25 -28 25 -70 1 -100 -22 -27 -37
-19 -43 21 -2 17 -19 35 -53 57 -82 53 -119 114 -79 130 9 3 31 -2 50 -11 83
-39 103 -6 39 63 -25 26 -45 55 -45 64 0 20 31 30 92 28 53 -1 59 6 26 35 -34
30 -77 104 -71 121 5 13 17 14 66 9 67 -7 67 -15 0 74 -48 63 -39 104 21 98
24 -2 35 -13 57 -53z m995 40 c7 -18 -29 -68 -111 -152 -66 -68 -89 -77 -106
-39 -16 34 18 101 81 160 42 39 55 46 90 46 27 0 43 -5 46 -15z m190 -17 c62
-41 116 -120 112 -162 -2 -16 -9 -31 -16 -34 -21 -7 -214 191 -206 211 10 27
57 20 110 -15z m357 1 c24 -11 47 -47 47 -73 0 -25 -27 -39 -49 -26 -29 15
-55 78 -40 96 14 17 12 17 42 3z m-1139 -75 c-12 -35 -39 -53 -55 -37 -17 17
-2 62 24 72 30 12 43 -2 31 -35z m1826 7 c0 -10 -18 -41 -40 -67 -48 -58 -50
-74 -11 -74 22 0 31 6 41 30 14 33 37 50 69 50 63 0 8 -120 -69 -148 -51 -19
-125 27 -136 84 -12 66 81 178 127 153 11 -5 19 -18 19 -28z m-300 2 c0 -25
-49 -83 -71 -83 -29 0 -24 34 12 69 34 34 59 40 59 14z m-1625 -33 c0 -8 -10
-16 -22 -18 -18 -3 -23 2 -23 18 0 16 5 21 23 18 12 -2 22 -10 22 -18z m815
-25 c30 -15 60 -41 78 -67 17 -24 42 -54 56 -67 32 -30 33 -57 4 -88 -20 -21
-21 -26 -8 -46 36 -57 41 -74 29 -101 -17 -37 -46 -33 -63 9 -10 24 -31 44
-62 63 -26 15 -65 44 -88 64 -38 34 -40 38 -25 54 16 15 22 15 88 -6 39 -13
74 -20 78 -17 11 12 -26 54 -59 66 -112 42 -128 55 -128 100 0 19 32 61 45 61
3 0 28 -11 55 -25z m590 -2 c26 -33 23 -67 -7 -71 -37 -5 -68 59 -41 86 17 17
24 15 48 -15z m-1132 -7 c5 -22 -91 -116 -119 -116 -41 0 -9 76 49 115 41 28
65 29 70 1z m858 -38 c52 -48 76 -88 64 -107 -23 -38 -140 61 -140 119 0 42
21 39 76 -12z m-448 -15 c4 -25 -56 -96 -101 -119 -16 -9 -35 -31 -43 -50 -18
-42 -39 -43 -60 -3 -23 45 -11 78 35 96 22 9 44 26 52 42 25 50 41 62 78 59
28 -2 37 -8 39 -25z m612 7 c24 -24 26 -65 3 -74 -26 -10 -92 61 -78 84 10 17
54 11 75 -10z m356 -63 c-9 -63 -21 -87 -46 -87 -15 0 -20 7 -20 29 0 36 16
80 37 103 25 28 36 10 29 -45z m-1681 32 c0 -8 -10 -15 -22 -17 -25 -4 -30 7
-11 26 15 15 33 10 33 -9z m439 5 c8 -20 -18 -75 -49 -104 -20 -19 -27 -21
-40 -10 -25 21 -16 68 23 113 19 21 58 22 66 1z m1144 -40 c2 -19 -3 -36 -13
-44 -24 -20 -39 1 -31 45 9 46 39 46 44 -1z m-1468 14 c0 -23 -39 -78 -55 -78
-29 0 -29 30 1 61 29 30 54 38 54 17z m1026 -90 c76 -80 92 -118 50 -118 -14
0 -40 17 -69 45 -48 49 -73 95 -63 120 8 21 29 9 82 -47z m329 32 c15 -16 15
-22 5 -35 -7 -8 -16 -15 -21 -15 -15 0 -49 39 -49 55 0 22 44 19 65 -5z m325
0 c0 -34 -42 -149 -61 -166 -54 -49 -52 80 3 159 23 32 58 37 58 7z m-1512
-48 c8 -55 -95 -166 -110 -120 -10 28 16 89 49 119 38 33 56 34 61 1z m1077
-32 c24 -48 -3 -64 -55 -33 -30 18 -42 51 -28 77 8 15 12 15 40 -1 16 -10 36
-29 43 -43z m-1361 41 c7 -10 -3 -41 -13 -41 -17 1 -33 20 -28 34 7 17 32 22
41 7z m747 -54 c20 -23 69 -69 110 -102 144 -115 174 -153 133 -169 -27 -10
-34 -7 -46 20 -7 16 -34 37 -69 54 -72 37 -115 73 -135 116 -20 42 -51 46 -60
7 -4 -16 -17 -37 -30 -48 -12 -11 -31 -36 -42 -55 -11 -20 -28 -36 -40 -38
-18 -3 -22 2 -22 26 0 87 95 224 160 231 3 1 22 -18 41 -42z m1161 9 c9 -48
-57 -173 -85 -163 -34 12 25 187 63 187 11 0 19 -9 22 -24z m-1437 -20 c21
-31 12 -66 -26 -102 -16 -15 -29 -33 -29 -39 0 -6 -6 -20 -14 -30 -14 -19 -15
-19 -35 -1 -25 23 -27 62 -6 114 33 77 81 103 110 58z m1012 -32 c3 -9 -1 -23
-10 -32 -13 -13 -21 -13 -42 -4 -29 13 -33 46 -8 61 20 11 51 -2 60 -25z
m-1669 14 c5 -7 16 -32 22 -55 12 -39 11 -45 -11 -78 -13 -19 -27 -35 -31 -35
-5 0 -8 -5 -8 -11 0 -7 16 -9 50 -4 60 8 74 -9 41 -51 -28 -36 -27 -44 14 -91
40 -47 42 -53 16 -53 -23 0 -115 89 -153 149 -38 60 -37 96 2 116 28 15 30 19
24 53 -3 20 -6 39 -6 42 0 22 4 30 16 30 7 0 18 -6 24 -12z m295 0 c11 -32
-33 -91 -60 -81 -19 8 -16 37 8 67 22 28 45 34 52 14z m1535 -21 c15 -42 15
-112 1 -140 -17 -33 -51 -67 -66 -67 -19 0 -16 24 9 82 21 49 21 52 5 81 -16
27 -16 32 -2 53 21 33 39 30 53 -9z m-1668 -17 c0 -13 -7 -20 -20 -20 -22 0
-28 25 -7 33 22 10 27 7 27 -13z m1129 4 c33 -23 111 -133 111 -155 0 -81
-124 38 -155 149 -8 27 10 29 44 6z m218 -73 c31 -46 31 -81 -1 -81 -32 0 -76
53 -76 91 0 27 3 30 27 27 18 -2 35 -15 50 -37z m665 17 c8 -22 -9 -88 -27
-108 -22 -25 -75 -27 -75 -4 0 25 73 124 91 124 4 0 9 -6 11 -12z m-2645 -60
c29 -32 43 -62 43 -92 0 -30 -20 -34 -42 -8 -15 17 -48 94 -48 112 0 18 26 11
47 -12z m803 -8 c0 -34 -50 -97 -68 -86 -20 12 -13 53 14 85 33 40 54 40 54 1z
m137 14 c3 -9 1 -25 -5 -35 -16 -25 -14 -68 3 -99 8 -14 14 -34 15 -45 0 -20
-43 -75 -59 -75 -5 0 -11 28 -15 63 -3 34 -9 82 -12 107 -5 37 -2 50 16 73 24
30 48 35 57 11z m1164 -35 c7 -14 8 -24 1 -31 -17 -17 -42 -1 -42 27 0 31 25
34 41 4z m649 -1 c0 -13 -14 -41 -30 -62 -19 -25 -30 -51 -30 -72 0 -38 -14
-51 -35 -34 -32 26 -9 97 53 163 31 33 42 34 42 5z m-2123 -22 c-9 -23 -37
-20 -37 3 0 21 20 33 34 20 5 -4 6 -15 3 -23z m511 12 c21 -21 13 -63 -19 -95
-40 -42 -109 -56 -109 -24 0 17 90 131 103 131 7 0 18 -5 25 -12z m235 -10 c7
-22 -20 -63 -73 -111 -53 -48 -80 -52 -80 -12 0 15 21 42 61 79 60 56 83 67
92 44z m431 -59 c25 -28 46 -57 46 -65 0 -27 -37 -13 -81 30 -45 47 -59 72
-52 93 7 19 34 1 87 -58z m-1586 -23 c39 -58 40 -85 6 -117 -14 -13 -35 -41
-45 -61 -19 -37 -51 -63 -63 -51 -3 3 -6 18 -6 33 0 15 -13 46 -29 70 -44 66
-27 88 36 49 53 -33 60 -13 22 63 -19 39 -29 72 -26 83 7 16 9 16 40 -2 17
-10 47 -40 65 -67z m1298 52 c61 -41 68 -75 15 -70 -46 4 -101 46 -101 78 0
34 27 31 86 -8z m879 12 c10 -16 -13 -76 -44 -110 -24 -28 -29 -30 -40 -16
-19 22 -7 83 20 112 23 25 53 31 64 14z m-431 -51 c45 -21 41 -58 -5 -59 -27
0 -63 35 -56 53 7 20 26 21 61 6z m242 -23 c3 -8 -2 -48 -10 -89 -9 -42 -16
-99 -16 -127 0 -56 -9 -62 -39 -23 -27 34 -37 71 -24 92 6 9 18 43 28 76 22
79 47 108 61 71z m302 -9 c9 -45 -96 -183 -121 -158 -15 15 17 90 59 140 37
42 56 48 62 18z m-424 7 c9 -4 16 -18 16 -34 0 -35 -29 -49 -47 -24 -16 22
-16 37 -1 52 14 14 12 14 32 6z m-1678 -75 c35 -49 47 -87 35 -108 -13 -21
-46 2 -86 62 -32 48 -44 94 -28 110 11 11 46 -17 79 -64z m411 44 c4 -10 -2
-23 -16 -35 -26 -20 -41 -16 -41 11 0 36 45 56 57 24z m613 -16 c0 -14 -17
-40 -45 -67 -24 -24 -47 -56 -50 -71 -8 -36 -34 -45 -59 -20 -19 20 -19 20 9
61 34 49 112 120 131 120 8 0 14 -10 14 -23z m-736 -12 c22 -17 16 -59 -11
-69 -21 -8 -43 18 -43 49 0 37 21 45 54 20z m466 2 c0 -33 -108 -147 -139
-147 -31 0 6 67 65 119 47 42 74 52 74 28z m566 -3 c48 -32 100 -153 73 -170
-19 -12 -78 63 -85 108 -8 47 -17 53 -48 33 -19 -13 -29 -14 -45 -5 -12 6 -21
14 -21 18 0 33 83 43 126 16z m-190 -25 c26 -16 34 -28 34 -49 0 -38 -31 -57
-55 -34 -25 25 -45 71 -39 88 8 21 20 20 60 -5z m1081 5 c11 -30 -64 -154 -94
-154 -28 0 5 105 49 155 17 19 37 19 45 -1z m-672 -55 c0 -30 -30 -37 -56 -13
-24 21 -24 40 -1 53 24 14 57 -10 57 -40z m341 27 c6 -15 -43 -86 -58 -86 -17
0 -7 61 12 80 24 24 39 25 46 6z m-1736 -52 c0 -34 -20 -73 -42 -80 -11 -3
-22 -3 -25 0 -7 7 17 96 30 114 14 17 37 -4 37 -34z m-221 -16 c11 -12 26 -37
32 -57 16 -50 25 -59 54 -53 22 4 25 1 25 -22 0 -15 -7 -29 -15 -32 -26 -10
-84 38 -110 90 -39 79 -31 123 14 74z m500 8 c8 -10 6 -19 -10 -40 -25 -32
-49 -34 -49 -4 0 21 26 58 40 58 4 0 13 -6 19 -14z m1199 -13 c3 -22 -22 -48
-38 -38 -14 8 -13 38 2 53 16 16 32 10 36 -15z m-1096 -38 c-7 -15 -20 -25
-32 -25 -26 0 -26 20 1 49 28 30 48 14 31 -24z m263 35 c13 -22 -65 -150 -101
-164 -8 -3 -18 3 -24 14 -28 53 94 200 125 150z m-550 -52 c23 -51 31 -95 55
-293 6 -49 22 -130 35 -180 35 -135 40 -210 21 -286 -19 -74 -64 -175 -100
-221 -13 -18 -33 -62 -45 -98 -103 -323 -128 -386 -163 -413 -25 -18 -124 -57
-147 -57 -21 0 -61 40 -61 61 0 9 7 22 15 29 9 8 15 29 15 55 0 48 11 55 83
55 52 0 106 27 113 56 9 34 -2 44 -52 44 -39 0 -46 3 -55 27 -13 34 -3 223 18
348 38 226 43 264 43 333 0 40 -7 123 -16 183 -9 60 -13 120 -10 133 3 14 44
65 90 115 46 51 91 107 100 126 10 19 21 35 27 35 6 0 21 -24 34 -52z m95 18
c0 -22 -22 -37 -37 -25 -16 14 -17 36 0 42 17 6 37 -3 37 -17z m910 -17 c0
-28 -18 -59 -35 -59 -13 0 -45 36 -45 52 0 5 9 17 20 28 16 16 25 18 40 10 11
-6 20 -20 20 -31z m-147 -11 c34 -31 43 -73 20 -93 -10 -8 -42 -35 -70 -61
-52 -46 -83 -53 -83 -19 0 21 45 65 65 65 14 0 16 9 14 53 -5 78 11 95 54 55z
m842 -34 c0 -32 -3 -39 -20 -39 -24 0 -32 34 -15 65 17 32 35 18 35 -26z
m-965 23 c0 -7 -14 -26 -30 -42 -17 -16 -36 -46 -42 -67 -9 -30 -16 -38 -34
-38 -48 0 -54 61 -10 109 36 41 116 67 116 38z m516 -45 c18 -12 31 -44 45
-114 7 -38 16 -68 19 -68 4 0 11 19 16 42 6 30 15 44 28 46 18 4 19 -3 13
-184 -6 -185 -20 -295 -54 -402 -11 -38 -20 -52 -32 -50 -24 5 -25 78 -2 234
21 136 20 167 -2 129 -14 -23 -20 -45 -48 -180 -30 -145 -29 -204 4 -306 19
-62 29 -119 35 -207 l9 -122 35 -33 c21 -19 38 -46 43 -67 4 -19 18 -47 32
-62 44 -46 -2 -69 -50 -24 -14 13 -39 50 -55 83 -17 33 -38 65 -47 73 -9 7
-25 30 -35 51 -28 53 -90 270 -90 311 0 19 -5 49 -11 67 -9 27 -14 30 -26 19
-23 -19 -12 -147 17 -194 17 -30 22 -63 30 -187 5 -82 12 -152 15 -155 3 -3
21 10 41 29 29 28 37 31 45 19 11 -17 3 -104 -12 -133 -7 -13 -16 -16 -27 -11
-9 4 -48 10 -85 12 -44 2 -70 8 -73 17 -3 7 7 41 21 76 26 61 27 64 15 163 -6
56 -14 103 -17 106 -6 7 -33 -31 -33 -47 0 -29 -18 -10 -23 25 -7 42 3 131 27
242 9 41 21 97 26 123 12 60 13 240 1 233 -4 -3 -11 -21 -14 -39 -9 -58 -48
-138 -65 -135 -56 11 -80 384 -29 436 22 21 43 9 51 -29 9 -44 47 -129 58
-129 6 0 6 45 2 104 l-7 104 39 41 c22 22 64 54 94 71 61 33 60 33 76 22z
m359 -17 c0 -22 -31 -33 -47 -17 -17 17 -1 44 24 40 15 -2 23 -10 23 -23z
m229 -1 c20 -20 20 -20 -5 -56 -29 -40 -63 -36 -67 10 -5 55 37 82 72 46z
m-1511 -45 c37 -10 117 -82 117 -105 0 -6 7 -17 15 -24 12 -10 16 -7 26 16 38
103 111 80 124 -39 12 -110 -17 -463 -40 -485 -15 -15 -33 33 -39 106 -17 200
-55 295 -79 201 -12 -44 -3 -133 28 -288 14 -70 30 -159 36 -199 8 -52 18 -81
36 -102 35 -42 28 -74 -17 -78 -80 -6 -126 -35 -160 -103 -25 -48 -25 -49 6
-49 16 0 37 12 54 30 15 17 34 28 42 25 16 -6 47 -52 81 -120 33 -65 36 -85
15 -99 -25 -15 -83 17 -98 55 -7 16 -18 29 -26 29 -17 0 -34 -32 -34 -64 0
-20 -5 -26 -23 -26 -13 0 -44 -13 -70 -30 -74 -46 -93 -37 -77 36 8 34 6 44
-17 76 -21 31 -23 41 -13 53 7 8 19 15 27 15 25 0 83 65 83 93 0 13 -10 36
-21 51 -31 40 -37 74 -59 361 -11 143 -25 289 -31 325 -17 115 -21 293 -7 311
31 38 61 45 121 28z m-189 -15 c13 -34 18 -209 7 -238 -11 -28 -13 -28 -29
-12 -14 14 -17 36 -16 129 0 62 4 118 8 125 10 17 23 15 30 -4z m-444 -126
c34 -80 38 -318 5 -318 -7 0 -16 8 -20 18 -9 25 -35 238 -35 294 0 62 24 65
50 6z m1127 -89 c33 -23 41 -57 52 -209 11 -135 27 -231 56 -325 19 -60 21
-187 4 -310 -7 -54 -9 -128 -5 -183 l6 -93 -34 -14 c-41 -18 -130 -20 -174 -4
-22 8 -35 22 -47 52 -46 114 -65 373 -41 552 10 77 16 194 16 332 0 174 3 213
14 220 22 12 128 0 153 -18z m-623 -451 c22 -266 27 -349 22 -365 -13 -42
-137 -84 -149 -50 -2 6 4 23 13 37 22 31 36 99 55 263 18 154 23 177 40 177 9
0 15 -20 19 -62z m1190 -111 c38 -57 62 -277 34 -305 -19 -19 -52 -14 -66 9
-18 28 -31 179 -23 244 10 78 28 94 55 52z m-1108 -306 c-4 -6 -16 -11 -27
-11 -21 0 -27 35 -6 43 16 6 41 -18 33 -32z m-111 -102 c-6 -39 -4 -54 9 -68
15 -16 15 -21 1 -47 -8 -16 -15 -37 -15 -46 0 -10 -7 -18 -15 -18 -11 0 -15
11 -15 40 0 37 -1 39 -20 28 -30 -19 -48 3 -28 34 8 13 18 37 22 53 8 31 42
75 59 75 5 0 6 -21 2 -51z m1288 13 c24 -26 47 -86 38 -100 -8 -13 -33 10 -64
60 -31 50 -11 81 26 40z m551 -3 c21 -17 30 -18 41 -9 12 10 23 5 59 -25 52
-42 76 -76 76 -105 0 -35 -40 -20 -77 28 l-33 44 -18 -31 c-9 -17 -22 -31 -29
-31 -17 0 -70 68 -76 98 -10 54 12 66 57 31z m-2424 -46 c0 -37 -28 -83 -50
-83 -16 0 -20 7 -20 31 0 31 15 69 33 81 18 11 37 -3 37 -29z m476 15 c8 -13
-49 -68 -71 -68 -29 0 -30 32 -1 57 29 25 61 30 72 11z m1582 -80 c2 -8 -2
-27 -8 -42 -9 -23 -13 -26 -25 -16 -18 15 -18 20 -5 55 10 28 33 30 38 3z
m-2128 -72 c16 -20 9 -60 -13 -64 -11 -2 -20 5 -26 19 -17 46 11 78 39 45z
m-120 -75 c0 -5 -6 -14 -14 -20 -16 -13 -32 2 -21 19 8 12 35 13 35 1z m1474
-26 c6 -17 37 -20 52 -5 13 13 74 1 74 -15 0 -23 28 -39 44 -26 11 10 21 9 46
-4 17 -9 28 -20 25 -25 -11 -17 8 -40 34 -40 38 -1 61 -22 61 -57 0 -25 5 -32
28 -36 32 -7 54 -46 39 -72 -7 -13 -2 -23 22 -45 35 -33 35 -32 19 -78 -11
-29 -10 -35 5 -43 21 -12 23 -62 2 -79 -17 -14 -20 -50 -5 -50 14 0 12 -47 -2
-60 -7 -6 -21 -17 -32 -24 -15 -10 -17 -16 -7 -34 15 -27 -9 -69 -42 -74 -16
-2 -23 -11 -25 -33 -4 -35 -29 -66 -51 -64 -33 3 -41 -2 -41 -24 0 -27 -47
-51 -84 -41 -21 5 -27 2 -35 -19 -12 -30 -53 -40 -82 -19 -20 14 -24 14 -40
-6 -22 -28 -69 -29 -92 -2 -15 18 -20 19 -37 7 -28 -19 -69 -9 -82 19 -10 21
-16 23 -45 18 -38 -7 -73 16 -73 48 0 13 -7 18 -28 18 -28 0 -78 40 -66 52 12
12 -8 48 -26 48 -26 0 -48 40 -43 76 4 25 1 32 -16 37 -26 6 -37 51 -21 83 11
20 11 27 -2 41 -24 27 -25 58 -3 82 16 19 17 25 6 42 -17 28 -5 76 21 82 17 5
20 11 15 39 -6 39 8 63 46 77 20 8 27 18 27 36 0 31 34 67 55 59 19 -8 45 13
45 35 0 24 35 42 69 35 23 -5 32 -2 40 14 12 23 59 38 74 23 16 -16 44 -13 51
4 3 9 18 15 40 15 21 0 36 -6 40 -15z m-1306 -99 c43 -23 121 -124 127 -164
11 -73 26 -108 64 -151 l39 -44 44 16 c53 20 102 22 120 4 7 -8 22 -18 33 -24
26 -14 131 -4 145 12 6 8 11 46 12 87 0 40 5 84 10 98 25 68 218 219 218 171
0 -5 -30 -49 -66 -98 -90 -120 -107 -156 -101 -210 4 -40 2 -45 -32 -71 -20
-16 -51 -37 -69 -48 -38 -22 -47 -48 -38 -106 8 -50 32 -82 89 -118 85 -53
155 -161 127 -195 -19 -22 -47 -1 -54 40 -5 23 -21 49 -44 72 -42 40 -140 90
-192 99 -82 12 -118 26 -131 50 -16 30 -26 30 -55 -1 -38 -41 -78 -34 -136 25
-27 28 -51 50 -53 50 -16 0 -35 -15 -35 -29 -1 -27 -22 -56 -75 -101 -57 -48
-74 -79 -75 -133 0 -31 -3 -38 -17 -35 -21 4 -34 58 -24 96 4 15 26 41 49 57
50 36 58 61 42 120 -12 42 -11 46 14 75 31 38 33 61 6 85 -24 22 -25 46 -5 86
32 61 16 159 -26 159 -27 0 -35 -27 -15 -49 9 -10 16 -23 16 -30 0 -23 -31
-71 -45 -71 -21 0 -28 38 -23 127 3 65 9 85 33 121 25 36 33 42 64 42 20 -1
47 -7 59 -14z m1885 -7 c16 -7 27 -25 35 -54 17 -64 67 -82 93 -34 6 12 21 19
39 19 19 0 34 7 42 20 7 11 17 20 22 20 30 0 110 -141 84 -149 -7 -2 -34 -26
-60 -54 -65 -68 -74 -105 -52 -215 3 -17 10 -22 27 -20 20 3 22 9 24 68 5 120
7 126 75 170 94 61 138 52 138 -25 0 -35 -4 -44 -26 -54 -21 -9 -25 -16 -19
-34 13 -40 2 -94 -35 -187 -13 -30 -25 -80 -29 -110 -3 -30 -14 -66 -24 -80
-9 -13 -17 -39 -17 -58 0 -44 -16 -72 -40 -72 -23 0 -24 3 -9 88 16 93 3 132
-57 162 -57 30 -96 74 -90 103 3 12 7 34 10 49 4 19 -1 35 -19 56 -29 34 -31
54 -10 72 18 15 20 91 3 108 -7 7 -26 12 -42 12 -43 0 -51 8 -70 65 -9 28 -30
68 -46 89 -16 21 -27 43 -24 47 7 12 48 11 77 -2z m866 -219 c17 -98 20 -234
6 -344 -13 -105 -23 -135 -43 -122 -43 27 -52 416 -12 520 9 26 13 28 25 15 8
-8 19 -39 24 -69z m-872 -19 c13 -6 35 -11 48 -11 29 0 30 -5 10 -40 -20 -35
-18 -83 5 -112 15 -20 19 -39 17 -91 -2 -57 1 -69 23 -93 l25 -27 -26 7 c-16
3 -38 0 -55 -9 -16 -8 -42 -15 -57 -15 -15 0 -39 -8 -54 -17 l-25 -17 7 35 c4
18 14 57 23 84 19 55 23 231 9 320 l-8 50 17 -27 c10 -14 28 -31 41 -37z
m-1347 -31 l0 -40 -45 0 c-32 0 -45 4 -45 14 0 8 -6 16 -12 19 -7 2 4 7 25 11
22 4 37 13 37 21 0 8 9 15 20 15 17 0 20 -7 20 -40z m1976 -126 c18 -9 38 -22
45 -30 32 -39 27 -140 -10 -181 -46 -53 -93 -136 -105 -188 -10 -38 -24 -55
-46 -55 -35 0 -28 29 31 131 32 57 59 112 59 121 0 9 5 20 10 23 14 9 12 42
-2 48 -7 2 -31 -15 -54 -39 -35 -38 -46 -44 -81 -44 -22 0 -42 2 -45 5 -3 3 5
35 18 71 13 36 24 81 24 100 0 27 5 37 23 44 35 14 96 11 133 -6z m-1817 -73
c7 -43 20 -102 31 -130 16 -45 17 -53 4 -58 -9 -3 -27 5 -42 19 -15 14 -43 35
-63 47 -47 28 -56 38 -64 82 -7 36 -5 38 51 78 31 22 61 41 64 41 4 0 13 -35
19 -79z m-841 -142 c-55 -47 -88 -96 -88 -132 0 -31 -14 -42 -29 -23 -26 31
21 126 95 195 47 43 48 44 58 22 8 -20 4 -27 -36 -62z m2252 -52 c0 -26 -42
-138 -65 -174 -11 -15 -25 -23 -45 -23 -39 0 -38 14 4 60 28 31 54 84 72 148
6 22 34 13 34 -11z m450 -57 c6 -11 10 -44 8 -73 -2 -40 -7 -53 -20 -55 -16
-3 -18 6 -18 72 0 76 10 94 30 56z m-1955 -31 c12 -18 -5 -79 -21 -79 -10 0
-13 8 -10 28 2 15 5 35 5 45 1 19 16 23 26 6z m-365 -209 c0 -7 7 -7 20 0 45
24 81 -20 46 -56 -18 -17 -40 -10 -54 19 l-11 22 0 -23 c-1 -19 -6 -23 -28
-20 -50 6 -41 67 10 68 9 0 17 -5 17 -10z m149 1 c9 -6 21 -5 33 2 14 9 23 9
37 0 10 -7 22 -9 26 -5 10 11 269 10 276 0 4 -6 14 -5 26 3 13 7 28 9 39 4 11
-5 24 -7 29 -5 26 8 121 7 133 -2 8 -7 16 -7 25 -1 15 12 115 14 123 2 3 -5
14 -5 25 1 13 8 25 7 39 0 40 -21 12 -68 -31 -52 -15 6 -33 5 -46 -1 -16 -7
-27 -6 -37 2 -10 8 -16 9 -21 1 -10 -16 -43 -12 -53 7 -9 16 -11 16 -27 0 -19
-20 -36 -22 -52 -6 -8 8 -16 7 -30 -2 -14 -8 -25 -9 -38 -1 -13 7 -24 6 -37
-1 -14 -9 -23 -9 -38 1 -12 7 -26 9 -33 5 -7 -4 -36 -8 -66 -8 -45 -1 -55 2
-59 17 -4 18 -5 18 -19 -2 -11 -16 -22 -20 -39 -16 -12 3 -34 1 -49 -6 -21
-10 -27 -9 -39 7 -9 12 -15 15 -20 7 -10 -16 -53 -15 -59 1 -3 9 -9 8 -23 -5
-14 -12 -24 -14 -37 -7 -17 10 -24 49 -10 62 9 10 35 8 52 -2z m1059 -20 c5
-32 -23 -54 -49 -40 -21 11 -26 55 -6 63 27 11 52 1 55 -23z m-80 -9 c-4 -32
-45 -44 -60 -17 -9 15 -11 15 -22 -1 -16 -21 -56 -10 -56 15 0 26 4 27 76 29
65 1 65 0 62 -26z"/>
<path d="M5427 9084 c-10 -13 -17 -32 -15 -41 4 -19 55 -59 64 -51 10 11 -8
118 -20 118 -6 0 -19 -12 -29 -26z"/>
<path d="M4543 8862 c-15 -10 -53 -79 -53 -97 0 -31 19 -13 46 40 26 55 29 70
7 57z"/>
<path d="M4938 8543 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4968 8523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3980 8031 c0 -21 95 -91 124 -91 24 0 29 20 11 40 -33 36 -135 74
-135 51z"/>
<path d="M4269 6120 c-4 -8 -12 -44 -18 -80 -6 -36 -23 -113 -38 -172 -27
-104 -26 -142 3 -104 30 42 68 231 62 316 -2 30 -6 48 -9 40z"/>
<path d="M4591 5334 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5194 4982 c-5 -9 -21 -50 -34 -89 -14 -40 -28 -73 -32 -73 -5 0 -8
33 -8 73 0 92 -15 120 -24 46 -18 -147 -15 -145 -50 -39 -24 72 -32 88 -34 67
-3 -25 2 -56 24 -157 4 -14 -19 12 -51 60 -68 100 -64 95 -69 89 -3 -3 15 -47
40 -99 37 -78 41 -91 22 -74 -13 11 -46 40 -74 64 -131 116 -136 112 -24 -17
44 -51 80 -94 80 -96 0 -2 -39 16 -86 40 -47 24 -91 42 -97 40 -7 -2 26 -29
73 -60 90 -60 87 -68 -13 -42 -32 8 -68 15 -80 15 -18 -1 -16 -4 13 -19 19
-10 56 -24 83 -32 26 -7 47 -16 47 -20 0 -4 -39 -10 -87 -14 -144 -12 -150
-21 -21 -30 65 -4 116 -11 113 -15 -2 -4 -43 -19 -90 -35 -47 -15 -85 -31 -85
-36 0 -11 -9 -12 100 11 109 23 100 22 100 12 0 -5 -12 -15 -27 -23 -36 -17
-114 -69 -131 -86 -25 -24 7 -14 98 32 50 25 90 42 90 37 0 -4 -18 -26 -41
-47 -45 -44 -115 -140 -83 -115 10 9 52 44 92 79 39 34 72 61 72 58 0 -2 -20
-43 -45 -90 -25 -48 -45 -93 -45 -100 0 -15 36 29 87 105 21 31 38 56 40 55 1
-1 -5 -29 -13 -62 -7 -33 -13 -76 -13 -95 0 -34 1 -34 11 -10 6 14 21 54 33
90 l22 65 7 -55 c16 -125 19 -140 27 -134 5 3 9 45 9 95 0 49 4 89 8 89 5 0
14 -19 21 -42 12 -44 18 -58 45 -118 20 -45 20 -18 0 70 -24 108 -15 115 38
29 44 -70 78 -112 78 -95 0 4 -20 48 -45 98 -25 50 -44 93 -41 95 3 3 29 -17
58 -43 88 -80 98 -88 98 -77 0 10 -11 24 -73 93 -75 84 -76 84 28 31 55 -28
104 -51 109 -51 14 0 -29 34 -101 81 -66 44 -70 59 -11 40 18 -5 58 -13 88
-16 51 -6 54 -6 35 9 -11 8 -30 16 -42 16 -12 0 -25 5 -28 10 -3 6 -14 10 -24
10 -10 0 -31 6 -45 14 -25 13 -20 14 71 21 108 8 105 19 -9 30 -38 4 -68 10
-68 15 0 4 21 13 48 20 53 13 126 49 100 50 -9 0 -47 -7 -84 -15 -36 -9 -73
-15 -81 -13 -12 2 87 75 171 125 14 9 16 13 6 13 -8 -1 -63 -25 -122 -54 -60
-29 -108 -51 -108 -49 0 3 22 28 49 56 63 66 95 107 84 107 -9 0 -14 -4 -97
-78 -26 -24 -50 -41 -53 -38 -3 3 11 37 31 76 52 98 40 110 -20 21 -29 -44
-50 -68 -52 -58 -2 8 4 50 13 92 16 81 16 96 -1 67z m-30 -285 c44 -41 47 -76
11 -123 -36 -47 -84 -55 -129 -21 -25 19 -35 36 -40 66 -5 36 -3 45 21 70 27
27 48 37 84 40 9 0 33 -14 53 -32z"/>
<path d="M5070 4655 c-26 -32 13 -81 48 -59 34 22 22 74 -18 74 -10 0 -23 -7
-30 -15z"/>
<path d="M3743 4973 c-22 -8 -14 -31 21 -60 18 -15 38 -44 44 -63 6 -19 21
-66 33 -103 34 -110 91 -158 145 -122 29 19 31 41 5 54 -10 6 -46 67 -81 137
-72 145 -110 181 -167 157z"/>
<path d="M5885 4908 c-62 -36 -40 -97 36 -98 46 0 69 23 46 48 -8 9 -17 26
-19 37 -4 23 -34 30 -63 13z"/>
<path d="M7510 8224 c0 -8 51 -66 140 -159 60 -63 199 -232 243 -295 10 -14
61 -90 114 -170 121 -181 154 -223 267 -334 96 -94 317 -276 335 -276 18 0 12
20 -34 125 -8 17 -24 62 -36 100 -12 39 -25 79 -29 90 -4 11 -23 67 -40 125
-29 92 -93 246 -109 260 -4 3 -20 23 -36 45 -46 62 -111 120 -206 185 -48 33
-90 60 -92 60 -3 0 -49 25 -102 56 -54 31 -114 63 -134 71 -52 22 -115 50
-198 89 -76 36 -83 38 -83 28z"/>
<path d="M2580 8180 c0 -5 -6 -10 -13 -10 -8 0 -27 -6 -43 -14 -16 -8 -47 -22
-69 -31 -80 -36 -281 -135 -325 -161 -25 -15 -61 -36 -80 -46 -76 -43 -195
-136 -250 -196 -39 -42 -75 -96 -109 -166 -28 -57 -51 -107 -51 -112 0 -22
-105 -345 -131 -401 -10 -24 -19 -47 -19 -53 0 -6 -5 -19 -12 -30 -24 -39 2
-30 69 21 264 206 371 318 534 561 67 100 148 215 178 253 31 39 62 79 71 89
15 20 104 119 194 216 70 75 81 90 67 90 -6 0 -11 -4 -11 -10z"/>
<path d="M8470 7724 c0 -9 74 -119 82 -122 5 -2 8 -9 8 -16 0 -7 8 -21 18 -32
9 -10 27 -37 38 -59 12 -22 35 -65 52 -96 34 -62 93 -210 111 -279 7 -25 17
-59 22 -76 5 -17 9 -43 9 -59 0 -15 5 -57 10 -94 6 -36 15 -95 21 -131 13 -84
29 -154 46 -208 15 -49 17 -106 4 -98 -8 5 -48 72 -89 146 -7 14 -32 50 -55
80 -23 30 -49 64 -57 75 -8 11 -55 61 -105 110 -64 64 -138 123 -255 202 -160
108 -212 154 -348 310 -24 27 -32 32 -27 17 3 -12 9 -50 14 -85 19 -135 63
-265 129 -382 34 -61 193 -227 217 -227 7 0 15 -4 17 -8 2 -5 37 -30 78 -57
287 -185 367 -248 450 -354 33 -42 65 -81 70 -88 19 -23 110 -210 110 -225 0
-9 6 -18 14 -21 22 -9 31 172 30 593 0 337 -2 373 -22 470 -25 115 -31 135
-75 230 -78 168 -191 285 -404 424 -101 65 -113 72 -113 60z"/>
<path d="M1525 7623 c-280 -183 -407 -341 -477 -593 -20 -71 -29 -139 -38
-278 -5 -79 -11 -146 -14 -150 -6 -10 22 -636 30 -679 6 -33 24 -46 24 -18 0
8 4 23 9 33 157 320 217 382 613 637 95 61 146 102 219 174 70 71 93 102 128
176 24 50 47 99 51 110 19 54 30 93 30 113 0 11 4 23 9 26 4 3 11 31 14 63 4
32 10 70 13 86 10 42 -7 32 -56 -34 -71 -95 -143 -156 -327 -281 -162 -111
-285 -215 -335 -284 -13 -17 -41 -56 -64 -85 -22 -30 -53 -76 -67 -102 -15
-27 -43 -71 -62 -100 -44 -65 -45 -48 -4 116 25 101 33 152 59 347 29 223 114
427 279 674 26 39 51 77 55 84 14 24 -19 11 -89 -35z"/>
<path d="M9179 7043 c22 -45 30 -67 61 -178 32 -116 23 -367 -31 -850 -8 -75
-20 -436 -19 -592 0 -7 -4 -13 -10 -13 -5 0 -10 10 -10 22 0 12 -4 35 -9 52
-5 17 -15 51 -21 76 -13 49 -15 54 -55 153 -25 61 -82 157 -117 197 -25 29
-137 120 -146 120 -6 0 -12 3 -14 8 -1 4 -34 29 -72 56 -146 104 -248 220
-327 375 -33 64 -49 75 -30 20 6 -18 11 -50 11 -72 0 -22 4 -54 9 -71 5 -17
15 -56 22 -86 32 -146 104 -317 183 -435 22 -33 92 -125 155 -204 64 -79 134
-170 156 -202 22 -31 58 -83 80 -116 83 -118 168 -330 206 -510 13 -65 29 -84
29 -36 0 15 4 40 10 57 5 17 18 69 29 116 36 155 91 384 101 423 6 20 15 56
21 80 6 23 15 56 20 73 5 17 9 39 9 49 0 10 4 32 9 49 5 17 14 56 21 86 6 30
15 71 20 90 5 19 19 111 31 204 41 318 10 567 -93 760 -33 62 -56 99 -99 161
-69 100 -160 196 -130 138z"/>
<path d="M874 6968 c-59 -64 -161 -213 -191 -278 -55 -123 -82 -210 -103 -335
-13 -77 -8 -295 9 -410 6 -38 13 -95 17 -125 3 -30 9 -64 14 -75 5 -11 11 -33
14 -50 5 -28 12 -63 35 -167 6 -24 13 -56 16 -73 3 -16 10 -47 15 -67 6 -21
14 -55 20 -75 14 -57 67 -279 85 -358 18 -81 33 -138 46 -181 5 -17 9 -40 9
-52 0 -12 4 -22 9 -22 10 0 22 32 45 120 39 147 45 164 114 310 25 53 166 272
194 301 11 12 80 95 115 139 83 104 133 171 133 179 0 4 7 14 15 21 8 7 21 24
28 39 8 14 26 47 40 72 51 90 112 268 136 399 5 30 14 79 20 108 14 66 14 82
1 82 -5 0 -10 -9 -10 -20 0 -11 -4 -20 -10 -20 -5 0 -10 -6 -10 -13 0 -7 -23
-50 -51 -95 -70 -114 -156 -199 -323 -317 -79 -56 -178 -140 -198 -168 -12
-18 -25 -34 -28 -37 -24 -23 -87 -161 -115 -256 -14 -49 -30 -100 -35 -114 -5
-14 -12 -43 -17 -65 -5 -27 -10 54 -14 245 -4 157 -12 332 -18 390 -45 406
-55 567 -42 681 16 142 23 183 30 194 4 6 14 30 21 55 7 25 18 51 23 58 20 26
-10 11 -39 -20z"/>
<path d="M5967 5894 c-20 -20 6 -277 32 -319 10 -17 11 -13 6 25 -3 25 -8 97
-11 160 -7 113 -13 147 -27 134z"/>
<path d="M9646 5698 c-3 -24 -10 -65 -16 -93 -6 -27 -15 -70 -20 -95 -8 -42
-17 -78 -40 -170 -5 -19 -15 -62 -24 -96 -19 -76 -63 -213 -76 -236 -6 -10
-10 -22 -10 -28 0 -6 -9 -32 -21 -58 -35 -79 -58 -130 -78 -174 -47 -102 -71
-159 -71 -167 0 -5 -9 -30 -19 -57 -10 -27 -24 -69 -31 -94 -7 -25 -16 -54
-20 -65 -5 -11 -11 -33 -15 -50 -3 -16 -11 -50 -16 -75 -6 -25 -13 -60 -16
-78 -3 -18 -10 -31 -16 -29 -8 2 -12 44 -13 118 -5 319 -68 510 -339 1029 -22
41 -42 77 -45 80 -3 3 -12 19 -20 35 -8 17 -27 49 -42 72 -15 23 -28 45 -28
50 0 4 -8 17 -16 28 -15 19 -16 15 -10 -50 24 -268 38 -398 47 -450 5 -33 14
-87 19 -120 11 -68 24 -129 40 -195 6 -25 14 -60 17 -78 3 -18 9 -35 14 -38 5
-3 9 -14 9 -26 0 -11 17 -72 39 -137 82 -247 97 -315 112 -501 13 -158 13
-176 4 -339 -6 -91 -5 -132 2 -130 18 7 235 256 311 357 39 53 92 122 116 152
149 189 293 482 350 712 72 290 70 554 -9 883 -13 55 -27 112 -30 128 -8 42
-32 33 -39 -15z"/>
<path d="M416 5683 c-17 -70 -25 -102 -40 -155 -38 -138 -51 -259 -50 -478 0
-185 3 -229 22 -315 13 -55 25 -111 28 -125 3 -14 9 -34 14 -45 4 -11 13 -36
20 -55 39 -114 127 -297 186 -387 19 -28 34 -54 34 -58 0 -5 29 -48 57 -84 7
-9 26 -34 42 -56 119 -162 314 -396 419 -504 12 -13 13 -4 6 60 -13 129 -7
457 11 561 9 51 20 104 25 118 4 14 18 59 30 100 13 41 34 109 48 150 24 74
35 113 52 180 5 19 14 55 20 80 18 68 19 75 44 225 33 189 66 479 66 564 0 50
-6 48 -42 -19 -14 -25 -34 -61 -46 -80 -56 -97 -79 -139 -163 -310 -86 -174
-121 -248 -139 -295 -79 -203 -100 -308 -109 -545 -4 -107 -9 -176 -9 -153 -1
23 -6 44 -10 47 -5 3 -14 31 -21 63 -19 87 -32 136 -42 155 -5 10 -9 29 -9 42
0 14 -4 28 -10 31 -5 3 -10 14 -10 24 0 15 -48 143 -85 226 -8 17 -23 51 -34
78 -42 96 -63 143 -77 171 -8 16 -14 35 -14 43 0 7 -4 21 -10 31 -12 22 -60
166 -70 212 -5 19 -17 69 -28 110 -18 71 -31 127 -54 245 -5 28 -14 64 -19 81
-5 17 -9 45 -9 62 0 39 -15 42 -24 5z"/>
<path d="M8643 4372 c-28 -60 -53 -136 -64 -189 -5 -24 -14 -64 -20 -90 -18
-81 -37 -251 -59 -538 -30 -372 -55 -591 -80 -680 -4 -16 -13 -57 -20 -90 -11
-58 -20 -96 -42 -180 -17 -64 -30 -109 -39 -130 -22 -55 -48 -139 -45 -143 4
-3 36 13 89 46 78 49 319 208 367 243 87 63 92 67 155 113 33 24 72 55 86 68
15 12 64 54 110 93 129 107 269 252 328 339 8 12 18 23 22 26 15 11 117 179
148 245 31 64 38 81 62 140 4 11 13 38 20 60 6 22 16 51 20 65 21 60 50 276
55 405 7 162 17 169 -101 -61 -88 -170 -214 -362 -306 -465 -37 -41 -114 -116
-171 -165 -126 -109 -220 -204 -261 -264 -17 -25 -46 -64 -64 -88 -18 -23 -40
-59 -50 -80 -9 -20 -29 -55 -43 -77 -15 -22 -30 -47 -33 -55 -9 -20 2 67 13
105 35 123 48 191 61 322 7 73 9 159 5 210 -18 227 -27 302 -55 473 -5 30 -14
84 -19 120 -12 73 -42 251 -45 262 -1 3 -12 -15 -24 -40z"/>
<path d="M1416 4317 c-24 -155 -58 -372 -65 -417 -25 -142 -48 -421 -43 -495
16 -212 27 -283 61 -425 11 -47 21 -92 21 -100 0 -17 -34 32 -79 114 -17 32
-46 78 -64 103 -17 25 -42 59 -54 77 -32 46 -149 165 -223 227 -242 202 -390
403 -567 771 -16 32 -32 58 -36 58 -5 0 -6 -69 -4 -152 4 -142 11 -200 36
-303 6 -22 13 -53 16 -70 6 -30 19 -63 36 -97 5 -10 9 -23 9 -29 0 -46 169
-337 258 -442 35 -42 249 -256 287 -287 17 -14 59 -50 95 -81 67 -58 424 -317
470 -341 14 -7 72 -44 130 -81 82 -53 106 -64 108 -52 3 15 -17 91 -37 140
-17 41 -31 92 -31 113 0 11 -4 23 -9 26 -5 3 -11 23 -14 43 -3 21 -10 59 -16
86 -26 115 -31 137 -44 217 -28 160 -37 235 -52 425 -39 487 -51 608 -75 720
-6 28 -13 64 -16 80 -3 17 -9 39 -14 50 -5 11 -19 48 -32 82 -26 73 -45 87
-52 40z"/>
<path d="M5201 3868 c-1 -10 -7 -18 -13 -19 -7 0 -4 -4 7 -8 26 -11 27 -9 16
20 -7 17 -10 19 -10 7z"/>
<path d="M4420 3851 c0 -10 36 -18 78 -19 19 -1 21 2 12 13 -9 11 -17 12 -31
4 -13 -6 -25 -6 -39 1 -12 6 -20 7 -20 1z"/>
<path d="M4545 3845 c-19 -14 -18 -14 13 -15 22 0 32 4 30 12 -6 18 -22 20
-43 3z"/>
<path d="M4614 3845 c-17 -13 -12 -14 49 -15 37 0 67 4 67 9 0 18 -21 23 -37
9 -9 -7 -19 -11 -22 -10 -32 19 -40 20 -57 7z"/>
<path d="M4760 3845 c-11 -13 -7 -15 26 -15 35 0 37 1 18 15 -24 18 -29 18
-44 0z"/>
<path d="M4895 3846 c-17 -13 -17 -13 8 -13 15 0 27 3 27 8 0 16 -17 18 -35 5z"/>
<path d="M4963 3853 c-13 -2 -23 -8 -23 -13 0 -13 53 -6 58 8 2 6 0 11 -5 11
-4 -1 -18 -4 -30 -6z"/>
<path d="M5045 3850 c-8 -13 9 -20 24 -11 10 6 9 10 -3 14 -8 3 -18 2 -21 -3z"/>
<path d="M5117 3853 c-13 -13 -7 -21 13 -16 11 3 17 9 14 14 -6 11 -18 12 -27
2z"/>
<path d="M5265 3850 c-4 -6 3 -10 14 -10 25 0 27 6 6 13 -8 4 -17 2 -20 -3z"/>
<path d="M5395 3850 c-18 -8 -17 -9 8 -9 16 -1 26 3 22 9 -3 6 -7 10 -8 9 -1
0 -11 -4 -22 -9z"/>
<path d="M5600 3850 c-12 -8 -9 -10 13 -10 16 0 26 4 22 10 -8 12 -16 12 -35
0z"/>
<path d="M5675 3850 c-4 -6 3 -10 14 -10 25 0 27 6 6 13 -8 4 -17 2 -20 -3z"/>
<path d="M4838 3838 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M5338 3843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5468 3843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5533 3843 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5743 3843 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5818 3843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2147 3503 c-4 -3 -7 -75 -7 -160 0 -146 1 -153 20 -153 18 0 20 7
20 75 l0 75 40 0 c46 0 62 -13 113 -93 39 -61 54 -74 68 -60 7 7 -7 35 -36 79
-42 62 -45 70 -29 79 37 21 54 46 54 78 -1 63 -45 87 -159 87 -43 0 -81 -3
-84 -7z m195 -50 c24 -21 23 -38 -4 -63 -17 -16 -35 -20 -90 -20 l-68 0 0 50
0 50 72 0 c52 0 76 -4 90 -17z"/>
<path d="M3847 3504 c-4 -4 -7 -76 -7 -161 0 -146 1 -153 20 -153 18 0 20 7
20 83 0 69 3 87 18 100 22 20 77 22 89 5 4 -7 8 -50 9 -95 1 -44 5 -86 9 -92
16 -27 25 7 25 95 0 90 -1 96 -26 119 -27 25 -78 34 -89 15 -3 -5 -12 -10 -20
-10 -9 0 -15 15 -17 47 -3 44 -15 62 -31 47z"/>
<path d="M4896 3503 c-3 -4 -6 -26 -6 -50 0 -31 -4 -43 -14 -43 -8 0 -18 5
-21 10 -3 6 -18 10 -32 10 -66 0 -118 -88 -94 -158 17 -48 30 -62 66 -78 25
-10 35 -10 59 3 26 14 30 13 38 -1 5 -8 14 -13 21 -10 18 7 21 310 3 317 -8 3
-17 3 -20 0z m-23 -131 c22 -19 23 -99 1 -130 -20 -29 -67 -29 -94 0 -31 33
-28 110 5 136 27 22 58 20 88 -6z"/>
<path d="M5213 3503 c-7 -2 -13 -11 -13 -19 0 -8 -4 -22 -9 -32 -19 -36 -51
-112 -51 -122 0 -6 -4 -18 -9 -28 -18 -34 -41 -90 -41 -101 0 -27 28 -7 46 32
l19 42 78 3 c60 2 77 0 77 -11 0 -29 32 -77 50 -77 21 0 20 7 -17 90 -8 19
-19 44 -23 55 -43 108 -74 168 -87 171 -4 1 -14 0 -20 -3z m46 -109 c11 -31
21 -60 21 -65 0 -5 -22 -9 -50 -9 -55 0 -59 6 -36 61 7 19 16 42 19 52 11 30
23 19 46 -39z"/>
<path d="M5946 3503 c-3 -3 -6 -75 -6 -160 0 -129 2 -153 15 -153 13 0 15 24
15 154 0 141 -5 177 -24 159z"/>
<path d="M6507 3503 c-13 -12 -7 -33 8 -33 8 0 15 9 15 20 0 20 -11 26 -23 13z"/>
<path d="M6940 3498 c1 -33 80 -302 92 -309 28 -18 36 -2 79 169 12 50 27 92
31 92 12 0 23 -31 54 -154 15 -58 34 -108 41 -110 14 -6 28 20 43 79 5 17 18
62 30 100 33 107 39 135 29 141 -5 3 -14 -7 -20 -23 -12 -30 -48 -161 -56
-205 -9 -49 -27 -32 -43 42 -37 163 -56 204 -87 184 -15 -9 -43 -92 -60 -174
-7 -33 -26 -80 -34 -80 -3 0 -15 42 -28 93 -36 147 -43 167 -58 167 -7 0 -13
-6 -13 -12z"/>
<path d="M7395 3500 c-10 -17 12 -37 25 -24 8 8 6 15 -4 23 -10 9 -16 9 -21 1z"/>
<path d="M2494 3395 c-35 -34 -36 -36 -32 -94 5 -69 23 -96 76 -111 41 -11 94
7 111 39 16 29 -2 34 -33 10 -35 -28 -83 -22 -109 13 -11 14 -16 31 -13 37 4
7 41 11 92 11 46 0 84 3 84 8 0 33 -25 84 -49 102 -44 30 -83 26 -127 -15z
m100 -6 c24 -11 42 -37 32 -46 -6 -6 -108 -10 -123 -5 -18 6 2 41 30 52 30 12
32 12 61 -1z"/>
<path d="M2749 3417 c-23 -18 -40 -53 -33 -71 8 -20 39 -39 92 -56 49 -16 56
-22 47 -50 -9 -29 -71 -27 -102 2 -15 14 -27 18 -33 12 -15 -15 15 -51 55 -64
30 -10 41 -9 73 7 81 40 61 104 -43 137 -59 18 -71 39 -32 57 25 11 77 -3 77
-21 0 -5 7 -10 15 -10 22 0 18 26 -7 50 -25 23 -83 27 -109 7z"/>
<path d="M2994 3411 c-59 -36 -68 -148 -15 -195 29 -26 74 -38 103 -27 27 10
68 48 68 63 0 15 -27 8 -45 -12 -36 -40 -125 -10 -125 43 0 15 12 17 85 17
l85 0 0 34 c0 25 -8 43 -31 65 -36 37 -78 41 -125 12z m112 -42 c9 -11 15 -22
12 -25 -9 -8 -127 -8 -136 0 -4 4 5 18 19 30 31 27 78 25 105 -5z"/>
<path d="M3243 3410 c-34 -20 -44 -50 -18 -50 7 0 18 6 22 14 20 36 113 20
113 -19 0 -18 -31 -35 -63 -35 -74 0 -122 -51 -92 -97 26 -39 60 -46 110 -24
36 16 44 17 49 5 3 -8 12 -14 21 -14 12 0 15 13 15 68 -1 85 -13 140 -37 157
-26 21 -83 18 -120 -5z m114 -126 c9 -24 -35 -64 -73 -64 -33 0 -59 21 -52 41
4 13 67 36 101 38 10 1 20 -6 24 -15z"/>
<path d="M3468 3383 c-5 -73 6 -198 17 -198 6 0 12 40 15 90 3 50 8 95 13 102
4 6 21 14 37 18 17 4 30 13 30 21 0 17 -41 19 -58 2 -9 -9 -15 -9 -24 0 -20
20 -26 14 -30 -35z"/>
<path d="M3655 3419 c-33 -16 -46 -46 -47 -108 -1 -38 5 -66 16 -83 19 -29 67
-50 94 -41 25 9 62 44 62 60 0 20 -24 16 -38 -7 -17 -28 -67 -27 -86 3 -8 12
-17 34 -20 50 -6 32 18 96 37 98 29 3 53 -4 60 -17 11 -18 37 -18 37 1 0 41
-68 67 -115 44z"/>
<path d="M4263 3410 c-18 -11 -33 -27 -33 -35 0 -21 17 -19 47 5 31 24 66 26
87 4 34 -34 7 -64 -57 -64 -83 0 -117 -84 -49 -123 32 -19 33 -19 73 1 40 20
41 20 57 2 8 -11 19 -17 23 -14 4 2 6 50 3 107 -4 91 -7 104 -27 119 -30 23
-85 22 -124 -2z m115 -132 c5 -28 -38 -59 -78 -56 -29 3 -35 7 -38 29 -3 23 2
27 40 36 55 14 72 12 76 -9z"/>
<path d="M4490 3310 c0 -100 3 -120 15 -120 12 0 15 15 15 78 0 94 15 122 64
122 43 0 56 -28 56 -125 0 -62 3 -75 16 -75 25 0 17 190 -10 219 -21 23 -75
28 -94 9 -9 -9 -15 -9 -24 0 -32 32 -38 14 -38 -108z"/>
<path d="M5430 3310 c0 -100 3 -120 15 -120 12 0 15 16 15 83 0 94 13 117 65
117 43 0 54 -24 55 -117 0 -69 3 -83 16 -83 14 0 15 14 12 101 -3 92 -5 103
-27 120 -27 22 -62 24 -92 7 -17 -10 -23 -10 -26 0 -3 6 -11 12 -19 12 -11 0
-14 -23 -14 -120z"/>
<path d="M5725 3418 c-22 -12 -45 -37 -45 -50 0 -15 27 -8 44 11 12 13 28 18
51 16 44 -4 55 -12 55 -35 0 -24 -29 -40 -73 -40 -37 0 -87 -37 -87 -65 0 -52
62 -85 110 -60 33 17 60 19 60 5 0 -5 8 -10 18 -10 15 0 17 9 12 101 -5 92 -8
102 -30 120 -28 21 -84 24 -115 7z m105 -142 c0 -31 -41 -58 -83 -54 -26 2
-33 8 -35 29 -3 23 2 27 40 36 69 17 78 16 78 -11z"/>
<path d="M6020 3420 c0 -6 4 -18 9 -28 5 -9 26 -59 46 -111 32 -83 35 -98 24
-123 -6 -16 -22 -31 -35 -34 -32 -8 -31 -34 0 -34 37 0 59 39 110 193 25 75
46 139 46 142 0 3 -6 5 -14 5 -8 0 -24 -33 -40 -83 -15 -45 -31 -88 -36 -96
-9 -16 -18 3 -61 118 -12 34 -28 61 -35 61 -8 0 -14 -5 -14 -10z"/>
<path d="M6285 3405 c-21 -21 -26 -31 -20 -52 8 -34 26 -47 86 -63 27 -8 51
-20 54 -28 16 -43 -71 -59 -112 -20 -25 24 -38 20 -29 -9 7 -22 32 -38 72 -47
62 -14 130 66 94 109 -6 8 -35 21 -63 31 -62 20 -78 31 -71 49 9 22 63 24 89
3 29 -23 46 -23 38 0 -19 54 -93 68 -138 27z"/>
<path d="M6500 3310 c0 -100 3 -120 15 -120 13 0 15 20 15 120 0 100 -2 120
-15 120 -12 0 -15 -20 -15 -120z"/>
<path d="M6613 3407 c-13 -13 -23 -32 -23 -44 0 -28 37 -63 66 -63 13 0 26 -4
29 -10 3 -5 15 -10 25 -10 22 0 30 -19 18 -42 -13 -26 -79 -24 -103 2 -24 26
-37 25 -33 -2 2 -15 16 -29 39 -41 32 -15 41 -16 75 -5 49 16 73 55 57 91 -8
18 -27 30 -65 42 -63 21 -81 34 -69 54 12 19 72 16 87 -3 12 -17 44 -22 44 -7
0 4 -12 20 -26 35 -34 33 -89 35 -121 3z"/>
<path d="M7390 3310 c0 -112 1 -120 19 -120 19 0 20 6 16 103 -2 56 -4 110 -5
120 0 9 -7 17 -15 17 -12 0 -15 -20 -15 -120z"/>
<path d="M7490 3310 c0 -95 3 -120 14 -120 10 0 15 22 18 84 5 95 16 116 62
116 49 0 56 -14 56 -112 0 -74 3 -88 16 -88 14 0 15 14 12 105 -3 90 -6 106
-23 119 -24 18 -59 20 -87 5 -14 -8 -23 -8 -31 0 -6 6 -17 11 -24 11 -10 0
-13 -28 -13 -120z"/>
<path d="M7771 3399 c-27 -26 -31 -38 -31 -84 0 -69 15 -100 56 -120 31 -14
36 -14 65 0 37 20 43 16 34 -23 -3 -18 -16 -34 -31 -41 -35 -15 -47 -14 -77 9
-30 24 -47 26 -47 6 0 -22 54 -56 89 -56 33 0 68 16 88 40 14 17 19 300 5 300
-4 0 -13 -5 -20 -12 -9 -9 -18 -9 -37 0 -40 19 -61 14 -94 -19z m113 -31 c22
-31 20 -100 -3 -126 -25 -30 -75 -29 -95 0 -9 12 -16 41 -16 63 0 56 22 85 65
85 24 0 39 -7 49 -22z"/>
<path d="M8220 3259 c-102 -150 -250 -401 -250 -423 0 -7 -4 -16 -8 -22 -9 -9
-81 -193 -100 -254 -6 -19 -27 -93 -47 -165 -20 -71 -45 -149 -55 -172 -11
-24 -20 -47 -20 -51 0 -14 -55 -123 -104 -206 -25 -42 -46 -80 -46 -83 0 -6
-140 -204 -150 -213 -17 -15 -80 -110 -80 -120 0 -13 9 -12 85 10 22 6 56 15
75 20 34 8 123 31 240 63 30 8 89 22 130 31 41 10 100 26 130 35 30 10 75 24
100 31 25 6 53 16 62 21 10 5 27 9 37 9 11 0 23 5 26 10 3 6 15 10 26 10 10 0
27 4 37 10 9 5 38 16 64 24 280 90 567 249 689 382 35 39 109 149 109 163 0 6
3 11 8 11 4 0 13 15 20 33 13 31 25 58 43 102 13 30 67 226 96 345 14 58 30
115 35 127 6 11 9 26 6 33 -2 7 -26 -22 -52 -65 -140 -221 -292 -392 -428
-483 -40 -26 -122 -72 -183 -101 -60 -29 -118 -56 -127 -62 -10 -5 -22 -9 -28
-9 -30 0 -308 -147 -395 -209 -55 -39 -127 -89 -160 -113 l-60 -42 28 36 c15
20 27 40 27 43 0 4 14 26 30 50 17 24 30 47 30 52 0 4 9 19 20 33 11 14 20 33
20 43 0 9 4 25 9 35 21 40 51 126 51 147 0 12 4 35 9 51 11 35 25 141 47 339
19 176 51 372 74 460 31 112 46 185 38 185 -3 0 -51 -68 -108 -151z"/>
<path d="M1763 3333 c3 -16 15 -64 26 -108 37 -139 64 -289 81 -450 17 -151
29 -247 40 -315 5 -36 13 -86 16 -113 4 -26 11 -50 15 -53 5 -3 9 -15 9 -28 0
-12 4 -26 8 -31 5 -6 15 -30 22 -55 15 -46 63 -144 85 -170 7 -8 25 -37 40
-63 l26 -48 -23 11 c-13 7 -43 29 -68 49 -25 20 -56 44 -70 53 -14 10 -44 31
-68 48 -23 16 -45 30 -48 30 -3 0 -25 14 -49 30 -24 17 -46 30 -49 30 -3 0
-47 20 -98 43 -51 24 -113 52 -138 62 -77 31 -306 145 -326 163 -11 9 -35 29
-55 44 -45 33 -140 129 -188 188 -20 25 -38 47 -42 50 -3 3 -25 34 -50 70 -24
36 -49 70 -54 76 -6 6 -28 39 -50 72 -21 34 -40 60 -42 59 -1 -2 1 -17 7 -33
5 -16 13 -47 19 -69 81 -324 104 -398 154 -505 55 -118 149 -236 245 -307 20
-16 46 -36 56 -45 11 -10 26 -18 33 -18 7 0 13 -3 13 -7 0 -9 0 -9 155 -85 61
-30 126 -60 145 -67 19 -8 54 -22 77 -32 24 -11 47 -19 53 -19 6 0 18 -4 28
-10 17 -10 220 -77 302 -100 25 -7 65 -19 90 -26 47 -14 217 -59 285 -75 22
-6 58 -15 80 -20 22 -6 55 -13 73 -16 18 -3 35 -9 38 -14 3 -5 15 -9 26 -9 12
0 38 -5 57 -11 20 -6 48 -14 63 -19 25 -7 51 3 30 12 -4 1 -19 19 -32 38 -30
44 -133 183 -140 190 -10 10 -50 69 -50 75 0 3 -12 24 -27 48 -49 76 -109 190
-133 252 -11 30 -26 67 -38 95 -6 14 -20 57 -31 95 -11 39 -32 111 -46 160
-55 188 -107 317 -179 440 -16 28 -45 79 -64 115 -19 36 -42 73 -50 83 -8 9
-20 26 -26 36 -22 39 -158 231 -163 231 -3 0 -3 -12 0 -27z"/>
<path d="M4667 2904 c-6 -7 11 -63 26 -82 5 -7 7 -15 4 -18 -3 -4 -27 12 -53
35 -44 39 -74 52 -74 32 0 -4 18 -23 40 -41 22 -18 40 -37 40 -41 0 -5 -22 -9
-50 -9 -39 0 -50 -3 -50 -16 0 -9 9 -18 20 -21 15 -4 20 -14 20 -39 l0 -34
-56 0 c-46 0 -55 3 -50 15 3 8 9 22 12 30 8 20 -43 65 -75 65 -26 -1 -61 -20
-61 -35 0 -4 16 -5 35 -2 25 4 41 1 57 -13 22 -18 22 -19 4 -38 -10 -12 -28
-22 -39 -24 -12 -2 -23 -11 -25 -20 -3 -14 3 -18 23 -18 15 0 33 -6 41 -14 17
-18 18 -58 2 -74 -19 -19 -69 -14 -94 9 -18 17 -22 18 -27 5 -9 -23 39 -56 83
-56 27 0 45 7 64 27 27 27 29 43 11 86 -7 16 -3 18 41 15 l49 -3 3 -62 c2 -47
7 -63 17 -63 12 0 15 22 15 119 0 112 1 120 21 126 11 4 31 4 45 1 24 -6 24
-8 24 -126 0 -100 3 -120 15 -120 12 0 15 20 15 120 l0 119 33 5 c17 3 50 4
72 3 l40 -2 3 -59 3 -58 -35 4 c-20 2 -36 0 -36 -5 0 -15 114 -146 128 -146
22 -2 13 24 -23 62 l-36 38 21 26 c14 19 20 41 20 79 0 44 3 54 18 56 9 1 24
2 32 4 8 1 21 2 28 3 6 1 12 8 12 16 0 13 -22 15 -139 15 l-139 0 -22 55 c-22
54 -42 80 -53 69z"/>
<path d="M7241 2896 c-15 -18 2 -31 25 -18 13 7 13 10 1 20 -11 10 -17 9 -26
-2z"/>
<path d="M3340 2892 c0 -14 11 -21 26 -15 8 3 11 9 8 14 -7 11 -34 12 -34 1z"/>
<path d="M5220 2889 c-41 -17 -60 -40 -60 -76 0 -18 -4 -33 -10 -33 -22 0 -8
-27 17 -36 l28 -9 0 -115 c0 -101 2 -115 17 -118 16 -3 18 10 20 120 l3 123
107 1 c116 0 132 -3 126 -32 -3 -16 -11 -19 -53 -16 -40 3 -56 -1 -78 -18 -58
-46 -19 -146 56 -142 17 1 41 3 52 4 14 2 21 -4 23 -19 2 -13 10 -23 17 -23
11 0 15 26 17 122 l3 123 29 5 c39 7 39 24 1 28 -25 3 -79 32 -95 52 -10 12
-100 50 -142 60 -51 11 -46 11 -78 -1z m117 -40 c71 -29 113 -53 105 -61 -5
-5 -62 -7 -128 -6 -106 3 -119 5 -122 21 -2 10 4 27 14 38 20 22 86 26 131 8z
m115 -196 c25 -22 23 -48 -7 -67 -45 -30 -115 -10 -115 32 0 46 83 70 122 35z"/>
<path d="M5926 2892 c-3 -5 15 -28 39 -52 25 -24 45 -48 45 -52 0 -4 -54 -8
-120 -8 -100 0 -120 -2 -120 -15 0 -16 16 -18 122 -15 37 1 81 -1 98 -5 27 -6
30 -10 30 -46 0 -32 -5 -42 -23 -50 -20 -9 -30 -6 -62 15 -40 27 -59 27 -102
0 -37 -23 -31 -81 11 -130 42 -48 56 -40 21 11 -40 60 -32 105 20 105 34 0 72
-29 48 -37 -7 -3 -13 -11 -13 -20 0 -10 6 -12 23 -8 12 4 26 11 32 16 5 5 17
9 27 9 15 0 18 -9 18 -55 0 -42 3 -55 15 -55 12 0 15 20 15 120 0 118 0 120
24 126 14 3 34 3 45 -1 18 -5 21 -14 21 -61 0 -45 4 -59 24 -79 30 -30 73 -42
96 -27 24 14 30 6 30 -40 0 -27 4 -38 15 -38 12 0 15 20 15 119 l0 119 26 6
c49 13 61 1 66 -66 4 -53 8 -64 34 -84 36 -29 57 -30 95 -3 26 19 29 26 29 80
0 55 2 60 26 70 15 5 35 7 45 4 17 -6 19 -18 19 -126 0 -99 3 -119 15 -119 13
0 15 20 15 120 0 112 1 120 19 120 30 0 51 11 51 26 0 12 -58 14 -354 14
l-353 0 -49 59 c-47 57 -67 71 -78 53z m364 -188 c0 -46 -12 -49 -49 -9 -37
41 -37 43 -8 47 43 7 57 -2 57 -38z m250 -17 c0 -58 -12 -77 -48 -77 -37 0
-52 23 -52 79 l0 51 50 0 50 0 0 -53z m-280 -68 c0 -15 -52 -10 -72 8 -17 15
-25 72 -12 86 7 7 84 -79 84 -94z"/>
<path d="M6955 2888 c-39 -15 -54 -35 -55 -75 0 -18 -4 -33 -10 -33 -21 0 -9
-28 15 -34 25 -6 25 -7 25 -126 0 -113 1 -120 20 -120 19 0 20 7 20 119 0 86
3 120 13 123 6 2 57 4 112 4 l100 -1 3 -26 c3 -26 2 -26 -45 -22 -65 7 -103
-21 -103 -76 0 -64 51 -97 123 -78 26 7 33 -2 28 -35 0 -5 8 -8 19 -8 19 0 20
7 20 120 0 136 0 135 90 126 25 -3 53 -5 63 -5 13 -1 17 -9 17 -35 0 -29 -4
-34 -31 -40 -59 -11 -76 -54 -39 -94 25 -27 41 -28 71 -3 20 16 24 30 27 95 4
70 6 77 27 82 13 3 33 1 44 -6 19 -10 21 -19 21 -125 0 -70 4 -115 10 -115 6
0 10 46 10 119 l0 119 35 6 c21 4 35 13 35 21 0 13 -30 15 -186 15 l-185 0
-57 39 c-88 60 -185 88 -237 69z m122 -39 c71 -29 113 -53 105 -61 -5 -5 -62
-7 -128 -6 -116 3 -119 4 -122 26 -2 13 2 30 8 38 15 17 97 19 137 3z m111
-191 c19 -19 15 -66 -7 -78 -45 -23 -111 -1 -111 38 0 43 86 72 118 40z m212
-48 c0 -15 -7 -20 -25 -20 -24 0 -34 18 -18 33 15 15 43 7 43 -13z"/>
<path d="M2488 2766 c-27 -20 -22 -25 23 -24 75 1 94 -60 23 -72 -42 -8 -44
-38 -2 -42 23 -2 37 -11 47 -30 14 -24 14 -28 -3 -47 -24 -26 -66 -27 -99 -1
-21 16 -26 17 -31 5 -8 -23 41 -55 86 -55 28 0 46 7 63 25 25 24 33 69 15 80
-24 15 -7 26 38 23 l47 -3 3 -62 c2 -47 7 -63 17 -63 12 0 15 22 15 119 0 87
3 120 13 124 19 7 231 9 250 1 11 -4 17 -17 17 -40 l0 -34 -72 0 c-40 0 -84
-6 -97 -13 -57 -28 -44 -117 17 -117 37 0 52 17 52 57 0 33 0 33 50 33 l50 0
0 -65 c0 -51 3 -65 15 -65 12 0 15 20 15 119 l0 119 31 6 c45 9 102 7 118 -3
11 -7 13 -23 8 -66 -3 -31 -8 -60 -11 -63 -3 -3 -17 1 -31 8 -28 15 -41 9 -28
-13 15 -28 105 -127 114 -127 15 0 10 37 -7 50 -55 47 -36 80 47 80 l59 0 0
-55 c0 -48 2 -55 20 -55 19 0 20 7 20 120 l0 121 30 6 c17 3 30 12 30 19 0 12
-66 14 -390 14 -332 0 -390 -2 -390 -14 0 -8 9 -18 20 -21 15 -5 20 -15 20
-41 l0 -34 -55 0 c-53 0 -55 1 -45 21 32 59 -56 118 -112 75z m845 -25 c4 -1
7 -21 7 -46 l0 -45 -55 0 -55 0 0 44 c0 49 6 54 60 50 19 -2 38 -3 43 -3z
m-454 -125 c15 -18 3 -46 -19 -46 -24 0 -33 15 -25 40 7 23 28 26 44 6z"/>
<path d="M3473 2763 c-30 -20 -32 -87 -4 -105 15 -9 18 -19 14 -49 -5 -32 -2
-39 21 -54 28 -19 83 -23 134 -11 30 8 32 7 32 -18 0 -16 5 -26 15 -26 12 0
15 20 15 119 0 128 4 136 55 126 11 -2 23 -4 28 -4 4 -1 7 -55 7 -121 0 -99 3
-120 15 -120 12 0 15 23 17 122 l3 123 114 3 c149 4 161 1 161 -43 l0 -35 -76
0 c-41 0 -84 -5 -94 -10 -47 -25 -50 -98 -5 -118 43 -20 75 8 75 63 0 25 2 26
48 23 l47 -3 3 -62 c2 -47 7 -63 17 -63 12 0 15 22 15 120 l0 119 35 7 c20 3
35 12 35 20 0 12 -47 14 -292 12 -203 -2 -292 -6 -295 -14 -2 -6 10 -14 27
-17 30 -6 30 -7 30 -76 0 -62 -2 -70 -25 -85 -54 -35 -168 -8 -119 29 10 8 25
15 32 15 6 0 12 7 12 15 0 11 -8 13 -30 9 -32 -7 -60 8 -60 32 0 10 8 12 30 8
53 -11 80 35 42 69 -23 21 -50 21 -79 0z m62 -33 c-3 -5 -13 -10 -21 -10 -8 0
-14 5 -14 10 0 6 9 10 21 10 11 0 17 -4 14 -10z m437 -117 c7 -27 -1 -43 -22
-43 -24 0 -33 15 -25 40 8 25 41 26 47 3z"/>
<path d="M5601 2754 c-27 -34 -26 -49 1 -68 27 -19 52 -20 78 -4 27 17 36 3
16 -27 -15 -23 -23 -26 -58 -23 -24 2 -43 -2 -45 -8 -5 -14 101 -114 121 -114
24 0 19 13 -14 45 l-31 30 32 28 c28 24 33 34 33 78 1 42 -3 53 -24 69 -37 29
-84 26 -109 -6z m79 -14 c23 -14 -10 -44 -39 -35 -36 11 -26 35 19 44 3 0 12
-4 20 -9z"/>
<path d="M2988 2449 c-28 -16 -21 -28 18 -31 48 -4 65 -29 40 -57 -22 -25 -65
-27 -105 -7 -16 9 -33 11 -40 6 -22 -13 33 -42 89 -48 42 -4 52 -1 74 21 40
40 34 87 -15 112 -33 17 -38 18 -61 4z"/>
<path d="M7652 2400 c-12 -11 -24 -20 -29 -20 -4 0 -18 -11 -32 -24 -15 -13
-78 -63 -141 -110 -185 -139 -333 -270 -505 -444 -88 -90 -178 -176 -200 -192
-75 -52 -245 -133 -440 -210 -168 -66 -185 -73 -185 -82 0 -5 8 -8 17 -8 9 0
42 -8 72 -19 129 -43 460 -133 561 -152 25 -4 65 -12 90 -18 180 -38 241 -47
418 -61 68 -5 127 -12 131 -14 11 -7 259 13 311 25 138 32 167 42 275 95 92
45 162 93 254 174 51 44 347 357 385 408 l20 25 -25 -13 c-13 -7 -26 -16 -29
-20 -13 -18 -309 -200 -326 -200 -2 0 -32 -13 -66 -28 -197 -89 -339 -111
-703 -113 -132 0 -296 -5 -365 -10 -69 -6 -159 -12 -200 -15 l-75 -5 95 48
c52 26 124 72 160 102 64 53 166 170 192 220 7 14 23 43 35 63 13 21 60 109
104 195 88 171 109 209 159 293 77 129 85 150 42 110z m-805 -1046 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M2439 2313 c15 -27 39 -66 52 -88 14 -22 36 -62 49 -90 14 -27 27
-52 30 -55 3 -3 36 -66 74 -140 38 -74 86 -164 108 -199 21 -34 38 -65 38 -67
0 -11 95 -126 136 -164 82 -77 212 -160 250 -160 8 0 14 -4 14 -10 0 -5 9 -10
19 -10 11 0 23 -5 27 -11 7 -12 -28 -10 -216 12 -47 5 -236 14 -420 19 -476
13 -526 21 -780 138 -73 34 -357 214 -372 236 -6 8 -8 8 -8 0 0 -28 385 -425
495 -510 54 -41 174 -114 189 -114 7 0 16 -4 22 -9 19 -19 168 -62 264 -76
200 -30 513 -12 785 45 28 6 70 15 95 20 51 10 107 24 165 40 22 6 57 16 78
21 20 5 54 13 75 18 57 15 99 26 197 56 50 15 103 30 119 34 40 9 44 28 9 42
-15 5 -41 17 -57 25 -16 8 -33 14 -38 14 -4 0 -27 8 -51 19 -23 10 -73 31
-112 46 -38 16 -97 41 -129 57 -33 15 -64 28 -68 28 -5 0 -29 14 -53 30 -24
17 -48 30 -54 30 -6 0 -11 5 -11 10 0 6 -6 10 -12 10 -7 0 -89 78 -183 173
-93 95 -199 198 -234 228 -36 30 -83 70 -105 89 -67 58 -134 111 -258 203 -64
48 -120 92 -123 97 -3 6 -13 10 -20 10 -11 0 -7 -13 14 -47z"/>
<path d="M3909 1983 c-4 -237 -3 -263 8 -267 6 -3 14 -2 17 1 3 4 6 75 6 160
0 129 -2 153 -15 153 -11 0 -15 -12 -16 -47z"/>
<path d="M4430 1979 l0 -50 -29 17 c-51 30 -108 0 -131 -70 -17 -51 2 -109 45
-142 36 -27 50 -29 85 -10 21 12 26 12 36 -2 26 -36 34 -1 34 154 0 147 -1
154 -20 154 -17 0 -20 -7 -20 -51z m-18 -76 c24 -22 25 -109 1 -135 -22 -25
-68 -23 -93 4 -25 27 -27 93 -4 126 18 27 69 30 96 5z"/>
<path d="M4530 2014 c0 -9 7 -14 17 -12 25 5 28 28 4 28 -12 0 -21 -6 -21 -16z"/>
<path d="M5036 1979 c-14 -11 -26 -29 -26 -39 0 -26 45 -59 71 -53 17 5 20 2
17 -18 -2 -15 -11 -25 -23 -27 -11 -2 -30 -8 -42 -14 -46 -23 -18 -98 36 -98
25 0 71 42 71 65 0 13 9 16 43 13 42 -3 42 -3 47 -48 3 -31 10 -45 20 -45 12
0 15 22 18 123 l3 122 39 0 39 0 3 -122 c2 -84 7 -123 15 -125 9 -3 13 27 15
119 l3 123 78 3 77 3 0 -49 c0 -58 -20 -83 -50 -64 -40 25 -30 -10 19 -67 63
-73 77 -84 91 -76 8 5 -2 21 -26 46 -36 37 -38 41 -24 62 8 12 17 49 20 82 l5
60 148 3 148 3 -3 -38 -3 -38 -78 -5 c-88 -6 -107 -19 -107 -76 0 -38 42 -94
71 -94 26 0 24 10 -11 43 -31 30 -39 64 -18 85 16 16 137 16 143 0 2 -7 5 -37
7 -67 3 -57 21 -79 32 -39 3 13 6 69 6 126 l0 102 31 0 c24 0 30 4 27 18 -3
16 -30 17 -398 17 -344 0 -395 -2 -395 -15 0 -9 11 -16 28 -18 26 -3 27 -6 27
-57 0 -56 -8 -65 -56 -65 -30 0 -40 25 -43 103 -1 21 -36 57 -55 57 -7 0 -25
-9 -40 -21z m62 -42 c3 -15 -2 -18 -25 -15 -17 2 -29 10 -31 21 -3 15 2 18 25
15 17 -2 29 -10 31 -21z m2 -147 c0 -16 -7 -20 -30 -20 -18 0 -30 5 -30 13 0
20 10 27 37 27 16 0 23 -6 23 -20z"/>
<path d="M4030 1834 c0 -96 3 -125 13 -122 8 3 14 34 17 90 3 50 11 92 19 102
16 19 76 22 88 4 4 -7 10 -53 13 -103 3 -52 9 -89 15 -88 14 4 18 163 6 197
-15 38 -53 53 -97 37 -26 -10 -39 -10 -47 -2 -23 23 -27 7 -27 -115z"/>
<path d="M4532 1838 c3 -101 6 -123 18 -123 12 0 15 20 15 120 0 107 -2 120
-18 123 -16 3 -17 -6 -15 -120z"/>
<path d="M4673 1940 c-34 -20 -44 -50 -18 -50 9 0 18 7 21 15 4 10 20 15 50
15 34 0 46 -5 55 -22 15 -28 -4 -43 -68 -52 -26 -3 -55 -14 -65 -23 -25 -22
-23 -68 4 -93 26 -24 54 -25 98 -4 31 16 34 16 45 0 7 -9 18 -16 25 -16 10 0
12 22 7 98 -6 114 -10 126 -43 141 -37 16 -75 13 -111 -9z m104 -118 c18 -11
5 -48 -21 -62 -12 -6 -38 -10 -57 -8 -29 2 -34 7 -34 28 0 20 7 26 45 36 57
15 53 15 67 6z"/>
<path d="M5365 1373 c-33 -8 -75 -17 -93 -20 -18 -3 -35 -9 -38 -14 -3 -5 -14
-9 -25 -9 -10 0 -27 -4 -37 -9 -9 -5 -39 -19 -67 -31 -27 -12 -58 -26 -67 -31
-27 -14 -66 -11 -127 10 -55 18 -103 32 -178 50 -87 22 -229 33 -269 22 -21
-6 -62 -11 -91 -11 -29 0 -62 -4 -75 -9 -13 -5 -43 -14 -68 -21 -75 -21 -169
-57 -330 -130 -182 -82 -268 -119 -330 -140 -19 -7 -44 -16 -55 -21 -40 -16
-112 -38 -185 -54 -41 -9 -93 -21 -115 -27 -53 -13 -160 -25 -330 -37 -186
-13 -636 10 -675 34 -17 11 -11 -7 9 -24 33 -29 180 -135 209 -151 15 -9 53
-32 85 -53 31 -20 59 -37 62 -37 2 0 30 -15 62 -33 49 -29 164 -80 238 -107
53 -19 156 -46 231 -59 172 -31 374 7 563 107 55 29 226 139 256 164 5 5 53
41 105 79 52 39 103 77 113 85 9 8 19 14 22 14 3 0 25 16 50 35 54 41 239 135
265 135 10 0 21 4 24 9 12 19 236 41 236 23 0 -5 -8 -12 -17 -15 -10 -3 -31
-14 -48 -24 -55 -33 -350 -226 -355 -233 -3 -3 -50 -37 -105 -74 -55 -38 -102
-72 -105 -75 -3 -3 -25 -22 -50 -41 -25 -19 -49 -38 -55 -42 -5 -5 -44 -34
-85 -65 -41 -32 -77 -60 -80 -63 -3 -3 -26 -21 -52 -40 -26 -19 -47 -39 -48
-45 0 -14 118 -160 129 -160 6 0 77 66 158 146 138 136 291 276 394 360 82 67
220 169 229 169 6 0 10 5 10 10 0 6 5 10 11 10 5 0 36 17 67 38 106 71 202
115 251 114 24 -1 49 -6 55 -12 5 -5 17 -10 26 -10 8 0 65 -24 125 -54 93 -45
166 -91 351 -216 12 -9 57 -45 100 -80 42 -36 93 -78 113 -93 20 -15 132 -121
249 -234 l214 -206 39 44 c22 24 53 63 69 86 l30 42 -32 23 c-18 12 -64 48
-103 78 -178 143 -294 229 -515 381 -47 33 -104 72 -127 88 -36 26 -87 56
-273 164 -19 11 -52 29 -73 40 -20 12 -36 26 -34 31 8 24 191 34 275 15 118
-26 138 -33 237 -80 61 -29 171 -93 185 -109 3 -3 34 -25 70 -49 36 -25 67
-47 70 -51 6 -6 75 -58 106 -80 220 -152 311 -206 399 -235 28 -9 59 -20 70
-25 66 -29 320 -38 429 -15 190 39 385 124 596 260 107 69 240 168 240 179 0
5 -22 5 -52 1 -196 -28 -531 -41 -643 -26 -44 5 -134 17 -200 26 -66 9 -165
27 -220 41 -55 14 -110 28 -123 31 -12 3 -25 9 -28 14 -3 5 -15 9 -28 9 -12 0
-26 4 -31 8 -6 4 -28 14 -50 21 -22 7 -58 21 -80 31 -22 10 -69 30 -105 45
-78 33 -228 102 -252 115 -10 6 -23 10 -28 10 -6 0 -30 9 -52 20 -23 11 -50
20 -59 20 -10 0 -20 4 -23 9 -3 5 -20 11 -38 14 -18 3 -60 12 -93 21 -78 20
-325 20 -410 -1z"/>
</g>
</svg>
        </>
    );
}

export default Ind;
