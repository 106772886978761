import React from 'react';

const Irs = () => {
    return (
        <div>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                width="95.000000pt" height="100.000000pt" viewBox="0 0 350.000000 350.000000"
                preserveAspectRatio="xMidYMid meet" className='w-16 md:w-auto -mt-12 pl-2 md:-mt-0 md:pl-0'>

                <g transform="translate(0.000000,350.000000) scale(0.100000,-0.100000)"
                fill="#ffffff" stroke="none">
                <path d="M1535 3442 c-6 -5 -55 -15 -110 -21 -55 -7 -111 -19 -125 -26 -14 -7
                -43 -16 -65 -19 -22 -4 -49 -13 -60 -20 -11 -7 -33 -16 -50 -20 -72 -17 -277
                -131 -394 -218 -24 -18 -50 -37 -58 -43 -22 -15 -236 -228 -248 -246 -5 -9
                -25 -36 -43 -60 -70 -93 -103 -148 -157 -259 -31 -63 -62 -127 -70 -142 -7
                -15 -19 -53 -26 -85 -7 -32 -16 -60 -21 -64 -4 -3 -15 -54 -24 -115 -8 -60
                -20 -117 -25 -126 -12 -23 -12 -433 0 -456 5 -9 16 -64 25 -121 9 -57 20 -109
                25 -115 5 -6 12 -27 16 -46 4 -19 13 -48 20 -65 7 -16 18 -46 25 -65 19 -53
                102 -220 109 -220 4 0 12 -12 19 -27 44 -101 193 -274 372 -430 97 -85 339
                -233 381 -233 11 0 19 -4 19 -8 0 -4 17 -14 38 -21 20 -7 51 -19 67 -26 17 -7
                48 -17 70 -20 22 -4 45 -12 51 -17 7 -5 54 -16 105 -24 52 -9 108 -18 124 -21
                62 -11 398 -14 409 -3 5 5 27 10 47 10 43 0 195 27 224 39 11 5 42 14 69 21
                27 6 58 18 70 26 11 7 27 14 35 14 17 0 225 102 229 113 2 4 8 7 14 7 6 0 26
                11 44 25 18 14 36 25 39 25 16 0 243 194 287 245 9 11 42 49 73 85 31 36 62
                75 67 88 6 12 15 22 20 22 4 0 8 4 8 9 0 6 14 31 32 57 34 51 108 197 108 214
                0 5 4 10 9 10 5 0 14 21 20 46 7 25 18 56 26 67 7 12 17 40 21 62 3 22 11 45
                15 51 5 6 7 18 3 27 -3 8 -1 18 5 21 5 4 13 32 17 64 3 31 9 61 12 67 3 5 5
                26 3 46 -1 20 2 40 8 44 15 9 15 361 0 370 -6 4 -9 26 -8 49 1 22 -1 48 -4 56
                -3 8 -11 47 -18 85 -6 39 -15 75 -19 80 -4 6 -11 28 -14 50 -4 22 -15 56 -25
                75 -10 20 -22 50 -26 68 -3 18 -10 32 -14 32 -4 0 -14 19 -21 43 -7 23 -21 54
                -31 69 -11 14 -19 30 -19 35 0 6 -18 35 -40 67 -22 31 -40 59 -40 62 0 5 -23
                34 -117 154 -56 70 -276 270 -297 270 -7 0 -18 9 -26 20 -8 11 -18 20 -23 20
                -5 0 -21 8 -35 19 -62 44 -197 114 -272 141 -19 7 -48 18 -65 25 -16 7 -46 16
                -65 20 -19 4 -40 11 -46 16 -12 10 -173 39 -215 39 -15 0 -31 5 -34 10 -8 12
                -417 14 -430 2z m530 -42 c22 -6 69 -18 104 -26 36 -8 69 -18 75 -22 6 -5 31
                -14 56 -21 25 -7 63 -21 85 -31 45 -20 72 -30 81 -29 4 0 10 -3 13 -8 3 -5 17
                -14 31 -22 132 -69 229 -133 307 -205 33 -31 64 -56 67 -56 4 0 24 -19 46 -42
                160 -175 234 -281 316 -448 65 -133 81 -168 90 -205 4 -16 10 -34 14 -40 4 -5
                13 -41 20 -80 7 -38 16 -75 20 -80 27 -33 39 -413 19 -555 -6 -41 -11 -85 -10
                -97 0 -13 -3 -23 -7 -23 -5 0 -14 -30 -21 -67 -7 -37 -16 -72 -21 -78 -4 -5
                -13 -30 -19 -55 -14 -52 -21 -70 -87 -205 -27 -55 -65 -122 -84 -149 -19 -27
                -41 -62 -50 -77 -37 -69 -321 -352 -388 -389 -16 -9 -51 -31 -78 -50 -69 -50
                -268 -147 -349 -171 -22 -6 -47 -15 -56 -19 -9 -5 -45 -14 -80 -21 -35 -6 -69
                -15 -74 -20 -18 -13 -200 -29 -339 -29 -147 0 -336 18 -336 31 0 5 -12 9 -26
                9 -27 0 -93 15 -129 30 -11 5 -38 14 -60 21 -22 6 -44 15 -50 19 -5 3 -32 15
                -60 25 -27 10 -66 27 -85 39 -19 11 -53 29 -75 41 -66 34 -105 60 -105 68 0 4
                -6 7 -14 7 -7 0 -50 32 -95 70 -44 39 -84 70 -88 70 -8 0 -113 108 -113 117 0
                3 -23 30 -51 61 -42 46 -96 122 -166 237 -12 19 -71 140 -98 200 -7 17 -19 48
                -25 70 -6 22 -18 54 -25 70 -7 17 -16 50 -20 75 -4 25 -11 50 -15 55 -39 49
                -39 622 -1 710 5 11 15 49 22 85 7 36 15 67 18 70 3 3 12 23 19 45 14 43 23
                62 71 166 17 37 31 72 31 78 0 6 5 11 10 11 6 0 10 5 10 11 0 6 14 30 30 54
                17 24 30 47 30 51 0 4 7 14 15 23 26 28 115 140 115 145 0 7 167 176 175 176
                3 0 34 23 68 51 95 77 97 79 103 79 3 0 25 14 49 30 24 17 48 30 54 30 6 0 11
                5 11 10 0 6 5 10 11 10 6 0 41 14 77 31 109 51 125 57 172 70 25 7 50 16 56
                21 10 7 124 36 169 43 11 1 27 5 35 8 30 12 100 15 330 12 137 -2 252 -8 275
                -15z"/>
                <path d="M1544 3391 c-5 -4 -52 -13 -104 -20 -52 -6 -99 -16 -105 -21 -5 -4
                -28 -11 -50 -15 -22 -4 -53 -13 -70 -20 -16 -7 -46 -18 -65 -25 -49 -17 -268
                -127 -305 -152 -5 -5 -36 -28 -67 -53 -31 -25 -61 -45 -66 -45 -5 0 -26 -18
                -46 -40 -20 -22 -40 -40 -44 -40 -4 0 -28 -20 -52 -45 -24 -25 -41 -45 -37
                -45 3 0 -10 -17 -31 -39 -20 -21 -57 -66 -82 -100 -25 -34 -49 -67 -55 -74
                -31 -39 -139 -253 -155 -309 -8 -27 -17 -48 -21 -48 -3 0 -12 -28 -19 -62 -7
                -35 -17 -68 -21 -73 -4 -6 -13 -51 -19 -100 -7 -50 -16 -98 -21 -108 -12 -22
                -11 -393 1 -416 4 -9 13 -56 20 -106 6 -49 15 -94 19 -100 5 -5 12 -28 16 -50
                3 -22 13 -53 20 -70 7 -16 18 -46 25 -65 11 -32 92 -199 130 -270 18 -33 102
                -144 113 -148 4 -2 7 -9 7 -15 0 -19 236 -257 254 -257 4 0 37 -24 74 -52 37
                -29 81 -61 97 -70 91 -50 216 -111 255 -124 25 -9 59 -21 75 -29 17 -7 48 -17
                70 -20 22 -4 45 -11 50 -15 6 -5 48 -13 95 -20 47 -6 101 -16 121 -21 47 -12
                337 -12 392 0 23 5 80 15 127 21 47 7 90 15 95 20 6 4 28 11 50 15 22 3 54 13
                70 20 17 7 46 18 65 25 60 21 276 131 307 155 7 6 40 30 73 55 95 70 211 174
                258 232 47 58 130 165 150 194 29 43 133 249 152 304 7 19 18 49 25 65 7 17
                16 48 20 70 4 22 10 45 15 50 4 6 14 53 21 105 7 52 16 100 20 105 14 19 4
                385 -14 499 -9 59 -20 113 -25 120 -5 6 -15 39 -22 74 -7 34 -16 62 -19 62 -3
                0 -12 19 -19 43 -12 37 -86 192 -130 272 -9 17 -41 60 -69 97 -29 37 -53 71
                -53 77 0 12 -239 251 -251 251 -6 0 -40 24 -77 53 -37 28 -80 60 -97 69 -58
                32 -230 116 -265 128 -19 7 -48 18 -65 25 -16 7 -48 17 -70 20 -22 4 -44 11
                -50 15 -5 5 -48 13 -95 20 -47 6 -99 16 -117 21 -36 11 -396 11 -409 0z m481
                -38 c11 -6 53 -17 92 -23 40 -7 75 -16 79 -21 3 -5 14 -9 25 -9 37 0 289 -104
                297 -122 2 -5 8 -8 14 -8 5 0 29 -13 53 -30 24 -16 47 -30 50 -30 9 0 129 -93
                133 -102 2 -5 9 -8 15 -8 12 0 198 -187 229 -231 10 -13 36 -47 58 -75 22 -29
                40 -55 40 -59 0 -3 14 -26 30 -50 17 -24 30 -48 30 -53 0 -6 3 -12 8 -14 7 -3
                62 -110 62 -122 0 -4 11 -32 25 -62 14 -30 28 -72 31 -94 4 -22 10 -43 14 -45
                5 -3 14 -38 20 -78 7 -39 17 -83 23 -96 17 -34 17 -522 0 -542 -6 -8 -14 -41
                -18 -74 -4 -33 -13 -73 -20 -90 -7 -16 -18 -50 -25 -75 -7 -25 -16 -49 -20
                -55 -4 -5 -13 -28 -20 -50 -15 -49 -62 -149 -72 -153 -5 -2 -8 -7 -8 -12 0
                -17 -65 -116 -122 -187 -32 -40 -58 -76 -58 -81 0 -10 -194 -202 -204 -202 -4
                0 -38 -25 -75 -55 -38 -30 -71 -55 -75 -55 -4 0 -27 -13 -51 -30 -24 -16 -48
                -30 -53 -30 -6 0 -12 -3 -14 -7 -3 -8 -110 -63 -121 -63 -3 0 -18 -5 -34 -12
                -15 -6 -30 -12 -33 -13 -3 -1 -18 -7 -35 -14 -16 -7 -43 -15 -60 -18 -16 -3
                -34 -9 -40 -13 -5 -5 -39 -13 -75 -20 -36 -6 -76 -17 -89 -23 -34 -17 -519
                -17 -552 0 -13 6 -55 17 -94 23 -38 6 -74 15 -80 19 -5 5 -27 12 -49 15 -21 4
                -62 18 -92 32 -29 13 -56 24 -61 24 -14 1 -140 67 -187 99 -24 17 -48 31 -51
                31 -10 0 -129 93 -138 108 -4 6 -14 12 -20 12 -13 0 -197 182 -197 194 0 3
                -27 39 -60 79 -33 41 -60 77 -60 81 0 4 -14 27 -30 51 -17 24 -30 48 -30 53 0
                6 -3 12 -7 14 -8 3 -63 110 -63 121 0 3 -5 18 -12 34 -6 15 -12 30 -13 33 -1
                3 -5 12 -8 20 -21 51 -30 76 -28 84 1 5 -5 23 -13 40 -8 17 -17 58 -21 91 -4
                33 -13 66 -19 74 -16 20 -15 532 0 552 7 8 15 39 19 69 4 30 14 69 22 87 8 18
                12 36 9 42 -4 5 -2 12 4 16 5 3 10 14 10 23 0 10 8 33 19 52 10 19 23 51 30
                70 15 47 64 141 101 195 17 24 30 47 30 51 0 4 25 37 55 75 30 37 55 71 55 76
                0 13 194 203 206 203 7 0 14 4 16 9 3 10 134 111 143 111 4 0 26 14 50 30 24
                17 48 30 53 30 6 0 12 3 14 8 3 7 110 62 121 62 3 0 18 5 34 12 15 6 30 12 33
                13 3 0 14 6 25 12 11 6 38 14 60 18 22 4 45 11 50 16 6 4 42 13 80 19 39 6 79
                15 90 20 34 16 81 18 310 16 142 -1 227 -6 240 -13z"/>
                <path d="M1621 3275 c-7 -30 -16 -57 -21 -60 -4 -3 -11 -23 -15 -45 -4 -25
                -14 -44 -26 -51 -27 -14 -15 -27 26 -27 37 0 56 15 35 28 -6 4 -10 16 -8 26 2
                14 13 20 42 22 25 2 36 0 31 -7 -3 -6 -1 -11 4 -11 15 0 14 -27 -1 -33 -7 -3
                11 -6 40 -6 28 -1 52 4 52 9 0 6 -6 10 -13 10 -13 0 -31 33 -87 153 -12 26
                -27 47 -34 47 -7 0 -18 -24 -25 -55z m39 -55 c0 -11 -7 -20 -15 -20 -8 0 -15
                9 -15 20 0 11 7 20 15 20 8 0 15 -9 15 -20z"/>
                <path d="M1847 3302 c-18 -19 -22 -59 -9 -77 4 -6 24 -21 44 -35 49 -32 53
                -70 8 -70 -23 0 -33 7 -45 30 -20 39 -30 38 -30 -4 0 -32 3 -36 43 -47 43 -12
                84 -2 104 26 25 33 -6 90 -64 119 -29 15 -30 40 -1 44 17 2 26 -4 35 -23 14
                -31 32 -28 36 6 5 44 -88 67 -121 31z"/>
                <path d="M2015 3290 c-3 -5 1 -10 9 -10 18 0 23 -45 7 -55 -5 -3 -13 -31 -17
                -62 -6 -53 -5 -58 20 -80 20 -17 39 -23 75 -23 55 0 69 12 78 64 3 20 10 36
                14 36 5 0 9 9 9 19 0 30 11 51 26 51 8 0 14 5 14 10 0 6 -9 10 -21 10 -11 0
                -28 4 -37 9 -15 8 -17 2 -18 -59 0 -82 -20 -120 -63 -120 -16 0 -35 7 -42 16
                -15 18 -14 73 1 88 6 6 10 20 10 33 0 12 9 27 21 34 18 9 19 13 8 24 -19 17
                -86 28 -94 15z"/>
                <path d="M1368 3283 c-21 -2 -38 -8 -38 -12 0 -5 -7 -8 -16 -7 -9 0 -19 -5
                -21 -11 -3 -8 4 -13 16 -13 16 0 21 -6 21 -24 0 -14 4 -27 9 -30 15 -10 24
                -105 11 -120 -7 -10 -8 -16 0 -21 5 -3 10 -2 10 4 0 5 26 13 58 17 84 10 103
                19 98 46 -3 13 -5 26 -5 31 -2 16 -21 6 -21 -11 0 -21 -42 -44 -65 -36 -16 7
                -32 51 -21 61 3 3 14 -1 25 -11 24 -22 31 -6 19 52 -10 47 -23 54 -32 17 -9
                -35 -33 -31 -38 6 -6 40 22 58 57 35 35 -23 45 -20 32 9 -12 26 -17 26 -99 18z"/>
                <path d="M2287 3224 c-3 -3 -1 -15 6 -25 9 -15 8 -27 -10 -61 -11 -24 -26 -55
                -32 -70 -6 -16 -17 -28 -26 -28 -25 0 -16 -14 21 -32 43 -22 54 -22 54 -3 0 8
                -4 15 -10 15 -6 0 -5 10 2 25 14 30 34 28 30 -3 -2 -12 2 -22 7 -22 6 0 11 -9
                11 -19 0 -19 48 -66 58 -56 6 6 -18 85 -31 102 -6 9 -1 16 18 23 14 5 30 10
                35 10 5 0 11 14 15 31 9 40 -23 76 -82 91 -24 6 -43 14 -43 19 0 10 -14 12
                -23 3z m97 -76 c7 -12 6 -21 -3 -32 -15 -18 -29 -20 -50 -7 -11 7 -10 13 4 35
                19 29 32 30 49 4z"/>
                <path d="M1073 3177 c-47 -24 -53 -36 -23 -44 11 -3 23 -17 27 -31 3 -15 15
                -41 25 -59 14 -23 18 -42 13 -63 -4 -16 -5 -30 -2 -30 15 0 97 43 97 50 0 5
                -6 7 -13 4 -10 -4 -47 42 -47 60 0 3 22 -7 50 -21 38 -20 57 -24 85 -19 34 7
                47 23 23 30 -7 2 -33 11 -58 20 l-46 16 1 53 0 52 -45 2 c-30 1 -59 -5 -87
                -20z m89 -24 c22 -20 23 -30 3 -50 -19 -19 -41 -4 -47 32 -8 39 12 47 44 18z"/>
                <path d="M946 3111 c-3 -5 -35 -28 -71 -52 -36 -23 -65 -48 -65 -54 0 -7 9
                -20 20 -30 20 -18 20 -17 20 13 0 18 4 32 9 32 5 0 16 3 25 6 10 4 16 1 16 -8
                0 -8 18 -38 40 -68 38 -50 39 -55 23 -72 -15 -17 -15 -18 0 -18 23 0 96 52 97
                69 0 10 -4 11 -12 3 -9 -7 -19 -5 -35 8 -13 10 -23 22 -23 26 0 4 -12 22 -26
                41 -34 44 -29 69 14 65 34 -2 43 13 20 36 -14 14 -44 16 -52 3z"/>
                <path d="M2498 3109 c20 -22 15 -117 -9 -150 -11 -16 -28 -28 -36 -27 -31 6
                -24 -22 10 -36 19 -7 38 -19 41 -25 15 -23 27 -11 21 20 -5 23 -3 34 12 45 25
                20 121 64 138 64 21 0 19 19 -2 27 -10 3 -25 12 -35 20 -11 10 -18 11 -23 3
                -4 -6 -5 -14 -1 -17 6 -6 -11 -23 -42 -41 -20 -11 -22 -10 -22 12 0 35 10 56
                26 56 40 0 -8 47 -57 56 -28 6 -32 4 -21 -7z"/>
                <path d="M1555 3024 c-16 -3 -39 -9 -50 -13 -11 -5 -49 -15 -85 -22 -36 -6
                -69 -15 -75 -19 -5 -3 -30 -13 -55 -20 -50 -16 -186 -84 -211 -106 -8 -8 -20
                -14 -25 -14 -6 0 -41 -26 -78 -57 -38 -32 -77 -63 -88 -69 -30 -18 -88 -77
                -88 -90 0 -7 -16 -26 -35 -43 -19 -17 -35 -33 -35 -37 0 -4 -12 -23 -27 -42
                -47 -59 -116 -186 -141 -257 -5 -16 -14 -39 -18 -50 -4 -11 -8 -22 -8 -25 -1
                -3 -4 -9 -7 -15 -4 -5 -12 -44 -19 -85 -7 -41 -18 -81 -24 -89 -16 -20 -15
                -410 1 -442 6 -13 17 -53 23 -89 7 -36 16 -69 20 -75 5 -5 9 -14 10 -20 4 -19
                12 -40 23 -65 6 -14 9 -31 8 -37 -1 -7 2 -13 7 -13 5 0 15 -18 22 -40 7 -22
                17 -40 22 -40 4 0 8 -5 8 -11 0 -6 13 -30 30 -54 16 -24 30 -47 30 -51 0 -33
                292 -324 326 -324 7 0 17 -6 21 -12 11 -17 30 -28 158 -92 61 -30 126 -58 145
                -61 19 -4 40 -11 45 -15 6 -4 46 -14 90 -21 44 -7 84 -16 90 -21 12 -10 355
                -11 355 0 0 4 41 13 92 20 50 7 96 16 102 21 6 4 23 11 38 15 15 3 40 11 55
                17 15 6 28 10 28 9 0 -3 162 85 219 118 85 51 281 239 332 318 10 16 21 31 24
                34 14 14 130 236 130 250 0 5 5 21 12 37 6 15 12 33 13 38 0 6 6 21 12 35 6
                14 14 52 18 85 4 33 12 66 17 73 14 16 14 367 1 384 -5 7 -16 52 -22 101 -7
                48 -17 87 -21 87 -4 0 -11 17 -15 37 -4 21 -12 47 -18 58 -6 11 -12 25 -13 31
                -3 20 -63 135 -84 161 -11 14 -20 30 -20 35 0 42 -327 368 -369 368 -5 0 -14
                6 -18 13 -15 23 -142 87 -223 113 -30 9 -59 20 -65 25 -5 4 -39 13 -75 20 -36
                7 -68 17 -72 23 -7 10 -383 19 -443 10z m258 -312 c34 -26 91 -60 127 -76 55
                -25 78 -30 146 -30 60 0 88 4 107 17 15 9 32 17 38 17 7 0 27 11 45 25 18 14
                39 25 46 25 8 0 29 -11 48 -25 19 -14 39 -25 46 -25 7 0 14 -4 16 -8 3 -7 77
                -52 187 -112 15 -9 40 -25 56 -37 l29 -21 -26 -31 c-25 -29 -44 -73 -51 -116
                -1 -11 -6 -26 -11 -34 -11 -17 -10 -307 1 -324 4 -7 9 -43 10 -82 2 -72 28
                -420 32 -420 1 0 4 -30 7 -67 3 -39 1 -68 -4 -68 -5 0 -12 -33 -15 -74 -6 -86
                -40 -167 -98 -238 -22 -26 -39 -50 -39 -54 0 -9 -85 -94 -95 -94 -5 0 -23 -11
                -41 -25 -18 -14 -36 -25 -41 -25 -5 0 -24 -9 -44 -19 -20 -11 -54 -23 -75 -27
                -22 -3 -44 -10 -49 -15 -6 -4 -31 -13 -57 -19 -41 -11 -93 -34 -210 -97 -16
                -8 -52 -36 -82 -63 l-53 -49 -39 33 c-58 49 -175 126 -204 133 -14 3 -34 12
                -45 18 -11 7 -42 18 -70 25 -27 7 -54 16 -60 21 -5 4 -30 12 -55 19 -86 22
                -154 63 -233 139 -42 41 -77 80 -77 87 0 6 -7 14 -15 18 -8 3 -20 17 -25 31
                -5 14 -14 25 -20 25 -5 0 -10 4 -10 10 0 5 -11 34 -25 63 -13 30 -27 74 -31
                98 -4 24 -10 49 -14 54 -8 11 -5 219 3 232 3 5 8 78 12 163 4 85 10 159 13
                165 3 5 5 40 5 77 -1 37 3 70 8 73 13 9 12 262 -1 270 -6 4 -10 14 -9 23 1 10
                -3 30 -9 45 -7 16 -12 33 -12 40 0 6 -12 24 -27 39 -31 33 -27 49 22 76 17 9
                32 19 35 22 10 10 65 41 83 47 9 3 17 9 17 14 0 5 6 9 13 9 6 0 30 14 51 30
                22 17 44 30 49 30 5 0 20 9 34 20 30 23 61 26 88 6 11 -7 38 -19 60 -25 22 -7
                47 -15 55 -18 8 -3 26 -7 40 -8 14 0 33 -5 43 -9 9 -5 30 -5 45 -1 15 4 39 7
                55 7 15 1 27 5 27 9 0 5 7 9 15 9 28 0 104 42 147 81 24 21 44 38 46 39 1 0
                31 -21 65 -48z"/>
                <path d="M1740 2576 c0 -19 -4 -38 -10 -41 -5 -3 -10 -26 -10 -51 0 -24 -4
                -44 -8 -44 -5 0 -19 14 -31 31 -19 25 -28 30 -50 25 -14 -3 -66 -14 -116 -24
                -49 -10 -97 -21 -105 -24 -8 -3 -46 -11 -84 -17 -59 -9 -75 -16 -106 -46 -20
                -19 -43 -35 -51 -35 -24 0 -59 30 -59 51 0 22 -38 27 -58 7 -15 -15 -16 -58
                -2 -58 6 0 10 -7 10 -15 0 -8 18 -26 40 -40 44 -28 44 -28 31 -53 -5 -9 -15
                -36 -22 -59 -7 -24 -15 -43 -19 -43 -3 0 -12 -17 -19 -37 -8 -21 -17 -40 -20
                -43 -18 -13 -81 -141 -81 -164 0 -29 47 -77 111 -113 29 -17 56 -23 99 -23 33
                0 60 4 60 10 0 6 7 10 15 10 20 0 53 19 84 49 49 47 50 58 8 143 -22 43 -43
                78 -47 78 -4 0 -13 18 -20 40 -7 22 -17 40 -21 40 -4 0 -11 10 -15 23 -3 12
                -12 29 -19 37 -7 8 -15 29 -17 47 -4 28 -1 34 24 44 16 6 35 21 43 32 18 26
                95 36 288 36 l137 1 0 -34 c0 -19 -4 -38 -10 -41 -15 -9 -12 -81 4 -94 50 -42
                126 -18 126 39 0 16 -4 32 -10 35 -5 3 -10 27 -10 52 l0 46 196 -5 c194 -6
                196 -6 223 -32 15 -14 32 -26 38 -26 6 0 16 -6 22 -14 9 -10 8 -15 -3 -19 -9
                -4 -14 -18 -14 -36 1 -18 -10 -47 -25 -69 -15 -21 -27 -43 -27 -50 0 -7 -4
                -12 -8 -12 -5 0 -15 -18 -22 -40 -8 -23 -18 -44 -24 -48 -14 -9 -56 -93 -56
                -111 0 -19 49 -71 67 -71 7 0 13 -4 13 -9 0 -5 19 -19 43 -30 46 -22 167 -30
                167 -11 0 6 7 10 15 10 22 0 115 93 115 116 0 11 -7 30 -17 43 -9 13 -26 42
                -38 65 -12 23 -41 77 -65 121 -23 44 -46 95 -50 113 -7 29 -6 32 16 32 14 0
                27 7 30 15 4 8 12 15 18 15 19 0 51 80 39 99 -14 23 -63 27 -63 6 0 -9 -12
                -27 -27 -41 l-28 -26 27 13 c15 6 30 20 33 31 4 10 15 18 26 18 31 0 20 -40
                -16 -61 -47 -28 -65 -23 -114 31 -44 48 -51 51 -116 61 -39 6 -79 15 -90 19
                -18 7 -171 37 -232 45 -13 2 -23 -2 -23 -9 0 -17 -35 -48 -43 -39 -4 3 -7 23
                -7 44 0 21 -4 41 -10 44 -5 3 -10 19 -10 35 0 16 -4 32 -10 35 -6 4 -10 -8
                -10 -29z m-74 -107 c5 -9 -1 -10 -20 -5 -16 4 -33 3 -39 -2 -7 -6 -46 -15 -87
                -22 -41 -7 -79 -15 -85 -18 -5 -3 -16 -7 -25 -8 -101 -17 -164 -38 -191 -66
                -16 -17 -32 -29 -35 -25 -12 11 62 67 96 72 19 4 44 10 55 15 11 5 49 15 85
                21 36 7 67 15 70 19 5 6 104 26 147 29 12 0 25 -4 29 -10z m225 1 c5 0 9 -4 9
                -10 0 -5 -13 -10 -29 -10 -16 0 -32 -6 -34 -12 -4 -9 -6 -8 -6 3 -1 20 20 41
                37 34 8 -3 18 -5 23 -5z m-811 -95 c0 -14 -4 -25 -10 -25 -5 0 -10 11 -10 25
                0 14 5 25 10 25 6 0 10 -11 10 -25z m642 -90 c-3 -53 0 -81 10 -92 11 -14 11
                -15 -2 -11 -11 3 -16 25 -18 91 -1 48 1 87 5 87 4 0 7 -34 5 -75z m-577 -371
                c-13 -21 -23 -24 -74 -24 -32 0 -60 3 -63 6 -5 5 44 107 115 237 l32 59 3
                -127 c2 -102 0 -132 -13 -151z m65 230 c0 -7 3 -14 8 -16 10 -4 35 -48 78
                -135 19 -40 40 -73 46 -73 6 0 6 -6 -2 -15 -18 -21 -102 -20 -129 3 -18 15
                -21 29 -23 150 -2 108 -1 129 10 115 6 -9 12 -22 12 -29z m1090 -88 c0 -128
                -1 -136 -22 -150 -24 -17 -123 -22 -132 -7 -4 5 10 38 29 73 19 34 40 74 47
                88 7 14 15 27 19 30 3 3 14 26 25 52 10 27 22 48 27 48 4 0 7 -60 7 -134z m47
                101 c6 -12 31 -60 55 -107 23 -47 51 -96 60 -109 24 -34 7 -51 -50 -51 -33 0
                -52 6 -69 22 -22 20 -23 29 -23 145 0 68 3 123 8 123 4 0 12 -10 19 -23z
                m-1017 -290 c0 -7 -14 -18 -30 -24 -28 -11 -30 -10 -25 8 6 17 1 19 -59 19
                -37 0 -75 -5 -85 -12 -20 -12 -51 -5 -51 12 0 6 46 10 125 10 95 0 125 -3 125
                -13z m1148 -4 c-2 -10 -13 -22 -25 -28 -11 -6 -28 -17 -37 -24 -23 -19 -20 3
                4 24 11 10 20 22 20 27 0 4 -36 8 -81 8 -73 0 -81 -2 -86 -20 -5 -20 -7 -20
                -24 -4 -11 9 -19 21 -19 26 0 4 57 8 126 8 113 0 125 -2 122 -17z"/>
                <path d="M1698 2030 c-25 -17 -52 -37 -58 -45 -7 -8 -18 -15 -24 -15 -6 0 -18
                -9 -26 -20 -8 -11 -18 -20 -22 -20 -5 0 -42 -26 -83 -57 -95 -73 -192 -145
                -213 -158 -9 -5 -41 -30 -72 -54 -30 -24 -61 -46 -69 -49 -8 -3 -22 -12 -30
                -19 -9 -8 -45 -36 -81 -63 -36 -27 -72 -56 -81 -65 -9 -8 -20 -15 -25 -15 -5
                -1 -22 -11 -38 -24 -29 -24 -29 -25 -23 -106 4 -45 11 -88 16 -94 5 -6 14 -31
                21 -55 6 -24 18 -50 26 -58 8 -8 14 -21 14 -29 0 -18 25 -18 44 1 8 8 18 15
                22 15 4 0 34 20 65 45 32 25 60 45 63 45 4 0 36 22 71 50 35 28 67 50 70 50 3
                0 11 4 18 10 38 31 147 110 151 110 3 0 37 24 76 53 130 97 194 142 212 149
                10 4 18 14 18 23 0 18 26 20 37 2 12 -19 99 -87 111 -87 7 0 17 -9 24 -20 7
                -11 17 -20 22 -20 5 0 24 -11 42 -25 18 -14 37 -25 43 -25 6 0 16 -9 23 -20 7
                -11 17 -20 23 -20 5 0 36 -20 67 -45 32 -25 61 -45 65 -45 4 0 33 -20 64 -45
                32 -25 60 -45 64 -45 4 0 33 -20 65 -45 32 -25 63 -45 69 -45 6 0 15 -9 21
                -20 6 -11 15 -20 21 -20 5 0 23 -10 39 -22 30 -21 30 -21 45 -2 15 20 35 78
                35 104 0 9 4 20 9 25 5 6 12 51 16 101 l7 91 -45 37 c-24 20 -47 36 -51 36 -4
                0 -36 23 -71 50 -35 28 -69 50 -74 50 -6 0 -15 9 -21 20 -6 11 -15 20 -21 20
                -10 0 -32 16 -207 147 -35 27 -71 53 -80 58 -10 6 -44 31 -77 56 -33 25 -68
                48 -77 52 -10 3 -18 11 -18 17 0 5 -5 10 -11 10 -6 0 -32 17 -58 38 -41 33
                -85 62 -94 62 -2 0 -24 -14 -49 -30z m82 -131 c0 -15 7 -19 33 -19 l32 -1 -25
                -22 c-38 -32 -30 -67 15 -67 27 0 34 4 37 23 2 12 8 22 13 22 6 0 14 -10 20
                -22 6 -15 18 -23 34 -23 l24 0 -20 -22 c-30 -34 -13 -68 34 -68 16 0 25 8 29
                24 7 29 34 24 34 -5 0 -14 7 -19 30 -19 34 0 41 -19 10 -27 -24 -6 -27 -48 -4
                -57 33 -13 54 -6 60 19 5 20 9 23 20 14 8 -6 14 -18 14 -25 0 -9 11 -14 30
                -14 35 0 38 -10 10 -35 -34 -31 -23 -55 26 -61 18 -2 24 4 29 27 6 24 9 28 21
                18 8 -6 14 -20 14 -30 0 -15 6 -18 24 -15 35 6 39 -4 14 -31 -27 -29 -16 -56
                24 -61 20 -2 27 2 32 23 8 31 30 28 34 -3 3 -17 10 -22 33 -22 l30 0 -22 -30
                c-19 -25 -20 -32 -9 -45 20 -24 64 -15 68 13 4 28 26 24 30 -6 3 -17 10 -22
                33 -22 33 0 36 -5 13 -27 -10 -11 -14 -26 -10 -45 6 -30 -10 -38 -32 -16 -9 9
                -18 9 -38 -1 -32 -14 -42 -7 -26 20 16 26 10 35 -43 60 -35 17 -48 18 -65 9
                -28 -15 -38 -5 -22 21 11 17 10 23 -10 40 -13 10 -38 27 -57 36 -31 15 -34 15
                -43 0 -16 -30 -31 -19 -25 18 5 32 3 37 -21 47 -15 7 -35 21 -44 32 l-17 21
                -27 -25 -27 -25 6 36 c4 29 1 40 -16 55 -37 32 -69 46 -81 34 -19 -19 -39 -12
                -32 13 5 20 -2 30 -40 57 -34 24 -49 29 -57 21 -20 -20 -37 -12 -37 17 -1 20
                -12 35 -45 60 -25 18 -45 28 -45 22 0 -6 -8 -14 -17 -17 -43 -16 -70 -44 -65
                -69 3 -17 0 -24 -11 -24 -9 0 -18 4 -22 9 -8 14 -65 -8 -65 -24 0 -7 -9 -15
                -20 -18 -17 -4 -19 -11 -14 -31 5 -20 2 -26 -9 -26 -9 0 -19 5 -23 11 -4 8 -9
                8 -17 0 -6 -6 -19 -11 -29 -11 -10 0 -18 -6 -18 -14 0 -7 -9 -16 -20 -19 -16
                -4 -18 -11 -13 -43 l6 -39 -22 19 c-22 20 -18 21 -93 -20 -24 -13 -26 -19 -21
                -52 6 -36 6 -37 -14 -19 -26 23 -29 22 -79 -15 -36 -28 -41 -37 -37 -60 5 -27
                5 -28 -25 -17 -25 9 -37 7 -64 -8 -54 -30 -61 -39 -46 -62 16 -26 6 -36 -22
                -21 -17 9 -29 9 -48 1 -31 -15 -40 -6 -23 21 12 19 11 24 -8 39 -28 22 -27 27
                7 31 19 2 28 9 30 26 2 12 9 22 15 22 7 0 17 -10 22 -22 12 -28 53 -31 69 -6
                8 14 5 22 -14 43 l-23 25 27 0 c19 0 29 7 36 24 12 32 30 42 30 18 0 -41 51
                -55 74 -20 8 13 5 22 -14 43 -13 14 -19 25 -14 26 5 1 20 2 34 2 17 2 24 7 22
                18 -1 9 5 22 13 29 13 10 15 9 15 -8 0 -17 12 -34 30 -43 3 -1 15 3 28 10 27
                15 29 47 2 61 -31 16 -24 30 15 30 28 0 35 4 35 19 0 30 17 34 24 5 6 -21 12
                -25 39 -22 40 4 50 40 18 60 -30 19 -26 28 14 28 28 0 35 4 35 19 0 31 16 33
                29 5 9 -20 17 -25 39 -22 36 4 42 41 10 68 l-23 19 37 1 c29 0 37 4 40 20 4
                29 21 30 33 2 7 -16 17 -22 35 -20 35 4 39 42 8 68 l-23 19 38 1 c29 0 37 4
                37 18 0 10 3 21 6 25 11 11 34 -5 34 -24z"/>
                <path d="M2148 1229 c-10 -5 -18 -15 -18 -21 0 -7 5 -7 14 1 10 8 16 9 21 1 3
                -5 0 -10 -8 -10 -23 0 -27 -23 -27 -141 0 -61 -4 -108 -8 -105 -9 6 -7 185 3
                224 3 12 1 22 -4 22 -10 0 -21 -35 -21 -71 l0 -27 -298 -1 c-175 -1 -309 2
                -324 8 -31 12 -68 4 -68 -15 0 -16 -16 -19 -25 -4 -8 13 -265 13 -265 0 0 -5
                52 -11 123 -13 l122 -3 -133 0 -132 1 0 -32 c0 -30 3 -33 30 -33 29 0 29 0 32
                -67 l3 -68 95 0 c147 -1 140 -4 140 74 1 53 3 63 13 51 10 -13 60 -15 350 -13
                l337 2 0 -24 c0 -14 5 -25 10 -25 6 0 10 -8 10 -18 0 -29 35 -54 84 -59 54 -7
                86 11 111 59 21 40 33 192 16 202 -6 4 -11 18 -11 31 0 37 -20 73 -44 79 -34
                9 -109 6 -128 -5z m102 -54 c19 -20 22 -30 15 -62 -9 -44 -9 -73 1 -126 11
                -62 -36 -98 -78 -60 -21 18 -25 82 -6 101 9 9 9 18 0 37 -24 52 -8 135 26 135
                10 0 29 -11 42 -25z m46 -101 c-3 -8 -10 -14 -15 -14 -7 0 -7 9 -1 26 6 14 11
                37 12 52 1 18 3 14 5 -12 3 -21 2 -44 -1 -52z m-836 25 c0 -11 -24 -39 -34
                -39 -11 0 -6 26 6 38 15 15 28 15 28 1z m610 -14 c-7 -8 -16 -15 -21 -15 -5 0
                -6 7 -3 15 4 8 13 15 21 15 13 0 13 -3 3 -15z m-177 -12 c-73 -2 -193 -2 -265
                0 -73 1 -14 3 132 3 146 0 205 -2 133 -3z m413 -78 c7 -40 0 -65 -18 -64 -10
                0 -10 2 0 6 14 6 16 59 2 68 -13 8 -13 25 0 25 5 0 12 -16 16 -35z m-960 0 c4
                -8 3 -23 -2 -32 -7 -16 -8 -16 -11 4 -4 18 -11 21 -54 21 -36 1 -49 -3 -49
                -14 0 -8 -4 -14 -10 -14 -11 0 -14 33 -3 43 3 4 33 7 65 7 43 0 60 -4 64 -15z
                m-159 -77 c-2 -18 -4 -6 -4 27 0 33 2 48 4 33 2 -15 2 -42 0 -60z m193 32 c0
                -29 -4 -40 -15 -40 -8 0 -15 5 -15 10 0 6 5 10 10 10 6 0 10 14 10 30 0 17 2
                30 5 30 3 0 5 -18 5 -40z m-130 -25 c0 -8 -7 -15 -15 -15 -26 0 -18 20 13 29
                1 1 2 -6 2 -14z"/>
                <path d="M2890 2680 c0 -29 -4 -40 -15 -40 -8 0 -24 -6 -35 -12 -19 -12 -18
                -13 13 -28 25 -12 33 -22 35 -48 4 -38 8 -39 33 -8 16 21 25 23 60 19 l42 -6
                -23 31 -24 32 22 30 c21 28 21 30 4 30 -10 0 -23 -5 -29 -11 -15 -15 -63 14
                -63 36 0 8 -4 15 -10 15 -5 0 -10 -18 -10 -40z"/>
                <path d="M560 2675 c-10 -27 -24 -30 -64 -15 -31 11 -33 1 -6 -33 l20 -25 -22
                -27 -22 -28 42 7 c39 6 43 4 52 -19 15 -39 40 -32 40 10 0 29 5 37 25 45 32
                12 31 16 -5 35 -23 12 -30 22 -30 45 0 36 -17 39 -30 5z"/>
                <path d="M250 2231 c0 -11 -4 -22 -10 -26 -5 -3 -7 -12 -3 -20 3 -8 0 -15 -6
                -15 -6 0 -11 -7 -11 -16 0 -12 6 -15 24 -10 25 6 113 -8 154 -24 12 -5 22 -17
                22 -26 0 -13 3 -14 14 -5 8 7 12 21 9 31 -3 11 0 20 6 20 6 0 11 8 11 18 0 15
                -9 18 -52 18 -56 1 -138 31 -138 51 0 6 -4 15 -10 18 -5 3 -10 -3 -10 -14z"/>
                <path d="M3190 2236 c0 -8 10 -16 23 -18 17 -2 23 -10 25 -40 3 -33 1 -37 -28
                -43 -40 -9 -48 -1 -25 25 20 23 13 25 -44 14 -34 -7 -43 -34 -11 -34 11 0 20
                -4 20 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5 -8 -10 -19 -10 -26 0
                -33 37 -13 68 16 25 16 25 -8 19 -14 -4 -28 -7 -32 -7 -13 0 -9 -36 7 -66 8
                -16 15 -39 15 -52 0 -28 29 -41 42 -19 5 10 17 17 28 17 10 0 20 5 22 12 3 7
                29 11 66 12 68 1 74 8 47 59 -8 15 -12 34 -9 41 3 8 1 17 -5 21 -6 3 -11 15
                -11 26 0 14 -7 19 -30 19 -19 0 -30 -5 -30 -14z"/>
                <path d="M206 2046 c-8 -42 -3 -54 16 -39 7 6 38 8 71 5 l58 -5 -35 -23 c-19
                -13 -37 -24 -41 -24 -13 0 -90 -63 -90 -73 0 -7 22 -15 50 -19 28 -4 69 -10
                93 -14 24 -3 46 -13 49 -21 4 -10 10 -6 21 16 15 31 11 61 -8 61 -5 0 -10 -7
                -10 -16 0 -12 -10 -14 -52 -12 -29 2 -53 7 -53 11 0 5 14 16 30 25 17 9 33 21
                36 25 3 4 21 16 40 25 27 13 35 24 37 50 4 36 -4 41 -24 15 -11 -15 -16 -16
                -33 -5 -12 6 -34 12 -50 12 -51 0 -90 12 -94 29 -1 9 -6 -1 -11 -23z"/>
                <path d="M3230 1997 c0 -7 14 -18 31 -24 27 -11 30 -15 27 -49 -2 -29 -10 -41
                -33 -55 -16 -10 -39 -18 -51 -18 -32 -1 -81 27 -87 50 -8 28 9 69 28 69 8 0
                15 7 15 15 0 18 -45 20 -57 3 -11 -17 -12 -118 -1 -118 4 0 8 -6 8 -14 0 -25
                55 -56 99 -56 33 0 47 6 76 35 28 29 35 43 35 76 0 23 -7 47 -16 56 -8 9 -13
                22 -10 29 4 10 -4 14 -29 14 -22 0 -35 -5 -35 -13z"/>
                <path d="M330 1791 c0 -5 9 -11 21 -14 16 -4 20 -12 17 -34 -3 -28 -4 -28 -81
                -31 -72 -3 -79 -1 -84 18 -10 39 -23 20 -23 -35 0 -56 8 -67 30 -40 13 15 121
                22 154 9 22 -8 20 -31 -4 -44 -31 -16 -24 -32 13 -28 36 3 34 -9 21 146 -6 61
                -6 62 -35 62 -16 0 -29 -4 -29 -9z"/>
                <path d="M3111 1711 c-1 -42 2 -51 12 -43 15 13 116 21 123 10 3 -4 14 -8 25
                -8 10 0 19 -4 19 -10 0 -5 7 -10 15 -10 10 0 15 10 15 28 0 15 3 37 6 50 7 25
                -7 29 -30 7 -10 -10 -36 -15 -84 -15 -61 0 -72 3 -85 23 -15 21 -16 20 -16
                -32z"/>
                <path d="M3100 1590 c0 -45 0 -45 16 -42 8 2 37 -9 65 -25 42 -22 47 -28 32
                -34 -10 -4 -29 -8 -43 -8 -15 -1 -31 -6 -38 -13 -9 -9 -16 -7 -32 7 -11 10
                -22 16 -24 14 -9 -10 -14 -109 -6 -109 6 0 10 7 10 15 0 10 14 17 40 21 22 4
                40 10 40 14 0 5 32 11 70 15 59 6 70 10 70 26 0 12 -9 21 -25 25 -14 3 -25 10
                -25 15 0 5 -8 13 -18 17 -38 15 -112 68 -112 80 0 6 -4 12 -10 12 -5 0 -10
                -13 -10 -30z"/>
                <path d="M387 1556 c-3 -8 -23 -18 -44 -22 -21 -4 -43 -11 -49 -15 -6 -5 -19
                -7 -28 -3 -9 3 -23 1 -31 -6 -12 -10 -15 -9 -15 4 0 9 -4 16 -10 16 -13 0 -12
                -27 3 -115 9 -52 17 -71 30 -73 9 -2 17 1 17 7 0 6 7 11 15 11 8 0 15 5 15 10
                0 6 -8 10 -17 10 -18 0 -46 46 -38 61 8 12 68 40 74 34 3 -3 0 -15 -7 -25 -20
                -32 -14 -38 33 -32 30 3 45 1 45 -7 0 -11 42 -16 52 -6 9 9 -14 140 -27 153
                -10 11 -13 11 -18 -2z m16 -93 c-2 -39 -3 -43 -13 -43 -5 0 -10 7 -10 15 0 8
                -9 15 -20 15 -22 0 -23 1 -14 25 4 10 16 15 33 13 19 -2 25 -8 24 -25z"/>
                <path d="M430 1340 c0 -13 -9 -22 -27 -26 -16 -4 -33 -11 -40 -16 -20 -15 -72
                -27 -78 -17 -4 5 -13 9 -21 9 -18 0 -18 -30 0 -55 8 -11 12 -25 9 -32 -3 -7 2
                -13 11 -13 9 0 16 8 16 20 0 11 4 20 9 20 5 0 17 3 26 7 14 5 16 2 10 -18 -4
                -13 -17 -36 -28 -50 -15 -21 -17 -33 -10 -55 14 -42 26 -38 53 19 l24 52 56 0
                c53 0 55 1 58 29 2 16 -1 39 -6 50 -5 12 -17 38 -25 59 -18 40 -37 49 -37 17z
                m33 -80 c7 -27 -29 -53 -55 -39 -24 13 -23 24 4 43 30 21 45 20 51 -4z"/>
                <path d="M3041 1334 c-12 -15 -21 -32 -21 -39 0 -7 -7 -26 -15 -41 -25 -49
                -15 -72 18 -40 8 8 18 11 23 7 5 -5 34 -16 64 -26 30 -9 62 -25 71 -36 18 -20
                29 -15 29 13 0 10 5 18 10 18 6 0 10 11 10 25 0 26 -9 32 -26 15 -11 -11 -56
                -1 -48 11 3 5 14 9 25 9 10 0 19 5 19 10 0 6 9 10 19 10 17 0 65 59 56 68 -5
                5 -96 -28 -112 -41 -9 -7 -13 -4 -13 10 0 52 -75 70 -109 27z m67 -36 c17 -17
                15 -48 -2 -48 -8 0 -18 5 -21 10 -3 6 -15 10 -26 10 -10 0 -19 4 -19 8 0 14
                23 32 40 32 9 0 21 -5 28 -12z"/>
                <path d="M2935 1105 c-19 -41 -35 -76 -35 -79 0 -2 15 -4 33 -3 38 1 128 -48
                135 -75 2 -10 11 -18 18 -18 8 0 13 3 12 8 -1 4 10 26 25 50 40 62 50 92 36
                108 -6 8 -20 14 -30 14 -16 0 -17 -2 -4 -27 13 -24 12 -29 -2 -46 -22 -23 -76
                -20 -81 6 -3 13 3 17 27 17 17 0 31 5 31 10 0 6 -7 10 -15 10 -9 0 -27 7 -40
                16 -26 17 -55 10 -36 -9 19 -19 12 -29 -16 -22 -34 8 -53 26 -35 32 6 3 10 8
                7 13 -7 11 15 27 45 33 25 4 25 4 3 12 -13 4 -23 11 -23 16 0 28 -24 0 -55
                -66z"/>
                <path d="M520 1132 c0 -23 -20 -40 -82 -75 -27 -14 -56 -23 -68 -20 -26 7 -26
                -14 -1 -44 21 -26 46 -31 38 -8 -6 14 40 55 61 55 6 0 12 6 15 13 3 8 7 6 12
                -7 5 -10 3 -21 -2 -25 -6 -3 -16 -32 -23 -63 -7 -32 -17 -58 -22 -58 -11 0
                -10 -27 2 -35 5 -3 27 6 49 20 23 14 45 25 51 25 5 0 10 4 10 8 0 12 59 32 76
                25 17 -6 17 1 2 44 -13 35 -38 45 -38 15 0 -22 -25 -47 -61 -61 -25 -9 -26 -8
                -21 12 2 12 8 27 12 32 16 21 31 100 25 132 -6 35 -35 47 -35 15z"/>
                <path d="M2838 960 c-30 -33 -33 -46 -19 -83 13 -33 50 -40 106 -21 50 17 75
                13 75 -10 0 -28 -28 -45 -65 -39 -43 7 -45 -7 -4 -31 l30 -18 34 34 c44 42 49
                97 10 122 -32 21 -47 20 -90 -4 -47 -27 -65 -26 -65 4 0 28 15 36 53 29 34 -7
                35 10 2 31 -32 21 -35 21 -67 -14z"/>
                <path d="M645 854 c-22 -9 -48 -16 -57 -16 -10 1 -20 -3 -23 -9 -4 -5 -24 -9
                -46 -9 l-40 0 21 -22 c11 -12 20 -25 20 -30 0 -4 7 -8 15 -8 8 0 15 9 15 19 0
                31 30 34 57 7 28 -28 27 -61 -2 -62 -11 -1 -24 -2 -28 -3 -11 -1 52 -81 64
                -81 5 0 9 14 9 30 0 31 7 48 47 110 12 19 25 47 28 63 6 24 4 27 -16 27 -13
                -1 -42 -8 -64 -16z m15 -21 c-1 -19 -18 -33 -34 -27 -15 6 -14 8 6 19 29 17
                28 16 28 8z"/>
                <path d="M788 773 c19 -8 14 -29 -15 -61 -16 -16 -36 -41 -46 -56 -13 -19 -24
                -25 -38 -21 -35 11 -21 -23 27 -67 78 -72 82 -74 110 -47 33 31 30 41 -11 33
                -27 -5 -41 -2 -55 11 -11 10 -20 22 -20 27 0 5 23 35 51 67 39 44 56 57 71 53
                20 -5 20 -4 3 22 -20 30 -45 46 -72 45 -10 0 -13 -3 -5 -6z"/>
                <path d="M2559 676 c-30 -23 -60 -50 -68 -59 -12 -15 -11 -17 12 -17 30 0 107
                -89 107 -125 0 -35 9 -35 54 -2 24 18 56 41 72 53 27 20 28 23 15 48 -17 31
                -31 34 -31 6 0 -22 -33 -60 -52 -60 -7 0 -21 12 -32 26 l-19 26 32 -7 c24 -5
                31 -3 31 9 0 9 -4 16 -10 16 -5 0 -10 6 -10 13 0 14 -31 47 -43 47 -4 0 -7
                -15 -7 -32 l0 -33 -24 30 -24 30 21 15 c12 8 32 13 45 11 21 -2 22 0 13 18
                -18 33 -25 32 -82 -13z"/>
                <path d="M2418 582 c-33 -20 -36 -32 -8 -32 26 0 82 -112 71 -143 -10 -27 -56
                -43 -76 -27 -28 23 -66 109 -59 135 7 30 3 30 -48 8 -43 -19 -48 -34 -16 -48
                12 -6 26 -24 32 -40 6 -17 20 -45 31 -64 19 -32 22 -33 72 -29 66 6 93 28 93
                77 0 35 -35 119 -55 133 -6 5 -5 12 3 20 30 30 3 37 -40 10z"/>
                <path d="M1153 501 c0 -20 -9 -57 -21 -83 -12 -26 -22 -52 -22 -57 0 -6 -9
                -11 -20 -11 -11 0 -20 -4 -20 -10 0 -5 9 -10 20 -10 11 0 20 -4 20 -9 0 -14
                52 -23 63 -12 7 7 6 12 -5 16 -10 4 -13 15 -10 31 7 38 29 28 37 -17 8 -46 35
                -79 65 -79 23 0 27 28 6 36 -8 3 -17 16 -20 29 -3 13 -8 30 -12 38 -3 10 5 18
                25 25 27 10 31 15 31 49 0 48 -8 60 -45 69 -17 4 -39 12 -50 17 -36 19 -42 16
                -42 -22z m93 -35 c7 -19 -13 -56 -31 -56 -19 0 -24 11 -18 43 6 29 39 38 49
                13z"/>
                <path d="M2172 469 c-23 -9 -22 -29 1 -29 11 0 47 -68 47 -90 0 -13 -33 0 -43
                17 -4 7 -13 13 -18 13 -6 0 -27 14 -48 30 -30 25 -43 29 -69 24 -37 -8 -40
                -14 -12 -32 11 -7 20 -19 20 -28 0 -9 6 -30 14 -47 22 -50 25 -75 10 -88 -23
                -19 -16 -29 19 -22 47 8 54 19 30 45 -20 22 -47 97 -38 106 2 3 24 -11 47 -30
                48 -39 108 -78 122 -78 11 0 -3 91 -17 108 -6 7 -11 36 -11 63 -1 52 -8 57
                -54 38z"/>
                <path d="M1360 446 c0 -7 7 -19 15 -26 9 -8 12 -17 6 -24 -4 -6 -11 -32 -15
                -57 -4 -25 -16 -56 -27 -68 -12 -12 -19 -23 -18 -25 15 -11 74 -26 122 -32
                l57 -6 0 32 0 31 -26 -20 c-24 -19 -27 -19 -51 -4 -17 12 -23 22 -19 37 3 12
                6 28 6 36 1 11 7 8 21 -9 19 -23 20 -23 34 -5 15 20 20 56 8 67 -3 4 -11 -1
                -18 -10 -14 -19 -35 -9 -35 16 0 29 25 45 49 31 12 -6 21 -17 21 -25 0 -8 7
                -15 16 -15 8 0 13 4 10 9 -4 5 -1 11 6 13 6 2 9 11 6 20 -5 11 -26 18 -72 23
                -36 3 -68 11 -71 16 -9 14 -25 11 -25 -5z"/>
                <path d="M1556 411 c-3 -5 1 -14 9 -21 8 -7 15 -18 15 -24 0 -6 9 -27 19 -47
                11 -20 22 -52 26 -70 4 -19 13 -42 20 -53 15 -19 35 -14 35 10 0 8 4 14 9 14
                5 0 12 14 16 31 11 48 56 129 72 129 8 0 13 6 11 12 -3 8 -19 12 -44 9 -45 -3
                -54 -9 -35 -20 15 -10 1 -73 -24 -105 -14 -19 -15 -19 -21 0 -3 10 -12 33 -20
                51 -18 40 -18 49 2 57 24 9 8 22 -25 20 -17 -1 -34 2 -37 7 -7 11 -21 12 -28
                0z"/>
                <path d="M1875 410 c-3 -5 -19 -8 -36 -6 -33 4 -49 -9 -21 -18 24 -8 45 -151
                25 -168 -23 -19 -15 -38 16 -38 17 0 33 5 36 10 3 6 31 10 61 10 53 0 54 0 54
                30 0 35 -10 38 -28 10 -15 -24 -72 -28 -72 -5 0 8 -4 15 -10 15 -5 0 -10 12
                -10 26 0 20 4 25 16 20 9 -3 18 -6 20 -6 2 0 4 -7 4 -15 0 -8 7 -15 16 -15 13
                0 14 8 8 48 -8 58 -12 63 -31 35 -21 -32 -30 -29 -35 12 -4 33 -2 35 24 35 16
                0 37 -5 48 -12 10 -7 23 -8 27 -4 4 5 2 11 -4 13 -7 3 -13 11 -13 19 0 16 -85
                20 -95 4z"/>
                </g>
            </svg>
        </div>
    );
}

export default Irs;
