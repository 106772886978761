import React from 'react';

const Hm = () => {
    return (
        <div>
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="110.000000pt" height="100.000000pt" viewBox="0 0 1200.000000 716.000000"
 preserveAspectRatio="xMidYMid meet" className='w-16 md:w-auto'>

<g transform="translate(0.000000,716.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M0 3600 l0 -3560 85 0 85 0 0 3560 0 3560 -85 0 -85 0 0 -3560z"/>
<path d="M2172 7149 c-638 -86 -1141 -579 -1238 -1214 -19 -124 -14 -383 11
-495 132 -603 594 -1041 1200 -1137 123 -19 381 -13 495 12 565 125 987 538
1117 1094 36 157 43 384 15 546 -103 599 -556 1064 -1152 1180 -102 20 -347
28 -448 14z m484 -193 c489 -123 863 -522 949 -1010 19 -114 19 -328 0 -442
-79 -453 -417 -843 -849 -983 -126 -40 -166 -49 -292 -61 -284 -26 -573 49
-824 215 -96 64 -271 239 -335 335 -148 224 -218 454 -217 720 1 546 353 1030
872 1199 154 50 233 61 425 57 141 -3 187 -8 271 -30z"/>
<path d="M2230 6725 c0 -3 16 -11 35 -20 24 -10 40 -25 50 -49 19 -46 19 -46
-9 -46 -28 0 -72 33 -81 60 -15 48 -25 12 -25 -90 0 -60 2 -110 5 -110 3 0 23
18 44 40 43 46 87 56 76 18 -12 -41 -36 -69 -66 -80 -56 -19 -28 -28 90 -28
113 0 162 12 107 26 -31 7 -63 41 -71 77 -5 19 -3 27 6 27 22 0 77 -37 89 -59
22 -43 30 -20 30 89 0 109 -10 138 -30 86 -11 -30 -47 -56 -77 -56 -27 0 -27
1 -8 46 10 24 26 39 50 49 19 9 35 17 35 20 0 3 -56 5 -125 5 -69 0 -125 -2
-125 -5z"/>
<path d="M2294 6391 c-91 -56 -47 -201 61 -201 45 0 91 33 105 74 23 72 -30
146 -105 146 -16 0 -44 -9 -61 -19z"/>
<path d="M1748 6264 c-9 -19 -9 -30 0 -44 10 -16 7 -20 -18 -29 -28 -9 -30 -8
-30 14 0 24 -25 45 -54 45 -42 0 -62 -70 -26 -92 18 -11 18 -14 3 -30 -21 -23
-29 -23 -36 2 -7 28 -53 37 -77 15 -30 -27 -25 -63 10 -83 34 -19 36 -25 16
-41 -10 -8 -16 -9 -21 -1 -12 19 -54 11 -71 -15 -15 -23 -15 -27 0 -50 9 -14
26 -25 37 -25 31 0 21 -42 -11 -47 -26 -4 -50 -28 -50 -50 0 -20 37 -53 60
-53 11 0 20 -6 20 -13 0 -7 -9 -20 -20 -30 -39 -33 -18 -87 34 -87 29 0 36 -5
45 -31 8 -23 15 -30 27 -25 8 3 24 6 34 6 17 0 15 9 -18 83 -61 139 -48 262
38 361 80 90 189 109 353 62 98 -29 217 -80 229 -99 4 -7 8 -92 8 -189 l0
-178 30 0 30 0 0 195 c0 180 -8 245 -30 245 -3 0 -14 6 -24 13 -16 12 -17 16
-5 34 35 57 -50 104 -87 48 -9 -14 -23 -25 -30 -25 -17 0 -17 5 -2 29 19 31
-4 71 -40 71 -34 0 -52 -16 -52 -47 0 -24 -15 -29 -39 -14 -9 6 -10 11 -2 15
29 18 -3 86 -41 86 -21 0 -48 -33 -48 -58 0 -17 -6 -22 -25 -22 -21 0 -25 5
-25 28 0 36 -16 52 -52 52 -21 0 -32 -7 -40 -26z"/>
<path d="M2742 6274 c-23 -16 -30 -58 -11 -70 8 -4 7 -9 -2 -15 -24 -15 -39
-10 -39 14 0 30 -18 47 -50 47 -14 0 -31 -5 -38 -12 -15 -15 -16 -54 -2 -63
16 -10 12 -25 -8 -25 -9 0 -22 10 -27 23 -7 15 -20 23 -42 25 -27 3 -35 -1
-45 -22 -8 -20 -8 -32 0 -51 6 -14 8 -25 3 -25 -4 0 -20 -11 -35 -25 l-26 -24
0 -206 0 -205 30 0 30 0 0 178 c0 97 4 182 8 189 25 39 282 124 378 124 162 1
279 -125 278 -301 0 -52 -8 -81 -36 -147 -33 -74 -35 -83 -18 -83 10 0 26 -3
34 -6 12 -5 19 2 27 25 9 26 16 31 45 31 24 0 37 6 44 21 15 28 5 66 -20 74
-27 8 -15 32 20 39 72 14 56 106 -17 97 -8 -1 -13 9 -13 24 0 18 5 25 19 25
25 0 50 35 44 61 -8 30 -49 46 -75 30 -17 -11 -22 -10 -29 1 -12 18 -11 28 1
28 17 0 50 39 50 60 0 11 -11 27 -25 36 -21 14 -31 15 -50 6 -14 -7 -25 -18
-25 -27 0 -20 -13 -19 -33 3 -15 16 -15 19 3 30 26 16 25 54 0 77 -29 26 -74
10 -78 -27 -3 -25 -5 -26 -32 -16 -25 8 -28 12 -18 28 9 14 9 25 0 44 -23 51
-109 20 -95 -35 4 -15 0 -19 -21 -19 -21 0 -26 5 -26 24 0 21 -31 56 -50 56
-3 0 -16 -7 -28 -16z"/>
<path d="M2326 6164 c-9 -8 -16 -21 -16 -28 0 -15 32 -46 47 -46 13 0 43 33
43 48 0 15 -29 42 -45 42 -7 0 -21 -7 -29 -16z"/>
<path d="M2322 6058 c-27 -27 -6 -68 35 -68 31 0 52 42 33 65 -15 18 -51 20
-68 3z"/>
<path d="M2326 5954 c-30 -30 -13 -74 29 -74 42 0 59 44 29 74 -8 9 -22 16
-29 16 -7 0 -21 -7 -29 -16z"/>
<path d="M2322 5838 c-28 -28 -7 -78 33 -78 27 0 45 18 45 45 0 27 -18 45 -45
45 -12 0 -26 -5 -33 -12z"/>
<path d="M1900 5738 c-35 -57 -37 -113 -7 -198 32 -90 33 -98 9 -77 -10 9 -32
19 -49 23 -25 5 -34 1 -52 -21 -11 -15 -21 -39 -21 -52 0 -31 25 -79 45 -87
29 -11 33 -7 22 23 -20 53 19 84 56 43 20 -22 21 -42 6 -75 -16 -36 -57 -54
-139 -62 -68 -6 -78 -5 -109 17 -35 23 -78 84 -68 95 13 13 63 -11 100 -48 22
-22 42 -38 44 -35 2 2 -3 57 -11 122 -9 65 -16 124 -16 131 0 27 -18 12 -24
-20 -7 -38 -37 -70 -78 -86 -23 -9 -28 -8 -28 5 0 31 29 82 61 107 18 14 31
28 28 31 -6 6 -127 -12 -141 -20 -12 -8 -10 -46 14 -211 l21 -152 39 -26 c21
-14 38 -28 38 -31 0 -3 -14 -25 -31 -50 -16 -24 -27 -49 -24 -54 4 -6 276 -10
771 -10 724 0 764 1 764 18 0 9 -11 34 -25 54 -14 20 -25 39 -25 42 0 3 17 17
37 31 l38 25 22 148 c24 160 27 208 15 216 -14 8 -135 26 -141 20 -3 -3 10
-17 28 -31 32 -25 61 -76 61 -107 0 -13 -5 -14 -27 -5 -42 16 -72 48 -79 86
-3 18 -10 33 -15 33 -5 0 -9 -3 -9 -7 0 -4 -7 -63 -16 -131 -9 -68 -14 -126
-11 -128 3 -3 22 13 44 35 35 38 103 67 103 45 0 -21 -42 -76 -72 -95 -29 -17
-43 -19 -108 -13 -82 7 -106 15 -132 48 -33 41 -12 106 35 106 21 0 32 -42 17
-69 -6 -12 -7 -21 -1 -21 65 0 94 116 38 155 -29 20 -49 19 -81 -6 -32 -25
-32 -18 0 67 29 79 27 147 -6 202 -24 39 -32 40 -63 2 -37 -44 -49 -113 -32
-186 8 -32 14 -68 15 -78 0 -18 -1 -18 -23 2 -31 29 -81 29 -101 0 -31 -43
-17 -116 26 -139 19 -10 20 -9 14 16 -10 40 1 65 30 65 30 0 49 -37 40 -75 -4
-14 -21 -35 -39 -46 -27 -17 -46 -19 -129 -17 -86 3 -99 6 -122 27 -24 22 -51
89 -41 99 10 10 82 -36 100 -65 11 -18 23 -32 28 -33 4 0 7 56 7 125 0 77 -4
125 -10 125 -5 0 -10 -7 -10 -16 0 -27 -56 -74 -89 -74 -28 0 -29 1 -21 31 12
41 28 59 68 76 60 25 36 33 -103 33 -139 0 -163 -8 -102 -33 39 -17 55 -35 67
-76 8 -30 7 -31 -21 -31 -33 0 -89 47 -89 74 0 9 -4 16 -10 16 -6 0 -10 -49
-10 -127 l0 -128 38 42 c20 24 49 47 65 53 25 9 27 8 27 -18 0 -69 -56 -102
-172 -102 -95 0 -143 21 -154 65 -9 38 10 75 40 75 29 0 40 -25 30 -65 -6 -25
-5 -26 14 -16 29 15 42 42 42 85 0 71 -69 100 -121 50 l-27 -26 18 80 c23 104
12 167 -38 225 -21 24 -29 22 -52 -15z m493 -600 l-37 -38 -38 37 -38 37 37
38 37 38 38 -37 38 -37 -37 -38z m-315 35 c4 -45 -19 -54 -131 -51 l-92 3 0
35 c0 33 2 35 40 41 22 4 72 6 110 5 l70 -1 3 -32z m732 29 c43 -7 45 -8 45
-42 l0 -35 -99 -3 c-64 -2 -103 1 -112 9 -14 12 -19 60 -7 72 8 9 113 8 173
-1z"/>
<path d="M2317 5709 c-30 -42 12 -92 59 -70 27 12 33 58 12 79 -19 19 -55 14
-71 -9z"/>
<path d="M1017 3583 c-4 -3 -7 -327 -7 -720 l0 -713 95 0 95 0 2 328 3 327
378 3 377 2 0 -330 0 -330 95 0 95 0 0 720 0 721 -92 -3 -93 -3 -3 -307 -2
-308 -380 0 -380 0 0 310 0 310 -88 0 c-49 0 -92 -3 -95 -7z"/>
<path d="M2420 2870 l0 -720 90 0 90 0 2 596 3 597 224 -597 224 -596 81 0 82
0 224 598 225 597 3 -597 2 -598 90 0 90 0 0 720 0 721 -131 -3 -131 -3 -224
-595 c-123 -327 -225 -599 -227 -604 -2 -5 -92 229 -202 520 -109 291 -211
564 -227 607 l-30 77 -129 0 -129 0 0 -720z"/>
<path d="M4690 2871 l0 -721 95 0 95 0 0 305 0 305 265 0 c217 0 272 -3 303
-15 102 -43 134 -126 152 -390 5 -77 14 -155 20 -172 l12 -33 108 0 109 0 -24
43 c-28 52 -42 114 -50 237 -9 133 -24 228 -44 275 -23 51 -88 110 -140 126
l-40 12 57 27 c115 56 180 160 190 305 12 199 -94 344 -289 396 -28 7 -186 13
-431 16 l-388 5 0 -721z m725 549 c112 -18 182 -94 192 -209 8 -90 -12 -156
-62 -207 -69 -68 -106 -76 -407 -81 l-258 -5 0 256 0 256 238 0 c130 0 264 -5
297 -10z"/>
<path d="M6268 3187 c-261 -89 -388 -368 -312 -686 43 -184 161 -312 329 -356
67 -17 208 -19 274 -4 147 33 279 166 307 307 l7 32 -81 0 -81 0 -22 -50 c-27
-63 -93 -123 -154 -140 -27 -8 -77 -11 -127 -8 -92 5 -161 33 -210 85 -38 40
-76 128 -84 196 l-7 57 393 0 393 0 -6 88 c-12 187 -91 348 -213 432 -103 72
-274 92 -406 47z m277 -154 c79 -42 147 -136 161 -222 l7 -41 -302 0 c-170 0
-301 4 -301 9 0 5 9 38 20 73 25 80 84 151 155 185 43 21 61 24 135 20 61 -2
96 -9 125 -24z"/>
<path d="M8198 3191 c-161 -53 -282 -191 -324 -368 -24 -100 -14 -274 19 -371
60 -173 174 -279 337 -313 82 -17 242 -6 313 20 112 44 194 132 237 256 11 32
20 60 20 62 0 1 -37 3 -82 3 l-83 0 -28 -60 c-46 -99 -117 -139 -249 -140
-181 -1 -305 109 -316 278 l-4 62 385 0 c308 0 386 3 390 13 10 26 -14 190
-37 258 -47 135 -145 248 -254 290 -81 32 -244 37 -324 10z m232 -142 c65 -20
131 -78 167 -148 19 -35 33 -78 33 -97 l0 -34 -295 0 -295 0 0 25 c0 88 94
217 181 248 53 18 157 21 209 6z"/>
<path d="M9329 3195 c-64 -20 -137 -72 -176 -123 l-33 -44 0 81 0 81 -80 0
-80 0 0 -520 0 -520 85 0 84 0 3 353 c3 348 3 353 27 397 56 108 126 152 252
158 93 5 128 -6 182 -56 52 -49 57 -90 57 -489 l0 -363 85 0 85 0 0 358 c0
200 -5 385 -11 421 -21 129 -75 208 -174 253 -64 28 -234 36 -306 13z"/>
<path d="M11378 3191 c-159 -52 -277 -184 -323 -362 -25 -94 -16 -278 18 -377
75 -216 225 -322 457 -322 225 0 378 105 434 298 l15 52 -82 0 -82 0 -28 -60
c-46 -99 -117 -139 -249 -140 -101 0 -172 26 -232 86 -52 52 -86 132 -86 204
l0 50 384 0 385 0 3 23 c12 89 -35 279 -93 368 -45 71 -130 144 -197 170 -81
32 -244 37 -324 10z m232 -142 c93 -28 183 -134 196 -232 l7 -47 -297 0 -296
0 0 25 c0 88 94 217 181 248 53 18 157 21 209 6z"/>
<path d="M6904 3168 c3 -13 88 -246 190 -518 l184 -495 93 0 92 0 184 500
c100 275 185 508 189 518 5 15 -3 17 -83 17 l-89 0 -138 -422 c-76 -233 -141
-427 -146 -431 -4 -5 -72 184 -151 420 l-144 428 -94 3 c-93 3 -93 3 -87 -20z"/>
<path d="M10022 2783 c3 -402 4 -409 27 -467 53 -133 165 -192 349 -184 78 3
108 9 152 30 56 26 109 70 148 123 l21 30 1 -82 0 -83 80 0 80 0 0 520 0 520
-85 0 -85 0 0 -324 c0 -300 -2 -329 -21 -391 -39 -128 -138 -195 -285 -195
-74 0 -108 11 -148 48 -65 61 -66 68 -66 485 l0 377 -86 0 -85 0 3 -407z"/>
<path d="M3323 1499 c-242 -24 -434 -173 -530 -409 -43 -108 -57 -193 -56
-350 1 -171 27 -292 89 -412 77 -148 220 -261 384 -305 89 -23 282 -23 369 0
243 66 406 262 433 522 l3 30 -92 3 c-102 3 -96 8 -107 -79 -9 -65 -58 -161
-110 -217 -154 -165 -459 -159 -627 11 -102 103 -152 254 -153 462 -1 270 90
453 267 540 248 121 540 19 608 -212 l12 -43 94 0 c105 0 102 -3 77 81 -77
263 -336 411 -661 378z"/>
<path d="M1315 1457 c-65 -22 -99 -43 -138 -86 -54 -60 -70 -104 -71 -196 -1
-96 20 -149 101 -262 52 -73 52 -73 30 -86 -159 -96 -235 -166 -275 -250 -71
-151 -34 -348 85 -457 64 -59 136 -88 254 -103 161 -20 314 29 423 136 l59 58
26 -31 c14 -16 46 -55 71 -85 l45 -54 113 -1 113 0 -125 144 c-68 79 -130 152
-136 163 -10 15 -7 29 14 77 31 69 52 148 61 229 l7 57 -80 0 -80 0 -6 -47
c-9 -63 -37 -163 -45 -163 -7 0 -244 285 -249 299 -1 5 29 31 67 59 134 97
197 210 189 337 -8 116 -60 190 -172 241 -71 32 -214 43 -281 21z m224 -166
c60 -43 78 -129 42 -206 -23 -51 -161 -180 -175 -164 -132 165 -160 240 -117
319 28 54 83 81 156 78 44 -3 70 -10 94 -27z m-19 -758 c88 -106 160 -197 160
-203 0 -22 -98 -108 -155 -135 -50 -24 -73 -29 -140 -29 -68 -1 -89 3 -137 27
-107 52 -161 156 -139 265 12 62 46 124 88 164 35 33 146 108 155 105 4 -1 79
-89 168 -194z"/>
<path d="M6280 1235 l0 -154 -87 -3 -88 -3 -3 -72 -3 -72 88 -3 88 -3 5 -385
c4 -332 7 -389 21 -415 39 -69 83 -85 241 -85 l119 0 -3 73 -3 72 -88 5 c-73
4 -91 9 -103 24 -11 16 -14 83 -14 368 l0 348 105 0 106 0 -3 73 -3 72 -102 3
-103 3 0 154 0 155 -85 0 -85 0 0 -155z"/>
<path d="M5468 1084 c-115 -27 -209 -96 -243 -176 -21 -52 -19 -162 4 -213 22
-50 79 -101 146 -131 27 -13 127 -41 220 -64 234 -56 288 -90 288 -184 0 -81
-49 -125 -160 -146 -75 -14 -167 -8 -235 15 -57 20 -113 78 -128 134 l-11 41
-83 0 -83 0 9 -57 c17 -108 84 -195 187 -244 25 -12 88 -29 140 -37 78 -13
109 -14 187 -4 211 27 329 115 354 266 19 120 -22 215 -120 274 -63 37 -104
51 -284 92 -211 49 -266 84 -266 167 0 37 6 51 33 78 73 73 279 73 366 -1 17
-14 39 -48 48 -75 l18 -49 83 0 84 0 -7 48 c-16 110 -77 192 -174 236 -101 45
-259 58 -373 30z"/>
<path d="M7083 1085 c-176 -38 -309 -173 -358 -360 -21 -80 -21 -261 0 -340
51 -194 181 -320 370 -360 88 -19 142 -19 230 0 103 22 184 66 246 133 57 62
110 166 128 250 15 74 13 235 -4 310 -66 277 -320 430 -612 367z m264 -169
c64 -32 126 -102 155 -175 19 -48 23 -74 23 -186 0 -152 -18 -212 -86 -288
-118 -135 -335 -137 -454 -5 -78 86 -113 222 -95 367 20 157 105 268 240 312
47 16 163 3 217 -25z"/>
<path d="M8264 1087 c-79 -20 -150 -67 -206 -135 l-28 -34 0 81 0 81 -80 0
-80 0 0 -520 0 -520 85 0 84 0 3 353 3 352 27 51 c97 184 364 210 425 42 8
-23 12 -148 12 -415 l1 -383 84 0 84 0 4 357 c3 338 4 360 24 403 69 148 299
202 401 94 48 -52 53 -94 53 -494 l0 -360 86 0 85 0 -3 423 c-3 416 -3 423
-26 472 -13 27 -33 60 -45 73 -55 59 -191 99 -301 89 -100 -9 -171 -41 -240
-108 -33 -33 -61 -59 -62 -59 -1 0 -7 13 -13 29 -18 41 -68 87 -125 112 -60
26 -180 34 -252 16z"/>
<path d="M9775 1088 c-176 -33 -286 -158 -272 -309 13 -149 106 -215 388 -279
206 -46 275 -87 286 -167 8 -64 -18 -107 -86 -140 -51 -25 -66 -28 -161 -28
-109 1 -175 19 -223 63 -25 22 -57 86 -57 112 0 19 -6 20 -85 20 -82 0 -85 -1
-85 -23 0 -13 7 -46 15 -73 49 -166 192 -249 425 -248 123 0 194 15 281 59
169 86 214 324 83 445 -61 56 -107 75 -310 125 -95 24 -189 51 -208 60 -110
52 -114 170 -8 217 90 40 239 33 309 -15 29 -20 63 -72 77 -119 5 -15 18 -18
87 -18 l82 0 -6 50 c-13 106 -76 193 -171 235 -94 41 -245 55 -361 33z"/>
<path d="M4174 1066 c-3 -8 -4 -189 -2 -402 3 -335 6 -396 21 -441 38 -113
122 -182 251 -204 165 -28 311 26 397 144 l29 40 0 -81 0 -82 80 0 80 0 0 520
0 521 -82 -3 -83 -3 -5 -350 c-5 -346 -5 -351 -29 -399 -54 -109 -138 -160
-266 -161 -101 0 -157 27 -195 95 l-25 45 -3 388 -3 387 -80 0 c-59 0 -81 -4
-85 -14z"/>
</g>
</svg>
        </div>
    );
}

export default Hm;
