import React from 'react'

export default function Mig() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="170.000000pt" viewBox="0 0 800.000000 1422.000000"
 preserveAspectRatio="xMidYMid meet" className='md:-mt-12'>

<g transform="translate(0.000000,1422.000000) scale(0.100000,-0.100000)"
fill="#fff" stroke="none">
<path d="M3540 10754 c-894 -135 -1680 -553 -2281 -1211 -239 -262 -488 -667
-642 -1044 -180 -441 -271 -880 -284 -1379 -4 -184 -2 -239 16 -380 34 -266
65 -413 137 -652 268 -891 848 -1644 1623 -2107 478 -286 999 -456 1601 -523
171 -18 558 -16 705 5 729 105 1356 375 1916 828 155 125 380 345 486 474 334
408 595 922 729 1435 81 309 114 554 121 905 5 244 3 285 -17 425 -54 376
-143 697 -284 1025 -47 108 -167 345 -211 415 -205 329 -348 512 -580 746
-138 140 -212 204 -340 301 -537 402 -1139 645 -1837 739 -71 10 -222 17 -403
19 -271 4 -301 3 -455 -21z m280 -184 c0 -5 -8 -10 -19 -10 -37 0 -41 -19 -30
-156 5 -71 12 -135 14 -141 2 -7 17 -13 32 -13 16 0 23 -4 18 -9 -10 -8 -165
-22 -165 -14 0 2 12 8 26 13 l26 10 -7 108 c-11 171 -15 186 -50 193 -31 6
-30 6 110 22 17 2 33 5 38 6 4 0 7 -3 7 -9z m253 -119 l125 -129 7 108 c4 60
4 113 0 119 -3 6 -17 11 -31 11 -13 0 -24 5 -24 11 0 6 7 8 16 5 9 -3 42 -6
75 -6 33 0 59 -4 59 -10 0 -5 -11 -10 -24 -10 -14 0 -27 -4 -30 -8 -3 -5 -10
-77 -17 -160 -7 -84 -15 -152 -19 -152 -4 0 -31 28 -61 63 -67 78 -190 207
-198 207 -3 0 -4 -55 -1 -122 5 -120 5 -122 30 -129 15 -4 -2 -7 -45 -7 -43 0
-61 3 -45 7 25 7 25 8 28 140 3 149 -5 171 -60 171 -15 0 -28 5 -28 10 0 6 26
10 59 10 l59 0 125 -129z m382 94 c54 -8 127 -20 162 -26 l63 -11 0 -39 c0
-44 -15 -52 -24 -14 -7 29 -26 40 -83 50 -42 7 -43 6 -43 -22 0 -15 -6 -62
-14 -103 -29 -159 -29 -158 1 -174 16 -8 21 -15 13 -15 -29 1 -155 27 -134 28
12 1 26 7 31 14 6 6 18 68 28 137 9 68 19 129 22 136 5 13 -28 24 -74 24 -30
0 -63 -21 -63 -41 0 -5 -3 -9 -8 -9 -4 0 -6 18 -4 40 2 22 9 40 16 40 6 0 56
-7 111 -15z m390 -79 c189 -51 196 -54 189 -84 -9 -36 -24 -52 -24 -25 0 32
-23 50 -88 68 -31 9 -64 18 -73 21 -13 3 -19 -7 -28 -43 -7 -27 -14 -55 -17
-64 -3 -11 10 -20 49 -32 67 -21 76 -21 99 0 19 17 19 16 12 -12 -4 -17 -13
-43 -20 -60 l-12 -30 -1 27 c-1 23 -9 29 -56 47 -31 12 -61 21 -68 21 -14 0
-51 -130 -40 -140 5 -4 38 -15 75 -25 66 -18 67 -17 104 4 20 12 38 20 39 19
2 -2 -9 -18 -23 -36 l-27 -33 -123 36 c-68 19 -132 35 -143 35 -10 0 -19 5
-19 11 0 6 8 8 19 4 10 -3 23 1 29 8 15 18 75 266 68 283 -3 8 -15 14 -26 14
-11 0 -20 5 -20 11 0 6 6 9 13 6 7 -2 57 -17 112 -31z m-1759 -22 c-20 -11
-39 -40 -66 -97 -53 -113 -55 -124 -30 -191 21 -56 22 -57 53 -50 18 3 30 4
27 1 -8 -8 -163 -57 -168 -53 -2 3 6 7 18 11 19 6 22 12 17 45 -9 69 -27 91
-122 147 -61 36 -101 53 -125 53 -19 0 -30 4 -25 9 15 13 165 65 171 58 3 -3
-4 -8 -15 -12 -37 -11 -23 -32 53 -78 l74 -45 31 70 c38 86 38 98 6 98 -43 0
-27 15 33 32 78 21 106 22 68 2z m2149 -106 c47 -16 50 -26 9 -24 l-38 1 -47
-115 c-26 -63 -48 -123 -48 -132 -1 -24 88 -68 136 -68 21 0 49 7 61 16 32 22
27 3 -8 -31 l-29 -28 -129 52 c-71 28 -134 51 -141 51 -6 0 -11 5 -11 10 0 6
10 8 23 5 31 -8 38 1 85 130 51 138 53 147 25 160 -47 20 -21 24 30 4 28 -12
66 -26 82 -31z m-2614 -104 c28 -26 24 -68 -12 -108 l-30 -35 68 -32 c44 -21
84 -32 115 -33 27 -1 48 -4 48 -7 0 -7 -79 -39 -96 -39 -6 0 -51 18 -99 40
-84 39 -125 45 -125 17 0 -7 13 -34 28 -62 26 -46 30 -50 62 -48 31 2 25 -2
-44 -37 -87 -44 -103 -49 -66 -20 l25 19 -67 128 -68 128 -37 -2 -38 -2 50 26
c174 92 241 108 286 67z m2935 -46 c83 -42 113 -67 54 -45 -24 9 -33 8 -44 -3
-21 -21 -126 -217 -126 -235 0 -15 87 -70 127 -81 12 -3 36 1 52 9 46 23 45 8
-2 -24 -35 -23 -44 -26 -57 -15 -20 17 -166 98 -207 115 -41 17 -42 26 -2 26
30 0 35 6 97 124 l65 124 -27 24 c-38 33 -25 29 70 -19z m-1284 -123 c556 -53
1079 -254 1509 -580 l55 -42 38 34 c47 44 60 35 38 -25 l-18 -47 157 -152
c403 -392 662 -823 809 -1348 235 -839 77 -1777 -418 -2485 -143 -204 -392
-472 -577 -621 -521 -420 -1198 -658 -1870 -659 -458 0 -963 125 -1370 340
-282 148 -478 292 -709 518 -338 333 -578 718 -734 1177 -149 440 -189 950
-111 1420 88 524 300 989 645 1410 84 103 328 347 399 400 22 17 42 32 45 35
15 16 97 75 190 137 572 380 1250 553 1922 488z m1624 -86 c42 -28 73 -53 71
-56 -3 -2 -15 1 -27 7 -28 15 -34 10 -120 -112 -70 -100 -80 -124 -58 -146 23
-23 -3 -12 -54 25 -74 51 -76 54 -43 48 29 -6 33 -1 104 102 41 59 76 116 79
126 3 13 -3 26 -19 38 -49 38 -5 17 67 -32z m-3744 -1 c40 -22 109 -63 152
-91 62 -39 85 -48 107 -43 16 3 29 1 29 -4 0 -6 -4 -10 -8 -10 -5 0 -34 -16
-66 -35 -61 -36 -78 -43 -58 -23 20 20 14 36 -24 62 l-36 24 -62 -42 c-53 -36
-61 -46 -64 -76 -2 -19 4 -44 11 -55 13 -16 17 -17 31 -6 9 8 19 12 21 9 3 -2
-17 -20 -43 -39 -51 -37 -74 -49 -54 -28 16 17 15 55 -2 181 -21 146 -26 218
-16 218 4 0 41 -19 82 -42z m-222 -113 c25 -27 22 -43 -5 -27 -24 13 -55 4
-93 -26 l-32 -27 81 -100 c112 -138 103 -131 136 -116 20 8 10 -4 -32 -43 -58
-54 -97 -76 -60 -36 18 20 18 21 -5 53 -42 57 -163 197 -171 197 -4 0 -23 -18
-43 -40 -36 -40 -44 -64 -26 -75 6 -3 10 -11 10 -18 0 -6 -15 3 -32 21 l-33
33 66 59 c74 66 201 169 210 170 3 0 16 -11 29 -25z m4235 -94 c23 -10 64 -44
92 -76 28 -31 56 -54 62 -50 6 3 11 3 11 -1 0 -11 -51 -84 -59 -84 -3 0 -5 20
-3 45 5 57 -27 103 -91 134 -55 27 -82 26 -131 -2 -100 -57 -114 -203 -25
-269 57 -42 64 -42 109 10 42 49 48 70 28 90 -7 7 -8 12 -2 12 5 -1 34 -23 64
-51 49 -44 52 -49 26 -43 -16 4 -31 2 -35 -3 -3 -6 -23 -29 -44 -52 l-38 -42
-44 20 c-109 48 -177 135 -179 226 0 61 18 95 74 133 42 28 127 30 185 3z
m-4581 -166 l-17 -26 106 -106 105 -107 26 17 c41 27 31 12 -34 -53 -60 -60
-71 -67 -48 -30 12 18 3 30 -93 126 l-106 106 -29 -18 c-21 -12 -13 0 26 41
74 80 93 95 64 50z m5036 -261 c0 -6 -11 -21 -25 -34 l-26 -24 6 39 c5 38 2
43 -50 99 l-56 60 -47 -47 -47 -48 52 -50 c45 -43 55 -48 73 -39 36 19 21 -7
-25 -44 -25 -21 -45 -33 -45 -29 0 5 5 14 11 20 17 17 3 45 -42 89 l-40 39
-51 -53 -51 -54 57 -56 c53 -54 59 -57 102 -55 51 2 49 -8 -6 -26 l-34 -11
-108 107 c-104 104 -125 131 -76 99 23 -15 26 -14 99 62 136 141 135 139 116
160 -9 10 -17 23 -17 28 0 5 52 -43 115 -106 63 -63 115 -120 115 -126z
m-5304 -36 c-9 -12 -16 -25 -16 -29 0 -9 216 -179 227 -179 11 0 75 76 92 109
8 15 11 39 7 65 l-6 41 26 -43 26 -43 -49 -57 c-27 -31 -71 -85 -97 -120 -26
-35 -51 -61 -54 -57 -3 3 0 11 6 17 7 7 12 17 12 24 0 14 -220 184 -237 184
-6 0 -16 -7 -23 -15 -7 -9 -15 -13 -17 -11 -3 3 21 37 52 76 32 39 60 69 62
66 3 -3 -2 -15 -11 -28z m5408 -138 c-6 -61 -31 -229 -40 -274 -6 -29 -5 -28
77 29 107 74 113 82 94 112 -9 13 -12 23 -7 23 5 0 24 -24 43 -52 43 -67 47
-77 21 -54 -12 10 -28 16 -37 12 -9 -3 -69 -42 -135 -87 -66 -45 -122 -80
-124 -78 -2 2 2 34 10 71 14 66 44 269 44 295 0 12 -166 -116 -182 -141 -4 -6
0 -21 8 -35 9 -13 12 -22 6 -20 -15 6 -94 109 -83 109 4 0 14 -7 21 -15 7 -8
17 -15 22 -15 14 0 206 159 212 177 4 8 -1 29 -9 46 -23 46 -18 46 25 -3 38
-44 39 -47 34 -100z m-5644 -190 c-11 -26 -11 -33 1 -44 23 -22 219 -136 233
-136 17 0 86 119 86 148 0 13 -5 32 -11 44 -20 38 0 30 27 -11 l27 -41 -40
-58 c-22 -31 -59 -90 -82 -130 -24 -41 -45 -71 -48 -69 -2 3 0 14 6 25 6 12
11 23 11 25 0 3 -33 24 -72 47 -40 24 -94 56 -119 72 -55 34 -73 35 -94 6 -31
-43 -10 7 35 84 45 77 63 94 40 38z m5915 -171 c48 -32 81 -83 98 -148 9 -35
20 -57 32 -61 15 -5 8 -13 -33 -38 -29 -18 -52 -30 -52 -27 0 2 10 20 21 40
47 78 -12 197 -105 212 -46 7 -135 -34 -166 -77 -52 -72 -16 -180 74 -226 47
-23 48 -25 17 -19 -131 21 -199 225 -108 324 52 57 154 66 222 20z m-6028 -19
c-14 -36 -17 -34 139 -115 l115 -60 20 24 c30 37 23 12 -21 -69 -33 -62 -39
-69 -35 -40 l6 35 -131 65 -132 65 -18 -22 c-33 -41 -32 -24 2 40 54 103 77
136 55 77z m-96 -193 c-5 -13 -6 -29 -2 -35 6 -10 201 -102 248 -116 16 -5 26
-2 35 13 7 12 16 21 20 21 6 0 -7 -30 -54 -130 l-17 -35 2 35 2 34 -110 47
c-60 26 -112 46 -114 44 -4 -4 70 -100 148 -193 24 -29 42 -54 39 -57 -2 -2
-63 -16 -136 -30 -194 -38 -193 -37 -87 -70 135 -41 154 -43 177 -14 17 20 19
21 13 4 -14 -41 -24 -71 -31 -100 l-8 -30 -5 35 -6 34 -125 37 c-69 21 -132
38 -141 38 -9 1 -22 -13 -30 -31 -20 -50 -21 -19 0 47 l19 59 123 28 c68 15
134 28 147 28 12 0 22 3 22 6 0 3 -41 57 -90 120 l-91 114 22 60 c12 33 25 60
30 60 5 0 5 -11 0 -23z m6354 -220 c30 -75 55 -140 55 -144 0 -9 -61 -35 -68
-29 -2 3 5 13 17 25 17 17 18 25 10 56 -16 56 -47 125 -57 125 -5 0 -33 -10
-61 -21 -57 -24 -56 -22 -20 -106 17 -41 26 -48 57 -44 30 4 7 -16 -37 -32
-62 -22 -68 -21 -46 3 19 21 17 49 -9 112 l-16 38 -58 -26 c-31 -14 -61 -29
-66 -34 -13 -13 32 -121 61 -147 13 -13 33 -23 44 -23 10 0 19 -4 19 -10 0
-11 -15 -13 -58 -4 -27 6 -33 14 -62 93 -18 47 -44 108 -57 134 -23 47 -26 81
-3 38 6 -12 17 -21 24 -21 7 0 58 20 112 44 170 74 156 65 150 105 -9 52 4 26
69 -132z m-1904 -4163 c-38 -38 -61 -92 -61 -143 l0 -24 23 20 c56 52 111 57
158 15 26 -23 32 -36 32 -70 0 -136 -188 -182 -246 -59 -41 85 -3 211 82 275
24 18 45 31 48 29 2 -2 -14 -22 -36 -43z m-2540 -24 c24 -16 51 -27 60 -24 15
4 14 -2 -4 -53 l-22 -58 -6 42 c-7 52 -40 90 -86 99 -35 7 -73 -15 -73 -41 0
-6 32 -48 70 -92 80 -92 92 -125 60 -165 -40 -52 -126 -45 -210 17 -20 15 -41
23 -52 19 -10 -3 -18 -4 -18 -2 0 8 50 108 54 108 2 0 4 -15 4 -34 0 -18 5
-43 12 -55 29 -54 138 -83 160 -42 14 27 5 47 -55 116 -84 97 -93 128 -50 170
36 36 98 34 156 -5z m400 -130 c20 0 24 -28 10 -65 -7 -16 -9 -15 -14 12 -6
29 -12 34 -73 52 -36 12 -71 21 -78 21 -14 0 -48 -112 -37 -123 4 -4 33 -13
65 -19 53 -12 59 -11 72 7 21 29 22 15 3 -45 -9 -30 -17 -45 -18 -34 -1 28
-16 39 -69 54 -24 6 -49 13 -56 16 -15 6 -61 -126 -48 -137 11 -11 118 -39
145 -39 13 0 36 10 51 21 36 28 43 18 14 -21 -20 -27 -28 -31 -56 -27 -43 8
-282 81 -282 87 0 3 14 5 31 5 32 0 32 0 75 130 24 71 44 136 44 143 0 8 -9
16 -20 19 -11 3 -20 9 -20 13 0 4 55 -10 121 -31 67 -22 130 -39 140 -39z
m1782 28 c-31 -15 -29 -33 17 -188 22 -74 39 -138 38 -142 -3 -8 -197 103
-281 160 -54 38 -55 36 -31 -66 34 -148 32 -145 65 -140 16 3 29 2 29 -2 0 -8
-139 -34 -146 -27 -3 2 7 7 21 11 18 5 25 13 25 32 0 46 -42 239 -55 253 -7 7
-28 16 -46 20 -32 7 -31 8 23 15 56 7 57 7 130 -42 92 -62 210 -133 214 -129
4 4 -50 190 -60 205 -4 7 -19 12 -34 13 -20 1 -13 5 28 19 61 20 97 25 63 8z
m-1452 -99 c40 -17 52 -19 64 -9 13 11 15 5 15 -43 0 -31 -4 -59 -10 -62 -5
-3 -10 2 -10 13 0 54 -79 107 -147 99 -59 -6 -99 -45 -119 -115 -20 -69 -12
-110 29 -150 58 -56 149 -60 212 -10 18 14 26 17 23 7 -2 -8 -19 -24 -38 -36
-54 -37 -175 -28 -236 17 -84 62 -90 185 -11 258 56 53 148 65 228 31z m972
-23 c49 -20 95 -78 102 -130 9 -60 -8 -107 -55 -150 -124 -111 -330 -44 -330
108 0 79 32 131 105 169 37 20 134 22 178 3z m-595 -12 l72 -6 -1 -41 c-1 -40
-1 -40 -10 -15 -11 28 -18 32 -81 42 l-37 7 -5 -43 c-3 -24 -7 -89 -10 -145
-5 -106 -3 -115 39 -127 11 -3 -20 -3 -69 -1 -83 4 -135 15 -68 15 37 0 38 5
48 178 l6 122 -50 0 c-41 0 -54 -4 -66 -22 -16 -22 -16 -22 -16 16 l0 38 88
-6 c48 -4 120 -9 160 -12z m257 -2 c-40 -8 -45 -21 -44 -121 0 -53 4 -118 7
-146 6 -45 10 -50 37 -56 22 -5 13 -7 -31 -8 -34 0 -76 -4 -93 -7 -20 -4 -31
-3 -31 5 0 6 11 11 24 11 32 0 36 14 36 140 0 143 -6 170 -36 170 -13 0 -24 3
-24 8 0 4 39 7 88 8 48 0 78 -2 67 -4z"/>
<path d="M2453 10204 c-18 -9 -33 -20 -33 -25 0 -17 54 -119 64 -119 15 0 75
49 86 71 21 38 -12 89 -58 89 -15 0 -42 -8 -59 -16z"/>
<path d="M3765 9963 c-290 -28 -644 -123 -927 -250 -51 -23 -56 -28 -61 -61
-2 -21 -9 -50 -15 -67 -6 -16 -21 -63 -32 -104 -22 -76 -28 -83 -120 -160 -68
-57 -124 -144 -143 -223 -16 -66 -38 -270 -58 -543 -11 -142 -10 -166 4 -215
25 -84 31 -89 62 -60 14 13 37 48 52 77 22 46 25 62 19 109 -4 33 -3 53 3 50
5 -3 32 22 60 57 50 61 106 105 119 93 3 -4 1 -25 -5 -48 -8 -29 -8 -48 -1
-61 5 -11 8 -36 6 -58 -3 -30 0 -39 15 -43 17 -4 18 -12 12 -78 -7 -71 -6 -76
20 -106 27 -32 29 -33 104 -30 l76 3 -3 35 c-2 19 -11 57 -20 84 -17 48 -19
50 -85 74 -62 23 -67 27 -52 41 8 9 37 23 63 32 45 15 47 17 55 64 7 44 6 51
-15 67 -13 10 -53 28 -90 39 -38 12 -68 24 -68 28 0 25 64 53 149 66 24 4 52
16 62 26 23 23 62 25 79 4 8 -9 29 -15 55 -15 82 0 122 -57 146 -204 14 -90
34 -121 71 -107 12 4 31 20 42 34 17 23 18 32 9 62 -14 44 -4 44 41 1 40 -37
46 -69 21 -116 -21 -41 -11 -57 47 -70 57 -14 128 -74 128 -109 0 -18 -6 -15
-43 24 -30 31 -52 45 -70 45 -33 0 -36 -21 -5 -35 12 -6 35 -26 51 -46 22 -29
30 -50 34 -100 l6 -64 -36 32 c-22 19 -43 30 -52 27 -19 -8 -20 -6 15 -61 37
-58 40 -121 10 -209 -31 -91 -32 -92 -39 -64 -9 32 -51 42 -51 12 0 -11 7 -36
15 -55 38 -91 2 -163 -116 -231 -61 -35 -67 -66 -34 -189 29 -107 23 -150 -26
-191 -37 -32 -70 -33 -95 -4 -17 19 -17 21 -1 15 28 -10 47 3 47 33 0 39 -26
63 -59 55 -40 -10 -51 -24 -51 -63 0 -45 34 -95 110 -161 71 -62 103 -123 101
-192 -1 -53 -23 -106 -47 -116 -23 -9 -25 1 -9 40 8 20 15 48 15 62 0 40 -48
103 -116 151 -58 42 -92 58 -66 32 15 -15 16 -48 2 -48 -5 0 -10 6 -10 14 0
22 -40 66 -61 66 -11 0 -24 -11 -31 -26 -11 -25 -9 -29 57 -87 148 -132 139
-121 140 -168 0 -24 -4 -54 -8 -65 -7 -19 -2 -23 44 -38 69 -23 114 -53 176
-119 75 -80 115 -85 253 -32 134 51 190 130 190 268 0 71 -4 86 -40 161 -62
131 -90 139 -90 25 0 -39 5 -101 11 -139 10 -59 10 -73 -4 -93 -25 -39 -67
-51 -144 -40 l-68 9 33 20 c64 36 82 55 82 82 l0 26 -34 -33 c-30 -29 -44 -34
-106 -41 -40 -4 -77 -13 -83 -19 -32 -32 -37 17 -7 67 30 49 68 78 132 102 55
20 84 40 56 40 -42 -1 -145 -31 -169 -49 l-29 -22 0 44 c0 81 48 125 153 142
20 3 37 9 37 14 0 17 -48 29 -94 24 l-48 -6 16 31 c21 41 44 52 105 52 32 0
51 4 51 12 0 6 -25 37 -55 69 -101 105 -136 231 -100 354 48 167 140 263 279
292 127 26 204 13 298 -53 37 -26 49 -30 57 -20 24 29 156 80 217 84 147 10
289 -74 378 -222 l36 -59 -6 64 c-4 48 1 88 20 164 26 105 23 128 -15 140 -31
10 -78 -14 -95 -48 -21 -40 -34 -20 -34 51 0 74 18 137 47 167 20 20 36 25 74
25 34 0 51 5 57 16 14 25 4 50 -24 63 -38 17 -60 13 -93 -15 -27 -23 -32 -24
-37 -9 -11 27 15 94 53 136 30 33 116 83 175 102 31 10 40 42 19 69 -17 20
-28 23 -88 23 -59 0 -79 -5 -148 -40 -44 -21 -84 -41 -89 -44 -16 -7 45 92 78
128 72 77 191 103 260 56 42 -28 68 -32 85 -11 14 17 2 41 -35 69 -26 19 -136
41 -166 34 -49 -13 22 52 99 91 72 37 110 38 175 3 71 -38 102 -39 124 -6 38
59 -3 168 -117 309 l-72 89 51 -6 c74 -9 155 -50 203 -105 58 -66 117 -171
130 -231 6 -28 15 -53 20 -57 12 -7 49 33 192 211 66 83 142 175 168 206 27
31 49 59 49 62 0 10 -160 122 -260 182 -399 242 -843 386 -1315 425 -114 9
-335 11 -420 3z m260 -340 c-39 -103 -28 -139 40 -130 22 3 51 9 64 13 22 6
22 5 11 -16 -7 -13 -14 -49 -15 -79 -1 -31 -6 -57 -11 -59 -5 -2 -26 2 -46 9
-30 10 -40 9 -53 -1 -31 -26 -16 -69 48 -137 25 -27 39 -33 71 -33 80 0 166
63 166 121 0 46 -42 33 -51 -15 -12 -65 -59 9 -59 93 0 81 46 118 70 55 5 -13
17 -24 25 -24 16 0 33 33 69 132 18 50 42 68 54 40 4 -9 7 -52 6 -95 l-1 -79
32 6 c18 4 40 4 49 0 35 -14 8 -122 -42 -167 -20 -19 -21 -19 -20 12 1 43 -12
55 -45 40 -23 -11 -27 -18 -27 -57 0 -51 32 -109 65 -118 11 -3 49 2 85 10 51
12 72 23 98 50 38 43 40 55 7 86 -18 17 -31 21 -54 17 l-30 -6 20 34 c11 19
21 50 22 69 1 20 7 36 12 36 19 0 56 -32 65 -56 13 -32 33 -30 64 7 20 24 26
41 26 80 0 49 0 49 28 43 15 -4 31 -10 35 -14 13 -12 -63 -157 -90 -171 -24
-13 -29 -29 -13 -45 6 -6 -1 -37 -21 -89 -41 -106 -74 -238 -89 -358 -8 -60
-18 -101 -25 -103 -7 -3 -68 8 -136 25 -188 45 -284 55 -509 56 -211 0 -317
-10 -467 -47 -46 -11 -87 -17 -91 -14 -5 3 -12 49 -15 103 -9 148 -50 278
-118 374 -13 19 -17 36 -13 58 6 27 4 31 -14 31 -15 0 -31 19 -61 73 l-40 72
37 22 c20 13 42 21 47 20 6 -2 15 -30 20 -63 13 -72 29 -78 84 -35 47 37 49
38 44 8 -4 -19 11 -67 34 -108 2 -4 -17 -12 -42 -18 -25 -6 -48 -18 -52 -27
-17 -45 52 -97 146 -110 51 -7 51 -6 96 38 39 39 45 51 45 86 0 22 -3 43 -8
46 -4 2 -22 -2 -41 -10 -31 -13 -33 -16 -27 -49 4 -19 4 -35 1 -35 -24 0 -65
81 -65 127 0 45 14 58 58 54 l40 -2 0 64 c0 65 8 107 20 107 14 0 62 -84 62
-106 0 -33 16 -38 49 -15 l29 21 16 -25 c22 -33 30 -77 22 -120 -8 -40 -17
-44 -31 -13 -21 49 -75 29 -75 -27 0 -25 8 -40 35 -64 31 -27 43 -31 94 -31
58 0 60 1 105 51 36 40 46 59 46 85 0 42 -17 50 -75 37 l-42 -10 5 69 c3 49 1
72 -9 85 -12 15 -12 16 6 11 11 -3 41 -14 66 -24 25 -9 53 -14 62 -11 29 11
32 57 9 98 -12 21 -22 40 -22 43 0 3 28 7 63 9 34 2 66 4 71 5 4 1 5 -6 1 -15z"/>
<path d="M3936 9444 c-25 -25 -20 -54 9 -54 31 0 52 34 34 56 -15 18 -24 18
-43 -2z"/>
<path d="M3671 8954 c-38 -31 -39 -41 -6 -56 21 -9 32 -8 60 5 40 19 44 39 13
61 -29 21 -31 20 -67 -10z"/>
<path d="M3930 8967 c-29 -15 -40 -61 -15 -70 8 -4 32 -1 53 6 48 15 57 52 17
67 -31 12 -25 12 -55 -3z"/>
<path d="M4172 8934 c-29 -20 -28 -42 3 -54 43 -16 104 21 91 55 -7 20 -65 20
-94 -1z"/>
<path d="M3422 8910 c-12 -11 -19 -26 -16 -34 14 -37 105 -22 112 18 8 38 -60
50 -96 16z"/>
<path d="M4455 8900 c-61 -24 -51 -77 13 -66 19 3 40 10 48 17 18 15 18 49 0
49 -8 0 -20 2 -28 4 -7 3 -22 1 -33 -4z"/>
<path d="M2701 9689 c-13 -38 1 -54 31 -38 19 10 23 35 9 57 -6 10 -10 9 -14
-7 -3 -12 -1 -19 4 -15 5 3 9 -1 9 -9 0 -15 -24 -30 -33 -20 -3 3 -1 16 4 30
5 14 8 28 5 30 -2 2 -9 -11 -15 -28z"/>
<path d="M2592 9672 c-6 -19 -7 -32 -1 -32 5 0 9 8 9 18 0 14 2 15 10 2 11
-18 12 -3 1 24 -7 18 -9 16 -19 -12z"/>
<path d="M2605 9630 c-3 -5 -13 -6 -23 -3 -13 4 -14 3 -6 -6 11 -12 44 -5 44
10 0 12 -7 11 -15 -1z"/>
<path d="M2486 9595 c4 -23 3 -26 -5 -15 -7 9 -11 10 -11 3 0 -6 5 -15 10 -18
15 -9 23 21 11 42 -9 15 -10 13 -5 -12z"/>
<path d="M2058 9197 c-119 -108 -224 -222 -313 -340 l-62 -83 9 -49 c26 -140
27 -137 -48 -246 l-55 -82 38 -31 c96 -78 285 -247 351 -314 40 -40 77 -70 82
-67 16 10 11 63 -9 103 -38 75 -64 176 -65 246 -1 74 -9 86 -46 66 -21 -11
-22 -10 -15 22 6 31 17 44 74 90 28 24 27 38 -4 38 -40 0 -26 45 29 92 24 21
28 29 19 39 -19 19 -57 -3 -89 -51 -34 -51 -42 -45 -14 9 27 54 88 121 109
121 27 0 61 29 54 46 -6 16 -49 16 -102 -1 -23 -8 -23 -8 -6 19 10 15 43 40
74 56 86 43 96 58 97 149 0 42 7 102 14 133 12 56 7 128 -10 128 -5 0 -55 -42
-112 -93z"/>
<path d="M5830 9283 c0 -5 -47 -91 -105 -192 -58 -102 -105 -186 -105 -187 0
-2 24 8 53 21 153 72 247 46 272 -72 6 -32 6 -33 -29 -33 -52 0 -80 -14 -115
-59 l-33 -41 142 -288 c150 -303 210 -432 210 -454 0 -7 7 -22 15 -32 24 -31
19 -101 -7 -127 -33 -30 -96 -57 -150 -62 -60 -6 -80 6 -243 146 -70 60 -128
107 -130 105 -2 -2 83 -91 189 -197 l193 -192 64 29 c35 17 170 82 299 146
275 136 291 139 351 76 22 -23 47 -65 60 -100 51 -140 23 4 -38 195 -144 449
-418 887 -767 1223 -97 93 -126 115 -126 95z"/>
<path d="M5035 9211 c3 -6 13 -9 23 -9 9 1 28 -2 42 -5 20 -6 19 -4 -5 8 -34
16 -69 20 -60 6z"/>
<path d="M5060 9186 c0 -3 19 -35 42 -72 23 -36 47 -87 53 -112 12 -46 12 -46
17 -15 2 18 2 30 -2 28 -9 -6 -32 63 -25 75 3 5 0 7 -7 5 -9 -4 -23 12 -38 45
-21 41 -40 64 -40 46z"/>
<path d="M5096 9169 c3 -6 13 -8 22 -4 8 3 19 0 24 -7 7 -10 9 -10 5 2 -2 8
-16 16 -31 17 -16 1 -24 -2 -20 -8z"/>
<path d="M5166 9136 c3 -9 11 -16 16 -16 6 0 4 -8 -3 -17 -10 -12 -11 -15 -1
-9 7 4 20 2 30 -6 10 -8 8 -1 -6 19 -26 37 -45 52 -36 29z"/>
<path d="M5219 9070 c0 -3 -2 -18 -4 -34 -4 -29 -4 -29 12 -7 11 17 12 26 4
34 -6 6 -11 9 -12 7z"/>
<path d="M5267 8958 c15 -42 29 -75 32 -73 5 6 -38 127 -50 140 -6 5 3 -25 18
-67z"/>
<path d="M5171 8920 c-1 -14 -10 -40 -21 -57 -25 -41 -60 -43 -130 -8 -76 38
-162 32 -197 -14 -18 -24 -16 -28 20 -34 17 -3 41 -9 52 -13 12 -4 17 -3 12 2
-5 5 -27 14 -50 20 l-42 11 35 21 c38 24 138 32 124 10 -4 -7 -3 -8 5 -4 6 4
26 -2 45 -13 33 -21 79 -27 111 -15 23 9 53 76 44 100 -7 17 -8 16 -8 -6z"/>
<path d="M5701 8889 l-64 -21 31 -18 c17 -11 44 -37 60 -59 l29 -39 17 29 c20
34 94 91 107 83 20 -12 7 16 -13 30 -30 21 -89 19 -167 -5z m146 0 l28 -12
-25 -10 c-14 -5 -41 -28 -60 -51 l-35 -42 -27 37 c-15 21 -37 41 -50 45 -19 6
-14 10 37 25 73 21 95 22 132 8z"/>
<path d="M5785 8840 c-4 -6 -11 -8 -16 -5 -5 3 -6 -1 -2 -10 4 -12 8 -13 13
-5 4 7 14 16 21 21 9 6 10 9 2 9 -6 0 -15 -5 -18 -10z"/>
<path d="M5230 8846 c-14 -8 -28 -12 -33 -9 -4 2 -6 1 -5 -4 1 -4 -22 -32 -52
-60 -29 -29 -50 -53 -46 -53 4 0 30 22 57 50 27 27 66 58 86 69 21 11 34 20
28 20 -5 0 -21 -6 -35 -13z"/>
<path d="M5159 8823 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M4951 8759 c36 -39 37 -71 3 -98 -31 -24 -19 -29 14 -5 32 22 30 73
-6 107 -39 37 -47 34 -11 -4z"/>
<path d="M1574 8764 c3 -9 9 -27 12 -40 8 -32 44 -33 43 -1 -1 16 -2 18 -6 5
-6 -22 -19 -23 -27 -2 -9 23 -8 26 7 18 6 -4 3 2 -7 14 -23 26 -30 28 -22 6z"/>
<path d="M2904 8726 c-5 -23 -8 -24 -37 -13 l-32 11 30 8 c24 6 21 6 -14 3
-55 -4 -80 -25 -30 -25 18 0 47 -7 63 -15 15 -8 29 -15 30 -15 0 0 2 16 3 35
3 40 -4 46 -13 11z"/>
<path d="M3038 8735 c-3 -4 -2 -23 4 -42 5 -20 7 -49 4 -65 -4 -20 -2 -28 8
-28 15 0 26 21 26 50 0 10 9 22 20 25 23 7 44 41 35 55 -11 17 -25 11 -35 -15
-12 -31 -32 -33 -28 -3 3 22 -20 37 -34 23z"/>
<path d="M1510 8670 c0 -7 9 -14 20 -17 11 -3 20 -10 20 -15 0 -6 -7 -8 -15
-4 -8 3 -15 1 -15 -4 0 -15 37 -12 43 3 3 8 -7 21 -24 31 -19 11 -29 13 -29 6z"/>
<path d="M4673 8662 c-18 -9 -33 -20 -33 -25 0 -4 15 0 33 10 17 9 52 20 77
24 l45 6 -45 1 c-25 0 -60 -7 -77 -16z"/>
<path d="M4800 8670 c0 -8 78 -35 84 -29 3 3 -15 12 -40 21 -24 8 -44 12 -44
8z"/>
<path d="M5050 8660 c-6 -11 -8 -20 -6 -20 3 0 10 9 16 20 6 11 8 20 6 20 -3
0 -10 -9 -16 -20z"/>
<path d="M4670 8629 c-41 -22 -54 -24 -44 -6 4 6 -5 1 -19 -12 -26 -24 -30
-44 -5 -24 32 25 134 55 170 49 20 -3 39 -2 42 3 11 17 -109 9 -144 -10z"/>
<path d="M4827 8633 c-3 -5 8 -16 24 -25 16 -10 29 -13 29 -7 0 5 -8 13 -17
16 -10 4 -21 11 -24 16 -4 5 -9 5 -12 0z"/>
<path d="M4895 8630 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2677 8624 c-8 -8 1 -24 12 -24 4 0 8 7 8 15 0 16 -9 20 -20 9z"/>
<path d="M4946 8621 c-4 -5 3 -7 14 -4 23 6 26 13 6 13 -8 0 -17 -4 -20 -9z"/>
<path d="M4992 8558 c-17 -40 -33 -90 -37 -111 -4 -20 -11 -40 -16 -43 -5 -3
-9 -18 -9 -35 0 -16 -3 -29 -7 -29 -16 0 -22 -251 -10 -370 5 -50 4 -70 -3
-60 -8 13 -10 12 -10 -2 0 -10 5 -18 10 -18 6 0 10 -10 10 -22 1 -13 12 -54
25 -92 13 -38 22 -74 21 -80 -2 -6 -1 -7 2 -2 8 15 81 -144 76 -162 -4 -12 -3
-13 3 -4 7 9 15 2 28 -28 10 -22 22 -40 27 -40 5 0 -13 42 -41 93 -28 51 -69
140 -92 198 l-42 104 -1 225 c-1 217 0 229 27 330 16 57 40 128 54 158 14 29
23 55 20 58 -3 3 -18 -28 -35 -68z"/>
<path d="M2625 8560 c-10 -11 -13 -20 -7 -20 6 0 12 6 15 13 3 9 10 9 30 -2
57 -30 21 -153 -50 -175 -13 -4 -23 -12 -23 -17 0 -5 -4 -9 -10 -9 -10 0 -2
99 10 115 5 6 2 16 -6 23 -12 11 -14 4 -14 -43 0 -58 -14 -95 -57 -152 -18
-23 -20 -32 -11 -36 7 -3 2 -6 -11 -6 -20 -1 -22 -4 -13 -13 10 -10 17 -7 35
11 12 13 16 21 10 17 -7 -4 -13 -2 -13 3 0 6 5 11 11 11 5 0 29 20 51 45 23
25 47 45 54 45 7 0 26 17 43 37 25 30 31 47 31 85 0 40 -4 50 -29 67 -28 20
-28 20 -46 1z m-69 -242 c-18 -26 -22 -17 -5 14 6 11 13 17 16 15 3 -3 -2 -16
-11 -29z"/>
<path d="M4882 8557 c-3 -43 -17 -62 -61 -82 -23 -10 -39 -20 -37 -22 6 -6 81
33 95 51 7 8 11 30 8 48 -3 30 -4 30 -5 5z"/>
<path d="M2595 8519 c-4 -6 2 -19 13 -29 17 -16 21 -17 26 -4 3 9 1 12 -4 9
-6 -3 -10 -1 -10 5 0 6 -5 8 -12 4 -8 -5 -9 -2 -5 9 7 19 2 23 -8 6z"/>
<path d="M2662 8510 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3362 8492 c-21 -42 -25 -60 -8 -32 20 32 20 8 1 -45 -8 -22 -14 -31
-15 -21 0 11 -2 17 -5 14 -3 -3 2 -34 11 -69 11 -42 15 -85 11 -124 -5 -52 -4
-55 5 -25 10 35 6 123 -8 170 -7 25 -7 25 13 5 11 -11 18 -26 15 -33 -6 -17
16 -42 37 -41 11 0 9 3 -6 9 -25 10 -30 34 -11 46 7 5 4 10 -11 15 -29 11 -37
35 -21 64 7 13 10 33 7 44 -3 12 -1 24 4 27 11 6 12 24 2 24 -5 0 -14 -12 -21
-28z"/>
<path d="M2877 8486 c-18 -14 -17 -15 3 -21 12 -4 24 -4 27 -1 4 3 -2 6 -12 6
-18 0 -18 1 1 15 10 8 15 14 9 14 -5 0 -18 -6 -28 -13z"/>
<path d="M4941 8474 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3095 8460 c-4 -6 6 -25 22 -43 43 -49 58 -92 44 -123 -24 -52 2 -77
33 -32 34 48 18 105 -47 176 -33 34 -42 38 -52 22z"/>
<path d="M3315 8430 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4740 8433 c-8 -3 -27 -16 -42 -28 -26 -22 -27 -23 -4 -21 13 0 23 7
22 16 -1 8 8 16 19 18 21 4 47 23 30 21 -6 0 -17 -3 -25 -6z"/>
<path d="M5604 8349 c-7 -12 12 -29 33 -29 17 0 73 -50 73 -66 0 -7 8 -18 18
-23 21 -11 192 -273 192 -295 0 -8 16 -24 34 -36 19 -11 38 -30 41 -41 9 -27
39 -20 43 10 4 27 -42 81 -69 81 -20 0 -199 269 -199 299 0 38 -23 82 -51 96
-33 18 -105 20 -115 4z"/>
<path d="M2627 8335 c-26 -25 -41 -85 -27 -98 10 -9 12 -8 7 6 -4 10 0 34 8
52 10 26 18 33 32 29 14 -4 15 -4 4 4 -11 8 -11 10 0 15 9 3 9 6 2 6 -6 1 -18
-6 -26 -14z"/>
<path d="M4640 8340 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4719 8329 c24 -4 50 -16 58 -26 13 -17 13 -16 4 3 -12 26 -32 34
-74 33 -28 -1 -27 -2 12 -10z"/>
<path d="M2651 8298 c-1 -25 20 -66 37 -73 13 -5 13 -2 -2 13 -9 11 -21 33
-26 48 -4 16 -9 21 -9 12z"/>
<path d="M3440 8282 c0 -6 7 -13 15 -16 12 -4 14 -10 5 -25 -12 -23 -13 -42
-2 -23 5 7 16 10 25 6 27 -10 20 -24 -12 -24 -37 0 -61 -23 -61 -58 0 -31 5
-39 40 -67 23 -18 23 -17 -2 11 -29 32 -37 75 -15 92 21 17 86 20 92 4 3 -8 8
-12 11 -9 4 3 -7 25 -24 48 -32 43 -72 77 -72 61z"/>
<path d="M4891 8234 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4785 8230 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1337 8121 c-180 -488 -236 -1007 -157 -1457 91 -513 312 -987 653
-1400 l58 -71 41 21 c40 19 94 24 193 17 22 -1 55 0 73 4 28 6 39 2 79 -29 78
-61 148 -60 190 3 54 81 51 164 -9 218 -69 63 -70 64 -59 77 8 10 5 25 -14 58
-63 113 -76 269 -33 389 36 97 90 189 157 262 70 77 80 109 53 169 -22 51 -66
92 -227 215 -66 50 -136 113 -157 140 -44 58 -78 155 -78 222 0 59 -15 104
-33 99 -6 -2 -19 -18 -27 -36 -8 -17 -18 -32 -21 -32 -12 0 -19 63 -19 170 0
81 -4 110 -14 119 -18 15 -31 -5 -41 -62 -11 -58 -29 -29 -27 45 1 66 33 170
78 256 27 51 31 99 10 128 -12 16 -26 19 -83 18 -37 -1 -77 -3 -88 -5 -18 -4
-21 3 -27 64 -12 127 -86 193 -125 112 -19 -39 -33 -37 -33 6 0 45 -34 103
-82 138 -51 37 -62 38 -93 6 -17 -17 -25 -35 -25 -60 0 -24 -4 -34 -11 -29 -8
5 -9 35 -4 103 11 127 8 161 -14 191 -34 46 -44 37 -84 -69z"/>
<path d="M2606 8185 c3 -22 10 -44 15 -49 5 -6 10 -30 11 -55 l1 -46 4 39 c3
22 0 49 -6 60 -6 12 -15 37 -21 56 l-10 35 6 -40z"/>
<path d="M2710 8210 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M4755 8200 c-11 -4 -41 -16 -68 -25 -58 -21 -97 -71 -97 -127 0 -43
5 -46 40 -17 l25 21 -27 -16 -28 -16 0 36 c0 51 58 107 129 124 28 7 51 16 51
21 0 5 -1 9 -2 8 -2 0 -12 -4 -23 -9z"/>
<path d="M2750 8197 c0 -3 24 -13 53 -23 28 -9 58 -22 66 -28 11 -8 13 -8 7 2
-11 19 -126 63 -126 49z"/>
<path d="M4779 8173 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M3327 8117 l-18 -32 21 21 c13 12 21 27 19 33 -2 6 -12 -4 -22 -22z"/>
<path d="M2891 8103 c7 -12 15 -20 18 -17 3 2 -3 12 -13 22 -17 16 -18 16 -5
-5z"/>
<path d="M4882 8070 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M3265 8080 l-30 -8 33 -1 c17 0 32 4 32 9 0 6 -1 9 -2 9 -2 -1 -16
-5 -33 -9z"/>
<path d="M2930 8055 c8 -10 30 -32 48 -48 21 -19 32 -38 32 -57 0 -29 29 -70
50 -70 7 0 1 9 -14 21 -18 15 -26 30 -26 53 0 26 -9 40 -46 72 -59 51 -61 52
-44 29z"/>
<path d="M3150 8041 c0 -10 6 -24 14 -30 21 -18 29 -66 16 -91 -9 -17 -9 -25
0 -34 9 -8 16 -5 31 13 26 32 27 121 1 144 -26 23 -62 22 -62 -2z"/>
<path d="M3265 8033 c8 -18 20 -33 26 -33 6 0 6 4 -1 13 -6 6 -17 21 -26 32
-13 17 -12 15 1 -12z"/>
<path d="M4660 8056 c0 -2 16 -6 35 -8 19 -2 35 0 35 4 0 4 -16 8 -35 8 -19 0
-35 -2 -35 -4z"/>
<path d="M4767 8039 c4 -13 8 -18 11 -10 2 7 -1 18 -6 23 -8 8 -9 4 -5 -13z"/>
<path d="M3477 8005 c3 -27 7 -52 9 -54 8 -9 2 65 -6 84 -6 13 -7 2 -3 -30z"/>
<path d="M2782 8023 c9 -10 18 -25 21 -33 4 -10 6 -11 6 -2 1 20 -18 52 -31
52 -7 0 -5 -7 4 -17z"/>
<path d="M2910 8030 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4740 8031 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M2616 8003 c-8 -29 16 -65 28 -44 4 6 2 11 -4 11 -6 0 -8 5 -4 12 4
7 3 8 -5 4 -8 -6 -10 0 -5 18 3 14 4 26 1 26 -3 0 -8 -12 -11 -27z"/>
<path d="M2736 7995 c-11 -14 -33 -29 -50 -34 -17 -5 -23 -10 -14 -10 29 -2
66 17 78 39 17 31 7 34 -14 5z"/>
<path d="M4754 7958 c-4 -24 -8 -47 -9 -53 -1 -5 -7 -23 -14 -39 -16 -38 -27
-186 -14 -186 5 0 14 15 21 34 15 42 37 62 82 74 l35 10 -32 1 c-17 1 -35 -4
-38 -10 -4 -6 -13 -8 -21 -5 -8 3 -13 0 -11 -7 1 -7 -3 -11 -10 -10 -7 2 -10
-4 -7 -12 3 -7 1 -22 -5 -32 -9 -15 -10 -13 -11 15 0 39 27 140 44 161 9 12 8
13 -3 6 -11 -6 -13 -4 -6 11 4 10 10 29 12 41 3 14 10 20 17 16 6 -4 5 1 -3
10 -7 9 -15 17 -16 17 -1 0 -6 -19 -11 -42z"/>
<path d="M4892 7970 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M4786 7945 c-3 -9 -6 -22 -5 -28 0 -7 5 -1 10 12 5 13 8 26 5 28 -2
2 -6 -3 -10 -12z"/>
<path d="M2725 7920 c-27 -10 -57 -19 -65 -20 -21 -1 -55 -28 -47 -37 4 -3 13
0 20 9 13 16 100 43 114 36 4 -3 16 3 26 14 20 21 18 21 -48 -2z"/>
<path d="M3471 7921 c-1 -16 -5 -21 -16 -18 -8 4 -16 3 -19 -1 -3 -4 4 -9 15
-9 21 -2 34 18 26 39 -3 7 -6 2 -6 -11z"/>
<path d="M3388 7893 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2683 7872 c-24 -2 -43 -7 -43 -12 0 -8 42 -5 78 6 6 2 12 -1 12 -6
0 -6 7 -10 15 -10 9 0 23 -15 31 -32 11 -27 14 -28 12 -9 -4 37 -55 67 -105
63z"/>
<path d="M3135 7850 c33 -16 62 -30 65 -30 14 1 -93 60 -107 60 -10 0 9 -14
42 -30z"/>
<path d="M3269 7853 c-22 -45 -22 -46 -10 -38 13 7 34 55 28 61 -2 2 -10 -8
-18 -23z"/>
<path d="M3361 7864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4791 7864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4770 7845 c0 -14 4 -25 9 -25 4 0 6 11 3 25 -2 14 -6 25 -8 25 -2 0
-4 -11 -4 -25z"/>
<path d="M4891 7865 c1 -19 18 -51 18 -35 0 8 -4 22 -9 30 -5 8 -9 11 -9 5z"/>
<path d="M4363 7839 c-7 -7 -13 -25 -13 -40 0 -42 -17 -44 -54 -5 -40 44 -70
46 -143 11 -50 -22 -66 -46 -43 -60 5 -3 19 -38 31 -77 26 -85 22 -172 -11
-262 l-22 -58 53 -55 c73 -74 137 -106 214 -107 69 0 193 34 236 67 45 33 79
110 79 179 0 54 -1 58 -24 58 -13 0 -34 9 -47 20 -24 20 -24 20 -5 35 11 8 26
15 33 15 7 0 13 7 13 15 0 20 -40 28 -73 16 -25 -10 -29 -8 -37 15 -6 16 -6
40 0 67 20 87 5 99 -55 46 -37 -32 -65 -33 -65 -2 0 32 -20 91 -38 113 -14 17
-19 19 -29 9z"/>
<path d="M4747 7830 c-3 -11 -1 -20 4 -20 5 0 9 9 9 20 0 11 -2 20 -4 20 -2 0
-6 -9 -9 -20z"/>
<path d="M3360 7832 c0 -6 9 -27 19 -46 11 -19 17 -37 13 -42 -4 -4 -1 -4 5 0
10 5 8 17 -8 49 -25 49 -29 54 -29 39z"/>
<path d="M4881 7833 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M3725 7813 c-83 -25 -159 -92 -190 -167 -8 -20 -15 -66 -15 -103 0
-100 27 -151 133 -246 76 -69 88 -77 114 -71 52 12 168 73 205 108 l38 35 -22
48 c-28 63 -36 204 -14 283 14 53 14 56 -7 78 -24 26 -104 52 -156 51 -20 0
-58 -7 -86 -16z"/>
<path d="M4865 7686 c0 -104 7 -121 83 -193 39 -38 46 -41 59 -27 9 8 12 18 8
22 -4 4 -10 1 -12 -5 -3 -7 -9 -13 -15 -13 -6 0 -8 6 -5 14 4 10 0 14 -12 11
-12 -2 -30 13 -56 48 -38 50 -38 51 -36 138 1 48 -2 90 -6 92 -4 3 -8 -36 -8
-87z"/>
<path d="M4920 7775 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M2740 7766 c0 -2 11 -6 25 -8 14 -3 25 -1 25 3 0 5 -11 9 -25 9 -14
0 -25 -2 -25 -4z"/>
<path d="M2645 7735 c-22 -13 -35 -25 -29 -25 5 0 14 4 20 9 5 5 29 16 54 25
33 11 39 15 20 15 -14 1 -43 -10 -65 -24z"/>
<path d="M4787 7747 c-10 -7 -29 -42 -42 -78 l-24 -66 20 -64 c48 -151 -22
-301 -180 -387 -29 -16 -48 -32 -43 -36 6 -3 32 7 59 24 102 65 192 72 222 17
9 -17 35 -50 58 -73 45 -44 63 -79 63 -119 0 -32 -17 -41 -41 -19 -27 25 -121
31 -153 10 -50 -32 -42 -74 18 -101 30 -13 30 -13 -30 -14 -77 -1 -188 32
-222 66 -22 22 -24 30 -19 82 l6 57 -42 15 c-42 16 -42 15 -122 -25 -109 -54
-224 -168 -250 -244 -24 -76 -17 -170 19 -245 27 -57 177 -221 226 -247 17 -9
20 -8 20 13 0 60 60 192 126 278 86 112 173 171 381 258 226 95 269 118 308
163 40 48 42 58 15 83 -26 24 -26 69 1 107 36 49 22 117 -18 92 -23 -15 -36
-69 -31 -132 l5 -53 -28 27 c-38 36 -53 84 -44 141 9 60 -14 55 -34 -8 -8 -24
-16 -51 -19 -59 -3 -10 -9 -4 -18 18 -11 25 -12 42 -4 70 5 20 10 59 10 85 0
50 -7 61 -78 114 -48 36 -66 85 -58 161 6 53 4 68 -10 83 -20 22 -25 23 -47 6z"/>
<path d="M3396 7699 c-8 -42 0 -50 9 -9 4 17 5 34 2 36 -2 3 -7 -10 -11 -27z"/>
<path d="M2702 7701 c20 -4 44 -13 52 -21 24 -19 20 -66 -6 -84 -12 -9 -18
-16 -13 -16 28 1 58 49 49 77 -11 30 -47 53 -84 52 l-35 -1 37 -7z"/>
<path d="M2575 7677 c-11 -11 -14 -16 -6 -12 8 4 32 14 55 21 l41 12 -35 0
c-21 -1 -43 -9 -55 -21z"/>
<path d="M3180 7690 c-11 -7 -6 -8 16 -4 17 3 42 1 55 -6 23 -11 23 -10 5 4
-22 18 -54 20 -76 6z"/>
<path d="M4034 7634 c-7 -63 0 -162 14 -176 12 -12 32 67 32 127 0 39 -22 105
-35 105 -2 0 -7 -25 -11 -56z"/>
<path d="M3181 7644 c-24 -31 -28 -84 -7 -84 45 0 85 54 66 90 -15 27 -35 25
-59 -6z"/>
<path d="M3270 7650 c5 -36 -2 -53 -32 -81 -32 -29 -32 -29 -8 -26 14 2 25 8
26 13 0 5 9 26 18 48 13 29 15 41 6 50 -9 8 -11 7 -10 -4z"/>
<path d="M3305 7594 c-22 -19 -31 -31 -20 -25 29 14 82 60 70 60 -5 1 -28 -15
-50 -35z"/>
<path d="M3123 7540 c0 -36 2 -50 4 -32 2 17 2 47 0 65 -2 17 -4 3 -4 -33z"/>
<path d="M6383 7553 c-57 -62 -106 -123 -98 -123 15 0 235 94 235 101 0 15
-59 69 -75 69 -9 0 -37 -21 -62 -47z"/>
<path d="M2667 7541 c-51 -33 -108 -121 -77 -121 6 0 10 8 10 18 1 16 29 74
30 60 0 -4 10 3 23 15 12 12 33 30 47 39 42 29 17 20 -33 -11z"/>
<path d="M4921 7557 c2 -1 12 -9 22 -16 15 -12 17 -12 11 3 -3 9 -13 16 -22
16 -8 0 -13 -1 -11 -3z"/>
<path d="M2690 7526 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3211 7485 c-1 -30 18 -125 41 -203 10 -35 10 -47 -1 -65 -13 -20
-13 -21 2 -8 21 17 21 47 2 104 -8 24 -21 79 -29 123 -8 43 -15 65 -15 49z"/>
<path d="M3161 7474 c-6 -15 -9 -29 -7 -32 3 -2 10 8 16 23 6 15 9 29 7 31 -2
2 -9 -8 -16 -22z"/>
<path d="M2635 7470 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M3088 7439 c-15 -16 -24 -29 -19 -29 10 0 56 49 50 54 -2 1 -16 -10
-31 -25z"/>
<path d="M2640 7451 c-8 -6 -18 -7 -22 -5 -4 3 -8 -2 -8 -10 0 -8 -9 -20 -20
-26 -11 -6 -20 -15 -20 -21 0 -6 19 5 41 25 27 22 54 36 78 39 l36 4 -35 1
c-19 1 -42 -2 -50 -7z"/>
<path d="M2730 7451 c0 -5 5 -13 10 -16 6 -3 10 -2 10 4 0 5 -4 13 -10 16 -5
3 -10 2 -10 -4z"/>
<path d="M5120 7446 c0 -3 14 -25 30 -48 17 -22 30 -36 30 -31 0 6 -13 27 -30
48 -16 21 -30 35 -30 31z"/>
<path d="M5780 7432 c0 -4 28 -20 62 -37 33 -16 76 -39 95 -52 40 -27 51 -29
32 -7 -33 40 -189 119 -189 96z"/>
<path d="M5024 7424 c-6 -3 -9 -15 -7 -28 3 -13 -3 -38 -12 -56 -9 -17 -14
-34 -12 -37 3 -2 10 5 17 16 6 12 18 21 27 21 13 0 13 3 3 15 -7 8 -10 28 -8
45 3 16 4 30 4 29 -1 0 -6 -3 -12 -5z"/>
<path d="M2715 7389 c-28 -27 -30 -35 -33 -113 -3 -68 0 -90 16 -121 l19 -39
8 59 c5 46 13 65 36 88 25 25 36 28 73 25 24 -1 42 0 39 5 -7 11 -72 8 -96 -4
-34 -18 -57 -46 -58 -71 0 -13 -4 -32 -9 -43 -7 -18 -8 -18 -15 8 -4 15 -5 60
-3 101 3 68 6 77 33 100 16 14 27 27 25 29 -3 3 -18 -8 -35 -24z"/>
<path d="M3005 7389 c-22 -11 -32 -17 -22 -14 11 3 30 8 43 11 13 3 24 10 24
15 0 11 4 12 -45 -12z"/>
<path d="M3060 7360 c-13 -9 -13 -10 0 -10 8 0 22 5 30 10 13 9 13 10 0 10 -8
0 -22 -5 -30 -10z"/>
<path d="M3010 7350 l-25 -8 28 -1 c15 -1 27 4 27 9 0 6 -1 10 -2 9 -2 -1 -14
-5 -28 -9z"/>
<path d="M5120 7350 c0 -5 5 -10 11 -12 7 -2 0 -12 -15 -21 -22 -15 -26 -15
-26 -2 0 8 -3 15 -7 15 -5 0 -9 -30 -10 -68 -1 -60 0 -63 9 -32 14 50 21 61
51 84 24 18 27 18 51 3 30 -20 41 -68 31 -134 -5 -34 -9 -43 -17 -32 -7 10 -8
8 -3 -8 5 -18 3 -22 -9 -17 -19 7 -20 12 -2 46 11 21 11 26 1 22 -23 -9 -28
-54 -9 -76 18 -19 18 -18 33 9 19 36 21 147 2 191 -9 22 -17 29 -21 21 -6 -9
-15 -8 -39 4 -17 8 -31 12 -31 7z"/>
<path d="M6813 7294 c-49 -106 -74 -191 -83 -276 -9 -92 -32 -134 -99 -179
-44 -29 -45 -32 -62 -115 -16 -77 -71 -260 -88 -291 -4 -7 -37 37 -81 109 -41
66 -92 147 -112 179 l-37 58 24 15 c14 9 29 16 35 16 16 0 12 12 -12 37 -18
20 -23 38 -26 113 -2 49 -2 100 2 114 6 23 9 24 64 19 55 -5 60 -4 89 26 21
21 35 48 43 84 21 94 21 94 -138 9 l-144 -77 -2 -130 c-2 -115 -6 -139 -30
-205 -58 -153 -167 -306 -309 -433 -199 -177 -199 -178 -148 -270 42 -74 81
-197 81 -254 0 -139 -73 -282 -227 -445 -83 -88 -94 -103 -91 -131 3 -30 11
-36 133 -97 126 -63 133 -65 206 -65 69 0 83 4 160 42 58 28 93 40 108 36 30
-8 79 40 187 183 306 406 498 869 574 1379 23 157 38 530 23 584 l-9 34 -31
-69z"/>
<path d="M3155 7323 c27 -7 65 -43 65 -62 0 -9 4 -22 9 -29 6 -10 10 -9 14 7
3 12 1 19 -4 15 -5 -3 -9 2 -9 11 0 23 -49 65 -74 64 -20 -1 -20 -2 -1 -6z"/>
<path d="M3075 7290 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4026 7244 c-35 -42 -98 -86 -149 -105 -20 -7 -36 -16 -35 -20 2 -3
31 -30 66 -58 68 -57 82 -58 123 -15 32 34 17 58 -40 62 -26 2 -44 9 -48 18
-7 20 25 54 51 54 23 0 199 -42 229 -55 10 -4 17 -3 17 4 0 6 -15 17 -33 25
-19 7 -56 39 -82 70 -27 31 -54 56 -59 55 -6 0 -24 -16 -40 -35z"/>
<path d="M2821 7225 c-23 -26 -31 -46 -31 -72 0 -43 31 -112 62 -139 15 -13
12 -5 -11 28 -42 63 -52 133 -23 166 12 13 25 22 29 19 5 -3 9 3 9 14 0 10 -1
19 -2 19 -1 0 -16 -16 -33 -35z"/>
<path d="M2866 7211 c-4 -5 -2 -20 3 -33 9 -21 9 -20 8 9 -2 36 -3 38 -11 24z"/>
<path d="M3041 7194 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3216 7193 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2891 7154 c-6 -15 -9 -29 -7 -32 3 -2 10 8 16 23 6 15 9 29 7 31 -2
2 -9 -8 -16 -22z"/>
<path d="M2940 7170 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4671 7169 c-13 -5 -21 -12 -18 -15 3 -3 14 -1 25 5 33 18 90 -7 112
-48 11 -20 32 -45 47 -55 l28 -19 -29 31 c-16 17 -34 41 -40 54 -11 21 -68 57
-91 57 -5 0 -21 -5 -34 -10z"/>
<path d="M3051 7154 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4585 7125 c-22 -14 -33 -25 -24 -25 9 0 22 7 30 16 8 10 22 13 38
10 14 -3 20 -3 13 0 -6 3 -10 10 -6 15 9 15 -9 10 -51 -16z"/>
<path d="M2920 7120 c0 -27 22 -71 33 -67 6 2 17 14 25 28 l14 24 -20 -22 -20
-22 -12 29 c-6 16 -9 34 -5 39 3 6 1 11 -4 11 -6 0 -11 -9 -11 -20z"/>
<path d="M3070 7130 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M4756 7117 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4672 7106 c7 -8 17 -12 22 -9 4 3 2 9 -5 14 -22 13 -31 11 -17 -5z"/>
<path d="M3020 7103 c14 -3 36 -15 50 -27 14 -13 43 -36 65 -52 l40 -28 -24
27 c-13 15 -33 29 -43 33 -11 3 -17 12 -14 19 3 9 0 12 -8 9 -7 -3 -19 2 -26
11 -7 8 -24 14 -39 14 l-26 -2 25 -4z"/>
<path d="M4646 7101 c5 -10 -1 -12 -27 -7 -48 10 -17 -8 49 -27 28 -9 52 -12
52 -8 0 5 -12 12 -27 16 -15 3 -33 14 -41 23 -7 9 -10 11 -6 3z"/>
<path d="M3110 7090 c0 -6 18 -26 40 -46 22 -20 38 -40 35 -44 -3 -4 14 -20
36 -35 23 -15 39 -21 37 -13 -2 7 -13 17 -24 22 -10 5 -43 36 -71 68 -29 32
-53 53 -53 48z"/>
<path d="M2840 7070 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
-2 0 0 -9 6 -20z"/>
<path d="M2880 7086 c0 -11 28 -76 33 -76 6 0 -17 68 -26 76 -4 4 -7 4 -7 0z"/>
<path d="M4520 7027 c0 -10 91 -47 114 -47 12 1 -1 9 -29 19 -27 10 -58 22
-67 26 -10 4 -18 5 -18 2z"/>
<path d="M4706 7015 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M4817 7019 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4866 7021 c-4 -6 -3 -11 3 -11 5 0 14 -12 20 -27 l11 -28 -6 30 c-6
35 -19 51 -28 36z"/>
<path d="M4490 6981 c0 -17 2 -31 4 -31 8 0 13 51 5 56 -5 3 -9 -9 -9 -25z"/>
<path d="M4747 6998 c2 -5 -2 -8 -10 -8 -8 0 -26 -9 -41 -21 -26 -20 -37 -14
-14 9 8 8 8 12 1 12 -17 0 -34 -25 -23 -36 13 -14 -3 -69 -20 -69 -48 2 -90
18 -88 33 2 12 -5 17 -24 17 -25 1 -26 0 -12 -16 15 -17 131 -59 161 -59 11 0
11 4 1 20 -31 50 35 110 121 110 24 0 42 3 39 6 -9 8 -97 10 -91 2z"/>
<path d="M2885 6990 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2920 6992 c0 -12 19 -26 26 -19 2 2 -2 10 -11 17 -9 8 -15 8 -15 2z"/>
<path d="M4850 6991 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z"/>
<path d="M2915 6960 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M2953 6945 c0 -20 43 -59 51 -46 4 5 -3 12 -14 16 -11 4 -18 10 -15
15 3 4 -1 13 -8 19 -10 8 -14 7 -14 -4z"/>
<path d="M4568 6953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4636 6917 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M4588 6913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3015 6890 c-3 -6 4 -10 17 -11 13 0 17 -3 10 -6 -9 -3 -3 -15 18
-39 39 -44 40 -96 5 -148 -28 -41 -24 -44 22 -16 37 22 59 83 44 122 -18 46
-103 118 -116 98z m100 -91 c16 -30 2 -104 -22 -113 -8 -3 -11 -2 -8 3 3 6 9
30 14 56 8 42 6 48 -20 82 -16 19 -29 38 -29 41 0 11 52 -44 65 -69z"/>
<path d="M3866 6472 c-3 -5 -5 -45 -4 -89 0 -101 -14 -144 -57 -177 -18 -14
-36 -26 -38 -26 -3 0 7 23 22 50 25 49 25 51 8 65 -27 20 -45 8 -57 -36 -16
-57 -27 -56 -40 7 -12 58 -30 75 -55 54 -28 -23 -17 -58 39 -119 139 -151 140
-188 20 -496 -22 -56 -28 -87 -28 -155 -1 -77 2 -92 32 -152 33 -67 137 -178
168 -178 8 0 23 -9 34 -20 16 -16 18 -25 10 -45 -14 -37 -13 -42 13 -49 36 -9
77 -45 77 -68 0 -12 -22 -69 -49 -127 -27 -59 -57 -138 -66 -176 -24 -106 -58
-170 -129 -243 -63 -66 -149 -189 -141 -203 11 -16 274 -31 481 -26 217 5 287
13 509 62 100 21 423 124 443 140 5 5 -30 90 -44 106 -9 10 -46 21 -97 29 -45
7 -90 17 -99 22 -22 11 -23 46 -2 76 14 20 14 24 -4 50 -10 15 -49 86 -87 159
-37 72 -82 149 -100 170 -48 57 -50 72 -15 134 85 151 109 239 89 322 -11 45
-24 63 -102 142 -102 103 -199 219 -227 276 -11 21 -25 62 -31 91 -15 69 -25
88 -49 88 -17 0 -20 -7 -20 -42 l0 -43 -46 48 -46 48 7 57 c6 50 4 60 -14 76
-22 20 -51 15 -51 -9 0 -9 7 -29 16 -46 8 -17 12 -39 8 -51 -6 -20 -7 -20 -19
8 -7 16 -24 40 -39 53 -34 32 -33 52 4 94 17 19 30 39 30 44 0 18 -46 53 -69
53 -25 0 -25 -4 -5 -82 5 -21 2 -29 -11 -35 -25 -9 -63 41 -67 87 -3 30 -7 35
-30 38 -22 3 -28 9 -28 28 0 17 -6 24 -19 24 -11 0 -22 -4 -25 -8z"/>
<path d="M2885 5711 c-64 -48 -105 -121 -105 -184 0 -56 30 -141 69 -194 l29
-39 -43 -12 c-82 -22 -298 -210 -343 -299 -23 -45 -75 -93 -100 -93 -8 0 -31
5 -49 12 -29 10 -39 10 -68 -6 -19 -11 -35 -22 -35 -25 0 -8 189 -139 282
-195 99 -59 289 -154 393 -195 l80 -33 58 35 c68 41 143 62 199 54 22 -3 51
-1 64 5 17 8 34 7 71 -6 26 -10 71 -23 100 -28 44 -9 56 -9 77 6 68 44 75 163
17 271 -29 54 -32 66 -26 114 4 29 13 71 20 92 13 37 13 39 -13 57 -15 10 -63
38 -107 62 -116 63 -212 127 -255 170 -73 73 -169 238 -230 395 -13 33 -26 63
-29 67 -3 4 -29 -10 -56 -31z"/>
<path d="M5099 5514 c-18 -20 -71 -67 -117 -106 -45 -38 -89 -82 -97 -98 -28
-54 -42 -129 -34 -185 15 -109 154 -378 224 -431 32 -24 40 -25 137 -23 93 2
107 0 141 -21 l38 -23 42 23 c75 41 300 199 381 267 24 20 27 26 15 34 -39 25
-98 20 -247 -23 -51 -15 -62 -5 -62 53 0 36 -1 37 -79 69 -89 35 -261 144
-261 163 0 8 16 50 35 94 48 110 48 162 1 209 -44 44 -73 44 -117 -2z"/>
<path d="M1876 5149 c-15 -16 -23 -29 -17 -29 5 0 13 5 16 10 3 6 17 10 31 10
14 0 24 6 24 14 0 26 -27 24 -54 -5z"/>
<path d="M1940 5065 l-32 -33 33 11 c59 21 75 39 45 50 -7 3 -28 -10 -46 -28z
m35 5 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
<path d="M2020 4975 c-27 -28 -25 -29 19 -14 17 6 31 17 31 25 0 22 -24 17
-50 -11z m40 11 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0
10 -2 10 -4z"/>
<path d="M3050 4450 c13 -8 13 -10 -1 -10 -9 0 -22 -7 -29 -15 -7 -8 -9 -15
-4 -15 11 1 81 39 74 40 -3 1 -16 3 -30 6 -19 3 -21 2 -10 -6z"/>
<path d="M3102 4370 c-25 -34 -24 -35 15 -18 35 16 46 30 30 40 -18 12 -22 10
-45 -22z m38 10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0
10 -4 10 -10z"/>
<path d="M3200 4299 c-7 -11 -9 -23 -6 -27 3 -3 6 0 6 6 0 7 8 12 18 13 10 0
12 3 4 6 -6 2 -10 9 -6 14 3 5 3 9 0 9 -3 0 -10 -9 -16 -21z"/>
<path d="M2100 9918 c0 -13 3 -46 6 -75 l7 -51 58 36 c32 20 55 39 51 43 -4 3
-33 21 -64 38 l-58 32 0 -23z"/>
<path d="M5320 4073 c-52 -19 -63 -66 -30 -121 37 -60 109 -68 136 -13 30 58
-47 155 -106 134z"/>
<path d="M4350 3959 c-55 -15 -84 -66 -83 -143 1 -102 49 -166 125 -166 84 0
128 50 128 142 -1 122 -73 193 -170 167z"/>
</g>
</svg>
    </>
  )
}