import React from 'react'

export default function Stemma() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 828.000000 1316.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1316.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M3760 13149 c0 -7 7 -17 16 -23 18 -13 34 -78 27 -106 -6 -24 -31
-34 -49 -19 -11 9 -14 7 -14 -9 0 -25 20 -72 32 -73 4 -1 14 -2 20 -3 7 0 14
-10 16 -21 3 -11 10 -23 18 -28 10 -6 13 -51 11 -216 -1 -115 -5 -207 -9 -204
-5 2 -8 0 -8 -5 0 -6 -11 -13 -24 -16 -28 -7 -36 -31 -30 -89 4 -40 2 -44 -32
-62 -20 -11 -40 -29 -44 -40 -8 -17 -9 -18 -9 -2 -1 14 -9 18 -43 17 -24 -1
-77 -1 -119 -1 l-76 0 -42 56 c-23 30 -47 55 -53 55 -6 0 -17 8 -24 17 -18 25
-30 8 -16 -22 7 -14 12 -35 12 -46 0 -41 28 -76 66 -84 24 -4 39 -2 44 5 9 14
19 2 25 -31 6 -28 11 -33 30 -26 20 8 19 24 -2 30 -12 3 -9 5 8 6 19 1 28 -5
32 -20 4 -15 11 -20 21 -16 11 5 16 0 16 -13 0 -11 -6 -20 -14 -20 -8 0 -18
-9 -21 -20 -4 -13 -15 -20 -32 -20 -13 0 -23 -5 -21 -11 2 -6 7 -10 11 -10 4
1 19 1 33 1 21 0 24 4 19 20 -4 11 -2 20 4 20 6 0 11 -3 11 -8 0 -12 59 -34
75 -28 27 10 16 23 -15 18 -34 -5 -35 -4 -18 35 17 39 17 39 32 17 13 -16 18
-17 35 -6 19 11 21 10 21 -11 0 -34 19 -47 66 -47 25 0 47 -6 54 -15 8 -10 16
-12 20 -6 8 14 49 19 47 6 -1 -5 -8 -9 -14 -9 -8 1 -13 -10 -13 -27 0 -22 5
-29 19 -29 10 0 24 7 31 15 7 8 9 15 5 15 -4 0 -2 6 4 13 6 8 8 17 5 20 -4 3
6 5 22 4 23 -2 29 2 29 18 0 18 -7 20 -59 20 -55 0 -60 2 -66 25 l-6 24 -16
-23 c-10 -15 -29 -26 -48 -28 -25 -2 -33 2 -42 23 -11 23 -10 28 7 40 11 8 25
12 32 10 7 -3 15 -1 18 4 4 6 -3 10 -14 10 -11 0 -23 5 -26 11 -8 12 23 49 39
48 6 0 6 -3 -1 -6 -24 -9 -13 -24 15 -21 15 2 26 8 25 16 -2 6 3 12 11 12 14
0 24 35 30 97 4 44 9 48 42 26 22 -14 25 -19 11 -21 -9 -2 -17 -8 -17 -14 0
-5 6 -8 14 -5 14 6 40 -38 31 -53 -3 -5 -1 -11 4 -14 12 -8 34 58 33 97 -1 31
22 47 41 28 16 -16 47 -14 47 4 0 9 -7 18 -15 21 -8 4 -15 13 -15 20 0 17 29
31 39 18 13 -19 62 -45 72 -38 6 3 -6 14 -27 24 -22 10 -31 19 -22 19 9 1 20
-3 23 -9 9 -15 19 -12 43 10 20 19 22 30 22 144 0 67 -2 125 -5 127 -2 3 -20
3 -40 1 -28 -3 -37 -10 -42 -31 -4 -14 -6 -32 -4 -40 2 -11 -1 -10 -13 2 -21
21 -20 43 2 56 16 10 16 12 -1 25 -16 13 -17 19 -6 56 l13 43 154 -5 c169 -5
183 -11 181 -67 -1 -23 -2 -22 -10 8 -10 32 -11 32 -29 16 -21 -19 -19 -27 13
-62 14 -16 17 -26 10 -35 -6 -7 -13 -44 -17 -83 -4 -38 -8 -78 -9 -87 -1 -9 3
-17 8 -18 6 -1 15 -10 20 -20 20 -37 34 -8 25 50 -4 30 -8 42 -9 27 0 -16 -5
-25 -11 -22 -11 7 -9 125 2 125 4 0 9 -17 11 -37 4 -41 12 95 10 160 -1 26 2
37 13 37 8 0 14 8 14 18 0 25 24 67 38 67 34 0 41 56 11 86 -14 14 -19 28 -15
45 4 13 4 24 0 24 -11 0 -34 -29 -44 -56 l-11 -27 -15 21 c-9 13 -13 31 -9 42
4 11 2 20 -4 20 -6 0 -11 -4 -11 -10 0 -5 -11 -12 -25 -16 -14 -3 -22 -10 -19
-16 3 -5 -3 -12 -15 -15 -12 -3 -21 -9 -21 -14 0 -5 -5 -9 -10 -9 -7 0 -7 6 0
20 14 26 -23 49 -47 28 -13 -10 -17 -10 -20 0 -3 6 -9 9 -15 6 -19 -12 -120
-15 -133 -4 -9 8 -14 6 -19 -5 -8 -22 -35 -18 -57 8 l-18 22 -1 -24 c0 -22 -3
-23 -40 -18 -31 4 -40 2 -40 -9 0 -12 -5 -12 -30 1 -17 9 -38 14 -47 13 -24
-4 -28 27 -6 49 10 11 19 31 20 46 2 26 0 27 -48 27 -30 0 -49 -4 -49 -11z
m468 -137 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2 31 -12z m122
-92 c-9 -6 -9 -9 -1 -15 7 -4 23 0 36 9 17 11 30 13 47 7 18 -8 19 -10 5 -10
-11 -1 -17 -7 -15 -18 3 -17 -16 -18 -267 -21 -197 -2 -276 0 -292 9 -26 14
-30 29 -8 29 9 0 18 6 21 14 3 8 15 14 27 14 12 0 39 3 60 7 35 7 37 6 37 -19
0 -25 2 -26 55 -26 52 0 91 13 107 34 3 6 39 9 80 8 40 -1 80 3 88 8 10 6 17
4 24 -7 5 -10 4 -18 -4 -23z m-345 -80 c4 -6 15 -8 26 -5 16 5 19 1 19 -24 0
-29 -1 -30 -37 -25 -43 5 -91 43 -71 56 18 11 56 10 63 -2z m-95 -7 c0 -17
-19 -35 -29 -29 -18 12 -12 36 9 36 11 0 20 -3 20 -7z m120 -103 c13 -9 12
-13 -9 -30 -13 -11 -18 -20 -12 -20 12 0 15 -58 5 -85 -5 -13 -9 -14 -15 -3
-8 12 -7 -18 6 -119 3 -29 1 -32 -28 -35 -26 -2 -32 -7 -32 -27 -1 -45 -20
-32 -33 22 -18 76 -16 87 13 87 18 0 23 -4 19 -15 -7 -17 14 -48 26 -40 18 11
-8 60 -41 78 -50 28 -65 57 -29 57 11 0 20 7 20 15 0 8 -4 15 -10 15 -5 0 -12
11 -16 25 -4 16 -13 25 -25 25 -26 0 -24 19 4 29 84 32 131 38 157 21z m30
-107 c0 -69 -12 -95 -26 -61 -15 33 -6 118 12 118 10 0 14 -15 14 -57z m-20
-112 c0 -17 -18 -21 -24 -6 -3 9 0 15 9 15 8 0 15 -4 15 -9z m54 -16 c3 -8 1
-15 -4 -15 -6 0 -10 7 -10 15 0 8 2 15 4 15 2 0 6 -7 10 -15z m-451 -272 c-7
-2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m4 -29 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M3943 12913 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4302 12905 c-11 -12 -10 -15 3 -15 8 0 15 7 15 15 0 18 -2 18 -18 0z"/>
<path d="M4160 12900 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z"/>
<path d="M3972 13130 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M3996 13143 c-4 -9 -4 -18 -1 -21 3 -3 5 0 5 6 0 7 14 12 32 12 35 0
31 12 -6 16 -15 1 -27 -4 -30 -13z"/>
<path d="M4246 13133 c-7 -24 5 -42 25 -43 15 0 30 31 24 50 -9 29 -41 25 -49
-7z"/>
<path d="M4420 13130 c0 -28 2 -30 20 -20 11 6 20 20 20 30 0 13 -7 20 -20 20
-16 0 -20 -7 -20 -30z"/>
<path d="M4471 13134 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3255 13112 c-6 -4 -12 -23 -13 -42 -1 -19 -5 -42 -7 -51 -4 -10 4
-20 20 -27 14 -7 25 -20 25 -31 0 -11 9 -21 23 -25 22 -6 22 -7 -6 -22 -18
-10 -39 -13 -61 -8 -34 6 -34 6 -18 33 10 17 13 37 8 54 -10 40 -24 33 -28
-14 -2 -29 -9 -45 -23 -53 -11 -6 -26 -18 -33 -26 -10 -11 -15 -12 -18 -2 -3
6 -21 12 -39 12 -22 0 -43 8 -59 23 -23 22 -24 25 -10 45 19 27 10 28 -16 2
-11 -11 -31 -20 -44 -20 -14 0 -28 -4 -31 -10 -12 -19 -45 -11 -45 10 0 11 6
20 13 20 8 0 7 4 -3 10 -24 15 -32 12 -25 -11 15 -45 -35 -106 -52 -63 -4 11
-8 12 -13 4 -5 -8 -19 -10 -40 -5 -24 5 -29 10 -19 16 11 7 11 9 1 9 -7 0 -10
5 -7 10 3 6 1 10 -4 10 -6 0 -10 3 -9 8 1 4 -4 22 -12 40 -13 28 -20 32 -52
32 -35 0 -38 -2 -44 -37 -10 -54 10 -146 44 -195 16 -24 32 -52 36 -63 l8 -20
8 20 c8 18 9 18 9 -5 1 -21 9 -28 48 -42 53 -19 63 -16 63 15 0 41 -12 65 -36
76 -13 6 -24 19 -24 30 0 11 -6 21 -12 24 -9 3 -7 7 5 12 11 4 29 2 44 -6 23
-13 27 -12 41 9 18 27 32 28 32 2 0 -11 5 -20 11 -20 11 0 8 35 -6 73 -4 9 -3
17 3 17 14 0 52 -49 52 -67 0 -27 26 -44 52 -34 17 7 19 10 6 10 -11 1 -18 9
-18 22 0 15 5 20 23 17 12 -2 22 -10 22 -18 0 -26 22 -40 62 -39 l38 1 -42 11
c-43 10 -56 30 -27 41 26 10 62 7 66 -5 3 -9 9 -9 24 -1 18 10 69 15 126 13
25 -1 41 -22 35 -46 -1 -5 -26 -8 -57 -7 -30 2 -66 -1 -80 -7 -24 -10 -23 -11
10 -9 72 2 106 -2 90 -12 -12 -7 -10 -11 11 -18 14 -6 25 -19 26 -30 1 -12 -3
-19 -10 -16 -7 2 -18 -8 -25 -23 -8 -18 -9 -29 -2 -31 5 -2 10 -12 10 -23 0
-23 13 -25 30 -4 40 48 39 -31 -2 -123 -30 -66 -29 -87 4 -76 26 8 47 -21 48
-63 0 -21 5 -33 14 -33 7 0 19 -7 26 -15 9 -11 16 -12 25 -5 6 5 16 6 20 2 5
-4 5 1 2 10 -3 9 -2 18 4 20 6 2 3 23 -6 53 -9 28 -20 64 -25 81 -13 40 -13
149 0 196 6 21 20 44 30 51 17 11 18 19 10 45 -6 18 -14 46 -19 62 -7 21 -6
28 1 24 41 -26 -74 186 -121 223 -18 14 -51 16 -66 5z m-592 -99 c-4 -11 -8
-13 -11 -5 -2 7 -9 9 -14 6 -7 -4 -8 -2 -4 4 12 19 36 15 29 -5z m603 -10
c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m-621 -91 c-8 -8
-13 0 -20 33 l-7 30 16 -29 c9 -16 14 -31 11 -34z m339 23 c-7 -16 -39 -20
-49 -6 -3 5 3 12 12 14 34 9 42 7 37 -8z m-287 -147 c-3 -8 -6 -5 -6 6 -1 11
2 17 5 13 3 -3 4 -12 1 -19z m649 -25 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2
-7 1 -15 -2 -18z m-14 -55 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3
12 -12z"/>
<path d="M4920 13069 c-73 -93 -88 -127 -99 -224 -7 -58 -7 -93 -1 -99 7 -7
10 -5 10 4 0 10 5 8 14 -6 17 -24 27 -146 16 -199 -4 -22 -15 -66 -24 -98 -19
-69 -20 -87 -5 -87 17 0 38 -31 38 -55 0 -11 -6 -29 -14 -39 -12 -16 -18 -17
-27 -8 -14 14 -48 16 -48 2 0 -5 -13 -7 -30 -3 -23 4 -30 2 -30 -11 0 -9 -8
-16 -18 -16 -21 0 -46 36 -32 45 6 3 10 24 10 45 0 21 5 42 10 45 15 9 12 25
-5 25 -9 0 -12 6 -9 15 4 8 1 15 -5 15 -6 0 -12 -6 -13 -12 -2 -22 -5 -33 -12
-48 -3 -8 -7 -35 -9 -59 l-2 -43 -55 -2 -55 -1 -3 50 c-2 27 -7 54 -11 60 -5
5 -7 20 -6 32 2 31 -43 49 -85 34 -48 -16 -70 2 -70 61 0 26 -4 49 -9 52 -5 3
-6 35 -3 71 4 60 3 65 -15 65 -14 0 -22 8 -26 28 -7 32 -37 53 -66 45 -25 -6
-27 -25 -6 -46 8 -9 11 -17 6 -19 -5 -2 -10 -32 -11 -68 -4 -89 -2 -112 10
-105 6 4 10 -10 10 -36 0 -23 3 -39 7 -36 3 4 5 27 5 51 -1 25 3 48 9 51 6 5
8 22 4 46 -9 49 17 98 41 78 17 -14 19 -86 2 -92 -10 -3 -10 -11 0 -39 16 -45
16 -118 0 -118 -7 0 -2 -7 10 -16 32 -22 29 -46 -5 -39 -16 3 -21 2 -13 -2 25
-11 42 -32 34 -44 -3 -6 -8 -33 -10 -60 -3 -39 0 -50 15 -59 14 -7 19 -6 18 3
0 6 1 40 1 75 2 56 4 62 23 62 15 0 19 5 15 18 -3 9 -1 24 4 32 19 31 34 -7
35 -93 0 -76 1 -82 20 -79 11 2 23 8 26 14 3 5 24 8 45 6 l39 -3 5 -62 c4 -49
2 -64 -9 -68 -19 -7 -100 5 -106 16 -3 4 -14 1 -25 -8 -31 -27 -13 -34 67 -28
62 4 74 3 70 -9 -3 -7 0 -19 6 -25 9 -9 8 -14 -3 -21 -13 -9 -13 -10 0 -10 13
0 13 -1 0 -10 -12 -8 -9 -10 13 -10 16 0 26 4 22 10 -3 6 -1 10 5 10 6 0 8 5
5 10 -3 6 0 10 7 10 8 0 5 5 -5 11 -13 8 -17 18 -13 35 4 14 2 24 -4 24 -5 0
-10 14 -10 30 0 28 2 29 20 18 11 -7 20 -18 20 -25 0 -7 10 -13 22 -13 20 0
20 1 4 19 -19 21 -14 41 8 41 8 0 16 -10 19 -22 5 -18 9 -19 21 -9 8 7 19 10
25 7 16 -10 41 24 37 50 -4 19 -1 24 17 24 18 0 19 -2 7 -10 -11 -7 3 -10 47
-10 39 0 65 5 70 13 4 6 17 17 28 23 15 9 20 22 20 58 1 28 6 51 14 55 8 6 9
11 3 15 -6 3 -12 2 -14 -3 -1 -5 -16 -12 -33 -16 -20 -4 -25 -3 -17 3 9 6 11
18 6 37 -5 21 -4 26 5 21 13 -9 51 20 51 38 0 6 -5 4 -10 -4 -5 -8 -10 -11
-10 -7 0 4 7 19 16 32 12 18 14 35 9 66 -4 25 -10 37 -15 29 -5 -8 -9 -7 -14
5 -10 26 -7 105 4 105 6 0 10 -7 10 -15 0 -8 6 -15 14 -15 7 0 19 7 26 15 8
10 9 15 1 15 -6 0 -8 11 -4 30 6 33 -10 41 -26 13 -5 -10 -13 -15 -17 -11 -10
11 24 58 42 58 8 0 12 5 9 10 -3 6 1 10 9 10 9 0 16 5 16 10 0 6 13 10 29 10
17 0 33 5 36 10 3 6 18 8 33 5 23 -4 24 -4 7 4 -17 8 -15 10 15 10 26 1 31 -2
20 -9 -11 -7 -8 -10 15 -9 17 0 39 4 50 9 18 7 18 8 -7 15 -15 4 -49 4 -75 0
-27 -3 -73 -8 -103 -9 -35 -2 -54 -7 -52 -14 1 -7 -4 -12 -10 -12 -7 0 -22
-14 -33 -30 -11 -16 -22 -30 -25 -30 -10 0 -6 44 5 60 6 8 10 24 10 35 0 17 9
21 58 26 32 4 61 10 65 13 11 12 -21 26 -46 19 -12 -3 -29 0 -37 7 -14 11 -14
15 1 31 22 25 66 24 89 -1 10 -11 24 -20 32 -20 7 0 13 -9 13 -20 0 -21 24
-23 190 -20 56 1 130 16 130 26 0 3 -10 14 -22 24 -19 15 -21 21 -10 31 6 7
12 19 12 26 0 22 -27 14 -49 -13 -18 -23 -26 -26 -56 -21 -19 3 -35 2 -35 -4
0 -5 19 -10 43 -10 l42 -2 -50 -7 c-68 -11 -107 2 -125 42 -8 18 -13 36 -12
41 1 4 -5 7 -13 7 -8 0 -15 -4 -15 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15
-10 0 -15 -36 -12 -49 3 -8 10 -23 12 -58 7 -27 -5 -50 -6 -52 -4 -2 2 1 4 7
4 7 0 12 7 12 16 0 9 5 12 12 8 33 -21 53 -10 48 25 -1 8 3 17 10 21 6 4 8 14
4 24 -5 13 -13 16 -35 11 -18 -4 -29 -2 -29 4 0 26 -33 7 -70 -40z m-45 -309
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m13 -44
c-7 -8 -15 -12 -17 -11 -5 6 10 25 20 25 5 0 4 -6 -3 -14z m-608 -26 c0 -5 -4
-10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m600 -320 c0
-5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-237
-178 c-5 -22 -23 -31 -23 -12 0 10 23 40 27 36 1 -1 0 -12 -4 -24z m74 12 c-3
-3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M3027 13082 c-10 -10 -17 -27 -17 -37 0 -18 1 -18 15 1 11 14 15 15
14 4 -3 -30 1 -50 10 -50 5 0 8 15 7 33 -1 22 3 32 14 32 21 0 32 18 17 27
-20 13 -43 9 -60 -10z"/>
<path d="M4048 13093 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5187 13094 c-3 -3 -2 -17 3 -29 7 -19 5 -25 -8 -29 -9 -3 1 -4 23
-2 30 3 46 -2 64 -18 13 -11 22 -16 19 -11 -2 6 -12 26 -21 46 -17 38 -61 61
-80 43z"/>
<path d="M2837 13062 c-23 -25 -21 -35 4 -22 19 11 20 10 9 -10 -16 -30 -8
-34 20 -10 29 25 29 25 24 -2 -5 -23 -4 -23 6 0 21 49 -28 83 -63 44z"/>
<path d="M5380 13055 c-10 -12 -10 -15 4 -15 9 0 16 -6 16 -14 0 -18 40 -9 47
11 12 30 -45 45 -67 18z"/>
<path d="M3100 13046 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M5566 13018 c-20 -29 -20 -48 0 -48 14 0 14 -2 0 -15 -8 -8 -21 -15
-28 -16 -7 -1 -2 -5 12 -10 21 -7 18 -8 -15 -5 -44 4 -95 -19 -95 -43 0 -7 -8
-20 -17 -28 -17 -13 -16 -14 7 -8 74 20 87 18 98 -14 7 -19 7 -33 1 -36 -5 -4
-9 -1 -9 5 0 6 -5 8 -11 5 -6 -4 -8 -14 -5 -22 3 -8 -1 -30 -10 -49 -9 -19
-14 -41 -10 -49 6 -18 -2 -45 -15 -45 -5 0 -9 9 -9 20 0 11 -4 20 -10 20 -6 0
-10 -28 -10 -65 0 -67 -13 -83 -40 -50 -25 30 -37 17 -42 -46 -3 -45 -10 -66
-24 -77 -16 -12 -16 -14 -1 -9 13 5 17 1 18 -16 2 -27 20 49 20 81 -1 19 4 22
37 22 21 0 44 6 51 13 11 10 12 8 7 -10 -4 -12 -3 -24 2 -27 14 -9 39 25 46
64 17 88 18 112 5 121 -11 8 -9 14 10 30 19 15 25 32 29 74 3 34 9 55 17 55 7
0 10 -11 7 -30 -6 -43 13 -37 43 13 24 42 35 104 16 93 -5 -4 -7 4 -3 16 3 14
2 19 -6 14 -7 -4 -12 -1 -12 8 0 8 5 18 10 21 6 3 10 19 10 36 0 25 -4 29 -29
29 -20 0 -35 -8 -45 -22z"/>
<path d="M3760 13016 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M3105 13010 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M5190 13010 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M3040 12960 c0 -21 20 -25 55 -10 19 7 18 9 -8 19 -39 15 -47 14 -47
-9z"/>
<path d="M3120 12966 c0 -9 7 -16 16 -16 17 0 14 22 -4 28 -7 2 -12 -3 -12
-12z"/>
<path d="M2166 12925 c-3 -8 -1 -15 5 -15 6 0 7 -4 4 -10 -3 -5 3 -10 14 -10
12 0 21 -6 21 -14 0 -8 7 -16 15 -20 16 -6 11 -34 -10 -51 -33 -28 -15 -95 22
-80 12 4 14 0 9 -17 -3 -13 -6 -28 -6 -34 0 -6 -13 -17 -30 -24 -16 -7 -30
-16 -30 -21 0 -10 76 -31 98 -27 14 3 13 8 -9 29 -20 21 -23 30 -16 47 8 18
34 32 63 32 6 0 10 -10 10 -22 -1 -17 5 -23 21 -23 34 -1 47 -18 34 -44 -7
-12 -18 -21 -26 -21 -10 0 -15 -10 -15 -27 0 -16 -5 -35 -10 -43 -8 -12 -10
-9 -10 13 0 33 -16 34 -54 3 -18 -15 -24 -26 -17 -30 6 -4 11 -16 11 -27 0
-11 5 -17 10 -14 6 3 10 -4 10 -16 0 -12 -3 -19 -7 -16 -9 10 -33 -13 -33 -32
0 -13 8 -16 41 -13 35 4 42 9 51 34 12 34 32 32 36 -5 4 -35 -14 -67 -38 -67
-18 0 -19 -5 -13 -63 l5 -62 -34 -13 c-19 -6 -46 -12 -59 -12 -14 0 -30 -5
-36 -11 -6 -6 -22 -7 -37 -3 -28 7 -28 7 -22 73 5 51 3 73 -10 96 -27 51 -44
104 -33 110 12 8 12 45 -1 45 -5 0 -7 -6 -4 -14 3 -8 0 -17 -7 -22 -7 -4 -10
-3 -5 4 3 6 2 13 -4 17 -5 3 -10 -1 -10 -9 0 -9 -7 -16 -16 -16 -14 0 -15 -9
-10 -67 4 -38 9 -84 12 -103 4 -26 3 -31 -5 -20 -6 8 -11 27 -11 43 0 15 -4
27 -10 27 -5 0 -12 11 -16 25 -3 14 -15 30 -26 36 -15 8 -17 13 -7 25 6 8 18
14 26 14 10 0 14 7 10 20 -3 11 1 26 8 34 16 16 9 26 -17 26 -18 0 -18 1 2 22
22 24 26 38 10 38 -5 0 -10 -7 -10 -15 0 -8 -7 -15 -15 -15 -9 0 -27 -7 -40
-16 -19 -12 -25 -13 -25 -3 0 8 -6 5 -15 -7 -13 -17 -14 -13 -15 49 0 53 3 67
15 67 8 0 15 5 15 11 0 6 -12 9 -27 6 -16 -2 -22 -2 -15 0 10 4 10 8 1 19 -15
18 -29 18 -29 -1 0 -20 -36 -19 -44 1 -3 9 -6 27 -6 40 0 25 -24 34 -33 12 -4
-10 -6 -10 -6 0 -1 7 -5 10 -11 7 -6 -3 -7 1 -3 10 3 10 8 13 12 6 4 -5 12 -7
18 -3 8 5 6 14 -7 32 -16 20 -22 23 -37 14 -20 -12 -25 -40 -7 -37 7 1 10 -6
7 -17 -3 -11 2 -31 11 -45 20 -30 20 -35 2 -35 -25 0 -1 -59 42 -104 21 -23
43 -52 50 -66 16 -34 62 -196 62 -220 0 -23 16 -35 23 -16 3 7 24 18 47 24 39
10 42 9 51 -15 12 -32 12 -33 -7 -33 -10 0 -14 -6 -11 -15 5 -11 1 -12 -17 -5
-28 10 -86 20 -86 14 0 -3 7 -21 15 -41 22 -52 19 -66 -20 -87 -19 -11 -35
-23 -35 -28 0 -4 15 -16 34 -25 46 -22 54 -52 19 -77 -14 -11 -35 -29 -47 -41
-16 -16 -30 -20 -58 -17 -36 4 -37 5 -28 47 1 6 2 22 1 37 -1 21 -7 27 -27 30
-21 2 -29 13 -44 53 -40 113 -67 164 -77 154 -8 -8 -12 4 -14 41 -2 29 -1 54
4 57 4 2 4 9 1 15 -4 6 -2 13 5 17 17 11 -30 123 -57 137 -12 6 -36 8 -54 5
-26 -4 -28 -6 -10 -9 12 -2 22 -8 22 -13 0 -5 8 -9 18 -9 24 0 65 -48 58 -66
-5 -12 -10 -13 -26 -4 -24 13 -45 1 -56 -32 -3 -11 -12 -17 -20 -14 -7 3 -26
-1 -41 -9 l-28 -15 42 0 c23 0 43 -5 45 -11 3 -8 10 -5 22 6 15 15 19 16 27 4
5 -8 8 -27 7 -44 -3 -25 -8 -31 -30 -33 -29 -3 -37 -16 -15 -26 10 -5 10 -7 0
-12 -26 -12 -12 -24 30 -25 33 0 38 -2 22 -9 -20 -9 -20 -9 2 -15 12 -4 27
-20 33 -36 7 -16 16 -29 20 -29 13 0 18 -74 6 -83 -8 -6 -8 -10 0 -13 36 -15
47 -31 35 -52 -15 -28 -7 -28 18 -1 22 24 27 19 36 -43 8 -56 3 -58 -49 -23
-20 14 -38 25 -40 25 -2 0 -6 -26 -8 -59 -3 -37 -10 -65 -21 -75 -16 -16 -17
-14 -17 33 0 44 -3 51 -20 51 -15 0 -20 -7 -20 -24 0 -14 4 -27 8 -30 13 -8 2
-68 -16 -88 -14 -16 -13 -18 6 -18 14 0 22 6 22 16 0 13 7 15 35 10 31 -6 36
-4 45 19 5 14 9 31 7 38 -3 14 77 57 166 88 37 13 67 27 67 31 0 5 9 8 19 8
11 0 30 13 44 30 22 26 24 33 14 55 -7 14 -14 25 -17 25 -8 0 -6 57 2 65 4 4
33 9 64 11 37 2 61 9 66 19 5 8 15 12 22 9 8 -3 17 -1 21 5 3 6 14 11 23 11
10 0 35 9 56 20 29 14 50 18 82 13 30 -4 44 -2 44 6 0 7 9 9 27 5 31 -8 64
-34 57 -46 -3 -4 4 -8 14 -8 14 0 20 7 20 25 0 15 -6 25 -14 25 -19 0 -17 17
3 29 26 14 19 135 -13 238 -20 64 -25 94 -20 135 9 79 9 118 -2 171 -9 49 -28
71 -71 82 -13 3 -51 24 -84 45 -66 44 -82 48 -91 25z m165 -117 c11 -11 19
-23 19 -25 0 -12 -22 -43 -31 -43 -13 0 -44 30 -44 43 0 6 -9 13 -20 18 -17 6
-17 8 -2 8 9 1 17 6 17 11 0 18 42 10 61 -12z m-461 -214 c0 -11 -4 -12 -19
-2 -10 6 -17 18 -14 25 7 17 33 -2 33 -23z m110 -74 c13 -8 13 -11 -2 -27 -17
-16 -19 -16 -35 0 -16 16 -16 19 -3 27 8 5 17 10 20 10 3 0 12 -5 20 -10z
m-70 -10 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10
-10z m-33 -32 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-267
-109 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z m350 1 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m416 -35
c-4 -14 -2 -34 4 -45 9 -16 8 -20 -5 -20 -8 0 -15 6 -15 14 0 7 -3 28 -6 45
-5 25 -3 31 11 31 13 0 16 -6 11 -25z m-254 -140 c23 -27 22 -38 -1 -25 -27
14 -31 13 -24 -6 5 -13 0 -14 -33 -8 -53 9 -60 18 -40 48 9 14 16 33 16 41 0
14 6 12 33 -7 17 -12 40 -32 49 -43z m285 43 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m-410 -60 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12
1 -19z"/>
<path d="M6050 12920 c-19 -11 -66 -36 -103 -55 -76 -40 -88 -59 -89 -139 0
-34 -3 -45 -9 -35 -11 16 -1 -36 11 -61 9 -18 0 -76 -29 -184 -25 -95 -28
-204 -6 -212 8 -4 15 -12 15 -20 0 -8 6 -14 13 -14 16 0 38 63 43 125 6 71 13
97 24 90 5 -3 6 9 3 29 -4 28 -1 36 16 45 25 14 28 31 4 31 -15 0 -15 1 0 18
20 21 21 45 6 73 -8 16 -7 25 7 40 9 10 24 19 32 19 12 0 12 3 2 15 -7 8 -19
14 -28 13 -24 -5 -25 19 -1 25 19 5 20 8 9 21 -7 9 -21 16 -32 16 -17 0 -18 3
-8 15 10 12 10 15 0 15 -7 0 0 10 15 22 15 12 40 21 56 21 16 0 29 4 29 9 0 4
8 8 17 8 9 0 14 3 10 6 -8 8 23 39 46 47 21 8 18 31 -4 35 -8 1 -30 -7 -49
-18z"/>
<path d="M2010 12871 c-7 -14 -6 -21 3 -24 9 -3 9 -9 -1 -26 -10 -17 -10 -26
-2 -36 19 -22 3 -34 -37 -28 l-36 6 19 -26 c19 -26 42 -37 79 -37 18 0 18 1
-3 16 -27 19 -28 34 -3 34 23 0 36 16 21 25 -13 8 -3 67 13 76 16 11 0 39 -22
39 -11 0 -25 -9 -31 -19z"/>
<path d="M6217 12879 c-9 -6 -15 -13 -13 -17 4 -6 43 -8 65 -3 6 1 -21 31 -28
31 -3 0 -14 -5 -24 -11z"/>
<path d="M2168 12873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1871 12846 c-9 -11 -10 -20 -1 -35 12 -24 15 -25 40 -11 17 9 18 14
8 35 -13 28 -30 32 -47 11z"/>
<path d="M6368 12849 c-19 -11 -26 -79 -8 -79 19 0 51 41 48 62 -4 26 -17 31
-40 17z"/>
<path d="M3098 12843 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5358 12838 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M6226 12841 c-3 -5 1 -11 9 -15 9 -3 15 0 15 9 0 16 -16 20 -24 6z"/>
<path d="M4160 12829 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M4250 12805 c0 -33 12 -46 25 -25 8 12 -5 60 -16 60 -5 0 -9 -16 -9
-35z"/>
<path d="M4112 12807 c-15 -18 -11 -56 5 -50 7 3 15 14 19 25 8 26 -9 44 -24
25z"/>
<path d="M5280 12810 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M5373 12813 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6511 12807 c-15 -15 -14 -34 2 -40 6 -3 3 -6 -8 -7 -11 0 -38 -19
-60 -40 -22 -22 -46 -40 -52 -40 -7 0 -13 -8 -13 -19 0 -24 25 -73 33 -64 4 3
7 -1 7 -10 0 -27 -15 -27 -53 -1 -29 19 -41 22 -55 15 -15 -9 -14 -10 9 -11
24 0 49 -16 49 -32 0 -4 -11 -8 -25 -8 -14 0 -25 5 -25 10 0 6 -9 10 -20 10
-11 0 -20 -4 -20 -10 0 -5 5 -10 11 -10 5 0 7 -4 4 -10 -6 -10 7 -14 33 -11 8
2 12 -5 10 -16 -2 -10 -9 -19 -16 -19 -6 -1 -17 -2 -23 -3 -7 -1 -8 -18 -3
-53 4 -29 7 -69 6 -89 -1 -22 4 -41 13 -49 23 -19 18 -40 -10 -40 -21 0 -25
-5 -25 -30 0 -16 5 -30 10 -30 6 0 10 -4 10 -10 0 -5 -15 -10 -32 -10 -24 0
-29 -3 -19 -9 12 -8 8 -11 -15 -16 -16 -4 -36 -9 -44 -12 -25 -8 28 -24 56
-17 18 5 31 1 45 -15 11 -12 24 -19 30 -16 14 9 11 -37 -6 -69 -17 -32 -20
-66 -5 -66 6 0 10 -6 10 -14 0 -8 6 -17 13 -19 6 -3 -2 -3 -19 0 -25 4 -35 0
-47 -18 -15 -21 -15 -23 10 -36 30 -15 97 -18 88 -3 -3 6 -16 10 -28 11 -21 0
-21 1 -2 8 l20 8 -20 5 c-11 3 8 6 43 7 40 1 61 -3 57 -9 -4 -6 5 -10 19 -10
14 0 26 -4 26 -10 0 -5 14 -10 30 -10 17 0 30 -4 30 -8 0 -9 44 -32 60 -32 14
0 40 55 40 85 0 22 4 25 35 25 21 0 37 -6 43 -16 16 -28 55 -13 64 25 11 42 5
48 -32 34 -25 -9 -30 -9 -30 3 0 19 -16 18 -24 -1 -4 -11 -14 -13 -36 -8 -24
4 -31 2 -28 -8 1 -8 -8 -29 -21 -47 -18 -26 -29 -32 -50 -30 -21 2 -27 9 -29
35 -3 27 0 32 12 27 12 -5 15 -2 10 10 -3 9 -1 16 4 16 6 0 13 9 17 19 3 10
16 22 28 26 13 4 17 8 10 11 -10 4 -9 12 2 35 9 16 13 41 10 56 -4 23 -3 22
10 -7 l14 -35 -5 38 c-7 40 14 77 43 77 7 0 13 9 13 19 0 11 5 23 10 26 6 3
10 1 10 -4 0 -6 7 -11 15 -11 16 0 20 18 7 35 -10 13 -70 17 -78 4 -3 -5 0
-16 6 -24 17 -20 5 -44 -20 -42 -19 1 -21 7 -18 62 3 58 4 60 30 58 21 -1 28
3 30 20 2 17 9 21 29 19 20 -2 28 2 32 19 4 15 11 20 23 15 9 -4 28 1 41 10
36 23 16 37 -54 38 -45 1 -58 5 -61 17 -3 16 11 24 83 50 18 6 18 7 -5 10 -79
7 -120 -17 -120 -71 0 -16 -5 -30 -11 -32 -6 -2 -17 -48 -24 -103 -10 -71 -24
-124 -51 -185 -20 -47 -40 -96 -44 -110 l-8 -25 -1 24 c-1 23 -2 24 -14 7 -8
-10 -12 -29 -9 -42 2 -12 -2 -32 -10 -43 -13 -19 -15 -19 -48 -3 -19 9 -36 20
-38 24 -2 4 -10 8 -17 8 -21 0 -33 31 -28 73 4 31 9 37 29 37 32 0 30 12 -4
32 -15 9 -36 26 -46 37 -17 18 -17 21 -3 21 23 0 40 64 36 133 -4 58 6 79 14
30 4 -26 4 -26 6 4 0 18 8 58 17 90 9 32 16 65 17 73 1 8 7 18 13 22 6 4 30
32 53 62 32 41 41 60 36 76 -5 15 -13 20 -26 17 -16 -4 -17 -2 -8 16 6 12 27
36 46 54 21 18 33 38 30 46 -7 19 -43 27 -56 14z m-194 -399 c-3 -8 -6 -5 -6
6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4308 12803 c-20 -5 -22 -12 -9 -32 8 -12 13 -12 35 3 39 25 23 42
-26 29z"/>
<path d="M5152 12791 c13 -21 87 -30 163 -21 53 7 25 19 -59 26 -34 3 -73 7
-88 9 -25 4 -27 3 -16 -14z"/>
<path d="M6014 12794 c-2 -8 -6 -20 -8 -28 -5 -14 12 -36 26 -36 4 0 8 7 8 15
0 8 7 15 15 15 8 0 15 8 15 19 0 23 -49 36 -56 15z"/>
<path d="M3012 12788 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M3068 12793 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5418 12783 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2880 12770 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M3058 12773 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4160 12771 c0 -10 30 -24 37 -17 3 3 2 9 -2 15 -8 13 -35 15 -35 2z"/>
<path d="M5450 12765 c0 -9 -4 -14 -9 -11 -4 3 -6 -6 -3 -19 4 -21 7 -23 24
-14 12 6 18 19 16 31 -4 26 -28 37 -28 13z"/>
<path d="M6270 12770 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1880 12754 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M6088 12763 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4332 12749 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
<path d="M5566 12745 c-3 -8 -1 -15 4 -15 6 0 10 7 10 15 0 8 -2 15 -4 15 -2
0 -6 -7 -10 -15z"/>
<path d="M6200 12750 c-24 -8 -23 -8 8 -9 17 -1 32 4 32 9 0 11 -5 11 -40 0z"/>
<path d="M2950 12723 c0 -25 0 -26 20 -8 11 10 20 19 20 20 0 2 -9 5 -20 8
-16 4 -20 0 -20 -20z"/>
<path d="M3218 12736 c-10 -7 -18 -18 -18 -25 0 -6 -9 -11 -20 -11 -23 0 -26
-10 -8 -28 13 -13 65 -16 70 -4 2 4 1 24 -2 44 -4 33 -7 35 -22 24z m2 -46 c0
-5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M5280 12730 c0 -23 4 -24 31 -10 27 15 24 30 -6 30 -18 0 -25 -5 -25
-20z"/>
<path d="M6275 12737 c-4 -10 -5 -21 -1 -24 10 -10 18 4 13 24 -4 17 -4 17
-12 0z"/>
<path d="M6333 12734 c-19 -20 -16 -38 3 -20 8 9 14 20 12 25 -2 5 -9 3 -15
-5z"/>
<path d="M1710 12735 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M2100 12720 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z"/>
<path d="M2895 12715 c-3 -8 -7 -21 -9 -29 -4 -12 13 -26 30 -26 2 0 4 16 4
35 0 35 -17 48 -25 20z"/>
<path d="M5360 12716 c0 -8 -10 -22 -22 -30 l-22 -16 21 0 c12 0 24 -6 26 -12
3 -8 6 -6 6 5 1 9 6 17 11 17 6 0 10 11 10 25 0 16 -6 25 -15 25 -8 0 -15 -6
-15 -14z"/>
<path d="M6218 12723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M1906 12711 c-4 -7 -5 -15 -2 -18 9 -9 19 4 14 18 -4 11 -6 11 -12 0z"/>
<path d="M5137 12693 c-13 -8 -16 -12 -7 -13 11 0 11 -3 2 -12 -14 -14 -16
-28 -3 -28 5 0 13 9 16 20 9 27 21 25 37 -7 7 -14 18 -28 23 -29 10 -3 45 57
45 77 0 13 -86 7 -113 -8z m93 -25 c0 -4 -7 -8 -15 -8 -8 0 -15 4 -15 8 0 5 7
9 15 9 8 0 15 -4 15 -9z"/>
<path d="M6145 12687 c-15 -7 -20 -20 -20 -48 0 -22 2 -39 6 -39 3 0 16 11 29
25 13 14 18 25 12 25 -7 0 -12 9 -12 19 0 14 8 21 28 24 25 4 25 4 2 4 -14 0
-34 -4 -45 -10z"/>
<path d="M3056 12682 c-3 -5 1 -9 9 -9 8 0 12 4 9 9 -3 4 -7 8 -9 8 -2 0 -6
-4 -9 -8z"/>
<path d="M1950 12670 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M2085 12669 c-13 -19 4 -22 42 -6 l38 16 -37 0 c-20 1 -40 -4 -43
-10z"/>
<path d="M2754 12655 c-8 -32 4 -135 16 -135 5 0 11 -8 13 -17 4 -15 5 -15 6
-2 1 17 47 29 131 32 44 2 45 1 48 -30 3 -35 -6 -41 -26 -20 -17 16 -62 16
-62 0 0 -8 6 -13 13 -12 19 3 76 -24 81 -38 3 -7 1 -13 -3 -13 -5 0 -12 6 -14
13 -3 6 -6 0 -6 -15 -1 -16 4 -28 9 -28 6 0 10 5 10 10 0 15 26 12 40 -5 11
-13 15 -12 32 7 18 21 19 32 13 118 -10 139 -10 140 -56 140 l-39 0 0 -50 c0
-44 -2 -50 -21 -50 -12 0 -23 8 -26 19 -8 31 -33 35 -33 6 0 -27 -7 -30 -29
-16 -7 5 -10 14 -7 20 4 6 2 11 -5 11 -8 0 -10 13 -6 43 3 23 3 35 1 25 -8
-25 -32 -30 -44 -8 -15 27 -28 25 -36 -5z"/>
<path d="M3104 12648 c3 -18 10 -51 14 -73 6 -31 9 -36 15 -21 9 23 -2 88 -14
80 -5 -3 -9 2 -9 11 0 9 5 13 10 10 6 -3 10 1 10 9 0 9 -7 16 -16 16 -13 0
-15 -7 -10 -32z"/>
<path d="M5070 12670 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>
<path d="M6206 12661 c-17 -19 -17 -20 13 -36 31 -15 51 -13 51 6 0 5 -7 9
-15 9 -8 0 -15 9 -15 20 0 25 -12 25 -34 1z"/>
<path d="M6302 12663 c4 -20 18 -26 37 -14 18 12 5 31 -20 31 -14 0 -19 -6
-17 -17z"/>
<path d="M5411 12656 c-11 -13 -11 -20 -3 -28 8 -8 13 -7 18 5 10 27 0 41 -15
23z"/>
<path d="M5240 12641 c-12 -24 -30 -191 -24 -227 3 -16 1 -23 -6 -19 -5 3 -10
1 -10 -5 0 -17 52 0 79 25 17 16 25 37 27 67 8 104 17 154 32 166 13 10 7 12
-36 12 -41 0 -53 -4 -62 -19z"/>
<path d="M2931 12634 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6021 12641 c-19 -12 -4 -31 25 -31 23 0 54 20 54 34 0 9 -64 7 -79
-3z"/>
<path d="M4956 12624 c-8 -21 -8 -24 3 -24 12 0 22 28 12 35 -5 3 -12 -2 -15
-11z"/>
<path d="M5000 12620 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M3266 12601 c-3 -5 2 -15 11 -22 14 -12 16 -12 9 0 -5 8 -3 17 5 22
11 7 11 9 -3 9 -9 0 -19 -4 -22 -9z"/>
<path d="M5062 12578 c4 -40 25 -48 34 -13 4 18 2 30 -7 36 -24 15 -30 10 -27
-23z"/>
<path d="M5135 12578 c-17 -35 -20 -51 -5 -28 9 13 12 12 25 -5 11 -14 14 -32
9 -62 -4 -29 -3 -43 5 -43 6 0 11 11 11 25 0 13 8 29 18 34 15 9 15 12 2 21
-12 8 -13 13 -2 30 6 11 12 28 11 37 -1 12 -4 9 -8 -7 -7 -24 -8 -25 -15 -3
-11 35 -33 35 -51 1z"/>
<path d="M5337 12588 c-8 -29 -4 -32 11 -8 6 10 8 21 3 24 -5 3 -11 -4 -14
-16z"/>
<path d="M1288 12580 c-26 -25 -22 -40 12 -40 17 0 30 -4 30 -9 0 -5 11 -8 25
-8 13 0 22 3 19 8 -3 5 0 9 7 9 17 0 -19 32 -51 46 -20 9 -28 8 -42 -6z"/>
<path d="M1910 12590 c0 -5 9 -10 19 -10 11 0 23 5 26 10 4 6 -5 10 -19 10
-14 0 -26 -4 -26 -10z"/>
<path d="M2051 12586 c-10 -12 -8 -17 8 -26 17 -9 21 -8 21 5 0 8 6 15 14 15
8 0 18 5 21 10 10 16 -51 12 -64 -4z"/>
<path d="M6195 12590 c11 -5 38 -8 60 -8 38 0 39 1 10 8 -16 4 -43 8 -60 8
-25 1 -27 -1 -10 -8z"/>
<path d="M6940 12570 c0 -5 -12 -10 -27 -11 -34 -1 -79 -17 -50 -18 9 -1 17
-7 17 -15 0 -8 7 -17 16 -20 18 -7 15 -52 -4 -48 -6 1 -12 -2 -12 -8 0 -5 -7
-10 -15 -10 -8 0 -15 -4 -15 -9 0 -13 68 -51 91 -51 20 0 26 -15 9 -25 -20
-12 -9 -25 15 -19 23 6 25 3 25 -25 0 -18 -5 -31 -12 -31 -7 0 0 -12 15 -25
32 -29 28 -31 -13 -10 l-30 15 12 -25 c6 -13 19 -27 29 -30 9 -3 20 -12 23
-21 3 -8 17 -19 31 -24 26 -10 35 -40 11 -40 -15 0 -39 -57 -30 -71 3 -5 -2
-9 -10 -9 -23 0 -39 -38 -32 -74 5 -25 2 -37 -14 -51 -15 -13 -20 -14 -20 -5
0 11 -3 11 -12 2 -10 -10 -10 -15 2 -23 13 -9 13 -10 -1 -6 -11 4 -21 -3 -29
-19 -8 -13 -17 -24 -22 -24 -4 0 -8 -14 -8 -31 0 -28 -2 -31 -25 -25 -20 5
-25 3 -25 -14 0 -13 9 -22 25 -26 14 -3 25 -8 25 -9 0 -2 -13 -17 -30 -33 -16
-17 -30 -38 -30 -46 0 -9 -8 -16 -20 -16 -11 0 -19 -3 -19 -7 4 -22 -3 -33
-20 -33 -11 0 -21 6 -23 14 -3 13 -6 13 -22 1 -16 -12 -17 -17 -6 -30 11 -14
9 -20 -18 -43 -29 -25 -66 -99 -134 -267 -11 -27 -25 -58 -29 -68 -8 -14 -4
-19 16 -27 15 -6 33 -10 42 -10 19 0 73 -58 73 -78 0 -11 -9 -13 -42 -8 l-43
6 30 -14 c56 -27 60 -32 41 -51 -9 -9 -33 -17 -53 -18 -20 -1 -31 -3 -25 -5 5
-1 8 -11 5 -20 -3 -14 -14 -16 -58 -14 l-54 4 12 -26 c7 -14 20 -26 31 -26 13
0 16 -3 8 -12 -9 -12 -12 -33 -14 -93 0 -19 -4 -20 -73 -10 -41 5 -77 13 -81
18 -4 4 -18 7 -30 7 -13 0 -26 5 -29 10 -3 6 -14 10 -23 10 -9 0 -29 6 -43 13
-30 17 -89 20 -105 6 -8 -6 -17 -5 -27 6 -9 8 -28 15 -43 15 -22 0 -25 -2 -14
-15 10 -13 9 -15 -9 -15 -11 0 -21 -5 -21 -11 0 -8 -5 -8 -15 1 -8 7 -26 10
-40 8 -17 -4 -25 -1 -25 8 0 8 -9 14 -20 14 -11 0 -20 -4 -20 -10 0 -5 7 -10
16 -10 8 0 12 -4 9 -10 -3 -5 3 -10 14 -10 12 0 21 -7 21 -15 0 -9 9 -15 22
-15 12 0 18 3 15 7 -4 3 5 12 19 20 32 16 48 17 38 1 -5 -8 -2 -9 12 -4 22 9
22 9 54 7 26 -1 85 -45 85 -64 0 -12 50 -28 60 -18 4 3 6 -15 4 -41 -4 -45 8
-67 30 -54 12 8 -4 109 -18 114 -6 2 -9 8 -5 13 3 5 0 9 -5 9 -6 0 -11 -4 -11
-10 0 -18 -25 -10 -50 17 l-25 26 45 -5 c25 -3 61 -12 80 -21 19 -8 49 -18 65
-21 56 -12 60 -13 58 -19 -2 -4 -3 -11 -3 -17 0 -5 -10 -10 -22 -11 -13 0 -32
-4 -43 -8 -17 -7 -15 -11 23 -36 49 -32 44 -48 -11 -36 -21 5 -37 5 -37 0 0
-5 5 -9 10 -9 6 0 16 -11 22 -25 6 -14 20 -25 31 -25 11 0 28 -9 37 -20 18
-21 18 -21 38 12 11 18 23 44 27 58 6 23 5 23 -14 -5 -19 -28 -21 -28 -21 -7
0 12 5 22 10 22 6 0 10 7 10 15 0 8 6 12 14 9 18 -7 50 51 41 74 -3 9 -3 13 1
9 4 -4 13 5 21 19 16 33 16 35 -2 28 -18 -7 -19 0 -5 27 6 10 20 19 30 19 11
0 20 5 20 10 0 6 23 10 50 10 28 0 50 5 50 10 0 6 7 10 15 10 8 0 17 11 21 25
3 14 10 25 16 25 5 0 7 4 3 9 -3 6 0 30 6 56 11 45 11 45 -30 90 l-42 45 24
48 c46 89 67 137 67 150 0 6 5 12 10 12 6 0 10 14 10 30 0 21 5 30 16 30 16 0
115 195 130 255 4 17 11 35 16 41 4 6 5 20 1 29 -4 11 -2 16 4 12 6 -4 22 29
38 76 44 128 111 241 184 312 22 22 47 56 55 75 l14 35 -44 3 c-39 2 -45 0
-53 -22 -11 -26 -31 -44 -31 -26 0 6 -4 8 -10 5 -6 -4 -5 -11 3 -20 7 -8 16
-10 22 -4 14 14 45 11 45 -5 0 -8 -4 -18 -10 -21 -5 -3 -10 1 -10 11 0 13 -3
14 -15 4 -17 -14 -21 -35 -5 -25 6 3 10 2 10 -4 0 -16 -18 -22 -24 -9 -3 7 -4
4 -1 -7 3 -12 -5 -32 -20 -50 -23 -28 -24 -29 -25 -7 0 12 4 22 9 22 9 0 31
52 31 73 0 5 -10 7 -23 3 -25 -6 -83 36 -73 53 4 5 2 12 -4 16 -6 4 -7 12 -3
18 5 7 8 26 8 42 1 17 4 41 8 55 4 14 4 19 1 13 -3 -7 -17 -13 -30 -13 -17 0
-24 6 -24 20 0 11 -4 20 -8 20 -9 0 -32 59 -32 84 0 9 8 20 17 23 15 6 15 7
-3 14 -18 7 -17 8 3 8 22 1 48 33 36 45 -10 10 -53 7 -53 -4z m123 -515 c-5
-13 -23 -22 -23 -11 0 15 14 35 21 31 4 -3 6 -12 2 -20z m-136 -357 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-87 -122 c0 -13 -6 -23 -15 -23
-16 0 -19 15 -9 41 8 22 24 10 24 -18z m-60 -34 c-6 -2 -10 -10 -10 -17 0 -7
-6 -16 -12 -18 -7 -3 -3 -6 10 -6 12 -1 22 -6 22 -12 0 -5 -4 -7 -10 -4 -5 3
-10 -1 -10 -9 0 -10 -6 -13 -16 -9 -13 5 -13 7 -2 14 7 5 8 9 2 9 -10 0 -39
50 -32 54 26 16 54 22 60 12 4 -6 3 -12 -2 -14z m-70 -163 l-22 -34 7 30 c7
34 27 63 33 47 1 -5 -7 -25 -18 -43z m-33 -108 c-10 -35 -9 -45 1 -48 18 -7
15 -20 -5 -26 -10 -2 -27 -8 -38 -13 -19 -8 -19 -7 -2 19 9 14 17 31 17 37 0
16 30 84 34 79 3 -2 -1 -24 -7 -48z m18 -201 c-3 -5 -10 -10 -16 -10 -5 0 -9
5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m37 -72 c-6 -6 -12 -6 -17 3 -5 8 -4
10 3 5 7 -4 12 0 13 11 0 12 2 13 6 5 3 -8 1 -18 -5 -24z m-63 1 c-22 -13 -31
-11 -17 5 7 8 17 12 22 9 4 -3 2 -9 -5 -14z m-106 -58 c-2 -6 4 -11 12 -11 14
0 13 -3 -2 -24 l-18 -24 -12 23 c-14 26 -7 58 11 52 6 -3 10 -10 9 -16z m104
-47 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-231 -241 c-10
-10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z"/>
<path d="M1222 12528 c-34 -21 -37 -34 -5 -24 10 3 24 15 31 26 7 11 11 20 10
19 -2 0 -18 -10 -36 -21z"/>
<path d="M5984 12542 c-6 -4 -14 -27 -17 -52 -3 -25 -9 -55 -13 -67 -6 -22 -4
-23 44 -18 49 4 51 3 66 -27 23 -45 20 -58 -12 -58 -35 0 -45 -9 -31 -27 16
-18 24 -17 53 11 20 18 26 33 26 67 0 41 -2 45 -27 47 -39 3 -53 21 -60 80 -6
53 -9 58 -29 44z"/>
<path d="M6040 12533 c1 -17 55 -46 90 -49 33 -2 37 0 33 17 -4 15 -14 19 -44
19 -22 0 -39 5 -39 10 0 6 -9 10 -20 10 -11 0 -20 -3 -20 -7z"/>
<path d="M6250 12515 c-10 -12 -10 -15 2 -15 8 0 15 7 15 15 0 8 -1 15 -2 15
-2 0 -8 -7 -15 -15z"/>
<path d="M2116 12505 c-2 -7 4 -15 15 -18 11 -3 19 -14 19 -27 0 -14 8 -24 21
-27 16 -5 19 -11 14 -34 -8 -33 4 -37 29 -10 18 19 12 51 -9 51 -7 0 -18 7
-25 15 -10 12 -10 18 0 30 16 19 13 22 -27 28 -21 3 -33 1 -37 -8z"/>
<path d="M3122 12500 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5121 12504 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6184 12508 c-5 -7 -9 -21 -9 -32 -2 -18 -1 -18 16 2 9 12 23 22 31
22 10 0 9 3 -2 10 -20 13 -27 12 -36 -2z"/>
<path d="M7060 12505 c7 -8 19 -15 27 -15 13 0 13 3 3 15 -7 8 -19 15 -27 15
-13 0 -13 -3 -3 -15z"/>
<path d="M3200 12465 c1 -26 2 -28 11 -12 8 14 8 22 0 30 -8 8 -11 3 -11 -18z"/>
<path d="M4270 12476 c0 -8 5 -18 10 -21 6 -3 10 3 10 14 0 12 -4 21 -10 21
-5 0 -10 -6 -10 -14z"/>
<path d="M6194 12448 c-10 -18 -26 -45 -37 -60 -17 -22 -19 -37 -15 -93 3 -36
8 -68 11 -71 10 -10 56 9 61 24 3 10 12 55 21 102 8 47 18 95 21 108 5 19 2
22 -20 22 -19 0 -30 -9 -42 -32z"/>
<path d="M1102 12438 c-54 -44 -54 -59 1 -17 23 18 44 34 46 36 2 2 2 5 -1 8
-3 3 -24 -9 -46 -27z"/>
<path d="M1348 12463 c-10 -2 -18 -13 -18 -23 0 -12 5 -17 14 -14 7 3 16 -2
19 -10 10 -25 37 -19 37 7 0 22 -17 48 -29 46 -4 -1 -14 -3 -23 -6z"/>
<path d="M5458 12454 c-13 -13 -20 -15 -31 -6 -8 7 -17 9 -20 5 -11 -10 -8
-93 3 -93 6 0 10 8 10 18 0 27 11 34 25 16 9 -14 16 -14 33 -5 25 13 28 39 9
64 -11 17 -13 17 -29 1z"/>
<path d="M6123 12463 c-23 -4 -33 -23 -13 -23 6 0 10 -13 10 -30 0 -16 5 -30
10 -30 9 0 14 25 12 60 0 8 5 18 11 22 13 8 7 8 -30 1z"/>
<path d="M1494 12451 c-17 -4 -36 -11 -42 -15 -19 -12 -14 -33 10 -39 24 -7
51 10 45 27 -2 6 3 16 12 23 18 14 18 14 -25 4z"/>
<path d="M7140 12456 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2794 12442 c4 -7 1 -12 -8 -12 -9 0 -16 -4 -16 -10 0 -15 39 -32 60
-25 23 7 27 45 6 45 -8 0 -22 3 -32 7 -12 4 -15 3 -10 -5z"/>
<path d="M2880 12431 c0 -25 6 -27 13 -6 4 8 2 17 -3 20 -6 4 -10 -3 -10 -14z"/>
<path d="M5063 12443 c-15 -6 -17 -49 -3 -58 6 -3 10 -5 10 -3 0 2 4 18 9 36
10 32 7 35 -16 25z"/>
<path d="M3290 12425 c0 -8 4 -15 9 -15 5 0 11 7 15 15 3 9 0 15 -9 15 -8 0
-15 -7 -15 -15z"/>
<path d="M4164 12425 c-4 -8 -11 -15 -18 -15 -6 0 -19 -7 -30 -15 -17 -13 -17
-14 10 -21 16 -4 38 -14 48 -23 18 -15 19 -15 12 4 -16 40 -12 50 16 47 30 -4
48 16 27 29 -22 14 -59 10 -65 -6z"/>
<path d="M2012 12400 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3594 12386 c-3 -14 -10 -26 -15 -26 -4 0 -5 -10 0 -22 5 -13 14 -23
20 -23 6 0 16 -6 20 -14 10 -15 31 -7 31 12 0 7 -12 20 -27 29 -21 13 -23 18
-10 18 21 0 22 23 2 40 -12 10 -15 7 -21 -14z"/>
<path d="M3209 12397 c-1 -1 -3 -12 -4 -24 -2 -19 1 -21 16 -12 23 12 24 25 4
33 -8 3 -16 4 -16 3z"/>
<path d="M1024 12375 c-10 -8 -14 -15 -8 -15 6 0 17 7 24 15 16 19 9 19 -16 0z"/>
<path d="M3110 12376 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M5156 12383 c-11 -11 -6 -23 9 -23 8 0 15 4 15 9 0 13 -16 22 -24 14z"/>
<path d="M1281 12365 c-13 -7 -45 -31 -70 -52 -58 -48 -90 -66 -118 -65 -17 1
-21 -2 -16 -16 6 -15 5 -16 -13 -2 -10 8 -22 20 -26 25 -15 20 -8 -7 12 -46
11 -21 43 -63 70 -92 27 -28 67 -86 87 -127 21 -41 41 -76 45 -78 5 -2 8 -13
8 -24 0 -30 71 -189 82 -182 5 3 4 12 -3 20 -15 18 -8 26 11 14 11 -7 12 -14
2 -35 -10 -22 -8 -37 18 -103 38 -98 66 -153 74 -145 3 4 6 -2 7 -13 0 -18 1
-18 9 1 7 17 9 15 9 -13 1 -18 5 -40 10 -50 49 -96 69 -141 63 -148 -4 -4 1
-4 11 -1 12 5 17 1 17 -14 0 -11 5 -29 11 -40 8 -15 6 -27 -8 -48 -68 -108
-75 -122 -69 -146 15 -61 117 -135 161 -118 9 3 23 -9 36 -29 11 -18 19 -26
16 -17 -3 8 3 21 13 27 15 9 20 8 23 -6 4 -15 5 -15 6 1 1 13 7 17 19 13 10
-3 31 6 52 24 l35 30 -45 -6 c-25 -4 -51 -12 -59 -18 -12 -9 -13 -7 -8 14 4
14 7 28 7 33 0 4 9 7 21 7 26 0 20 24 -8 28 -15 2 -23 -5 -31 -27 -8 -23 -15
-29 -28 -24 -14 5 -15 3 -5 -15 17 -32 14 -44 -9 -30 -17 11 -19 21 -16 65 3
48 2 52 -15 43 -9 -6 -20 -18 -22 -27 -2 -10 -3 -8 -2 5 1 12 -2 25 -6 28 -5
3 -1 17 8 32 21 32 32 41 47 38 6 -2 8 -1 4 1 -15 9 -8 23 11 23 11 0 23 5 26
10 3 6 17 10 30 10 13 0 27 5 30 10 4 6 -18 10 -58 10 -60 0 -65 2 -60 20 3
12 0 21 -8 21 -8 1 4 5 26 8 39 6 40 7 39 46 0 26 -17 74 -48 137 -26 54 -45
102 -41 108 3 5 1 10 -5 10 -6 0 -17 15 -25 34 -19 44 -27 49 -53 30 -31 -22
-28 -70 8 -139 17 -31 30 -63 30 -71 0 -7 11 -38 25 -68 14 -30 25 -57 25 -59
0 -3 -10 -4 -23 -4 -15 0 -27 9 -35 25 -15 32 -15 42 -2 42 6 0 10 -6 10 -14
0 -8 5 -18 11 -22 12 -7 7 15 -14 66 -6 14 -11 30 -12 35 -3 20 -35 84 -45 90
-5 3 -10 17 -10 31 0 13 -6 24 -12 24 -10 0 -9 3 1 9 11 7 9 13 -8 30 -33 33
-15 55 45 54 6 0 9 8 7 18 -3 11 3 23 16 31 12 7 21 21 21 30 0 24 -3 23 -40
-12 -18 -16 -41 -30 -52 -30 -26 0 -60 -19 -53 -29 3 -5 -2 -11 -10 -15 -8 -3
-15 -13 -15 -23 0 -10 -8 -2 -20 20 -24 45 -25 46 -1 40 12 -3 20 2 24 16 3
12 12 21 19 21 10 0 13 9 10 30 -5 32 -22 52 -22 25 0 -9 -4 -13 -10 -10 -5 3
-10 15 -10 26 0 10 -5 19 -10 19 -6 0 -8 11 -4 25 4 18 1 27 -10 31 -29 11
-18 42 24 65 45 25 57 47 33 56 -9 4 -19 1 -21 -5 -2 -7 -18 -13 -35 -13 -18
-1 -24 -3 -14 -6 23 -6 22 -20 -3 -28 -27 -8 -43 3 -37 26 3 11 2 19 -3 19
-10 0 -36 66 -45 113 -4 20 -13 37 -20 37 -7 0 -15 9 -18 21 -3 11 -15 23 -26
26 -12 3 -21 12 -21 19 0 8 -5 14 -11 14 -5 0 -7 5 -4 11 4 6 9 8 11 6 3 -3
18 8 34 24 16 16 36 29 46 29 9 0 15 3 11 6 -8 9 42 62 67 70 31 10 46 30 51
69 4 26 1 35 -10 35 -8 0 -15 -8 -15 -17 0 -10 -21 -37 -47 -61 -35 -33 -48
-40 -55 -30 -7 10 -8 8 -4 -4 6 -20 -36 -68 -59 -68 -8 0 -15 -4 -15 -9 0 -12
-39 -21 -46 -10 -8 14 -24 10 -24 -6 0 -8 -4 -15 -10 -15 -5 0 -10 5 -10 11 0
5 -4 8 -8 5 -4 -2 -8 4 -8 14 0 10 3 18 6 18 26 -2 32 3 20 17 -16 19 -5 34
12 17 16 -16 29 -15 22 2 -5 12 37 49 126 111 46 31 39 46 -9 20z m-116 -255
c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m145
-200 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z
m340 -810 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4
-10z m50 -4 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z"/>
<path d="M2225 12363 c-7 -23 -25 -32 -25 -13 0 19 -17 10 -28 -15 -14 -30
-16 -46 -3 -26 10 16 31 6 31 -15 0 -8 7 -14 15 -14 8 0 15 5 15 11 0 5 5 7
10 4 6 -3 13 1 17 10 3 10 1 20 -5 24 -5 3 -12 17 -15 31 -4 25 -4 25 -12 3z"/>
<path d="M3040 12369 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M6800 12369 c0 -5 9 -9 20 -9 11 0 20 2 20 4 0 2 -9 6 -20 9 -11 3
-20 1 -20 -4z"/>
<path d="M2830 12347 c0 -21 12 -28 27 -15 11 9 16 9 23 -2 5 -8 12 -11 16 -7
4 3 1 13 -6 22 -15 19 -60 20 -60 2z"/>
<path d="M2955 12350 c-3 -5 3 -10 14 -10 11 0 23 5 26 10 3 6 -3 10 -14 10
-11 0 -23 -4 -26 -10z"/>
<path d="M4000 12346 c0 -21 21 -31 31 -15 14 22 11 29 -11 29 -11 0 -20 -6
-20 -14z"/>
<path d="M4090 12351 c0 -11 29 -35 35 -29 10 9 -7 38 -21 38 -8 0 -14 -4 -14
-9z"/>
<path d="M5438 12315 c-3 -46 -1 -50 19 -37 13 8 7 82 -7 82 -5 0 -10 -20 -12
-45z"/>
<path d="M6111 12318 c1 -37 2 -39 9 -18 12 36 12 60 0 60 -5 0 -10 -19 -9
-42z"/>
<path d="M3055 12330 c-3 -5 -12 -7 -19 -4 -20 8 -28 -12 -11 -26 9 -7 15 -8
15 -2 0 5 8 9 17 8 10 0 19 7 21 17 4 18 -13 24 -23 7z"/>
<path d="M5938 12323 c-21 -26 -52 -102 -44 -110 3 -4 25 -7 49 -8 23 -1 75
-13 115 -26 66 -21 74 -22 88 -7 14 14 9 16 -54 22 -47 5 -72 12 -76 22 -3 8
-18 14 -35 14 -32 0 -32 0 -15 83 6 31 -6 35 -28 10z"/>
<path d="M6720 12330 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M6800 12332 c0 -5 7 -13 16 -16 10 -4 14 -1 12 6 -5 15 -28 23 -28
10z"/>
<path d="M7227 12320 c-4 -16 0 -20 21 -19 16 1 20 3 10 6 -10 2 -15 10 -11
19 3 8 1 14 -5 14 -5 0 -12 -9 -15 -20z"/>
<path d="M1006 12310 c10 -39 21 -44 21 -10 0 17 -4 26 -7 19 -6 -8 -9 -7 -14
2 -3 8 -3 3 0 -11z"/>
<path d="M5135 12315 c-3 -8 -7 -22 -10 -30 -2 -9 -16 -15 -32 -15 -15 0 -35
-5 -43 -10 -11 -7 -7 -10 17 -10 44 0 93 30 93 58 0 25 -18 30 -25 7z"/>
<path d="M6890 12316 c-16 -20 -9 -60 13 -64 14 -3 17 4 17 37 0 43 -9 51 -30
27z"/>
<path d="M2728 12310 c-16 -10 -16 -13 0 -57 13 -35 14 -47 5 -50 -7 -3 -13
-15 -13 -28 0 -20 -7 -25 -35 -31 -19 -3 -41 -4 -49 -1 -8 3 -36 -1 -62 -9
-54 -16 -131 -10 -178 13 -29 15 -76 10 -76 -7 0 -5 11 -12 24 -16 25 -6 41
-40 31 -66 -3 -8 3 -22 14 -32 19 -18 20 -18 26 7 4 14 14 32 23 40 19 16 122
29 122 16 0 -5 -6 -9 -13 -9 -8 0 -14 -6 -14 -12 0 -7 -4 -21 -8 -30 -5 -14
-1 -17 25 -16 31 1 55 18 26 18 -9 0 -16 5 -16 10 0 6 24 10 53 10 37 0 48 3
37 10 -8 5 -30 10 -48 10 -22 0 -32 4 -30 13 3 8 31 13 81 15 65 2 81 0 105
-18 17 -12 35 -18 45 -14 18 6 24 24 8 24 -15 0 -18 45 -4 58 7 7 13 22 13 33
0 25 25 35 53 21 21 -11 207 -13 207 -2 0 3 -4 11 -9 19 -7 11 -13 12 -23 4
-7 -6 -48 -14 -90 -18 -70 -7 -78 -6 -78 10 0 12 -5 15 -16 11 -9 -3 -26 -6
-39 -6 -14 0 -25 -7 -27 -17 -2 -10 -7 7 -12 37 -9 65 -23 80 -58 60z m29
-182 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M3995 12298 c2 -9 0 -19 -4 -21 -3 -2 0 -2 8 0 7 2 20 -2 28 -8 17
-15 28 -5 19 16 -4 11 -13 14 -25 10 -12 -3 -21 -1 -24 7 -2 7 -3 5 -2 -4z"/>
<path d="M4264 12296 c-6 -25 1 -46 15 -46 11 0 21 38 14 56 -8 22 -22 17 -29
-10z"/>
<path d="M7040 12300 c0 -11 5 -20 10 -20 6 0 10 9 10 20 0 11 -4 20 -10 20
-5 0 -10 -9 -10 -20z"/>
<path d="M5520 12300 c-8 -5 -11 -12 -7 -16 4 -4 -2 -13 -13 -21 -24 -17 -26
-51 -5 -59 8 -4 15 -12 15 -20 0 -7 8 -18 18 -23 12 -8 13 -10 3 -11 -8 0 -12
-2 -9 -5 9 -9 38 6 38 20 0 8 -7 21 -16 30 -15 14 -14 17 10 36 31 24 33 40 8
62 -21 20 -22 20 -42 7z"/>
<path d="M3150 12290 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M3250 12289 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M1450 12283 c8 -4 21 -15 28 -25 15 -21 27 -17 35 12 5 18 0 20 -36
19 -23 0 -35 -3 -27 -6z"/>
<path d="M2572 12282 c-14 -10 -2 -54 18 -67 5 -3 8 -11 5 -16 -4 -5 1 -9 9
-9 23 0 23 85 0 94 -19 7 -16 7 -32 -2z"/>
<path d="M2180 12264 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M2230 12265 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>
<path d="M2975 12270 c-17 -7 -15 -9 13 -9 17 -1 32 4 32 9 0 12 -17 12 -45 0z"/>
<path d="M4125 12270 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M5670 12270 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M3200 12259 c0 -3 11 -11 24 -17 19 -9 27 -8 36 4 7 8 10 15 6 15
-21 4 -66 3 -66 -2z"/>
<path d="M4975 12256 c-16 -12 -16 -13 -1 -19 18 -7 46 1 46 13 0 15 -28 19
-45 6z"/>
<path d="M3304 12246 c-3 -8 -1 -23 5 -33 9 -15 10 -13 11 15 0 34 -7 42 -16
18z"/>
<path d="M6062 12248 c-18 -18 -15 -28 8 -28 13 0 20 7 20 20 0 23 -10 26 -28
8z"/>
<path d="M1220 12239 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M3921 12232 c-6 -10 -7 -29 -4 -40 3 -12 0 -28 -6 -36 -10 -12 -3
-15 39 -18 42 -2 50 0 50 15 0 11 -8 17 -23 17 -31 0 -38 8 -25 30 12 19 5 50
-11 50 -6 0 -15 -8 -20 -18z"/>
<path d="M5202 12220 c3 -35 9 -37 32 -11 21 23 14 41 -15 41 -15 0 -19 -6
-17 -30z"/>
<path d="M3141 12218 c-11 -29 -8 -32 24 -24 22 6 24 9 15 26 -14 26 -29 25
-39 -2z"/>
<path d="M4160 12233 c0 -15 38 -32 70 -32 19 0 28 3 20 6 -8 2 -24 8 -35 13
-36 15 -55 19 -55 13z"/>
<path d="M5360 12226 c0 -18 36 -22 60 -6 13 9 13 10 0 10 -8 0 -25 3 -37 6
-17 5 -23 2 -23 -10z"/>
<path d="M6101 12224 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6217 12220 c-10 -14 -20 -18 -33 -14 -15 5 -16 4 -8 -6 15 -15 51
-4 57 18 4 16 5 16 6 0 0 -10 6 -18 11 -18 14 0 12 16 -4 29 -11 9 -17 8 -29
-9z"/>
<path d="M6881 12224 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4001 12208 c0 -15 4 -38 10 -52 10 -20 9 -28 -5 -41 -9 -9 -16 -18
-16 -19 0 -9 51 -56 61 -56 7 0 9 3 6 7 -4 3 0 14 9 24 18 20 55 14 47 -8 -4
-11 -2 -14 8 -11 8 3 13 11 12 17 -2 6 4 11 12 11 8 0 15 -7 15 -16 0 -14 7
-15 37 -11 52 9 89 38 85 67 -4 28 -30 70 -43 70 -17 0 -9 -34 13 -57 l21 -23
-37 0 c-26 0 -35 4 -30 12 5 7 3 8 -5 3 -7 -4 -38 -9 -69 -10 -45 -3 -52 -1
-32 6 13 6 33 9 45 7 11 -2 25 3 31 11 18 23 -14 47 -59 42 -33 -3 -39 -7 -44
-32 -8 -42 -30 -36 -38 11 -4 22 -11 40 -15 40 -5 0 -11 8 -13 18 -3 10 -5 7
-6 -10z"/>
<path d="M5085 12220 c-4 -6 3 -10 14 -10 25 0 27 6 6 13 -8 4 -17 2 -20 -3z"/>
<path d="M5441 12214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5674 12216 c-6 -6 -22 -19 -34 -29 -21 -18 -21 -19 -3 -33 17 -14
15 -15 -17 -11 -22 3 -40 -1 -50 -11 -13 -14 -12 -15 18 -9 18 4 32 2 32 -3 0
-6 15 -10 34 -10 43 0 66 -24 37 -39 -11 -6 -36 -11 -55 -11 -20 0 -36 -4 -36
-9 0 -14 41 -20 85 -12 33 6 40 11 38 28 -3 27 11 32 67 24 37 -5 46 -10 48
-28 5 -37 41 -30 49 8 5 27 8 29 17 17 10 -14 13 -13 23 8 8 17 8 24 -1 28 -6
3 3 3 19 0 17 -3 44 -12 60 -19 44 -19 127 -23 133 -6 3 9 -1 11 -17 6 -11 -3
-21 -2 -21 3 0 5 -24 8 -52 6 -40 -3 -47 -1 -28 5 22 8 21 9 -15 10 -23 1 -45
5 -50 10 -6 4 -34 16 -62 27 -29 10 -53 22 -53 26 0 4 -9 8 -20 8 -12 0 -20
-7 -20 -16 0 -9 9 -18 20 -21 27 -7 25 -23 -2 -23 -13 0 -29 -4 -36 -9 -18
-11 -94 7 -109 26 -9 12 -9 20 4 39 17 27 15 39 -3 20z"/>
<path d="M3778 12213 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M5140 12210 c0 -5 9 -10 21 -10 11 0 17 5 14 10 -3 6 -13 10 -21 10
-8 0 -14 -4 -14 -10z"/>
<path d="M6917 12205 c-11 -8 -31 -11 -50 -8 -26 4 -38 -1 -65 -26 -39 -38
-40 -53 -5 -76 25 -17 28 -17 60 0 39 21 43 35 9 35 -30 0 -56 9 -56 20 0 14
31 20 51 10 13 -7 19 -7 19 0 0 5 14 10 30 10 20 0 30 5 30 15 0 8 5 15 11 15
5 0 7 5 4 10 -8 13 -16 12 -38 -5z"/>
<path d="M1360 12201 c11 -8 12 -11 2 -11 -7 0 -10 -5 -7 -11 4 -6 -2 -13 -14
-16 -12 -3 -21 -9 -21 -13 0 -4 -11 -12 -25 -18 -24 -11 -34 -45 -16 -56 4 -3
21 -40 36 -83 31 -91 36 -97 63 -88 29 9 35 37 18 80 -9 21 -13 41 -10 46 3 5
1 8 -5 7 -18 -5 -61 76 -50 92 8 13 10 13 19 0 8 -13 10 -12 10 3 0 9 7 17 15
17 9 0 18 7 21 15 7 17 31 20 36 5 2 -6 9 -1 16 10 11 19 10 20 -27 20 -22 0
-48 3 -58 6 -15 5 -15 4 -3 -5z"/>
<path d="M6778 12203 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3214 12191 c-9 -10 10 -21 37 -21 29 0 40 -15 17 -22 -7 -3 -6 -7 5
-11 12 -5 17 -1 17 12 0 16 24 30 51 31 3 0 7 -30 8 -67 1 -37 6 -72 12 -79 5
-6 10 -94 10 -203 0 -109 5 -191 10 -191 17 0 9 -20 -8 -21 -14 0 -13 -2 5 -9
22 -9 23 -13 26 -60 l1 -24 144 3 c79 1 155 7 167 11 13 5 49 7 81 3 74 -7 73
-7 228 2 151 9 157 9 211 0 51 -9 73 12 24 22 -18 3 -34 12 -37 20 -8 21 18
137 34 146 19 11 17 67 -2 67 -8 0 -15 -6 -16 -12 0 -7 -5 0 -10 17 -5 18 -5
44 2 69 13 48 5 83 -27 113 -36 33 -96 31 -142 -4 l-35 -26 7 -78 c6 -71 4
-81 -14 -99 -13 -13 -20 -32 -19 -57 0 -31 2 -34 9 -18 8 19 9 19 9 -2 1 -13
6 -23 11 -23 6 0 10 -10 9 -22 0 -22 -1 -22 -9 -3 -5 11 -9 14 -9 7 -1 -7 -5
-10 -11 -7 -5 3 -10 2 -10 -2 0 -5 10 -13 23 -18 14 -7 22 -19 22 -35 0 -22
-5 -26 -42 -31 -26 -3 -44 -1 -46 5 -9 24 7 122 21 132 13 9 13 12 -1 17 -30
11 -69 -8 -51 -26 3 -3 7 -29 8 -57 2 -55 7 -50 -71 -66 -12 -2 -23 1 -25 8
-3 8 -15 9 -44 2 -26 -7 -51 -7 -73 0 -26 7 -36 7 -39 -2 -2 -7 -12 -12 -22
-12 -10 0 -21 -7 -24 -16 -3 -8 -10 -12 -16 -9 -6 4 -8 11 -5 16 4 5 -7 9 -24
9 -16 0 -33 5 -37 11 -4 7 -15 1 -32 -16 -20 -22 -30 -26 -55 -21 -38 8 -37 7
-40 45 -1 17 -6 31 -12 31 -5 0 -2 5 8 11 11 7 17 22 17 49 0 22 -4 40 -8 40
-4 0 -8 28 -8 63 0 34 -5 100 -11 146 -7 53 -7 92 -2 105 6 12 6 44 2 76 -7
45 -12 55 -28 55 -11 0 -20 7 -19 15 1 12 -14 15 -73 15 -41 0 -76 -2 -79 -4z"/>
<path d="M4436 12192 c-2 -4 -1 -14 5 -22 7 -12 9 -10 9 8 0 23 -5 28 -14 14z"/>
<path d="M4840 12180 c0 -11 2 -20 5 -20 2 0 9 9 15 20 9 16 8 20 -5 20 -8 0
-15 -9 -15 -20z"/>
<path d="M4896 12168 c-16 -16 -35 -27 -41 -24 -14 5 -14 -36 0 -46 6 -4 0 -8
-12 -8 -13 0 -23 -4 -23 -10 0 -5 9 -10 21 -10 11 0 17 -4 14 -10 -3 -5 -1
-10 5 -10 6 0 9 -4 5 -10 -3 -5 0 -18 6 -28 10 -16 9 -23 -9 -42 -20 -22 -20
-22 -31 -2 -14 26 -14 65 0 79 7 7 1 12 -21 17 -22 4 -30 10 -27 20 3 8 -5 24
-19 37 -13 12 -24 21 -25 18 0 -2 -2 -14 -5 -26 -2 -13 0 -23 5 -23 5 0 11 -9
14 -21 4 -15 11 -19 25 -14 25 7 37 -22 27 -71 -6 -37 -35 -48 -35 -14 0 26
-17 26 -25 -1 -5 -15 -10 -17 -20 -9 -9 8 -15 8 -20 0 -3 -5 0 -10 7 -11 7 0
4 -4 -7 -9 -13 -5 -1 -7 38 -6 31 2 57 -1 57 -5 0 -5 7 -9 15 -9 8 0 15 -9 15
-20 0 -11 4 -20 10 -20 5 0 7 6 5 13 -7 19 12 44 24 32 19 -19 -3 -69 -39 -87
-32 -16 -35 -16 -50 0 -15 15 -15 18 -2 23 13 5 13 7 -2 12 -10 4 -25 3 -34
-3 -11 -6 -12 -10 -4 -10 9 0 10 -5 3 -18 -15 -28 -73 -18 -89 16 -15 31 -10
37 17 22 23 -12 45 -5 36 11 -4 5 -1 9 5 9 6 0 8 5 5 10 -3 6 -20 10 -36 10
-18 0 -28 4 -24 10 3 6 1 10 -5 10 -6 0 -8 5 -5 10 3 6 -4 10 -16 10 -17 0
-19 -3 -10 -13 6 -8 10 -32 8 -55 -1 -32 -6 -42 -19 -42 -10 0 -18 5 -18 10 0
6 -7 10 -15 10 -8 0 -15 -7 -15 -15 0 -14 12 -20 34 -16 4 1 17 -1 28 -5 16
-5 18 -8 7 -15 -10 -6 -8 -9 10 -9 20 0 22 -3 16 -27 -3 -16 -9 -44 -12 -63
-4 -29 -3 -33 8 -22 7 6 16 33 19 60 l5 47 60 1 c33 1 46 -1 29 -3 -34 -4 -70
-35 -60 -51 3 -6 15 -8 25 -5 10 3 22 0 26 -7 11 -18 -15 -40 -48 -40 -23 0
-28 -3 -20 -11 6 -6 33 -10 62 -8 43 3 51 1 51 -13 0 -12 -8 -18 -22 -18 -32
0 -52 -15 -45 -33 3 -8 -3 -24 -13 -35 -10 -11 -16 -23 -13 -26 4 -3 -8 -6
-25 -6 -18 0 -32 4 -32 9 0 19 -79 27 -213 21 -4 0 -3 -8 2 -19 6 -10 7 -21 4
-25 -8 -7 295 -6 315 2 8 3 11 13 8 28 -9 33 10 74 33 74 27 0 36 31 15 47
-10 7 -22 13 -27 13 -4 0 -6 9 -2 19 3 11 1 22 -5 26 -17 10 -12 45 5 39 10
-4 15 0 15 11 0 11 7 15 23 13 21 -3 22 -8 24 -103 1 -55 2 -106 2 -112 1 -19
28 -16 37 5 4 9 9 42 10 72 2 30 6 116 9 190 4 74 10 149 15 165 10 31 16 163
9 169 -2 1 -17 -10 -33 -26z m-19 -520 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-320 -104 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z"/>
<path d="M5021 12191 c-48 -5 -52 -8 -38 -20 10 -7 17 -19 17 -27 0 -7 9 -18
20 -24 11 -6 20 -17 20 -25 0 -20 15 -19 36 2 15 16 15 17 -2 24 -14 6 -11 8
14 8 21 1 32 6 32 16 0 8 -8 15 -17 15 -15 0 -16 -2 -3 -10 11 -7 0 -10 -37
-10 -29 0 -53 5 -53 10 0 6 7 10 15 10 8 0 15 5 15 10 0 6 18 10 40 10 22 0
40 5 40 10 0 10 -10 11 -99 1z"/>
<path d="M3050 12170 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M4340 12169 c0 -6 -9 -8 -20 -4 -23 7 -28 -16 -7 -33 7 -6 13 -37 14
-73 1 -37 7 -65 15 -70 14 -9 31 -2 24 10 -3 4 -8 23 -11 42 -3 19 -8 42 -11
53 -4 10 0 23 7 28 11 8 10 9 -3 4 -10 -3 -20 -1 -23 4 -4 6 12 11 37 11 24 1
33 4 20 6 -15 3 -22 10 -18 19 3 8 -1 14 -9 14 -8 0 -15 -5 -15 -11z"/>
<path d="M4488 12173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4538 12173 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5140 12170 c0 -5 10 -10 23 -10 18 0 19 2 7 10 -19 13 -30 13 -30 0z"/>
<path d="M5333 12168 c2 -7 10 -12 16 -10 6 1 11 -3 11 -10 0 -6 3 -8 6 -5 8
8 -14 37 -28 37 -5 0 -8 -6 -5 -12z"/>
<path d="M1440 12145 c-13 -14 -31 -25 -39 -25 -14 0 -14 -1 -1 -10 12 -8 13
-13 2 -30 -10 -17 -10 -20 3 -20 8 0 15 4 15 9 0 6 12 9 28 8 25 -2 27 1 30
37 4 51 -9 62 -38 31z"/>
<path d="M2910 12155 c0 -8 7 -15 15 -15 8 0 15 7 15 15 0 8 -7 15 -15 15 -8
0 -15 -7 -15 -15z"/>
<path d="M2990 12155 c0 -9 9 -15 21 -15 18 0 19 2 9 15 -7 8 -16 15 -21 15
-5 0 -9 -7 -9 -15z"/>
<path d="M3168 12163 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4800 12145 c0 -14 5 -25 10 -25 6 0 10 11 10 25 0 14 -4 25 -10 25
-5 0 -10 -11 -10 -25z"/>
<path d="M5985 12163 c15 -15 65 -25 65 -13 0 5 -6 10 -12 10 -7 0 -24 3 -38
6 -17 5 -22 3 -15 -3z"/>
<path d="M6168 12163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2235 12150 c3 -5 15 -10 26 -10 11 0 17 5 14 10 -3 6 -15 10 -26 10
-11 0 -17 -4 -14 -10z"/>
<path d="M5168 12152 c-68 -6 -40 -22 40 -23 46 0 69 -3 58 -8 -19 -7 -19 -8
3 -14 23 -6 37 11 29 34 -4 12 -62 17 -130 11z"/>
<path d="M6952 12153 c-6 -2 -9 -9 -6 -14 4 -6 11 -6 19 1 8 7 19 9 24 6 5 -4
-11 -28 -36 -53 -53 -55 -93 -115 -93 -140 -1 -16 -1 -17 -10 -2 -7 12 -5 30
4 57 8 22 12 43 9 46 -11 11 -33 -23 -33 -51 0 -16 -6 -35 -12 -42 -11 -11
-10 -13 5 -8 12 5 17 1 17 -13 0 -13 5 -18 15 -14 9 4 15 0 15 -9 0 -9 -10
-19 -22 -23 -29 -9 -51 -31 -43 -44 10 -16 -58 -12 -83 6 -25 17 -52 13 -52
-10 0 -7 7 -16 15 -20 8 -3 15 -10 15 -17 0 -6 -7 -9 -15 -5 -26 10 -45 -5
-45 -35 0 -16 4 -29 9 -29 23 0 43 15 53 39 10 24 11 25 18 6 7 -18 8 -19 15
-2 4 9 18 17 31 17 13 0 34 10 47 23 13 12 45 41 73 64 40 34 54 55 73 110 33
94 50 125 63 117 7 -4 8 -3 4 4 -4 7 -2 12 5 12 7 0 4 7 -7 15 -19 14 -48 18
-72 8z"/>
<path d="M2863 12134 c-3 -8 -1 -20 6 -27 8 -8 11 -4 11 16 0 30 -7 35 -17 11z"/>
<path d="M3040 12140 c0 -5 12 -10 26 -10 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 -4 -19 -10z"/>
<path d="M3120 12140 c-12 -8 -9 -10 13 -10 15 0 27 5 27 10 0 13 -20 13 -40
0z"/>
<path d="M3310 12135 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M4508 12143 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5396 12141 c-11 -17 4 -73 17 -68 18 5 20 40 4 61 -10 13 -16 16
-21 7z"/>
<path d="M5453 12133 c-17 -6 -16 -40 1 -46 13 -5 56 31 56 46 0 8 -38 8 -57
0z"/>
<path d="M2295 12120 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M3212 12110 c-17 -28 -15 -32 11 -28 14 2 23 11 25 26 4 28 -19 29
-36 2z"/>
<path d="M1600 12090 c0 -19 5 -30 14 -30 20 0 29 16 21 40 -10 32 -35 25 -35
-10z"/>
<path d="M2215 12110 c-10 -17 5 -31 26 -25 11 4 31 0 44 -9 30 -20 35 -20 35
-2 0 9 -15 17 -40 21 -22 4 -40 11 -40 16 0 12 -17 12 -25 -1z"/>
<path d="M3060 12101 c0 -11 -5 -21 -10 -23 -6 -2 -13 -19 -16 -39 -6 -34 -4
-37 25 -47 28 -10 31 -9 31 8 0 13 -9 22 -25 26 -29 7 -31 16 -10 34 13 10 19
10 33 -4 23 -20 32 -20 32 -1 0 8 -9 19 -20 25 -11 6 -17 16 -14 25 4 9 0 15
-10 15 -9 0 -16 -8 -16 -19z"/>
<path d="M3175 12110 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M6890 12105 c-6 -8 -10 -19 -8 -24 1 -5 11 2 21 15 19 26 8 34 -13 9z"/>
<path d="M4658 12103 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M2695 12090 c-3 -5 -3 -10 2 -10 4 0 1 -5 -7 -10 -12 -8 -11 -10 8
-10 12 0 22 -4 22 -10 0 -5 8 -10 18 -10 16 1 16 1 0 11 -10 5 -18 19 -18 29
0 21 -15 27 -25 10z"/>
<path d="M2975 12089 c-10 -15 1 -23 20 -15 9 3 13 10 10 16 -8 13 -22 13 -30
-1z"/>
<path d="M6230 12090 c-10 -6 -11 -10 -2 -10 6 0 12 -9 12 -20 0 -32 17 -23
18 10 1 32 -4 35 -28 20z"/>
<path d="M5633 12083 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2086 12073 c-4 -5 -19 -8 -32 -8 -32 0 -36 -19 -4 -20 66 -3 79 -7
108 -35 18 -16 36 -30 42 -30 18 0 10 19 -15 36 -14 9 -22 20 -19 25 3 5 -1 6
-9 3 -9 -3 -16 3 -20 15 -5 21 -36 29 -51 14z"/>
<path d="M4998 12073 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5305 12070 c3 -5 10 -10 16 -10 5 0 9 5 9 10 0 6 -7 10 -16 10 -8 0
-12 -4 -9 -10z"/>
<path d="M5520 12070 c0 -5 12 -10 26 -10 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 -4 -19 -10z"/>
<path d="M2910 12055 c0 -8 -7 -15 -15 -15 -22 0 -18 -17 5 -23 14 -3 20 -14
20 -31 0 -35 36 -36 42 -2 4 20 0 26 -16 28 -16 2 -20 9 -18 31 1 15 -2 27 -8
27 -5 0 -10 -7 -10 -15z"/>
<path d="M6140 12054 c-8 -8 -26 -23 -39 -33 -13 -10 -22 -23 -20 -30 3 -7 20
1 46 24 23 19 49 35 58 35 9 0 13 5 10 10 -9 14 -37 11 -55 -6z"/>
<path d="M2470 12050 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4650 12025 c0 -19 5 -35 10 -35 6 0 10 16 10 35 0 19 -4 35 -10 35
-5 0 -10 -16 -10 -35z"/>
<path d="M5085 12050 c-6 -10 19 -14 57 -11 10 1 26 -3 37 -8 13 -8 22 -8 26
-1 7 12 -4 16 -67 25 -28 4 -49 2 -53 -5z"/>
<path d="M5340 12050 c0 -5 -6 -10 -14 -10 -7 0 -16 -14 -20 -31 -9 -45 -8
-49 9 -49 10 0 15 10 15 29 0 16 7 36 15 45 17 17 19 26 5 26 -5 0 -10 -4 -10
-10z"/>
<path d="M2623 12043 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M3558 12043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3620 12031 c0 -11 5 -23 10 -26 6 -3 10 3 10 14 0 11 -4 23 -10 26
-5 3 -10 -3 -10 -14z"/>
<path d="M5978 12043 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3457 12027 c-3 -8 -1 -20 4 -28 8 -12 10 -12 18 0 10 15 4 41 -9 41
-4 0 -10 -6 -13 -13z"/>
<path d="M3810 12015 c0 -14 -8 -29 -20 -35 -31 -16 -24 -30 15 -30 37 0 40 5
29 59 -8 37 -24 41 -24 6z"/>
<path d="M3922 12023 c5 -26 38 -29 38 -4 0 15 -6 21 -21 21 -14 0 -19 -5 -17
-17z"/>
<path d="M4010 12026 c0 -6 9 -12 20 -12 11 0 20 3 20 7 0 4 -9 9 -20 12 -12
3 -20 0 -20 -7z"/>
<path d="M4150 12029 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M4218 12027 c-14 -11 -9 -13 30 -12 27 0 47 5 49 13 6 16 -56 16 -79
-1z"/>
<path d="M4520 12030 c0 -5 -9 -10 -20 -10 -11 0 -20 -3 -20 -8 0 -4 -18 -6
-40 -4 -42 4 -55 -13 -27 -36 19 -16 122 -28 113 -13 -4 6 -2 11 4 11 6 0 8 5
5 10 -3 6 -3 16 2 23 13 21 12 37 -3 37 -8 0 -14 -4 -14 -10z"/>
<path d="M4755 12030 c3 -5 12 -10 20 -10 8 0 17 5 20 10 4 6 -5 10 -20 10
-15 0 -24 -4 -20 -10z"/>
<path d="M5920 12030 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10
-11 0 -20 -4 -20 -10z"/>
<path d="M2341 12014 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2455 12021 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
<path d="M2768 12023 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5495 12005 c2 -19 -2 -25 -19 -26 -20 0 -20 0 2 -9 29 -12 28 -36
-3 -44 -15 -4 -27 -16 -31 -32 -8 -33 5 -45 40 -36 27 7 28 10 30 77 1 39 1
76 -2 83 -8 22 -20 13 -17 -13z"/>
<path d="M5558 12023 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2275 12010 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10
-11 0 -17 -4 -14 -10z"/>
<path d="M3520 12010 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M3688 12013 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5040 11999 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14
-5 0 -10 -9 -10 -21z"/>
<path d="M5111 12003 c-1 -16 5 -23 17 -23 10 0 13 3 6 8 -6 4 -14 14 -17 22
-4 10 -6 8 -6 -7z"/>
<path d="M5250 12000 l-25 -21 25 7 c31 8 33 10 28 24 -2 8 -13 4 -28 -10z"/>
<path d="M5868 12014 c-8 -13 9 -54 22 -54 5 0 10 6 10 13 0 19 -26 52 -32 41z"/>
<path d="M1430 11988 c0 -14 6 -18 26 -16 22 2 30 -4 46 -34 23 -44 30 -46 57
-17 12 13 26 19 36 15 12 -4 16 -1 13 11 -2 11 -16 20 -38 23 -19 4 -46 12
-60 20 -40 21 -80 20 -80 -2z"/>
<path d="M1607 12003 c-6 -11 11 -53 21 -53 5 0 7 14 4 30 -4 29 -16 39 -25
23z"/>
<path d="M2609 11991 c-13 -11 -21 -22 -17 -26 10 -10 48 14 48 30 0 19 -3 19
-31 -4z"/>
<path d="M2700 12000 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M2773 11976 c3 -19 -1 -55 -8 -80 l-14 -46 39 0 c47 0 57 13 35 47
-10 15 -15 36 -12 51 6 33 -8 62 -30 62 -12 0 -14 -7 -10 -34z"/>
<path d="M3172 11998 c6 -18 28 -21 28 -4 0 9 -7 16 -16 16 -9 0 -14 -5 -12
-12z"/>
<path d="M5570 12000 c0 -5 4 -10 9 -10 5 0 18 -3 28 -7 16 -6 16 -5 4 10 -15
19 -41 23 -41 7z"/>
<path d="M5980 12000 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M4025 11990 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4240 11972 c0 -13 4 -20 10 -17 6 4 10 -6 10 -24 0 -16 5 -33 10
-36 18 -11 10 49 -10 75 l-20 25 0 -23z"/>
<path d="M5714 11975 c-18 -14 -17 -14 14 -15 22 0 32 5 32 15 0 19 -21 19
-46 0z"/>
<path d="M5798 11971 c-10 -12 -12 -24 -5 -44 6 -19 4 -40 -6 -69 -9 -24 -16
-36 -16 -28 -1 12 -11 14 -46 12 -30 -3 -45 0 -45 8 0 8 -13 11 -40 8 -22 -2
-41 0 -43 5 -1 5 -18 7 -37 5 -35 -3 -35 -3 -12 -16 13 -8 36 -15 50 -18 15
-2 52 -8 82 -14 147 -26 160 -26 315 -11 5 1 4 6 -2 12 -6 6 -38 10 -71 10
-55 0 -61 2 -66 24 -3 13 -3 30 0 39 8 20 -13 83 -30 89 -7 3 -20 -2 -28 -12z"/>
<path d="M2435 11957 c-8 -12 -15 -17 -15 -12 0 5 -5 4 -11 -2 -7 -7 -25 -9
-41 -6 -23 4 -27 3 -22 -10 4 -12 -5 -16 -43 -19 -26 -3 -61 -10 -78 -16 -16
-6 -57 -19 -89 -29 -52 -15 -57 -18 -38 -26 15 -7 30 -6 48 1 14 7 35 12 47
12 12 0 39 9 60 20 21 12 56 20 83 20 37 0 45 3 40 15 -3 9 0 15 8 15 8 0 17
-7 20 -16 3 -8 10 -13 15 -10 11 7 23 -22 15 -36 -3 -5 -14 -8 -24 -5 -13 3
-17 0 -13 -9 4 -11 -5 -14 -37 -14 -25 0 -39 4 -35 10 3 6 -1 10 -10 10 -19 0
-15 -20 6 -31 21 -10 151 -29 162 -22 4 3 8 35 7 71 -2 91 -2 92 15 92 8 0 15
4 15 9 0 5 -16 9 -35 9 -25 0 -40 -6 -50 -21z"/>
<path d="M2717 11959 c-3 -11 -14 -19 -25 -19 -24 0 -38 -29 -17 -36 9 -4 22
-1 30 6 12 10 16 8 24 -11 9 -23 10 -23 10 -2 1 12 -4 23 -10 25 -5 2 -7 11
-4 21 4 9 11 14 16 11 5 -3 9 -1 9 4 0 20 -28 20 -33 1z"/>
<path d="M3457 11972 c-17 -3 -16 -5 4 -23 17 -15 25 -16 36 -8 7 7 12 12 11
13 -2 1 -10 6 -18 12 -8 6 -23 9 -33 6z"/>
<path d="M5588 11960 c23 -22 42 -26 42 -10 0 6 -7 10 -15 10 -8 0 -15 5 -15
10 0 6 -8 10 -17 10 -14 0 -14 -3 5 -20z"/>
<path d="M3585 11960 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M4921 11954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6008 11963 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2243 11953 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2358 11953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3527 11946 c-3 -8 -2 -18 3 -21 13 -8 24 15 12 27 -6 6 -11 4 -15
-6z"/>
<path d="M4300 11920 c0 -23 5 -40 11 -40 7 0 18 -8 25 -17 11 -15 13 -15 14
-3 0 8 -9 21 -21 28 -17 11 -20 20 -15 43 3 16 1 29 -4 29 -6 0 -10 -18 -10
-40z"/>
<path d="M6132 11947 c-9 -11 -7 -16 14 -25 33 -16 37 -15 50 6 9 14 8 18 -5
18 -9 1 -23 4 -32 8 -9 4 -21 1 -27 -7z"/>
<path d="M2050 11940 c0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -15 26 -12 39 4
9 11 7 15 -11 20 -34 9 -58 7 -58 -4z"/>
<path d="M3027 11920 c-26 -22 -33 -36 -30 -54 3 -13 0 -27 -6 -31 -8 -4 -5
-14 8 -31 15 -19 19 -41 20 -105 0 -44 3 -85 6 -90 3 -5 8 -21 10 -36 2 -16 7
-38 11 -51 6 -22 5 -22 -82 -21 -118 1 -236 -10 -228 -22 3 -5 1 -9 -5 -9 -5
0 -12 6 -14 13 -4 10 -6 10 -6 0 -1 -9 -23 -13 -78 -13 -42 0 -74 -2 -72 -4 2
-2 55 -6 118 -10 101 -6 113 -5 108 8 -9 22 46 22 63 1 8 -10 20 -13 29 -9 9
4 43 10 76 14 33 4 87 14 120 23 39 11 98 17 170 17 105 0 135 7 135 32 0 4
-20 8 -45 8 -25 0 -45 -5 -45 -11 0 -6 -17 -9 -42 -7 -37 3 -44 7 -46 27 -3
19 8 56 25 80 2 3 10 -4 17 -15 14 -18 15 -18 28 10 10 23 10 30 0 34 -15 5
-16 38 -2 47 13 8 3 25 -16 25 -8 0 -14 -8 -14 -19 0 -10 -7 -24 -15 -31 -27
-23 -39 4 -42 102 -5 132 -5 132 -58 146 -61 16 -59 16 -98 -18z"/>
<path d="M5031 11937 c-17 -17 -13 -29 8 -22 11 3 22 1 26 -5 6 -10 25 -5 25
8 0 15 -48 30 -59 19z"/>
<path d="M5140 11932 l-35 -17 -6 -85 c-7 -100 -28 -157 -29 -77 0 30 -4 46
-10 42 -5 -3 -10 -2 -10 3 0 18 24 77 34 85 7 5 4 6 -7 3 -10 -3 -23 -6 -28
-6 -5 0 -9 -36 -9 -80 0 -74 -1 -80 -21 -80 -16 0 -19 -4 -14 -20 3 -10 1 -28
-5 -40 -11 -20 -11 -20 -36 7 -25 28 -26 28 -20 4 3 -13 -1 -39 -9 -56 -12
-31 -12 -32 9 -27 11 3 25 11 29 19 7 11 11 12 24 1 23 -19 46 15 31 47 -7 16
-7 28 1 41 10 18 10 18 12 -1 1 -17 2 -16 10 5 7 20 8 16 5 -19 -3 -24 3 -66
12 -93 l16 -50 -74 -73 -75 -73 -80 -4 c-60 -3 -88 -9 -108 -24 -33 -24 -36
-42 -3 -20 24 15 56 13 56 -5 0 -5 19 -8 43 -7 36 1 38 2 10 5 -25 3 -33 9
-33 24 0 24 28 25 46 2 13 -17 13 -17 14 0 0 10 8 17 20 17 13 0 17 -5 14 -14
-3 -8 2 -16 13 -19 12 -3 8 -6 -12 -8 -19 -1 -7 -5 30 -9 l60 -7 -53 -1 c-29
-1 -62 -8 -75 -14 -12 -7 -23 -13 -25 -14 -11 -4 14 -34 29 -34 10 0 19 -4 19
-10 0 -14 133 -7 148 8 17 15 8 42 -13 42 -9 0 -13 5 -10 10 3 6 0 10 -7 10
-9 0 -9 3 2 10 11 7 5 10 -22 10 -33 0 -38 3 -38 23 0 24 70 98 123 131 25 15
35 16 80 5 29 -6 57 -14 62 -18 45 -27 217 -43 213 -18 -2 8 9 11 37 9 62 -6
76 -18 35 -30 l-35 -10 35 -1 c38 -1 54 7 58 27 1 7 -8 12 -24 12 -15 0 -23 4
-19 11 5 7 -9 10 -41 9 -27 -2 -56 1 -65 4 -9 4 -32 6 -50 5 -19 -2 -58 -2
-86 -1 -52 2 -53 3 -53 33 0 20 4 28 12 23 7 -4 8 -3 4 4 -4 6 0 27 8 46 8 19
14 65 14 103 -1 104 -2 100 37 95 27 -3 35 -1 35 11 0 20 -17 31 -25 17 -10
-16 -35 8 -35 34 0 29 -56 86 -84 86 -11 0 -37 -8 -56 -18z"/>
<path d="M5546 11943 c-12 -12 -6 -33 9 -33 8 0 15 6 15 14 0 17 -14 28 -24
19z"/>
<path d="M2178 11933 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3632 11919 c-9 -14 -19 -18 -32 -14 -15 5 -20 2 -20 -13 0 -22 19
-33 53 -31 17 1 15 3 -8 9 -29 7 -29 8 -4 9 22 1 49 32 49 56 0 14 -27 2 -38
-16z"/>
<path d="M3680 11936 c0 -10 28 -13 41 -5 10 6 7 9 -13 9 -16 0 -28 -2 -28 -4z"/>
<path d="M4518 11923 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5900 11921 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5
0 -10 -4 -10 -9z"/>
<path d="M1963 11905 c-9 -24 4 -30 32 -15 19 10 22 16 13 22 -20 12 -38 9
-45 -7z"/>
<path d="M2880 11866 c0 -44 -4 -55 -17 -59 -14 -4 -12 -5 5 -6 20 -1 22 3 22
59 0 33 -2 60 -5 60 -3 0 -5 -24 -5 -54z"/>
<path d="M3750 11910 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M4433 11913 c-23 -8 -17 -22 30 -74 9 -10 17 -27 17 -36 0 -13 3 -14
12 -5 7 7 37 12 68 13 l55 1 -58 12 c-33 6 -56 14 -53 17 4 4 -5 14 -19 23
-14 10 -29 25 -33 35 -4 10 -13 16 -19 14z"/>
<path d="M5304 11909 c-10 -17 12 -32 35 -24 29 9 27 35 -3 35 -14 0 -28 -5
-32 -11z"/>
<path d="M5680 11911 c0 -5 -12 -13 -27 -18 -27 -9 -27 -10 16 -17 24 -4 46
-4 49 -1 3 3 -4 5 -16 5 -12 0 -22 5 -22 10 0 6 5 10 10 10 6 0 10 5 10 10 0
6 -4 10 -10 10 -5 0 -10 -4 -10 -9z"/>
<path d="M5940 11910 c-23 -15 5 -30 58 -30 27 0 42 -4 42 -13 0 -7 25 -17 65
-25 78 -15 85 -15 85 -2 0 6 -7 10 -15 10 -8 0 -15 4 -15 9 0 4 -20 11 -45 14
-25 4 -58 13 -73 21 -40 20 -85 27 -102 16z"/>
<path d="M3880 11900 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M4776 11895 c-4 -10 1 -15 14 -15 13 0 18 5 14 15 -4 8 -10 15 -14
15 -4 0 -10 -7 -14 -15z"/>
<path d="M5591 11894 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2930 11878 c0 -17 3 -20 9 -9 5 8 16 11 26 7 15 -5 16 -4 5 9 -18
22 -40 18 -40 -7z"/>
<path d="M3920 11890 c0 -5 16 -10 36 -10 21 0 33 4 29 10 -3 6 -19 10 -36 10
-16 0 -29 -4 -29 -10z"/>
<path d="M6175 11890 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
-2 0 -7 -4 -10 -10z"/>
<path d="M6386 11885 c12 -9 14 -14 6 -15 -23 0 14 -30 37 -30 11 0 23 -4 26
-10 3 -5 19 -10 35 -10 16 0 32 5 35 10 3 6 -3 10 -15 10 -12 0 -18 5 -15 10
4 6 -14 10 -47 11 -39 0 -48 3 -33 9 18 8 18 9 2 9 -10 1 -15 6 -12 11 3 6 -4
10 -16 10 -22 0 -22 0 -3 -15z"/>
<path d="M1875 11880 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M1924 11875 c3 -10 -1 -15 -12 -15 -15 0 -15 -2 -2 -10 22 -14 27
-12 33 9 3 12 -1 22 -10 25 -11 4 -13 2 -9 -9z"/>
<path d="M3812 11870 c-7 -11 -26 -23 -43 -27 -22 -4 -27 -8 -17 -14 8 -6 -3
-9 -32 -10 -44 -1 -44 -2 15 -10 57 -9 61 -8 83 16 21 25 30 65 14 65 -4 0
-13 -9 -20 -20z"/>
<path d="M4922 11865 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M2000 11870 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10
-11 0 -20 -4 -20 -10z"/>
<path d="M3250 11870 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M3529 11865 c-1 -8 -2 -21 -3 -27 -1 -7 -12 -14 -26 -15 -14 0 -31
-1 -37 -2 -7 -1 -13 -6 -13 -12 0 -7 12 -9 33 -5 31 6 32 5 32 -32 0 -43 -25
-53 -35 -13 -5 20 -7 22 -18 9 -17 -22 -4 -48 24 -48 25 0 100 35 110 52 11
18 -20 29 -33 12 -13 -18 -33 -11 -33 12 0 11 15 14 65 14 51 0 65 3 65 15 0
9 -9 15 -25 15 -14 0 -25 -4 -25 -8 0 -5 -13 -7 -30 -4 -16 2 -29 7 -28 11 0
3 0 14 -1 24 -1 21 -21 23 -22 2z"/>
<path d="M3690 11870 c-12 -8 -10 -10 8 -10 13 0 20 4 17 10 -7 12 -6 12 -25
0z"/>
<path d="M4950 11864 c0 -8 5 -12 10 -9 6 3 10 10 10 16 0 5 -4 9 -10 9 -5 0
-10 -7 -10 -16z"/>
<path d="M2595 11850 c-27 -11 -58 -20 -67 -20 -10 0 -18 -5 -18 -11 0 -11 17
-10 113 7 31 5 35 9 26 20 -9 12 -4 14 32 13 24 -1 34 -4 22 -6 -28 -5 -31
-23 -4 -23 19 0 35 23 24 34 -12 12 -79 5 -128 -14z"/>
<path d="M3488 11863 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3864 11848 c-24 -26 -40 -63 -23 -53 6 4 16 -1 23 -10 10 -14 16
-15 34 -6 18 10 22 9 22 -3 0 -19 48 -32 62 -18 18 18 -10 42 -49 42 -29 0
-33 3 -29 18 4 10 0 26 -7 36 -12 16 -14 16 -33 -6z"/>
<path d="M4800 11860 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>
<path d="M1810 11849 c0 -7 9 -9 24 -5 14 3 26 1 28 -5 2 -7 9 -5 17 5 12 14
9 16 -28 16 -23 0 -41 -5 -41 -11z"/>
<path d="M2961 11844 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5993 11843 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M6245 11840 c3 -6 -4 -10 -17 -11 -14 0 -17 -3 -8 -6 28 -9 60 -26
60 -32 -1 -15 -19 -33 -35 -35 -26 -4 -37 -39 -19 -65 19 -26 35 -27 54 -1 8
11 26 21 40 21 23 2 23 2 3 6 -20 4 -22 10 -20 54 2 52 7 58 27 34 7 -8 23
-15 37 -15 13 0 31 -7 39 -15 9 -8 26 -15 39 -15 13 0 27 -4 30 -10 3 -5 13
-10 20 -10 26 0 -14 37 -61 56 -24 10 -44 22 -44 26 0 4 -25 8 -55 8 -30 0
-55 5 -55 10 0 6 -9 10 -21 10 -11 0 -17 -4 -14 -10z"/>
<path d="M1502 11828 c-19 -19 -14 -26 14 -22 31 3 38 9 29 23 -8 14 -28 14
-43 -1z"/>
<path d="M1950 11833 c-23 -4 -84 -29 -135 -56 -78 -41 -116 -57 -136 -58
l-24 0 24 -10 c18 -7 27 -6 33 3 5 9 8 6 8 -9 0 -12 -12 -30 -27 -42 -25 -18
-26 -20 -8 -21 l20 -1 -20 -11 c-36 -20 -59 -49 -46 -58 19 -11 115 1 124 16
4 8 16 14 25 14 9 0 41 10 70 22 46 19 55 20 64 8 8 -12 9 -12 4 3 -3 9 -1 17
4 17 11 0 34 39 27 46 -8 8 -37 -8 -31 -17 3 -5 -3 -9 -12 -9 -10 0 -25 -7
-35 -16 -10 -9 -38 -19 -63 -21 l-44 -5 -7 46 c-4 26 -6 48 -4 49 76 41 189
88 200 81 12 -7 11 -51 -1 -59 -19 -12 -10 -25 18 -25 21 0 24 -2 12 -10 -9
-6 -10 -10 -3 -10 6 0 14 -13 18 -30 4 -16 11 -32 16 -35 5 -4 6 1 3 9 -3 9
-2 16 4 16 6 0 13 -6 15 -12 3 -7 4 15 4 50 -1 61 -2 62 -29 62 -35 0 -38 30
-4 46 18 8 27 7 44 -7 24 -19 27 -40 12 -70 -6 -11 -7 -19 -1 -19 5 0 12 7 15
16 9 22 26 7 26 -23 0 -18 -4 -24 -15 -19 -11 4 -15 -2 -15 -24 l0 -30 35 20
c20 11 38 20 40 20 14 0 46 -85 40 -109 -4 -18 5 -54 29 -111 18 -47 32 -88
29 -92 -14 -25 -91 7 -112 46 -17 30 -41 42 -52 24 -5 -8 -3 -14 6 -16 8 -2
15 -9 15 -16 0 -18 33 -46 55 -46 14 0 15 -3 5 -15 -7 -8 -20 -15 -29 -15 -9
0 -22 -5 -28 -11 -6 -6 -22 -7 -38 -3 -20 5 -23 9 -13 15 9 6 7 9 -10 9 -13 0
-20 -4 -17 -10 3 -5 -4 -11 -17 -11 -16 -1 -12 -4 12 -9 l35 -7 -32 -8 c-18
-4 -30 -12 -27 -17 7 -11 76 -1 84 12 3 5 22 10 42 12 20 1 34 6 32 10 -3 4 4
8 15 8 11 0 23 5 26 10 3 6 15 10 26 10 10 0 19 5 19 10 0 6 8 10 18 10 10 0
29 8 42 17 14 10 45 20 70 23 30 3 45 10 45 19 0 8 -6 16 -12 19 -7 2 -13 -4
-13 -13 0 -9 -6 -15 -12 -13 -7 3 -21 42 -31 89 -10 46 -24 98 -32 116 -8 17
-15 41 -15 53 0 12 -9 43 -21 70 -34 79 -81 79 -126 0 -15 -27 -31 -46 -35
-44 -4 3 -4 13 1 23 5 9 9 26 8 36 -1 13 4 20 15 19 10 -1 14 4 11 12 -7 20
-18 17 -35 -8 -17 -25 -42 -16 -35 13 3 11 0 19 -6 19 -7 0 -6 4 3 10 8 5 12
11 10 13 -8 7 -122 14 -150 10z"/>
<path d="M4021 11824 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4400 11817 c0 -17 5 -27 15 -27 8 0 17 -14 21 -35 8 -44 24 -46 24
-2 0 24 -8 41 -30 62 l-30 29 0 -27z"/>
<path d="M1788 11823 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2143 11815 c0 -9 9 -15 21 -15 16 0 18 2 7 9 -11 7 -11 9 0 14 9 3
6 6 -7 6 -12 1 -21 -5 -21 -14z"/>
<path d="M2770 11820 c-8 -5 -10 -10 -4 -10 7 0 11 -8 10 -17 -1 -12 7 -19 22
-21 20 -3 23 0 20 20 -5 35 -22 45 -48 28z"/>
<path d="M5525 11814 c11 -8 19 -18 17 -22 -2 -4 4 -8 12 -10 35 -7 11 32 -24
41 -24 6 -24 6 -5 -9z"/>
<path d="M1698 11813 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2720 11810 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M3250 11802 c0 -14 11 -21 26 -15 21 8 17 23 -6 23 -11 0 -20 -4 -20
-8z"/>
<path d="M4934 11798 c-4 -7 -3 -8 4 -4 8 5 12 -6 13 -36 1 -24 4 -33 6 -20 4
18 9 21 25 16 20 -6 20 -6 2 19 -23 31 -41 40 -50 25z"/>
<path d="M5390 11800 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M5440 11788 c0 -11 8 -27 18 -34 20 -16 62 -18 62 -4 0 6 -7 10 -15
10 -9 0 -27 11 -40 25 -24 23 -25 23 -25 3z"/>
<path d="M6503 11803 c9 -3 15 -9 12 -14 -4 -5 6 -9 22 -9 22 0 25 -2 13 -11
-13 -8 -12 -10 4 -7 10 2 25 12 32 23 12 19 11 20 -44 21 -31 0 -49 -1 -39 -3z"/>
<path d="M1690 11790 c20 -13 43 -13 35 0 -3 6 -16 10 -28 10 -18 0 -19 -2 -7
-10z"/>
<path d="M2687 11794 c-14 -14 -7 -35 11 -32 9 2 17 10 17 17 0 16 -18 25 -28
15z"/>
<path d="M3615 11788 c-4 -9 -4 -21 -1 -27 4 -5 2 -12 -4 -16 -15 -9 -12 -25
4 -25 16 0 28 49 16 70 -8 13 -10 13 -15 -2z"/>
<path d="M4327 11794 c-14 -15 -6 -34 14 -34 14 0 19 5 17 17 -3 18 -20 27
-31 17z"/>
<path d="M4576 11778 c-12 -14 -14 -24 -8 -30 15 -15 42 -2 42 21 0 29 -14 33
-34 9z"/>
<path d="M5940 11785 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0
-10 -7 -10 -15z"/>
<path d="M2950 11775 c-9 -11 -16 -12 -26 -4 -15 13 -35 4 -29 -14 2 -7 6 -24
9 -39 4 -17 11 -25 19 -22 22 8 40 -23 34 -58 -7 -36 17 -96 26 -68 13 37 17
71 9 66 -5 -3 -8 3 -8 12 0 83 -5 142 -13 142 -5 0 -14 -7 -21 -15z m17 -47
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m10 -130 c-3 -8 -6 -5
-6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M6011 11763 c-20 -21 -32 -47 -41 -89 -7 -32 -16 -67 -22 -77 -5 -10
-16 -52 -24 -95 -12 -68 -18 -81 -51 -109 -21 -17 -34 -35 -30 -39 4 -4 7 -2
7 4 0 7 13 12 29 12 17 0 32 4 36 10 3 5 19 1 38 -10 18 -11 40 -20 50 -20 9
0 17 -3 17 -7 0 -5 11 -15 23 -24 17 -12 34 -15 74 -9 39 5 54 3 57 -7 3 -7
16 -14 28 -14 16 -1 13 -4 -12 -10 -27 -6 -21 -7 26 -4 39 3 67 0 80 -9 34
-22 218 -80 188 -59 -15 11 -25 43 -14 43 5 0 13 12 16 26 5 19 9 22 15 12 6
-9 9 -7 9 10 0 12 -5 22 -11 22 -12 0 -6 26 25 93 23 52 41 63 31 18 -3 -17
-2 -32 4 -36 10 -6 15 11 13 45 -1 20 21 32 30 17 5 -8 8 -7 8 5 0 9 -6 19
-12 21 -7 3 -1 6 13 6 18 1 28 8 32 21 3 11 1 20 -5 20 -6 0 -7 16 -3 41 l7
42 -30 -6 c-17 -4 -34 -2 -37 3 -3 6 0 10 7 10 9 0 9 3 -2 10 -11 7 -5 10 23
10 20 0 37 4 37 10 0 5 -7 7 -15 4 -26 -10 -65 5 -65 25 0 32 -11 51 -30 51
-13 0 -16 -6 -12 -25 6 -33 -5 -75 -21 -75 -20 0 -127 35 -127 42 0 3 8 9 18
12 9 3 4 3 -13 0 -16 -3 -38 -6 -47 -7 -10 -1 -18 -5 -18 -9 0 -4 -9 -8 -20
-8 -11 0 -20 -5 -20 -11 0 -7 11 -9 30 -6 23 5 28 3 23 -8 -3 -8 -11 -15 -19
-15 -8 0 -14 -4 -14 -10 0 -5 16 -10 35 -10 26 0 33 3 29 15 -5 12 2 15 30 15
36 0 48 -13 24 -24 -18 -8 53 1 75 9 10 4 17 3 17 -4 0 -6 -12 -11 -26 -11
-15 0 -23 -4 -19 -10 4 -6 16 -8 27 -4 12 4 24 1 28 -7 5 -7 26 -13 47 -13 34
-1 38 -4 41 -30 3 -28 3 -28 -32 -22 -28 5 -43 2 -65 -13 -38 -28 -42 -27 -78
14 -17 19 -38 35 -46 35 -26 0 -77 22 -71 31 3 5 -13 9 -35 9 -23 0 -41 -4
-41 -10 0 -5 7 -7 15 -4 8 4 15 2 15 -4 0 -6 -6 -12 -12 -15 -7 -3 11 -8 41
-12 32 -5 60 -15 70 -26 8 -11 21 -19 28 -19 7 0 13 -4 13 -10 0 -5 7 -10 15
-10 8 0 15 -6 15 -14 0 -8 10 -22 22 -31 19 -12 21 -18 11 -33 -6 -9 -17 -15
-25 -13 -7 2 -38 6 -68 8 l-55 3 40 -17 c22 -9 42 -19 45 -22 3 -3 12 -6 20
-6 14 0 33 -71 23 -87 -7 -13 -46 -9 -77 7 -15 8 -41 15 -57 15 -16 0 -29 4
-29 10 0 5 -27 15 -60 22 -68 14 -80 30 -55 78 18 34 43 38 85 13 22 -12 36
-14 60 -7 48 14 36 29 -23 29 l-52 0 -6 60 c-3 33 -6 52 -7 43 -1 -10 -11 -22
-23 -28 -11 -6 -18 -17 -16 -23 2 -7 -9 -29 -25 -51 -15 -21 -28 -44 -28 -51
0 -30 -49 -48 -102 -37 -15 3 -8 34 18 86 16 32 27 71 29 106 1 33 12 74 26
102 l23 48 -20 47 c-24 60 -51 67 -93 26z"/>
<path d="M6147 11770 c3 -11 0 -20 -6 -20 -6 0 -11 -5 -11 -11 0 -5 5 -7 10
-4 7 4 9 -2 4 -19 -4 -16 -2 -26 5 -26 6 0 11 13 11 29 0 17 5 33 10 36 8 5 7
11 -1 21 -17 20 -28 17 -22 -6z"/>
<path d="M1436 11757 c-9 -35 -7 -40 9 -23 17 16 19 32 6 40 -5 3 -11 -4 -15
-17z"/>
<path d="M3650 11770 c0 -15 36 -30 71 -30 18 0 29 3 26 7 -4 3 -2 12 4 20 9
11 1 13 -45 13 -31 0 -56 -4 -56 -10z"/>
<path d="M5655 11760 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
-2 0 -7 -4 -10 -10z"/>
<path d="M5957 11754 c-6 -16 -13 -20 -29 -16 -17 4 -20 2 -15 -12 4 -10 7
-22 7 -28 0 -6 12 1 26 15 14 15 24 34 22 44 -4 16 -5 15 -11 -3z"/>
<path d="M2610 11751 c0 -5 8 -15 18 -21 13 -9 8 -10 -25 -8 -24 2 -43 0 -43
-5 0 -4 19 -7 43 -5 34 2 42 7 42 23 0 11 -8 21 -17 23 -10 2 -18 -1 -18 -7z"/>
<path d="M2860 11750 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M3807 11753 c-15 -14 -6 -33 16 -34 21 -1 21 -1 -3 -8 -31 -8 -33
-35 -4 -39 16 -2 23 4 28 22 5 19 13 26 31 26 18 0 23 4 18 16 -4 10 -13 14
-29 10 -12 -3 -26 -2 -29 4 -7 12 -19 13 -28 3z"/>
<path d="M4480 11740 c0 -13 7 -20 20 -20 24 0 27 25 4 34 -23 9 -24 8 -24
-14z"/>
<path d="M4800 11749 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M5316 11744 c-9 -23 -8 -24 14 -24 11 0 20 2 20 4 0 12 -13 36 -20
36 -4 0 -11 -7 -14 -16z"/>
<path d="M5600 11740 c0 -11 4 -20 9 -20 5 0 7 9 4 20 -3 11 -7 20 -9 20 -2 0
-4 -9 -4 -20z"/>
<path d="M5760 11750 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5808 11754 c-12 -11 4 -34 24 -34 13 0 18 6 16 17 -3 18 -29 29 -40
17z"/>
<path d="M2448 11743 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4349 11734 c-9 -11 -6 -14 20 -14 17 0 31 -3 31 -8 0 -21 101 -45
118 -28 12 12 -22 27 -53 23 -20 -3 -35 2 -45 13 -21 24 -57 31 -71 14z"/>
<path d="M2310 11730 c0 -5 12 -10 26 -10 14 0 23 4 19 10 -3 6 -15 10 -26 10
-10 0 -19 -4 -19 -10z"/>
<path d="M2750 11730 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1860 11715 c-32 -18 -25 -18 25 0 22 8 31 14 20 14 -11 0 -31 -6
-45 -14z"/>
<path d="M2444 11685 c2 -25 9 -52 16 -60 13 -16 39 -20 41 -7 3 32 11 48 31
64 l23 18 -26 0 c-15 0 -32 7 -39 15 -7 8 -21 15 -31 15 -16 0 -18 -7 -15 -45z"/>
<path d="M2690 11720 c-13 -8 -11 -11 8 -16 12 -4 22 -13 22 -20 0 -8 7 -14
15 -14 21 0 19 30 -2 46 -22 16 -24 16 -43 4z"/>
<path d="M2805 11724 c13 -5 8 -70 -7 -102 -9 -17 -9 -25 2 -35 8 -7 11 -8 7
-1 -4 7 4 14 19 18 19 5 24 11 19 26 -3 10 0 28 7 40 11 18 10 23 -4 34 -10 7
-15 16 -11 19 3 4 -5 7 -18 6 -13 0 -19 -3 -14 -5z"/>
<path d="M3728 11723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4264 11707 c-8 -21 3 -67 16 -67 13 0 13 50 0 70 -8 12 -10 12 -16
-3z"/>
<path d="M5452 11709 c2 -6 8 -10 13 -10 5 0 11 4 13 10 2 6 -4 11 -13 11 -9
0 -15 -5 -13 -11z"/>
<path d="M5495 11711 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M5550 11700 c5 -11 14 -29 20 -39 7 -15 6 -21 -8 -24 -9 -2 -3 -5 13
-5 17 0 44 -4 60 -8 l30 -7 -19 22 c-11 11 -25 21 -32 21 -7 0 -18 14 -26 30
-7 17 -21 30 -31 30 -14 0 -15 -4 -7 -20z"/>
<path d="M5700 11709 c0 -7 -11 -9 -29 -6 -18 4 -33 1 -40 -7 -15 -18 -3 -26
25 -16 21 8 24 7 24 -14 0 -26 14 -35 42 -28 13 3 16 11 12 28 -4 14 -1 31 6
39 10 13 8 15 -14 15 -14 0 -26 -5 -26 -11z"/>
<path d="M6400 11711 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0
-20 -4 -20 -9z"/>
<path d="M6745 11710 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M4573 11703 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5765 11700 c-1 -3 1 -27 5 -55 5 -34 4 -53 -4 -58 -6 -5 -51 -5
-101 -2 -49 4 -106 8 -126 10 -50 3 -59 11 -59 51 0 28 -4 35 -17 32 -13 -2
-19 -14 -21 -40 -2 -26 -9 -39 -23 -43 -10 -4 -19 -11 -19 -16 0 -14 34 -11
56 6 26 20 37 19 31 -5 -4 -17 -1 -18 27 -14 21 4 27 3 18 -4 -10 -7 -6 -9 15
-9 15 -1 64 -3 108 -6 72 -4 82 -8 112 -36 41 -39 41 -48 1 -59 -23 -6 -34
-16 -39 -35 -7 -26 -6 -27 32 -27 21 0 39 1 39 3 0 1 9 26 19 55 17 45 18 56
6 80 -26 54 -27 70 -5 82 15 8 20 21 20 54 0 44 0 44 -36 43 -19 -1 -37 -3
-39 -7z"/>
<path d="M3525 11689 c-4 -6 -13 -8 -21 -5 -7 3 -16 1 -19 -4 -4 -6 4 -10 17
-11 23 -1 23 -1 -2 -9 -22 -7 -22 -8 -4 -9 11 -1 29 6 40 14 10 8 36 15 57 15
20 0 37 5 37 10 0 13 -97 13 -105 -1z"/>
<path d="M1620 11676 c-13 -17 -7 -46 9 -46 14 0 25 40 14 51 -6 6 -15 4 -23
-5z"/>
<path d="M1840 11680 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2599 11671 c11 -7 7 -10 -15 -13 -20 -2 -30 -9 -31 -23 -2 -11 0
-14 3 -7 3 6 17 12 30 12 14 0 22 -4 19 -10 -3 -6 0 -13 8 -16 20 -7 109 9
109 21 0 12 -87 45 -117 45 -16 0 -17 -2 -6 -9z"/>
<path d="M4330 11661 c0 -12 6 -21 16 -21 9 0 14 7 12 17 -5 25 -28 28 -28 4z"/>
<path d="M5500 11669 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M3881 11663 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M4466 11651 c-13 -20 12 -31 77 -31 40 0 56 3 51 11 -4 7 -27 10 -60
7 -43 -4 -54 -1 -56 11 -3 13 -5 13 -12 2z"/>
<path d="M5313 11644 c-4 -10 1 -22 10 -29 23 -16 22 -55 0 -55 -12 0 -14 -3
-6 -11 8 -8 17 -7 32 3 25 15 26 27 6 54 -8 10 -15 27 -15 37 0 22 -19 22 -27
1z"/>
<path d="M3285 11640 c-7 -12 12 -40 26 -40 5 0 8 11 7 25 -2 25 -21 34 -33
15z"/>
<path d="M4678 11643 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6168 11645 c-7 -8 22 -45 35 -45 5 0 7 10 5 22 -3 22 -27 35 -40 23z"/>
<path d="M3730 11630 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M4610 11636 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8
0 -15 -2 -15 -4z"/>
<path d="M3696 11615 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M6608 11623 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2046 11613 c-14 -4 -15 -7 -6 -13 7 -4 -6 -10 -33 -14 -25 -4 -48
-13 -51 -21 -3 -8 -14 -15 -24 -15 -10 0 -34 -7 -52 -16 -24 -12 -29 -19 -19
-25 11 -7 9 -9 -6 -10 -11 -1 0 -5 25 -8 42 -6 45 -4 50 19 4 19 12 25 34 27
16 1 33 8 37 15 7 10 9 9 9 -4 0 -10 5 -18 10 -18 6 0 10 7 10 15 0 8 5 15 10
15 6 0 10 -9 10 -20 0 -11 4 -20 10 -20 5 0 8 14 6 30 -2 17 1 30 5 30 5 0 9
9 9 20 0 21 -2 21 -34 13z m1 -39 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17
-2 13 -5z"/>
<path d="M2147 11614 c-4 -4 -7 -14 -7 -23 0 -11 3 -12 11 -4 6 6 10 16 7 23
-2 6 -7 8 -11 4z"/>
<path d="M3568 11613 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3805 11610 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
-2 0 -7 -4 -10 -10z"/>
<path d="M4256 11605 c4 -8 12 -15 20 -15 8 0 14 7 14 15 0 8 -9 15 -20 15
-13 0 -18 -5 -14 -15z"/>
<path d="M4630 11610 c-13 -8 -13 -10 2 -10 9 0 23 -3 32 -6 11 -4 16 -1 16
10 0 18 -27 21 -50 6z"/>
<path d="M3000 11591 c0 -11 4 -22 9 -25 4 -3 6 6 3 19 -5 30 -12 33 -12 6z"/>
<path d="M3465 11601 c-6 -11 9 -23 19 -14 9 9 7 23 -3 23 -6 0 -12 -4 -16 -9z"/>
<path d="M5873 11590 c-12 -28 -8 -39 8 -23 6 6 9 19 6 29 -3 17 -5 16 -14 -6z"/>
<path d="M2739 11596 c-7 -7 -140 -19 -199 -19 -61 0 -87 -15 -72 -44 14 -27
22 -94 11 -88 -5 4 -9 2 -9 -4 0 -5 -3 -17 -6 -25 -5 -13 -1 -15 17 -13 13 2
25 9 27 15 2 7 8 12 13 12 6 0 8 -4 4 -9 -10 -18 13 -21 30 -4 16 15 15 17 -7
29 -12 7 -19 13 -15 14 5 0 1 7 -7 16 -20 19 -20 30 -1 46 9 7 23 19 31 27 13
12 17 11 30 -8 17 -24 62 -26 60 -1 -1 11 10 15 45 16 57 1 79 9 79 29 0 14
-21 21 -31 11z"/>
<path d="M2845 11591 c-6 -11 15 -28 35 -29 8 0 35 -6 59 -12 24 -6 49 -9 55
-6 6 2 1 5 -11 5 -13 1 -23 7 -23 15 0 22 -103 46 -115 27z"/>
<path d="M4008 11593 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5277 11581 c3 -12 9 -21 14 -21 14 0 11 16 -5 29 -12 10 -13 8 -9
-8z"/>
<path d="M2351 11574 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4313 11561 c-14 -22 -8 -31 23 -31 39 0 74 18 68 35 -9 23 -76 20
-91 -4z"/>
<path d="M2125 11560 c-6 -10 9 -45 32 -72 15 -17 2 -35 -16 -23 -13 7 -25
-15 -14 -26 4 -4 18 -6 30 -3 28 8 41 68 18 90 -8 8 -15 20 -15 26 0 6 -7 5
-17 -3 -15 -12 -16 -12 -10 4 7 18 2 23 -8 7z"/>
<path d="M4823 11559 c-25 -7 -30 -13 -26 -30 5 -17 11 -20 36 -15 31 6 49 23
44 40 -5 14 -18 15 -54 5z"/>
<path d="M6378 11563 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4943 11542 c-32 -17 -45 -52 -20 -52 28 0 78 32 75 48 -4 22 -18 23
-55 4z"/>
<path d="M5877 11537 c-10 -10 -17 -25 -15 -33 5 -24 41 7 36 32 -3 19 -4 19
-21 1z"/>
<path d="M6645 11550 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M2690 11530 c0 -5 6 -10 14 -10 8 0 16 -6 18 -12 3 -8 9 -4 17 10 11
21 10 22 -19 22 -16 0 -30 -4 -30 -10z"/>
<path d="M2843 11533 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M1711 11521 c-11 -7 -12 -16 -4 -43 10 -32 10 -32 20 -10 8 21 11 22
22 9 9 -12 10 -25 2 -51 -5 -21 -6 -34 0 -31 5 4 9 1 9 -4 0 -6 6 -11 14 -11
11 0 13 -9 9 -35 -3 -21 -1 -41 7 -50 7 -8 10 -24 7 -35 -3 -11 -1 -23 5 -26
5 -3 2 -14 -8 -25 -16 -18 -16 -19 4 -19 12 0 22 5 22 10 0 6 14 10 30 10 17
0 38 4 48 9 9 5 29 14 45 22 25 11 39 49 18 49 -17 0 -61 -27 -54 -33 3 -4 -6
-7 -21 -7 -16 0 -25 4 -21 10 3 6 -1 10 -10 10 -25 0 -17 74 9 78 10 2 22 -1
26 -8 5 -9 9 -9 14 -1 4 6 2 11 -5 11 -10 0 -10 3 -1 12 7 7 12 16 12 20 0 12
-35 9 -48 -4 -9 -9 -15 -9 -23 -1 -6 6 -18 9 -25 6 -8 -3 -14 0 -14 6 0 6 9
11 21 11 24 0 24 -1 -16 62 -16 26 -40 51 -52 57 -28 13 -25 13 -42 2z"/>
<path d="M2080 11512 c0 -5 -9 -12 -20 -15 -24 -6 -27 -45 -5 -53 28 -11 61
55 35 71 -5 3 -10 2 -10 -3z"/>
<path d="M5580 11511 c0 -6 6 -11 13 -11 8 0 7 -4 -3 -11 -12 -8 -11 -10 5 -7
25 4 27 31 3 36 -10 2 -18 -1 -18 -7z"/>
<path d="M2005 11500 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
-2 0 -7 -4 -10 -10z"/>
<path d="M4028 11503 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4128 11503 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3555 11490 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>
<path d="M3713 11493 c4 -3 -1 -12 -11 -20 -15 -12 4 -13 137 -9 84 3 156 7
159 10 3 3 -4 6 -16 6 -12 0 -22 5 -22 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20
-9 0 -5 -16 -5 -37 0 -44 10 -179 12 -170 2z m74 -9 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M4408 11488 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M4730 11440 c-30 -33 -63 -60 -72 -60 -10 0 -18 -7 -18 -15 0 -8 6
-15 14 -15 33 0 64 21 91 61 16 24 38 54 49 67 14 16 16 22 5 21 -8 0 -39 -27
-69 -59z"/>
<path d="M6240 11490 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1970 11480 c20 -13 33 -13 25 0 -3 6 -14 10 -23 10 -15 0 -15 -2 -2
-10z"/>
<path d="M3505 11481 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M4040 11480 c-22 -7 -20 -8 12 -7 20 1 34 5 31 10 -5 8 -9 8 -43 -3z"/>
<path d="M4200 11481 l-45 -6 34 -8 c37 -8 83 -8 63 0 -6 2 -10 9 -6 14 3 5 4
8 2 7 -2 0 -23 -4 -48 -7z"/>
<path d="M4453 11482 c-13 -2 -23 -8 -23 -13 0 -9 37 -3 49 7 8 7 -4 10 -26 6z"/>
<path d="M3083 11466 c9 -7 17 -25 17 -40 0 -32 25 -35 34 -3 l6 22 18 -21 18
-21 11 23 c12 27 3 38 -25 30 -10 -4 -27 1 -38 9 -10 8 -28 14 -39 14 -19 0
-19 0 -2 -13z"/>
<path d="M4538 11473 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5641 11470 c-7 -4 -12 -11 -12 -16 1 -5 -6 -17 -14 -26 -14 -17 -12
-18 16 -18 17 0 28 4 24 10 -3 6 1 10 9 10 19 0 21 36 3 43 -8 3 -19 2 -26 -3z"/>
<path d="M3280 11445 c0 -9 6 -12 15 -9 8 4 15 7 15 9 0 2 -7 5 -15 9 -9 3
-15 0 -15 -9z"/>
<path d="M4648 11453 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5066 11451 c-3 -5 1 -11 9 -14 9 -4 12 -1 8 9 -6 16 -10 17 -17 5z"/>
<path d="M1933 11443 c9 -2 17 -13 17 -23 0 -10 10 -20 23 -24 32 -8 49 -7 43
3 -3 5 1 14 9 21 8 7 12 16 9 22 -3 5 -12 4 -20 -3 -11 -9 -18 -9 -26 -1 -7 7
-26 11 -42 11 -17 -1 -23 -3 -13 -6z"/>
<path d="M2605 11440 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10
-2 0 -7 -4 -10 -10z"/>
<path d="M5105 11430 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M5185 11430 c3 -5 18 -10 32 -10 32 0 28 5 -10 14 -19 4 -26 3 -22
-4z"/>
<path d="M3050 11423 c0 -4 -47 -7 -105 -7 -59 1 -102 -2 -99 -7 10 -16 -27
-22 -74 -11 -53 12 -72 7 -72 -18 0 -18 36 -38 47 -26 4 3 -1 6 -10 6 -10 0
-17 5 -17 11 0 6 14 9 33 7 19 -2 31 -8 30 -15 -2 -8 7 -13 25 -12 16 1 20 3
10 6 -44 11 -11 23 65 23 56 0 77 3 67 10 -10 7 7 10 58 10 52 0 73 3 70 12
-5 14 -28 23 -28 11z"/>
<path d="M3680 11418 c0 -5 -15 -8 -32 -9 -31 -1 -31 -1 -8 -8 14 -4 43 -6 65
-5 22 2 32 1 23 -2 -21 -7 -24 -34 -4 -34 23 0 46 11 41 20 -3 4 9 13 25 20
47 20 35 27 -40 26 -38 0 -70 -4 -70 -8z"/>
<path d="M3867 11424 c-12 -12 6 -34 28 -34 24 0 49 22 38 33 -8 9 -58 9 -66
1z"/>
<path d="M4218 11411 c-16 -11 -25 -11 -39 -3 -10 7 -37 12 -59 12 -33 0 -40
-3 -40 -19 0 -16 -7 -20 -40 -19 -22 0 -40 4 -40 9 0 5 16 9 36 9 21 0 33 4
29 10 -10 17 -71 11 -88 -8 -10 -10 -17 -22 -17 -25 0 -11 158 -8 165 3 8 13
98 13 112 -1 6 -6 28 -9 49 -6 l39 4 -42 1 c-62 3 -53 22 10 22 35 0 47 3 37
9 -10 7 -3 11 25 13 22 3 5 4 -39 4 -50 -1 -85 -6 -98 -15z"/>
<path d="M4414 11419 c-11 -19 203 -24 227 -6 13 10 -8 12 -103 12 -66 0 -122
-3 -124 -6z"/>
<path d="M5861 11414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3520 11405 c0 -12 -13 -15 -60 -15 -57 0 -60 -1 -60 -25 0 -24 -2
-24 -87 -28 -85 -3 -85 -3 24 -5 97 -2 112 0 106 13 -4 13 1 15 36 9 26 -4 41
-3 41 4 0 6 -10 12 -22 15 -13 2 13 3 57 2 44 -2 61 -1 37 2 -26 3 -40 8 -36
15 4 5 0 14 -7 19 -20 13 -29 11 -29 -6z"/>
<path d="M5120 11410 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30
0z"/>
<path d="M5289 11401 c11 -7 11 -9 0 -14 -8 -3 0 -6 18 -6 17 -1 34 3 37 8 7
11 -17 21 -47 21 -16 0 -18 -3 -8 -9z"/>
<path d="M4341 11390 c-15 -9 71 -20 161 -20 43 0 68 -4 72 -12 5 -10 7 -10
12 -1 11 24 3 26 -94 25 -53 -1 -91 2 -85 6 8 5 -1 8 -21 9 -18 1 -39 -2 -45
-7z"/>
<path d="M5390 11390 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M5474 11387 c3 -10 6 -21 6 -23 0 -2 10 -4 23 -3 14 0 17 2 7 6 -8 3
-21 13 -29 22 -12 15 -13 14 -7 -2z"/>
<path d="M6521 11384 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2168 11383 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2622 11378 c-20 -20 -14 -38 12 -38 30 0 42 16 26 35 -15 18 -22 19
-38 3z"/>
<path d="M3288 11382 c13 -4 22 -11 20 -17 -2 -5 12 -11 31 -13 29 -3 33 -1
23 11 -7 7 -9 17 -5 20 3 4 -16 7 -43 6 -33 -1 -42 -3 -26 -7z"/>
<path d="M2400 11371 c0 -6 -9 -11 -21 -11 -11 0 -27 -7 -35 -15 -14 -13 -14
-15 2 -15 15 0 15 -2 -3 -17 -11 -10 -23 -30 -27 -45 -5 -24 -3 -28 15 -28 14
0 19 5 16 14 -8 20 15 66 33 67 8 0 33 1 55 1 l40 0 -45 7 c-25 5 -52 6 -60 3
-11 -4 -12 -2 -4 7 6 7 33 16 60 20 27 5 42 9 34 10 -8 1 -25 4 -37 7 -14 4
-23 2 -23 -5z"/>
<path d="M5648 11373 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M6240 11370 c0 -5 4 -10 9 -10 6 0 13 5 16 10 3 6 -1 10 -9 10 -9 0
-16 -4 -16 -10z"/>
<path d="M2498 11363 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3027 11354 c-32 -7 -61 -15 -65 -19 -7 -7 19 -6 215 13 39 4 75 11
80 16 11 11 -160 3 -230 -10z"/>
<path d="M4980 11361 c0 -12 102 -13 120 -1 10 7 -5 10 -52 10 -38 0 -68 -4
-68 -9z"/>
<path d="M5120 11360 c0 -6 28 -9 68 -8 37 1 78 -2 92 -7 22 -7 23 -6 8 9 -12
12 -36 16 -92 16 -44 0 -76 -4 -76 -10z"/>
<path d="M3583 11353 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4155 11350 c-3 -5 -28 -10 -56 -10 -27 0 -49 -4 -49 -10 0 -5 -9
-10 -21 -10 -11 0 -18 4 -15 9 6 9 0 9 -144 10 -63 0 -97 -4 -102 -12 -4 -6
-21 -10 -38 -9 -16 2 -30 -1 -30 -6 0 -22 211 -33 259 -14 21 8 36 10 40 4 9
-15 161 -27 161 -13 0 7 17 10 43 10 l42 -2 -40 -7 c-22 -4 60 -6 182 -5 122
2 226 6 232 10 21 12 11 25 -19 25 -16 0 -30 5 -30 10 0 6 -8 10 -17 10 -15 0
-16 -2 -3 -10 11 -7 7 -10 -18 -10 -20 0 -31 4 -27 10 3 6 -1 10 -9 10 -9 0
-16 3 -16 8 0 4 -34 8 -76 10 -48 1 -73 -2 -69 -8 4 -6 -15 -10 -50 -10 -35 0
-54 4 -50 10 4 6 -10 10 -34 10 -22 0 -43 -4 -46 -10z m295 -20 c0 -5 -2 -10
-4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-397 -27 c-7 -2
-19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M5565 11350 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M2418 11343 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M2505 11340 c-28 -7 -27 -8 13 -9 23 0 42 4 42 9 0 11 -11 11 -55 0z"/>
<path d="M5668 11343 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M2270 11330 c0 -5 -7 -10 -15 -10 -17 0 -20 -17 -5 -22 24 -8 -90
-128 -121 -128 -11 0 -39 -9 -61 -20 -23 -11 -56 -20 -72 -20 -17 0 -42 -9
-56 -20 -14 -11 -32 -20 -41 -20 -43 0 -149 -48 -174 -78 -15 -18 -24 -36 -20
-40 4 -4 10 -1 12 6 3 6 10 12 17 12 7 0 21 8 32 18 10 10 39 24 64 31 25 7
55 17 68 22 12 5 32 13 45 18 12 4 33 13 47 19 14 6 43 13 65 16 50 5 76 15
95 36 8 10 30 25 48 34 18 9 36 25 39 34 4 9 14 21 22 27 16 9 21 11 41 14 15
2 12 31 -3 31 -7 0 -13 9 -13 20 0 23 7 25 24 8 9 -9 12 -8 12 5 0 11 -8 17
-25 17 -14 0 -25 -4 -25 -10z"/>
<path d="M5158 11333 c23 -2 40 -8 37 -14 -4 -5 4 -9 16 -9 22 0 22 0 3 15
-10 8 -37 14 -59 13 l-40 -1 43 -4z"/>
<path d="M6336 11331 c-7 -11 22 -23 35 -15 5 3 9 9 9 15 0 12 -37 12 -44 0z"/>
<path d="M2820 11320 c13 -9 13 -10 0 -10 -12 0 -13 -2 -1 -9 8 -5 27 -5 43 0
24 7 29 5 26 -9 -1 -9 1 -23 5 -29 15 -24 26 -13 25 27 l0 40 -57 0 c-40 0
-52 -3 -41 -10z"/>
<path d="M4750 11317 c0 -26 26 -39 40 -22 15 18 13 20 -17 29 -17 5 -23 3
-23 -7z"/>
<path d="M5343 11310 c2 -11 8 -25 14 -31 16 -16 53 -1 53 21 0 10 8 21 18 23
9 3 3 5 -13 6 -17 0 -36 -4 -43 -11 -9 -9 -12 -9 -12 0 0 7 -4 12 -10 12 -5 0
-8 -9 -7 -20z"/>
<path d="M5670 11315 c-11 -13 -8 -15 21 -14 19 1 26 3 16 6 -9 2 -15 9 -12
14 10 15 -12 10 -25 -6z"/>
<path d="M5790 11320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M5890 11326 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M2570 11310 c0 -5 9 -10 19 -10 11 0 23 5 26 10 4 6 -5 10 -19 10
-14 0 -26 -4 -26 -10z"/>
<path d="M2730 11310 c-12 -8 -10 -10 8 -10 13 0 20 4 17 10 -7 12 -6 12 -25
0z"/>
<path d="M2968 11313 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3265 11310 c4 -6 -8 -10 -29 -10 -29 0 -36 -4 -36 -20 0 -17 7 -20
43 -21 32 0 37 -2 22 -9 -14 -6 -4 -9 38 -9 55 -1 57 0 58 27 1 16 3 20 6 10
6 -22 41 -24 49 -3 12 31 -15 45 -88 45 -44 0 -67 -4 -63 -10z"/>
<path d="M3505 11310 c15 -7 10 -9 -25 -10 -38 -2 -41 -3 -20 -10 30 -10 130
-13 130 -4 0 16 -38 33 -70 33 -29 0 -31 -2 -15 -9z"/>
<path d="M3650 11305 c7 -8 16 -15 21 -15 5 0 9 7 9 15 0 9 -9 15 -21 15 -18
0 -19 -2 -9 -15z"/>
<path d="M4680 11310 l45 -7 -42 -1 c-36 -2 -43 -5 -43 -22 0 -18 3 -19 21
-10 15 9 24 8 35 -1 17 -14 20 -12 29 24 7 27 7 27 -41 25 l-49 -1 45 -7z"/>
<path d="M5098 11313 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2389 11305 c-12 -48 10 -90 35 -65 7 7 5 10 -7 10 -27 0 -22 48 6
53 19 4 18 4 -5 6 -16 0 -29 -1 -29 -4z"/>
<path d="M5488 11303 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1860 11290 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M3075 11291 l50 -5 -55 -5 c-40 -4 -55 -10 -55 -21 0 -8 -13 -18 -30
-22 -24 -6 -22 -7 12 -4 25 3 44 -1 47 -8 3 -6 3 -1 0 12 -7 30 21 30 37 0
l12 -22 17 22 c12 15 29 22 52 22 l33 0 -44 20 c-24 11 -61 19 -85 18 -41 -2
-41 -2 9 -7z"/>
<path d="M2191 11281 c-1 -8 -8 -11 -21 -8 -11 3 -18 1 -14 -4 3 -5 -7 -9 -23
-10 -25 0 -26 -1 -8 -9 19 -8 19 -9 -2 -9 -13 -1 -23 4 -23 9 0 6 -5 10 -10
10 -6 0 -10 -8 -8 -17 2 -14 -5 -18 -37 -20 -33 -1 -36 0 -15 7 l25 8 -27 1
c-16 1 -28 -3 -28 -9 0 -5 -6 -10 -13 -10 -18 0 -67 -24 -67 -32 0 -5 36 -8
80 -8 47 0 80 4 80 10 0 6 12 10 28 10 21 0 24 2 12 10 -12 8 -11 10 7 10 31
0 53 10 53 25 0 7 5 15 12 17 6 2 8 11 5 19 -4 11 -6 11 -6 0z"/>
<path d="M2640 11275 c-14 -8 -35 -14 -47 -15 -12 0 -25 -5 -29 -11 -4 -7 -22
-9 -45 -6 -22 3 -39 2 -39 -3 0 -4 -13 -10 -29 -13 -16 -3 -41 -9 -56 -13 -20
-5 -26 -4 -21 5 5 7 -3 11 -23 11 -23 0 -29 -4 -25 -15 5 -11 2 -13 -10 -9
-11 4 -16 2 -14 -7 2 -9 -8 -15 -29 -17 -37 -4 -44 -16 -10 -18 12 -1 49 -6
82 -12 59 -9 59 -10 16 -11 -56 -1 -49 -9 14 -15 48 -5 178 18 201 35 5 5 19
6 30 3 10 -3 28 1 38 9 18 13 14 14 -32 9 -35 -3 -52 -1 -52 7 0 17 -40 13
-89 -9 -26 -12 -62 -19 -90 -19 -29 1 -36 4 -18 6 15 2 27 9 27 14 0 5 9 9 20
9 11 0 20 5 20 10 0 6 11 10 24 10 14 0 28 5 31 10 8 12 79 13 120 1 44 -12
70 1 73 38 3 35 -2 37 -38 16z"/>
<path d="M5430 11278 c0 -4 16 -8 35 -8 19 0 35 2 35 4 0 2 -16 6 -35 8 -19 2
-35 0 -35 -4z"/>
<path d="M4805 11270 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M5163 11267 c-42 -10 -59 -27 -28 -27 8 0 15 -7 15 -15 0 -8 -7 -15
-15 -15 -8 0 -15 5 -15 10 0 6 -9 10 -20 10 -11 0 -20 -4 -20 -9 0 -5 -13 -14
-30 -20 -16 -5 -30 -11 -30 -13 0 -2 30 -3 67 -3 90 -1 170 -10 186 -22 18
-15 125 -25 132 -13 3 5 15 10 26 10 10 0 19 5 19 11 0 5 -5 7 -10 4 -6 -4
-25 -1 -44 5 -34 11 -70 5 -60 -11 3 -5 -10 -9 -28 -9 -25 0 -29 3 -18 10 12
7 9 11 -16 16 -33 6 -43 34 -12 34 10 0 18 -4 18 -10 0 -5 18 -10 40 -10 23 0
40 5 40 11 0 6 -7 9 -15 5 -19 -7 -47 3 -40 15 3 4 -11 11 -32 15 l-38 7 40
13 40 12 -55 -1 c-30 0 -74 -5 -97 -10z m48 -34 c-8 -3 -9 -8 -3 -12 6 -4 5
-10 -3 -18 -6 -6 -17 -8 -24 -4 -18 12 -1 41 23 40 12 0 16 -3 7 -6z"/>
<path d="M5558 11273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5998 11255 c3 -19 0 -25 -14 -25 -9 0 -15 -3 -12 -6 3 -3 21 -1 39
5 19 5 26 10 17 10 -31 2 -19 21 13 21 17 0 28 4 24 10 -3 6 -21 10 -39 10
-30 0 -33 -2 -28 -25z"/>
<path d="M6080 11270 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M4038 11263 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z"/>
<path d="M4230 11260 c20 -13 90 -13 90 0 0 6 -24 10 -52 10 -38 0 -49 -3 -38
-10z"/>
<path d="M5600 11260 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0
-15 -4 -15 -10z"/>
<path d="M2750 11256 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8
0 -15 -2 -15 -4z"/>
<path d="M5288 11253 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5523 11253 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5760 11251 c0 -16 31 -41 50 -41 23 0 70 -20 70 -30 0 -12 109 -30
182 -30 37 0 68 -4 68 -9 0 -7 57 -27 178 -62 23 -7 42 -16 42 -21 0 -4 9 -8
20 -8 11 0 20 -4 20 -10 0 -6 3 -9 8 -9 26 4 62 -3 57 -11 -4 -6 10 -10 36
-10 23 0 39 3 36 7 -4 3 -1 13 6 21 10 13 8 15 -20 9 -22 -4 -37 -2 -47 8 -8
8 -21 15 -28 15 -7 0 -23 7 -35 15 -14 10 -32 13 -47 9 -17 -4 -37 1 -59 15
-19 12 -46 21 -61 21 -14 0 -26 5 -26 10 0 6 -9 10 -20 10 -28 0 -71 22 -67
34 2 6 18 21 37 33 27 19 30 24 16 29 -11 4 -27 -3 -44 -19 -15 -15 -42 -32
-60 -38 -18 -6 -32 -15 -32 -20 0 -13 -28 -11 -53 5 -12 7 -60 26 -107 41 -47
15 -93 31 -102 36 -11 6 -18 6 -18 0z"/>
<path d="M3505 11240 c-11 -5 -34 -9 -51 -9 -36 -1 -64 -25 -41 -34 8 -3 35
-9 61 -12 72 -11 62 -20 -27 -23 -45 -2 -83 -5 -85 -7 -3 -2 3 -11 11 -20 17
-17 102 -22 92 -5 -3 6 5 10 19 10 13 0 32 3 42 7 11 4 15 3 10 -5 -3 -6 -2
-13 4 -17 6 -4 10 7 10 28 0 38 -13 45 -80 48 -26 1 -20 4 24 10 57 8 96 0 96
-21 0 -6 5 -10 11 -10 6 0 9 7 5 15 -3 10 3 17 19 21 14 3 22 10 19 15 -3 5
-10 6 -16 3 -6 -4 -14 0 -19 7 -6 10 -9 10 -9 2 0 -9 -7 -10 -26 -3 -30 12
-42 12 -69 0z m48 -7 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z"/>
<path d="M3778 11243 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3831 11241 c-7 -5 -2 -12 14 -19 33 -15 35 -15 35 2 0 16 -32 27
-49 17z"/>
<path d="M4080 11240 c0 -5 7 -10 15 -10 8 0 24 -6 35 -12 17 -11 22 -10 30 2
5 8 6 18 4 22 -7 12 -84 10 -84 -2z"/>
<path d="M4376 11235 c4 -10 19 -15 48 -14 22 1 33 3 24 6 -10 2 -18 9 -18 14
0 5 -13 9 -30 9 -22 0 -28 -4 -24 -15z"/>
<path d="M4535 11240 c-17 -7 -18 -9 -4 -15 26 -10 102 -1 107 13 4 14 -70 16
-103 2z"/>
<path d="M4672 11243 c-43 -8 -16 -28 41 -31 l57 -3 -27 -25 c-23 -21 -25 -27
-13 -34 10 -7 11 -10 3 -10 -7 0 -13 -4 -13 -10 0 -11 109 -12 150 -1 25 6 33
30 15 43 -15 11 -55 10 -55 -2 0 -5 -7 -10 -15 -10 -8 0 -15 -4 -15 -10 0 -5
-6 -10 -14 -10 -9 0 -12 7 -9 19 3 14 18 21 51 26 49 7 103 35 69 35 -9 0 -17
-4 -17 -10 0 -5 -7 -10 -15 -10 -8 0 -14 5 -12 12 1 6 -9 12 -23 13 -102 6
-105 6 -85 15 16 7 13 8 -15 8 -19 0 -45 -3 -58 -5z"/>
<path d="M2720 11213 c0 -28 38 -40 70 -23 30 16 90 4 90 -17 0 -14 2 -15 13
-4 10 11 10 14 0 18 -7 3 -13 11 -13 18 0 7 6 15 13 18 7 2 8 8 3 13 -5 5 -11
4 -13 -3 -5 -14 -35 -17 -47 -6 -4 4 -32 8 -62 8 -49 0 -54 -2 -54 -22z m80
-3 c0 -5 -17 -10 -37 -10 -28 0 -34 3 -23 10 20 13 60 13 60 0z"/>
<path d="M3670 11232 c0 -5 14 -7 30 -4 17 2 30 6 30 8 0 2 -13 4 -30 4 -16 0
-30 -4 -30 -8z"/>
<path d="M3900 11229 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M5480 11233 c0 -3 7 -14 16 -24 19 -21 44 -14 44 12 0 14 -7 19 -30
19 -16 0 -30 -3 -30 -7z"/>
<path d="M5613 11233 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M6505 11230 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M3140 11209 c-14 -12 -26 -16 -28 -10 -2 6 -8 11 -13 11 -6 0 -7 -4
-4 -10 9 -15 -69 -12 -83 2 -9 9 -15 10 -20 2 -4 -7 -20 -12 -35 -12 -32 1
-42 -6 -33 -21 4 -5 29 -7 60 -4 l54 6 12 -39 c19 -61 31 -64 28 -9 l-3 50 59
12 c32 6 62 16 65 21 3 6 24 12 46 15 30 3 26 4 -20 5 -47 1 -65 -3 -85 -19z"/>
<path d="M3964 11212 c4 -7 15 -13 24 -13 9 -1 4 -5 -13 -9 l-30 -8 30 -1 c29
-2 29 -3 8 -13 -13 -6 -23 -15 -23 -20 0 -11 27 -10 35 2 3 6 16 10 28 9 20
-1 20 -2 -3 -9 -16 -5 4 -8 60 -8 47 1 72 3 56 5 -16 3 -35 11 -43 19 -8 8
-21 14 -30 14 -9 0 -21 7 -27 15 -14 18 -83 34 -72 17z"/>
<path d="M4942 11213 c-22 -4 -23 -6 -8 -22 21 -23 69 -17 64 8 -3 17 -19 21
-56 14z"/>
<path d="M5683 11213 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M6240 11200 c0 -15 7 -20 24 -20 35 0 40 15 10 29 -33 15 -34 14 -34
-9z"/>
<path d="M3294 11194 c3 -9 6 -18 6 -20 0 -10 53 -2 65 10 12 12 12 15 -1 20
-9 3 -29 6 -46 6 -23 0 -29 -4 -24 -16z"/>
<path d="M4535 11199 c-4 -5 -18 -14 -31 -19 -44 -17 -26 -30 39 -29 34 0 71
4 81 8 16 6 13 10 -23 29 -47 25 -57 27 -66 11z"/>
<path d="M6196 11203 c-12 -12 -5 -23 14 -23 11 0 20 4 20 9 0 11 -26 22 -34
14z"/>
<path d="M2578 11193 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3661 11193 c-19 -4 -21 -7 -10 -14 9 -6 10 -9 2 -9 -7 0 -10 -7 -7
-15 4 -11 22 -15 63 -16 31 -1 47 -4 36 -6 -11 -3 -22 -9 -25 -13 -3 -4 -29
-10 -58 -14 -52 -7 -52 -6 -47 17 4 14 1 28 -8 35 -12 10 -16 8 -20 -10 -4
-12 -1 -25 4 -29 19 -11 -4 -18 -82 -25 -59 -6 -68 -9 -48 -16 15 -6 47 -5 85
1 33 6 85 11 116 11 68 0 121 17 114 36 -3 8 1 14 9 14 8 0 15 5 15 10 0 6 9
10 20 10 25 0 25 9 3 26 -17 13 -109 17 -162 7z"/>
<path d="M4262 11190 c-65 -7 -110 -30 -60 -30 15 0 30 -4 33 -10 3 -5 23 -9
43 -9 l37 2 -40 7 -40 7 42 2 c23 0 43 7 46 14 2 6 8 8 13 4 5 -4 2 -12 -5
-16 -9 -6 -10 -11 -2 -15 6 -4 20 -1 31 7 19 13 20 16 5 31 -9 9 -22 15 -28
15 -7 -1 -40 -5 -75 -9z"/>
<path d="M4423 11193 c-10 -3 -10 -7 -1 -17 9 -9 16 -10 24 -2 20 18 5 31 -23
19z"/>
<path d="M5680 11191 c0 -6 -7 -11 -16 -11 -8 0 -12 -4 -9 -10 3 -5 25 -10 48
-10 31 0 38 -3 27 -10 -11 -7 -3 -10 28 -10 26 0 41 -4 37 -10 -3 -5 3 -10 14
-10 27 0 28 30 1 37 -11 3 -20 9 -20 14 0 4 -17 11 -37 14 -21 4 -46 9 -55 12
-10 2 -18 0 -18 -6z m68 -18 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2
18 -5z"/>
<path d="M6320 11180 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M1755 11170 c-3 -5 -17 -10 -30 -10 -13 0 -27 -5 -31 -12 -5 -8 8 -9
51 -4 66 7 76 13 55 26 -20 13 -37 12 -45 0z"/>
<path d="M1848 11174 c12 -3 22 -10 22 -15 0 -7 13 -8 33 -4 31 7 31 6 12 -10
-20 -16 -19 -16 5 -9 29 7 46 21 38 30 -4 3 -35 7 -70 9 -35 2 -53 2 -40 -1z"/>
<path d="M2755 11160 c4 -6 -16 -10 -54 -10 -32 0 -61 -5 -63 -10 -2 -6 -23
-12 -48 -13 -25 -2 -50 -6 -57 -10 -6 -4 -39 -10 -72 -13 -59 -7 -77 -18 -46
-30 8 -4 21 -2 28 4 22 18 64 18 85 -1 13 -12 22 -15 27 -8 3 6 1 11 -6 11
-10 0 -10 3 -1 12 6 6 37 15 68 19 31 4 60 12 63 18 4 6 20 7 46 1 22 -5 48
-10 58 -10 22 0 23 36 1 44 -23 9 -36 7 -29 -4z"/>
<path d="M4678 11163 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5077 11153 c-11 -11 -30 -16 -52 -15 -29 2 -35 -1 -35 -18 0 -22 19
-26 39 -7 10 10 16 8 31 -10 23 -29 59 -29 94 -2 30 24 33 35 9 47 -39 20 -69
22 -86 5z"/>
<path d="M3142 11139 c-16 -25 2 -54 27 -43 9 3 23 7 31 8 8 1 23 6 34 11 12
5 21 3 26 -6 12 -18 25 -4 25 27 1 23 -2 24 -65 24 -55 0 -67 -3 -78 -21z
m121 4 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M5460 11152 c0 -19 26 -29 75 -26 28 1 60 3 73 3 12 1 22 5 22 11 0
5 -10 9 -22 8 -13 -1 -51 2 -85 6 -40 5 -63 4 -63 -2z"/>
<path d="M5840 11150 c0 -5 9 -10 19 -10 11 0 23 5 26 10 4 6 -5 10 -19 10
-14 0 -26 -4 -26 -10z"/>
<path d="M6432 11143 c5 -24 21 -26 36 -3 11 18 10 20 -14 20 -18 0 -25 -5
-22 -17z"/>
<path d="M1825 11139 c-10 -15 -4 -18 28 -12 21 4 25 8 15 14 -18 12 -36 11
-43 -2z"/>
<path d="M3858 11143 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M4670 11134 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M2881 11124 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4928 11133 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5969 11122 l-34 -16 35 -4 c19 -2 39 -8 45 -13 12 -12 96 -11 115 2
11 8 2 9 -32 4 -31 -4 -48 -2 -48 4 0 6 -7 11 -16 11 -8 0 -13 4 -10 9 11 17
-20 19 -55 3z"/>
<path d="M6588 11133 c19 -8 14 -21 -10 -26 -14 -3 -9 -5 15 -6 20 0 37 2 37
5 0 10 -33 33 -45 33 -5 0 -4 -3 3 -6z"/>
<path d="M2917 11117 c-3 -8 -1 -20 4 -28 8 -12 10 -12 19 1 5 8 10 21 10 28
0 16 -27 16 -33 -1z"/>
<path d="M4268 11123 c18 -2 45 -2 60 0 15 2 0 4 -33 4 -33 0 -45 -2 -27 -4z"/>
<path d="M5660 11120 c-11 -7 -7 -10 18 -10 17 0 32 -5 32 -10 0 -6 19 -11 43
-10 23 0 57 -2 75 -6 19 -4 32 -2 32 4 0 11 -34 22 -73 22 -16 0 -26 4 -22 10
8 13 -85 13 -105 0z"/>
<path d="M5900 11120 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M2262 11104 c9 -9 21 -13 27 -9 16 10 3 25 -22 25 -20 0 -20 -1 -5
-16z"/>
<path d="M2340 11110 c-12 -8 -10 -10 8 -10 13 0 20 4 17 10 -7 12 -6 12 -25
0z"/>
<path d="M2990 11099 c0 -24 23 -21 28 4 2 10 -3 17 -12 17 -10 0 -16 -9 -16
-21z"/>
<path d="M5370 11110 c-8 -5 -33 -10 -55 -11 l-40 -2 40 -6 c22 -3 42 -3 43 2
2 4 8 5 13 1 12 -7 49 5 49 17 0 12 -31 11 -50 -1z"/>
<path d="M5600 11110 c0 -13 11 -13 30 0 12 8 11 10 -7 10 -13 0 -23 -4 -23
-10z"/>
<path d="M2168 11103 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3340 11101 c0 -8 90 -3 99 5 2 2 -19 4 -47 4 -29 0 -52 -4 -52 -9z"/>
<path d="M4425 11100 c-77 -5 -75 -6 60 -12 77 -4 164 -10 193 -14 34 -4 58
-2 65 5 8 8 1 11 -26 11 -20 0 -37 3 -37 8 0 8 -139 9 -255 2z"/>
<path d="M4780 11100 c13 -8 12 -10 -3 -10 -31 0 -10 -17 29 -23 24 -4 44 -1
56 7 19 14 19 14 -1 21 -12 3 -30 3 -41 0 -11 -4 -20 -2 -20 4 0 6 -8 11 -17
11 -15 0 -16 -2 -3 -10z"/>
<path d="M4930 11100 c-12 -7 -10 -10 5 -15 11 -4 28 -11 38 -16 25 -13 20 3
-7 23 -14 10 -28 13 -36 8z"/>
<path d="M3818 11093 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3895 11090 c4 -6 -5 -10 -19 -10 -17 0 -26 -6 -26 -16 0 -8 -5 -12
-10 -9 -15 10 -80 -15 -80 -30 0 -7 7 -16 16 -19 22 -8 109 -7 169 3 31 6 52
5 56 -1 7 -10 214 -5 282 7 24 5 37 3 37 -4 0 -7 28 -11 80 -11 62 0 80 3 80
14 0 20 30 29 48 14 9 -8 34 -12 64 -10 38 3 48 1 48 -12 0 -13 10 -15 63 -10
34 3 68 8 75 10 7 2 32 -6 55 -18 39 -20 65 -23 172 -19 18 1 25 7 25 20 0 11
-6 23 -12 27 -34 21 -133 21 -168 -1 -5 -3 -26 -3 -45 1 -25 4 -35 11 -31 20
4 10 -5 14 -33 14 -21 0 -53 3 -70 6 -19 4 -31 2 -31 -4 0 -6 17 -13 38 -15
20 -3 -6 -2 -58 0 -65 3 -100 9 -112 19 -10 10 -46 18 -92 21 -68 5 -76 4 -70
-10 5 -14 -2 -16 -48 -13 -29 1 -63 8 -75 14 -31 17 -115 15 -149 -3 -16 -8
-39 -15 -51 -15 -13 0 -23 -5 -23 -11 0 -5 -4 -7 -10 -4 -5 3 -10 15 -10 26 0
13 -7 19 -24 19 -13 0 -23 -6 -22 -12 0 -7 -3 -14 -9 -15 -16 -4 -11 31 5 39
10 4 5 7 -13 7 -16 1 -26 -3 -22 -9z m818 -77 c-7 -2 -19 -2 -25 0 -7 3 -2 5
12 5 14 0 19 -2 13 -5z"/>
<path d="M3968 11093 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4028 11093 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4113 11093 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4183 11093 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4263 11093 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M5235 11090 c-3 -5 1 -10 9 -10 9 0 16 5 16 10 0 6 -4 10 -9 10 -6 0
-13 -4 -16 -10z"/>
<path d="M2098 11083 c-30 -8 -20 -23 15 -22 26 0 28 2 12 8 l-20 8 20 5 c11
3 13 6 5 6 -8 0 -23 -2 -32 -5z"/>
<path d="M2218 11083 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2642 11083 c-6 -2 -10 -9 -6 -14 8 -14 31 -11 38 6 5 15 -7 18 -32
8z"/>
<path d="M3200 11071 c0 -14 5 -19 15 -14 17 6 15 25 -3 31 -7 2 -12 -5 -12
-17z"/>
<path d="M3265 11080 c28 -12 37 -12 30 0 -3 6 -16 10 -28 9 -21 0 -21 -1 -2
-9z"/>
<path d="M5928 11083 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2022 11058 c2 -18 10 -23 36 -24 38 -1 39 2 10 27 -31 25 -50 24
-46 -3z"/>
<path d="M2711 11064 c-7 -9 -10 -19 -5 -24 5 -5 14 2 21 16 13 27 5 31 -16 8z"/>
<path d="M2880 11060 c0 -13 5 -18 14 -14 8 3 12 12 9 20 -8 21 -23 17 -23 -6z"/>
<path d="M5375 11070 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M5457 11068 c-2 -7 -5 -17 -6 -23 -2 -5 -10 -18 -18 -27 -15 -17 -14
-18 5 -18 11 0 27 -2 34 -5 10 -4 13 4 12 32 -1 21 -2 41 -3 46 -2 12 -18 8
-24 -5z"/>
<path d="M6144 11065 c19 -14 36 -12 36 6 0 5 -12 9 -27 9 -27 -1 -27 -1 -9
-15z"/>
<path d="M2340 11060 c-12 -8 -11 -10 7 -10 12 0 25 5 28 10 8 13 -15 13 -35
0z"/>
<path d="M3103 11050 c1 -14 6 -18 17 -14 8 4 27 9 42 12 40 6 24 22 -22 22
-33 0 -39 -3 -37 -20z"/>
<path d="M5049 11048 c17 -45 -12 -132 -41 -124 -10 2 -18 1 -18 -4 0 -4 28
-10 62 -12 49 -4 64 -9 76 -26 15 -23 49 -28 86 -13 19 7 19 8 0 15 -19 8 -19
8 1 16 15 7 9 9 -25 9 -25 1 -61 4 -81 7 -36 6 -36 6 -38 60 -1 30 3 63 9 74
9 17 8 20 -14 20 -22 0 -24 -3 -17 -22z"/>
<path d="M5944 11058 c-3 -4 14 -8 36 -8 34 0 43 -4 47 -20 7 -28 26 -20 30
12 3 25 2 25 -53 24 -30 0 -58 -4 -60 -8z"/>
<path d="M6221 11056 c-16 -19 1 -28 30 -18 l23 9 -21 11 c-16 9 -23 8 -32 -2z"/>
<path d="M2253 11053 c-7 -2 -13 -11 -13 -19 0 -11 8 -14 28 -12 16 2 27 9 27
18 0 15 -21 22 -42 13z"/>
<path d="M2580 11048 c0 -19 34 -38 59 -31 28 7 27 28 -1 29 -13 0 -31 4 -40
8 -12 6 -18 4 -18 -6z"/>
<path d="M3500 11045 c-17 -9 -41 -11 -76 -6 -29 4 -74 4 -100 0 -27 -3 -42
-7 -34 -8 13 -1 13 -2 0 -11 -12 -8 -12 -10 -1 -10 8 0 12 -3 9 -6 -3 -3 -15
0 -26 7 -23 14 -52 6 -52 -16 0 -9 9 -15 26 -15 14 0 23 -4 20 -8 -3 -5 35 -9
84 -10 52 0 88 4 84 9 -3 5 15 9 40 9 25 0 46 5 46 10 0 6 9 10 20 10 11 0 20
-7 20 -15 0 -19 27 -19 64 0 15 8 36 15 46 15 22 0 46 28 34 39 -17 16 -177
21 -204 6z m180 -15 c0 -12 -59 -12 -115 -1 -35 8 -31 9 38 10 45 1 77 -3 77
-9z m-172 -17 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z"/>
<path d="M5145 11051 c-25 -5 -27 -8 -11 -14 10 -4 16 -13 13 -21 -3 -8 -1
-17 6 -19 9 -3 9 -8 0 -18 -7 -7 -13 -17 -13 -21 0 -10 71 -40 78 -33 3 3 0 5
-6 5 -7 0 -12 4 -12 10 0 5 18 13 40 16 30 5 40 11 40 25 0 15 -9 19 -50 21
-60 4 -71 22 -16 26 50 4 87 -5 85 -21 -4 -34 1 -40 20 -23 10 9 22 16 27 15
23 -4 45 2 39 11 -4 6 -10 8 -15 5 -4 -3 -10 -1 -12 4 -1 5 -21 12 -43 14 -22
3 -56 10 -75 16 -38 12 -49 12 -95 2z"/>
<path d="M5600 11055 c0 -2 15 -9 33 -15 l32 -10 -37 3 c-20 2 -34 -1 -32 -5
3 -4 23 -8 45 -8 27 0 39 4 39 14 0 8 12 17 28 19 15 2 -3 5 -40 6 -38 0 -68
-1 -68 -4z"/>
<path d="M2180 11041 c0 -9 -77 -39 -125 -48 -16 -3 -33 -11 -37 -17 -4 -5
-33 -10 -66 -11 -40 0 -59 -4 -59 -12 0 -13 17 -18 47 -14 8 0 32 3 52 6 21 2
40 0 43 -5 4 -6 16 -10 28 -9 12 0 17 3 11 5 -23 10 8 54 38 54 15 0 30 5 33
10 3 6 23 10 43 10 31 0 38 4 38 20 0 14 -7 20 -23 20 -13 0 -23 -4 -23 -9z"/>
<path d="M2820 11035 c0 -8 -9 -15 -20 -15 -11 0 -20 -5 -20 -11 0 -6 7 -8 16
-5 9 3 34 7 57 7 30 2 34 3 15 6 -16 2 -28 11 -28 19 0 8 -4 14 -10 14 -5 0
-10 -7 -10 -15z"/>
<path d="M3030 11040 c-12 -8 -9 -10 13 -10 16 0 26 4 22 10 -8 12 -16 12 -35
0z"/>
<path d="M6080 11021 c0 -17 5 -31 10 -31 6 0 10 11 10 24 0 14 -4 28 -10 31
-6 4 -10 -7 -10 -24z"/>
<path d="M2442 11031 c27 -4 53 -12 60 -19 16 -16 28 -15 28 2 0 19 -23 26
-83 25 l-52 -2 47 -6z"/>
<path d="M5815 11029 c-4 -6 -28 -8 -58 -6 -29 2 -42 2 -29 0 13 -2 32 -13 43
-24 13 -13 33 -19 67 -19 26 0 53 -5 60 -12 8 -8 15 -8 27 2 20 17 19 30 -2
30 -15 0 -16 2 -3 10 12 7 8 10 -15 11 -26 0 -27 2 -10 9 15 6 8 9 -27 9 -26
1 -50 -4 -53 -10z"/>
<path d="M1963 11023 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2915 11021 c-3 -6 4 -15 17 -22 16 -9 23 -9 26 0 2 6 10 11 18 11 9
0 12 5 9 10 -8 13 -62 13 -70 1z"/>
<path d="M2350 11005 c-10 -11 -9 -18 3 -30 20 -19 47 -19 47 0 0 11 8 13 31
8 21 -4 34 -1 40 8 15 24 10 29 -31 29 -24 0 -40 -5 -40 -12 0 -9 -3 -9 -12 0
-16 16 -23 15 -38 -3z"/>
<path d="M3028 11013 c25 -10 12 -33 -19 -33 -17 0 -28 -4 -24 -10 3 -5 -1
-10 -9 -10 -9 0 -16 -4 -16 -10 0 -5 16 -10 35 -10 57 0 38 -24 -25 -31 l-55
-6 63 -1 c52 -2 62 -5 62 -19 1 -17 1 -17 14 0 13 17 28 23 24 10 -5 -15 36
-53 56 -53 11 0 35 -5 53 -12 28 -10 36 -10 43 2 6 9 5 17 -2 21 -6 4 23 11
63 17 l74 9 -97 2 c-81 1 -98 -2 -98 -14 0 -10 -10 -15 -27 -15 -21 0 -24 3
-15 12 7 7 12 20 12 30 0 15 -9 18 -52 19 l-53 1 66 16 c40 9 71 23 78 34 10
16 7 18 -25 18 -31 0 -35 3 -29 20 6 18 2 20 -52 19 -32 0 -52 -3 -45 -6z"/>
<path d="M6241 11016 c10 -9 109 -14 109 -6 0 6 -25 10 -57 10 -31 0 -54 -2
-52 -4z"/>
<path d="M6160 10999 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M1858 10993 c-19 -5 -25 -33 -7 -33 5 0 7 5 4 10 -4 6 5 10 19 10 14
0 26 5 26 10 0 10 -12 11 -42 3z"/>
<path d="M5560 10987 c0 -8 -3 -22 -6 -31 -4 -11 0 -16 14 -16 12 0 24 5 27
10 4 6 15 8 25 5 10 -3 21 -1 25 5 4 6 -5 10 -22 11 -25 0 -26 1 -8 10 18 8
17 9 -4 5 -14 -3 -31 0 -38 6 -10 8 -13 7 -13 -5z"/>
<path d="M6000 10965 c0 -19 -5 -35 -11 -35 -8 0 -8 -5 1 -15 7 -8 22 -15 34
-15 19 1 19 2 5 13 -15 11 -15 16 -3 46 18 42 18 41 -6 41 -16 0 -20 -7 -20
-35z"/>
<path d="M6207 10963 c-6 -48 10 -69 19 -24 4 20 15 36 28 41 16 7 17 9 4 9
-9 1 -23 4 -31 7 -11 4 -16 -5 -20 -33z"/>
<path d="M6418 10993 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M2221 10976 c-19 -23 -8 -30 26 -18 16 6 21 11 11 11 -9 1 -18 6 -20
12 -2 7 -9 5 -17 -5z"/>
<path d="M2560 10969 c0 -14 5 -19 17 -17 24 4 26 38 2 38 -13 0 -19 -7 -19
-21z"/>
<path d="M2680 10976 c0 -25 12 -46 26 -46 10 0 14 10 12 30 -2 21 -7 30 -20
30 -10 0 -18 -6 -18 -14z"/>
<path d="M3735 10980 c4 -6 -7 -10 -24 -10 -17 0 -31 -3 -31 -7 0 -5 36 -9 80
-9 44 -1 77 2 74 7 -3 5 -15 9 -27 9 -18 0 -19 2 -7 10 11 7 3 10 -28 10 -26
0 -41 -4 -37 -10z"/>
<path d="M3987 10983 c-32 -8 -12 -28 32 -31 29 -2 41 0 36 7 -3 6 -18 12 -33
12 l-27 1 30 8 c27 7 27 8 5 8 -14 0 -33 -2 -43 -5z"/>
<path d="M4173 10983 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M4275 10980 l20 -9 -20 -1 c-16 -1 -15 -4 8 -12 15 -5 35 -7 43 -4
25 9 -8 36 -43 35 -25 0 -26 -1 -8 -9z"/>
<path d="M5688 10983 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2810 10966 c0 -10 -9 -16 -25 -16 -38 0 -44 -29 -12 -54 15 -12 27
-25 27 -28 0 -10 -49 -2 -65 12 -12 10 -15 10 -15 -3 0 -11 -14 -18 -47 -22
-27 -4 -61 -11 -77 -16 -16 -5 -49 -9 -73 -9 -24 0 -51 -4 -61 -9 -37 -19
-101 -33 -160 -35 -43 -1 -62 -5 -62 -14 0 -7 -13 -15 -30 -18 -16 -4 -28 -10
-25 -14 7 -11 -80 -34 -104 -27 -17 4 -31 -9 -31 -30 0 -11 98 4 114 18 9 8
22 19 29 24 11 10 91 29 172 42 17 2 39 8 50 13 27 11 131 32 175 36 19 1 80
11 135 22 71 13 100 23 100 33 0 8 10 15 23 17 17 3 22 -1 20 -14 -2 -9 2 -19
8 -21 6 -2 8 10 6 32 -4 32 -2 35 22 35 19 0 26 5 26 19 0 22 -29 36 -44 21
-14 -14 -47 -13 -43 2 1 6 -6 14 -15 16 -11 2 -18 -2 -18 -12z m60 -50 c0 -9
-30 -14 -35 -6 -4 6 3 10 14 10 12 0 21 -2 21 -4z"/>
<path d="M5100 10965 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5
0 -10 -7 -10 -15z"/>
<path d="M3212 10954 c-11 -12 -10 -17 3 -30 12 -12 27 -14 64 -9 39 5 49 3
54 -9 4 -9 18 -16 32 -16 15 0 24 4 20 10 -4 6 7 10 27 10 32 0 32 0 10 16
-18 12 -38 14 -102 8 -80 -7 -86 -4 -81 29 2 12 -13 7 -27 -9z"/>
<path d="M4095 10960 c-13 -6 -15 -9 -5 -9 8 -1 21 -11 29 -23 l13 -22 13 25
c7 13 11 27 9 31 -6 10 -33 9 -59 -2z"/>
<path d="M4370 10960 c0 -5 7 -10 16 -10 8 0 12 -4 9 -10 -4 -6 6 -10 22 -10
22 0 25 2 13 10 -13 9 -13 10 0 11 8 0 4 5 -10 9 -36 11 -50 11 -50 0z"/>
<path d="M4560 10960 c0 -5 12 -10 26 -10 14 0 23 -4 19 -10 -3 -5 3 -10 14
-10 12 0 21 -4 21 -10 0 -5 7 -10 16 -10 8 0 13 4 10 9 -6 9 108 6 180 -5 24
-3 47 -10 50 -15 10 -16 49 -9 67 12 18 20 17 20 -43 18 -34 -1 -66 3 -72 9
-7 7 -35 9 -77 5 -44 -3 -86 0 -127 11 -72 19 -84 20 -84 6z"/>
<path d="M5388 10948 c-13 -13 -26 -22 -30 -20 -11 3 -10 -16 1 -22 13 -9 60
17 65 36 7 28 -11 31 -36 6z"/>
<path d="M5458 10963 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M5695 10956 c11 -9 14 -16 8 -16 -7 0 -12 -6 -10 -12 1 -8 -7 -12
-20 -10 -13 2 -23 -2 -23 -7 0 -6 7 -11 15 -11 8 0 15 -4 15 -10 0 -5 -7 -10
-17 -10 -9 0 -14 -2 -12 -5 3 -2 25 -1 50 3 31 6 43 13 40 23 -2 8 -4 25 -5
39 -2 20 -8 26 -31 28 -29 3 -29 3 -10 -12z"/>
<path d="M6057 10963 c-4 -3 -7 -14 -7 -24 0 -21 39 -33 47 -13 6 16 39 19 48
4 3 -5 -1 -10 -9 -10 -9 0 -16 -4 -16 -10 0 -5 6 -10 13 -10 22 0 36 15 33 35
-3 17 -10 20 -45 18 -22 -1 -41 2 -41 8 0 10 -14 12 -23 2z"/>
<path d="M6280 10960 l-45 -7 55 -2 c40 -1 51 -4 40 -11 -11 -7 -7 -10 17 -10
17 0 38 -6 45 -12 39 -37 88 -49 88 -23 0 8 -9 15 -19 15 -11 0 -22 4 -26 9
-3 5 -19 12 -35 16 -17 4 -30 13 -30 21 0 15 -15 16 -90 4z"/>
<path d="M3537 10950 c-133 -11 -129 -24 8 -25 l59 0 11 -39 c16 -54 40 -63
31 -11 -5 26 -4 40 5 45 15 10 4 41 -13 39 -7 -1 -52 -5 -101 -9z"/>
<path d="M3864 10935 c9 -13 16 -27 16 -31 0 -4 -36 -4 -80 -1 -44 3 -82 2
-85 -3 -8 -13 23 -33 40 -26 9 3 13 2 10 -4 -4 -6 10 -10 34 -10 22 0 43 5 46
11 4 6 13 8 21 5 8 -3 26 -1 41 5 32 12 66 3 43 -11 -10 -7 9 -10 58 -10 65 0
72 2 72 20 0 18 -8 20 -97 22 -86 3 -95 5 -82 18 26 28 21 40 -17 40 -36 0
-36 0 -20 -25z m136 -55 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16
10 5 0 9 -4 9 -10z"/>
<path d="M2311 10937 c-6 -8 -8 -17 -4 -20 3 -4 0 -7 -8 -7 -8 0 -25 -9 -39
-20 -14 -11 -33 -20 -42 -20 -17 0 -25 -30 -12 -43 9 -9 35 4 28 14 -3 5 5 13
18 18 13 5 30 16 37 25 7 9 21 16 30 16 28 0 42 20 26 36 -17 17 -20 17 -34 1z"/>
<path d="M2446 10934 c19 -15 18 -15 -3 -9 -29 8 -79 -5 -62 -16 7 -4 21 -3
31 2 14 7 20 6 24 -6 3 -8 15 -15 26 -15 10 0 17 4 13 9 -3 5 4 16 15 24 18
13 19 15 5 21 -9 3 -28 6 -43 6 -25 0 -25 0 -6 -16z"/>
<path d="M5760 10940 c0 -15 49 -23 64 -11 11 10 15 9 18 -4 2 -13 6 -13 20 5
18 20 17 20 -42 20 -33 0 -60 -4 -60 -10z"/>
<path d="M5922 10933 c3 -19 22 -43 33 -43 3 0 4 4 0 9 -3 5 -1 13 5 16 8 5 7
11 -1 21 -16 20 -40 18 -37 -3z"/>
<path d="M2120 10936 c3 -3 25 -9 48 -15 32 -7 42 -6 39 2 -2 7 -23 13 -48 15
-24 2 -41 1 -39 -2z"/>
<path d="M2645 10930 c4 -6 11 -8 16 -5 14 9 11 15 -7 15 -8 0 -12 -5 -9 -10z"/>
<path d="M3708 10933 c17 -2 47 -2 65 0 17 2 3 4 -33 4 -36 0 -50 -2 -32 -4z"/>
<path d="M4013 10933 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4233 10933 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5294 10925 c-4 -8 -16 -16 -28 -16 -18 -1 -18 -2 -3 -6 12 -3 16
-10 13 -20 -5 -13 -4 -14 9 -3 12 10 24 8 66 -10 l51 -21 19 20 c25 27 25 50
0 27 -25 -23 -82 -10 -96 22 -11 26 -23 28 -31 7z"/>
<path d="M5445 10908 c9 -41 17 -50 38 -42 22 8 22 30 0 43 -15 9 -14 10 10
11 15 0 27 5 27 10 0 6 -18 10 -41 10 -41 0 -41 0 -34 -32z"/>
<path d="M1947 10895 c-11 -16 -24 -22 -35 -19 -14 5 -15 4 -2 -5 8 -6 27 -11
43 -11 24 0 27 3 25 30 -3 36 -8 37 -31 5z"/>
<path d="M2030 10905 c-8 -9 -8 -15 -2 -15 12 0 26 19 19 26 -2 2 -10 -2 -17
-11z"/>
<path d="M2560 10906 c0 -8 -8 -17 -17 -20 -12 -5 -14 -9 -5 -18 9 -9 15 -9
23 -1 6 6 28 9 50 6 42 -6 45 -3 29 28 -13 23 -80 28 -80 5z m35 -6 c-3 -5
-10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z"/>
<path d="M3558 10913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3983 10913 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M4225 10910 c-4 -6 -13 -9 -20 -6 -19 7 -27 -5 -14 -21 6 -7 23 -13
38 -13 15 0 33 -6 40 -12 10 -11 16 -8 30 11 12 15 24 22 34 18 9 -3 19 -2 22
3 4 6 -3 10 -14 10 -12 0 -21 5 -21 10 0 14 -44 12 -58 -2 -10 -10 -15 -10
-21 1 -6 10 -10 10 -16 1z"/>
<path d="M4338 10913 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M4493 10913 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4560 10912 c0 -7 -17 -12 -45 -12 -25 0 -45 -4 -45 -10 0 -5 -7 -10
-16 -10 -8 0 -12 5 -9 10 3 6 2 10 -3 10 -6 0 -13 -7 -16 -16 -4 -9 -9 -14
-13 -10 -3 3 -25 -14 -47 -38 -61 -67 -68 -75 -61 -85 5 -9 39 16 92 67 13 12
34 22 46 23 21 1 20 1 -3 9 -18 6 -4 8 49 9 58 1 72 4 68 15 -3 7 0 19 6 25 6
6 8 14 4 18 -4 3 -7 1 -7 -5z m-60 -26 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10
4 0 6 5 11 10 11 6 0 10 -2 10 -4z"/>
<path d="M4582 10898 c2 -13 8 -23 13 -23 6 0 11 10 13 23 2 15 -2 22 -13 22
-11 0 -15 -7 -13 -22z"/>
<path d="M5761 10904 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6248 10913 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2137 10903 c-15 -14 -6 -23 22 -23 37 0 49 16 18 24 -28 7 -32 7
-40 -1z"/>
<path d="M3218 10893 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M3480 10890 c-12 -8 -11 -10 5 -11 11 -1 4 -5 -15 -9 l-35 -7 42 -2
c23 0 43 -6 45 -13 3 -9 6 -9 12 0 4 7 2 12 -3 12 -6 0 -11 5 -11 10 0 6 17
10 37 10 47 0 40 6 -17 13 -25 3 -52 2 -60 -3z"/>
<path d="M4127 10880 c3 -11 7 -20 8 -20 1 0 5 9 8 20 3 12 0 20 -8 20 -8 0
-11 -8 -8 -20z"/>
<path d="M4636 10884 c-3 -8 -2 -13 3 -10 5 3 57 6 116 7 59 0 104 3 101 6 -3
3 -52 7 -110 9 -86 3 -105 0 -110 -12z"/>
<path d="M5810 10890 c0 -13 20 -13 40 0 12 8 9 10 -12 10 -16 0 -28 -4 -28
-10z"/>
<path d="M6256 10891 c-7 -10 23 -20 42 -14 6 3 12 9 12 14 0 12 -47 12 -54 0z"/>
<path d="M6333 10893 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2980 10880 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M5523 10874 c-3 -9 0 -19 6 -24 8 -4 1 -12 -20 -20 -29 -13 -33 -12
-50 9 -17 21 -19 21 -19 5 0 -11 12 -27 25 -37 20 -14 31 -16 57 -8 18 5 25
10 16 10 -31 2 -19 21 13 21 20 0 28 4 23 11 -11 18 49 1 63 -18 9 -12 28 -15
77 -14 36 1 73 -3 83 -8 36 -19 118 -40 121 -31 2 6 18 11 35 11 25 1 20 4
-18 10 -91 14 -115 21 -109 30 3 5 -26 9 -65 9 -38 0 -73 5 -76 10 -4 6 -11 8
-16 4 -18 -11 -101 17 -95 32 4 10 -3 14 -20 14 -13 0 -27 -7 -31 -16z"/>
<path d="M2050 10855 c-32 -34 -20 -40 32 -16 37 17 40 21 23 27 -11 4 -23 9
-26 10 -3 2 -17 -8 -29 -21z"/>
<path d="M2383 10873 c9 -2 17 -11 17 -19 0 -14 12 -19 23 -8 10 10 -20 34
-40 33 -17 -1 -17 -2 0 -6z"/>
<path d="M2448 10873 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3671 10864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5014 10871 c3 -5 -8 -11 -24 -13 -16 -3 -32 -1 -34 4 -2 5 -3 3 -2
-4 2 -8 21 -14 49 -16 36 -2 51 2 66 17 l21 21 -41 0 c-22 0 -38 -4 -35 -9z"/>
<path d="M5948 10873 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4703 10863 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M6338 10863 c12 -2 32 -2 45 0 12 2 2 4 -23 4 -25 0 -35 -2 -22 -4z"/>
<path d="M6450 10860 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2148 10853 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2337 10853 c-4 -3 -17 -7 -29 -8 -28 -2 -36 -25 -9 -25 12 0 21 5
21 11 0 8 4 8 15 -1 11 -9 18 -9 35 3 11 7 17 17 13 20 -8 9 -38 9 -46 0z"/>
<path d="M3290 10850 c0 -5 7 -10 16 -10 8 0 12 5 9 10 -3 6 -10 10 -16 10 -5
0 -9 -4 -9 -10z"/>
<path d="M4800 10850 c0 -5 6 -10 14 -10 8 0 18 5 21 10 3 6 -3 10 -14 10 -12
0 -21 -4 -21 -10z"/>
<path d="M5075 10850 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>
<path d="M5203 10845 c0 -10 6 -14 14 -10 8 3 22 5 31 5 15 0 15 2 2 10 -23
15 -47 12 -47 -5z"/>
<path d="M3876 10841 c-5 -7 -59 -11 -155 -11 -97 0 -156 -4 -174 -12 -36 -17
-34 -32 3 -24 101 21 94 21 88 -2 -5 -16 -2 -22 11 -22 9 0 22 5 27 10 7 7
101 13 235 17 136 3 239 11 264 18 28 9 33 13 18 14 -13 1 -23 6 -23 11 0 13
-43 13 -57 -1 -11 -11 -173 -8 -209 5 -13 5 -24 3 -28 -3z"/>
<path d="M6368 10843 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2148 10833 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2773 10833 c18 -5 18 -5 0 -19 -16 -12 -15 -13 15 -14 24 0 32 4 32
18 0 13 -8 18 -32 19 -18 1 -25 -1 -15 -4z"/>
<path d="M4236 10825 c-8 -8 -19 -15 -25 -15 -6 0 -11 -4 -11 -10 0 -5 9 -10
20 -10 23 0 70 28 70 41 0 15 -37 10 -54 -6z"/>
<path d="M4549 10832 l-96 -3 5 -24 c2 -14 6 -25 8 -25 2 0 26 9 54 20 62 25
63 25 48 0 -16 -25 -3 -38 22 -22 11 7 26 12 35 12 15 0 40 37 28 42 -5 2 -51
2 -104 0z"/>
<path d="M5275 10830 c4 -6 -11 -10 -37 -10 -64 -1 -23 -20 44 -21 80 -1 82
-1 69 14 -6 8 -8 17 -4 20 3 4 -13 7 -36 7 -26 0 -40 -4 -36 -10z"/>
<path d="M6442 10828 c7 -19 38 -22 38 -4 0 10 -9 16 -21 16 -12 0 -19 -5 -17
-12z"/>
<path d="M1963 10824 c3 -3 2 -17 -3 -30 -12 -30 2 -31 35 -2 33 29 32 38 -7
38 -17 0 -29 -3 -25 -6z"/>
<path d="M2850 10820 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M3330 10817 c-14 -7 -27 -21 -29 -32 -2 -11 -8 -23 -15 -27 -6 -4 4
-8 22 -8 20 0 31 4 27 10 -4 6 5 10 19 10 14 0 26 5 26 10 0 6 29 10 68 10 73
0 71 19 -3 21 -39 1 -39 2 -10 9 25 6 21 8 -25 9 -30 0 -66 -5 -80 -12z"/>
<path d="M4685 10821 c6 -11 -15 -61 -27 -61 -4 0 -8 -7 -8 -15 0 -19 19 -20
26 0 6 13 30 25 64 31 8 1 25 6 38 9 16 5 22 3 22 -8 0 -11 10 -13 46 -9 27 3
55 0 69 -8 27 -14 80 -7 109 15 19 14 18 15 -13 15 -19 0 -30 4 -26 10 4 6
-13 10 -44 10 -28 0 -51 5 -51 10 0 14 -27 13 -32 -1 -2 -7 -17 -11 -33 -10
-100 5 -115 7 -115 14 0 4 -7 7 -16 7 -8 0 -13 -4 -9 -9z m270 -31 c4 -6 -10
-10 -34 -10 -23 0 -41 5 -41 10 0 6 15 10 34 10 19 0 38 -4 41 -10z"/>
<path d="M1790 10799 c0 -20 -33 -43 -45 -31 -14 13 7 -42 33 -86 l25 -44 16
34 c11 23 22 32 33 30 13 -2 16 1 12 12 -4 12 0 16 15 16 23 0 29 -15 9 -23
-7 -3 -3 -6 10 -6 13 -1 22 5 22 13 0 9 13 16 33 19 19 2 7 5 -28 6 -45 1 -56
4 -45 12 11 7 0 9 -40 5 -55 -5 -55 -5 -27 12 33 20 33 24 2 39 -22 10 -25 9
-25 -8z m18 -114 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z"/>
<path d="M2680 10806 c0 -8 -10 -17 -22 -19 -13 -2 -4 -5 20 -6 40 -1 56 -14
30 -24 -7 -2 -9 -8 -5 -13 4 -5 12 -2 17 6 5 8 24 14 44 14 24 0 36 5 36 14 0
9 -7 11 -20 7 -27 -9 -60 3 -60 21 0 8 -9 14 -20 14 -11 0 -20 -6 -20 -14z"/>
<path d="M2960 10811 c0 -17 14 -18 50 -5 l35 13 -42 0 c-24 1 -43 -3 -43 -8z"/>
<path d="M3215 10800 c-4 -6 -31 -10 -61 -9 -40 2 -59 -2 -74 -16 -44 -40 -21
-44 92 -16 87 21 133 51 79 51 -16 0 -33 -5 -36 -10z"/>
<path d="M5048 10803 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M6070 10798 c0 -11 47 -21 66 -14 8 3 12 11 9 16 -7 12 -75 10 -75
-2z"/>
<path d="M2085 10780 c10 -11 31 -20 47 -20 21 0 28 5 28 20 0 17 -7 20 -47
20 -45 0 -46 0 -28 -20z"/>
<path d="M2880 10789 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M2040 10776 c0 -10 8 -16 22 -16 12 0 19 1 17 3 -2 1 -12 9 -21 16
-15 12 -18 11 -18 -3z"/>
<path d="M5016 10715 c-40 -41 -84 -78 -97 -83 -13 -5 -59 -9 -102 -10 l-78
-1 6 29 c7 33 4 35 -25 20 -11 -5 -29 -14 -40 -20 -11 -5 -37 -10 -59 -10 -21
0 -54 -3 -72 -7 -31 -7 -27 -9 31 -14 41 -3 9 -6 -90 -6 -90 0 -140 3 -120 7
19 4 -113 9 -295 10 -181 1 -314 6 -295 10 l35 6 -29 9 c-17 5 -85 3 -160 -4
-72 -7 -144 -14 -161 -15 -42 -4 -124 -16 -160 -24 -25 -6 -21 -8 30 -13 l60
-6 -60 -3 c-45 -3 -30 -5 60 -10 95 -5 69 -6 -120 -7 -133 -1 -229 3 -215 7
17 5 -2 8 -61 9 -51 1 -89 -3 -96 -10 -17 -17 -183 -2 -183 17 0 16 56 20 62
5 2 -5 15 -7 31 -4 22 4 25 8 16 19 -7 9 -21 11 -36 8 -13 -4 -50 -1 -82 6
-31 6 -68 10 -82 8 -19 -4 -15 -6 19 -13 24 -4 39 -11 32 -15 -6 -3 -8 -14 -4
-23 4 -11 1 -17 -8 -17 -7 0 -31 -9 -53 -20 -22 -11 -55 -20 -75 -21 -28 0
-31 -2 -15 -9 15 -6 6 -9 -35 -10 -43 -2 -50 -4 -30 -10 23 -7 22 -8 -11 -9
-19 -1 -39 -5 -45 -10 -5 -6 -31 -11 -59 -12 -27 -1 -80 -13 -117 -26 -37 -13
-73 -23 -80 -23 -7 0 -26 -9 -43 -20 l-30 -20 63 0 c40 0 61 4 57 10 -4 6 10
10 37 9 24 -1 34 -3 23 -6 -11 -2 -29 -8 -40 -12 -49 -21 -103 -32 -130 -28
-26 3 -25 4 10 7 38 2 39 3 10 9 -36 7 -48 41 -15 41 11 0 23 7 26 15 4 8 14
15 24 15 25 0 33 15 25 50 -6 26 -11 30 -41 30 -22 0 -37 -6 -40 -15 -4 -8
-24 -18 -45 -21 l-38 -6 22 -19 c17 -14 19 -21 9 -27 -7 -5 -11 -19 -10 -32 2
-16 -3 -25 -14 -28 -13 -3 -18 3 -18 21 0 36 -10 57 -27 57 -13 0 -14 -3 -1
-22 16 -25 8 -90 -11 -96 -6 -2 -11 -9 -11 -16 0 -12 -31 -46 -41 -46 -3 0 -6
11 -7 25 -1 19 3 25 19 25 11 0 18 4 15 9 -4 5 0 11 6 14 10 4 11 7 0 18 -17
19 -15 59 4 59 8 0 12 5 9 11 -4 6 -14 8 -22 5 -9 -4 -13 -1 -11 6 3 7 22 16
44 19 31 4 35 7 21 14 -15 6 -16 10 -5 16 10 7 8 9 -7 10 -39 2 38 28 83 29
24 0 62 9 84 20 26 12 62 20 95 20 35 0 63 7 85 20 18 11 47 20 65 20 17 0 35
5 38 10 3 6 26 10 51 10 24 0 44 4 44 10 0 5 18 15 40 21 31 9 46 9 66 1 13
-7 37 -11 52 -11 l27 1 -30 8 -30 8 30 1 c21 1 26 4 17 10 -8 4 -13 11 -13 15
4 25 -1 36 -16 36 -14 0 -15 2 -5 9 11 7 11 9 -2 14 -12 5 -16 0 -16 -17 0
-13 -6 -28 -12 -33 -18 -14 -122 -33 -177 -33 -34 0 -51 -5 -64 -20 -17 -20
-30 -24 -80 -20 -15 1 -27 -3 -27 -9 0 -6 -9 -11 -19 -11 -11 0 -22 -4 -25 -8
-3 -5 -53 -15 -110 -22 -58 -7 -104 -16 -102 -19 2 -3 -17 -7 -44 -7 -34 -2
-55 -9 -75 -25 -27 -24 -61 -79 -49 -79 4 0 -3 -8 -14 -19 -17 -14 -22 -31
-23 -67 -1 -27 -3 -41 -6 -31 -4 17 -23 25 -23 9 0 -10 31 -55 44 -64 5 -4 14
-4 18 -2 4 3 6 -1 3 -8 -6 -17 54 -56 114 -73 40 -12 43 -12 27 0 -10 8 -24
16 -30 19 -7 3 -3 11 8 20 17 14 18 16 3 16 -9 0 -17 5 -17 10 0 6 17 10 38
10 27 0 33 -3 22 -10 -11 -7 7 -10 60 -12 l75 -1 -65 -7 -65 -6 48 -13 c26 -7
50 -17 53 -22 3 -5 17 -9 32 -10 24 0 25 -1 7 -9 -19 -8 -19 -9 2 -9 12 -1 23
-5 25 -9 5 -13 58 -24 58 -12 0 5 9 10 20 10 11 0 20 -4 20 -10 0 -5 10 -10
23 -10 18 0 19 -2 7 -10 -11 -7 -5 -10 25 -10 22 0 67 -7 100 -15 33 -8 77
-15 98 -15 21 0 48 -5 59 -11 11 -6 75 -17 142 -25 117 -13 267 -31 466 -54
52 -7 153 -14 225 -16 71 -3 134 -9 140 -14 6 -5 141 -12 300 -16 346 -10 354
-10 475 -1 52 4 231 12 398 18 166 7 302 15 302 19 0 4 46 10 103 14 171 12
190 14 252 25 33 5 112 15 175 21 63 6 131 15 151 21 20 5 50 9 67 9 17 0 34
5 37 10 3 6 23 10 43 10 28 0 34 3 22 11 -12 8 -10 10 10 8 69 -4 100 1 100
17 0 14 7 15 40 10 39 -6 58 8 23 17 -10 2 1 2 24 -1 28 -3 49 1 65 12 12 9
17 16 10 16 -6 0 -12 5 -12 11 0 7 16 8 45 3 41 -6 45 -4 45 14 0 17 -8 22
-37 25 -21 2 -15 2 12 0 28 -3 65 -6 83 -6 17 -1 32 -5 32 -9 0 -16 43 -7 73
14 43 30 51 40 55 63 2 11 7 22 10 25 4 3 8 10 9 17 3 13 -35 29 -44 19 -4 -3
1 -6 10 -6 10 0 17 -4 17 -9 0 -15 -32 -22 -46 -10 -7 6 -18 8 -24 4 -5 -3
-10 -1 -10 4 0 6 -4 11 -10 11 -5 0 -10 -8 -10 -18 0 -11 -9 -27 -20 -37 -11
-10 -18 -21 -15 -25 2 -4 -8 -5 -23 -1 -24 5 -24 6 -5 9 14 2 27 16 36 40 8
20 14 38 12 40 -1 1 -26 -4 -56 -12 -38 -9 -48 -15 -34 -19 11 -2 -7 -5 -40
-6 -40 0 -55 2 -46 8 11 7 4 11 -25 14 -31 3 -27 4 19 5 36 1 56 6 52 12 -3 6
-15 10 -26 10 -11 0 -30 7 -44 16 -13 8 -37 16 -54 16 -17 1 -28 5 -25 10 3 4
-6 8 -19 8 -14 0 -41 7 -62 15 -20 9 -44 15 -53 14 -10 -1 -24 1 -32 4 -17 6
-61 16 -107 24 -18 3 -33 9 -33 13 0 8 -35 16 -135 29 -54 7 -56 8 -18 9 23 1
43 6 45 11 2 4 28 6 59 4 41 -4 64 -12 82 -29 15 -13 36 -24 49 -24 16 0 19 3
10 8 -22 15 -2 42 32 43 28 0 29 1 9 9 -13 5 -23 16 -23 25 0 8 -7 15 -15 15
-8 0 -15 -4 -15 -10 0 -14 -44 -12 -58 2 -10 10 -15 10 -21 -1 -6 -9 -11 -10
-15 -2 -4 6 -19 11 -34 12 l-27 1 25 8 c19 6 13 8 -30 9 -34 1 -50 4 -42 10
10 6 7 10 -12 13 -14 3 -26 1 -26 -4 0 -4 -11 -6 -24 -2 -29 7 -43 -11 -19
-25 16 -9 16 -10 -4 -11 -13 0 -23 -5 -23 -11 0 -7 25 -9 78 -6 l77 6 -85 -13
c-76 -11 -81 -13 -45 -18 25 -3 22 -4 -10 -1 -27 2 -77 8 -110 14 -33 6 -84
13 -113 16 l-54 5 22 23 22 23 -22 12 c-17 9 -25 9 -39 -4 -20 -18 -76 -22
-86 -6 -10 16 -45 12 -45 -5 0 -10 10 -15 30 -15 17 0 30 -4 30 -10 0 -5 11
-10 25 -10 14 0 25 -4 25 -9 0 -5 12 -12 28 -14 15 -2 -79 -4 -208 -4 -178 1
-207 2 -120 7 63 3 91 7 63 8 l-53 2 22 28 c78 95 184 174 196 143 1 -4 19 -5
40 -3 28 2 33 1 22 -7 -11 -8 -2 -11 38 -11 50 0 70 -13 35 -24 -10 -2 29 -5
87 -5 58 0 130 -2 160 -4 30 -2 37 -2 15 0 -28 3 -35 7 -25 14 10 6 -2 9 -37
9 -29 0 -53 4 -53 9 0 5 -37 11 -82 14 -46 2 -117 12 -158 21 -41 9 -83 16
-93 16 -9 0 -50 -34 -91 -75z m371 19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-1724 -101 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2
23 -4z m656 -18 c-7 -7 -690 -7 -697 0 -3 3 154 5 348 5 194 0 351 -2 349 -5z
m1324 -12 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-2025 -10
c-26 -2 -71 -2 -100 0 -29 2 -8 3 47 3 55 0 79 -1 53 -3z m1200 0 c-38 -2 -98
-2 -135 0 -38 2 -7 3 67 3 74 0 105 -1 68 -3z m-2050 -40 c-10 -2 -26 -2 -35
0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m257 -5 c-72 -17 -139 -23 -175 -17 -26
5 -15 7 38 8 40 0 72 5 72 11 0 6 23 9 53 9 l52 -1 -40 -10z m2298 5 c-13 -2
-35 -2 -50 0 -16 2 -5 4 22 4 28 0 40 -2 28 -4z m-2620 -10 c-7 -2 -21 -2 -30
0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m2750 -10 c-24 -2 -62 -2 -85 0 -24 2 -5
4 42 4 47 0 66 -2 43 -4z m-2615 -20 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4 30
0 43 -2 28 -4z m2697 -3 c-4 -6 8 -10 29 -10 20 0 36 -4 36 -10 0 -5 8 -10 18
-10 14 0 15 2 2 10 -13 8 -12 10 3 10 9 0 17 -4 17 -10 0 -5 20 -10 45 -10 25
0 45 -4 45 -10 0 -5 11 -10 25 -10 14 0 33 -4 43 -9 9 -5 42 -16 72 -24 49
-14 51 -15 21 -16 -34 -1 -88 13 -123 30 -10 5 -32 9 -48 9 -18 0 -29 4 -25
10 4 6 -16 10 -54 10 -33 0 -62 4 -65 9 -3 5 -18 11 -33 14 -16 4 -46 10 -68
15 l-40 10 53 1 c33 1 51 -3 47 -9z m-2837 -17 c-16 -2 -28 -9 -28 -14 0 -5
-35 -8 -77 -7 l-78 2 65 4 c47 3 59 6 45 12 -14 6 -1 8 40 8 33 -1 48 -3 33
-5z m-232 -52 c-33 -9 -73 -15 -90 -12 -25 5 -20 7 24 10 41 2 50 5 35 11 -14
6 -3 9 35 8 l55 0 -59 -17z m-128 -28 c-15 -2 -42 -2 -60 0 -18 2 -6 4 27 4
33 0 48 -2 33 -4z m3707 -22 c-3 -6 17 -12 47 -14 50 -3 49 -3 -14 -5 -38 -1
-68 2 -68 7 0 6 -19 12 -42 14 -34 3 -29 4 20 5 38 1 61 -2 57 -7z m-4118 -37
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m110 0 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m4343 -68 c0 -2 -7 -7 -16 -10 -8 -3
-12 -2 -9 4 6 10 25 14 25 6z m-82 -23 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4
22 1 32 -1 23 -4z m-4135 -40 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2
13 -5z m3965 0 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m95 0
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-3958 -23 c-4 -6 13
-10 45 -10 32 0 49 -4 45 -10 -4 -6 8 -10 27 -10 19 0 38 -4 44 -9 5 -5 34
-11 64 -14 30 -3 73 -10 95 -16 39 -10 39 -10 -15 -6 -30 3 -80 9 -110 15 -30
6 -70 13 -87 17 -18 3 -33 9 -33 14 0 5 -22 9 -50 9 -27 0 -50 4 -50 9 0 5
-12 12 -27 14 -20 3 -15 4 15 6 27 0 41 -3 37 -9z m3752 4 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-44 -21 c-7 -2 -19 -2 -25 0 -7 3 -2 5
12 5 14 0 19 -2 13 -5z m-90 -20 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24
-2 18 -5z m-85 -20 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z
m-3061 -40 c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32 -4z m2850 0
c-20 -2 -52 -2 -70 0 -17 2 0 4 38 4 39 0 53 -2 32 -4z m-2749 -20 c-16 -2
-40 -2 -55 0 -16 2 -3 4 27 4 30 0 43 -2 28 -4z m2599 1 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2424 -21 c-18 -2 -48 -2 -65 0 -18 2 -4 4
32 4 36 0 50 -2 33 -4z m2245 0 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27 4 30 0 43
-2 28 -4z m-2095 -20 c-24 -2 -62 -2 -85 0 -24 2 -5 4 42 4 47 0 66 -2 43 -4z
m2010 0 c-18 -2 -48 -2 -65 0 -18 2 -4 4 32 4 36 0 50 -2 33 -4z m-1825 -20
c-26 -2 -71 -2 -100 0 -29 2 -8 3 47 3 55 0 79 -1 53 -3z m220 0 c-10 -2 -26
-2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m1355 0 c-68 -2 -178 -2 -245 0
-68 1 -13 3 122 3 135 0 190 -2 123 -3z m-605 -20 c-179 -2 -476 -2 -660 0
-183 1 -36 2 327 2 363 0 513 -1 333 -2z m460 0 c-15 -2 -42 -2 -60 0 -18 2
-6 4 27 4 33 0 48 -2 33 -4z m115 0 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25
0 35 -2 23 -4z"/>
<path d="M6000 10776 c0 -23 29 -27 40 -6 9 17 7 20 -15 20 -15 0 -25 -6 -25
-14z"/>
<path d="M2573 10765 c0 -18 19 -15 25 3 2 7 -3 12 -10 12 -8 0 -15 -7 -15
-15z"/>
<path d="M3568 10773 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M3855 10764 c-20 -15 -20 -16 4 -12 14 2 26 9 29 16 5 16 -9 15 -33
-4z"/>
<path d="M4380 10769 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M5514 10769 c-3 -6 -22 -9 -43 -6 -20 3 -28 3 -18 1 9 -3 15 -10 12
-15 -4 -5 8 -9 24 -9 17 0 31 5 31 10 0 15 57 12 64 -2 5 -10 7 -10 12 0 3 6
18 12 32 13 24 0 25 1 7 9 -29 12 -113 12 -121 -1z"/>
<path d="M4245 10760 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M2008 10753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2864 10742 c-7 -7 -23 -16 -36 -21 -19 -8 -16 -10 23 -10 25 -1 54
5 64 13 14 10 25 11 42 3 22 -10 53 3 53 22 0 13 -133 7 -146 -7z"/>
<path d="M3738 10753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5698 10753 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5781 10747 c-14 -18 -4 -27 31 -27 15 0 28 -3 28 -7 0 -12 51 -33
79 -33 21 0 23 -2 11 -10 -10 -7 9 -10 63 -10 50 0 76 -4 72 -10 -4 -6 6 -10
24 -10 28 0 30 2 21 25 -9 24 -8 25 30 25 22 0 40 -4 40 -10 0 -14 22 -12 44
5 17 13 3 15 -125 14 -122 0 -145 2 -156 17 -6 9 -17 13 -23 9 -18 -11 -80 4
-80 20 0 19 -44 20 -59 2z"/>
<path d="M2453 10737 c-43 -14 -42 -28 1 -24 19 1 32 6 29 11 -3 4 1 12 8 17
18 11 4 9 -38 -4z"/>
<path d="M6148 10743 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3750 10733 c-78 -7 -103 -14 -92 -25 13 -13 102 -19 102 -7 0 5 11
7 25 3 21 -5 25 -3 25 15 0 12 -6 20 -12 19 -7 0 -29 -3 -48 -5z"/>
<path d="M5168 10733 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5710 10730 c20 -13 50 -13 50 0 0 6 -15 10 -32 10 -25 0 -29 -3 -18
-10z"/>
<path d="M5991 10731 c-12 -8 -9 -11 12 -17 16 -3 31 -4 34 0 4 3 -5 7 -18 7
-21 1 -21 2 6 9 28 7 28 8 5 9 -14 0 -32 -3 -39 -8z"/>
<path d="M2337 10723 c-22 -4 -28 -9 -25 -25 4 -30 23 -33 47 -7 21 23 27 40
14 38 -5 -1 -21 -4 -36 -6z"/>
<path d="M2550 10720 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M2748 10723 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4624 10719 c-3 -6 -1 -16 5 -22 8 -8 11 -5 11 11 0 24 -5 28 -16 11z"/>
<path d="M3340 10710 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M3902 10711 l-23 -6 21 -17 c11 -10 20 -14 20 -10 0 5 12 13 28 18
l27 10 -25 5 c-14 3 -35 3 -48 0z"/>
<path d="M4960 10709 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0
-10 -5 -10 -11z"/>
<path d="M5158 10713 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M2228 10703 c-27 -7 -31 -33 -5 -34 21 0 21 -1 2 -8 -17 -7 -16 -9 9
-15 17 -4 33 -3 39 3 8 8 6 11 -7 11 -9 0 -15 4 -11 9 3 5 -5 12 -17 14 -20 4
-19 4 5 6 15 0 27 6 27 11 0 10 -12 11 -42 3z"/>
<path d="M5633 10698 c1 -7 -11 -15 -28 -19 -24 -6 -19 -7 25 -8 59 0 150 -20
150 -33 0 -5 16 -8 35 -8 37 0 85 -18 85 -32 0 -4 14 -8 31 -8 16 0 28 -4 24
-9 -3 -6 31 -8 87 -4 64 4 90 2 83 -5 -5 -5 -19 -12 -30 -15 -11 -3 -1 -5 22
-6 23 0 43 -6 45 -12 2 -6 22 -13 43 -14 39 -2 135 -28 165 -45 8 -4 14 -15
13 -25 -1 -11 4 -14 15 -10 11 4 9 -1 -6 -15 -18 -17 -33 -21 -65 -18 -23 1
-43 -1 -44 -7 -1 -5 12 -9 28 -7 21 3 38 -3 54 -18 22 -21 24 -21 50 -4 18 12
23 20 16 27 -14 14 -14 27 -1 27 6 0 10 -6 10 -13 0 -7 9 -18 20 -25 33 -21
25 10 -18 65 -43 54 -100 84 -199 104 -35 7 -63 17 -63 21 0 4 -22 8 -50 8
-27 0 -50 5 -50 10 0 6 -26 10 -57 11 -42 0 -52 3 -38 9 15 6 8 9 -27 9 -26 1
-50 6 -53 11 -3 6 -17 10 -31 10 -13 0 -24 4 -24 9 0 4 -37 13 -82 19 -45 6
-84 15 -86 21 -5 15 -53 14 -49 -1z m764 -320 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z"/>
<path d="M2743 10693 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M1826 10661 c-9 -16 -16 -39 -16 -49 0 -23 25 -48 34 -33 4 5 26 13
51 16 31 5 42 11 38 21 -3 8 2 17 11 20 9 3 16 14 16 24 0 15 -8 18 -42 17
-24 -1 -51 3 -60 7 -13 6 -21 0 -32 -23z"/>
<path d="M2720 10671 c0 -5 9 -7 20 -4 11 3 20 7 20 9 0 2 -9 4 -20 4 -11 0
-20 -4 -20 -9z"/>
<path d="M2965 10670 c3 -5 15 -10 26 -10 10 0 19 5 19 10 0 6 -12 10 -26 10
-14 0 -23 -4 -19 -10z"/>
<path d="M3788 10673 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5440 10670 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M4240 10661 c0 -6 -63 -7 -152 -4 -92 3 -167 1 -188 -5 -26 -8 29
-10 220 -10 140 0 244 3 230 8 -23 6 -21 8 15 12 25 3 8 5 -42 6 -46 1 -83 -2
-83 -7z m77 -7 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M4475 10660 c3 -5 -3 -11 -12 -13 -10 -3 4 -5 31 -6 27 0 47 1 45 3
-11 9 -69 24 -64 16z"/>
<path d="M5351 10653 c-16 -18 -2 -37 25 -31 24 4 29 25 7 36 -13 7 -22 6 -32
-5z"/>
<path d="M5643 10653 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5070 10640 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8
0 -15 -4 -15 -10z"/>
<path d="M6130 10641 c0 -5 12 -12 27 -16 15 -3 30 -10 33 -14 3 -5 39 -11 80
-14 41 -4 69 -4 62 -1 -6 3 -10 9 -7 14 9 14 -54 23 -70 10 -10 -8 -15 -9 -15
-2 0 6 -7 9 -15 6 -8 -4 -26 1 -40 10 -27 17 -55 21 -55 7z"/>
<path d="M2013 10629 c-44 -13 -51 -29 -13 -29 18 0 29 -4 25 -10 -3 -5 -1
-10 4 -10 20 0 11 20 -11 30 -18 7 -11 9 32 10 39 0 51 3 40 10 -18 12 -34 11
-77 -1z"/>
<path d="M2880 10630 c0 -5 20 -10 45 -10 25 0 45 5 45 10 0 6 -20 10 -45 10
-25 0 -45 -4 -45 -10z"/>
<path d="M3201 10624 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5723 10633 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2168 10623 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M2548 10612 c-10 -2 -18 -8 -18 -13 0 -5 -12 -10 -27 -10 -16 -1 -1
-5 31 -10 61 -9 90 -3 46 8 l-25 7 25 6 c22 6 23 7 5 11 -11 3 -28 3 -37 1z"/>
<path d="M3025 10610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M5020 10610 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M5468 10603 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6108 10603 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5500 10590 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M2808 10583 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6333 10573 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2445 10560 c-11 -5 -33 -11 -48 -15 -16 -3 -27 -11 -24 -18 4 -14
100 -10 105 4 2 5 22 9 45 10 31 0 37 2 22 8 -36 14 -80 19 -100 11z"/>
<path d="M2305 10550 c4 -6 -9 -11 -32 -11 -30 -2 -32 -3 -10 -6 19 -3 27 -10
27 -24 0 -21 16 -24 40 -9 13 8 13 10 1 10 -11 0 -9 4 5 15 16 13 17 16 4 25
-19 13 -43 13 -35 0z"/>
<path d="M6385 10550 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3
0 -8 -4 -11 -10z"/>
<path d="M2175 10530 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M5890 10514 c0 -12 6 -15 23 -10 12 3 33 6 46 6 35 0 23 6 -26 14
-36 5 -43 3 -43 -10z"/>
<path d="M6088 10513 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M5982 10499 c-13 -5 -20 -13 -17 -19 8 -13 51 -13 77 1 17 9 18 13 7
20 -17 11 -37 10 -67 -2z"/>
<path d="M5898 10483 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6094 10472 c-11 -11 -26 -22 -34 -26 -8 -3 -4 -4 10 -2 14 3 46 8
73 11 48 7 60 18 31 29 -29 11 -60 6 -80 -12z"/>
<path d="M6350 10449 c0 -6 -7 -8 -16 -5 -8 3 -12 2 -9 -4 10 -16 45 -12 45 5
0 8 -4 15 -10 15 -5 0 -10 -5 -10 -11z"/>
<path d="M6120 10428 c0 -10 31 -10 48 0 8 6 2 9 -18 9 -16 0 -30 -4 -30 -9z"/>
<path d="M1940 10390 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5
0 -10 -4 -10 -10z"/>
<path d="M6303 10203 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M2088 10193 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M497 9573 c-10 -10 -7 -2807 3 -3028 8 -176 26 -397 50 -624 21 -193
105 -609 166 -821 106 -372 132 -452 198 -615 151 -368 176 -430 188 -450 6
-11 40 -76 75 -144 36 -69 77 -143 93 -165 15 -23 48 -82 73 -132 25 -49 61
-110 79 -135 19 -24 75 -106 125 -181 252 -376 621 -787 1008 -1119 133 -114
494 -383 610 -455 61 -37 169 -103 240 -147 169 -103 635 -327 714 -343 31 -7
126 30 338 132 65 31 150 71 190 89 39 18 118 61 175 97 56 36 174 109 261
163 87 53 185 118 218 144 33 25 109 82 169 126 313 231 729 635 969 939 47
60 120 153 162 205 41 53 87 116 101 141 14 25 62 96 106 158 45 62 101 153
126 202 25 49 57 108 71 130 46 74 192 354 205 395 7 22 35 92 62 155 122 283
160 390 252 715 125 442 199 852 221 1230 8 132 19 287 24 345 6 64 10 674 11
1553 l0 1447 -3638 0 c-2001 0 -3642 -3 -3645 -7z m520 -270 c3 -16 7 -52 7
-80 1 -29 6 -53 11 -54 25 -3 42 5 76 36 42 37 78 59 86 52 8 -8 -45 -70 -77
-92 -16 -11 -31 -26 -33 -35 -8 -33 4 -40 68 -40 35 0 70 -3 79 -6 21 -8 21
-24 0 -24 -8 0 -13 -4 -10 -9 9 -14 -20 -21 -80 -21 -36 0 -55 -4 -56 -12 0
-7 -2 -17 -2 -23 -2 -13 -18 -15 -36 -4 -13 7 -13 8 1 9 9 0 16 6 16 13 0 6 4
20 8 30 4 10 3 17 -4 17 -7 0 -8 8 -3 23 13 35 4 57 -24 57 -14 0 -27 -7 -30
-15 -4 -8 -12 -15 -20 -15 -16 0 -19 16 -4 25 17 10 11 25 -10 25 -11 0 -20
-4 -20 -10 0 -5 -9 -10 -19 -10 -19 0 -36 -40 -34 -80 1 -46 4 -60 14 -60 5 0
8 4 4 9 -3 5 -2 12 2 14 11 6 48 -8 48 -19 0 -5 4 -15 8 -21 6 -9 2 -13 -12
-13 -14 0 -19 5 -15 15 5 11 2 13 -10 9 -9 -4 -15 -9 -12 -12 3 -2 -9 -18 -27
-34 -42 -39 -106 -82 -112 -76 -6 6 44 78 55 78 12 0 40 54 40 78 0 17 -10 20
-66 25 -102 8 -116 46 -17 47 53 0 93 15 93 35 0 8 -4 15 -9 15 -5 0 -25 16
-45 36 -82 82 -62 102 27 29 80 -65 93 -62 102 27 3 40 9 76 11 80 10 16 24 7
31 -19z m3116 -63 c5 -74 5 -75 31 -73 14 1 33 9 42 18 32 31 84 67 93 63 6
-1 -12 -28 -39 -58 -73 -82 -67 -100 35 -100 30 0 56 -4 59 -8 3 -5 -4 -19
-16 -32 -18 -19 -28 -22 -74 -18 -47 4 -55 2 -60 -16 -5 -13 0 -25 14 -36 23
-19 72 -88 72 -101 0 -5 -17 1 -37 12 -40 22 -75 58 -67 71 9 14 -14 17 -33 3
-15 -10 -22 -30 -26 -72 -6 -55 -28 -90 -48 -77 -12 7 -11 144 0 144 5 0 11
-7 15 -15 3 -8 1 -15 -4 -15 -6 0 -10 -7 -10 -16 0 -14 2 -14 15 0 10 10 13
24 9 41 -4 15 -2 25 5 25 6 0 11 -6 11 -12 0 -7 7 -2 16 10 8 12 18 20 22 18
4 -2 8 7 8 20 1 13 -2 21 -7 18 -5 -3 -9 14 -9 37 0 29 -7 48 -22 65 -32 33
-40 30 -63 -18 l-20 -43 -3 38 c-2 28 -8 37 -22 37 -26 0 -110 85 -110 112 0
15 59 -24 95 -64 56 -59 85 -35 85 71 0 37 18 67 31 54 4 -5 10 -42 12 -83z
m3168 59 c4 -13 7 -50 8 -81 1 -51 4 -58 21 -58 10 0 34 13 52 28 110 94 148
95 56 1 -37 -37 -53 -61 -53 -80 -1 -15 4 -25 9 -22 15 9 120 3 130 -8 6 -5 2
-16 -12 -29 -16 -15 -35 -20 -76 -20 -72 0 -77 -20 -19 -78 75 -76 79 -112 6
-56 -71 54 -83 87 -53 145 17 30 -8 49 -56 43 -33 -4 -35 -3 -16 6 24 10 22
30 -4 30 -17 0 -19 26 -1 33 6 3 -6 6 -28 7 -24 1 -34 4 -24 8 12 5 16 24 18
77 1 38 5 73 8 78 9 14 27 2 34 -24z m-175 -51 c16 -13 35 -28 42 -35 7 -7 20
-17 29 -22 14 -7 15 -14 6 -33 -10 -23 -11 -22 -67 33 -52 52 -68 79 -47 79 4
0 21 -10 37 -22z m-6171 -128 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11
10 16 10 6 0 7 -4 4 -10z m3067 -13 c-6 -7 -9 -22 -7 -33 2 -10 6 -31 10 -46
6 -29 25 -39 25 -13 0 8 -4 15 -10 15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0
10 -7 10 -16 0 -10 6 -14 14 -11 10 4 13 -3 12 -26 -1 -25 -7 -33 -23 -35 -12
-2 -33 -15 -45 -30 -29 -34 -87 -76 -95 -68 -10 10 26 55 62 79 25 16 35 29
35 48 0 30 -23 54 -44 46 -17 -7 -96 11 -114 25 -20 17 4 28 68 28 30 0 66 6
80 13 29 16 38 13 22 -6z m3170 -22 l-5 -45 37 0 c38 0 45 -10 23 -32 -8 -7
-12 -18 -10 -24 4 -11 -112 -99 -147 -112 -22 -9 11 39 61 87 57 56 51 89 -17
88 -45 0 -114 20 -114 33 0 5 21 12 48 15 105 14 122 17 122 26 0 5 2 9 4 9 2
0 1 -20 -2 -45z m-3068 -12 c3 -21 7 -47 9 -58 6 -35 -5 -38 -24 -6 -26 43
-25 101 2 101 4 0 10 -17 13 -37z m-3164 -3 c0 -21 -5 -30 -16 -30 -10 0 -12
4 -7 13 8 12 9 17 12 37 3 23 11 8 11 -20z m3120 15 c0 -8 -4 -15 -10 -15 -5
0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m3140 -15 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m114 -44 c3 -13 9 -31 12
-40 4 -10 1 -16 -9 -16 -22 0 -28 -13 -37 -87 -10 -82 -25 -99 -39 -47 -13 48
-14 124 -2 124 5 0 7 10 4 23 -4 19 -3 20 7 5 17 -28 27 -21 34 22 7 45 21 52
30 16z m-6292 -55 c-8 -4 -18 -41 -23 -82 -5 -40 -13 -77 -19 -83 -14 -14 -30
37 -30 100 0 47 3 54 19 54 11 0 23 5 26 10 3 6 14 10 23 10 13 0 14 -2 4 -9z
m106 -44 c76 -78 39 -80 -55 -3 -26 22 -31 31 -21 37 23 15 33 10 76 -34z
m5687 -289 c2 -7 1 -74 -4 -148 l-8 -135 -1 137 c-1 79 -6 139 -12 143 -5 3
-10 1 -10 -6 0 -10 -3 -10 -12 -1 -9 9 -618 12 -2681 12 l-2668 0 4 -1022 c3
-689 8 -1039 15 -1073 6 -27 16 -119 21 -203 6 -85 14 -159 20 -165 5 -7 12
-55 16 -107 4 -52 11 -111 17 -130 6 -19 12 -44 14 -55 7 -59 26 -157 39 -208
8 -32 15 -68 15 -80 0 -12 7 -41 15 -65 9 -24 13 -48 10 -53 -4 -5 -3 -19 0
-30 6 -17 9 -19 16 -8 6 9 7 4 3 -15 -5 -22 -4 -27 6 -21 9 6 10 3 1 -13 -7
-12 -7 -23 -2 -26 5 -4 12 0 14 6 4 8 6 7 6 -4 1 -9 -3 -20 -9 -23 -5 -3 -10
-12 -10 -18 0 -8 3 -8 9 2 6 9 11 10 15 2 4 -6 3 -11 -2 -11 -11 0 35 -164 47
-168 5 -2 7 -12 4 -22 -6 -22 12 -28 20 -7 2 6 8 9 12 5 4 -4 0 -14 -8 -22
-14 -14 -14 -20 1 -58 9 -24 20 -44 24 -46 5 -2 8 -15 8 -28 0 -28 16 -32 24
-6 3 9 3 -3 1 -28 -4 -32 -2 -41 5 -30 7 11 10 7 10 -20 0 -28 3 -32 11 -20 9
12 10 11 6 -7 -3 -13 -1 -23 3 -23 4 0 6 -9 3 -20 -4 -14 0 -20 12 -20 12 0
15 -5 11 -16 -9 -24 3 -37 22 -22 13 12 14 10 4 -9 -15 -27 -16 -73 -2 -73 6
0 10 -6 9 -12 -3 -26 1 -39 11 -33 6 4 8 12 4 18 -3 7 2 3 11 -8 9 -12 14 -29
10 -42 -3 -13 -1 -31 4 -40 5 -10 12 -26 15 -36 3 -10 10 -15 16 -12 5 3 7 0
4 -9 -3 -8 4 -23 15 -33 12 -9 22 -29 22 -43 1 -17 3 -19 6 -7 9 34 21 16 20
-28 -2 -37 3 -49 25 -70 15 -14 34 -25 42 -25 22 0 29 -18 10 -28 -14 -8 -14
-12 -3 -30 8 -12 19 -22 25 -22 6 0 11 -13 12 -30 1 -40 9 -50 38 -50 27 0 31
-12 10 -32 -11 -11 -11 -17 -1 -31 7 -9 20 -17 29 -17 9 0 16 -8 16 -17 0 -10
9 -29 20 -43 11 -14 20 -30 20 -37 0 -30 23 -60 40 -56 22 6 49 -35 31 -46 -7
-5 -4 -14 9 -29 11 -11 20 -27 21 -34 0 -7 4 -4 9 7 10 23 13 5 4 -20 -6 -14
-8 -14 -25 1 -11 9 -19 22 -19 27 0 6 -7 19 -17 29 -9 11 -11 18 -5 18 7 0 12
5 12 11 0 6 -7 8 -16 5 -8 -3 -12 -2 -9 3 3 5 -3 12 -13 15 -9 3 -26 23 -37
43 -11 21 -23 40 -26 43 -18 15 -111 170 -125 209 -10 25 -24 54 -32 63 -8 9
-11 23 -6 30 4 7 4 10 -1 6 -5 -5 -15 2 -23 14 -29 41 -165 323 -184 383 -11
33 -37 101 -58 150 -87 206 -182 518 -226 745 -14 74 -30 153 -35 175 -8 41
-50 293 -69 420 -18 122 -42 413 -50 615 -9 234 -12 1805 -4 1838 l6 22 2699
0 c2151 0 2700 -3 2704 -12z m-3887 -305 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17
5 19 0 27 -2 18 -5z m2427 -2 c11 -5 20 -14 20 -22 0 -9 9 -13 24 -11 13 2 21
-1 17 -6 -3 -5 3 -17 14 -27 11 -10 20 -27 20 -37 0 -10 6 -21 13 -23 8 -4 13
-41 15 -123 2 -64 8 -118 13 -120 5 -2 9 -10 9 -17 0 -8 10 -15 22 -17 12 -2
28 -14 35 -28 7 -14 19 -24 26 -22 8 1 14 -5 14 -13 0 -11 -7 -14 -21 -9 -12
3 -16 2 -11 -3 6 -4 11 -16 12 -25 3 -59 3 -59 16 -46 8 6 12 22 9 34 -5 26
16 43 29 23 6 -10 9 -11 9 -1 0 6 0 13 -1 15 0 1 11 24 24 52 19 36 22 52 14
57 -8 5 -7 8 5 8 9 0 20 10 23 23 18 59 38 87 60 87 28 0 58 -27 51 -45 -3 -9
2 -11 15 -8 18 5 20 2 14 -30 -4 -20 -2 -51 3 -69 6 -18 13 -54 17 -80 5 -41
4 -47 -9 -42 -11 4 -16 0 -16 -10 0 -9 -13 -43 -30 -76 -16 -33 -30 -62 -30
-65 0 -8 24 -1 32 10 5 6 8 5 8 -3 0 -7 9 -22 20 -34 11 -12 20 -30 20 -40 0
-10 8 -18 18 -19 10 0 12 -3 5 -6 -7 -2 -13 -16 -13 -29 0 -24 1 -24 21 -10
12 8 28 12 35 9 8 -3 14 0 14 6 0 6 -6 11 -12 12 -8 0 -8 3 2 7 25 12 60 46
60 59 0 7 6 13 13 13 15 0 42 48 38 69 -1 7 2 10 8 7 5 -4 12 -2 16 4 11 17
61 11 80 -10 11 -12 15 -28 12 -41 -3 -12 -2 -32 3 -45 7 -17 5 -26 -5 -32 -9
-4 -16 -25 -17 -49 -1 -23 -5 -40 -10 -37 -4 3 -8 -4 -8 -15 0 -11 -9 -25 -20
-31 -10 -6 -17 -16 -14 -24 3 -7 -2 -16 -10 -19 -10 -4 -16 -19 -16 -39 0 -25
-3 -29 -10 -18 -8 13 -10 13 -10 -2 0 -9 5 -20 11 -24 7 -4 9 -22 6 -45 -5
-36 -3 -39 19 -39 30 0 32 -18 2 -22 -26 -3 -28 -18 -5 -32 19 -12 73 5 70 22
-2 7 4 9 14 5 11 -4 14 -3 9 5 -4 7 -2 10 6 7 16 -5 42 14 51 38 3 9 16 17 27
17 11 0 20 4 20 9 0 19 44 19 68 0 15 -11 19 -19 11 -19 -12 0 -12 -2 0 -9 7
-5 11 -14 7 -20 -5 -7 -1 -9 8 -5 18 7 20 -13 6 -56 -7 -23 -8 -23 -9 -3 -1
12 -5 24 -10 27 -4 3 -6 -5 -4 -19 4 -16 -2 -29 -16 -41 -12 -10 -21 -27 -21
-38 0 -11 -3 -16 -7 -13 -9 9 -34 -13 -27 -24 3 -5 -3 -6 -12 -2 -13 5 -16 3
-11 -10 3 -8 1 -18 -6 -21 -9 -3 -8 -7 3 -15 12 -8 8 -10 -15 -8 -24 2 -29 -2
-27 -15 2 -12 -4 -18 -16 -18 -28 0 -41 -20 -48 -74 -5 -32 -10 -43 -15 -34
-7 11 -11 10 -19 -2 -17 -25 -11 -42 25 -76 36 -36 55 -43 55 -21 0 8 9 22 20
32 18 16 20 16 20 2 0 -13 9 -17 33 -16 30 1 31 1 7 9 -23 7 -23 8 -3 9 24 1
39 16 23 24 -6 3 -5 4 2 3 7 -2 26 1 43 5 45 13 63 3 70 -41 4 -22 8 -40 10
-40 24 0 33 15 29 48 l-4 37 9 -35 c9 -33 7 -37 -34 -77 -24 -22 -41 -44 -38
-47 4 -3 -1 -6 -10 -6 -22 0 -58 -31 -50 -43 4 -6 0 -8 -8 -5 -15 6 -42 -16
-32 -26 4 -3 -6 -6 -22 -6 -15 0 -54 -13 -87 -30 -32 -16 -68 -30 -78 -30 -41
0 -55 -19 -51 -66 1 -24 7 -44 12 -44 5 0 9 -7 9 -15 0 -12 -7 -14 -26 -9
l-26 7 17 -27 c10 -14 26 -26 36 -26 10 0 21 -4 24 -10 10 -16 104 -12 122 5
19 19 82 11 101 -14 15 -18 10 -77 -8 -95 -6 -6 -11 -6 -10 -1 6 28 0 36 -15
21 -8 -8 -15 -18 -15 -23 0 -16 -125 -65 -171 -67 -26 0 -42 -4 -36 -8 7 -4
-4 -8 -23 -9 -88 -3 -165 -7 -170 -8 -3 -1 1 -5 7 -9 17 -11 -34 -82 -57 -82
-10 0 -20 -9 -23 -20 -4 -15 0 -20 15 -20 10 0 16 -4 13 -10 -4 -6 8 -10 29
-10 20 0 36 -5 36 -10 0 -6 10 -8 23 -5 15 4 28 1 36 -9 8 -9 26 -16 42 -16
15 0 39 -4 53 -9 17 -7 26 -7 30 2 2 7 5 -7 5 -30 1 -24 -4 -43 -10 -43 -5 0
-8 -4 -5 -9 3 -5 -4 -11 -15 -14 -12 -3 -18 -10 -15 -15 3 -6 -2 -8 -14 -5
-13 4 -20 0 -20 -10 0 -16 -20 -27 -48 -27 -15 0 -15 2 -2 10 12 8 11 10 -7
10 -12 0 -25 -4 -28 -10 -3 -5 -24 -10 -46 -10 -27 0 -38 4 -33 11 4 7 -2 7
-17 -1 -13 -7 -20 -9 -16 -4 7 8 -99 31 -148 33 -15 1 -17 -1 -6 -8 8 -5 10
-14 6 -21 -6 -10 -10 -9 -17 4 -12 21 -20 20 -49 -5 -13 -11 -39 -27 -56 -34
-18 -8 -33 -18 -33 -24 0 -5 15 -11 33 -13 22 -2 32 -8 32 -20 0 -10 6 -23 14
-30 10 -10 12 -10 7 0 -5 7 -1 12 9 12 11 0 15 -5 12 -14 -4 -9 2 -17 13 -21
11 -3 29 -18 40 -32 11 -14 20 -21 20 -14 0 16 17 13 24 -5 3 -8 2 -12 -4 -9
-6 3 -10 1 -10 -5 0 -6 7 -13 15 -16 9 -4 15 -19 15 -36 0 -23 -4 -29 -15 -24
-10 3 -15 -1 -15 -11 0 -9 -3 -13 -7 -10 -3 4 -11 3 -17 -2 -12 -9 -171 8
-161 17 7 7 -56 36 -73 33 -7 -1 -21 7 -30 19 -9 11 -27 22 -39 23 -12 0 -25
7 -27 14 -3 7 -12 13 -21 13 -8 0 -15 5 -15 11 0 5 -5 7 -10 4 -6 -4 -17 -1
-25 5 -11 9 -18 7 -32 -9 -11 -13 -29 -21 -50 -21 -37 0 -43 -14 -14 -30 15
-7 18 -17 15 -37 -6 -32 -14 -44 -14 -20 0 10 -7 17 -15 17 -9 0 -15 -9 -15
-21 0 -18 5 -21 30 -17 20 3 30 0 30 -8 0 -8 -6 -11 -15 -8 -8 4 -15 1 -15 -5
0 -6 6 -11 14 -11 8 0 16 -7 20 -15 3 -8 16 -15 30 -15 14 0 28 -4 31 -10 3
-6 -4 -10 -17 -10 l-23 0 22 -18 c19 -15 22 -30 14 -74 0 -5 -12 -8 -25 -8
-13 0 -26 -6 -29 -14 -8 -20 -42 -14 -71 11 -14 13 -26 22 -26 20 0 -1 9 -24
19 -52 12 -30 25 -50 34 -49 9 1 13 -5 10 -17 -3 -11 -1 -19 5 -19 18 0 42
-67 42 -118 0 -63 7 -82 31 -82 10 0 19 -4 19 -10 0 -6 -9 -6 -26 0 -32 12
-34 12 -34 0 0 -5 7 -10 15 -10 8 0 15 -4 15 -10 0 -5 -4 -10 -10 -10 -21 0
-9 -18 17 -25 41 -10 53 -24 53 -61 0 -24 -5 -34 -15 -34 -8 0 -15 -7 -15 -15
0 -10 -11 -15 -35 -16 -19 -1 -39 -3 -45 -4 -5 -1 -21 -3 -35 -4 -32 -1 -34
-31 -2 -31 13 0 17 -3 10 -8 -6 -4 -11 -31 -11 -63 1 -69 -8 -99 -30 -99 -13
0 -17 -9 -17 -41 0 -35 5 -43 24 -51 13 -4 33 -6 44 -2 15 5 19 3 14 -9 -4
-11 -2 -14 8 -10 8 3 17 -2 21 -10 4 -12 23 -14 102 -12 179 6 240 23 182 49
-8 3 -4 4 9 2 35 -7 81 4 81 20 0 9 10 14 28 13 47 -2 77 -14 76 -32 -1 -9 2
-14 7 -11 5 3 9 -2 9 -11 0 -9 5 -13 10 -10 6 3 10 -1 10 -9 0 -9 5 -16 10
-16 6 0 10 -9 10 -19 0 -23 55 -80 81 -84 22 -4 38 -17 20 -17 -7 0 -9 -5 -6
-10 4 -6 11 -8 16 -5 5 4 9 -3 9 -14 0 -11 5 -23 10 -26 6 -3 10 -31 10 -61 0
-36 4 -54 13 -55 8 0 7 -3 -2 -6 -8 -3 -25 -18 -38 -34 -21 -25 -26 -26 -56
-16 -17 7 -38 18 -45 25 -7 6 -21 13 -30 14 -49 4 -72 3 -72 -4 0 -4 -6 -8
-14 -8 -8 0 -17 -8 -21 -19 -15 -49 -85 -66 -85 -21 0 11 -10 20 -25 24 -14 4
-23 11 -20 16 3 5 -5 20 -17 32 -13 13 -22 28 -20 34 1 7 -4 14 -13 18 -8 3
-15 17 -15 31 l0 26 -82 -3 c-72 -3 -83 -5 -82 -20 1 -10 -4 -18 -12 -18 -8 0
-14 -9 -14 -20 0 -11 5 -20 10 -20 6 0 10 -5 10 -12 0 -9 -3 -9 -12 0 -7 7
-15 12 -19 12 -11 0 -18 -39 -7 -42 14 -5 -2 -38 -19 -38 -8 0 -12 4 -9 8 2 4
-6 8 -18 8 -24 0 -24 0 -30 -62 -5 -54 -11 -64 -45 -64 -20 0 -39 12 -69 46
-23 25 -42 52 -42 60 0 8 -7 28 -15 45 -9 16 -15 45 -13 64 2 28 6 35 23 35
14 0 16 3 7 8 -9 7 -8 13 7 26 11 10 24 15 30 12 5 -4 12 -2 16 4 3 5 0 10 -7
11 -7 0 -3 4 10 10 13 5 20 13 17 19 -3 5 -1 10 4 10 6 0 11 5 11 11 0 5 -4 7
-10 4 -6 -3 -7 1 -4 9 4 12 0 16 -16 16 -14 0 -19 -4 -15 -12 4 -7 -3 -2 -14
12 -11 14 -21 28 -21 33 0 4 -7 7 -16 7 -8 0 -13 4 -10 9 3 5 -20 8 -52 6 -43
-1 -66 3 -91 18 -19 12 -36 17 -38 11 -4 -11 -104 -6 -117 7 -13 12 -46 11
-46 -1 0 -23 -18 -8 -23 20 -7 33 -24 40 -33 13 -15 -46 -16 -48 -21 -35 -3 6
-9 9 -14 6 -6 -3 -5 -12 2 -21 10 -12 10 -19 0 -35 -7 -11 -8 -17 -2 -13 6 3
11 -1 11 -9 0 -9 7 -16 15 -16 17 0 19 9 5 30 -5 9 -5 19 2 27 13 16 28 7 28
-16 0 -10 7 -24 15 -31 14 -11 13 -14 -2 -25 -16 -12 -16 -14 0 -38 19 -29 23
-87 6 -87 -5 0 -8 -4 -4 -10 3 -5 -16 -12 -44 -16 -28 -4 -56 -13 -62 -20 -6
-8 -18 -14 -25 -14 -8 0 -14 -2 -14 -4 0 -2 -3 -12 -6 -20 -5 -12 -2 -15 10
-10 11 4 19 -2 25 -19 11 -27 29 -43 38 -33 4 3 -3 16 -15 29 -28 30 -29 57
-2 57 18 0 19 -3 10 -20 -9 -17 -7 -22 15 -32 14 -6 25 -18 25 -25 0 -8 -4
-12 -9 -9 -5 3 -11 0 -14 -8 -6 -16 56 -80 68 -69 4 5 2 -2 -4 -15 -10 -18 -9
-27 4 -44 20 -29 20 -136 0 -164 -8 -12 -12 -24 -8 -27 3 -3 -2 -14 -11 -23
-10 -9 -15 -23 -12 -31 3 -8 2 -12 -2 -9 -5 3 -23 -8 -40 -24 -22 -20 -38 -27
-53 -23 -15 4 -20 3 -15 -5 4 -7 1 -12 -8 -12 -9 0 -16 -6 -17 -12 0 -10 -2
-10 -6 0 -2 6 -13 12 -23 12 -12 0 -19 9 -22 30 -2 16 0 31 5 33 4 1 11 31 14
65 6 66 -9 112 -50 154 -10 10 -15 23 -12 28 4 6 2 10 -4 10 -5 0 -13 -5 -16
-10 -3 -6 -1 -10 4 -10 6 0 11 -4 11 -10 0 -5 -6 -10 -14 -10 -14 0 -15 28 -2
48 3 5 -2 9 -11 9 -9 0 -18 -10 -21 -21 -6 -20 -6 -20 -13 4 -4 15 -6 -1 -6
-40 3 -154 -6 -459 -12 -456 -7 5 -8 0 -15 -72 -3 -24 -10 -50 -16 -57 -6 -8
-12 -34 -13 -58 -1 -25 -5 -43 -9 -40 -5 2 -8 -8 -8 -23 0 -18 -12 -37 -37
-60 -20 -19 -41 -34 -47 -34 -16 0 -66 63 -66 84 0 21 -21 88 -31 96 -8 7 -27
86 -34 144 -4 27 -6 131 -6 232 0 114 -3 184 -9 184 -6 0 -10 20 -10 45 0 46
-14 61 -23 24 -4 -17 -11 -20 -36 -16 -19 3 -29 1 -25 -4 3 -6 -1 -18 -9 -27
-20 -23 -35 -93 -26 -117 12 -32 10 -113 -3 -122 -7 -4 -24 -6 -37 -4 -15 2
-21 -1 -17 -8 5 -7 -4 -11 -26 -10 -25 1 -28 3 -13 7 11 4 21 12 22 19 3 14
-32 27 -47 18 -5 -3 -15 5 -21 19 -7 14 -15 23 -20 21 -4 -3 -11 0 -15 6 -4 8
-3 9 4 5 7 -4 12 -2 12 4 0 6 -6 13 -14 16 -21 8 -39 98 -33 164 6 65 26 110
71 155 41 42 56 62 56 74 0 5 8 16 18 23 10 7 21 21 25 31 5 15 0 17 -42 17
-26 0 -51 3 -55 7 -4 5 -24 13 -44 18 -39 11 -54 43 -33 69 6 8 9 22 5 32 -4
12 -3 15 3 7 15 -20 41 8 37 42 -2 20 0 25 6 16 7 -12 11 -12 25 2 9 9 13 21
10 27 -3 6 3 14 15 18 l21 8 -20 26 c-29 36 -26 48 9 48 17 0 30 5 30 10 0 6
-18 10 -40 10 -22 0 -40 -4 -40 -10 0 -5 -8 -10 -17 -10 -12 0 -14 -3 -6 -8
22 -14 -1 -27 -50 -29 -26 -1 -54 -7 -62 -13 -9 -7 -20 -8 -30 -3 -16 9 -81 2
-145 -15 -38 -11 -33 -15 15 -13 l30 0 -29 -9 c-17 -5 -36 -5 -46 0 -9 5 -22
5 -31 -1 -7 -5 -27 -9 -42 -9 -16 0 -26 -4 -22 -10 4 -6 -6 -11 -22 -11 -27
-2 -27 -2 -3 -6 34 -6 47 -20 32 -35 -9 -9 -15 -9 -24 0 -9 9 -9 12 2 12 7 0
11 3 7 6 -11 11 -86 -38 -80 -53 4 -11 8 -12 13 -3 9 15 80 -7 80 -25 0 -5 13
-19 29 -30 16 -10 27 -23 24 -28 -2 -4 -12 -2 -21 5 -14 11 -15 10 -8 -7 14
-33 14 -59 1 -80 -6 -11 -15 -32 -19 -47 -3 -16 -11 -28 -16 -28 -6 0 -10 -5
-10 -12 0 -21 -63 -78 -86 -78 -27 0 -46 22 -38 44 10 26 -16 66 -43 66 -13 0
-23 5 -23 11 0 5 -5 7 -12 3 -7 -4 -8 -3 -4 4 4 6 14 8 24 5 15 -6 15 -5 3 10
-7 9 -18 14 -24 10 -5 -3 -7 -1 -3 5 3 6 -1 17 -9 26 -13 12 -13 17 -2 27 10
10 11 18 3 36 -10 21 -17 23 -104 23 l-92 0 -6 -30 c-4 -16 -11 -30 -16 -30
-6 0 -7 -7 -4 -15 3 -10 -1 -18 -12 -22 -9 -4 -28 -19 -41 -35 -13 -15 -29
-28 -36 -28 -6 0 -17 -11 -23 -25 -6 -14 -16 -25 -22 -25 -12 0 -50 36 -50 48
0 18 -23 32 -36 21 -9 -7 -16 -5 -23 10 -7 12 -17 18 -26 15 -8 -4 -12 -11 -8
-17 4 -6 -2 -8 -16 -4 -16 4 -21 2 -18 -7 8 -19 -37 -38 -52 -23 -9 9 -15 8
-22 -5 -15 -26 -41 -23 -38 5 1 12 -2 39 -8 59 -8 28 -8 53 2 100 7 35 11 70
8 78 -3 11 -1 11 10 -2 16 -20 27 -23 27 -8 0 17 33 47 55 51 12 3 28 24 44
64 14 33 30 65 36 71 22 23 78 22 130 -1 28 -12 57 -25 65 -29 8 -3 4 -4 -10
-2 l-25 5 25 -19 c28 -22 90 -26 108 -8 9 9 12 9 12 -1 0 -11 17 -12 78 -7 85
8 165 33 155 49 -4 6 4 7 21 3 19 -5 26 -3 21 4 -4 6 -2 17 4 23 11 14 4 57
-9 57 -5 0 -13 14 -19 31 -8 24 -7 35 5 55 9 13 14 33 11 44 -3 11 -2 18 2 15
4 -2 8 9 8 26 0 16 -4 25 -8 18 -4 -8 -9 -5 -13 8 -6 19 -45 23 -91 8 -25 -8
-54 13 -60 43 -2 12 -7 27 -10 32 -7 12 19 53 28 43 4 -3 7 0 7 8 0 7 15 26
33 41 17 15 27 28 21 28 -18 0 -7 114 15 164 10 24 28 53 39 64 11 11 22 28
24 39 2 10 15 20 33 24 17 4 22 7 13 8 -22 1 -24 21 -3 21 24 0 17 15 -7 17
-27 2 -70 -27 -50 -34 6 -3 10 -9 6 -14 -3 -5 -12 -2 -21 6 -10 11 -25 14 -44
10 -30 -7 -69 21 -69 51 0 18 23 44 40 44 6 0 8 5 5 10 -3 6 -1 10 5 10 6 0 8
5 5 10 -4 6 -11 7 -17 4 -6 -4 -8 -1 -6 7 3 8 11 13 17 11 19 -4 51 17 51 33
0 8 9 15 20 15 11 0 20 7 20 16 0 8 4 13 8 10 9 -5 42 55 42 75 0 6 -7 9 -15
6 -8 -4 -17 -2 -20 3 -7 11 -82 -26 -102 -50 -11 -13 -12 -13 -13 1 0 9 -7 20
-16 23 -9 4 -14 13 -10 21 3 9 -1 15 -10 15 -9 0 -18 -9 -21 -20 -6 -23 -14
-25 -32 -7 -10 10 -15 9 -25 -5 -7 -10 -16 -14 -20 -10 -4 4 -12 -1 -17 -11
-6 -9 -16 -17 -23 -17 -7 0 -28 -8 -47 -17 -18 -9 -48 -17 -65 -17 -18 -1 -30
-4 -27 -8 2 -5 -35 -8 -84 -8 -48 0 -83 4 -76 8 8 5 9 13 3 22 -17 27 -11 60
13 70 12 5 16 9 10 9 -18 1 -16 18 3 25 10 4 13 2 8 -6 -4 -7 7 -2 24 11 18
12 32 27 32 32 0 5 4 9 9 9 5 0 22 5 36 10 23 9 25 13 15 25 -11 13 -10 14 2
10 9 -4 18 1 22 10 3 9 11 13 16 10 11 -7 40 11 40 25 0 10 -33 9 -46 -1 -7
-6 -16 -3 -24 6 -7 8 -22 15 -35 15 -29 0 -37 10 -23 27 9 11 9 17 -3 24 -19
12 -25 11 -42 -5 -11 -11 -48 -15 -148 -17 -74 -2 -140 -7 -146 -12 -8 -6 -13
-6 -13 0 0 5 -18 9 -40 10 -27 1 -38 -2 -34 -9 4 -7 3 -8 -4 -4 -7 4 -12 13
-12 20 0 7 -8 20 -19 30 -10 9 -17 28 -16 44 5 66 4 65 19 53 10 -8 16 -9 21
-1 3 5 17 10 31 10 13 0 32 4 42 9 35 17 85 30 95 24 6 -4 7 -1 2 7 -6 10 -3
12 13 8 12 -4 22 -2 22 4 0 6 9 8 19 5 14 -3 18 -1 14 9 -5 13 -20 18 -50 17
-7 -1 -14 7 -15 16 -1 9 -2 23 -4 31 -1 8 -2 21 -3 27 -1 7 -19 16 -41 19 -22
4 -40 10 -40 15 0 5 -29 9 -64 9 -36 0 -68 5 -71 10 -3 6 -16 10 -28 10 -22 0
-111 29 -93 30 6 0 1 6 -10 14 -14 11 -21 11 -26 3 -10 -15 -30 12 -21 27 3 5
1 6 -5 2 -13 -8 -45 54 -40 81 4 25 31 45 40 30 4 -7 27 -12 52 -12 25 0 39
-3 33 -8 -7 -5 6 -5 28 -2 22 3 67 9 100 12 l59 5 -34 15 c-33 14 -34 15 -15
26 24 15 26 55 2 68 -9 6 -17 20 -17 32 0 14 -11 28 -31 39 -16 10 -27 13 -24
8 11 -17 -11 -11 -35 10 -13 11 -33 20 -45 20 -21 0 -169 70 -150 70 6 0 2 5
-7 11 -10 5 -18 16 -18 24 0 8 -14 28 -30 44 -21 20 -27 31 -18 34 10 4 10 8
-2 16 -10 7 -11 11 -2 11 6 0 12 6 12 13 0 6 9 23 20 37 l20 25 0 -24 c0 -15
5 -21 14 -18 7 3 30 2 50 -3 20 -4 39 -4 42 1 9 15 31 10 31 -6 0 -8 -8 -15
-18 -15 -10 0 -21 -4 -24 -10 -4 -6 12 -10 39 -10 35 0 46 4 46 16 0 8 5 12
11 8 6 -3 9 -15 6 -25 -7 -25 17 -25 43 1 42 42 35 184 -7 148 -11 -9 -17 -8
-29 5 -8 9 -9 12 -2 7 18 -15 25 -12 23 11 -2 28 -45 55 -45 28 0 -5 4 -8 9
-4 5 3 12 1 16 -5 13 -21 -22 -9 -43 15 -23 27 -29 40 -12 30 6 -3 7 1 4 9 -3
9 -18 16 -33 17 -14 0 -20 3 -13 6 10 4 8 9 -7 20 -12 8 -19 19 -16 24 4 5 -3
9 -14 9 -15 0 -19 4 -14 17 5 12 3 14 -7 8 -8 -5 -11 -4 -7 3 4 5 -5 33 -20
61 -23 45 -25 54 -13 76 7 13 17 22 22 19 4 -3 8 0 8 5 0 6 -4 11 -10 11 -5 0
-10 5 -10 10 0 6 7 10 15 10 8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15 -7 15
-15 0 -8 4 -15 10 -15 5 0 7 8 4 18 -5 13 -2 12 13 -4 10 -12 23 -30 30 -40
10 -18 11 -18 17 -1 18 53 18 53 26 27 3 -14 2 -28 -4 -32 -13 -9 26 -10 76
-2 27 5 38 12 42 30 4 13 18 30 33 37 l27 14 -22 12 c-12 6 -22 20 -22 31 0
23 -28 52 -42 44 -7 -4 -7 0 0 13 9 16 6 23 -14 38 -15 11 -22 24 -19 33 3 8
2 31 -1 51 -6 30 -8 33 -15 16 -6 -13 -8 3 -5 43 2 34 0 62 -5 62 -4 0 -2 14
4 30 10 27 16 30 52 30 31 0 37 -3 25 -11 -12 -8 -10 -10 10 -6 14 2 34 8 45
13 19 7 19 7 3 -9 -20 -21 -14 -44 14 -53 16 -5 18 -8 7 -15 -12 -7 -11 -9 1
-9 12 0 12 -2 2 -8 -9 -6 -5 -15 18 -32 28 -22 43 -50 18 -34 -7 4 -8 3 -4 -4
9 -15 46 -16 46 -2 0 6 5 10 11 10 5 0 7 -5 3 -12 -5 -8 0 -9 14 -6 25 7 31
-8 7 -15 -11 -4 -10 -4 2 -3 11 1 19 11 21 25 2 15 0 21 -7 17 -6 -4 -11 0
-11 9 0 8 7 18 15 21 8 4 15 13 15 21 0 20 27 46 40 38 16 -10 11 21 -5 35
-11 9 -14 23 -10 44 5 24 4 28 -5 16 -8 -12 -9 -9 -4 13 3 15 2 27 -3 27 -17
0 -23 118 -10 187 6 31 5 42 -5 46 -7 2 -5 3 5 1 10 -1 21 5 24 14 15 40 63
54 89 27 9 -8 13 -15 9 -15 -3 0 3 -12 12 -27 10 -15 23 -47 28 -72 7 -33 17
-49 35 -58 14 -7 18 -13 10 -13 -12 0 -13 -2 -1 -9 7 -5 11 -13 8 -18 -3 -5 4
-10 15 -10 26 -2 28 -4 30 -36 1 -14 6 -23 10 -21 12 8 10 58 -3 71 -9 9 -8
16 5 28 9 9 16 20 16 24 0 5 13 11 29 14 27 6 29 9 33 62 1 30 -1 57 -7 59 -6
3 -3 9 5 15 13 9 31 64 47 144 3 15 9 27 13 27 4 0 11 11 14 25 4 14 10 23 15
20 5 -2 12 6 15 20 6 22 12 25 50 25 40 0 44 -3 49 -27 3 -15 3 -42 0 -60 -3
-18 -1 -33 4 -33 5 0 7 -26 5 -61 -3 -44 -1 -60 7 -55 6 4 11 15 12 24 0 9 5
4 10 -13 5 -16 7 -38 5 -47 -5 -22 -4 -22 23 1 16 14 18 21 9 24 -7 3 5 6 27
6 67 1 70 -4 70 -128 0 -60 3 -113 8 -117 4 -4 8 6 8 22 1 23 3 25 6 7 5 -27
16 -29 33 -8 7 8 16 12 21 9 5 -3 9 0 9 5 0 6 20 11 44 11 l43 0 4 -77 c2 -43
4 -100 4 -128 1 -64 15 -126 26 -119 5 3 6 -2 3 -10 -4 -9 1 -18 10 -22 10 -4
16 -18 16 -38 1 -17 8 -42 16 -56 14 -23 15 -23 11 -3 -3 14 3 29 16 40 20 18
20 18 14 -28 -5 -38 -3 -51 13 -69 11 -13 20 -29 20 -37 0 -7 5 -13 10 -13 6
0 10 5 10 10 0 6 7 10 15 10 18 0 19 -14 4 -42 -8 -15 -8 -23 2 -32 9 -8 10
-8 4 4 -6 10 -1 8 13 -4 12 -10 19 -22 17 -27 -3 -4 -1 -10 5 -14 5 -3 10 -1
10 4 0 6 9 11 20 11 11 0 20 -5 20 -11 0 -5 -4 -8 -9 -5 -5 4 -11 0 -13 -6 -3
-7 9 -25 25 -41 25 -23 32 -25 45 -15 14 12 14 11 1 -9 -11 -18 -11 -25 -1
-35 9 -9 12 -9 13 2 0 10 4 7 9 -5 10 -23 13 -186 4 -209 -3 -9 -10 -13 -16
-10 -5 3 -5 11 -1 18 4 7 0 3 -9 -7 -10 -11 -18 -27 -18 -38 0 -10 -6 -22 -12
-26 -7 -4 -22 -19 -33 -33 -15 -19 -16 -22 -2 -11 11 9 17 10 17 2 0 -11 -13
-15 -37 -12 -7 1 -13 -6 -13 -15 0 -11 -6 -15 -19 -11 -14 3 -21 -3 -29 -29
-5 -19 -6 -34 -2 -34 5 0 5 -13 0 -30 -8 -28 -7 -30 27 -36 23 -4 37 -13 40
-25 3 -10 25 -29 49 -41 38 -18 49 -20 80 -10 20 6 42 8 50 5 8 -3 14 0 14 6
0 6 -7 11 -17 11 -21 0 -12 9 15 15 32 6 103 86 96 108 -4 13 -2 17 5 13 6 -4
11 -2 11 4 0 6 4 9 9 6 5 -3 14 15 22 41 10 36 10 48 1 51 -7 2 -12 13 -13 25
0 12 -3 16 -6 10 -7 -18 -33 -16 -55 4 -24 21 -23 44 2 66 12 10 18 22 15 27
-3 6 -1 10 5 10 7 0 9 7 6 17 -4 10 -4 14 0 11 3 -4 18 5 32 19 28 28 36 73
13 73 -8 0 -12 3 -8 6 3 3 15 3 28 -1 16 -6 18 -9 8 -16 -7 -4 -11 -11 -7 -14
9 -9 78 63 78 81 0 25 -26 44 -59 44 -17 0 -31 -4 -31 -8 0 -5 -16 -13 -35
-19 -20 -6 -35 -7 -35 -2 0 5 9 9 20 9 11 0 20 5 20 11 0 7 -13 8 -39 4 -30
-5 -40 -3 -44 9 -4 9 -14 16 -24 17 -16 0 -16 1 2 9 19 8 19 9 -2 9 -12 1 -27
10 -33 21 -6 12 -22 20 -38 21 -17 1 -20 3 -9 6 9 2 17 8 17 13 0 5 -6 7 -14
4 -9 -3 -21 5 -31 19 -9 14 -24 24 -33 22 -9 -1 -11 0 -5 2 13 5 11 9 -26 49
-17 18 -24 61 -12 69 5 2 11 -8 14 -24 10 -47 50 -86 104 -100 134 -34 194
-44 206 -32 8 8 5 11 -12 11 -16 0 -21 4 -17 15 3 8 0 15 -7 15 -9 0 -9 2 1 9
10 6 9 11 -4 22 -10 8 -15 9 -11 3 4 -7 -5 -11 -24 -12 -16 0 -27 4 -24 9 4 5
-3 9 -14 9 -10 0 -21 -5 -23 -11 -7 -20 -99 32 -127 72 -14 21 -33 40 -41 42
-9 3 -6 5 8 6 12 1 22 7 22 16 0 12 7 14 26 9 16 -4 25 -3 21 3 -3 5 -2 15 4
23 7 12 9 12 9 -3 0 -10 4 -16 9 -13 5 3 7 14 5 24 -8 29 33 45 110 44 44 -1
72 -6 77 -14 7 -10 12 -11 29 0 11 6 30 18 43 25 12 6 25 17 29 22 4 6 8 6 8
1 0 -5 6 -4 13 2 14 12 57 5 57 -8 0 -4 15 -8 33 -7 18 0 55 -10 82 -23 28
-14 54 -23 58 -22 5 1 6 -2 2 -8 -4 -6 8 -10 30 -10 22 0 34 -4 30 -10 -4 -6
10 -10 35 -10 24 0 39 -4 35 -10 -4 -6 5 -7 24 -4 23 5 32 2 40 -14 18 -33 13
-70 -9 -77 -23 -7 -27 -45 -5 -45 12 0 14 -9 11 -36 -6 -44 1 -64 20 -57 8 3
14 -1 14 -10 0 -8 9 -34 20 -58 l20 -44 -25 -20 c-14 -12 -25 -30 -25 -40 0
-15 -2 -17 -9 -7 -6 11 -9 9 -14 -7 -3 -14 1 -22 14 -26 12 -4 18 -13 15 -23
-2 -9 -7 -26 -10 -37 -3 -11 -1 -27 4 -35 8 -12 10 -8 11 15 1 20 3 23 6 10 2
-11 6 -30 9 -42 2 -12 17 -29 32 -37 15 -9 21 -15 15 -16 -19 0 -16 -20 3 -20
8 0 12 -4 9 -10 -3 -5 1 -10 9 -10 9 0 16 9 16 20 0 13 7 20 20 20 23 0 26 23
11 80 -19 75 -21 99 -7 94 8 -3 15 5 19 23 3 15 36 60 72 98 105 112 116 125
110 135 -3 6 2 10 12 11 11 0 13 3 5 6 -6 2 -10 9 -7 13 3 5 13 6 22 3 12 -5
15 -2 11 9 -4 10 4 23 20 35 38 28 69 96 81 178 6 39 15 91 19 117 5 26 5 53
1 60 -5 7 -9 37 -9 66 l0 52 41 0 c28 0 38 -4 33 -11 -5 -9 1 -10 21 -5 24 6
27 5 22 -13 -3 -12 -2 -21 2 -21 14 0 29 23 25 39 -3 9 1 5 8 -7 10 -21 9 -26
-13 -43 -22 -17 -22 -21 -3 -18 4 0 10 -5 14 -11 5 -7 11 10 15 40 5 38 10 49
17 39 7 -10 8 -9 3 6 -12 31 -5 176 8 190 25 24 45 24 72 -1 15 -14 28 -24 29
-22 1 2 7 14 13 28 l12 25 0 -23 c2 -49 21 -8 22 46 0 41 3 51 9 37 5 -13 7 8
5 55 -2 46 2 81 9 89 9 12 8 13 -6 8 -10 -3 -18 -2 -18 3 0 6 9 10 20 10 11 0
20 5 20 10 0 12 7 12 35 1z m1452 -173 c-2 -24 -4 -5 -4 42 0 47 2 66 4 43 2
-24 2 -62 0 -85z m-1587 -48 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10
15 10 8 0 15 -4 15 -10z m477 -102 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4
-12 1 -19z m-20 -20 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m313 -278 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m200 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11
-4 11 -10z m617 -112 c-2 -24 -4 -5 -4 42 0 47 2 66 4 43 2 -24 2 -62 0 -85z
m-2727 48 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z m-1768 -28
c-5 -19 -7 -20 -10 -5 -2 9 -8 14 -14 11 -7 -4 -8 -2 -4 4 13 22 35 14 28 -10z
m2509 -131 c-8 -8 -11 -7 -11 4 0 20 13 34 18 19 3 -7 -1 -17 -7 -23z m1986
-119 c-2 -29 -3 -6 -3 52 0 58 1 81 3 53 2 -29 2 -77 0 -105z m-1910 120 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1357 -12 c0 -3 -4 -8 -10
-11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m2581 -49 c-10 -9
-11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m-4011 -17 c0 -5 -7 -10 -16
-10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9 -4 9 -10z m-85 -60 c3 -5 1 -10
-4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m1515 -44 c-15 -15
-33 4 -19 20 10 12 14 12 20 2 5 -7 4 -17 -1 -22z m-1567 7 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m1608 -56 c-8 -8 -11 -7 -11 4 0 20 13 34
18 19 3 -7 -1 -17 -7 -23z m64 -27 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0
6 7 10 16 10 8 0 12 -4 9 -10z m3173 -180 c-1 -118 -6 -101 -10 37 -2 50 0 86
4 80 4 -7 7 -59 6 -117z m-2891 114 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m630 -36 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z
m-754 -15 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z m774 -165
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-12 -18 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m2247 -147 c-2 -151
-24 -436 -49 -623 -14 -114 -18 -129 -26 -100 -5 21 -5 41 2 54 6 12 13 46 15
76 3 30 12 110 20 177 9 68 18 220 22 338 8 233 18 285 16 78z m-4682 62 c0
-8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m1736
-82 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z m2489 -134 c11
-17 -1 -21 -15 -4 -8 9 -8 15 -2 15 6 0 14 -5 17 -11z m-158 -75 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m103 -144 c0 -5 -11 -10 -25 -10
-14 0 -25 5 -25 10 0 6 11 10 25 10 14 0 25 -4 25 -10z m-4247 -7 c-7 -2 -19
-2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-1060 -16 c3 -8 8 -43 12 -79 7
-63 8 -65 33 -60 15 3 29 8 32 12 13 17 102 71 108 65 4 -3 -19 -31 -51 -61
-31 -30 -57 -61 -57 -68 0 -37 12 -46 64 -46 117 0 130 -32 16 -40 -69 -5 -75
-7 -80 -30 -8 -31 -2 -50 16 -50 8 0 23 -8 35 -17 24 -21 54 -73 41 -73 -13 0
-84 49 -104 72 -9 11 -25 18 -35 16 -14 -3 -19 -17 -25 -72 -6 -65 -20 -98
-34 -83 -4 4 -9 40 -12 82 -6 91 -15 94 -103 34 -33 -22 -65 -39 -72 -36 -7 2
11 27 41 57 35 37 52 62 52 79 0 25 -3 26 -62 27 -47 1 -65 6 -75 20 -19 26
-1 34 73 34 53 0 62 3 67 20 4 14 -7 30 -38 58 -38 33 -67 82 -50 82 8 0 95
-59 113 -77 7 -7 22 -13 31 -13 15 0 20 12 26 72 3 40 9 76 11 80 8 12 21 9
27 -5z m6287 -13 c6 -14 10 -52 10 -85 0 -58 1 -60 23 -53 12 4 38 19 57 34
65 51 90 64 90 48 0 -22 -97 -118 -120 -118 -11 0 -20 5 -20 10 0 6 -9 10 -20
10 -11 0 -20 -4 -20 -10 0 -5 -10 -10 -22 -10 -21 0 -21 1 -3 13 12 10 14 16
7 21 -7 4 -12 2 -12 -4 0 -6 -6 -10 -12 -9 -24 3 -58 -13 -58 -27 0 -8 10 -14
24 -14 14 0 28 -5 32 -11 5 -8 -4 -10 -30 -7 -34 4 -36 2 -36 -24 0 -27 -2
-28 -48 -28 -27 0 -63 7 -80 16 -58 28 -36 44 64 44 60 0 64 1 64 23 0 13 -20
41 -50 70 -28 27 -50 56 -50 64 0 9 5 11 13 7 24 -15 92 -69 95 -76 2 -5 16
-8 31 -8 24 0 27 4 29 38 6 105 22 139 42 86z m-935 -4 c3 -5 1 -10 -4 -10 -6
0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m-4868 -72 c-2 -13 -4 -3 -4
22 0 25 2 35 4 23 2 -13 2 -33 0 -45z m4760 -94 c-3 -3 -12 -4 -19 -1 -8 3 -5
6 6 6 11 1 17 -2 13 -5z m1202 -56 c51 -2 66 -6 66 -18 0 -11 -16 -16 -65 -20
-58 -5 -67 -3 -88 17 -13 13 -18 23 -12 23 5 0 10 10 11 23 l0 22 11 -22 c9
-19 20 -23 77 -25z m-5222 -14 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m5023 -4 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0
15 -4 15 -10z m70 -20 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10
6 0 10 -4 10 -10z m34 4 c9 -3 16 -18 16 -33 0 -18 15 -41 50 -75 48 -47 60
-66 41 -66 -5 0 -36 21 -70 46 -82 62 -94 58 -103 -34 -3 -41 -9 -76 -12 -80
-14 -13 -27 18 -33 81 -5 56 -3 67 9 67 11 0 14 8 11 28 -3 26 -3 26 7 3 8
-18 13 -21 24 -12 7 6 15 8 19 5 9 -10 29 37 24 57 -6 21 -5 22 17 13z m-164
-26 c0 -9 3 -9 12 0 20 20 28 14 26 -20 -2 -25 -10 -36 -29 -44 -14 -7 -48
-28 -75 -49 -68 -52 -72 -24 -6 35 33 29 52 55 52 68 0 12 5 22 10 22 6 0 10
-5 10 -12z m-455 -186 c-10 -53 -26 -135 -37 -182 -10 -46 -23 -118 -29 -160
-10 -71 -72 -303 -117 -435 -53 -153 -83 -235 -110 -300 -17 -38 -41 -104 -55
-145 -45 -132 -269 -555 -394 -742 -152 -227 -154 -231 -166 -219 -7 7 -5 16
9 28 24 22 84 115 84 131 0 6 8 15 17 21 15 8 15 11 3 28 -13 16 -13 17 1 6
19 -16 33 -5 18 14 -9 10 -6 13 15 13 15 0 25 4 21 9 -3 5 1 16 9 24 8 8 12
17 9 21 -4 3 2 6 11 6 12 0 16 5 13 14 -3 8 1 20 9 26 8 7 14 19 14 27 0 8 4
12 9 8 11 -6 34 29 25 38 -3 3 4 15 15 26 12 12 21 29 21 40 0 10 6 24 14 30
8 6 20 26 27 44 7 18 22 45 33 62 34 49 45 74 41 95 -2 11 1 19 7 17 6 -1 13
4 16 12 3 8 0 11 -7 7 -7 -4 -9 -1 -5 8 3 8 11 13 18 10 13 -5 66 106 66 139
0 10 5 15 10 12 6 -3 11 0 12 7 0 7 2 18 2 23 1 9 7 24 43 120 8 22 24 57 35
77 10 21 16 43 12 50 -4 7 -3 8 4 4 8 -4 12 0 12 11 1 20 32 119 48 149 18 34
77 236 108 369 4 17 8 35 10 40 5 16 32 167 38 211 3 23 11 59 19 80 8 21 20
75 27 119 15 102 22 133 28 117 2 -7 -4 -56 -13 -110z m-4565 -22 c0 -5 -5
-10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-599 -83 c-10
-9 -11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m4015 -54 c-11 -11 -19 6
-11 24 8 17 8 17 12 0 3 -10 2 -21 -1 -24z m1041 -40 c-3 -10 -5 -4 -5 12 0
17 2 24 5 18 2 -7 2 -21 0 -30z m-3920 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m238 -123 c3 -5 1 -12 -4 -15 -5 -3 -11 1 -15 9 -6 16 9 21
19 6z m198 -21 c4 -18 3 -19 -9 -9 -12 9 -13 9 -9 -3 4 -9 0 -15 -10 -15 -18
0 -20 28 -3 45 14 14 24 8 31 -18z m2594 8 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13
3 -3 4 -12 1 -19z m-434 5 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13
-5z m27 -32 c0 -6 -4 -13 -10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4
10 -9z m-2540 -35 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z
m2960 -16 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11
-10z m840 -17 c-1 -24 -2 -26 -11 -10 -5 9 -7 22 -4 27 10 17 15 11 15 -17z
m-100 -323 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-1220 -480 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6
0 11 -4 11 -10z m-90 -47 c0 -21 -3 -24 -9 -14 -5 8 -7 20 -4 28 8 21 13 15
13 -14z m-3191 -68 c0 -5 -4 -3 -9 5 -8 13 -10 13 -10 1 0 -11 -3 -12 -12 -3
-8 8 -8 15 2 28 13 15 15 14 21 -3 4 -10 8 -22 8 -28z m1681 -85 c0 -5 -5 -10
-11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m1005 -20 c-3 -5 -15
-10 -26 -10 -10 0 -19 5 -19 10 0 6 12 10 26 10 14 0 23 -4 19 -10z m108 3
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m-1437 -51 c-4 -3 -11
-1 -17 5 -8 8 -8 15 2 26 12 13 14 13 17 -5 2 -11 1 -22 -2 -26z m-1282 -71
c-6 -6 -11 1 -18 29 l-7 25 15 -25 c8 -14 12 -27 10 -29z m3832 -17 c-4 -9 -9
-15 -11 -12 -3 3 -3 13 1 22 4 9 9 15 11 12 3 -3 3 -13 -1 -22z m-436 -144
c13 -8 13 -10 -2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z m-2903 -66 c2 -13 1
-15 -4 -4 -3 8 -11 19 -17 23 -7 6 -6 7 4 4 8 -2 16 -12 17 -23z m1160 -6 c-3
-8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m1553 12 c0 -5 -2 -10 -4
-10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m-2583 -56 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m418 -4 c3 -5 -3 -10 -15 -10
-12 0 -18 5 -15 10 3 6 10 10 15 10 5 0 12 -4 15 -10z m-648 -6 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-147 -14 c0 -5 -7 -10 -15 -10 -8 0
-15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15 -10z m1600 -123 c0 -14 -6 -24 -14
-24 -11 0 -12 5 -3 24 5 13 12 23 14 23 1 0 3 -10 3 -23z m447 -32 c9 -24 -1
-27 -15 -5 -8 13 -8 20 -2 20 6 0 13 -7 17 -15z m1974 -56 c4 -13 10 -46 14
-72 4 -28 13 -51 21 -54 8 -3 37 16 70 46 53 48 74 61 74 43 0 -4 -27 -39 -61
-76 -33 -38 -57 -72 -52 -76 4 -5 38 -11 74 -14 71 -6 99 -13 99 -27 0 -7 -16
-11 -110 -25 -19 -3 -45 -8 -57 -11 -13 -3 -23 -1 -23 6 0 6 7 8 15 4 14 -5
14 0 -2 38 -9 24 -24 51 -32 61 -19 22 -45 24 -37 3 3 -9 0 -15 -9 -15 -9 0
-12 -6 -9 -15 4 -8 1 -15 -4 -15 -18 0 -23 18 -10 32 7 7 14 18 15 23 1 6 4
19 8 30 4 16 3 15 -6 -2 -8 -15 -21 -23 -37 -23 -14 0 -23 -3 -19 -6 7 -8 -17
-34 -32 -34 -6 0 -11 6 -11 13 0 8 -10 22 -21 33 -12 10 -35 38 -51 62 -39 57
-16 56 53 -3 30 -25 63 -45 76 -45 21 0 23 4 23 59 0 66 7 94 23 88 6 -2 14
-14 18 -28z m-2414 -11 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-2032 -31 c4 -24 5 -52 3 -63 -5 -33 35 -29 73 8 39 39 109 84 109 71 0 -5
-25 -36 -55 -69 -30 -32 -59 -72 -65 -89 -10 -30 -10 -30 -7 8 2 20 -1 37 -5
37 -4 0 -8 -5 -8 -11 0 -5 -4 -8 -8 -5 -4 2 -27 7 -50 9 -44 5 -48 1 -44 -45
1 -10 -5 -18 -13 -18 -8 0 -16 -8 -16 -17 -1 -17 -2 -17 -6 0 -7 25 -23 21
-23 -6 0 -31 30 -64 54 -59 10 2 23 -3 28 -12 6 -11 14 -13 32 -6 13 5 27 6
30 3 16 -16 1 -23 -54 -23 -56 0 -110 -15 -110 -30 0 -7 -75 -60 -85 -60 -16
0 1 27 45 74 28 30 50 62 50 74 0 19 -6 22 -47 22 -61 0 -125 18 -120 33 2 7
38 16 85 21 101 11 105 23 32 103 -27 30 -50 59 -50 65 0 5 21 -5 47 -24 25
-18 45 -37 44 -42 -2 -5 2 -12 8 -15 9 -6 17 -31 20 -63 1 -5 9 -8 19 -7 11 0
12 2 4 6 -10 4 -9 9 5 19 17 14 17 14 -1 12 -10 -2 -21 2 -23 9 -6 16 38 28
47 13 5 -9 9 -9 14 -1 4 6 4 16 1 22 -4 6 -4 30 0 55 8 58 32 59 40 1z m2743
-12 c0 -14 -3 -23 -7 -20 -8 9 -9 55 0 50 3 -3 7 -16 7 -30z m-2200 -23 c16
-15 26 -34 24 -41 -3 -8 0 -11 6 -7 6 3 8 -1 5 -13 -3 -11 5 -28 21 -44 13
-14 22 -28 19 -31 -4 -3 1 -6 11 -6 9 0 14 5 11 10 -3 6 0 10 8 10 9 0 13 -10
12 -30 -1 -23 3 -30 16 -30 10 0 21 -4 24 -10 3 -6 -1 -7 -9 -4 -11 4 -16 0
-16 -11 0 -12 -9 -5 -28 21 -16 21 -36 45 -46 53 -10 8 -24 28 -33 45 -9 17
-29 47 -44 67 -16 20 -26 39 -22 43 9 9 10 9 41 -22z m1502 -26 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m1854 -8 c-5 -9
-2 -8 10 1 12 10 20 11 27 4 8 -8 -8 -37 -54 -97 -204 -270 -544 -618 -807
-825 -36 -28 -100 -79 -142 -113 -43 -35 -120 -90 -171 -123 -52 -33 -127 -83
-168 -110 -82 -56 -212 -135 -222 -135 -3 0 -29 -13 -57 -30 -56 -33 -92 -37
-130 -14 l-25 15 33 -5 c18 -3 32 -1 32 4 0 6 11 10 25 10 14 0 25 5 25 11 0
6 8 8 19 4 11 -3 22 -1 26 5 3 5 15 10 26 10 10 0 19 5 19 10 0 6 5 10 11 10
6 0 19 10 30 23 13 16 18 18 19 7 0 -9 6 -4 13 10 8 16 23 26 40 28 15 2 27 7
27 12 0 5 -8 7 -17 3 -10 -3 -15 -3 -12 1 4 4 19 9 35 12 22 3 25 2 14 -6 -13
-9 -13 -10 0 -10 9 0 21 14 27 30 9 25 17 30 43 30 18 0 29 4 25 10 -3 6 1 10
9 10 9 0 16 5 16 10 0 6 7 10 16 10 9 0 12 5 8 13 -4 6 -4 9 1 5 4 -4 17 -2
29 4 20 11 20 11 1 19 -18 7 -18 8 5 9 14 2 28 2 33 1 4 0 7 6 7 15 0 10 6 14
15 10 9 -3 15 0 15 8 0 7 12 18 27 25 21 10 28 9 34 -1 5 -7 9 -8 9 -3 0 6 -4
15 -9 20 -6 6 0 11 17 12 14 0 28 6 30 12 2 6 10 11 18 11 9 0 12 5 9 10 -3 6
-1 10 5 10 6 0 9 4 6 9 -3 5 2 15 11 22 12 10 14 10 9 2 -15 -26 12 -12 34 17
15 20 26 27 30 19 5 -7 22 4 49 30 23 23 46 41 52 41 6 0 8 4 5 9 -3 5 8 14
25 21 20 9 26 16 18 20 -15 10 -1 33 17 26 8 -3 19 4 25 15 6 11 17 18 24 15
9 -3 12 3 10 19 -1 14 1 23 5 20 3 -2 21 9 39 25 33 30 47 38 36 20 -3 -5 -2
-10 2 -10 5 0 14 9 20 20 11 17 10 18 -5 13 -15 -6 -15 -4 -4 10 8 8 26 17 40
19 19 2 26 8 24 21 -2 9 1 17 5 16 26 -4 34 2 28 23 -6 16 -4 20 8 15 10 -4
17 2 20 15 3 11 10 17 15 14 5 -3 9 2 8 12 -1 11 6 17 19 17 26 0 50 25 60 61
5 16 8 32 7 35 0 3 9 5 21 4 15 -2 27 7 39 29 9 17 21 31 27 31 6 0 10 2 9 5
-2 3 12 20 30 38 18 18 29 32 25 33 -4 0 2 4 15 10 13 5 21 13 17 18 -3 6 4
15 15 21 11 6 20 17 20 24 0 8 10 16 22 20 22 5 23 6 3 21 -20 15 -20 16 1 13
12 -2 31 6 43 16 26 23 27 38 4 29 -14 -4 -12 -1 5 16 12 13 25 36 29 52 3 16
10 29 15 29 5 0 6 -6 2 -12z m442 -90 c-1 -7 -1 -10 1 -5 7 10 43 9 43 -2 0
-6 -9 -17 -21 -25 -22 -15 -18 -36 5 -36 8 0 16 -10 19 -22 7 -25 15 -22 20 8
12 60 20 74 43 74 31 0 31 -14 1 -27 -13 -6 -24 -17 -24 -25 0 -7 -4 -22 -9
-32 -5 -14 -4 -17 5 -11 10 6 11 3 2 -14 -10 -18 -9 -21 10 -21 22 0 22 9 3
-121 -3 -22 -9 -37 -14 -32 -14 15 -32 100 -29 134 3 30 1 34 -20 31 -13 -1
-43 -20 -68 -42 -79 -69 -100 -48 -30 28 24 26 46 57 50 70 5 20 2 22 -41 22
-55 0 -122 17 -122 30 0 5 12 12 28 16 15 3 32 7 37 9 6 2 33 4 62 4 37 1 51
-3 49 -11z m-4113 -17 c14 -14 -24 -28 -93 -32 -88 -6 -90 -22 -15 -104 56
-61 59 -78 8 -52 -49 26 -75 62 -77 107 -1 22 2 40 7 40 4 0 6 4 3 9 -3 5 1
12 9 15 8 3 15 9 14 13 -1 4 -1 12 0 17 1 12 130 1 144 -13z m-296 -33 c-3 -8
-6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m4541 -38 c7 -11 23 -20 36 -20
15 0 37 -15 59 -39 39 -44 62 -81 49 -81 -18 0 -112 79 -112 94 0 3 8 3 18 -1
9 -3 14 -2 9 2 -4 5 -29 8 -55 7 -30 -2 -42 1 -33 7 8 5 11 16 6 30 -8 27 6
27 23 1z m-3730 -39 l-2 -48 -19 24 c-18 22 -18 25 -3 48 9 14 19 25 21 25 3
0 4 -22 3 -49z m3772 39 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15
10 8 0 15 -4 15 -10z m-4520 -82 c0 -47 -10 -78 -25 -78 -16 0 -21 22 -17 72
3 29 8 38 23 38 15 0 19 -7 19 -32z m810 22 c0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-2 -56 c20 -18 28 -33 24 -46 -4 -16
-9 -14 -38 19 -51 57 -41 75 14 27z m64 -101 c12 -15 32 -17 23 -3 -3 6 -1 10
4 10 13 0 14 -10 1 -30 -8 -13 -12 -12 -28 5 -22 25 -33 51 -24 60 4 4 9 -1
11 -12 2 -10 8 -24 13 -30z m95 -79 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m1430 -16 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-1407 -28 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4
11 -10z m94 -76 c53 -30 70 -49 35 -40 -13 4 -6 -2 15 -14 22 -11 46 -20 53
-20 15 0 18 -36 4 -45 -12 -7 -101 74 -101 92 0 7 -6 13 -13 13 -8 0 -22 9
-32 20 -24 26 -17 25 39 -6z m163 -139 c-7 -7 -16 -10 -22 -7 -5 2 -5 -1 1 -7
9 -10 17 -10 33 -2 24 13 28 0 6 -19 -15 -13 -25 -8 -64 28 -18 18 0 31 34 24
19 -3 21 -7 12 -17z m58 -45 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9 10 0 6 7 10
16 10 8 0 12 -4 9 -10z m-25 -20 c9 -6 20 -5 29 3 11 9 13 9 8 0 -4 -7 0 -16
10 -22 10 -6 15 -11 11 -11 -4 0 5 -12 20 -27 15 -15 29 -27 33 -26 3 1 10 -7
17 -18 18 -28 1 -23 -38 11 -19 16 -55 46 -78 65 -40 32 -48 48 -12 25z m190
0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m0
-70 c0 -5 -9 -14 -20 -20 -24 -13 -26 -7 -4 14 18 18 24 20 24 6z m1540 -14
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z
m-1542 -58 c-2 -8 5 -12 17 -10 17 2 18 1 5 -8 -12 -10 -11 -11 7 -7 14 3 20
1 17 -7 -2 -6 5 -18 17 -26 12 -8 37 -30 55 -47 18 -18 39 -33 47 -33 7 0 22
-8 33 -18 11 -10 32 -27 47 -37 15 -10 25 -20 23 -22 -3 -3 -18 4 -34 16 -17
12 -35 21 -41 21 -6 0 -11 7 -11 16 0 9 -5 14 -12 12 -7 -3 -21 3 -31 11 -11
9 -39 32 -62 51 -67 55 -78 64 -105 83 l-24 17 27 0 c16 0 26 -5 25 -12z m322
-236 c0 -4 12 -15 28 -24 17 -11 21 -18 11 -18 -8 0 -24 8 -35 18 -11 11 -26
22 -34 25 -10 3 -7 6 8 6 12 1 22 -2 22 -7z m50 -2 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m107 -42 c-3 -7 -5 -2 -5 12 0 14
2 19 5 13 2 -7 2 -19 0 -25z m-73 11 c-2 -4 2 -10 8 -12 8 -4 9 -6 2 -6 -6 -1
-17 5 -23 12 -8 10 -7 14 4 14 8 0 12 -4 9 -8z m-9 -29 c4 -7 13 -6 26 1 12 6
23 8 27 5 3 -3 0 -6 -7 -6 -9 0 -9 -5 -1 -20 8 -15 17 -19 32 -14 17 5 20 3
14 -11 -4 -13 -2 -16 9 -11 9 3 15 0 15 -9 0 -8 -3 -15 -6 -15 -9 0 -68 43
-72 52 -2 4 -9 8 -15 8 -16 1 -54 30 -40 30 7 0 15 -5 18 -10z m159 -65 c-4
-8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11 13 -6z m426 11 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-424 -47 c3 -6
17 -8 30 -5 19 5 24 2 24 -14 0 -25 -5 -25 -38 -1 -15 10 -34 21 -42 24 -12 4
-12 6 2 6 9 1 20 -4 24 -10z m88 -40 c-4 -8 1 -9 17 -4 17 4 20 3 10 -3 -11
-8 -10 -12 5 -21 14 -8 15 -10 4 -10 -22 0 -53 26 -45 38 3 6 8 11 11 11 3 0
2 -5 -2 -11z m189 -6 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z
m-60 -377 c1 -37 -1 -66 -5 -66 -5 0 -8 -4 -8 -9 0 -13 56 16 115 61 81 61 89
47 19 -31 -35 -39 -45 -57 -42 -76 6 -27 -7 -54 -19 -42 -4 5 -1 12 7 17 12 8
12 10 -3 16 -9 3 -17 16 -17 28 0 20 -1 20 -20 -4 -11 -14 -20 -19 -20 -12 0
21 -20 13 -20 -8 0 -11 -4 -20 -8 -20 -5 0 -8 18 -8 40 1 33 -2 40 -18 40 -16
0 -17 -2 -5 -17 10 -12 11 -17 2 -20 -7 -2 -9 -11 -6 -19 4 -12 -1 -14 -26 -9
-32 5 -36 1 -31 -39 1 -14 -3 -17 -15 -13 -9 4 -14 3 -10 -3 9 -14 -62 -12
-105 3 -19 7 -35 19 -35 27 0 12 16 16 72 18 67 3 73 5 79 26 4 18 -3 32 -34
62 -35 36 -62 74 -62 88 0 16 23 1 73 -44 65 -59 92 -64 101 -19 3 17 6 42 6
56 0 13 5 34 11 45 10 19 12 19 20 5 5 -9 10 -45 12 -81z m209 -158 c18 -29 8
-38 -41 -38 -69 0 -101 -13 -94 -39 3 -12 10 -21 15 -21 13 0 46 -37 64 -72
17 -32 16 -33 -22 -13 -39 19 -73 56 -75 80 -5 51 1 77 16 71 11 -4 15 1 15
18 0 12 2 25 4 27 10 10 110 -2 118 -13z m-274 0 c-6 -18 -28 -21 -28 -4 0 9
7 16 16 16 9 0 14 -5 12 -12z m112 -38 c0 -30 -6 -45 -25 -62 -21 -18 -25 -30
-25 -80 0 -54 -2 -59 -26 -68 -14 -6 -27 -10 -30 -10 -2 0 -4 29 -4 65 0 59 2
65 20 65 18 0 19 3 9 21 -10 20 -9 21 14 15 21 -5 26 -2 31 19 4 15 11 23 18
19 6 -4 9 -1 6 7 -3 8 -18 15 -34 15 -48 2 -54 4 -54 13 0 6 21 12 48 14 26 2
48 4 50 5 1 1 2 -16 2 -38z m-88 -19 c-8 -4 -13 -11 -12 -15 1 -4 -1 -12 -3
-19 -3 -7 -6 -5 -6 5 -1 23 -41 20 -41 -3 0 -17 -94 -109 -111 -109 -20 0 -7
23 36 64 25 25 45 53 45 65 0 19 6 21 53 21 32 0 47 -4 39 -9z"/>
<path d="M867 9189 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M4160 9094 c0 -31 4 -44 12 -42 17 6 18 76 1 82 -10 4 -13 -6 -13
-40z"/>
<path d="M4038 8983 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2904 8276 c-11 -8 -34 -43 -50 -78 -27 -58 -29 -72 -29 -178 1 -100
5 -128 32 -220 47 -151 77 -224 118 -282 20 -27 34 -52 32 -55 -3 -2 -19 3
-36 12 -53 27 -111 119 -182 285 -24 56 -42 86 -54 89 -16 3 -17 -2 -12 -46 3
-27 18 -76 33 -109 35 -77 129 -237 149 -254 28 -23 52 -64 59 -100 l7 -35
-18 35 c-9 19 -55 73 -101 120 -81 83 -106 119 -226 323 -57 97 -96 188 -96
227 0 15 -7 20 -29 20 -41 0 -55 -46 -48 -158 5 -81 9 -93 71 -216 70 -140
128 -223 225 -325 60 -62 77 -101 45 -101 -24 0 -174 154 -264 270 -43 55 -60
69 -84 70 -53 1 33 -119 226 -312 27 -27 45 -48 42 -48 -18 0 -167 102 -220
150 -33 30 -100 90 -147 133 -48 43 -101 99 -118 125 -63 94 -89 97 -89 11 0
-98 47 -176 195 -324 93 -94 139 -132 205 -169 112 -63 150 -91 170 -124 10
-15 29 -38 44 -51 23 -22 35 -51 20 -51 -14 0 -52 25 -84 55 -19 18 -73 55
-120 82 -47 26 -101 60 -120 74 -19 14 -64 47 -98 72 -35 26 -68 47 -73 47
-18 0 -8 -51 14 -71 30 -28 296 -203 321 -212 6 -3 9 -7 6 -9 -8 -9 -144 39
-262 93 -149 68 -312 149 -318 158 -12 17 -84 60 -93 54 -15 -9 1 -61 32 -107
49 -71 165 -171 249 -215 47 -24 139 -57 228 -82 166 -47 207 -64 277 -114 87
-65 43 -74 -51 -11 -34 23 -69 36 -104 41 -115 15 -310 70 -345 97 -21 16 -26
-13 -7 -39 13 -16 52 -37 114 -60 119 -44 207 -86 191 -91 -51 -17 -464 54
-565 97 -59 26 -91 28 -82 5 3 -9 6 -20 6 -24 0 -5 20 -29 45 -53 41 -41 104
-78 180 -107 53 -21 257 -50 385 -56 l125 -7 32 32 c25 24 41 31 70 30 33 0
35 -1 16 -9 -13 -5 -23 -18 -23 -28 0 -25 -20 -42 -50 -42 -14 0 -31 -5 -38
-12 -8 -8 -66 -14 -177 -16 -163 -4 -165 -5 -165 -27 0 -20 6 -23 68 -28 37
-4 115 -7 174 -7 96 0 133 -9 115 -27 -3 -2 -55 -15 -115 -29 -155 -33 -373
-53 -457 -42 -80 11 -97 2 -71 -36 33 -51 159 -86 307 -87 116 0 328 37 407
71 40 18 54 41 32 55 -20 12 -9 23 37 34 26 7 63 25 82 40 50 39 73 50 160 77
80 25 131 30 131 15 0 -29 -53 -64 -155 -102 -167 -62 -175 -67 -175 -99 0
-25 -8 -32 -62 -57 -35 -16 -101 -42 -148 -58 -125 -41 -150 -53 -150 -71 0
-14 7 -15 48 -9 48 7 136 35 227 73 28 11 64 26 82 32 19 7 34 21 37 35 7 27
40 38 81 27 29 -8 29 -8 -36 -47 -35 -21 -80 -50 -99 -65 -57 -43 -171 -105
-240 -133 -148 -57 -238 -86 -283 -92 -57 -7 -72 -29 -38 -56 32 -26 104 -34
201 -22 100 13 174 37 303 97 168 78 213 118 187 167 -9 16 -7 21 16 29 14 6
38 21 53 34 130 119 289 243 316 248 28 4 30 -24 4 -56 -25 -31 -238 -194
-288 -220 -31 -17 -33 -20 -27 -60 8 -51 5 -55 -151 -155 -61 -40 -114 -79
-117 -87 -8 -21 47 -18 93 5 47 25 168 122 199 162 38 47 112 78 112 46 0 -8
-12 -20 -26 -28 -15 -7 -52 -45 -84 -84 -98 -119 -212 -219 -366 -318 -112
-72 -107 -94 18 -84 89 8 175 47 283 129 108 82 156 129 221 215 74 98 124
136 124 95 0 -26 -111 -186 -177 -255 -54 -57 -64 -85 -27 -79 10 2 33 14 50
27 45 34 170 203 186 249 13 39 41 67 54 54 3 -4 -3 -20 -15 -37 -12 -16 -30
-58 -41 -93 -11 -36 -38 -92 -59 -126 -22 -34 -48 -76 -57 -93 -13 -25 -18
-29 -26 -18 -7 10 -8 8 -3 -8 9 -32 -25 -90 -100 -172 -98 -107 -81 -132 44
-66 101 54 157 99 212 173 50 67 170 301 184 357 3 17 20 57 36 90 29 60 38
118 19 130 -21 13 17 144 52 182 27 29 37 16 32 -41 -1 -22 -5 -78 -8 -122 -4
-52 -17 -111 -35 -165 -17 -46 -37 -113 -46 -149 -9 -35 -30 -89 -46 -120 -28
-53 -36 -80 -23 -80 3 0 19 7 34 17 36 21 64 71 85 153 9 36 23 76 32 88 13
22 16 22 32 8 14 -13 20 -39 25 -109 6 -90 47 -258 68 -285 18 -21 12 -85 -11
-129 -68 -135 -152 -161 -324 -101 -153 53 -245 37 -322 -54 -48 -57 -58 -93
-51 -188 l5 -85 -39 -27 c-65 -45 -47 -73 29 -47 49 17 76 5 101 -46 l22 -45
-21 -60 c-36 -104 -25 -106 70 -12 38 37 82 78 99 90 32 25 60 23 60 -3 0 -21
-47 -68 -63 -62 -16 5 -37 -17 -37 -40 1 -10 7 -29 15 -43 27 -47 36 -37 -154
-158 -38 -24 -88 -46 -120 -53 -69 -14 -253 -8 -259 9 -2 7 -11 12 -19 12 -9
0 -40 14 -69 31 -33 18 -64 29 -80 27 -32 -4 -61 -40 -70 -85 l-6 -33 61 0
c34 0 72 5 83 11 44 24 141 -30 105 -59 -21 -17 -240 -36 -264 -23 -33 17 -81
13 -103 -9 -27 -27 -29 -130 -2 -130 41 1 94 20 123 44 44 37 66 34 78 -11 6
-21 11 -44 11 -50 0 -15 16 -17 24 -4 3 5 23 12 43 16 63 11 85 30 78 68 -5
26 -2 37 15 52 19 17 37 20 124 20 55 0 113 -4 128 -8 24 -8 27 -6 30 19 2 23
18 39 83 82 168 113 250 139 368 119 43 -8 47 -7 47 12 0 11 -16 32 -36 47
-39 30 -90 99 -96 129 -3 17 3 20 42 20 l45 0 0 65 c0 76 19 91 55 41 31 -45
55 -40 84 18 20 38 36 54 72 71 56 28 117 29 144 5 42 -38 20 -87 -95 -213
-140 -153 -105 -162 122 -31 79 46 70 -5 -24 -128 -37 -49 -97 -122 -133 -163
-67 -74 -96 -117 -87 -126 14 -15 110 50 202 137 116 110 141 122 131 62 -17
-93 -123 -263 -250 -402 -71 -77 -86 -108 -86 -176 0 -72 17 -111 69 -157 62
-57 70 -52 68 40 -2 69 1 82 27 127 43 72 168 225 185 225 11 0 13 -13 7 -67
-27 -269 -13 -573 33 -724 11 -35 25 -83 31 -106 10 -38 14 -43 40 -43 26 0
30 5 40 43 6 23 20 70 30 103 10 34 24 99 31 145 15 96 18 479 5 582 -7 58 -6
67 8 67 19 0 126 -127 179 -213 39 -62 39 -62 33 -144 l-6 -82 30 10 c39 14
106 90 114 130 19 97 2 142 -107 268 -97 113 -129 159 -185 263 -44 84 -61
141 -43 152 6 4 54 -34 107 -84 99 -95 175 -150 206 -150 32 0 20 23 -53 105
-106 119 -219 275 -219 303 0 20 4 22 23 17 12 -4 36 -17 52 -30 41 -31 104
-61 143 -70 50 -10 42 21 -24 91 -77 81 -131 154 -139 186 -16 64 87 92 170
45 28 -15 47 -36 63 -70 26 -55 44 -59 80 -17 27 31 61 40 63 18 1 -7 2 -38 3
-68 l1 -55 48 2 c26 2 47 0 47 -4 0 -19 -56 -96 -94 -130 -24 -21 -47 -48 -50
-60 -7 -22 -6 -22 78 -16 116 8 157 -5 298 -99 101 -66 118 -81 118 -104 0
-24 3 -26 28 -20 73 18 183 19 227 1 25 -9 45 -22 46 -28 0 -7 1 -28 2 -47 2
-29 7 -36 32 -43 17 -5 43 -15 59 -23 34 -17 39 -13 56 39 16 50 32 55 78 23
28 -19 117 -55 140 -56 1 0 2 29 2 63 0 49 -4 68 -18 80 -24 22 -85 22 -117 1
-20 -13 -31 -14 -55 -6 -16 6 -68 14 -115 18 -89 7 -99 12 -89 38 9 25 62 34
167 31 53 -2 97 -1 97 1 0 3 -7 21 -15 41 -33 78 -67 91 -135 48 -81 -51 -152
-68 -269 -63 -80 4 -116 10 -146 25 -67 34 -178 104 -207 131 -26 23 -26 26
-13 53 25 47 19 65 -31 93 -25 14 -50 23 -55 20 -16 -10 -9 23 9 47 13 15 25
20 37 15 10 -3 15 -9 12 -13 -10 -9 12 -43 29 -43 9 0 12 5 8 13 -4 6 22 -18
57 -55 59 -62 99 -85 99 -57 0 6 -8 33 -17 59 -14 40 -15 54 -5 89 18 57 51
78 101 62 90 -26 109 1 34 51 l-41 28 5 93 c5 73 2 99 -10 122 -22 41 -61 81
-110 112 -57 36 -144 36 -239 0 -133 -51 -205 -48 -270 11 -39 35 -88 120 -88
152 0 9 16 62 35 119 20 61 38 140 45 198 6 53 13 107 16 119 10 41 41 11 63
-60 29 -93 68 -165 96 -178 14 -6 28 -8 32 -5 3 4 -9 39 -27 78 -18 39 -41
101 -51 138 -10 36 -30 104 -45 151 -33 109 -47 320 -21 320 9 0 29 -21 44
-47 25 -44 28 -57 29 -148 0 -97 2 -103 52 -222 28 -67 63 -146 78 -175 32
-65 123 -205 153 -235 39 -41 132 -104 188 -129 103 -46 111 -9 21 86 -37 39
-79 96 -101 140 -22 41 -57 103 -79 137 -22 34 -51 95 -64 135 -14 40 -34 82
-45 94 -12 13 -16 24 -11 28 19 12 41 -6 47 -37 8 -36 29 -67 124 -180 60 -72
103 -107 131 -107 20 0 10 29 -21 57 -30 28 -166 209 -188 250 -6 12 -11 29
-11 38 0 32 60 -17 117 -95 77 -104 105 -132 208 -209 113 -85 166 -115 234
-135 127 -37 224 -4 131 44 -103 54 -305 231 -440 386 -14 16 -38 39 -54 52
-15 13 -25 26 -22 29 14 15 64 -14 134 -79 42 -39 94 -84 116 -100 48 -34 135
-63 150 -48 13 13 2 20 -146 112 -65 40 -120 80 -123 89 -3 9 -1 29 5 45 10
24 9 31 -7 42 -213 151 -333 252 -333 278 0 41 55 13 185 -96 43 -36 112 -91
154 -123 48 -37 75 -64 73 -74 -2 -9 -1 -31 2 -48 6 -30 16 -37 151 -104 177
-87 274 -115 405 -116 75 0 102 4 123 18 38 25 34 45 -10 55 -86 20 -124 32
-236 76 -65 25 -139 58 -165 72 -57 32 -192 117 -249 156 -23 15 -47 28 -55
29 -7 1 3 5 22 10 46 12 77 4 85 -22 7 -21 11 -23 165 -88 78 -33 181 -61 223
-61 4 0 7 10 5 23 -2 17 -12 24 -43 31 -22 4 -85 26 -140 48 -55 22 -118 45
-140 51 -51 14 -50 14 -36 31 26 31 7 47 -104 90 -162 63 -215 93 -215 123 0
18 32 16 114 -8 85 -25 128 -46 180 -88 21 -17 49 -31 62 -31 44 0 55 -10 48
-45 -5 -30 -3 -33 35 -49 23 -9 94 -28 159 -42 160 -36 317 -38 418 -7 83 26
138 61 142 89 3 20 0 20 -65 11 -37 -5 -88 -6 -113 -3 -25 3 -86 10 -136 16
-109 11 -335 57 -348 70 -15 15 31 20 154 18 148 -2 199 7 211 39 5 13 9 25 9
26 0 1 -55 1 -123 -1 -69 -2 -147 2 -178 8 -30 6 -70 14 -91 17 -28 4 -38 11
-42 29 -10 38 -42 47 -171 49 -114 2 -165 12 -165 35 0 17 22 22 122 29 95 6
101 5 108 -14 10 -27 45 -45 100 -49 34 -3 49 -10 66 -31 l22 -28 129 6 c195
9 363 40 449 82 84 41 158 103 172 145 10 28 9 30 -14 30 -14 0 -41 -8 -62
-19 -76 -38 -302 -80 -517 -97 l-70 -5 35 20 c19 11 64 30 100 42 36 12 70 25
75 29 6 3 31 14 58 24 32 12 52 27 62 46 8 16 15 31 15 35 0 8 -28 6 -32 -3
-7 -19 -296 -102 -353 -102 -20 0 -54 -14 -91 -38 -74 -48 -123 -53 -78 -8 42
43 130 84 259 121 44 13 103 30 130 38 117 34 235 106 333 203 57 57 77 89 96
152 12 36 -29 28 -89 -18 -45 -35 -111 -69 -360 -185 -60 -28 -206 -77 -212
-71 -2 1 63 45 143 97 158 102 194 132 194 160 0 32 -40 22 -99 -25 -71 -55
-180 -125 -250 -160 -29 -15 -72 -47 -96 -72 -26 -26 -52 -44 -65 -44 -21 0
-19 4 18 44 22 24 45 53 51 64 11 20 88 74 193 135 73 42 160 119 240 211 98
111 126 165 134 254 7 69 6 72 -14 72 -15 0 -38 -22 -75 -72 -30 -40 -85 -100
-123 -133 -38 -33 -71 -62 -74 -65 -14 -14 -139 -119 -170 -141 -30 -22 -113
-69 -122 -69 -2 0 43 48 100 107 136 142 182 200 182 229 0 49 -30 25 -161
-127 -108 -126 -177 -189 -206 -189 -26 0 -12 34 35 84 129 140 177 207 249
351 58 114 78 195 69 273 -8 70 -21 94 -49 90 -17 -2 -27 -19 -47 -78 -14 -41
-37 -95 -51 -120 -157 -271 -160 -275 -243 -357 -46 -44 -92 -93 -101 -107
-10 -15 -20 -25 -22 -22 -7 6 33 80 52 96 22 18 123 183 156 255 15 33 30 82
33 108 5 46 5 48 -16 45 -17 -2 -27 -17 -43 -63 -46 -134 -138 -286 -188 -312
-31 -16 -47 -10 -32 11 58 81 78 121 121 246 l49 145 0 140 0 140 -35 65 c-25
46 -41 66 -57 68 -25 4 -26 2 -23 -113 3 -135 -111 -565 -150 -565 -18 0 -11
51 10 68 28 23 41 108 38 239 l-3 108 -32 3 -32 3 0 -104 c0 -156 -23 -237
-68 -237 -21 0 -24 4 -20 20 12 46 -41 140 -79 140 -12 0 -14 -12 -8 -72 7
-78 -5 -161 -37 -268 -22 -74 -80 -156 -201 -287 -94 -102 -107 -121 -118
-170 -19 -82 23 -167 109 -225 105 -70 122 -108 82 -174 -42 -69 -181 -140
-257 -131 -92 10 -154 96 -162 223 -5 83 -3 85 55 54 45 -24 83 -26 83 -5 0 8
-22 36 -49 61 -58 54 -76 93 -86 189 -4 38 -16 87 -27 108 -11 22 -17 42 -14
45 4 3 21 -1 39 -10 60 -28 74 -5 28 47 -38 43 -111 177 -111 203 0 25 -40 57
-132 106 -116 61 -145 59 -252 -18 l-50 -35 -39 35 c-23 21 -45 33 -54 30 -8
-3 -18 -6 -22 -6 -15 0 -61 -49 -61 -64 0 -8 20 -21 50 -31 27 -9 70 -35 96
-58 66 -60 103 -79 141 -72 67 12 116 -60 59 -86 -18 -9 -32 -8 -55 2 -17 8
-31 17 -31 21 0 5 -12 8 -26 8 -14 0 -23 -4 -19 -10 3 -5 1 -10 -6 -10 -9 0
-9 -3 0 -14 9 -11 9 -15 -1 -19 -7 -3 8 -6 34 -6 46 -1 47 -2 54 -36 12 -66
-2 -101 -62 -152 -48 -41 -84 -88 -84 -108 0 -12 33 -4 80 20 l50 25 0 -28 c0
-15 -4 -32 -9 -37 -4 -6 -32 -63 -61 -127 -73 -163 -151 -247 -232 -250 -90
-2 -236 94 -259 172 -15 48 -3 70 59 111 107 72 152 135 152 214 0 63 -19 95
-118 200 -146 155 -182 211 -219 345 -17 63 -23 111 -23 187 0 65 -4 103 -10
103 -23 0 -60 -50 -66 -90 -4 -23 -13 -48 -21 -56 -22 -22 -52 -17 -57 9 -16
89 -26 178 -26 242 0 72 -1 75 -24 75 -45 0 -49 -19 -42 -173 9 -199 -10 -163
-75 143 -25 120 -29 157 -23 218 7 78 0 87 -42 58z m-273 -641 c26 -49 97
-153 119 -175 31 -31 23 -35 -9 -5 -39 37 -80 97 -138 203 -12 24 -23 46 -23
50 0 13 19 -15 51 -73z m3044 13 c-38 -74 -99 -163 -157 -227 -27 -29 -58 -70
-68 -90 -46 -87 -326 -298 -356 -268 -3 3 -3 14 0 25 7 22 184 161 239 186 42
21 188 165 250 248 23 31 57 87 76 123 18 36 35 64 38 62 2 -3 -8 -29 -22 -59z
m-2570 22 c8 -12 -3 -50 -14 -50 -7 0 -31 43 -31 55 0 10 39 5 45 -5z m-111
-26 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m1107 -113 c16
-16 29 -34 29 -40 0 -13 -42 -31 -71 -31 -11 0 -31 -12 -44 -27 -20 -23 -27
-26 -42 -16 -10 6 -16 17 -14 25 2 7 6 26 9 41 3 17 14 33 28 39 13 6 24 15
24 19 0 7 19 14 43 18 5 0 22 -12 38 -28z m-1030 -1 c10 -6 19 -17 19 -24 0
-8 21 -42 47 -77 104 -141 145 -219 115 -219 -19 0 -149 138 -169 180 -19 40
-45 150 -36 150 3 0 13 -5 24 -10z m2157 -53 c5 -42 -53 -139 -123 -206 -94
-90 -111 -80 -48 28 21 35 62 92 90 127 29 35 53 68 53 75 0 23 25 1 28 -24z
m-2843 -118 c104 -106 403 -279 482 -279 36 0 49 -7 97 -51 59 -54 73 -85 34
-74 -13 4 -32 9 -43 12 -11 3 -72 30 -136 60 -92 43 -119 61 -132 86 -12 22
-41 45 -90 71 -119 64 -187 118 -261 204 -37 43 1 21 49 -29z m586 -99 c90
-46 229 -163 229 -192 0 -27 -90 16 -186 88 -106 80 -172 145 -188 184 -8 21
-20 41 -26 45 -5 3 -10 10 -10 16 0 5 30 -19 66 -54 36 -34 88 -73 115 -87z
m2967 123 c-49 -60 -158 -150 -217 -182 -34 -18 -64 -31 -67 -28 -2 2 37 31
88 65 51 33 118 85 148 116 56 58 89 77 48 29z m-3110 -204 c7 -12 11 -24 7
-27 -7 -8 -58 22 -63 37 -7 20 43 11 56 -10z m2685 14 c7 -6 -40 -43 -55 -43
-13 0 -9 18 8 34 16 17 36 21 47 9z m79 -84 c-13 -23 -42 -43 -122 -81 -158
-76 -214 -89 -194 -46 3 7 26 30 49 51 36 31 55 40 104 47 33 5 74 18 90 29
33 24 73 40 83 35 4 -3 -1 -18 -10 -35z m415 -126 c-22 -21 -263 -101 -273
-90 -3 2 55 24 128 49 141 48 155 52 145 41z m-3577 -48 c30 -9 73 -23 95 -31
40 -14 40 -14 5 -8 -43 6 -221 64 -235 76 -9 8 10 3 135 -37z m530 -92 c49
-20 76 -37 79 -48 3 -16 -4 -17 -85 -12 -49 3 -124 15 -166 26 -42 12 -87 23
-100 24 -13 2 -24 11 -26 20 -4 19 19 22 138 21 70 -1 98 -6 160 -31z m2662
13 c11 -17 1 -23 -49 -29 -27 -3 -73 -13 -103 -22 -30 -9 -92 -19 -137 -22
-73 -5 -83 -4 -83 11 0 18 62 50 140 72 52 14 221 7 232 -10z m-2604 -212 c23
-4 32 -10 32 -24 0 -28 -32 -35 -158 -35 -112 0 -146 7 -97 19 18 5 25 13 25
31 0 24 0 24 83 19 45 -3 97 -7 115 -10z m-203 -158 c18 -13 18 -14 -3 -25
-11 -6 -27 -11 -34 -11 -22 0 -52 26 -44 38 10 16 58 15 81 -2z m2745 -1 c0
-21 -37 -37 -71 -30 -32 7 -39 31 -11 38 40 11 82 7 82 -8z m-2030 -128 c0
-38 -131 -189 -212 -245 -60 -41 -88 -49 -88 -25 0 21 28 49 108 109 30 22 74
69 99 107 35 50 52 67 70 67 13 0 23 -6 23 -13z m1319 -54 c27 -38 81 -93 123
-126 82 -64 105 -92 84 -100 -38 -15 -235 152 -276 234 -25 48 -25 59 0 59 13
0 37 -23 69 -67z m-1320 -260 c-13 -36 -47 -63 -78 -63 -24 0 -24 2 19 45 51
52 75 60 59 18z m1251 10 c33 -23 72 -67 61 -71 -20 -7 -60 19 -79 52 -24 40
-19 45 18 19z m-1610 -99 c0 -11 -192 -204 -225 -227 -27 -18 -26 -15 10 24
48 54 205 209 211 209 2 0 4 -3 4 -6z m2120 -168 c0 -6 -34 23 -75 64 -41 41
-75 79 -75 84 0 6 34 -23 75 -64 41 -41 75 -79 75 -84z m-1912 -63 c-46 -99
-107 -197 -117 -187 -2 2 1 9 6 16 6 7 24 38 40 68 17 30 49 90 73 133 23 42
43 77 45 77 2 0 -19 -48 -47 -107z m1605 -118 c27 -51 31 -62 14 -42 -25 30
-107 178 -107 193 0 12 47 -64 93 -151z"/>
<path d="M5270 8029 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3200 7839 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2660 7770 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2482 7650 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M5867 7513 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
-9 2 -12 -2z"/>
<path d="M6050 7309 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2148 7224 c17 -8 15 -25 -3 -18 -8 4 -15 1 -15 -6 0 -8 9 -10 27 -5
45 11 46 18 3 29 -14 3 -19 3 -12 0z"/>
<path d="M2098 7213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6135 6930 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M4620 6877 c-30 -26 -33 -65 -8 -91 16 -16 16 -18 -4 -41 -27 -32
-85 -34 -116 -5 -28 26 -36 25 -21 -1 6 -12 9 -36 6 -53 -5 -33 24 -112 36
-99 4 3 7 -1 7 -9 0 -18 39 -31 55 -18 6 5 24 8 40 6 16 -2 35 3 42 10 8 7 20
11 27 8 8 -3 17 -1 21 5 3 6 18 11 32 11 15 0 24 3 20 6 -3 3 6 17 20 30 14
13 22 24 18 24 -4 0 0 9 9 19 15 16 16 22 4 42 -18 33 -35 49 -48 49 -7 0 -9
7 -6 16 3 8 2 12 -4 9 -14 -9 -57 23 -63 46 -3 12 -10 18 -15 15 -13 -8 -35
20 -27 34 9 15 3 12 -25 -13z m24 -42 c-7 -16 -24 -21 -24 -6 0 8 7 13 27 20
1 1 0 -6 -3 -14z m31 -25 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10
3 0 8 -4 11 -10z"/>
<path d="M3890 6876 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M2183 6816 c7 -14 13 -23 15 -21 7 6 -9 45 -18 45 -4 0 -3 -11 3 -24z"/>
<path d="M6378 6828 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M2170 6777 c0 -10 8 -17 18 -17 9 0 13 4 7 8 -5 4 -13 11 -17 16 -5
5 -8 2 -8 -7z"/>
<path d="M2240 6390 c0 -5 9 -10 20 -10 11 0 20 5 20 10 0 6 -9 10 -20 10 -11
0 -20 -4 -20 -10z"/>
<path d="M2420 6070 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2491 6046 c-9 -11 -6 -12 14 -8 14 2 25 8 25 13 0 14 -26 11 -39 -5z"/>
<path d="M2558 5833 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5468 5788 c9 -9 15 -9 24 0 9 9 7 12 -12 12 -19 0 -21 -3 -12 -12z"/>
<path d="M4746 5733 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M3686 5725 c-3 -9 -10 -14 -15 -11 -5 3 -9 0 -8 -7 1 -28 -3 -47 -10
-45 -14 5 -63 -45 -63 -64 0 -13 -7 -18 -25 -18 -14 0 -25 -4 -25 -10 0 -5 -4
-10 -10 -10 -5 0 -6 8 -3 18 6 16 6 16 -11 -1 -13 -13 -27 -16 -46 -12 -25 5
-28 3 -32 -24 -2 -16 -1 -28 4 -25 14 9 9 -13 -7 -26 -13 -11 -15 -8 -15 14 0
16 -4 25 -10 21 -5 -3 -10 -1 -10 5 0 6 -4 8 -10 5 -5 -3 -10 -21 -10 -40 0
-21 -7 -39 -17 -47 -19 -14 -27 -48 -12 -48 5 0 9 -8 9 -17 0 -22 42 -63 64
-63 10 0 14 -5 11 -10 -4 -6 -3 -10 2 -9 21 4 54 -2 50 -8 -3 -5 11 -9 31 -11
20 -1 41 -7 46 -12 13 -13 66 -13 66 0 0 6 16 10 36 10 32 0 35 2 23 16 -11
13 -9 14 17 9 27 -6 28 -5 11 8 -10 9 -14 18 -8 21 5 3 11 0 14 -6 3 -8 13 -2
28 17 27 34 30 50 10 50 -8 0 -16 5 -18 12 -6 17 18 27 29 12 6 -9 7 -2 2 21
-4 19 -8 44 -9 55 -3 36 -27 85 -41 85 -17 0 -16 -3 -5 38 6 20 6 32 0 32 -5
0 -10 -6 -10 -12 -1 -7 -5 5 -9 27 -6 36 -7 37 -9 9 -1 -20 -6 -31 -16 -31
-19 0 -19 0 5 49 11 23 18 43 16 45 -2 2 -6 -3 -10 -12z m-289 -237 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4560 5612 c-13 -59 -27 -109 -31 -112 -3 -3 -10 -24 -13 -46 -6 -31
-3 -45 8 -57 9 -9 16 -19 16 -24 0 -14 42 -87 54 -95 6 -4 19 -5 30 -2 10 4
16 2 13 -3 -3 -5 4 -9 16 -9 30 -1 158 33 171 46 5 5 20 10 32 10 12 0 24 4
26 9 2 5 23 18 47 29 42 21 43 21 48 2 6 -23 43 -27 43 -5 0 27 -22 53 -41 48
-14 -4 -17 0 -14 15 2 13 -3 22 -16 25 -10 3 -19 12 -19 20 0 7 -10 19 -22 26
-13 6 -15 9 -5 7 14 -5 16 -2 7 13 -13 26 -74 86 -67 66 8 -19 3 -19 -16 0
-11 11 -23 12 -47 5 -35 -10 -80 5 -80 27 0 7 -4 13 -9 13 -5 0 -12 10 -16 23
-4 13 -8 17 -12 10 -2 -7 -9 -13 -15 -13 -7 0 -6 5 1 14 9 10 9 20 0 40 -14
30 -24 33 -34 9 -6 -17 -7 -17 -19 -1 -11 15 -16 3 -36 -90z m260 -86 c0 -8
-4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m-15
-216 c-3 -5 -11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z"/>
<path d="M3040 5596 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M3305 5406 c-19 -29 -13 -46 16 -46 15 0 19 6 17 31 -3 40 -14 45
-33 15z"/>
<path d="M5018 5393 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3215 5360 c-3 -6 0 -16 8 -24 11 -12 17 -11 38 6 l24 20 -28 -7
c-19 -4 -26 -2 -22 5 3 5 2 10 -4 10 -5 0 -13 -5 -16 -10z"/>
<path d="M5070 5355 c0 -16 16 -20 24 -6 3 5 -1 11 -9 15 -9 3 -15 0 -15 -9z"/>
<path d="M5057 5323 c3 -7 13 -13 23 -13 10 0 20 6 23 13 3 9 -4 14 -23 14
-19 0 -26 -5 -23 -14z"/>
<path d="M3790 4875 c0 -8 -8 -19 -17 -24 -16 -9 -16 -10 -1 -11 17 0 78 31
78 40 0 3 -14 6 -30 7 -20 2 -30 -2 -30 -12z"/>
<path d="M4442 4883 c-21 -8 -2 -23 29 -23 16 0 29 -5 29 -11 0 -5 -4 -7 -10
-4 -5 3 -10 -1 -10 -10 0 -14 3 -14 26 2 15 9 22 19 15 21 -6 2 -11 9 -11 16
0 13 -43 18 -68 9z"/>
<path d="M4547 4846 c-22 -17 -22 -23 5 -37 17 -9 20 -9 15 4 -3 8 -1 19 5 25
15 15 -5 22 -25 8z"/>
<path d="M3757 4813 c-4 -3 -7 -16 -7 -29 0 -13 -8 -24 -20 -27 -33 -9 -35 -7
-32 18 4 27 -4 31 -28 15 -13 -9 -13 -10 0 -10 11 0 9 -5 -7 -18 -12 -9 -23
-28 -25 -42 -2 -20 -9 -25 -33 -25 -35 0 -41 -13 -20 -38 8 -9 15 -20 15 -23
0 -10 28 -34 39 -34 6 0 11 -6 11 -14 0 -18 17 -29 30 -21 6 4 0 15 -14 26
-15 12 -23 26 -19 34 3 9 -1 15 -10 15 -8 0 -17 9 -20 20 -4 15 0 20 15 20 10
0 17 -3 14 -8 -2 -4 7 -12 20 -19 13 -6 22 -7 18 -2 -3 5 2 9 12 9 12 0 15 6
12 25 -4 20 -1 25 16 25 22 0 46 17 46 33 0 4 -5 5 -12 1 -8 -5 -9 -2 -5 10 4
10 7 21 7 26 0 4 6 8 13 7 6 0 21 1 31 1 20 2 20 2 0 17 -21 16 -37 19 -47 8z"/>
<path d="M4516 4774 c-8 -22 -7 -23 34 -11 31 8 33 10 15 18 -31 12 -42 10
-49 -7z"/>
<path d="M4558 4753 c-16 -4 -16 -6 -4 -19 21 -21 46 -17 46 6 0 20 -8 22 -42
13z"/>
<path d="M4645 4710 c-12 -4 -26 -14 -33 -23 -13 -15 -7 -47 9 -47 17 0 49 40
49 60 0 11 -1 20 -2 19 -2 0 -12 -4 -23 -9z"/>
<path d="M4720 4695 c0 -9 5 -15 11 -13 6 2 11 8 11 13 0 5 -5 11 -11 13 -6 2
-11 -4 -11 -13z"/>
<path d="M4560 4690 c0 -5 7 -10 15 -10 8 0 15 5 15 10 0 6 -7 10 -15 10 -8 0
-15 -4 -15 -10z"/>
<path d="M3526 4663 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M4695 4670 c-3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 -10 10 -15 10
-5 0 -12 -4 -15 -10z"/>
<path d="M4633 4614 c-11 -23 -10 -27 7 -31 10 -3 21 -12 23 -22 4 -14 5 -14
6 2 0 9 7 17 14 18 6 0 3 4 -8 9 -11 5 -14 9 -7 9 7 1 22 10 34 21 l22 20 -40
0 c-32 0 -41 -5 -51 -26z"/>
<path d="M3200 4576 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M4460 4552 c0 -4 11 -9 26 -10 14 -1 23 2 19 8 -7 12 -45 14 -45 2z"/>
<path d="M3767 4524 c-7 -7 24 -44 36 -44 5 0 4 7 -3 15 -10 12 -9 15 6 15 16
0 17 2 4 10 -17 11 -34 13 -43 4z"/>
<path d="M4860 4481 c0 -6 9 -11 19 -11 11 0 28 -3 38 -6 14 -4 15 -4 3 4 -27
17 -60 24 -60 13z"/>
<path d="M5448 4463 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4425 4310 c-3 -5 1 -10 10 -10 9 0 13 5 10 10 -3 6 -8 10 -10 10 -2
0 -7 -4 -10 -10z"/>
<path d="M3105 4289 c4 -5 0 -21 -9 -34 -33 -50 -14 -87 54 -105 22 -6 40 -16
40 -23 0 -7 7 -22 15 -33 13 -16 16 -17 25 -4 5 8 10 24 10 36 0 11 14 36 30
54 17 19 29 38 27 44 -2 5 -32 22 -68 37 -73 30 -133 44 -124 28z"/>
<path d="M5066 4266 c-77 -29 -87 -45 -54 -84 14 -17 28 -49 32 -72 8 -48 26
-49 38 -2 5 18 14 32 20 32 26 0 97 43 103 61 9 27 -33 89 -60 88 -11 0 -47
-10 -79 -23z"/>
<path d="M4488 4273 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3718 4253 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3840 4244 c0 -8 5 -12 10 -9 6 4 8 11 5 16 -9 14 -15 11 -15 -7z"/>
<path d="M890 6245 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25 -28
25 -3 0 5 -11 18 -25z"/>
<path d="M923 6213 c-20 -7 -15 -33 5 -30 25 4 42 16 41 30 0 9 -2 9 -6 0 -2
-7 -11 -13 -19 -13 -9 0 -12 5 -9 10 7 11 6 11 -12 3z"/>
<path d="M986 6206 c3 -7 -2 -20 -10 -29 -9 -9 -16 -22 -16 -30 0 -23 -13 -28
-32 -10 -17 15 -18 15 -18 0 0 -10 5 -17 10 -17 6 0 10 -10 10 -22 0 -19 2
-20 10 -8 8 12 10 11 10 -7 0 -13 -4 -23 -10 -23 -5 0 -10 -5 -10 -10 0 -15
50 -6 61 11 7 12 9 11 9 -3 0 -9 5 -20 12 -24 6 -4 8 -3 5 2 -3 6 -2 16 4 24
7 13 9 12 9 -2 0 -32 27 -19 43 20 17 42 13 122 -6 134 -19 12 -55 9 -60 -4
-4 -10 -6 -10 -6 0 -1 6 -5 12 -11 12 -5 0 -7 -6 -4 -14z m24 -63 c0 -20 -13
-53 -20 -53 -13 0 -18 38 -7 49 13 13 27 14 27 4z"/>
<path d="M6204 3711 c20 -22 26 -25 26 -13 0 4 -10 13 -22 21 -23 14 -23 14
-4 -8z"/>
<path d="M1798 3623 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5466 3294 c-22 -21 -20 -27 4 -14 11 6 20 15 20 20 0 14 -6 12 -24
-6z"/>
<path d="M5150 2980 c-8 -5 -12 -12 -9 -15 4 -3 12 1 19 10 14 17 11 19 -10 5z"/>
<path d="M4355 2390 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4091 1564 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7655 4488 c-44 -24 -127 -102 -151 -143 -34 -59 -44 -98 -45 -175
-2 -130 25 -210 111 -329 90 -125 107 -181 90 -303 -13 -89 -24 -107 -86 -138
-27 -14 -85 -47 -129 -74 -44 -26 -107 -62 -140 -78 -189 -96 -285 -203 -285
-321 0 -64 18 -101 85 -172 62 -66 126 -166 150 -234 18 -52 20 -172 3 -189
-7 -7 -23 -12 -38 -12 -17 0 -21 3 -12 9 22 13 -115 51 -183 50 -67 -1 -189
-20 -201 -32 -4 -4 -14 -7 -22 -7 -35 0 -247 -115 -290 -157 -7 -7 -16 -13
-20 -13 -4 0 -23 -15 -42 -33 -19 -18 -87 -77 -150 -132 -63 -54 -147 -128
-185 -164 -39 -36 -117 -105 -175 -153 -58 -47 -118 -99 -135 -115 -16 -15
-68 -57 -115 -93 -47 -35 -87 -67 -90 -70 -3 -3 -21 -17 -40 -30 -19 -13 -81
-61 -137 -106 -56 -46 -121 -95 -145 -110 -24 -15 -81 -52 -128 -83 -47 -30
-94 -61 -105 -67 -47 -27 -173 -98 -207 -117 -21 -11 -36 -22 -34 -25 2 -2 14
2 26 8 11 6 26 9 33 6 7 -2 5 -5 -5 -5 -9 -1 -18 -4 -20 -8 -9 -23 -97 -53
-150 -53 -32 0 -58 -4 -58 -10 0 -5 -9 -10 -19 -10 -11 0 -23 -4 -26 -10 -3
-5 -15 -10 -26 -10 -10 0 -19 -4 -19 -10 0 -5 -13 -10 -30 -10 -16 0 -30 -4
-30 -9 0 -5 -21 -12 -47 -14 -186 -22 -180 -21 -143 -28 19 -3 -44 -6 -140 -6
-101 0 -158 3 -135 7 34 6 30 7 -32 9 -39 0 -75 5 -78 11 -3 5 -22 10 -41 10
-19 0 -42 4 -52 9 -40 22 -122 51 -141 51 -12 0 -21 5 -21 10 0 13 -97 13
-102 0 -5 -14 -50 -17 -55 -2 -3 8 6 12 26 12 17 0 31 5 31 10 0 6 -8 10 -17
10 -15 0 -16 2 -3 10 12 7 8 10 -15 10 -21 0 -26 -3 -16 -9 9 -6 -3 -12 -38
-21 -28 -6 -51 -16 -51 -21 0 -11 -58 -11 -85 1 -16 7 -11 9 21 9 51 1 78 25
39 35 -14 4 -25 11 -25 16 0 11 23 13 34 3 10 -10 80 -33 85 -28 2 3 -1 5 -7
5 -7 0 -12 5 -12 10 0 6 10 10 23 10 22 0 22 0 -3 14 -187 102 -443 266 -597
383 -178 135 -258 197 -271 210 -8 7 -30 26 -50 41 -47 35 -69 53 -161 132
-42 36 -101 86 -131 110 -30 24 -95 82 -143 127 -49 46 -112 101 -140 122 -29
22 -86 69 -127 106 -114 103 -279 202 -368 221 -15 3 -36 9 -47 14 -39 16
-127 30 -183 30 -52 -1 -117 -13 -199 -39 -41 -12 -46 -5 -47 68 0 110 60 233
176 358 26 29 52 63 58 77 11 29 9 126 -2 119 -5 -2 -8 5 -8 16 0 11 -4 23
-10 26 -5 3 -15 16 -21 28 -10 21 -9 21 10 -2 24 -27 28 -13 7 27 -28 55 -126
129 -266 204 -36 19 -99 55 -140 80 -41 25 -93 55 -116 66 -22 11 -46 27 -53
35 -7 9 -17 55 -23 104 -15 126 -6 152 119 334 52 74 73 138 80 244 7 104 -13
175 -72 253 -59 78 -145 138 -145 100 0 -6 14 -36 32 -67 25 -44 32 -67 32
-111 0 -74 -19 -106 -126 -217 -120 -124 -143 -174 -142 -303 2 -100 24 -179
61 -218 10 -10 36 -38 58 -63 23 -26 62 -55 92 -69 29 -13 50 -27 47 -31 -2
-4 6 -10 18 -12 20 -4 19 -4 -5 -6 -16 0 -37 8 -49 19 -12 11 -27 20 -32 20
-6 0 -4 -5 4 -11 12 -7 8 -9 -14 -7 -20 2 -30 9 -32 22 -1 10 -11 26 -21 36
-10 9 -25 27 -33 39 -13 19 -15 20 -21 4 -6 -15 -8 -13 -14 10 -15 57 -35 20
-44 -82 -4 -38 -11 -66 -18 -69 -8 -2 -13 6 -14 20 0 13 -13 -20 -28 -72 -15
-52 -32 -105 -39 -117 -36 -66 23 -138 113 -136 38 1 38 1 10 -7 -24 -6 -14
-8 45 -10 67 -3 70 -4 30 -9 l-45 -7 40 -5 c22 -3 51 -2 63 3 25 9 85 -3 112
-23 12 -8 10 -9 -10 -4 -14 3 -45 8 -70 10 -25 2 -36 2 -25 -1 46 -13 108 -35
133 -48 33 -17 112 -92 112 -106 0 -5 10 -10 21 -10 20 0 21 -3 15 -77 -4 -43
-7 -84 -8 -90 -1 -7 -4 -13 -8 -13 -4 0 -7 -7 -7 -15 0 -18 13 -20 20 -2 3 6
6 7 6 1 1 -13 61 -6 61 7 0 5 12 9 28 10 24 0 25 1 7 9 -18 8 -18 9 6 9 14 1
34 10 44 21 10 11 28 20 40 20 13 0 28 7 35 15 7 9 15 13 18 11 2 -3 13 5 23
17 10 12 18 17 19 11 1 -38 -148 -127 -255 -153 -135 -32 -213 -21 -375 54
-147 68 -202 77 -312 54 -73 -16 -116 -39 -165 -92 -42 -44 -43 -47 -43 -110
0 -48 4 -66 15 -71 8 -3 15 -11 15 -18 0 -25 49 -118 89 -168 23 -29 41 -58
41 -65 0 -7 7 -18 15 -25 10 -9 15 -9 15 -1 0 8 6 8 20 -1 11 -7 20 -17 20
-22 0 -4 -7 -3 -15 4 -64 53 40 -112 145 -230 102 -115 158 -187 191 -245 16
-29 42 -66 58 -83 33 -33 120 -67 151 -57 11 3 32 8 47 11 16 2 37 8 48 13 42
17 92 31 116 31 14 0 29 4 35 8 18 16 128 35 132 23 2 -6 21 -11 42 -11 21 0
42 -4 45 -10 3 -5 17 -10 30 -10 13 0 27 -5 30 -10 3 -5 14 -10 24 -10 10 0
45 -13 77 -30 33 -17 77 -30 99 -31 39 -1 39 -2 10 -9 -16 -4 -22 -8 -12 -9
10 0 27 -10 39 -21 12 -11 34 -26 50 -32 15 -7 28 -16 28 -20 0 -5 7 -8 15 -8
8 0 15 -4 15 -10 0 -5 7 -10 15 -10 8 0 15 -4 15 -8 0 -4 12 -15 28 -24 15 -9
38 -27 52 -40 35 -32 179 -152 275 -228 44 -35 105 -86 136 -114 73 -68 88
-78 140 -107 24 -13 65 -43 90 -66 67 -63 366 -273 388 -273 5 0 11 -4 13 -9
9 -26 297 -197 413 -246 22 -9 62 -28 89 -41 28 -13 56 -24 63 -24 7 0 13 -4
13 -10 0 -5 17 -9 38 -9 30 2 32 3 9 6 -15 2 -25 9 -22 14 6 8 -9 13 -28 10
-5 -1 -5 3 -2 9 5 8 15 6 31 -5 14 -9 35 -15 47 -13 14 2 23 -3 25 -14 2 -12
-3 -18 -15 -19 -10 -1 11 -5 46 -10 87 -11 106 -20 61 -29 -26 -6 -16 -8 43
-9 56 -1 76 -4 71 -13 -5 -8 9 -9 55 -5 41 5 60 3 56 -3 -4 -6 -11 -8 -16 -5
-5 4 -9 1 -9 -4 0 -6 21 -12 48 -13 37 -1 31 -3 -28 -8 l-75 -6 68 -2 c38 -1
66 -6 63 -11 -14 -22 633 -26 656 -3 7 7 28 13 47 14 28 1 23 3 -19 8 -51 6
-48 7 40 10 75 2 87 4 55 10 -37 7 -35 7 25 10 53 2 59 4 35 10 -26 7 -22 8
25 10 51 2 53 3 20 10 -30 6 -26 8 30 10 49 1 59 4 40 10 -20 6 -16 8 23 9 29
1 46 5 42 11 -3 6 5 10 19 10 14 0 47 10 73 21 79 36 126 57 160 70 17 7 30
16 28 19 -2 3 7 6 18 6 20 2 195 97 207 112 3 4 17 13 32 21 15 8 37 22 47 33
11 10 23 18 26 18 4 0 25 13 48 29 23 16 60 39 82 51 44 24 148 101 153 113 2
4 10 7 18 7 8 0 14 5 14 10 0 6 4 10 10 10 9 0 21 9 73 54 12 10 38 30 57 45
19 14 44 34 54 44 11 9 24 17 28 17 15 0 167 127 161 134 -4 3 -2 6 4 6 6 0
30 18 53 40 23 22 46 40 49 40 4 0 16 8 27 18 10 9 44 36 74 59 30 23 91 71
135 108 44 36 88 72 97 80 73 61 265 175 346 206 12 5 32 13 45 18 59 25 275
61 247 42 -12 -8 -7 -11 22 -11 20 0 38 -4 40 -8 7 -19 170 -52 252 -52 83 0
84 0 123 38 22 20 52 60 68 87 37 64 76 115 147 190 76 80 226 283 261 352 9
17 23 37 30 45 23 22 66 95 73 121 3 14 12 28 20 31 11 4 14 27 14 95 l0 90
-42 41 c-75 70 -215 105 -326 81 -28 -6 -97 -33 -153 -60 -140 -69 -188 -78
-312 -57 -73 12 -120 27 -178 56 -90 45 -138 85 -113 94 9 3 25 1 36 -5 11 -6
32 -8 46 -5 20 5 23 4 12 -4 -8 -5 -24 -10 -35 -10 -18 0 -17 -2 5 -17 13 -9
36 -18 50 -19 14 -1 41 -11 60 -23 19 -11 48 -21 64 -21 26 0 28 2 21 28 -14
57 4 160 26 146 5 -3 9 0 9 7 0 22 98 108 152 134 28 14 64 25 80 25 15 0 28
5 28 10 0 6 16 10 36 10 21 0 33 4 29 10 -4 6 7 10 26 10 70 0 141 36 167 86
12 22 3 74 -17 97 -5 7 -14 28 -19 47 -8 27 -10 30 -11 11 -1 -20 -3 -22 -16
-11 -9 7 -14 19 -11 27 3 8 -1 31 -9 51 -9 20 -13 43 -10 51 3 9 2 12 -2 7 -4
-4 -12 18 -18 50 -7 42 -15 58 -27 60 -15 2 -16 -1 -6 -19 10 -20 10 -21 -5
-8 -15 12 -22 10 -52 -13 -19 -15 -35 -34 -35 -42 0 -7 -5 -14 -11 -14 -5 0
-8 4 -5 9 4 5 0 12 -6 14 -10 4 -10 6 0 6 17 1 53 38 111 115 57 75 76 144 68
241 -9 108 -37 162 -146 277 -145 155 -160 212 -85 345 35 63 35 80 -1 61z
m-7155 -1030 c0 -6 -6 -5 -15 2 -8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4 15 -11
15 -16z m7331 -8 c24 -13 25 -30 2 -30 -19 1 -53 22 -53 34 0 9 29 7 51 -4z
m-151 -37 c0 -15 -2 -15 -9 -4 -8 12 -12 12 -25 2 -8 -7 -21 -11 -28 -8 -8 4
-7 6 5 6 9 1 17 6 17 11 0 6 9 10 20 10 12 0 20 -7 20 -17z m-7170 -3 c13 -8
13 -10 -2 -10 -9 0 -20 5 -23 10 -8 13 5 13 25 0z m237 -46 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m53 -49 c0 -8 -4 -15 -10 -15 -5 0 -10 7
-10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z m6707 9 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-80 -40 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-6520 -20 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z
m6506 -1 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m521 -39 c3
-8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m-6921 -21 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m84 -53 c30 -23 18 -27 -15 -4 -12 8
-27 12 -34 8 -7 -4 -8 -3 -4 4 10 17 24 15 53 -8z m-710 -36 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m740 -26 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-614 -35 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0
19 -2 13 -5z m7187 -3 c-21 -7 -18 -8 20 -11 34 -1 38 -3 16 -6 -15 -3 -40 -2
-54 2 -21 5 -23 8 -11 16 7 5 23 8 34 8 16 -1 16 -2 -5 -9z m138 3 c-10 -2
-28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m-150 -40 c-10 -2 -28 -2 -40
0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z m-71 -19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-580 -96 c-2 -13 -4 -3 -4 22 0 25 2 35 4 23 2 -13 2
-33 0 -45z m-5787 -17 c0 -9 -7 -26 -16 -37 -14 -17 -15 -17 -10 -1 3 10 6 30
6 43 0 14 4 22 10 19 6 -3 10 -14 10 -24z m5820 -12 c0 -11 -4 -17 -10 -14 -5
3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m20 -44 c0 -8 -2 -15 -4
-15 -2 0 -6 7 -10 15 -3 8 -1 15 4 15 6 0 10 -7 10 -15z m-5889 -18 c-10 -9
-11 -8 -5 6 3 10 9 15 12 12 3 -3 0 -11 -7 -18z m6144 -77 c4 -6 -8 -10 -30
-10 -22 0 -34 4 -30 10 3 6 17 10 30 10 13 0 27 -4 30 -10z m58 -40 c3 -13 0
-20 -8 -20 -17 0 -28 17 -20 30 10 16 22 12 28 -10z m-6380 -387 c-7 -2 -19
-2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m6197 3 c0 -2 -7 -7 -16 -10 -8 -3
-12 -2 -9 4 6 10 25 14 25 6z m64 -17 c-8 -14 -24 -10 -24 6 0 9 6 12 15 9 8
-4 12 -10 9 -15z m-6309 -6 c-11 -3 -24 -9 -29 -14 -6 -5 -19 -9 -30 -9 -12 0
-29 -7 -39 -15 -11 -7 -25 -11 -32 -9 -7 3 -16 1 -20 -5 -3 -6 -19 -11 -35
-11 -16 0 -32 -4 -35 -10 -3 -5 -19 -10 -36 -10 -16 0 -29 -4 -29 -8 0 -9
-173 -32 -242 -32 -27 0 -48 -4 -48 -10 0 -18 -72 -11 -89 9 -9 11 -30 21 -46
23 -30 3 -48 28 -21 28 9 0 16 -4 16 -10 0 -5 13 -10 28 -10 15 0 33 -5 39
-11 16 -16 202 -5 313 20 52 11 140 36 195 55 55 18 114 34 130 34 20 0 23 -2
10 -5z m6402 -4 c73 -30 109 -42 168 -57 22 -6 59 -15 81 -21 23 -6 53 -11 67
-11 14 0 29 -4 32 -10 3 -6 52 -10 112 -10 59 0 104 -3 100 -6 -10 -11 -251
-6 -255 6 -2 5 -17 10 -34 10 -39 0 -111 21 -120 35 -5 6 -8 6 -8 -2 0 -16
-27 -17 -32 -2 -2 6 -15 12 -30 12 -14 1 -24 4 -22 6 6 6 -76 44 -111 52 l-30
6 27 2 c15 0 40 -4 55 -10z m-697 -29 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3
6 8 10 11 10 2 0 4 -4 4 -10z m-5023 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m-720 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m750 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m4940
0 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1493 2 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-35 -16 c-3 -5
-18 -9 -33 -9 l-27 1 30 8 c17 4 31 8 33 9 2 0 0 -3 -3 -9z m-6340 -38 c-3 -3
-11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m6304 14 c-2 -2 -26 -6 -54
-9 -32 -4 -43 -3 -30 3 19 8 93 14 84 6z m28 -22 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-7120 -40 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m5538 -3 c3 -5 1 -12 -4 -15 -5 -3 -11 1 -15 9 -6 16 9 21 19
6z m-4619 -59 c-6 -5 -36 23 -36 34 0 4 9 -1 20 -11 11 -10 18 -20 16 -23z
m50 -47 c1 -8 -2 -13 -7 -9 -5 3 -9 12 -9 21 0 19 13 9 16 -12z m72 -60 c12
-14 17 -25 12 -25 -6 0 -13 7 -16 15 -4 8 -18 15 -33 15 -20 0 -23 2 -11 10
21 14 24 13 48 -15z m5842 -5 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z m-5913 -32 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m-107 -13 c0 -7 6 -15 13 -18 9 -3 9 -7 -2 -20 -12 -15 -11 -17
6 -17 27 0 34 -9 22 -31 -11 -21 -49 -26 -50 -6 0 9 -2 9 -6 -1 -2 -6 -10 -9
-15 -6 -7 4 -8 2 -4 -4 4 -7 18 -12 32 -12 13 0 24 -4 24 -9 0 -5 7 -12 16
-15 13 -5 15 -2 11 14 -4 15 0 20 13 20 26 0 32 -14 25 -54 -5 -25 -31 -59
-95 -125 -49 -50 -93 -91 -99 -91 -6 0 -11 -13 -11 -30 0 -43 39 -42 65 3 34
59 120 166 133 167 10 0 13 -31 11 -142 -2 -181 -6 -208 -30 -208 -11 0 -19 5
-19 10 0 6 4 10 9 10 16 0 19 50 4 58 -11 6 -12 14 -4 35 7 17 7 28 1 32 -6 4
-10 17 -10 31 0 14 -4 22 -10 19 -5 -3 -8 -33 -7 -66 2 -33 0 -62 -5 -65 -4
-3 -8 0 -8 5 0 6 -7 11 -15 11 -8 0 -15 -9 -15 -20 0 -11 -7 -20 -15 -20 -20
0 -19 14 1 35 14 13 14 18 2 33 -7 9 -17 20 -23 24 -13 10 -26 -14 -14 -28 7
-8 2 -10 -18 -7 -22 4 -25 2 -13 -6 13 -9 13 -11 -2 -11 -9 0 -24 -3 -34 -7
-13 -5 -11 1 9 22 31 34 22 52 -18 38 -26 -8 -27 -10 -11 -26 14 -14 15 -17 3
-17 -9 0 -17 3 -19 8 -2 4 -25 19 -53 33 -34 18 -51 34 -56 52 -4 15 -4 27 1
27 13 0 32 -29 25 -40 -3 -5 1 -10 9 -10 10 0 17 9 17 23 l2 22 4 -22 c2 -13
9 -23 14 -23 6 0 7 -4 4 -10 -3 -5 3 -10 15 -10 12 0 18 5 15 10 -3 6 0 10 7
10 7 0 2 9 -12 20 -22 17 -23 19 -7 25 9 4 23 3 30 -3 9 -8 19 -2 37 25 13 19
37 49 52 65 15 17 28 41 28 53 0 13 6 26 13 29 6 4 2 4 -11 2 -14 -3 -25 1
-29 10 -4 11 -9 12 -18 4 -9 -8 -14 -7 -17 2 -3 7 -7 3 -10 -10 -4 -14 -2 -20
5 -15 6 4 8 -2 5 -15 -6 -23 17 -32 24 -10 2 6 10 8 16 4 17 -10 15 -27 -2
-20 -8 3 -28 -8 -45 -27 -17 -17 -31 -26 -31 -19 0 9 -3 10 -14 1 -8 -6 -20
-10 -27 -9 -9 2 -13 -4 -11 -17 2 -17 1 -17 -8 -5 -8 13 -11 13 -19 1 -7 -12
-13 -12 -40 0 -42 19 -39 28 8 22 35 -5 41 -3 54 23 9 16 41 63 71 104 75 100
76 101 104 132 21 23 23 30 12 43 -7 8 -24 15 -39 15 -21 0 -31 -10 -54 -49
-15 -27 -47 -74 -70 -103 -24 -29 -57 -75 -75 -102 -18 -27 -32 -42 -32 -34 0
9 -3 25 -6 35 -5 15 -4 15 5 3 9 -13 11 -13 11 3 0 9 4 17 10 17 5 0 6 7 3 17
-4 11 -3 14 5 9 7 -4 12 -3 12 3 0 5 15 25 33 44 75 80 87 100 74 116 -9 11
-9 13 0 8 7 -5 17 1 23 13 6 12 20 25 32 29 28 10 78 1 78 -14z m260 -10 c0
-8 -4 -15 -10 -15 -5 0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m-460 -19
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m518
-34 c2 -7 -2 -10 -12 -6 -13 5 -15 2 -9 -12 7 -18 6 -18 -12 -1 -15 16 -16 20
-4 28 17 10 31 7 37 -9z m4271 -5 c-25 -12 -59 -32 -76 -44 -17 -13 -38 -23
-46 -23 -7 0 -19 -10 -25 -22 -8 -17 -11 -19 -11 -6 -1 9 -4 23 -7 31 -4 11 0
14 13 11 11 -3 26 3 37 15 11 12 29 21 40 22 12 0 15 3 9 6 -21 8 -15 25 7 18
11 -3 28 -2 37 4 10 6 29 10 43 10 21 -1 17 -4 -21 -22z m-172 7 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m258 -17 c11 -7 21 -19 23 -27 3
-10 -3 -9 -20 7 -13 11 -32 23 -43 26 l-20 5 20 0 c11 1 29 -4 40 -11z m1280
-7 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m-5836 -20 c9 0 22 -5 29 -12 9 -9 12 -9 12 0 0 7 4 12 9 12 10 0 7 -63 -5
-112 -4 -17 -2 -28 4 -28 7 0 9 -21 6 -55 -3 -43 -1 -55 11 -55 8 0 18 7 23
16 7 13 11 14 17 4 5 -9 13 -2 23 21 8 19 15 37 14 40 0 3 14 5 33 4 24 0 40
-9 55 -28 12 -15 26 -27 32 -27 11 0 30 -15 70 -57 12 -13 28 -23 35 -23 18 0
16 25 -3 44 -8 9 -11 16 -5 16 16 0 33 -39 26 -60 -4 -13 -2 -21 7 -22 7 0 18
-2 23 -3 6 -2 15 -3 20 -4 6 -1 3 -6 -5 -11 -8 -5 -10 -10 -5 -11 49 -4 75
-16 75 -34 0 -8 9 -24 20 -35 11 -11 20 -15 20 -9 0 5 5 7 10 4 6 -3 10 -13
10 -21 0 -23 -35 -16 -62 14 -14 15 -47 43 -73 62 -26 19 -66 51 -88 71 -23
20 -52 42 -65 48 -23 12 -23 11 -20 -26 4 -37 6 -38 51 -47 26 -5 47 -14 47
-20 0 -13 -83 -118 -159 -202 -28 -32 -51 -65 -51 -75 0 -10 -8 -23 -17 -29
-15 -8 -12 -9 12 -4 51 10 68 23 125 97 67 87 140 167 152 167 5 0 12 -8 15
-17 4 -10 13 -24 20 -31 7 -8 11 -17 9 -20 -2 -4 10 -8 27 -8 17 -1 33 -6 34
-11 2 -5 28 -8 58 -6 40 2 53 -1 49 -10 -3 -8 13 -23 36 -36 22 -12 40 -19 40
-16 0 4 12 -7 26 -24 14 -17 32 -31 39 -31 7 0 12 -10 11 -22 -1 -15 4 -22 13
-20 8 2 17 -2 21 -8 4 -6 26 -11 49 -12 37 0 40 -2 24 -14 -17 -12 -17 -13 5
-14 16 0 22 6 22 21 0 18 2 19 15 9 14 -12 13 -15 -4 -32 -18 -16 -26 -17 -66
-7 -28 7 -45 17 -45 26 0 11 -7 14 -25 10 -22 -4 -28 1 -39 28 -12 28 -45 59
-146 133 -25 18 -41 7 -80 -55 -19 -30 -57 -81 -85 -112 -27 -31 -60 -70 -72
-86 -45 -60 -17 22 30 88 15 22 22 26 28 15 6 -9 9 -7 9 9 0 12 9 28 20 36 14
10 18 21 14 42 -3 15 -1 31 3 34 4 3 14 16 21 29 11 19 10 22 -5 23 -10 1 -23
1 -29 0 -7 -1 -15 9 -18 21 -5 14 -9 18 -13 11 -2 -7 -10 -13 -17 -13 -7 0
-12 -10 -12 -22 1 -15 -6 -24 -19 -26 -17 -4 -18 -2 -6 12 7 9 9 16 4 16 -5 0
-17 -15 -28 -32 -10 -18 -24 -36 -31 -40 -7 -5 -10 -18 -8 -30 2 -13 2 -17 -2
-10 -9 19 -38 14 -52 -8 -11 -18 -10 -20 19 -20 19 0 28 -4 24 -11 -4 -6 -14
-8 -22 -5 -20 8 -64 -4 -57 -15 5 -9 -22 -13 -58 -10 -9 1 -19 -3 -23 -9 -3
-6 3 -10 17 -10 16 0 19 -3 10 -9 -11 -7 -11 -9 2 -14 24 -9 19 -23 -6 -22
-30 0 -50 14 -42 27 4 6 -6 21 -22 34 l-29 23 25 1 c20 0 30 11 50 53 14 28
23 58 22 66 -2 7 1 10 6 7 21 -12 44 6 38 30 -7 25 5 36 37 38 21 1 38 26 18
26 -6 0 -11 -6 -11 -12 0 -9 -4 -8 -10 2 -8 12 -10 12 -10 1 0 -8 -3 -11 -7
-8 -3 4 7 20 23 36 21 21 32 27 41 19 10 -8 13 -5 13 11 0 15 -6 21 -22 21
-12 0 -18 3 -15 6 9 9 -31 57 -49 60 -12 2 -13 -1 -3 -12 10 -12 6 -20 -17
-43 -27 -27 -30 -28 -47 -13 -16 15 -17 15 -11 -1 3 -9 -1 -23 -9 -32 -22 -21
-30 -19 -16 6 11 21 11 21 -14 3 -14 -11 -22 -23 -18 -29 5 -5 11 -18 15 -29
6 -17 5 -18 -17 -3 -22 14 -25 14 -50 -12 -14 -15 -32 -28 -40 -28 -9 0 1 17
26 41 88 87 202 218 202 234 0 18 -15 25 -30 14 -6 -4 -32 -36 -58 -72 -66
-89 -75 -100 -87 -100 -6 0 -11 66 -12 166 -3 176 -2 173 -51 188 -11 4 -15
11 -11 18 6 9 9 9 12 0 2 -7 11 -12 21 -12z m4498 -36 c13 -8 29 -27 37 -43
15 -28 14 -29 -9 -34 -14 -2 -25 0 -25 4 0 5 -4 9 -10 9 -20 0 -10 -39 13 -46
12 -3 33 -14 47 -24 31 -21 139 -60 167 -60 12 0 23 8 26 20 3 11 10 20 16 20
17 0 13 -15 -9 -35 -24 -22 -26 -45 -3 -45 14 0 15 -2 4 -12 -11 -10 -14 -10
-18 0 -7 19 -40 14 -46 -8 -7 -27 -17 -25 -55 10 -30 27 -33 28 -26 8 4 -13
14 -28 22 -34 23 -17 -2 -74 -32 -74 -17 0 -18 2 -6 10 17 11 10 13 -14 4 -9
-3 -16 -17 -16 -31 0 -30 -33 -29 -38 2 -5 24 -36 55 -54 55 -7 0 -1 -12 15
-25 33 -29 33 -32 3 -32 -18 0 -23 4 -19 16 3 9 -2 6 -11 -7 -21 -27 -14 -41
16 -35 28 5 57 -32 39 -50 -8 -8 -11 -6 -11 6 0 9 -6 17 -13 17 -22 0 -28 -12
-14 -28 10 -13 9 -14 -9 -8 -16 5 -24 2 -29 -11 -7 -17 -8 -17 -15 2 -7 17 -9
14 -9 -12 -1 -32 -8 -38 -40 -33 -12 1 -12 -1 -3 -12 9 -12 5 -17 -23 -27 -19
-7 -40 -9 -46 -5 -8 4 -9 3 -5 -4 5 -8 -5 -12 -33 -12 -23 0 -41 -4 -41 -10 0
-5 -15 -11 -32 -11 -24 -1 -27 -3 -10 -6 12 -2 20 -9 17 -14 -8 -12 -2 -11 29
4 14 8 23 17 19 20 -3 4 3 7 15 7 13 0 22 -6 22 -15 0 -8 -9 -15 -19 -15 -33
0 -42 -48 -14 -76 17 -16 16 -17 -12 -12 -26 5 -28 5 -12 -7 16 -12 13 -13
-25 -8 -24 3 -45 8 -48 10 -7 7 40 43 56 43 8 0 14 5 14 10 0 6 -4 10 -9 10
-5 0 -16 3 -25 6 -12 4 -16 0 -16 -15 0 -11 -9 -26 -19 -33 -10 -6 -17 -18
-14 -25 3 -9 0 -11 -10 -7 -8 3 -18 0 -23 -7 -5 -9 3 -10 33 -6 35 6 41 4 46
-12 5 -20 -9 -41 -27 -41 -6 0 -4 5 4 10 13 8 13 10 -1 10 -9 0 -31 3 -48 6
-27 6 -30 4 -25 -10 4 -10 13 -15 21 -12 8 3 11 1 8 -4 -3 -6 -17 -10 -31 -10
-22 0 -24 -3 -18 -22 6 -22 6 -22 -14 2 -20 24 -21 24 -49 9 -31 -18 -108 -36
-83 -19 8 5 26 10 40 10 20 0 22 2 10 10 -13 9 -13 11 0 19 105 64 190 147
175 171 -11 18 -55 12 -56 -7 -2 -49 -68 -113 -102 -100 -9 3 -28 25 -43 49
-15 24 -51 69 -81 100 -73 77 -70 92 25 137 50 24 72 41 72 53 0 30 -30 21
-101 -31 -38 -28 -82 -57 -99 -66 -34 -18 -38 -38 -12 -57 38 -27 192 -224
192 -245 0 -25 -19 -13 -36 23 -10 20 -37 57 -61 83 -24 25 -43 48 -43 52 0 3
-10 19 -21 33 -16 21 -24 24 -30 15 -5 -9 -10 -5 -14 13 -5 18 -13 26 -26 25
-10 -1 -19 -4 -19 -8 0 -8 51 -43 63 -44 23 -1 37 -77 16 -90 -7 -5 -8 4 -4
28 4 21 3 37 -4 41 -6 4 -11 2 -11 -4 0 -6 -4 -9 -9 -6 -5 4 -12 0 -15 -6 -3
-7 -3 -3 0 10 4 16 1 22 -11 22 -13 0 -16 -6 -12 -22 27 -113 40 -153 50 -160
9 -6 9 -10 1 -15 -7 -5 -14 -17 -17 -28 -3 -15 -5 -12 -6 10 -1 17 -12 57 -25
90 l-23 60 -7 -52 c-6 -41 -4 -61 13 -99 12 -26 19 -49 17 -51 -8 -8 -186 100
-215 131 -32 34 -51 33 -76 -3 -10 -16 -22 -29 -25 -28 -3 1 -32 -17 -65 -39
-33 -23 -70 -44 -82 -48 -13 -4 -48 -27 -78 -52 -30 -25 -55 -39 -55 -32 0 20
55 78 74 78 9 0 16 5 16 10 0 6 9 10 20 10 11 0 20 5 20 10 0 6 9 10 21 10 15
0 19 4 15 16 -5 12 -2 15 10 10 12 -5 15 -2 10 9 -4 11 3 17 24 22 17 3 30 10
30 15 0 5 5 6 10 3 16 -10 12 22 -5 39 -13 13 -13 15 3 19 9 2 25 -3 35 -12
16 -13 19 -13 33 7 18 26 42 29 51 6 3 -8 17 -20 31 -25 14 -5 22 -12 18 -16
-10 -10 15 -33 35 -33 15 0 19 7 19 35 0 19 5 35 10 35 12 0 12 -2 1 -57 -8
-39 -7 -41 10 -32 15 8 18 6 13 -8 -4 -13 0 -12 15 6 22 25 30 101 10 101 -15
0 -39 26 -38 42 0 10 2 10 6 1 2 -7 17 -13 33 -13 24 0 29 5 35 36 3 20 4 38
1 41 -10 10 -66 -19 -66 -34 0 -20 -23 -11 -26 10 -1 10 4 17 14 17 15 0 15 2
2 10 -12 8 -9 10 13 10 22 0 30 6 38 30 12 32 36 40 46 15 4 -12 9 -10 19 11
12 22 12 30 2 43 -12 14 -11 14 5 2 11 -9 17 -10 17 -2 0 6 6 11 14 11 7 0 26
10 40 21 22 17 25 24 14 31 -10 8 -9 9 4 4 20 -6 34 11 30 37 -4 19 13 23 23
6 8 -12 86 -2 95 12 3 4 12 4 20 -1 11 -7 12 -10 2 -10 -7 0 -11 -4 -7 -10 3
-5 -1 -25 -9 -45 -9 -20 -11 -33 -5 -29 6 4 15 0 20 -7 6 -10 9 -6 9 14 0 19
4 26 12 21 8 -5 10 7 5 44 -4 37 -3 52 6 53 8 0 8 2 -1 6 -9 3 -5 12 13 31 14
14 28 36 31 49 4 12 13 23 21 23 14 0 43 23 43 34 0 3 -6 6 -14 6 -8 0 -18 4
-21 10 -4 6 4 6 22 -1 19 -7 29 -7 31 0 5 14 87 14 113 0 11 -5 32 -11 47 -13
15 -1 26 -7 25 -14 -2 -7 13 -10 42 -9 25 1 45 2 45 3 0 1 -14 15 -30 33 -24
25 -32 28 -36 16 -4 -8 -10 -15 -16 -15 -5 0 -6 5 -3 10 9 14 -8 13 -23 -2 -9
-9 -12 -9 -12 0 0 11 6 14 39 16 24 2 25 26 1 26 -12 0 -19 4 -16 9 4 5 1 11
-6 13 -9 3 -9 6 -1 11 23 14 127 -112 181 -219 22 -43 42 -57 66 -48 33 13 26
51 -16 95 -44 47 -138 191 -138 212 0 39 46 43 97 11z m-4262 -6 c6 -4 2 -8
-7 -8 -10 0 -18 7 -18 17 0 9 3 12 8 7 4 -5 12 -12 17 -16z m4360 12 c-3 -6 1
-13 10 -16 13 -5 14 -9 4 -15 -10 -6 -9 -9 6 -14 55 -18 60 -24 18 -22 -31 1
-43 6 -43 17 0 8 -3 25 -6 38 -4 13 -2 22 5 22 7 0 9 -5 6 -10z m-4288 -37 c0
-7 -6 -13 -14 -13 -7 0 -13 6 -13 13 0 8 6 14 13 14 8 0 14 -6 14 -14z m4324
-55 c10 -11 31 -18 53 -18 20 0 36 -4 36 -10 0 -5 6 -10 14 -10 8 0 17 -4 20
-9 3 -5 -15 -4 -42 0 -93 18 -142 37 -142 55 0 19 41 13 61 -8z m-5574 -44
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1295 -6 c-9 -9 -15 -9
-24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z m3678 8 c0 -11 -19 -15 -25 -6 -3
5 1 10 9 10 9 0 16 -2 16 -4z m-4537 -63 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5
14 0 19 -2 13 -5z m966 -35 l-1 -33 -11 27 c-6 15 -18 29 -26 31 -9 3 -4 5 12
6 24 1 27 -2 26 -31z m100 -8 c4 0 16 -8 27 -18 10 -10 30 -26 44 -35 22 -16
23 -17 4 -14 -11 1 -31 13 -44 25 -13 12 -30 20 -38 17 -7 -2 -10 0 -7 5 3 6
0 10 -8 10 -20 0 -40 26 -32 40 5 8 13 5 26 -9 11 -12 23 -21 28 -21z m4278
24 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-940 -10 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-4324 -11 c-7 -2 -19 -2 -25
0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m937 -44 c0 -5 -7 -9 -15 -9 -15 0 -20 12
-9 23 8 8 24 -1 24 -14z m3424 -14 c-4 -8 -10 -15 -16 -15 -5 0 -7 4 -3 9 3 5
-5 12 -17 14 -17 3 -15 5 9 6 25 1 31 -2 27 -14z m-3374 -4 c0 -11 -26 -22
-34 -14 -12 12 -5 23 14 23 11 0 20 -4 20 -9z m4215 -1 c-3 -5 -10 -10 -16
-10 -5 0 -9 5 -9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-70 -24 c8 -7 12 -16 9
-19 -10 -9 -34 4 -34 19 0 18 8 18 25 0z m-4485 -31 c0 -8 -4 -15 -10 -15 -5
0 -7 7 -4 15 4 8 8 15 10 15 2 0 4 -7 4 -15z m382 -10 c0 -5 -5 -11 -11 -13
-6 -2 -11 4 -11 13 0 9 5 15 11 13 6 -2 11 -8 11 -13z m-62 1 c0 -11 -19 -15
-25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m3167 -2 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m950 -10 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-897 -8 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11
6 0 10 -2 10 -4z m847 -2 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13
-5z m-4795 -16 c-9 -9 -15 -9 -24 0 -9 9 -7 12 12 12 19 0 21 -3 12 -12z
m3855 -18 c3 -10 2 -11 -4 -4 -4 6 -15 14 -23 17 -11 5 -9 6 4 4 11 -1 21 -9
23 -17z m123 10 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4
-4 4 -10z m798 -10 c-7 -11 -16 -20 -20 -20 -5 0 -3 9 4 20 7 11 16 20 20 20
5 0 3 -9 -4 -20z m-3808 0 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4
10 6 0 11 -4 11 -10z m-857 -67 c7 -9 5 -13 -6 -13 -16 0 -30 26 -22 40 3 4
-5 6 -17 2 l-23 -5 20 22 19 23 10 -29 c5 -15 13 -34 19 -40z m3697 63 c0 -3
-4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m-3435 -44
c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9 12 -12z m3392 2 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2 -55 c-4 -5 -12 -9 -18 -8 -9 0
-8 2 1 6 6 2 9 10 6 15 -4 7 -2 8 5 4 6 -4 9 -12 6 -17z m885 1 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-3792 -43 c23
-23 42 -48 42 -54 0 -6 7 -13 15 -17 19 -7 19 -26 0 -26 -8 0 -17 10 -20 23
-4 12 -18 30 -32 41 -25 18 -25 18 -14 -3 10 -18 8 -21 -14 -27 -14 -3 -25
-10 -25 -16 0 -5 10 -6 23 -2 12 3 19 3 15 -1 -5 -5 0 -14 10 -21 19 -13 48
-91 38 -102 -3 -3 -6 0 -6 7 0 7 -6 19 -14 26 -12 12 -16 12 -26 0 -10 -12
-10 -18 1 -32 13 -15 11 -16 -23 -10 -21 3 -40 1 -43 -4 -4 -5 0 -9 9 -9 19 0
25 -19 15 -47 -5 -13 -6 -30 -3 -38 4 -10 0 -15 -11 -15 -9 0 -19 -9 -22 -20
-5 -20 -33 -29 -33 -10 0 6 -7 10 -15 10 -9 0 -28 11 -42 24 -22 20 -30 23
-47 14 -17 -9 -19 -8 -8 3 6 7 12 20 11 29 0 11 -2 12 -6 1 -4 -10 -11 -7 -27
10 -11 13 -32 24 -46 24 -18 0 -25 5 -24 18 1 9 -4 17 -12 17 -8 0 -14 9 -13
23 1 14 3 16 6 6 2 -9 22 -22 44 -29 22 -8 55 -31 74 -52 19 -21 39 -38 44
-38 5 0 14 -7 21 -15 18 -22 53 -18 75 8 22 25 30 57 15 57 -19 0 -25 -5 -22
-17 3 -15 -44 -18 -53 -3 -3 6 -15 10 -25 10 -10 0 -32 12 -49 26 -37 30 -36
32 47 144 51 69 108 130 122 130 3 0 25 -19 48 -43z m3761 -3 c-14 -18 -24
-11 -15 11 3 9 11 13 16 10 8 -5 7 -11 -1 -21z m-4052 -16 c-3 -7 -5 -2 -5 12
0 14 2 19 5 13 2 -7 2 -19 0 -25z m3070 5 c-3 -3 -11 -2 -17 2 -9 6 -9 10 1
16 14 8 27 -7 16 -18z m938 -13 c3 -5 -1 -10 -10 -10 -9 0 -14 -4 -11 -9 9
-13 -26 -23 -38 -11 -7 7 -6 10 2 10 6 0 9 7 6 15 -5 11 1 15 19 15 14 0 29
-4 32 -10z m-1013 -20 c-12 -5 -25 -16 -28 -25 -4 -8 -13 -15 -22 -15 -10 0
-7 8 12 25 15 14 35 25 44 24 12 0 11 -3 -6 -9z m118 0 c8 -5 11 -10 5 -10 -5
0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z m-3130 -9 c0 -6 -4 -13
-10 -16 -5 -3 -10 1 -10 9 0 9 5 16 10 16 6 0 10 -4 10 -9z m3946 -22 c3 -6 2
-15 -3 -20 -13 -13 -43 -1 -43 17 0 16 36 19 46 3z m-1003 -26 c-7 -2 -19 -2
-25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m234 -15 c-3 -8 -6 -5 -6 6 -1 11 2
17 5 13 3 -3 4 -12 1 -19z m-2730 -7 c-8 -32 -100 -173 -139 -214 -20 -21 -39
-51 -43 -67 -3 -15 -13 -31 -22 -34 -8 -4 -13 -13 -9 -21 3 -9 0 -15 -9 -15
-8 0 -15 3 -15 8 0 4 -14 13 -32 21 -28 13 -30 15 -12 22 16 6 14 8 -10 8 -24
1 -26 3 -16 16 10 12 15 12 33 -5 11 -11 32 -20 45 -20 24 0 24 2 18 46 -4 29
-2 43 4 39 6 -4 8 -11 5 -16 -4 -5 -1 -9 4 -9 6 0 11 7 11 16 0 8 4 13 9 10 4
-3 17 4 27 16 11 12 14 18 7 15 -6 -4 -14 -3 -18 3 -3 5 2 10 12 11 10 0 13 3
6 6 -20 8 -15 23 7 23 11 0 20 -6 20 -12 0 -9 4 -8 10 2 13 20 13 33 0 25 -5
-3 -10 -1 -10 4 0 6 5 11 11 11 5 0 7 5 4 10 -3 6 -2 10 4 10 5 0 11 -5 13
-12 3 -7 7 -6 11 5 3 9 2 17 -3 17 -6 0 -10 7 -10 16 0 12 3 14 13 6 10 -8 16
-6 25 9 7 11 10 24 7 29 -3 6 1 7 9 4 11 -4 16 0 16 15 0 12 5 21 11 21 6 0 9
-9 6 -19z m-57 5 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z
m-506 -20 c-14 -11 -21 -11 -26 -3 -6 9 -8 8 -8 -1 0 -7 -5 -10 -11 -6 -15 9
9 24 40 24 24 0 24 -1 5 -14z m696 -9 c0 -17 -2 -18 -17 -6 -13 11 -17 11 -21
1 -2 -7 -10 -19 -19 -27 -8 -8 -11 -15 -7 -15 3 0 -11 -18 -32 -40 -22 -22
-41 -52 -44 -67 -3 -16 -15 -32 -27 -38 -27 -11 -36 -31 -27 -57 5 -17 7 -17
17 -4 10 13 14 13 24 1 11 -11 10 -16 -4 -23 -13 -8 -11 -12 19 -26 19 -9 39
-16 44 -15 21 4 44 -2 44 -10 0 -16 -84 1 -128 26 -52 30 -58 42 -38 81 18 34
100 147 147 202 34 39 69 48 69 17z m-730 -27 c0 -5 -7 -7 -15 -4 -8 4 -15 8
-15 10 0 2 7 4 15 4 8 0 15 -4 15 -10z m730 -10 c0 -5 -2 -10 -4 -10 -3 0 -8
5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m2907 4 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m-3577 -14 c0 -5 -5 -10 -11 -10 -5 0 -7 -4 -4
-10 3 -6 -1 -7 -10 -4 -12 5 -13 9 -4 20 13 16 29 18 29 4z m990 6 c0 -3 -4
-8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m1955 -25 c-6
-5 -25 10 -25 20 0 5 6 4 14 -3 8 -7 12 -15 11 -17z m246 -3 c22 -12 39 -25
39 -29 0 -4 9 -11 20 -14 11 -3 26 -12 32 -18 16 -15 100 -63 150 -85 39 -18
58 -42 33 -42 -16 0 -82 37 -108 61 -10 9 -35 22 -57 29 -22 7 -40 19 -40 26
0 13 -22 24 -51 24 -10 0 -18 4 -16 9 1 5 -1 9 -5 10 -20 3 -29 0 -25 -6 3 -5
-1 -14 -9 -21 -9 -8 -14 -9 -14 -2 0 6 -7 4 -16 -5 -9 -8 -24 -13 -34 -10 -13
4 -19 -1 -23 -21 -3 -16 0 -27 9 -30 52 -16 50 -15 32 -25 -10 -6 -18 -15 -18
-20 0 -6 5 -7 10 -4 6 3 10 -4 10 -16 0 -22 0 -22 16 -3 14 18 15 17 11 -13
-2 -18 0 -33 5 -33 5 0 7 -4 4 -9 -3 -5 3 -20 14 -34 11 -14 20 -30 20 -35 0
-6 5 -14 12 -18 8 -5 9 -2 5 10 -4 10 -3 15 3 11 13 -8 13 -55 -1 -55 -5 0 -7
-4 -4 -10 3 -5 -1 -10 -9 -10 -10 0 -13 6 -10 15 4 8 -2 27 -12 43 -10 15 -29
47 -42 72 -14 25 -30 52 -37 60 -24 29 -65 107 -65 123 0 10 8 17 19 17 41 0
81 22 81 44 0 42 16 45 71 14z m-2158 -5 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5
17 0 24 -2 18 -5z m-133 -7 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11
10 11 6 0 10 -2 10 -4z m2970 -6 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6
-1 10 4 10 6 0 11 -4 11 -10z m-1053 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
11 1 17 -2 13 -5z m813 -14 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z m-2753 -22 c12 -6 20 -15 17 -20 -3 -4 0 -8 5 -8 19 0 12
-16 -29 -68 -44 -55 -48 -64 -23 -55 11 4 14 3 9 -6 -4 -6 -15 -10 -24 -9 -11
2 -17 -3 -16 -14 1 -10 -2 -16 -6 -13 -4 3 -11 -2 -14 -11 -4 -9 -9 -14 -12
-11 -5 5 9 34 29 61 4 6 23 35 43 65 35 51 36 55 18 68 -29 21 -48 15 -70 -25
-104 -185 -126 -221 -144 -237 -30 -27 -36 -10 -7 19 14 14 22 27 19 30 -3 3
0 6 7 6 8 0 11 6 7 16 -3 8 -2 13 3 10 8 -5 51 73 51 93 0 5 7 11 15 15 8 3
14 9 13 13 -2 11 35 73 45 73 4 0 7 7 7 15 0 15 14 13 57 -7z m-287 -10 c0
-10 -4 -18 -9 -18 -5 0 -11 8 -13 18 -3 11 0 19 8 19 8 0 14 -9 14 -19z m2150
12 c0 -5 -8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z m828 -10 c13 0
12 -3 -5 -15 -12 -8 -23 -27 -25 -40 -4 -32 -22 -34 -25 -3 -2 21 -1 21 6 -2
7 -21 9 -17 16 30 4 30 10 49 12 43 3 -7 12 -13 21 -13z m-3473 2 c11 -8 18
-32 9 -32 -13 0 -44 21 -44 30 0 12 21 13 35 2z m3390 0 c12 -9 18 -102 7
-102 -5 0 -13 20 -19 45 -7 33 -7 45 1 45 6 0 4 5 -4 10 -8 5 -10 10 -5 10 6
0 15 -4 20 -8z m-2818 -24 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m2574 -7 c-8 -5 -21 -6 -30 -3 -14 6 -14 7 2 10 9 2 17 9 17 15 0 7 4 7
12 -1 10 -10 10 -15 -1 -21z m506 23 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1
17 -2 13 -5z m-3607 -35 c0 -5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9
6 -3 10 -10 10 -16z m1075 3 c-3 -3 -11 0 -18 7 -9 10 -8 11 6 5 10 -3 15 -9
12 -12z m2495 8 c0 -5 -7 -10 -16 -10 -8 0 -12 5 -9 10 3 6 10 10 16 10 5 0 9
-4 9 -10z m-545 -9 c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z
m-2972 -28 c5 -26 4 -26 -9 -8 -16 22 -18 35 -5 35 5 0 11 -12 14 -27z m2781
-2 c20 -22 146 -232 146 -243 0 -21 -21 -4 -37 30 -9 20 -22 40 -27 44 -6 4
-7 8 -2 8 5 0 1 9 -9 20 -14 16 -21 18 -27 8 -6 -9 -8 -7 -4 7 6 23 -44 101
-63 98 -6 -2 -11 3 -11 10 0 7 -5 5 -11 -5 -12 -22 -72 -51 -96 -46 -23 4 -30
38 -8 38 11 0 15 -5 11 -15 -11 -29 20 -15 54 25 38 44 58 49 84 21z m-212
-23 c-9 -9 -12 -7 -12 12 0 19 3 21 12 12 9 -9 9 -15 0 -24z m-1852 2 c0 -5
-8 -10 -17 -10 -15 0 -16 2 -3 10 19 12 20 12 20 0z m2640 -31 c-12 -42 -18
-46 -17 -12 1 23 12 42 24 43 1 0 -2 -14 -7 -31z m-957 9 c7 -7 12 -20 12 -28
0 -8 8 -16 18 -18 15 -3 18 0 12 17 -8 27 15 28 39 2 17 -19 17 -20 -21 -30
-21 -6 -58 -22 -82 -36 -52 -30 -56 -31 -58 -6 -1 14 3 18 13 14 8 -3 14 0 14
6 0 6 9 11 20 11 23 0 41 31 22 38 -7 2 -12 12 -12 23 0 22 7 24 23 7z m135
-11 c2 -10 -3 -17 -12 -17 -9 0 -16 2 -16 4 0 2 -3 12 -6 21 -5 13 -2 16 12
13 10 -2 20 -11 22 -21z m-250 6 c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0
27 -2 18 -5z m-2009 -75 c24 -21 54 -40 65 -43 12 -2 35 -7 51 -11 l30 -7 -39
-9 c-32 -7 -41 -5 -52 9 -7 10 -14 14 -14 10 0 -5 -6 -2 -14 5 -38 37 -68 58
-81 58 -8 0 -15 7 -15 15 0 17 -9 19 -29 6 -7 -5 -10 -14 -7 -20 10 -16 -3
-14 -20 3 -12 13 -11 15 5 21 13 4 18 12 14 22 -9 23 51 -10 106 -59z m695 57
c27 -9 45 -11 57 -4 19 10 35 -4 24 -21 -3 -6 1 -13 9 -16 9 -3 13 -11 10 -16
-4 -6 6 -9 22 -8 48 3 64 1 64 -11 0 -7 -10 -9 -24 -7 -19 4 -22 1 -18 -16 5
-19 5 -19 -25 2 -17 12 -36 22 -42 22 -10 0 -52 22 -98 51 -29 18 -49 6 -63
-38 -7 -21 -41 -93 -75 -160 -48 -94 -65 -119 -76 -112 -12 8 -12 11 1 20 11
8 12 12 3 16 -15 5 -18 43 -4 43 5 0 11 -6 14 -12 4 -13 40 47 41 68 1 6 9 18
19 28 9 10 17 29 17 42 0 14 4 23 9 20 5 -3 17 17 28 46 11 31 25 53 36 56 15
4 16 7 5 13 -21 14 22 10 66 -6z m2441 -5 c-3 -5 -10 -10 -16 -10 -5 0 -9 5
-9 10 0 6 7 10 16 10 8 0 12 -4 9 -10z m-2187 -17 c-10 -2 -28 -2 -40 0 -13 2
-5 4 17 4 22 1 32 -1 23 -4z m1328 -35 c1 -36 -9 -36 -23 1 -9 25 -8 29 6 28
10 0 16 -11 17 -29z m42 21 c-2 -6 -8 -10 -13 -10 -5 0 -11 4 -13 10 -2 6 4
11 13 11 9 0 15 -5 13 -11z m422 -36 c0 -7 -4 -13 -9 -13 -10 0 -21 25 -21 48
1 15 3 14 15 -2 8 -11 15 -26 15 -33z m307 31 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m-847 -44 c0 -5 8 -11 18 -13 9 -3 2 -5 -16 -6 -22 -1
-31 3 -26 10 4 7 1 10 -7 7 -8 -3 -14 -11 -14 -19 0 -8 -10 -15 -23 -17 -12
-2 -20 -8 -17 -13 4 -5 1 -9 -6 -9 -8 0 -9 -11 -5 -32 4 -23 3 -28 -4 -18 -15
24 -31 -2 -24 -38 4 -18 10 -32 15 -32 5 0 9 -9 9 -21 0 -15 5 -19 15 -15 10
3 18 -1 22 -12 10 -27 41 -54 46 -39 4 9 7 9 18 0 7 -7 20 -13 29 -12 10 0 9
3 -5 9 -18 8 -18 9 5 10 30 1 58 21 53 37 -2 6 1 14 6 18 6 3 11 0 11 -7 0
-10 3 -9 9 2 6 8 7 18 5 22 -3 4 2 5 10 2 20 -8 20 -13 -2 -46 -24 -37 -72
-77 -100 -84 -60 -16 -172 118 -172 205 0 41 19 69 73 104 34 23 77 27 77 7z
m-1846 -6 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m407 -25
c31 -30 48 -39 67 -37 20 2 27 9 26 24 0 17 6 21 29 22 28 1 28 0 12 -24 -15
-23 -15 -24 11 -24 19 0 25 4 20 15 -3 10 1 15 12 15 15 1 15 2 -2 15 -11 8
-15 15 -9 15 7 0 18 -7 27 -15 8 -8 19 -15 25 -15 6 0 11 -4 11 -10 0 -5 13
-10 29 -10 17 0 33 -4 36 -10 3 -5 16 -10 28 -10 12 0 31 -7 41 -15 19 -14 19
-15 -6 -15 -14 0 -40 7 -58 17 -84 41 -186 -2 -249 -107 -54 -90 4 -206 120
-243 101 -33 140 -21 164 52 9 27 25 57 35 66 27 25 16 35 -36 35 -41 0 -44
-2 -44 -26 0 -40 -21 -112 -36 -125 -17 -14 -91 27 -123 67 -25 32 -29 116 -7
167 20 50 80 97 122 97 38 0 84 -25 84 -46 0 -7 10 -20 22 -28 24 -17 58 -6
58 19 0 8 5 15 10 15 14 0 13 -15 -5 -56 -9 -22 -19 -33 -26 -28 -6 3 -15 0
-20 -7 -13 -22 -11 -29 9 -22 23 8 52 44 52 65 0 10 9 27 20 38 11 11 20 24
20 30 0 6 8 10 18 10 14 0 15 2 2 10 -9 6 -10 10 -3 10 6 0 20 3 30 6 15 5 16
4 3 -6 -12 -10 -11 -11 9 -7 13 3 29 1 36 -6 9 -9 2 -16 -32 -34 -42 -21 -73
-51 -100 -97 -19 -33 -16 -146 5 -183 60 -108 201 -122 292 -28 19 19 30 35
26 35 -5 0 -2 7 5 17 21 25 17 131 -7 169 -29 47 -105 104 -140 104 -8 0 -22
7 -30 15 -14 13 -11 15 20 15 25 0 38 -6 46 -21 6 -11 17 -18 23 -16 6 2 22
-8 35 -22 16 -17 32 -25 49 -23 22 3 23 2 13 -17 -8 -15 -8 -21 0 -21 6 0 9
-4 5 -10 -3 -5 0 -24 8 -42 16 -39 15 -140 -2 -146 -6 -2 -11 -8 -11 -14 0 -6
-8 -17 -17 -24 -16 -12 -16 -15 -3 -23 8 -6 11 -14 6 -18 -5 -4 -11 -2 -13 5
-6 17 -23 15 -23 -3 0 -8 -3 -14 -7 -13 -5 1 -24 -5 -43 -13 -75 -34 -105 -40
-133 -26 -15 6 -27 15 -27 20 0 4 -11 7 -24 7 -25 0 -51 23 -43 37 3 5 -1 17
-9 28 -10 13 -14 14 -14 5 0 -11 -5 -10 -17 4 -13 14 -15 20 -5 23 15 6 6 23
-13 23 -7 0 -15 -12 -17 -27 -2 -22 -9 -29 -31 -31 -20 -3 -27 -8 -23 -18 4
-9 2 -12 -5 -8 -6 4 -15 0 -20 -7 -8 -12 -10 -12 -18 -1 -7 11 -12 12 -29 1
-16 -10 -26 -10 -47 -1 -15 7 -31 9 -37 6 -6 -4 -8 -2 -5 3 8 12 -20 27 -42
24 -7 -1 -10 2 -6 8 3 6 -1 11 -11 11 -10 0 -15 3 -11 6 3 3 -4 17 -16 30 -22
25 -57 32 -58 12 0 -7 -5 -3 -11 9 -12 24 -28 31 -28 12 0 -7 -15 -9 -42 -6
-59 7 -127 24 -104 26 20 1 20 1 0 16 -23 17 -69 20 -58 3 4 -7 3 -8 -4 -4 -7
4 -12 13 -12 20 0 8 -9 16 -19 19 -14 3 -18 1 -14 -10 4 -11 0 -14 -13 -11
-13 2 -19 12 -19 30 1 15 -3 25 -6 22 -4 -2 -10 0 -14 6 -9 15 18 12 41 -5 10
-8 27 -15 36 -15 16 0 114 -47 128 -60 44 -43 111 -21 95 31 -5 15 -10 17 -25
9 -28 -15 -60 -12 -109 10 -40 19 -43 22 -37 51 3 16 20 51 37 76 18 25 41 69
51 97 35 91 56 97 119 35z m-606 21 c-3 -5 -13 -10 -21 -10 -8 0 -14 5 -14 10
0 6 9 10 21 10 11 0 17 -4 14 -10z m1005 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4
10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-869 -33 c-5 -7 -8 -22 -5 -33 7 -23 77
-58 104 -51 13 3 18 1 14 -5 -5 -7 2 -9 20 -6 14 3 26 1 26 -3 0 -5 -19 -9
-43 -9 -50 0 -147 44 -147 66 0 23 33 81 38 66 2 -7 -1 -18 -7 -25z m1127 26
c-10 -2 -26 -2 -35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m89 1 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-277 -14 c0 -5 -4 -10 -9 -10 -6 0
-13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4 16 -10z m801 -9 c-30 -30 -44 -93 -31
-139 14 -53 85 -152 108 -152 6 0 12 -4 14 -8 2 -5 21 -12 43 -15 57 -9 121
24 160 83 35 53 51 60 48 22 -3 -38 -24 -73 -42 -70 -9 2 -16 -3 -16 -11 0 -8
-17 -20 -38 -27 -20 -7 -47 -20 -59 -29 -13 -8 -27 -15 -33 -15 -5 0 -3 5 5
10 11 7 3 10 -30 10 -26 0 -51 -5 -58 -12 -16 -16 -17 5 -2 33 6 10 7 19 3 19
-13 1 -83 67 -83 80 0 6 -5 8 -12 4 -6 -4 -8 -3 -5 3 9 15 -24 93 -40 93 -8 0
-13 11 -12 28 1 16 3 20 6 10 8 -31 23 -19 23 17 0 22 6 38 15 41 8 3 17 14
20 25 4 10 13 19 21 19 10 0 9 -5 -5 -19z m-978 2 c-7 -2 -21 -2 -30 0 -10 3
-4 5 12 5 17 0 24 -2 18 -5z m-1038 -13 c-3 -5 -10 -10 -16 -10 -5 0 -9 5 -9
10 0 6 7 10 16 10 8 0 12 -4 9 -10z m1425 0 c0 -5 -9 -10 -20 -10 -11 0 -20 5
-20 10 0 6 9 10 20 10 11 0 20 -4 20 -10z m520 -10 c0 -5 -5 -10 -11 -10 -5 0
-7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-1805 -21 c9 -26 -2 -38 -12 -13
-3 10 -11 22 -17 26 -7 5 -4 8 6 8 9 0 20 -9 23 -21z m1002 15 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m37 -47 c-12 -15 -25 -22 -36 -18 -14
5 -14 8 -2 19 8 7 14 9 15 5 0 -4 5 3 10 16 7 18 13 21 21 13 8 -8 5 -17 -8
-35z m694 -15 c6 -14 12 -32 12 -40 0 -21 24 -40 51 -38 21 1 24 -3 26 -36 1
-26 -3 -38 -13 -38 -7 0 -11 -6 -8 -14 4 -10 16 -13 37 -10 23 3 39 -1 57 -17
18 -15 20 -19 7 -15 -60 19 -67 19 -67 -5 0 -18 3 -20 12 -11 20 20 33 14 47
-24 11 -29 11 -38 1 -44 -16 -10 -40 -2 -40 14 0 6 -13 27 -28 46 -16 19 -58
78 -94 130 -37 52 -76 101 -87 107 -21 13 -51 8 -51 -8 0 -5 -5 -9 -10 -9 -6
0 -5 8 2 20 7 11 23 20 35 20 13 0 32 -9 43 -20 26 -26 49 -26 26 -1 -10 11
-15 24 -12 30 10 15 39 -5 54 -37z m-1803 28 c3 -6 -1 -7 -9 -4 -18 7 -21 14
-7 14 6 0 13 -4 16 -10z m1393 -25 c-3 -3 -9 2 -12 12 -6 14 -5 15 5 6 7 -7
10 -15 7 -18z m1212 5 c0 -13 -7 -20 -20 -20 -12 0 -20 7 -20 16 0 10 6 14 15
10 8 -3 15 -1 15 4 0 6 2 10 5 10 3 0 5 -9 5 -20z m-2443 -2 c15 -7 30 -15 33
-19 3 -3 23 -18 45 -32 44 -30 83 -35 91 -12 4 8 10 15 15 15 5 0 3 -11 -3
-25 -6 -14 -17 -25 -25 -25 -7 0 -13 -4 -13 -8 0 -5 -16 -16 -36 -26 -29 -13
-34 -20 -25 -29 9 -9 14 -8 22 3 9 13 10 12 5 -2 -3 -12 0 -18 9 -18 8 0 15 5
15 10 0 7 7 7 21 0 24 -14 20 -36 -7 -32 -10 2 -43 15 -72 29 -37 17 -52 30
-48 40 4 10 1 13 -13 8 -23 -7 -61 21 -61 45 0 13 -2 13 -10 0 -5 -8 -12 -11
-16 -7 -4 4 3 17 15 28 29 27 22 33 -23 21 -25 -7 -43 -7 -61 1 -32 15 -41 37
-14 37 11 0 18 -3 16 -7 -3 -5 1 -14 9 -20 8 -8 15 -9 19 -3 3 6 19 10 36 10
16 0 29 4 29 10 0 5 -14 7 -32 4 -25 -4 -29 -3 -18 5 20 14 63 14 97 -1z
m1873 -28 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4
10 -10z m-760 -10 c0 -5 7 -10 15 -10 26 0 84 -91 85 -132 0 -27 -4 -38 -15
-38 -8 0 -15 2 -15 4 0 2 -3 11 -6 20 -3 9 -1 16 5 16 6 0 11 -7 11 -17 0 -9
3 -14 6 -10 9 8 -18 77 -28 71 -5 -3 -8 -2 -7 3 4 22 -3 34 -26 43 -14 5 -25
14 -25 20 0 5 -4 10 -10 10 -5 0 -10 -7 -10 -15 0 -9 -11 -20 -25 -27 -13 -6
-22 -14 -20 -18 2 -4 0 -20 -5 -36 l-10 -29 4 28 c4 36 -2 34 -15 -3 -13 -37
-34 -40 -25 -4 8 32 52 104 74 122 19 15 42 16 42 2z m1427 -6 c-3 -3 -12 -4
-19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-1187 -24 c0 -5 -4 -10 -10 -10 -5 0
-10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m1157 4 c-3 -3 -12 -4 -19 -1
-8 3 -5 6 6 6 11 1 17 -2 13 -5z m-901 -96 c3 -11 21 -40 39 -64 19 -24 36
-53 40 -64 5 -16 15 -20 48 -21 37 0 39 -1 19 -10 -13 -5 -26 -18 -29 -29 -7
-26 -23 -26 -18 0 2 13 -2 20 -11 20 -8 0 -14 5 -14 10 0 6 -6 10 -13 10 -8 0
-13 10 -13 23 1 15 -4 22 -16 21 -18 -2 -34 29 -23 45 9 16 -13 22 -33 10 -12
-8 -17 -19 -14 -32 5 -18 6 -18 17 4 12 21 13 20 17 -19 3 -36 1 -42 -16 -42
-10 0 -24 7 -30 16 -10 12 -12 8 -11 -22 1 -22 -6 -52 -17 -70 -15 -25 -23
-30 -43 -26 -33 6 -32 15 5 29 24 9 30 17 30 41 0 24 -5 31 -27 35 -33 6 -43
-6 -43 -50 0 -18 -5 -33 -11 -33 -13 0 -10 68 6 95 6 11 10 34 9 50 0 17 5 42
13 57 7 15 13 31 12 35 -4 22 2 43 12 43 12 0 10 -20 -11 -99 -6 -24 -9 -45
-8 -47 2 -1 17 -4 33 -6 26 -3 30 0 33 25 2 15 -1 27 -7 27 -7 0 -11 24 -11
61 l0 61 39 -32 c22 -17 43 -40 47 -52z m749 72 c3 -5 -1 -10 -9 -10 -9 0 -16
5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z m102 4 c-3 -3 -12 -4 -19 -1 -8 3
-5 6 6 6 11 1 17 -2 13 -5z m-17 -24 c-20 -13 -33 -13 -25 0 3 6 14 10 23 10
15 0 15 -2 2 -10z m-1350 -20 c0 -4 -6 -10 -14 -13 -12 -5 -14 2 -7 31 2 7 21
-10 21 -18z m240 0 c0 -4 -6 -10 -14 -13 -10 -4 -12 0 -9 14 5 18 23 18 23 -1z
m110 -14 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10
-4z m-375 -54 c0 -12 7 -16 21 -14 31 4 59 -41 42 -68 -7 -11 -20 -21 -28 -21
-8 -1 1 -5 20 -8 44 -9 63 4 55 38 -4 19 -3 22 4 11 8 -12 12 -10 20 10 l10
25 0 -26 c1 -24 -44 -99 -69 -114 -25 -15 -80 -9 -94 11 -41 54 -78 154 -57
154 6 0 11 -4 11 -10 0 -5 7 -10 16 -10 24 0 44 -30 42 -65 l0 -30 -8 30 c-4
17 -8 26 -9 21 0 -6 -8 -1 -16 10 -22 29 -18 6 6 -32 20 -33 21 -33 45 -18 31
21 31 47 -1 72 -14 11 -25 30 -25 43 0 12 3 19 7 15 4 -4 8 -15 8 -24z m1122
-14 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-1950 -14 c-3 -3
-12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m51 -43 c9 -5 37 -13 62 -18
40 -8 42 -9 18 -15 -15 -3 -25 -1 -22 3 3 5 -13 9 -35 9 -23 0 -41 4 -41 9 0
5 -8 12 -17 14 -17 4 -17 5 0 6 9 0 25 -3 35 -8z m239 -27 c-3 -3 -12 -4 -19
-1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m1376 -1 c-7 -2 -19 -2 -25 0 -7 3 -2 5
12 5 14 0 19 -2 13 -5z m-1500 -30 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0
24 -2 18 -5z m1205 -11 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2 31
-12z m62 8 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1 10 9 10 9 0 16 -4
16 -10z m14 -22 c-7 -11 -54 -11 -54 0 0 5 13 9 30 9 16 0 27 -4 24 -9z m-789
-18 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16 10 0 6 4 10 9 10 6 0 13 -4 16 -10z
m-152 -27 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m26 -28 c-10
-10 -22 -13 -34 -9 -18 7 -18 8 1 15 10 4 26 8 34 8 12 1 12 -1 -1 -14z m641
-5 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z
m123 -77 c-13 -2 -33 -2 -45 0 -13 2 -3 4 22 4 25 0 35 -2 23 -4z"/>
<path d="M1817 1823 c3 -7 6 -13 8 -13 2 0 5 6 8 13 3 8 -1 14 -8 14 -7 0 -11
-6 -8 -14z"/>
<path d="M1772 1748 c6 -16 36 -34 44 -25 9 9 -15 37 -33 37 -8 0 -13 -5 -11
-12z"/>
<path d="M1841 1726 c-9 -10 -8 -15 4 -20 10 -4 15 1 15 14 0 24 -4 25 -19 6z"/>
<path d="M1795 1670 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M1672 1661 c-16 -9 -5 -41 14 -41 8 0 13 8 11 20 -4 26 -10 31 -25
21z"/>
<path d="M1640 1615 c0 -8 5 -15 10 -15 6 0 10 7 10 15 0 8 -4 15 -10 15 -5 0
-10 -7 -10 -15z"/>
<path d="M1787 1485 c-11 -28 9 -43 30 -22 16 15 16 17 1 17 -9 0 -18 5 -20
12 -3 7 -7 4 -11 -7z"/>
<path d="M2097 1486 c-3 -8 -2 -18 4 -22 16 -9 22 2 11 21 -8 15 -10 16 -15 1z"/>
<path d="M2160 1329 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2440 1272 c0 -7 -7 -12 -16 -12 -17 0 -15 -21 3 -27 12 -4 29 35 19
44 -3 4 -6 1 -6 -5z"/>
<path d="M6410 1729 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M6105 1610 c-12 -4 -47 -36 -78 -69 -66 -72 -69 -87 -30 -174 23 -51
36 -66 87 -99 72 -46 118 -50 174 -13 50 33 92 88 99 129 6 38 -24 116 -60
155 -53 56 -144 91 -192 71z m56 -54 c44 -19 84 -57 120 -114 39 -62 37 -89
-9 -141 -34 -39 -42 -43 -67 -37 -16 4 -41 18 -56 30 l-27 23 30 1 c22 0 32
-5 35 -19 3 -11 14 -19 27 -19 17 0 18 2 6 10 -12 8 -10 10 8 10 13 0 21 4 17
9 -3 5 6 18 20 29 14 11 25 27 25 37 0 35 -53 105 -79 105 -14 0 -14 1 -1 10
13 9 13 10 0 11 -8 1 -22 1 -30 0 -13 -2 -13 -1 0 8 8 5 11 13 7 17 -3 4 -13
1 -22 -6 -9 -7 -18 -10 -22 -6 -3 3 -1 6 5 6 7 0 12 5 12 10 0 6 -14 10 -31
10 -25 0 -30 -3 -25 -16 5 -13 2 -15 -13 -11 -14 3 -18 1 -14 -9 3 -7 -2 -21
-12 -30 -9 -9 -13 -19 -10 -22 3 -3 16 6 29 21 13 15 31 27 40 27 26 0 18 -17
-14 -30 -39 -16 -38 -33 5 -53 19 -9 35 -21 35 -27 0 -13 -16 -13 -42 1 -12 6
-18 7 -14 1 7 -11 -19 -24 -29 -14 -10 10 -45 93 -45 108 0 19 70 84 90 84 10
0 33 -6 51 -14z m19 -95 c0 -11 -4 -23 -10 -26 -6 -4 -10 5 -10 19 0 14 5 26
10 26 6 0 10 -9 10 -19z m-72 -111 c7 -11 9 -20 4 -20 -10 0 -32 22 -32 32 0
15 15 8 28 -12z m47 10 c-3 -5 -12 -10 -18 -10 -7 0 -6 4 3 10 19 12 23 12 15
0z"/>
<path d="M6317 1566 c-8 -21 15 -26 31 -7 12 14 13 14 8 -1 -3 -10 -1 -18 5
-18 6 0 9 8 7 18 -4 22 -43 28 -51 8z"/>
<path d="M6490 1561 c0 -5 7 -14 15 -21 13 -10 15 -9 15 9 0 12 -6 21 -15 21
-8 0 -15 -4 -15 -9z"/>
<path d="M6585 1550 c3 -5 13 -10 21 -10 8 0 14 5 14 10 0 6 -9 10 -21 10 -11
0 -17 -4 -14 -10z"/>
<path d="M6364 1483 c1 -26 3 -28 24 -18 27 12 25 39 -4 43 -17 2 -21 -2 -20
-25z"/>
<path d="M5975 1343 c6 -23 -10 -33 -17 -12 -2 7 -9 5 -17 -5 -11 -14 -11 -16
2 -16 8 0 17 -6 19 -12 3 -8 9 -4 17 10 9 16 9 27 1 40 -9 15 -10 14 -5 -5z"/>
<path d="M5860 1315 c-19 -11 -21 -14 -7 -15 9 0 17 -4 17 -10 0 -5 4 -10 9
-10 5 0 8 11 7 25 0 14 -1 25 -1 25 0 -1 -11 -7 -25 -15z"/>
<path d="M5995 1310 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6026 1275 c4 -8 11 -15 16 -15 6 0 5 6 -2 15 -7 8 -14 15 -16 15 -2
0 -1 -7 2 -15z"/>
<path d="M5807 1273 c-4 -3 -7 -13 -7 -22 0 -12 3 -12 12 -3 7 7 20 12 31 12
10 0 15 5 12 10 -7 11 -38 13 -48 3z"/>
<path d="M5970 1271 c0 -5 8 -17 17 -26 14 -14 15 -22 5 -47 -8 -22 -8 -28 1
-25 7 3 11 9 10 15 -1 5 9 13 22 16 14 4 25 11 25 16 0 6 5 10 11 10 5 0 7 -4
4 -10 -4 -6 6 -10 24 -10 23 0 29 4 25 15 -4 8 -12 15 -19 15 -7 0 -16 6 -19
13 -5 10 -7 10 -12 0 -9 -20 -48 -15 -72 7 -12 12 -22 16 -22 11z"/>
<path d="M5606 1261 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"/>
<path d="M5892 1258 c-5 -7 -12 -22 -14 -33 -4 -26 42 -33 66 -10 27 27 21 55
-13 55 -16 0 -33 -6 -39 -12z"/>
<path d="M5855 1190 c3 -5 11 -10 16 -10 6 0 7 5 4 10 -3 6 -11 10 -16 10 -6
0 -7 -4 -4 -10z"/>
<path d="M5924 1164 c3 -9 6 -18 6 -20 0 -2 9 -4 20 -4 11 0 20 5 20 10 0 6
-7 10 -15 10 -8 0 -15 5 -15 10 0 6 -5 10 -11 10 -6 0 -8 -7 -5 -16z"/>
<path d="M5950 1110 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M5316 1065 c4 -8 8 -15 10 -15 2 0 4 7 4 15 0 8 -4 15 -10 15 -5 0
-7 -7 -4 -15z"/>
<path d="M2510 1082 c0 -35 11 -52 34 -52 28 0 42 11 48 38 5 19 2 22 -22 22
-16 0 -35 3 -44 6 -11 4 -16 0 -16 -14z"/>
<path d="M5355 969 c-11 -16 -1 -19 13 -3 7 8 8 14 3 14 -5 0 -13 -5 -16 -11z"/>
<path d="M3380 645 c0 -25 14 -34 24 -16 8 12 -3 41 -15 41 -5 0 -9 -11 -9
-25z"/>
<path d="M3440 599 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3329 568 c4 -28 6 -29 9 -9 2 13 0 27 -5 32 -6 6 -7 -3 -4 -23z"/>
<path d="M3517 586 c-4 -9 -13 -13 -22 -10 -10 4 -15 0 -15 -15 0 -19 2 -20
20 -9 19 12 34 37 26 45 -2 2 -6 -3 -9 -11z"/>
<path d="M3364 548 c3 -13 5 -26 5 -30 1 -5 12 -8 26 -8 18 0 28 7 35 26 14
35 15 34 -30 34 -36 0 -39 -2 -36 -22z"/>
<path d="M3307 553 c-12 -11 -8 -40 7 -52 10 -9 15 -8 19 3 3 8 -1 20 -8 26
-8 7 -12 16 -9 21 6 11 0 12 -9 2z"/>
<path d="M3662 548 c10 -6 10 -10 -2 -19 -12 -9 -11 -10 5 -4 11 4 28 10 38
12 30 9 18 22 -18 21 -23 -1 -31 -4 -23 -10z"/>
<path d="M3605 513 c-11 -14 -25 -32 -32 -38 -7 -7 -8 -17 -2 -26 12 -19 11
-62 -1 -54 -5 3 -10 -3 -10 -14 0 -22 14 -28 25 -11 11 18 23 11 31 -17 4 -16
6 -32 5 -36 -1 -5 8 -11 19 -14 11 -3 17 -9 14 -14 -3 -5 0 -9 5 -9 6 0 11 7
11 15 0 9 6 12 15 9 8 -4 15 -1 15 5 0 6 -7 11 -15 11 -8 0 -15 6 -15 14 0 9
7 13 20 11 21 -4 24 3 20 64 -1 19 2 31 8 27 5 -3 17 5 26 18 8 14 26 26 38
29 l23 4 -22 9 c-21 9 -23 12 -23 34 0 17 -39 11 -60 -10 -11 -11 -32 -20 -46
-20 -21 0 -25 4 -21 20 7 28 -5 25 -28 -7z m45 -86 c0 -20 -25 1 -28 24 -2 23
-2 23 13 5 8 -11 15 -24 15 -29z m47 -27 c-1 -11 -5 -20 -9 -20 -10 0 -10 4
-2 24 8 22 13 20 11 -4z m-42 -49 c-3 -6 -11 -8 -17 -5 -6 4 -5 9 3 15 16 10
23 4 14 -10z"/>
<path d="M3419 498 c-1 -2 -3 -15 -5 -30 -2 -22 1 -28 16 -28 17 0 36 27 25
38 -3 3 -5 0 -5 -7 0 -9 -4 -9 -14 1 -10 9 -11 15 -2 20 8 6 8 8 -1 8 -7 0
-14 -1 -14 -2z"/>
<path d="M3350 443 c-13 -15 -12 -16 4 -10 10 4 22 7 27 7 6 0 7 5 4 10 -9 14
-18 12 -35 -7z"/>
<path d="M3420 410 c0 -5 5 -10 10 -10 6 0 10 -7 10 -15 0 -8 -5 -15 -10 -15
-7 0 -7 -6 0 -20 6 -11 16 -20 22 -20 7 0 7 4 -1 14 -7 8 -8 15 -2 18 14 5 1
58 -15 58 -8 0 -14 -4 -14 -10z"/>
<path d="M4630 470 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M4690 346 c0 -2 7 -9 15 -16 13 -11 14 -10 9 4 -5 14 -24 23 -24 12z"/>
<path d="M2896 535 c-20 -21 -12 -35 20 -35 23 0 33 -13 17 -23 -5 -2 -2 -2 5
0 19 4 46 -24 29 -30 -8 -3 -6 -7 7 -12 10 -4 21 -3 25 2 3 5 15 15 26 21 18
10 17 11 -12 12 -17 0 -34 4 -38 9 -3 6 10 8 32 6 33 -4 31 -3 -12 9 -27 8
-42 15 -32 15 10 1 16 5 12 11 -3 5 -13 6 -22 3 -12 -5 -14 -3 -8 7 6 9 4 11
-5 5 -7 -4 -15 -2 -20 4 -5 9 -11 8 -24 -4z"/>
<path d="M4004 257 c5 -32 36 -52 36 -22 0 8 8 15 17 15 24 0 13 6 -24 13 -21
4 -31 2 -29 -6z"/>
<path d="M393 3470 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M7891 3434 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M563 3425 c0 -8 4 -12 9 -9 4 3 8 9 8 15 0 5 -4 9 -8 9 -5 0 -9 -7
-9 -15z"/>
<path d="M7908 3343 c5 -21 7 -23 10 -9 2 10 0 22 -6 28 -6 6 -7 0 -4 -19z"/>
<path d="M7425 2650 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M4758 853 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3483 163 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
</g>
</svg>
    </>
  )
}
