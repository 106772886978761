import React from 'react'

export default function Mitlo() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 1200.000000 1208.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1208.000000) scale(0.100000,-0.100000)"
fill="#fff" stroke="none">
<path d="M5962 11583 c-7 -3 -23 -15 -36 -27 -12 -11 -39 -34 -60 -49 -20 -16
-44 -44 -53 -62 -13 -27 -28 -38 -73 -54 -32 -12 -61 -28 -64 -36 -8 -23 -50
-18 -80 10 -62 57 -105 65 -141 25 -12 -13 -31 -20 -57 -20 -22 0 -40 -5 -42
-12 -13 -50 -30 -118 -35 -144 -3 -17 -22 -52 -41 -77 -34 -45 -36 -46 -92
-47 -55 0 -57 -1 -98 -50 -23 -27 -48 -50 -56 -50 -8 0 -28 14 -46 30 -17 17
-42 30 -55 30 -13 0 -40 8 -61 19 -20 10 -66 24 -102 30 -55 10 -77 10 -133
-3 -37 -9 -81 -23 -97 -31 -16 -8 -42 -15 -59 -15 -23 0 -39 -11 -72 -50 -23
-27 -57 -58 -74 -69 -38 -22 -53 -65 -80 -220 -22 -131 -33 -140 -115 -98 -84
43 -123 51 -158 33 -18 -9 -32 -21 -32 -26 0 -16 -47 -60 -63 -60 -9 0 -23
-13 -32 -30 -8 -16 -27 -37 -40 -46 -14 -9 -37 -42 -51 -74 -14 -32 -32 -61
-40 -64 -8 -3 -33 4 -56 16 l-41 22 -38 -22 c-21 -12 -43 -22 -49 -22 -7 0
-28 -7 -47 -16 -32 -16 -37 -16 -69 0 -19 9 -52 16 -72 16 -21 0 -43 5 -49 11
-25 25 -173 -21 -173 -52 0 -5 -16 -29 -35 -55 -19 -25 -35 -51 -35 -58 0 -8
-10 -25 -21 -40 -21 -26 -24 -27 -84 -21 -51 6 -69 4 -93 -11 -33 -19 -52 -52
-52 -88 0 -12 -7 -31 -15 -42 -10 -13 -14 -44 -15 -97 0 -97 -9 -115 -61 -123
-63 -10 -85 -54 -99 -196 -14 -136 -21 -155 -63 -173 -37 -16 -88 -67 -110
-111 -10 -20 -35 -41 -69 -58 -31 -16 -62 -41 -75 -61 -13 -20 -35 -38 -50
-41 -38 -9 -109 -73 -144 -129 l-29 -45 -84 0 c-82 0 -85 -1 -110 -31 -14 -17
-26 -38 -26 -47 0 -10 -10 -22 -22 -27 -34 -15 -51 -60 -61 -165 -8 -88 -33
-207 -46 -221 -3 -4 -23 -10 -43 -13 -31 -6 -38 -4 -38 9 0 8 -7 15 -15 15
-17 0 -19 -12 -5 -26 16 -16 0 -46 -21 -41 -22 6 -21 10 -20 -118 0 -128 0
-122 10 -107 6 10 22 -7 56 -62 37 -60 54 -78 79 -84 31 -8 43 -30 32 -59 -6
-14 -10 -13 -29 6 -30 27 -48 27 -77 -4 -14 -15 -51 -33 -90 -45 -37 -11 -74
-28 -83 -38 -27 -29 -46 -85 -53 -154 l-6 -65 -77 79 c-42 44 -83 91 -90 103
-8 13 -25 26 -38 30 -12 3 -46 19 -75 36 l-52 31 -53 -26 c-48 -23 -69 -46
-43 -46 20 0 9 -21 -17 -32 -16 -7 -42 -23 -59 -35 -17 -13 -35 -23 -41 -23
-6 0 -22 -9 -35 -20 -24 -18 -35 -38 -69 -117 l-13 -32 -91 2 c-88 1 -91 0
-137 -34 -41 -30 -47 -39 -47 -72 -1 -60 19 -129 51 -168 23 -30 28 -47 28
-92 0 -56 -8 -67 -49 -67 -30 0 -82 -31 -93 -55 -8 -18 -4 -40 17 -101 16 -44
32 -84 37 -91 5 -6 8 -29 6 -50 l-3 -38 -70 4 c-41 2 -77 9 -86 17 -19 17
-110 19 -126 3 -6 -6 -22 -17 -35 -23 -19 -9 -24 -21 -26 -64 -4 -66 25 -120
120 -218 66 -70 68 -74 68 -123 0 -48 2 -52 40 -77 22 -14 74 -34 117 -43 l78
-17 7 -58 c4 -32 12 -64 18 -71 6 -7 50 -25 97 -40 76 -24 104 -28 222 -29
l136 -1 3 -37 c2 -22 9 -38 15 -38 16 0 54 25 95 63 17 17 32 26 32 20 0 -15
-33 -57 -111 -139 -62 -67 -67 -76 -84 -150 -10 -44 -19 -97 -20 -119 -2 -22
-8 -53 -15 -70 -29 -73 -43 -221 -46 -485 -1 -146 2 -294 7 -330 15 -100 67
-279 114 -385 7 -16 16 -48 19 -69 3 -26 17 -51 40 -75 24 -25 39 -55 51 -101
10 -36 30 -88 46 -117 l28 -52 -45 -55 c-51 -61 -71 -118 -48 -137 9 -7 14
-31 14 -64 0 -61 42 -235 64 -267 14 -20 14 -24 -1 -40 -20 -22 -43 -83 -43
-114 0 -12 6 -27 14 -33 8 -7 12 -20 9 -31 -7 -25 109 -148 146 -157 75 -17
145 -98 146 -168 l0 -40 95 -33 c85 -29 153 -72 139 -87 -3 -3 -48 8 -100 24
-154 46 -164 47 -200 12 -39 -40 -34 -60 35 -127 44 -44 61 -54 110 -64 32 -6
74 -18 93 -26 37 -16 37 -19 18 -81 -6 -19 -4 -23 14 -23 28 0 36 -22 23 -60
-11 -29 -9 -31 26 -46 20 -9 53 -22 72 -29 23 -8 41 -24 51 -46 9 -19 41 -58
70 -87 l54 -52 135 2 c117 2 139 0 163 -16 21 -14 27 -26 27 -53 0 -28 9 -44
40 -76 38 -37 43 -39 83 -34 48 7 127 -2 127 -14 0 -4 -9 -9 -19 -11 -19 -3
-19 -4 2 -48 24 -49 69 -79 122 -80 53 -1 69 -10 105 -61 20 -28 46 -52 60
-55 14 -4 30 -10 35 -14 6 -5 21 -10 35 -12 24 -3 25 -7 25 -63 0 -91 12 -98
47 -27 50 106 121 128 267 83 52 -16 85 -34 115 -61 66 -62 48 -63 -93 -5 -54
23 -75 26 -134 23 -65 -4 -71 -6 -103 -41 -26 -29 -34 -45 -34 -76 0 -44 8
-53 68 -70 36 -10 46 -19 65 -57 18 -37 19 -48 9 -65 -10 -15 -10 -25 2 -47
20 -39 35 -49 83 -60 23 -5 54 -19 69 -31 15 -11 39 -21 54 -21 17 0 30 -7 34
-17 5 -13 11 -7 27 25 11 22 39 57 61 76 34 31 48 36 87 35 25 0 64 -9 86 -19
l40 -19 -43 0 c-61 -1 -152 -29 -180 -55 -13 -11 -33 -34 -44 -49 -24 -33 -22
-36 65 -109 l58 -48 88 0 c74 0 93 -3 115 -21 14 -11 26 -30 26 -41 0 -31 82
-106 124 -114 39 -7 199 12 232 28 29 14 74 -15 74 -48 0 -24 12 -29 55 -25
11 1 26 -6 33 -16 16 -22 105 -22 169 1 32 11 68 13 150 9 146 -8 148 -9 144
-67 -4 -65 14 -112 47 -124 59 -22 151 -35 235 -35 88 1 89 1 117 34 27 32 29
32 30 12 0 -29 33 -30 78 -3 44 27 54 26 95 -15 21 -21 45 -35 59 -35 14 0 42
-13 64 -30 36 -27 45 -29 127 -30 77 0 94 -3 132 -26 l44 -25 238 3 c230 3
240 4 283 27 38 21 57 24 122 22 72 -2 82 1 157 38 44 23 95 54 113 70 l34 30
48 -30 c53 -33 65 -32 48 6 -11 24 -10 25 7 15 11 -5 29 -22 40 -36 l21 -27
112 7 c204 12 214 14 248 52 26 30 30 42 30 93 0 58 1 59 31 65 17 3 55 6 85
6 29 0 64 5 77 11 18 8 35 6 78 -10 72 -28 168 -29 191 -2 10 12 24 17 35 15
14 -4 23 2 31 21 17 36 74 59 115 46 131 -40 194 -39 260 3 42 27 57 44 71 80
10 26 26 51 36 56 10 6 56 10 102 10 46 1 92 6 104 13 41 24 92 73 107 103 19
37 57 64 90 64 14 0 30 7 37 15 7 8 39 25 72 37 67 24 72 31 82 123 5 49 11
65 36 87 17 16 41 28 54 28 16 0 33 11 47 32 l23 31 -21 36 c-11 20 -36 47
-54 59 -30 21 -43 23 -109 20 -55 -4 -89 -12 -127 -31 -54 -28 -118 -36 -111
-16 8 24 126 85 199 103 65 16 82 17 115 6 43 -14 87 -50 115 -94 l18 -29 5
24 c3 13 7 37 8 53 3 24 10 30 48 43 29 9 65 35 101 69 48 48 62 56 100 58 58
5 90 23 140 80 39 45 40 46 16 46 -13 0 -30 6 -36 14 -10 12 -3 15 38 18 28 1
65 0 83 -4 61 -12 91 19 123 130 5 17 26 33 70 54 61 28 65 29 89 13 20 -13
43 -16 106 -14 l80 4 50 53 c27 29 50 61 50 71 0 25 12 33 103 67 l77 29 -6
42 c-5 34 -3 44 10 49 12 5 14 15 9 49 -5 38 -3 45 20 59 13 9 47 20 74 25
113 17 173 75 173 164 l0 50 -77 -6 c-48 -3 -105 -15 -147 -31 -38 -15 -71
-24 -74 -21 -10 9 39 52 76 68 20 8 64 24 97 34 l60 19 -3 38 c-3 30 3 47 27
81 17 24 31 48 31 54 0 13 17 23 44 26 11 1 26 3 32 4 24 2 144 126 144 148 0
12 4 24 9 27 21 13 21 61 0 128 -20 65 -20 72 -6 106 51 124 70 230 47 272 -5
10 -29 29 -52 43 -24 14 -59 41 -77 61 -30 31 -32 36 -15 36 30 0 78 -34 120
-84 21 -25 44 -46 51 -46 28 0 9 88 -28 125 -37 38 -69 83 -69 96 0 7 9 22 20
34 11 12 23 38 25 57 2 19 11 48 20 64 8 16 15 34 15 39 0 6 18 30 39 54 21
24 41 57 44 75 10 56 24 110 50 181 60 168 71 262 71 600 0 264 -12 445 -36
540 -11 44 -24 109 -29 144 -10 76 -20 92 -117 194 -41 43 -72 81 -69 84 4 3
27 -9 53 -26 26 -17 52 -31 59 -31 6 0 15 15 20 33 l10 32 131 1 c105 1 142 5
192 23 34 11 71 21 82 21 23 0 43 40 34 65 -7 18 22 84 39 91 7 2 42 10 79 18
37 8 75 21 84 30 8 9 21 16 27 16 28 1 41 21 48 79 6 57 10 63 74 128 79 79
104 128 105 199 0 60 -15 79 -85 100 -50 15 -87 11 -105 -11 -19 -23 -116 -30
-145 -11 -27 18 -27 18 21 121 40 86 43 98 26 117 -12 15 -13 20 -2 33 20 24
-6 44 -68 52 -80 9 -87 18 -69 83 8 30 26 72 40 93 25 36 47 113 47 161 0 34
-80 93 -136 102 -24 3 -64 3 -88 0 -41 -6 -44 -5 -71 30 -16 21 -34 53 -41 73
-10 32 -23 42 -104 83 -108 55 -106 53 -91 71 12 15 -3 34 -45 55 -23 12 -72
-1 -114 -30 -13 -10 -32 -18 -41 -18 -17 0 -79 -63 -79 -79 0 -16 -71 -84 -79
-75 -12 14 -31 74 -32 103 l-1 26 -13 -30 -13 -30 -15 35 c-22 51 -45 67 -115
84 -66 15 -92 30 -92 51 0 12 -21 25 -41 25 -6 0 -21 -12 -34 -26 -20 -22 -26
-24 -31 -11 -13 36 0 62 41 75 22 7 47 20 57 29 20 18 20 18 22 -47 1 -55 24
-70 107 -70 42 0 63 -5 83 -21 25 -19 26 -20 26 -2 0 31 -74 95 -117 102 -34
6 -38 9 -39 39 -1 18 -7 68 -12 110 -20 137 -22 171 -12 177 18 11 11 71 -10
90 -15 13 -29 16 -57 12 -21 -4 -43 -3 -50 1 -21 13 -46 131 -39 184 7 52 -9
132 -24 123 -6 -4 -10 4 -10 16 0 31 -39 89 -60 89 -24 0 -43 23 -29 37 8 8 4
15 -12 27 -17 12 -46 16 -106 16 -90 0 -107 8 -118 59 -7 33 -65 83 -130 112
-25 11 -60 39 -81 64 -20 25 -48 48 -63 51 -31 8 -61 32 -61 49 0 19 -57 86
-95 112 -47 32 -55 51 -56 135 -1 76 -21 157 -48 195 -9 12 -39 29 -67 38 -69
20 -72 31 -88 271 -2 37 -19 60 -57 80 -33 17 -91 18 -106 3 -18 -18 -60 18
-79 67 -9 25 -25 51 -35 57 -9 7 -27 29 -38 48 -17 29 -34 41 -82 59 -57 20
-63 21 -100 6 -22 -9 -48 -16 -59 -16 -11 0 -35 -8 -55 -17 -33 -16 -37 -16
-70 0 -20 9 -45 17 -57 17 -12 0 -30 8 -40 17 -18 16 -22 16 -69 -1 -48 -18
-51 -18 -71 0 -11 11 -29 37 -38 60 -24 54 -201 230 -240 239 -23 5 -49 -2
-107 -30 -114 -54 -125 -45 -152 126 -15 91 -61 179 -112 215 -22 15 -49 40
-60 56 -11 15 -28 28 -37 28 -9 1 -35 8 -57 16 -62 24 -125 37 -180 36 -51 0
-148 -22 -184 -41 -11 -6 -30 -11 -43 -11 -14 0 -37 -13 -53 -30 -16 -16 -35
-30 -42 -30 -7 0 -32 20 -56 44 -36 38 -50 46 -95 52 -46 6 -57 12 -91 53 -21
25 -41 57 -44 71 -8 39 -48 144 -59 158 -6 6 -18 12 -27 12 -9 0 -35 10 -57
22 l-41 22 -47 -22 c-25 -12 -51 -31 -58 -42 -15 -24 -45 -26 -68 -5 -9 8 -45
25 -81 38 -62 22 -64 24 -77 69 -8 29 -20 49 -32 53 -10 3 -38 21 -62 40 -41
33 -66 40 -96 28z m43 -448 c0 -10 -6 -20 -12 -22 -8 -3 -13 5 -13 22 0 17 5
25 13 23 6 -3 12 -13 12 -23z m-375 -154 c0 -19 -26 -48 -36 -39 -7 7 17 58
28 58 4 0 8 -8 8 -19z m-905 -106 l40 -25 -52 0 c-65 0 -108 17 -92 37 17 20
59 15 104 -12z m2641 13 c12 -20 -28 -38 -85 -38 -58 0 -59 7 -6 34 35 19 81
21 91 4z m-821 -58 c4 -6 4 -18 1 -26 -5 -13 -9 -13 -30 2 -14 8 -30 13 -36 9
-5 -3 -10 -2 -10 4 0 8 20 15 57 20 6 0 15 -4 18 -9z m-2035 -59 c0 -20 7 -47
16 -60 14 -20 22 -22 58 -17 57 9 85 -11 68 -48 -6 -14 -23 -31 -37 -38 -22
-10 -29 -8 -50 12 -14 13 -27 31 -30 41 -5 14 -11 16 -34 9 -33 -9 -85 10 -95
35 -24 64 22 121 87 107 12 -3 17 -14 17 -41z m3023 38 c30 -16 37 -28 37 -66
0 -42 -44 -86 -79 -78 -18 5 -31 -2 -56 -29 -38 -42 -44 -43 -79 -15 -56 44
-20 88 59 73 31 -5 36 -3 45 21 5 14 10 44 10 66 0 33 3 39 21 39 11 0 30 -5
42 -11z m-1636 -21 c27 -22 13 -31 -39 -24 -63 9 -69 13 -43 32 21 15 59 11
82 -8z m273 2 c20 -13 9 -21 -39 -27 -33 -5 -41 -2 -41 10 0 25 50 36 80 17z
m-419 -113 c-8 -8 -11 -5 -11 9 0 27 14 41 18 19 2 -9 -1 -22 -7 -28z m204 2
c8 -25 -2 -86 -15 -94 -14 -9 -23 40 -16 80 8 39 21 45 31 14z m-1025 -58 c19
-67 11 -130 -36 -289 -25 -87 -51 -166 -57 -177 -20 -39 -327 -335 -384 -371
-32 -20 -76 -54 -98 -74 -66 -62 -337 -230 -371 -230 -13 0 -24 23 -24 49 0
10 21 43 48 74 26 30 55 67 65 81 l17 25 37 -24 c48 -33 66 -32 113 11 21 20
62 44 89 55 124 47 364 278 436 420 10 19 32 59 51 89 38 63 74 235 74 353 0
60 2 68 15 57 8 -7 19 -29 25 -49z m2179 -194 c25 -93 105 -254 171 -349 64
-91 287 -296 340 -313 49 -15 115 -54 138 -80 25 -29 47 -30 108 -5 30 13 32
12 74 -34 57 -61 90 -107 90 -122 0 -22 -31 -45 -52 -38 -18 5 -155 85 -270
156 -25 16 -54 40 -63 54 -9 14 -46 44 -83 68 -81 52 -160 123 -307 276 -101
106 -112 121 -136 190 -61 174 -82 310 -58 385 l13 40 7 -75 c3 -41 16 -110
28 -153z m-1380 141 c-1 -13 -4 -34 -8 -48 -10 -37 -23 -8 -15 35 7 39 25 48
23 13z m-169 -11 c0 -7 -18 -30 -39 -51 -39 -38 -42 -39 -107 -37 -72 2 -94
12 -94 42 0 30 27 48 65 44 20 -2 48 1 63 5 45 14 112 12 112 -3z m1076 -7
c18 -7 24 -17 24 -39 0 -33 -6 -36 -92 -46 -56 -6 -56 -6 -102 38 -68 65 -64
71 50 63 53 -4 107 -11 120 -16z m-512 -70 c39 -43 52 -99 18 -81 -29 15 -82
90 -82 116 0 17 33 0 64 -35z m-212 0 c-3 -29 -18 -47 -54 -66 -63 -33 -68 28
-5 68 49 30 63 30 59 -2z m-352 -20 c0 -5 -5 -29 -11 -52 -9 -34 -9 -54 3 -93
8 -27 12 -60 10 -72 -3 -22 -119 -149 -128 -140 -2 2 2 19 10 38 26 62 -13 86
-88 55 -18 -8 -41 -24 -50 -37 l-16 -23 0 24 c0 13 10 34 22 47 18 20 31 23
88 23 37 0 74 5 83 13 19 13 46 110 47 164 0 17 3 38 6 47 7 18 24 22 24 6z
m878 -14 c3 -12 -1 -17 -10 -14 -7 3 -15 13 -16 22 -3 12 1 17 10 14 7 -3 15
-13 16 -22z m34 -128 c7 -69 42 -102 96 -91 61 12 85 7 114 -26 46 -51 34 -85
-12 -36 -26 27 -88 49 -114 39 -19 -7 -21 -46 -4 -74 7 -10 9 -22 5 -27 -12
-11 -157 138 -157 162 0 12 11 36 25 55 14 19 25 40 25 48 0 7 4 11 9 8 4 -3
11 -29 13 -58z m1563 -79 c18 -34 13 -48 -10 -29 -13 11 -22 60 -10 60 2 0 12
-14 20 -31z m-4075 -9 c0 -11 -7 -20 -15 -20 -18 0 -19 12 -3 28 16 16 18 15
18 -8z m-196 -15 c8 -22 -19 -29 -72 -19 -70 14 -66 34 6 34 45 0 61 -4 66
-15z m4580 1 c-3 -7 -23 -11 -52 -10 -55 2 -55 2 -48 12 8 13 105 12 100 -2z
m-111 -66 c21 -19 54 -40 73 -47 36 -13 74 -49 99 -95 12 -21 14 -45 9 -97 -4
-39 -11 -68 -15 -65 -5 3 -16 29 -25 59 -8 30 -31 73 -50 96 -19 22 -34 45
-34 50 0 5 -15 9 -33 9 -31 0 -36 5 -65 62 -45 89 -33 97 41 28z m-4413 33 c0
-5 -20 -46 -43 -92 -41 -82 -43 -85 -87 -95 -38 -9 -48 -17 -65 -51 -11 -23
-28 -52 -37 -65 -18 -24 -18 -23 -18 56 0 74 3 85 30 122 31 44 48 50 79 30
28 -17 67 12 82 62 10 32 17 40 36 40 12 0 23 -3 23 -7z m2070 -2 c0 -25 -100
-74 -117 -57 -3 3 8 19 25 36 29 29 92 43 92 21z m251 -1 c42 -12 94 -60 65
-60 -25 0 -81 28 -102 51 -19 22 -14 23 37 9z m293 -42 c24 -18 52 -48 62 -66
9 -18 37 -51 62 -73 24 -23 42 -42 40 -44 -7 -7 -202 172 -209 193 -11 29 -6
28 45 -10z m-1988 -16 c-23 -23 -33 -6 -10 19 12 13 20 16 22 9 3 -7 -3 -19
-12 -28z m3204 -94 c0 -22 -33 -4 -54 30 -13 20 -31 43 -40 51 -9 7 -16 18
-16 24 0 6 25 -13 55 -43 30 -30 55 -58 55 -62z m-2085 93 c5 -9 -75 -92 -97
-100 -15 -5 22 56 51 85 26 26 37 29 46 15z m132 -9 c9 -5 1 -12 -23 -21 -26
-9 -40 -23 -50 -48 -20 -46 -49 -42 -49 7 0 30 5 38 35 52 38 19 68 22 87 10z
m681 -16 c20 -19 28 -86 10 -86 -7 0 -18 14 -23 32 -8 25 -19 35 -54 46 -31
11 -40 17 -30 23 17 12 80 2 97 -15z m-358 -6 c27 -27 26 -74 -2 -92 -29 -19
-67 -4 -70 26 -5 78 29 109 72 66z m-890 -15 c10 -12 3 -18 -45 -40 -86 -38
-96 -33 -43 22 34 35 68 42 88 18z m1811 -14 c29 -30 37 -51 19 -51 -7 0 -90
36 -109 47 -2 1 -1 9 2 18 10 25 56 18 88 -14z m-2555 -7 c-3 -9 -6 -20 -6
-25 0 -5 -12 -9 -27 -9 l-26 0 23 25 c27 28 45 33 36 9z m-369 -40 c-15 -13
-33 -24 -40 -24 -7 0 4 14 23 29 42 35 57 30 17 -5z m3963 4 c0 -12 -14 -10
-28 4 -19 19 -14 29 8 16 11 -7 20 -16 20 -20z m763 -61 c-34 -36 -53 -68 -53
-91 0 -24 62 -113 88 -128 13 -7 37 -29 54 -50 24 -30 36 -38 63 -38 18 0 38
-7 45 -15 20 -24 -12 -52 -73 -65 -29 -6 -61 -13 -73 -16 -19 -5 -22 0 -27 45
-8 66 -45 136 -101 191 -53 51 -59 84 -23 125 23 26 119 86 126 79 3 -2 -9
-19 -26 -37z m-4821 -5 c-23 -22 -42 -44 -42 -50 0 -6 -5 -23 -10 -37 -10 -26
-11 -26 -35 -11 -25 16 -39 8 -29 -17 3 -10 -8 -25 -31 -44 -20 -15 -45 -47
-56 -70 -10 -24 -25 -43 -34 -43 -21 0 -19 8 12 53 18 26 26 50 25 71 -4 38
42 146 62 146 8 0 18 -4 21 -10 11 -17 40 -11 96 19 30 17 56 30 58 30 2 1
-14 -16 -37 -37z m353 28 c-7 -12 -65 -43 -65 -35 0 5 61 45 68 45 2 0 0 -5
-3 -10z m1130 -20 c-16 -16 -31 -28 -33 -25 -4 4 48 54 57 55 3 0 -8 -13 -24
-30z m2345 -2 c0 -11 -7 -10 -31 2 -28 16 -23 32 6 18 14 -6 25 -15 25 -20z
m566 -48 c24 -41 43 -82 44 -90 1 -33 60 -140 125 -225 41 -54 78 -115 92
-153 l23 -62 -26 16 c-43 29 -214 279 -214 314 0 9 -25 67 -55 130 -54 112
-67 156 -44 148 7 -2 31 -37 55 -78z m-4976 13 c36 -46 27 -87 -35 -152 -61
-65 -85 -112 -85 -171 0 -45 -7 -48 -90 -30 -66 15 -91 30 -88 54 2 16 12 22
41 24 31 3 49 15 106 70 72 71 111 125 111 155 0 11 -9 30 -20 44 -11 14 -20
32 -20 39 0 20 56 -3 80 -33z m175 -108 c0 -98 -4 -137 -13 -147 -11 -11 -16
-9 -29 7 -12 17 -14 41 -8 145 7 124 17 162 39 140 7 -7 11 -61 11 -145z
m4589 134 c29 -17 41 -19 71 -10 45 12 65 5 65 -23 0 -11 6 -41 14 -66 16 -54
12 -70 -18 -70 -39 0 -60 24 -65 73 -1 4 -10 7 -21 7 -11 0 -20 6 -20 13 0 7
-16 32 -36 55 -40 48 -39 50 10 21z m-3916 -40 c-7 -21 -14 -27 -21 -20 -7 7
-5 17 8 31 24 27 26 25 13 -11z m1027 1 c3 -5 -1 -10 -9 -10 -9 0 -16 5 -16
10 0 6 4 10 9 10 6 0 13 -4 16 -10z m1585 0 c0 -5 -5 -10 -11 -10 -5 0 -7 5
-4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z m-3094 -32 c-3 -18 -24 -59 -46 -93 -21
-33 -52 -93 -69 -134 -16 -41 -41 -86 -54 -100 -14 -14 -39 -49 -56 -77 -35
-58 -101 -116 -101 -89 0 26 44 107 90 169 83 108 109 149 116 180 9 44 39
100 75 141 39 44 54 45 45 3z m4124 22 c0 -5 -9 -10 -20 -10 -11 0 -20 5 -20
10 0 6 9 10 20 10 11 0 20 -4 20 -10z m699 -121 c1 -62 -9 -111 -22 -106 -17
5 -35 68 -36 122 -1 35 6 60 21 85 l22 35 7 -45 c4 -25 8 -66 8 -91z m-4554
-79 c-18 -56 -130 -150 -203 -169 -42 -12 -73 -43 -106 -109 -13 -25 -36 -41
-102 -74 -97 -47 -112 -44 -90 20 16 43 81 159 114 200 20 25 126 67 148 58
11 -4 10 -11 -4 -35 -22 -39 -11 -48 30 -24 33 19 49 43 65 97 6 23 18 33 55
45 26 9 53 22 60 29 18 18 42 -9 33 -38z m4329 18 c9 -12 16 -26 16 -30 0 -14
-28 -9 -44 8 -9 8 -16 22 -16 30 0 22 27 17 44 -8z m-202 -8 c39 -11 58 -34
58 -69 0 -11 18 -39 40 -62 22 -23 40 -44 40 -46 0 -10 -46 -1 -78 16 -37 19
-152 131 -152 149 0 24 32 28 92 12z m1028 -9 c0 -10 -7 -24 -15 -31 -12 -10
-16 -9 -21 4 -3 9 -4 23 0 31 8 21 36 19 36 -4z m-4500 -47 c6 -14 8 -29 5
-33 -2 -5 -37 -11 -77 -14 -41 -3 -103 -17 -141 -31 -92 -35 -101 -33 -37 11
51 35 183 89 223 92 10 1 21 -10 27 -25z m2841 -3 c86 -33 189 -93 170 -99 -6
-2 -42 8 -79 23 -39 16 -95 30 -134 33 -74 5 -90 15 -77 50 11 29 29 28 120
-7z m-2531 -24 c15 -8 27 -24 30 -42 4 -26 11 -30 49 -37 24 -4 46 -9 48 -12
11 -10 -78 -45 -170 -66 -103 -24 -129 -23 -75 3 43 20 85 64 72 77 -25 25
-89 27 -184 5 -52 -12 -99 -20 -104 -17 -17 10 36 38 102 55 37 9 90 23 117
31 63 18 84 19 115 3z m2271 -16 c30 -10 70 -21 90 -25 32 -6 116 -56 93 -56
-5 0 -55 10 -112 22 -80 17 -112 19 -149 12 -64 -12 -66 -33 -7 -71 27 -18 42
-33 35 -37 -12 -8 -140 24 -208 52 -42 16 -56 32 -30 32 33 0 87 22 87 36 0
42 19 54 84 54 35 0 87 -8 117 -19z m1107 4 c56 -24 110 -82 151 -165 49 -97
58 -130 34 -130 -33 0 -157 56 -167 76 -6 10 -30 44 -52 75 -30 41 -39 60 -33
72 6 9 5 26 -1 42 -5 14 -10 31 -10 36 0 14 36 11 78 -6z m-4858 -30 c0 -16
-34 -38 -44 -28 -3 3 -6 15 -6 25 0 13 7 18 25 18 15 0 25 -6 25 -15z m5108
-8 c2 -12 -1 -15 -12 -11 -19 7 -22 38 -3 32 6 -3 13 -12 15 -21z m-5460 -33
c23 -16 28 -39 13 -62 -7 -11 -14 -11 -43 1 -48 20 -56 36 -28 59 27 22 30 22
58 2z m5840 -21 c3 -17 -5 -26 -34 -38 -20 -8 -42 -15 -49 -15 -21 0 -26 31
-10 57 13 20 23 24 53 21 29 -2 38 -8 40 -25z m-4658 8 c0 -17 -75 -74 -130
-99 -30 -14 -76 -42 -101 -64 -26 -21 -50 -38 -54 -38 -14 0 29 42 75 73 25
16 63 47 85 67 57 54 125 87 125 61z m3458 -10 c20 -11 59 -38 87 -61 27 -23
65 -50 85 -60 19 -10 53 -34 75 -54 34 -30 37 -35 19 -36 -12 0 -48 24 -83 55
-34 30 -69 55 -79 55 -20 1 -63 24 -120 65 -70 51 -60 75 16 36z m-4418 -20
c0 -5 -7 -14 -15 -21 -12 -10 -15 -10 -15 2 0 8 3 18 7 21 9 10 23 9 23 -2z
m5364 -16 c3 -8 2 -15 -3 -15 -5 0 -14 7 -21 15 -10 12 -10 15 3 15 8 0 17 -7
21 -15z m-5437 -60 c10 -27 -26 -99 -78 -159 -53 -59 -99 -88 -159 -101 -31
-6 -105 -42 -135 -65 -15 -11 -55 -19 -55 -11 0 4 36 25 81 47 67 32 91 51
141 109 33 39 69 88 80 110 11 22 30 50 41 63 24 26 75 30 84 7z m5559 -7 c11
-13 27 -40 37 -60 9 -20 31 -54 50 -75 18 -21 48 -57 66 -81 l33 -43 -28 6
c-76 17 -114 38 -157 88 -81 95 -103 147 -75 175 19 19 53 14 74 -10z m-3356
-8 c17 -6 -3 -8 -62 -8 -47 0 -92 5 -100 9 -17 11 128 10 162 -1z m1195 0
c-43 -11 -175 -11 -150 0 11 5 56 8 100 8 57 0 71 -3 50 -8z m-210 -20 c-3 -5
-18 -10 -33 -10 -21 0 -24 2 -12 10 20 13 53 13 45 0z m-2656 -127 c-16 -30
-85 -81 -165 -122 -65 -33 -84 -37 -84 -21 0 14 106 97 160 125 25 12 56 35
70 50 21 23 26 24 28 10 2 -10 -2 -29 -9 -42z m4470 -2 c59 -31 191 -133 191
-148 0 -45 -247 112 -265 168 -10 30 5 43 22 20 8 -12 31 -30 52 -40z m-2459
-100 c0 -5 51 -10 113 -10 162 -2 207 -4 207 -13 0 -8 -47 -11 -183 -12 -63
-1 -71 -8 -28 -25 30 -13 221 -13 221 0 0 4 14 5 30 4 29 -3 40 8 20 20 -19
12 -10 25 18 26 l27 1 -30 8 c-46 13 166 0 278 -16 53 -8 150 -26 215 -40 64
-14 156 -31 203 -39 48 -7 101 -21 119 -30 49 -25 152 -63 233 -84 113 -31
578 -261 637 -316 14 -12 41 -31 60 -40 43 -22 279 -198 375 -280 189 -162
400 -383 538 -565 31 -41 63 -84 72 -95 35 -46 106 -157 131 -207 15 -29 45
-79 67 -110 24 -34 40 -69 41 -87 1 -17 4 -31 9 -31 4 0 7 -16 7 -35 0 -19 4
-35 10 -35 5 0 7 8 4 18 -10 27 37 -48 62 -103 13 -27 23 -57 23 -65 -1 -13
-1 -13 -6 0 -14 40 -54 120 -63 125 -6 3 -10 -3 -10 -15 0 -12 5 -18 10 -15 6
3 10 2 10 -3 0 -6 -7 -12 -15 -16 -11 -4 -15 2 -16 22 0 15 -3 21 -6 13 -5
-11 -7 -11 -14 0 -6 10 -9 6 -9 -13 0 -14 5 -28 10 -30 6 -2 12 -14 15 -26 2
-13 7 -33 11 -44 4 -14 3 -18 -6 -13 -8 5 -9 2 -2 -11 5 -11 14 -32 19 -49 5
-16 14 -29 19 -28 6 1 16 -14 23 -33 7 -19 16 -34 20 -34 5 0 11 -9 14 -20 3
-11 11 -20 19 -19 10 0 10 2 1 6 -7 2 -13 20 -13 39 0 21 4 33 10 29 6 -3 10
-1 10 4 0 6 -4 11 -10 11 -5 0 -10 5 -10 11 0 5 5 7 11 3 8 -4 9 1 5 17 -4 13
9 -5 28 -41 19 -36 35 -69 35 -75 0 -5 -7 2 -15 18 -16 29 -31 38 -16 10 5
-10 7 -24 5 -30 -3 -7 0 -13 5 -13 11 0 35 -87 27 -100 -3 -5 -1 -11 5 -15 6
-4 8 -10 5 -15 -7 -12 17 -110 27 -110 4 0 8 -5 8 -11 0 -5 -4 -7 -10 -4 -5 3
-10 -1 -10 -10 0 -9 5 -13 10 -10 6 4 10 -5 10 -19 0 -14 5 -26 10 -26 6 0 10
-17 10 -37 0 -28 -3 -34 -10 -23 -11 17 -11 16 -14 -22 -1 -16 2 -28 6 -28 4
0 9 8 9 18 1 16 2 16 6 0 2 -9 13 -20 24 -23 14 -5 19 -16 19 -46 0 -36 -14
-49 -30 -27 -3 3 -5 -33 -5 -80 -1 -48 1 -77 3 -64 2 12 8 20 13 17 5 -4 9 -1
9 6 0 6 9 19 19 28 11 9 21 30 24 46 4 32 12 -97 9 -153 -1 -22 2 -31 10 -26
7 5 9 -3 6 -25 -2 -17 -1 -31 3 -31 11 0 18 -70 29 -289 12 -216 3 -400 -25
-555 -8 -43 -15 -104 -15 -135 0 -62 -22 -156 -77 -326 -20 -60 -39 -138 -43
-172 -6 -43 -24 -95 -60 -170 -28 -59 -59 -133 -69 -163 -23 -73 -97 -206
-105 -188 -3 7 -3 3 0 -9 2 -12 -2 -38 -11 -59 -20 -48 -85 -134 -102 -134
-16 0 -17 22 -1 38 9 9 9 12 0 12 -7 0 -12 5 -12 11 0 5 5 7 10 4 6 -3 10 3
10 14 0 12 5 21 10 21 6 0 10 7 10 15 0 12 -4 13 -25 3 -14 -6 -25 -17 -25
-24 0 -7 -6 -19 -13 -26 -18 -18 -38 -78 -30 -91 3 -6 1 -7 -5 -3 -7 4 -17 -3
-24 -18 -6 -14 -15 -26 -20 -26 -4 0 -8 -9 -8 -21 0 -11 -4 -17 -10 -14 -5 3
-10 1 -10 -5 0 -6 -11 -22 -25 -36 -14 -14 -23 -30 -20 -35 4 -5 1 -9 -4 -9
-6 0 -11 -8 -10 -17 0 -13 2 -14 6 -4 5 11 7 11 14 0 6 -10 9 -8 9 8 0 13 9
28 20 35 11 7 20 19 20 26 0 17 26 43 34 35 3 -4 6 1 6 10 0 10 6 17 13 18 9
0 9 2 0 6 -10 3 -10 7 0 18 9 9 14 10 19 1 7 -10 9 -5 8 19 -1 14 30 34 44 29
9 -3 16 -8 16 -10 0 -11 -45 -90 -74 -132 -19 -26 -43 -65 -54 -87 -11 -22
-32 -51 -47 -65 -15 -14 -42 -48 -61 -76 -88 -136 -441 -495 -609 -619 -39
-29 -77 -60 -84 -69 -18 -22 -209 -149 -269 -180 -26 -13 -64 -38 -85 -54 -37
-30 -297 -163 -347 -178 -14 -4 -66 -26 -115 -50 -50 -23 -113 -48 -140 -55
-28 -6 -117 -33 -200 -60 -86 -27 -180 -51 -220 -54 -38 -4 -126 -20 -195 -35
-210 -47 -286 -55 -565 -54 -275 0 -430 14 -587 54 -42 11 -120 25 -175 30
-69 7 -142 25 -248 59 -82 26 -172 54 -200 60 -27 7 -90 32 -140 56 -49 24
-113 50 -141 59 -28 9 -73 31 -100 48 -27 17 -76 42 -109 56 -33 13 -89 46
-125 72 -36 26 -81 55 -100 65 -48 24 -218 141 -255 175 -16 15 -50 41 -75 59
-121 85 -307 260 -410 386 -32 39 -87 103 -124 144 -36 40 -66 77 -66 82 0 4
-22 34 -48 66 -54 65 -202 285 -202 300 0 5 -22 45 -48 89 -61 100 -171 326
-179 368 -3 17 -30 87 -58 156 -29 69 -56 147 -59 173 -4 27 -14 67 -22 90 -8
23 -30 103 -48 177 -28 114 -36 174 -51 388 -18 260 -16 575 5 737 5 39 9 109
10 157 0 84 18 169 89 418 11 36 22 83 25 106 3 22 26 86 50 141 25 55 52 126
61 157 30 102 232 501 254 501 5 0 13 4 18 9 5 5 3 6 -4 2 -17 -10 -16 0 3 29
9 14 20 23 24 20 5 -3 12 0 16 6 4 8 3 9 -4 5 -7 -4 -12 -4 -12 -1 0 10 66
110 110 167 23 29 62 81 88 115 143 187 442 498 470 488 7 -3 11 -1 8 3 -8 13
82 84 96 76 6 -4 8 -3 4 4 -7 11 86 92 135 117 25 13 27 13 24 -3 -1 -9 -8
-16 -14 -14 -6 1 -11 -4 -11 -12 0 -8 -18 -23 -40 -33 -22 -10 -42 -26 -45
-35 -4 -10 -13 -18 -21 -18 -8 0 -14 -6 -14 -14 0 -8 -8 -17 -17 -20 -10 -3
-36 -22 -58 -41 -22 -19 -46 -33 -53 -30 -7 3 -10 0 -7 -5 3 -6 -1 -10 -9 -10
-9 0 -15 -3 -15 -7 3 -21 -3 -33 -17 -33 -8 0 -12 -4 -9 -10 3 -5 2 -10 -3
-10 -6 0 -35 -25 -66 -55 -30 -30 -53 -55 -51 -55 6 0 -42 -54 -70 -80 -51
-47 -81 -70 -92 -70 -7 0 -13 -5 -13 -11 0 -5 5 -7 11 -3 24 14 -23 -54 -69
-102 -7 -8 -11 -14 -7 -14 7 0 0 -9 -55 -70 -22 -24 -40 -47 -40 -51 0 -4 -6
-14 -13 -21 -7 -7 -30 -39 -51 -72 -29 -44 -41 -55 -48 -45 -7 10 -8 8 -3 -7
3 -13 0 -28 -8 -35 -7 -8 -22 -30 -33 -49 -10 -19 -20 -37 -22 -40 -2 -2 -8 2
-13 10 -9 12 -10 12 -5 -3 3 -10 -1 -35 -10 -55 -12 -30 -17 -34 -25 -22 -8
12 -9 10 -4 -6 3 -13 -2 -30 -13 -45 -11 -13 -27 -42 -37 -63 -15 -32 -20 -36
-25 -21 -6 17 -7 17 -12 -3 -3 -11 -1 -23 6 -26 6 -2 3 -5 -6 -5 -10 -1 -18
-9 -18 -18 0 -15 2 -16 10 -3 8 13 10 12 10 -4 0 -12 -5 -16 -15 -12 -10 3
-18 -1 -21 -12 -3 -9 -10 -25 -15 -36 -7 -13 -7 -16 0 -11 7 3 11 -2 11 -14 0
-12 -3 -21 -8 -21 -9 0 -42 -74 -42 -96 0 -10 -6 -14 -15 -10 -14 5 -20 -11
-17 -53 0 -8 -7 -21 -16 -28 -11 -8 -12 -12 -4 -13 15 0 16 -30 1 -30 -5 0 -7
5 -4 10 3 6 1 10 -4 10 -6 0 -11 -7 -11 -15 0 -8 5 -15 10 -15 6 0 7 -7 1 -17
-5 -10 -14 -31 -21 -48 -20 -48 -28 -60 -38 -58 -5 1 -8 -23 -7 -53 3 -50 -7
-71 -18 -41 -3 6 -6 2 -6 -11 -1 -13 4 -33 11 -45 11 -21 11 -21 19 -2 6 15 8
9 8 -20 -1 -22 -3 -34 -6 -27 -10 26 -18 10 -15 -30 2 -32 1 -39 -7 -28 -8 12
-11 6 -11 -22 0 -21 4 -38 10 -38 5 0 7 7 4 15 -4 8 -1 15 5 15 6 0 11 -7 11
-17 0 -29 11 -6 24 51 8 31 10 59 5 62 -9 5 -7 39 6 112 7 40 25 68 25 40 1
-7 19 30 42 82 25 59 36 96 29 98 -16 5 -14 22 4 22 10 0 13 9 10 32 -2 17 -1
36 3 42 3 6 8 20 9 30 4 26 13 34 13 13 0 -10 5 -17 10 -17 6 0 10 8 10 18 0
10 9 24 20 32 11 8 20 19 20 25 0 17 24 65 33 65 4 0 7 9 7 20 0 11 9 36 21
56 11 21 18 46 16 56 -5 16 -3 17 9 7 10 -8 14 -8 14 0 0 6 -1 12 -2 14 -2 1
7 13 20 27 12 13 22 32 22 42 0 11 5 16 10 13 6 -4 10 5 10 20 0 15 4 24 10
20 6 -4 10 5 10 21 0 15 3 25 6 21 9 -9 86 92 79 104 -4 5 -1 8 7 7 7 -2 12 5
12 14 -1 10 2 16 6 13 14 -8 17 18 7 46 -9 23 -7 30 16 53 23 23 27 24 35 10
7 -13 14 -10 40 22 18 21 31 45 31 54 -1 11 -3 10 -6 -2 -2 -10 -9 -18 -14
-18 -11 0 -11 3 1 34 11 29 30 35 30 9 0 -15 2 -14 15 3 8 10 14 24 15 29 0 6
-4 3 -10 -5 -5 -8 -12 -11 -16 -7 -13 12 16 35 38 29 17 -4 20 -2 15 12 -4 9
-3 15 1 12 5 -3 43 31 86 74 69 72 76 82 65 101 -9 18 -6 26 25 55 19 18 38
29 41 24 4 -6 2 -10 -3 -10 -5 0 -9 -7 -9 -15 0 -9 29 11 68 47 38 35 85 78
106 96 21 18 30 29 21 26 -19 -6 -24 12 -10 32 12 17 102 74 102 64 0 -4 -6
-10 -12 -13 -7 -2 -10 -8 -5 -12 4 -5 18 0 32 11 13 10 77 59 141 108 65 50
112 92 106 94 -7 2 -12 8 -12 14 0 7 5 6 14 -1 16 -13 52 -2 98 30 24 17 27
24 17 36 -8 9 -9 17 -3 21 13 10 17 52 5 52 -6 0 -25 -8 -43 -18 -18 -10 -41
-19 -52 -19 -18 0 -18 1 0 14 10 7 28 13 41 13 13 0 23 5 23 11 0 5 -6 7 -12
4 -7 -4 1 5 17 20 41 36 84 58 101 51 9 -3 12 -1 9 4 -3 6 -2 10 4 10 5 0 12
-6 14 -12 4 -8 6 -7 6 4 1 11 7 15 21 11 11 -3 17 -9 14 -14 -3 -5 -10 -7 -15
-4 -5 4 -9 0 -9 -9 0 -8 6 -17 13 -20 6 -4 2 -4 -10 -2 -17 3 -23 -1 -23 -14
0 -16 4 -17 29 -7 17 6 28 14 25 18 -2 4 3 12 11 19 12 10 15 10 15 -1 0 -9 3
-10 8 -3 4 6 15 13 25 17 9 3 17 14 17 22 0 9 4 14 8 11 4 -2 14 -1 22 5 11 6
4 9 -22 9 -20 0 -39 4 -43 9 -10 17 107 82 122 68 3 -3 12 1 21 9 8 9 23 13
33 11 10 -3 20 2 23 10 4 9 13 13 22 9 8 -3 13 -2 10 3 -7 11 22 23 36 14 7
-3 8 -1 4 5 -8 13 34 36 80 45 23 4 31 2 31 -9 0 -8 -3 -14 -7 -14 -4 0 -17
-9 -30 -20 -18 -15 -27 -17 -42 -9 -15 8 -19 8 -14 0 6 -10 -13 -16 -36 -12
-5 0 -8 -5 -8 -13 0 -7 -9 -16 -20 -19 -11 -3 -20 -10 -20 -17 0 -6 5 -8 10
-5 6 3 10 0 10 -7 0 -10 2 -10 9 0 4 8 11 13 15 13 26 -4 46 1 41 9 -3 6 1 10
9 10 9 0 16 5 16 10 0 5 3 9 8 9 19 -3 56 3 45 8 -7 2 -13 9 -13 14 0 6 11 2
24 -8 19 -14 22 -21 13 -33 -11 -13 -10 -13 6 -1 9 7 17 19 16 25 -3 29 3 36
34 37 30 0 31 1 10 11 -28 14 -30 28 -3 28 11 0 20 6 20 14 0 17 55 41 71 30
7 -4 10 -3 6 3 -8 12 20 26 34 17 5 -3 9 -1 9 4 0 16 73 40 138 46 34 3 106
17 160 30 140 36 286 59 290 48 2 -7 15 -7 35 -1 17 5 60 9 95 10 40 1 59 5
50 10 -8 6 0 9 25 9 20 0 37 -4 37 -9z m317 3 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m43 -26 c0 -4 -7 -8 -15 -8 -8 0 -15 4 -15 8 0 5 7 9
15 9 8 0 15 -4 15 -9z m-2906 -39 c23 -18 26 -28 26 -80 0 -42 -4 -61 -14 -65
-26 -10 -106 53 -106 83 0 7 -11 13 -27 13 -30 0 -103 -52 -183 -130 -83 -81
-78 -46 11 66 87 110 228 164 293 113z m5642 -10 c35 -18 82 -51 103 -74 37
-40 87 -123 79 -131 -2 -2 -37 28 -78 67 -73 68 -145 113 -162 103 -4 -3 -17
-23 -28 -44 -33 -65 -89 -75 -78 -15 3 15 0 40 -6 57 -10 26 -8 32 13 49 34
27 84 24 157 -12z m239 11 c3 -5 -1 -10 -9 -10 -8 0 -18 5 -21 10 -3 6 1 10 9
10 8 0 18 -4 21 -10z m-5415 -60 c0 -34 -13 -38 -55 -16 -33 18 -67 9 -191
-50 -52 -25 -91 -25 -50 0 11 6 36 25 55 42 56 49 71 53 159 54 l82 0 0 -30z
m4725 17 c46 -28 125 -90 125 -98 0 -13 -5 -12 -73 21 -75 35 -148 56 -170 47
-26 -10 -20 -41 14 -71 41 -36 27 -53 -33 -40 l-43 9 -3 59 c-2 32 -1 65 2 72
7 18 152 19 181 1z m-5856 -64 c-1 -61 -2 -65 -11 -36 -9 28 -4 103 7 103 3 0
5 -30 4 -67z m-191 -64 c2 -19 -1 -46 -6 -59 -10 -25 -10 -25 -28 12 -14 31
-15 42 -6 64 16 34 36 26 40 -17z m1312 26 c19 -23 0 -37 -59 -43 -43 -4 -50
-7 -48 -25 1 -15 -6 -22 -28 -28 -32 -9 -65 -3 -65 11 0 5 18 15 41 21 22 7
47 22 55 33 29 42 82 58 104 31z m5928 -8 c5 -25 -18 -98 -28 -92 -11 7 -14
98 -3 109 10 10 28 1 31 -17z m-6430 -81 l-3 -40 -95 -36 c-52 -20 -110 -44
-127 -53 -33 -17 -33 -17 -33 7 0 34 23 49 64 42 30 -6 37 -2 67 32 19 21 47
60 62 86 l28 48 20 -23 c14 -17 19 -34 17 -63z m-938 3 c0 -12 -7 -19 -20 -19
-14 0 -20 7 -20 23 0 12 -3 33 -6 47 l-6 25 26 -28 c14 -15 26 -37 26 -48z
m1705 60 c-58 -12 -114 -11 -95 1 8 5 42 9 75 9 58 -1 59 -2 20 -10z m4088 4
c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13 -5z m796 -52 c18 -27 43
-57 57 -65 28 -19 31 -36 7 -36 -36 0 -136 37 -145 53 -5 11 -5 32 2 57 15 56
40 53 79 -9z m-5404 29 c4 -6 -1 -22 -10 -36 -13 -20 -23 -25 -48 -22 -35 3
-47 28 -27 53 14 17 76 20 85 5z m5145 -5 c17 -21 8 -50 -17 -53 -24 -4 -60
39 -48 58 9 15 51 12 65 -5z m-6571 -19 c39 -17 45 -60 20 -136 -12 -36 -31
-83 -42 -105 -36 -67 -33 -8 2 74 43 96 41 113 -14 148 -24 15 -44 31 -45 36
0 9 44 -1 79 -17z m7715 -20 c-3 -9 -10 -16 -15 -16 -11 0 -11 7 -2 39 5 19 8
20 14 8 5 -8 6 -22 3 -31z m289 32 c-18 -6 -33 -15 -33 -19 0 -5 -13 -15 -30
-24 -18 -9 -30 -23 -30 -36 0 -11 14 -46 30 -79 17 -33 30 -66 30 -75 0 -32
-18 -12 -48 53 -37 81 -48 122 -33 122 6 0 11 9 11 20 0 11 1 20 3 20 1 0 20
7 42 15 22 8 51 14 65 14 22 -1 21 -2 -7 -11z m-5883 -38 c0 -25 -16 -26 -24
-2 -3 9 -9 23 -12 30 -5 11 -2 12 15 3 11 -7 21 -21 21 -31z m3719 10 c-11
-30 -20 -36 -32 -23 -6 5 30 53 39 53 2 0 -2 -13 -7 -30z m589 6 c11 -11 9
-15 -12 -20 -40 -10 -80 16 -59 38 10 9 55 -2 71 -18z m-4628 -84 c0 -24 -41
-62 -66 -62 -48 0 -59 75 -11 82 40 6 77 -4 77 -20z m4426 -6 c6 -24 -10 -66
-24 -66 -16 0 -82 51 -82 64 0 21 11 26 56 26 38 0 44 -3 50 -24z m417 -13
c-41 -16 -46 -26 -35 -66 19 -67 -18 -85 -82 -42 -20 14 -42 25 -49 25 -6 0
-48 -16 -94 -36 -58 -24 -94 -34 -120 -32 l-38 3 67 32 c37 17 82 34 100 38
74 15 164 51 181 71 17 22 58 34 87 25 9 -3 3 -9 -17 -18z m335 15 c16 -16 15
-38 0 -38 -23 0 -41 18 -35 34 8 19 19 20 35 4z m-6551 -20 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m893 12 c12 -8 11 -10 -7 -10 -12 0 -25 5
-28 10 -8 13 15 13 35 0z m5888 -100 c3 -74 1 -85 -19 -106 -25 -26 -44 -31
-52 -11 -6 17 18 146 33 180 22 46 35 26 38 -63z m-6763 40 c19 -37 19 -60 0
-60 -21 0 -37 35 -29 65 8 32 10 31 29 -5z m84 -25 c15 -41 18 -61 11 -80 -13
-34 -23 -31 -43 10 -18 37 -21 65 -11 103 10 35 21 26 43 -33z m151 33 c0 -13
-3 -40 -6 -60 l-7 -38 -19 36 c-18 33 -18 38 -3 60 19 30 35 31 35 2z m77 -23
c23 -66 18 -205 -6 -205 -11 0 -23 76 -27 173 -4 87 9 100 33 32z m6696 11
c12 -37 2 -70 -28 -90 -48 -31 -59 7 -25 82 22 48 39 51 53 8z m682 -83 c-34
-64 -36 -66 -31 -30 3 21 17 63 32 95 25 51 28 54 31 30 3 -16 -9 -51 -32 -95z
m-6638 72 c60 -20 125 -46 144 -58 l34 -22 -33 -3 c-22 -2 -56 8 -105 32 -40
20 -79 36 -87 36 -8 0 -35 -14 -59 -30 -59 -40 -81 -40 -81 3 0 42 25 77 55
77 13 0 72 -16 132 -35z m6023 1 c0 -15 -28 -41 -36 -34 -2 3 1 17 7 31 12 26
29 28 29 3z m-7713 -45 c4 -26 2 -41 -5 -41 -14 0 -35 67 -28 87 10 25 27 2
33 -46z m7839 -52 c-9 -46 -37 -100 -50 -96 -26 10 10 127 40 127 13 0 15 -6
10 -31z m-7326 -1 c24 -27 29 -98 5 -98 -20 0 -45 40 -52 84 -4 29 -2 36 10
36 9 0 25 -10 37 -22z m1687 -33 c0 -8 -9 -15 -21 -15 -15 0 -17 -3 -8 -9 17
-11 -11 -36 -32 -28 -9 4 -16 -1 -18 -11 -3 -12 -6 -14 -11 -5 -5 8 9 25 40
47 26 20 48 36 49 36 0 0 1 -7 1 -15z m-2039 -40 c27 -56 27 -55 -2 -55 -26 0
-76 41 -76 63 0 18 20 37 40 37 9 0 26 -20 38 -45z m7132 -20 c0 -70 -27 -195
-41 -195 -23 0 -31 48 -21 129 16 131 62 180 62 66z m930 46 c5 -11 7 -26 4
-35 -6 -16 -63 -46 -89 -46 -15 0 -14 3 3 28 11 15 23 37 26 50 9 27 41 29 56
3z m-7490 -42 c16 -60 -5 -81 -36 -34 -14 21 -14 30 -4 55 15 37 26 31 40 -21z
m1600 45 c0 -3 -7 -12 -15 -21 -20 -19 -32 -10 -14 11 14 15 29 21 29 10z
m4830 -17 c0 -39 -103 -77 -205 -76 l-50 0 40 21 c22 12 76 31 120 43 100 28
95 28 95 12z m-5869 -27 c35 -10 73 -26 84 -34 19 -14 17 -15 -30 -16 -88 0
-195 36 -195 67 0 19 42 14 141 -17z m6323 -27 c-10 -30 -37 -38 -42 -12 -1 8
2 28 8 44 10 27 12 27 27 12 13 -13 15 -23 7 -44z m-5349 27 c3 -5 1 -10 -4
-10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z m6050 -2 c26 -12 34
-28 13 -28 -7 0 -23 -3 -35 -6 -18 -5 -23 -2 -23 13 0 17 8 33 16 33 2 0 15
-5 29 -12z m-8302 -5 c9 -9 -12 -33 -29 -33 -8 0 -14 9 -14 20 0 19 28 28 43
13z m345 -21 c3 -10 -5 -13 -29 -10 -18 2 -34 9 -37 16 -3 10 5 13 29 10 18
-2 34 -9 37 -16z m7162 -13 c0 -21 -46 -119 -56 -119 -12 0 8 115 23 132 18
21 33 15 33 -13z m500 22 c0 -5 -14 -14 -30 -21 -38 -16 -45 -43 -25 -99 19
-54 19 -67 1 -51 -8 6 -22 34 -31 61 -16 47 -14 80 7 107 12 14 78 17 78 3z
m-7195 -22 c9 -27 -8 -45 -24 -26 -12 15 -5 47 9 47 5 0 12 -9 15 -21z m1340
-19 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z
m6118 -212 c2 -112 12 -246 20 -298 9 -52 19 -142 23 -199 6 -87 5 -103 -7
-99 -18 6 -26 61 -39 258 -5 85 -14 171 -20 190 -11 38 -14 290 -4 328 15 55
22 8 27 -180z m-7653 161 c0 -10 -4 -19 -10 -19 -5 0 -10 12 -10 26 0 14 4 23
10 19 6 -3 10 -15 10 -26z m-569 -160 c-5 -85 -17 -179 -25 -209 -9 -30 -16
-91 -16 -135 0 -71 -11 -168 -25 -232 -3 -13 -10 -23 -16 -23 -14 0 -3 162 18
255 9 42 15 114 14 178 0 60 6 148 14 195 23 134 26 145 37 134 6 -6 6 -65 -1
-163z m2004 131 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4
11 -10z m5129 -67 c-3 -21 1 -63 9 -93 8 -30 12 -63 9 -73 -6 -24 -31 -34 -42
-17 -9 15 -1 165 11 198 13 34 20 26 13 -15z m-5199 7 c3 -5 -1 -10 -10 -10
-9 0 -13 5 -10 10 3 6 8 10 10 10 2 0 7 -4 10 -10z m-1066 -104 c13 -55 -1
-89 -18 -44 -12 32 -21 115 -13 123 8 7 15 -12 31 -79z m6476 27 c-10 -72 -15
-83 -36 -83 -31 0 -19 71 18 112 23 26 25 23 18 -29z m-6275 -7 c0 -13 3 -44
6 -70 7 -46 6 -48 -17 -44 -34 4 -50 75 -25 113 21 31 36 32 36 1z m705 -53
c-9 -8 -18 -13 -21 -10 -8 8 19 36 29 30 4 -2 0 -11 -8 -20z m-1622 -33 c11
-10 29 -91 22 -98 -3 -3 -16 0 -31 6 -17 8 -31 9 -44 2 -38 -20 -50 -13 -50
31 0 63 14 81 58 73 20 -3 41 -10 45 -14z m1587 13 c0 -4 -10 -17 -22 -30 -23
-24 -48 -31 -48 -12 0 6 7 9 14 6 10 -4 13 -1 9 9 -3 10 2 14 18 14 19 0 21 2
9 10 -13 8 -12 10 3 10 9 0 17 -3 17 -7z m6401 -50 c8 -61 -1 -70 -49 -54 -35
12 -43 11 -63 -3 -13 -9 -27 -16 -31 -16 -14 0 -9 27 7 40 8 7 15 28 15 47 l0
34 58 -3 57 -3 6 -42z m-6883 -25 c7 -7 12 -19 12 -27 0 -13 -15 -16 -85 -16
-47 0 -85 3 -85 6 0 8 39 24 90 38 48 13 54 13 68 -1z m5785 -16 c21 -8 36
-17 33 -20 -3 -2 -39 -5 -81 -6 -70 -1 -75 0 -75 20 0 11 3 24 7 27 9 9 67 -1
116 -21z m307 -27 c0 -3 -9 -5 -20 -5 -11 0 -20 7 -20 15 0 13 4 14 20 5 11
-6 20 -13 20 -15z m-6480 10 c0 -2 -15 -12 -32 -21 -18 -9 -56 -41 -85 -70
-28 -30 -56 -54 -62 -54 -17 0 -13 36 7 59 33 39 172 109 172 86z m-520 -40
c6 -8 14 -32 17 -53 5 -36 0 -47 -52 -121 -31 -44 -61 -81 -67 -81 -23 0 16
109 56 155 26 30 35 75 16 75 -5 0 -10 9 -10 20 0 23 22 26 40 5z m680 5 c-8
-5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5 -5 -10z m6403 -6
c48 -24 67 -44 67 -71 0 -12 -4 -23 -8 -23 -13 0 -75 48 -94 73 -32 40 -19 48
35 21z m414 -31 c-7 -37 8 -71 37 -85 27 -11 49 -52 61 -107 l7 -33 -32 23
c-33 25 -110 146 -110 174 0 23 18 55 32 55 6 0 8 -11 5 -27z m-6333 -60 c-11
-12 -18 -13 -27 -4 -8 8 -7 11 6 11 9 0 17 5 17 11 0 5 5 7 10 4 6 -4 4 -12
-6 -22z m-24 -21 c0 -4 -9 -13 -20 -20 -23 -14 -26 -8 -7 13 13 16 27 19 27 7z
m-830 -25 c0 -16 -77 -93 -86 -85 -9 9 23 65 48 82 27 19 38 20 38 3z m6929
-23 c22 -28 28 -54 12 -54 -16 0 -71 51 -71 66 0 23 37 16 59 -12z m-8210
-170 c23 -74 51 -245 51 -307 0 -66 46 -136 89 -138 15 0 -6 -10 -47 -21 -40
-11 -76 -18 -79 -15 -3 3 -9 27 -14 53 -5 26 -16 55 -24 64 -27 30 -18 41 20
23 40 -19 56 -12 32 14 -8 10 -25 44 -37 76 -12 31 -26 57 -31 57 -16 0 -10
26 11 45 26 24 25 49 -5 110 -21 44 -46 112 -61 170 -4 14 7 7 35 -21 29 -31
45 -60 60 -110z m9491 39 c-15 -49 -33 -101 -39 -116 -18 -45 -14 -77 9 -78
11 -1 25 -3 30 -4 6 -1 15 -3 20 -4 6 0 10 -10 10 -22 0 -18 -5 -20 -41 -18
-48 4 -59 -8 -59 -68 0 -35 3 -43 18 -43 10 0 21 -2 24 -5 8 -8 -38 -117 -60
-141 -18 -21 -20 -21 -70 -6 -46 14 -49 17 -30 26 11 6 33 29 49 51 26 38 29
49 38 192 8 138 12 159 39 217 29 62 63 106 81 106 5 0 -4 -39 -19 -87z
m-1336 -12 c28 -31 10 -26 -38 11 -61 47 -65 52 -21 30 22 -11 49 -29 59 -41z
m-6827 22 c-22 -25 -77 -56 -77 -44 0 14 59 61 77 61 15 0 15 -2 0 -17z
m-1657 -17 c0 -8 -4 -18 -10 -21 -5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10
-6 10 -14z m1356 -42 c141 -41 161 -160 37 -220 l-54 -27 3 -46 3 -46 -38 -3
c-25 -2 -45 2 -56 12 -26 24 -86 46 -108 40 -11 -3 -43 1 -71 9 -65 20 -85 14
-116 -34 -34 -50 -50 -51 -42 -1 8 44 36 86 61 90 10 2 42 -4 72 -13 40 -13
65 -15 101 -8 l47 8 6 65 c11 113 45 180 97 189 2 1 28 -6 58 -15z m904 6 c0
-5 -4 -10 -10 -10 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -14 0 -13 18 3 35
14 17 27 19 27 5z m6545 -4 c43 -18 64 -58 81 -160 8 -44 20 -84 27 -88 22
-14 91 -8 135 12 54 25 87 14 107 -35 17 -39 20 -99 5 -90 -5 3 -17 22 -26 41
-23 47 -51 57 -111 39 -26 -8 -62 -15 -79 -15 -17 0 -53 -11 -80 -25 -79 -40
-124 -33 -124 20 0 35 -10 48 -66 92 -36 27 -64 70 -64 97 0 25 62 88 105 107
49 22 47 22 90 5z m-6988 -102 c3 -8 -24 -13 -79 -16 -67 -4 -94 -11 -143 -37
-33 -17 -69 -31 -80 -31 -18 0 -17 2 6 25 17 18 51 32 104 46 43 10 81 22 84
25 10 9 104 -1 108 -12z m6583 1 c23 -13 19 -14 -57 -15 -76 0 -94 5 -76 23
12 13 108 7 133 -8z m-8626 -37 c9 -12 16 -31 16 -41 0 -15 -6 -13 -40 14 -38
31 -41 32 -81 20 -22 -7 -44 -9 -47 -6 -14 14 31 32 83 34 45 1 56 -3 69 -21z
m8779 -16 c11 -12 18 -25 14 -28 -8 -8 -71 19 -106 45 l-24 18 47 -7 c27 -4
57 -16 69 -28z m1915 16 c25 -21 11 -27 -29 -13 -60 21 -87 19 -114 -11 -20
-22 -25 -23 -25 -10 0 10 5 26 11 38 10 18 17 20 77 13 37 -4 73 -12 80 -17z
m-10527 -46 l34 -47 -33 23 c-47 34 -82 89 -48 76 7 -3 29 -26 47 -52z m10222
-11 c-23 -20 -73 -63 -112 -96 -72 -61 -81 -77 -87 -155 -1 -14 -2 -29 -3 -34
-1 -9 -78 -2 -88 7 -2 3 -5 11 -6 19 -5 33 -26 17 -76 -57 l-53 -80 5 43 c3
26 16 57 34 79 15 20 39 52 53 72 23 32 33 37 86 46 59 10 64 13 170 113 60
56 111 97 113 91 2 -7 -14 -29 -36 -48z m-9909 43 c20 -8 20 -27 1 -65 -21
-39 -35 -28 -35 27 0 44 5 49 34 38z m-223 -121 l24 -26 -32 23 c-34 25 -39
30 -25 30 5 0 19 -12 33 -27z m2049 17 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10
10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-369 -29 c-11 -20 -68 -48 -78 -38 -3 4
2 18 11 32 12 17 25 25 47 25 26 0 28 -2 20 -19z m6727 7 c12 -12 17 -48 7
-48 -13 0 -62 30 -74 44 -12 14 -9 16 21 16 19 0 39 -5 46 -12z m-6833 -90
c-9 -43 -20 -54 -40 -38 -21 18 -19 36 7 54 33 23 40 20 33 -16z m6904 22 c25
-13 34 -39 20 -56 -19 -23 -38 -16 -47 19 -13 46 -6 55 27 37z m-8272 -28 c10
-13 9 -18 -7 -29 -17 -12 -19 -21 -14 -49 l6 -34 -48 0 -49 0 -2 71 -1 71 51
-7 c29 -4 58 -14 64 -23z m943 3 c0 -4 -8 -20 -17 -38 -16 -30 -18 -31 -21 -9
-2 13 2 30 8 38 12 14 30 19 30 9z m286 -9 c10 -8 16 -18 12 -22 -11 -10 -60
14 -53 26 8 14 18 13 41 -4z m7204 5 c0 -6 -10 -17 -22 -25 -27 -19 -48 -5
-28 19 14 16 50 21 50 6z m-8002 -55 c41 -62 41 -100 2 -218 -53 -158 -41
-188 75 -188 73 0 75 -1 90 -31 31 -59 2 -105 -31 -50 -20 34 -50 47 -137 57
-52 6 -61 4 -83 -17 -24 -22 -26 -22 -52 -7 -32 19 -55 60 -46 82 8 20 55 46
83 46 27 0 46 31 65 105 35 133 5 217 -79 217 -42 0 -44 9 -7 37 45 34 81 24
120 -33z m8860 34 c38 -23 34 -40 -8 -40 -56 0 -87 -65 -75 -158 11 -92 37
-139 87 -161 24 -10 51 -28 61 -39 18 -19 17 -21 -14 -66 -18 -25 -36 -46 -41
-46 -4 0 -15 12 -25 26 -16 25 -20 26 -82 21 -90 -8 -129 -23 -147 -59 -20
-39 -50 -34 -46 7 2 17 10 40 18 53 12 19 23 22 78 22 115 0 142 48 87 153
-21 40 -31 77 -36 130 -8 81 -2 101 43 150 31 32 55 34 100 7z m-9234 -51 c14
-17 26 -43 26 -57 l-1 -27 -34 37 c-33 35 -47 78 -26 78 5 0 21 -14 35 -31z
m661 -60 c-8 -12 -35 -12 -35 0 0 11 22 20 33 14 4 -2 5 -9 2 -14z m8091 -131
c77 -155 97 -198 90 -198 -7 0 -47 71 -113 198 -57 110 -73 152 -58 152 2 0
39 -69 81 -152z m114 137 c0 -8 -4 -15 -9 -15 -13 0 -22 16 -14 24 11 11 23 6
23 -9z m-9080 -15 c-49 -38 -104 -51 -85 -20 9 15 64 39 90 39 18 1 18 -1 -5
-19z m1015 -63 c-15 -29 -51 -100 -81 -159 -47 -92 -74 -134 -74 -114 0 3 39
86 86 183 55 112 89 171 91 160 3 -10 -7 -42 -22 -70z m8981 52 c16 -18 16
-19 -2 -19 -24 0 -66 28 -59 40 8 12 41 1 61 -21z m-9219 5 c6 -18 -42 -68
-56 -60 -12 8 -6 41 12 64 12 15 39 12 44 -4z m8421 4 c13 -13 17 -68 5 -68
-15 0 -53 45 -53 62 0 21 30 24 48 6z m-9718 -19 c0 -5 -7 -9 -15 -9 -15 0
-20 12 -9 23 8 8 24 -1 24 -14z m11010 1 c0 -21 -16 -26 -39 -11 -18 11 -5 31
20 31 12 0 19 -7 19 -20z m-8743 -52 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3
4 -12 1 -19z m-1445 -67 c37 -80 49 -121 38 -121 -14 0 -90 147 -90 175 0 40
18 21 52 -54z m7900 34 c0 -5 -5 -11 -11 -13 -6 -2 -11 4 -11 13 0 9 5 15 11
13 6 -2 11 -8 11 -13z m1452 -37 c-12 -40 -84 -178 -92 -178 -22 0 -18 18 20
94 22 44 45 87 50 94 17 20 30 14 22 -10z m-9475 -267 c11 -6 35 -11 55 -11
23 0 44 -8 63 -25 27 -24 28 -25 9 -40 -32 -24 -55 -18 -114 33 -96 82 -236
226 -256 262 -13 24 20 -3 102 -87 66 -67 129 -126 141 -132z m9796 199 c-22
-42 -131 -156 -184 -193 -26 -17 -63 -49 -83 -71 l-37 -41 -26 26 -26 25 26
28 c22 24 31 27 60 22 32 -6 39 -2 95 51 34 32 89 86 122 121 34 34 63 62 65
62 2 0 -3 -13 -12 -30z m-8620 -31 c42 -37 50 -72 9 -40 -36 28 -66 26 -132
-6 -54 -28 -108 -87 -154 -172 -19 -35 -38 -41 -38 -11 0 43 43 109 107 166
115 102 151 113 208 63z m7318 15 c64 -33 158 -126 188 -186 34 -67 35 -78 10
-78 -13 0 -25 15 -40 54 -25 59 -73 110 -142 150 -52 29 -86 33 -115 11 -25
-19 -35 -19 -27 0 6 16 71 65 86 65 4 0 22 -7 40 -16z m-7292 -177 c-11 -28
-69 -90 -101 -107 -17 -9 -20 -8 -20 13 0 29 27 58 80 88 22 12 40 27 40 33 0
5 2 7 5 4 3 -3 1 -17 -4 -31z m7229 -30 c30 -30 61 -61 68 -70 7 -10 27 -17
44 -17 48 0 68 -24 68 -82 0 -46 -1 -48 -29 -48 -32 0 -43 11 -59 57 -6 18
-37 58 -70 90 -55 54 -103 123 -85 123 5 0 33 -24 63 -53z m-8206 0 c6 -15 7
-30 3 -33 -12 -12 -37 18 -37 43 0 32 22 25 34 -10z m7976 -34 c0 -22 -8 -14
-19 20 -10 29 -9 30 4 13 8 -11 15 -26 15 -33z m1140 3 c0 -8 -4 -18 -10 -21
-5 -3 -10 3 -10 14 0 12 5 21 10 21 6 0 10 -6 10 -14z m90 -6 c0 -5 -4 -10
-10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m-8733 -27 c2 -6
-9 -19 -26 -28 -26 -13 -34 -28 -57 -101 -22 -69 -26 -98 -22 -155 4 -46 11
-76 22 -88 14 -16 15 -24 6 -40 -8 -17 -15 -19 -29 -12 -14 8 -21 33 -31 115
-15 126 -9 219 18 255 40 55 109 86 119 54z m8253 -13 c62 -44 65 -52 65 -189
-1 -135 -9 -185 -28 -178 -8 3 -11 27 -9 79 4 94 -35 236 -74 267 -32 25 -33
55 -1 45 12 -3 33 -15 47 -24z m-9260 10 c0 -5 -9 -10 -21 -10 -11 0 -17 5
-14 10 3 6 13 10 21 10 8 0 14 -4 14 -10z m10224 1 c3 -5 -9 -11 -27 -14 -37
-5 -48 0 -27 13 19 12 47 13 54 1z m-8847 -25 c1 -1 -9 -29 -22 -62 -21 -52
-27 -59 -52 -59 -26 0 -28 3 -31 46 -3 41 0 49 25 68 19 16 35 20 53 16 14 -4
26 -8 27 -9z m7674 -13 c45 -27 51 -73 9 -73 -17 0 -70 52 -70 69 0 25 23 26
61 4z m-7854 -5 c37 -13 40 -23 17 -56 -11 -16 -20 -20 -32 -13 -9 5 -32 6
-49 4 -30 -5 -33 -3 -33 20 0 15 9 32 22 41 27 19 32 19 75 4z m8913 -8 c0 -5
-9 -14 -20 -20 -22 -12 -27 -1 -8 18 14 14 28 16 28 2z m300 -3 c0 -38 -59
-49 -100 -19 l-25 17 55 6 c30 3 58 6 63 7 4 1 7 -4 7 -11z m-10535 -17 c-11
-12 -31 -20 -51 -20 -23 0 -37 6 -44 20 -10 19 -8 20 51 20 61 0 62 0 44 -20z
m230 10 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16
-10z m-584 -35 c0 -5 -9 -11 -21 -13 -20 -3 -27 8 -13 22 10 9 34 3 34 -9z
m834 -7 c-6 -15 -54 -42 -62 -35 -2 3 10 17 28 31 33 28 43 29 34 4z m10350 2
c0 -10 -8 -14 -25 -12 -31 5 -34 8 -25 22 10 16 50 8 50 -10z m-767 -54 c12
-4 22 -12 22 -17 0 -11 -27 -12 -32 0 -1 4 -22 20 -45 35 -24 14 -43 31 -43
37 0 6 17 -3 38 -19 20 -16 47 -32 60 -36z m-885 -64 c30 -54 73 -123 96 -153
23 -30 50 -78 61 -108 12 -30 39 -86 62 -125 22 -39 43 -75 46 -81 3 -5 -1 -4
-10 3 -8 7 -39 49 -68 95 -28 45 -67 105 -87 132 -19 28 -43 68 -53 90 -10 22
-42 78 -69 125 -48 80 -62 120 -42 120 5 0 33 -44 64 -98z m-8793 64 c0 -2 -8
-10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m916 -38 c-4 -18 -21
-55 -39 -83 -18 -27 -47 -79 -64 -116 -16 -36 -50 -88 -74 -116 -24 -27 -52
-65 -62 -83 -10 -18 -20 -30 -23 -27 -3 3 5 25 19 49 14 24 30 60 37 81 6 20
28 60 48 87 20 28 64 93 96 145 33 52 61 95 64 95 2 0 2 -15 -2 -32z m8333
-15 c1 -1 0 -7 -4 -13 -5 -8 -13 -6 -26 6 -22 20 -14 40 9 22 10 -7 19 -13 21
-15z m816 7 c-3 -5 -20 -19 -38 -30 l-32 -22 35 31 c35 32 46 38 35 21z
m-10410 -36 l30 -25 -32 15 c-17 9 -35 20 -38 26 -11 18 10 10 40 -16z m8674
-49 c0 -5 -4 -3 -9 5 -5 8 -9 22 -9 30 0 16 17 -16 18 -35z m-7964 4 c19 -35
10 -54 -11 -25 -13 19 -20 56 -9 56 2 0 12 -14 20 -31z m8645 -99 c0 -4 3 -15
6 -23 8 -22 -26 -30 -47 -11 -22 20 -25 50 -6 57 14 5 47 -10 47 -23z m460 15
c-7 -8 -17 -15 -22 -15 -6 0 -5 7 2 15 7 8 17 15 22 15 6 0 5 -7 -2 -15z
m-8983 -25 c21 -11 52 -20 70 -20 18 0 33 -5 33 -11 0 -9 -20 -10 -68 -6 -48
5 -74 3 -85 -6 -10 -6 -28 -43 -41 -80 -27 -78 -42 -73 -31 11 8 64 26 106 52
121 25 14 28 14 70 -9z m8791 -8 c6 -9 12 -36 12 -60 0 -53 -17 -53 -44 2
l-18 37 -63 -6 c-39 -5 -70 -3 -81 4 -16 9 -14 10 16 11 19 0 53 9 75 20 43
20 82 17 103 -8z m-8953 -1 c3 -5 1 -12 -5 -16 -13 -8 -50 3 -50 16 0 12 47
12 55 0z m9065 -6 c-13 -16 -55 -20 -64 -6 -9 14 5 21 42 21 29 0 33 -2 22
-15z m-8434 -60 c-4 -14 -13 -25 -21 -25 -21 0 -19 47 3 59 21 12 27 1 18 -34z
m-746 -73 c0 -18 -34 1 -54 30 -26 37 -18 41 19 11 19 -15 35 -34 35 -41z
m9290 23 c0 -12 -28 -35 -44 -35 -14 0 -5 30 12 39 24 14 32 13 32 -4z m-9382
-127 c3 -22 -7 -33 -59 -69 -55 -39 -135 -122 -207 -215 l-22 -29 21 45 c12
25 40 70 62 100 53 73 179 201 192 197 5 -2 11 -15 13 -29z m9543 -59 c80 -82
138 -163 126 -175 -3 -3 -26 20 -52 51 -26 30 -79 78 -117 106 -38 27 -72 57
-75 65 -5 13 12 44 24 44 3 0 45 -41 94 -91z m-151 -19 c0 -15 -19 -22 -40
-15 -22 7 -27 31 -7 38 15 5 47 -10 47 -23z m-9300 10 c0 -12 -28 -25 -36 -17
-9 9 6 27 22 27 8 0 14 -5 14 -10z m690 -114 c-12 -14 -36 -28 -52 -31 -19 -5
-37 -19 -50 -41 -24 -41 -58 -47 -58 -10 0 24 16 37 105 88 17 9 41 17 54 18
l24 0 -23 -24z m7960 -18 c71 -41 85 -64 56 -87 -12 -10 -18 -6 -32 18 -10 17
-41 41 -73 58 -30 15 -57 34 -59 41 -7 24 39 11 108 -30z m-8810 -34 c-21 -61
-230 -301 -230 -263 0 9 12 26 26 40 14 13 35 42 46 64 23 46 123 184 147 203
21 17 25 1 11 -44z m9633 -19 c26 -33 61 -88 78 -122 16 -34 43 -73 59 -87 28
-23 41 -52 21 -44 -5 2 -52 49 -105 105 -87 93 -110 127 -131 192 -13 42 26
19 78 -44z m-9498 25 c8 -14 -33 -70 -51 -70 -8 0 -14 10 -14 23 0 26 10 42
30 50 23 8 28 8 35 -3z m248 -1 c40 -21 107 -80 102 -90 -11 -17 -41 -9 -81
21 -69 52 -128 52 -188 -1 -47 -41 -62 -110 -46 -210 13 -87 19 -99 88 -171
37 -39 55 -66 55 -83 0 -33 -3 -35 -53 -46 -51 -12 -61 -6 -54 31 4 20 -9 73
-45 175 -28 80 -51 161 -51 178 0 47 81 182 117 195 38 14 130 14 156 1z
m8946 -2 c13 -6 46 -41 72 -77 27 -36 65 -82 84 -103 41 -44 40 -67 -3 -67
-24 0 -32 -6 -41 -32 -7 -18 -29 -66 -49 -107 -32 -63 -37 -83 -36 -137 l0
-64 -35 6 c-20 4 -46 14 -58 22 -21 13 -23 12 -29 -4 -16 -45 -43 -101 -59
-119 -16 -19 -16 -19 -10 10 20 102 19 120 -14 152 l-31 32 22 15 c32 23 44
20 68 -14 27 -38 35 -38 48 0 7 19 23 34 45 43 28 12 38 26 62 83 39 98 43
166 15 232 -28 64 -70 102 -113 102 -36 0 -98 -28 -140 -62 -32 -26 -56 -14
-38 19 10 21 65 66 91 76 28 10 125 7 149 -6z m146 -12 c27 -26 32 -45 13 -45
-16 0 -58 41 -58 57 0 20 18 16 45 -12z m-847 -144 c16 -20 44 -45 62 -56 54
-33 150 -127 150 -147 0 -20 -11 -22 -57 -9 -28 8 -35 6 -50 -14 -10 -13 -31
-27 -48 -31 l-30 -7 33 37 c17 20 32 45 32 55 0 19 -34 69 -107 159 -60 74
-67 91 -52 127 l12 30 13 -54 c7 -29 25 -70 42 -90z m-8048 29 c0 -5 -9 -14
-20 -20 -27 -15 -43 -83 -36 -155 l6 -60 -31 31 c-41 41 -45 109 -9 172 20 36
28 42 57 42 18 0 33 -4 33 -10z m8498 -22 c42 -70 39 -135 -9 -189 l-29 -34 6
70 c7 72 -3 110 -42 163 -16 22 -16 22 19 22 29 0 39 -6 55 -32z m-8172 -71
c-42 -37 -49 -48 -53 -89 -4 -35 -1 -55 11 -73 16 -24 16 -25 -3 -25 -11 0
-23 9 -26 21 -5 15 -14 20 -38 19 -50 -3 -57 -1 -57 14 0 23 79 109 116 127
18 9 45 26 60 38 48 38 40 11 -10 -32z m-940 -41 c-9 -23 -38 -68 -66 -101
-27 -32 -57 -80 -67 -107 -9 -26 -28 -58 -41 -71 l-25 -22 7 27 c22 95 196
378 204 334 2 -10 -3 -37 -12 -60z m9220 -12 c-3 -8 -10 -14 -16 -14 -14 0
-13 53 3 69 10 10 13 7 15 -14 2 -15 1 -33 -2 -41z m464 20 c30 -34 115 -202
146 -288 l14 -39 -21 19 c-12 10 -39 55 -59 99 -22 46 -53 95 -75 115 -41 39
-65 79 -65 110 0 31 24 24 60 -16z m-8487 -18 c7 -17 -18 -40 -33 -31 -5 3
-10 15 -10 26 0 23 35 27 43 5z m-453 -73 c-1 -10 -13 -36 -28 -57 -23 -34
-35 -41 -87 -53 -33 -8 -90 -23 -127 -34 -61 -18 -68 -23 -79 -56 -6 -21 -9
-55 -5 -84 8 -58 2 -62 -18 -14 -25 60 -30 122 -12 150 14 22 21 23 64 18 66
-7 117 15 139 63 14 28 31 43 68 60 61 28 85 30 85 7z m8238 -11 c34 -11 47
-24 64 -56 11 -22 26 -44 32 -48 6 -4 -6 -8 -26 -8 -24 0 -46 8 -65 24 -15 13
-37 25 -48 27 -11 3 -26 16 -32 31 -16 32 -7 62 16 52 9 -4 35 -14 59 -22z
m-342 -94 c-9 -12 -16 -30 -16 -39 0 -27 51 -119 87 -157 19 -19 32 -37 30
-39 -20 -19 -133 119 -143 175 -8 43 9 82 36 82 20 0 20 -1 6 -22z m1053 -55
c38 -56 39 -73 6 -73 -27 0 -65 32 -65 54 0 17 18 66 24 66 2 0 18 -21 35 -47z
m-9699 -4 c0 -33 -33 -72 -57 -67 -27 5 -25 22 7 67 34 46 50 46 50 0z m9130
31 c8 -5 11 -10 5 -10 -5 0 -17 5 -25 10 -8 5 -10 10 -5 10 6 0 17 -5 25 -10z
m-9010 -95 c0 -3 -15 -11 -34 -19 -19 -8 -42 -26 -52 -41 -15 -24 -16 -33 -5
-74 22 -82 82 -205 114 -232 l31 -26 18 21 c15 18 19 19 28 6 16 -24 11 -48
-11 -55 -17 -6 -20 -13 -15 -36 13 -65 -65 -9 -85 62 -7 24 -24 58 -37 74 -51
61 -82 145 -82 218 0 84 18 107 85 107 25 0 45 -2 45 -5z m880 -5 c0 -39 -64
-105 -96 -98 -10 2 -1 19 32 56 44 50 64 63 64 42z m8620 0 c29 -16 41 -64 29
-123 -21 -103 -30 -128 -75 -193 -25 -36 -53 -87 -62 -112 -15 -38 -22 -47
-42 -47 -14 0 -35 -6 -47 -13 -13 -7 -23 -9 -23 -4 0 11 30 38 39 35 3 -1 9 7
13 18 6 15 3 19 -12 19 -23 0 -55 44 -46 65 9 23 44 18 62 -10 9 -14 20 -25
25 -25 21 0 127 207 146 286 6 26 -38 80 -81 99 l-31 13 43 1 c24 1 52 -3 62
-9z m-635 -103 c4 -28 13 -77 20 -107 24 -102 25 -110 16 -110 -16 0 -51 127
-58 209 -7 87 11 94 22 8z m-8317 -54 c-1 -43 -7 -91 -15 -108 -13 -28 -13
-25 -8 40 3 39 7 81 9 95 2 14 4 31 5 38 0 6 3 12 5 12 3 0 4 -35 4 -77z
m-199 -28 c0 -5 -8 6 -19 25 -11 19 -19 40 -19 45 0 6 8 -6 19 -25 11 -19 19
-39 19 -45z m-521 24 c-4 -27 -23 -36 -32 -14 -8 22 13 60 26 47 5 -5 8 -20 6
-33z m9782 7 c0 -14 -4 -28 -10 -31 -6 -4 -10 7 -10 24 0 17 5 31 10 31 6 0
10 -11 10 -24z m-8806 -71 c-35 -24 -66 -124 -67 -212 0 -43 1 -87 4 -97 2
-10 -2 -21 -8 -23 -8 -3 -13 5 -13 20 0 13 -10 32 -21 43 -19 17 -21 27 -16
100 2 46 12 94 21 113 18 35 69 71 101 71 19 0 19 0 -1 -15z m7858 -12 c32
-29 40 -53 49 -134 8 -86 4 -119 -17 -119 -15 0 -20 18 -28 101 -8 74 -28 121
-68 154 l-33 27 39 -6 c21 -3 47 -13 58 -23z m-132 9 c0 -4 -4 -22 -9 -40 -11
-38 5 -95 44 -154 14 -21 25 -44 25 -52 0 -22 -69 -66 -102 -66 -28 0 -37 13
-18 25 5 3 21 26 36 50 l26 45 -26 31 c-44 52 -37 169 10 169 8 0 14 -3 14 -8z
m505 -44 c11 -20 16 -40 11 -45 -10 -10 -66 52 -66 73 0 28 34 10 55 -28z
m-8614 15 c-15 -28 -64 -70 -91 -78 -21 -7 -19 -2 19 44 25 29 51 51 62 51 16
0 18 -4 10 -17z m8800 -63 c23 -13 25 -57 3 -88 -20 -29 -26 -28 -47 7 -10 17
-26 33 -37 36 -11 4 -20 10 -20 14 0 6 48 31 78 40 2 1 12 -4 23 -9z m-8963
-13 l33 -12 -46 -39 c-45 -37 -47 -37 -66 -20 -18 16 -26 64 -12 77 10 10 54
7 91 -6z m629 -56 c-4 -18 1 -52 13 -85 10 -30 16 -57 13 -59 -8 -9 -65 11
-85 30 -27 24 -20 65 14 81 16 7 31 24 34 38 9 34 18 30 11 -5z m7996 -155
c20 -30 31 -72 23 -85 -11 -18 -76 28 -76 54 0 57 27 72 53 31z m-8948 4 c6
-10 -34 -68 -47 -69 -10 -1 -11 63 -1 72 10 11 41 8 48 -3z m633 -27 c2 -23
-3 -35 -20 -47 -38 -27 -51 -21 -45 20 11 68 59 88 65 27z m8950 13 c2 -10 -1
-23 -6 -28 -12 -12 -56 28 -46 43 10 16 48 6 52 -15z m-9088 -93 c13 -17 16
-42 15 -129 -1 -107 -1 -109 -28 -124 -23 -13 -28 -13 -41 0 -14 13 -12 24 11
93 22 63 25 84 17 115 -9 37 -9 37 -65 40 -48 2 -61 -1 -87 -23 -37 -31 -42
-31 -42 -1 0 43 35 58 124 53 65 -3 82 -8 96 -24z m8762 -33 c31 -63 34 -106
13 -176 -18 -61 -19 -75 -1 -99 21 -30 75 -22 103 14 32 40 43 39 43 -5 0 -31
-4 -37 -35 -50 -19 -7 -50 -14 -69 -14 -29 0 -38 5 -51 30 -8 16 -27 37 -41
46 -28 19 -54 63 -54 93 0 12 11 26 29 35 42 22 58 108 25 140 -16 17 -108 22
-131 8 -25 -16 -25 -61 2 -143 24 -75 25 -83 10 -98 -14 -14 -18 -14 -43 -1
-26 15 -27 19 -30 110 -2 52 0 105 3 118 8 34 58 52 135 49 l65 -2 27 -55z
m-572 -48 c0 -21 -2 -24 -10 -12 -13 20 -13 53 0 45 6 -3 10 -18 10 -33z
m-7392 -84 c18 -18 15 -51 -4 -44 -9 3 -18 6 -20 6 -2 0 -4 11 -4 25 0 27 9
32 28 13z m-932 -6 c19 -13 38 -56 26 -60 -6 -2 -27 -17 -48 -33 -20 -16 -40
-29 -44 -29 -13 0 -20 29 -20 75 0 46 -2 44 65 53 6 1 15 -2 21 -6z m7910 -3
c10 -17 -23 -46 -38 -31 -8 8 -8 15 2 27 15 18 26 19 36 4z m-7122 -9 c36 -13
59 -51 47 -81 l-9 -24 -6 25 c-3 14 -7 34 -8 45 -3 16 -11 20 -38 20 -33 0
-37 -4 -67 -60 -17 -33 -34 -81 -38 -108 -4 -26 -14 -55 -23 -63 -14 -15 -16
-14 -25 7 -6 17 -4 41 8 79 32 102 47 140 62 155 17 17 60 19 97 5z m7338 -7
c21 -24 36 -75 44 -152 6 -52 4 -63 -7 -59 -8 3 -16 22 -19 44 -6 42 -48 120
-80 149 -11 10 -20 22 -20 27 0 16 67 8 82 -9z m-129 -65 c-10 -21 -12 -21
-18 -5 -10 25 2 54 17 39 8 -8 8 -18 1 -34z m-8207 -49 c15 -31 17 -31 70 -25
54 6 61 3 45 -23 -7 -10 -23 -13 -65 -8 -71 7 -76 11 -76 53 0 42 5 43 26 3z
m324 -4 c22 -26 8 -50 -29 -50 -37 0 -58 22 -48 49 8 20 60 21 77 1z m8624 9
c23 -9 20 -51 -4 -59 -11 -3 -24 -3 -30 0 -14 8 -13 38 2 53 14 14 12 14 32 6z
m-8406 -48 c20 -19 34 -38 29 -42 -10 -10 -101 42 -105 60 -6 28 36 18 76 -18z
m8232 19 c0 -9 -12 -20 -27 -26 -16 -6 -36 -17 -45 -26 -34 -31 -41 -7 -8 30
33 39 80 51 80 22z m-8080 -91 c15 -39 12 -48 -19 -66 -21 -11 -33 -29 -42
-60 -11 -41 -8 -139 6 -183 3 -11 -3 -4 -14 17 -30 53 -42 275 -17 301 27 27
75 22 86 -9z m7925 3 c18 -29 25 -201 9 -217 -8 -8 -13 4 -17 40 -8 70 -27
103 -63 115 -28 9 -30 13 -22 35 5 13 14 30 20 37 17 21 56 15 73 -10z m-7866
-76 c8 -5 9 -11 3 -15 -5 -3 -15 1 -22 9 -14 16 -3 20 19 6z m7746 -1 c-3 -5
-11 -10 -16 -10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-8485 -97
c27 -26 31 -38 45 -137 10 -74 12 -113 5 -120 -18 -18 -32 10 -51 105 -15 71
-26 100 -46 121 -22 23 -24 30 -13 43 17 21 25 19 60 -12z m9253 16 c12 -7 24
-13 26 -15 2 -2 -12 -18 -31 -36 -34 -33 -56 -92 -73 -202 -4 -26 -10 -35 -17
-28 -7 7 -7 42 1 111 6 56 11 108 11 115 0 17 38 66 51 66 6 0 20 -5 32 -11z
m-9353 -45 c0 -29 -120 -127 -136 -111 -11 11 32 61 75 89 44 28 61 34 61 22z
m1123 -35 c9 -5 17 -14 17 -19 0 -12 -3 -12 -35 0 -18 7 -33 7 -45 0 -31 -16
-70 -93 -77 -149 -7 -58 -27 -68 -44 -23 -21 53 11 160 60 200 17 14 90 8 124
-9z m7317 -41 c31 -66 37 -109 20 -152 -17 -45 -40 -33 -40 22 -1 51 -9 69
-57 127 -35 42 -42 41 -100 -1 -20 -14 -23 -14 -23 -1 0 21 23 37 77 52 86 24
91 22 123 -47z m-7804 -215 c38 -41 35 -46 -33 -58 l-43 -7 0 39 c0 37 14 63
33 63 5 0 25 -17 43 -37z m8007 8 c16 -16 42 -35 59 -42 28 -12 29 -13 12 -26
-29 -21 -63 -15 -95 16 -30 30 -40 81 -16 81 6 0 25 -14 40 -29z m-7823 12
c-1 -5 -13 -26 -28 -48 -30 -43 -70 -57 -91 -31 -10 12 -8 17 16 29 15 8 37
24 47 36 18 20 56 29 56 14z m8014 -9 c11 -27 6 -64 -8 -70 -7 -3 -27 1 -44 8
l-32 13 21 33 c22 34 53 42 63 16z m-7786 -37 c2 -19 -1 -27 -13 -27 -19 0
-30 24 -21 46 9 23 31 11 34 -19z m7022 8 c0 -29 38 -55 81 -55 32 0 47 6 71
30 16 17 31 30 34 30 8 0 4 -72 -6 -91 -14 -25 -41 -24 -74 2 -22 17 -35 20
-74 15 -94 -12 -96 -12 -100 7 -2 11 8 32 24 48 31 32 44 36 44 14z m320 -10
c0 -8 -7 -15 -15 -15 -8 0 -15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z
m-7217 -9 c3 -8 22 -20 41 -27 41 -15 41 -27 3 -49 -24 -13 -28 -13 -42 1 -19
18 -32 89 -17 89 5 0 12 -6 15 -14z m-772 -6 c36 -20 65 -109 41 -124 -10 -6
-112 107 -112 123 0 14 45 14 71 1z m984 -10 c27 -30 8 -42 -53 -34 l-51 6 32
23 c40 29 50 30 72 5z m7675 1 c0 -10 -7 -24 -15 -31 -8 -7 -25 -30 -37 -51
-31 -56 -52 -52 -45 8 4 35 12 53 32 70 32 28 65 30 65 4z m-8182 -22 c2 -9
-11 -22 -33 -32 -45 -21 -45 -21 -45 2 0 39 71 66 78 30z m7696 15 c16 -6 22
-35 10 -47 -9 -9 -54 14 -54 28 0 23 16 30 44 19z m650 -18 c-8 -21 -34 -28
-51 -15 -11 10 -11 14 -1 20 22 15 58 11 52 -5z m-8249 -6 c-3 -5 -11 -10 -16
-10 -6 0 -7 5 -4 10 3 6 11 10 16 10 6 0 7 -4 4 -10z m-66 -185 c1 -5 -22 13
-49 42 -28 28 -50 57 -50 65 1 15 99 -91 99 -107z m620 109 c18 -4 38 -16 46
-28 19 -30 29 -86 16 -86 -6 0 -11 6 -11 13 0 25 -40 57 -70 57 -23 0 -33 -7
-45 -30 -20 -38 -35 -30 -35 19 0 58 22 70 99 55z m6439 -10 c23 -16 31 -67
11 -79 -5 -4 -28 5 -49 20 -44 31 -67 25 -97 -25 -20 -34 -33 -39 -33 -11 0
82 104 140 168 95z m-7958 -50 c-20 -23 -50 -18 -50 10 0 32 53 54 58 24 2
-11 -2 -26 -8 -34z m1103 -26 c3 -46 9 -92 12 -103 6 -21 -10 -54 -20 -43 -11
11 -27 161 -20 196 10 56 22 35 28 -50z m8326 63 c14 -9 10 -43 -9 -71 -9 -13
-12 -12 -25 4 -21 28 -20 76 3 76 9 0 23 -4 31 -9z m-1114 -11 c10 -17 -7
-159 -22 -177 -12 -16 -13 -13 -7 27 4 25 6 71 6 103 -1 53 8 71 23 47z m235
-25 c-7 -9 -27 -25 -44 -37 -28 -19 -27 -17 8 20 22 23 42 40 44 37 3 -2 -1
-12 -8 -20z m-7863 -151 c37 -18 83 -71 83 -97 0 -24 -6 -22 -53 18 -22 19
-56 38 -75 41 -29 6 -38 2 -58 -20 -27 -28 -70 -34 -105 -15 -30 16 -13 29 39
29 53 0 64 5 81 38 14 26 40 28 88 6z m7719 -39 c1 -33 8 -65 19 -81 18 -28
15 -40 -10 -31 -18 7 -53 61 -62 96 -5 19 0 30 21 48 15 13 28 23 29 23 1 0 3
-25 3 -55z m382 32 c4 -17 -2 -21 -49 -31 -15 -3 -46 -22 -69 -41 -23 -19 -46
-35 -51 -35 -32 0 21 78 76 112 37 23 88 20 93 -5z m-7768 -38 c0 -51 -47
-125 -68 -105 -2 3 6 25 18 50 13 27 19 54 15 66 -3 11 -3 25 0 30 11 18 35
-10 35 -41z m6786 37 c8 -21 -23 -75 -50 -87 -17 -8 -29 -8 -45 0 -26 14 -27
28 -3 35 10 4 31 20 47 36 32 33 43 37 51 16z m-6109 -71 c-2 -5 -19 -11 -38
-13 -20 -2 -41 -12 -50 -24 -11 -14 -22 -19 -34 -15 -14 6 -16 15 -10 50 11
64 17 67 80 38 30 -14 53 -30 52 -36z m-161 31 c3 -8 -8 -35 -25 -59 -25 -37
-38 -46 -83 -58 -49 -13 -53 -17 -63 -54 l-12 -39 -12 49 c-7 28 -21 65 -32
83 -10 18 -19 44 -19 58 0 41 24 29 59 -28 15 -25 30 -38 44 -38 33 0 84 35
102 70 17 32 32 38 41 16z m6373 -36 c30 -32 45 -40 74 -40 29 0 41 7 71 43
28 34 37 40 48 29 10 -10 8 -22 -14 -65 -15 -29 -33 -72 -39 -97 -19 -78 -53
-99 -43 -27 8 54 -8 72 -69 81 -33 5 -52 15 -71 38 -25 31 -33 58 -19 71 12
13 24 7 62 -33z m1021 30 c0 -27 -95 -41 -118 -18 -22 22 -12 28 53 28 36 0
65 -4 65 -10z m150 -1 c0 -26 -45 -82 -101 -124 -70 -53 -90 -60 -32 -10 23
20 52 52 64 73 38 64 69 91 69 61z m-8707 -54 c43 -63 45 -70 15 -54 -40 20
-78 62 -78 86 0 37 22 26 63 -32z m519 -37 c3 -7 0 -28 -6 -47 l-12 -33 -27
41 c-27 40 -33 62 -21 74 9 8 60 -19 66 -35z m7678 22 c0 -17 -50 -110 -59
-110 -11 0 -24 78 -15 91 13 22 74 37 74 19z m-7879 -206 c25 -18 52 -41 59
-50 12 -13 10 -14 -16 -8 -16 4 -46 13 -68 21 -55 19 -76 16 -119 -12 -43 -30
-57 -26 -57 16 0 26 6 33 53 55 62 29 83 26 148 -22z m8120 34 c55 -18 90 -47
87 -71 -4 -30 -32 -33 -67 -8 -36 26 -62 26 -142 1 -60 -19 -91 -35 -152 -75
-16 -10 -27 -13 -27 -6 0 6 22 22 48 35 27 13 71 42 98 64 47 37 101 71 115
72 3 0 21 -5 40 -12z m-7373 -51 c47 -48 51 -57 23 -57 -26 0 -81 53 -81 79 0
30 11 26 58 -22z m376 12 c18 -27 11 -47 -20 -55 -42 -11 -72 34 -42 64 18 18
45 14 62 -9z m5830 -1 c23 -37 -35 -76 -70 -48 -18 16 -18 35 2 55 20 20 53
16 68 -7z m346 5 c0 -12 -35 -64 -69 -103 -29 -33 -30 -34 -31 -11 0 13 18 45
41 72 38 45 59 60 59 42z m-6469 -36 c-13 -13 -26 -3 -16 12 3 6 11 8 17 5 6
-4 6 -10 -1 -17z m5947 5 c2 -7 -6 -12 -17 -12 -21 0 -27 11 -14 24 9 9 26 2
31 -12z m-6648 -52 c21 -11 41 -24 45 -30 12 -19 -16 -10 -57 20 -48 35 -44
38 12 10z m566 -84 c6 -15 -22 -76 -34 -76 -15 0 -32 24 -32 45 0 39 53 64 66
31z m6678 -2 c9 -8 16 -18 16 -21 0 -11 -42 -73 -49 -73 -5 0 -16 18 -26 40
-15 32 -15 42 -5 55 16 19 44 19 64 -1z m-6858 -28 c39 -18 58 -35 82 -75 18
-28 28 -51 24 -51 -6 0 -105 49 -195 97 -38 20 -74 9 -97 -30 -30 -52 -39 -58
-68 -51 -33 9 -42 20 -42 60 0 57 32 72 155 73 75 1 99 -3 141 -23z m7214 11
c33 -16 51 -81 29 -103 -9 -8 -28 -16 -44 -17 -26 -2 -31 3 -43 38 -24 73 -42
72 -184 -5 -61 -33 -113 -60 -115 -60 -1 0 14 25 35 56 51 77 106 104 217 104
44 0 91 -6 105 -13z m-7372 -199 c21 -21 13 -45 -23 -77 -35 -30 -38 -31 -138
-31 -108 0 -211 -19 -252 -46 -21 -14 -25 -14 -25 -2 0 8 23 33 50 55 44 36
60 42 113 48 34 3 97 5 141 3 81 -3 86 -1 100 45 6 20 17 22 34 5z m7325 -42
c2 -7 51 -11 145 -12 l142 -1 50 -34 c44 -30 67 -56 58 -65 -2 -1 -30 7 -63
18 -45 16 -93 22 -190 26 -123 4 -131 5 -157 30 -27 25 -36 60 -21 76 7 7 29
-16 36 -38z m-6154 15 c28 -18 -1 -31 -67 -30 -77 1 -114 9 -120 27 -4 14 164
17 187 3z m5129 -3 c-6 -17 -43 -26 -115 -27 -60 -1 -81 8 -68 29 9 15 188 13
183 -2z m-5345 -88 c36 -9 45 -16 37 -24 -13 -13 -290 -10 -303 3 -5 5 9 17
31 28 32 16 51 18 113 12 41 -3 96 -12 122 -19z m5624 6 c15 -13 25 -27 22
-30 -11 -11 -288 -11 -299 0 -17 17 108 47 217 52 21 1 41 -6 60 -22z m-5283
-16 c14 -5 26 -14 26 -20 0 -13 -70 -13 -90 0 -13 9 -13 11 0 20 19 12 32 12
64 0z m4766 -9 c0 -10 -34 -21 -67 -21 -23 0 -30 10 -16 23 10 10 83 8 83 -2z
m-5252 -60 c11 -6 7 -13 -13 -29 -29 -23 -51 -28 -160 -37 -188 -14 -225 -16
-225 -7 0 13 113 42 167 42 27 0 82 9 123 19 89 23 91 23 108 12z m382 -15
c97 -12 124 -21 115 -36 -6 -10 -322 -7 -332 4 -4 4 5 19 21 33 24 21 36 24
65 18 20 -3 79 -12 131 -19z m5097 3 c24 -11 43 -25 43 -30 0 -5 -24 -10 -52
-10 -196 -6 -298 -5 -298 2 0 16 37 27 120 34 47 4 94 11 105 16 30 12 35 11
82 -12z m313 -4 c23 -8 70 -15 104 -15 57 0 156 -25 174 -45 7 -8 -130 -1
-275 14 -78 8 -133 29 -133 51 0 15 82 12 130 -5z m-148 -61 c20 -6 30 -31 23
-54 -5 -16 -20 -17 -158 -14 -84 3 -158 7 -166 9 -27 10 8 32 72 45 34 7 69
17 77 22 14 8 114 3 152 -8z m-5408 -8 c53 -13 97 -38 85 -50 -3 -3 -75 -7
-160 -8 l-154 -3 -3 24 c-3 15 3 30 14 38 24 17 142 17 218 -1z m440 -25 c34
-11 82 -23 107 -26 26 -3 55 -12 65 -20 17 -13 14 -14 -37 -15 -31 0 -78 7
-105 15 -27 8 -62 15 -79 15 -44 0 -62 14 -46 34 17 20 22 20 95 -3z m4401 -6
c0 -16 -9 -21 -50 -26 -27 -4 -63 -12 -78 -18 -37 -14 -145 -15 -131 -1 6 6
42 17 81 25 38 8 85 21 104 30 45 19 74 16 74 -10z m886 -24 c36 -28 131 -38
161 -17 26 19 56 20 95 5 l28 -11 -40 -19 c-46 -21 -148 -25 -192 -8 -15 6
-44 13 -63 16 -45 5 -64 21 -49 39 16 19 32 18 60 -5z m-6198 -6 c3 -17 -103
-47 -184 -52 -62 -5 -82 -2 -115 15 l-39 20 28 7 c16 4 47 4 70 1 109 -18 131
-18 162 3 31 21 74 24 78 6z m1423 -1 c18 -14 18 -15 -5 -4 -20 10 -27 8 -57
-21 -47 -45 -50 -68 -24 -140 13 -33 20 -62 16 -66 -11 -11 -43 26 -76 89
l-32 60 19 27 c23 31 89 71 119 71 11 0 29 -7 40 -16z m3420 -27 l47 -43 -27
-60 c-30 -62 -71 -114 -92 -114 -8 0 0 26 22 70 33 66 34 70 20 99 -9 16 -28
39 -42 50 l-27 21 -41 -24 c-25 -14 -52 -43 -70 -72 -44 -75 -89 -127 -135
-152 -68 -38 -50 -6 66 117 105 111 159 150 210 151 11 0 42 -19 69 -43z
m-4540 -12 c16 -12 17 -16 5 -24 -18 -12 -142 -21 -211 -16 -30 2 -59 4 -65 5
-5 0 -1 8 8 18 29 28 230 42 263 17z m1297 -115 c53 -56 95 -106 92 -111 -20
-31 -157 95 -211 194 l-26 49 23 -16 c14 -8 68 -61 122 -116z m4490 108 c9 -5
17 -13 17 -18 0 -17 -51 -23 -149 -16 -106 7 -141 14 -141 27 0 21 50 28 150
23 58 -4 113 -11 123 -16z m-5215 -33 c13 -13 -124 -9 -183 6 -5 1 19 5 55 8
60 6 115 0 128 -14z m4517 5 c-29 -12 -134 -12 -115 0 8 5 42 9 75 9 43 0 54
-3 40 -9z m-5767 -31 c30 -11 28 -29 -3 -29 -33 0 -95 26 -95 40 0 11 53 5 98
-11z m6977 1 c-9 -14 -85 -32 -104 -24 -13 5 -10 9 14 20 35 15 99 19 90 4z
m-2225 -45 c11 -13 8 -17 -17 -26 -29 -10 -37 -20 -62 -83 -15 -37 -25 -27
-17 16 5 26 66 108 80 108 2 0 9 -7 16 -15z m1873 -7 c10 -5 15 -13 12 -18 -5
-8 -295 -3 -304 5 -12 13 20 21 112 26 106 6 150 3 180 -13z m-6078 1 c4 -6 5
-12 4 -14 -2 -2 -73 -7 -156 -11 -108 -5 -153 -4 -153 4 0 6 8 16 18 21 23 14
279 14 287 0z m1635 -4 c7 -9 10 -18 7 -21 -11 -11 -57 6 -57 21 0 20 34 19
50 0z m3198 -92 c2 -8 -5 -13 -17 -13 -21 0 -35 13 -24 24 10 10 36 3 41 -11z
m-3128 -50 c0 -14 -6 -23 -15 -23 -17 0 -30 42 -20 67 6 17 7 17 21 -1 8 -11
14 -30 14 -43z m4470 47 c0 -5 -4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10
10 6 0 10 -4 10 -10z m-5427 -35 c50 -26 101 -65 94 -72 -2 -1 -36 8 -75 22
-76 26 -140 32 -173 15 -26 -14 -25 -64 2 -98 19 -25 19 -27 4 -30 -28 -6 -67
20 -81 55 -21 50 1 90 71 128 39 21 91 14 158 -20z m3812 5 c-26 -50 -19 -84
26 -136 45 -52 46 -62 4 -137 -35 -63 -101 -103 -183 -111 -62 -7 -63 -7 -60
16 3 21 9 23 75 28 65 5 75 8 107 39 66 64 68 118 7 175 -34 32 -36 43 -21 97
8 29 30 52 58 58 1 1 -4 -12 -13 -29z m652 17 c88 -49 83 -177 -8 -185 l-34
-4 26 29 c39 41 44 68 20 92 -36 36 -110 28 -246 -24 -35 -14 25 44 70 67 75
39 132 47 172 25z m-3819 -74 c19 -24 32 -43 29 -43 -4 0 -29 20 -57 45 -28
25 -55 45 -60 45 -6 0 -10 7 -10 16 0 24 54 -11 98 -63z m1132 58 c0 -11 -5
-32 -11 -48 -10 -27 -11 -27 -25 -9 -18 25 -18 41 2 60 21 21 34 20 34 -3z
m245 13 c34 -14 50 -65 49 -166 0 -102 -10 -128 -47 -128 -20 0 -20 0 -1 21
16 18 19 34 16 112 -3 103 -12 124 -53 139 -16 5 -26 14 -23 19 6 10 35 12 59
3z m-1500 -12 c6 -4 11 -16 13 -27 2 -11 6 -32 9 -47 4 -21 -2 -32 -28 -55
-19 -15 -46 -41 -61 -57 -26 -27 -28 -27 -28 -7 0 12 20 44 45 70 25 26 45 54
45 60 0 7 -9 26 -21 42 -19 27 -19 29 -2 29 10 0 23 -4 28 -8z m1775 -11 c5
-11 10 -29 10 -40 0 -19 -1 -19 -25 4 -13 14 -33 25 -44 25 -21 0 -35 13 -24
24 15 15 73 6 83 -13z m320 7 c0 -13 -36 -78 -43 -78 -3 0 -2 11 0 24 3 13 0
33 -6 45 -11 19 -9 21 19 21 17 0 30 -5 30 -12z m-1539 -47 c-62 -44 -72 -57
-78 -97 -4 -31 1 -52 22 -93 43 -85 5 -83 -48 2 -25 38 -28 52 -22 86 6 34 16
48 63 84 32 24 63 42 71 39 11 -4 9 -9 -8 -21z m2629 20 c0 -5 -20 -25 -45
-45 -54 -45 -58 -40 -9 14 35 39 54 50 54 31z m-424 -32 c62 -36 77 -61 68
-119 -11 -71 -79 -149 -106 -122 -11 11 6 45 44 86 31 33 24 83 -18 122 -19
19 -40 34 -45 34 -5 0 -9 7 -9 15 0 20 2 20 66 -16z m-4157 -26 c-13 -15 -28
-16 -48 -4 -12 8 -12 12 -2 25 10 12 18 13 37 4 18 -9 21 -14 13 -25z m1066
-2 c-7 -32 -31 -41 -52 -20 -9 9 -4 17 20 34 18 12 34 21 35 19 2 -1 1 -16 -3
-33z m3987 4 c19 5 18 3 -7 -14 -44 -31 -65 -28 -65 9 0 30 0 30 25 14 15 -10
33 -13 47 -9z m1033 4 c0 -8 -6 -14 -12 -13 -7 1 -25 2 -40 3 l-28 2 30 14
c35 16 50 14 50 -6z m-2944 -102 c-45 -43 -50 -61 -24 -90 17 -18 17 -19 -7
-16 -20 3 -26 11 -28 36 -3 25 6 41 42 82 25 29 47 57 47 64 1 7 4 1 6 -12 4
-18 -5 -34 -36 -64z m-1032 2 c5 -16 8 -29 6 -29 -8 0 -65 82 -65 93 0 19 49
-35 59 -64z m1881 54 c0 -12 -35 -66 -60 -91 -45 -47 -33 -1 17 66 22 30 43
42 43 25z m-3725 -33 c-3 -5 -13 -10 -21 -10 -8 0 -14 5 -14 10 0 6 9 10 21
10 11 0 17 -4 14 -10z m5545 0 c0 -5 -4 -10 -9 -10 -6 0 -13 5 -16 10 -3 6 1
10 9 10 9 0 16 -4 16 -10z m-2098 -45 c4 -5 19 -30 34 -55 l26 -45 -56 58
c-31 32 -54 63 -50 68 6 10 30 -4 46 -26z m-1332 17 c0 -4 -16 -23 -35 -42
-39 -38 -45 -31 -15 18 18 29 50 44 50 24z m-1609 -58 c28 -14 66 -40 84 -57
18 -18 49 -47 69 -65 34 -31 47 -62 27 -62 -16 0 -71 51 -71 65 0 21 -89 94
-131 106 -32 10 -42 9 -63 -5 -25 -16 -29 -25 -41 -98 -4 -28 -13 -42 -33 -52
-48 -24 -56 -8 -17 35 29 32 35 47 35 83 0 24 3 51 6 60 10 25 77 20 135 -10z
m4627 -7 l3 -28 -30 15 c-16 9 -41 16 -55 16 -29 0 -142 -83 -151 -111 -4 -10
-18 -33 -32 -49 l-25 -31 36 -36 c35 -35 47 -75 26 -88 -5 -3 -26 3 -47 14
-31 15 -47 18 -83 11 -90 -18 -94 -6 -16 44 38 25 57 44 65 68 15 44 134 162
191 188 25 11 61 19 80 18 30 -3 35 -7 38 -31z m-4011 -41 c39 -37 48 -40 108
-44 l66 -4 24 -53 c28 -58 30 -76 13 -93 -20 -20 -23 -14 -20 31 5 61 -15 80
-75 71 -36 -5 -48 -3 -66 13 -12 11 -39 30 -60 42 -35 21 -67 65 -67 92 0 14
17 2 77 -55z m2463 -21 c-7 -8 -17 -15 -23 -15 -6 0 -2 7 9 15 25 19 30 19 14
0z m-1830 -61 c0 -30 -17 -64 -31 -64 -10 0 -7 32 7 83 7 24 24 11 24 -19z
m530 -13 c-1 -13 -3 -13 -15 3 -19 26 -19 42 0 26 8 -7 15 -20 15 -29z m2900
9 c42 -36 31 -57 -17 -33 -24 11 -33 22 -33 39 0 31 7 30 50 -6z m-1396 -16
c3 -9 6 -23 6 -31 0 -13 -3 -13 -15 -3 -16 13 -21 50 -6 50 5 0 12 -7 15 -16z
m-881 -23 c14 -14 -1 -18 -79 -24 -77 -6 -97 -17 -179 -96 -105 -103 -143
-202 -131 -350 5 -74 5 -75 -10 -43 -9 22 -16 70 -17 135 -2 101 -2 103 35
168 21 37 58 91 82 120 l45 54 -22 17 -22 17 24 9 c33 12 260 7 274 -7z
m-2818 -1 c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16
-10z m915 -5 c0 -16 -27 -32 -37 -22 -3 4 -3 13 0 22 8 20 37 20 37 0z m3190
-44 c0 -11 -3 -11 -14 -1 -8 6 -12 18 -9 27 5 12 7 12 14 1 5 -7 9 -19 9 -27z
m350 29 c0 -5 -6 -10 -14 -10 -8 0 -18 5 -21 10 -3 6 3 10 14 10 12 0 21 -4
21 -10z m-4555 -14 c16 -12 18 -19 10 -43 -15 -41 -37 -35 -51 15 -12 38 -11
42 5 42 9 0 26 -6 36 -14z m1659 -36 c-5 -17 -13 -32 -19 -33 -16 -3 -19 65
-3 74 21 13 32 -7 22 -41z m2260 17 c8 -22 6 -31 -8 -45 -16 -16 -18 -16 -32
2 -8 11 -14 33 -14 49 0 24 4 28 22 25 14 -2 26 -14 32 -31z m-3183 1 c16 -13
42 -33 57 -45 l27 -23 -67 2 c-57 2 -71 -1 -91 -20 -30 -28 -43 -28 -51 2 -8
33 9 68 42 89 36 22 48 21 83 -5z m4849 17 c0 -11 -29 -75 -34 -75 -3 0 -10 9
-16 20 -16 30 -3 60 26 60 13 0 24 -2 24 -5z m-5316 -41 c25 -14 46 -32 46
-40 0 -25 -29 -15 -70 26 -47 47 -41 50 24 14z m5031 -9 c-48 -41 -97 -62 -88
-38 7 19 91 73 112 73 10 0 0 -14 -24 -35z m-1037 -28 c28 -26 28 -54 0 -84
-28 -30 -53 -32 -37 -2 8 15 8 33 0 64 -6 24 -11 47 -11 50 0 8 20 -4 48 -28z
m-2953 -42 c0 -36 -4 -65 -8 -65 -14 0 -57 49 -57 65 0 17 43 65 58 65 4 0 8
-29 7 -65z m1545 -4 c0 -5 -21 -27 -47 -47 -27 -21 -63 -51 -80 -68 -35 -32
-42 -23 -19 22 30 57 146 131 146 93z m-1940 -16 c0 -8 -7 -15 -15 -15 -8 0
-15 7 -15 15 0 8 7 15 15 15 8 0 15 -7 15 -15z m3730 -5 c0 -5 -4 -10 -10 -10
-5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m677 -58 c18 -24 33 -47
33 -52 0 -5 -22 2 -49 17 -32 16 -82 29 -138 37 l-88 12 40 16 c25 10 64 16
105 15 63 -2 65 -3 97 -45z m-346 -182 c28 -15 23 -37 -11 -55 -39 -20 -40
-20 -40 19 0 29 7 40 28 45 2 1 12 -4 23 -9z m-4371 -35 c0 -40 -8 -43 -41
-17 -28 24 -20 52 16 52 22 0 25 -4 25 -35z m1819 -29 c-13 -29 -32 -36 -22
-8 8 22 25 44 30 39 2 -3 -2 -16 -8 -31z m-1407 -100 c20 -24 24 -43 6 -29 -7
6 -25 13 -40 17 -26 6 -36 24 -22 39 9 9 37 -4 56 -27z m3558 14 c0 -13 -60
-43 -68 -35 -9 8 36 45 54 45 8 0 14 -4 14 -10z m-2671 -78 c-92 -100 -137
-145 -154 -154 -11 -6 14 25 55 69 41 43 83 89 93 102 10 13 21 20 24 17 4 -3
-5 -19 -18 -34z m1815 -77 c48 -52 85 -95 83 -95 -11 0 -177 168 -177 179 0 6
2 11 3 11 2 0 43 -43 91 -95z m-230 -61 c25 -35 44 -64 42 -64 -27 0 -60 28
-85 72 -17 29 -31 56 -31 60 0 20 32 -10 74 -68z m-1398 8 c-17 -34 -44 -58
-53 -48 -7 7 19 58 46 90 14 15 16 16 19 3 2 -9 -4 -29 -12 -45z"/>
<path d="M6200 9120 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M5415 9110 c3 -5 1 -10 -4 -10 -6 0 -11 -4 -11 -10 0 -5 7 -7 15 -4
17 7 21 34 4 34 -5 0 -7 -4 -4 -10z"/>
<path d="M5441 9087 c-1 -32 1 -36 13 -25 8 7 10 15 6 18 -4 3 -10 14 -13 25
-3 12 -5 4 -6 -18z"/>
<path d="M5848 9113 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5360 9080 c-1 -14 -4 -19 -7 -12 -6 15 -33 16 -33 1 0 -5 4 -8 9 -5
5 3 13 0 19 -7 9 -11 29 -13 75 -8 4 1 7 8 7 16 0 13 -4 14 -22 4 -29 -16 -32
-15 -40 13 -6 23 -6 23 -8 -2z"/>
<path d="M5480 9080 c0 -6 7 -10 15 -10 8 0 15 2 15 4 0 2 -7 6 -15 10 -8 3
-15 1 -15 -4z"/>
<path d="M5240 9076 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M5140 9049 c0 -5 -4 -7 -10 -4 -6 3 -10 -4 -9 -17 0 -13 3 -17 6 -10
4 9 10 7 23 -8 10 -12 24 -20 32 -19 7 1 25 -1 41 -5 15 -5 48 -5 73 -1 39 5
44 9 39 26 -4 12 -2 19 6 19 29 0 -3 12 -38 15 -21 1 -45 4 -53 6 -8 2 -14 -2
-12 -8 3 -17 -3 -16 -22 3 -12 11 -16 12 -16 2 0 -7 -11 -13 -25 -13 -29 0
-33 11 -7 18 13 4 11 5 -5 6 -13 0 -23 -4 -23 -10z m180 -29 c0 -17 -39 -32
-58 -21 -9 6 -12 11 -7 11 25 3 47 9 53 14 8 9 12 7 12 -4z"/>
<path d="M5070 8955 c-11 -13 -10 -14 4 -9 9 3 16 10 16 15 0 13 -6 11 -20 -6z"/>
<path d="M4928 8953 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4860 8880 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M6005 8879 c-8 -23 -24 -287 -18 -309 4 -16 13 -20 49 -20 39 0 44 3
50 28 13 59 15 62 42 62 38 0 50 -10 65 -55 12 -35 19 -41 50 -46 46 -7 54 -2
62 41 5 28 2 41 -16 64 -15 19 -19 33 -13 41 38 51 46 69 47 99 2 71 -47 96
-199 103 -92 5 -114 3 -119 -8z m189 -85 c33 -32 13 -74 -35 -74 -46 0 -59 10
-59 44 0 18 3 36 7 39 12 13 72 7 87 -9z"/>
<path d="M5645 8858 c-31 -28 -69 -148 -80 -256 l-7 -72 39 0 c31 0 46 7 68
30 35 37 93 41 132 10 19 -14 41 -20 80 -20 66 0 67 -12 -26 187 l-66 143 -57
0 c-45 0 -62 -5 -83 -22z m105 -120 c11 -18 20 -38 20 -45 0 -18 -33 -33 -58
-26 -17 4 -22 13 -22 38 0 71 27 86 60 33z"/>
<path d="M4727 8836 c-4 -10 -1 -13 8 -9 8 3 12 9 9 14 -7 12 -11 11 -17 -5z"/>
<path d="M6516 8843 c-4 -4 -15 -26 -25 -48 -10 -23 -32 -61 -49 -84 -53 -73
-89 -191 -59 -191 6 0 22 -3 33 -6 15 -4 32 4 57 25 34 30 36 31 85 20 42 -8
54 -16 71 -45 18 -30 25 -34 62 -34 l42 0 -6 43 c-6 43 -52 267 -62 298 -8 24
-129 42 -149 22z m88 -135 c3 -13 6 -38 6 -55 0 -31 -2 -33 -34 -33 -44 0 -62
20 -45 52 30 58 62 74 73 36z"/>
<path d="M4540 8830 c0 -6 5 -10 10 -10 6 0 10 -5 10 -11 0 -6 -9 -9 -20 -6
-11 3 -20 1 -20 -4 0 -5 17 -9 38 -8 20 0 31 3 25 6 -7 2 -13 11 -13 18 0 7
-7 15 -15 19 -8 3 -15 1 -15 -4z"/>
<path d="M5250 8810 c-52 -16 -107 -31 -122 -34 -38 -9 -37 -41 7 -185 41
-136 51 -150 98 -133 19 7 68 16 111 22 91 11 114 22 122 56 9 37 -2 103 -21
124 -13 14 -16 28 -11 54 9 45 -14 90 -54 111 -16 8 -30 15 -32 14 -2 0 -46
-13 -98 -29z m80 -99 c0 -24 -5 -27 -51 -36 -31 -6 -37 -4 -41 13 -8 28 10 42
54 42 31 0 38 -4 38 -19z m20 -127 c0 -40 -83 -62 -93 -25 -7 27 3 38 43 49
43 11 50 8 50 -24z"/>
<path d="M4685 8820 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M4640 8800 c0 -5 -6 -10 -13 -10 -18 0 -75 -28 -83 -41 -8 -14 97 28
115 46 14 13 13 15 -2 15 -9 0 -17 -4 -17 -10z"/>
<path d="M4388 8773 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6842 8758 c-22 -22 -13 -47 23 -62 41 -18 44 -34 15 -105 -23 -59
-34 -141 -22 -159 5 -8 28 -16 50 -19 48 -7 55 3 66 100 3 32 13 74 21 94 15
35 16 35 59 29 38 -6 46 -4 55 13 28 51 1 81 -74 81 -18 0 -62 9 -97 20 -74
23 -80 24 -96 8z"/>
<path d="M4891 8714 c-19 -13 -22 -22 -16 -48 19 -97 20 -94 -19 -113 -46 -22
-66 -11 -84 43 -18 54 -22 56 -70 37 -54 -21 -55 -43 -3 -137 21 -39 46 -96
56 -126 10 -30 21 -59 25 -64 13 -13 78 13 85 34 3 11 0 41 -8 68 -8 27 -12
54 -9 59 11 16 80 25 101 14 11 -6 25 -28 31 -48 7 -21 16 -41 19 -46 10 -10
80 20 87 36 2 7 -16 57 -41 110 -26 53 -49 114 -52 134 -3 21 -10 44 -16 51
-13 17 -58 15 -86 -4z"/>
<path d="M4280 8620 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M4459 8602 c-13 -11 -20 -25 -16 -34 5 -13 12 -14 46 -5 33 9 41 16
41 34 0 29 -37 31 -71 5z"/>
<path d="M4205 8580 c-3 -5 -2 -10 4 -10 5 0 13 5 16 10 3 6 2 10 -4 10 -5 0
-13 -4 -16 -10z"/>
<path d="M7400 8551 c-17 -33 -12 -61 14 -71 13 -5 27 -14 31 -20 12 -19 -6
-67 -51 -135 -24 -37 -44 -74 -44 -82 0 -7 16 -21 35 -30 44 -21 52 -14 111
88 58 100 67 107 104 86 51 -30 70 -19 70 43 0 32 -23 50 -61 50 -12 0 -59 20
-105 45 -45 25 -85 45 -88 45 -3 0 -10 -9 -16 -19z"/>
<path d="M4503 8532 c-39 -19 -40 -25 -9 -78 13 -22 35 -72 50 -111 34 -88 65
-121 104 -107 15 5 28 17 30 26 8 43 -108 291 -135 287 -5 -1 -23 -9 -40 -17z"/>
<path d="M4314 8436 c-27 -19 -71 -47 -99 -62 -64 -34 -85 -53 -85 -76 0 -27
43 -44 75 -30 43 20 53 14 84 -50 16 -35 46 -78 65 -97 32 -31 38 -33 61 -23
14 7 28 19 32 28 6 16 -49 129 -91 188 -20 28 -20 29 -2 40 53 32 58 40 49 76
-5 19 -16 36 -24 38 -9 1 -38 -13 -65 -32z"/>
<path d="M8105 8399 c-11 -4 -14 -8 -7 -8 24 -2 42 -27 29 -40 -8 -8 -5 -18
16 -38 15 -15 27 -22 27 -15 0 6 7 12 15 12 8 0 15 -4 15 -10 0 -5 5 -10 10
-10 12 0 50 -36 50 -47 0 -14 47 -63 54 -56 3 3 6 -2 6 -13 0 -14 3 -16 9 -6
6 10 15 7 39 -13 29 -24 46 -55 22 -40 -5 3 -10 2 -10 -4 0 -16 64 -65 68 -52
5 16 42 -16 38 -32 -2 -7 -1 -8 2 -4 5 11 72 -55 72 -71 0 -7 5 -10 12 -6 7 4
8 3 4 -4 -10 -17 -24 -15 -29 6 -4 14 -5 14 -6 1 0 -9 6 -22 14 -29 10 -9 15
-9 15 -1 0 6 7 11 15 11 9 0 15 -9 15 -22 0 -19 -2 -20 -10 -8 -5 8 -10 11
-10 5 0 -15 49 -72 50 -58 0 6 -4 15 -10 18 -5 3 -10 11 -10 16 0 5 10 1 22
-11 12 -11 28 -20 35 -20 7 0 13 -4 13 -10 0 -5 7 -10 15 -10 25 0 17 35 -15
68 -16 18 -30 34 -30 36 0 7 -170 173 -173 170 -2 -2 -10 7 -17 21 -7 13 -21
27 -32 31 -10 3 -18 10 -18 15 0 6 -4 7 -10 4 -5 -3 -10 -1 -10 5 0 19 -124
132 -134 123 -3 -4 -6 0 -6 7 0 7 -3 11 -6 7 -3 -3 -17 4 -30 16 -44 41 -54
48 -54 37 0 -6 -5 -8 -10 -5 -6 4 -8 10 -5 15 9 14 -19 28 -40 19z m61 -87
c-6 -5 -36 23 -36 34 0 4 9 -1 20 -11 11 -10 18 -20 16 -23z m274 -234 c0 -6
-6 -5 -15 2 -8 7 -15 14 -15 16 0 2 7 1 15 -2 8 -4 15 -11 15 -16z m195 -159
c11 -16 1 -19 -13 -3 -7 8 -8 14 -3 14 5 0 13 -5 16 -11z m62 -71 c-3 -8 -6
-5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M7784 8362 c-16 -12 -90 -105 -119 -152 -16 -25 -40 -57 -53 -72 -31
-33 -26 -40 67 -102 36 -24 75 -53 85 -65 11 -11 25 -21 32 -21 12 0 54 46 54
60 0 4 -18 15 -39 25 -22 9 -52 30 -67 46 -23 24 -25 31 -14 44 18 22 32 19
91 -20 56 -38 79 -39 79 -3 0 16 -15 33 -53 58 -39 27 -52 42 -52 60 0 20 6
26 28 28 23 3 37 -6 74 -47 26 -28 52 -51 59 -51 15 0 54 48 54 67 0 7 -20 22
-45 33 -24 10 -64 37 -87 58 -51 46 -80 63 -94 54z"/>
<path d="M5675 8295 c-60 -8 -156 -19 -212 -24 -103 -10 -184 -32 -303 -81
-36 -15 -90 -33 -120 -40 -30 -7 -93 -32 -140 -55 -135 -69 -157 -76 -186 -63
-18 8 -24 17 -20 31 4 17 3 18 -13 3 -20 -19 -31 -20 -31 -5 0 5 5 7 10 4 6
-3 10 2 10 12 0 14 -8 10 -30 -17 -16 -19 -30 -38 -30 -42 0 -5 -8 -8 -17 -8
-15 0 -16 -2 -3 -10 11 -7 11 -10 2 -10 -7 0 -10 -4 -7 -10 3 -5 0 -10 -9 -10
-8 0 -16 -5 -18 -11 -2 -5 -10 -11 -18 -12 -8 -2 -21 -6 -27 -10 -9 -5 -13 -2
-13 9 0 15 -4 14 -25 -6 -14 -13 -25 -20 -25 -15 0 4 -14 -6 -30 -24 -17 -17
-37 -31 -44 -31 -8 0 -18 -5 -21 -11 -4 -6 -13 -8 -21 -5 -8 3 -14 -1 -14 -9
0 -8 -8 -12 -19 -10 -12 2 -18 -1 -16 -8 10 -27 -88 -103 -118 -91 -7 2 -24
-7 -37 -21 -13 -14 -20 -25 -15 -25 4 0 16 10 26 22 11 11 21 19 24 16 11 -11
-47 -57 -63 -51 -15 6 -15 5 -3 -10 10 -12 10 -17 2 -17 -16 0 -42 -26 -34
-34 4 -3 0 -6 -8 -6 -8 0 -21 -9 -29 -19 -7 -11 -17 -18 -22 -15 -4 3 -8 -2
-8 -12 0 -13 -3 -14 -14 -5 -18 15 -39 -9 -30 -35 4 -13 3 -15 -3 -7 -7 9 -24
-2 -63 -42 -30 -30 -51 -58 -47 -61 4 -4 7 -2 7 5 0 7 5 9 10 6 6 -3 10 2 11
12 0 10 3 13 5 6 6 -13 -50 -91 -85 -118 -24 -18 -24 -18 -18 1 5 12 -3 8 -23
-14 -16 -18 -30 -40 -30 -50 0 -10 7 -5 16 15 11 22 21 30 32 25 10 -3 12 -7
4 -10 -7 -2 -12 -10 -12 -18 0 -12 4 -12 20 -4 11 6 20 8 20 4 0 -3 6 -1 14 5
10 9 15 8 19 -3 4 -9 -11 -36 -34 -66 -38 -48 -68 -67 -55 -35 3 8 3 22 0 30
-5 12 -15 7 -47 -20 -41 -36 -61 -40 -36 -8 10 14 8 13 -10 -2 -13 -11 -32
-38 -43 -60 -12 -26 -14 -33 -3 -21 15 20 19 16 16 -15 -2 -22 21 -30 37 -13
7 8 12 16 12 19 -1 14 38 46 44 36 7 -10 -11 -52 -41 -98 -15 -23 -43 -21 -43
3 0 8 -5 14 -12 14 -6 0 -9 -3 -6 -6 3 -3 -13 -25 -35 -50 -44 -49 -63 -44
-27 6 12 16 19 30 17 30 -11 0 -59 -73 -67 -100 -10 -35 -3 -40 10 -7 7 19 9
17 9 -16 1 -23 -7 -50 -18 -65 -10 -15 -25 -46 -33 -70 -8 -23 -17 -39 -21
-35 -4 3 -7 -20 -7 -53 0 -43 -6 -66 -20 -84 -11 -14 -20 -32 -20 -40 0 -9 -4
-20 -9 -25 -6 -6 -12 -32 -15 -60 -20 -198 -28 -253 -49 -345 -40 -172 -52
-299 -52 -530 1 -231 9 -314 54 -540 16 -80 34 -171 40 -203 6 -32 27 -95 47
-140 46 -104 95 -257 82 -257 -5 0 -17 -7 -25 -16 -15 -14 -15 -20 1 -70 25
-80 144 -303 186 -349 10 -11 35 -47 55 -79 83 -132 215 -266 260 -266 13 0
22 46 11 60 -15 19 225 -192 244 -215 8 -10 46 -41 85 -70 60 -44 123 -103 89
-82 -11 6 -54 -27 -54 -41 1 -14 36 -49 85 -82 28 -19 58 -42 68 -51 25 -23
263 -151 317 -170 77 -27 173 -79 188 -100 9 -13 21 -19 34 -16 21 6 142 -12
135 -20 -2 -2 -31 0 -63 5 -46 8 -55 7 -41 -3 12 -9 14 -14 5 -23 -9 -9 -8
-12 4 -12 14 0 14 -4 -3 -40 -10 -21 -19 -43 -19 -48 0 -4 -9 -27 -20 -50 -11
-22 -20 -48 -20 -57 0 -8 -4 -15 -10 -15 -5 0 -10 -8 -10 -17 -1 -26 -19 -46
-70 -78 -25 -15 -51 -37 -58 -47 -7 -10 -18 -16 -23 -12 -16 9 -49 -26 -49
-53 0 -19 5 -23 30 -23 16 0 48 7 70 16 22 9 51 14 65 12 17 -4 25 -1 25 9 0
8 15 -5 32 -28 18 -23 38 -44 43 -46 15 -4 35 46 35 86 0 20 4 41 8 47 8 13
55 28 135 44 70 14 76 39 12 52 -67 13 -119 32 -139 50 -11 10 -16 18 -12 18
5 0 3 6 -3 14 -8 10 -8 21 -1 40 5 15 10 41 10 57 0 17 4 28 9 24 5 -3 11 9
13 27 3 28 5 30 18 17 23 -21 40 -22 33 -1 -5 16 -5 16 8 1 13 -16 60 -29 102
-30 10 0 20 -6 23 -14 3 -7 20 -13 40 -13 20 0 38 -3 41 -6 3 -3 13 0 21 7 13
9 14 9 8 0 -8 -15 -5 -15 78 -13 50 1 58 -1 48 -12 -8 -11 -7 -14 8 -14 10 0
16 4 14 8 -3 4 0 8 6 8 6 0 13 -7 16 -15 4 -8 9 -13 13 -11 4 3 13 1 20 -4 11
-6 9 -13 -8 -31 -11 -12 -21 -35 -21 -49 0 -50 -22 -80 -62 -86 -21 -4 -38
-10 -38 -15 0 -5 -11 -9 -25 -9 -14 0 -25 -5 -25 -11 0 -5 -4 -7 -10 -4 -21
13 -8 -7 31 -50 35 -38 41 -50 36 -75 -4 -19 -2 -30 5 -30 6 0 8 3 5 7 -4 4 4
19 19 34 21 22 25 33 19 59 -4 20 -15 34 -32 41 -33 12 -24 33 17 37 40 4 47
11 89 84 18 32 36 60 42 63 5 4 8 -28 6 -77 l-2 -83 44 -3 c24 -2 49 2 54 7 8
8 5 11 -10 11 -11 0 -24 7 -27 16 -10 26 -7 54 7 54 9 0 9 2 0 8 -9 6 -6 16
12 36 14 16 25 25 26 20 0 -5 4 0 9 11 8 19 9 19 9 1 1 -20 1 -20 15 0 8 10
19 20 25 20 6 1 16 2 21 3 6 0 10 9 11 19 1 11 4 9 9 -8 4 -14 8 -18 9 -9 1 9
8 16 16 16 8 0 15 -7 16 -16 0 -14 1 -14 7 -1 10 21 32 23 33 2 0 -15 1 -15 8
2 7 17 8 17 24 2 16 -16 17 -16 17 0 0 14 2 14 16 3 9 -7 23 -14 32 -15 8 -2
18 -8 22 -15 6 -8 9 -6 13 7 4 18 5 18 6 -1 1 -17 3 -18 11 -5 8 12 10 10 11
-10 0 -23 1 -23 9 -5 5 11 9 16 9 10 1 -5 6 -3 11 5 8 12 10 12 10 -5 0 -15 3
-16 9 -7 6 10 13 4 25 -21 9 -19 16 -42 16 -52 0 -9 5 -13 10 -10 6 3 10 -3
10 -15 0 -24 -10 -29 -94 -46 l-61 -13 73 4 c70 3 86 8 88 23 1 8 20 58 33 87
9 18 10 18 10 -5 1 -15 5 -30 10 -35 5 -6 11 -21 13 -35 3 -22 8 -25 48 -28
70 -4 76 -9 44 -34 -70 -52 -124 -113 -124 -138 0 -27 -38 -69 -73 -83 -9 -3
-17 -15 -17 -25 0 -11 -3 -34 -6 -51 -7 -31 -6 -32 22 -26 17 4 49 28 75 57
41 45 50 50 75 44 16 -4 36 -13 44 -20 25 -22 82 -38 94 -26 7 7 6 10 -2 10
-9 0 -9 6 0 25 8 19 8 35 0 64 -18 58 -16 66 31 119 58 68 77 91 77 97 0 3
-28 3 -62 -1 -42 -4 -73 -2 -93 7 l-30 12 35 7 35 6 -42 7 c-24 4 -43 12 -43
18 0 6 9 9 20 6 13 -3 20 0 20 9 0 8 5 14 10 14 6 0 10 22 10 50 0 35 4 49 13
47 6 -1 15 -9 19 -17 5 -12 7 -11 7 3 1 10 6 15 11 12 6 -3 10 0 11 7 1 7 4 2
8 -11 4 -14 12 -26 19 -27 7 -1 8 0 2 3 -5 2 -10 11 -10 19 0 9 5 12 10 9 6
-3 10 0 10 7 0 11 2 11 9 0 6 -10 10 -10 21 2 7 9 15 14 19 12 3 -2 12 2 18
10 22 23 -11 27 -78 9 -16 -4 -30 -2 -33 4 -4 7 1 11 14 11 39 0 250 75 250
89 0 3 11 19 25 35 14 16 25 33 25 37 0 9 259 136 315 154 22 7 72 34 110 59
39 26 95 58 125 73 91 43 288 185 405 291 236 216 282 265 401 432 34 47 82
114 107 150 26 36 62 97 81 136 18 39 49 96 68 125 19 30 41 73 48 94 19 62
49 110 78 128 85 53 120 113 171 298 75 269 96 451 88 756 -5 217 -21 331 -82
578 -19 78 -35 152 -35 165 0 13 -14 53 -31 89 -18 36 -47 100 -66 141 -18 41
-39 85 -45 98 -14 27 -56 41 -69 21 -4 -7 -10 -11 -12 -9 -22 26 -92 139 -104
168 -14 35 -82 148 -165 276 -36 55 -50 94 -28 81 6 -4 10 8 10 28 0 26 -10
44 -50 85 -27 29 -50 56 -50 60 0 5 -20 30 -45 56 -25 27 -45 46 -45 43 0 -3
-20 14 -44 39 -25 24 -48 44 -53 44 -5 0 -34 18 -64 40 -30 22 -64 47 -77 57
-12 9 -20 18 -18 21 6 6 -35 47 -63 61 -12 7 -19 17 -15 24 4 7 4 9 0 5 -11
-9 -81 24 -115 55 -36 31 -111 69 -111 55 0 -17 -61 3 -119 40 -30 19 -88 48
-128 63 -39 16 -94 40 -122 54 -28 14 -81 32 -117 41 -37 8 -88 26 -113 38
-76 39 -207 76 -305 86 -50 6 -127 17 -171 26 -116 22 -545 28 -690 9z m570
-88 c42 -6 64 -19 67 -39 2 -17 14 -24 58 -34 30 -7 58 -10 61 -8 10 6 151 6
174 0 11 -2 58 -12 105 -21 124 -24 178 -41 240 -77 30 -18 68 -37 85 -43 16
-5 36 -22 43 -38 7 -15 31 -39 52 -52 22 -14 55 -48 73 -77 58 -90 88 -111
231 -163 29 -11 44 -20 36 -25 -8 -5 -7 -9 4 -13 9 -4 16 -2 16 3 0 5 7 7 15
4 8 -4 12 -10 9 -15 -3 -5 1 -9 9 -9 8 0 18 -7 21 -16 4 -9 13 -14 21 -10 8 3
14 0 12 -7 -1 -7 8 -12 21 -11 12 0 22 -3 22 -8 0 -4 10 -8 23 -8 12 0 40 -16
62 -35 22 -19 67 -48 100 -65 56 -28 109 -76 144 -127 8 -12 20 -19 27 -16 6
2 15 -1 20 -8 4 -7 3 -10 -4 -5 -6 3 -13 2 -17 -4 -3 -5 1 -10 9 -10 9 0 16
-4 16 -10 0 -5 5 -10 11 -10 15 0 101 -77 116 -103 7 -12 12 -33 13 -47 0 -15
13 -37 30 -53 17 -16 27 -33 24 -39 -3 -5 -14 3 -24 19 -9 16 -32 42 -50 58
-17 17 -43 41 -57 54 -13 13 -27 20 -30 17 -4 -3 -1 -50 6 -103 8 -57 10 -128
7 -168 -4 -38 -8 -90 -10 -115 -4 -42 -28 -121 -56 -182 -13 -28 -21 -32 -50
-29 -12 1 -12 0 -2 -7 7 -5 14 -26 16 -46 2 -25 17 -56 40 -87 20 -27 36 -51
36 -55 0 -4 22 -39 48 -78 27 -39 53 -84 59 -101 6 -16 14 -39 19 -50 4 -11 9
-42 11 -70 5 -66 10 -78 28 -71 11 4 15 -2 15 -27 0 -24 3 -28 10 -17 8 13 10
12 10 -2 0 -10 -4 -18 -10 -18 -5 0 -10 -9 -10 -21 0 -16 4 -19 20 -14 22 7
28 -10 7 -18 -19 -7 -28 2 -56 51 -17 33 -23 58 -22 103 0 39 -3 58 -10 53 -6
-3 -8 -12 -5 -20 3 -7 0 -11 -7 -8 -7 2 -37 51 -67 107 -47 90 -143 226 -159
227 -3 0 -28 -24 -56 -55 -34 -38 -60 -57 -82 -61 -47 -9 -43 9 22 107 30 45
56 87 57 93 4 17 -77 150 -116 190 -18 20 -31 38 -29 40 3 2 -53 64 -124 137
-260 270 -299 303 -328 279 -7 -6 -30 -37 -50 -69 -38 -60 -85 -97 -111 -87
-10 4 -14 -2 -14 -20 0 -19 19 -45 68 -93 83 -83 132 -143 173 -212 18 -30 51
-83 74 -119 24 -36 49 -87 58 -115 8 -27 34 -99 57 -160 42 -107 43 -114 51
-275 11 -218 1 -466 -22 -545 -44 -149 -113 -325 -152 -384 -23 -36 -49 -80
-57 -98 -20 -44 -118 -161 -205 -246 -186 -181 -231 -214 -415 -307 -147 -73
-203 -91 -435 -136 -183 -35 -195 -36 -332 -30 -91 4 -168 14 -215 26 -40 11
-113 27 -161 35 -66 10 -112 26 -179 60 -50 25 -96 45 -102 45 -24 0 -116 50
-152 83 -22 19 -57 42 -79 51 -22 9 -66 43 -98 74 -64 62 -147 164 -147 181 0
5 12 19 28 31 59 47 189 -2 318 -120 97 -88 131 -100 284 -99 63 1 127 -3 141
-9 40 -16 98 -71 120 -113 34 -68 73 -71 95 -7 6 21 27 44 55 63 58 39 192
178 201 207 12 45 8 99 -12 138 -24 48 -25 76 -3 84 9 4 53 10 98 13 44 3 86
11 94 17 26 20 91 130 97 163 12 66 -16 233 -45 266 -15 18 -37 19 -98 4 -32
-8 -37 19 -17 97 22 87 14 116 -30 116 -18 0 -58 5 -89 11 -74 15 -120 5 -166
-36 -21 -19 -59 -43 -84 -54 -26 -11 -47 -26 -47 -33 0 -18 46 -44 91 -52 50
-9 79 -43 78 -92 0 -21 4 -62 10 -91 30 -150 -35 -230 -124 -152 -53 47 -65
77 -64 158 1 81 -8 99 -104 198 -57 58 -62 61 -78 47 -16 -14 -27 -15 -82 -5
-82 14 -235 14 -276 0 -50 -18 -58 -43 -26 -89 15 -21 44 -50 66 -65 22 -14
44 -37 49 -50 14 -36 44 -31 78 13 28 35 167 132 190 132 6 0 38 -22 71 -50
92 -77 111 -167 26 -127 -49 23 -74 21 -119 -9 -28 -19 -59 -29 -107 -34 -119
-13 -202 -72 -184 -130 9 -29 37 -25 70 10 16 16 30 28 32 26 3 -2 -1 -22 -8
-44 -6 -22 -9 -52 -5 -66 6 -24 11 -26 60 -26 39 0 68 7 107 28 29 15 54 33
56 39 3 7 -18 23 -45 37 -48 24 -68 48 -56 67 8 14 85 11 161 -7 77 -18 93
-28 93 -58 0 -20 6 -33 37 -83 10 -17 7 -27 -21 -69 -19 -27 -47 -56 -62 -63
-16 -7 -84 -17 -151 -22 -88 -6 -136 -14 -173 -30 -60 -26 -77 -21 -84 23 -13
90 -29 212 -36 290 -8 76 -16 100 -61 190 -64 125 -68 173 -26 255 32 62 35
85 18 135 -7 21 -10 42 -7 46 3 5 29 12 58 15 63 8 94 29 129 88 19 34 33 46
62 53 31 8 42 6 65 -11 15 -11 32 -37 38 -59 7 -25 18 -40 30 -43 35 -7 250
21 228 30 -25 9 -35 31 -24 49 7 11 25 11 100 1 201 -28 258 25 160 149 -17
21 -37 56 -46 77 -25 59 -35 68 -48 41 -10 -20 -24 -25 -106 -35 -52 -6 -136
-12 -187 -12 -50 -1 -103 -5 -116 -10 -13 -5 -54 -39 -89 -75 -36 -36 -70 -66
-75 -66 -5 0 -15 11 -21 25 -16 34 -62 33 -133 -2 -57 -30 -62 -41 -44 -105
10 -37 -10 -37 -47 -1 -36 35 -36 73 0 115 49 56 133 213 157 293 29 94 36
104 133 207 43 46 84 99 91 118 23 58 39 70 91 70 26 0 69 7 96 15 80 25 245
55 304 55 77 0 210 -28 256 -54 23 -13 40 -30 42 -43 2 -11 18 -35 37 -52 38
-34 47 -73 43 -184 -3 -61 0 -71 20 -87 46 -38 17 -67 -34 -34 -18 13 -28 14
-36 6 -16 -16 -1 -45 61 -115 28 -32 54 -69 57 -83 10 -40 -3 -123 -26 -161
-11 -20 -19 -38 -16 -40 3 -3 35 -16 72 -29 l67 -23 39 31 c57 44 204 118 237
118 40 0 92 -39 120 -90 19 -34 26 -64 29 -126 3 -49 0 -89 -7 -100 -8 -15
-10 -4 -11 52 0 84 -13 129 -48 167 -29 31 -48 33 -89 12 -15 -8 -45 -21 -66
-30 -55 -21 -58 -42 -12 -82 21 -18 47 -33 57 -33 15 0 18 8 18 55 0 48 3 55
19 55 26 0 46 -31 72 -110 12 -36 25 -69 30 -74 12 -14 11 -67 -2 -105 -13
-36 -35 -51 -78 -51 -19 0 -34 -7 -40 -19 -12 -21 -5 -31 20 -31 31 0 30 -18
-2 -55 -58 -66 -114 -83 -239 -72 l-25 2 -5 -144 -5 -144 -104 -97 c-78 -73
-102 -102 -98 -114 9 -22 45 -15 142 26 141 61 175 69 265 67 82 -2 85 -1 91
22 30 114 99 298 123 328 15 20 39 56 52 81 14 25 33 60 44 77 11 18 24 63 30
100 18 114 32 187 38 203 3 8 7 42 7 75 5 213 7 235 26 259 24 30 24 37 2 30
-10 -3 10 12 45 35 34 22 65 47 68 55 7 19 -13 91 -41 149 -14 26 -23 47 -21
47 2 0 -11 15 -28 33 -17 19 -47 62 -65 98 -49 91 -113 189 -121 184 -3 -2 -5
10 -2 28 l3 32 -35 -37 c-20 -21 -42 -38 -49 -38 -7 0 -17 -4 -22 -9 -6 -5
-35 -11 -65 -14 -30 -3 -49 -3 -42 -1 17 8 15 31 -2 37 -8 3 -32 3 -52 0 -27
-4 -40 -2 -48 9 -18 24 -72 48 -110 48 -31 0 -37 4 -58 48 -13 27 -33 56 -45
66 -23 17 -23 17 -24 -21 -1 -21 -1 -41 -1 -44 1 -8 -186 28 -221 42 -42 18
-49 39 -32 104 18 68 43 113 66 120 37 12 86 7 122 -13 20 -12 37 -17 37 -12
0 6 6 10 14 10 8 0 16 6 19 14 3 8 28 21 56 31 28 9 51 22 51 28 -1 18 -27 50
-31 38 -3 -7 -26 -7 -69 -1 -36 5 -115 10 -176 10 -95 0 -113 -3 -127 -18 -22
-24 -67 -135 -67 -164 0 -94 -73 -176 -95 -107 -8 24 -21 24 -28 0 -3 -13 -18
-20 -51 -25 -26 -4 -88 -21 -138 -37 -50 -16 -100 -29 -110 -29 -11 0 -33 -13
-49 -29 -16 -16 -29 -26 -29 -21 0 13 -38 18 -49 7 -6 -6 -11 -18 -11 -27 0
-9 -16 -31 -35 -50 -19 -19 -35 -42 -35 -51 0 -27 -27 -30 -83 -10 -29 11 -90
33 -136 50 -122 44 -143 68 -96 109 14 12 25 18 25 14 0 -5 6 -9 13 -9 6 0 23
-3 36 -7 16 -6 21 -5 17 3 -4 6 4 16 19 23 22 10 26 17 22 42 -2 17 2 48 9 69
14 40 7 71 -20 89 -7 5 -6 8 3 8 8 0 11 3 8 6 -3 4 -1 16 5 28 6 11 -7 3 -28
-19 -22 -22 -70 -58 -108 -80 -38 -22 -81 -55 -95 -74 -14 -18 -44 -55 -66
-81 -47 -57 -141 -259 -160 -344 -21 -96 -45 -229 -45 -248 0 -14 9 -18 43
-18 24 0 70 -9 102 -20 66 -23 107 -25 138 -8 19 9 20 9 11 -3 -6 -8 -10 -27
-9 -42 1 -15 -2 -30 -7 -33 -4 -3 -8 -14 -8 -25 0 -11 -6 -22 -14 -25 -8 -3
-12 -12 -9 -20 3 -8 0 -20 -6 -28 -7 -8 -10 -27 -7 -42 2 -16 7 -53 11 -81 4
-29 11 -53 16 -53 5 0 9 -11 9 -25 0 -14 4 -25 9 -25 17 0 33 -87 27 -146 -13
-129 -3 -290 16 -278 5 4 8 -2 7 -12 -2 -11 0 -24 4 -31 8 -12 26 -96 33 -153
3 -19 9 -51 14 -70 6 -19 10 -56 10 -82 0 -26 5 -50 11 -53 6 -4 8 -12 6 -19
-3 -6 1 -20 9 -31 8 -10 14 -32 14 -48 0 -16 4 -26 10 -22 6 3 10 0 10 -7 0
-25 -42 -17 -122 22 -134 67 -239 88 -271 56 -8 -8 -18 -40 -21 -70 -4 -30
-13 -60 -21 -66 -17 -14 -168 -25 -229 -16 -58 9 -86 37 -86 87 0 41 -11 54
-57 64 -18 3 -62 31 -98 61 -62 50 -70 54 -120 54 -75 0 -85 6 -85 47 0 29 -8
43 -42 74 -42 38 -42 39 -29 71 8 18 16 52 18 76 5 38 2 44 -16 49 -18 5 -20
11 -16 52 2 25 -1 64 -7 86 -6 22 -10 65 -9 96 1 31 -5 72 -14 93 -15 36 -15
39 12 86 15 27 24 52 20 56 -4 4 -44 7 -88 7 -78 -1 -81 0 -74 21 4 11 7 30 6
40 0 11 5 29 11 40 14 26 69 98 83 108 16 12 122 9 186 -4 47 -9 63 -9 83 1
15 8 37 11 58 7 99 -19 118 -19 118 -2 0 28 49 39 173 37 117 -2 147 5 147 33
0 7 -51 29 -112 50 -62 21 -141 52 -175 68 -61 29 -63 29 -137 18 -67 -11 -81
-18 -132 -61 -68 -57 -109 -69 -162 -45 -20 9 -57 18 -82 19 -58 4 -61 20 -15
83 82 114 83 116 90 194 3 41 11 80 16 87 7 7 7 18 1 30 -26 49 -65 272 -67
387 -3 161 11 215 52 199 9 -3 11 0 7 10 -3 8 0 18 6 22 8 5 7 11 -2 23 -10
12 -10 14 0 8 8 -5 12 -1 12 13 0 10 -5 21 -11 23 -18 6 9 46 76 110 33 31 71
63 85 70 14 7 59 39 101 70 80 61 230 140 314 165 98 30 150 69 215 163 13 19
35 46 48 60 14 14 36 39 49 57 23 29 112 83 138 83 6 0 20 8 31 18 10 9 30 19
44 21 14 1 27 6 30 11 17 25 183 51 317 49 141 -2 175 5 216 48 30 30 37 33
60 24 15 -6 33 -8 41 -5 44 17 132 22 296 18 102 -2 194 -6 205 -7z m-1525
-193 c0 -2 -7 -4 -15 -4 -8 0 -15 4 -15 10 0 5 7 7 15 4 8 -4 15 -8 15 -10z
m2448 -61 c24 -23 -2 -14 -33 13 -26 22 -27 24 -5 13 13 -7 31 -18 38 -26z
m-3018 -343 c0 -15 -27 -23 -47 -15 -16 7 -15 9 7 15 36 11 40 11 40 0z m-30
-34 c0 -11 -19 -15 -25 -6 -3 5 1 10 9 10 9 0 16 -2 16 -4z m-122 -126 c-29
-31 -68 -76 -87 -99 -33 -42 -49 -43 -46 -3 1 17 38 62 50 62 3 0 17 7 31 16
19 13 22 20 13 30 -8 10 -8 14 0 14 6 0 11 6 11 14 0 16 48 36 68 28 8 -3 -6
-25 -40 -62z m-73 22 c-13 -14 -27 -23 -31 -20 -8 8 38 57 48 51 4 -2 -3 -16
-17 -31z m-5 -294 c0 -15 -30 -51 -79 -93 l-33 -30 31 50 c47 76 54 85 68 85
7 0 13 -6 13 -12z m-110 -132 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5
11 10 11 6 0 10 -2 10 -4z m2692 -526 c52 -27 103 -60 114 -71 l19 -22 -34 7
c-19 3 -54 16 -79 29 -48 23 -145 106 -126 107 6 0 54 -22 106 -50z m1173
-231 c17 -10 39 -23 50 -28 25 -11 48 -37 69 -78 14 -29 14 -35 -1 -76 -15
-42 -15 -48 1 -101 24 -76 51 -96 132 -96 80 0 114 -12 114 -42 0 -37 -36 -87
-67 -94 -15 -3 -34 -7 -40 -10 -20 -6 -15 -40 11 -71 30 -36 59 -92 62 -120 1
-12 10 -46 19 -74 16 -46 16 -60 4 -122 l-13 -70 30 -23 c16 -13 41 -29 56
-35 23 -9 28 -19 34 -65 4 -36 2 -59 -5 -68 -13 -16 -48 -10 -73 12 -12 10
-28 12 -50 8 -93 -17 -115 -18 -176 -6 -82 15 -136 3 -182 -40 -27 -26 -34
-28 -46 -16 -16 16 -19 61 -4 51 6 -4 10 13 10 43 0 27 3 52 7 56 4 3 8 17 10
29 2 12 8 33 13 47 6 14 12 41 15 60 11 96 22 156 29 169 12 19 10 384 -3 423
-5 18 -15 67 -22 108 -7 41 -16 77 -21 80 -4 4 -9 38 -10 78 -1 41 -5 67 -10
60 -4 -7 -8 -8 -8 -2 0 11 21 34 30 33 3 0 19 -9 35 -20z m-3899 -294 c1 -8
-3 -13 -9 -12 -7 1 -11 -4 -9 -12 1 -7 -2 -19 -8 -25 -5 -7 -7 -16 -3 -19 3
-4 1 -7 -7 -7 -10 0 -10 -3 -1 -14 7 -9 8 -17 2 -20 -5 -3 -7 -15 -4 -26 4
-16 0 -20 -20 -20 -28 0 -42 -31 -38 -87 2 -26 -1 -33 -15 -33 -9 0 -15 -3
-11 -6 3 -4 0 -12 -7 -20 -7 -7 -13 -23 -12 -36 1 -25 1 -24 116 -19 64 4 169
-1 144 -6 -17 -3 -20 -9 -16 -25 5 -21 3 -21 -93 -14 -68 5 -100 4 -105 -4 -4
-7 -22 -8 -47 -4 -63 10 -68 16 -44 55 12 19 21 42 21 51 0 10 10 27 23 39 12
12 16 19 10 15 -19 -11 -16 1 16 63 38 72 61 126 61 141 0 7 7 28 16 47 17 34
35 33 40 -2z m4767 -272 c-7 -3 -13 -10 -13 -18 0 -7 -7 -18 -15 -25 -13 -11
-14 -10 -3 11 23 42 30 51 37 43 3 -4 0 -9 -6 -11z m-405 -180 c-10 -2 -26 -2
-35 0 -10 3 -2 5 17 5 19 0 27 -2 18 -5z m-4328 -325 c0 -5 -9 -8 -20 -8 -23
0 -25 6 -7 24 12 13 26 4 27 -16z m150 -28 c-11 -21 -16 -22 -88 -25 -24 0
-46 -6 -50 -11 -9 -13 -68 -34 -76 -27 -2 3 -15 -2 -27 -10 -17 -12 -25 -13
-40 -4 -26 16 -24 24 8 32 15 3 48 16 73 28 30 14 62 21 97 20 29 -1 55 2 58
8 4 5 17 9 31 9 22 0 23 -3 14 -20z m3593 -297 c15 -3 27 -9 27 -14 0 -5 14
-9 30 -9 17 0 30 -4 30 -9 0 -5 6 -9 13 -8 32 1 48 -4 42 -13 -4 -6 5 -10 20
-10 15 0 24 -4 20 -10 -3 -5 3 -10 15 -10 12 0 19 -4 16 -8 -3 -5 -24 -7 -48
-5 -39 3 -41 2 -23 -12 11 -8 29 -15 39 -15 11 0 17 -3 13 -6 -8 -9 -109 16
-127 31 -27 22 -79 43 -82 34 -3 -10 -64 16 -73 31 -3 5 0 19 7 30 9 15 19 19
33 14 11 -3 32 -8 48 -11z m-3418 -63 c-3 -5 -13 -10 -21 -10 -7 0 -19 -9 -26
-19 -6 -10 -17 -17 -25 -14 -7 3 -16 -2 -19 -11 -3 -9 -15 -16 -25 -16 -10 0
-19 -4 -19 -10 0 -5 -9 -10 -21 -10 -14 0 -19 -5 -16 -15 4 -8 1 -17 -5 -21
-7 -5 -8 -2 -3 6 5 8 3 11 -4 9 -7 -3 -12 -8 -11 -13 1 -5 -13 -18 -30 -29
-24 -15 -34 -17 -40 -8 -5 9 -9 8 -14 -5 -5 -13 -9 -15 -18 -6 -19 19 15 46
152 116 123 62 157 76 145 56z m3832 -96 c40 -17 42 -25 6 -20 -31 4 -84 24
-68 26 6 0 3 5 -5 11 -18 13 16 4 67 -17z m-100 -10 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m203 -50 c0 -2 -13 2 -30 11 -16 9 -30 18 -30
21 0 2 14 -2 30 -11 17 -9 30 -18 30 -21z m-4299 -38 c-12 -15 -60 -30 -68
-22 -6 6 48 35 66 36 10 0 11 -3 2 -14z m3514 4 c4 -6 12 -10 18 -9 26 3 38
-2 59 -26 11 -13 24 -22 28 -20 4 3 10 -4 13 -15 3 -11 11 -20 19 -20 10 0 9
3 -2 10 -11 7 -11 10 -2 10 7 0 16 -9 20 -19 4 -11 9 -19 12 -19 9 3 63 -36
90 -62 l25 -26 -20 26 -20 25 26 -23 c28 -24 33 -48 12 -56 -7 -3 -13 -1 -13
5 0 7 -10 9 -23 5 -16 -4 -34 4 -64 28 -23 18 -40 36 -36 40 3 3 2 6 -3 7 -33
2 -62 13 -115 40 -66 35 -91 68 -69 94 14 17 36 20 45 5z m-2729 -38 c24 -13
-47 -86 -75 -77 -10 3 -22 1 -26 -4 -4 -7 1 -9 12 -5 17 5 17 5 1 -6 -13 -8
-23 -8 -39 1 -17 8 -20 8 -14 -1 4 -7 4 -15 -1 -19 -5 -3 -14 -15 -21 -26 -6
-11 -16 -19 -23 -17 -6 1 -9 -2 -6 -7 8 -13 -19 -33 -48 -35 -13 0 -22 -4 -20
-7 6 -10 -82 -76 -291 -220 l-60 -41 75 74 c110 107 444 393 485 416 15 8 22
7 28 -3 4 -8 15 -18 23 -23z m382 -62 c12 -12 22 -28 22 -38 0 -15 2 -15 10
-2 7 10 10 11 10 2 0 -15 -81 -105 -88 -97 -3 2 9 18 27 34 17 16 31 33 31 37
0 4 -13 -4 -28 -19 -16 -15 -31 -24 -35 -20 -4 3 -7 -5 -7 -20 0 -32 -9 -57
-21 -57 -5 0 -9 7 -9 16 0 8 5 12 10 9 6 -3 10 -1 10 4 0 6 -4 11 -10 11 -5 0
-10 7 -10 16 0 8 5 12 10 9 6 -3 10 -1 10 4 0 6 -7 11 -15 11 -11 0 -15 -13
-15 -54 0 -54 -15 -81 -30 -56 -6 9 -9 4 -12 -15 -3 -29 -25 -47 -25 -21 0 8
4 13 9 10 4 -3 8 0 8 5 0 6 -7 11 -15 11 -8 0 -15 -9 -15 -20 0 -12 -7 -20
-17 -21 -17 -1 -17 -2 0 -6 9 -2 17 -9 17 -14 0 -6 -5 -7 -12 -3 -7 4 -8 3 -4
-5 10 -15 -183 -211 -201 -204 -7 3 -13 -2 -13 -12 0 -10 4 -13 13 -9 20 13
-13 -26 -93 -108 -41 -42 -92 -96 -112 -120 -25 -28 -38 -37 -38 -26 0 9 20
40 45 70 64 76 266 333 290 369 11 17 55 73 97 125 43 52 92 116 109 142 38
56 60 82 69 82 3 0 16 -9 28 -20z m2100 -112 c-2 -7 4 -12 12 -13 8 0 20 -10
26 -23 6 -13 15 -22 20 -21 5 1 14 -8 20 -20 6 -12 15 -21 20 -20 5 1 14 -8
20 -20 6 -12 15 -21 19 -19 4 2 12 -5 16 -14 23 -46 28 -53 38 -50 6 1 11 -6
11 -16 0 -9 8 -22 18 -28 9 -6 25 -25 34 -42 16 -27 16 -31 2 -36 -8 -3 -12
-2 -9 4 3 6 -2 10 -12 10 -10 0 -49 30 -88 65 -38 36 -88 80 -109 98 -104 84
-116 110 -68 139 33 21 33 21 30 6z m688 -79 c3 -5 -3 -6 -12 -2 -12 4 -15 3
-10 -5 4 -7 2 -12 -4 -12 -7 0 -10 7 -6 15 3 8 -4 21 -17 30 -28 19 -34 19
-41 -2 -3 -13 -4 -12 -3 3 1 12 -9 31 -23 43 -14 12 5 3 43 -20 37 -23 70 -45
73 -50z m-2551 13 c0 -9 6 -16 13 -14 7 1 15 -12 19 -30 5 -27 7 -29 14 -13 4
11 8 14 8 7 2 -25 -29 -83 -39 -77 -7 4 -10 0 -8 -11 2 -11 -2 -18 -9 -16 -7
1 -13 -5 -13 -14 0 -8 -4 -12 -10 -9 -6 4 -7 -1 -3 -11 5 -13 3 -16 -10 -11
-11 4 -21 -1 -28 -16 -12 -21 -11 -21 6 -7 11 9 -2 -24 -31 -80 -27 -52 -55
-95 -63 -95 -8 0 -16 -7 -18 -15 -4 -13 -3 -13 6 0 13 19 14 1 1 -20 -5 -8
-15 -15 -22 -15 -7 0 -17 -10 -23 -22 -5 -13 -13 -23 -17 -23 -5 0 -8 -6 -8
-14 0 -8 -7 -17 -16 -20 -8 -3 -13 -10 -9 -15 3 -6 -4 -15 -16 -21 -19 -11
-20 -10 -14 12 4 12 35 77 68 143 32 66 79 160 103 208 24 48 44 95 44 103 0
15 35 90 53 112 12 15 22 8 22 -16z m2502 -24 c-3 -8 -6 -5 -6 6 -1 11 2 17 5
13 3 -3 4 -12 1 -19z m198 -88 c10 -11 16 -20 13 -20 -3 0 -13 9 -23 20 -10
11 -16 20 -13 20 3 0 13 -9 23 -20z m-1111 -15 c9 -14 21 -23 26 -20 6 4 10
-6 10 -22 0 -22 2 -25 10 -13 9 13 10 13 11 0 1 -8 1 -24 0 -35 -2 -17 0 -17
8 -5 8 11 11 11 12 3 2 -13 5 -27 9 -40 1 -5 2 -11 2 -15 -1 -3 5 -12 13 -18
8 -7 15 -19 15 -26 0 -8 7 -14 15 -14 9 0 18 -12 21 -30 4 -16 11 -28 16 -27
6 1 12 -9 14 -23 1 -14 6 -37 9 -52 6 -23 4 -26 -11 -22 -20 5 -58 54 -110
140 -17 28 -45 65 -62 84 -18 18 -32 37 -32 41 0 5 -10 20 -22 35 -32 36 -41
72 -19 77 36 10 49 6 65 -18z m-1280 -25 c15 0 26 -21 26 -48 0 -9 -11 -46
-24 -82 -14 -36 -31 -96 -40 -134 -11 -47 -24 -76 -41 -92 -32 -30 -35 -30
-36 -1 0 21 -1 21 -10 2 -6 -14 -8 2 -4 50 5 78 40 212 75 294 21 47 25 51 31
32 5 -11 15 -21 23 -21z m2396 3 c0 -7 -39 17 -46 28 -3 6 5 3 20 -6 14 -10
26 -19 26 -22z m-2131 -81 c1 4 13 3 28 -3 20 -7 28 -6 35 3 12 19 9 -33 -7
-147 -8 -55 -15 -143 -15 -195 0 -52 -11 -187 -25 -300 -14 -113 -25 -214 -26
-225 0 -13 -3 -15 -6 -6 -5 11 -7 11 -15 0 -5 -9 -6 147 -3 391 6 424 11 530
26 495 4 -11 8 -17 8 -13z m708 -3 c7 -13 13 -19 13 -14 0 6 4 4 9 -3 5 -8 6
-34 3 -59 -4 -36 -2 -44 8 -38 8 5 11 4 6 -3 -4 -6 -2 -20 4 -32 5 -11 15 -29
20 -40 6 -11 10 -25 10 -31 0 -6 9 -22 20 -36 11 -14 33 -69 49 -122 35 -115
61 -201 67 -221 3 -8 -1 -6 -8 6 -7 12 -19 19 -25 17 -7 -3 -13 1 -13 9 0 13
-44 103 -80 163 -9 17 -25 55 -35 85 -10 30 -40 98 -67 150 -53 101 -71 176
-47 191 23 14 53 4 66 -22z m-365 -18 c21 -17 27 -34 37 -108 7 -48 15 -95 18
-103 10 -24 12 -38 34 -185 33 -228 40 -289 36 -307 -3 -10 -2 -20 2 -23 4 -2
6 -18 3 -36 -3 -18 0 -34 7 -39 8 -4 11 -31 9 -76 l-3 -69 -28 110 c-15 61
-34 164 -43 230 -15 120 -44 264 -90 445 -24 95 -31 180 -15 180 4 0 19 -9 33
-19z m1302 -76 c8 -8 17 -15 20 -15 3 0 3 7 0 15 -10 24 2 17 22 -12 13 -22
14 -25 2 -15 -13 10 -19 10 -31 -2 -13 -13 -17 -13 -31 3 -30 33 -14 57 18 26z
m-430 -101 c3 -8 2 -12 -4 -9 -6 3 -10 10 -10 16 0 14 7 11 14 -7z m562 -16
l29 -33 -32 29 c-31 28 -38 36 -30 36 2 0 16 -15 33 -32z m-502 -115 c-5 -14
-4 -15 4 -4 11 15 19 4 55 -74 10 -22 23 -47 30 -55 7 -9 3 -6 -8 6 -11 11
-26 30 -33 40 -13 18 -13 18 -7 -1 5 -17 3 -17 -20 5 -45 42 -70 90 -57 110 8
13 7 22 -3 36 -8 11 -15 26 -15 33 0 8 13 -6 30 -32 17 -27 27 -54 24 -64z
m-2064 33 c0 -2 -8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m-40
-56 c-6 -11 -13 -20 -16 -20 -2 0 0 9 6 20 6 11 13 20 16 20 2 0 0 -9 -6 -20z
m405 -72 c-15 -21 -16 -21 -12 -3 3 11 9 36 14 55 l8 35 3 -32 c2 -20 -4 -42
-13 -55z m-484 -26 c-6 -11 -9 -27 -6 -34 3 -8 -2 -23 -12 -33 -10 -11 -24
-32 -32 -47 -19 -36 -44 -47 -31 -13 12 32 49 104 68 133 18 27 28 22 13 -6z
m2269 -47 c14 -19 14 -19 -2 -6 -10 7 -18 18 -18 24 0 6 1 8 3 6 1 -2 9 -13
17 -24z m-1808 -45 c-2 -14 -10 -27 -18 -27 -16 0 -19 23 -4 32 6 3 10 18 11
33 0 22 2 24 8 8 4 -10 5 -31 3 -46z m1858 20 c12 -16 19 -30 16 -30 -2 0 -14
14 -26 30 -12 17 -19 30 -16 30 2 0 14 -13 26 -30z m-1828 -7 c-3 -16 -7 -22
-10 -15 -4 13 4 42 11 42 2 0 2 -12 -1 -27z m-18 -68 c-4 -8 -8 -15 -10 -15
-2 0 -4 7 -4 15 0 8 4 15 10 15 5 0 7 -7 4 -15z m1323 -17 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m103 -155 c0 -31 -13 -29 -43 7 -33 38 -41
64 -28 89 6 11 8 28 5 38 -3 10 11 -11 30 -47 19 -36 35 -75 36 -87z m-1457
97 c-3 -11 -11 -20 -18 -20 -7 0 -16 -6 -18 -12 -4 -9 -6 -8 -6 2 -1 19 10 36
29 43 8 3 16 6 17 6 0 1 -1 -8 -4 -19z m-248 -548 c-3 -3 -11 0 -18 7 -9 10
-8 11 6 5 10 -3 15 -9 12 -12z m633 -129 c-16 -2 -40 -2 -55 0 -16 2 -3 4 27
4 30 0 43 -2 28 -4z m832 -9 c0 -2 -21 -4 -47 -3 -37 1 -42 3 -23 8 24 7 70 3
70 -5z m-227 -1 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m-419
-22 c4 -5 -3 -7 -14 -4 -23 6 -26 13 -6 13 8 0 17 -4 20 -9z m583 -68 c-3 -10
-5 -4 -5 12 0 17 2 24 5 18 2 -7 2 -21 0 -30z"/>
<path d="M6018 8133 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M5756 8121 c-30 -12 1 -18 49 -10 22 3 30 7 18 8 -12 0 -27 3 -35 5
-7 3 -22 1 -32 -3z"/>
<path d="M6205 8105 c84 -29 92 -30 45 -5 -20 10 -54 21 -75 24 -30 5 -23 0
30 -19z"/>
<path d="M5098 8020 c-35 -10 -209 -159 -228 -194 -23 -44 -3 -47 115 -15 61
16 160 40 220 52 76 15 100 23 78 25 -30 3 -48 22 -22 22 8 0 7 5 -2 16 -10
13 -10 17 1 24 8 5 18 6 22 4 4 -3 8 2 8 10 0 9 5 16 10 16 6 0 10 7 10 15 0
9 -9 15 -25 15 -14 0 -25 5 -25 10 0 12 -123 12 -162 0z"/>
<path d="M6522 8018 c2 -6 10 -14 16 -16 7 -2 10 2 6 12 -7 18 -28 22 -22 4z"/>
<path d="M6760 8021 c0 -8 -75 -26 -92 -22 -5 0 -8 -9 -9 -21 0 -22 -1 -22 -9
-3 -7 17 -9 18 -9 2 -1 -10 10 -22 25 -27 14 -6 23 -14 19 -19 -3 -5 3 -12 12
-14 15 -4 14 -5 -3 -6 -25 -1 -28 -31 -3 -31 10 0 104 -22 209 -49 175 -45
190 -47 190 -30 0 24 -175 198 -217 216 -36 15 -113 18 -113 4z"/>
<path d="M6562 7990 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M6580 7989 c0 -4 7 -9 15 -13 9 -3 15 0 15 8 0 7 -7 13 -15 13 -8 0
-15 -4 -15 -8z"/>
<path d="M5400 7938 c0 -19 2 -20 10 -8 13 19 13 30 0 30 -5 0 -10 -10 -10
-22z"/>
<path d="M6586 7915 c-6 -17 34 -31 61 -21 14 6 12 9 -11 21 -35 19 -42 19
-50 0z"/>
<path d="M6231 7624 c-34 -80 -60 -152 -57 -160 3 -8 17 -18 30 -24 29 -10
200 -124 233 -153 13 -11 26 -18 29 -14 7 6 -23 36 -110 111 -52 44 -64 80
-46 144 8 33 15 42 33 42 30 0 50 14 54 39 1 11 3 24 4 28 4 23 -3 33 -23 33
-16 0 -19 3 -11 11 18 18 -22 89 -51 89 -20 0 -30 -18 -85 -146z"/>
<path d="M5118 7755 c-2 -3 -22 -7 -44 -9 -21 -2 -96 -23 -166 -45 -138 -45
-159 -61 -179 -133 -11 -43 -15 -245 -5 -255 10 -10 69 22 119 65 25 22 70 54
100 71 30 17 61 39 68 48 8 9 48 38 89 65 41 26 67 48 57 48 -10 0 -25 6 -33
14 -16 17 -19 56 -4 56 6 0 10 9 10 20 0 11 8 24 18 27 9 4 23 13 30 20 11 11
7 13 -21 13 -18 0 -36 -2 -39 -5z"/>
<path d="M5816 7729 c16 -17 45 -39 65 -49 20 -11 50 -31 68 -46 20 -16 38
-24 48 -20 14 6 12 10 -12 22 -16 8 -48 34 -71 57 -50 51 -76 67 -106 67 -21
0 -21 -1 8 -31z"/>
<path d="M6943 7725 c30 -17 36 -35 11 -35 -9 0 -13 -5 -10 -12 3 -7 9 -21 12
-30 4 -10 11 -18 16 -18 5 0 8 -8 7 -17 -3 -20 20 -74 36 -85 5 -4 6 -8 2 -8
-5 0 -1 -7 7 -16 9 -8 16 -22 16 -30 0 -16 1 -17 49 -75 35 -42 111 -85 133
-76 27 10 32 98 13 193 -27 126 -31 130 -165 176 -141 48 -164 55 -127 33z"/>
<path d="M5700 7641 c0 -5 4 -12 9 -16 6 -3 6 -17 1 -34 -4 -16 -5 -32 -2 -36
3 -3 9 -27 12 -53 4 -40 9 -47 29 -50 46 -7 45 23 -8 140 -21 46 -41 70 -41
49z"/>
<path d="M4630 7615 c-7 -8 -20 -15 -29 -15 -9 0 -21 -4 -26 -8 -6 -5 -37 -19
-70 -32 -33 -13 -84 -39 -112 -57 -29 -18 -63 -38 -75 -43 -23 -10 -70 -39
-191 -119 -80 -53 -107 -100 -133 -231 -19 -98 -14 -249 11 -340 8 -30 21 -78
27 -107 7 -29 18 -55 26 -58 21 -8 56 18 89 66 99 143 279 346 407 459 l97 85
-6 95 c-8 118 -8 178 -1 258 3 34 4 62 2 62 -2 0 -9 -7 -16 -15z"/>
<path d="M7324 7462 c0 -81 -3 -169 -8 -195 l-8 -48 48 -42 c120 -102 397
-407 433 -476 17 -31 61 -81 83 -92 24 -13 42 18 75 127 24 78 27 105 26 219
-1 192 -39 311 -117 366 -22 15 -66 46 -96 67 -30 21 -68 46 -85 54 -16 8 -48
25 -70 38 -98 56 -124 69 -185 90 -36 12 -72 25 -81 30 -15 8 -16 -6 -15 -138z"/>
<path d="M4660 7526 c0 -14 5 -26 10 -26 6 0 10 9 10 19 0 11 -4 23 -10 26 -6
4 -10 -5 -10 -19z"/>
<path d="M5081 7424 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5030 7403 c-125 -82 -162 -109 -205 -150 -36 -34 -50 -54 -47 -68 2
-11 28 -49 56 -85 29 -36 63 -80 75 -97 12 -18 31 -33 41 -33 20 0 280 253
280 272 0 7 -14 -3 -32 -21 -17 -17 -37 -30 -44 -27 -7 3 -15 0 -18 -7 -2 -7
-5 -4 -5 6 -1 9 -5 17 -11 17 -5 0 -10 7 -10 15 0 8 -4 15 -9 15 -5 0 -9 26
-8 58 1 50 -19 123 -33 121 -3 0 -16 -7 -30 -16z"/>
<path d="M5249 7293 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M7044 7265 c-7 -16 27 -67 58 -90 20 -13 24 -13 40 2 12 11 18 12 18
4 0 -17 -82 -101 -98 -101 -7 0 -11 -4 -7 -9 3 -6 -6 -7 -22 -2 -33 9 -56 4
-46 -12 4 -6 0 -8 -9 -4 -29 11 -28 -29 1 -57 40 -38 59 -33 81 20 11 25 43
68 70 94 28 27 50 57 50 67 0 20 -36 57 -82 86 -33 21 -47 21 -54 2z m26 -5
c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z"/>
<path d="M5634 7233 c-66 -4 -74 -12 -74 -71 0 -19 4 -21 24 -16 14 3 31 2 38
-2 7 -5 64 -7 126 -5 89 2 118 0 136 -13 l24 -15 -10 37 c-6 20 -17 49 -24 65
-14 26 -16 27 -97 25 -45 -1 -110 -3 -143 -5z"/>
<path d="M4563 7028 c-141 -132 -235 -231 -351 -370 -45 -54 -80 -88 -92 -88
-10 0 -22 -5 -25 -10 -4 -7 3 -9 18 -6 28 5 166 -60 178 -84 4 -8 12 -28 19
-45 7 -19 39 -51 89 -87 43 -32 81 -58 84 -58 2 0 7 10 10 23 13 54 69 184
100 232 19 28 47 75 62 104 14 29 71 102 126 162 54 60 99 113 99 118 0 4 -26
34 -58 67 -31 32 -73 83 -93 112 l-35 52 -131 -122z"/>
<path d="M3961 7064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M6891 6961 c-9 -6 -10 -11 -2 -15 6 -4 11 -16 11 -27 0 -11 5 -17 10
-14 6 3 10 0 10 -7 0 -7 9 -19 20 -26 11 -7 20 -18 20 -25 0 -7 11 -28 25 -47
14 -19 25 -31 25 -27 0 13 48 -24 65 -49 13 -20 14 -32 6 -56 -10 -27 -6 -39
25 -99 20 -38 39 -69 43 -69 3 0 14 7 24 15 14 10 17 20 12 37 -12 39 -136
269 -174 321 -66 93 -88 108 -120 88z"/>
<path d="M5428 6753 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7935 6600 c-3 -5 -3 -10 2 -10 4 0 1 -5 -7 -10 -12 -8 -11 -10 7
-10 16 0 20 5 16 20 -6 23 -9 24 -18 10z"/>
<path d="M4012 6578 c-7 -7 -12 -21 -12 -31 0 -15 5 -18 23 -13 12 3 29 6 37
6 12 0 13 2 1 9 -7 5 -22 6 -32 4 -10 -3 -19 -1 -19 5 0 6 8 12 18 14 10 2 13
6 6 10 -6 4 -16 2 -22 -4z"/>
<path d="M6600 5795 c0 -56 103 -195 145 -195 23 0 55 37 55 64 0 27 -44 86
-64 86 -7 0 -33 14 -59 30 -52 33 -77 38 -77 15z"/>
<path d="M6605 5630 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M6527 5553 c-14 -14 -5 -25 36 -43 49 -23 101 -27 95 -7 -10 30 -112
69 -131 50z"/>
<path d="M6740 4769 c-25 -11 -70 -40 -100 -63 -30 -24 -79 -55 -108 -70 -51
-26 -203 -161 -223 -198 -8 -16 -7 -17 14 -9 17 7 38 5 66 -5 57 -19 143 -5
253 42 96 40 145 72 208 131 25 24 60 48 78 54 17 6 32 16 32 22 -1 34 -108
117 -149 116 -14 0 -46 -9 -71 -20z"/>
<path d="M4920 4516 c0 -2 7 -6 15 -10 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -8 0
-15 -2 -15 -4z"/>
<path d="M4815 4390 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4800 4367 c0 -10 8 -17 18 -17 9 0 13 4 7 8 -5 4 -13 11 -17 16 -5
5 -8 2 -8 -7z"/>
<path d="M4559 4063 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M5195 4270 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M5075 4089 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M6620 3595 c0 -8 4 -15 10 -15 5 0 7 7 4 15 -4 8 -8 15 -10 15 -2 0
-4 -7 -4 -15z"/>
<path d="M8320 8281 c0 -5 7 -12 16 -15 14 -5 15 -4 4 9 -14 17 -20 19 -20 6z"/>
<path d="M3957 8191 c-23 -21 -56 -46 -74 -55 -77 -39 -70 -111 17 -171 l38
-27 69 46 c78 53 113 59 113 22 0 -29 -43 -63 -92 -72 -69 -14 -88 -43 -50
-77 26 -24 54 -21 121 12 62 32 99 69 120 119 14 34 3 64 -45 121 -23 28 -31
31 -58 25 -25 -5 -29 -4 -18 7 18 20 15 43 -11 67 -37 35 -80 29 -130 -17z
m119 -62 c7 -12 -32 -49 -80 -75 -24 -13 -30 -14 -37 -2 -27 42 15 85 83 87
15 1 30 -4 34 -10z"/>
<path d="M8480 8136 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M8074 8091 c-17 -10 -41 -35 -53 -54 l-22 -36 25 -48 c14 -28 40 -58
61 -71 42 -27 46 -65 7 -70 -31 -5 -50 13 -62 57 -5 20 -26 52 -46 71 l-35 35
-20 -24 c-28 -35 -33 -65 -15 -100 10 -18 14 -41 10 -56 -5 -19 1 -31 24 -55
33 -33 53 -37 71 -16 11 12 18 12 54 -4 28 -13 46 -16 58 -9 21 11 99 98 99
111 0 5 -19 28 -43 51 -49 47 -67 76 -67 107 0 16 5 21 22 18 16 -2 31 -20 51
-60 34 -69 68 -86 98 -49 17 21 17 26 3 59 -19 47 -37 66 -106 113 -75 52 -78
52 -114 30z"/>
<path d="M8560 8050 c1 -12 90 -89 90 -77 0 1 -20 23 -45 47 -25 25 -45 38
-45 30z"/>
<path d="M3590 8001 c-14 -28 -12 -56 5 -71 12 -10 19 -7 39 17 29 32 31 44
14 61 -18 18 -46 14 -58 -7z"/>
<path d="M3465 8001 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M3676 7935 c-3 -9 -16 -19 -28 -24 -21 -8 -15 -17 100 -130 112 -111
124 -120 144 -110 30 16 57 50 58 71 0 10 -16 26 -37 37 -21 10 -51 34 -68 51
-35 37 -141 120 -154 120 -5 0 -11 -7 -15 -15z"/>
<path d="M3257 7882 c-10 -10 -17 -22 -17 -27 0 -4 9 2 20 15 11 13 20 25 20
27 0 7 -8 2 -23 -15z"/>
<path d="M8344 7780 c-31 -28 -63 -59 -70 -69 -8 -10 -34 -32 -59 -47 -49 -32
-56 -53 -25 -84 27 -27 50 -25 96 10 56 42 68 40 60 -12 -19 -121 -19 -123 7
-166 29 -45 47 -60 48 -40 0 7 8 51 18 98 19 96 19 96 131 99 72 2 86 11 70
42 -29 55 -38 59 -132 59 -48 0 -88 4 -88 9 0 5 18 25 40 44 22 19 40 38 40
44 0 13 -53 63 -67 63 -7 0 -38 -23 -69 -50z"/>
<path d="M3120 7705 c-7 -9 -8 -15 -2 -15 5 0 12 7 16 15 3 8 4 15 2 15 -2 0
-9 -7 -16 -15z"/>
<path d="M3304 7688 c-47 -22 -61 -110 -19 -116 51 -7 67 -16 148 -74 48 -35
110 -78 139 -97 l52 -34 29 33 29 33 -58 53 c-33 30 -72 61 -88 70 -44 24
-128 92 -149 120 -20 25 -48 30 -83 12z m56 -46 c0 -27 -15 -52 -32 -52 -13 0
-9 55 4 68 19 19 28 14 28 -16z"/>
<path d="M3151 7594 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3120 7581 c0 -6 5 -13 10 -16 6 -3 10 1 10 9 0 9 -4 16 -10 16 -5 0
-10 -4 -10 -9z"/>
<path d="M8737 7532 c-22 -24 -22 -25 12 -62 25 -28 32 -30 50 -21 28 15 27
33 -5 70 -30 36 -35 37 -57 13z"/>
<path d="M8644 7461 c-29 -15 -86 -53 -128 -84 -42 -30 -82 -57 -88 -59 -8 -3
-4 -16 11 -41 30 -49 57 -48 116 3 26 22 66 50 88 61 23 12 56 35 74 51 l31
30 -20 34 c-11 19 -23 34 -26 34 -4 0 -30 -13 -58 -29z"/>
<path d="M3172 7327 c-42 -45 -28 -64 88 -122 41 -21 93 -53 116 -71 56 -46
78 -44 99 8 9 23 29 58 44 77 33 43 64 101 58 108 -3 2 -24 7 -46 10 l-40 6
-25 -57 c-31 -70 -50 -72 -114 -15 -52 45 -111 79 -140 79 -10 0 -28 -10 -40
-23z"/>
<path d="M8745 7279 c-27 -23 -87 -59 -132 -80 -63 -29 -83 -43 -83 -58 0 -11
5 -23 11 -27 6 -4 26 -39 44 -78 35 -77 63 -100 103 -85 36 14 37 37 3 85 -17
25 -31 50 -31 57 1 16 117 93 153 103 36 8 42 24 31 77 -12 57 -36 59 -99 6z"/>
<path d="M2836 7264 c-5 -14 -4 -15 9 -4 17 14 19 20 6 20 -5 0 -12 -7 -15
-16z"/>
<path d="M3306 7027 c-13 -30 -31 -57 -40 -60 -26 -10 -99 10 -153 41 -26 16
-56 31 -65 35 -26 9 -50 -20 -46 -57 3 -27 12 -36 78 -73 41 -23 106 -52 144
-64 l69 -23 22 27 c19 21 75 164 75 189 0 12 -31 38 -46 38 -8 0 -25 -24 -38
-53z"/>
<path d="M2720 7025 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M9063 6953 c-18 -7 -16 -20 7 -43 11 -11 23 -38 26 -60 8 -49 19 -58
45 -34 31 28 26 87 -12 118 -30 26 -42 29 -66 19z"/>
<path d="M8801 6914 c-57 -7 -109 -18 -114 -23 -17 -17 9 -54 56 -82 23 -14
51 -42 62 -62 19 -35 19 -38 2 -66 -25 -43 -20 -101 8 -101 23 0 86 43 145
100 32 30 70 65 86 76 l29 22 -19 76 -19 76 -66 -1 c-36 -1 -113 -7 -170 -15z
m159 -97 c0 -23 -29 -55 -60 -65 -26 -9 -32 -8 -45 11 -21 30 -19 46 8 57 34
14 97 12 97 -3z"/>
<path d="M2847 6858 c-29 -16 -21 -56 16 -73 17 -9 43 -22 57 -30 14 -8 35
-15 46 -15 25 0 70 -18 168 -66 37 -19 75 -34 82 -34 18 0 49 63 41 83 -3 9
-21 20 -39 25 -18 5 -85 32 -149 61 -121 53 -145 56 -155 16 -9 -38 -30 -38
-26 0 4 36 -13 49 -41 33z"/>
<path d="M2625 6769 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M3415 6681 c-3 -5 -1 -12 5 -16 5 -3 10 1 10 9 0 18 -6 21 -15 7z"/>
<path d="M9088 6633 c-14 -3 -18 -15 -18 -54 0 -59 -10 -65 -117 -74 -114 -10
-113 -10 -113 -58 0 -24 5 -48 12 -55 19 -19 66 -14 143 14 113 41 110 41 131
-3 13 -25 27 -39 43 -41 35 -5 44 10 31 54 -6 22 -15 76 -20 121 -5 46 -12 87
-15 93 -6 10 -45 12 -77 3z"/>
<path d="M2826 6576 c-21 -72 -20 -93 5 -106 11 -7 37 -26 57 -44 21 -19 47
-36 60 -40 46 -15 19 -26 -61 -26 -96 0 -105 -6 -131 -83 -26 -77 -22 -94 22
-106 20 -6 53 -11 72 -11 19 0 74 -10 123 -22 87 -21 90 -21 103 -3 18 24 18
85 2 86 -44 2 -179 36 -179 44 1 6 47 12 107 15 90 4 108 8 120 24 28 39 18
63 -43 104 -64 44 -100 79 -90 89 4 3 39 0 78 -7 67 -13 72 -13 85 5 19 26 17
52 -4 64 -28 15 -251 71 -282 71 -26 0 -30 -5 -44 -54z"/>
<path d="M2632 6530 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M9402 6430 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2521 6414 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2535 6350 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2560 6331 c0 -6 4 -13 10 -16 6 -3 7 1 4 9 -7 18 -14 21 -14 7z"/>
<path d="M2535 6310 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M2533 6283 c-7 -2 -13 -13 -13 -24 0 -10 -6 -19 -12 -19 -10 0 -10
-2 0 -9 8 -4 14 -12 15 -16 2 -8 -42 -29 -55 -27 -13 3 -8 -188 7 -258 7 -36
13 -55 14 -42 1 13 5 20 11 17 6 -4 8 -15 5 -26 -5 -14 -3 -17 9 -13 14 5 16
30 16 159 0 85 4 156 8 159 8 4 17 107 10 105 -2 0 -9 -3 -15 -6z m-14 -125
c0 -27 -2 -29 -8 -13 -5 11 -6 26 -4 33 8 21 13 13 12 -20z"/>
<path d="M9001 6254 c-78 -14 -91 -23 -91 -64 0 -50 10 -54 102 -42 45 7 115
12 154 12 87 0 102 12 88 68 l-9 37 -85 2 c-47 0 -118 -5 -159 -13z"/>
<path d="M9483 6085 c0 -27 2 -38 4 -22 2 15 2 37 0 50 -2 12 -4 0 -4 -28z"/>
<path d="M9444 6010 c0 -52 1 -74 3 -47 2 26 2 68 0 95 -2 26 -3 4 -3 -48z"/>
<path d="M9480 5828 c0 -38 -3 -49 -10 -38 -7 10 -9 -14 -8 -75 0 -49 3 -79 5
-65 8 50 14 57 26 30 l11 -25 3 30 c2 17 8 47 15 67 8 28 8 48 -2 83 -18 64
-40 60 -40 -7z"/>
<path d="M2877 5810 c-15 -125 -64 -210 -121 -210 -20 0 -23 5 -23 39 0 21 -3
44 -7 50 -9 14 -96 74 -101 69 -3 -2 14 -30 35 -61 22 -32 40 -62 40 -67 0 -6
5 -10 10 -10 6 0 10 -11 10 -24 0 -26 -46 -82 -92 -111 -31 -19 -39 -49 -10
-41 9 2 54 12 99 21 98 19 109 15 144 -59 14 -28 32 -52 41 -54 14 -3 16 9 15
84 l-2 87 35 21 c19 11 59 25 89 31 101 21 73 44 -64 54 -65 5 -79 9 -75 21 2
8 6 59 8 113 3 79 1 97 -11 97 -10 0 -16 -16 -20 -50z"/>
<path d="M9060 5823 c0 -35 23 -95 42 -110 7 -6 25 -12 39 -15 22 -4 26 -1 23
17 -3 33 -18 55 -63 100 l-41 39 0 -31z"/>
<path d="M9283 5726 c-42 -41 -63 -51 -100 -47 -6 1 -14 -12 -18 -28 -12 -55
-63 -80 -118 -58 -50 21 -108 29 -142 18 l-30 -8 30 -12 c69 -27 131 -50 149
-56 19 -6 19 -18 -1 -147 -5 -34 -3 -38 15 -38 14 0 37 21 67 60 40 52 51 60
79 60 30 0 93 -16 139 -35 31 -13 19 21 -28 76 -63 74 -62 104 5 172 53 54 60
80 22 85 -16 3 -36 -10 -69 -42z"/>
<path d="M2900 5195 c-13 -15 -9 -120 6 -185 6 -27 -15 -58 -53 -78 -29 -16
-43 -15 -43 3 0 32 -23 63 -51 69 -52 10 -61 -3 -25 -35 19 -16 41 -29 50 -29
9 0 16 -7 16 -15 0 -8 -4 -15 -8 -15 -4 0 -13 -12 -19 -27 -7 -16 -26 -52 -42
-81 -17 -29 -31 -55 -31 -57 0 -12 31 -2 99 30 40 19 81 35 91 35 10 0 43 -22
73 -50 50 -46 77 -61 77 -43 0 4 -10 35 -21 69 -12 35 -19 74 -16 86 4 15 29
37 72 62 44 26 65 44 65 57 0 22 -30 25 -125 9 -66 -11 -93 0 -60 24 17 13 18
18 8 48 -7 18 -15 52 -19 75 -8 51 -26 70 -44 48z"/>
<path d="M9040 5140 c0 -39 23 -104 40 -115 4 -3 19 1 33 8 l24 13 -36 63
c-45 80 -61 88 -61 31z"/>
<path d="M9248 5047 c-31 -7 -48 -16 -51 -29 -3 -11 -14 -18 -30 -18 -30 0
-87 -19 -87 -30 0 -4 -14 -13 -30 -20 -29 -12 -34 -10 -70 19 -32 26 -48 31
-90 31 -44 0 -51 -3 -48 -17 4 -23 103 -72 146 -73 22 0 32 -5 32 -15 0 -8 -4
-15 -10 -15 -5 0 -10 -7 -10 -15 0 -8 -4 -15 -9 -15 -8 0 -51 -107 -51 -129 0
-23 30 -10 88 39 33 28 63 50 66 50 4 0 43 -16 87 -36 43 -21 82 -35 85 -32 3
3 -11 38 -30 77 -20 39 -36 77 -36 85 0 8 29 41 65 72 91 80 88 94 -17 71z"/>
<path d="M3057 4548 c3 -27 1 -29 -6 -13 -9 18 -10 17 -10 -7 -1 -16 3 -28 8
-28 5 0 12 -15 15 -34 7 -37 50 -91 66 -81 14 9 12 42 -5 99 -18 59 -41 96
-58 96 -9 0 -12 -11 -10 -32z"/>
<path d="M8890 4549 c0 -11 -7 -28 -16 -36 -11 -12 -12 -18 -4 -23 9 -6 9 -9
-1 -13 -10 -4 -10 -6 -1 -6 14 -1 17 -31 2 -31 -15 0 -12 -17 7 -40 22 -25 35
-25 57 -1 14 16 16 30 10 82 -3 35 -11 70 -17 77 -15 19 -37 14 -37 -9z"/>
<path d="M3279 4419 c-60 -14 -142 -55 -176 -88 -40 -39 -62 -39 -98 -1 -16
17 -35 30 -42 30 -7 0 -13 5 -13 10 0 6 -18 10 -40 10 -22 0 -40 -4 -40 -10 0
-5 -8 -6 -17 -3 -16 6 -16 5 -4 -10 15 -19 139 -87 158 -87 7 0 13 -11 13 -26
0 -14 -4 -23 -10 -19 -5 3 -10 -1 -10 -9 0 -9 -4 -16 -8 -16 -9 0 -42 -92 -42
-116 0 -29 36 -12 91 42 53 52 60 56 91 50 18 -4 58 -22 88 -41 35 -22 56 -30
58 -22 1 7 -7 39 -18 71 -31 91 -28 109 30 170 69 73 67 84 -11 65z"/>
<path d="M8655 4402 c-2 -3 19 -26 47 -53 53 -53 82 -58 86 -16 2 33 -37 64
-87 69 -23 2 -44 2 -46 0z"/>
<path d="M8985 4366 c-19 -8 -36 -10 -47 -5 -25 13 -46 1 -64 -37 -9 -18 -26
-36 -37 -40 -12 -4 -28 -16 -36 -26 -7 -11 -19 -17 -27 -14 -16 6 -44 -32 -67
-92 -21 -53 -21 -52 2 -52 17 0 52 20 120 71 26 19 34 20 59 10 16 -7 45 -32
66 -57 36 -43 66 -57 66 -30 0 7 -7 46 -16 86 -20 85 -19 86 78 134 84 41 84
60 -2 63 -38 2 -78 -3 -95 -11z"/>
<path d="M9267 4357 c-19 -24 -22 -43 -7 -52 6 -3 10 1 10 9 0 9 4 16 9 16 12
0 21 25 12 38 -4 7 -13 3 -24 -11z"/>
<path d="M9240 4270 c0 -5 7 -7 15 -4 8 4 15 8 15 10 0 2 -7 4 -15 4 -8 0 -15
-4 -15 -10z"/>
<path d="M9271 4214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M9173 4123 c-7 -3 -13 -20 -13 -39 0 -29 2 -33 14 -22 9 8 10 15 4
19 -6 4 -6 8 2 11 7 2 11 11 9 19 -2 9 -10 14 -16 12z"/>
<path d="M9121 3984 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M8623 3982 c-7 -4 -17 -32 -23 -62 -6 -30 -16 -65 -22 -77 -6 -11 -7
-26 -4 -32 4 -6 2 -11 -3 -11 -6 0 -11 -7 -11 -15 0 -16 -33 -52 -73 -77 -25
-17 -27 -17 -36 0 -10 16 -10 16 -11 -1 0 -11 -12 -22 -30 -28 -27 -10 -90
-86 -90 -109 0 -5 37 2 83 14 45 13 87 25 92 26 28 7 43 -5 74 -57 57 -96 71
-94 71 13 0 60 4 78 19 93 20 20 108 51 143 51 40 0 12 33 -37 44 -16 4 -48
11 -70 17 -22 6 -50 10 -62 10 -14 -1 -23 5 -23 14 0 8 8 15 18 15 17 0 18 8
15 83 -5 100 -5 98 -20 89z"/>
<path d="M3346 3970 c-4 -8 0 -35 7 -59 15 -53 58 -91 84 -75 28 17 24 27 -32
89 -39 44 -55 56 -59 45z"/>
<path d="M3561 3875 c-35 -19 -67 -32 -71 -30 -5 3 -11 -7 -15 -22 -7 -30 -80
-103 -102 -103 -8 0 -24 5 -35 11 -20 11 -35 12 -137 10 -37 -1 -42 -3 -31
-16 7 -8 20 -15 29 -15 9 0 49 -8 89 -17 70 -17 73 -19 67 -43 -4 -14 -9 -55
-12 -92 -4 -57 -3 -68 10 -68 9 0 28 17 43 38 37 50 75 82 99 82 11 0 50 -10
87 -21 38 -12 71 -19 74 -16 4 3 -13 28 -36 54 -71 80 -72 101 -8 195 39 58
48 88 26 88 -7 0 -42 -16 -77 -35z"/>
<path d="M8370 3864 c0 -3 7 -12 15 -20 17 -18 20 -38 4 -28 -14 8 20 -59 35
-68 19 -13 21 -9 23 42 1 41 -2 50 -18 55 -10 4 -19 11 -19 16 0 5 -9 9 -20 9
-11 0 -20 -3 -20 -6z"/>
<path d="M8848 3553 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M8223 3468 c-5 -7 -19 -31 -29 -53 -39 -79 -77 -139 -98 -157 -35
-29 -157 -88 -181 -88 -23 0 -37 -12 -48 -43 -6 -16 1 -17 84 -16 65 2 92 6
96 16 10 26 33 14 33 -17 0 -47 26 -100 49 -100 15 0 24 13 36 53 10 28 21 56
25 60 4 4 34 14 66 22 33 7 72 19 88 25 27 10 28 11 11 30 -9 10 -41 26 -71
36 -30 9 -54 20 -54 25 0 4 -10 10 -22 12 -22 4 -22 5 -2 11 35 12 74 196 40
196 -7 0 -18 -6 -23 -12z"/>
<path d="M8753 3445 c-18 -19 -33 -40 -33 -48 0 -7 -10 -19 -22 -27 -27 -19
-108 -103 -108 -113 0 -4 -24 -31 -52 -61 -29 -30 -64 -68 -78 -85 -14 -17
-30 -31 -37 -31 -6 0 -18 -8 -25 -19 -7 -10 -17 -18 -23 -17 -5 0 -17 -14 -25
-32 -9 -18 -18 -31 -21 -28 -3 3 -24 -13 -48 -34 -24 -22 -49 -40 -55 -40 -6
0 -22 -12 -36 -27 -14 -14 -37 -33 -51 -42 -14 -9 -27 -20 -28 -26 0 -5 -6
-10 -12 -10 -5 0 -18 -9 -27 -21 -18 -22 -87 -69 -132 -90 -8 -4 -27 -20 -42
-35 -14 -15 -29 -25 -32 -21 -3 3 -14 -2 -23 -11 -10 -8 -30 -19 -45 -22 -32
-8 -118 -89 -118 -110 0 -21 -30 -18 -31 3 -1 11 -4 9 -9 -8 -4 -14 -8 -19 -9
-12 -1 10 -10 12 -32 7 -39 -9 -62 -13 -71 -14 -5 -1 -8 -8 -8 -16 0 -14 -12
-19 -32 -16 -5 0 -8 -6 -8 -15 0 -11 -6 -15 -20 -11 -12 3 -20 0 -20 -8 0 -8
-11 -12 -30 -11 -16 1 -27 0 -25 -3 8 -7 -78 -42 -92 -37 -7 3 -12 0 -10 -7 1
-7 -8 -12 -20 -11 -13 0 -23 -3 -23 -8 0 -4 -9 -8 -20 -8 -11 0 -20 -5 -20
-11 0 -7 -12 -10 -30 -7 -16 3 -30 1 -30 -4 0 -4 -3 -7 -7 -7 -5 1 -10 0 -13
-1 -3 -1 -18 -4 -35 -6 -16 -2 -39 -5 -50 -8 -11 -2 -23 -5 -27 -5 -5 -1 -8
-9 -8 -19 0 -14 -7 -18 -30 -16 -32 2 -37 -5 -18 -24 6 -6 9 -14 6 -17 -3 -3
-12 1 -21 9 -12 13 -18 13 -33 2 -17 -13 -17 -14 2 -24 10 -5 34 -13 52 -17
25 -5 32 -3 30 7 -2 9 7 12 30 10 23 -2 32 1 30 10 -2 9 7 12 30 10 21 -1 31
2 27 8 -6 10 9 13 61 10 21 -1 33 4 38 15 4 11 12 14 26 10 11 -4 20 -2 20 4
0 6 9 11 20 11 11 0 23 6 26 14 3 8 13 12 25 9 10 -3 19 0 19 6 0 6 12 11 26
11 14 0 23 4 19 10 -4 6 7 10 25 10 18 0 29 4 25 10 -4 6 7 10 24 10 17 0 31
5 31 10 0 6 7 10 15 10 20 0 105 42 105 52 0 4 9 8 21 8 11 0 17 5 14 10 -4 6
5 10 19 10 16 0 25 5 23 13 -1 7 7 11 21 10 13 -2 21 1 17 7 -3 6 1 10 10 10
15 0 137 78 143 92 2 5 13 8 25 8 16 0 18 3 8 9 -18 11 1 23 26 15 13 -4 15
-3 4 4 -9 7 -10 12 -2 16 6 4 9 12 6 17 -3 5 1 6 9 3 10 -4 16 -1 16 9 0 10 6
13 21 10 16 -4 17 -8 7 -14 -7 -5 -8 -9 -3 -9 17 1 42 24 38 37 -3 7 4 10 19
6 17 -3 19 -2 9 5 -16 12 1 25 22 16 12 -4 14 0 10 14 -3 12 2 22 12 26 13 5
16 2 11 -10 -4 -11 3 -8 23 8 18 15 24 26 17 30 -6 4 0 8 14 8 18 0 21 3 11 9
-11 7 -8 12 11 25 14 9 32 14 40 11 8 -3 21 2 29 12 13 17 13 17 -4 4 -15 -12
-20 -12 -30 -1 -7 7 -7 10 -1 7 12 -7 46 28 37 37 -4 3 -1 6 6 6 16 0 42 26
34 34 -4 3 -1 6 6 6 7 0 34 23 61 50 27 28 57 50 67 50 12 0 14 3 6 8 -9 6 -7
12 7 22 11 8 20 21 20 29 0 8 3 12 6 8 7 -7 114 99 114 113 0 6 3 9 8 7 8 -5
26 12 31 28 7 24 48 65 66 65 13 0 16 3 8 8 -9 6 -7 12 7 22 11 8 20 21 20 28
0 8 12 26 28 40 15 15 28 37 30 50 4 28 4 28 -35 -13z m-996 -951 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M3753 3453 c-7 -2 -13 -12 -13 -20 0 -20 68 -85 81 -77 5 3 7 10 4
15 -4 5 -2 9 4 9 5 0 12 -6 14 -12 3 -7 6 -8 6 -2 1 12 -67 94 -77 93 -4 0
-13 -3 -19 -6z"/>
<path d="M7951 3457 c2 -1 13 -9 24 -17 19 -14 19 -14 6 3 -7 9 -18 17 -24 17
-6 0 -8 -1 -6 -3z"/>
<path d="M3997 3411 c-19 -21 -42 -55 -52 -74 -15 -32 -80 -83 -130 -101 -11
-5 -47 -10 -80 -12 -103 -7 -125 -13 -125 -34 0 -11 1 -20 3 -20 1 0 40 -5 86
-10 64 -9 86 -8 98 2 38 31 60 -5 32 -50 -15 -24 -3 -132 15 -132 18 0 40 27
70 88 l26 52 90 0 c50 0 90 3 90 8 0 4 -27 31 -61 61 -69 62 -70 69 -17 178
44 90 19 114 -45 44z"/>
<path d="M7940 3423 c0 -23 42 -67 66 -70 25 -4 26 -3 13 14 -26 33 -79 71
-79 56z"/>
<path d="M8017 3390 c34 -32 67 -39 76 -16 4 10 1 13 -9 9 -9 -3 -28 4 -44 16
-43 32 -60 25 -23 -9z"/>
<path d="M7947 3339 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M7428 3092 c-16 -9 -28 -20 -28 -25 0 -4 5 -5 10 -2 6 3 10 1 10 -4
0 -6 -6 -11 -12 -11 -10 0 -10 -2 -1 -8 6 -4 16 -34 22 -67 l10 -60 -5 78 c-5
77 -5 78 18 75 13 -2 22 -9 20 -16 -1 -8 2 -11 6 -8 5 3 18 -4 28 -17 19 -22
19 -22 -1 -5 -26 22 -57 9 -34 -14 21 -21 57 -28 69 -13 8 9 3 20 -17 43 -16
16 -33 38 -39 48 -15 25 -25 26 -56 6z"/>
<path d="M4517 3052 c-15 -15 -40 -56 -57 -90 -33 -69 -75 -114 -135 -145 -22
-11 -42 -20 -45 -20 -3 0 -15 -3 -28 -8 -13 -5 -34 -9 -46 -9 -13 0 -29 -7
-36 -15 -23 -28 27 -31 185 -12 16 2 21 -6 25 -38 8 -54 42 -135 57 -135 13 0
37 73 48 147 8 49 17 53 123 53 41 0 72 4 72 10 0 6 -6 10 -13 10 -7 0 -23 9
-36 21 -12 11 -40 31 -62 44 -24 13 -36 25 -30 29 6 4 8 18 5 30 -3 13 -1 50
5 82 6 32 7 62 3 66 -4 4 -20 -4 -35 -20z"/>
<path d="M7726 3040 c-21 -15 -44 -40 -51 -57 -34 -80 -161 -157 -285 -172
-68 -9 -89 -16 -79 -25 2 -3 40 -6 84 -7 97 -3 118 -7 111 -25 -3 -7 -1 -16 4
-19 6 -4 10 -28 10 -54 0 -31 5 -53 15 -61 8 -7 12 -17 9 -24 -13 -27 59 63
79 99 l23 40 87 -3 c74 -2 87 0 87 13 0 8 -10 20 -22 25 -37 15 -126 99 -105
100 10 0 17 8 17 19 0 10 16 53 35 94 19 42 31 78 27 80 -4 2 -24 -8 -46 -23z"/>
<path d="M4260 3016 c0 -2 7 -9 15 -16 9 -7 15 -8 15 -2 0 5 -7 12 -15 16 -8
3 -15 4 -15 2z"/>
<path d="M6884 2840 c3 -21 0 -30 -10 -30 -17 0 -17 -2 -2 -34 6 -14 19 -28
29 -31 10 -3 19 -18 21 -32 6 -47 29 -50 90 -10 29 20 69 43 88 50 33 14 33
15 -30 11 -48 -2 -68 -7 -77 -21 -12 -15 -13 -13 -9 20 3 23 1 37 -6 37 -6 0
-8 8 -5 19 8 31 -10 48 -53 49 -40 2 -41 1 -36 -28z"/>
<path d="M8198 2803 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5506 2762 c-3 -5 1 -9 9 -9 8 0 15 4 15 9 0 4 -4 8 -9 8 -6 0 -12
-4 -15 -8z"/>
<path d="M6843 2730 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M7170 2757 c-67 -27 -90 -39 -90 -46 0 -5 -17 -18 -37 -28 -22 -11
-39 -29 -41 -40 -2 -11 -14 -25 -26 -31 -27 -12 -56 -64 -56 -99 0 -14 -14
-44 -30 -68 -26 -37 -30 -52 -27 -90 2 -25 7 -48 10 -51 11 -11 58 30 87 77
17 28 36 52 44 55 7 3 40 -2 72 -10 38 -10 62 -12 68 -6 7 7 6 10 -1 10 -5 0
-10 13 -9 29 1 27 2 28 19 14 30 -28 20 6 -18 57 -19 26 -35 51 -35 54 0 4 22
31 49 59 28 31 51 65 54 84 7 38 1 44 -33 30z"/>
<path d="M4945 2720 c-16 -7 -17 -9 -3 -9 9 -1 20 4 23 9 7 11 7 11 -20 0z"/>
<path d="M6743 2643 c-13 -2 -23 -8 -23 -13 0 -10 38 -1 46 11 3 5 4 8 2 8 -2
-1 -13 -4 -25 -6z"/>
<path d="M6750 2590 c11 -7 5 -10 -22 -10 -21 0 -38 -4 -38 -9 0 -8 34 -18
128 -37 12 -2 22 -12 22 -21 0 -10 9 0 20 22 11 22 20 41 20 42 0 2 -25 3 -55
3 -30 0 -55 5 -55 10 0 6 -8 10 -17 10 -15 0 -16 -2 -3 -10z"/>
<path d="M6480 2580 c-11 -7 -6 -10 20 -10 26 0 31 3 20 10 -8 5 -17 10 -20
10 -3 0 -12 -5 -20 -10z"/>
<path d="M5840 2536 c-1 -16 -92 -56 -128 -56 -18 0 -36 -7 -44 -18 -7 -9 -26
-25 -41 -35 -15 -10 -32 -28 -36 -40 -12 -30 -36 -49 -64 -51 -24 -1 -56 -54
-57 -93 0 -35 29 -29 91 18 42 31 60 39 79 34 14 -3 33 -7 43 -9 10 -1 25 -11
35 -21 16 -18 15 -18 -8 0 l-25 20 24 -28 c24 -27 38 -36 26 -17 -3 6 -2 10 4
10 5 0 14 -8 20 -17 8 -15 12 -16 23 -5 9 9 12 37 11 88 l-3 74 67 33 c52 24
69 39 76 60 12 36 -4 57 -28 37 -14 -12 -18 -11 -26 1 -5 8 -9 17 -9 20 0 11
-30 6 -30 -5z"/>
<path d="M6063 2502 c-47 -7 -14 -41 64 -66 43 -14 52 -22 55 -44 l4 -27 14
28 c7 15 19 27 27 27 23 0 -4 39 -35 52 -15 6 -36 14 -47 18 -30 12 -57 16
-82 12z"/>
<path d="M6898 2148 c5 -5 16 -8 23 -6 8 3 3 7 -10 11 -17 4 -21 3 -13 -5z"/>
<path d="M5255 2140 c17 -7 17 -9 3 -9 -22 -1 -24 -28 -3 -28 8 0 15 3 15 6 0
3 20 5 45 3 25 -2 45 -8 45 -13 0 -6 7 -6 18 -1 9 5 25 8 35 7 9 -2 17 1 17 7
0 24 -30 33 -110 35 -55 1 -78 -1 -65 -7z"/>
<path d="M2237 7649 c-27 -16 -45 -66 -53 -143 -8 -75 -2 -80 56 -45 69 42
120 90 120 113 0 27 -45 72 -80 80 -14 3 -33 1 -43 -5z"/>
<path d="M9673 7648 c-12 -6 -27 -23 -33 -38 -21 -51 -10 -71 70 -127 41 -29
81 -52 88 -53 18 0 15 39 -9 115 -20 66 -55 116 -81 114 -7 0 -23 -5 -35 -11z"/>
<path d="M1588 8088 c-3 -28 -10 -36 -53 -58 -35 -18 -65 -45 -102 -94 -53
-68 -62 -96 -44 -141 7 -16 13 -5 30 52 25 84 46 103 109 103 101 0 142 65 88
139 l-25 33 -3 -34z"/>
</g>
</svg>
    </>
  )
}
