import React from 'react'
import Inputform from '../Bitcoin Mixer/Bitcoin/Form' 

function Select() {
  return (
    <>
    <Inputform/>
    </>
  )
}

export default Select
