import React from 'react'

export default function Gru() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 800.000000 861.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,861.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M3930 8490 c0 -13 -5 -18 -15 -14 -11 5 -15 -1 -15 -25 0 -21 5 -31
15 -31 19 0 19 -15 -1 -45 -10 -15 -14 -34 -10 -49 4 -16 1 -26 -8 -29 -9 -4
-17 4 -21 21 -12 47 -19 50 -123 45 -110 -6 -125 -12 -152 -56 -21 -34 -27
-102 -10 -112 6 -3 10 -15 10 -25 0 -10 7 -25 15 -34 8 -8 15 -19 15 -25 0 -5
8 -14 17 -19 12 -7 17 -25 18 -68 2 -99 21 -126 100 -139 73 -12 75 -14 75
-51 0 -46 -21 -174 -29 -174 -3 0 -29 22 -56 48 -28 26 -65 54 -82 61 -22 9
-29 18 -26 30 4 15 -6 20 -59 30 -35 7 -98 23 -141 37 -98 30 -122 30 -159 -1
-16 -14 -36 -25 -43 -25 -28 0 -45 -31 -45 -82 l0 -51 23 21 23 21 34 -21 35
-22 -40 -11 -40 -12 35 -18 34 -18 -71 -23 c-40 -13 -102 -37 -139 -54 -37
-16 -69 -30 -71 -30 -2 0 7 15 21 33 33 42 32 47 -1 47 -58 0 -58 16 -2 53 30
20 66 48 81 64 43 45 97 143 119 213 17 57 41 240 31 240 -2 0 -22 -15 -45
-34 -61 -50 -188 -122 -283 -162 -45 -19 -85 -34 -88 -34 -3 0 -1 12 4 26 16
41 13 43 -37 28 -137 -41 -276 -132 -373 -244 l-58 -68 -7 45 -7 46 -50 -49
c-89 -87 -182 -245 -253 -433 -27 -71 -28 -73 -40 -50 -7 13 -18 43 -25 66 -7
23 -17 40 -21 37 -16 -9 -87 -250 -104 -349 -9 -55 -17 -159 -18 -232 l-1
-133 -52 0 -52 0 35 70 c85 173 83 400 -6 634 l-11 28 -51 -83 c-72 -120 -150
-219 -259 -329 l-95 -95 -5 54 -5 53 -88 -82 c-144 -135 -219 -258 -288 -470
l-37 -115 -27 58 c-15 32 -31 55 -35 50 -17 -19 -64 -154 -82 -238 -16 -70
-21 -147 -26 -348 l-7 -259 -64 66 c-35 37 -65 65 -67 64 -5 -6 15 -260 25
-313 l9 -50 -22 28 c-21 26 -103 87 -117 87 -4 0 6 -41 23 -91 16 -51 44 -183
63 -295 24 -143 44 -227 66 -283 20 -50 29 -87 24 -98 -10 -24 -26 23 -66 185
-36 149 -60 206 -102 251 -25 26 -102 71 -123 71 -2 0 12 -34 31 -76 35 -78
72 -189 120 -364 14 -52 43 -133 65 -178 28 -58 40 -97 39 -125 0 -40 -4 -35
-68 99 -93 191 -126 227 -239 249 l-32 7 56 -79 c31 -43 90 -148 131 -233 73
-151 135 -251 186 -299 l26 -23 -48 -60 c-82 -102 -160 -255 -206 -406 -7 -25
-2 -25 51 -5 7 3 5 -28 -7 -89 -10 -51 -23 -156 -29 -233 -14 -197 -16 -185
24 -146 l34 33 10 -61 c15 -82 46 -155 130 -308 62 -110 112 -226 112 -255 0
-4 -31 -5 -70 -2 -38 4 -70 4 -70 1 0 -4 24 -19 53 -33 79 -40 161 -99 251
-179 71 -64 84 -80 102 -133 l21 -61 -22 17 c-176 139 -224 162 -346 171 -49
4 -89 5 -89 4 0 -1 37 -23 83 -48 107 -61 156 -99 307 -243 129 -121 218 -189
294 -223 l43 -19 0 -56 c0 -40 6 -63 20 -82 71 -96 203 -96 264 0 16 26 19 45
16 91 -3 49 -8 62 -37 92 -40 42 -84 56 -148 48 -45 -5 -51 -2 -156 68 -121
82 -137 99 -117 123 13 15 16 15 40 -1 27 -19 183 -102 238 -128 41 -19 90
-19 127 0 26 14 31 14 46 0 10 -9 19 -36 22 -64 8 -87 81 -148 165 -138 83 9
134 66 141 157 2 33 8 60 11 60 4 0 18 -11 31 -25 13 -14 41 -30 62 -37 49
-14 48 -24 -1 -32 -98 -15 -160 -131 -116 -216 20 -39 5 -39 -23 0 -34 45 -76
63 -139 58 -44 -4 -60 -11 -88 -38 -41 -40 -59 -89 -51 -135 6 -32 4 -34 -60
-75 -58 -36 -68 -40 -82 -27 -266 223 -322 266 -402 310 -135 74 -225 92 -383
75 -59 -6 -105 -13 -104 -15 2 -2 59 -19 125 -38 144 -41 245 -87 355 -162 81
-55 348 -265 343 -270 -6 -6 -146 47 -214 81 -40 20 -122 67 -183 105 -132 83
-181 97 -264 76 -95 -25 -96 -30 -15 -56 40 -12 112 -47 159 -77 47 -30 122
-78 166 -105 81 -52 218 -117 245 -117 36 -1 42 -15 24 -58 -9 -23 -20 -42
-26 -42 -18 0 -74 33 -155 92 -126 90 -188 115 -339 137 -71 10 -130 18 -132
17 -1 -2 60 -36 135 -77 76 -41 172 -102 214 -136 88 -71 178 -130 220 -145
27 -9 29 -13 23 -42 -3 -17 -7 -32 -9 -34 -1 -1 -33 19 -71 44 -131 89 -239
126 -399 139 l-80 6 114 -56 c122 -59 278 -158 350 -222 l44 -39 -26 -49 c-14
-28 -46 -86 -72 -130 l-46 -80 94 95 c51 52 100 105 109 117 15 22 15 23 92 8
43 -8 96 -15 119 -15 67 0 71 -8 43 -76 -26 -65 -67 -204 -60 -204 3 0 26 11
52 24 63 32 123 98 167 185 l35 71 48 -6 c73 -9 73 -9 60 -46 -6 -18 -17 -42
-24 -53 -38 -62 -23 -230 31 -341 114 -233 384 -290 742 -156 40 14 73 30 73
33 0 4 -21 10 -46 14 -81 12 -98 28 -44 39 97 19 270 114 351 192 l45 45 58
-121 c45 -95 55 -124 46 -135 -9 -11 -1 -15 45 -20 105 -14 273 27 354 84 16
12 34 21 40 21 17 0 82 -42 201 -130 122 -90 167 -117 176 -107 14 14 -89 148
-183 237 -52 50 -93 93 -90 96 2 2 54 -29 115 -70 116 -77 152 -92 201 -81 16
3 80 39 142 80 63 40 116 72 118 69 2 -2 -34 -39 -81 -83 -102 -95 -217 -241
-190 -241 5 0 65 41 133 91 68 50 144 103 168 117 l43 25 67 -36 c106 -57 235
-85 329 -72 44 6 49 9 45 29 -3 14 15 62 47 127 29 57 54 106 56 108 2 2 24
-15 50 -38 94 -82 193 -138 317 -177 31 -10 57 -21 57 -25 0 -10 -66 -29 -97
-29 -45 0 -21 -16 70 -48 245 -85 434 -87 573 -6 53 31 126 108 153 163 54
104 65 263 27 386 -8 27 -14 50 -13 51 1 1 27 4 59 8 l56 6 42 -78 c49 -92 98
-145 162 -178 26 -13 49 -24 52 -24 7 0 -34 139 -60 204 -13 33 -24 63 -24 68
0 4 22 8 49 8 27 0 86 7 131 16 l82 15 16 -23 c8 -13 58 -66 109 -118 l94 -95
-46 80 c-26 44 -58 102 -72 130 l-26 49 44 39 c71 64 185 137 324 210 l130 68
-85 -7 c-148 -11 -266 -54 -395 -143 l-60 -41 -8 31 c-5 17 -7 32 -5 34 2 1
31 17 65 34 34 17 104 64 155 106 54 43 152 107 235 152 78 42 141 78 140 79
-1 2 -52 -4 -112 -12 -157 -22 -220 -47 -368 -147 -70 -48 -133 -87 -141 -87
-11 0 -46 64 -46 84 0 2 35 16 78 31 89 31 146 62 292 158 127 84 178 110 254
132 67 20 63 25 -30 51 -56 15 -66 15 -116 0 -29 -8 -105 -48 -168 -87 -127
-80 -246 -139 -330 -163 -66 -20 -70 -24 105 114 239 190 364 259 556 310 61
16 113 31 116 33 2 3 -47 10 -109 17 -233 25 -341 -21 -645 -274 l-152 -126
-65 41 c-66 41 -66 41 -60 79 8 51 -25 122 -70 150 -67 41 -170 19 -212 -45
-25 -38 -33 -30 -16 18 22 61 10 112 -37 159 -27 26 -50 39 -79 44 -52 8 -53
20 -5 33 20 6 49 22 64 37 32 31 35 28 42 -50 5 -51 26 -87 71 -120 38 -29
124 -29 163 0 43 32 73 82 73 123 0 20 7 44 15 55 13 18 16 18 49 2 19 -9 48
-16 64 -16 31 0 205 82 280 131 51 34 54 35 70 9 11 -18 5 -25 -70 -81 -120
-89 -169 -112 -223 -103 -57 8 -125 -23 -155 -70 -27 -44 -27 -120 0 -164 82
-134 285 -70 283 90 l-1 49 62 31 c95 48 138 81 274 211 143 136 214 191 320
249 40 22 72 42 70 43 -2 1 -41 -1 -88 -4 -106 -9 -165 -35 -282 -124 l-87
-66 21 57 c32 89 168 212 358 322 l55 31 -72 -5 c-40 -3 -73 -1 -73 3 0 30 53
149 120 271 76 136 110 223 125 314 l6 39 35 -34 c40 -39 38 -51 24 142 -9
119 -30 266 -46 322 -6 18 -4 18 26 2 18 -9 34 -15 36 -12 7 7 -55 175 -89
242 -32 65 -89 150 -135 204 l-29 35 59 70 c43 51 83 119 148 251 49 100 110
210 135 244 26 35 44 65 41 68 -2 3 -29 -2 -59 -11 -71 -21 -121 -75 -173
-186 -21 -46 -51 -109 -66 -140 l-26 -55 -1 41 c-1 28 12 68 39 127 22 47 60
159 85 249 43 159 76 252 117 331 l19 39 -23 -7 c-103 -31 -150 -97 -198 -279
-51 -196 -60 -226 -70 -226 -16 0 -12 38 9 78 29 58 48 133 80 327 16 99 43
222 59 273 16 50 28 92 25 92 -16 0 -97 -57 -117 -83 l-24 -30 9 47 c10 54 29
309 23 315 -2 2 -32 -27 -68 -65 l-64 -69 -5 260 c-4 207 -9 278 -25 350 -19
86 -65 220 -83 239 -5 6 -20 -15 -35 -50 l-26 -59 -38 115 c-20 63 -48 138
-60 165 -43 97 -129 212 -223 300 l-92 87 -3 -51 -3 -51 -54 47 c-101 88 -238
258 -314 388 l-40 68 -11 -29 c-52 -136 -64 -198 -64 -344 0 -156 6 -186 64
-301 l28 -56 -53 -2 -53 -2 0 134 c0 76 -8 176 -17 233 -16 96 -88 339 -103
348 -4 2 -18 -25 -31 -62 l-24 -66 -13 29 c-7 17 -32 75 -54 130 -60 142 -124
248 -199 328 -69 73 -79 74 -80 4 -1 -26 -5 -23 -61 42 -110 128 -254 219
-402 255 l-27 6 15 -36 c15 -35 15 -36 -8 -29 -60 18 -251 118 -322 169 -43
31 -79 56 -81 54 -6 -8 17 -180 31 -228 35 -121 116 -233 213 -295 47 -29 43
-45 -11 -45 -35 0 -35 -4 -1 -48 15 -20 20 -33 12 -29 -8 3 -50 22 -94 41 -44
19 -101 41 -127 48 -27 6 -48 14 -48 18 0 3 15 12 33 19 l32 13 -37 12 c-39
12 -39 22 1 44 20 12 26 11 47 -9 l24 -22 0 47 c0 52 -13 86 -34 86 -7 0 -30
11 -50 25 -46 31 -71 31 -166 1 -41 -13 -103 -30 -138 -36 -53 -11 -63 -16
-59 -31 3 -12 -4 -21 -26 -30 -17 -7 -54 -34 -82 -61 -27 -26 -53 -44 -57 -40
-7 8 -28 138 -28 175 0 23 6 26 65 36 90 16 115 27 115 50 0 11 6 21 13 23 8
3 12 26 12 67 1 49 5 65 18 72 9 6 17 15 17 21 0 6 6 19 13 27 7 9 20 36 27
59 12 37 12 50 -1 84 -22 65 -48 77 -166 83 l-102 5 -11 -35 c-14 -41 -44 -50
-34 -10 4 16 0 34 -10 50 -20 30 -20 45 -1 45 10 0 15 10 15 30 0 20 -5 30
-15 30 -8 0 -15 7 -15 15 0 10 -10 15 -30 15 -23 0 -30 -4 -30 -20z m-691
-424 c-28 -177 -120 -320 -248 -385 -22 -12 -41 -25 -41 -30 0 -11 31 -36 53
-44 24 -9 13 -28 -52 -92 -40 -38 -54 -46 -63 -37 -9 9 -3 21 30 55 23 23 42
47 42 52 -1 6 -19 22 -41 38 l-40 28 78 58 c135 100 204 191 248 326 14 44 28
86 31 94 11 33 12 0 3 -63z m1455 -29 c18 -50 49 -118 69 -149 44 -65 142
-160 210 -203 26 -17 47 -33 47 -36 0 -4 -18 -18 -40 -33 -22 -14 -40 -30 -40
-35 0 -4 23 -30 51 -56 61 -57 199 -124 333 -162 83 -23 95 -29 80 -40 -9 -7
-22 -9 -28 -5 -6 3 -49 14 -96 24 -126 26 -235 83 -331 176 l-79 75 40 18 c22
10 40 24 40 31 0 7 -21 24 -46 37 -102 54 -196 181 -227 306 -19 77 -31 162
-21 152 4 -4 21 -49 38 -100z m-1494 24 c-42 -139 -142 -268 -278 -360 -70
-47 -70 -52 -5 -92 l35 -22 -38 -38 c-20 -21 -41 -36 -45 -33 -5 2 -31 37 -60
77 l-51 72 6 -65 c4 -36 9 -83 12 -106 l6 -40 -84 -32 c-45 -17 -118 -41 -162
-54 l-79 -22 36 -32 35 -32 -63 -41 c-89 -58 -205 -169 -280 -270 -36 -47 -65
-89 -65 -93 0 -4 17 -8 37 -10 l36 -3 -86 -57 c-71 -48 -86 -54 -90 -40 -4 13
-5 12 -6 -5 -1 -19 -23 -41 -70 -68 -31 -18 9 303 56 449 25 77 27 79 37 54 5
-16 12 -28 15 -28 3 0 19 37 35 83 62 179 187 410 254 470 20 19 21 18 27 -30
4 -26 7 -51 8 -56 1 -4 22 19 45 52 92 126 230 240 340 281 69 26 74 26 61 -6
-5 -15 -7 -29 -5 -31 15 -15 250 88 346 151 30 19 56 36 57 36 0 0 -7 -26 -17
-59z m1650 -40 c52 -27 128 -60 169 -75 78 -28 79 -28 56 27 -11 27 10 21 110
-27 105 -51 205 -138 287 -248 l53 -71 10 59 10 59 36 -45 c100 -126 149 -221
264 -525 6 -18 9 -16 20 15 l12 35 26 -80 c29 -94 53 -232 63 -364 l7 -93 -40
26 c-36 24 -41 32 -51 90 -16 87 -57 206 -100 291 -19 38 -43 101 -54 140 -24
86 -93 264 -112 291 -8 10 -3 -12 11 -51 14 -38 41 -113 60 -165 19 -52 30
-90 26 -85 -5 6 -29 37 -54 69 -134 181 -363 369 -594 490 -77 41 -69 33 39
-34 209 -131 378 -274 508 -430 83 -100 195 -290 183 -310 -3 -5 5 -26 19 -47
24 -37 71 -184 64 -203 -2 -6 -43 17 -93 51 l-90 60 43 -3 c23 -2 42 -1 42 3
0 18 -114 164 -179 229 -75 74 -234 193 -245 182 -3 -3 29 -28 72 -56 90 -59
229 -195 290 -283 l43 -63 -43 0 c-24 0 -51 7 -62 15 -17 13 -14 14 29 17 l47
3 -113 100 c-63 54 -163 135 -223 179 l-109 80 39 17 c46 21 57 23 49 9 -11
-18 9 -10 38 15 15 13 27 26 27 28 0 3 -15 8 -34 11 -62 12 -220 68 -289 103
-64 32 -157 105 -157 124 0 4 18 20 40 36 22 15 40 31 40 34 0 3 -26 23 -57
44 -128 85 -222 197 -268 320 -35 91 -33 103 8 76 17 -12 75 -44 127 -70z
m-1464 -430 l-36 -49 80 20 c44 11 86 18 94 15 8 -3 24 -24 36 -46 l20 -40
-38 -6 c-31 -6 -131 -35 -179 -53 -8 -2 -13 4 -13 16 0 26 -44 78 -83 99 -18
9 -56 16 -86 16 l-55 0 50 19 c65 26 182 57 217 57 l29 1 -36 -49z m1284 6
l95 -31 -55 -6 c-30 -3 -69 -13 -87 -23 -38 -20 -73 -63 -73 -88 0 -23 -3 -23
-70 0 -30 11 -73 22 -95 26 -68 11 -68 11 -45 54 11 22 24 42 29 46 9 5 109
-14 165 -32 16 -4 14 1 -10 33 -52 66 -52 66 2 59 27 -4 92 -21 144 -38z
m-1191 20 c14 -17 4 -27 -26 -27 -21 0 -22 3 -13 20 13 23 23 25 39 7z m991
-17 c8 -15 6 -17 -13 -13 -51 11 -53 13 -33 28 21 16 31 13 46 -15z m-511
-144 c-1 -143 -1 -144 -25 -150 -21 -6 -22 -9 -13 -33 5 -14 8 -27 7 -28 -10
-8 -50 -26 -52 -23 -2 2 -9 12 -15 23 l-13 20 9 -20 c4 -11 8 -52 8 -92 0 -40
4 -66 8 -60 6 8 7 7 4 -3 -4 -17 -29 -21 -39 -5 -4 5 -8 1 -8 -11 0 -15 6 -20
23 -20 32 1 36 12 30 86 -5 64 -5 66 21 78 l26 12 -1 -196 c-1 -107 1 -197 5
-201 3 -3 12 8 19 26 7 17 17 28 22 24 4 -5 5 -2 1 5 -11 19 -24 14 -29 -10
-2 -13 -4 78 -4 202 -1 124 3 212 7 195 l7 -30 10 25 c15 35 34 49 85 64 34
10 48 10 66 0 15 -7 20 -14 12 -19 -7 -4 -8 -11 -2 -18 11 -14 22 -116 13
-118 -47 -9 -61 -7 -61 11 0 10 -4 22 -10 25 -5 3 -10 -1 -10 -9 0 -10 -9 -16
-26 -16 -15 0 -23 -4 -18 -11 3 -6 16 -8 28 -5 30 7 64 -39 47 -65 -7 -12 -7
-19 -1 -19 5 0 10 4 10 8 0 10 60 32 87 32 16 0 22 -8 27 -42 5 -29 3 -44 -6
-50 -7 -4 -9 -8 -4 -8 5 0 3 -6 -3 -14 -7 -8 -13 -28 -13 -45 -1 -17 -9 -38
-20 -48 -18 -16 -18 -15 -12 41 l7 58 -70 -5 c-39 -2 -75 -7 -81 -11 -6 -3
-12 -1 -14 5 -6 18 -48 -12 -48 -34 0 -17 1 -17 16 -3 18 19 38 20 65 5 16 -8
25 -7 42 8 14 12 18 13 12 3 -6 -11 -3 -10 12 3 26 22 32 22 26 -3 -3 -11 0
-20 6 -20 6 0 11 -4 11 -10 0 -5 -8 -10 -19 -10 -10 0 -25 -8 -32 -17 -11 -15
-11 -16 1 -5 23 20 41 15 35 -11 -4 -13 -7 -31 -9 -41 -4 -27 -37 -50 -48 -33
-6 9 -12 7 -23 -7 -24 -31 -18 -36 31 -29 38 5 46 3 42 -8 -3 -8 -13 -14 -24
-15 -10 0 -29 -6 -42 -13 -12 -6 -30 -10 -40 -7 -9 3 -24 1 -32 -4 -15 -9 -17
-8 36 -25 13 -4 15 -14 11 -47 -3 -23 -13 -47 -21 -54 -9 -6 -16 -16 -16 -23
0 -15 -8 -14 -29 7 -22 21 -55 82 -44 82 5 0 3 4 -4 8 -7 4 -10 17 -6 30 4 17
11 21 27 17 18 -5 17 -3 -4 9 -14 9 -40 15 -58 16 -18 0 -42 5 -53 10 -24 14
-52 67 -46 90 3 12 -4 22 -19 29 -28 12 -47 55 -38 83 5 17 3 18 -15 9 -20
-11 -21 -9 -21 44 0 93 41 142 84 100 13 -14 17 -9 32 47 16 54 19 60 25 38 6
-23 7 -23 8 -3 1 12 4 30 7 40 5 15 4 16 -6 3 -10 -13 -11 -12 -6 4 8 22 -3
86 -14 86 -4 0 -15 -18 -24 -40 -18 -46 -31 -53 -22 -12 l6 27 -18 -24 c-9
-14 -25 -34 -35 -45 -19 -22 -19 -16 0 31 5 12 3 14 -7 8 -15 -9 -15 -9 34 64
44 64 60 81 81 81 17 0 17 -2 -2 -46 -2 -6 7 -14 22 -18 20 -5 24 -11 21 -31
-4 -19 0 -25 14 -25 10 0 22 5 25 10 4 6 11 8 16 5 9 -6 14 29 11 78 -1 16 1
26 5 24 5 -3 7 30 5 74 -2 51 0 79 7 79 7 0 11 -48 10 -144z m-361 -14 l3 -32
-121 0 c-67 0 -119 4 -116 8 9 14 185 62 216 59 9 -2 16 -15 18 -35z m880 -4
c35 -11 62 -21 60 -23 -2 -1 -56 -5 -120 -8 l-118 -5 0 23 c0 48 7 53 63 43
29 -5 80 -18 115 -30z m-1690 -30 c-7 -17 -93 -48 -193 -68 l-80 -17 49 -25
48 -25 -80 -56 c-86 -60 -221 -171 -312 -254 l-55 -51 50 5 c48 5 49 5 33 -13
-11 -11 -34 -18 -63 -20 l-46 -1 17 26 c73 112 205 245 317 319 40 25 74 48
76 49 3 2 -11 16 -30 32 -25 22 -30 31 -19 34 8 3 54 17 102 31 47 14 106 35
130 45 43 19 65 14 56 -11z m734 -45 c15 -15 28 -37 28 -50 0 -23 -3 -24 -57
-22 -32 1 -84 -3 -116 -10 -87 -18 -123 2 -77 44 19 17 22 17 75 -5 31 -12 58
-20 61 -17 3 3 -12 11 -33 17 -66 22 -75 28 -63 50 9 17 21 20 82 20 64 0 75
-3 100 -27z m1038 6 c7 -11 10 -22 8 -23 -18 -16 -167 -61 -187 -58 -24 3 -29
10 -37 53 l-11 49 108 0 c100 0 109 -2 119 -21z m-1816 -30 c-10 -12 -31 -30
-46 -40 -22 -16 -31 -17 -52 -8 -14 7 -26 15 -26 19 0 4 24 12 53 19 28 6 59
16 67 21 23 15 25 12 4 -11z m2489 -16 l59 -17 -32 -16 -32 -17 43 -25 c81
-48 240 -176 266 -213 l26 -38 -24 6 c-13 4 -30 7 -36 7 -16 0 -146 117 -155
139 -3 10 -18 25 -33 35 -35 23 -176 168 -155 161 8 -4 41 -14 73 -22z m-628
-3 c10 -11 15 -26 11 -35 -5 -14 -17 -16 -84 -10 -42 4 -78 9 -80 10 -3 3 115
52 129 54 3 1 14 -8 24 -19z m-2793 -107 c28 -105 30 -282 4 -367 -24 -80 -66
-161 -115 -224 -23 -28 -41 -53 -41 -55 0 -12 64 -29 95 -25 19 2 35 3 35 3 0
-1 -11 -34 -24 -73 -69 -203 -200 -363 -396 -486 l-65 -41 60 -3 c33 -1 66 -5
72 -7 9 -3 -5 -32 -40 -87 -97 -150 -308 -560 -295 -573 2 -3 22 4 43 15 71
36 70 38 83 -137 18 -229 57 -396 138 -585 47 -111 17 -80 -45 45 -71 145
-118 314 -143 524 -6 54 -15 102 -19 106 -3 5 -25 -1 -47 -12 l-40 -20 19 87
c41 189 116 367 213 512 32 47 58 89 59 94 1 5 -22 16 -52 25 l-54 16 96 78
c183 149 307 299 348 421 20 58 21 57 -63 75 -32 7 -58 14 -58 16 0 1 24 42
54 89 126 201 163 353 143 601 -9 115 1 112 35 -12z m4316 39 c-17 -85 -11
-260 11 -345 23 -86 80 -214 132 -298 20 -29 39 -60 43 -67 8 -14 2 -17 -76
-32 l-56 -11 10 -37 c37 -136 187 -316 396 -474 28 -22 52 -42 52 -43 0 -2
-25 -11 -55 -20 -39 -11 -53 -20 -48 -28 4 -7 28 -43 54 -82 64 -95 132 -234
170 -345 30 -91 71 -260 63 -260 -3 0 -22 9 -44 20 -21 11 -41 17 -43 12 -3
-4 -8 -41 -11 -82 -19 -197 -79 -418 -152 -560 -61 -118 -94 -157 -50 -59 86
191 138 413 151 647 5 80 11 122 19 122 6 0 34 -11 63 -24 l51 -24 -35 76
c-66 146 -183 367 -252 477 l-69 110 70 9 70 8 -78 51 c-89 58 -207 167 -259
237 -44 60 -109 189 -129 258 l-15 52 48 0 c26 0 55 6 65 13 16 12 14 16 -22
62 -57 74 -78 112 -106 195 -46 137 -44 288 6 449 22 73 41 68 26 -7z m-4134
-68 c-36 -98 -65 -257 -71 -386 -3 -65 -10 -118 -14 -118 -15 0 -10 242 7 341
9 52 30 137 47 189 29 92 30 94 42 67 10 -25 8 -39 -11 -93z m3941 34 c15 -46
33 -112 41 -148 18 -80 29 -394 16 -470 -8 -48 -9 -37 -10 85 -2 196 -20 318
-68 469 -17 54 -24 91 -19 113 3 18 8 33 10 33 2 0 16 -37 30 -82z m-4170 -62
c14 -187 -45 -380 -174 -571 -22 -33 -37 -62 -33 -66 4 -3 35 -12 70 -19 73
-15 74 -19 33 -105 -57 -120 -173 -248 -355 -390 l-89 -69 59 -17 c32 -9 58
-21 57 -27 -2 -6 -26 -46 -54 -89 -98 -151 -169 -327 -209 -521 -10 -53 -18
-98 -15 -100 2 -2 23 8 48 22 l44 26 12 -92 c38 -302 120 -536 236 -678 67
-82 134 -186 174 -270 32 -67 36 -84 36 -155 0 -68 -4 -88 -29 -137 -16 -32
-32 -55 -36 -53 -4 2 -15 35 -25 72 -27 99 -49 147 -93 195 -105 118 -274 333
-324 414 -198 320 -339 730 -338 978 l1 61 28 -32 29 -31 -5 216 c-5 211 4
377 29 482 16 72 49 170 57 170 4 0 22 -30 40 -67 l33 -67 28 109 c49 195 119
343 217 456 47 55 115 119 126 119 3 0 7 -21 9 -47 l3 -48 135 135 c74 74 162
174 195 221 33 47 63 81 67 76 3 -6 9 -51 13 -101z m846 54 c-45 -41 -62 -63
-57 -75 3 -10 2 -14 -5 -10 -5 4 -36 -18 -68 -48 -36 -34 -72 -59 -96 -66 -21
-6 -40 -9 -42 -8 -16 17 144 156 287 250 51 33 43 14 -19 -43z m3702 -159 c64
-75 205 -211 227 -219 5 -2 10 18 12 45 l3 48 54 -50 c145 -135 248 -330 301
-570 l17 -80 36 75 35 75 17 -39 c56 -130 80 -338 72 -631 l-5 -210 29 35 29
34 0 -53 c0 -204 -92 -523 -228 -791 -92 -180 -159 -281 -326 -483 -157 -190
-177 -224 -207 -342 -6 -25 -15 -48 -20 -51 -5 -3 -23 24 -40 61 -28 60 -31
73 -27 138 4 56 13 89 41 147 47 98 103 186 156 247 120 135 203 359 246 658
9 66 18 121 20 123 2 2 22 -6 45 -18 23 -12 44 -20 46 -18 7 8 -48 233 -78
317 -38 108 -86 203 -155 310 -30 46 -52 85 -50 87 2 2 29 12 61 21 l57 18
-73 55 c-108 82 -271 242 -322 318 -26 37 -55 90 -66 119 l-20 52 38 10 c20 5
54 13 75 17 20 3 37 8 37 10 0 2 -23 38 -51 81 -118 182 -163 319 -163 498 0
66 3 132 7 148 7 27 8 26 54 -40 25 -38 78 -106 116 -152z m-3983 -35 c0 -2
-8 -10 -17 -17 -16 -13 -17 -12 -4 4 13 16 21 21 21 13z m3258 -21 c-3 -3 -9
2 -12 12 -6 14 -5 15 5 6 7 -7 10 -15 7 -18z m-3262 -50 c-11 -8 -31 -15 -45
-14 -26 1 -26 1 4 14 42 19 66 19 41 0z m3289 0 c29 -13 29 -13 5 -14 -14 0
-34 6 -44 14 -26 19 -2 19 39 0z m-2385 -140 c-14 -8 -29 -14 -35 -14 -5 0 1
6 15 14 14 8 30 14 35 14 6 0 -1 -6 -15 -14z m1407 -7 c-3 -8 -6 -5 -6 6 -1
11 2 17 5 13 3 -3 4 -12 1 -19z m-1184 -100 c23 -8 26 -14 20 -32 -12 -34 -6
-84 15 -113 49 -70 51 -68 -56 -70 -70 -2 -100 1 -111 11 -9 8 -27 16 -41 18
-24 4 -23 5 13 23 35 18 38 22 33 49 -4 16 -8 31 -10 33 -2 2 -26 -10 -54 -26
-27 -17 -54 -31 -59 -31 -6 0 -16 20 -23 45 -10 36 -10 48 0 60 7 9 11 16 9
18 -2 1 -13 7 -24 13 -33 18 -23 34 35 57 30 13 64 28 74 34 16 9 31 3 85 -35
36 -24 79 -49 94 -54z m1105 50 c-3 -7 -13 -15 -24 -17 -16 -3 -17 -1 -5 13
16 19 34 21 29 4z m235 -3 c2 -9 -51 -49 -76 -58 -16 -5 22 -36 95 -79 33 -19
59 -42 62 -55 3 -11 8 -27 11 -35 3 -8 -7 -21 -25 -32 -28 -16 -33 -16 -43 -3
-12 15 -161 42 -177 32 -5 -3 -16 10 -25 29 -16 33 -16 35 4 55 11 11 25 38
31 60 9 33 15 41 34 41 12 0 35 14 51 30 28 29 54 36 58 15z m-297 -37 c-9
-12 -16 -41 -16 -64 0 -29 -5 -44 -16 -48 -17 -6 -16 -50 1 -88 6 -16 1 -18
-39 -18 -25 0 -46 3 -46 8 0 4 12 25 27 47 27 40 35 93 20 125 -6 12 3 21 35
37 24 12 44 22 46 22 2 1 -4 -9 -12 -21z m-1483 -10 c8 -30 4 -34 -57 -57 -71
-27 -184 -41 -322 -41 l-115 0 26 -37 c14 -21 25 -41 25 -44 0 -4 -13 -9 -29
-12 -59 -12 -191 -69 -270 -116 -80 -49 -201 -140 -201 -153 0 -3 16 -10 35
-15 l34 -8 -59 -100 c-33 -54 -74 -135 -91 -180 -18 -44 -63 -124 -101 -178
-37 -54 -68 -101 -68 -105 0 -4 23 -15 50 -25 57 -20 59 -26 40 -147 -15 -94
-38 -157 -71 -194 -29 -33 -104 -74 -116 -63 -3 4 5 30 17 59 22 49 24 70 30
308 6 224 10 271 34 383 45 214 120 403 219 556 l41 64 11 -27 11 -27 32 45
c39 54 40 55 102 71 44 12 48 11 42 -4 -16 -43 -92 -153 -145 -210 -66 -71
-127 -162 -177 -261 -37 -73 -83 -191 -77 -197 2 -2 14 25 27 59 34 95 108
227 158 280 25 27 42 48 38 48 -18 0 78 101 162 170 111 93 178 132 191 111
10 -15 99 -10 176 11 22 5 87 11 145 13 75 2 121 8 160 22 73 27 87 27 93 1z
m1817 6 c0 -8 13 -28 29 -44 38 -38 26 -58 -20 -34 -19 9 -44 23 -56 31 l-22
15 27 24 c32 28 42 30 42 8z m-293 -26 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-2417 8 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10
11 6 0 10 -2 10 -4z m-105 -145 c-96 -143 -172 -326 -215 -515 -22 -99 -26
-55 -4 52 38 193 109 366 211 519 55 83 58 86 61 58 2 -23 -10 -50 -53 -114z
m3130 123 c55 0 118 -6 140 -12 69 -19 175 -27 175 -12 0 23 53 0 130 -57 87
-66 233 -209 225 -222 -3 -5 8 -20 24 -34 37 -31 92 -116 143 -221 44 -91 41
-67 -3 32 -39 86 -122 211 -200 302 -63 74 -129 167 -142 203 -5 14 -3 17 11
13 9 -3 35 -9 57 -15 32 -8 47 -21 75 -62 l35 -51 11 28 12 28 42 -64 c97
-150 170 -336 216 -554 23 -105 27 -156 33 -381 7 -255 7 -261 34 -319 l27
-58 -28 6 c-43 11 -104 58 -124 98 -26 50 -44 122 -53 207 l-7 71 51 23 c28
12 51 26 51 30 0 5 -31 52 -68 105 -37 53 -80 129 -95 167 -24 64 -74 159
-135 257 l-22 36 42 7 42 7 -35 30 c-123 104 -269 188 -399 229 -41 13 -77 24
-79 26 -2 1 8 20 22 42 l26 38 -162 5 c-198 6 -232 18 -211 73 4 11 12 14 23
9 9 -5 61 -9 116 -10z m-3225 -56 c0 -74 -5 -73 -14 5 -5 38 -4 57 3 57 7 0
11 -24 11 -62z m100 47 c-11 -13 -15 -13 -21 -3 -6 9 -9 7 -9 -8 0 -11 -11
-35 -24 -52 l-23 -32 -7 37 c-11 72 -11 73 45 73 45 0 50 -2 39 -15z m3874
-61 c100 -154 176 -342 210 -523 25 -129 18 -137 -13 -16 -52 201 -117 351
-212 492 -43 64 -63 123 -41 123 4 0 29 -34 56 -76z m75 44 c-1 -18 -4 -43 -8
-56 -6 -24 -6 -24 -29 7 -12 17 -22 37 -22 46 0 9 -4 14 -9 11 -5 -3 -13 0
-19 7 -9 10 0 13 39 15 l49 2 -1 -32z m-2935 -4 c4 -11 -6 -20 -38 -34 -83
-37 -174 -52 -298 -48 l-117 3 36 -37 36 -37 -29 -7 c-53 -12 -277 -104 -389
-158 l-110 -53 48 -13 c26 -7 47 -14 47 -17 0 -5 -209 -305 -222 -318 -16 -16
81 174 123 241 21 33 39 64 39 68 0 3 -11 6 -24 6 -41 0 -39 17 6 53 113 90
248 162 371 198 42 13 77 27 77 32 0 5 -9 21 -21 35 l-21 27 136 6 c138 7 261
29 306 54 32 18 37 18 44 -1z m-729 -20 c-16 -14 -43 -35 -60 -47 l-30 -22 34
48 c26 35 41 47 60 47 l26 -1 -30 -25z m3343 -19 c37 -51 34 -57 -12 -17 -17
15 -43 35 -56 44 l-25 17 30 0 c24 0 37 -9 63 -44z m-3765 -25 c4 -33 9 -71
12 -83 3 -12 0 -31 -8 -41 -15 -21 -13 -21 -85 30 -2 1 4 26 12 55 9 29 14 54
12 56 -2 2 -15 -1 -30 -7 -50 -18 -59 -13 -34 20 19 26 28 30 68 30 l47 0 6
-60z m4244 43 c7 -10 18 -27 24 -37 10 -17 9 -18 -12 -12 -13 3 -34 9 -46 12
l-23 6 16 -53 16 -53 -34 -28 c-40 -33 -42 -34 -54 -7 -8 17 3 132 15 176 1 2
43 10 65 12 10 0 25 -7 33 -16z m-4397 -17 c0 -2 -8 -10 -17 -17 -16 -13 -17
-12 -4 4 13 16 21 21 21 13z m4451 -13 c13 -16 12 -17 -3 -4 -10 7 -18 15 -18
17 0 8 8 3 21 -13z m-964 -9 l132 -7 -21 -29 c-11 -16 -17 -33 -12 -37 5 -5
41 -18 80 -30 100 -29 234 -99 333 -173 l83 -63 -33 -9 c-19 -5 -36 -11 -37
-12 -2 -1 9 -22 25 -46 55 -80 166 -298 135 -263 -9 10 -202 289 -215 312 -5
8 7 17 39 26 l46 13 -98 48 c-124 60 -258 117 -349 146 -38 12 -71 24 -73 25
-2 2 14 19 35 39 l38 36 -86 -6 c-47 -4 -111 -4 -142 0 -48 6 -57 11 -57 28 0
16 5 19 23 15 12 -4 81 -9 154 -13z m-2163 -19 c9 -37 0 -45 -50 -45 l-46 0 7
-32 c4 -18 8 -34 9 -35 0 -1 21 2 46 7 25 5 46 7 47 6 1 -1 0 -12 -4 -24 -4
-17 -24 -28 -97 -51 -163 -52 -369 -72 -541 -52 l-60 7 40 19 c22 10 100 39
174 65 l133 47 -25 20 -26 20 102 5 c79 4 122 13 187 36 47 16 88 30 91 31 4
1 10 -10 13 -24z m2121 -45 l70 0 -25 -20 c-14 -10 -22 -21 -19 -24 3 -3 57
-23 120 -45 62 -22 137 -50 164 -62 l50 -22 -65 -9 c-123 -17 -330 -1 -488 37
l-23 6 29 24 c29 24 37 51 21 71 -5 6 -2 20 8 33 13 19 22 22 52 17 20 -3 68
-6 106 -6z m-3341 -94 c21 -18 26 -29 21 -45 -8 -27 -23 -27 -59 2 -25 20 -27
26 -17 45 14 27 19 27 55 -2z m4292 -8 c5 -17 0 -27 -22 -45 -36 -28 -40 -28
-55 -1 -10 19 -8 25 17 45 36 29 51 29 60 1z m-4323 -81 c21 -14 26 -24 21
-40 -9 -30 -9 -30 -41 -13 -54 30 -55 30 -42 59 12 27 15 27 62 -6z m4357 2
c13 -24 6 -36 -39 -62 l-31 -18 -10 27 c-8 21 -8 30 2 35 7 4 22 15 33 23 27
20 32 20 45 -5z m-3901 -56 c-7 -10 -14 -19 -15 -21 -1 -1 -9 1 -19 4 -15 6
-15 8 6 20 33 18 44 17 28 -3z m3422 -16 c-7 -7 -15 -6 -26 3 -24 20 -18 31
10 18 17 -8 22 -15 16 -21z m-3903 -18 c18 -11 21 -20 17 -39 -4 -14 -8 -26
-10 -28 -1 -2 -26 11 -54 28 -50 31 -52 37 -28 68 8 10 14 10 31 -1 12 -8 31
-20 44 -28z m4430 10 c16 -24 14 -27 -50 -64 -38 -21 -48 -24 -48 -12 0 9 -3
22 -6 30 -4 10 9 24 38 41 52 31 50 31 66 5z m-4482 -81 c48 -29 54 -36 49
-58 -4 -14 -8 -27 -10 -28 -3 -3 -122 63 -133 73 -7 6 19 45 29 45 6 0 35 -15
65 -32z m4537 7 c9 -13 14 -26 10 -28 -5 -2 -38 -21 -74 -41 l-67 -36 -4 30
c-4 27 0 33 51 64 31 19 58 35 61 35 3 0 13 -10 23 -24z m-4578 -87 c57 -33
75 -48 75 -65 0 -36 -15 -35 -87 3 -120 65 -117 63 -97 85 9 10 21 19 26 19 5
0 42 -19 83 -42z m4650 -7 c-6 -5 -49 -30 -97 -56 l-87 -46 -7 26 c-3 14 -5
28 -3 30 3 2 40 24 84 49 79 45 80 45 100 26 12 -12 16 -23 10 -29z m-4623
-109 c21 -10 38 -24 38 -33 0 -9 -20 -59 -43 -110 -24 -52 -52 -116 -62 -142
-10 -26 -21 -44 -24 -38 -4 5 -56 31 -116 56 -61 26 -114 51 -118 55 -10 9 9
50 24 50 5 0 59 -22 119 -50 60 -27 112 -50 115 -50 3 0 5 6 5 14 0 7 -49 36
-110 64 -60 28 -110 55 -110 59 0 4 7 17 16 28 14 19 17 18 120 -33 95 -47
135 -58 118 -31 -3 5 -50 32 -105 60 -91 45 -99 51 -89 69 10 19 7 20 -35 20
-49 0 -59 14 -21 31 21 10 39 3 142 -50 65 -33 124 -61 132 -61 34 0 -4 29
-112 86 l-117 61 27 20 28 20 70 -39 c39 -22 88 -47 108 -56z m4684 69 l25
-20 -23 -15 c-13 -8 -67 -36 -120 -62 -54 -27 -98 -54 -98 -61 0 -22 21 -15
149 52 l124 64 25 -19 25 -20 -29 0 c-51 0 -76 -11 -63 -26 6 -7 8 -17 5 -22
-3 -5 -48 -28 -101 -52 -52 -24 -95 -49 -95 -56 0 -20 5 -19 119 33 100 46
104 47 117 28 8 -11 13 -23 11 -28 -1 -4 -47 -28 -102 -54 -110 -50 -120 -56
-111 -72 4 -6 51 11 123 45 108 50 117 53 129 37 7 -10 14 -23 14 -29 0 -6
-56 -35 -124 -65 l-123 -55 -30 70 c-17 39 -46 107 -64 150 -19 44 -30 81 -24
83 6 2 55 28 110 58 55 30 102 54 103 55 2 0 15 -9 28 -19z m-4692 -405 c-76
-188 -158 -466 -180 -612 -8 -54 -74 -185 -74 -147 0 38 48 283 79 403 37 143
95 310 158 459 l48 114 3 -63 c3 -53 -3 -76 -34 -154z m4570 -50 c76 -201 153
-504 171 -671 l6 -60 -31 53 c-19 33 -37 86 -50 146 -44 213 -113 442 -176
583 -18 41 -27 81 -30 138 l-5 80 40 -89 c23 -49 57 -130 75 -180z m-2234 74
c45 -77 85 -142 90 -145 4 -3 56 48 116 113 59 64 111 118 115 120 4 2 16 -21
27 -50 77 -206 95 -247 106 -241 6 4 85 56 175 116 90 60 162 104 160 98 -23
-63 -507 -1041 -516 -1041 -7 0 -13 2 -13 4 0 3 106 222 235 487 129 265 234
484 232 485 -1 2 -68 -42 -149 -96 -124 -85 -147 -97 -153 -82 -4 9 -29 75
-56 147 -28 71 -51 130 -53 132 -2 2 -52 -51 -112 -117 -59 -66 -111 -120
-115 -120 -3 0 -43 63 -88 140 -46 77 -86 142 -89 144 -4 2 -45 -61 -92 -140
-46 -79 -87 -144 -90 -144 -4 0 -55 54 -115 120 -60 66 -109 119 -111 117 -3
-2 -50 -121 -88 -225 l-28 -72 -146 100 c-80 54 -147 98 -149 96 -2 -1 126
-267 283 -590 170 -349 284 -596 282 -609 -2 -14 -119 216 -312 613 -169 349
-311 640 -314 648 -3 8 69 -35 159 -95 90 -60 169 -112 175 -116 8 -4 30 42
64 132 29 77 56 145 60 151 6 9 28 -9 71 -56 35 -38 87 -93 115 -123 l51 -54
88 146 c48 81 91 147 95 147 5 0 45 -63 90 -140z m-2547 18 c67 -28 123 -52
125 -53 1 -1 -4 -17 -11 -35 -8 -18 -17 -28 -21 -22 -3 5 -59 31 -123 57 -65
26 -122 50 -126 55 -9 8 13 50 26 50 5 0 63 -23 130 -52z m5066 26 c6 -15 10
-28 8 -30 -2 -3 -63 -29 -135 -58 -112 -47 -131 -52 -135 -38 -3 9 -8 26 -13
36 -4 12 -4 17 2 13 5 -3 63 19 129 48 66 29 123 54 126 54 3 1 11 -11 18 -25z
m-5819 -173 c0 -75 27 -247 55 -357 37 -141 92 -283 171 -441 39 -79 69 -143
68 -143 -7 0 -118 187 -157 266 -109 219 -179 471 -193 704 l-6 95 31 -35 c26
-30 31 -43 31 -89z m714 67 c72 -29 132 -53 134 -54 1 0 -3 -15 -9 -32 -10
-29 -12 -30 -32 -18 -12 7 -75 33 -139 56 -65 24 -118 47 -118 52 0 13 19 48
27 48 3 0 65 -24 137 -52z m5151 22 c15 -29 15 -31 -7 -39 -184 -68 -254 -96
-257 -105 -2 -6 -10 5 -16 24 l-12 35 136 57 c75 31 137 57 138 57 1 1 9 -13
18 -29z m605 -130 c-25 -217 -79 -406 -171 -600 -53 -110 -144 -270 -154 -270
-2 0 28 65 66 144 118 244 192 506 206 726 5 84 8 98 32 123 l26 29 3 -28 c2
-16 -1 -71 -8 -124z m-5468 13 c0 -60 0 -117 0 -128 -3 -38 -33 -104 -45 -100
-7 3 -37 -42 -72 -105 -32 -60 -60 -108 -62 -106 -17 17 146 546 168 546 5 0
10 -48 11 -107z m4504 -82 c35 -106 99 -350 92 -356 -2 -2 -30 46 -63 106 -33
60 -65 107 -71 105 -24 -9 -44 76 -44 184 0 58 3 116 7 130 6 23 8 21 25 -20
10 -24 35 -91 54 -149z m-4763 70 c73 -24 77 -27 71 -51 -3 -14 -7 -27 -9 -29
-3 -2 -72 20 -154 49 l-150 52 14 29 15 28 68 -26 c37 -14 102 -37 145 -52z
m521 40 c21 -22 36 -46 33 -53 -7 -18 -122 -172 -141 -187 -9 -8 -6 7 9 46 18
43 45 184 45 230 0 12 22 -3 54 -36z m3956 -23 c0 -49 18 -132 41 -188 l19
-45 -35 40 c-19 22 -57 71 -83 109 l-47 68 44 39 c50 44 61 40 61 -23z m689
26 c6 -15 10 -28 9 -29 -7 -5 -301 -105 -303 -103 -1 2 -5 14 -8 28 -6 24 -1
27 131 76 75 29 142 52 148 53 6 0 16 -11 23 -25z m-5234 -115 c55 -19 106
-33 113 -33 15 2 14 4 2 -33 l-11 -30 -152 48 c-84 27 -153 49 -154 49 -2 0
-3 -7 -3 -15 0 -10 46 -29 151 -62 146 -47 150 -49 145 -73 -3 -14 -16 -77
-30 -140 -14 -63 -32 -163 -41 -223 -19 -132 -38 -177 -75 -177 -22 0 -29 7
-37 34 l-10 33 45 -5 c60 -7 54 20 -8 30 -50 9 -45 5 -54 42 l-7 28 48 -7 c26
-4 52 -8 57 -8 5 -1 10 3 13 10 4 10 -73 33 -111 33 -11 0 -29 58 -22 67 1 1
29 -4 61 -12 75 -18 98 -18 93 -3 -3 7 -37 20 -77 29 -73 17 -91 28 -91 62 0
21 7 21 103 -2 48 -11 80 -15 84 -9 9 15 -11 24 -102 43 -79 16 -84 19 -90 47
-4 16 -5 32 -2 34 2 3 50 -7 106 -22 56 -15 104 -25 107 -21 14 14 -11 25
-111 52 l-105 28 0 33 0 33 63 -18 c34 -10 85 -25 114 -34 49 -15 63 -14 63 4
0 5 -33 18 -72 29 -144 42 -168 51 -168 67 0 12 -9 14 -40 11 -22 -2 -40 -3
-40 -2 0 7 41 117 49 133 11 20 12 20 54 2 23 -10 87 -33 142 -52z m5283 9
c39 -106 40 -101 -7 -93 -37 6 -41 5 -41 -14 0 -12 -2 -21 -4 -21 -11 0 -214
-62 -227 -69 -14 -8 -18 -4 -23 21 -3 17 -5 32 -4 33 2 1 68 24 148 50 80 26
149 51 154 56 27 25 -35 14 -166 -29 l-146 -47 -10 32 c-9 28 -8 33 6 33 10 0
78 22 152 49 74 27 138 50 142 50 4 1 15 -23 26 -51z m-4603 -20 c32 -68 31
-101 -4 -207 -35 -101 -65 -170 -120 -267 l-40 -72 64 -7 c36 -3 65 -10 65
-13 0 -4 -23 -37 -51 -74 -65 -87 -159 -172 -282 -256 l-98 -66 51 -26 51 -26
-85 -62 c-47 -34 -89 -62 -94 -62 -16 0 -162 286 -162 318 0 19 317 602 324
595 3 -3 6 -17 6 -30 0 -29 3 -29 55 7 74 51 158 134 220 219 33 45 64 81 68
81 4 0 19 -24 32 -52z m3785 0 c67 -100 254 -278 291 -278 5 0 9 11 9 25 0 14
3 25 8 25 10 0 322 -582 322 -601 0 -17 -124 -267 -147 -296 l-18 -22 -82 60
c-46 33 -83 64 -83 67 0 4 20 18 45 31 l45 24 -98 66 c-53 36 -130 98 -171
136 -70 66 -161 177 -161 196 0 5 24 9 53 9 28 0 57 4 63 8 8 5 -2 31 -32 81
-75 128 -144 313 -144 386 0 26 48 135 59 135 3 0 22 -24 41 -52z m-5212 -106
c7 -21 23 -110 37 -198 13 -87 32 -184 41 -214 31 -107 97 -206 177 -267 24
-19 28 -28 25 -65 l-3 -43 -66 65 c-123 121 -178 236 -210 437 -17 108 -51
277 -74 373 -6 23 -3 21 28 -12 18 -21 39 -55 45 -76z m6623 17 c-12 -41 -32
-146 -46 -234 -29 -179 -46 -248 -80 -315 -23 -45 -23 -48 -8 -103 13 -45 14
-62 5 -83 -7 -14 -16 -23 -21 -20 -16 10 -24 105 -11 122 7 8 11 17 8 19 -3 3
-16 -12 -30 -32 -28 -42 -137 -153 -150 -153 -4 0 -8 16 -8 35 0 31 9 44 63
95 108 101 149 204 187 467 11 78 27 161 36 185 13 38 62 103 71 94 2 -2 -5
-37 -16 -77z m-4147 -538 c300 -261 546 -481 546 -490 0 -11 -5 -10 -22 6 -87
77 -1089 943 -1093 943 -2 0 21 -74 52 -165 32 -90 55 -166 53 -169 -3 -2 -72
-11 -154 -19 -81 -9 -150 -17 -152 -20 -2 -2 34 -64 81 -137 47 -74 85 -137
85 -140 0 -4 -72 -27 -160 -51 -88 -24 -160 -48 -160 -52 0 -5 52 -62 115
-126 l116 -118 -24 -16 c-13 -8 -78 -42 -145 -76 -67 -34 -122 -63 -122 -66 0
-2 54 -43 120 -91 66 -48 123 -91 126 -95 2 -5 -54 -59 -125 -121 -71 -62
-128 -114 -126 -116 2 -2 173 29 379 69 333 64 404 74 384 53 -8 -8 -820 -165
-837 -162 -8 2 53 62 137 133 83 72 151 133 151 136 1 3 -58 48 -130 100 -92
66 -127 96 -117 102 7 5 75 41 150 80 l137 72 -121 128 c-66 70 -119 129 -117
131 2 1 74 22 159 46 85 24 159 46 163 50 5 4 -32 71 -81 148 l-90 140 42 6
c22 3 95 10 161 17 66 6 121 12 123 14 1 1 -27 87 -63 190 -36 103 -65 192
-65 197 0 5 17 -5 39 -24 21 -18 284 -246 585 -507z m2171 335 c-36 -104 -64
-190 -63 -191 2 -2 57 -8 123 -14 66 -7 139 -14 161 -17 l42 -6 -90 -140 c-49
-77 -86 -144 -81 -148 4 -5 78 -27 163 -51 85 -23 157 -44 159 -46 3 -2 -24
-34 -60 -71 -35 -37 -90 -94 -121 -127 l-58 -60 150 -78 c83 -43 150 -80 150
-81 0 -1 -59 -45 -130 -96 -72 -52 -129 -97 -128 -100 2 -4 72 -66 156 -139
83 -73 152 -133 152 -135 0 -4 -752 143 -758 148 -20 21 67 9 343 -44 174 -34
319 -59 321 -57 2 2 -54 54 -126 116 -72 62 -128 116 -126 119 3 4 59 45 125
92 66 47 120 88 121 92 0 4 -37 26 -82 49 -46 23 -112 57 -146 77 l-64 35 123
123 c83 83 118 125 108 128 -8 3 -81 24 -164 47 -82 22 -152 43 -153 44 -2 2
35 65 83 140 48 75 85 138 82 141 -2 3 -72 12 -153 21 -82 8 -151 17 -152 19
-2 1 21 75 52 165 31 89 55 163 53 165 -2 2 -234 -197 -516 -441 -282 -245
-515 -445 -518 -445 -26 0 77 96 542 499 297 258 541 465 543 462 1 -4 -27
-92 -63 -195z m-3165 101 c12 -79 3 -107 -75 -228 -25 -39 -45 -72 -43 -74 2
-1 25 -8 51 -15 27 -7 51 -19 54 -26 6 -17 -66 -133 -128 -204 -29 -33 -88
-88 -132 -122 -44 -35 -89 -70 -100 -79 -21 -16 -20 -17 21 -38 23 -12 42 -25
42 -29 0 -5 -37 -35 -82 -67 -46 -33 -104 -74 -130 -93 -45 -32 -47 -32 -57
-13 -10 17 0 27 89 92 55 39 97 75 95 79 -2 3 -22 15 -45 25 -22 10 -40 21
-40 24 1 3 38 31 83 60 123 82 337 306 337 353 0 4 -24 8 -52 8 -78 0 -82 6
-40 75 43 74 118 254 127 307 4 22 9 38 10 36 2 -2 9 -34 15 -71z m3735 -243
l53 -102 -59 -4 c-32 -2 -61 -7 -65 -10 -10 -11 75 -127 145 -198 55 -56 129
-115 256 -203 18 -14 17 -16 -30 -39 l-49 -25 88 -64 c49 -35 95 -69 103 -74
12 -9 12 -13 0 -29 -13 -17 -21 -13 -143 74 -71 51 -131 94 -132 96 -2 1 14
12 36 23 23 11 43 25 46 29 3 5 -22 28 -55 51 -128 90 -251 225 -307 337 l-21
42 59 16 c33 10 60 19 60 21 0 1 -16 27 -36 56 -69 103 -96 167 -89 213 16
104 15 104 51 -6 19 -57 59 -149 89 -204z m765 263 l0 -32 -92 -24 c-105 -26
-134 -39 -121 -52 5 -5 47 2 98 16 113 31 115 31 115 -5 0 -22 -5 -31 -22 -35
-13 -3 -56 -13 -96 -23 -40 -9 -73 -21 -72 -27 1 -13 40 -10 118 8 l63 15 -3
-31 c-3 -31 -6 -33 -70 -48 -89 -22 -103 -28 -92 -39 5 -5 37 -3 78 6 38 8 71
12 74 10 2 -3 0 -17 -5 -33 -8 -24 -17 -29 -68 -38 -32 -5 -60 -16 -63 -22 -4
-15 26 -16 81 -4 39 9 42 6 31 -35 -4 -15 -18 -23 -50 -30 -55 -11 -58 -30 -4
-26 35 2 38 0 33 -20 -6 -25 -30 -48 -50 -48 -24 0 -51 75 -67 185 -9 60 -25
152 -36 204 -11 52 -20 96 -20 98 0 1 8 3 18 3 9 0 60 14 112 30 52 16 98 29
103 29 4 1 7 -14 7 -32z m-5104 -311 c-152 -283 -176 -324 -176 -296 0 31 323
622 328 599 1 -8 -67 -145 -152 -303z m4742 164 c145 -262 242 -450 242 -467
0 -10 -3 -13 -9 -7 -16 17 -321 592 -321 605 0 23 17 -2 88 -131z m-4488 116
c0 -2 -11 -11 -25 -20 -14 -9 -25 -13 -25 -8 0 5 10 14 23 20 27 13 27 13 27
8z m4360 -28 c0 -13 -13 -9 -40 12 l-25 20 33 -12 c17 -7 32 -16 32 -20z
m-5594 -109 c14 -28 42 -117 64 -198 44 -168 75 -251 118 -313 35 -51 121
-134 157 -150 31 -14 34 -54 5 -63 -44 -14 -179 126 -239 249 -24 49 -60 154
-90 262 -27 99 -61 207 -75 239 -14 31 -26 59 -26 62 0 2 14 -5 30 -17 17 -12
42 -44 56 -71z m6839 40 c-24 -45 -50 -123 -91 -269 -63 -226 -112 -325 -209
-424 -66 -67 -100 -90 -120 -82 -27 10 -18 50 17 71 52 30 119 96 153 150 47
75 72 140 116 308 45 173 68 226 111 262 38 32 45 27 23 -16z m-6626 -139 c0
-14 -4 -34 -8 -44 -8 -21 -24 45 -36 149 l-6 50 26 -65 c13 -36 24 -76 24 -90z
m6382 59 c-7 -44 -16 -90 -20 -102 -7 -18 -9 -16 -15 16 -5 27 -1 57 15 107
13 38 25 68 27 65 2 -2 -1 -41 -7 -86z m-5001 -45 l0 -76 -32 6 c-18 3 -36 10
-39 14 -6 6 59 131 69 132 1 0 2 -34 2 -76z m3629 5 l39 -64 -36 -13 c-19 -6
-37 -10 -40 -7 -8 7 -15 161 -8 154 4 -3 24 -35 45 -70z m-4960 -155 c-18 -70
-19 -71 -35 -41 -13 23 -13 37 -3 89 l12 61 18 -35 c15 -29 16 -43 8 -74z
m6281 -45 c-16 -37 -25 -28 -37 31 -7 35 -6 53 6 79 l16 33 14 -56 c11 -46 11
-62 1 -87z m-3163 30 c73 -35 144 -108 192 -200 19 -35 35 -65 36 -67 1 -1 22
9 47 23 39 22 138 49 138 39 0 -2 -13 -22 -29 -45 -31 -42 -45 -94 -57 -214
-5 -54 -9 -64 -15 -45 -6 22 -7 21 -8 -11 -1 -45 -24 -111 -51 -149 -11 -15
-20 -36 -20 -45 0 -12 -6 -15 -22 -11 -13 3 -18 3 -11 0 8 -4 9 -13 1 -35 -9
-25 -15 -29 -32 -24 -42 13 -152 17 -188 7 -34 -9 -37 -8 -42 14 -4 14 -4 29
-1 34 3 4 -4 6 -15 3 -27 -7 -25 5 3 18 20 9 19 9 -6 4 -38 -8 -111 43 -138
97 -18 35 -20 51 -14 97 12 99 15 261 6 304 -6 23 -8 43 -6 46 10 9 91 -22
132 -51 l43 -29 0 65 c0 68 -28 164 -55 186 -49 41 16 34 112 -11z m-3048 -74
c49 -88 54 -117 17 -87 -14 10 -37 33 -51 50 -23 27 -26 38 -20 72 4 22 8 40
10 40 2 0 22 -34 44 -75z m6160 13 c1 -18 -13 -40 -46 -75 -63 -64 -68 -40
-11 61 44 79 56 82 57 14z m-6593 -8 c33 -23 77 -95 129 -210 57 -126 103
-200 158 -258 39 -39 40 -43 24 -59 -15 -16 -18 -16 -37 2 -49 44 -104 132
-183 290 -47 94 -96 186 -109 205 -40 59 -41 60 -21 52 10 -4 28 -14 39 -22z
m1663 -20 c-5 -25 -11 -55 -14 -67 -3 -12 -23 -32 -45 -44 -51 -29 -95 -95
-152 -229 -25 -58 -57 -127 -71 -155 -23 -44 -26 -47 -27 -23 -1 65 22 133 53
159 l31 27 -43 21 c-23 12 -41 26 -39 30 2 5 38 36 80 70 82 66 147 135 201
213 18 25 33 45 34 44 1 -1 -2 -22 -8 -46z m3685 -14 c41 -60 179 -196 239
-236 20 -14 37 -27 37 -30 0 -3 -20 -16 -45 -29 l-45 -24 29 -21 c31 -21 40
-48 56 -161 l6 -40 -31 57 c-16 32 -49 101 -72 155 -64 151 -112 220 -169 243
-22 10 -30 21 -38 61 -11 51 -14 79 -8 79 2 0 21 -24 41 -54z m1648 12 c-16
-24 -71 -125 -121 -226 -70 -138 -105 -196 -145 -240 -47 -49 -56 -55 -70 -43
-14 12 -10 19 35 70 36 40 73 101 127 211 86 177 122 234 160 254 43 24 45 19
14 -26z m-6687 -102 c-8 -59 -23 -69 -39 -25 -5 15 -4 41 4 68 10 39 13 42 27
29 11 -12 13 -28 8 -72z m595 70 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6
5 11 10 11 6 0 10 -2 10 -4z m-364 -421 c1 -224 6 -346 18 -440 59 -466 180
-864 390 -1293 99 -200 94 -186 75 -190 -16 -3 -97 150 -110 208 -4 19 -34 96
-67 170 -70 159 -222 598 -222 643 0 23 -3 28 -9 18 -7 -11 -12 -4 -20 25 -37
142 -73 383 -91 613 -11 146 -14 542 -4 579 l7 23 16 -23 c13 -20 16 -66 17
-333z m5939 223 c14 -225 -23 -690 -76 -952 -32 -162 -37 -175 -51 -155 -7 10
-8 7 -5 -9 10 -43 -193 -614 -274 -774 -10 -21 -19 -48 -19 -61 0 -14 -21 -65
-47 -113 -55 -106 -51 -100 -64 -87 -7 7 11 53 60 151 215 429 340 826 403
1282 18 130 22 207 23 475 1 253 4 325 15 342 8 12 17 20 21 17 4 -2 10 -55
14 -116z m-6016 -35 c1 -46 -17 -42 -83 18 -55 50 -65 73 -50 114 5 13 19 4
69 -42 51 -47 63 -64 64 -90z m1138 64 c-19 -34 -63 -125 -97 -201 -70 -158
-130 -257 -195 -320 -47 -46 -153 -121 -161 -114 -11 12 29 78 58 96 90 56
173 172 247 345 72 167 96 210 137 247 50 44 54 25 11 -53z m5043 32 c0 -19
-15 -41 -54 -78 -30 -28 -59 -51 -64 -51 -6 0 -13 12 -17 27 -5 23 2 35 56 89
65 66 79 68 79 13z m-1276 26 c36 -24 72 -84 131 -221 94 -219 157 -311 254
-374 35 -23 51 -41 59 -68 6 -20 10 -37 8 -39 -2 -2 -31 15 -64 37 -99 67
-176 158 -237 285 -124 257 -185 385 -185 390 0 9 10 6 34 -10z m-4769 -77 c9
-14 11 -24 5 -26 -20 -7 -10 -32 13 -32 20 0 67 -46 67 -65 0 -3 -22 -5 -50
-5 -52 0 -67 -13 -27 -24 12 -3 41 -6 64 -6 35 0 45 -5 63 -30 l21 -30 -85 0
c-70 0 -86 -3 -86 -15 0 -12 18 -15 99 -15 94 0 99 -1 115 -25 9 -13 16 -27
16 -30 0 -3 -38 -5 -84 -5 -92 0 -146 -8 -146 -21 0 -5 58 -9 129 -9 129 0
130 0 151 -27 l22 -28 -139 -8 c-148 -8 -172 -12 -157 -27 11 -11 285 -2 304
10 8 5 5 19 -10 46 l-22 39 41 -43 c30 -32 41 -54 45 -85 14 -115 49 -349 61
-407 7 -36 13 -66 11 -67 -1 -1 -22 5 -46 13 -25 9 -67 18 -95 21 -76 9 -86
20 -24 28 70 10 105 23 93 35 -11 11 -293 -35 -308 -51 -18 -17 3 -21 58 -12
53 9 56 9 81 -17 l26 -27 -72 -14 c-40 -8 -74 -20 -77 -27 -5 -16 28 -15 103
3 58 14 61 13 78 -6 26 -31 22 -36 -35 -49 -101 -21 -128 -31 -128 -46 0 -10
7 -13 23 -9 12 3 56 14 99 24 l76 18 12 -25 c14 -32 13 -32 -100 -61 -58 -15
-90 -28 -90 -37 0 -18 -6 -18 112 12 81 20 103 23 109 12 22 -34 10 -44 -91
-73 -95 -26 -120 -38 -106 -52 3 -4 54 7 114 24 l107 30 11 -26 11 -26 -108
-35 c-64 -20 -109 -39 -109 -47 0 -18 9 -17 122 18 82 26 102 29 109 18 21
-34 9 -45 -78 -73 -134 -43 -138 -45 -126 -57 10 -10 100 14 219 58 9 3 39
-46 32 -52 -2 -1 -52 -19 -111 -40 -59 -21 -110 -42 -113 -47 -12 -19 27 -17
90 5 l66 25 15 -21 c8 -12 15 -24 15 -28 0 -3 -34 -19 -75 -34 -45 -16 -75
-33 -75 -41 0 -20 9 -18 96 16 42 17 78 29 79 28 8 -10 26 -50 23 -52 -2 -2
-40 -17 -85 -35 -45 -18 -84 -38 -86 -44 -4 -13 -95 225 -136 358 -96 316
-146 606 -159 925 -9 214 -4 450 8 450 5 0 16 -10 25 -22z m5809 -140 c15
-358 -35 -763 -138 -1132 -33 -116 -111 -344 -121 -354 -2 -3 -10 0 -17 5 -7
6 -41 21 -76 33 -34 13 -65 26 -67 30 -3 5 3 18 13 29 l17 22 68 -26 c64 -24
87 -25 87 -6 0 5 -42 23 -92 41 -51 18 -103 37 -115 41 -20 7 -22 11 -11 34 6
14 14 25 18 25 3 0 53 -16 109 -35 96 -32 129 -36 116 -15 -3 5 -49 23 -103
40 -53 18 -101 36 -105 40 -12 11 2 50 17 50 8 0 58 -14 112 -30 65 -20 101
-27 108 -20 11 11 -25 27 -137 60 -75 22 -78 24 -72 49 4 14 8 27 10 29 1 2
50 -11 108 -27 122 -36 117 -35 117 -17 0 9 -22 21 -57 31 -32 9 -78 22 -103
29 -46 13 -57 29 -41 56 9 12 23 11 105 -9 107 -25 111 -26 111 -9 0 7 -42 22
-95 36 -52 13 -96 25 -98 26 -1 2 3 15 9 30 8 16 18 25 28 22 133 -35 202 -42
187 -17 -3 4 -46 16 -96 26 -49 11 -90 22 -90 26 0 4 8 16 18 28 17 21 18 22
90 6 90 -18 92 -18 92 -1 0 10 -22 18 -70 27 -38 7 -70 15 -70 19 0 4 10 16
23 28 21 20 28 21 77 12 65 -12 60 -13 60 4 0 16 -3 16 -174 47 -113 19 -154
20 -142 0 3 -5 43 -14 88 -21 l83 -13 -95 -18 c-52 -9 -105 -20 -117 -24 -13
-4 -23 -3 -23 2 0 5 11 74 24 154 14 79 30 189 36 243 9 84 15 105 38 135 15
19 30 39 34 45 4 6 10 7 14 4 3 -4 -3 -20 -15 -37 -13 -18 -17 -32 -11 -36 17
-10 294 -23 304 -14 18 16 -2 21 -138 28 -75 5 -139 11 -143 14 -3 4 2 16 12
27 16 18 31 20 147 20 99 0 129 3 126 13 -3 8 -38 13 -116 15 -62 1 -112 6
-112 10 0 4 8 17 18 29 15 20 28 23 111 25 63 2 96 7 98 15 3 9 -20 13 -83 15
l-87 3 27 33 c26 30 32 32 88 32 73 0 77 23 4 28 l-47 3 21 30 c14 18 30 29
46 29 15 0 24 6 24 15 0 8 -5 15 -11 15 -5 0 -2 13 8 30 9 17 20 30 24 30 3 0
9 -73 13 -162z m-6110 29 c4 -27 16 -69 27 -94 11 -25 17 -48 15 -51 -3 -2
-21 11 -39 29 -36 36 -57 102 -57 180 l0 41 24 -29 c13 -16 26 -49 30 -76z
m6416 -30 c-7 -35 -21 -63 -42 -84 -18 -18 -35 -33 -38 -33 -4 0 4 30 16 68
13 37 24 78 24 90 0 13 10 36 23 51 l22 28 3 -35 c2 -18 -2 -57 -8 -85z
m-5141 -3 c-18 -60 -137 -282 -206 -384 -20 -30 -23 -32 -23 -12 0 12 21 50
46 84 50 68 100 162 159 301 21 48 39 86 41 84 3 -2 -5 -35 -17 -73z m3905
-135 c30 -64 77 -146 105 -182 47 -61 63 -97 43 -97 -5 0 -30 37 -56 83 -27
45 -71 120 -99 166 -43 73 -88 185 -86 216 0 10 3 5 93 -186z m-5109 112 c31
-27 32 -30 18 -50 -8 -12 -18 -19 -23 -16 -10 6 -41 95 -33 95 3 0 20 -13 38
-29z m6258 -16 c-18 -55 -27 -62 -47 -34 -14 21 -13 24 21 50 20 16 37 29 39
29 1 0 -4 -20 -13 -45z m-6155 -212 c14 -238 33 -400 72 -591 29 -143 29 -144
13 -224 l-17 -82 -47 52 -48 52 6 -103 c6 -107 -5 -220 -34 -334 -8 -35 -19
-63 -23 -63 -23 0 -119 187 -154 298 -20 64 -66 280 -66 311 0 3 -5 0 -12 -7
-22 -22 -28 -12 -28 51 0 81 24 227 50 301 25 73 25 75 0 69 -27 -7 -25 4 13
89 57 124 166 301 210 340 23 21 44 38 47 38 4 0 11 -89 18 -197z m851 83 c-9
-80 -16 -109 -30 -122 -18 -17 -19 -15 -19 52 0 38 4 84 10 103 10 37 39 85
46 78 3 -3 0 -52 -7 -111z m5240 72 c45 -39 209 -321 235 -405 6 -19 4 -23
-14 -23 -17 0 -21 -4 -15 -17 24 -61 56 -223 62 -311 6 -103 6 -104 -15 -98
-24 8 -22 14 -47 -128 -30 -165 -108 -363 -178 -450 l-23 -29 -25 104 c-20 86
-24 130 -24 252 l0 149 -46 -50 -46 -51 -17 81 c-15 79 -15 81 14 226 38 195
58 356 72 594 9 145 15 195 23 190 7 -4 26 -19 44 -34z m-888 -22 c13 -29 19
-64 19 -121 l0 -80 -19 24 c-11 15 -21 50 -25 85 -4 34 -9 81 -13 105 -7 54
10 49 38 -13z m-4412 3 c-6 -13 -14 -65 -17 -116 -4 -88 -6 -95 -32 -113 -38
-27 -40 -26 -40 18 0 21 -7 59 -16 85 -9 26 -14 51 -12 57 3 11 121 99 126 95
2 -2 -2 -14 -9 -26z m4512 -27 l56 -41 -18 -53 c-10 -29 -18 -70 -19 -90 0
-21 -3 -38 -6 -38 -3 0 -19 9 -35 21 -27 19 -29 24 -29 93 0 40 -6 94 -14 120
-10 33 -11 44 -3 38 7 -5 37 -27 68 -50z m-5519 -139 c-46 -80 -122 -242 -122
-259 0 -2 10 -4 21 -4 17 0 20 -4 15 -22 -48 -167 -68 -327 -50 -395 5 -18 10
-21 23 -13 16 8 20 -2 35 -90 32 -180 91 -335 175 -460 34 -50 36 -57 21 -96
-11 -27 -11 -27 -41 37 -17 35 -59 116 -94 179 -79 141 -104 212 -112 313 l-6
78 -34 -33 -34 -32 6 74 c11 136 26 244 45 328 11 46 20 86 20 88 0 3 -13 -2
-30 -11 -23 -12 -30 -12 -30 -3 1 28 69 179 112 249 40 64 123 169 133 169 2
0 -22 -44 -53 -97z m6478 5 c61 -80 120 -188 155 -281 20 -53 17 -64 -15 -47
-12 6 -24 10 -26 7 -2 -2 5 -42 15 -88 21 -87 37 -204 44 -322 l5 -69 -33 32
-32 31 -6 -79 c-8 -100 -37 -181 -123 -334 -37 -68 -78 -147 -90 -177 l-22
-54 -12 40 c-12 39 -11 43 19 86 81 119 145 284 176 460 15 85 20 97 35 92 15
-5 19 2 25 35 10 60 -4 190 -31 300 -13 52 -24 96 -24 98 0 2 9 0 21 -3 18 -6
20 -4 14 17 -10 34 -109 234 -146 297 -45 75 -22 57 51 -41z m-5283 -74 c-31
-97 -34 -101 -117 -185 -67 -67 -102 -93 -165 -124 -44 -22 -82 -40 -84 -42
-2 -1 3 -32 10 -68 11 -59 15 -65 33 -59 41 12 126 69 176 117 53 53 56 53 40
-5 -12 -41 -57 -82 -142 -128 l-52 -28 -56 38 -56 38 -17 89 c-19 98 -42 256
-51 348 -6 58 -6 59 9 33 17 -30 85 -262 85 -290 0 -15 5 -17 28 -12 116 29
251 147 337 295 28 48 51 86 52 85 1 -1 -12 -47 -30 -102z m4126 -45 c68 -103
136 -168 220 -208 107 -51 98 -56 129 67 30 120 69 233 75 215 6 -16 -23 -232
-49 -373 l-21 -116 -51 -29 c-28 -17 -57 -31 -63 -33 -29 -8 -193 112 -193
141 0 7 -3 23 -7 37 -6 21 2 17 52 -28 62 -57 149 -112 177 -112 12 0 19 14
27 50 6 28 11 57 11 64 0 7 -39 32 -87 56 -67 33 -105 61 -164 119 -70 71 -78
83 -104 161 -15 47 -30 92 -32 100 -4 17 -3 15 80 -111z m-2828 80 c80 -5 128
-2 177 8 82 18 125 7 176 -41 52 -48 65 -80 52 -122 -6 -19 -10 -40 -7 -47 2
-6 7 5 11 25 4 21 12 40 17 44 7 4 9 -2 5 -17 -3 -13 -3 -19 0 -12 7 16 67 -5
60 -21 -26 -60 -38 -127 -33 -182 l5 -62 -42 -23 c-114 -63 -130 -69 -190 -70
-56 -2 -58 -2 -21 -9 l40 -6 -35 -6 c-19 -4 -70 -11 -113 -17 -81 -11 -126
-29 -164 -64 l-23 -20 0 30 c0 38 17 90 42 125 10 14 18 29 18 31 0 3 -31 22
-70 43 -38 20 -92 58 -119 85 -50 49 -105 147 -115 206 l-5 32 48 -30 c53 -33
159 -57 204 -45 l28 7 -27 34 c-41 55 -72 158 -41 138 6 -4 62 -10 122 -14z
m1416 7 c59 -13 271 -8 297 7 34 22 -8 -105 -50 -150 l-21 -22 29 -7 c46 -12
151 11 206 45 l50 31 -7 -32 c-13 -66 -64 -159 -113 -206 -27 -27 -81 -65
-121 -86 -39 -20 -71 -39 -71 -41 0 -2 11 -20 24 -40 23 -33 52 -132 42 -142
-3 -3 -17 7 -33 21 -33 31 -97 53 -178 61 -33 3 -76 9 -95 12 l-35 7 40 6 c38
7 37 7 -17 9 -72 1 -209 62 -201 88 3 7 -3 9 -15 6 -19 -4 -20 1 -20 79 1 55
-5 96 -16 122 -21 51 -21 52 13 64 22 8 31 7 35 -1 3 -7 3 -1 0 13 -5 24 -5
25 11 5 16 -19 16 -19 10 6 -9 35 46 107 105 137 51 26 57 26 131 8z m-508
-46 c76 -29 130 -79 169 -157 95 -194 -16 -412 -230 -453 -60 -11 -147 2 -202
30 -49 25 -115 95 -141 149 -33 70 -37 171 -9 245 35 93 116 168 210 197 49
15 148 9 203 -11z m-2368 -149 l-27 -26 4 57 c3 38 11 65 24 79 l19 21 3 -53
c3 -46 0 -56 -23 -78z m4411 73 c3 -11 4 -38 2 -60 l-3 -39 -22 23 c-18 17
-23 33 -23 72 0 48 1 50 20 38 12 -7 23 -22 26 -34z m-2461 6 c-23 -15 -32
-17 -44 -7 -12 10 -9 13 15 18 52 12 59 9 29 -11z m644 -16 c7 -7 -6 -15 -31
-19 -17 -3 -31 2 -44 16 l-19 21 45 -7 c25 -4 47 -9 49 -11z m-2660 -100 c-16
-19 -103 -64 -124 -64 -16 0 -20 27 -6 49 5 7 37 30 72 50 l64 37 3 -29 c2
-15 -2 -35 -9 -43z m4639 -3 c12 -11 22 -29 22 -41 0 -19 -4 -21 -27 -16 -16
4 -48 19 -73 34 -39 24 -45 33 -48 65 l-3 38 53 -30 c29 -17 63 -39 76 -50z
m-4486 -43 c-16 -36 -72 -103 -72 -86 0 35 74 148 88 134 2 -3 -5 -24 -16 -48z
m4267 -18 c5 -8 11 -28 15 -45 l8 -30 -27 25 c-31 29 -44 50 -56 93 l-9 30 31
-29 c17 -16 34 -36 38 -44z m-4354 -17 c-3 -16 -18 -53 -32 -84 -20 -47 -33
-61 -73 -83 -26 -14 -51 -26 -55 -26 -3 0 10 19 29 42 20 23 46 62 58 87 17
38 61 89 77 91 2 0 0 -12 -4 -27z m4460 -65 c17 -31 45 -75 64 -98 l33 -41
-51 25 c-58 30 -73 49 -101 131 -25 72 -25 71 3 53 12 -8 36 -40 52 -70z
m-4571 -20 c-38 -66 -64 -81 -64 -37 0 13 11 25 33 34 40 18 40 18 31 3z
m4646 -3 c21 -11 30 -23 30 -40 0 -32 -11 -32 -36 3 -43 58 -42 62 6 37z
m-3187 -38 c1 -4 -177 -106 -395 -226 l-398 -218 177 -7 c98 -4 179 -8 180
-10 2 -1 -15 -71 -36 -154 -22 -84 -36 -152 -33 -152 4 1 77 16 162 35 85 18
156 32 158 30 4 -4 -16 -262 -23 -307 -3 -18 0 -28 8 -28 6 0 77 30 156 65 80
36 148 62 152 58 3 -5 14 -77 24 -160 10 -84 22 -153 27 -153 4 0 65 38 135
85 l127 85 59 -158 c56 -150 77 -194 77 -161 0 27 189 1162 195 1167 3 3 8 2
11 -1 8 -7 -197 -1267 -206 -1267 -3 0 -34 78 -69 173 -35 94 -68 180 -73 189
-8 16 -26 6 -144 -72 -86 -59 -136 -86 -138 -78 -3 7 -12 78 -21 158 -9 80
-18 150 -20 157 -3 7 -59 -13 -161 -58 -86 -39 -158 -68 -161 -65 -3 2 1 79 8
170 6 92 10 168 8 170 -2 3 -76 -11 -163 -30 -87 -19 -160 -33 -162 -31 -2 1
15 72 36 157 22 85 40 156 40 158 0 1 -51 3 -112 3 -62 1 -156 4 -208 8 l-94
6 429 237 c237 130 434 236 438 235 5 -2 9 -6 10 -10z m2217 -268 l355 -194
-75 -6 c-41 -4 -135 -7 -207 -8 -73 0 -133 -3 -133 -6 0 -2 18 -73 40 -157 22
-83 38 -153 36 -155 -2 -2 -75 12 -162 31 -87 19 -161 33 -163 30 -3 -2 1 -79
8 -170 7 -91 11 -168 8 -171 -3 -2 -76 27 -162 66 -102 46 -157 66 -160 58 -2
-7 -11 -77 -20 -157 -9 -80 -18 -151 -21 -158 -2 -8 -51 19 -138 78 -74 49
-138 86 -141 80 -3 -5 -35 -87 -70 -182 -36 -95 -68 -180 -71 -188 -4 -8 -53
264 -109 605 -57 341 -101 624 -98 628 3 5 9 5 13 0 4 -4 50 -263 101 -575 51
-312 96 -564 100 -560 4 4 33 77 65 162 32 85 58 156 59 158 1 1 59 -36 128
-83 69 -47 130 -85 135 -85 5 0 17 69 27 153 10 83 21 155 25 160 3 4 73 -22
153 -58 81 -36 152 -65 157 -65 5 0 7 12 5 28 -8 50 -27 304 -22 308 2 2 75
-12 163 -31 87 -19 159 -35 160 -35 1 0 -15 69 -37 152 -21 84 -37 154 -35
155 1 2 82 6 179 11 l176 7 -364 200 c-207 113 -365 205 -365 213 0 10 15 5
53 -15 28 -17 212 -117 407 -224z m-3905 214 c111 -35 206 -99 288 -195 39
-46 45 -58 39 -82 -7 -27 -8 -26 -125 78 -117 106 -149 128 -199 140 -34 8
-34 5 -4 -47 13 -23 59 -123 101 -222 42 -99 93 -209 112 -244 l36 -64 -52 6
c-31 4 -51 2 -51 -4 0 -5 9 -36 20 -68 26 -78 80 -169 146 -247 30 -35 54 -66
54 -69 0 -9 -63 26 -95 53 -51 42 -84 97 -129 209 -24 59 -54 125 -66 146
l-23 38 51 -7 51 -6 -39 73 c-21 41 -61 139 -90 219 -60 165 -79 206 -127 273
l-34 49 43 -7 c24 -3 66 -13 93 -22z m5155 -45 c-39 -63 -57 -107 -126 -293
-20 -55 -53 -134 -75 -174 -21 -41 -37 -76 -36 -77 1 -1 22 1 47 6 25 5 46 7
48 5 2 -1 -7 -19 -19 -39 -11 -20 -43 -88 -69 -151 -54 -130 -89 -180 -158
-224 -64 -41 -71 -39 -32 7 89 106 190 289 190 344 0 4 -22 8 -50 8 l-50 0 35
63 c19 34 61 125 94 203 32 77 77 177 100 222 38 76 40 82 21 82 -35 0 -96
-37 -162 -98 -169 -154 -160 -148 -170 -122 -11 29 5 54 87 134 88 88 242 164
332 165 l32 1 -39 -62z m-4808 -58 l-27 -86 -47 48 -47 48 47 25 c26 13 58 33
72 44 14 11 26 17 27 13 2 -4 -10 -45 -25 -92z m4446 39 l53 -31 -43 -47 c-24
-26 -45 -45 -48 -43 -3 2 -12 29 -21 60 -9 31 -22 72 -28 90 -10 34 -10 34 12
18 12 -9 46 -30 75 -47z m-4733 -37 c17 -10 62 -48 100 -83 39 -35 91 -82 117
-104 l47 -40 -7 -93 -7 -94 -60 111 c-67 125 -91 162 -76 121 19 -54 72 -163
105 -218 28 -47 36 -73 45 -152 15 -120 43 -241 76 -321 14 -34 25 -65 25 -68
0 -12 -77 71 -130 141 -58 76 -111 176 -124 231 l-7 29 57 -8 c32 -4 55 -3 53
2 -2 5 -21 38 -41 74 -20 36 -66 133 -101 216 -36 83 -79 179 -95 213 -33 65
-28 74 23 43z m4976 -39 c-16 -32 -54 -119 -86 -193 -64 -151 -108 -242 -136
-282 -11 -14 -19 -29 -19 -32 0 -3 24 -6 54 -6 l55 0 -9 -29 c-16 -57 -50
-123 -100 -194 -59 -83 -137 -173 -145 -166 -3 3 6 34 20 68 33 81 62 203 75
319 8 73 16 102 40 141 36 58 113 221 108 227 -3 2 -23 -31 -46 -74 -23 -42
-52 -94 -65 -116 l-24 -38 -7 93 -6 94 27 20 c16 11 68 58 118 103 79 74 143
120 168 122 4 0 -6 -26 -22 -57z m-5340 -240 c57 -175 129 -366 180 -480 l50
-113 -82 -121 c-44 -66 -82 -119 -84 -117 -7 7 -65 219 -80 296 -9 45 -17 129
-18 187 -1 58 -5 105 -8 105 -4 0 -12 -7 -19 -15 -27 -33 -33 -16 -26 77 9
137 36 278 51 278 2 0 18 -44 36 -97z m5747 -28 c6 -38 14 -113 18 -165 7 -93
6 -95 -12 -79 -42 35 -48 35 -41 -6 8 -47 -8 -224 -29 -313 -22 -96 -66 -242
-73 -242 -3 1 -41 54 -84 120 l-78 118 19 39 c36 70 120 287 176 453 31 91 59
172 62 180 9 25 31 -27 42 -105z m-5889 -71 c-27 -79 -29 -84 -24 -40 3 27 5
80 5 118 l0 70 24 -30 25 -29 -30 -89z m5984 21 l6 -110 -23 76 c-12 41 -26
81 -30 88 -5 8 1 22 15 37 12 13 23 23 24 21 1 -1 5 -51 8 -112z m-5946 -92
c-6 -68 -7 -126 -3 -130 4 -4 16 -2 26 5 11 7 22 12 25 12 3 0 5 -35 5 -79 0
-43 7 -116 15 -163 13 -76 79 -320 92 -341 3 -5 46 51 95 125 94 138 101 138
47 -2 -32 -84 -119 -242 -136 -247 -6 -2 -25 24 -42 59 -51 107 -84 256 -99
447 -4 48 -12 86 -18 88 -6 2 -26 -8 -43 -22 l-31 -27 0 49 c1 63 23 184 51
278 12 41 23 74 24 73 1 -2 -2 -58 -8 -125z m5937 -106 c9 -50 16 -108 16
-128 l0 -38 -36 24 c-20 14 -40 23 -44 20 -4 -2 -10 -46 -14 -97 -7 -107 -29
-237 -52 -317 -25 -86 -72 -183 -86 -179 -31 10 -186 328 -176 360 2 5 41 -47
88 -116 46 -70 87 -123 91 -119 13 13 78 249 94 341 8 46 15 120 15 163 0 44
3 79 7 79 3 0 14 -7 24 -16 16 -14 18 -14 24 6 6 20 3 98 -10 215 -7 69 42
-95 59 -198z m-5434 123 c0 -5 -10 -10 -22 -9 -22 0 -22 1 -3 9 11 5 21 9 23
9 1 1 2 -3 2 -9z m4910 -10 c12 -8 11 -10 -7 -10 -13 0 -23 5 -23 10 0 13 11
13 30 0z m547 -52 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m-6030 -10 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m6040 -50
c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-6057 -49 c-5 -35 -10
-95 -10 -134 l0 -70 36 43 c20 23 39 42 44 42 4 0 11 -24 14 -52 4 -29 9 -75
12 -102 l6 -48 -46 31 c-26 18 -67 41 -91 52 -25 11 -45 22 -45 25 0 3 13 42
29 87 16 45 34 108 41 140 17 75 24 67 10 -14z m5595 31 c42 -17 81 -27 87
-24 7 5 9 2 6 -7 -44 -110 -62 -149 -67 -147 -4 2 -44 18 -89 37 -72 30 -81
37 -76 55 3 12 9 24 13 28 3 3 43 -9 88 -27 51 -20 85 -29 89 -23 11 17 0 23
-143 79 -20 7 -21 11 -11 34 6 14 15 25 19 25 5 0 43 -14 84 -30z m534 -203
c16 -41 15 -44 -31 -65 -24 -11 -63 -33 -88 -50 -24 -17 -46 -30 -47 -28 -4 4
18 191 24 196 3 3 25 -17 50 -45 l44 -50 -5 50 c-3 28 -8 84 -11 125 l-5 75
29 -90 c15 -49 33 -103 40 -118z m-5527 138 c0 -8 1 -20 2 -26 1 -6 -30 -24
-69 -40 -38 -16 -75 -36 -81 -44 -9 -12 -14 -7 -28 22 -9 19 -16 36 -14 38 14
9 157 63 171 64 9 1 18 -6 19 -14z m32 -81 c3 -9 6 -21 6 -28 0 -11 -80 -53
-156 -83 -9 -3 -39 45 -32 53 5 4 162 72 169 73 4 1 10 -6 13 -15z m4891 -26
c44 -18 82 -33 84 -35 6 -4 -21 -64 -28 -61 -3 2 -41 20 -85 40 -60 29 -77 41
-72 54 3 8 6 20 6 25 0 14 9 12 95 -23z m-4835 -87 c0 -3 -39 -24 -86 -47
l-86 -40 -14 27 c-17 34 -17 39 -1 39 6 1 44 16 82 34 70 34 70 34 88 14 9
-11 17 -23 17 -27z m4825 -11 c28 -13 51 -24 53 -25 3 -3 -15 -42 -23 -53 -1
-1 -41 16 -89 38 -87 40 -90 43 -63 77 10 13 9 13 122 -37z m-5499 -52 c69
-41 90 -64 99 -107 l7 -35 -73 63 c-40 35 -97 79 -126 99 -29 19 -53 36 -53
38 0 10 100 -30 146 -58z m6118 11 c-32 -23 -90 -68 -127 -102 l-68 -60 7 39
c7 41 11 44 83 96 44 31 123 67 150 68 8 0 -12 -19 -45 -41z m-5393 2 l23 -19
-102 -51 -101 -51 -15 28 -14 27 86 42 c48 23 90 42 93 42 4 1 17 -7 30 -18z
m4703 -32 l85 -41 -14 -28 -14 -27 -100 51 c-94 48 -99 52 -84 68 22 25 30 23
127 -23z m-4596 -32 l43 -23 -31 -23 c-69 -51 -89 -130 -49 -201 57 -100 200
-106 264 -10 l16 25 -5 -25 c-4 -14 -9 -45 -12 -70 -3 -25 -7 -55 -10 -68 l-5
-23 -34 30 c-66 57 -157 51 -215 -15 -40 -46 -58 -33 -30 21 25 48 25 78 1
131 -24 52 -70 85 -127 92 -40 4 -47 9 -65 43 -18 37 -18 39 -2 40 10 0 61 22
113 49 52 27 97 49 100 49 3 0 24 -10 48 -22z m4518 -37 c51 -27 100 -50 109
-50 13 0 13 -5 -5 -40 -18 -36 -24 -40 -59 -40 -104 0 -178 -130 -128 -226 21
-41 6 -67 -18 -30 -26 39 -77 66 -125 66 -48 0 -104 -24 -125 -53 -15 -21 -13
-26 -31 91 -3 20 -8 46 -11 56 -3 12 7 6 27 -17 74 -85 203 -68 255 34 33 63
8 153 -54 196 l-32 22 43 20 c24 10 47 20 52 20 5 1 51 -21 102 -49z m-4845
-172 c-9 -15 -20 -34 -38 -67 -2 -3 -62 31 -102 57 -11 8 -6 27 25 100 l38 91
45 -81 c36 -67 42 -84 32 -100z m5134 91 c20 -49 36 -92 34 -93 -13 -12 -104
-66 -111 -66 -4 0 -8 6 -8 14 0 7 -10 25 -21 40 l-22 27 44 85 c24 46 44 84
45 84 1 0 18 -41 39 -91z m-4682 26 c36 -60 36 -113 1 -154 -14 -17 -21 -31
-14 -31 18 0 70 56 70 75 0 14 3 15 15 5 13 -11 13 -15 0 -41 -19 -37 -63 -59
-115 -59 -32 0 -48 7 -78 34 -34 32 -37 38 -37 91 0 53 3 59 37 91 54 49 86
46 121 -11z m4208 30 c38 -20 69 -71 69 -113 0 -127 -158 -173 -234 -69 -19
26 -19 28 -3 44 15 16 17 16 17 1 0 -23 49 -78 68 -77 11 0 12 2 4 6 -20 8
-42 59 -42 97 0 43 48 125 73 126 10 0 31 -7 48 -15z m-5262 -64 c65 -25 189
-111 269 -188 35 -35 68 -63 73 -63 4 0 38 -23 75 -51 37 -28 91 -64 121 -81
l53 -30 -26 -21 -26 -20 -46 22 c-86 42 -151 92 -290 223 -75 71 -170 152
-210 179 -77 51 -75 61 7 30z m6223 -41 c-39 -28 -122 -99 -184 -158 -147
-139 -187 -172 -279 -221 -70 -38 -77 -40 -97 -25 -12 8 -22 19 -22 24 0 4 24
21 53 38 28 16 81 51 117 78 36 27 70 47 75 46 6 -1 17 9 25 23 24 38 198 172
274 211 103 51 123 43 38 -16z m-5451 -35 c60 -31 85 -106 54 -165 -52 -100
-198 -91 -233 15 -11 34 -11 46 4 83 28 73 105 103 175 67z m4787 1 c86 -44
102 -151 31 -210 -42 -36 -90 -43 -135 -19 -95 49 -96 180 -3 226 42 20 72 21
107 3z m-5020 -88 c13 -7 22 -20 22 -34 0 -12 3 -30 7 -40 5 -14 -10 -9 -63
20 -93 51 -101 59 -86 91 l12 26 43 -26 c23 -14 52 -31 65 -37z m5266 27 c16
-25 16 -25 -36 -58 -107 -68 -117 -70 -109 -22 5 29 16 40 61 69 30 19 58 34
61 35 4 1 14 -10 23 -24z m-4465 -140 c11 -11 10 -16 -8 -34 -14 -14 -16 -21
-8 -21 8 0 22 9 31 19 10 11 24 17 32 14 22 -8 -9 -39 -53 -54 -28 -9 -43 -8
-80 6 -71 27 -102 101 -72 169 l11 24 67 -55 c36 -31 72 -61 80 -68z m3701 89
c34 -89 -38 -184 -130 -171 -45 6 -95 36 -82 49 5 5 25 0 48 -13 l39 -23 -25
28 -24 27 34 27 c19 15 53 44 75 64 22 21 43 38 48 38 4 0 12 -12 17 -26z
m-3984 -80 c41 -20 64 -59 64 -109 0 -74 -49 -125 -121 -125 -104 0 -163 106
-106 191 39 59 98 74 163 43z m4241 -9 c28 -19 53 -71 53 -112 0 -61 -66 -123
-132 -123 -31 0 -83 33 -104 65 -9 14 -17 44 -17 66 0 99 116 159 200 104z
m-3801 -71 c23 -9 75 -55 132 -115 109 -115 192 -183 282 -232 108 -59 170
-59 170 0 0 30 -80 117 -157 170 -43 30 -51 39 -37 43 42 15 165 -63 217 -137
25 -34 28 -47 23 -78 -16 -82 -66 -93 -191 -42 -179 74 -321 190 -474 390 -17
21 -13 21 35 1z m3210 -73 c-120 -144 -227 -229 -383 -303 -152 -74 -228 -61
-228 36 0 34 7 52 36 87 46 55 134 109 191 116 l43 5 -70 -49 c-83 -59 -155
-135 -155 -162 0 -39 11 -51 49 -51 90 0 290 140 428 300 45 53 76 79 106 91
23 8 44 14 46 13 2 -2 -26 -39 -63 -83z m-3218 15 c169 -207 394 -363 541
-374 l66 -4 -65 -67 c-74 -75 -147 -173 -181 -243 -13 -27 -28 -48 -35 -48
-21 0 -135 55 -209 100 -96 59 -217 155 -299 238 l-68 70 36 4 c33 4 35 2 30
-17 -5 -15 2 -12 31 15 80 75 95 93 104 125 5 19 24 55 42 80 18 25 30 48 27
51 -4 3 -16 -8 -29 -25 -28 -38 -35 -39 -42 -4 -3 18 1 36 14 52 11 13 17 27
13 30 -3 3 -14 -5 -25 -18 -16 -20 -20 -21 -25 -7 -3 9 -1 23 6 31 7 9 10 18
6 22 -3 4 -12 -2 -20 -12 -12 -17 -17 -18 -40 -6 l-25 14 37 28 c20 15 42 28
48 28 6 1 34 -28 62 -63z m3339 38 c50 -34 49 -32 27 -48 -17 -13 -22 -12 -43
7 -23 21 -24 21 -16 2 15 -40 17 -55 7 -55 -5 0 -16 10 -25 23 -8 12 -18 19
-22 15 -4 -4 2 -19 13 -33 12 -15 19 -33 16 -41 -15 -50 -14 -50 -36 -16 -11
17 -24 32 -29 32 -14 0 -11 -7 25 -57 18 -27 36 -61 40 -77 4 -17 33 -55 66
-88 60 -59 77 -70 66 -43 -4 12 3 15 30 15 l35 0 -26 -34 c-32 -43 -186 -177
-266 -233 -59 -40 -208 -122 -261 -142 -24 -9 -28 -6 -79 81 -33 55 -88 127
-141 183 l-87 92 72 5 c83 6 141 29 241 96 78 52 255 218 313 293 20 27 38 49
40 49 1 0 19 -12 40 -26z m-3087 -55 c122 -27 134 -33 82 -37 l-44 -3 89 -60
c111 -76 183 -145 183 -177 0 -21 -3 -23 -32 -17 -94 20 -322 190 -401 299
l-21 29 25 -7 c13 -3 67 -16 119 -27z m2789 -73 c-108 -107 -216 -185 -290
-211 -56 -20 -69 -19 -69 5 0 29 79 105 184 175 l88 60 -43 6 c-37 4 -40 6
-19 11 140 36 228 56 240 57 8 0 -33 -46 -91 -103z m-3789 66 c37 -20 70 -72
70 -110 0 -80 -86 -145 -161 -123 -43 13 -84 57 -94 100 -10 45 24 113 66 134
44 22 76 21 119 -1z m4835 8 c7 0 27 -12 44 -26 118 -99 -16 -284 -147 -204
-66 41 -79 136 -25 192 28 29 77 50 101 42 8 -2 20 -4 27 -4z m-4238 -30 c45
-27 65 -63 65 -112 -2 -100 -113 -157 -199 -101 -74 49 -68 166 10 213 41 25
83 25 124 0z m3659 1 c68 -31 94 -114 54 -178 -37 -61 -115 -80 -175 -43 -29
18 -41 36 -58 92 -16 47 32 121 89 138 41 12 44 12 90 -9z m-2896 -36 l85 -13
-85 0 c-66 -1 -93 3 -120 18 l-35 19 35 -5 c19 -3 73 -11 120 -19z m2164 15
c-13 -13 -116 -30 -174 -29 -33 1 -15 6 65 19 61 10 112 18 115 19 2 0 -1 -4
-6 -9z m-4079 -34 c96 -37 174 -89 350 -236 193 -160 280 -222 380 -271 111
-53 219 -79 335 -81 85 -1 90 -2 50 -10 -25 -5 -99 -7 -165 -5 -204 6 -309 56
-594 282 -258 205 -368 273 -523 324 -38 13 -68 25 -68 28 0 16 171 -7 235
-31z m6012 -6 c-155 -53 -260 -117 -502 -310 -88 -69 -181 -141 -207 -159 -77
-52 -208 -110 -283 -126 -74 -16 -204 -19 -270 -6 -34 6 -24 8 60 13 126 8
196 27 310 82 107 52 194 113 375 265 257 216 362 269 530 270 l75 1 -88 -30z
m-3952 -20 c126 -14 316 -43 311 -47 -2 -2 -35 -12 -75 -23 -39 -11 -103 -34
-142 -52 l-72 -32 69 -21 c115 -35 118 -41 33 -55 -81 -14 -186 -48 -262 -87
l-48 -24 39 53 c22 29 45 70 50 90 18 65 -9 134 -72 183 l-31 25 55 0 c30 0
96 -5 145 -10z m1883 -34 c-87 -87 -85 -173 9 -276 l35 -40 -83 39 c-97 45
-170 68 -254 79 l-60 8 42 22 c22 12 60 24 82 28 23 4 41 10 41 14 0 19 -215
99 -269 101 -110 2 297 63 458 68 l44 1 -45 -44z m-3059 -51 c69 -59 59 -170
-20 -211 -107 -55 -229 60 -174 166 38 73 134 95 194 45z m4232 2 c39 -26 66
-89 56 -129 -26 -101 -156 -135 -221 -57 -95 112 43 268 165 186z m-2519 -30
l73 -34 -35 -6 c-19 -3 -103 -17 -187 -31 l-152 -26 -61 29 c-34 16 -56 32
-51 36 52 31 259 78 313 70 16 -3 61 -20 100 -38z m914 18 c33 -8 86 -24 118
-36 l58 -21 -58 -26 c-72 -31 -82 -31 -264 -2 -201 31 -200 30 -99 72 91 39
119 40 245 13z m-3599 -83 c27 -10 93 -46 147 -81 159 -100 297 -161 425 -186
19 -4 48 -16 65 -26 25 -15 27 -19 12 -25 -9 -4 -47 -4 -84 0 -152 16 -251 57
-460 194 -68 44 -148 91 -179 104 -43 17 -50 24 -36 29 30 12 61 10 110 -9z
m1113 -27 c144 -175 334 -320 521 -397 79 -32 92 -45 64 -68 -11 -9 -35 -1
-124 45 -138 72 -292 188 -415 312 -77 77 -96 102 -96 125 0 34 12 30 50 -17z
m1725 8 l40 -28 -59 -7 c-143 -18 -271 -116 -397 -302 -33 -49 -74 -116 -90
-148 -16 -32 -32 -58 -35 -58 -12 0 -34 73 -34 117 l0 47 -67 -66 c-101 -98
-296 -267 -304 -262 -4 2 -10 24 -14 50 -6 44 -8 45 -33 39 -48 -12 -116 -57
-187 -126 -67 -65 -88 -76 -206 -115 -10 -4 -18 1 -21 14 -5 18 -12 19 -76 14
-94 -6 -125 7 -153 65 -28 57 -22 137 14 209 27 53 114 141 165 168 31 16 32
16 137 -36 58 -29 105 -56 105 -59 0 -4 -58 -40 -130 -80 -71 -40 -128 -75
-126 -76 2 -2 69 30 149 72 80 42 149 75 154 74 24 -4 42 3 47 19 4 10 113 72
275 157 149 76 268 141 266 143 -2 2 -115 -52 -252 -120 -136 -67 -252 -125
-256 -127 -14 -5 22 59 70 126 49 70 88 96 219 149 192 78 493 147 749 173 6
1 28 -11 50 -26z m2125 4 c0 -45 -211 -247 -370 -354 -79 -53 -239 -135 -257
-131 -10 2 -18 12 -18 22 0 14 22 28 85 55 170 73 299 168 450 329 52 56 98
102 103 102 4 0 7 -10 7 -23z m1184 16 c3 -4 -13 -14 -36 -23 -24 -9 -110 -59
-193 -112 -82 -52 -173 -107 -202 -121 -81 -42 -188 -67 -278 -67 -74 1 -78 2
-60 16 11 8 45 22 75 30 184 50 283 94 427 188 40 26 96 58 125 71 50 23 127
33 142 18z m-2755 -19 c224 -33 411 -80 596 -152 106 -41 127 -53 176 -102 43
-43 118 -151 119 -171 0 -2 -120 55 -267 128 -148 73 -272 132 -278 132 -5 1
116 -65 270 -144 168 -88 287 -155 298 -170 12 -15 29 -25 44 -25 14 0 84 -29
154 -65 71 -36 129 -64 129 -61 0 3 -54 35 -120 72 l-119 68 42 24 c23 13 71
39 107 57 l65 33 36 -18 c147 -76 234 -254 181 -374 -32 -71 -90 -88 -215 -63
-6 1 -13 -8 -15 -20 l-3 -21 -59 24 c-33 13 -66 24 -73 24 -8 0 -47 33 -88 73
-68 66 -140 114 -196 131 -19 6 -22 2 -28 -39 -4 -25 -11 -48 -16 -51 -10 -6
-143 110 -281 243 l-88 85 0 -46 c0 -25 -7 -64 -16 -87 l-17 -41 -47 85 c-148
265 -293 398 -467 431 l-66 12 34 22 c41 26 69 27 208 6z m-2252 -165 c-15 -8
-161 -35 -287 -53 -84 -13 -98 -12 -119 1 -24 15 -24 16 -5 30 53 40 91 52
128 42 55 -15 108 3 153 55 l37 43 52 -56 c33 -35 48 -58 41 -62z m3901 48
c28 -26 92 -39 132 -27 33 9 73 -5 124 -43 19 -14 19 -15 -5 -30 -22 -14 -35
-13 -144 3 -117 17 -221 36 -259 47 -15 4 -9 14 40 64 l58 58 16 -27 c10 -15
26 -35 38 -45z m-2115 46 c-7 -2 -19 -2 -25 0 -7 3 -2 5 12 5 14 0 19 -2 13
-5z m204 1 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-2352 -21
c11 -14 5 -22 -48 -56 -59 -38 -60 -38 -79 -19 -20 19 -19 20 38 56 66 41 72
42 89 19z m2123 1 c53 -6 92 -25 92 -46 0 -12 -93 -1 -205 24 -69 16 -85 23
-70 29 19 8 52 7 183 -7z m390 10 c43 -12 21 -22 -90 -43 -121 -23 -168 -26
-168 -12 0 19 29 30 115 44 111 18 115 18 143 11z m2061 -47 l44 -30 -22 -18
c-21 -17 -23 -17 -81 24 -56 38 -59 42 -44 59 19 20 27 17 103 -35z m-2552 -6
c54 -11 119 -23 146 -26 l47 -6 0 -202 0 -203 -38 4 -37 4 12 141 c14 169 15
261 4 232 -5 -11 -14 -100 -21 -197 l-13 -178 -41 6 c-170 23 -274 49 -398 96
-87 33 -86 27 -23 113 41 55 39 58 19 -49 -4 -24 -3 -37 3 -33 5 3 18 44 27
91 15 70 24 90 51 116 l32 31 -33 -123 c-29 -112 -34 -141 -18 -126 3 3 21 68
41 143 36 133 38 138 77 167 22 16 45 27 52 24 7 -3 57 -14 111 -25z m667
-176 c21 -80 42 -142 46 -140 5 3 -7 62 -25 133 l-34 127 33 -37 c24 -27 37
-57 50 -115 20 -85 35 -123 36 -88 0 11 -5 37 -11 58 -17 63 -6 62 40 -2 l42
-61 -45 -19 c-89 -39 -213 -73 -338 -94 -70 -11 -130 -19 -132 -16 -2 2 -10
81 -16 174 -7 94 -17 179 -21 190 -11 29 -10 -66 4 -227 13 -149 13 -148 -39
-148 l-24 0 0 204 0 204 65 7 c36 4 107 17 158 29 l92 22 40 -28 c40 -28 42
-31 79 -173z m-2263 100 l29 -24 -37 -10 c-55 -15 -155 -29 -254 -37 -73 -6
-90 -4 -123 13 l-38 20 118 17 c66 9 144 23 174 30 80 20 99 19 131 -9z m3724
6 c22 -5 87 -17 145 -26 58 -8 120 -18 139 -21 l35 -6 -41 -18 c-37 -16 -54
-17 -150 -9 -97 8 -251 34 -260 43 -9 8 61 56 76 52 9 -3 34 -9 56 -15z
m-4902 -16 c74 -19 137 -51 228 -117 41 -29 96 -64 122 -78 51 -27 51 -27 37
-65 l-10 -25 -59 29 c-32 17 -100 63 -150 103 -50 40 -128 94 -174 120 -87 51
-86 57 6 33z m5987 -35 c-41 -22 -120 -76 -174 -120 -96 -77 -199 -140 -210
-129 -3 3 -8 19 -11 35 -6 26 -2 31 36 49 24 11 79 46 123 78 97 70 139 93
209 112 98 26 108 17 27 -25z m-4703 -45 c25 -19 44 -35 42 -36 -47 -9 -213
-14 -270 -8 -129 15 -136 24 -29 38 53 6 118 18 145 26 28 7 54 14 58 14 5 1
29 -15 54 -34z m3538 16 c50 -11 122 -23 160 -27 l70 -7 -50 -14 c-54 -15
-285 -21 -325 -8 -23 7 -22 8 16 41 21 18 39 33 39 34 1 0 41 -9 90 -19z
m-4286 -47 c2 -2 -1 -10 -7 -17 -7 -8 -41 -87 -76 -176 -62 -155 -108 -244
-143 -273 -10 -8 -6 6 11 40 14 28 44 114 66 190 22 77 49 165 61 196 22 55
23 57 53 50 17 -3 33 -8 35 -10z m212 -28 c41 -19 103 -40 139 -46 36 -7 74
-16 85 -20 18 -6 17 -8 -10 -22 -42 -21 -167 -38 -283 -38 -87 0 -172 13 -172
26 0 2 13 31 30 64 l30 59 52 4 c29 2 53 4 54 5 1 1 34 -13 75 -32z m4689 -37
c17 -32 30 -61 30 -63 0 -14 -87 -26 -180 -25 -125 0 -246 18 -279 41 l-24 17
24 5 c13 2 51 10 85 16 33 7 93 26 133 44 59 25 84 30 127 28 l55 -4 29 -59z
m94 4 c14 -38 41 -122 60 -188 19 -66 46 -147 60 -180 l27 -60 -25 30 c-39 46
-80 129 -129 256 -25 64 -54 134 -66 157 -19 38 -19 41 -3 45 44 12 50 7 76
-60z m-4164 33 c0 -2 -19 -15 -43 -29 -58 -34 -134 -117 -160 -175 -28 -61
-30 -162 -4 -214 30 -59 59 -73 150 -72 l79 1 -6 -29 -6 -28 48 24 c71 36 149
56 216 56 62 0 239 -20 233 -26 -2 -2 -82 -11 -179 -19 -167 -14 -298 -41
-313 -65 -12 -19 -25 -10 -25 18 0 43 -13 56 -40 42 -34 -18 -104 -6 -151 24
-22 15 -46 25 -52 23 -17 -5 91 -65 130 -73 18 -3 47 -1 64 5 l31 11 -4 -53
-4 -53 48 32 c60 41 148 60 334 74 201 15 368 63 467 132 16 11 30 18 33 15 9
-9 -51 -134 -103 -214 -95 -145 -234 -246 -418 -305 -55 -18 -102 -34 -104
-35 -7 -5 23 -36 52 -53 l27 -17 -72 -21 c-116 -35 -187 -45 -278 -39 -86 5
-168 27 -205 54 l-20 15 20 -7 c31 -11 314 -17 363 -8 32 6 3 9 -110 10 -225
3 -293 14 -333 53 -76 73 -122 217 -110 340 18 175 146 425 268 522 64 51 177
104 177 84z m3328 -31 c201 -94 386 -455 353 -691 -12 -80 -56 -177 -104 -225
-40 -41 -68 -46 -322 -58 l-140 -6 85 -8 c49 -4 128 -3 189 3 57 6 105 9 107
7 3 -2 -19 -15 -48 -28 -48 -22 -66 -24 -198 -24 -132 1 -153 3 -229 29 l-84
28 32 15 c37 18 59 51 39 58 -7 2 -35 10 -63 18 -238 69 -421 221 -515 431
-15 33 -31 72 -35 85 l-7 24 28 -20 c107 -75 264 -119 498 -139 191 -16 249
-29 319 -73 l47 -31 -6 28 c-7 29 -7 28 -5 55 1 15 7 17 36 11 50 -9 94 2 149
37 65 40 52 48 -16 10 -56 -32 -126 -41 -158 -21 -19 12 -30 -5 -30 -47 l0
-32 -32 19 c-53 31 -141 49 -298 60 -215 16 -228 23 -69 39 154 15 206 9 308
-39 35 -17 65 -30 67 -30 2 0 1 12 -2 28 l-6 27 74 -3 c84 -4 126 15 153 67
65 128 -11 300 -181 408 -35 22 8 14 64 -12z m-3561 -78 c-34 -34 -78 -91 -97
-125 -19 -35 -41 -70 -48 -78 -13 -14 -112 -21 -112 -7 0 22 74 114 146 182
74 70 89 80 127 84 23 2 43 4 44 5 2 1 -25 -26 -60 -61z m3759 24 c75 -55 204
-202 204 -232 0 -5 -24 -9 -52 -9 l-53 0 -40 72 c-22 39 -67 100 -100 135
l-60 63 31 0 c19 0 47 -12 70 -29z m-4788 -41 c89 -35 232 -126 232 -149 0 -9
-3 -26 -6 -38 -6 -22 -8 -21 -58 21 -64 54 -154 114 -248 167 -69 38 -71 40
-33 34 22 -4 73 -19 113 -35z m865 -23 c-71 -74 -109 -131 -189 -287 -41 -81
-75 -131 -113 -170 -34 -34 -56 -50 -59 -42 -5 16 41 148 76 217 71 141 184
264 297 323 22 12 42 21 44 22 2 0 -23 -28 -56 -63z m1609 -21 c106 -27 283
-56 347 -56 35 0 41 -3 41 -20 0 -24 -14 -23 -230 12 -112 18 -399 84 -409 94
-2 2 1 15 8 28 l13 23 67 -28 c37 -15 110 -39 163 -53z m2511 -4 c67 -52 141
-147 191 -242 41 -78 90 -214 84 -232 -3 -8 -25 8 -59 42 -38 39 -72 89 -113
170 -80 156 -118 213 -190 288 l-61 65 45 -23 c25 -13 71 -43 103 -68z m-2866
1 c-2 -2 -15 5 -28 16 -24 19 -24 19 -4 41 l20 22 8 -38 c4 -20 6 -39 4 -41z
m1413 56 c7 -11 9 -23 7 -26 -20 -19 -430 -106 -564 -119 -63 -6 -73 -4 -73 9
0 11 13 17 43 21 168 21 224 30 313 51 55 13 134 37 175 54 90 36 86 36 99 10z
m170 -18 c0 -4 -14 -15 -31 -25 l-31 -18 7 23 c4 13 9 32 12 42 5 18 6 18 24
1 10 -9 19 -20 19 -23z m2150 8 c-87 -50 -182 -113 -244 -163 l-56 -46 -10 25
c-15 40 -14 41 48 83 74 51 153 91 222 113 85 28 102 23 40 -12z m-5275 -24
c66 0 139 3 163 8 56 10 48 -3 -22 -36 -65 -31 -162 -50 -214 -42 -37 6 -124
26 -130 30 -1 1 2 14 8 29 9 25 13 27 43 19 17 -4 86 -8 152 -8z m4600 0 c66
0 136 3 156 8 32 7 37 5 48 -19 6 -15 10 -28 8 -31 -13 -13 -159 -34 -202 -29
-60 8 -151 40 -185 66 l-25 19 40 -7 c22 -4 94 -7 160 -7z m-2955 -142 c0 -26
-20 -19 -109 37 -113 71 -110 69 -88 85 16 12 29 6 107 -48 49 -34 89 -67 90
-74z m1520 122 c16 -19 21 -15 -91 -85 -89 -56 -109 -63 -109 -37 1 11 165
134 181 136 4 1 12 -6 19 -14z m-1493 -11 c21 -6 21 -7 6 -40 -19 -38 -21 -39
-47 -19 -16 12 -18 19 -8 40 12 27 15 28 49 19z m1315 -19 c10 -21 8 -28 -8
-40 -27 -20 -30 -19 -44 16 -11 26 -11 32 1 40 24 15 39 10 51 -16z m-1196 0
c78 -24 292 -64 382 -71 l82 -7 -6 -51 c-4 -28 -17 -88 -30 -134 -13 -45 -24
-86 -24 -90 0 -4 -8 6 -19 21 -22 34 -61 63 -61 47 0 -21 -90 -151 -143 -208
-66 -70 -135 -120 -225 -166 -39 -20 -74 -36 -76 -36 -3 0 8 30 24 66 43 96
69 209 79 342 5 64 7 120 5 123 -4 6 -89 -5 -126 -17 -11 -3 -19 -2 -17 4 32
82 86 192 94 192 7 0 34 -7 61 -15z m1141 -85 c23 -52 41 -95 40 -97 -2 -1
-33 3 -69 10 -37 6 -70 8 -74 5 -10 -11 15 -240 36 -323 11 -44 34 -108 50
-142 16 -35 25 -63 19 -63 -5 0 -40 15 -77 34 -81 41 -192 127 -244 189 -35
42 -111 154 -122 180 -3 7 -18 -3 -40 -26 l-35 -39 -20 69 c-23 80 -44 193
-37 200 3 3 44 9 93 15 98 10 304 50 373 72 25 8 49 13 55 12 5 -2 29 -45 52
-96z m-1359 -25 c1 -1 -4 -7 -11 -13 -10 -9 -31 1 -90 44 -43 31 -75 60 -72
65 4 6 141 -69 173 -96z m1641 82 c-101 -77 -177 -116 -167 -86 6 18 153 107
162 98 5 -5 8 -10 5 -12z m-3224 -14 c44 2 89 8 99 12 15 6 11 -3 -15 -39
l-34 -47 -95 6 c-52 3 -116 11 -141 18 l-46 12 13 31 13 32 63 -15 c40 -10 92
-13 143 -10z m4853 -12 c10 -29 10 -30 -37 -40 -26 -5 -88 -13 -137 -17 l-90
-7 -39 49 c-27 33 -33 45 -20 39 37 -15 197 -17 250 -2 28 8 53 12 57 11 3 -2
11 -17 16 -33z m-3390 -39 c66 -47 73 -57 43 -67 -13 -4 -43 9 -90 40 -39 25
-71 49 -71 52 0 11 35 31 45 25 5 -3 38 -25 73 -50z m1813 32 c15 -18 4 -28
-89 -85 -48 -29 -55 -31 -69 -16 -14 14 -12 18 32 49 26 18 57 41 69 50 26 22
40 22 57 2z m-2111 -93 c10 -46 16 -58 29 -54 10 3 23 -7 35 -28 l19 -32 -69
7 c-38 3 -104 9 -146 13 -43 3 -78 9 -78 14 0 19 164 138 191 139 4 0 12 -26
19 -59z m1186 -21 c7 -41 25 -109 39 -150 15 -41 29 -83 32 -93 4 -13 -8 -32
-38 -63 -24 -24 -40 -44 -36 -44 4 0 43 18 87 40 44 22 82 40 85 40 5 0 35
-55 35 -64 0 -3 -37 -33 -82 -68 -46 -34 -100 -78 -121 -97 l-38 -34 -37 35
c-20 19 -74 63 -119 97 -46 34 -83 64 -83 67 0 9 30 64 36 64 2 0 26 -11 52
-24 26 -13 64 -31 85 -39 l38 -16 -41 48 -40 47 29 80 c16 43 34 108 41 144
20 115 19 112 42 108 19 -3 24 -14 34 -78z m1189 51 c56 -33 135 -98 135 -112
0 -5 -21 -9 -46 -9 -25 0 -76 -5 -114 -11 -38 -6 -83 -9 -100 -7 l-32 3 22 28
c12 16 28 26 36 23 9 -4 17 12 25 54 6 33 14 60 18 60 3 0 29 -13 56 -29z
m-3660 -1 c33 -5 79 -10 103 -10 l43 0 -17 -31 -16 -31 -76 7 c-78 7 -173 22
-179 29 -2 2 2 16 9 31 10 23 16 26 42 20 17 -3 58 -10 91 -15z m1518 -26 l71
-46 -48 -25 c-27 -14 -51 -21 -55 -16 -55 84 -72 114 -66 123 10 17 23 12 98
-36z m1940 40 c4 -4 -60 -114 -77 -132 -2 -2 -26 8 -52 21 l-48 24 69 46 c67
43 95 54 108 41z m1546 -30 c6 -15 10 -28 9 -29 -9 -8 -144 -25 -196 -25 -52
0 -63 3 -76 22 -9 12 -16 26 -16 30 0 5 37 9 83 9 45 1 96 5 112 9 56 14 71
11 84 -16z m-3261 3 c7 -7 7 -18 2 -27 -7 -11 -11 -11 -24 -1 -8 7 -12 19 -9
27 6 17 17 18 31 1z m1415 -1 c3 -8 -1 -20 -9 -27 -13 -10 -17 -10 -24 1 -5 9
-5 20 2 27 14 17 25 16 31 -1z m-1733 -46 c35 -55 35 -54 3 -64 -18 -6 -27 1
-52 37 -28 41 -29 45 -13 60 9 10 19 17 23 17 4 0 21 -23 39 -50z m-1010 23
c0 -2 -5 -13 -10 -24 -8 -15 -21 -19 -60 -19 -53 0 -66 10 -51 37 7 15 19 17
65 15 31 -2 56 -6 56 -9z m3091 1 c17 -15 17 -18 -8 -55 -20 -30 -32 -39 -54
-39 -16 0 -29 3 -29 7 0 10 61 103 68 103 3 0 13 -7 23 -16z m1079 -31 c0 -9
-16 -13 -55 -13 -43 0 -56 4 -65 19 -15 29 -14 30 43 33 52 3 77 -9 77 -39z
m-2867 -23 c-3 -11 -11 -16 -19 -13 -15 6 -17 13 -8 37 9 23 33 1 27 -24z
m177 13 c-1 -70 -22 -225 -41 -295 -12 -43 -38 -111 -59 -153 -43 -86 -45 -86
67 -35 116 53 245 160 331 275 l23 30 -16 -40 c-78 -197 -260 -325 -461 -325
l-74 0 37 48 c48 62 86 148 112 252 21 85 38 210 29 210 -3 0 -30 -9 -61 -19
-32 -11 -57 -18 -57 -15 0 3 7 18 15 34 14 28 27 34 110 53 40 9 45 7 45 -20z
m1177 6 c36 -10 48 -18 58 -43 7 -17 12 -32 11 -34 -2 -1 -29 7 -61 18 l-58
20 7 -68 c14 -149 77 -325 148 -415 l32 -40 -84 6 c-149 11 -255 61 -354 168
-46 49 -116 167 -116 194 0 4 20 -20 45 -54 53 -70 122 -134 200 -186 59 -39
176 -95 198 -95 9 0 1 26 -25 78 -48 96 -83 237 -93 365 l-7 97 27 0 c14 0 47
-5 72 -11z m108 -29 c-9 -15 -22 -12 -29 7 -9 21 16 37 27 17 4 -8 5 -19 2
-24z m-1490 -40 c3 -5 1 -19 -5 -31 -15 -28 -11 -30 32 -17 46 14 88 -10 88
-51 0 -69 -70 -259 -119 -321 l-19 -24 -11 44 c-7 25 -25 87 -42 139 l-31 95
44 87 c43 87 51 98 63 79z m1529 -30 c8 -22 29 -60 44 -86 l29 -46 -19 -52
c-11 -28 -30 -88 -42 -134 -12 -46 -26 -83 -31 -84 -28 -1 -118 213 -131 312
-6 43 -4 46 26 63 25 15 36 17 62 8 38 -13 43 -10 28 18 -11 21 -6 41 10 41 4
0 15 -18 24 -40z m-1404 4 c0 -8 -4 -12 -10 -9 -5 3 -10 10 -10 16 0 5 5 9 10
9 6 0 10 -7 10 -16z m1264 -5 c-8 -14 -24 -10 -24 6 0 9 6 12 15 9 8 -4 12
-10 9 -15z m-779 -93 l16 -38 -30 22 c-29 20 -40 48 -24 64 9 9 20 -3 38 -48z
m314 32 c6 -18 3 -27 -16 -41 -32 -25 -37 -17 -19 27 17 42 24 44 35 14z
m-1019 -190 c29 -91 24 -93 -19 -8 -29 56 -36 78 -28 88 13 17 20 6 47 -80z
m1701 -14 c-22 -44 -40 -75 -41 -70 0 6 12 47 27 93 23 70 29 81 41 69 12 -11
7 -25 -27 -92z m-1034 -29 c40 -31 73 -58 73 -60 0 -8 -160 99 -160 108 0 14
14 6 87 -48z m436 35 c-17 -15 -198 -132 -187 -121 29 31 179 139 187 134 5
-3 5 -9 0 -13z m-497 -114 c63 -58 188 -196 154 -170 -8 6 -47 36 -86 65 -38
30 -95 70 -125 89 -30 18 -56 35 -58 37 -5 4 30 43 38 43 4 0 38 -29 77 -64z
m567 40 c9 -7 17 -16 17 -19 0 -4 -24 -22 -52 -40 -29 -18 -93 -64 -143 -102
l-90 -69 42 50 c48 58 189 194 201 194 4 0 15 -7 25 -14z"/>
<path d="M2775 7749 c-237 -141 -432 -309 -544 -470 -28 -40 -51 -69 -51 -66
0 3 14 42 30 86 17 45 44 118 61 164 16 45 26 78 21 72 -19 -22 -83 -177 -111
-272 -16 -55 -43 -128 -61 -162 -32 -64 -91 -229 -89 -252 0 -8 8 9 18 37 10
29 32 74 50 102 45 70 184 200 264 246 74 43 87 55 30 29 -108 -49 -216 -138
-275 -223 l-18 -25 10 28 c37 104 176 292 307 414 144 134 209 183 469 350 11
6 17 12 14 12 -3 1 -59 -31 -125 -70z"/>
<path d="M2583 7726 c-54 -57 -133 -171 -133 -191 0 -5 19 21 41 56 23 35 68
94 101 132 33 37 57 67 55 67 -3 0 -32 -29 -64 -64z"/>
<path d="M2540 7480 c-8 -5 -10 -10 -5 -10 6 0 17 5 25 10 8 5 11 10 5 10 -5
0 -17 -5 -25 -10z"/>
<path d="M2021 6814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M5296 7740 c33 -36 77 -93 99 -125 54 -82 63 -90 29 -26 -16 29 -64
90 -108 135 -55 57 -61 62 -20 16z"/>
<path d="M5300 7489 c172 -58 191 -64 169 -50 -20 13 -287 101 -302 100 -7 0
53 -23 133 -50z"/>
<path d="M5500 7256 c0 -2 28 -20 63 -42 34 -21 99 -75 145 -120 45 -46 82
-80 82 -77 0 20 -91 121 -143 160 -74 54 -147 94 -147 79z"/>
<path d="M4100 7260 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M3966 7207 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M3812 7175 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M4040 7110 c-12 -8 -11 -10 8 -10 12 0 22 5 22 10 0 13 -11 13 -30 0z"/>
<path d="M3763 7070 c-3 -11 -9 -18 -13 -15 -4 2 -16 -5 -26 -18 l-19 -22 23
19 c22 19 22 19 22 -2 0 -11 -5 -24 -10 -27 -6 -4 -9 -20 -8 -36 3 -33 28 -53
56 -46 14 4 13 5 -3 6 -11 1 -27 6 -35 12 -11 8 -5 9 23 4 l38 -6 -27 41 c-24
36 -26 45 -17 75 6 19 9 35 6 35 -3 0 -7 -9 -10 -20z"/>
<path d="M4130 7070 l-25 -20 38 0 c30 0 37 4 37 20 0 25 -18 25 -50 0z"/>
<path d="M3995 7050 c-3 -5 -16 -10 -28 -10 -16 0 -18 -3 -9 -12 10 -10 17 -7
34 10 12 12 19 22 16 22 -4 0 -10 -4 -13 -10z"/>
<path d="M3860 6976 c0 -7 -7 -19 -15 -26 -8 -7 -15 -18 -14 -24 0 -7 2 -6 6
2 7 18 18 15 51 -13 l29 -24 -3 25 c-5 46 -54 100 -54 60z"/>
<path d="M4011 6934 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3840 6901 c0 -8 -7 -11 -17 -6 -15 5 -16 4 -5 -6 9 -10 15 -10 24
-1 6 6 9 15 5 19 -4 3 -7 1 -7 -6z"/>
<path d="M3990 6895 c0 -8 2 -15 4 -15 2 0 6 7 10 15 3 8 1 15 -4 15 -6 0 -10
-7 -10 -15z"/>
<path d="M3837 6849 c7 -7 15 -10 18 -7 3 3 -2 9 -12 12 -14 6 -15 5 -6 -5z"/>
<path d="M3962 6820 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3881 6814 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3966 6717 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M3990 6695 c-7 -8 -17 -12 -23 -8 -6 3 -7 1 -3 -5 3 -6 -2 -15 -11
-21 -10 -6 -13 -11 -7 -11 5 0 23 14 39 30 16 17 26 30 23 30 -3 0 -11 -7 -18
-15z"/>
<path d="M1462 6683 c-99 -125 -189 -259 -271 -402 -39 -69 -71 -118 -71 -109
0 47 34 198 64 284 19 54 33 100 31 102 -1 2 -16 -30 -33 -70 -35 -85 -58
-182 -68 -288 -5 -53 -20 -107 -51 -185 -25 -60 -60 -168 -78 -240 -24 -91
-35 -122 -39 -105 -3 14 -8 114 -12 224 -4 119 -10 193 -15 185 -12 -20 -11
-236 2 -349 6 -52 12 -198 13 -325 2 -196 6 -246 25 -335 22 -105 89 -325 96
-318 2 2 -9 44 -25 93 -48 152 -65 249 -64 375 0 98 4 128 28 200 37 111 112
256 181 350 l55 75 -61 -65 c-101 -107 -167 -236 -205 -400 l-13 -55 5 85 c10
170 36 304 92 478 l26 83 161 143 c88 79 190 169 228 201 37 32 67 60 67 62 0
12 -188 -146 -332 -280 l-117 -108 22 53 c72 175 206 406 354 614 45 62 80
114 78 114 -2 0 -34 -39 -73 -87z"/>
<path d="M1060 4735 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M6397 6715 c80 -106 189 -271 246 -371 56 -96 167 -327 167 -345 0
-7 -44 30 -97 82 -95 92 -230 208 -323 279 -65 49 -31 16 95 -91 61 -51 158
-138 218 -192 59 -54 107 -95 107 -90 1 19 34 -71 65 -177 35 -121 60 -271 69
-405 l5 -80 -23 90 c-30 115 -89 232 -164 326 -88 111 -115 127 -38 24 126
-170 216 -399 216 -549 0 -71 -21 -197 -50 -304 -11 -41 -19 -77 -16 -79 2 -3
7 4 9 14 74 256 80 294 82 558 2 127 8 275 14 330 12 110 13 293 2 340 -4 17
-10 -55 -15 -170 -3 -110 -9 -216 -13 -235 -6 -33 -7 -32 -20 30 -64 304 -230
637 -477 955 -97 124 -140 169 -59 60z"/>
<path d="M6666 6585 c4 -11 22 -59 40 -106 18 -47 42 -128 54 -180 19 -90 20
-91 15 -31 -5 71 -51 217 -90 290 -15 26 -23 38 -19 27z"/>
<path d="M6782 6175 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M6851 4789 c-6 -18 -9 -34 -7 -36 2 -2 9 10 15 28 6 18 9 34 7 36 -2
2 -9 -10 -15 -28z"/>
<path d="M6826 4725 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M2381 6524 c-76 -16 -197 -72 -253 -115 -51 -41 -42 -49 14 -11 80
55 157 89 278 122 77 22 52 24 -39 4z"/>
<path d="M5435 6523 c124 -29 264 -87 333 -140 47 -35 51 -22 5 16 -73 61
-266 135 -340 130 -15 -1 -15 -2 2 -6z"/>
<path d="M1863 5018 c-105 -130 -211 -278 -285 -393 -59 -93 -60 -95 -54 -95
2 0 93 123 202 273 109 149 208 287 222 305 13 17 22 32 19 32 -3 0 -50 -55
-104 -122z"/>
<path d="M1651 4975 c-26 -84 -51 -191 -50 -213 0 -9 18 48 39 128 41 149 47
203 11 85z"/>
<path d="M1783 4732 c-129 -93 -154 -113 -113 -92 63 33 259 179 240 179 -3 0
-60 -39 -127 -87z"/>
<path d="M6017 5014 c44 -60 139 -192 213 -294 175 -244 203 -274 92 -100
-100 156 -340 475 -373 496 -6 3 25 -42 68 -102z"/>
<path d="M6231 5005 c0 -5 15 -68 33 -140 19 -71 35 -120 35 -109 1 12 -10 64
-23 115 -24 92 -44 154 -45 134z"/>
<path d="M6010 4796 c29 -37 213 -166 237 -166 3 0 -30 25 -73 56 -43 31 -101
73 -129 93 -27 19 -43 27 -35 17z"/>
<path d="M3899 4738 c4 -26 6 -28 9 -10 4 27 12 27 63 2 83 -43 182 -177 161
-218 -5 -9 -7 -23 -4 -31 2 -8 0 -11 -5 -8 -6 4 -15 -11 -22 -32 -17 -58 -13
-61 15 -8 25 48 88 103 131 113 13 4 23 10 23 15 0 11 -67 -15 -90 -35 -30
-26 -44 -30 -37 -12 9 22 -33 105 -78 154 -32 36 -105 82 -151 96 -19 6 -20 3
-15 -26z"/>
<path d="M3912 4675 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
<path d="M3903 4605 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M3740 4558 c0 -35 -2 -81 -5 -103 -4 -35 -3 -36 4 -10 5 17 9 60 10
98 1 83 17 88 79 24 47 -47 51 -73 31 -167 -6 -27 -9 -55 -7 -62 3 -7 15 39
27 101 12 62 20 115 17 119 -3 3 -6 -5 -6 -17 0 -20 -6 -17 -48 28 -35 37 -55
51 -75 51 -26 0 -27 -1 -27 -62z"/>
<path d="M4251 4524 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4242 4470 c0 -19 2 -27 5 -17 2 9 2 25 0 35 -3 9 -5 1 -5 -18z"/>
<path d="M3992 4460 c-5 -19 -19 -59 -31 -88 -25 -63 -27 -111 -5 -138 15 -19
16 -19 9 1 -4 11 -10 30 -12 42 -7 29 49 187 59 170 13 -21 9 -64 -17 -153
-14 -47 -22 -90 -19 -95 11 -18 20 -8 17 19 -2 15 5 54 16 86 24 68 26 130 7
166 l-14 24 -10 -34z"/>
<path d="M4243 4375 c0 -22 2 -30 4 -17 2 12 2 30 0 40 -3 9 -5 -1 -4 -23z"/>
<path d="M4142 4307 c-35 -67 -41 -112 -17 -143 14 -18 14 -18 15 4 0 12 11
45 25 72 25 49 32 94 17 117 -4 7 -20 -12 -40 -50z m9 -74 c-29 -57 -30 -57
-31 -24 0 18 12 56 28 84 25 46 27 49 30 25 2 -15 -10 -52 -27 -85z"/>
<path d="M3973 4133 c20 -2 54 -2 75 0 20 2 3 4 -38 4 -41 0 -58 -2 -37 -4z"/>
<path d="M3908 4123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4108 4123 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3977 4113 c18 -2 50 -2 70 0 21 2 7 4 -32 4 -38 0 -55 -2 -38 -4z"/>
<path d="M3908 4103 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4098 4103 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M1180 4016 c-154 -14 -164 -32 -15 -28 126 4 195 14 198 27 3 14 -39
14 -183 1z"/>
<path d="M1187 3925 c-65 -7 -121 -16 -125 -19 -18 -19 28 -22 153 -9 155 15
169 18 160 33 -7 12 -35 11 -188 -5z"/>
<path d="M1195 3833 c-104 -14 -137 -24 -122 -39 6 -6 259 21 300 33 9 2 17 9
17 14 0 12 -62 10 -195 -8z"/>
<path d="M1232 3745 c-79 -13 -146 -26 -149 -28 -3 -3 -2 -10 1 -15 5 -9 46
-4 249 29 41 7 67 15 64 22 -5 17 -13 16 -165 -8z"/>
<path d="M6535 4010 c-9 -15 6 -17 160 -29 91 -7 142 -8 149 -1 5 5 8 12 5 14
-4 4 -241 26 -284 26 -13 0 -27 -5 -30 -10z"/>
<path d="M6530 3920 c0 -12 20 -16 148 -29 136 -14 165 -14 160 2 -8 21 -308
48 -308 27z"/>
<path d="M6520 3835 c0 -8 1 -15 3 -15 1 0 69 -9 151 -20 81 -11 150 -20 152
-20 2 0 4 7 4 15 0 8 -4 15 -8 15 -8 0 -179 23 -264 35 -30 4 -38 2 -38 -10z"/>
<path d="M6505 3751 c-3 -5 -2 -12 3 -15 15 -9 303 -49 309 -42 15 15 -19 24
-152 45 -161 24 -152 24 -160 12z"/>
<path d="M781 3959 c-6 -18 -9 -34 -7 -36 2 -2 9 10 15 28 6 18 9 34 7 36 -2
2 -9 -10 -15 -28z"/>
<path d="M855 3844 c4 -113 36 -319 51 -333 4 -3 4 10 0 29 -16 83 -37 232
-46 325 l-9 100 4 -121z"/>
<path d="M745 3854 c-24 -84 -45 -200 -44 -242 1 -25 3 -29 5 -12 2 14 9 59
15 100 6 41 20 104 31 138 11 35 17 66 15 69 -3 2 -13 -22 -22 -53z"/>
<path d="M799 3740 c-5 -163 6 -479 20 -585 11 -81 3 494 -9 640 l-7 90 -4
-145z"/>
<path d="M911 3484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7106 3965 c4 -11 17 -52 30 -91 20 -62 45 -178 57 -269 3 -16 3 0 1
36 -5 78 -44 239 -73 300 -12 24 -18 35 -15 24z"/>
<path d="M7036 3853 c-4 -60 -16 -159 -27 -222 -10 -63 -17 -116 -14 -119 3
-3 5 -3 5 -1 0 2 9 49 20 105 18 96 39 344 28 344 -3 0 -8 -48 -12 -107z"/>
<path d="M7087 3765 c-11 -187 -16 -687 -6 -620 9 65 24 677 17 710 -2 11 -7
-29 -11 -90z"/>
<path d="M6981 3484 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3127 4033 c-18 -17 -3 -51 43 -95 l50 -48 -28 -6 c-42 -9 -49 -12
-27 -13 11 0 70 11 130 24 61 13 98 24 84 24 -14 1 -51 -4 -82 -10 -51 -11
-59 -10 -91 10 -43 27 -76 67 -76 93 0 17 5 19 48 14 36 -4 40 -3 17 3 -39 11
-60 13 -68 4z"/>
<path d="M3243 4033 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M2980 3854 c34 -90 99 -163 181 -205 36 -18 48 -20 73 -11 l29 11
-21 -22 c-26 -29 -62 -89 -62 -104 0 -7 8 -13 18 -12 10 0 12 3 5 6 -30 12 47
112 112 146 46 25 3 21 -56 -5 l-51 -21 -43 21 c-61 29 -112 77 -146 136 -16
28 -29 56 -29 61 0 6 31 9 77 8 98 -3 84 4 -19 10 -76 4 -76 4 -68 -19z"/>
<path d="M3631 3854 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3603 3760 c0 -41 2 -58 4 -37 2 20 2 54 0 75 -2 20 -4 3 -4 -38z"/>
<path d="M3623 3765 c0 -38 2 -53 4 -32 2 20 2 52 0 70 -2 17 -4 1 -4 -38z"/>
<path d="M3612 3660 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M3631 3664 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3293 3533 c15 -2 39 -2 55 0 15 2 2 4 -28 4 -30 0 -43 -2 -27 -4z"/>
<path d="M3228 3523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4743 4033 c9 -2 25 -2 35 0 9 3 1 5 -18 5 -19 0 -27 -2 -17 -5z"/>
<path d="M4825 4029 c-23 -6 -19 -7 18 -3 42 5 47 3 47 -15 0 -27 -52 -85 -89
-101 -23 -9 -42 -9 -81 -1 -28 6 -63 11 -78 10 -15 0 25 -11 88 -24 63 -14
122 -24 130 -24 17 1 8 4 -32 13 l-27 6 50 47 c39 36 49 52 47 72 -3 29 -24
35 -73 20z"/>
<path d="M4401 3864 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4950 3870 l-75 -7 78 1 c42 0 77 -3 77 -7 0 -23 -54 -108 -89 -140
-50 -45 -118 -80 -142 -74 -11 3 -17 1 -13 -4 17 -29 120 27 187 100 45 51 88
143 64 139 -7 -1 -46 -5 -87 -8z"/>
<path d="M4413 3765 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M4393 3760 c0 -41 2 -58 4 -37 2 20 2 54 0 75 -2 20 -4 3 -4 -38z"/>
<path d="M4494 3808 l-29 -23 50 -6 c28 -3 79 -14 115 -24 61 -17 67 -17 105
-1 l40 17 -85 29 c-103 37 -157 38 -196 8z m173 -10 c76 -28 83 -35 42 -43
-22 -4 -57 1 -99 14 -36 12 -76 21 -90 21 -24 1 -24 1 -6 15 28 21 85 19 153
-7z"/>
<path d="M4382 3660 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M4705 3663 c65 -34 142 -134 112 -146 -7 -3 -5 -6 6 -6 9 -1 17 4 17
9 0 19 -37 84 -59 104 -11 10 -18 22 -16 26 5 9 -49 30 -74 30 -9 -1 -3 -8 14
-17z"/>
<path d="M4401 3654 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M4452 3657 c-24 -14 -20 -27 8 -27 10 0 43 -12 75 -26 46 -21 63 -25
88 -17 l31 9 -58 33 c-68 38 -112 47 -144 28z m108 -22 c97 -49 65 -62 -39
-16 -57 26 -64 38 -23 40 7 1 35 -10 62 -24z"/>
<path d="M4683 3533 c15 -2 37 -2 50 0 12 2 0 4 -28 4 -27 0 -38 -2 -22 -4z"/>
<path d="M4778 3523 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4260 3714 c-25 -146 -98 -217 -239 -234 l-66 -7 56 -2 c147 -3 249
92 265 248 9 81 -1 78 -16 -5z"/>
<path d="M4221 3698 c-27 -117 -93 -178 -197 -178 -39 -1 -45 -3 -31 -11 21
-12 89 -3 127 16 58 30 104 100 115 178 10 63 1 60 -14 -5z"/>
<path d="M4181 3701 c-3 -34 -45 -101 -74 -120 -40 -26 -23 -28 19 -2 35 21
67 81 61 114 -2 12 -5 16 -6 8z"/>
<path d="M1600 2267 c22 -62 9 -125 -34 -159 l-21 -17 23 6 c67 17 94 117 47
170 l-25 28 10 -28z"/>
<path d="M6280 2258 c-37 -61 -20 -127 40 -158 l24 -13 -27 32 c-29 35 -36 95
-17 145 13 36 4 32 -20 -6z"/>
<path d="M1884 2095 c5 -11 11 -40 13 -65 4 -39 1 -51 -22 -78 -30 -36 -21
-42 18 -12 46 36 45 125 -1 162 -14 11 -15 10 -8 -7z"/>
<path d="M5991 2079 c-32 -52 -27 -95 13 -135 19 -19 40 -34 48 -34 8 1 7 4
-5 11 -40 24 -58 98 -37 155 15 40 4 41 -19 3z"/>
<path d="M2280 1743 c-94 -130 -109 -153 -94 -153 5 0 30 31 56 68 25 37 70
100 98 140 28 39 47 72 41 72 -5 0 -51 -57 -101 -127z"/>
<path d="M2418 1803 c-44 -52 -189 -265 -184 -270 7 -8 -4 -22 110 136 85 119
105 151 93 151 -2 0 -11 -8 -19 -17z"/>
<path d="M2374 1625 c-53 -75 -94 -139 -90 -142 3 -4 7 -4 9 -2 20 27 185 257
191 267 4 6 3 12 -3 12 -6 0 -54 -61 -107 -135z"/>
<path d="M2435 1594 c-53 -74 -94 -138 -91 -141 7 -8 -3 -20 103 127 106 149
107 150 95 150 -6 0 -54 -61 -107 -136z"/>
<path d="M2487 1565 c-54 -75 -96 -142 -95 -147 2 -6 48 51 102 126 53 75 96
141 94 147 -2 6 -47 -51 -101 -126z"/>
<path d="M2537 1524 c-54 -75 -96 -141 -94 -147 3 -9 207 265 207 278 0 20
-28 -13 -113 -131z"/>
<path d="M2637 1558 c-109 -150 -148 -208 -139 -208 10 0 188 238 199 266 12
31 -9 10 -60 -58z"/>
<path d="M2650 1463 c-51 -70 -94 -133 -97 -140 -12 -30 10 -8 60 60 109 149
148 207 139 207 -6 0 -52 -57 -102 -127z"/>
<path d="M2710 1445 c-50 -69 -93 -132 -97 -140 -11 -32 8 -12 60 58 110 150
148 207 138 207 -5 0 -51 -56 -101 -125z"/>
<path d="M5516 1858 c33 -57 199 -279 202 -270 4 12 -188 282 -200 282 -5 0
-6 -6 -2 -12z"/>
<path d="M5460 1804 c0 -11 194 -274 203 -274 11 0 8 5 -97 152 -81 113 -106
142 -106 122z"/>
<path d="M5410 1756 c0 -6 172 -248 191 -270 5 -5 11 -7 15 -4 3 3 -38 66 -91
140 -86 119 -115 153 -115 134z"/>
<path d="M5350 1725 c0 -8 200 -285 206 -285 13 0 -5 29 -91 151 -86 120 -115
154 -115 134z"/>
<path d="M5306 1679 c6 -8 50 -69 99 -137 85 -119 105 -142 105 -125 0 16
-185 266 -200 271 -12 4 -13 2 -4 -9z"/>
<path d="M5261 1630 c114 -163 186 -259 193 -260 4 0 6 6 3 13 -10 27 -189
267 -199 267 -6 0 -5 -8 3 -20z"/>
<path d="M5200 1620 c0 -16 193 -279 202 -274 7 4 -188 284 -198 284 -2 0 -4
-4 -4 -10z"/>
<path d="M5140 1585 c0 -10 192 -270 202 -274 17 -6 8 8 -91 147 -81 114 -111
148 -111 127z"/>
<path d="M5086 1558 c4 -7 46 -67 93 -132 47 -66 89 -124 93 -130 4 -6 10 -7
14 -4 7 8 -185 278 -199 278 -4 0 -5 -6 -1 -12z"/>
<path d="M1592 1894 c13 -50 4 -101 -23 -130 -25 -27 -15 -32 17 -9 48 33 56
122 14 160 -19 17 -19 16 -8 -21z"/>
<path d="M6291 1899 c-12 -19 -21 -48 -21 -66 0 -37 38 -88 72 -96 19 -5 21
-4 8 6 -46 36 -62 95 -40 153 15 40 4 41 -19 3z"/>
<path d="M2164 1905 c5 -11 11 -41 13 -66 5 -41 2 -50 -28 -84 -31 -35 -31
-37 -9 -25 42 22 59 41 66 77 7 36 -9 85 -34 105 -14 11 -15 10 -8 -7z"/>
<path d="M5711 1889 c-32 -52 -27 -95 13 -135 19 -19 40 -34 48 -34 8 1 7 4
-5 11 -40 24 -58 98 -37 155 15 40 4 41 -19 3z"/>
<path d="M1872 1724 c13 -50 4 -101 -23 -130 -25 -27 -15 -32 17 -9 48 33 56
122 14 160 -19 17 -19 16 -8 -21z"/>
<path d="M6011 1729 c-12 -19 -21 -48 -21 -66 0 -37 38 -88 72 -96 19 -5 21
-4 8 6 -46 36 -62 95 -40 153 15 40 4 41 -19 3z"/>
<path d="M3250 1515 c-14 -8 -20 -14 -14 -15 5 0 19 7 30 15 24 18 16 19 -16
0z"/>
<path d="M3312 1476 c-42 -46 -73 -123 -71 -180 0 -24 4 -18 18 29 21 73 51
131 86 168 14 15 22 27 17 27 -5 0 -28 -20 -50 -44z"/>
<path d="M2947 1261 c-40 -42 -114 -167 -105 -176 2 -2 38 48 80 111 42 63 75
114 74 114 -2 0 -24 -22 -49 -49z"/>
<path d="M2685 1159 c-71 -68 -145 -169 -165 -227 -11 -31 -12 -32 55 71 22
34 75 99 119 145 43 45 76 82 72 82 -3 0 -40 -32 -81 -71z"/>
<path d="M4559 1486 c38 -44 63 -96 86 -177 l15 -54 -5 55 c-6 69 -50 156 -95
189 l-35 26 34 -39z"/>
<path d="M4906 1300 c4 -8 24 -37 44 -65 20 -27 53 -77 74 -110 41 -65 40 -40
-2 37 -22 42 -135 176 -116 138z"/>
<path d="M5191 1164 c74 -79 119 -137 163 -209 44 -73 47 -64 6 14 -42 78
-107 158 -171 210 l-54 45 56 -60z"/>
<path d="M3545 1365 c-21 -91 -35 -168 -32 -171 8 -9 9 -7 51 171 39 164 40
165 28 165 -4 0 -25 -74 -47 -165z"/>
<path d="M3647 1520 c-4 -8 -22 -84 -42 -169 -35 -152 -43 -212 -26 -181 13
24 83 339 78 353 -3 8 -6 8 -10 -3z"/>
<path d="M3695 1498 c-29 -123 -72 -334 -69 -337 2 -2 6 -2 9 1 11 11 82 349
74 354 -5 3 -11 -5 -14 -18z"/>
<path d="M3740 1418 c-7 -40 -20 -118 -30 -173 -17 -93 -18 -116 -5 -103 5 4
65 328 65 345 0 2 -4 3 -9 3 -5 0 -15 -33 -21 -72z"/>
<path d="M3810 1488 c0 -2 -9 -82 -19 -178 -11 -96 -17 -177 -15 -179 10 -10
14 14 29 150 8 79 17 159 19 177 3 17 1 32 -5 32 -5 0 -9 -1 -9 -2z"/>
<path d="M4240 1516 c0 -18 66 -317 77 -346 4 -11 7 -11 10 -3 2 7 -12 86 -33
175 -36 160 -53 214 -54 174z"/>
<path d="M4300 1525 c0 -23 73 -321 81 -330 6 -5 9 -4 9 4 0 25 -72 316 -81
326 -6 6 -9 6 -9 0z"/>
<path d="M4191 1473 c4 -21 22 -101 39 -178 30 -137 35 -153 44 -144 4 4 -35
193 -69 337 -3 12 -9 22 -14 22 -5 0 -5 -16 0 -37z"/>
<path d="M4077 1483 c-5 -9 32 -355 38 -362 13 -13 12 20 -6 184 -19 175 -22
195 -32 178z"/>
<path d="M4130 1483 c0 -19 60 -347 64 -350 14 -14 10 31 -17 185 -17 94 -34
172 -39 172 -4 0 -8 -3 -8 -7z"/>
<path d="M1931 770 c-1 -38 36 -166 62 -215 40 -76 158 -156 252 -170 34 -5
31 -2 -24 19 -88 34 -168 91 -208 150 -18 26 -33 52 -33 58 0 5 21 -7 46 -26
133 -101 363 -114 574 -31 35 14 68 29 74 35 5 5 -15 0 -44 -11 -152 -58 -365
-78 -472 -46 -99 31 -170 89 -198 162 l-11 30 21 -22 c27 -30 60 -37 118 -27
l47 8 -65 6 c-76 6 -117 28 -126 68 -10 46 -12 48 -13 12z"/>
<path d="M2981 707 c-32 -52 -150 -160 -215 -197 -33 -18 -91 -46 -130 -61
-39 -16 -64 -28 -56 -28 31 0 187 66 242 103 66 43 141 122 169 178 24 47 18
50 -10 5z"/>
<path d="M5960 771 c-7 -50 -46 -75 -128 -81 l-67 -6 47 -8 c57 -10 91 -3 118
24 29 29 25 11 -10 -43 -34 -55 -102 -101 -178 -123 -113 -33 -316 -14 -473
45 -30 11 -46 14 -36 7 10 -8 61 -28 115 -46 211 -70 424 -47 547 61 l39 34
-29 -54 c-45 -82 -116 -137 -240 -182 -50 -19 -50 -19 -10 -13 90 13 189 73
235 142 36 54 83 201 78 245 l-4 32 -4 -34z"/>
<path d="M4880 754 c0 -6 17 -37 39 -70 44 -67 117 -140 176 -176 45 -28 154
-73 200 -83 17 -4 -6 9 -50 27 -149 62 -265 154 -335 265 -17 26 -30 42 -30
37z"/>
<path d="M3602 906 c-32 -88 -91 -209 -137 -276 l-37 -55 37 35 c53 51 100
133 135 239 36 106 37 156 2 57z"/>
<path d="M4266 965 c20 -124 86 -270 159 -350 l45 -50 -40 65 c-52 83 -95 171
-137 285 -22 59 -31 76 -27 50z"/>
</g>
</svg>
    </>
  )
}
