import React from 'react'

export default function Fbi() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100.000000pt" height="100.000000pt" viewBox="0 0 2048.000000 2048.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        fill="#fff" stroke="none">
        <path d="M9655 20043 c-330 -222 -606 -403 -615 -403 -8 0 -224 43 -480 95
        -376 77 -466 92 -472 81 -4 -7 -37 -50 -74 -96 l-66 -82 31 -126 c18 -70 34
        -135 37 -145 6 -18 -8 -19 -380 -12 -379 8 -386 7 -403 -12 -21 -24 -510 -402
        -524 -406 -5 -1 -35 57 -67 128 l-58 130 -116 38 -116 38 -49 -53 c-28 -29
        -166 -178 -307 -330 l-257 -276 -72 -6 c-40 -3 -207 -10 -372 -16 -165 -6
        -465 -17 -668 -25 -202 -8 -372 -14 -378 -12 -6 1 -131 -305 -278 -680 -146
        -375 -271 -686 -276 -692 -8 -8 -762 -344 -817 -364 -16 -6 -18 -21 -18 -114
        0 -59 4 -113 8 -118 4 -6 62 -57 130 -114 67 -57 122 -106 122 -110 0 -3 -148
        -103 -328 -221 -180 -118 -329 -218 -332 -221 -3 -4 -52 -154 -109 -333 -58
        -179 -109 -326 -113 -326 -5 0 -48 26 -96 58 -48 32 -103 68 -124 80 l-36 22
        -119 -45 c-65 -26 -121 -49 -125 -54 -6 -5 -138 -889 -138 -922 0 -4 -258
        -213 -572 -465 -315 -251 -575 -461 -576 -466 -2 -5 83 -330 188 -723 l192
        -714 -227 -377 c-125 -207 -229 -382 -231 -389 -3 -7 33 -64 80 -127 l86 -115
        76 -7 c43 -3 108 -6 146 -6 48 0 70 -4 74 -14 3 -7 -45 -175 -105 -373 l-111
        -360 124 -349 124 -349 -76 -7 c-43 -4 -112 -10 -155 -13 l-78 -7 -87 -100
        c-48 -55 -87 -108 -86 -117 0 -9 105 -187 234 -396 128 -209 235 -389 237
        -400 2 -11 -88 -327 -201 -702 -113 -376 -206 -688 -206 -695 0 -7 9 -19 20
        -26 37 -23 1135 -914 1141 -926 3 -6 53 -196 110 -422 l104 -411 122 -48 123
        -47 90 56 c59 36 92 51 95 43 3 -7 48 -165 100 -352 53 -187 98 -348 101 -357
        3 -10 10 -18 15 -18 8 0 588 -398 611 -419 4 -3 -49 -52 -116 -109 l-123 -103
        -13 -125 c-6 -69 -10 -126 -9 -127 2 -2 193 -78 424 -170 l420 -168 274 -702
        c150 -386 276 -705 280 -709 3 -3 326 -20 716 -37 391 -17 719 -34 730 -37 16
        -4 440 -448 580 -607 l30 -34 100 29 c55 16 105 35 112 41 6 7 37 68 68 137
        31 69 61 129 65 134 7 7 519 -383 577 -440 l21 -21 304 13 c166 7 338 15 382
        17 l78 4 -5 -31 c-4 -17 -22 -88 -40 -158 l-35 -127 79 -95 c43 -53 79 -101
        79 -107 0 -5 8 -8 18 -5 43 12 871 186 887 186 15 0 1083 -684 1229 -787 47
        -34 48 -34 70 -16 11 10 281 191 599 403 l577 383 458 -77 c251 -43 462 -78
        467 -79 6 -1 43 35 83 80 l72 81 -36 152 c-20 83 -34 154 -31 157 3 3 177 -1
        387 -8 210 -8 384 -10 387 -6 2 4 130 109 284 232 l279 224 14 -32 c41 -98
        120 -282 122 -285 4 -6 246 -43 259 -40 6 2 144 153 305 336 l293 332 71 0
        c346 2 1439 20 1444 24 2 3 114 316 247 697 134 380 247 694 251 698 5 4 199
        90 432 192 l423 184 -6 95 c-10 152 -8 147 -122 244 -57 48 -103 89 -103 90 0
        2 140 95 310 206 171 112 315 210 319 219 5 9 50 174 101 366 51 193 97 354
        101 359 4 5 71 -33 151 -84 l143 -93 110 56 111 56 38 448 c22 246 44 456 50
        465 6 9 272 222 591 473 319 251 588 465 599 475 18 18 13 36 -203 733 -121
        393 -221 717 -221 720 0 2 104 175 230 382 127 208 229 383 227 389 -3 6 -39
        57 -80 113 l-76 101 -53 6 c-29 3 -94 9 -144 13 l-92 8 114 355 c62 195 113
        362 114 370 0 8 -58 170 -130 360 -71 189 -128 345 -127 346 1 1 72 7 157 13
        240 18 212 5 275 131 l55 110 -241 387 -242 388 180 725 c100 399 182 731 184
        739 3 8 -213 180 -556 442 -353 270 -563 436 -568 451 -4 13 -37 214 -73 448
        -36 234 -70 427 -75 430 -4 3 -53 21 -107 40 l-99 34 -123 -72 c-68 -40 -127
        -69 -130 -65 -4 4 -58 168 -119 363 -61 195 -117 359 -124 363 -6 5 -149 91
        -317 193 -168 101 -306 187 -307 191 -1 5 59 58 133 118 l135 110 -7 125 c-4
        69 -7 125 -8 126 0 0 -197 79 -436 174 -239 95 -440 178 -446 184 -6 6 -127
        326 -268 710 -140 384 -257 700 -258 701 -3 3 -1326 45 -1404 45 l-51 0 -304
        325 c-167 179 -308 325 -312 325 -5 0 -53 -14 -106 -30 l-98 -31 -60 -147
        c-33 -81 -63 -146 -67 -145 -8 2 -457 330 -555 406 l-64 50 -379 -8 c-208 -4
        -378 -3 -378 2 0 4 18 72 40 151 22 78 40 149 40 156 0 7 -32 53 -71 102 -39
        49 -75 97 -81 107 -6 11 -16 16 -25 12 -46 -17 -849 -206 -862 -202 -9 2 -284
        182 -611 399 -327 217 -599 397 -605 399 -5 3 -280 -176 -610 -398z m1219 -28
        l596 -395 -7 -48 c-3 -26 -20 -146 -38 -267 l-31 -220 -30 2 c-16 1 -99 9
        -184 17 -241 25 -415 36 -672 43 l-238 6 0 629 c0 345 2 628 4 628 3 0 272
        -178 600 -395z m1566 -308 l63 -79 -97 -351 c-53 -193 -98 -352 -100 -355 -9
        -8 -128 22 -132 33 -3 7 36 199 85 428 50 228 91 419 91 423 0 11 13 -4 90
        -99z m-3855 2 c248 -50 451 -92 452 -93 4 -4 33 -539 30 -543 -2 -1 -53 -10
        -113 -18 -116 -15 -512 -88 -572 -105 -20 -5 -38 -8 -40 -5 -2 2 -40 138 -84
        302 -43 164 -94 355 -113 426 -22 85 -30 127 -22 127 7 0 215 -41 462 -91z
        m-680 -379 l121 0 52 -204 c29 -112 52 -210 52 -219 0 -10 -16 -19 -50 -26
        -56 -12 -522 -145 -563 -160 l-25 -10 -111 310 c-61 170 -111 312 -111 316 0
        3 115 3 257 0 141 -4 311 -7 378 -7z m5595 -261 c157 -115 292 -214 300 -219
        12 -7 3 -37 -54 -182 -38 -95 -72 -177 -76 -181 -4 -4 -83 22 -176 59 -93 36
        -238 89 -321 118 -84 28 -153 55 -153 61 0 19 182 555 188 555 4 0 135 -95
        292 -211z m-7007 136 l72 -23 153 -343 c84 -188 149 -346 145 -350 -16 -16
        -116 -50 -124 -42 -6 7 -230 488 -340 731 l-28 62 25 -6 c13 -3 57 -17 97 -29z
        m7962 -335 c152 -162 279 -300 281 -305 4 -9 -226 -449 -243 -466 -5 -4 -109
        43 -233 105 -124 61 -271 133 -327 159 l-102 46 43 103 c23 57 96 232 162 390
        92 225 121 285 130 275 7 -6 137 -145 289 -307z m-8589 -535 c97 -184 128
        -253 120 -261 -6 -6 -105 -67 -221 -136 -218 -130 -517 -320 -643 -410 -70
        -50 -73 -51 -86 -32 -8 10 -183 246 -389 524 -205 278 -373 506 -372 508 1 1
        236 12 521 22 286 11 605 24 709 29 105 4 199 8 210 7 16 -1 49 -56 151 -251z
        m5054 161 c1488 -139 2781 -599 3980 -1414 824 -559 1559 -1285 2131 -2102
        934 -1333 1456 -2910 1497 -4515 36 -1396 -235 -2668 -828 -3890 -578 -1191
        -1440 -2234 -2507 -3032 -1110 -830 -2438 -1378 -3798 -1567 -427 -59 -722
        -79 -1175 -79 -463 0 -758 20 -1205 84 -1511 215 -2954 860 -4135 1848 -360
        302 -752 693 -1051 1051 -1045 1250 -1699 2780 -1878 4395 -40 352 -46 482
        -45 925 0 324 5 485 17 630 92 1045 315 1920 715 2805 226 500 473 927 818
        1415 428 605 983 1188 1609 1690 699 560 1642 1074 2450 1337 750 243 1296
        357 2025 423 271 24 1104 22 1380 -4z m5543 -696 c136 -371 248 -680 249 -687
        3 -12 -318 -383 -331 -383 -4 0 -48 37 -97 82 -210 192 -466 404 -694 573 -58
        43 -105 83 -105 89 0 19 718 1017 725 1009 4 -5 118 -312 253 -683z m-12523
        -880 l187 -195 -252 -257 -253 -258 -354 290 c-194 160 -354 294 -356 298 -1
        5 177 87 395 184 l398 176 24 -22 c12 -11 108 -109 211 -216z m13672 -411 c-9
        -8 -462 -382 -526 -434 l-48 -39 -49 54 c-27 30 -47 58 -46 61 1 4 151 134
        332 289 l330 281 7 -103 c3 -57 3 -106 0 -109z m-14240 -349 c65 -52 116 -98
        115 -102 -1 -4 -36 -47 -78 -95 -42 -48 -135 -167 -208 -263 -73 -96 -134
        -175 -136 -175 -6 0 -574 389 -572 392 2 1 149 99 327 217 l324 214 56 -46
        c31 -26 108 -90 172 -142z m14734 -653 c62 -194 110 -355 108 -358 -2 -3 -83
        -55 -180 -114 -170 -104 -178 -108 -191 -89 -8 10 -66 97 -129 192 -63 95
        -144 214 -180 264 -49 69 -62 93 -52 99 7 4 123 89 258 187 135 98 247 177
        250 175 3 -3 55 -163 116 -356z m-16003 -189 c62 -39 148 -92 192 -118 44 -27
        140 -86 213 -133 73 -48 142 -89 153 -92 10 -4 19 -10 19 -14 0 -4 -18 -36
        -40 -70 -37 -58 -42 -61 -63 -50 -53 29 -793 461 -800 468 -8 8 165 79 196 80
        10 1 68 -31 130 -71z m91 -283 c208 -121 380 -225 383 -231 2 -6 -35 -76 -83
        -155 -48 -79 -132 -231 -186 -337 -57 -110 -105 -191 -112 -189 -30 12 -562
        261 -568 266 -6 5 107 839 121 899 1 2 16 -4 34 -15 17 -10 202 -117 411 -238z
        m16501 133 c9 -37 125 -788 125 -807 0 -13 -32 -33 -112 -71 -62 -30 -177 -84
        -255 -121 l-142 -68 -92 177 c-51 97 -130 243 -175 324 -46 81 -85 152 -86
        157 -3 9 705 429 724 430 4 1 10 -9 13 -21z m-17566 -1924 c350 -113 638 -208
        640 -209 1 -2 -13 -52 -33 -112 -64 -194 -137 -463 -197 -722 -32 -139 -60
        -255 -62 -258 -5 -5 -604 89 -617 97 -6 4 -94 317 -195 696 -102 379 -187 695
        -190 702 -2 6 1 12 7 12 7 0 298 -93 647 -206z m18811 189 c0 -5 -79 -328
        -177 -719 l-177 -711 -261 -47 c-143 -26 -266 -48 -274 -49 -10 -2 -24 44 -51
        173 -62 289 -122 517 -206 789 -25 79 -42 146 -39 149 6 6 1164 420 1178 421
        4 1 7 -2 7 -6z m-19031 -2249 c245 -25 446 -46 447 -47 0 -1 -1 -32 -4 -69 -4
        -66 -5 -68 -31 -68 -14 0 -188 9 -386 20 -198 11 -365 20 -372 20 -10 0 -143
        172 -143 185 0 9 38 6 489 -41z m19104 -69 c-42 -84 -51 -95 -78 -100 -68 -10
        -745 -57 -758 -52 -10 4 -16 27 -19 74 -3 37 -3 70 -1 73 4 4 821 97 875 99
        l28 1 -47 -95z m-18703 -992 c0 -71 5 -215 10 -322 5 -107 7 -197 4 -200 -5
        -5 -426 -41 -432 -36 -3 3 -242 674 -242 680 0 3 149 5 330 5 l330 0 0 -127z
        m18527 120 c-2 -5 -53 -161 -112 -348 -59 -187 -110 -345 -114 -350 -8 -14
        -437 16 -434 30 3 17 23 562 23 621 l0 54 321 0 c176 0 318 -3 316 -7z
        m-18483 -915 c9 -113 53 -449 75 -580 l18 -106 -161 -31 c-418 -79 -398 -76
        -412 -56 -32 43 -466 756 -463 759 6 5 923 103 930 99 3 -1 9 -40 13 -85z
        m18291 -18 c204 -17 378 -31 388 -33 14 -2 -28 -77 -204 -367 -122 -201 -223
        -367 -225 -369 -4 -5 -527 91 -536 98 -4 3 4 73 17 155 14 82 34 235 46 340
        12 105 23 197 25 204 3 11 18 12 62 8 31 -4 224 -20 427 -36z m-17845 -2020
        c104 -301 166 -459 282 -719 33 -73 56 -137 51 -141 -9 -8 -471 -227 -491
        -232 -16 -4 -1153 916 -1139 922 61 24 1197 368 1211 367 15 -2 32 -41 86
        -197z m17601 -5 c335 -107 611 -195 615 -195 3 0 4 -4 2 -10 -1 -5 -268 -218
        -592 -473 l-589 -465 -171 82 c-94 46 -206 100 -249 122 l-77 39 36 75 c114
        239 338 810 384 980 6 22 15 40 21 40 6 0 285 -88 620 -195z m-16847 -1626
        c20 -33 36 -62 36 -65 0 -2 -24 -18 -52 -35 -29 -16 -102 -61 -163 -99 -183
        -114 -307 -185 -319 -182 -39 11 -196 75 -192 79 7 7 640 362 647 362 4 1 23
        -26 43 -60z m15831 -212 c193 -121 351 -221 353 -222 2 -2 -40 -26 -93 -54
        l-96 -51 -137 88 c-75 49 -186 120 -247 157 -142 88 -275 176 -275 181 0 3 37
        66 77 132 14 23 15 23 41 6 15 -10 185 -117 377 -237z m-15279 -612 c53 -71
        142 -185 197 -253 l100 -123 -39 -32 c-21 -18 -88 -73 -149 -123 l-110 -91
        -55 37 c-30 21 -169 117 -308 213 l-254 176 249 165 c136 90 253 164 259 163
        7 -1 56 -60 110 -132z m14659 -105 c140 -96 254 -177 255 -180 0 -3 -139 -96
        -310 -208 l-310 -203 -160 136 c-88 75 -160 143 -160 150 0 8 5 17 11 21 13 8
        263 321 333 416 44 60 52 67 68 55 10 -6 133 -91 273 -187z m-430 -563 c143
        -121 263 -223 267 -226 9 -8 31 -201 25 -208 -3 -2 -156 120 -341 272 l-336
        277 52 58 c29 32 57 55 63 52 5 -3 127 -105 270 -225z m-13641 -37 c39 -41
        123 -129 188 -195 l118 -121 -141 -169 c-78 -94 -152 -182 -165 -198 l-23 -27
        -406 162 -406 162 23 22 c13 13 164 139 337 282 l315 260 45 -52 c25 -28 77
        -85 115 -126z m12889 -618 l187 -188 -237 -676 c-131 -372 -242 -678 -248
        -679 -10 -2 -744 1000 -744 1016 0 6 28 30 62 55 178 129 674 545 756 634 14
        14 27 26 30 26 4 0 91 -84 194 -188z m-11503 -597 c197 -142 551 -367 783
        -499 38 -22 45 -30 39 -44 -60 -118 -226 -423 -233 -427 -9 -6 -1400 53 -1407
        59 -8 9 712 985 722 978 6 -3 49 -34 96 -67z m9565 -773 c61 -114 110 -215
        110 -224 0 -15 -551 -656 -565 -657 -7 -1 -344 749 -339 753 2 2 83 40 179 85
        96 45 247 119 335 166 88 46 162 84 165 84 3 1 55 -93 115 -207z m-7920 -153
        c17 -7 31 -14 33 -14 1 -1 -62 -141 -141 -311 l-143 -309 -84 -26 c-47 -14
        -86 -25 -88 -24 -3 3 292 654 320 707 9 19 7 20 103 -23z m6992 -215 c35 -82
        63 -153 62 -159 0 -11 -539 -445 -554 -446 -8 0 -185 509 -185 532 0 4 73 32
        163 62 89 31 223 80 297 110 74 30 139 54 144 53 5 -1 38 -69 73 -152z m-6082
        -117 c99 -31 236 -71 305 -88 69 -18 129 -36 134 -40 5 -4 -9 -76 -30 -162
        l-38 -155 -65 -6 c-124 -10 -691 -30 -697 -23 -4 4 35 126 87 272 72 205 97
        265 109 262 8 -3 96 -30 195 -60z m4712 -174 c3 -16 39 -166 78 -334 l71 -306
        -57 -64 c-32 -35 -60 -61 -63 -59 -6 7 -186 724 -186 743 0 10 73 34 142 46 4
        0 11 -11 15 -26z m-3862 -42 c142 -28 396 -72 496 -85 l45 -7 -29 -232 c-16
        -128 -32 -235 -35 -238 -6 -6 -851 -189 -871 -189 -6 0 181 715 204 778 3 7
        13 11 22 8 10 -3 86 -19 168 -35z m2909 -354 c16 -130 27 -239 25 -241 -5 -4
        -1140 -760 -1157 -770 -10 -6 -12 112 -10 591 l3 598 265 6 c243 6 566 28 715
        49 33 4 76 7 95 6 l35 -1 29 -238z"/>
        <path d="M9830 18423 c-576 -23 -1315 -145 -1870 -308 -726 -214 -1238 -430
        -1831 -771 -655 -377 -1168 -772 -1719 -1324 -462 -461 -744 -811 -1127 -1395
        -404 -618 -770 -1430 -973 -2160 -213 -768 -311 -1476 -312 -2260 -1 -496 31
        -900 108 -1360 325 -1941 1340 -3701 2864 -4966 1256 -1042 2802 -1689 4420
        -1848 1056 -104 2099 -12 3095 274 1178 339 2240 921 3175 1741 177 155 554
        531 715 714 1535 1739 2258 4000 2019 6315 -214 2070 -1226 4000 -2815 5366
        -1515 1303 -3427 2003 -5428 1987 -135 -1 -280 -3 -321 -5z m-40 -390 c0 -16
        3 -38 6 -49 5 -17 0 -23 -23 -28 -76 -20 -77 -37 -28 -429 26 -203 33 -282 24
        -287 -18 -12 -132 -21 -138 -12 -46 69 -427 549 -429 542 -2 -6 4 -79 13 -163
        33 -302 38 -317 105 -317 37 0 38 -1 45 -46 3 -25 4 -49 0 -52 -10 -10 -299
        -41 -306 -34 -4 4 -9 26 -12 48 -5 40 -3 42 26 50 16 5 38 20 47 34 17 24 16
        36 -15 275 -17 138 -35 257 -39 267 -8 17 -36 28 -77 28 -24 0 -27 5 -33 47
        -3 25 -5 48 -3 49 5 6 341 43 347 39 4 -2 76 -95 160 -205 84 -110 155 -199
        157 -196 10 9 -29 282 -43 303 -11 17 -24 23 -53 23 -38 0 -38 0 -45 47 -3 25
        -4 48 -2 51 5 4 250 39 294 41 18 1 22 -4 22 -26z m662 20 l186 -6 22 -61 c13
        -34 33 -89 46 -124 l24 -63 -35 -15 c-51 -21 -63 -18 -84 24 -35 68 -120 132
        -176 132 l-25 0 -2 -182 c-6 -380 -6 -377 21 -399 13 -10 36 -19 52 -19 28 0
        29 -2 29 -50 l0 -50 -215 0 -215 0 0 49 0 49 40 7 c66 11 67 16 75 323 l7 272
        -31 0 c-62 0 -152 -67 -183 -136 -13 -28 -31 -30 -75 -6 l-32 17 33 85 c19 47
        41 102 49 123 l16 37 143 0 c79 0 227 -3 330 -7z m1287 -128 c135 -32 245
        -118 299 -233 24 -50 27 -69 27 -157 0 -82 -4 -112 -24 -164 -49 -129 -149
        -225 -273 -262 -104 -32 -240 -24 -332 19 -87 41 -168 118 -207 197 -55 112
        -48 268 17 396 48 96 159 184 261 208 60 14 162 12 232 -4z m-2953 -102 c44
        -53 82 -103 87 -109 5 -8 -5 -22 -28 -39 l-34 -27 -61 41 c-34 22 -78 45 -98
        51 -55 15 -161 11 -217 -8 -61 -21 -64 -35 -40 -140 l18 -73 31 6 c87 17 103
        36 91 110 l-6 44 42 11 c23 6 45 8 49 3 8 -8 90 -320 90 -341 0 -9 -58 -32
        -82 -32 -4 0 -17 18 -28 40 -26 51 -55 65 -115 56 -37 -6 -45 -11 -41 -24 3
        -9 14 -57 25 -105 30 -128 32 -131 96 -124 120 12 228 94 290 221 l37 73 39
        -7 c22 -4 42 -10 45 -13 3 -3 -7 -72 -23 -154 l-28 -148 -330 -82 c-182 -45
        -338 -82 -347 -83 -23 0 -47 82 -27 89 24 8 71 61 66 74 -2 6 -30 119 -62 250
        -31 131 -64 244 -72 252 -8 8 -32 15 -53 15 -36 0 -38 1 -49 46 -7 26 -10 48
        -9 50 3 2 641 170 659 173 4 1 42 -43 85 -96z m3794 -129 c168 -53 312 -100
        321 -105 12 -6 19 -38 28 -135 14 -140 13 -144 -45 -144 -29 0 -34 6 -68 74
        -47 96 -105 149 -193 176 -46 14 -70 17 -83 10 -19 -10 -54 -91 -65 -152 -7
        -35 -6 -35 35 -46 54 -13 84 -2 110 40 11 18 21 37 23 41 1 5 22 4 45 -3 38
        -10 42 -14 36 -33 -70 -222 -107 -333 -113 -339 -3 -4 -23 -2 -44 4 l-38 12 6
        51 c6 45 4 55 -17 78 -12 15 -39 33 -59 41 l-37 15 -32 -110 c-29 -101 -31
        -112 -17 -133 9 -13 30 -29 47 -36 26 -11 30 -17 26 -36 -14 -54 -27 -76 -41
        -70 -8 3 -100 33 -206 66 -161 52 -189 64 -184 78 4 9 12 29 17 45 9 24 15 27
        52 27 29 0 48 6 60 18 21 24 159 446 160 492 1 29 -5 38 -36 58 l-36 24 15 44
        c8 24 18 44 21 44 4 0 144 -43 312 -96z m-4690 -26 c18 -39 18 -44 4 -53 -49
        -31 -64 -48 -64 -75 0 -39 163 -459 189 -489 19 -20 27 -22 65 -17 l43 7 17
        -42 c9 -23 15 -43 12 -46 -2 -2 -93 -39 -202 -82 l-199 -79 -18 46 c-13 32
        -15 48 -7 50 28 10 70 56 70 78 0 21 -150 428 -155 422 -1 -2 5 -142 14 -312
        l16 -309 -45 -19 c-25 -11 -54 -20 -66 -21 -15 -2 -67 55 -213 233 -224 273
        -215 263 -203 230 87 -226 144 -367 160 -393 17 -30 22 -32 69 -29 l50 2 16
        -42 c9 -23 16 -43 14 -44 -5 -5 -288 -114 -296 -114 -4 0 -16 19 -25 43 -16
        39 -16 43 0 52 42 24 56 47 51 79 -6 40 -161 440 -182 473 -14 21 -23 24 -63
        22 -42 -3 -47 -1 -58 26 -25 58 -24 58 144 123 87 33 163 62 169 64 6 2 84
        -88 172 -199 89 -112 163 -203 164 -203 1 0 -3 116 -9 257 -6 142 -8 262 -4
        266 7 8 327 135 343 136 4 1 17 -18 27 -41z m-1104 -524 c49 -42 93 -80 98
        -84 4 -5 -4 -24 -18 -43 l-27 -35 -53 24 c-52 24 -139 45 -160 39 -6 -2 -23
        -6 -38 -9 -16 -4 -28 -13 -28 -22 0 -21 235 -442 263 -472 22 -24 47 -25 102
        -7 17 5 25 -2 44 -35 13 -23 21 -43 20 -44 -11 -10 -371 -206 -378 -206 -4 0
        -17 17 -29 38 l-21 39 34 31 c21 18 35 40 35 54 0 30 -258 498 -274 498 -38 0
        -91 -102 -101 -193 l-7 -67 -34 -1 c-19 -1 -40 -2 -46 -3 -16 -1 -51 244 -38
        258 10 10 552 315 561 316 3 0 46 -34 95 -76z m7174 -34 c74 -40 145 -79 158
        -87 l22 -14 -21 -40 c-17 -34 -24 -40 -42 -35 -54 16 -83 17 -94 4 -6 -7 -59
        -103 -118 -213 -143 -268 -173 -298 -309 -297 -185 1 -366 142 -384 299 -10
        91 61 193 134 193 114 0 148 -123 49 -181 -39 -23 -42 -36 -20 -79 36 -69 154
        -95 213 -45 16 13 65 93 112 182 140 263 138 258 90 303 l-30 28 22 46 22 45
        30 -18 c17 -10 92 -51 166 -91z m452 -261 l168 -112 -16 -26 c-38 -63 -39 -64
        -74 -46 -18 9 -42 14 -54 11 -23 -6 -235 -308 -268 -383 -49 -112 45 -214 189
        -205 12 1 36 16 54 34 45 46 259 369 259 392 0 11 -12 32 -27 48 l-26 27 27
        41 26 40 135 -90 135 -90 -26 -39 c-24 -37 -28 -39 -54 -29 -67 23 -75 16
        -208 -177 -182 -265 -212 -291 -332 -283 -123 9 -298 125 -349 232 -25 50 -28
        131 -7 181 8 19 71 118 140 220 68 102 127 194 131 204 3 12 -4 30 -20 49 -14
        16 -25 32 -25 35 0 10 42 77 48 77 4 0 82 -50 174 -111z m-3627 -44 c1131
        -101 2173 -466 3104 -1087 716 -478 1340 -1101 1824 -1820 614 -914 973 -1921
        1094 -3068 25 -242 25 -1004 0 -1245 -98 -928 -348 -1752 -762 -2515 -987
        -1819 -2718 -3056 -4750 -3394 -381 -64 -612 -81 -1080 -81 -405 0 -497 5
        -825 46 -1165 143 -2293 610 -3225 1334 -422 328 -852 758 -1180 1180 -1150
        1480 -1613 3376 -1280 5240 166 925 564 1871 1113 2640 231 324 453 585 743
        875 329 329 659 593 1094 877 611 398 1404 728 2105 878 499 106 682 129 1250
        159 136 7 612 -4 775 -19z m-4809 -160 c64 -37 98 -113 91 -201 -3 -47 -12
        -75 -32 -105 -14 -23 -25 -42 -23 -44 2 -1 59 -19 127 -39 133 -38 204 -45
        237 -22 19 14 23 12 52 -27 18 -22 29 -44 25 -47 -5 -4 -56 -42 -115 -84
        l-107 -77 -205 66 c-197 63 -207 65 -231 49 l-24 -16 38 -56 c61 -90 85 -112
        123 -112 19 0 43 7 55 15 21 14 23 13 48 -20 15 -19 25 -39 23 -44 -3 -9 -333
        -251 -342 -251 -2 0 -15 17 -29 39 l-26 39 24 22 c32 30 39 54 24 82 -7 13
        -77 112 -156 220 -98 134 -152 200 -168 204 -13 3 -37 -2 -55 -11 -17 -9 -33
        -15 -34 -13 -2 2 -14 19 -29 38 l-26 36 211 153 c259 190 335 231 420 231 47
        0 71 -6 104 -25z m9189 -346 c81 -30 145 -85 184 -158 24 -46 37 -61 49 -57
        10 3 29 -6 45 -19 l27 -24 -47 -116 c-26 -63 -51 -115 -55 -115 -5 0 -24 7
        -43 14 l-35 15 0 81 c0 66 -4 90 -25 129 -29 59 -96 118 -142 127 -73 14 -107
        -49 -60 -115 91 -128 196 -292 206 -320 16 -50 14 -80 -13 -136 -85 -184 -326
        -208 -496 -49 -28 26 -62 71 -77 103 -14 31 -28 57 -29 59 -1 2 -12 -1 -23 -8
        -27 -14 -85 16 -76 40 50 137 113 280 123 280 7 0 28 -6 46 -14 l33 -14 -4
        -93 c-4 -79 -2 -103 16 -151 37 -96 126 -171 203 -171 71 0 98 55 62 126 -9
        18 -44 67 -78 109 -125 159 -160 240 -137 325 38 141 193 209 346 152z m-9990
        -539 l335 -141 47 21 46 20 29 -27 c15 -15 28 -32 28 -37 0 -6 -67 -79 -150
        -161 l-150 -150 -35 35 -35 35 26 37 c15 20 24 43 21 51 -4 12 -127 77 -145
        77 -4 0 -47 -39 -95 -88 l-87 -87 35 -73 c39 -82 50 -86 109 -46 l38 26 29
        -28 c16 -15 29 -33 29 -39 0 -5 -58 -68 -128 -138 l-128 -127 -33 34 -34 35
        17 34 c9 19 16 51 16 70 0 23 -47 152 -130 353 -71 174 -130 322 -130 330 0
        12 119 124 132 124 4 0 158 -63 343 -140z m10716 -127 c106 -115 206 -223 223
        -241 l30 -32 -59 -113 c-32 -61 -60 -114 -61 -116 -2 -2 -22 4 -45 14 -36 17
        -40 22 -34 44 4 14 9 60 11 101 4 59 1 82 -13 109 -10 19 -22 36 -28 38 -10 3
        -158 -130 -322 -290 -100 -97 -108 -115 -72 -161 l22 -27 -34 -36 -34 -35
        -152 159 -152 158 35 35 c29 30 37 33 52 23 58 -42 72 -45 102 -26 49 31 401
        365 397 377 -2 6 -19 20 -38 31 -36 21 -140 28 -205 13 -30 -7 -32 -5 -49 38
        -9 25 -15 47 -13 49 4 4 230 93 240 94 3 1 93 -92 199 -206z m-11286 -202 c22
        -10 56 -36 76 -58 94 -104 75 -224 -60 -393 -34 -41 -61 -78 -61 -81 0 -13
        136 -119 153 -119 10 0 32 10 49 21 l30 22 34 -34 34 -35 -124 -149 c-69 -83
        -129 -150 -133 -150 -5 0 -24 12 -43 27 l-35 27 18 30 c36 61 35 61 -236 290
        -157 132 -173 139 -224 103 l-29 -20 -37 31 -36 32 130 155 c207 245 268 305
        332 321 42 10 112 2 162 -20z m11681 -244 c22 -29 82 -102 132 -164 l92 -112
        -40 -31 -40 -32 -31 26 c-57 48 -64 45 -321 -167 -87 -71 -159 -138 -162 -150
        -3 -13 3 -38 14 -60 11 -21 20 -39 20 -41 0 -2 -17 -15 -39 -29 l-38 -25 -132
        163 c-72 89 -131 165 -131 168 0 3 16 18 35 32 l34 27 36 -28 c35 -26 37 -27
        69 -11 42 20 383 294 403 323 12 20 12 27 -3 61 -10 21 -18 41 -18 45 -1 8 64
        58 73 57 3 0 24 -23 47 -52z m-12198 -476 c4 -2 2 -46 -4 -67 -5 -17 -16 -23
        -57 -28 -121 -15 -208 -69 -269 -165 -45 -72 -40 -90 42 -145 38 -25 72 -46
        75 -46 2 0 16 17 30 37 36 54 33 85 -15 125 l-39 34 25 37 c13 20 27 37 30 37
        3 0 75 -45 159 -101 l154 -100 -27 -38 -27 -38 -46 15 c-25 8 -58 12 -72 8
        -23 -5 -67 -60 -67 -83 0 -14 181 -123 206 -123 42 0 107 110 122 204 10 62 1
        149 -23 215 -10 31 -12 48 -5 56 12 14 69 40 74 34 34 -44 156 -254 153 -263
        -5 -13 -365 -566 -377 -579 -4 -5 -24 2 -44 15 -34 22 -36 25 -30 66 4 29 2
        47 -7 59 -24 28 -429 293 -449 293 -10 0 -32 -11 -49 -25 l-30 -26 -35 23
        c-20 13 -36 26 -36 29 0 5 159 256 315 495 l52 81 134 -18 c74 -10 135 -18
        137 -18z m12549 -73 c118 -44 224 -180 257 -330 4 -18 13 -28 25 -28 11 0 23
        -12 31 -29 12 -29 9 -35 -75 -151 l-87 -120 -39 31 c-22 17 -38 34 -37 38 75
        140 87 261 35 340 -37 56 -78 75 -158 75 -167 0 -380 -179 -379 -318 1 -69 48
        -149 110 -188 38 -23 92 -38 162 -46 l63 -7 3 -52 3 -52 -44 -6 c-105 -16
        -243 40 -327 132 -27 30 -66 88 -87 130 -36 74 -38 81 -38 178 0 94 2 105 34
        168 44 90 122 167 216 212 117 57 221 64 332 23z m680 -1354 c-45 -56 -86
        -103 -92 -103 -5 0 -22 10 -38 22 l-27 23 25 61 c35 87 41 155 20 228 -18 67
        -62 138 -87 143 -21 4 -168 -59 -168 -73 0 -17 44 -88 62 -99 12 -7 31 -6 68
        6 l50 16 21 -40 c11 -22 19 -41 17 -43 -2 -1 -76 -39 -165 -84 l-163 -81 -20
        41 -21 40 40 40 c23 21 41 48 41 59 0 20 -29 100 -36 100 -3 0 -52 -23 -110
        -52 -123 -61 -129 -72 -90 -151 55 -113 131 -171 268 -206 l73 -19 0 -48 0
        -49 -153 -3 -153 -3 -155 315 c-85 173 -154 318 -154 324 0 5 18 17 40 27 l39
        18 36 -32 c26 -23 43 -30 58 -26 12 3 123 56 247 118 l225 112 -2 51 c-2 50
        -2 51 40 71 l42 21 152 -310 153 -310 -83 -104z m-13695 635 c136 -29 265
        -124 320 -236 27 -55 32 -77 32 -137 1 -43 -7 -93 -18 -126 -16 -48 -202 -453
        -225 -488 -7 -11 -16 -10 -50 7 l-42 22 7 48 c6 45 4 49 -23 69 -43 31 -451
        222 -473 222 -10 0 -31 -16 -45 -35 l-27 -34 -41 21 c-23 11 -43 22 -45 23 -8
        7 240 483 268 518 43 51 112 97 179 118 66 21 111 23 183 8z m-1069 -2375 c34
        -6 37 -9 37 -40 0 -34 28 -74 52 -74 6 0 121 -12 256 -26 134 -14 250 -23 259
        -20 8 3 22 24 30 46 15 38 18 40 59 40 24 0 45 -4 48 -8 5 -9 -61 -672 -67
        -679 -5 -4 -239 -83 -248 -83 -4 0 -14 20 -22 44 l-15 43 31 18 c54 32 108 87
        132 136 26 50 47 154 38 188 -6 24 -44 37 -135 47 l-68 6 0 -54 c0 -64 12 -79
        78 -99 l43 -13 -7 -40 c-3 -23 -8 -43 -10 -45 -4 -4 -85 5 -296 31 -80 10 -88
        13 -88 32 0 50 11 66 45 66 78 0 115 35 115 110 0 26 -2 27 -67 33 -38 4 -92
        7 -120 7 -48 0 -54 -3 -68 -30 -8 -16 -15 -39 -15 -51 0 -20 -3 -21 -47 -15
        -25 3 -49 8 -51 11 -5 4 15 215 35 378 6 50 7 50 66 41z m14693 -81 c18 -57
        29 -74 48 -78 11 -3 165 10 341 29 176 18 323 31 326 27 4 -3 9 -37 13 -76 l6
        -70 -284 -210 c-156 -115 -280 -211 -276 -213 11 -4 418 36 448 44 26 7 42 40
        42 85 0 28 1 29 49 29 48 0 48 0 54 -37 12 -77 30 -273 26 -277 -2 -2 -24 -6
        -48 -7 -42 -3 -45 -2 -55 28 -23 69 -20 68 -253 44 -114 -12 -234 -24 -265
        -28 -66 -6 -78 -17 -85 -72 -5 -38 -7 -39 -50 -46 -25 -3 -47 -5 -49 -3 -6 6
        -38 347 -33 351 2 2 96 70 209 152 113 81 208 151 212 154 8 7 -249 -14 -287
        -23 -36 -9 -55 -34 -55 -71 0 -32 -2 -34 -45 -41 -25 -3 -48 -4 -51 -2 -5 6
        -33 247 -34 290 0 23 5 27 33 31 17 2 38 5 45 5 7 1 15 -6 18 -15z m-14741
        -865 l20 -43 260 -3 c143 -1 270 0 282 3 16 4 25 17 33 45 11 40 11 40 61 40
        l49 0 -2 -345 -3 -345 -123 -60 -122 -59 -20 39 -20 39 53 50 c90 85 134 192
        121 293 -10 71 -20 78 -120 78 l-84 0 0 -49 c0 -66 22 -91 83 -98 l47 -6 0
        -48 0 -49 -185 0 -185 0 0 49 c0 48 1 49 35 55 54 10 84 43 91 99 l7 47 -125
        0 c-99 0 -128 -3 -138 -15 -9 -10 -11 -42 -8 -103 5 -120 35 -179 130 -266
        l71 -65 -15 -37 c-9 -21 -19 -39 -24 -41 -5 -1 -70 26 -144 60 l-135 63 -3
        357 -2 357 48 0 c46 0 48 -1 67 -42z m15267 -68 c90 -28 164 -83 219 -163 65
        -95 82 -155 77 -275 -6 -117 -30 -187 -90 -258 -100 -118 -191 -158 -349 -152
        -87 3 -103 7 -171 41 -203 100 -295 342 -214 565 43 119 158 223 281 253 61
        15 181 9 247 -11z m-14549 -817 c20 -158 38 -417 35 -496 -2 -73 -8 -102 -28
        -142 -52 -106 -174 -192 -309 -217 -128 -24 -291 28 -370 118 -71 81 -94 172
        -127 517 -9 98 -15 180 -12 182 2 3 25 8 50 11 44 6 45 6 51 -24 9 -44 32 -62
        82 -62 48 0 413 37 458 46 39 8 57 30 57 69 0 38 8 43 66 44 l41 1 6 -47z
        m14055 -109 c28 -6 32 -11 38 -51 4 -30 14 -49 29 -59 25 -16 484 -69 522 -59
        24 6 53 51 53 84 0 17 5 18 43 14 58 -6 67 -10 63 -25 -3 -7 -14 -102 -27
        -211 l-22 -198 -46 3 c-41 3 -46 6 -49 28 -6 55 -14 71 -39 81 -14 5 -134 21
        -266 35 -273 29 -280 29 -297 -37 l-11 -42 -46 7 c-26 3 -48 8 -50 10 -3 2 6
        99 18 215 25 231 19 218 87 205z m136 -536 c15 -67 14 -69 -43 -90 -106 -40
        -193 -158 -128 -173 171 -40 453 -96 491 -97 53 -3 72 12 83 65 3 15 7 27 10
        27 22 0 93 -25 93 -33 0 -23 -83 -403 -90 -410 -4 -5 -26 -3 -49 3 -40 11 -41
        12 -41 55 0 49 -16 71 -57 79 -16 3 -135 29 -267 57 -256 55 -256 55 -256 -1
        0 -41 38 -112 85 -160 l45 -46 -26 -37 c-24 -36 -27 -37 -47 -25 -12 8 -63 40
        -113 71 l-91 57 68 317 c38 173 70 318 73 320 4 5 207 40 238 42 11 1 20 -8
        22 -21z m-14000 -544 c46 -194 75 -338 70 -343 -27 -24 -204 -161 -208 -161
        -3 0 -16 15 -31 34 l-25 33 44 66 c58 86 73 153 59 248 -13 82 -34 119 -67
        119 -13 0 -55 -7 -94 -16 l-70 -16 10 -40 c17 -80 38 -95 112 -84 l42 7 11
        -45 c9 -39 8 -46 -6 -50 -51 -15 -316 -76 -331 -76 -11 0 -20 10 -24 28 -4 15
        -9 34 -12 43 -3 10 9 21 36 33 55 24 74 54 65 106 -4 22 -11 42 -15 45 -10 6
        -169 -27 -212 -44 -34 -13 -41 -38 -27 -98 30 -120 103 -210 216 -264 l65 -31
        -6 -46 c-3 -25 -8 -47 -9 -49 -5 -4 -290 59 -299 66 -3 3 -41 155 -83 336 -41
        182 -78 335 -80 342 -2 7 15 16 43 22 40 8 48 7 56 -7 21 -37 56 -62 78 -57
        309 68 478 110 496 122 20 12 23 21 20 54 -3 36 -1 40 22 45 14 3 30 7 35 9
        38 13 39 7 119 -331z m14371 -376 c7 -7 -123 -408 -131 -408 -3 0 -25 5 -50
        11 l-44 11 6 54 c6 50 5 53 -17 59 -14 3 -53 5 -88 3 l-64 -3 -41 -119 -41
        -118 40 -35 c87 -75 103 -76 136 -11 15 30 24 38 37 33 9 -4 29 -12 45 -17 15
        -5 27 -14 27 -18 0 -10 -107 -317 -115 -330 -3 -5 -25 -2 -49 6 -42 14 -43 16
        -43 59 0 30 -7 55 -24 80 -13 19 -135 134 -271 255 -137 121 -248 227 -248
        234 0 22 51 160 62 167 5 3 100 9 211 13 281 10 499 23 519 31 10 4 27 22 38
        40 20 32 21 33 59 21 22 -6 43 -14 46 -18z m-14158 -338 c145 -352 164 -430
        128 -518 -59 -140 -246 -164 -358 -46 l-31 32 -76 -126 c-70 -118 -75 -130
        -76 -186 l-1 -60 -42 -14 c-23 -8 -44 -12 -46 -11 -2 2 -26 58 -53 125 l-50
        121 114 184 c110 178 120 204 95 232 -10 11 -169 -51 -193 -75 -19 -19 -19
        -31 -2 -90 3 -11 -4 -22 -23 -31 -71 -37 -63 -46 -147 164 -43 105 -76 196
        -73 202 2 7 22 18 42 26 35 12 40 12 52 -5 30 -43 44 -54 64 -54 24 0 449 171
        487 196 22 15 24 21 19 64 -6 48 -6 48 31 64 20 8 41 14 46 13 5 -2 47 -95 93
        -207z m13183 -395 c143 -22 140 -21 140 -58 0 -48 -11 -57 -85 -72 -162 -34
        -253 -132 -231 -249 20 -107 241 -246 391 -246 69 0 113 18 148 59 57 68 58
        162 1 219 -45 45 -93 45 -127 0 l-25 -32 -41 19 c-23 10 -41 19 -41 20 0 1 26
        60 59 131 32 71 67 150 79 177 l21 47 45 -22 c45 -21 45 -23 40 -60 -4 -25 -2
        -43 7 -54 7 -9 68 -42 136 -74 l123 -59 -23 -53 c-21 -49 -26 -53 -60 -56
        l-37 -3 0 -67 c-1 -276 -218 -458 -474 -398 -242 57 -403 265 -366 474 15 90
        51 161 113 227 37 38 50 59 45 69 -12 21 20 84 41 80 9 -2 63 -10 121 -19z
        m-12697 -652 l48 -81 -225 -276 c-125 -151 -230 -286 -236 -300 -5 -15 -5 -37
        1 -58 5 -18 9 -34 9 -35 0 -4 -69 -43 -77 -43 -9 0 -54 77 -200 336 l-23 41
        44 24 45 23 36 -39 35 -40 60 64 60 64 -23 41 c-13 23 -41 71 -61 109 -32 57
        -42 67 -64 67 -49 -1 -114 -18 -129 -33 -14 -14 -14 -20 0 -61 l16 -46 -41
        -21 c-22 -11 -44 -17 -48 -12 -9 10 -157 274 -165 296 -4 9 6 22 29 37 l36 22
        31 -26 c59 -50 75 -50 434 -7 182 21 337 38 345 38 8 -1 36 -39 63 -84z
        m12291 -71 c3 -5 -2 -27 -10 -50 -11 -27 -13 -45 -7 -57 15 -26 434 -285 462
        -285 14 0 39 13 57 31 l31 30 37 -21 c20 -12 36 -26 36 -31 0 -10 -196 -327
        -215 -347 -10 -11 -19 -9 -49 9 -30 18 -36 26 -31 43 18 56 17 81 -7 102 -48
        43 -421 264 -446 264 -14 0 -38 -12 -54 -27 l-28 -27 -38 22 c-20 12 -36 26
        -35 30 1 5 50 88 109 185 l107 176 38 -19 c21 -11 40 -24 43 -28z m-11935
        -491 l120 -164 -40 -29 -40 -29 -35 30 c-38 34 -49 36 -80 18 -48 -28 -417
        -302 -421 -313 -7 -18 21 -64 59 -101 56 -54 120 -76 231 -81 l97 -4 0 -39 c0
        -22 0 -42 -1 -46 0 -6 -264 -72 -291 -73 -5 0 -361 481 -381 516 -4 7 10 23
        33 39 l39 27 27 -21 c39 -30 73 -26 135 17 97 68 344 255 352 266 8 12 4 61
        -7 88 -6 12 2 23 31 42 21 14 41 25 45 24 4 -2 62 -77 127 -167z m11741 15
        c69 -20 75 -24 72 -46 -10 -65 -15 -69 -96 -69 -79 0 -135 -17 -173 -53 -12
        -11 -20 -25 -17 -30 4 -5 102 -81 220 -169 231 -173 247 -179 295 -133 l28 26
        35 -22 c20 -12 36 -26 36 -31 0 -5 -57 -85 -127 -178 l-127 -170 -29 17 c-46
        27 -50 37 -32 72 33 65 28 71 -210 251 -217 165 -220 167 -240 148 -28 -28
        -32 -127 -7 -205 l19 -62 -40 -22 c-32 -19 -41 -20 -48 -9 -5 8 -37 58 -70
        112 l-61 98 198 263 197 264 51 -15 c28 -9 85 -25 126 -37z m-616 -716 c58
        -27 106 -51 106 -54 0 -3 -7 -24 -16 -46 l-16 -40 -68 6 c-79 8 -153 -11 -208
        -53 -66 -50 -91 -125 -55 -165 31 -34 69 -23 198 62 67 44 146 91 176 105 102
        47 190 28 266 -59 72 -82 85 -204 34 -312 -46 -98 -144 -190 -223 -209 -30 -8
        -33 -16 -18 -44 7 -14 3 -24 -18 -44 l-27 -26 -139 64 c-76 36 -140 66 -142
        68 -3 2 5 25 16 50 20 44 22 46 48 35 15 -6 65 -11 112 -11 75 -1 93 2 143 26
        129 64 184 199 103 253 -37 23 -58 15 -198 -78 -150 -99 -210 -128 -266 -128
        -79 0 -163 55 -205 134 -33 62 -30 164 5 236 37 75 104 141 172 171 39 17 55
        29 54 41 -3 16 38 68 53 68 4 0 55 -22 113 -50z m-10352 -220 c286 -275 335
        -362 272 -488 -34 -68 -83 -96 -170 -96 l-61 -1 14 -32 c26 -63 9 -135 -45
        -195 -47 -52 -103 -78 -170 -78 -93 0 -127 24 -360 254 l-213 210 36 35 c35
        35 35 35 64 18 16 -9 37 -17 48 -17 10 0 102 83 206 183 201 197 206 203 170
        266 -18 30 -17 30 11 60 17 17 32 31 36 31 3 0 76 -68 162 -150z m9823 -344
        c54 -26 101 -48 102 -50 2 -2 -4 -20 -13 -39 l-17 -36 -91 5 c-78 5 -99 3
        -149 -16 -60 -23 -149 -86 -167 -120 -13 -25 2 -56 63 -124 l43 -49 43 38 c38
        35 42 43 38 72 -3 18 -15 46 -27 62 l-20 30 33 25 c19 14 37 26 40 26 4 0 58
        -62 121 -137 l115 -138 -21 -22 c-32 -34 -52 -36 -93 -8 -21 14 -47 25 -58 25
        -18 0 -97 -46 -97 -57 0 -2 34 -44 76 -93 55 -65 83 -90 100 -90 37 0 129 92
        166 167 27 55 33 80 37 160 l6 96 39 14 c22 7 41 11 43 9 6 -5 83 -299 80
        -301 -1 -1 -117 -98 -257 -216 -141 -118 -263 -220 -271 -226 -13 -11 -21 -7
        -47 26 l-31 39 19 25 c22 28 25 58 10 87 -23 42 -312 376 -334 384 -16 6 -33
        4 -56 -8 -33 -16 -33 -16 -61 16 -16 18 -28 36 -29 39 0 4 117 106 260 228
        228 193 263 219 283 212 12 -5 67 -30 122 -55z m-9210 -212 l157 -126 -29 -31
        c-15 -18 -29 -34 -30 -36 -1 -2 -19 3 -40 11 -26 11 -44 13 -58 6 -23 -11
        -258 -304 -286 -358 -24 -44 -24 -74 0 -127 21 -45 67 -76 133 -88 67 -13 103
        17 245 200 70 91 132 176 137 190 8 19 4 30 -16 57 l-26 33 27 37 c14 21 29
        37 33 35 4 -1 62 -44 128 -95 129 -100 126 -95 82 -150 -18 -23 -19 -23 -55
        -6 -31 15 -40 15 -59 5 -13 -7 -68 -72 -123 -144 -181 -238 -244 -291 -340
        -290 -174 4 -385 195 -385 348 0 67 15 94 148 260 183 228 190 241 150 295
        l-21 29 27 36 c15 19 31 35 35 35 5 0 79 -57 166 -126z m8518 -256 l21 -35
        -28 -34 c-26 -31 -28 -38 -23 -99 2 -36 32 -197 66 -359 33 -161 61 -297 61
        -301 0 -4 -32 -27 -71 -50 l-71 -42 -280 212 c-154 116 -291 215 -305 221 -17
        6 -35 4 -60 -6 -21 -9 -42 -14 -47 -11 -13 4 -51 74 -45 80 10 10 358 226 363
        226 3 0 17 -18 31 -40 l25 -40 -37 -33 c-22 -21 -34 -40 -31 -49 7 -17 368
        -302 375 -295 6 6 -85 392 -102 434 -15 36 -32 40 -75 18 -29 -15 -29 -15 -54
        27 -14 24 -22 46 -18 50 19 18 268 167 276 165 5 -2 18 -19 29 -39z m-7641
        -343 c201 -109 265 -157 294 -220 27 -57 31 -129 10 -178 -33 -80 -145 -136
        -243 -121 -28 4 -43 3 -43 -5 0 -29 51 -293 59 -310 6 -10 22 -27 36 -38 l25
        -18 -26 -43 -25 -42 -98 52 c-159 86 -140 54 -172 293 -16 116 -29 213 -29
        216 0 3 -11 11 -25 17 -24 11 -26 8 -70 -66 -56 -97 -58 -130 -10 -169 l34
        -27 -21 -36 c-12 -19 -24 -39 -28 -43 -6 -7 -343 176 -369 199 -7 7 32 84 42
        84 6 0 26 -5 44 -12 29 -11 36 -10 53 7 10 11 73 114 139 230 135 234 141 254
        90 296 l-32 26 23 41 23 41 86 -47 c47 -25 152 -83 233 -127z m6821 -157 l21
        -35 -37 -36 c-31 -29 -37 -40 -31 -61 3 -15 67 -155 140 -311 74 -157 134
        -287 134 -288 0 -2 -31 -18 -69 -37 l-69 -33 -292 203 c-160 111 -293 200
        -294 199 -5 -6 181 -408 196 -426 9 -11 24 -14 59 -9 45 6 48 5 68 -27 12 -19
        21 -38 21 -44 0 -5 -64 -39 -142 -75 l-143 -66 -22 41 -22 42 35 28 c22 19 34
        38 34 53 0 14 -36 105 -81 202 -141 309 -144 313 -214 293 -35 -9 -36 -9 -56
        31 -11 22 -17 44 -12 48 4 4 76 39 160 78 l152 70 216 -149 c118 -82 217 -149
        220 -149 2 0 -23 57 -57 128 -65 137 -96 177 -131 167 -12 -4 -29 -8 -37 -11
        -11 -3 -23 11 -38 41 l-22 45 88 40 c48 23 110 51 137 64 28 14 54 23 59 22 5
        -1 18 -18 29 -38z m-5983 -249 c168 -67 308 -125 312 -128 3 -3 10 -68 14
        -143 l7 -138 -46 0 -46 0 -15 53 c-32 112 -116 195 -234 233 -52 16 -54 16
        -72 -2 -17 -18 -70 -133 -70 -153 0 -15 87 -44 113 -37 15 3 33 22 48 51 17
        32 28 43 39 39 8 -3 27 -11 43 -16 15 -5 27 -13 27 -17 0 -6 -130 -334 -134
        -338 -1 -2 -21 5 -44 14 l-42 16 2 58 c1 37 -3 64 -13 75 -14 17 -78 48 -85
        42 -2 -2 -22 -48 -44 -102 -52 -125 -50 -143 15 -176 44 -22 63 -25 155 -25
        103 0 106 1 187 41 l81 42 32 -33 31 -32 -112 -107 c-61 -58 -117 -106 -123
        -106 -6 0 -156 59 -333 131 l-321 131 16 44 c16 43 17 44 63 44 31 0 51 5 61
        18 17 20 198 464 198 486 0 8 -16 28 -35 43 l-35 29 17 42 c9 23 21 42 27 42
        6 0 148 -55 316 -121z m5139 -124 c14 -36 14 -39 -4 -49 -10 -6 -30 -21 -43
        -34 -29 -28 -30 -24 52 -247 35 -93 71 -193 80 -221 23 -72 44 -88 99 -80 l44
        7 17 -42 c9 -23 14 -44 11 -47 -3 -4 -95 -39 -203 -78 l-197 -72 -18 45 c-10
        25 -16 46 -15 47 39 22 78 61 78 79 0 21 -148 436 -172 480 -16 32 -29 38 -74
        31 -40 -6 -40 -5 -57 37 -9 23 -15 45 -12 47 9 9 380 140 390 138 5 -1 16 -20
        24 -41z m-4280 -165 c40 -10 77 -22 81 -27 4 -4 26 -163 49 -352 24 -189 47
        -355 52 -368 5 -13 24 -33 44 -44 36 -22 38 -31 14 -96 l-8 -21 -88 24 c-48
        13 -141 38 -205 56 -127 33 -125 32 -112 98 7 32 8 32 50 26 62 -8 77 10 69
        83 -11 102 -2 93 -131 128 -84 23 -121 30 -130 22 -7 -6 -27 -33 -45 -60 -39
        -62 -35 -89 19 -113 l39 -18 -10 -44 c-7 -29 -16 -44 -26 -44 -22 1 -338 84
        -342 90 -1 3 3 26 9 51 10 41 14 46 36 42 13 -3 43 4 67 14 45 20 65 44 409
        491 35 45 68 82 74 82 6 0 44 -9 85 -20z m3385 -189 c42 -60 76 -111 76 -114
        0 -2 -18 -16 -40 -31 l-41 -27 -37 36 c-20 19 -62 48 -92 63 -47 23 -67 27
        -140 27 -110 -1 -130 -12 -130 -69 0 -42 16 -146 24 -153 2 -3 20 -1 40 3 56
        10 77 33 83 89 7 56 14 65 59 65 l31 0 22 -137 c12 -76 24 -161 27 -189 l6
        -51 -32 -7 c-52 -10 -54 -8 -68 37 -20 63 -37 77 -93 77 -26 0 -50 -4 -54 -9
        -6 -11 9 -125 27 -189 12 -48 34 -62 92 -62 32 0 34 -2 39 -42 3 -24 4 -44 2
        -46 -4 -4 -418 -72 -421 -69 -1 1 -5 23 -9 48 l-7 45 41 16 c39 15 41 19 41
        57 0 59 -67 486 -80 510 -10 20 -36 26 -82 22 -10 -1 -18 1 -18 6 -1 4 -5 27
        -9 51 -5 23 -8 42 -7 43 3 2 644 107 658 108 11 1 44 -38 92 -108z m-2824 59
        c211 -28 200 -24 200 -64 0 -55 -8 -66 -50 -66 -21 0 -43 -6 -49 -14 -13 -15
        -71 -386 -71 -454 0 -58 25 -98 78 -124 73 -35 168 -14 207 46 18 27 79 448
        70 484 -4 16 -19 29 -43 39 -37 14 -38 15 -32 61 4 39 8 47 25 46 43 -1 290
        -37 296 -43 4 -3 4 -26 0 -51 -6 -44 -7 -45 -46 -48 -22 -1 -46 -9 -54 -16 -8
        -9 -26 -103 -47 -242 -18 -126 -40 -248 -49 -271 -33 -86 -134 -143 -254 -143
        -72 0 -178 23 -227 48 -80 42 -144 132 -144 204 0 18 14 133 31 254 18 122 29
        229 26 239 -4 9 -24 23 -45 31 -35 14 -38 18 -34 52 5 56 9 63 34 57 13 -2 93
        -14 178 -25z m1755 -101 c111 -41 191 -113 237 -215 20 -43 23 -65 23 -179 0
        -124 -1 -133 -29 -185 -108 -206 -352 -301 -568 -220 -113 42 -218 156 -253
        274 -31 104 -11 247 48 349 98 167 348 248 542 176z"/>
        <path d="M11618 17810 c-112 -34 -186 -156 -195 -322 -7 -104 8 -172 45 -211
        73 -77 174 -83 257 -15 115 96 157 402 67 501 -37 41 -121 64 -174 47z"/>
        <path d="M9895 16724 c-22 -2 -112 -8 -200 -14 -503 -34 -1110 -159 -1605
        -333 -714 -251 -1295 -569 -1910 -1046 -461 -357 -908 -827 -1272 -1336 -252
        -354 -450 -696 -625 -1085 -329 -730 -496 -1369 -569 -2182 -31 -347 -22 -856
        22 -1258 138 -1268 675 -2504 1519 -3495 165 -193 538 -567 730 -730 540 -459
        1138 -822 1785 -1085 1294 -525 2727 -619 4090 -268 791 203 1572 577 2235
        1070 1026 763 1790 1780 2238 2978 414 1106 514 2342 286 3520 -306 1578
        -1194 2996 -2484 3965 -871 654 -1864 1073 -2930 1235 -326 50 -493 62 -890
        65 -209 2 -398 1 -420 -1z m434 -529 c39 -126 71 -236 71 -243 0 -7 -12 -30
        -26 -50 -19 -26 -20 -30 -4 -12 12 14 28 33 35 43 11 16 33 17 271 15 l259 -3
        -210 -149 c-115 -82 -212 -151 -214 -153 -3 -2 32 -114 76 -249 45 -135 77
        -244 72 -242 -5 2 -99 68 -209 148 l-199 145 -1 142 0 142 -222 71 c-123 39
        -277 89 -343 110 l-120 39 260 1 261 0 79 245 c43 135 82 242 85 238 4 -5 39
        -111 79 -238z m-2734 -615 l179 -179 233 119 c128 65 233 116 233 112 -1 -4
        -54 -107 -118 -230 l-117 -223 181 -187 c100 -103 181 -188 180 -189 -1 -1
        -116 17 -255 40 l-253 42 -60 120 c-33 66 -61 121 -63 123 -1 1 -159 -22 -350
        -53 -192 -30 -351 -55 -354 -54 -3 0 96 52 222 115 125 63 227 118 227 123 0
        11 -68 433 -76 474 -3 15 -2 27 3 27 4 0 89 -81 188 -180z m2623 108 c-14 -18
        -102 -141 -197 -273 -95 -132 -177 -244 -182 -250 -11 -11 4 38 83 280 l63
        189 120 43 c66 23 124 42 129 42 4 1 -3 -14 -16 -31z m2928 -262 c-25 -135
        -46 -247 -46 -250 0 -2 99 -59 220 -126 121 -67 220 -125 220 -129 0 -4 -113
        -20 -252 -36 l-251 -29 -96 100 -97 100 -264 -123 c-146 -68 -290 -135 -320
        -149 l-55 -24 80 78 c44 42 127 121 184 175 l104 98 -108 230 c-59 127 -105
        232 -103 234 2 3 151 -78 396 -216 l52 -29 186 176 c102 96 188 173 191 171 2
        -3 -16 -116 -41 -251z m-5472 -646 c-29 -184 -54 -339 -55 -345 -1 -5 -20 103
        -43 240 l-43 250 96 97 c52 54 96 96 97 95 1 -1 -23 -153 -52 -337z m5213 -82
        c23 -183 40 -334 39 -336 -4 -4 -206 433 -206 446 0 14 112 222 120 222 3 0
        24 -150 47 -332z m-2536 -662 c72 -139 119 -194 192 -227 l52 -24 625 -5 625
        -5 215 -169 c118 -94 239 -190 268 -214 l53 -45 -55 -22 c-161 -65 -255 -167
        -304 -328 -13 -43 -16 -127 -19 -484 l-4 -433 -1740 0 -1739 0 -2 218 c-1 119
        -2 323 -3 452 -2 299 -20 371 -118 477 -53 58 -105 91 -185 118 l-63 21 133
        121 c73 67 178 160 233 207 l100 86 585 0 c361 0 610 4 650 11 151 24 222 86
        320 279 18 36 44 80 58 99 l25 34 18 -24 c10 -13 46 -78 80 -143z m-4381 -194
        l0 -259 245 -78 c134 -44 241 -82 237 -86 -11 -10 -459 -149 -479 -149 -11 0
        -23 3 -29 8 -5 4 -1 0 8 -10 16 -15 18 -40 18 -273 0 -141 -4 -254 -9 -251
        -20 13 -291 406 -291 422 0 11 18 73 40 139 22 65 38 120 37 120 -8 4 -564
        409 -572 416 -5 5 101 -28 235 -73 l244 -83 22 -65 c12 -36 23 -60 23 -54 1 6
        -8 36 -18 66 l-19 56 146 206 c81 113 150 205 155 206 4 0 7 -116 7 -258z
        m8759 -72 c81 -119 147 -207 154 -205 70 24 471 135 477 133 5 -2 -61 -93
        -146 -203 -86 -112 -152 -206 -149 -214 3 -7 66 -103 141 -213 75 -110 134
        -201 132 -204 -3 -2 -112 35 -244 84 l-239 87 -40 136 c-34 117 -43 136 -60
        136 -86 -2 -656 17 -660 22 -2 4 97 36 221 72 124 35 229 66 234 69 5 3 12
        119 16 258 4 138 10 252 14 252 3 0 70 -94 149 -210z m-9009 -363 c-8 -8 -130
        -96 -270 -197 -140 -100 -262 -188 -270 -194 -16 -12 10 25 175 252 l110 151
        135 1 c113 0 132 -2 120 -13z m8929 -446 c-62 -176 -115 -319 -116 -317 -2 2
        1 115 5 251 7 194 12 249 23 256 8 4 55 37 104 72 50 35 92 63 94 61 2 -2 -47
        -147 -110 -323z m-4819 -2356 l0 -1455 -260 0 -260 0 0 1455 0 1455 260 0 260
        0 0 -1455z m1387 0 l-2 -1455 -262 0 -263 0 0 1455 0 1455 265 0 264 0 -2
        -1455z m-6435 832 l117 -222 33 3 c18 2 132 20 253 42 121 21 222 36 225 34 2
        -3 -68 -78 -156 -167 -88 -89 -168 -172 -178 -184 -17 -21 -15 -25 82 -210 55
        -103 109 -207 120 -230 l22 -41 -153 72 c-268 129 -314 154 -320 175 -2 11
        -13 73 -23 138 l-18 118 -335 44 c-185 24 -339 46 -343 50 -4 3 100 26 230 49
        130 24 239 48 242 53 3 4 21 119 40 254 19 135 37 245 40 245 3 0 58 -100 122
        -223z m11038 86 c1 -10 14 -124 29 -253 l29 -235 -41 -45 -42 -45 45 42 45 41
        249 -48 c137 -27 250 -51 252 -53 3 -2 -100 -53 -228 -112 -128 -59 -234 -108
        -234 -109 -1 0 11 -104 27 -231 16 -126 28 -243 28 -260 l0 -30 -47 51 c-26
        28 -105 113 -177 189 l-129 137 28 136 c35 170 33 157 19 155 -12 -3 -607 327
        -601 332 2 2 111 -18 243 -45 132 -26 242 -47 245 -45 3 2 60 103 125 224 66
        121 123 220 128 221 4 0 7 -8 7 -17z m-11200 -884 c-169 -345 -158 -344 -110
        -6 15 103 31 192 36 197 12 12 218 120 221 116 2 -2 -64 -140 -147 -307z
        m11040 187 c0 -2 -91 -89 -202 -192 -112 -103 -225 -208 -252 -233 -57 -54
        -62 -66 86 202 72 129 115 197 126 198 10 0 58 7 107 14 101 14 135 17 135 11z
        m-10288 -2151 l-68 -241 214 -144 214 -145 -50 -6 c-28 -4 -144 -10 -259 -14
        l-208 -7 -75 98 c-41 54 -79 104 -85 111 -8 11 -75 -10 -339 -107 -181 -66
        -331 -119 -332 -117 -2 1 87 71 196 156 195 149 199 153 189 179 -42 107 -168
        456 -166 458 1 1 97 -61 212 -137 116 -76 213 -139 218 -139 4 0 96 70 205
        154 108 85 198 152 199 148 2 -4 -28 -115 -65 -247z m9737 3 c80 -67 169 -141
        196 -164 l50 -42 215 138 c118 76 216 137 218 135 2 -2 -39 -111 -91 -243
        l-94 -240 194 -162 c106 -90 193 -166 193 -169 0 -4 -98 1 -217 11 -120 9
        -234 17 -254 17 -35 1 -38 4 -110 121 -51 82 -78 118 -88 114 -42 -14 -654
        -164 -658 -161 -2 3 90 64 206 137 l209 131 -59 232 c-33 128 -62 240 -65 250
        -3 9 -2 17 1 17 4 0 73 -55 154 -122z m-6056 -3 c175 -45 280 -153 263 -267
        -15 -92 -84 -162 -251 -254 -42 -23 -93 -69 -137 -121 -30 -35 -38 -41 -38
        -25 0 11 -10 26 -21 34 -16 10 -23 27 -26 64 -5 45 0 60 41 142 42 84 103 162
        126 162 6 0 10 11 10 24 0 28 -21 36 -92 36 -41 0 -48 -3 -48 -20 0 -11 5 -29
        12 -40 10 -16 9 -26 -11 -57 -12 -21 -30 -55 -38 -75 -9 -21 -19 -38 -22 -38
        -3 0 -21 26 -40 56 -30 50 -33 60 -23 90 11 38 6 74 -12 74 -6 -1 -38 -7 -71
        -13 -55 -12 -60 -15 -57 -38 2 -13 17 -36 33 -51 47 -43 109 -189 109 -254 0
        -49 -3 -56 -25 -66 -18 -8 -25 -18 -23 -32 3 -19 11 -21 91 -24 80 -3 88 -5
        81 -20 -4 -9 -16 -60 -27 -113 l-20 -96 -68 -7 c-154 -14 -305 -83 -644 -293
        -302 -187 -389 -237 -549 -314 -244 -118 -526 -199 -703 -202 -133 -3 -154 23
        -212 255 -58 232 -111 345 -225 487 l-62 77 65 -8 c125 -16 303 9 476 69 152
        52 353 155 640 329 351 212 479 286 615 354 338 169 660 233 883 175z m2902
        -49 c205 -43 433 -129 665 -250 52 -27 225 -123 384 -214 567 -323 787 -407
        1103 -419 l151 -6 -61 -76 c-129 -162 -229 -349 -292 -548 -66 -205 -90 -220
        -306 -192 -275 36 -536 136 -860 331 -449 270 -584 345 -684 380 -94 34 -249
        61 -301 53 -40 -6 -41 -5 -48 27 -4 18 -11 76 -16 129 -5 52 -12 103 -16 112
        -5 15 3 17 70 17 69 0 76 2 76 20 0 11 -9 29 -20 40 -18 18 -20 33 -20 165 0
        134 1 146 20 157 11 7 20 23 20 35 0 22 -4 23 -80 23 -78 0 -80 -1 -80 -24 0
        -13 7 -26 15 -30 12 -4 15 -32 15 -165 0 -132 -3 -160 -15 -165 -8 -3 -15 -12
        -15 -21 0 -9 -15 1 -37 26 -20 22 -57 52 -82 66 -199 112 -240 143 -271 203
        -73 143 13 292 200 345 83 23 328 13 485 -19z m-5490 -31 c82 -40 106 -146 56
        -250 -24 -49 -183 -215 -206 -215 -6 0 -30 26 -53 58 -23 31 -72 91 -108 132
        -36 41 -81 96 -99 122 l-34 46 57 35 c88 54 120 68 186 83 73 16 151 12 201
        -11z m7871 -55 c81 -7 224 -35 242 -46 11 -7 -59 -110 -124 -184 -41 -47 -96
        -118 -121 -158 l-47 -73 -20 25 c-12 13 -58 53 -102 90 -82 67 -118 106 -147
        162 -22 42 -8 89 38 137 55 57 97 64 281 47z m-7652 -469 c26 -57 65 -139 87
        -182 21 -43 39 -83 39 -88 0 -5 -43 -17 -95 -26 -123 -22 -333 -18 -382 8 -42
        22 -82 92 -90 154 -9 82 16 119 203 293 35 32 70 76 83 105 l23 49 43 -104
        c23 -58 63 -152 89 -209z m7475 107 c129 -124 161 -177 161 -271 0 -64 -17
        -112 -48 -141 -19 -17 -34 -18 -159 -13 -130 4 -349 41 -340 56 59 105 188
        411 223 530 3 10 12 3 28 -20 13 -19 74 -82 135 -141z m-4848 56 c-17 -157
        -31 -418 -31 -551 0 -143 11 -138 -145 -63 -122 59 -198 123 -231 196 -30 65
        -30 86 0 149 30 65 97 125 225 203 72 43 160 119 174 149 5 13 12 21 14 19 2
        -2 0 -48 -6 -102z m-4256 -319 c4 -148 5 -268 2 -265 -3 3 -41 106 -85 229
        l-80 224 71 108 72 107 6 -66 c4 -37 10 -188 14 -337z m6324 265 c245 -153
        280 -185 288 -264 11 -97 -63 -190 -212 -264 -68 -33 -184 -77 -189 -71 -2 2
        -7 166 -11 364 l-7 360 29 -40 c17 -22 62 -60 102 -85z m3926 -339 c-13 -183
        -26 -335 -28 -337 -2 -2 -29 104 -61 237 -50 205 -56 242 -45 257 38 49 153
        182 155 179 1 -1 -8 -152 -21 -336z m-6334 -131 c97 -91 294 -172 539 -220
        289 -57 772 -84 1065 -60 517 44 903 162 1059 325 l43 46 7 -63 c4 -35 20
        -164 37 -286 44 -340 38 -418 -41 -524 -181 -240 -683 -377 -1390 -379 -282 0
        -561 34 -860 108 -391 95 -629 241 -630 385 0 18 16 207 37 421 l38 387 22
        -45 c12 -25 45 -68 74 -95z m-2306 -1137 l49 -233 -25 -27 -24 -28 30 27 30
        26 247 -27 c136 -15 249 -29 252 -32 3 -2 -94 -61 -215 -129 -120 -69 -219
        -128 -219 -132 0 -5 23 -117 50 -249 28 -133 49 -244 46 -246 -2 -3 -88 70
        -190 162 -154 138 -186 172 -186 194 1 14 7 78 14 141 7 63 13 116 12 116 -1
        1 -146 66 -324 144 -177 79 -321 144 -320 145 2 1 113 -10 248 -26 135 -15
        248 -24 252 -21 3 4 43 90 89 192 133 301 121 277 129 255 4 -11 29 -125 55
        -252z m7225 -38 c51 -126 94 -230 94 -231 1 -1 116 6 256 16 235 15 271 16
        259 7 -2 -2 -90 -74 -195 -160 -123 -100 -200 -157 -215 -158 -23 0 -23 -1 -4
        -9 15 -6 45 -68 113 -236 51 -125 91 -231 90 -235 -2 -4 -97 53 -213 127
        l-210 135 -7 62 c-3 34 -9 96 -13 139 l-7 76 -333 83 c-184 46 -331 86 -327
        90 4 3 88 11 187 18 99 6 205 14 236 17 l56 6 63 250 c35 137 66 245 70 241 4
        -4 49 -111 100 -238z m-7386 -427 c-30 -35 -404 -450 -431 -478 -16 -17 10 50
        82 209 58 129 106 235 108 236 2 2 234 51 251 54 5 0 1 -9 -10 -21z m7319 -61
        c-70 -116 -357 -566 -360 -563 -2 1 23 109 54 239 l58 237 125 50 c144 57 133
        54 123 37z m-5078 -701 c83 -102 155 -185 160 -185 6 0 114 40 240 88 127 49
        231 88 233 86 2 -1 -59 -98 -134 -216 -122 -190 -135 -215 -124 -233 7 -11 80
        -103 162 -204 83 -101 148 -185 146 -187 -2 -2 -235 60 -444 120 l-52 15 -52
        129 -52 130 -96 -5 c-172 -10 -607 -30 -610 -29 -1 1 104 42 234 91 l237 89
        -7 154 c-3 85 -9 202 -13 260 -5 91 -4 104 8 94 8 -6 82 -95 164 -197z m2976
        -116 c-11 -123 -18 -226 -16 -230 2 -3 108 -51 234 -105 127 -54 231 -101 231
        -105 0 -3 -111 -32 -247 -63 -135 -31 -247 -58 -248 -59 -2 -2 -13 -115 -25
        -253 -12 -137 -23 -251 -25 -253 -3 -3 -9 7 -149 236 l-118 193 46 112 c63
        152 59 147 93 117 l28 -24 -25 25 c-14 14 -145 131 -292 259 -148 128 -268
        234 -268 236 0 5 14 -1 248 -102 l213 -92 167 192 c147 169 167 189 170 166 2
        -15 -6 -127 -17 -250z m-278 -383 c4 -1 -126 -82 -288 -179 -163 -98 -293
        -172 -288 -165 4 6 78 93 165 193 l157 181 123 -14 c68 -7 126 -14 131 -16z
        m-2710 -305 c-48 -177 -88 -320 -90 -318 -2 2 -8 81 -13 177 -5 96 -12 204
        -15 241 l-5 68 101 80 c56 44 103 79 105 77 1 -1 -36 -147 -83 -325z"/>
        <path d="M10329 15843 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21
        -13z"/>
        <path d="M10465 15650 c27 -12 43 -12 25 0 -8 5 -22 9 -30 9 -10 0 -8 -3 5 -9z"/>
        <path d="M10175 13651 c-123 -55 -147 -196 -49 -286 82 -76 51 -144 -74 -164
        -298 -46 -606 -81 -706 -81 -77 0 -98 -14 -111 -75 -13 -61 -31 -73 -84 -55
        -35 11 -45 11 -69 -1 -15 -7 -31 -15 -35 -17 -4 -2 -33 21 -63 52 -46 48 -57
        55 -73 46 -57 -30 -15 -126 81 -186 49 -31 48 -54 -4 -96 -83 -65 -273 -243
        -270 -253 7 -25 144 -144 193 -168 157 -74 339 -52 490 59 43 31 99 96 99 114
        0 4 -68 66 -150 139 -83 73 -155 142 -161 152 -13 26 -5 37 47 59 36 16 48 29
        73 78 21 42 37 61 55 66 27 7 166 -6 321 -29 55 -9 134 -20 175 -26 230 -30
        267 -39 309 -74 19 -17 33 -41 41 -75 21 -88 67 -97 87 -15 18 74 61 109 154
        128 225 46 681 104 720 92 11 -4 32 -32 47 -63 23 -47 36 -61 75 -80 78 -37
        71 -50 -108 -207 -85 -75 -155 -143 -155 -152 0 -29 103 -119 181 -157 194
        -95 382 -63 540 92 l71 70 -104 93 c-56 52 -131 118 -165 148 -35 29 -63 59
        -63 66 0 7 20 25 44 39 70 41 124 116 111 155 -2 9 -14 22 -26 29 -20 13 -26
        10 -75 -43 -41 -45 -57 -56 -71 -50 -69 29 -74 29 -93 17 -46 -28 -90 -3 -90
        51 0 57 -31 77 -113 77 -65 0 -301 24 -442 45 -55 9 -140 20 -190 25 -165 19
        -205 40 -205 106 0 28 9 46 34 75 68 75 68 164 -1 239 -53 60 -125 75 -198 41z
        m128 -123 c23 -27 22 -63 -3 -88 -29 -29 -74 -26 -99 6 -28 35 -26 59 5 90 29
        30 67 27 97 -8z m-995 -862 c61 -54 112 -100 112 -102 0 -2 -67 -4 -150 -4
        l-150 0 0 136 0 136 38 -33 c20 -19 88 -79 150 -133z m-228 24 l0 -130 -147 0
        -148 0 25 25 c54 54 255 234 263 234 4 1 7 -57 7 -129z m2317 -127 c-2 -2 -68
        -2 -147 -1 l-142 3 144 129 143 128 3 -127 c1 -70 1 -129 -1 -132z m178 151
        c66 -58 131 -116 145 -130 l25 -24 -153 0 -152 0 0 130 c0 72 4 130 8 130 4 0
        62 -48 127 -106z"/>
        <path d="M5890 13316 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
        13z"/>
        <path d="M8390 8360 l-105 -38 -3 -56 c-3 -54 -2 -57 16 -47 11 6 27 22 36 36
        9 14 22 25 30 25 17 0 116 -240 116 -280 0 -16 -7 -33 -16 -38 -18 -10 -11
        -52 9 -52 7 0 54 14 105 32 104 37 112 50 49 83 -35 18 -42 31 -92 155 -31 76
        -51 138 -46 143 5 5 27 4 50 -3 32 -8 46 -8 57 1 11 9 7 17 -29 45 -23 19 -49
        34 -57 33 -8 0 -62 -18 -120 -39z"/>
        <path d="M8130 8249 c-35 -17 -66 -32 -68 -34 -9 -9 21 -45 38 -45 23 0 32
        -16 94 -169 41 -100 45 -116 32 -130 -9 -11 -11 -23 -5 -38 4 -13 11 -23 13
        -23 12 0 156 70 156 75 0 21 -22 43 -39 38 -25 -6 -38 14 -95 154 -50 121 -53
        138 -32 150 17 10 3 53 -16 52 -7 0 -42 -14 -78 -30z"/>
        <path d="M7875 8084 c-78 -59 -78 -59 -70 -83 5 -16 12 -20 31 -15 23 6 28 -1
        84 -120 58 -122 71 -168 50 -181 -13 -8 -13 -18 4 -40 13 -18 19 -15 112 54
        55 41 102 77 106 80 3 3 1 31 -6 61 -10 45 -17 56 -35 58 -22 3 -23 1 -16 -35
        5 -31 2 -44 -15 -66 -12 -15 -28 -27 -36 -27 -8 0 -39 50 -73 118 -74 148 -77
        159 -56 182 13 15 14 23 5 39 -6 12 -14 21 -18 21 -4 0 -34 -21 -67 -46z"/>
        <path d="M7604 7908 c-106 -58 -111 -63 -102 -84 6 -13 21 -24 36 -26 22 -3
        37 -24 95 -132 59 -109 68 -132 58 -148 -8 -13 -8 -24 -1 -38 6 -11 12 -20 14
        -20 2 0 237 129 243 134 1 0 -3 24 -8 51 -5 28 -11 65 -14 83 -10 58 -44 31
        -45 -36 0 -36 -27 -80 -60 -97 -16 -8 -31 -15 -34 -15 -9 0 -75 113 -70 122
        10 15 48 8 71 -13 19 -19 23 -20 29 -6 7 19 -76 177 -93 177 -20 0 -23 -15
        -10 -49 10 -27 9 -33 -3 -41 -25 -16 -36 -11 -53 25 -24 49 -21 62 13 80 38
        20 68 19 112 -4 33 -16 38 -16 43 -3 7 18 -71 102 -95 102 -8 0 -65 -28 -126
        -62z"/>
        <path d="M7293 7731 c-57 -28 -103 -56 -103 -63 0 -23 16 -39 32 -33 21 8 28
        -4 93 -143 51 -110 56 -126 45 -147 -15 -28 -7 -55 15 -55 25 0 188 86 212
        111 48 52 56 126 22 211 -38 97 -96 151 -174 163 -34 5 -54 -1 -142 -44z m162
        -74 c48 -50 81 -138 71 -191 -8 -43 -51 -84 -72 -70 -19 11 -124 236 -124 264
        0 10 8 24 18 29 35 21 65 12 107 -32z"/>
        <path d="M7050 7625 c-41 -17 -75 -37 -74 -43 2 -28 5 -31 31 -34 24 -3 32
        -16 79 -128 51 -121 64 -173 45 -184 -5 -3 -7 -15 -4 -27 7 -25 23 -22 111 18
        54 25 59 30 51 51 -5 14 -17 22 -33 22 -26 0 -36 16 -101 174 -39 94 -42 114
        -21 130 12 9 13 15 3 32 l-12 21 -75 -32z"/>
        <path d="M6733 7581 c-39 -3 -74 -9 -77 -12 -11 -11 4 -36 28 -47 18 -8 34
        -42 74 -155 29 -80 52 -152 52 -160 0 -9 -9 -22 -20 -30 -16 -11 -19 -21 -14
        -41 7 -28 19 -31 74 -17 19 6 54 15 78 21 36 9 42 15 42 37 0 22 -4 25 -25 21
        -15 -3 -30 1 -38 11 -22 26 -50 98 -43 110 11 18 26 13 38 -14 11 -26 32 -33
        43 -15 5 8 -16 78 -50 163 -8 19 -47 24 -44 5 3 -26 -1 -48 -10 -48 -12 0 -30
        48 -33 90 -3 34 -2 35 35 38 33 3 43 -2 71 -31 25 -27 36 -33 49 -25 15 8 15
        13 -8 58 l-25 50 -63 -2 c-34 -1 -94 -4 -134 -7z"/>
        <path d="M11860 8387 c0 -13 9 -32 20 -42 19 -17 20 -29 18 -169 -3 -148 -3
        -151 -25 -154 -17 -2 -23 -10 -23 -27 0 -21 9 -27 63 -44 l62 -19 3 25 c2 14
        -3 30 -11 36 -11 9 -16 45 -20 145 -3 72 -4 132 -2 132 3 0 30 -81 61 -179 53
        -166 59 -180 83 -184 14 -3 27 -4 28 -3 1 1 9 65 18 142 22 202 23 204 51 214
        14 6 24 17 24 29 0 16 -13 24 -62 39 -63 18 -63 18 -69 -2 -4 -14 1 -26 14
        -37 18 -15 19 -23 11 -105 l-9 -89 -39 130 c-21 72 -43 135 -50 141 -9 9 -122
        44 -141 44 -3 0 -5 -11 -5 -23z"/>
        <path d="M12224 8245 c-31 -41 -27 -50 22 -47 21 2 36 -2 39 -10 3 -7 -12 -72
        -32 -143 -36 -122 -40 -130 -65 -133 -26 -3 -43 -25 -32 -43 5 -9 157 -89 169
        -89 9 0 6 32 -7 66 -10 28 -7 49 22 156 18 68 35 127 37 131 7 12 43 -23 54
        -50 10 -27 39 -31 39 -5 0 9 -3 34 -6 55 -6 35 -12 40 -107 92 -55 30 -101 55
        -103 55 -2 0 -15 -16 -30 -35z"/>
        <path d="M12489 8129 c-8 -15 -8 -24 0 -32 18 -18 13 -39 -34 -164 -30 -81
        -52 -125 -71 -139 -14 -12 -24 -29 -22 -38 1 -10 44 -39 94 -67 l92 -49 39 52
        c52 70 53 72 43 88 -6 10 -15 8 -40 -14 -26 -22 -41 -26 -74 -24 -40 3 -41 4
        -40 38 1 19 9 52 18 74 13 32 18 36 31 26 9 -8 12 -22 8 -42 -4 -20 -1 -33 8
        -38 10 -7 21 12 46 82 25 69 30 93 21 99 -8 5 -19 -3 -30 -21 -11 -20 -23 -28
        -33 -24 -16 7 -15 20 7 77 22 56 81 12 96 -70 11 -64 30 -40 34 42 l3 69 -85
        47 c-47 27 -88 48 -92 49 -4 0 -12 -9 -19 -21z"/>
        <path d="M12805 7956 c-65 -28 -138 -144 -151 -239 -14 -97 24 -167 90 -167
        28 0 35 -4 38 -22 2 -13 11 -24 20 -26 13 -2 26 14 50 62 19 40 40 69 53 72
        21 7 35 22 35 40 0 6 -28 23 -62 38 -48 21 -63 24 -69 14 -11 -17 -11 -25 2
        -46 9 -14 8 -26 -5 -50 -19 -37 -50 -42 -67 -11 -35 66 69 279 137 279 36 0
        45 -21 38 -89 -6 -52 -4 -61 9 -61 18 0 39 62 41 120 1 27 -3 34 -15 32 -11
        -1 -19 7 -23 23 -11 41 -65 55 -121 31z"/>
        <path d="M12999 7832 c-7 -18 -7 -28 0 -30 19 -6 12 -30 -41 -145 -60 -130
        -65 -137 -89 -137 -16 0 -37 -35 -27 -45 2 -3 33 -18 68 -34 63 -28 65 -28 78
        -9 12 16 12 22 -2 37 -15 16 -15 22 5 70 12 28 27 50 33 48 13 -4 13 -4 21
        -120 7 -88 7 -88 41 -104 18 -9 37 -13 41 -9 4 4 6 52 5 107 -3 95 -3 99 18
        99 51 0 100 60 100 124 0 49 -38 83 -149 133 l-92 42 -10 -27z m154 -113 c37
        -50 -11 -109 -71 -88 -37 13 -38 19 -12 77 23 51 50 54 83 11z"/>
        <path d="M13272 7685 c-7 -15 -8 -27 -2 -30 17 -11 11 -51 -24 -146 -48 -132
        -47 -129 -61 -129 -16 0 -29 -30 -18 -41 4 -4 31 -16 60 -27 49 -19 53 -19 58
        -2 3 9 3 24 0 33 -10 25 64 247 91 272 36 33 30 49 -29 73 -65 27 -61 27 -75
        -3z"/>
        <path d="M13410 7622 c-24 -29 -27 -38 -16 -44 8 -5 26 -3 40 4 15 6 29 8 33
        4 4 -4 -13 -67 -37 -139 -36 -105 -49 -133 -65 -136 -21 -4 -35 -18 -35 -38 0
        -13 148 -65 161 -57 14 8 10 40 -6 54 -19 16 -13 51 30 180 33 100 58 126 71
        76 4 -17 13 -26 26 -26 17 0 19 6 16 48 l-3 47 -92 32 -92 32 -31 -37z"/>
        <path d="M13648 7565 c-3 -14 -2 -28 2 -30 4 -3 11 -49 14 -102 6 -86 5 -104
        -13 -146 -15 -34 -26 -47 -41 -47 -11 0 -20 -7 -20 -15 0 -8 0 -18 -1 -22 0
        -4 30 -15 68 -25 51 -13 69 -14 75 -5 11 17 10 35 -2 42 -14 8 -13 47 3 82 16
        39 149 183 169 183 14 0 28 19 28 40 0 11 -96 33 -110 25 -13 -8 -13 -45 0
        -45 18 0 11 -14 -30 -60 l-39 -45 -1 58 c0 50 2 57 20 57 14 0 20 7 20 24 0
        21 -7 26 -52 36 -29 6 -60 14 -69 16 -11 3 -18 -3 -21 -21z"/>
        <path d="M11545 7442 c-33 -14 -62 -27 -64 -28 -2 -2 -1 -14 3 -28 4 -18 11
        -23 26 -19 18 5 20 0 20 -48 0 -79 -8 -84 -58 -38 -46 43 -50 54 -26 63 11 4
        13 13 8 28 -3 13 -8 24 -9 26 -2 3 -187 -67 -193 -73 -12 -11 12 -45 31 -45
        27 0 53 -17 137 -91 53 -46 69 -67 82 -109 16 -49 16 -52 -2 -67 -15 -13 -16
        -20 -7 -40 7 -16 16 -22 26 -17 9 3 48 17 86 32 39 14 71 26 73 27 2 2 0 15
        -4 29 -4 18 -11 25 -20 22 -26 -10 -43 6 -63 59 -19 49 -19 59 -6 150 9 68 20
        107 35 127 23 32 23 62 0 65 -8 1 -42 -10 -75 -25z"/>
        <path d="M8973 7434 c-3 -9 -1 -24 5 -33 7 -9 15 -21 18 -27 3 -6 -12 -72 -33
        -145 -36 -125 -40 -134 -65 -137 -32 -4 -45 -47 -19 -61 40 -21 244 -81 277
        -81 46 0 90 42 100 95 6 35 4 49 -20 107 -5 11 -1 18 13 23 26 8 51 54 51 94
        0 54 -42 87 -165 130 -161 56 -154 55 -162 35z m187 -109 c32 -16 45 -44 35
        -75 -8 -26 -46 -35 -90 -20 -34 11 -36 14 -29 43 14 69 31 79 84 52z m-38
        -171 c35 -18 42 -44 24 -88 -13 -32 -19 -36 -51 -36 -55 0 -78 16 -70 48 24
        101 25 103 48 96 12 -3 34 -13 49 -20z"/>
        <path d="M9330 7295 c0 -14 4 -25 10 -25 27 0 28 -40 4 -170 -26 -141 -34
        -160 -65 -160 -13 0 -19 -7 -19 -23 0 -21 9 -25 93 -45 50 -12 95 -22 100 -22
        4 0 7 13 7 29 0 21 -6 30 -21 34 -20 5 -21 10 -15 63 3 31 9 60 12 65 13 22
        36 -11 74 -107 40 -99 44 -104 79 -113 90 -24 88 -24 94 1 5 17 0 28 -18 43
        -13 11 -38 50 -54 88 l-30 67 37 16 c87 34 98 154 18 203 -29 18 -255 81 -290
        81 -10 0 -16 -9 -16 -25z m228 -101 c27 -31 28 -58 2 -84 -22 -22 -31 -23 -80
        -14 l-32 7 6 55 c4 30 9 59 12 64 10 16 68 -2 92 -28z"/>
        <path d="M11000 7270 c-107 -25 -115 -28 -115 -51 0 -17 6 -25 23 -27 29 -4
        36 -21 67 -161 30 -136 30 -147 6 -160 -13 -7 -17 -18 -14 -36 6 -30 3 -30
        108 -4 76 19 85 24 85 45 0 16 -7 24 -22 26 -25 3 -43 34 -53 91 -11 56 9 48
        96 -39 l77 -77 66 13 c36 7 66 14 66 15 0 22 -23 55 -39 55 -11 0 -47 25 -80
        56 l-61 56 25 20 c56 44 51 138 -10 186 -36 29 -78 27 -225 -8z m130 -50 c27
        -14 42 -47 35 -71 -8 -23 -93 -66 -105 -52 -13 14 -31 106 -23 114 8 7 37 16
        61 18 7 0 22 -4 32 -9z"/>
        <path d="M10655 7196 c-137 -21 -140 -22 -143 -47 -2 -21 2 -28 20 -32 21 -6
        25 -17 46 -154 l24 -147 -21 -17 c-19 -16 -28 -49 -16 -61 7 -7 316 39 325 49
        12 13 41 143 35 154 -13 19 -33 7 -60 -35 -31 -49 -88 -86 -133 -86 -34 0 -37
        5 -47 88 -7 51 -7 52 20 52 18 0 31 -9 43 -30 19 -33 52 -41 51 -12 0 9 -6 53
        -12 97 -11 71 -15 80 -35 83 -19 3 -22 -1 -22 -28 0 -23 -6 -33 -25 -42 -32
        -14 -34 -11 -42 49 -5 46 -4 50 18 56 61 17 102 5 152 -41 25 -23 29 -24 43
        -11 14 14 11 22 -22 77 -20 34 -41 61 -48 61 -6 -1 -74 -11 -151 -23z"/>
        <path d="M9865 7053 c-90 -176 -108 -203 -136 -203 -14 0 -19 -7 -19 -29 0
        -27 3 -29 53 -34 29 -4 67 -10 85 -13 31 -6 32 -5 32 25 0 20 -5 31 -14 31
        -24 0 -29 20 -16 61 14 43 17 44 88 29 41 -9 51 -16 65 -46 26 -53 22 -64 -18
        -64 -31 0 -35 -3 -35 -25 0 -14 6 -25 13 -25 6 0 51 -6 99 -14 85 -12 87 -12
        90 8 2 12 -5 29 -18 41 -13 11 -56 105 -100 218 -43 108 -81 197 -84 197 -3 0
        -41 -71 -85 -157z m91 -12 l11 -40 -31 6 c-41 8 -40 6 -21 42 21 41 29 39 41
        -8z"/>
        <path d="M10353 7173 c-27 -3 -33 -9 -33 -27 0 -15 7 -26 20 -29 26 -7 25 -23
        -14 -142 l-32 -100 -37 101 c-20 55 -37 108 -37 118 0 9 11 21 25 26 15 6 25
        17 25 30 0 19 -6 20 -105 20 -102 0 -105 -1 -105 -22 0 -14 11 -29 28 -39 21
        -13 46 -59 112 -202 48 -106 88 -180 92 -174 4 7 33 85 64 175 57 162 82 212
        110 212 9 0 14 11 14 30 0 29 -1 30 -47 28 -27 0 -63 -3 -80 -5z"/>
        <path d="M6619 6233 l-34 -38 38 34 c34 33 42 41 34 41 -2 0 -19 -17 -38 -37z"/>
        <path d="M13958 6033 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
        <path d="M13998 6023 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
        <path d="M14038 6013 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
        <path d="M11560 4765 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
        -28 25 -3 0 5 -11 18 -25z"/>
        <path d="M5685 16464 c-49 -25 -93 -63 -100 -84 -5 -15 10 -43 55 -105 l62
        -86 61 48 c41 32 70 65 86 96 22 44 22 50 9 85 -7 20 -25 42 -38 49 -34 17
        -99 16 -135 -3z"/>
        <path d="M4870 15664 c0 -6 82 -207 87 -213 4 -6 123 110 123 120 0 3 -21 15
        -47 27 -27 11 -74 32 -105 46 -32 14 -58 23 -58 20z"/>
        <path d="M4372 15244 c-36 -18 -82 -78 -82 -105 0 -10 30 -43 68 -75 37 -32
        77 -66 88 -77 21 -18 22 -18 59 33 45 61 56 118 31 164 -35 65 -105 90 -164
        60z"/>
        <path d="M3369 13697 c-68 -36 -136 -146 -113 -186 12 -20 451 -239 481 -240
        12 -1 31 10 42 25 52 67 55 185 5 257 -58 83 -222 167 -328 167 -25 0 -61 -10
        -87 -23z"/>
        <path d="M17513 10306 c-67 -17 -128 -57 -150 -98 -27 -53 -23 -136 9 -186 90
        -135 401 -152 524 -29 34 34 43 51 48 93 13 89 -40 172 -133 212 -49 20 -231
        25 -298 8z"/>
        <path d="M2799 9405 c-114 -12 -214 -25 -223 -28 -40 -16 -4 -181 50 -229 48
        -42 116 -62 209 -61 155 2 245 45 291 140 23 46 26 62 21 114 -3 33 -11 66
        -17 74 -16 20 -73 18 -331 -10z"/>
        <path d="M17120 7799 c109 -102 104 -101 131 -21 12 37 24 73 27 80 3 9 -24
        12 -114 12 l-119 -1 75 -70z"/>
        <path d="M3430 7639 c-41 -17 -79 -35 -84 -40 -19 -18 62 -168 105 -193 28
        -17 86 -21 104 -8 61 43 71 126 28 213 -34 67 -50 70 -153 28z"/>
        <path d="M3800 6699 c-30 -5 -70 -11 -88 -14 l-33 -6 37 -67 c20 -37 40 -71
        44 -75 6 -7 76 72 143 161 11 14 -17 15 -103 1z"/>
        <path d="M5140 5050 l-65 -59 54 -55 c90 -91 155 -107 201 -49 25 32 25 73 0
        122 -20 40 -88 101 -111 101 -8 -1 -43 -27 -79 -60z"/>
        <path d="M4912 4817 c-76 -77 -82 -85 -73 -109 12 -33 66 -84 115 -109 51 -27
        96 -19 141 26 43 43 53 90 30 136 -17 32 -112 139 -124 139 -4 0 -44 -37 -89
        -83z"/>
        <path d="M6582 3942 c-29 -49 -52 -94 -52 -99 0 -6 31 -24 68 -42 119 -54 213
        -36 228 44 12 68 -74 163 -163 181 -28 6 -31 2 -81 -84z"/>
        <path d="M8261 3163 c-35 -49 -63 -92 -63 -98 1 -8 117 -44 145 -45 8 0 0 137
        -12 198 l-6 32 -64 -87z"/>
        <path d="M10504 3086 c-79 -35 -128 -141 -129 -281 0 -119 22 -188 79 -252
        l44 -48 78 0 c64 0 84 4 105 20 67 51 99 136 99 266 0 141 -47 256 -120 294
        -36 18 -114 19 -156 1z"/>
        </g>
    </svg>
    </>
  )
}
