import React from 'react'

export default function Zealand() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="210.000000pt" height="150.000000pt" viewBox="0 0 1200.000000 437.000000"
 preserveAspectRatio="xMidYMid meet" className='md:-mt-7 mt-3'>

<g transform="translate(0.000000,437.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M1965 4359 c-390 -37 -778 -189 -1105 -433 -122 -90 -326 -294 -416
-416 -502 -674 -583 -1541 -212 -2285 278 -559 786 -972 1390 -1129 207 -54
324 -69 553 -69 354 0 640 66 950 217 218 107 392 230 569 404 345 338 563
773 631 1253 20 146 21 438 1 579 -68 483 -282 917 -615 1249 -462 461 -1100
691 -1746 630z m209 -215 c9 -4 16 -17 15 -33 l0 -26 -16 23 c-17 24 -52 29
-71 10 -18 -18 -15 -103 4 -122 18 -19 47 -20 77 -5 22 12 22 12 3 -10 -25
-28 -73 -28 -103 1 -18 16 -23 32 -23 67 1 74 54 118 114 95z m147 -4 c46 0
49 -2 49 -25 0 -14 -4 -25 -10 -25 -5 0 -10 5 -10 10 0 6 -12 15 -27 21 -30
11 -43 0 -43 -38 0 -19 6 -23 30 -23 17 0 30 -4 30 -10 0 -5 -13 -10 -30 -10
-28 0 -30 -3 -30 -35 0 -31 3 -35 26 -35 15 0 33 8 40 17 16 23 25 14 17 -16
-5 -20 -10 -22 -42 -17 -20 3 -49 6 -65 6 -21 0 -26 3 -18 13 12 15 22 157 11
157 -5 0 -9 5 -9 11 0 6 7 8 16 5 9 -3 38 -6 65 -6z m-317 -10 c-6 -6 -8 -30
-3 -63 4 -28 8 -68 8 -87 l1 -34 -66 64 -66 65 7 -55 c4 -30 12 -61 17 -67 8
-10 4 -13 -13 -13 -23 0 -24 4 -26 71 -1 51 -6 76 -19 90 -16 18 -16 19 7 19
15 0 43 -20 81 -57 l58 -57 -7 55 c-3 29 -10 56 -15 57 -18 8 -6 22 19 22 18
0 24 -3 17 -10z m-216 -48 c4 -24 -13 -30 -21 -8 -8 20 -57 8 -57 -14 0 -39
11 -49 42 -43 22 4 29 3 24 -5 -4 -7 -16 -12 -27 -12 -14 0 -19 -7 -19 -29 0
-17 5 -33 11 -36 19 -12 56 4 63 27 l7 23 10 -24 c5 -13 8 -26 5 -28 -6 -6
-109 -33 -126 -33 -12 0 -13 2 -1 9 12 7 11 20 -4 82 -10 41 -23 77 -28 81 -6
3 9 11 34 18 24 6 49 13 54 15 19 6 30 -1 33 -23z m796 22 c17 -7 22 -54 6
-54 -5 0 -10 7 -10 15 0 17 -36 39 -50 30 -20 -12 -9 -41 27 -71 31 -26 35
-34 29 -58 -4 -15 -17 -31 -31 -36 -32 -12 -85 6 -85 29 0 28 12 33 24 8 15
-30 60 -31 64 -1 2 14 -8 29 -33 48 -19 15 -35 35 -35 46 0 39 48 61 94 44z
m191 -72 c0 -29 -19 -33 -23 -6 -2 14 -12 24 -27 26 -19 4 -26 -1 -34 -24 -9
-26 -8 -30 14 -38 33 -12 32 -24 -1 -17 -20 3 -27 -1 -35 -20 -5 -13 -9 -28
-9 -33 0 -15 50 -24 63 -11 23 23 37 3 19 -26 -6 -9 -20 -8 -62 4 -53 15 -87
33 -62 33 14 0 54 132 46 153 -5 13 2 13 53 -2 48 -14 58 -21 58 -39z m-1171
2 c9 -23 0 -30 -19 -14 -21 17 -64 4 -74 -23 -10 -26 2 -73 25 -98 20 -22 57
-25 73 -5 14 17 4 46 -17 46 -22 0 -3 17 28 25 24 5 25 3 27 -39 1 -33 -1 -44
-11 -40 -7 3 -19 -2 -26 -11 -22 -27 -75 -19 -104 16 -36 43 -35 91 3 130 33
33 85 40 95 13z m1279 -19 c47 -19 70 -51 56 -78 -6 -11 -20 -22 -30 -24 -16
-5 -18 -11 -12 -47 3 -22 11 -45 17 -49 6 -4 0 -7 -14 -5 -22 3 -25 8 -28 50
-4 56 -25 70 -42 28 -7 -16 -9 -34 -5 -39 8 -14 3 -14 -31 -1 -24 9 -25 12
-11 23 25 19 67 133 54 146 -16 16 3 14 46 -4z m-1469 -40 c-8 -19 45 -145 60
-145 13 -1 -34 -29 -48 -30 -6 0 -5 5 1 11 14 14 -43 146 -66 152 -12 3 -10 7
9 15 36 15 50 14 44 -3z m1627 -25 c10 -6 19 -15 19 -21 0 -6 -4 -8 -9 -5 -9
6 -26 -112 -17 -121 7 -8 96 78 90 88 -8 14 10 11 32 -6 15 -12 16 -14 3 -15
-8 0 -43 -25 -76 -55 -34 -30 -66 -55 -72 -55 -8 0 -11 31 -9 100 2 55 7 100
11 100 5 0 17 -5 28 -10z m-1787 -45 c-4 -8 9 -44 27 -79 31 -59 35 -63 56
-53 14 6 23 19 23 34 0 12 5 23 10 23 6 0 10 -4 10 -9 0 -5 3 -16 6 -25 4 -10
-9 -22 -45 -40 -55 -28 -65 -31 -56 -16 7 12 -62 140 -76 140 -17 0 -9 17 14
28 31 15 37 14 31 -3z m1989 -69 c20 -16 22 -26 5 -26 -16 0 -90 -118 -82
-131 8 -13 -7 -11 -36 6 -15 9 -19 14 -10 15 19 0 93 118 86 136 -7 18 14 17
37 0z m-2139 -20 c-3 -8 12 -40 32 -73 21 -32 39 -59 40 -61 1 -2 13 4 27 13
17 11 23 22 20 35 -7 27 14 26 22 -1 7 -22 -2 -32 -67 -69 -28 -16 -36 -17
-32 -7 7 19 -66 137 -86 137 -11 1 -8 6 9 20 27 23 43 26 35 6z m1394 -55
c297 -66 556 -203 771 -407 303 -288 479 -674 498 -1088 35 -783 -502 -1472
-1275 -1636 -141 -30 -393 -38 -538 -16 -719 106 -1271 665 -1365 1381 -17
128 -6 403 20 527 134 623 628 1115 1242 1237 151 30 147 30 359 26 151 -3
216 -8 288 -24z m-1480 -13 c19 -19 14 -29 -10 -23 -13 3 -32 0 -42 -8 -19
-14 -19 -15 1 -40 18 -24 22 -25 39 -12 30 23 40 17 16 -8 l-22 -24 20 -26
c21 -26 22 -26 45 -9 17 12 22 23 18 40 -6 27 8 29 24 3 11 -17 7 -24 -30 -53
-57 -46 -69 -53 -65 -36 4 18 -70 118 -95 127 -17 6 -14 12 29 44 55 41 56 41
72 25z m2362 -12 c13 -15 20 -32 17 -40 -9 -23 -27 -19 -24 5 1 12 -7 27 -18
35 -27 18 -62 0 -88 -44 -26 -44 -16 -81 23 -89 35 -7 40 -23 7 -23 -28 0 -65
32 -73 64 -7 29 17 79 48 100 34 24 81 20 108 -8z m150 -160 c-21 -22 -40 -15
-22 7 9 11 6 19 -12 36 l-24 23 -17 -26 c-16 -25 -16 -28 -1 -45 24 -27 19
-35 -10 -16 -25 16 -27 16 -48 -4 -28 -29 -11 -57 33 -54 34 1 37 -13 8 -31
-14 -9 -26 -2 -64 37 -32 31 -42 47 -31 47 19 0 108 96 108 117 0 8 19 -3 50
-29 49 -42 50 -43 30 -62z m-2642 62 c19 -19 14 -29 -12 -22 -43 10 -40 -16 5
-63 24 -25 52 -48 62 -52 16 -6 16 -9 -8 -31 -23 -22 -25 -22 -25 -4 0 25 -94
119 -109 110 -7 -4 -8 -18 -5 -34 l7 -27 -22 19 -22 20 48 48 c50 50 61 56 81
36z m-94 -198 c30 -25 55 -48 55 -52 1 -5 -36 -8 -81 -8 l-83 -1 36 -33 c20
-18 42 -36 50 -38 11 -5 11 -9 -3 -24 -15 -15 -18 -16 -18 -3 0 21 -94 99
-119 99 -14 0 -19 5 -15 15 5 12 25 15 95 15 l89 0 -39 35 c-21 19 -46 35 -56
35 -17 0 -17 1 1 21 17 19 19 19 26 2 4 -10 32 -38 62 -63z m-147 -162 c26
-18 56 -35 66 -38 18 -5 18 -6 -2 -30 -14 -17 -21 -20 -21 -10 -1 18 -117 103
-130 95 -15 -9 -12 8 7 34 16 24 16 24 24 4 4 -12 29 -36 56 -55z m3173 -8
c39 -58 40 -59 19 -70 -24 -14 -37 -6 -21 13 7 8 7 18 2 27 -7 11 -19 7 -65
-25 -32 -21 -60 -47 -64 -58 -7 -18 -8 -17 -24 7 -19 28 -22 40 -8 31 12 -7
126 72 126 88 0 7 -10 12 -22 11 -26 -1 -29 6 -11 24 19 19 25 15 68 -48z
m-3333 -93 c0 -8 14 -35 32 -61 22 -32 42 -49 62 -53 16 -3 29 -7 29 -10 0
-13 -22 -43 -32 -43 -6 0 -8 3 -5 7 4 3 -5 15 -20 25 -32 21 -122 25 -141 6
-19 -19 -15 13 4 37 11 14 18 16 21 8 3 -8 22 -13 45 -13 l40 0 -21 33 c-11
17 -28 31 -36 31 -18 -2 -18 1 -3 26 15 23 25 26 25 7z m3428 -62 c25 -48 28
-57 15 -65 -8 -5 -22 -10 -30 -10 -12 0 -12 3 2 16 13 13 14 22 6 40 -13 27
-26 30 -53 10 -17 -12 -18 -18 -7 -40 14 -31 6 -34 -17 -5 -15 18 -20 19 -44
8 -15 -7 -27 -17 -27 -23 1 -22 28 -46 51 -46 23 -1 23 -1 5 -15 -29 -22 -33
-20 -63 38 -37 70 -37 68 -20 61 19 -7 137 56 137 74 0 25 16 10 45 -43z
m-3511 -111 c22 -8 20 -24 -3 -24 -10 0 -21 -6 -24 -14 -4 -10 14 -23 61 -43
37 -15 72 -27 79 -25 17 4 16 -7 -2 -38 -9 -17 -14 -20 -15 -9 0 18 -135 77
-147 65 -4 -4 -1 -16 6 -27 16 -26 9 -34 -18 -20 -20 11 -20 12 7 76 28 67 30
69 56 59z m3611 -101 c21 -49 16 -85 -13 -99 -32 -16 -62 -6 -75 25 -6 14 -12
27 -13 29 -4 7 -54 -22 -54 -32 -1 -16 -30 25 -30 42 0 7 5 10 10 7 14 -9 141
37 147 53 8 18 11 15 28 -25z m-3699 -82 c-7 -11 120 -57 146 -54 15 2 15 -2
6 -28 -9 -25 -13 -28 -19 -16 -11 19 -130 60 -152 51 -15 -5 -15 -2 -6 25 6
17 15 31 21 31 5 0 7 -4 4 -9z m3799 -111 l5 -35 -14 30 c-18 35 -21 64 -6 49
5 -5 12 -25 15 -44z m-132 20 c64 0 97 -9 97 -24 0 -6 -31 -34 -70 -61 -38
-28 -70 -57 -70 -64 0 -23 -16 0 -24 34 -6 24 -5 28 5 18 28 -28 51 26 29 68
-12 21 -50 26 -50 7 0 -17 -20 12 -20 29 0 9 7 10 26 3 14 -6 49 -10 77 -10z
m-3649 -55 c20 -21 27 -23 55 -15 18 5 40 15 49 22 13 11 15 10 9 -5 -4 -10
-7 -23 -7 -28 0 -5 -18 -12 -40 -15 -57 -9 -71 -30 -28 -42 22 -6 35 -5 40 2
13 19 15 2 5 -34 -6 -20 -12 -28 -14 -19 -4 18 -133 52 -159 42 -12 -5 -15 -2
-11 13 3 10 9 31 13 47 6 25 37 57 57 57 4 0 18 -11 31 -25z m-38 -171 c27 -4
57 -11 67 -16 26 -13 38 -53 27 -83 -14 -37 -46 -48 -112 -37 -38 6 -61 5 -68
-2 -7 -7 -10 0 -10 22 0 29 14 44 22 23 2 -5 33 -12 70 -16 61 -7 67 -6 78 14
26 49 -13 74 -116 76 -36 0 -40 2 -36 23 3 16 8 20 17 12 6 -5 34 -13 61 -16z
m3844 -43 c0 -18 -3 -21 -12 -13 -9 7 -29 2 -67 -17 -59 -30 -57 -38 13 -47
37 -5 47 -3 52 10 9 24 24 9 24 -24 0 -22 -4 -27 -18 -24 -9 3 -42 -6 -72 -19
l-55 -24 65 -14 c36 -8 67 -11 68 -7 8 18 22 6 22 -18 0 -20 -3 -24 -12 -16
-7 5 -50 18 -95 27 -49 11 -83 23 -83 30 0 7 24 23 53 35 l52 23 -57 14 c-32
9 -58 20 -58 27 0 6 34 26 76 45 42 19 78 41 81 48 8 20 23 -4 23 -36z m-3900
-160 c0 -5 -7 -11 -15 -15 -9 -3 -15 -18 -15 -34 0 -23 6 -32 30 -42 71 -29
138 17 111 78 l-11 27 20 -24 c23 -27 25 -40 9 -74 -23 -51 -100 -62 -148 -21
-25 20 -31 34 -31 63 0 21 3 41 7 44 10 10 43 8 43 -2z m3828 -126 c43 -5 67
-3 77 5 13 11 15 8 15 -19 0 -28 -3 -31 -20 -27 -11 3 -29 9 -40 12 -18 5 -20
1 -20 -40 0 -44 1 -46 30 -46 17 0 30 5 30 10 0 6 5 10 10 10 6 0 10 -14 10
-31 0 -26 -3 -30 -17 -24 -34 15 -142 18 -158 5 -13 -11 -15 -7 -15 26 0 30 3
35 12 26 7 -7 27 -12 45 -12 32 0 33 1 33 40 0 39 -1 40 -35 40 -19 0 -35 -4
-35 -10 0 -5 -4 -10 -10 -10 -5 0 -10 14 -10 31 0 24 4 29 18 25 9 -2 45 -8
80 -11z m-3694 -67 c3 -13 6 -43 6 -67 0 -33 -3 -41 -12 -33 -15 12 -158 4
-158 -9 0 -5 -4 -9 -10 -9 -6 0 -10 28 -10 65 0 63 1 65 25 65 14 0 25 -4 25
-10 0 -5 -7 -10 -15 -10 -10 0 -15 -10 -15 -30 0 -27 3 -30 30 -30 27 0 30 3
30 30 0 17 5 30 10 30 6 0 10 -13 10 -30 0 -31 7 -34 48 -24 16 5 22 14 22 35
0 19 -5 29 -14 29 -8 0 -18 5 -21 10 -3 6 5 10 18 10 17 0 27 -7 31 -22z
m3691 -177 c39 -20 70 -44 70 -51 0 -10 -28 -21 -85 -33 -47 -11 -88 -24 -92
-30 -12 -19 -18 -2 -12 32 4 20 10 28 14 21 14 -22 43 -6 52 29 7 27 6 35 -9
46 -13 9 -22 10 -30 3 -14 -12 -18 8 -7 36 6 15 7 15 18 1 6 -9 43 -33 81 -54z
m-3691 13 c9 -9 16 -32 16 -55 0 -32 -3 -39 -20 -39 -11 0 -20 5 -20 10 0 6 4
10 9 10 16 0 23 40 11 55 -21 26 -39 16 -62 -32 -28 -59 -66 -70 -93 -29 -22
33 -13 76 15 76 23 0 27 -15 5 -24 -20 -7 -19 -45 0 -52 20 -8 41 11 50 44 4
15 11 33 17 39 14 18 53 16 72 -3z m3647 -197 c39 -9 75 -15 80 -11 10 6 12
-17 3 -41 -5 -11 -9 -13 -14 -6 -3 6 -21 16 -38 21 l-33 9 23 -40 c12 -21 28
-39 36 -39 9 0 12 -6 8 -16 -3 -9 -6 -20 -6 -25 0 -5 -4 -9 -10 -9 -5 0 -10 7
-10 15 0 9 -8 28 -17 43 -9 15 -18 28 -19 30 -3 6 -109 -40 -116 -51 -12 -20
-15 -3 -6 32 5 19 13 30 16 24 8 -13 82 13 82 28 0 17 -51 31 -66 18 -17 -14
-18 -1 -2 38 7 18 12 23 15 13 2 -9 31 -22 74 -33z m-3603 -41 c27 -28 57
-135 31 -109 -11 11 -131 -19 -156 -39 -8 -7 -15 1 -22 29 -23 80 -5 126 52
141 43 11 71 5 95 -22z m3540 -176 c39 -38 72 -74 72 -80 0 -6 -34 -10 -89
-10 -49 0 -96 -3 -105 -6 -13 -5 -14 -2 -5 25 6 17 15 31 21 31 5 0 6 -4 3
-10 -3 -6 4 -10 18 -10 32 0 57 51 37 75 -7 9 -20 13 -28 10 -10 -4 -12 0 -8
11 3 8 6 20 6 25 0 5 1 9 3 9 1 0 35 -31 75 -70z m-3496 -32 c-10 -18 -29 -50
-41 -71 l-23 -37 53 21 c30 11 56 25 60 30 3 5 11 -2 18 -17 10 -23 10 -26 -6
-20 -20 8 -123 -30 -141 -53 -11 -14 -14 -14 -22 -1 -6 10 -3 24 9 45 51 82
61 101 61 107 0 12 -83 -23 -97 -39 -10 -13 -13 -12 -22 6 -15 27 -14 34 4 27
9 -3 48 7 88 23 39 16 73 25 75 20 2 -5 -5 -24 -16 -41z m3419 -146 c38 -21
67 -31 79 -27 19 6 19 4 8 -20 -12 -27 -12 -27 -90 -20 -43 4 -78 5 -78 2 0
-2 20 -31 45 -63 25 -31 45 -60 45 -64 0 -3 -7 -15 -15 -26 -13 -17 -14 -17
-15 -2 0 20 -124 93 -140 83 -13 -8 -13 1 0 26 6 10 15 19 21 19 6 0 8 -4 5
-8 -3 -5 20 -21 50 -35 l56 -28 -46 60 c-25 33 -46 62 -46 66 0 4 35 4 78 1
l77 -7 -50 28 c-27 15 -56 26 -62 25 -7 -2 -13 0 -13 3 0 3 6 14 13 25 7 13
13 16 15 8 1 -7 30 -28 63 -46z m-3316 -3 c15 -28 15 -30 -1 -27 -28 5 -40
-26 -21 -56 18 -27 52 -35 60 -13 4 9 8 8 16 -3 16 -23 14 -27 -23 -28 -19 -1
-63 -6 -97 -11 -88 -15 -91 -2 -18 80 32 37 59 72 59 78 0 19 9 12 25 -20z
m139 -230 c13 -23 14 -30 4 -27 -20 5 -139 -75 -133 -89 9 -26 -11 -12 -29 19
-18 29 -18 31 -2 25 12 -5 37 7 77 35 56 39 59 42 45 62 -8 12 -23 20 -35 19
-26 -3 -27 5 -3 23 15 12 20 10 37 -11 11 -14 29 -39 39 -56z m3050 -36 c26
-38 42 -71 37 -76 -5 -5 -42 1 -86 13 -43 12 -86 19 -96 17 -23 -6 -24 2 -3
31 14 20 17 21 30 7 23 -22 38 -18 58 15 14 25 15 34 6 45 -7 8 -16 12 -21 9
-14 -8 -11 20 3 34 10 10 14 8 20 -7 4 -11 27 -51 52 -88z m-2986 -61 c-7 -3
-19 -11 -26 -20 -10 -13 -8 -20 14 -44 22 -22 31 -25 46 -17 10 5 16 14 13 20
-4 5 -2 9 4 9 6 0 15 -9 21 -19 13 -26 13 -31 -3 -25 -8 3 -51 -9 -96 -25 -62
-23 -83 -27 -87 -17 -3 7 13 45 36 84 22 39 40 79 40 88 0 15 4 14 26 -7 17
-16 21 -25 12 -27z m2807 -13 c-9 -14 94 -108 119 -109 17 0 15 -5 -14 -35
-55 -57 -110 -56 -110 1 0 18 -8 25 -36 33 -19 5 -42 7 -50 4 -17 -6 -19 8 -3
24 7 7 23 7 50 1 21 -6 46 -7 56 -4 16 7 15 9 -7 26 -14 11 -31 20 -38 20 -9
0 -8 7 4 25 15 24 43 37 29 14z m-2650 -150 c28 -28 43 -49 34 -47 -17 3 -123
-105 -112 -116 3 -3 2 -6 -3 -6 -6 0 -29 19 -52 43 -41 42 -42 44 -24 60 21
19 38 11 20 -10 -9 -11 -7 -19 10 -35 21 -21 22 -21 42 -3 25 22 26 41 3 54
-10 5 -15 13 -10 17 4 4 15 0 25 -9 24 -22 27 -22 47 9 16 24 16 27 -1 45 -12
13 -24 17 -43 12 -26 -6 -26 -6 -7 16 11 11 20 21 20 21 1 0 24 -23 51 -51z
m2579 -49 c29 -27 59 -50 66 -50 9 0 8 -6 -5 -20 -20 -23 -35 -26 -35 -8 0 17
-81 98 -99 98 -23 0 -50 -30 -44 -48 9 -25 85 -102 95 -96 17 11 7 -14 -13
-32 -16 -14 -21 -15 -17 -4 3 9 -13 35 -38 62 -53 57 -58 95 -18 127 15 12 33
21 41 21 8 0 38 -22 67 -50z m-2404 -100 c0 -4 -40 -19 -90 -35 l-90 -29 21
-22 c18 -19 26 -21 41 -13 11 6 22 8 25 5 7 -7 -16 -36 -29 -36 -17 0 -99 73
-91 80 4 4 46 20 92 35 l85 28 -29 23 c-22 17 -33 20 -47 13 -21 -11 -26 9 -7
28 9 9 25 2 65 -30 30 -23 54 -44 54 -47z m2254 -43 c27 -39 43 -54 55 -50 12
4 11 1 -5 -16 l-20 -22 -69 40 c-38 22 -70 39 -71 38 -1 -1 4 -35 13 -75 11
-57 12 -76 3 -88 -15 -17 -33 -19 -27 -1 6 17 -73 127 -91 127 -12 0 -12 3 3
20 23 26 31 25 39 -2 3 -13 19 -39 35 -58 l29 -35 -15 73 c-8 41 -13 76 -10
78 2 3 31 -11 63 -30 33 -19 60 -33 62 -31 7 7 -54 84 -67 85 -11 0 -9 5 5 21
18 20 19 20 24 1 3 -10 22 -44 44 -75z m-2104 -64 c-1 -10 -5 -37 -9 -60 -7
-37 -6 -41 8 -34 9 5 27 21 40 35 14 14 30 26 38 26 16 0 16 9 -3 -90 -8 -45
-13 -89 -10 -96 7 -18 -14 -17 -37 0 -11 9 -14 15 -7 18 12 4 42 128 31 128
-4 0 -24 -18 -45 -39 -29 -29 -36 -44 -31 -60 6 -20 6 -20 -23 -4 -24 13 -27
20 -17 28 10 8 35 94 35 121 0 3 -22 -14 -49 -37 -29 -25 -47 -48 -44 -56 4
-11 1 -12 -21 -2 -14 7 -26 16 -26 21 0 5 4 7 9 3 5 -3 40 22 78 54 74 65 83
70 83 44z m1856 -15 c0 -18 4 -66 8 -105 11 -90 5 -92 -78 -19 -34 29 -70 56
-81 58 -17 3 -16 6 12 21 39 21 44 21 37 4 -3 -8 2 -19 10 -26 12 -10 21 -9
45 4 33 17 41 42 16 51 -21 9 -19 19 8 32 12 6 22 11 23 11 0 1 0 -14 0 -31z
m-1576 -110 c16 -6 20 -12 12 -17 -17 -10 -64 -131 -58 -147 7 -19 -3 -18 -63
7 -52 22 -55 26 -45 53 9 22 24 20 24 -4 0 -16 36 -48 45 -38 15 17 26 53 18
55 -6 2 -19 11 -29 19 -18 14 -17 15 13 9 25 -5 34 -2 42 14 16 30 14 40 -14
53 -19 8 -28 8 -40 -2 -20 -17 -25 -11 -14 17 8 22 9 22 46 6 21 -8 49 -20 63
-25z m1388 25 c-3 -30 46 -142 62 -146 29 -6 -50 -38 -88 -35 -36 3 -37 4 -37
46 0 34 -5 47 -25 62 -14 11 -31 20 -37 20 -20 0 -15 17 5 22 10 3 32 -7 52
-23 19 -16 38 -29 42 -29 16 0 8 40 -11 59 -20 20 -19 20 7 30 15 5 28 10 29
10 1 1 1 -7 1 -16z m-722 -98 l18 -55 64 0 65 0 -49 -37 c-27 -20 -50 -37 -51
-38 -2 -1 4 -25 13 -54 8 -29 13 -55 11 -58 -3 -2 -25 10 -50 27 l-44 32 -52
-37 c-56 -40 -58 -38 -36 25 21 61 20 65 -31 102 l-47 33 63 5 63 5 14 40 c20
61 22 65 27 65 3 0 13 -25 22 -55z m-552 -34 c15 -39 29 -71 31 -71 3 0 11 23
20 52 12 41 12 53 3 60 -16 10 12 11 36 2 14 -5 14 -8 3 -18 -18 -14 -49 -129
-41 -150 6 -17 -4 -21 -22 -8 -6 4 -24 40 -40 81 l-28 74 -14 -34 c-8 -19 -15
-48 -15 -66 -1 -17 -3 -30 -6 -29 -4 2 -15 6 -25 10 -17 6 -17 8 -4 19 9 6 23
39 32 72 23 83 25 87 34 81 5 -3 21 -37 36 -75z m1094 52 c11 -10 28 -43 37
-73 10 -30 23 -58 29 -62 13 -9 8 -14 -24 -21 -23 -6 -25 -3 -25 31 -1 44 -34
116 -55 120 -9 1 -24 -4 -35 -11 -17 -13 -18 -19 -6 -71 7 -31 19 -63 28 -71
18 -18 17 -20 -15 -30 -21 -6 -23 -4 -18 12 4 11 -1 39 -9 63 -21 61 -19 86
11 109 33 26 55 27 82 4z"/>
<path d="M2861 3968 c-11 -30 -3 -47 21 -48 9 0 38 30 38 40 0 11 -29 40 -40
40 -4 0 -13 -14 -19 -32z"/>
<path d="M2033 3689 c-189 -18 -336 -60 -518 -149 -156 -75 -276 -161 -400
-285 -123 -123 -209 -244 -286 -400 -90 -185 -127 -314 -150 -530 -35 -331 72
-719 278 -1007 72 -101 214 -246 313 -320 273 -203 567 -299 909 -299 398 1
744 140 1030 416 225 217 371 481 438 795 26 121 26 449 0 570 -89 411 -310
741 -647 965 -209 139 -440 221 -695 245 -121 11 -151 11 -272 -1z m427 -44
c597 -130 1035 -569 1161 -1165 32 -151 32 -419 0 -570 -32 -148 -67 -248
-132 -377 -76 -150 -153 -257 -274 -378 -272 -271 -601 -416 -985 -432 -741
-30 -1385 494 -1512 1232 -26 153 -21 409 11 546 64 281 187 511 379 711 235
244 509 388 847 444 107 18 403 11 505 -11z"/>
<path d="M2144 3108 c22 -32 20 -41 -9 -34 -23 6 -25 4 -25 -26 0 -25 3 -29
12 -20 19 19 41 15 33 -5 -4 -10 -8 -23 -9 -29 -1 -7 -8 -19 -15 -28 -16 -19
-9 -31 14 -23 8 4 15 16 15 27 0 11 7 20 15 20 8 0 15 -9 15 -20 0 -11 7 -23
15 -27 25 -9 30 5 9 28 -10 12 -18 22 -16 23 2 0 0 9 -4 19 -9 24 0 31 25 17
20 -10 21 -8 21 27 0 29 -3 34 -12 25 -16 -16 -42 -15 -35 1 20 49 21 47 -22
47 -42 0 -42 0 -27 -22z m40 -59 c-3 -5 -10 -7 -15 -3 -5 3 -7 10 -3 15 3 5
10 7 15 3 5 -3 7 -10 3 -15z"/>
<path d="M2974 2965 c-7 -19 -14 -41 -14 -50 0 -11 -6 -14 -23 -10 -28 7 -97
-15 -108 -35 -38 -69 -49 -108 -41 -145 6 -30 13 -41 32 -45 55 -13 13 -46
-83 -65 -21 -4 -41 -26 -87 -98 -33 -50 -63 -102 -66 -114 -6 -22 -10 -23
-135 -23 l-129 0 0 -470 c0 -393 2 -469 14 -464 14 5 30 -32 20 -48 -10 -15
-33 -8 -39 12 -3 11 -14 20 -23 19 -11 0 -12 -3 -4 -6 17 -7 15 -43 -4 -50 -9
-3 -19 0 -24 6 -5 10 -9 9 -13 -3 -5 -13 -22 -16 -77 -16 -60 0 -70 3 -71 18
0 10 -3 12 -6 5 -2 -7 -13 -13 -24 -13 -14 0 -19 7 -19 25 0 14 6 25 13 25 8
0 7 4 -3 10 -21 13 -30 12 -24 -3 2 -7 -1 -20 -8 -30 -19 -25 -43 -6 -34 27 4
15 11 24 16 21 7 -4 10 163 10 464 l0 471 -30 0 c-17 0 -45 8 -63 17 -18 9
-58 17 -92 18 -33 1 -86 2 -117 3 l-57 2 -22 73 c-24 83 -57 132 -104 154 -33
16 -38 42 -8 39 21 -1 42 62 40 123 -1 27 1 47 6 44 4 -2 7 1 7 7 0 14 -33 26
-96 35 -51 6 -52 6 -80 -30 -20 -27 -29 -50 -30 -82 -1 -24 -5 -47 -8 -50 -11
-11 -6 -41 7 -46 9 -4 8 -8 -3 -16 -8 -6 -18 -11 -22 -11 -5 0 -42 69 -84 153
-42 83 -78 154 -79 155 -2 2 -12 -1 -22 -7 -10 -6 -15 -11 -11 -11 4 0 -13
-32 -38 -70 -90 -140 -103 -197 -58 -264 28 -41 28 -39 -2 -169 -17 -73 -17
-79 -1 -113 10 -20 39 -59 66 -87 26 -29 53 -65 58 -82 12 -38 43 -40 55 -3 7
21 5 33 -12 55 -19 26 -20 34 -10 81 13 58 17 62 78 78 43 11 44 11 59 -20 14
-30 13 -36 -13 -96 -29 -69 -36 -140 -49 -490 -3 -80 -8 -155 -10 -167 -5 -21
-6 -20 -37 10 -18 18 -45 52 -60 77 -36 59 -35 44 2 -29 16 -32 26 -61 23 -65
-3 -3 2 -9 11 -12 12 -5 14 -9 6 -14 -7 -5 -2 -10 16 -14 18 -5 30 -3 35 6 6
9 9 10 9 1 0 -7 -12 -19 -27 -28 l-28 -16 30 5 30 4 -24 -20 -25 -19 30 6 29
6 -24 -21 -25 -21 30 7 c16 4 35 11 42 17 8 6 12 6 12 -2 0 -6 -3 -11 -7 -11
-5 0 -21 -11 -38 -25 l-30 -25 43 16 c54 21 63 12 23 -22 -41 -34 -34 -41 18
-14 49 25 52 17 9 -29 l-33 -34 50 28 c45 25 94 65 88 72 -8 8 -2 43 6 39 16
-10 0 -49 -38 -96 -51 -62 -58 -75 -35 -62 34 19 116 92 104 92 -9 0 -9 4 -3
13 6 6 9 27 7 45 -2 22 1 32 9 29 11 -3 12 -26 4 -77 -2 -8 -19 -40 -40 -70
-44 -65 -47 -83 -10 -49 l27 24 -19 -30 c-10 -16 -25 -40 -33 -53 -19 -29 -3
-68 34 -82 36 -14 42 -13 69 14 l24 24 50 -26 c41 -22 54 -25 78 -17 23 9 29
18 34 51 3 23 13 49 22 58 9 10 17 22 17 27 0 5 5 9 11 9 12 0 12 1 -25 -82
-29 -65 -23 -76 13 -23 59 87 81 94 35 11 -15 -26 -24 -49 -22 -51 7 -7 108
103 108 117 0 9 3 9 11 1 7 -7 2 -28 -20 -72 -37 -72 -28 -79 19 -16 19 25 37
45 42 45 4 0 8 8 9 18 0 11 2 12 6 4 3 -7 -2 -21 -10 -30 -19 -22 -50 -83 -44
-88 2 -2 25 19 51 46 27 28 50 49 52 47 2 -2 -12 -25 -32 -51 -20 -26 -34 -49
-32 -51 2 -2 24 13 48 35 58 51 72 51 26 0 -53 -58 -49 -62 19 -17 l30 20 -27
-29 c-42 -46 -34 -48 28 -9 31 20 59 34 62 31 3 -3 1 -6 -5 -6 -5 0 -25 -19
-43 -42 -34 -42 -29 -43 26 -2 17 13 34 20 38 17 3 -4 6 -1 6 6 0 6 7 23 16
36 l16 25 18 -22 c10 -12 15 -29 12 -38 -3 -8 -2 -12 3 -8 4 4 27 -6 51 -23
41 -28 63 -27 28 1 -8 8 -20 22 -26 33 -10 20 -10 20 18 0 16 -11 40 -26 54
-33 22 -11 20 -7 -10 25 -35 38 -29 39 22 3 42 -29 42 -21 1 29 -38 46 -20 49
22 3 11 -12 28 -26 39 -32 13 -7 10 2 -13 36 -39 57 -28 59 24 6 21 -22 40
-38 43 -36 2 2 -10 26 -27 52 -16 27 -28 51 -25 53 2 2 5 2 7 0 37 -48 81 -99
84 -95 2 2 -9 33 -26 69 -34 77 -22 75 49 -9 25 -29 49 -54 53 -54 4 0 -8 29
-28 63 -56 101 -48 99 35 -8 46 -59 82 -67 145 -32 l45 26 26 -26 c19 -19 31
-23 52 -18 35 9 62 33 62 57 0 21 -79 143 -87 134 -3 -2 9 -25 27 -50 l31 -46
-24 0 c-13 0 -50 -16 -83 -36 -74 -45 -102 -56 -125 -49 -24 8 -13 35 15 35
11 0 32 6 46 13 21 11 26 21 30 72 l5 60 40 6 c30 4 36 7 23 13 -10 5 -15 12
-12 17 3 5 2 9 -3 9 -15 0 -13 182 2 241 8 31 15 82 15 112 0 47 -3 56 -17 55
-10 -1 -35 4 -56 11 -21 7 -40 11 -41 9 -2 -1 3 -57 10 -123 7 -66 11 -127 9
-136 -3 -9 -17 -26 -30 -39 l-25 -23 -1 29 c0 25 -2 26 -9 9 -5 -11 -9 -33 -9
-48 -1 -31 -28 -48 -42 -26 -5 8 -9 28 -9 44 -1 59 -17 59 -24 1 -6 -45 -11
-56 -26 -56 -13 0 -20 10 -25 38 -11 67 -25 73 -25 11 0 -43 -4 -58 -16 -63
-22 -9 -21 -9 -34 29 -11 35 -11 37 42 93 68 74 118 171 124 243 4 42 27 101
106 267 56 116 108 212 115 212 7 0 13 -2 13 -4 0 -3 -45 -101 -100 -217 -98
-208 -123 -269 -75 -179 22 42 24 43 25 18 0 -24 4 -28 28 -29 15 0 43 -6 62
-13 30 -11 36 -18 40 -51 3 -22 11 -40 20 -43 8 -3 12 -1 8 3 -4 4 0 14 10 22
16 12 16 13 -2 6 -16 -5 -18 -3 -13 11 4 10 3 15 -3 11 -6 -3 -10 -1 -10 4 0
6 5 11 10 11 7 0 7 6 0 19 -5 11 -7 26 -4 34 3 8 1 18 -5 21 -6 4 -8 13 -4 21
3 8 0 15 -6 15 -7 0 -9 11 -7 26 6 30 -2 56 -15 48 -5 -3 -9 -1 -9 5 0 5 5 13
11 17 8 5 7 10 -4 16 -9 5 -14 15 -11 23 4 8 10 12 15 9 5 -3 9 7 8 23 0 25
-1 26 -9 8 -8 -19 -9 -19 -9 3 -1 31 63 154 88 170 29 17 30 72 1 72 -11 0
-20 3 -20 8 0 4 -14 7 -30 8 -17 1 -30 -3 -30 -7 0 -15 -27 -10 -51 10 -21 17
-22 21 -10 49 7 18 16 30 20 27 4 -2 15 5 24 16 15 17 36 24 61 20 5 -1 16 4
25 12 19 16 52 31 64 28 5 -1 6 3 2 9 -3 5 -2 10 3 10 6 0 13 7 16 16 6 16 -6
12 -35 -13 -16 -13 -135 -63 -152 -63 -5 0 -6 8 -3 18 6 16 -1 9 -54 -61 -12
-15 -26 -25 -31 -21 -5 3 -9 0 -9 -6 0 -6 8 -14 18 -17 9 -4 31 -17 47 -29 33
-24 64 -32 82 -21 6 4 22 3 36 -2 14 -5 28 -7 31 -4 7 7 -1 -34 -9 -47 -9 -15
-51 -12 -132 9 -159 41 -171 69 -88 196 57 87 77 105 115 105 11 0 34 7 50 15
26 14 30 14 30 1 0 -8 4 -17 9 -20 5 -4 7 11 4 34 -4 31 -2 41 11 46 10 4 16
18 16 36 0 26 -2 28 -15 14 -9 -8 -14 -21 -13 -28 2 -8 -9 -14 -31 -16 -24 -3
-32 0 -28 10 2 7 15 12 28 10 22 -2 21 0 -8 19 -37 23 -37 23 -24 47 5 9 13
26 16 37 5 13 9 15 12 8 6 -18 20 -16 20 2 0 8 -4 12 -9 9 -4 -3 -8 4 -8 14 0
37 106 57 137 26 13 -12 14 -18 3 -24 -10 -6 -10 -9 1 -13 16 -5 -32 -27 -60
-27 -28 0 -33 46 -7 58 15 8 17 11 5 11 -9 1 -24 -8 -34 -19 -10 -11 -13 -20
-7 -20 7 0 12 -7 12 -15 0 -9 7 -18 15 -21 8 -4 12 -11 8 -18 -4 -6 5 -3 20 7
30 20 37 21 37 7 0 -5 -7 -10 -15 -10 -8 0 -15 -5 -15 -11 0 -6 7 -9 15 -5 8
3 15 3 15 0 0 -3 -13 -10 -30 -15 -27 -10 -30 -15 -30 -55 0 -24 -5 -53 -12
-64 -19 -30 1 -38 24 -10 16 20 19 34 14 70 -6 43 -5 45 18 45 14 0 21 -4 18
-10 -4 -5 -8 -21 -9 -35 -2 -14 -5 -38 -8 -55 -4 -27 -3 -28 9 -12 7 9 22 17
32 18 16 0 15 2 -4 10 -19 7 -22 14 -16 31 4 13 8 32 9 43 1 11 5 31 9 44 6
18 10 21 17 10 6 -10 9 -4 9 18 0 17 5 35 11 38 6 4 8 14 5 22 -4 8 9 28 31
48 30 28 33 33 15 28 -26 -6 -30 12 -4 26 9 6 12 11 5 11 -7 0 -26 -15 -43
-32 -23 -23 -29 -27 -21 -11 15 29 14 33 -6 26 -13 -5 -15 -2 -11 15 9 34 -1
26 -18 -13z m-1681 -237 c58 -120 102 -218 99 -218 -4 0 -55 97 -112 216 -112
232 -118 245 -103 229 6 -5 58 -108 116 -227z m-113 173 c7 -14 7 -22 -2 -27
-7 -5 -8 -3 -3 6 5 8 4 11 -3 6 -6 -4 -9 -13 -6 -20 3 -8 1 -17 -5 -21 -12 -7
-15 33 -5 59 8 21 11 20 24 -3z m1828 -29 c2 -12 -1 -22 -7 -22 -6 0 -11 9
-11 21 0 11 -4 17 -10 14 -5 -3 -10 -1 -10 5 0 21 35 5 38 -18z m-1870 -11
c-2 -17 -1 -28 4 -25 11 7 10 -3 -2 -27 -12 -22 -35 -26 -27 -4 4 8 1 17 -5
21 -7 5 -8 2 -3 -6 5 -9 4 -11 -3 -6 -8 5 -5 17 11 41 27 42 30 43 25 6z m355
18 c20 -5 37 -16 37 -23 0 -8 7 -16 15 -20 20 -7 19 -35 0 -42 -14 -6 -14 -8
1 -19 15 -10 15 -13 2 -26 -8 -7 -13 -22 -10 -33 4 -16 1 -18 -19 -14 -17 3
-25 0 -26 -11 -1 -9 -4 -29 -7 -46 -4 -17 -3 -23 1 -13 8 16 11 16 49 -1 46
-21 59 -43 43 -72 -5 -11 -8 -28 -6 -37 2 -11 7 -5 14 18 7 23 12 30 16 20 3
-8 16 -43 28 -78 12 -34 26 -65 30 -68 4 -2 59 -7 120 -9 62 -3 117 -9 122
-14 5 -5 -3 -6 -19 -3 -18 3 -25 1 -20 -6 5 -8 -9 -11 -46 -10 -29 1 -80 -4
-113 -11 l-59 -13 -23 28 c-40 51 -48 80 -28 110 23 36 12 42 -13 7 -25 -35
-62 -130 -54 -137 3 -4 -4 -6 -16 -6 -30 0 -34 13 -21 62 17 64 5 110 -50 181
-47 63 -48 64 -29 87 19 24 26 86 7 75 -6 -4 -8 -9 -5 -12 9 -9 -14 -43 -29
-43 -18 0 -20 16 -4 26 6 3 9 18 6 33 -8 40 36 130 63 131 3 0 22 -5 43 -11z
m-290 -64 c-8 -5 -25 -18 -37 -28 -21 -17 -21 -17 9 -5 17 6 35 15 41 20 6 4
20 -13 36 -45 l26 -53 -32 -54 c-18 -30 -44 -84 -58 -118 l-25 -64 -27 47
c-28 51 -32 84 -16 116 6 11 7 19 1 19 -15 0 -22 49 -10 71 10 19 12 20 29 4
16 -14 18 -14 24 0 3 9 2 13 -3 10 -6 -3 -14 2 -20 12 -11 20 14 78 29 68 6
-4 7 1 3 11 -5 13 -3 15 7 9 9 -5 11 -4 6 3 -4 7 -4 17 0 23 4 8 12 3 20 -12
11 -21 11 -25 -3 -34z m-93 -50 c0 -22 -45 -115 -54 -112 -18 6 -19 37 -1 57
9 10 12 20 7 21 -6 3 -4 20 5 42 14 34 16 36 29 20 7 -10 14 -22 14 -28z m177
-148 c-12 -16 -31 -46 -43 -67 -24 -43 -64 -89 -64 -74 0 6 5 15 12 22 7 7 9
12 5 12 -8 0 26 79 59 136 27 46 27 46 40 23 10 -20 9 -28 -9 -52z m-189 17
c0 -40 -2 -45 -14 -32 -11 11 -12 20 -3 46 6 18 12 32 13 32 2 0 3 -21 4 -46z
m305 -10 c60 -68 77 -104 77 -166 0 -32 -4 -58 -9 -58 -15 0 -19 23 -7 42 14
24 -8 68 -34 68 -16 0 -28 16 -50 66 -26 58 -31 84 -14 84 2 0 19 -16 37 -36z
m-45 -146 c-31 -24 -35 -23 -42 15 -4 17 -15 43 -25 57 -19 24 -19 25 2 54
l21 30 33 -70 33 -69 -22 -17z m-227 23 c20 -36 38 -57 45 -54 10 4 11 -2 1
-27 -11 -29 -10 -34 12 -51 22 -18 23 -22 12 -63 -6 -25 -11 -59 -11 -75 0
-17 -2 -31 -5 -31 -6 0 -95 96 -113 122 -20 27 -18 83 4 167 10 39 19 71 20
71 2 0 17 -27 35 -59z m169 -1 c0 -10 3 -27 6 -37 4 -11 3 -14 -3 -6 -6 8 -14
8 -33 -2 -25 -14 -24 -14 15 -15 39 0 78 18 102 48 17 20 63 8 63 -17 0 -13
-10 -22 -30 -26 -17 -4 -50 -18 -75 -31 -54 -31 -140 -47 -155 -29 -8 9 2 36
32 95 l42 82 18 -22 c10 -12 18 -30 18 -40z m378 -154 l52 2 -1 -242 0 -241
-66 129 c-48 97 -67 146 -74 194 -5 36 -18 86 -30 111 -20 45 -19 62 2 118
l10 28 27 -50 28 -50 52 1z m-248 45 l20 -40 -23 1 c-12 1 -28 6 -36 12 -11 8
-13 8 -7 -1 4 -8 1 -13 -9 -13 -8 0 -19 10 -24 23 -7 18 -3 25 18 39 36 24 38
23 61 -21z m304 -596 c4 -48 91 -206 140 -254 42 -41 43 -44 33 -77 -7 -22
-17 -34 -29 -34 -15 0 -18 8 -18 55 0 66 -16 73 -26 10 -3 -25 -10 -47 -14
-50 -17 -11 -40 28 -40 66 0 56 -17 48 -25 -11 -5 -39 -11 -50 -25 -50 -13 0
-19 12 -25 53 l-7 52 -12 -38 c-6 -22 -13 -37 -16 -35 -3 1 1 -3 9 -9 10 -9
12 -18 5 -34 l-10 -21 -30 25 -30 25 1 -25 c0 -15 -5 -28 -11 -30 -16 -5 -12
76 5 86 7 5 22 6 34 3 17 -4 19 -2 13 19 -4 13 -11 53 -17 89 -5 36 -16 106
-24 156 -8 49 -15 130 -14 180 l0 89 64 -125 c117 -228 67 -111 -85 202 -82
170 -150 312 -149 316 2 18 301 -606 303 -633z m991 625 c-3 -5 -12 -10 -18
-10 -7 0 -6 4 3 10 19 12 23 12 15 0z m-814 -110 c19 0 20 -2 8 -16 -8 -9 -12
-28 -11 -41 3 -22 2 -24 -18 -13 -16 9 -25 9 -33 1 -17 -17 -26 -13 -20 9 3
12 -2 29 -12 40 -18 19 -17 20 8 20 18 0 28 7 33 22 l7 23 8 -23 c5 -13 17
-22 30 -22z m-388 -107 l67 -138 -1 -110 c-1 -66 5 -142 15 -190 8 -44 17
-113 18 -152 2 -40 6 -73 11 -73 4 0 7 -4 7 -8 0 -16 -59 -29 -113 -24 -41 3
-57 1 -57 -8 0 -10 -3 -10 -15 0 -8 7 -31 10 -50 8 -19 -2 -35 0 -35 4 0 4
-11 3 -24 -4 -50 -22 -52 -15 -50 188 3 310 17 480 43 544 13 30 27 65 32 78
7 17 16 22 47 22 l38 0 67 -137z m985 112 c4 -20 4 -20 -11 -1 -14 19 -14 19
-25 0 -9 -15 -11 -16 -12 -3 0 35 42 38 48 4z m223 -15 c11 0 19 -7 19 -17 0
-15 2 -15 9 -4 9 15 41 9 41 -8 0 -5 -6 -16 -14 -23 -7 -8 -17 -24 -20 -36 -4
-12 -11 -20 -16 -17 -5 3 -11 -1 -14 -7 -3 -7 -3 -1 0 15 5 20 2 27 -10 27
-10 0 -13 -6 -10 -15 4 -8 1 -15 -5 -15 -8 0 -6 -10 4 -30 19 -37 19 -37 -5
-43 -15 -4 -20 -14 -20 -41 0 -37 -57 -166 -73 -166 -5 0 -6 7 -3 16 3 9 6
103 6 209 l0 194 46 -19 c25 -11 54 -20 65 -20z m-1181 -12 c18 -42 37 -118
27 -108 -2 3 -21 38 -41 78 -29 57 -33 72 -21 72 9 0 24 -18 35 -42z m977 -8
c18 -10 39 -33 48 -51 20 -37 26 -109 12 -127 -5 -7 -10 -33 -11 -59 -1 -28
-6 -48 -14 -51 -9 -3 -11 6 -6 39 5 30 4 40 -4 35 -7 -4 -12 -20 -12 -37 0
-48 -15 -33 -17 19 -7 135 -6 132 -34 132 l-26 0 -2 -92 c-1 -51 -4 -95 -7
-97 -3 -3 -13 0 -22 6 -12 8 -16 22 -15 54 1 24 0 38 -3 32 -9 -21 -34 -15
-34 8 0 12 -8 23 -20 26 -23 6 -27 23 -5 23 9 0 15 9 15 25 0 43 37 104 73
119 43 19 47 19 84 -4z m-687 -70 c11 0 20 -5 20 -11 0 -6 -6 -9 -14 -6 -11 5
-13 -3 -10 -35 4 -27 2 -38 -6 -33 -6 3 -8 10 -5 15 8 12 -34 3 -51 -11 -11
-9 -13 -4 -9 30 2 23 0 39 -5 36 -5 -3 -11 -1 -15 5 -4 6 4 10 18 10 14 0 28
7 32 18 6 16 7 16 16 0 6 -10 19 -18 29 -18z m147 0 c25 0 26 -1 8 -20 -11
-12 -16 -29 -13 -45 5 -24 4 -25 -16 -12 -18 12 -24 12 -42 0 -20 -13 -21 -12
-16 12 3 16 -2 33 -13 45 -18 19 -17 20 9 20 15 0 29 7 33 16 5 15 7 15 15 0
5 -9 21 -16 35 -16z m-764 -36 c-9 -23 -23 -16 -23 12 0 22 2 24 14 14 8 -6
12 -18 9 -26z m694 -94 c26 0 31 -18 7 -27 -12 -4 -14 -14 -9 -40 6 -33 6 -34
-17 -22 -28 16 -58 19 -55 6 1 -5 -4 -12 -11 -14 -9 -4 -10 2 -2 23 9 23 7 31
-11 51 l-22 23 30 0 c23 0 32 6 37 23 l7 22 11 -22 c7 -14 20 -23 35 -23z
m863 -84 c0 -6 3 -21 6 -33 5 -19 3 -22 -22 -19 -16 2 -35 4 -41 5 -17 1 -16
22 2 48 15 22 55 22 55 -1z m-870 -118 c0 -5 4 -6 10 -3 11 7 51 -12 62 -29 4
-6 -1 -20 -10 -31 -9 -11 -10 -14 -2 -7 16 14 30 16 30 3 0 -5 -13 -14 -29
-21 -16 -6 -31 -20 -34 -31 -5 -20 -57 -28 -57 -9 0 5 -7 7 -15 4 -8 -4 -15
-1 -15 5 0 5 -15 21 -34 33 -28 20 -32 27 -24 42 17 30 118 68 118 44z m510
-22 c0 -18 -57 -69 -68 -62 -14 8 -3 47 21 73 15 17 23 20 34 12 7 -6 13 -17
13 -23z m165 -14 c-7 -7 -45 29 -45 41 0 6 11 0 25 -13 14 -13 23 -26 20 -28z
m-29 7 c28 -30 28 -31 10 -45 -18 -13 -23 -11 -48 12 -21 21 -25 31 -17 45 13
26 20 24 55 -12z m-71 -99 c38 -38 66 -73 62 -77 -8 -7 -147 125 -147 139 0
18 19 4 85 -62z m19 39 c-9 -10 -21 -16 -26 -13 -5 3 -2 14 8 25 9 10 21 16
26 13 5 -3 2 -14 -8 -25z m289 -16 c12 -7 8 -74 -8 -133 -8 -30 -15 -76 -15
-102 0 -40 -3 -48 -22 -54 -13 -4 -33 -17 -45 -30 -53 -57 -59 -5 -7 57 40 48
39 37 17 227 l-6 53 39 -7 c21 -3 42 -9 47 -11z m-381 -75 c-22 -22 -46 -38
-53 -36 -10 3 -1 18 28 47 24 25 47 40 54 36 7 -5 -3 -21 -29 -47z m-537 32
c3 -5 2 -10 -4 -10 -5 0 -13 5 -16 10 -3 6 -2 10 4 10 5 0 13 -4 16 -10z m50
-12 c5 4 5 -1 2 -11 -4 -11 -3 -16 3 -12 5 3 15 -6 22 -20 10 -21 9 -28 -2
-35 -15 -9 -116 -7 -126 3 -10 10 21 58 47 71 13 7 29 9 35 4 6 -4 15 -4 19 0z
m-485 -224 c0 -9 -23 -48 -37 -62 -11 -11 -13 -11 -13 2 0 9 -4 16 -10 16 -14
0 -13 23 2 38 12 12 58 17 58 6z m125 -50 c-8 -59 -14 -74 -32 -74 -11 0 -13
5 -7 18 4 9 9 38 12 64 3 36 7 45 19 41 11 -4 13 -16 8 -49z m849 15 c3 -23
-9 -49 -24 -49 -4 0 -11 12 -14 26 -5 19 -2 30 11 39 21 16 21 16 27 -16z
m-445 7 c9 -11 10 -20 1 -35 -16 -31 -40 -27 -38 7 3 45 15 55 37 28z m787
-41 c-1 -25 -4 -45 -8 -45 -5 0 -8 1 -9 3 -10 52 -9 87 3 87 11 0 15 -12 14
-45z m-806 -31 c96 -34 115 -37 215 -38 103 -1 117 1 218 37 113 40 238 59
251 38 4 -6 3 -11 -3 -11 -5 0 -12 -15 -16 -32 -4 -18 -9 -41 -11 -50 -8 -26
60 -36 86 -13 26 23 25 43 -2 72 -25 26 -13 31 20 7 19 -13 22 -24 22 -85 l0
-71 -30 26 c-36 30 -99 41 -182 32 -32 -3 -57 -2 -54 2 3 5 -13 26 -34 47
l-40 39 -1 -54 c-1 -30 -1 -58 0 -62 2 -19 -135 -48 -224 -48 -81 0 -107 5
-197 36 -156 54 -283 56 -339 7 -15 -12 -17 -10 -23 24 -11 69 -7 92 17 108
27 17 87 10 87 -10 0 -8 3 -21 7 -31 5 -13 -3 -12 -39 10 -25 14 -47 26 -50
26 -3 0 1 -18 8 -41 8 -22 12 -45 9 -50 -4 -5 -1 -9 6 -9 8 0 9 11 5 35 l-7
36 40 -26 c22 -14 42 -25 45 -25 10 0 -19 96 -33 107 -13 10 -1 12 64 8 60 -3
107 -13 185 -41z m711 6 c27 -15 26 -66 -2 -75 -30 -10 -41 7 -34 49 8 40 8
40 36 26z m-1073 -113 c3 -62 12 -73 68 -85 24 -5 39 -14 39 -22 0 -29 -41
-20 -115 25 -41 24 -87 45 -103 45 l-27 0 32 50 c35 52 50 61 83 50 16 -6 21
-18 23 -63z m861 10 c-2 -3 -14 -8 -26 -11 -19 -6 -23 -3 -23 13 0 12 6 21 13
22 10 0 10 2 0 6 -7 2 -13 10 -13 16 0 6 12 -1 27 -15 15 -15 25 -29 22 -31z
m155 -30 c12 -46 5 -62 -11 -24 -8 20 -19 37 -25 37 -5 0 -7 4 -3 10 9 15 32
1 39 -23z m-894 -22 c0 -42 -2 -45 -19 -35 -11 5 -31 10 -45 10 -38 0 -33 34
9 58 42 24 55 16 55 -33z m970 30 c39 -20 41 -55 4 -55 -14 0 -34 -5 -45 -10
-17 -10 -19 -7 -19 35 0 51 10 56 60 30z m-1165 -47 c14 -6 25 -17 25 -25 0
-18 -10 -16 -30 7 -14 16 -19 18 -23 6 -4 -9 2 -16 15 -19 27 -8 17 -33 -13
-29 -46 5 -78 56 -41 65 30 8 40 7 67 -5z m1385 -4 c16 -16 -59 -72 -80 -59
-13 8 -1 35 17 35 10 0 12 6 8 21 -7 20 -5 21 21 15 16 -4 31 -10 34 -12z
m-590 -4 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4 4 -10z
m520 4 c0 -7 -27 -34 -34 -34 -12 0 -6 28 7 33 17 8 27 8 27 1z m-612 -34 c7
-11 11 -20 9 -20 -2 0 -12 9 -22 20 -10 11 -14 20 -9 20 5 0 15 -9 22 -20z
m-128 -4 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10 25 14 25 6z"/>
<path d="M1472 2846 c-18 -21 -19 -27 -7 -43 15 -20 25 -15 28 15 0 11 4 19 8
16 14 -8 19 14 6 25 -10 8 -19 5 -35 -13z"/>
<path d="M1427 2813 c-3 -5 4 -15 16 -23 21 -12 21 -12 7 6 -8 10 -15 20 -16
22 -1 2 -4 -1 -7 -5z"/>
<path d="M1414 2778 c-4 -7 -3 -8 5 -4 6 4 14 0 17 -8 3 -9 10 -16 15 -16 15
0 10 15 -11 27 -14 9 -21 10 -26 1z"/>
<path d="M1073 2745 c0 -8 4 -15 9 -15 4 0 8 4 8 9 0 6 -4 12 -8 15 -5 3 -9
-1 -9 -9z"/>
<path d="M1065 2710 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1076 2423 c-10 -10 -7 -21 8 -26 8 -3 17 0 20 8 5 14 -17 28 -28 18z"/>
<path d="M1169 2352 c9 -7 10 -11 2 -15 -7 -4 -8 -5 0 -3 15 2 13 20 -3 24
-10 3 -10 2 1 -6z"/>
<path d="M1080 2346 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M1450 1765 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M2691 2264 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2680 2229 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M2433 2050 c0 -25 2 -35 4 -22 2 12 2 32 0 45 -2 12 -4 2 -4 -23z"/>
<path d="M1829 1813 c-13 -16 -12 -17 4 -4 9 7 17 15 17 17 0 8 -8 3 -21 -13z"/>
<path d="M1889 1818 c-6 -21 -5 -38 2 -38 5 0 9 2 9 4 0 2 3 11 6 20 3 9 1 16
-5 16 -6 0 -11 -1 -12 -2z"/>
<path d="M1896 1707 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M2565 1420 c-3 -5 -3 -21 1 -35 8 -31 6 -31 -36 0 -19 14 -36 25 -40
25 -3 0 -4 -20 -2 -45 2 -25 8 -45 13 -45 5 0 6 15 2 32 l-6 33 24 -18 c61
-43 60 -43 58 12 -3 53 -4 57 -14 41z"/>
<path d="M1768 1370 c-3 -50 -3 -50 27 -49 17 1 22 3 13 6 -10 2 -18 14 -18
25 0 14 5 18 15 14 8 -3 15 -1 15 4 0 6 -7 10 -15 10 -21 0 -19 28 3 33 11 3
7 5 -10 6 -24 1 -26 -2 -30 -49z"/>
<path d="M1859 1358 l3 -53 21 28 21 28 11 -37 10 -37 28 39 c15 22 27 44 27
50 0 5 -10 -7 -23 -26 l-22 -35 -3 39 -3 38 -31 -28 -30 -29 4 38 c2 22 0 37
-7 37 -6 0 -9 -20 -6 -52z"/>
<path d="M2293 1353 c-8 -3 -7 -11 3 -31 8 -15 14 -35 14 -44 0 -10 7 -18 15
-18 18 0 19 -4 -3 51 -9 25 -22 44 -29 42z"/>
<path d="M2063 1343 c25 -6 21 -18 -18 -51 l-35 -29 37 -11 c24 -7 41 -8 47
-2 7 7 -2 10 -24 10 l-35 1 39 34 c22 19 37 37 34 40 -2 3 -18 6 -34 9 -16 2
-21 1 -11 -1z"/>
<path d="M2125 1290 c0 -40 3 -50 17 -50 11 0 15 6 11 19 -3 13 1 21 14 24 16
4 16 5 1 6 -12 1 -18 9 -18 26 0 14 -6 25 -12 25 -9 0 -13 -17 -13 -50z"/>
<path d="M2219 1298 c-6 -24 -13 -46 -16 -50 -2 -5 1 -8 6 -8 6 0 11 7 11 15
0 8 4 15 9 15 5 0 7 10 5 23 -2 12 -1 15 2 7 11 -26 37 -50 53 -50 10 0 12 3
5 8 -5 4 -20 24 -33 45 -12 20 -24 37 -26 37 -3 0 -9 -19 -16 -42z"/>
<path d="M1990 1320 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2355 1280 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2175 1250 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M1540 1289 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3141 2977 c-8 -10 -7 -14 4 -14 8 0 12 4 9 8 -2 4 2 10 8 12 8 4 9
6 2 6 -6 1 -17 -5 -23 -12z"/>
<path d="M1910 2906 c0 -14 -4 -26 -10 -26 -5 0 -10 7 -10 15 0 9 -9 15 -21
15 -18 0 -20 -4 -15 -25 7 -27 -3 -32 -22 -13 -17 17 -28 -18 -12 -37 10 -13
8 -15 -14 -15 -29 0 -37 -26 -11 -36 20 -8 19 -24 -1 -24 -9 0 -14 -7 -12 -17
2 -11 11 -17 21 -15 21 4 23 -14 2 -22 -22 -8 -18 -36 5 -36 13 0 20 -7 20
-19 0 -11 6 -26 13 -33 14 -15 39 -77 40 -98 0 -8 -3 -22 -7 -30 -4 -11 2 -19
22 -26 23 -9 44 -7 98 7 94 24 265 24 359 0 65 -17 90 -16 123 5 2 1 -1 12 -7
23 -9 16 -8 28 4 51 9 17 13 35 10 40 -4 6 0 13 9 16 8 3 18 19 21 35 5 20 13
29 26 29 22 0 26 28 4 36 -21 8 -19 26 2 22 10 -2 19 4 21 15 2 12 -3 17 -17
17 -27 0 -27 16 -1 23 11 3 20 13 20 22 0 13 -6 16 -25 13 -27 -6 -32 1 -14
19 7 7 9 19 5 29 -5 13 -9 15 -18 6 -19 -19 -29 -14 -22 13 5 21 3 25 -15 25
-12 0 -21 -6 -21 -15 0 -8 -4 -15 -10 -15 -5 0 -10 12 -10 26 0 19 -4 25 -17
22 -10 -2 -17 -11 -17 -21 2 -24 -23 -21 -29 4 -6 24 -32 21 -32 -4 0 -8 -4
-19 -8 -23 -4 -4 -7 0 -7 9 0 10 -4 17 -10 17 -5 0 -13 0 -17 0 -5 0 -8 -10
-8 -22 -1 -28 -21 -35 -28 -8 -3 14 -10 18 -21 14 -11 -4 -14 -13 -11 -25 4
-11 2 -19 -4 -19 -10 0 -16 29 -12 53 0 4 -19 7 -44 7 -43 0 -45 -1 -45 -30 0
-16 -5 -30 -11 -30 -6 0 -9 6 -6 14 3 8 -1 20 -9 27 -12 10 -16 8 -21 -10 -7
-27 -27 -22 -28 7 0 12 -3 22 -7 22 -5 0 -12 0 -18 0 -5 0 -10 -7 -10 -16 0
-8 -5 -12 -10 -9 -6 4 -8 10 -5 15 3 4 -2 13 -11 21 -13 10 -16 9 -21 -9 -6
-26 -31 -30 -29 -5 0 10 -7 19 -16 21 -14 3 -18 -3 -18 -22z m280 -92 c0 -56
-2 -65 -17 -62 -14 2 -17 14 -16 56 1 72 1 72 18 72 12 0 15 -14 15 -66z
m-128 34 l69 -23 -3 -40 c-1 -25 5 -50 16 -68 22 -33 15 -44 -19 -29 -24 11
-25 10 -25 -23 0 -19 3 -35 6 -35 13 0 50 24 58 38 8 14 10 14 20 0 10 -14 48
-38 60 -38 3 0 6 16 6 35 0 33 -1 34 -25 23 -33 -15 -42 -5 -21 26 9 14 16 45
16 68 l0 43 74 24 c122 39 194 20 216 -58 10 -36 -3 -111 -19 -111 -4 0 -6
-13 -3 -30 5 -33 -11 -41 -26 -13 -8 14 -12 12 -26 -16 -13 -24 -14 -32 -4
-30 7 1 22 1 33 0 19 -2 19 -3 -4 -21 -26 -22 -71 -26 -89 -8 -17 17 -15 68 3
68 10 0 12 -6 8 -17 -7 -17 -5 -17 17 -2 33 22 34 63 2 67 -17 3 -21 8 -17 23
8 23 -2 59 -16 59 -6 0 -16 -15 -24 -34 -12 -28 -18 -33 -39 -29 -33 7 -44
-18 -26 -55 11 -22 14 -24 17 -9 7 28 24 19 20 -10 -8 -63 -97 -65 -119 -3 -5
17 -16 30 -23 30 -7 0 -18 -13 -23 -30 -15 -41 -51 -57 -88 -38 -16 8 -28 20
-27 26 2 7 -1 12 -7 12 -5 0 -7 7 -4 15 8 20 22 19 26 -2 3 -16 6 -15 16 5 19
34 6 65 -24 59 -21 -4 -27 1 -39 29 -8 19 -19 34 -24 34 -13 0 -25 -45 -18
-64 4 -10 -2 -16 -16 -18 -28 -4 -34 -34 -11 -59 20 -23 39 -25 30 -4 -3 9 0
15 8 15 16 0 19 -49 4 -67 -16 -19 -63 -16 -89 7 -23 18 -23 19 -4 21 11 1 26
1 33 0 10 -2 9 6 -4 30 -14 28 -18 30 -26 16 -13 -25 -28 -21 -28 7 0 13 -7
37 -15 52 -20 40 -19 83 5 122 35 57 99 66 212 30z m152 -309 c-14 -23 -41
-28 -64 -12 l-23 16 24 17 c21 16 25 16 46 3 12 -8 20 -19 17 -24z m-149 -10
c0 -20 -6 -25 -32 -27 -29 -3 -33 0 -33 22 0 18 6 26 23 29 34 6 42 2 42 -24z
m284 15 c2 -2 1 -14 -3 -28 -5 -22 -9 -24 -37 -16 -40 12 -39 11 -33 37 5 19
11 21 38 16 17 -3 33 -7 35 -9z m-429 -35 c0 -5 5 -7 10 -4 6 3 10 1 10 -4 0
-6 -5 -11 -11 -11 -5 0 -7 -4 -4 -10 7 -11 -6 -13 -29 -4 -12 5 -13 10 -5 25
10 20 29 26 29 8z m535 -15 c0 -10 -8 -20 -19 -22 -10 -2 -16 0 -12 6 3 6 0
13 -6 15 -10 4 -9 8 2 16 20 14 35 8 35 -15z"/>
<path d="M3083 2874 c-12 -19 -18 -34 -13 -34 5 0 12 5 15 10 5 8 11 7 21 -1
13 -10 11 -16 -11 -39 -24 -25 -25 -29 -10 -44 23 -22 32 -20 11 2 -15 18 -15
20 5 38 29 26 41 61 29 82 -5 9 -10 13 -10 7 0 -6 -3 -5 -8 1 -4 7 -15 -1 -29
-22z m41 -6 c-3 -4 -9 -8 -15 -8 -5 0 -9 4 -9 8 0 5 7 9 15 9 8 0 12 -4 9 -9z"/>
<path d="M3056 2803 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2828 2783 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2860 2776 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M3046 2771 c-4 -5 -2 -12 3 -15 5 -4 12 -2 15 3 4 5 2 12 -3 15 -5 4
-12 2 -15 -3z"/>
<path d="M2996 2762 c-2 -4 -1 -14 5 -22 7 -12 9 -10 9 8 0 23 -5 28 -14 14z"/>
<path d="M3008 2729 c-9 -10 -14 -20 -10 -23 3 -3 0 -6 -7 -6 -9 0 -9 -3 1
-14 14 -14 38 -11 38 6 0 5 -6 6 -12 2 -9 -6 -9 -3 0 9 20 24 12 47 -10 26z"/>
<path d="M3046 2677 c-9 -7 -26 -32 -36 -55 l-19 -42 24 16 c14 9 22 21 18 27
-4 6 9 2 28 -10 18 -12 38 -34 43 -50 5 -15 13 -37 17 -48 5 -11 8 -131 6
-267 -1 -135 1 -244 4 -242 7 4 19 -72 19 -122 0 -18 -5 -36 -11 -40 -6 -3 -9
-15 -6 -25 3 -10 0 -20 -6 -22 -6 -2 -4 -6 5 -10 23 -8 33 61 23 156 -4 40 -9
183 -10 319 -2 160 -8 254 -15 268 -6 12 -11 33 -10 48 3 40 1 56 -6 49 -4 -3
-13 0 -21 8 -8 8 -26 15 -41 15 l-27 0 25 20 c28 22 24 28 -4 7z"/>
<path d="M3031 2444 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2416 2413 c-6 -14 -5 -15 5 -6 7 7 10 15 7 18 -3 3 -9 -2 -12 -12z"/>
<path d="M2830 2420 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
-4 -4 -4 -10z"/>
<path d="M2941 2354 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2145 2329 c-18 -9 -18 -9 3 -5 18 3 22 1 18 -11 -3 -8 -6 -25 -6
-38 0 -16 -7 -25 -25 -29 -35 -9 -31 -26 5 -22 28 3 30 1 30 -31 0 -30 -3 -33
-24 -30 -13 1 -30 8 -38 14 -18 15 -58 13 -58 -3 0 -7 7 -14 15 -18 8 -3 12
-12 9 -21 -4 -11 0 -15 17 -15 12 0 31 -7 41 -17 13 -11 18 -12 18 -3 0 10 3
10 15 0 12 -10 15 -10 15 0 0 10 3 10 15 0 12 -10 15 -9 15 6 0 17 1 17 15 -2
12 -16 14 -17 15 -2 0 9 14 30 31 47 17 17 29 31 27 32 -34 8 -53 7 -63 -1 -7
-6 -22 -13 -34 -15 -18 -3 -21 1 -21 30 0 26 7 38 30 55 34 24 42 47 11 30
-28 -15 -41 -12 -39 8 4 51 -2 58 -37 41z"/>
<path d="M2250 2229 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3011 2214 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2941 2194 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2830 2173 c0 -18 5 -33 10 -33 13 0 13 30 0 50 -7 11 -10 7 -10 -17z"/>
<path d="M3047 2115 c3 -22 7 -42 9 -44 8 -9 2 45 -6 64 -7 14 -8 8 -3 -20z"/>
<path d="M2911 2114 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3021 2084 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2881 2064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3050 2030 c0 -6 4 -9 9 -6 5 4 12 1 14 -4 3 -6 4 -5 3 2 -3 15 -26
23 -26 8z"/>
<path d="M2145 2020 c-18 -8 -17 -9 8 -9 15 -1 27 4 27 9 0 12 -8 12 -35 0z"/>
<path d="M2921 2023 c-1 -6 -4 -20 -7 -30 -5 -17 -5 -17 6 0 6 10 9 23 6 30
-3 9 -5 9 -5 0z"/>
<path d="M2160 1972 c0 -22 -8 -32 -32 -45 l-33 -17 38 3 c36 3 37 2 37 -30 0
-28 -3 -33 -24 -33 -14 0 -28 5 -31 10 -3 6 -23 10 -43 10 l-37 0 23 -18 c16
-13 21 -23 16 -37 -6 -15 -4 -17 11 -12 10 5 28 1 43 -9 13 -9 21 -11 18 -5
-10 15 7 14 22 -1 9 -9 12 -9 12 1 0 11 3 11 15 1 12 -10 15 -10 15 0 0 10 3
10 15 0 12 -10 15 -10 15 3 0 9 14 29 31 46 l31 31 -36 0 c-20 0 -36 -4 -36
-10 0 -5 -11 -10 -25 -10 -21 0 -25 5 -25 32 0 23 9 39 33 60 l32 29 -33 -7
c-31 -5 -33 -4 -27 15 5 15 2 21 -9 21 -11 0 -16 -9 -16 -28z"/>
<path d="M3012 1970 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M2871 1954 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3051 1923 c-1 -18 4 -33 9 -33 12 0 12 17 0 45 -7 17 -9 15 -9 -12z"/>
<path d="M2050 1920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2250 1920 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2901 1914 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2910 1836 c0 -9 5 -16 10 -16 6 0 10 4 10 9 0 6 -4 13 -10 16 -5 3
-10 -1 -10 -9z"/>
<path d="M3072 1777 c-12 -7 -19 -16 -16 -20 8 -7 44 -10 44 -3 0 2 3 11 6 20
7 19 -3 20 -34 3z"/>
<path d="M2911 1754 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M3109 1747 c-10 -22 -15 -24 -37 -16 -13 5 -26 8 -27 7 -1 -2 -8 -13
-14 -26 -9 -16 -18 -21 -34 -17 -18 5 -26 0 -37 -20 -10 -18 -21 -25 -36 -23
-25 3 -80 -47 -63 -58 6 -3 17 3 25 15 9 13 26 21 43 21 20 0 31 7 39 25 9 20
17 24 35 20 17 -5 26 0 36 18 10 18 19 22 41 19 21 -3 31 1 40 17 6 12 16 19
22 15 6 -4 9 -1 6 6 -8 24 -27 22 -39 -3z"/>
<path d="M3001 1741 c-27 -15 -30 -19 -16 -24 23 -9 21 -10 39 18 9 14 15 25
14 25 -2 -1 -18 -9 -37 -19z"/>
<path d="M3126 1714 c-4 -9 -4 -19 -2 -21 8 -8 26 8 26 23 0 19 -16 18 -24 -2z"/>
<path d="M3169 1693 c-13 -21 -25 -41 -27 -45 -2 -4 -17 -22 -33 -39 -20 -21
-27 -25 -23 -13 4 13 1 12 -8 -4 -8 -12 -17 -19 -21 -16 -4 4 -7 0 -7 -9 0
-23 28 -47 53 -47 20 1 20 2 -5 16 -16 8 -28 17 -28 20 0 2 9 0 19 -6 26 -13
52 -14 31 0 -11 7 -11 10 -2 10 7 0 10 5 7 10 -3 6 -1 10 5 10 7 0 10 7 6 15
-3 8 -1 15 4 15 6 0 9 3 8 8 -1 4 10 31 25 60 32 61 29 69 -4 15z"/>
<path d="M2154 1705 c-10 -8 -14 -14 -9 -15 15 0 40 18 34 24 -3 3 -14 -1 -25
-9z"/>
<path d="M2833 1693 c9 -51 18 -65 33 -52 18 15 18 30 0 23 -11 -4 -13 1 -10
25 4 24 1 31 -11 31 -12 0 -15 -7 -12 -27z"/>
<path d="M2928 1699 c-22 -12 -23 -29 -3 -29 8 0 19 9 25 20 12 22 4 25 -22 9z"/>
<path d="M3045 1674 c-18 -28 -14 -29 25 -9 35 18 36 21 10 28 -13 3 -24 -3
-35 -19z"/>
<path d="M2160 1660 c0 -22 -7 -33 -27 -44 l-28 -15 33 -1 c29 0 32 -3 32 -30
0 -27 -3 -30 -30 -30 -16 0 -30 5 -30 10 0 6 -17 10 -37 10 l-38 -1 23 -18
c12 -11 20 -26 16 -34 -4 -10 2 -14 21 -14 14 0 32 -9 40 -19 14 -19 14 -18
15 0 0 20 0 20 15 0 14 -19 14 -19 15 1 1 20 1 20 15 1 15 -20 15 -20 15 0 1
19 1 19 15 0 15 -19 15 -19 15 1 0 11 14 35 32 52 l32 31 -37 0 c-20 0 -37 -4
-37 -10 0 -5 -11 -10 -25 -10 -39 0 -35 49 7 83 33 28 31 50 -2 29 -21 -13
-33 -2 -24 22 4 11 1 16 -10 16 -11 0 -16 -9 -16 -30z"/>
<path d="M2974 1635 c-11 -16 -26 -25 -43 -25 -16 0 -31 -8 -39 -21 -7 -11
-19 -19 -25 -16 -17 6 -21 -127 -6 -162 11 -24 98 -101 114 -101 4 0 -7 18
-24 39 -17 21 -31 42 -31 45 0 4 20 -5 43 -19 60 -34 67 -31 26 11 l-34 36 40
-17 c68 -29 71 -28 33 4 -21 18 -38 36 -38 41 0 4 24 0 53 -11 47 -17 49 -17
27 -1 -13 9 -36 27 -50 40 l-25 23 25 -14 c14 -8 36 -18 50 -22 23 -6 22 -4
-10 24 l-35 31 33 -16 c37 -18 56 -12 22 6 -12 6 -28 26 -35 43 -14 31 -14 31
-15 7 -1 -23 -2 -23 -10 -5 -7 17 -9 14 -9 -13 -1 -36 -16 -36 -24 0 -2 13 -8
31 -12 40 -5 12 1 25 19 41 21 20 24 26 11 31 -9 3 -21 -4 -31 -19z m-17 -65
c-4 -16 0 -47 8 -69 12 -35 12 -41 -2 -52 -31 -26 -43 -19 -45 24 -3 72 2 102
19 115 24 17 26 16 20 -18z m-58 -63 c-1 -34 1 -74 5 -87 5 -18 4 -21 -2 -10
-14 22 -35 129 -28 146 3 8 10 14 16 14 7 0 10 -24 9 -63z"/>
<path d="M2055 1610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2255 1610 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
-8 -4 -11 -10z"/>
<path d="M2809 1498 c-1 -32 -2 -64 -3 -70 -4 -24 19 -63 61 -108 23 -24 44
-42 47 -39 3 2 -10 29 -29 59 -18 30 -31 59 -28 64 3 5 1 6 -4 3 -7 -4 -13 15
-18 51 -11 89 -23 108 -26 40z"/>
<path d="M2780 1289 c0 -5 5 -7 10 -4 6 3 10 8 10 11 0 2 -4 4 -10 4 -5 0 -10
-5 -10 -11z"/>
<path d="M3958 2878 c-31 -11 -36 -29 -13 -48 24 -20 65 3 65 35 0 27 -9 29
-52 13z"/>
<path d="M3970 2698 c1 -34 16 -38 50 -13 l34 25 -42 0 c-27 0 -42 -4 -42 -12z"/>
<path d="M270 2649 c-14 -25 -12 -27 28 -38 25 -8 31 -6 35 10 6 21 -16 49
-38 49 -8 0 -19 -9 -25 -21z"/>
<path d="M4030 1900 c0 -28 2 -30 28 -24 43 10 45 19 7 37 l-35 17 0 -30z"/>
<path d="M337 1700 c-43 -13 -57 -29 -57 -66 0 -40 5 -41 90 -17 66 18 76 37
40 73 -22 22 -30 23 -73 10z"/>
<path d="M3930 1511 c0 -5 -3 -16 -6 -25 -5 -13 1 -16 32 -16 l37 0 -23 25
c-24 26 -40 32 -40 16z"/>
<path d="M482 1250 l-23 -29 38 6 c47 7 47 7 33 32 -14 27 -20 26 -48 -9z"/>
<path d="M3705 1080 c-22 -24 -21 -25 28 -38 l37 -10 -22 31 c-12 18 -22 33
-23 35 -1 1 -10 -7 -20 -18z"/>
<path d="M706 941 c-10 -18 -16 -34 -14 -36 2 -1 18 3 36 9 l34 12 -20 24 -19
24 -17 -33z"/>
<path d="M3594 906 c-10 -27 1 -51 25 -54 38 -6 48 14 21 43 -27 29 -38 31
-46 11z"/>
<path d="M3057 506 c-17 -13 -16 -15 13 -36 29 -21 30 -21 30 -1 0 24 -10 51
-19 51 -3 0 -14 -7 -24 -14z"/>
<path d="M2880 395 c-20 -24 -3 -55 31 -55 16 0 29 2 29 4 0 1 -5 17 -11 35
-12 33 -29 40 -49 16z"/>
<path d="M7983 3593 c-35 -7 -28 -31 10 -35 l32 -3 3 -241 2 -242 -25 -6 c-52
-13 -23 -26 60 -26 83 0 112 13 60 26 l-25 6 0 264 0 264 -47 -1 c-27 -1 -58
-4 -70 -6z"/>
<path d="M9288 3593 c-30 -8 -21 -33 11 -33 25 0 29 -4 35 -37 3 -21 6 -55 6
-77 0 -35 -2 -38 -22 -31 -13 4 -48 10 -78 12 -45 4 -61 0 -90 -19 -79 -52
-109 -193 -61 -291 41 -85 131 -112 205 -62 46 31 46 31 46 -1 0 -25 2 -26 38
-20 20 3 49 6 65 6 17 0 27 5 27 14 0 9 -12 16 -27 18 l-28 3 -6 250 c-3 138
-7 256 -8 263 -1 12 -74 15 -113 5z m1 -218 c16 -8 34 -28 40 -46 12 -36 15
-198 3 -215 -23 -35 -102 -45 -140 -18 -36 25 -52 68 -52 139 0 132 59 187
149 140z"/>
<path d="M5030 3579 c0 -6 16 -25 35 -44 l35 -33 -2 -213 -3 -213 -24 -6 c-14
-3 -26 -11 -29 -18 -3 -9 18 -12 78 -12 60 0 81 3 78 12 -3 7 -15 15 -29 18
l-24 6 -3 178 c-1 110 1 177 7 174 5 -1 80 -92 166 -200 86 -109 163 -198 171
-198 12 0 14 40 14 256 l0 255 30 10 c17 6 30 17 30 25 0 11 -18 14 -80 14
-64 0 -80 -3 -80 -15 0 -8 11 -17 25 -21 l25 -6 0 -185 0 -185 -160 206 -161
206 -49 0 c-29 0 -50 -5 -50 -11z"/>
<path d="M6760 3514 l0 -75 27 3 c24 3 28 9 38 53 l10 50 108 3 c59 1 107 0
107 -2 0 -3 -70 -112 -155 -242 -85 -131 -155 -244 -155 -251 0 -11 39 -13
203 -11 l202 3 3 68 c3 66 2 67 -22 67 -20 0 -27 -7 -35 -37 -6 -21 -11 -44
-11 -50 0 -10 -31 -13 -121 -13 -93 0 -119 3 -113 13 4 6 74 114 156 239 81
125 148 235 148 243 0 13 -28 15 -195 15 l-195 0 0 -76z"/>
<path d="M5715 3412 c-72 -46 -96 -95 -96 -190 0 -84 29 -138 91 -169 73 -37
168 -26 213 25 28 31 12 45 -26 22 -44 -28 -116 -27 -152 2 -30 24 -55 74 -55
112 l0 25 116 3 c94 2 119 6 127 19 16 26 -2 88 -37 127 -30 32 -39 36 -92 39
-46 3 -66 0 -89 -15z m100 -26 c33 -14 65 -59 65 -91 0 -12 -16 -15 -95 -15
-59 0 -95 4 -95 10 0 24 30 79 51 94 28 20 31 20 74 2z"/>
<path d="M5962 3413 c2 -9 14 -20 27 -23 21 -5 33 -28 87 -174 34 -93 67 -173
72 -178 25 -25 45 -1 84 101 45 114 45 114 79 11 12 -36 28 -77 36 -92 15 -31
47 -37 58 -10 4 9 31 84 62 167 59 162 66 175 94 175 12 0 19 7 19 20 0 19 -5
21 -72 18 -76 -3 -100 -20 -53 -38 19 -7 25 -15 21 -28 -44 -139 -85 -244 -90
-232 -12 31 -86 244 -86 247 0 2 12 8 26 13 49 19 22 35 -64 38 -69 2 -82 0
-82 -13 0 -8 11 -20 24 -24 14 -6 30 -26 39 -49 16 -39 15 -42 -12 -113 -15
-41 -31 -83 -35 -94 -7 -18 -8 -18 -17 -3 -5 9 -25 61 -43 115 -19 54 -38 106
-41 115 -5 13 0 20 19 28 14 5 26 16 26 25 0 12 -16 15 -91 15 -80 0 -90 -2
-87 -17z"/>
<path d="M7305 3416 c-111 -50 -143 -235 -56 -330 65 -71 195 -75 254 -8 27
30 12 47 -20 22 -39 -30 -122 -28 -157 5 -29 27 -56 82 -56 115 0 19 6 20 120
20 106 0 120 2 129 19 16 29 -7 91 -49 133 -33 34 -43 38 -86 38 -27 -1 -62
-7 -79 -14z m128 -57 c15 -16 27 -40 27 -54 0 -25 0 -25 -96 -25 -106 0 -105
-1 -68 72 29 56 88 59 137 7z"/>
<path d="M7643 3419 c-31 -9 -33 -12 -33 -54 0 -43 1 -45 29 -45 25 0 30 5 38
38 7 32 13 37 41 40 50 5 82 -33 82 -98 0 -48 -1 -50 -28 -50 -47 0 -133 -30
-158 -55 -18 -18 -24 -35 -24 -69 0 -37 5 -48 31 -70 40 -34 101 -35 148 -3
l34 23 23 -23 c23 -23 57 -29 88 -17 25 10 19 34 -8 34 -13 0 -27 7 -30 16 -3
9 -6 67 -6 130 0 176 -25 215 -135 213 -33 0 -75 -5 -92 -10z m136 -322 c-15
-12 -35 -17 -63 -15 -34 2 -43 8 -54 31 -21 44 -8 74 36 87 20 5 51 11 67 12
29 3 30 2 33 -47 3 -42 0 -53 -19 -68z"/>
<path d="M8273 3419 c-31 -9 -33 -12 -33 -54 0 -43 1 -45 29 -45 25 0 30 5 38
38 9 35 11 37 47 36 51 -2 68 -22 74 -88 l4 -56 -29 0 c-46 0 -130 -28 -159
-53 -21 -19 -27 -32 -27 -67 0 -37 5 -48 33 -73 29 -26 38 -29 82 -25 32 3 57
13 72 27 l24 22 25 -25 c25 -25 59 -32 91 -20 26 10 18 32 -11 36 l-28 3 -5
140 c-5 145 -12 171 -52 199 -25 18 -122 20 -175 5z m157 -259 c0 -41 -4 -52
-24 -65 -37 -25 -97 -16 -115 16 -32 57 7 97 97 98 l42 1 0 -50z"/>
<path d="M8623 3423 c-44 -6 -42 -29 2 -37 l35 -7 0 -153 0 -154 -25 -6 c-52
-13 -23 -26 60 -26 83 0 111 11 60 24 l-25 6 0 141 0 140 35 15 c53 22 90 18
114 -12 19 -24 21 -40 21 -155 l0 -129 -25 -6 c-52 -13 -22 -24 65 -24 94 0
111 8 60 27 l-30 11 0 134 c0 143 -11 182 -61 208 -31 17 -111 7 -146 -18 -32
-23 -43 -20 -43 10 0 19 -18 21 -97 11z"/>
<path d="M7050 2881 c-17 -32 4 -71 38 -71 33 0 52 16 52 45 0 12 -5 26 -12
33 -19 19 -66 15 -78 -7z"/>
<path d="M9920 2881 c-17 -32 4 -71 38 -71 33 0 52 16 52 45 0 12 -5 26 -12
33 -19 19 -66 15 -78 -7z"/>
<path d="M9423 2873 c-18 -2 -33 -10 -33 -17 0 -6 15 -16 33 -21 l32 -10 3
-238 2 -237 -30 -7 c-59 -11 -25 -28 63 -31 70 -2 87 0 87 12 0 7 -11 17 -25
20 l-25 6 -2 263 -3 262 -35 1 c-19 1 -50 -1 -67 -3z"/>
<path d="M9653 2873 c-44 -6 -42 -29 2 -37 l35 -7 0 -239 0 -240 -25 -6 c-14
-3 -25 -12 -25 -20 0 -11 19 -14 85 -14 66 0 85 3 85 14 0 8 -11 17 -25 20
l-25 6 0 265 0 265 -37 -2 c-21 0 -53 -3 -70 -5z"/>
<path d="M5141 2849 c-55 -28 -81 -70 -81 -131 0 -65 32 -100 135 -147 102
-47 125 -71 125 -127 0 -63 -29 -94 -88 -94 -70 0 -116 27 -122 70 -4 29 -10
36 -32 38 -28 3 -28 3 -28 -53 0 -55 1 -56 38 -72 20 -9 66 -18 102 -20 53 -4
73 0 109 19 65 34 86 67 86 138 0 51 -4 63 -30 91 -16 17 -66 48 -110 69 -95
44 -119 68 -118 116 2 70 62 101 141 73 26 -9 31 -17 39 -57 7 -41 11 -47 36
-50 l27 -3 0 65 c0 79 -10 87 -115 93 -58 4 -78 0 -114 -18z"/>
<path d="M8052 2853 c2 -9 14 -20 28 -23 l25 -6 3 -233 2 -233 -30 -11 c-16
-6 -30 -17 -30 -24 0 -10 24 -13 95 -13 77 0 95 3 95 15 0 8 -11 17 -25 21
l-25 6 0 234 0 234 25 10 c14 5 25 16 25 25 0 12 -17 15 -96 15 -85 0 -95 -2
-92 -17z"/>
<path d="M7308 2818 c-26 -9 -28 -14 -28 -64 0 -53 0 -54 -30 -54 -23 0 -30
-4 -30 -20 0 -16 7 -20 30 -20 l30 0 0 -149 c0 -135 2 -151 21 -175 16 -20 29
-26 60 -26 46 0 79 14 79 35 0 10 -9 12 -36 8 -25 -4 -39 -2 -45 8 -5 8 -9 78
-9 157 l0 142 45 0 c38 0 45 3 45 20 0 17 -7 20 -45 20 l-45 0 0 65 c0 36 -3
65 -7 64 -5 0 -20 -5 -35 -11z"/>
<path d="M8843 2818 c-32 -8 -33 -10 -33 -63 0 -54 0 -55 -30 -55 -23 0 -30
-4 -30 -20 0 -16 7 -20 30 -20 l30 0 0 -140 c0 -185 12 -210 98 -210 38 1 81
24 68 37 -4 4 -22 6 -41 4 -49 -4 -55 16 -55 173 l0 136 45 0 c38 0 45 3 45
20 0 17 -7 20 -45 20 l-44 0 -3 63 -3 63 -32 -8z"/>
<path d="M10415 2738 c-11 -6 -27 -21 -36 -32 -15 -18 -20 -19 -43 -8 -66 30
-162 5 -196 -50 -26 -44 -25 -87 4 -130 22 -33 22 -35 5 -46 -35 -22 -51 -57
-39 -87 6 -16 18 -33 26 -40 13 -9 13 -13 -5 -29 -43 -39 -19 -112 45 -136 44
-17 155 -11 201 10 70 31 103 107 68 157 -20 28 -61 40 -170 47 -99 7 -124 20
-106 54 9 17 23 20 91 23 73 4 82 7 110 35 38 37 48 90 27 134 -16 30 -15 31
9 40 14 6 34 10 45 10 14 0 19 7 19 30 0 33 -19 39 -55 18z m-98 -90 c17 -16
23 -32 23 -62 0 -47 -6 -58 -38 -75 -28 -15 -77 -1 -98 26 -18 24 -17 84 2
111 21 30 79 30 111 0z m48 -327 c27 -7 30 -11 30 -46 0 -50 -26 -65 -116 -65
-82 0 -119 23 -119 74 0 17 4 36 10 42 10 10 146 7 195 -5z"/>
<path d="M5542 2689 c-136 -67 -131 -312 8 -369 50 -21 142 -9 185 24 51 40
32 56 -30 26 -91 -44 -165 2 -190 118 l-7 32 119 0 c152 0 167 13 122 106 -38
80 -121 105 -207 63z m132 -50 c14 -17 26 -41 26 -55 0 -20 -5 -24 -29 -24
-15 0 -56 -3 -91 -7 l-63 -6 6 36 c10 62 33 87 82 87 36 0 48 -5 69 -31z"/>
<path d="M5921 2692 c-79 -40 -116 -128 -99 -237 9 -56 30 -90 78 -122 59 -41
183 -23 211 29 16 30 4 32 -47 7 -44 -21 -50 -21 -88 -8 -33 11 -46 23 -63 59
-41 79 -25 211 29 244 11 6 30 7 55 0 31 -7 40 -15 48 -41 7 -27 14 -33 37
-33 27 0 28 2 28 49 0 46 -2 50 -31 60 -47 16 -119 13 -158 -7z"/>
<path d="M6188 2703 c-58 -5 -66 -29 -13 -39 l35 -6 0 -119 c0 -137 15 -191
61 -214 43 -23 101 -19 143 10 20 14 39 25 41 25 3 0 5 -11 5 -25 0 -28 7 -29
84 -19 52 8 66 34 17 34 -26 0 -29 3 -35 46 -3 26 -6 107 -6 181 l0 134 -62
-7 c-35 -4 -66 -9 -70 -11 -18 -10 -6 -23 27 -29 l35 -6 0 -134 0 -133 -30
-16 c-39 -20 -83 -19 -111 4 -23 18 -24 25 -27 175 -3 153 -4 156 -25 154 -12
-1 -43 -3 -69 -5z"/>
<path d="M6663 2703 c-50 -5 -59 -29 -15 -39 15 -4 30 -12 34 -18 4 -6 8 -75
8 -154 l0 -142 -30 -7 c-59 -11 -25 -28 63 -31 70 -2 87 0 87 12 0 7 -11 17
-25 20 -25 6 -25 8 -25 113 0 90 3 111 21 139 37 60 75 70 90 24 8 -22 17 -30
35 -30 22 0 24 4 24 54 0 46 -3 56 -20 61 -37 12 -79 -6 -113 -46 -19 -21 -37
-36 -40 -32 -4 3 -7 24 -7 45 0 42 7 39 -87 31z"/>
<path d="M7043 2703 c-51 -5 -59 -30 -13 -39 l30 -6 0 -154 0 -154 -25 -6
c-14 -3 -25 -13 -25 -20 0 -12 17 -14 87 -12 91 3 114 16 63 35 l-30 11 0 176
0 176 -22 -2 c-13 -1 -42 -3 -65 -5z"/>
<path d="M8343 2703 c-52 -5 -58 -29 -10 -41 l32 -8 3 -152 3 -152 -31 -7
c-57 -11 -26 -28 58 -31 67 -2 82 0 82 12 0 8 -9 18 -20 21 -19 6 -20 15 -20
146 l0 140 35 15 c47 19 78 18 106 -5 23 -18 24 -26 27 -155 l4 -135 -26 -7
c-14 -3 -26 -12 -26 -20 0 -11 19 -14 85 -14 66 0 85 3 85 14 0 8 -11 17 -25
20 l-25 6 0 128 c0 149 -16 200 -69 222 -43 17 -105 7 -146 -25 l-35 -26 0 30
c0 34 6 32 -87 24z"/>
<path d="M9115 2688 c-126 -74 -122 -297 6 -363 61 -32 183 -10 213 38 17 27
-1 30 -47 8 -52 -25 -69 -26 -113 -5 -39 19 -69 63 -79 117 l-7 37 120 0 c135
0 149 6 137 62 -17 78 -64 120 -139 125 -40 3 -60 -1 -91 -19z m136 -47 c17
-17 29 -40 29 -55 0 -22 -5 -26 -29 -26 -15 0 -56 -3 -90 -6 l-61 -7 0 28 c0
15 9 43 21 61 18 29 26 34 61 34 30 0 46 -7 69 -29z"/>
<path d="M9913 2703 c-51 -5 -59 -30 -13 -39 l30 -6 0 -154 0 -154 -25 -6
c-14 -3 -25 -13 -25 -20 0 -12 17 -14 87 -12 91 3 114 16 63 35 l-30 11 0 176
0 176 -22 -2 c-13 -1 -42 -3 -65 -5z"/>
<path d="M10605 2696 c-141 -61 -147 -315 -9 -372 67 -28 166 -12 207 34 24
26 5 39 -27 19 -13 -9 -38 -18 -57 -22 -68 -12 -149 60 -149 131 l0 33 123 3
122 3 3 32 c4 43 -31 109 -71 133 -37 23 -98 25 -142 6z m112 -41 c19 -13 51
-81 41 -89 -1 -2 -45 -7 -95 -10 -91 -7 -93 -7 -93 14 0 24 15 56 38 83 19 21
79 23 109 2z"/>
<path d="M10903 2703 c-54 -5 -58 -29 -8 -39 l35 -6 0 -154 0 -154 -25 -6
c-14 -3 -25 -12 -25 -20 0 -11 19 -14 85 -14 66 0 85 3 85 14 0 8 -11 17 -25
20 l-25 6 0 138 0 138 35 17 c49 23 82 21 110 -8 24 -23 25 -29 25 -154 0
-121 -1 -130 -20 -136 -11 -3 -20 -13 -20 -21 0 -12 15 -14 82 -12 84 3 115
20 58 31 l-30 7 0 137 c0 120 -3 142 -20 171 -35 58 -112 69 -175 26 -38 -26
-54 -22 -55 14 0 6 -10 11 -22 10 -13 -1 -42 -3 -65 -5z"/>
<path d="M11435 2696 c-67 -30 -105 -102 -105 -198 0 -114 65 -188 164 -188
53 0 112 24 130 53 17 27 -1 30 -48 7 -105 -50 -185 31 -173 176 5 74 35 124
72 124 45 0 75 -18 81 -50 5 -25 11 -30 35 -30 28 0 29 2 29 49 0 46 -2 50
-31 60 -43 15 -117 13 -154 -3z"/>
<path d="M11765 2688 c-52 -30 -95 -111 -95 -180 0 -80 39 -151 102 -184 60
-31 182 -8 212 39 17 27 -1 30 -47 8 -51 -25 -66 -26 -111 -7 -38 16 -71 64
-81 119 l-7 37 120 0 c91 0 122 3 131 14 20 24 2 86 -39 130 -33 35 -43 40
-91 43 -43 3 -62 -1 -94 -19z m136 -47 c17 -17 29 -40 29 -55 0 -23 -4 -26
-37 -26 -21 0 -62 -3 -90 -6 l-53 -7 0 29 c0 16 9 44 20 62 17 28 25 32 61 32
32 0 47 -6 70 -29z"/>
<path d="M7450 2686 c0 -7 12 -16 28 -19 24 -6 34 -23 103 -186 77 -180 77
-180 60 -213 -14 -29 -22 -33 -59 -36 -40 -3 -43 -5 -40 -30 3 -23 8 -27 40
-30 30 -3 43 2 62 23 14 15 59 119 107 246 78 205 86 221 112 226 15 3 27 12
27 19 0 11 -18 14 -75 14 -60 0 -75 -3 -75 -15 0 -8 8 -15 18 -15 10 0 21 -2
24 -5 3 -3 -12 -52 -32 -108 -69 -185 -54 -181 -114 -32 -29 72 -51 133 -49
138 2 4 13 7 24 7 10 0 19 7 19 15 0 12 -17 15 -90 15 -70 0 -90 -3 -90 -14z"/>
<path d="M6726 2164 c-21 -21 -20 -40 4 -64 24 -24 29 -24 58 -4 24 17 29 53
10 72 -17 17 -54 15 -72 -4z"/>
<path d="M5145 2126 c-62 -31 -85 -68 -85 -134 0 -64 26 -92 128 -139 104 -47
132 -75 132 -129 0 -27 -7 -51 -21 -68 -17 -22 -28 -26 -72 -26 -70 0 -115 27
-119 72 -2 28 -7 34 -30 36 -28 3 -28 3 -28 -53 l0 -56 43 -19 c61 -28 160
-27 212 3 101 57 115 179 28 247 -16 12 -57 35 -93 51 -105 48 -131 84 -106
144 22 53 56 65 127 45 34 -10 38 -14 45 -58 8 -41 12 -47 37 -50 27 -3 27 -3
27 61 0 60 -2 65 -27 77 -16 7 -55 14 -88 17 -50 4 -68 0 -110 -21z"/>
<path d="M5563 1971 c-60 -28 -96 -86 -101 -166 -9 -137 55 -216 173 -216 78
0 165 45 138 72 -7 7 -21 4 -44 -10 -43 -26 -85 -27 -126 -1 -41 25 -63 59
-70 110 l-6 40 120 0 c141 0 146 3 128 73 -14 55 -37 84 -82 103 -44 19 -80
17 -130 -5z m122 -45 c25 -25 42 -72 31 -83 -13 -13 -186 -16 -186 -4 0 16 28
81 41 98 19 23 87 16 114 -11z"/>
<path d="M5897 1983 c-48 -3 -58 -7 -55 -21 2 -11 15 -18 36 -20 l32 -3 0
-154 0 -155 -25 -6 c-14 -3 -25 -12 -25 -20 0 -11 19 -14 85 -14 66 0 85 3 85
14 0 8 -11 17 -25 20 -25 6 -25 7 -25 116 l0 109 38 41 c47 48 68 51 77 10 6
-25 11 -30 36 -30 30 0 30 0 27 52 -3 46 -6 54 -29 62 -35 12 -89 -15 -123
-62 l-26 -37 0 37 c0 39 -8 69 -19 66 -3 -1 -32 -3 -64 -5z"/>
<path d="M6725 1981 c-44 -4 -60 -10 -60 -21 0 -10 12 -16 33 -18 l33 -3 -3
-157 c-3 -154 -3 -157 -25 -160 -13 -2 -23 -10 -23 -18 0 -11 19 -14 90 -14
93 0 116 12 60 31 l-30 11 0 179 c0 98 -3 178 -7 177 -5 -1 -35 -4 -68 -7z"/>
<path d="M7034 1979 c-18 -5 -48 -27 -67 -48 -82 -91 -66 -263 30 -322 63 -39
196 -17 217 36 9 24 -11 27 -46 5 -18 -11 -44 -20 -58 -20 -38 0 -90 36 -106
75 -18 43 -18 146 -1 188 22 50 41 61 89 51 36 -8 44 -14 52 -41 7 -25 14 -33
32 -33 22 0 24 4 24 49 0 46 -2 50 -31 60 -37 13 -91 13 -135 0z"/>
<path d="M7384 1976 c-79 -35 -127 -149 -104 -247 22 -91 84 -140 175 -140 56
0 98 16 128 49 26 29 6 35 -39 12 -21 -11 -50 -20 -64 -20 -65 0 -130 68 -130
137 l0 32 123 3 122 3 3 30 c4 43 -36 114 -78 136 -40 22 -92 24 -136 5z m115
-53 c17 -16 33 -40 36 -54 6 -23 3 -27 -26 -33 -17 -3 -60 -6 -95 -6 -58 0
-64 2 -64 21 0 23 15 55 38 82 23 27 76 22 111 -10z"/>
<path d="M6190 1966 c0 -7 12 -16 28 -19 25 -5 33 -21 98 -179 64 -156 73
-173 96 -176 24 -3 29 5 94 169 60 150 73 174 97 183 15 6 27 16 27 23 0 9
-21 13 -75 13 -55 0 -75 -3 -75 -13 0 -7 11 -17 24 -23 l25 -9 -45 -120 c-24
-66 -47 -127 -50 -135 -3 -9 -27 40 -55 114 -49 129 -54 156 -24 156 8 0 15 7
15 15 0 12 -17 15 -90 15 -70 0 -90 -3 -90 -14z"/>
<path d="M7080 1175 c0 -8 9 -15 20 -15 19 0 20 -7 20 -175 0 -149 -2 -175
-15 -175 -8 0 -15 -4 -15 -10 0 -6 27 -10 60 -10 58 0 80 12 40 23 -18 5 -20
14 -20 102 l0 96 35 14 c42 18 80 7 88 -27 4 -13 7 -57 7 -99 0 -68 -2 -78
-22 -92 -22 -15 -20 -16 45 -16 43 -1 66 3 62 9 -3 6 -12 10 -20 10 -10 0 -14
25 -17 104 -3 94 -5 107 -27 130 -28 30 -71 34 -111 11 -14 -8 -28 -14 -32
-15 -5 0 -8 34 -8 75 l0 75 -45 0 c-33 0 -45 -4 -45 -15z"/>
<path d="M7690 1175 c0 -9 9 -15 25 -15 l25 0 0 -171 c0 -162 -1 -171 -20
-176 -40 -11 -18 -23 40 -23 33 0 60 4 60 10 0 6 -7 10 -15 10 -11 0 -15 11
-15 43 0 39 13 67 31 67 4 0 26 -29 49 -65 40 -62 44 -65 81 -65 40 0 53 14
21 22 -10 3 -40 37 -67 77 l-49 71 44 45 c24 25 50 45 57 45 7 0 13 5 13 10 0
6 -25 10 -55 10 -57 0 -65 -5 -41 -24 12 -9 6 -20 -35 -65 l-49 -55 0 132 0
132 -50 0 c-38 0 -50 -4 -50 -15z"/>
<path d="M5030 1125 c0 -42 4 -55 15 -55 10 0 17 14 21 40 6 39 8 40 45 40
l39 0 0 -166 c0 -157 -1 -166 -20 -171 -43 -11 -17 -23 50 -23 67 0 93 12 50
23 -19 5 -20 14 -20 171 l0 166 39 0 c37 0 40 -2 48 -37 14 -58 37 -50 41 15
l3 52 -155 0 -156 0 0 -55z"/>
<path d="M5760 1171 c0 -5 11 -12 25 -15 l25 -6 0 -165 0 -165 -25 -6 c-48
-12 -25 -24 45 -24 67 0 93 12 50 23 -17 4 -20 14 -20 71 l0 66 51 0 c30 0 63
7 80 17 31 18 59 67 59 103 0 37 -30 81 -65 96 -38 16 -225 20 -225 5z m210
-41 c14 -14 20 -33 20 -64 0 -69 -28 -91 -107 -87 -22 1 -23 5 -23 86 l0 85
45 0 c32 0 51 -6 65 -20z"/>
<path d="M6130 1165 c0 -12 15 -15 75 -15 60 0 75 3 75 15 0 12 -15 15 -75 15
-60 0 -75 -3 -75 -15z"/>
<path d="M6470 1171 c0 -5 10 -11 23 -15 19 -4 31 -33 83 -183 79 -230 84
-232 145 -64 24 67 46 118 50 113 3 -4 23 -58 44 -119 52 -160 57 -158 135 68
47 135 68 183 82 187 37 9 16 22 -37 22 -54 0 -74 -13 -36 -23 18 -4 16 -14
-28 -141 -25 -75 -49 -136 -52 -136 -9 0 -89 261 -83 271 3 5 12 9 20 9 8 0
14 5 14 10 0 6 -30 10 -70 10 -67 0 -93 -12 -50 -23 11 -3 25 -18 31 -34 9
-24 6 -43 -25 -133 -21 -58 -40 -108 -44 -112 -4 -4 -29 56 -54 133 -44 134
-46 141 -27 146 42 11 16 23 -51 23 -38 0 -70 -4 -70 -9z"/>
<path d="M5431 1045 c-55 -47 -67 -155 -24 -214 28 -37 88 -55 134 -42 39 12
76 45 61 55 -5 3 -15 -1 -22 -9 -14 -17 -72 -20 -101 -5 -22 12 -49 61 -49 90
0 19 6 20 90 20 l90 0 0 26 c0 88 -110 137 -179 79z m92 -6 c26 -12 51 -54 42
-70 -9 -13 -135 -12 -135 1 0 18 29 68 42 73 20 8 25 8 51 -4z"/>
<path d="M6138 1063 c-26 -7 -25 -73 1 -73 13 0 20 9 25 30 6 34 29 39 64 14
16 -11 22 -25 22 -55 0 -34 -3 -39 -24 -39 -31 0 -87 -19 -108 -38 -10 -8 -18
-29 -18 -47 0 -60 59 -88 120 -56 32 16 39 17 44 5 3 -8 19 -14 41 -14 38 0
47 14 15 23 -18 5 -20 14 -20 107 0 94 -2 103 -25 125 -19 20 -34 25 -72 24
-26 -1 -56 -3 -65 -6z m112 -181 c0 -45 -14 -62 -50 -62 -34 0 -50 17 -50 52
0 22 6 29 28 34 15 3 32 7 37 9 28 10 35 3 35 -33z"/>
<path d="M7463 1063 c-19 -4 -23 -11 -23 -39 0 -45 33 -48 49 -4 19 56 79 31
84 -34 l2 -39 -50 -12 c-27 -6 -60 -17 -72 -23 -49 -27 -37 -106 20 -125 21
-7 37 -4 67 11 33 16 42 17 51 6 6 -8 27 -14 45 -14 36 0 46 14 16 22 -16 5
-20 19 -25 108 -4 88 -8 105 -28 126 -18 19 -32 24 -68 23 -25 -1 -56 -4 -68
-6z m115 -183 c4 -39 -16 -60 -58 -60 -32 0 -50 17 -50 46 0 27 16 41 55 47
45 6 50 3 53 -33z"/>
<path d="M8068 1063 c-26 -7 -25 -73 1 -73 13 0 20 9 25 30 6 34 29 39 64 14
16 -11 22 -25 22 -55 0 -34 -3 -39 -24 -39 -31 0 -87 -19 -108 -38 -10 -8 -18
-29 -18 -47 0 -60 59 -88 120 -56 32 16 39 17 44 5 3 -8 19 -14 41 -14 38 0
47 14 15 23 -18 5 -20 14 -20 107 0 94 -2 103 -25 125 -19 20 -34 25 -72 24
-26 -1 -56 -3 -65 -6z m112 -181 c0 -45 -14 -62 -50 -62 -34 0 -50 17 -50 52
0 22 6 29 28 34 15 3 32 7 37 9 28 10 35 3 35 -33z"/>
<path d="M8318 1063 c-27 -7 -22 -23 7 -23 l25 0 0 -114 c0 -109 -1 -114 -22
-122 -17 -6 -9 -9 36 -11 62 -4 82 2 53 18 -14 8 -17 25 -17 103 0 97 4 107
47 120 15 5 28 1 42 -13 18 -18 21 -34 21 -110 0 -81 -2 -90 -22 -104 -22 -15
-20 -16 40 -16 35 -1 62 3 62 9 0 6 -6 10 -14 10 -12 0 -15 22 -18 102 l-3
101 32 14 c40 16 38 16 57 -3 23 -22 23 -214 1 -214 -8 0 -15 -4 -15 -10 0 -6
27 -10 60 -10 58 0 80 12 40 23 -18 5 -20 14 -20 102 0 91 -9 131 -34 147 -20
14 -61 8 -95 -13 l-33 -20 -15 20 c-19 28 -72 28 -107 0 l-26 -20 0 20 c0 17
-6 21 -32 20 -18 -1 -41 -3 -50 -6z"/>
<path d="M8828 1063 c-26 -7 -25 -73 1 -73 13 0 20 9 25 30 11 59 86 25 86
-39 0 -38 0 -38 -49 -45 -65 -9 -101 -37 -101 -80 0 -61 59 -89 120 -57 32 16
39 17 44 5 3 -8 19 -14 41 -14 37 0 48 14 16 23 -17 4 -19 16 -23 112 -3 98
-5 108 -27 126 -22 18 -87 24 -133 12z m112 -181 c0 -44 -14 -62 -49 -62 -32
0 -47 9 -54 35 -9 28 5 46 44 55 57 13 59 13 59 -28z"/>
<path d="M9078 1063 c-27 -7 -22 -23 7 -23 l25 0 0 -115 c0 -93 -3 -115 -14
-115 -8 0 -18 -4 -21 -10 -4 -6 18 -10 59 -10 64 0 88 12 46 23 -18 5 -20 14
-20 80 0 67 3 79 26 106 31 38 50 39 58 5 5 -17 12 -24 24 -22 13 2 17 13 17
43 0 37 -2 40 -31 43 -25 2 -37 -4 -63 -33 l-31 -36 0 36 c0 33 -2 35 -32 34
-18 -1 -41 -3 -50 -6z"/>
<path d="M9348 1063 c-24 -6 -23 -23 1 -23 24 0 31 -26 31 -117 0 -89 13 -119
57 -134 28 -9 40 -8 72 10 33 16 40 17 45 5 3 -9 20 -14 46 -14 42 0 54 14 20
25 -19 6 -20 15 -20 131 l0 124 -27 0 c-37 0 -73 -10 -73 -20 0 -5 10 -10 23
-12 21 -3 22 -8 25 -99 3 -94 2 -96 -23 -107 -66 -30 -95 -5 -95 82 0 35 -3
84 -6 110 -6 42 -9 46 -33 45 -14 -1 -34 -3 -43 -6z"/>
<path d="M9693 1063 c-20 -8 -15 -21 10 -25 21 -3 22 -8 25 -111 3 -102 1
-109 -18 -114 -40 -11 -19 -23 40 -23 33 0 60 4 60 10 0 6 -7 10 -15 10 -12 0
-15 18 -15 104 l0 104 31 11 c39 14 35 14 53 -5 23 -22 23 -214 1 -214 -8 0
-15 -4 -15 -10 0 -6 27 -10 63 -9 59 0 61 1 40 16 -21 14 -23 23 -23 108 0 96
4 106 47 119 15 5 28 1 42 -13 18 -18 21 -34 21 -110 0 -81 -2 -90 -22 -104
-22 -15 -20 -16 45 -16 43 -1 66 3 62 9 -3 6 -12 10 -19 10 -11 0 -15 27 -18
109 -3 90 -7 113 -22 130 -25 27 -75 27 -110 0 l-27 -21 -20 21 c-25 26 -64
27 -106 1 -33 -20 -33 -20 -33 0 0 16 -6 20 -32 19 -18 0 -39 -3 -45 -6z"/>
<path d="M10203 1060 c-17 -7 -23 -18 -23 -40 0 -42 34 -42 49 0 19 56 79 31
84 -34 l2 -39 -50 -12 c-67 -15 -92 -30 -99 -59 -9 -37 8 -71 43 -86 27 -11
36 -10 71 8 33 16 42 17 51 6 6 -8 27 -14 45 -14 36 0 46 15 15 23 -16 4 -19
17 -23 105 -3 90 -6 104 -28 127 -19 20 -33 25 -69 24 -25 0 -56 -4 -68 -9z
m115 -180 c4 -39 -16 -60 -58 -60 -32 0 -50 17 -50 46 0 27 16 41 55 47 45 6
50 3 53 -33z"/>
<path d="M10453 1063 c-20 -7 -15 -23 6 -23 26 0 31 -18 31 -127 0 -86 -2 -95
-20 -100 -40 -11 -18 -23 40 -23 33 0 60 4 60 10 0 6 -7 10 -15 10 -12 0 -15
16 -15 84 0 74 3 86 25 110 30 33 43 33 55 1 5 -14 17 -25 25 -25 13 0 15 9
13 43 -3 38 -6 42 -31 45 -22 3 -36 -5 -62 -34 l-35 -38 0 37 c0 35 -2 37 -32
36 -18 0 -39 -3 -45 -6z"/>
<path d="M10718 1063 c-26 -7 -22 -23 6 -23 24 0 24 -2 28 -104 2 -83 7 -108
21 -124 34 -37 69 -36 150 6 4 2 7 -5 7 -15 0 -15 7 -18 45 -15 47 4 59 16 26
27 -24 7 -31 44 -31 161 l0 94 -29 0 c-35 0 -61 -9 -61 -21 0 -5 9 -9 20 -9
19 0 20 -7 20 -100 0 -108 -6 -120 -64 -120 -44 0 -56 32 -56 147 l0 103 -32
-1 c-18 -1 -41 -3 -50 -6z"/>
</g>
</svg>
    </>
  )
}
