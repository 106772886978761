import React from 'react';

const Interpol = () => {
    return (
        <>
        <svg className='md:-mt-16 -mt-10' version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="110.000000pt" viewBox="0 0 1024.000000 934.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,934.000000) scale(0.100000,-0.100000)"
        fill="#ffffff" stroke="none">
        <path d="M5064 9301 c-36 -22 -59 -70 -50 -105 6 -24 4 -25 -62 -31 -37 -3
        -73 -8 -79 -10 -9 -3 -13 -29 -13 -85 l0 -80 35 0 35 0 0 -344 c0 -318 -11
        -541 -28 -558 -4 -4 -85 -10 -180 -14 -197 -8 -192 -10 -192 69 l0 47 -80 0
        -79 0 -7 -48 c-6 -47 -6 -48 -39 -44 -25 3 -40 -3 -66 -26 -32 -28 -34 -35
        -34 -92 0 -57 2 -64 34 -92 26 -23 41 -29 66 -26 33 4 33 3 39 -44 l7 -48 78
        0 78 0 6 56 c3 31 8 58 11 61 3 3 73 2 155 -2 l150 -7 10 -32 c6 -17 15 -58
        20 -91 10 -68 6 -75 -47 -75 -62 0 -296 -40 -437 -75 -360 -87 -651 -215 -972
        -427 l-92 -61 18 -26 c10 -14 82 -117 160 -229 l143 -203 -120 -118 -121 -118
        -17 76 c-17 77 -49 160 -77 195 -14 19 -15 18 -34 -25 -11 -24 -59 -91 -107
        -149 -49 -58 -102 -126 -120 -152 -17 -26 -33 -48 -36 -48 -3 0 -17 19 -32 43
        -15 23 -40 55 -57 71 l-30 28 -33 -37 c-198 -224 -254 -527 -163 -885 13 -52
        27 -104 30 -115 9 -26 -20 8 -118 134 l-72 94 -8 -54 c-5 -30 -6 -128 -4 -219
        4 -133 9 -183 30 -260 34 -132 80 -244 142 -347 29 -49 49 -90 44 -91 -14 -5
        -134 53 -175 85 l-37 28 7 -70 c21 -205 111 -415 247 -577 21 -26 39 -49 39
        -52 0 -3 -55 -7 -122 -7 -68 -1 -135 -4 -149 -8 l-26 -6 71 -95 c39 -52 117
        -141 173 -197 57 -56 103 -105 103 -110 0 -4 -66 -189 -147 -410 l-147 -403
        -133 0 -133 0 0 -90 0 -90 55 0 c45 0 64 -6 108 -34 29 -19 103 -59 164 -90
        322 -162 620 -225 959 -204 274 17 608 127 867 286 57 36 75 42 122 42 l55 0
        0 90 0 89 -132 3 -132 3 -28 79 c-16 43 -27 80 -26 81 2 2 66 -10 143 -26 77
        -15 206 -36 288 -46 l147 -17 0 -51 c0 -27 5 -164 10 -304 l10 -254 30 -46
        c16 -25 58 -87 93 -138 l64 -92 93 137 94 137 11 305 10 304 150 17 c137 15
        317 47 394 69 17 5 31 6 31 2 0 -4 -12 -42 -27 -84 l-28 -78 -132 0 -133 0 0
        -90 0 -90 55 0 c49 0 66 -6 139 -50 480 -289 1022 -359 1519 -196 138 45 301
        119 418 189 83 50 102 57 147 57 l52 0 0 90 0 90 -130 0 c-71 0 -130 1 -130 3
        0 2 -67 187 -150 412 l-150 408 43 35 c102 85 319 343 302 360 -4 4 -72 9
        -151 10 l-145 4 37 40 c91 101 187 276 224 410 19 68 44 238 36 245 -2 2 -17
        -8 -34 -22 -30 -25 -151 -85 -173 -85 -6 0 4 24 21 53 89 148 156 326 184 486
        16 95 20 278 8 380 l-7 54 -93 -117 c-51 -65 -95 -116 -97 -114 -2 2 3 26 11
        53 74 239 73 534 -1 718 -41 99 -148 257 -176 257 -11 0 -56 -50 -90 -101
        l-26 -40 -20 34 c-11 18 -56 76 -99 128 -112 133 -132 161 -147 204 l-13 38
        -23 -31 c-34 -48 -61 -117 -74 -187 -7 -35 -16 -64 -20 -65 -14 -3 -238 223
        -233 235 2 6 74 111 160 233 86 123 156 227 156 232 0 4 -53 43 -117 85 -134
        86 -411 227 -553 279 -240 88 -515 154 -739 178 -71 8 -115 17 -118 25 -7 18
        17 149 30 166 9 12 46 17 165 23 l152 7 0 -62 0 -61 79 0 c86 0 91 3 91 64 0
        30 2 32 34 28 43 -5 72 10 92 50 47 91 -16 205 -101 184 -23 -6 -25 -4 -25 28
        0 63 -4 66 -91 66 l-79 0 0 -62 0 -61 -182 7 c-121 5 -185 11 -190 19 -15 25
        -21 158 -22 517 l-1 375 33 3 32 3 0 85 0 84 -37 0 c-21 0 -55 3 -75 6 -35 6
        -38 9 -38 40 0 18 -5 44 -11 57 -25 54 -116 74 -175 38z m94 -57 c42 -29 21
        -75 -37 -76 -29 -1 -71 25 -71 44 0 17 41 48 63 48 12 0 33 -7 45 -16z m120
        -144 c38 0 42 -2 42 -24 0 -23 -3 -24 -101 -31 -55 -4 -148 -4 -205 0 -101 7
        -104 8 -104 31 0 22 4 23 68 27 37 3 110 3 162 1 52 -2 114 -4 138 -4z m-8
        -532 c0 -227 3 -430 6 -450 l7 -38 -168 0 c-152 0 -167 1 -161 17 15 38 26
        277 26 571 l0 312 145 0 145 0 0 -412z m-816 -464 c3 -9 6 -67 6 -129 0 -62
        -3 -120 -6 -129 -3 -9 -17 -16 -30 -16 l-24 0 0 145 0 145 24 0 c13 0 27 -7
        30 -16z m1376 -129 l0 -145 -24 0 c-23 0 -24 4 -28 68 -1 37 -1 102 1 145 3
        74 4 77 27 77 l24 0 0 -145z m-1504 26 c9 -17 9 -32 2 -53 -8 -24 -15 -29 -36
        -26 -34 4 -51 46 -34 83 15 33 51 31 68 -4z m1643 15 c17 -20 10 -77 -11 -90
        -39 -24 -74 36 -48 84 12 23 42 26 59 6z m-301 -12 c37 -6 43 -10 40 -28 -3
        -19 -12 -22 -88 -28 -113 -8 -893 -10 -1008 -2 -88 7 -92 8 -92 30 0 13 6 25
        13 25 163 17 1028 18 1135 3z m-588 -494 l0 -360 -63 0 -64 0 -6 228 c-6 197
        -22 369 -43 465 l-6 27 91 0 91 0 0 -360z m244 303 c-20 -91 -33 -231 -40
        -455 l-7 -208 -64 0 -63 0 0 360 0 360 94 0 93 0 -13 -57z m-451 -215 c11 -42
        20 -448 11 -448 -5 0 -67 -9 -139 -19 -336 -50 -650 -169 -923 -351 -68 -45
        -126 -79 -129 -77 -2 3 -67 94 -143 203 l-139 198 32 24 c49 36 236 146 327
        192 260 131 586 232 905 280 169 26 192 25 198 -2z m689 2 c431 -61 828 -206
        1181 -432 l78 -50 -18 -28 c-50 -79 -269 -390 -274 -390 -4 0 -49 29 -100 64
        -277 188 -607 314 -963 365 l-120 17 2 185 c1 101 5 208 8 237 6 50 7 52 38
        52 17 0 93 -9 168 -20z m-216 -521 c268 -26 562 -108 789 -219 485 -238 841
        -635 979 -1091 48 -159 60 -251 61 -441 l0 -178 -27 -25 c-16 -14 -28 -29 -28
        -32 0 -3 9 2 20 12 19 17 20 17 20 -1 -1 -37 -39 -190 -70 -279 -150 -426
        -493 -798 -945 -1022 -147 -73 -135 -72 -204 -19 -52 40 -73 49 -148 65 -94
        19 -108 18 -210 -20 l-62 -24 -24 23 c-13 13 -39 25 -58 28 -42 7 -141 -18
        -225 -56 -98 -44 -110 -45 -217 -10 -52 17 -108 30 -124 28 -27 -3 -28 -6 -28
        -53 0 -27 -4 -50 -10 -50 -5 0 -43 8 -84 18 -40 10 -81 15 -90 12 -11 -3 -29
        7 -51 30 -19 19 -39 35 -45 35 -19 0 -151 142 -174 188 -12 23 -24 42 -27 42
        -15 0 9 -66 42 -120 l35 -58 -27 -27 c-15 -15 -42 -29 -63 -32 -20 -3 -46 -14
        -58 -25 -11 -11 -38 -23 -58 -28 -32 -7 -49 -2 -163 55 -217 107 -413 251
        -571 422 -583 629 -597 1515 -33 2159 111 127 327 310 425 361 28 14 29 14 23
        -2 -3 -9 -6 -28 -6 -41 0 -20 -5 -24 -29 -24 -17 0 -44 -13 -67 -31 -22 -17
        -52 -38 -69 -45 -38 -17 -8 -19 46 -3 72 22 74 9 13 -90 -30 -49 -75 -107 -99
        -130 -24 -22 -46 -49 -50 -61 -4 -12 -33 -52 -64 -88 -41 -48 -60 -79 -65
        -107 -3 -22 -16 -70 -27 -107 -20 -65 -21 -68 -4 -120 10 -29 22 -58 27 -65 5
        -6 6 -22 3 -36 -5 -17 3 -48 24 -95 17 -39 31 -76 31 -82 0 -7 6 -10 14 -7 9
        4 18 -5 26 -23 7 -16 22 -31 36 -34 20 -5 24 -12 24 -45 0 -35 3 -40 29 -46
        43 -9 53 -41 28 -90 -16 -32 -20 -60 -20 -135 0 -90 2 -98 36 -163 21 -38 37
        -79 37 -91 0 -15 9 -27 25 -34 14 -7 34 -30 44 -54 56 -123 71 -163 71 -193 0
        -18 7 -52 15 -75 8 -24 18 -68 21 -99 3 -31 17 -85 30 -121 20 -55 37 -78 105
        -145 45 -44 78 -71 74 -60 -69 172 -77 262 -31 360 16 33 38 98 50 145 18 72
        26 88 51 104 28 19 30 24 31 85 0 49 7 81 27 124 32 69 34 114 7 166 -28 56
        -47 71 -89 71 -30 0 -44 8 -79 43 -23 23 -47 57 -53 76 -11 31 -80 111 -96
        111 -4 0 -22 14 -40 31 l-32 32 -28 -19 c-15 -10 -39 -17 -51 -15 -13 1 -37
        -3 -53 -10 -24 -9 -35 -9 -54 1 -21 11 -25 21 -24 59 0 184 -4 200 -40 167
        -41 -37 -41 19 -1 94 17 30 30 60 30 67 0 14 54 26 96 21 28 -3 29 -5 27 -47
        -2 -40 -1 -43 13 -29 9 8 18 35 21 59 5 35 16 55 54 96 27 28 49 61 49 72 0
        11 9 22 20 26 11 3 20 13 20 20 0 8 44 35 97 62 88 44 98 51 106 83 15 59 47
        83 47 35 0 -52 37 -11 54 60 4 16 13 36 19 43 6 7 12 25 13 39 2 14 14 42 28
        62 l26 36 -25 13 c-25 13 -33 36 -16 47 5 3 12 1 16 -5 7 -12 45 -14 45 -2 0
        5 9 18 21 31 16 17 24 20 35 10 11 -9 14 -8 14 9 0 11 3 34 6 51 9 45 -15 41
        -55 -9 -19 -24 -44 -43 -61 -46 -37 -8 -67 -41 -130 -144 -53 -87 -78 -120
        -90 -120 -10 0 -30 72 -30 108 0 37 52 102 81 102 10 0 36 16 58 35 21 19 49
        35 62 35 30 0 87 34 113 68 11 15 33 40 49 56 15 16 26 31 23 33 -3 3 7 15 22
        28 15 13 24 26 21 29 -10 11 -57 -33 -63 -60 -4 -14 -12 -23 -19 -21 -7 2 -27
        -2 -45 -9 -39 -16 -65 -8 -59 17 4 16 3 17 -17 5 -11 -8 -37 -19 -57 -25 -31
        -10 -38 -18 -43 -46 -4 -19 -12 -35 -18 -35 -6 0 -8 10 -4 24 8 33 -39 53 -85
        37 -41 -14 -46 -14 -31 1 33 33 -13 45 -66 18 -35 -18 -43 -19 -61 -8 -12 7
        -21 16 -21 20 0 12 226 122 334 162 174 65 429 122 621 139 94 8 330 6 431 -4z
        m-2023 -586 c26 -127 28 -151 17 -158 -6 -3 -33 -40 -60 -81 -28 -41 -52 -74
        -54 -74 -2 0 -2 27 1 60 7 72 -6 78 -36 17 -21 -43 -42 -157 -34 -187 2 -8 -9
        -45 -25 -80 -16 -36 -39 -96 -52 -135 -12 -38 -26 -74 -31 -79 -4 -5 -18 25
        -30 65 -17 57 -23 99 -23 179 0 126 19 183 98 296 62 89 196 254 206 254 4 0
        14 -35 23 -77z m3692 -22 c151 -184 180 -231 210 -336 29 -97 7 -324 -35 -368
        -4 -5 -18 22 -30 60 -13 37 -36 97 -51 132 -16 35 -29 80 -29 101 0 60 -20
        152 -43 195 l-22 40 -5 -65 -5 -65 -17 31 c-9 17 -35 54 -57 82 -34 42 -41 59
        -41 95 0 51 14 129 30 172 10 26 13 28 23 15 7 -9 39 -49 72 -89z m-4070 -175
        l33 -52 -21 -60 c-17 -46 -21 -81 -21 -164 1 -119 19 -204 64 -294 l30 -59
        -15 -85 c-8 -46 -15 -87 -15 -91 -2 -27 -48 97 -85 227 l-44 157 0 -90 c-1
        -134 34 -275 93 -379 19 -33 25 -61 30 -149 l6 -108 -49 42 c-50 41 -50 41
        -44 90 7 60 -13 95 -60 107 -29 7 -34 14 -59 87 -47 140 -61 228 -61 380 1
        155 19 249 72 360 36 76 89 148 102 139 6 -3 26 -30 44 -58z m4432 16 c48 -72
        80 -143 105 -229 19 -65 23 -102 23 -228 -1 -156 -12 -234 -58 -385 -21 -67
        -25 -75 -54 -82 -57 -15 -86 -75 -64 -133 6 -15 -2 -28 -38 -62 -25 -23 -48
        -42 -51 -43 -3 0 -4 44 -2 97 3 91 6 104 42 178 21 44 49 118 60 165 24 93 39
        270 24 270 -6 0 -27 -64 -47 -141 -20 -78 -48 -165 -61 -193 -23 -48 -24 -49
        -26 -21 0 17 -7 59 -15 95 -13 62 -13 66 10 105 48 83 70 180 70 310 -1 98 -5
        130 -22 172 l-20 51 25 44 c27 47 52 78 61 78 3 0 20 -21 38 -48z m-4709 -916
        c40 -47 72 -93 72 -103 0 -31 28 -61 68 -74 20 -6 57 -30 82 -53 25 -23 64
        -57 87 -75 38 -30 43 -40 58 -110 22 -96 78 -257 116 -333 16 -31 29 -62 29
        -67 0 -5 -17 0 -37 11 -21 10 -48 17 -60 15 -14 -3 -23 1 -23 9 0 27 -25 66
        -51 80 -31 16 -113 128 -194 266 -57 95 -70 94 -36 -3 21 -63 79 -179 114
        -230 l24 -35 -24 -30 c-14 -17 -26 -25 -31 -19 -124 168 -226 395 -257 573
        -13 73 -21 262 -12 262 2 0 36 -38 75 -84z m4978 -18 c-14 -204 -56 -353 -152
        -538 -55 -105 -118 -200 -132 -200 -4 0 -15 12 -25 25 -17 24 -17 25 16 77 54
        84 85 151 107 229 11 41 20 76 20 79 0 3 -16 -24 -36 -60 -61 -109 -184 -278
        -218 -298 -20 -12 -36 -32 -42 -52 -8 -27 -14 -33 -42 -35 -17 -2 -43 -10 -57
        -19 -14 -9 -25 -12 -25 -8 0 5 17 47 39 93 45 97 96 253 106 322 4 28 17 59
        29 71 55 59 165 144 199 155 37 12 67 51 67 86 0 17 130 175 144 175 5 0 6
        -44 2 -102z m-4801 -63 c0 -27 -3 -30 -30 -30 -21 0 -31 5 -33 18 -6 33 6 49
        35 45 24 -3 28 -8 28 -33z m4604 16 c13 -24 -8 -55 -34 -50 -28 6 -42 34 -26
        53 18 22 48 20 60 -3z m-4690 -720 c62 -22 124 -50 137 -62 12 -11 38 -24 56
        -28 22 -5 40 -18 56 -41 13 -19 38 -43 57 -52 39 -21 97 -98 115 -153 21 -66
        76 -168 120 -224 56 -70 42 -61 -129 88 -79 69 -160 139 -179 156 l-36 30 44
        -65 c24 -36 91 -113 149 -173 l105 -107 -65 23 c-35 13 -106 34 -156 47 l-93
        22 -58 67 c-144 164 -218 298 -256 461 -14 57 -13 62 2 56 9 -3 68 -23 131
        -45z m4855 22 c-38 -177 -135 -357 -274 -511 -43 -46 -52 -51 -135 -72 -50
        -12 -118 -32 -152 -45 l-63 -22 103 107 c96 100 222 262 166 214 -100 -87
        -338 -292 -353 -304 -10 -8 6 19 35 61 30 41 69 108 87 149 64 144 88 177 171
        238 17 13 31 30 31 37 0 17 31 35 59 35 11 0 32 11 48 24 15 13 75 40 133 61
        58 20 112 41 120 46 25 14 31 10 24 -18z m-4636 5 c19 -19 14 -56 -8 -63 -41
        -13 -69 43 -32 64 22 13 26 13 40 -1z m4332 -27 c0 -28 -28 -47 -48 -34 -24
        15 -27 44 -7 59 22 17 55 1 55 -25z m-4187 -83 c8 -12 7 -21 -2 -32 -19 -22
        -48 -20 -61 5 -23 41 38 68 63 27z m4052 1 c9 -28 -16 -53 -44 -44 -20 6 -29
        43 -14 58 15 15 52 6 58 -14z m-3898 -111 c103 -157 267 -330 414 -439 l67
        -50 -15 -28 -16 -27 -25 23 c-32 30 -89 31 -126 2 l-26 -20 -61 58 c-136 128
        -238 286 -259 402 -10 54 -14 151 -6 151 3 0 26 -33 53 -72z m3739 2 c-14
        -158 -95 -306 -249 -459 l-85 -84 -27 22 c-38 29 -89 28 -120 -4 l-25 -24 -15
        28 -14 28 83 66 c114 90 297 284 380 400 37 53 71 97 73 97 3 0 3 -32 -1 -70z
        m-4051 -415 c167 -35 361 -111 488 -190 l57 -37 -25 -30 c-14 -16 -25 -36 -25
        -44 0 -13 -3 -13 -22 0 -63 41 -331 136 -344 123 -2 -2 78 -47 177 -101 99
        -54 177 -102 172 -106 -22 -19 -122 -53 -151 -51 -43 2 -184 47 -243 77 -94
        47 -280 216 -379 343 l-34 44 125 -5 c68 -3 160 -13 204 -23z m4593 -22 c-52
        -73 -207 -223 -291 -281 -89 -62 -156 -93 -247 -116 -91 -22 -106 -21 -182 10
        l-68 28 58 32 c31 17 116 64 187 103 90 49 119 69 95 65 -67 -13 -195 -57
        -263 -91 -65 -32 -87 -35 -87 -11 0 7 -9 22 -21 34 l-20 22 58 35 c231 139
        477 213 716 216 l98 1 -33 -47z m-3651 -157 c239 -143 563 -256 873 -303 159
        -24 510 -24 670 0 155 24 311 62 450 109 129 44 343 145 438 207 35 23 67 42
        72 44 5 1 70 -101 144 -228 l135 -229 -67 -40 c-88 -52 -284 -145 -399 -190
        -184 -72 -414 -131 -648 -168 -167 -26 -559 -36 -742 -19 -271 25 -558 89
        -800 177 -136 51 -482 219 -489 239 -3 6 55 113 128 238 125 212 134 225 153
        213 11 -7 48 -29 82 -50z m-232 14 c8 -27 -12 -52 -39 -48 -21 3 -32 28 -22
        54 8 22 54 18 61 -6z m2953 -11 c2 -18 -3 -29 -14 -33 -27 -11 -54 3 -54 28 0
        30 9 38 40 34 19 -2 26 -10 28 -29z m-3064 -85 c19 -18 19 -20 6 -45 -6 -10
        -17 -19 -25 -19 -15 0 -45 26 -45 40 0 5 7 16 16 24 8 9 19 16 24 16 5 0 16
        -7 24 -16z m3156 -29 c0 -15 -7 -26 -20 -30 -41 -13 -70 43 -33 64 25 13 53
        -5 53 -34z m-3750 -95 c42 -30 131 -69 202 -89 l61 -17 -49 -31 c-27 -18 -80
        -41 -119 -53 -38 -12 -78 -25 -88 -29 -15 -5 -4 -17 60 -63 121 -86 257 -147
        368 -164 l45 -6 0 -154 0 -154 -401 0 c-230 0 -399 4 -397 9 2 5 66 180 143
        390 76 210 141 381 143 381 2 0 17 -9 32 -20z m4334 3 c30 -77 276 -753 276
        -758 0 -3 -180 -5 -400 -5 l-400 0 0 153 0 154 68 12 c119 22 246 82 369 174
        29 22 51 43 48 47 -2 4 -23 10 -46 14 -50 8 -130 41 -182 75 l-38 24 96 32
        c52 18 116 46 142 63 57 36 59 37 67 15z m-3767 -60 c-2 -5 -23 -40 -46 -80
        -24 -40 -40 -77 -36 -83 4 -6 -11 -10 -42 -11 -26 -1 -61 -5 -78 -8 -29 -7
        -28 -8 24 -24 30 -9 89 -17 130 -17 66 -1 85 -5 140 -34 l65 -33 -29 -19 c-25
        -16 -47 -19 -145 -18 -95 0 -128 5 -192 26 -73 24 -201 89 -213 107 -3 5 19
        21 50 35 30 13 74 38 97 54 54 37 119 70 183 93 52 19 99 25 92 12z m3310 -42
        c34 -16 89 -48 123 -71 34 -23 82 -51 107 -62 l46 -20 -49 -31 c-129 -80 -243
        -111 -391 -105 -93 4 -133 15 -133 37 0 4 26 20 58 35 46 21 75 27 147 30 132
        6 233 45 120 47 -72 1 -99 9 -103 29 -2 8 -21 45 -43 82 -21 36 -39 69 -39 71
        0 11 101 -16 157 -42z m-3080 -317 c8 -22 -10 -49 -32 -49 -10 0 -22 7 -28 16
        -15 24 -1 49 28 49 15 0 28 -7 32 -16z m2758 -5 c9 -28 -16 -53 -44 -44 -20 6
        -29 43 -14 58 15 15 52 6 58 -14z m-2594 -40 c57 -23 152 -57 211 -76 92 -30
        108 -38 116 -61 6 -15 22 -57 36 -94 l26 -68 -405 0 -405 0 0 149 0 148 46 7
        c68 9 94 7 94 -6 0 -6 10 -22 22 -35 45 -48 131 -20 145 48 3 16 7 29 8 29 2
        0 49 -19 106 -41z m2489 14 c0 -68 95 -99 145 -48 14 13 25 29 25 35 0 11 36
        13 93 4 l37 -7 0 -148 0 -149 -400 0 c-220 0 -400 1 -400 3 0 1 15 43 34 92
        l34 90 133 46 c74 25 168 60 209 77 41 17 78 31 83 31 4 1 7 -11 7 -26z
        m-1333 -247 c2 -2 2 -164 1 -362 l-3 -359 -46 71 -46 71 -7 199 c-3 109 -9
        244 -12 298 l-6 99 58 -7 c32 -4 59 -8 61 -10z m178 -12 c0 -10 -4 -140 -8
        -289 l-9 -270 -45 -72 c-24 -40 -46 -73 -49 -73 -2 0 -4 162 -4 359 l0 360 48
        4 c63 4 67 3 67 -19z m-677 -146 l-3 -23 -1100 0 -1100 0 -3 23 -3 22 1106 0
        1106 0 -3 -22z m3270 0 l-3 -23 -1100 0 -1100 0 -3 23 -3 22 1106 0 1106 0 -3
        -22z m-3448 -87 c0 -17 -284 -149 -395 -185 -216 -68 -296 -80 -530 -80 -239
        0 -324 13 -540 85 -112 37 -335 139 -380 173 -18 14 75 15 913 16 549 0 932
        -4 932 -9z m3270 0 c0 -17 -284 -149 -395 -185 -214 -67 -296 -80 -530 -80
        -239 0 -323 13 -540 85 -112 37 -335 139 -380 173 -18 14 75 15 913 16 549 0
        932 -4 932 -9z"/>
        <path d="M4585 7514 c-102 -37 -143 -145 -96 -250 30 -65 72 -89 156 -89 82 1
        130 21 137 59 l5 25 -34 -26 c-47 -36 -111 -37 -148 -3 -71 65 -75 184 -9 245
        29 27 80 33 115 15 16 -9 19 -7 19 15 0 24 -3 25 -52 24 -29 0 -71 -7 -93 -15z"/>
        <path d="M4229 7424 l-77 -25 24 -72 c13 -40 36 -114 51 -164 14 -51 29 -93
        33 -93 4 0 21 5 39 11 24 9 31 16 26 28 -4 9 -15 40 -26 69 l-18 52 49 25 c56
        28 70 48 70 100 0 27 -8 44 -29 66 -35 35 -44 35 -142 3z m80 -38 c31 -33 31
        -84 1 -108 -29 -23 -36 -16 -57 56 -14 50 -14 59 -2 67 22 14 34 11 58 -15z"/>
        <path d="M4010 7337 c-14 -6 -26 -12 -28 -13 -2 -1 24 -64 56 -140 32 -77 61
        -146 65 -156 5 -15 8 -15 41 0 19 10 34 20 33 23 -50 131 -125 299 -132 298
        -5 0 -21 -5 -35 -12z"/>
        <path d="M3746 7215 c-49 -17 -112 -79 -127 -123 -24 -73 11 -169 78 -213 40
        -27 118 -25 173 4 114 60 146 194 67 284 -53 61 -113 76 -191 48z m71 -42 c58
        -27 114 -139 99 -198 -19 -75 -105 -100 -167 -48 -38 32 -79 115 -79 160 0 19
        10 43 25 62 22 26 36 33 78 40 5 0 25 -6 44 -16z"/>
        <path d="M5500 7506 c-1 -17 -11 -92 -24 -166 -13 -74 -22 -136 -21 -138 1 -2
        19 -6 39 -9 34 -5 36 -4 37 23 0 16 10 90 23 164 13 74 22 137 20 138 -1 2
        -19 7 -38 12 -35 8 -36 7 -36 -24z"/>
        <path d="M5733 7461 c-55 -26 -93 -82 -100 -153 -6 -64 10 -105 59 -141 65
        -48 168 -64 187 -29 9 17 6 18 -44 14 -52 -4 -56 -3 -90 31 -31 31 -35 40 -35
        89 0 72 32 134 83 161 45 23 78 17 118 -23 29 -28 29 -28 40 -8 10 18 8 24
        -12 39 -27 20 -94 39 -136 39 -15 0 -47 -9 -70 -19z"/>
        <path d="M6001 7248 c-35 -88 -61 -161 -59 -162 1 -2 17 -8 34 -13 l31 -11 22
        70 c21 63 25 69 44 63 56 -16 107 -17 131 -2 33 22 52 80 37 115 -14 33 -33
        46 -115 75 l-63 23 -62 -158z m151 90 c48 -37 9 -128 -53 -128 -28 0 -31 3
        -25 24 10 41 45 116 54 116 4 0 15 -6 24 -12z"/>
        <path d="M6350 7215 c-70 -32 -110 -94 -110 -172 0 -153 214 -236 323 -126 85
        85 67 217 -38 278 -55 33 -128 41 -175 20z m171 -54 c85 -86 -29 -286 -147
        -257 -101 26 -98 156 5 249 53 48 99 50 142 8z"/>
        <path d="M4755 6896 c-48 -15 -101 -42 -134 -67 -20 -16 -21 -19 -6 -19 24 0
        185 79 185 91 0 5 -1 9 -2 8 -2 0 -21 -6 -43 -13z"/>
        <path d="M4815 6870 c-22 -11 -58 -23 -80 -27 -23 -4 -50 -17 -65 -32 l-25
        -26 23 -3 c12 -2 22 -6 22 -10 0 -3 -22 -29 -49 -57 -54 -54 -111 -153 -111
        -191 0 -31 13 -30 54 1 18 14 37 25 42 25 5 0 21 15 35 33 20 25 42 37 86 50
        60 16 123 55 93 57 -8 1 -3 7 11 13 28 13 109 112 109 134 0 7 -7 13 -15 13
        -18 0 -20 20 -2 21 6 0 3 4 -8 9 -34 15 -79 11 -120 -10z"/>
        <path d="M4470 6860 c-13 -8 -13 -10 2 -10 9 0 20 5 23 10 8 13 -5 13 -25 0z"/>
        <path d="M4576 6851 c-11 -12 -16 -24 -12 -28 9 -9 39 22 34 37 -2 7 -10 4
        -22 -9z"/>
        <path d="M4415 6840 c-3 -5 -1 -10 4 -10 6 0 11 5 11 10 0 6 -2 10 -4 10 -3 0
        -8 -4 -11 -10z"/>
        <path d="M4437 6813 c-4 -3 -7 -11 -7 -17 0 -6 5 -5 12 2 6 6 9 14 7 17 -3 3
        -9 2 -12 -2z"/>
        <path d="M5708 6803 c-10 -2 -18 -9 -18 -14 0 -15 46 -10 60 6 10 12 9 15 -6
        14 -11 -1 -27 -3 -36 -6z"/>
        <path d="M5957 6777 c-24 -25 -38 -33 -48 -26 -24 15 -65 10 -94 -12 -25 -20
        -30 -21 -80 -9 -29 7 -65 11 -79 8 -18 -4 -32 2 -47 18 -13 14 -34 24 -51 24
        -26 0 -28 -3 -28 -39 0 -33 -4 -40 -22 -43 -14 -2 -24 -12 -28 -27 -6 -25 -30
        -42 -30 -22 0 6 -9 11 -21 11 -15 0 -19 5 -15 15 3 9 0 15 -9 15 -9 0 -15 -9
        -15 -23 0 -28 -58 -119 -71 -111 -5 3 -2 18 6 34 14 27 14 30 -4 40 -11 5 -29
        10 -41 10 -12 0 -35 7 -50 17 -47 27 -84 4 -125 -77 -18 -36 -40 -72 -48 -78
        -15 -13 -12 -52 4 -52 4 0 19 4 34 10 23 9 27 7 37 -18 7 -15 13 -29 14 -30 1
        -2 12 -1 23 3 20 6 20 8 6 41 -13 32 -13 39 0 65 18 33 30 37 44 11 34 -63 19
        -152 -30 -178 -29 -14 -31 -14 -62 12 l-31 26 -9 -25 c-5 -17 -20 -30 -41 -37
        -19 -6 -41 -19 -51 -30 -10 -11 -22 -17 -27 -14 -11 7 -68 -15 -68 -26 0 -4
        11 -11 25 -14 31 -8 33 -36 4 -61 -17 -16 -25 -17 -50 -8 -25 10 -31 9 -44
        -10 -17 -24 -20 -79 -5 -107 8 -15 16 -17 45 -10 20 4 44 6 54 3 14 -4 21 2
        26 24 6 27 26 44 112 95 28 17 35 18 65 5 41 -17 88 -71 88 -100 0 -20 1 -20
        18 -4 16 17 16 20 -11 69 -16 27 -27 52 -24 54 2 2 18 -2 35 -8 22 -9 32 -19
        32 -34 0 -12 11 -32 23 -45 13 -13 29 -33 34 -43 10 -18 12 -17 28 13 13 25
        21 32 37 28 19 -5 20 -2 16 43 -3 27 -3 52 0 55 3 3 15 -4 28 -16 19 -18 25
        -19 44 -7 19 11 23 11 28 -1 3 -8 16 -26 29 -39 29 -29 24 -57 -10 -48 -27 7
        -127 -4 -127 -14 0 -4 8 -18 19 -31 17 -21 20 -22 50 -9 41 17 51 5 51 -62 0
        -51 -8 -60 -68 -73 -22 -4 -38 -2 -49 7 -9 7 -41 13 -72 14 -74 0 -167 39
        -179 74 -9 25 -13 26 -78 24 -38 -1 -92 -7 -120 -13 -28 -5 -61 -7 -73 -4 -18
        5 -38 -11 -105 -80 -71 -73 -88 -98 -124 -180 -53 -120 -55 -162 -10 -213 21
        -23 34 -51 37 -77 6 -38 10 -43 51 -59 55 -21 168 -23 214 -4 24 10 46 11 77
        5 24 -4 62 -8 84 -9 36 0 42 -4 52 -30 9 -24 9 -36 -3 -58 -21 -40 -18 -53 27
        -123 50 -76 53 -118 19 -228 -19 -62 -21 -79 -11 -106 6 -17 11 -64 11 -104 0
        -40 5 -82 12 -94 7 -12 19 -42 28 -67 10 -29 20 -44 30 -43 35 5 128 31 148
        42 31 16 118 153 127 198 8 43 31 88 80 157 37 51 44 94 24 140 -17 36 -7 61
        77 192 90 141 117 191 109 204 -4 6 -28 7 -59 4 -50 -6 -53 -5 -84 30 -51 58
        -165 306 -149 322 10 11 47 -36 47 -60 0 -14 23 -62 50 -108 28 -45 53 -93 57
        -106 4 -18 10 -21 27 -16 130 40 176 76 176 140 0 51 -31 97 -51 77 -22 -22
        -39 -14 -69 34 -36 56 -39 83 -9 83 14 0 24 -10 32 -30 10 -27 15 -29 42 -24
        26 5 33 2 42 -19 10 -22 16 -24 56 -20 36 4 49 1 66 -16 11 -11 21 -32 21 -45
        0 -13 9 -30 19 -37 11 -8 45 -63 76 -124 31 -60 60 -114 65 -119 15 -15 31 41
        24 82 -7 44 3 72 46 131 l32 43 39 -41 c21 -22 39 -45 39 -50 0 -5 9 -13 20
        -16 11 -4 22 -18 25 -33 20 -95 49 -152 39 -75 -5 36 -5 37 10 19 9 -11 16
        -26 16 -33 0 -18 14 -16 33 5 26 29 20 65 -18 118 -19 27 -38 55 -41 62 -9 18
        13 40 61 61 l42 19 -3 62 c-2 38 -9 69 -19 80 -8 9 -15 35 -15 57 0 30 -9 55
        -34 91 -31 47 -35 62 -14 62 4 0 19 -19 34 -41 18 -30 29 -38 37 -30 12 12 -6
        61 -23 61 -5 0 -10 7 -10 14 0 8 -11 50 -24 93 -14 43 -30 97 -36 121 -7 29
        -18 46 -33 51 -12 5 -33 21 -46 35 -14 14 -33 26 -43 26 -11 0 -35 17 -53 38
        -28 30 -35 45 -35 80 0 23 4 42 9 42 12 0 57 -58 65 -85 6 -19 156 -122 156
        -107 0 28 -98 124 -144 142 -24 9 -26 13 -20 45 4 22 1 49 -9 74 -12 29 -14
        48 -7 72 5 17 7 36 4 41 -3 5 -31 20 -61 34 -67 29 -156 33 -185 7 -17 -15
        -19 -15 -38 2 -19 17 -21 17 -53 -18z m-270 -575 c6 -4 13 -23 15 -42 2 -27 8
        -36 26 -38 12 -2 22 -9 22 -16 0 -7 3 -34 6 -60 l7 -47 -34 3 c-28 2 -38 10
        -56 43 -34 63 -50 130 -34 149 15 17 29 20 48 8z"/>
        <path d="M4526 6784 c-4 -11 -1 -14 11 -12 9 2 18 9 21 16 6 18 -25 15 -32 -4z"/>
        <path d="M5750 6750 c0 -13 11 -13 30 0 12 8 11 10 -7 10 -13 0 -23 -4 -23
        -10z"/>
        <path d="M4794 6611 c-2 -2 -4 -13 -4 -23 0 -21 39 -25 58 -6 22 22 14 37 -19
        35 -17 -1 -32 -4 -35 -6z"/>
        <path d="M4912 6443 c-7 -11 -5 -32 4 -62 7 -24 11 -48 8 -53 -6 -9 17 -11 40
        -2 23 9 20 27 -10 55 -17 16 -24 31 -21 47 5 28 -8 38 -21 15z"/>
        <path d="M4843 6365 c-3 -9 -3 -18 0 -21 9 -9 27 6 27 22 0 19 -19 18 -27 -1z"/>
        <path d="M6520 6191 c0 -5 8 -19 19 -32 l18 -23 -5 32 c-2 18 -10 32 -18 32
        -8 0 -14 -4 -14 -9z"/>
        <path d="M6594 6003 c-11 -69 -5 -103 19 -103 17 0 21 131 4 154 -11 15 -14 8
        -23 -51z"/>
        <path d="M5358 6013 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
        <path d="M5450 6000 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
        -10 -4 -10 -10z"/>
        <path d="M6584 5746 c-3 -13 -1 -32 4 -42 8 -14 10 -10 14 20 5 43 -8 59 -18
        22z"/>
        <path d="M3797 5740 c12 -5 28 -18 35 -30 12 -19 38 -28 38 -12 -1 21 -41 52
        -67 51 -26 0 -27 -1 -6 -9z"/>
        <path d="M3880 5668 c0 -19 34 -25 58 -9 11 6 10 10 0 19 -21 17 -58 11 -58
        -10z"/>
        <path d="M6470 5650 c0 -11 7 -20 15 -20 8 0 15 6 15 14 0 8 -7 16 -15 20 -10
        4 -15 -1 -15 -14z"/>
        <path d="M6616 5573 c4 -16 16 -46 27 -68 16 -31 22 -36 25 -22 6 29 -7 85
        -24 102 -24 23 -35 18 -28 -12z"/>
        <path d="M6670 5410 c-7 -16 -9 -38 -5 -47 6 -17 7 -17 21 1 16 22 19 62 5 70
        -5 3 -14 -8 -21 -24z"/>
        <path d="M6214 5406 c-8 -35 1 -44 16 -16 8 15 9 25 1 33 -7 7 -12 2 -17 -17z"/>
        <path d="M6465 5376 c5 -40 3 -61 -5 -66 -14 -9 12 -116 43 -174 l22 -41 3 37
        c2 25 -6 59 -24 98 -21 46 -24 64 -16 72 18 18 14 58 -9 98 l-20 35 6 -59z"/>
        <path d="M6592 5297 c-36 -37 -41 -75 -17 -133 15 -37 35 -38 35 -2 0 11 7 45
        16 75 10 35 13 62 7 75 -8 18 -10 17 -41 -15z"/>
        <path d="M6646 5213 c-7 -16 -17 -52 -21 -81 -6 -49 -5 -54 11 -50 14 2 21 16
        25 53 4 28 10 62 14 78 5 17 3 27 -4 27 -6 0 -17 -12 -25 -27z"/>
        <path d="M6720 5196 c0 -19 5 -38 10 -41 6 -4 10 10 10 34 0 23 -4 41 -10 41
        -5 0 -10 -15 -10 -34z"/>
        <path d="M6860 5200 c6 -11 13 -20 16 -20 2 0 0 9 -6 20 -6 11 -13 20 -16 20
        -2 0 0 -9 6 -20z"/>
        <path d="M6786 5124 c5 -27 9 -70 9 -96 0 -47 10 -62 21 -33 9 22 44 19 44 -4
        0 -11 5 -23 11 -27 9 -5 10 6 5 42 -4 27 -9 70 -13 97 -4 38 -9 47 -25 47 -11
        0 -29 5 -40 11 -20 11 -20 10 -12 -37z"/>
        <path d="M6888 5113 c-6 -63 8 -78 17 -18 4 28 2 52 -3 57 -6 6 -11 -7 -14
        -39z"/>
        <path d="M6720 5080 c0 -5 5 -10 11 -10 5 0 7 5 4 10 -3 6 -8 10 -11 10 -2 0
        -4 -4 -4 -10z"/>
        <path d="M6510 5055 c0 -18 35 -41 75 -51 l30 -7 -34 37 c-36 37 -71 48 -71
        21z"/>
        <path d="M6670 5015 c0 -9 6 -12 15 -9 8 4 12 10 9 15 -8 14 -24 10 -24 -6z"/>
        <path d="M6640 4980 c0 -5 13 -10 29 -10 17 0 33 5 36 10 4 6 -8 10 -29 10
        -20 0 -36 -4 -36 -10z"/>
        <path d="M6962 4965 c0 -16 2 -22 5 -12 2 9 2 23 0 30 -3 6 -5 -1 -5 -18z"/>
        <path d="M5755 4936 c-22 -24 -48 -47 -57 -52 -14 -7 -19 -28 -24 -86 -4 -42
        -4 -84 0 -94 6 -15 9 -15 31 6 25 24 65 115 65 150 0 11 9 24 20 30 15 8 20
        21 20 50 0 22 -3 40 -7 39 -5 0 -26 -20 -48 -43z"/>
        <path d="M6701 4891 c-11 -15 -33 -31 -49 -34 -19 -4 -32 -16 -42 -39 -8 -18
        -37 -49 -64 -70 -56 -42 -77 -74 -106 -169 -29 -94 -30 -92 43 -84 55 6 64 4
        81 -14 18 -20 25 -98 11 -121 -4 -6 3 -10 14 -10 11 0 44 21 73 47 115 102
        161 187 165 303 1 41 7 98 14 127 6 29 8 60 4 70 -5 14 -12 7 -30 -34 -26 -57
        -30 -60 -58 -39 -15 12 -16 18 -8 35 31 57 -10 85 -48 32z"/>
        <path d="M3305 4750 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
        -7 -4 -4 -10z"/>
        <path d="M6733 4293 c-4 -10 -12 -21 -19 -26 -7 -4 -11 -14 -7 -23 6 -17 23
        -6 54 38 l20 28 -21 0 c-11 0 -24 -8 -27 -17z"/>
        <path d="M6525 4280 c-10 -11 -15 -23 -12 -26 10 -10 35 3 47 25 15 28 -10 29
        -35 1z"/>
        <path d="M6602 4173 c-35 -21 -63 -42 -64 -48 -3 -21 32 -15 64 12 18 15 42
        33 52 39 21 11 33 34 19 34 -5 0 -37 -17 -71 -37z"/>
        <path d="M3800 3566 l-85 -160 34 -18 c19 -10 35 -17 36 -15 1 1 40 74 86 163
        l85 160 -36 15 -35 16 -85 -161z"/>
        <path d="M6233 3674 c-18 -8 -33 -16 -33 -18 0 -1 34 -73 75 -159 41 -85 75
        -160 75 -166 0 -9 172 68 188 84 3 2 -1 11 -7 19 -11 13 -19 11 -59 -15 -25
        -16 -49 -29 -53 -29 -7 0 -54 93 -113 223 -19 42 -36 77 -38 76 -2 0 -18 -7
        -35 -15z"/>
        <path d="M4066 3631 c-3 -5 -17 -45 -31 -91 -15 -45 -41 -117 -60 -161 -18
        -43 -29 -79 -25 -79 5 0 15 -3 24 -6 10 -4 16 -1 16 7 0 19 72 219 79 219 3 0
        35 -72 70 -159 47 -115 70 -161 83 -164 11 -3 21 0 23 6 8 25 83 224 104 275
        18 46 19 53 5 59 -22 8 -26 0 -69 -120 -20 -59 -39 -107 -40 -105 -2 2 -32 72
        -67 157 -48 115 -70 156 -86 162 -12 5 -24 4 -26 0z"/>
        <path d="M5965 3574 c-85 -21 -142 -60 -171 -115 -38 -74 4 -212 76 -250 40
        -22 119 -25 171 -8 92 31 145 110 137 205 -5 62 -43 126 -87 148 -33 17 -96
        27 -126 20z m75 -72 c79 -79 80 -226 1 -266 -64 -33 -117 -11 -155 66 -44 88
        -30 205 27 228 50 20 88 12 127 -28z"/>
        <path d="M4440 3492 c0 -15 9 -21 41 -25 22 -3 43 -8 46 -10 7 -8 -17 -163
        -38 -246 -10 -41 -17 -76 -15 -78 2 -2 20 -6 40 -10 43 -7 37 -21 71 170 20
        111 31 147 40 143 8 -3 30 -8 49 -12 31 -5 36 -4 36 13 0 16 -17 22 -116 42
        -64 13 -124 26 -135 28 -14 4 -19 0 -19 -15z"/>
        <path d="M5516 3465 c-38 -8 -71 -16 -74 -19 -2 -3 3 -45 13 -93 9 -48 25
        -126 34 -173 l16 -86 33 7 c18 4 35 9 38 12 3 3 1 29 -5 57 -17 78 -15 100 10
        100 69 0 127 67 115 133 -13 68 -63 86 -180 62z m76 -42 c37 -42 29 -116 -14
        -132 -13 -5 -26 -6 -30 -2 -4 3 -10 39 -14 79 -7 72 -7 72 18 72 14 0 32 -8
        40 -17z"/>
        <path d="M4786 3374 c-3 -44 -10 -126 -15 -184 -6 -57 -9 -105 -8 -106 1 -1
        46 -4 100 -8 92 -7 97 -6 97 12 0 16 -10 21 -61 26 -49 6 -60 10 -55 22 3 9 6
        38 6 66 l0 51 65 -6 c60 -6 65 -5 65 13 0 16 -7 20 -37 20 -21 0 -49 3 -62 7
        -22 6 -23 9 -18 61 3 30 8 56 10 58 2 3 30 1 61 -4 55 -9 56 -8 56 15 0 22 -3
        23 -69 23 -38 0 -82 3 -99 6 l-30 6 -6 -78z"/>
        <path d="M5141 3433 l-54 -4 7 -61 c3 -34 6 -114 6 -179 l0 -119 43 0 42 0 -3
        53 c-2 30 -2 67 0 83 3 27 7 23 47 -49 24 -43 49 -81 56 -84 7 -2 29 -3 48 -1
        l36 3 -57 83 -56 82 37 31 c31 26 37 36 37 70 0 22 -5 49 -10 59 -11 20 -70
        41 -105 38 -11 -1 -44 -3 -74 -5z m99 -43 c42 -42 11 -136 -43 -128 -20 3 -22
        10 -25 76 -3 71 -3 72 23 72 14 0 34 -9 45 -20z"/>
        <path d="M707 1028 c-5 -513 -13 -732 -32 -923 l-6 -61 77 0 77 0 -7 86 c-11
        153 -19 872 -11 995 l7 120 37 -41 c20 -22 156 -168 302 -325 324 -346 460
        -495 643 -704 77 -88 148 -161 157 -163 17 -3 18 27 16 540 -1 486 10 895 29
        989 l5 26 -76 0 c-67 1 -75 -1 -69 -16 3 -9 8 -272 11 -586 l4 -570 -78 80
        c-119 124 -478 507 -777 833 -150 162 -277 292 -286 292 -16 0 -18 -43 -23
        -572z"/>
        <path d="M8021 1585 c-343 -78 -554 -288 -611 -611 -17 -93 -8 -337 15 -419
        39 -140 124 -275 234 -368 244 -208 704 -246 1018 -83 266 138 407 407 390
        746 -19 370 -205 615 -544 716 -77 23 -109 27 -263 30 -128 3 -192 0 -239 -11z
        m384 -81 c222 -53 373 -224 426 -480 18 -84 20 -123 16 -254 -6 -179 -21 -248
        -79 -368 -70 -143 -195 -246 -348 -287 -30 -9 -95 -18 -145 -21 -237 -16 -450
        86 -558 267 -129 219 -142 590 -28 834 50 106 105 173 188 228 146 97 337 127
        528 81z"/>
        <path d="M2275 1507 l0 -69 40 6 c22 3 129 9 237 13 l197 6 6 -24 c9 -38 11
        -1109 2 -1259 l-8 -135 105 0 106 0 -8 160 c-4 88 -7 405 -7 705 l0 545 150 0
        c83 0 189 -3 237 -7 l86 -7 4 67 3 67 -575 0 -575 0 0 -68z"/>
        <path d="M3705 1405 c10 -226 7 -967 -5 -1180 -5 -93 -10 -174 -10 -180 0 -7
        142 -10 405 -10 l405 0 0 66 0 66 -42 -9 c-24 -5 -158 -11 -299 -15 l-256 -6
        -6 24 c-4 13 -7 163 -7 333 l0 309 223 -7 c122 -3 243 -9 270 -13 l47 -6 0 67
        0 66 -182 -7 c-101 -5 -221 -8 -268 -8 l-85 0 -3 110 c-2 61 0 192 3 293 l7
        182 97 0 c95 0 321 -14 434 -26 l57 -6 0 64 0 63 -396 0 -397 0 8 -170z"/>
        <path d="M27 1371 c10 -251 10 -868 0 -1126 l-8 -200 106 0 106 -1 -8 121
        c-10 152 -10 1201 0 1316 l8 86 -106 0 -106 0 8 -196z"/>
        <path d="M4844 1521 c24 -192 32 -1302 10 -1446 l-6 -40 107 0 108 0 -7 135
        c-3 74 -9 240 -13 368 l-6 232 51 0 51 0 77 -87 c108 -124 267 -327 389 -498
        l106 -147 124 -2 c69 0 125 0 125 1 0 2 -44 56 -98 120 -113 135 -502 625
        -502 633 0 3 32 13 70 22 109 26 196 74 271 148 83 82 111 148 111 262 1 177
        -84 281 -265 328 -69 17 -109 19 -393 18 l-316 -2 6 -45z m576 -47 c80 -22
        142 -71 175 -138 25 -52 27 -63 23 -149 -5 -105 -22 -148 -85 -210 -47 -48
        -114 -83 -195 -102 -69 -16 -264 -20 -287 -6 -12 7 -13 48 -7 237 3 126 9 264
        12 306 l7 77 81 4 c113 5 211 -1 276 -19z"/>
        <path d="M6235 1550 c2 -8 9 -188 16 -400 11 -358 8 -797 -7 -1020 l-7 -95
        108 0 108 0 -7 75 c-3 41 -9 197 -12 346 l-7 271 174 6 c138 4 190 9 249 27
        160 46 266 128 323 248 30 64 32 73 32 187 0 111 -2 124 -28 177 -49 99 -143
        163 -280 189 -27 5 -188 8 -358 7 -274 -2 -309 -4 -304 -18z m591 -75 c135
        -40 188 -124 187 -295 -1 -115 -28 -187 -97 -256 -96 -95 -292 -138 -453 -97
        l-23 5 0 336 0 335 168 -6 c104 -4 186 -12 218 -22z"/>
        <path d="M9398 1426 c9 -187 9 -807 -1 -1126 l-8 -255 406 0 405 0 0 61 0 60
        -57 -8 c-32 -4 -169 -11 -304 -14 l-247 -7 -7 229 c-8 230 0 941 11 1110 l7
        91 -106 0 -106 0 7 -141z"/>
        </g>
        </svg>
        </>
    );
}

export default Interpol;
