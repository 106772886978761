import React from 'react';

const Fran = () => {
    return (
        <>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="95.000000pt" height="100.000000pt" viewBox="0 0 800.000000 800.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
        fill="#ffffff" stroke="none">
        <path d="M3750 7989 c-1249 -79 -2383 -733 -3077 -1774 -893 -1339 -893 -3091
        0 -4430 342 -512 805 -946 1337 -1252 923 -531 2027 -669 3064 -384 1207 332
        2212 1247 2659 2421 180 472 260 914 260 1430 0 362 -31 627 -114 963 -249
        1011 -885 1881 -1782 2441 -684 427 -1528 637 -2347 585z m662 -84 c540 -57
        1056 -222 1533 -493 123 -69 379 -242 485 -327 345 -276 634 -593 862 -946
        342 -527 545 -1100 614 -1731 23 -207 23 -609 0 -816 -129 -1181 -758 -2216
        -1741 -2866 -544 -360 -1135 -569 -1800 -637 -155 -16 -576 -16 -730 0 -1100
        113 -2060 640 -2731 1497 -705 900 -978 2062 -752 3194 186 935 721 1780 1494
        2362 619 467 1363 735 2164 782 117 7 462 -4 602 -19z"/>
        <path d="M3740 7854 c-116 -9 -390 -47 -505 -70 -623 -126 -1201 -403 -1700
        -813 -120 -99 -346 -321 -458 -450 -443 -513 -749 -1149 -872 -1815 -45 -248
        -59 -410 -59 -706 0 -295 13 -443 60 -700 241 -1308 1134 -2394 2377 -2889
        725 -289 1563 -347 2328 -160 1127 274 2076 1045 2573 2091 190 399 310 832
        358 1288 16 161 16 589 -1 750 -114 1086 -657 2052 -1520 2704 -547 413 -1175
        665 -1879 752 -122 15 -581 27 -702 18z m268 -230 c3 -24 -27 -34 -104 -34
        -42 0 -44 -1 -44 -31 l0 -31 68 4 c63 3 67 2 70 -19 3 -20 -2 -22 -68 -29
        l-71 -7 3 -30 3 -30 75 -1 c65 -1 75 -4 78 -20 6 -29 -16 -36 -120 -36 l-98 0
        0 73 c0 39 -3 102 -6 139 l-7 67 39 4 c22 2 71 4 109 3 63 -1 70 -3 73 -22z
        m-341 -94 c9 -68 9 -104 1 -128 -22 -72 -138 -98 -198 -44 -29 25 -32 35 -45
        132 -8 58 -10 109 -6 113 5 5 19 7 32 5 22 -3 25 -10 35 -93 7 -49 15 -98 19
        -108 10 -23 51 -32 82 -18 25 12 26 13 20 86 -4 41 -10 93 -13 116 l-7 40 34
        -3 34 -3 12 -95z m770 89 c43 -6 80 -12 81 -14 2 -2 1 -13 -3 -25 -6 -19 -13
        -21 -73 -18 -66 3 -67 2 -70 -23 -3 -22 2 -27 29 -33 17 -3 42 -6 55 -6 19 0
        24 -5 24 -26 0 -27 -1 -27 -59 -21 l-59 5 -7 -59 c-7 -58 -8 -59 -33 -53 -15
        4 -29 8 -31 10 -4 4 18 216 26 252 6 25 12 26 120 11z m381 -78 c47 -25 47
        -107 0 -132 -15 -8 -17 -14 -8 -24 13 -16 9 -125 -5 -124 -39 2 -49 16 -46 61
        3 51 -7 63 -60 73 l-35 7 -10 -49 c-7 -37 -14 -49 -29 -51 -45 -5 -47 7 -15
        144 16 71 30 132 30 135 0 9 150 -25 178 -40z m-1568 -3 c52 -36 72 -82 67
        -151 -4 -44 -2 -59 10 -66 10 -5 13 -15 8 -29 -6 -20 -8 -21 -33 -8 -24 12
        -31 11 -57 -5 -95 -59 -211 26 -202 148 8 108 122 168 207 111z m1857 -69 l31
        -10 7 -145 c4 -80 6 -147 4 -149 -2 -2 -16 -1 -31 2 -23 4 -27 11 -30 41 -3
        34 -6 37 -51 51 -48 15 -48 15 -70 -12 -20 -23 -25 -25 -49 -14 l-27 12 92
        117 c51 65 92 118 93 118 1 0 15 -5 31 -11z m-2170 -131 c23 -73 40 -134 39
        -136 -2 -1 -16 -4 -31 -5 -28 -2 -29 1 -71 128 -38 117 -41 131 -26 137 42 17
        47 10 89 -124z m2446 29 c15 -10 41 -129 47 -212 1 -5 18 27 40 72 33 71 41
        81 59 76 12 -3 24 -9 26 -14 5 -7 -90 -234 -105 -252 -4 -4 -19 -3 -33 4 -28
        13 -31 20 -47 143 -9 69 -20 112 -20 78 0 -6 -16 -45 -35 -87 -33 -73 -35 -76
        -57 -64 -13 6 -24 13 -26 14 -1 1 16 45 39 96 23 52 48 109 55 127 15 34 30
        39 57 19z m-2742 -107 c22 -52 42 -96 44 -98 2 -3 32 6 66 18 51 19 62 21 70
        9 17 -28 8 -36 -81 -71 -81 -33 -91 -35 -99 -20 -11 22 -101 240 -101 246 0 6
        32 14 49 12 7 0 31 -44 52 -96z m-211 -20 c11 -11 20 -33 20 -50 0 -23 6 -33
        25 -40 15 -6 32 -25 41 -46 14 -33 13 -38 -3 -67 -12 -21 -43 -45 -88 -69 -39
        -21 -73 -38 -76 -38 -13 0 -131 242 -122 251 20 20 139 79 161 79 12 0 31 -9
        42 -20z m3375 -74 c46 -30 77 -104 57 -134 -8 -12 -13 -12 -31 -1 -11 8 -21
        21 -21 30 0 24 -31 57 -61 64 -33 8 -83 -32 -99 -79 -23 -70 33 -127 91 -93
        23 13 35 14 54 5 56 -25 -23 -75 -102 -64 -44 6 -46 5 -29 -13 21 -23 11 -51
        -19 -51 -27 0 -74 35 -62 46 4 4 13 2 19 -4 7 -7 19 -12 27 -12 11 0 10 4 -4
        20 -16 18 -16 20 -1 26 14 5 13 10 -8 35 -32 38 -40 78 -25 128 12 42 59 97
        94 112 28 12 91 4 120 -15z m-1427 -71 c850 -108 1601 -542 2113 -1221 936
        -1243 815 -2978 -286 -4079 -846 -846 -2092 -1134 -3222 -744 -914 316 -1634
        1041 -1940 1952 -327 979 -155 2046 464 2868 286 380 652 688 1070 902 387
        197 771 304 1228 341 100 8 453 -4 573 -19z m-2228 -76 c37 -56 60 -102 60
        -119 0 -43 -40 -92 -92 -114 -69 -28 -93 -15 -167 97 l-61 92 23 17 c12 10 24
        18 27 18 3 0 27 -34 54 -76 28 -42 60 -83 73 -92 22 -14 26 -13 51 5 15 11 29
        28 30 37 2 10 -22 56 -53 103 l-57 86 23 18 c13 10 25 19 27 19 1 0 29 -41 62
        -91z m3958 -59 l23 -19 -21 -78 c-12 -43 -30 -108 -41 -145 l-20 -68 -25 17
        c-22 14 -24 21 -19 53 6 34 3 38 -33 64 -39 26 -40 26 -70 9 -29 -17 -31 -17
        -51 1 -21 19 -21 19 101 102 68 45 125 83 128 83 3 0 15 -8 28 -19z m-4286
        -111 c11 -6 26 -26 34 -44 18 -44 6 -80 -43 -122 l-37 -31 33 -37 c31 -36 32
        -38 15 -56 -10 -11 -24 -18 -31 -17 -7 2 -51 50 -98 107 l-85 103 66 54 c68
        56 103 66 146 43z m4502 -58 c11 -12 15 -25 10 -33 -13 -21 -165 -198 -171
        -198 -12 0 -43 22 -43 31 0 11 171 217 181 218 3 1 14 -8 23 -18z m-4904 -187
        c-7 -7 -16 -11 -21 -8 -15 9 -10 56 9 77 17 19 17 18 20 -18 2 -20 -2 -43 -8
        -51z m120 40 c11 -13 6 -23 -39 -68 l-52 -52 21 -19 c20 -18 21 -18 68 28 45
        44 49 46 65 30 16 -16 14 -20 -30 -65 l-47 -48 24 -25 24 -25 53 52 53 52 20
        -20 21 -20 -76 -74 -76 -73 -102 101 -102 102 70 70 c38 38 75 70 81 70 6 0
        17 -7 24 -16z m4981 -31 c46 -44 60 -95 36 -128 -15 -19 -16 -19 -36 -1 -15
        13 -19 26 -15 43 8 30 -20 63 -53 63 -31 0 -29 -17 9 -90 36 -69 33 -98 -13
        -133 -68 -50 -193 55 -174 144 9 38 22 45 45 24 14 -12 17 -25 13 -45 -4 -22
        1 -35 20 -54 29 -29 49 -33 66 -12 10 12 6 27 -18 73 -37 69 -39 99 -9 130 34
        36 80 31 129 -14z m224 -245 c82 -101 81 -99 59 -115 -17 -13 -23 -10 -69 41
        -49 55 -50 55 -72 37 l-21 -18 43 -53 c42 -52 43 -54 25 -67 -18 -13 -23 -10
        -52 23 -18 21 -38 44 -45 53 -10 14 -14 13 -37 -8 l-26 -24 47 -55 c36 -42 44
        -58 37 -70 -6 -9 -15 -17 -20 -19 -10 -3 -150 155 -143 161 35 33 213 176 217
        175 4 0 30 -28 57 -61z m-5408 -10 c13 -12 23 -32 23 -45 0 -18 6 -23 25 -23
        13 0 44 -13 69 -28 l45 -29 -21 -20 -21 -21 -44 23 c-50 27 -59 26 -91 -16
        l-24 -30 28 -22 c15 -12 34 -27 43 -33 13 -10 12 -15 -6 -37 l-20 -26 -104 85
        c-57 46 -106 89 -107 94 -4 10 76 112 107 138 23 19 72 14 98 -10z m-526 -798
        c26 -15 26 -66 -1 -80 -48 -26 -91 30 -55 70 21 23 29 24 56 10z m6370 0 c23
        -13 25 -53 3 -74 -30 -30 -74 -10 -74 34 0 41 33 60 71 40z m373 -777 c14 -52
        37 -186 32 -191 -7 -6 -269 -62 -273 -58 -3 3 -43 195 -43 207 0 5 13 9 28 9
        28 0 29 -3 41 -71 13 -69 14 -71 40 -64 14 4 27 7 28 8 1 1 -4 31 -12 65 -11
        54 -11 64 1 69 27 10 40 -5 52 -66 12 -59 14 -61 40 -55 15 4 29 8 31 10 2 2
        -1 29 -8 60 -16 84 -15 94 13 94 15 0 27 -7 30 -17z m-7019 -88 c152 -26 138
        -17 130 -83 -10 -84 -36 -135 -78 -155 -75 -36 -176 -6 -204 60 -13 33 -14 49
        -3 121 12 82 13 83 39 78 14 -3 67 -12 116 -21z m7079 -231 c8 -32 3 -37 -50
        -52 -48 -14 -55 -26 -47 -77 l6 -36 52 6 c51 7 53 6 59 -20 4 -15 4 -30 1 -34
        -13 -12 -277 -38 -281 -27 -2 6 -8 45 -12 86 -10 78 -5 106 23 133 21 21 78
        22 95 2 11 -14 15 -14 32 2 16 14 73 37 109 42 4 1 10 -11 13 -25z m-7139
        -109 c137 -10 145 -13 145 -46 0 -35 -5 -35 -178 -17 -108 11 -113 12 -108 32
        3 12 6 27 6 34 0 9 9 12 28 8 15 -2 63 -8 107 -11z m29 -172 l108 -6 -4 -93
        c-3 -81 -6 -97 -25 -116 -27 -26 -86 -30 -109 -6 -14 14 -19 14 -43 2 -14 -8
        -45 -14 -68 -14 -41 0 -43 1 -43 30 0 26 4 30 38 35 57 9 67 18 70 64 l3 41
        -55 0 -56 0 0 35 c0 34 1 35 38 35 22 0 87 -3 146 -7z m7081 -57 c70 -30 88
        -147 31 -204 -25 -26 -32 -27 -141 -30 l-115 -4 0 36 0 35 107 3 c107 3 108 3
        121 31 12 24 11 31 -2 53 -16 23 -21 24 -126 24 l-110 0 0 29 c0 16 6 32 13
        34 28 12 189 6 222 -7z m-6971 -308 c3 -29 6 -79 6 -110 0 -54 -2 -58 -24 -58
        -19 0 -24 6 -30 38 -3 20 -6 54 -6 75 0 38 -9 44 -44 31 -14 -5 -15 -17 -10
        -69 6 -59 5 -63 -15 -70 -13 -4 -25 -4 -28 -1 -3 2 -8 35 -11 71 l-5 66 -31
        -3 -31 -3 3 -77 c4 -78 4 -78 -22 -78 -27 0 -29 7 -40 152 l-5 66 107 7 c59 3
        114 8 122 9 52 12 57 8 64 -46z m6788 -80 c-3 -77 -3 -78 21 -78 28 0 37 20
        37 87 0 46 7 55 38 47 19 -6 20 -11 15 -75 l-6 -69 30 0 c28 0 31 3 37 46 3
        26 6 60 6 75 0 24 4 29 24 29 14 0 27 -4 30 -9 6 -9 -11 -201 -18 -209 -6 -6
        -270 20 -277 27 -3 3 -1 52 4 109 10 103 10 103 36 100 26 -3 26 -4 23 -80z
        m-6805 -188 c66 -40 85 -142 37 -199 -31 -38 -52 -39 -60 -5 -5 18 -1 31 15
        48 55 58 -6 126 -96 106 -41 -10 -73 -41 -73 -72 0 -23 43 -68 65 -68 10 0 15
        -10 15 -31 0 -31 -1 -31 -36 -26 -123 20 -137 202 -20 253 46 20 116 17 153
        -6z m6971 -75 c27 -5 32 -10 32 -35 0 -16 -3 -31 -7 -33 -5 -3 -55 4 -113 14
        -58 10 -120 21 -137 24 -33 6 -41 23 -28 58 6 14 46 9 253 -28z m-175 -136
        c12 -7 25 -16 28 -22 4 -5 38 -10 77 -11 39 -1 73 -4 77 -7 3 -4 3 -19 -1 -34
        -6 -26 -9 -27 -53 -21 -52 6 -66 1 -75 -31 -3 -13 -8 -31 -11 -41 -4 -16 4
        -21 48 -31 50 -11 54 -14 49 -37 -2 -14 -11 -28 -18 -31 -23 -9 -276 58 -271
        71 3 6 12 46 22 89 23 103 65 138 128 106z m-6693 -101 c6 -24 20 -74 30 -113
        21 -72 17 -85 -23 -85 -12 0 -20 10 -24 31 -3 17 -11 33 -18 35 -7 3 -57 -6
        -111 -20 -54 -14 -102 -22 -106 -18 -3 4 -8 18 -10 32 -3 24 2 26 100 52 57
        15 106 29 109 31 2 3 0 22 -6 42 -9 33 -8 37 12 45 34 14 36 13 47 -32z m6550
        -188 c18 -10 18 -12 -5 -79 -20 -60 -21 -70 -8 -75 31 -12 40 -4 57 49 19 61
        24 66 53 59 l21 -6 -21 -64 c-12 -35 -19 -65 -16 -68 2 -2 16 -7 30 -11 26 -6
        28 -4 49 62 13 37 24 69 25 71 1 1 14 -2 28 -7 l26 -10 -31 -93 c-17 -51 -34
        -97 -39 -102 -10 -11 -269 75 -269 89 0 22 64 195 72 195 5 0 18 -5 28 -10z
        m-101 -45 c7 -19 9 -45 5 -57 l-6 -21 -19 24 c-19 25 -23 47 -13 73 9 25 19
        19 33 -19z m-6366 -52 c3 -16 2 -31 -1 -35 -4 -3 -65 -24 -135 -46 l-128 -40
        -10 25 c-5 13 -9 28 -9 31 0 8 235 90 261 91 11 1 19 -9 22 -26z m58 -189 c56
        -54 61 -127 11 -186 -93 -111 -262 -57 -262 84 0 121 162 187 251 102z m6324
        -8 c-20 -63 -23 -59 84 -106 56 -24 101 -44 101 -46 0 -15 -22 -54 -31 -54 -6
        0 -52 18 -101 40 -50 22 -94 40 -99 40 -4 0 -13 -16 -20 -35 -14 -38 -25 -42
        -52 -22 -16 11 -14 20 29 119 46 104 48 106 71 95 18 -8 23 -16 18 -31z m8
        -348 c48 2 87 1 87 -3 0 -3 -7 -19 -15 -35 -14 -28 -20 -30 -70 -30 l-55 0 26
        -51 c22 -45 24 -55 14 -80 -7 -16 -16 -29 -21 -29 -5 0 -26 34 -46 75 -36 72
        -39 75 -68 71 -16 -3 -54 -8 -83 -11 l-52 -6 16 35 c15 30 22 35 65 40 27 4
        49 9 49 12 0 4 -9 23 -21 44 -20 36 -20 38 -4 70 l17 33 37 -69 38 -69 86 3z
        m-6203 83 c11 -28 8 -36 -39 -116 -28 -48 -51 -89 -51 -92 0 -2 34 15 77 38
        72 40 77 41 90 24 7 -11 13 -24 13 -31 0 -11 -222 -134 -242 -134 -4 0 -15 11
        -23 24 -15 23 -14 27 30 102 26 44 51 86 56 95 5 10 -17 2 -64 -22 -40 -21
        -77 -39 -83 -39 -6 0 -16 11 -22 25 -11 24 -8 26 111 89 67 36 125 66 128 66
        4 0 12 -13 19 -29z m5922 -245 c15 -11 14 -17 -22 -71 -40 -61 -41 -68 -10
        -85 16 -9 23 -3 52 45 18 30 35 55 38 55 4 0 14 -6 24 -13 17 -13 16 -17 -18
        -70 l-36 -56 22 -15 c12 -9 26 -16 30 -16 3 0 23 27 43 60 20 33 40 60 44 60
        4 0 16 -6 26 -14 18 -13 17 -18 -35 -97 -30 -46 -57 -88 -60 -92 -7 -8 -240
        138 -240 150 0 14 106 173 115 173 6 -1 18 -6 27 -14z m-5663 -202 c65 -39 91
        -128 51 -175 l-20 -24 -19 25 c-14 17 -17 28 -10 37 30 35 -27 101 -75 89 -35
        -9 -84 -55 -91 -86 -8 -34 27 -80 61 -80 24 0 34 23 14 35 -16 10 -12 33 8 44
        16 8 25 2 55 -39 l37 -49 -63 -45 c-63 -45 -87 -47 -87 -7 0 9 -10 21 -22 26
        -31 14 -68 72 -68 107 0 108 140 194 229 142z m5280 -231 c-11 -13 -15 -11
        -25 18 -10 30 -9 37 10 57 l21 24 3 -42 c2 -23 -2 -48 -9 -57z m125 55 c7 -12
        -1 -28 -37 -71 l-47 -55 24 -15 c23 -16 25 -15 65 34 36 42 45 48 62 39 26
        -15 25 -21 -22 -74 l-42 -47 22 -22 22 -22 52 58 c52 58 53 58 71 38 18 -20
        18 -21 -6 -53 -14 -18 -45 -55 -70 -82 l-45 -50 -104 89 c-57 48 -105 91 -107
        95 -4 11 130 161 141 157 6 -2 15 -10 21 -19z m-5172 -113 c34 -42 63 -81 63
        -87 0 -6 -8 -16 -18 -22 -16 -9 -25 -3 -66 44 -43 47 -51 53 -65 41 -23 -19
        -21 -28 24 -79 39 -44 40 -46 23 -65 -18 -19 -19 -18 -65 34 -47 51 -48 52
        -68 34 -11 -10 -20 -21 -20 -25 0 -3 20 -30 45 -59 44 -51 45 -54 29 -73 -10
        -10 -21 -17 -24 -16 -4 2 -38 38 -75 81 l-68 78 24 24 c44 43 184 164 191 165
        3 0 35 -34 70 -75z m89 19 l24 -27 -45 7 c-70 11 -98 46 -37 46 23 0 41 -8 58
        -26z m4894 -114 c18 -20 18 -21 -10 -50 l-29 -30 79 -80 79 -80 -22 -22 -22
        -22 -80 79 -80 79 -30 -29 -30 -29 -20 20 -21 20 78 81 c43 44 81 81 84 82 3
        0 14 -8 24 -19z m-4831 -69 c17 -18 17 -24 -3 -122 -12 -56 -21 -104 -21 -107
        0 -4 28 25 63 62 l62 68 22 -23 21 -24 -98 -105 c-98 -104 -99 -104 -119 -86
        -26 23 -26 39 -2 151 l20 88 -65 -64 -64 -63 -22 22 -23 22 100 100 c55 55
        102 100 106 100 3 0 14 -9 23 -19z m4701 -244 l78 -88 -24 -25 -25 -24 -92
        104 c-51 58 -92 109 -92 114 0 5 10 16 23 25 26 18 12 29 132 -106z m-4407
        -18 c42 -34 78 -63 80 -64 6 -5 -22 -45 -32 -45 -4 0 -30 18 -56 40 -54 45
        -65 48 -80 21 -8 -17 -3 -25 40 -58 27 -21 49 -41 50 -44 0 -3 -6 -14 -14 -24
        -13 -18 -16 -17 -66 23 -50 40 -54 41 -72 26 -27 -25 -23 -38 21 -68 65 -45
        73 -58 55 -84 -15 -21 -15 -21 -99 45 -47 37 -85 69 -85 72 0 8 169 221 175
        221 3 0 40 -28 83 -61z m82 36 l23 -25 -44 0 c-40 0 -80 13 -69 23 12 10 46
        26 56 27 6 0 21 -11 34 -25z m4114 -35 c27 -10 60 -64 52 -85 -3 -8 5 -16 19
        -19 12 -3 37 -22 54 -42 31 -34 31 -37 15 -55 -23 -26 -35 -24 -69 11 -34 36
        -51 37 -88 7 l-27 -23 31 -42 c30 -41 31 -44 14 -62 -10 -11 -23 -20 -29 -20
        -10 0 -176 212 -176 224 0 6 133 102 150 109 22 8 26 8 54 -3z m-3966 -169
        c73 -49 85 -64 84 -104 -1 -33 -39 -77 -67 -77 -8 0 -11 -7 -8 -19 4 -17 -36
        -121 -47 -121 -3 0 -16 9 -29 19 -23 17 -23 19 -6 50 23 45 16 78 -22 102
        l-32 20 -26 -45 c-15 -26 -30 -46 -34 -46 -3 0 -17 7 -29 16 -26 18 -28 13 65
        157 45 70 68 97 79 93 8 -3 40 -23 72 -45z m3750 -73 c73 -128 70 -175 -18
        -227 -44 -26 -90 -27 -122 -1 -14 10 -48 57 -76 104 l-50 85 24 16 c14 9 29
        13 34 10 5 -3 26 -33 46 -68 65 -108 86 -123 132 -91 33 23 28 47 -29 141
        l-52 86 24 18 c13 10 26 19 29 19 3 0 29 -41 58 -92z m-3459 -95 l22 -18 -17
        -145 c-10 -80 -18 -146 -20 -148 -1 -2 -16 4 -32 12 -27 14 -30 20 -25 49 4
        30 2 34 -40 54 l-44 21 -21 -21 c-18 -17 -26 -19 -49 -11 -16 5 -28 14 -28 20
        0 14 206 203 221 204 7 0 21 -8 33 -17z m3152 -39 c25 -23 24 -29 -8 -43 -18
        -8 -29 -9 -37 -1 -24 24 -83 8 -96 -26 -19 -50 34 -144 82 -144 31 0 71 47 64
        75 -5 19 -1 25 26 34 38 14 43 10 43 -38 0 -92 -102 -150 -195 -111 -66 27
        -109 144 -77 207 20 38 38 52 84 68 41 14 84 6 114 -21z m-400 -53 c-17 -33
        -47 -58 -57 -48 -10 10 16 64 37 74 38 20 41 16 20 -26z m-2548 -2 l25 -11
        -43 -107 c-39 -94 -42 -107 -27 -113 9 -4 39 -17 65 -28 45 -18 47 -21 36 -42
        -6 -13 -13 -24 -14 -25 -2 -3 -178 67 -184 73 -6 6 101 264 110 264 5 0 19 -5
        32 -11z m2641 -35 c12 -32 4 -41 -60 -64 -57 -20 -65 -25 -60 -44 3 -11 6 -24
        6 -28 0 -4 30 4 66 18 37 14 68 23 70 21 2 -2 5 -14 6 -26 3 -20 -5 -26 -52
        -44 -68 -26 -63 -20 -55 -55 l6 -30 70 25 c71 27 84 25 91 -10 2 -15 -14 -25
        -92 -53 -52 -19 -99 -33 -104 -31 -11 4 -106 261 -97 265 38 15 188 71 192 71
        4 1 10 -6 13 -15z m-2319 -82 c89 -28 105 -41 86 -71 -7 -11 -20 -10 -77 7
        -38 12 -70 20 -71 18 -1 -1 -4 -13 -5 -26 -3 -21 4 -26 60 -43 56 -17 62 -21
        56 -40 -10 -33 -10 -33 -79 -10 l-65 21 -8 -31 -8 -32 70 -21 c63 -19 71 -24
        68 -45 -1 -12 -5 -25 -7 -28 -8 -7 -210 56 -209 65 4 37 80 264 88 264 6 0 51
        -13 101 -28z m2038 -40 c11 -11 17 -27 14 -35 -7 -20 -55 -28 -61 -11 -7 19
        -60 18 -76 -1 -25 -31 -5 -45 65 -45 61 0 69 -3 97 -30 25 -26 29 -36 24 -62
        -8 -41 -23 -57 -71 -79 -53 -25 -85 -24 -126 4 -41 28 -45 58 -8 67 19 6 29 3
        37 -11 22 -34 102 -18 102 20 0 24 -19 31 -80 31 -62 0 -96 15 -110 49 -18 45
        11 96 70 120 36 15 100 6 123 -17z m-1629 -57 c62 -8 114 -37 133 -75 32 -64
        0 -173 -61 -205 -31 -17 -41 -17 -121 -6 -81 12 -86 15 -82 35 3 11 13 71 22
        131 19 132 21 139 39 132 7 -3 38 -8 70 -12z m1179 -149 c37 -71 66 -130 64
        -132 -2 -2 -17 -7 -34 -10 -26 -5 -31 -2 -43 26 -12 28 -17 31 -43 26 -16 -3
        -40 -6 -53 -6 -18 0 -24 -6 -24 -22 0 -32 -10 -44 -42 -52 -23 -5 -28 -3 -28
        13 0 37 62 276 73 283 7 5 23 7 37 6 21 -3 36 -23 93 -132z m-805 107 l82 -6
        0 -30 0 -30 -74 7 c-70 6 -74 6 -79 -15 -9 -34 -1 -39 69 -39 l65 0 -3 -27
        c-3 -28 -3 -28 -70 -24 l-68 3 0 -31 c0 -33 12 -37 105 -43 36 -3 40 -5 37
        -28 -2 -14 -5 -26 -7 -28 -2 -2 -50 0 -107 5 l-103 8 2 45 c1 25 4 89 8 143 8
        112 -7 102 143 90z m386 -49 c3 -26 6 -78 6 -117 l0 -69 70 4 70 4 0 -27 c0
        -32 -25 -39 -130 -39 l-67 0 -7 53 c-3 28 -6 94 -6 145 l0 92 29 0 c26 0 29
        -3 35 -46z"/>
        <path d="M4682 7489 c-10 -38 -9 -39 23 -45 53 -10 80 -2 83 25 5 30 -4 38
        -55 49 l-41 9 -10 -38z"/>
        <path d="M3134 7492 c-39 -26 -41 -98 -3 -146 15 -19 30 -26 56 -26 29 0 33 3
        24 14 -11 13 -5 46 8 46 4 0 12 -5 18 -11 33 -33 26 62 -8 105 -20 26 -69 35
        -95 18z"/>
        <path d="M5047 7354 c-16 -19 -27 -37 -25 -39 1 -2 15 -6 31 -10 27 -7 27 -6
        27 39 0 25 -1 46 -2 46 -2 0 -16 -16 -31 -36z"/>
        <path d="M2323 7182 c-30 -16 -31 -18 -17 -45 14 -26 16 -26 43 -13 42 22 56
        43 41 61 -16 19 -27 18 -67 -3z"/>
        <path d="M2385 7093 c-41 -21 -39 -19 -25 -53 14 -34 20 -36 59 -16 44 23 53
        39 35 64 -18 26 -29 27 -69 5z"/>
        <path d="M3670 7034 c-1225 -133 -2253 -994 -2599 -2178 -42 -142 -80 -321
        -103 -486 -17 -128 -17 -612 0 -740 70 -500 226 -920 488 -1315 485 -731 1261
        -1219 2134 -1340 180 -25 534 -30 720 -11 1444 153 2575 1281 2725 2718 175
        1676 -1043 3178 -2717 3353 -178 18 -475 18 -648 -1z m201 -525 c126 -12 264
        -41 382 -80 88 -29 317 -132 317 -143 0 -4 -27 7 -60 24 l-60 30 -52 -26 -51
        -26 114 -60 c87 -46 139 -66 219 -85 58 -13 113 -25 123 -26 19 -1 181 -146
        173 -154 -2 -2 -31 10 -65 26 -34 17 -61 29 -61 27 0 -3 10 -22 22 -42 12 -20
        29 -60 38 -87 l16 -50 33 57 32 57 21 -26 20 -26 -29 -66 -30 -67 34 -57 c18
        -32 49 -94 68 -139 19 -45 55 -107 80 -140 24 -32 67 -95 95 -141 28 -46 54
        -84 58 -83 22 1 82 17 82 21 0 3 -7 30 -16 60 -9 30 -14 56 -12 58 7 6 1028
        335 1042 335 7 0 39 -38 70 -85 325 -487 494 -1095 472 -1695 -21 -604 -222
        -1181 -576 -1654 -493 -660 -1210 -1076 -2030 -1178 -155 -19 -563 -16 -720 5
        -657 91 -1240 379 -1705 842 -227 227 -459 555 -585 832 -160 348 -251 716
        -266 1068 l-6 140 228 73 c126 40 345 110 486 156 l257 84 17 -47 c95 -262
        224 -469 416 -666 l97 -100 -54 110 c-67 134 -102 261 -116 420 -10 111 -15
        128 -59 225 -85 184 -144 393 -157 558 -3 46 -12 89 -19 96 -7 7 -34 16 -60
        20 -26 3 -64 11 -85 16 -38 11 -39 12 -39 57 0 40 2 44 13 31 12 -16 164 -54
        175 -44 3 3 -11 22 -29 43 -19 21 -42 49 -51 62 l-17 23 -35 -21 c-19 -12 -37
        -27 -40 -34 -12 -31 -4 43 15 130 185 856 1002 1451 1870 1362z m-1904 -1671
        c-2 -13 -4 -5 -4 17 -1 22 1 32 4 23 2 -10 2 -28 0 -40z m0 -150 c-3 -7 -5 -2
        -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z"/>
        <path d="M2590 5889 c-25 -29 -64 -79 -86 -113 -41 -60 -129 -225 -122 -230 1
        -1 60 0 131 2 l127 4 0 194 c0 107 -1 194 -3 194 -1 0 -23 -23 -47 -51z"/>
        <path d="M4218 5450 c-126 -84 -313 -190 -415 -235 l-61 -27 59 -71 c114 -136
        179 -310 179 -481 l0 -91 227 0 c126 0 264 -4 309 -8 l81 -8 6 118 c8 138 36
        317 72 458 23 92 24 101 9 110 -125 74 -262 194 -329 287 l-20 27 -117 -79z"/>
        <path d="M2351 5462 c-12 -23 -51 -210 -51 -245 0 -18 -8 -28 -29 -36 -24 -10
        -30 -20 -35 -58 -3 -25 -6 -69 -6 -98 0 -45 3 -54 30 -73 27 -20 41 -22 174
        -22 l146 0 30 60 30 59 0 216 0 215 -140 0 c-125 0 -140 -2 -149 -18z"/>
        <path d="M5156 5263 c8 -76 10 -83 31 -83 45 1 47 16 9 79 -21 34 -39 61 -42
        61 -2 0 -2 -26 2 -57z"/>
        <path d="M5375 5157 c-16 -6 -32 -12 -34 -14 -2 -1 9 -33 24 -70 15 -37 41
        -106 56 -153 l29 -85 -6 60 c-5 60 -35 276 -38 274 0 -1 -14 -6 -31 -12z"/>
        <path d="M5208 5123 l-36 -4 -7 -82 c-15 -181 -71 -363 -156 -512 -39 -68 -48
        -90 -37 -96 7 -5 17 -9 22 -9 5 0 66 -23 137 -51 l127 -51 56 37 c31 20 68 51
        82 68 26 31 26 32 19 147 -8 134 -27 243 -61 352 -35 109 -81 208 -96 206 -7
        -1 -29 -3 -50 -5z"/>
        <path d="M2141 5111 c-12 -7 -10 -14 8 -36 l21 -28 0 37 c0 37 -5 42 -29 27z"/>
        <path d="M5712 5083 l-33 -4 8 -97 c44 -534 -107 -1036 -435 -1443 -78 -96
        -257 -269 -353 -341 -830 -620 -2000 -504 -2690 267 -138 154 -283 388 -358
        578 -13 31 -27 57 -31 57 -11 0 -126 -124 -178 -193 -69 -89 -137 -210 -163
        -288 -35 -108 -34 -252 2 -329 83 -176 307 -319 696 -444 378 -121 996 -216
        1558 -241 72 -3 163 -8 202 -11 l73 -6 -467 -486 c-256 -268 -485 -507 -507
        -531 l-41 -43 110 6 c165 11 315 34 460 71 152 39 251 78 247 97 -27 106 -26
        233 4 343 21 80 83 203 144 285 63 86 215 232 308 297 61 43 84 53 145 63 109
        19 182 8 362 -52 l160 -54 24 -44 c21 -38 26 -41 32 -24 4 10 8 49 8 85 1 58
        -2 70 -31 111 -37 52 -98 117 -185 200 l-60 57 56 37 c493 324 889 924 1027
        1554 23 104 27 151 28 280 1 171 -10 216 -60 239 -14 6 -26 10 -27 9 -1 0 -17
        -3 -35 -5z m-812 -2384 l35 -30 -40 3 c-35 3 -40 6 -43 31 -4 34 4 34 48 -4z"/>
        <path d="M2603 4810 c-18 -36 -33 -71 -33 -77 0 -13 58 -48 66 -40 3 2 4 44 2
        93 l-3 89 -32 -65z"/>
        <path d="M2230 4833 c0 -104 56 -351 111 -494 35 -91 42 -90 54 6 6 55 64 298
        79 336 5 11 -8 25 -46 49 -29 19 -81 56 -116 82 -35 27 -67 48 -73 48 -5 0 -9
        -12 -9 -27z"/>
        <path d="M2372 4854 c3 -3 34 -25 69 -49 l62 -45 19 41 c10 23 18 45 18 50 0
        5 -39 9 -87 9 -47 0 -84 -3 -81 -6z"/>
        <path d="M2535 4648 c-43 -131 -74 -291 -82 -425 -6 -98 -5 -103 21 -148 108
        -184 276 -369 459 -503 50 -37 183 -122 191 -122 2 0 -1 21 -6 48 -16 78 -13
        279 5 355 10 38 17 72 17 77 0 5 -38 28 -84 50 -113 53 -244 143 -338 230
        l-78 72 0 161 0 162 -50 28 c-36 19 -52 24 -55 15z"/>
        <path d="M4830 4288 l-85 -90 65 7 c165 18 377 70 349 85 -17 9 -196 76 -224
        83 -15 4 -39 -15 -105 -85z"/>
        <path d="M5351 4301 l-24 -19 21 -11 c19 -10 22 -8 28 14 8 34 3 37 -25 16z"/>
        <path d="M5213 4229 c-100 -43 -280 -80 -462 -94 l-84 -7 7 -106 c9 -131 38
        -303 76 -451 17 -63 32 -116 34 -118 2 -3 36 15 75 38 160 95 368 387 475 666
        l16 43 -48 24 c-47 23 -47 23 -89 5z"/>
        <path d="M4540 4048 c-182 -122 -459 -205 -747 -223 -62 -4 -113 -9 -113 -11
        0 -11 67 -135 126 -234 67 -114 153 -226 226 -297 l43 -42 80 10 c115 14 217
        36 325 71 91 29 240 92 240 101 0 3 -16 66 -35 140 -32 126 -71 362 -77 466
        -2 25 -7 46 -13 47 -5 1 -30 -11 -55 -28z"/>
        <path d="M2456 3950 c3 -25 14 -84 25 -132 47 -226 186 -451 331 -538 l61 -37
        56 36 c117 75 161 106 161 113 0 4 -17 16 -37 27 -176 91 -426 320 -561 514
        l-43 62 7 -45z"/>
        <path d="M3200 3886 c-22 -58 -32 -163 -27 -270 3 -61 9 -121 13 -133 6 -22 8
        -22 57 20 172 142 338 314 317 327 -7 4 -38 11 -70 14 -47 5 -183 37 -262 62
        -13 4 -21 -2 -28 -20z"/>
        <path d="M3547 3705 c-64 -70 -186 -184 -287 -267 l-44 -36 58 -26 c166 -73
        389 -125 580 -133 l130 -6 -63 74 c-81 96 -183 249 -246 372 -27 53 -52 97
        -55 97 -3 0 -36 -34 -73 -75z"/>
        <path d="M3048 3281 c-53 -36 -97 -69 -98 -73 0 -8 180 -79 257 -102 l53 -15
        -24 47 c-12 26 -36 81 -51 122 -16 41 -31 78 -34 81 -3 4 -50 -23 -103 -60z"/>
        <path d="M3235 3308 c11 -35 55 -138 81 -189 22 -44 26 -47 81 -58 122 -24
        302 -33 437 -21 111 9 313 43 323 54 2 2 -20 21 -49 41 -43 31 -61 38 -93 36
        -75 -3 -313 19 -416 40 -102 20 -288 78 -342 106 -28 14 -29 14 -22 -9z"/>
        <path d="M4528 3270 c-81 -30 -288 -80 -331 -80 -44 0 -39 -16 12 -43 l49 -25
        94 37 c119 47 306 147 261 140 -5 -1 -42 -14 -85 -29z"/>
        <path d="M4137 2442 c-17 -19 -47 -84 -47 -102 0 -6 19 2 41 17 50 33 130 38
        172 10 42 -27 32 -3 -15 37 -37 32 -90 56 -124 56 -6 0 -18 -8 -27 -18z"/>
        <path d="M4220 1949 l0 -191 114 4 c132 4 156 14 194 88 39 78 26 183 -31 238
        -43 41 -81 52 -189 52 l-88 0 0 -191z m207 95 c27 -17 47 -85 38 -132 -11 -57
        -44 -82 -110 -82 l-55 0 0 121 0 122 53 -7 c28 -4 62 -14 74 -22z"/>
        <path d="M4685 2117 c-57 -32 -86 -90 -87 -171 0 -50 5 -75 23 -108 45 -80
        153 -113 227 -68 28 17 33 18 38 5 4 -8 17 -15 30 -15 24 0 24 1 24 100 l0
        100 -80 0 -80 0 0 -30 c0 -29 2 -30 44 -30 39 0 44 -2 39 -20 -8 -31 -52 -60
        -89 -60 -68 0 -113 79 -94 164 13 57 45 86 96 86 36 0 46 -5 67 -31 20 -26 29
        -30 61 -27 l37 3 -23 44 c-14 28 -36 51 -58 63 -49 25 -125 23 -175 -5z"/>
        <path d="M5049 2117 c-23 -16 -37 -35 -44 -61 -17 -69 19 -104 150 -143 51
        -15 60 -22 63 -44 4 -34 -19 -51 -69 -51 -42 1 -79 23 -79 49 0 9 -13 13 -40
        13 -28 0 -40 -4 -40 -15 0 -30 25 -66 59 -88 46 -28 149 -30 199 -3 34 18 62
        66 62 107 0 38 -60 83 -136 104 -78 20 -94 30 -94 56 0 23 29 39 70 39 24 0
        35 -7 48 -30 14 -25 23 -30 55 -30 42 0 47 12 22 60 -21 41 -63 60 -133 60
        -45 0 -67 -5 -93 -23z"/>
        <path d="M5350 1945 l0 -185 145 0 145 0 0 35 0 35 -100 0 -100 0 0 45 0 45
        91 0 90 0 -3 33 -3 32 -87 3 -88 3 0 39 0 40 95 0 95 0 0 30 0 30 -140 0 -140
        0 0 -185z"/>
        <path d="M6022 6875 c-18 -12 -36 -25 -39 -29 -7 -6 24 -32 41 -35 8 -2 39 79
        33 85 -1 1 -17 -8 -35 -21z"/>
        <path d="M1733 6789 c-40 -25 -41 -38 -8 -69 l25 -24 30 29 c35 33 37 46 12
        68 -23 21 -20 21 -59 -4z"/>
        <path d="M1237 6292 c-15 -15 -27 -33 -27 -38 0 -12 48 -60 53 -53 2 2 15 19
        30 37 31 39 32 45 11 66 -22 23 -36 20 -67 -12z"/>
        <path d="M436 4632 c-3 -5 -6 -26 -8 -47 -3 -51 26 -75 93 -75 54 0 75 19 81
        75 3 29 2 30 -62 42 -79 15 -97 16 -104 5z"/>
        <path d="M7402 4391 c-9 -5 -12 -24 -10 -52 3 -43 4 -44 42 -47 l39 -3 -6 46
        c-4 26 -9 51 -13 56 -7 11 -35 11 -52 0z"/>
        <path d="M480 4086 c0 -51 9 -66 40 -66 23 0 29 5 34 31 12 63 9 69 -34 69
        -39 0 -40 -1 -40 -34z"/>
        <path d="M7324 3266 c-3 -9 -7 -30 -8 -47 -1 -28 3 -33 28 -40 17 -5 32 -6 35
        -3 3 2 5 26 5 52 1 44 -1 47 -27 50 -17 2 -29 -2 -33 -12z"/>
        <path d="M693 2720 c-44 -26 -55 -51 -42 -88 21 -61 112 -64 159 -5 25 33 25
        54 -1 87 -25 32 -70 35 -116 6z"/>
        <path d="M6016 1247 c-28 -23 -29 -24 -11 -50 22 -34 27 -34 66 1 24 22 30 33
        25 50 -10 29 -43 28 -80 -1z"/>
        <path d="M2057 1101 l-17 -29 34 -21 c43 -27 62 -26 77 1 10 19 8 25 -22 50
        -42 35 -51 35 -72 -1z"/>
        <path d="M2345 890 c-16 -16 -27 -31 -24 -34 2 -2 14 -8 26 -15 20 -10 22 -8
        28 30 4 22 5 42 3 45 -3 2 -17 -10 -33 -26z"/>
        <path d="M3335 618 c-5 -17 -25 -152 -22 -154 1 -1 19 -4 40 -8 52 -10 87 25
        87 86 0 24 -4 48 -8 54 -20 30 -89 45 -97 22z"/>
        <path d="M4436 539 c-8 -49 -5 -53 29 -45 l25 6 -20 40 c-11 22 -22 40 -24 40
        -2 0 -6 -19 -10 -41z"/>
        </g>
        </svg>
</>
    );
}

export default Fran;
