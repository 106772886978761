import React from 'react'

export default function Sig() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="250.000000pt" height="200.000000pt" viewBox="0 0 922.000000 768.000000"
 preserveAspectRatio="xMidYMid meet" className='md:-mt-14 mt-3'>

<g transform="translate(0.000000,768.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M1002 4666 c-83 -30 -172 -239 -172 -406 0 -167 62 -312 185 -436
135 -135 284 -192 474 -181 255 15 465 174 553 417 20 57 23 83 23 205 0 134
-2 143 -33 220 -40 99 -90 169 -132 183 l-31 11 21 -27 c110 -141 145 -238
144 -397 -1 -86 -6 -124 -23 -175 -52 -150 -159 -273 -296 -341 -105 -52 -145
-59 -173 -29 -12 13 -24 43 -28 67 -4 27 -13 47 -25 53 -30 17 -86 11 -123
-12 -40 -26 -49 -59 -14 -55 29 3 36 -28 12 -55 -58 -63 -301 78 -404 235
-138 210 -117 517 48 699 19 21 33 38 31 37 -2 0 -19 -6 -37 -13z"/>
<path d="M2530 3880 c0 -696 2 -790 15 -790 13 0 15 66 15 530 l0 530 30 0 30
0 0 260 0 260 -45 0 -45 0 0 -790z"/>
<path d="M4030 4480 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M800 4451 c-44 -62 -51 -232 -15 -371 30 -119 81 -206 175 -301 139
-140 267 -198 456 -207 141 -6 232 13 355 75 219 109 356 324 367 577 6 128
-8 203 -43 236 l-24 22 15 -48 c9 -27 18 -89 21 -139 24 -405 -338 -739 -739
-685 -266 36 -493 239 -554 496 -21 87 -21 231 -1 309 9 32 15 61 13 63 -2 2
-14 -11 -26 -27z"/>
<path d="M3270 4465 c-17 -20 -10 -246 9 -268 12 -15 48 -17 335 -17 219 0
325 3 333 11 8 8 13 8 17 0 4 -6 24 -11 45 -11 20 0 42 5 48 11 8 8 13 8 17 0
10 -15 79 -14 94 1 7 7 12 43 12 90 0 65 -2 76 -15 72 -11 -5 -15 -22 -15 -64
0 -68 -16 -90 -55 -72 -19 9 -24 20 -27 56 -2 36 -6 44 -17 37 -7 -5 -14 -25
-15 -45 -3 -49 -18 -63 -51 -48 -21 10 -25 18 -25 57 0 67 -34 55 -38 -14 -2
-19 -5 -38 -8 -42 -6 -10 -573 -12 -598 -3 -13 5 -16 27 -16 135 0 71 -4 129
-9 129 -5 0 -14 -7 -21 -15z"/>
<path d="M4493 4472 c-8 -5 -13 -48 -15 -132 l-3 -125 -40 0 -40 0 -3 102 c-2
85 -5 101 -17 96 -12 -4 -15 -26 -15 -104 l0 -99 -55 0 c-42 0 -55 -3 -55 -15
0 -13 21 -15 125 -15 113 0 125 2 135 19 11 22 14 281 3 281 -5 0 -13 -4 -20
-8z"/>
<path d="M4603 4473 c-10 -3 -13 -43 -13 -149 0 -137 1 -144 20 -144 19 0 20
7 20 150 0 83 -3 150 -7 149 -5 0 -14 -3 -20 -6z"/>
<path d="M5173 4473 c-10 -3 -13 -43 -13 -149 0 -137 1 -144 20 -144 19 0 20
7 20 150 0 83 -3 150 -7 149 -5 0 -14 -3 -20 -6z"/>
<path d="M5427 4473 c-13 -13 -8 -270 6 -281 16 -14 396 -15 413 -1 8 6 14 6
18 0 9 -14 774 -15 791 -1 9 8 15 8 20 0 8 -13 78 -13 92 1 8 8 13 8 17 0 10
-15 79 -14 94 1 7 7 12 43 12 90 0 65 -2 76 -15 72 -11 -5 -15 -22 -15 -64 0
-69 -16 -90 -55 -72 -21 10 -25 18 -25 56 0 36 -3 44 -15 40 -10 -4 -15 -20
-15 -44 0 -48 -21 -67 -55 -52 -21 10 -25 18 -25 57 0 70 -36 53 -42 -20 l-3
-40 -270 -3 c-148 -1 -285 0 -302 3 l-33 6 0 71 c0 60 -2 69 -16 64 -9 -3 -18
-6 -20 -6 -2 0 -4 -29 -4 -64 0 -73 -11 -84 -76 -71 -30 5 -34 10 -34 36 0 41
-24 95 -48 108 -44 24 -132 5 -132 -27 0 -20 19 -23 45 -6 32 21 71 7 85 -32
25 -64 2 -84 -93 -84 -117 0 -117 0 -117 82 0 60 -2 69 -16 64 -9 -3 -18 -6
-20 -6 -2 0 -4 -29 -4 -64 0 -70 -5 -76 -64 -76 -52 0 -56 12 -56 147 0 112
-5 135 -23 116z"/>
<path d="M6997 4473 c-4 -3 -7 -71 -7 -150 0 -120 2 -143 15 -143 13 0 15 23
15 150 0 137 -4 163 -23 143z"/>
<path d="M7363 4473 c-18 -7 -18 -264 -1 -281 9 -9 93 -12 315 -12 l303 0 0
-29 c0 -53 58 -83 116 -61 35 13 44 26 44 62 l0 27 132 3 133 3 13 28 c18 38
6 104 -24 134 -37 37 -144 26 -144 -15 0 -20 19 -23 45 -6 32 21 71 7 85 -32
27 -71 6 -84 -135 -84 l-114 0 11 30 c14 41 2 96 -25 115 -12 8 -38 15 -58 15
-53 0 -79 -35 -79 -106 l0 -54 -292 2 -293 3 -3 133 c-1 72 -6 132 -10 131 -4
0 -13 -3 -19 -6z m729 -150 c42 -38 7 -113 -53 -113 l-32 0 5 52 c7 70 41 96
80 61z m7 -161 c13 -25 -4 -42 -41 -42 -17 0 -31 7 -38 19 -17 33 -12 41 30
41 28 0 42 -5 49 -18z"/>
<path d="M2910 4430 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M2970 4430 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M4010 4430 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M4060 4430 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M5750 4430 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M5950 4430 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M6000 4430 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M7220 4430 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M4870 4400 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M4930 4400 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M2908 4359 c-22 -12 -41 -80 -32 -116 11 -47 32 -63 84 -63 53 0 74
16 85 67 8 40 -10 100 -35 113 -24 13 -80 12 -102 -1z m86 -41 c31 -45 11
-108 -34 -108 -47 0 -67 73 -30 110 26 26 45 25 64 -2z"/>
<path d="M3158 4349 c-15 -9 -18 -25 -18 -114 0 -103 0 -105 -25 -111 -14 -3
-25 -12 -25 -20 0 -20 43 -18 69 4 20 16 22 25 19 134 -3 108 -4 116 -20 107z"/>
<path d="M5063 4353 c-9 -3 -13 -25 -13 -63 0 -37 -5 -62 -14 -69 -9 -8 -51
-11 -122 -9 l-109 3 -3 58 c-2 48 -6 58 -18 53 -9 -3 -18 -6 -20 -6 -10 0 -3
-117 8 -128 18 -18 282 -17 297 2 17 20 11 164 -6 159z"/>
<path d="M5318 4349 c-15 -9 -18 -25 -18 -114 0 -103 0 -105 -25 -111 -35 -9
-32 -34 3 -34 54 0 63 24 60 153 -3 107 -4 115 -20 106z"/>
<path d="M7233 4353 c-9 -3 -13 -35 -13 -109 0 -102 -1 -105 -25 -116 -31 -14
-33 -38 -2 -38 53 1 67 35 67 164 0 58 -3 106 -7 105 -5 0 -14 -3 -20 -6z"/>
<path d="M710 4212 c-34 -107 47 -318 179 -463 289 -321 794 -334 1100 -28
104 105 174 233 208 381 13 60 13 72 1 105 l-15 36 -12 -77 c-45 -305 -264
-544 -566 -617 -85 -20 -249 -16 -338 9 -293 83 -503 327 -535 622 l-8 75 -14
-43z"/>
<path d="M4358 4138 c-17 -13 -29 -77 -15 -78 4 0 24 1 45 1 36 1 36 2 7 8
-24 5 -30 12 -30 31 0 21 4 25 28 23 29 -2 37 11 11 21 -22 8 -28 8 -46 -6z"/>
<path d="M5570 4120 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M8310 4120 c0 -13 7 -20 20 -20 13 0 20 7 20 20 0 13 -7 20 -20 20
-13 0 -20 -7 -20 -20z"/>
<path d="M675 4003 c-6 -31 0 -49 35 -119 80 -158 216 -292 375 -369 300 -147
655 -103 903 110 123 105 231 266 240 355 2 28 1 50 -4 50 -5 0 -19 -29 -32
-64 -56 -155 -208 -324 -369 -409 -203 -106 -414 -122 -630 -48 -217 75 -394
242 -479 454 l-32 78 -7 -38z"/>
<path d="M2928 3944 c-22 -11 -28 -22 -28 -47 0 -45 10 -55 66 -75 51 -19 66
-41 44 -67 -16 -20 -50 -19 -79 1 -15 11 -26 13 -33 6 -15 -15 -1 -32 39 -49
27 -11 42 -12 69 -3 84 28 67 125 -27 145 -34 8 -54 36 -37 53 14 14 60 16 74
2 6 -6 15 -6 24 1 13 8 11 12 -9 29 -27 24 -65 25 -103 4z"/>
<path d="M3273 3945 c-42 -18 -66 -57 -67 -109 -2 -81 44 -128 124 -128 75 0
90 13 90 78 l0 55 -42 -3 c-31 -2 -44 -8 -46 -20 -3 -14 3 -18 27 -18 24 0 31
-4 31 -20 0 -22 -28 -40 -63 -40 -32 0 -73 33 -81 66 -9 34 10 91 34 104 27
14 73 12 95 -4 20 -14 45 -11 45 5 0 15 -63 49 -90 49 -14 -1 -39 -7 -57 -15z"/>
<path d="M4208 3940 c-37 -29 -38 -71 -2 -99 15 -12 35 -21 44 -21 10 0 28 -8
40 -18 45 -37 -17 -81 -71 -50 -15 9 -32 12 -40 8 -32 -21 54 -65 103 -53 40
10 58 33 58 74 0 43 -16 57 -83 79 -31 10 -38 16 -35 33 3 25 45 35 76 18 27
-14 50 10 28 29 -29 23 -89 24 -118 0z"/>
<path d="M5813 3941 c-81 -51 -82 -179 -2 -222 29 -15 90 -15 129 1 28 12 30
16 30 66 l0 54 -45 0 c-38 0 -45 -3 -45 -20 0 -15 7 -20 25 -20 20 0 25 -5 25
-24 0 -57 -106 -44 -130 15 -16 38 -5 95 23 114 28 20 76 19 99 -2 14 -13 21
-14 30 -5 19 19 -21 53 -68 58 -28 4 -48 -1 -71 -15z"/>
<path d="M6554 3944 c-41 -20 -64 -63 -64 -117 0 -100 111 -157 190 -97 24 18
24 20 8 37 -10 9 -18 13 -18 7 0 -13 -44 -34 -71 -34 -34 0 -69 44 -69 88 0
77 68 118 128 76 22 -15 42 -12 42 7 0 15 -63 49 -90 49 -14 0 -39 -7 -56 -16z"/>
<path d="M7310 3938 c-100 -68 -62 -226 56 -230 22 -1 54 4 72 11 30 13 32 16
32 67 l0 54 -45 0 c-38 0 -45 -3 -45 -20 0 -15 7 -20 25 -20 20 0 25 -5 25
-24 0 -45 -68 -50 -111 -7 -32 33 -37 67 -14 110 22 44 82 56 117 24 14 -13
21 -14 30 -5 19 19 -21 52 -68 59 -30 4 -47 -1 -74 -19z"/>
<path d="M8053 3941 c-90 -56 -78 -195 20 -228 42 -14 95 -3 121 26 25 28 2
37 -38 16 -70 -38 -142 21 -122 100 15 59 87 86 128 48 14 -13 21 -14 30 -5
19 19 -21 53 -68 58 -28 4 -48 -1 -71 -15z"/>
<path d="M3119 3947 c-1 -1 -3 -55 -4 -119 -2 -109 -1 -118 16 -118 17 0 19
10 19 114 0 92 -3 115 -15 120 -8 3 -16 4 -16 3z"/>
<path d="M3480 3830 c0 -117 1 -120 20 -110 16 9 20 23 22 87 l3 76 59 -86
c38 -56 66 -87 78 -87 16 0 18 11 18 120 0 117 -1 120 -20 110 -16 -8 -20 -23
-22 -83 l-3 -72 -58 83 c-39 54 -65 82 -78 82 -18 0 -19 -8 -19 -120z"/>
<path d="M3803 3933 c-3 -10 -23 -62 -45 -116 -21 -53 -38 -100 -38 -102 0 -3
9 -5 19 -5 13 0 23 11 31 35 11 33 14 35 59 35 45 0 48 -2 60 -35 8 -24 19
-35 32 -35 10 0 19 4 19 8 0 4 -20 57 -44 117 -35 87 -48 111 -65 113 -13 2
-23 -3 -28 -15z m45 -76 c16 -46 16 -47 -18 -47 -33 0 -35 3 -19 49 14 40 22
39 37 -2z"/>
<path d="M3980 3830 l0 -120 80 0 c78 0 80 1 70 20 -8 16 -21 20 -60 20 l-50
0 0 88 c0 82 -8 112 -32 112 -4 0 -8 -54 -8 -120z"/>
<path d="M4460 3830 c0 -113 1 -120 20 -120 19 0 20 7 20 114 0 95 -3 115 -16
120 -9 3 -18 6 -20 6 -2 0 -4 -54 -4 -120z"/>
<path d="M4560 3829 c0 -105 2 -120 15 -109 10 8 15 37 17 90 l3 79 63 -90
c42 -59 70 -89 83 -89 18 0 19 8 19 120 0 66 -3 120 -7 120 -24 -1 -32 -22
-35 -94 l-3 -79 -59 86 c-38 56 -66 87 -78 87 -16 0 -18 -11 -18 -121z"/>
<path d="M4810 3935 c7 -8 28 -15 46 -15 l34 0 0 -105 c0 -87 3 -105 15 -105
12 0 15 18 15 105 l0 105 34 0 c18 0 39 7 46 15 11 13 -1 15 -95 15 -94 0
-106 -2 -95 -15z"/>
<path d="M5050 3830 l0 -120 80 0 c78 0 80 1 70 20 -8 16 -21 20 -60 20 l-50
0 0 35 c0 34 1 35 40 35 29 0 40 4 40 15 0 11 -11 15 -40 15 -39 0 -40 1 -40
35 l0 35 49 0 c34 0 51 4 55 15 4 13 -7 15 -69 15 l-75 0 0 -120z"/>
<path d="M5250 3830 l0 -120 85 0 c83 0 85 1 73 20 -10 16 -24 20 -65 20 l-53
0 0 94 c0 91 -5 106 -36 106 -2 0 -4 -54 -4 -120z"/>
<path d="M5460 3830 l0 -120 80 0 c78 0 80 1 70 20 -8 16 -21 20 -60 20 l-50
0 0 90 c0 79 -2 91 -20 100 -19 10 -20 8 -20 -110z"/>
<path d="M5660 3830 c0 -113 1 -120 20 -120 19 0 20 7 20 114 0 95 -3 115 -16
120 -9 3 -18 6 -20 6 -2 0 -4 -54 -4 -120z"/>
<path d="M6020 3830 l0 -120 80 0 c44 0 80 4 80 8 0 20 -28 32 -72 32 l-48 0
0 35 c0 35 0 35 45 35 33 0 45 4 45 15 0 11 -12 15 -45 15 -45 0 -45 0 -45 34
0 35 0 35 52 38 30 2 53 8 56 16 3 9 -16 12 -72 12 l-76 0 0 -120z"/>
<path d="M6230 3830 c0 -117 1 -120 20 -110 16 9 20 23 22 88 l3 77 60 -87
c85 -124 95 -121 95 32 0 101 -2 118 -15 114 -10 -4 -15 -27 -17 -87 l-3 -82
-60 87 c-45 65 -66 87 -82 88 -23 0 -23 -2 -23 -120z"/>
<path d="M6740 3830 l0 -120 81 0 c70 0 80 2 75 16 -3 9 -6 18 -6 20 0 2 -25
4 -55 4 l-55 0 0 35 c0 35 0 35 45 35 33 0 45 4 45 15 0 11 -12 15 -45 15 -45
0 -45 0 -45 34 0 35 0 35 52 38 30 2 53 8 56 16 3 9 -16 12 -72 12 l-76 0 0
-120z"/>
<path d="M7083 3933 c-3 -10 -23 -62 -45 -116 -21 -53 -38 -100 -38 -102 0 -3
9 -5 19 -5 13 0 23 11 31 35 11 33 14 35 59 35 45 0 48 -2 60 -35 8 -24 19
-35 32 -35 24 0 25 -4 -30 136 -30 78 -43 100 -60 102 -13 2 -23 -3 -28 -15z
m36 -55 c25 -66 24 -68 -8 -68 -35 0 -35 -1 -19 51 13 45 16 47 27 17z"/>
<path d="M7530 3830 l0 -120 80 0 c78 0 80 1 70 20 -8 16 -21 20 -60 20 l-50
0 0 35 c0 34 1 35 40 35 29 0 40 4 40 15 0 11 -11 15 -40 15 -39 0 -40 1 -40
35 0 35 0 35 44 35 26 0 48 6 56 15 11 13 3 15 -64 15 l-76 0 0 -120z"/>
<path d="M7740 3829 c0 -105 2 -120 15 -109 10 8 15 37 17 92 l3 80 63 -91
c43 -62 69 -91 82 -91 19 0 20 7 20 120 0 66 -3 120 -7 120 -24 -1 -32 -22
-35 -94 l-3 -78 -59 86 c-38 56 -66 86 -78 86 -16 0 -18 -10 -18 -121z"/>
<path d="M8230 3944 c0 -3 18 -35 40 -71 32 -53 40 -76 40 -115 0 -36 4 -48
15 -48 11 0 15 12 15 51 0 43 7 62 40 115 22 35 40 65 40 68 0 2 -9 2 -21 -1
-11 -3 -32 -24 -47 -46 l-26 -39 -16 23 c-30 48 -80 87 -80 63z"/>
<path d="M680 3826 c0 -97 210 -303 394 -386 140 -64 197 -75 376 -75 187 0
239 11 378 76 105 48 191 109 270 191 83 84 122 144 122 187 0 34 0 34 -41
-29 -115 -179 -304 -315 -514 -371 -116 -31 -308 -31 -425 0 -221 59 -410 198
-522 383 l-38 62 0 -38z"/>
<path d="M2880 3580 c0 -7 932 -10 2775 -10 1843 0 2775 3 2775 10 0 7 -932
10 -2775 10 -1843 0 -2775 -3 -2775 -10z"/>
<path d="M985 3427 c10 -31 27 -42 124 -77 137 -50 225 -63 386 -57 149 6 211
18 333 69 54 22 76 38 88 59 l15 30 -51 -26 c-87 -44 -224 -85 -330 -97 -166
-19 -335 9 -488 82 -78 37 -83 38 -77 17z"/>
<path d="M1222 3261 c17 -27 25 -30 139 -40 128 -12 287 3 313 29 26 26 19 31
-29 20 -105 -24 -328 -21 -418 6 -16 5 -17 3 -5 -15z"/>
<path d="M1350 3177 c0 -6 98 -77 106 -77 5 0 29 18 54 40 l45 39 -102 1 c-57
0 -103 -1 -103 -3z"/>
</g>
</svg>
    </>
  )
}
