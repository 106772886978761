import React from 'react'

export default function Escudo() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 800.000000 800.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,800.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M3740 7914 c-19 -2 -87 -9 -150 -15 -849 -79 -1685 -470 -2319 -1084
-662 -641 -1070 -1478 -1173 -2410 -20 -183 -17 -674 6 -850 132 -1039 605
-1910 1396 -2571 587 -491 1313 -795 2103 -880 280 -31 665 -22 967 22 1513
219 2771 1321 3195 2797 266 930 177 1934 -249 2804 -257 523 -622 976 -1089
1350 -127 103 -406 287 -563 372 -419 228 -876 375 -1374 441 -117 16 -224 22
-435 25 -154 2 -296 1 -315 -1z m1210 -290 c39 -35 41 -36 59 -19 13 11 27 14
40 10 30 -9 27 -49 -6 -75 -37 -29 -86 -21 -128 20 -34 33 -50 37 -59 15 -7
-19 -26 -19 -50 -2 -17 13 -17 16 -2 44 18 35 28 41 73 42 24 1 43 -9 73 -35z
m-708 14 c18 -15 152 -474 144 -494 -8 -20 -53 -17 -75 5 -10 10 -22 36 -25
58 -4 21 -10 43 -14 49 -4 6 -47 14 -95 17 l-88 5 -26 -59 c-25 -57 -27 -59
-64 -59 -29 0 -39 4 -39 16 0 21 190 457 204 466 17 12 61 9 78 -4z m-528 -23
c66 -32 96 -136 60 -211 -20 -42 -82 -80 -147 -89 -37 -6 -47 -11 -43 -24 3
-9 8 -47 12 -85 5 -53 3 -70 -7 -77 -20 -13 -57 -11 -66 4 -14 22 -61 451 -52
469 15 28 191 38 243 13z m1080 -59 c24 -10 37 -24 45 -48 10 -32 80 -327 82
-344 0 -5 22 65 48 155 26 91 52 166 58 168 18 6 63 -16 63 -31 0 -18 -116
-431 -125 -446 -11 -18 -55 -11 -79 13 -17 17 -32 62 -59 177 -20 85 -40 170
-43 188 -4 18 -8 31 -10 29 -2 -2 -22 -68 -44 -148 -51 -182 -54 -189 -84
-189 -52 0 -51 15 14 247 34 119 66 223 72 230 13 16 21 16 62 -1z m-1666 -42
c16 -11 22 -24 20 -42 -3 -26 -5 -27 -51 -24 -62 5 -105 -13 -114 -46 -9 -39
31 -67 116 -82 114 -19 161 -62 161 -149 0 -79 -62 -142 -158 -157 -67 -11
-108 2 -122 37 -7 16 -9 33 -6 37 4 4 41 7 81 7 64 0 77 3 99 24 14 14 26 33
26 43 0 34 -43 65 -101 73 -78 10 -135 38 -159 77 -24 40 -26 100 -4 138 38
64 160 101 212 64z m-519 -186 c5 -13 7 -28 4 -34 -2 -6 -40 -28 -83 -49 -44
-22 -80 -40 -80 -42 0 -1 12 -27 27 -57 l26 -54 73 36 c62 31 75 34 89 22 31
-25 14 -53 -54 -88 -36 -18 -66 -34 -68 -36 -2 -2 11 -33 28 -69 l33 -65 78
39 c42 21 85 39 93 39 14 0 43 -45 34 -54 -19 -17 -234 -116 -245 -112 -15 6
-214 397 -214 421 0 16 201 124 233 124 10 1 21 -9 26 -21z m2981 -73 c20 -10
30 -23 30 -38 -1 -44 -66 -486 -73 -493 -4 -4 -23 0 -43 10 -36 17 -36 17 -29
64 3 26 8 56 11 67 4 17 -10 27 -78 62 l-82 42 -48 -45 c-47 -44 -48 -44 -78
-29 -16 9 -30 22 -30 29 0 17 359 346 378 346 7 0 26 -7 42 -15z m-1395 -375
c728 -54 1361 -349 1871 -872 440 -452 704 -999 796 -1648 8 -61 13 -191 12
-365 0 -229 -3 -292 -22 -414 -29 -184 -87 -411 -147 -573 -64 -174 -201 -452
-217 -442 -7 5 -38 27 -68 50 -58 44 -297 202 -340 224 -24 13 -24 13 -8 -11
130 -183 194 -317 232 -480 12 -49 12 -59 -3 -82 -8 -15 -18 -27 -22 -27 -3 0
-21 39 -39 87 -18 49 -89 199 -157 334 l-123 247 -88 42 c-204 99 -574 242
-588 227 -3 -3 23 -43 58 -89 120 -157 254 -406 333 -622 40 -108 115 -391
115 -432 0 -42 17 -61 90 -104 39 -23 70 -45 70 -50 0 -14 -6 -13 -51 10 -22
12 -51 25 -65 30 l-26 10 7 -84 c7 -78 6 -85 -13 -95 -18 -10 -21 -4 -42 103
l-22 114 -122 47 c-149 59 -290 107 -421 144 -159 45 -148 45 -142 8 38 -218
48 -292 58 -421 17 -218 3 -444 -25 -415 -2 2 -9 72 -16 154 -22 293 -93 707
-125 731 -42 31 -444 84 -758 100 l-137 6 2 -418 3 -419 50 -2 c296 -14 616
-68 865 -146 72 -23 74 -24 46 -33 -22 -8 -60 -4 -150 13 -215 41 -606 83
-780 83 l-39 0 6 -140 7 -140 -50 0 -50 0 0 140 0 140 -72 0 c-215 0 -553 -33
-771 -76 -117 -23 -133 -24 -165 -11 -36 14 -36 15 -34 68 8 192 40 465 78
661 14 76 24 144 21 152 -10 25 -357 -72 -597 -166 l-85 -34 -45 -141 c-41
-130 -46 -140 -63 -129 -16 13 -16 18 3 116 12 56 19 104 17 106 -2 2 -45 -15
-96 -37 -50 -23 -94 -39 -98 -36 -9 10 -7 11 107 75 l104 57 47 165 c94 329
241 665 414 943 25 39 41 73 37 75 -26 8 -334 -107 -548 -205 l-142 -64 -35
-107 c-43 -132 -131 -333 -205 -469 -92 -167 -76 -163 -164 -42 -630 866 -731
2004 -263 2964 515 1057 1626 1700 2790 1615z m-3468 -3025 c90 -27 152 -120
140 -210 -9 -60 -27 -95 -53 -101 -38 -10 -44 3 -24 49 25 54 25 94 0 134 -28
47 -73 67 -150 66 -161 -1 -231 -93 -165 -217 27 -50 26 -66 -4 -66 -65 0
-105 164 -61 250 46 91 187 133 317 95z m6893 -40 c0 -36 2 -35 -102 -60 -25
-6 -27 -11 -33 -83 -4 -43 -9 -84 -11 -93 -3 -12 12 -23 56 -43 50 -23 60 -31
60 -52 0 -13 -7 -27 -15 -30 -15 -6 -409 177 -434 201 -11 11 -12 22 -5 46 l9
33 225 57 c124 31 231 58 238 58 7 1 12 -12 12 -34z m-6743 -307 c13 -17 54
-228 47 -239 -3 -5 -16 -9 -28 -9 -22 0 -25 8 -41 91 l-18 91 -31 -6 c-17 -4
-48 -9 -68 -13 -32 -5 -37 -9 -33 -27 33 -145 33 -148 -2 -144 -26 3 -29 8
-43 78 l-15 76 -75 -15 c-41 -7 -76 -15 -78 -16 -1 -1 4 -34 13 -74 22 -104
20 -121 -10 -121 -28 0 -26 -4 -54 140 -11 57 -15 99 -9 105 6 7 287 69 429
94 4 0 11 -5 16 -11z m6462 -88 c118 -22 217 -40 219 -40 2 0 2 -15 0 -32 -2
-26 -7 -33 -23 -31 -65 5 -435 79 -438 88 -6 13 10 55 20 55 4 0 105 -18 222
-40z m-164 -90 c4 -6 -9 -28 -28 -51 -19 -22 -38 -56 -41 -74 -8 -38 11 -91
40 -117 60 -53 190 -76 251 -44 54 27 75 105 52 196 -3 11 3 14 21 12 34 -4
40 -12 46 -68 17 -142 -83 -246 -221 -230 -183 21 -284 137 -244 279 20 72
101 135 124 97z m-6225 -139 c22 -42 10 -62 -129 -210 l-140 -148 171 55 c139
45 172 52 179 41 26 -40 10 -49 -196 -115 -110 -35 -208 -64 -218 -64 -23 0
-43 44 -31 69 5 11 72 86 149 167 92 97 131 144 115 139 -353 -113 -355 -114
-348 -60 3 16 38 30 198 82 107 34 205 62 217 63 12 0 27 -9 33 -19z m6264
-287 c99 -41 187 -80 194 -88 16 -16 -1 -61 -27 -71 -9 -4 -86 -15 -171 -26
-85 -10 -175 -22 -200 -25 l-45 -6 170 -68 c132 -53 171 -72 173 -87 2 -11 -3
-25 -11 -31 -11 -10 -56 5 -213 69 -109 44 -204 85 -211 91 -13 10 0 60 21 77
11 10 313 51 378 51 l33 1 -25 13 c-14 7 -89 39 -167 71 -79 32 -143 62 -143
67 0 5 5 18 11 29 13 24 2 27 233 -67z m-6091 -189 c37 -82 65 -155 63 -162
-3 -7 -15 -13 -26 -13 -16 0 -28 15 -49 59 l-28 60 -184 -84 c-165 -75 -185
-82 -196 -67 -29 37 -17 47 163 127 97 44 178 84 181 91 2 7 -8 36 -22 65 -26
50 -26 53 -9 66 10 7 23 12 29 10 5 -2 41 -70 78 -152z m5742 -109 c17 -12 16
-17 -23 -90 -23 -43 -42 -81 -42 -84 0 -4 26 -22 58 -39 l57 -32 38 70 c38 69
55 81 76 55 9 -11 4 -28 -24 -78 -19 -36 -35 -67 -35 -70 0 -4 30 -23 67 -43
52 -28 68 -33 75 -23 5 7 25 43 44 81 26 47 42 67 56 67 10 0 21 -7 24 -15 7
-17 -102 -225 -119 -225 -18 0 -389 204 -394 217 -5 13 104 223 116 223 4 0
16 -6 26 -14z m-5611 -149 c52 -91 59 -150 21 -195 -36 -43 -90 -59 -137 -39
l-35 15 -21 -42 c-29 -57 -111 -158 -125 -153 -7 2 -16 15 -21 28 -7 22 -1 36
48 98 61 80 71 121 39 160 l-18 20 -85 -50 -84 -51 -19 23 c-10 12 -16 26 -15
31 6 16 373 227 393 225 13 -1 33 -25 59 -70z m5490 -156 c16 -18 15 -20 -25
-42 -133 -75 -143 -215 -21 -302 34 -25 55 -32 105 -35 57 -4 66 -1 95 23 36
30 76 100 67 115 -8 13 -91 70 -102 70 -5 0 -19 -18 -33 -40 -25 -41 -49 -50
-68 -27 -9 10 0 29 35 80 26 37 54 67 62 67 22 0 170 -102 176 -121 8 -25 -30
-119 -66 -162 -89 -108 -239 -107 -349 3 -51 51 -70 94 -70 160 0 84 38 152
112 203 48 32 59 33 82 8z m-5369 -112 c22 -6 55 -22 73 -36 45 -34 92 -119
92 -167 0 -81 -67 -169 -170 -220 -102 -52 -169 -41 -246 38 -92 94 -94 184
-7 277 53 57 97 86 156 105 53 16 54 16 102 3z m5239 -344 c91 -77 166 -145
166 -151 0 -6 -9 -19 -20 -29 -19 -17 -24 -13 -182 120 -90 76 -169 143 -176
150 -19 17 2 57 28 53 10 -2 93 -66 184 -143z m-4847 -67 c13 -12 23 -33 23
-47 0 -14 -20 -105 -45 -201 -24 -96 -43 -175 -42 -177 1 -1 56 57 121 128
112 123 120 129 140 116 11 -8 21 -20 21 -26 0 -21 -295 -331 -315 -331 -11 0
-27 11 -36 25 -15 23 -13 35 34 230 l49 206 -71 -78 c-171 -188 -180 -197
-205 -184 -12 7 -21 18 -19 26 4 19 297 335 311 335 6 0 21 -10 34 -22z m4628
-128 l140 -140 60 60 c63 64 79 71 94 41 9 -15 -2 -32 -65 -100 -42 -44 -81
-81 -87 -81 -7 0 -86 71 -177 159 l-165 158 19 22 c11 11 24 21 30 21 6 0 74
-63 151 -140z m-214 34 c12 -15 5 -25 -58 -83 l-72 -66 44 -50 44 -50 25 20
c13 11 41 33 61 49 28 23 41 27 53 20 28 -18 23 -29 -38 -79 -33 -28 -60 -52
-60 -55 0 -3 24 -31 53 -63 l52 -57 69 62 c55 50 71 60 85 51 9 -6 16 -17 16
-25 0 -18 -174 -170 -189 -165 -19 6 -296 332 -293 345 3 15 168 161 183 162
6 0 18 -7 25 -16z m-217 -183 c19 -21 24 -14 -76 -91 -19 -15 -17 -21 95 -180
113 -161 115 -165 97 -184 -10 -10 -24 -16 -32 -14 -7 3 -63 76 -123 162 -60
86 -113 156 -118 156 -5 0 -31 -16 -58 -36 -41 -30 -52 -34 -65 -23 -8 6 -14
19 -12 27 3 13 251 199 269 201 3 1 14 -8 23 -18z m-3790 -63 l25 -23 -66
-230 c-36 -126 -67 -232 -69 -234 -2 -2 -15 1 -29 7 -29 14 -32 40 -10 102 8
24 13 45 11 48 -3 2 -38 28 -77 57 l-73 52 -47 -33 c-53 -39 -69 -41 -93 -15
-16 17 -8 24 181 154 109 76 203 137 210 137 7 0 23 -10 37 -22z m3588 -318
c55 -102 102 -190 105 -196 7 -18 -26 -44 -54 -44 -16 0 -89 40 -187 103 -88
56 -166 106 -174 110 -7 5 5 -24 27 -65 22 -40 62 -113 90 -163 49 -89 49 -90
29 -107 -11 -10 -24 -18 -28 -18 -9 0 -220 384 -220 400 0 16 61 49 79 42 13
-5 205 -125 315 -197 l28 -19 -30 55 c-138 250 -143 261 -131 275 29 35 50 11
151 -176z m-3276 126 c82 -38 127 -114 88 -147 -14 -11 -18 -10 -27 6 -6 11
-27 37 -47 58 -32 32 -44 37 -82 37 -80 0 -151 -72 -180 -183 -11 -43 -11 -52
7 -84 28 -52 63 -73 128 -80 63 -6 73 -22 33 -48 -60 -40 -203 32 -235 118
-41 107 53 291 170 333 52 19 89 16 145 -10z m214 -111 c10 -12 -4 -51 -81
-220 -95 -208 -104 -221 -142 -192 -15 11 -9 29 69 197 47 102 90 195 95 208
10 25 41 29 59 7z m2664 -230 c86 -199 89 -208 70 -222 -10 -7 -25 -12 -32
-11 -10 2 -192 404 -192 424 0 7 44 23 55 20 5 -1 50 -96 99 -211z m-2379 121
c109 -51 138 -130 97 -264 -42 -132 -123 -200 -227 -189 -90 9 -175 68 -191
134 -11 42 2 147 25 199 59 132 172 179 296 120z m2036 -27 c15 -29 5 -36 -82
-60 -41 -12 -78 -24 -82 -29 -7 -6 18 -116 28 -128 2 -2 37 7 79 19 42 12 78
20 80 18 2 -2 7 -14 10 -26 6 -20 1 -24 -62 -42 -38 -12 -75 -21 -82 -21 -7 0
-10 -6 -7 -13 3 -7 13 -42 23 -78 9 -35 18 -65 19 -66 1 -2 39 9 84 23 91 28
114 26 109 -8 -3 -19 -19 -27 -118 -55 -63 -18 -118 -29 -122 -25 -4 4 -35
102 -68 216 -45 152 -58 212 -50 220 8 9 194 71 222 75 4 1 12 -8 19 -20z
m-1758 -71 c14 -15 41 -85 77 -205 l55 -183 35 168 c19 92 38 172 43 179 7 11
44 7 52 -7 6 -9 -71 -393 -85 -427 -6 -14 -18 -23 -30 -23 -42 0 -64 41 -120
227 -30 101 -57 183 -60 183 -3 0 -21 -78 -41 -172 -32 -161 -37 -173 -58
-176 -13 -2 -27 0 -32 5 -9 9 62 383 81 431 6 15 18 22 35 22 14 0 36 -10 48
-22z m1414 -60 c58 -38 87 -102 91 -203 4 -105 -19 -154 -94 -197 -49 -27
-205 -60 -219 -46 -8 8 -76 410 -73 433 4 26 85 45 184 42 58 -2 78 -7 111
-29z m-901 -6 c18 -11 138 -462 126 -474 -6 -6 -22 -8 -36 -4 -19 5 -28 16
-36 49 -6 23 -13 50 -16 59 -5 17 -21 21 -140 32 l-52 5 -26 -60 c-23 -53 -28
-59 -56 -59 -20 0 -30 5 -30 15 0 15 177 411 193 433 10 13 55 16 73 4z m264
-207 l5 -200 88 -3 87 -3 0 -29 0 -30 -118 0 -119 0 -6 98 c-9 130 -9 357 0
366 4 4 19 6 33 4 l25 -3 5 -200z"/>
<path d="M4156 7444 c-20 -48 -35 -88 -34 -90 2 -1 32 -6 67 -10 55 -6 63 -5
59 8 -4 8 -16 52 -28 96 -12 45 -23 82 -25 82 -2 0 -19 -39 -39 -86z"/>
<path d="M3575 7553 c-19 -4 -20 -8 -11 -88 l9 -83 36 5 c60 8 86 33 89 85 3
40 -1 49 -24 67 -25 20 -52 24 -99 14z"/>
<path d="M5454 7091 l-71 -67 58 -32 58 -32 6 32 c13 80 24 162 22 164 -1 1
-34 -28 -73 -65z"/>
<path d="M3990 6585 c0 -8 -7 -15 -15 -15 -8 0 -15 -4 -15 -10 0 -5 7 -10 15
-10 22 0 18 -33 -6 -54 -15 -12 -19 -24 -14 -39 13 -41 11 -62 -5 -58 -43 10
-142 16 -147 8 -3 -5 -14 -7 -24 -4 -11 3 -17 1 -13 -4 3 -5 -2 -9 -10 -9 -9
0 -16 -4 -16 -10 0 -5 -7 -10 -15 -10 -8 0 -15 -3 -15 -7 0 -5 -4 -14 -9 -22
-8 -12 -11 -12 -25 1 -8 9 -13 18 -11 20 3 3 -5 4 -18 4 -12 -1 -24 -4 -27 -8
-3 -4 -27 -9 -52 -10 -25 -2 -44 -6 -42 -10 2 -4 -4 -9 -14 -12 -23 -8 -87
-54 -87 -64 0 -4 -2 -6 -5 -5 -12 4 4 -103 18 -125 11 -17 12 -26 3 -35 -17
-17 -14 -25 11 -29 12 -2 28 -14 34 -26 7 -13 25 -32 40 -42 16 -9 42 -29 57
-44 15 -14 31 -26 35 -26 11 0 34 -58 28 -69 -8 -13 9 -23 69 -37 46 -11 37
-12 -112 -13 l-163 -1 0 -186 0 -186 -22 6 c-13 4 -54 9 -91 13 l-68 6 2 124
c1 89 6 128 16 138 7 8 13 27 13 44 0 25 -4 30 -27 33 l-26 3 26 31 c15 18 27
38 27 46 0 23 -31 45 -72 52 -29 5 -38 12 -38 26 0 11 -7 20 -15 20 -8 0 -15
-9 -15 -19 0 -15 -10 -21 -40 -26 -22 -4 -50 -15 -62 -25 -24 -19 -26 -12 28
-87 16 -22 16 -23 -10 -23 -22 0 -26 -4 -26 -31 0 -17 6 -37 14 -44 10 -10 14
-50 15 -152 l1 -138 -60 -28 c-32 -15 -62 -36 -65 -47 -8 -25 32 -117 59 -135
11 -8 31 -15 43 -15 21 0 23 -4 23 -52 l0 -53 -50 -22 c-28 -12 -53 -29 -56
-38 -12 -30 7 -100 36 -134 l29 -34 10 26 c8 19 7 35 -1 53 -10 21 -9 27 3 35
8 5 17 9 21 9 4 0 9 -55 11 -122 l3 -123 2 126 2 125 52 20 c29 10 56 20 60
22 4 2 9 -63 11 -145 l3 -148 4 155 4 155 6 -155 6 -155 2 157 c3 213 21 217
25 6 l3 -163 2 166 2 166 40 28 c42 29 47 47 34 114 -5 23 -14 33 -40 43 -30
10 -34 16 -34 46 l0 34 58 -6 c31 -4 71 -11 88 -17 l30 -9 0 -288 -1 -287 28
-57 c33 -67 70 -101 152 -139 55 -25 65 -26 131 -19 40 5 90 18 113 29 53 28
109 84 131 134 l18 40 22 -42 c20 -39 20 -42 3 -41 -36 4 -64 0 -59 -9 3 -5
-1 -11 -9 -15 -17 -6 -20 -57 -5 -72 6 -6 -10 -8 -43 -6 l-52 4 34 -26 c21
-15 48 -26 67 -26 18 0 35 -6 38 -12 5 -10 7 -10 12 0 3 7 24 12 48 12 48 0
97 27 75 41 -21 13 -57 11 -89 -6 -17 -9 -30 -11 -30 -5 0 5 6 10 13 10 8 0
22 9 32 20 15 17 16 24 7 45 -6 14 -16 25 -22 25 -7 0 -4 -7 4 -16 19 -19 20
-36 4 -52 -19 -19 -27 -14 -26 18 1 38 -18 62 -33 40 -5 -8 -8 -27 -6 -42 2
-16 2 -28 1 -28 -17 0 -34 17 -34 35 0 54 80 72 122 27 39 -41 117 -75 193
-82 63 -6 76 -4 130 20 73 32 120 78 151 145 23 49 24 57 24 337 0 158 4 289
8 292 41 25 253 30 290 7 9 -5 12 -35 10 -103 l-3 -96 -62 30 -63 29 0 46 c0
44 -1 45 -27 38 -40 -9 -50 -24 -56 -78 -4 -46 -3 -49 37 -81 22 -18 71 -48
109 -65 l67 -32 2 -132 2 -132 6 135 6 135 4 -135 4 -135 3 128 c2 73 8 127
13 127 6 0 11 -53 12 -127 l2 -128 3 121 c3 117 4 122 24 117 14 -4 18 -11 13
-22 -15 -36 -15 -67 0 -80 9 -8 16 -9 16 -4 0 6 11 21 23 35 15 16 27 44 31
74 5 43 4 51 -16 66 -13 10 -38 23 -55 29 -32 10 -33 13 -33 65 0 50 2 54 24
54 34 0 60 27 85 89 l22 53 -22 24 c-12 13 -41 31 -65 40 l-43 16 -3 119 c-2
73 1 121 7 123 6 3 3 9 -5 15 -13 10 -12 14 8 30 14 12 22 30 22 50 0 26 -4
31 -25 31 -14 0 -25 4 -25 9 0 6 11 22 25 37 14 15 25 31 25 35 0 9 -64 29
-94 29 -12 0 -16 5 -12 15 3 8 1 15 -4 15 -6 0 -10 7 -10 15 0 8 -4 15 -10 15
-5 0 -10 -7 -10 -15 0 -8 -4 -15 -10 -15 -5 0 -7 -7 -4 -15 4 -10 0 -15 -12
-15 -34 0 -94 -21 -94 -32 0 -6 11 -21 25 -34 14 -13 25 -28 25 -34 0 -5 -11
-10 -25 -10 -21 0 -25 -5 -25 -31 0 -20 8 -37 23 -49 17 -14 19 -21 10 -31
-22 -22 11 -39 76 -39 l61 0 0 -97 0 -98 -122 0 c-68 0 -138 -3 -155 -7 l-33
-7 0 184 0 185 -167 1 c-154 1 -164 2 -118 13 66 16 77 24 70 47 -4 11 -2 19
4 19 6 0 11 9 11 20 0 11 7 20 15 20 8 0 15 4 15 8 0 5 23 23 51 41 28 19 54
44 59 57 5 13 21 26 35 30 25 6 34 28 15 39 -6 4 -4 18 6 37 14 28 16 74 3
118 -5 17 -54 57 -81 66 -10 3 -16 8 -14 12 2 4 -17 8 -42 10 -25 1 -49 6 -52
10 -3 4 -15 7 -27 8 -13 0 -21 -1 -18 -4 2 -2 -2 -11 -10 -19 -12 -12 -18 -9
-44 17 -16 17 -33 32 -38 33 -4 1 -7 5 -6 9 2 4 -4 5 -13 1 -9 -3 -19 -1 -23
4 -5 9 -104 3 -151 -8 -16 -4 -18 17 -5 58 5 15 1 27 -14 39 -25 22 -27 42 -6
50 8 4 15 10 15 15 0 5 -7 9 -15 9 -8 0 -15 7 -15 15 0 8 -7 15 -15 15 -8 0
-15 -7 -15 -15z m-70 -208 c19 -7 35 -18 34 -24 -1 -7 -2 -24 -2 -37 -2 -20
-9 -25 -46 -30 -25 -4 -70 -16 -102 -27 -64 -23 -74 -18 -74 37 0 69 99 112
190 81z m279 3 c49 -14 81 -47 81 -84 0 -55 -9 -59 -81 -37 -35 11 -81 23
-101 26 -30 6 -38 12 -43 36 -8 36 -2 46 38 58 43 12 66 13 106 1z m-515 -65
c3 -8 2 -15 -3 -15 -4 0 -8 -7 -8 -15 0 -8 5 -11 10 -8 7 4 8 -1 3 -12 -4 -10
-2 -21 3 -24 5 -3 -17 -6 -48 -7 -39 -1 -76 -10 -114 -27 -53 -24 -56 -24 -62
-7 -8 25 3 65 24 84 44 40 183 62 195 31z m799 -9 c52 -25 80 -80 58 -115 -7
-11 -13 -10 -32 3 -35 22 -147 49 -177 42 -17 -4 -22 -3 -14 2 7 5 10 16 6 27
-5 11 -4 16 3 12 5 -3 10 0 10 8 0 8 -4 15 -8 15 -5 0 -6 7 -2 16 8 22 103 15
156 -10z m-463 -7 c0 -5 -7 -9 -15 -9 -15 0 -20 12 -9 23 8 8 24 -1 24 -14z
m-5 -51 c1 -35 -20 -31 -22 4 -2 19 2 28 10 25 7 -2 12 -15 12 -29z m-125
-173 c0 -8 -4 -15 -10 -15 -5 0 -10 7 -10 15 0 8 5 15 10 15 6 0 10 -7 10 -15z
m250 -1 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5 16 9 14 15 11 15 -7z m-490 -45 c0
-5 -4 -9 -10 -9 -5 0 -10 7 -10 16 0 8 5 12 10 9 6 -3 10 -10 10 -16z m735 12
c3 -5 1 -12 -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m-655 -121 c0 -5 -2
-10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1 10 4 10 6 0 11 -4 11 -10z m840 -430 l0
-310 -184 0 -184 0 -7 37 c-8 45 -37 102 -61 124 -28 24 -80 49 -104 49 -19 0
-20 5 -20 205 l0 205 280 0 280 0 0 -310z m-1370 66 l0 -94 -42 -7 c-24 -3
-51 -9 -60 -11 -16 -5 -18 4 -18 100 l0 106 60 0 60 0 0 -94z m17 -48 c-2 -29
-3 -8 -3 47 0 55 1 79 3 53 2 -26 2 -71 0 -100z m33 47 c0 -57 -4 -95 -10 -95
-6 0 -10 38 -10 95 0 57 4 95 10 95 6 0 10 -38 10 -95z m1657 -57 c-2 -29 -3
-6 -3 52 0 58 1 81 3 53 2 -29 2 -77 0 -105z m33 47 c0 -63 -4 -105 -10 -105
-6 0 -10 42 -10 105 0 63 4 105 10 105 6 0 10 -42 10 -105z m-1723 -332 c-2
-27 -3 -5 -3 47 0 52 1 74 3 48 2 -27 2 -69 0 -95z m33 58 c0 -44 -4 -83 -10
-86 -6 -4 -10 25 -10 79 0 51 4 86 10 86 6 0 10 -33 10 -79z m-50 -21 c0 -49
-2 -90 -4 -90 -2 0 -29 -11 -60 -25 l-56 -25 0 105 0 104 33 10 c17 5 44 10
60 10 l27 1 0 -90z m1707 -77 c-2 -32 -3 -8 -3 52 0 61 1 87 3 58 2 -29 2 -78
0 -110z m33 41 c0 -67 -4 -103 -10 -99 -6 3 -10 50 -10 106 0 59 4 99 10 99 6
0 10 -42 10 -106z m-940 -684 c0 -5 -2 -10 -4 -10 -3 0 -8 5 -11 10 -3 6 -1
10 4 10 6 0 11 -4 11 -10z"/>
<path d="M4320 5741 c0 -17 -3 -30 -7 -30 -5 1 -15 1 -23 -1 -11 -1 -9 -4 5
-10 29 -13 47 -12 39 2 -8 12 9 4 36 -17 8 -7 26 -9 39 -6 23 6 23 7 -14 44
-21 21 -40 35 -43 32 -3 -2 -11 0 -19 6 -10 9 -13 5 -13 -20z"/>
<path d="M4450 5710 c0 -18 19 -40 35 -40 6 0 11 11 11 25 0 22 -9 26 -12 4
-3 -23 -6 -23 -19 1 -9 16 -14 19 -15 10z"/>
<path d="M4136 5694 c-8 -21 -8 -21 9 -21 8 0 15 9 15 19 0 22 -16 24 -24 2z"/>
<path d="M4207 5693 c-9 -8 22 -43 38 -43 7 0 17 -10 24 -22 6 -13 10 -15 7
-6 -3 9 2 19 10 22 22 9 18 39 -6 33 -10 -3 -27 1 -37 9 -21 15 -27 17 -36 7z"/>
<path d="M4085 5681 c-7 -12 12 -24 25 -16 11 7 4 25 -10 25 -5 0 -11 -4 -15
-9z"/>
<path d="M4466 5635 c-9 -14 -21 -23 -26 -20 -6 4 -10 -1 -10 -9 0 -29 22 -12
51 37 15 26 3 20 -15 -8z"/>
<path d="M4093 5643 c-22 -8 -14 -22 15 -25 15 -2 26 -8 25 -15 -2 -7 2 -13 8
-13 5 0 7 -4 4 -10 -3 -5 -1 -10 4 -10 18 0 12 20 -13 45 -13 13 -21 26 -18
29 6 7 -8 6 -25 -1z"/>
<path d="M4367 5629 c3 -10 9 -16 14 -13 13 8 11 21 -5 27 -10 4 -12 0 -9 -14z"/>
<path d="M4207 5603 c-4 -3 -7 -11 -7 -17 0 -7 3 -7 8 0 4 7 18 14 32 17 l25
4 -26 2 c-14 0 -29 -2 -32 -6z"/>
<path d="M4370 5578 c0 -12 3 -17 8 -12 4 5 11 12 15 15 5 3 2 9 -7 12 -12 5
-16 0 -16 -15z"/>
<path d="M4228 5581 c8 -4 13 -11 13 -15 -3 -22 1 -27 19 -21 23 7 28 -13 5
-21 -8 -4 -15 -11 -15 -17 0 -6 12 0 26 14 19 17 28 20 31 11 4 -10 8 -10 19
-1 11 9 14 8 14 -7 0 -16 2 -17 10 -4 5 8 9 26 9 40 0 23 -1 23 -9 5 -5 -11
-9 -15 -9 -10 -1 6 -6 3 -12 -5 -7 -11 -12 -12 -15 -3 -3 7 -12 9 -20 6 -8 -3
-14 0 -14 7 0 10 -3 10 -15 0 -13 -10 -15 -9 -15 9 0 13 -6 21 -17 21 -14 0
-15 -2 -5 -9z"/>
<path d="M4430 5559 c0 -11 5 -17 10 -14 6 3 10 13 10 21 0 8 -4 14 -10 14 -5
0 -10 -9 -10 -21z"/>
<path d="M4183 5559 c15 -9 16 -13 5 -27 -9 -12 -6 -12 10 2 22 19 30 32 13
21 -5 -3 -12 -1 -16 5 -3 5 -12 10 -18 10 -7 0 -4 -5 6 -11z"/>
<path d="M4445 5529 c-4 -6 -5 -12 -2 -15 2 -3 7 2 10 11 7 17 1 20 -8 4z"/>
<path d="M4100 5519 c0 -6 9 -9 20 -6 11 3 18 1 15 -3 -3 -4 5 -6 17 -2 21 5
21 6 2 13 -29 11 -54 10 -54 -2z"/>
<path d="M4210 5498 c0 -5 -4 -6 -10 -3 -5 3 -10 -1 -10 -10 0 -14 2 -14 25 1
29 19 30 21 10 21 -8 0 -15 -4 -15 -9z"/>
<path d="M4107 5493 c-12 -11 -8 -23 7 -23 8 0 16 -7 20 -15 8 -23 23 -18 28
10 4 20 3 23 -8 14 -11 -9 -15 -7 -20 5 -7 17 -16 20 -27 9z"/>
<path d="M4314 5447 c21 -19 27 -30 22 -48 -6 -24 -6 -24 5 -2 13 25 2 46 -35
64 -14 7 -11 2 8 -14z"/>
<path d="M4451 5447 c-13 -21 -12 -21 5 -5 10 10 16 20 13 22 -3 3 -11 -5 -18
-17z"/>
<path d="M4247 5403 c-3 -4 1 -17 9 -28 10 -13 14 -15 14 -4 0 20 -16 43 -23
32z"/>
<path d="M4295 5390 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M4194 5381 c3 -5 14 -11 25 -14 14 -3 18 -1 14 9 -3 8 -14 14 -25 14
-10 0 -17 -4 -14 -9z"/>
<path d="M4182 5352 c-19 -6 -14 -22 8 -22 11 0 20 -6 20 -14 0 -20 17 -29 22
-13 6 15 -34 55 -50 49z"/>
<path d="M4300 5348 c0 -9 -6 -19 -12 -21 -10 -4 -10 -6 0 -6 14 -1 28 26 18
36 -3 4 -6 -1 -6 -9z"/>
<path d="M4344 5339 c-3 -5 7 -9 22 -9 15 0 24 3 20 7 -11 11 -35 12 -42 2z"/>
<path d="M4337 5303 c-13 -12 -7 -22 9 -16 13 5 15 2 10 -15 -7 -22 8 -39 20
-21 5 8 9 8 14 -1 4 -7 13 -9 19 -6 15 10 14 36 -2 30 -7 -2 -22 5 -34 16 -24
22 -27 23 -36 13z"/>
<path d="M3060 4730 c0 -11 -7 -23 -15 -26 -9 -4 -15 -19 -15 -39 0 -25 -5
-35 -20 -40 -11 -3 -20 -13 -20 -22 0 -12 6 -14 33 -8 17 5 48 7 67 6 19 -2
53 -3 75 -3 22 0 56 1 75 3 19 1 50 -1 68 -6 26 -6 32 -4 32 8 0 9 -9 19 -20
22 -15 5 -20 15 -20 40 0 20 -6 35 -15 39 -8 3 -15 15 -15 26 0 19 -6 20 -105
20 -99 0 -105 -1 -105 -20z"/>
<path d="M4745 4730 c4 -13 1 -22 -9 -26 -10 -4 -16 -18 -16 -39 0 -27 -5 -34
-25 -39 -24 -6 -25 -10 -25 -77 l0 -71 33 7 c17 5 47 8 65 7 35 -2 111 -2 170
-1 20 1 49 -2 65 -6 l27 -7 0 71 c0 67 -1 71 -25 77 -20 5 -25 12 -25 39 0 21
-6 35 -16 39 -10 4 -13 13 -9 26 6 19 2 20 -105 20 -107 0 -111 -1 -105 -20z
m86 -136 c20 -4 53 -1 72 5 28 9 45 9 76 0 23 -7 41 -16 41 -21 0 -5 -10 -6
-22 -1 -13 4 -40 7 -60 6 -43 -3 -119 -3 -175 -1 -21 0 -48 -2 -60 -7 -14 -4
-23 -3 -23 2 0 14 61 32 90 27 14 -2 41 -7 61 -10z m34 -53 c67 6 135 3 144
-6 7 -7 -47 -10 -159 -10 -94 0 -170 1 -170 3 0 12 55 21 100 16 30 -3 69 -5
85 -3z"/>
<path d="M3023 4578 c-20 -7 -33 -18 -33 -28 0 -15 5 -16 46 -7 32 8 58 8 87
0 31 -9 53 -9 84 0 29 8 55 8 87 0 41 -9 46 -8 46 7 0 28 -80 46 -130 30 -22
-7 -51 -10 -67 -6 -53 14 -88 15 -120 4z"/>
<path d="M3013 4522 c-15 -2 -23 -10 -23 -24 0 -17 4 -19 28 -12 15 4 42 7 60
6 17 -1 57 -2 87 -2 30 0 70 1 88 2 17 1 44 -2 60 -6 23 -7 27 -5 27 13 0 13
-7 22 -17 22 -41 4 -290 4 -310 1z"/>
<path d="M4270 4509 c-13 -5 -58 -16 -100 -24 -67 -13 -69 -14 -19 -10 52 4
192 43 159 43 -8 1 -26 -4 -40 -9z"/>
<path d="M3765 4495 c22 -8 49 -13 60 -13 11 0 -2 6 -30 14 -63 17 -84 16 -30
-1z"/>
<path d="M3868 4473 c12 -2 30 -2 40 0 9 3 -1 5 -23 4 -22 0 -30 -2 -17 -4z"/>
<path d="M3968 4463 c23 -2 61 -2 85 0 23 2 4 4 -43 4 -47 0 -66 -2 -42 -4z"/>
<path d="M3449 4227 c-103 -29 -181 -96 -228 -197 -23 -49 -26 -67 -26 -165 0
-100 3 -116 29 -172 33 -73 104 -144 174 -176 38 -17 66 -22 142 -22 52 0 103
3 113 7 15 7 17 24 17 154 0 135 -1 146 -17 139 -10 -4 -32 -9 -50 -12 -64
-11 -108 74 -65 126 19 23 49 28 100 17 l32 -8 0 151 c0 95 -4 152 -11 156
-21 14 -164 15 -210 2z m169 -23 c21 -6 22 -10 22 -126 0 -118 0 -119 -22
-118 -51 5 -71 -1 -99 -29 -41 -41 -41 -98 0 -143 25 -28 36 -33 75 -33 l46 0
0 -111 c0 -128 3 -124 -89 -124 -199 0 -331 141 -331 351 0 158 87 279 233
324 45 15 127 19 165 9z"/>
<path d="M3930 4226 c-68 -19 -102 -40 -147 -89 -64 -70 -68 -88 -68 -382 l0
-260 140 0 140 0 3 246 c3 266 6 284 51 259 20 -10 21 -18 21 -260 l0 -250
141 0 140 0 -3 283 c-3 267 -4 284 -25 322 -49 92 -131 136 -263 141 -51 2
-103 -2 -130 -10z m260 -40 c49 -25 78 -54 103 -101 21 -38 22 -56 25 -302 l3
-263 -110 0 -111 0 0 237 c0 263 -2 273 -64 273 -70 0 -76 -24 -76 -291 l0
-219 -105 0 -105 0 0 253 c0 223 3 257 19 293 23 51 92 110 151 129 63 21 223
15 270 -9z"/>
<path d="M4417 4223 c-4 -3 -7 -170 -7 -370 l0 -363 160 0 160 0 0 370 0 370
-153 0 c-85 0 -157 -3 -160 -7z m283 -363 l0 -340 -125 0 -125 0 0 340 0 340
125 0 125 0 0 -340z"/>
<path d="M3720 3358 c-96 -6 -159 -13 -167 -21 -6 -7 -44 -82 -83 -167 -154
-334 -340 -850 -307 -850 7 0 57 9 112 19 122 23 325 48 460 56 55 3 128 8
163 11 l62 6 0 479 0 479 -42 -1 c-24 -1 -113 -6 -198 -11z"/>
<path d="M4054 2894 c5 -301 11 -481 17 -485 6 -3 76 -9 157 -12 171 -7 407
-36 577 -71 66 -13 122 -22 124 -20 7 6 -77 258 -132 399 -88 222 -289 611
-329 636 -6 4 -58 10 -117 13 -58 3 -151 9 -205 12 l-99 6 7 -478z"/>
<path d="M3320 3315 c-260 -43 -491 -96 -508 -118 -17 -21 -202 -446 -267
-611 -70 -178 -185 -500 -185 -517 0 -14 9 -11 155 49 133 55 278 104 432 147
l102 28 49 151 c27 83 66 194 87 246 49 126 180 397 248 512 84 144 82 138 55
137 -13 0 -88 -11 -168 -24z"/>
<path d="M4520 3335 c0 -4 13 -24 29 -44 173 -220 369 -620 452 -923 l24 -87
130 -41 c185 -59 283 -95 420 -157 66 -30 126 -57 133 -60 19 -8 15 11 -28
155 -104 345 -228 633 -392 909 -53 88 -66 104 -101 118 -65 26 -268 73 -436
100 -222 36 -231 38 -231 30z"/>
<path d="M1877 2802 c-119 -72 -264 -171 -331 -225 l-29 -24 38 -64 c21 -35
66 -104 99 -153 l60 -89 13 29 c7 16 33 79 58 139 26 61 86 188 136 284 49 96
87 176 86 178 -2 1 -60 -32 -130 -75z"/>
<path d="M3715 2309 c-236 -17 -559 -62 -582 -81 -21 -17 -91 -339 -142 -648
-44 -266 -43 -253 -23 -247 9 3 54 17 100 31 212 65 455 103 722 114 l165 7 3
418 2 417 -67 -1 c-38 -1 -117 -5 -178 -10z"/>
<path d="M7308 3706 c-49 -11 -88 -23 -88 -26 1 -7 192 -91 197 -87 2 2 6 34
10 71 7 77 14 75 -119 42z"/>
<path d="M1117 2414 l-58 -36 17 -27 c49 -77 94 -92 140 -45 20 19 24 31 19
52 -8 34 -37 84 -50 88 -6 2 -36 -13 -68 -32z"/>
<path d="M1274 2111 c-51 -23 -120 -84 -141 -124 -47 -92 59 -211 160 -178 53
18 125 69 153 110 46 68 21 161 -53 196 -41 20 -69 19 -119 -4z"/>
<path d="M2027 1318 c-48 -33 -87 -64 -87 -67 0 -3 27 -25 59 -47 l58 -41 30
106 c17 58 30 107 29 108 -1 2 -42 -25 -89 -59z"/>
<path d="M2824 971 c-68 -42 -118 -217 -80 -277 25 -37 99 -68 144 -59 52 10
97 62 122 141 25 82 25 110 0 151 -37 61 -124 82 -186 44z"/>
<path d="M4468 833 c-10 -2 -18 -5 -18 -6 0 -5 51 -295 56 -320 5 -26 8 -27
42 -21 120 21 157 66 150 178 -8 125 -56 177 -158 175 -30 -1 -63 -3 -72 -6z"/>
<path d="M3691 689 c-24 -55 -42 -101 -40 -103 2 -2 34 -7 72 -11 49 -5 67 -3
67 5 0 11 -50 202 -54 207 -1 1 -21 -43 -45 -98z"/>
</g>
</svg>
    </>
  )
}
