import React from 'react'

export default function Fin() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 1200.000000 1200.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1200.000000) scale(0.100000,-0.100000)"
fill="#fff" stroke="none">
<path d="M5760 11994 c-803 -45 -1442 -190 -2129 -483 -1054 -451 -1986 -1227
-2622 -2183 -45 -68 -85 -127 -90 -132 -4 -5 -73 17 -156 49 -97 38 -168 60
-205 63 -32 2 -58 1 -58 -2 0 -4 9 -24 19 -45 24 -45 104 -121 212 -198 l78
-56 -26 -46 c-14 -25 -34 -59 -44 -76 -98 -167 -290 -594 -384 -854 -238 -659
-348 -1305 -348 -2031 0 -351 14 -556 58 -867 277 -1920 1483 -3600 3215
-4477 191 -97 407 -194 560 -253 984 -375 2028 -488 3070 -332 966 145 1905
541 2695 1136 1265 952 2105 2369 2330 3926 44 311 58 516 58 867 0 226 -5
381 -17 510 -73 805 -272 1499 -630 2205 -61 121 -111 223 -111 227 0 4 36 33
80 66 217 158 315 252 301 287 -12 33 -86 17 -281 -61 -104 -42 -199 -78 -210
-80 -16 -3 -35 20 -105 128 -47 73 -144 212 -216 308 -823 1098 -1972 1871
-3304 2219 -283 74 -657 137 -980 166 -170 15 -629 26 -760 19z m470 -439 l0
-65 -115 0 -115 0 0 -55 0 -55 115 0 115 0 0 -60 0 -60 -115 0 -115 0 0 -85 0
-85 115 0 115 0 0 -60 0 -60 -175 0 -175 0 0 325 0 325 175 0 175 0 0 -65z
m-632 -2 c94 -69 97 -235 6 -297 l-34 -23 91 -132 c50 -73 89 -134 87 -135 -1
-2 -32 -6 -68 -9 l-65 -6 -84 124 c-47 69 -87 125 -91 125 -3 0 1 -59 8 -130
8 -72 13 -132 11 -134 -2 -2 -30 -6 -61 -10 -54 -6 -58 -5 -62 16 -9 39 -68
619 -64 623 8 9 216 20 256 14 24 -3 55 -15 70 -26z m1168 14 c9 -9 175 -660
169 -665 -2 -2 -31 -1 -64 3 l-59 7 -17 68 c-16 67 -17 69 -53 74 -127 18
-227 27 -229 20 -2 -5 -16 -31 -31 -59 -30 -55 -52 -63 -124 -48 l-37 8 83
155 c45 85 117 222 160 304 l78 148 59 -4 c32 -2 62 -7 65 -11z m-1661 -110
l7 -45 -51 -7 c-28 -4 -53 -10 -56 -15 -3 -4 18 -117 46 -250 27 -133 48 -247
46 -253 -5 -16 -115 -37 -120 -23 -2 6 -26 120 -53 254 -54 268 -45 252 -126
233 l-37 -9 -10 50 c-6 27 -11 55 -11 61 0 8 61 25 173 48 193 40 179 43 192
-44z m2319 -8 c32 -21 96 -101 96 -119 0 -3 -25 -18 -55 -33 l-55 -27 -9 24
c-20 50 -90 83 -119 54 -22 -22 -13 -44 54 -139 37 -52 76 -112 87 -133 28
-53 26 -122 -4 -177 -74 -134 -293 -113 -362 34 -30 63 -27 69 33 88 l55 17
23 -44 c12 -24 31 -48 41 -54 49 -26 111 0 111 46 0 12 -33 71 -73 131 -90
132 -101 159 -95 224 6 55 39 100 93 125 46 22 135 13 179 -17z m388 -117 c26
-10 50 -21 53 -24 4 -4 -28 -100 -70 -215 -85 -229 -93 -274 -56 -304 52 -43
112 -35 143 17 10 17 50 118 89 225 39 107 75 198 80 203 4 5 20 4 36 -2 15
-6 40 -16 55 -21 l27 -10 -29 -78 c-99 -272 -142 -379 -165 -409 -75 -99 -229
-100 -327 -3 -51 51 -64 89 -55 165 5 44 156 474 167 474 3 0 26 -8 52 -18z
m-3844 -178 c38 -15 39 -20 10 -79 -19 -38 -26 -45 -43 -39 -11 3 -39 9 -62
11 -36 4 -43 2 -58 -21 -17 -25 -17 -28 0 -45 9 -11 74 -38 145 -60 142 -45
182 -71 211 -136 23 -49 24 -94 4 -142 -21 -50 -41 -71 -97 -99 -67 -34 -144
-33 -209 3 -27 14 -49 29 -49 33 0 5 45 73 65 98 2 2 18 -6 36 -17 58 -35 110
-27 135 22 27 51 1 73 -148 121 -69 23 -141 54 -160 68 -66 50 -84 127 -48
198 48 94 158 129 268 84z m4515 -100 c111 -56 157 -91 178 -138 26 -57 24
-93 -8 -157 -35 -67 -79 -99 -139 -99 l-44 0 0 -160 c0 -88 -2 -160 -5 -160
-2 0 -29 12 -60 27 l-55 27 0 153 c0 84 -3 153 -8 153 -4 0 -33 -52 -65 -115
-31 -63 -61 -115 -67 -115 -16 0 -100 43 -100 52 0 18 281 568 290 568 6 0 43
-16 83 -36z m504 -279 l26 -21 -13 -118 c-7 -64 -10 -119 -7 -123 3 -3 51 20
107 51 55 31 105 56 110 56 15 0 96 -54 97 -65 1 -5 -76 -53 -170 -106 l-172
-96 -77 -114 c-43 -63 -83 -113 -88 -111 -20 5 -90 55 -90 64 0 5 32 57 72
116 l72 107 23 195 c12 107 22 201 22 208 1 14 39 -4 88 -43z m-4527 25 c41
-41 7 -120 -52 -120 -24 0 -68 44 -68 68 0 59 79 93 120 52z m-1167 -184 c70
-107 134 -214 143 -238 59 -165 -147 -339 -306 -258 -21 11 -54 39 -73 62 -45
56 -257 375 -257 387 0 5 11 16 25 25 14 9 36 23 49 32 l25 15 130 -195 c72
-108 142 -203 156 -212 33 -22 78 -10 110 28 44 52 41 59 -117 296 -60 90
-111 169 -114 177 -5 12 69 74 91 74 6 1 67 -87 138 -193z m3192 129 c977
-100 1839 -461 2600 -1089 154 -127 473 -446 601 -601 97 -117 236 -300 275
-362 19 -30 19 -31 0 -36 -17 -4 -38 19 -113 122 -152 207 -280 355 -482 557
-196 196 -347 327 -543 471 -1281 944 -2962 1179 -4453 621 -853 -319 -1590
-873 -2145 -1613 -53 -71 -103 -131 -110 -133 -35 -8 -19 29 59 136 333 454
752 852 1231 1167 693 455 1485 720 2325 775 176 11 577 4 755 -15z m-2746
-271 c12 -15 21 -35 21 -46 0 -24 -44 -68 -68 -68 -33 0 -72 38 -72 71 0 65
80 93 119 43z m1753 -91 c16 -10 38 -32 48 -49 45 -72 65 -249 36 -317 -25
-58 -59 -81 -131 -85 -64 -4 -65 -4 -104 35 -49 49 -73 125 -75 237 -1 94 18
144 67 175 41 26 118 28 159 4z m1186 -10 c44 -32 62 -79 62 -163 0 -202 -85
-314 -212 -280 -92 25 -128 102 -110 238 14 105 49 184 94 212 42 27 125 23
166 -7z m-1614 -255 c26 -112 48 -212 49 -222 2 -15 -7 -22 -37 -28 -21 -5
-40 -7 -42 -5 -1 1 -21 81 -44 177 -23 96 -43 176 -44 177 -1 1 -19 -2 -40 -8
-21 -6 -40 -8 -43 -6 -2 3 1 24 8 46 11 36 17 42 53 52 23 5 46 12 51 14 37
13 40 6 89 -197z m2007 182 l37 -10 -23 -98 c-40 -168 -83 -333 -88 -338 -3
-3 -23 0 -45 5 l-40 11 44 173 c24 94 44 175 44 179 0 4 -18 12 -40 18 -22 6
-40 14 -40 17 0 4 12 20 28 36 29 30 35 30 123 7z m-2414 -315 c40 -114 72
-210 70 -211 -2 -2 -20 -10 -40 -19 -44 -18 -32 -38 -109 185 -27 80 -51 146
-53 149 -2 2 -23 -4 -46 -13 l-41 -17 7 43 c4 24 10 47 14 51 11 11 104 46
116 43 5 -1 42 -96 82 -211z m2815 194 c29 -11 55 -22 56 -24 5 -4 -137 -408
-146 -417 -8 -8 -82 11 -82 21 0 3 27 82 60 174 33 93 60 171 60 173 0 2 -18
9 -40 15 -22 7 -40 15 -40 17 0 7 63 61 72 62 4 0 31 -9 60 -21z m-3226 -160
c107 -66 184 -295 127 -375 -44 -61 -138 -82 -199 -45 -60 37 -124 147 -146
253 -10 47 -9 59 10 100 35 80 135 112 208 67z m3664 -12 c50 -26 80 -75 80
-129 0 -69 -71 -221 -126 -270 -61 -54 -132 -55 -199 -4 -66 50 -69 131 -11
253 74 154 157 203 256 150z m-5673 -111 l52 -103 98 -13 c148 -19 145 -13 49
-109 l-83 -83 19 -110 c11 -60 18 -112 15 -115 -3 -3 -51 19 -106 47 l-100 53
-101 -53 c-56 -28 -104 -49 -107 -46 -3 3 3 55 14 115 l19 110 -83 83 c-96 95
-100 87 58 109 l91 12 51 104 c28 56 54 103 57 103 3 0 29 -47 57 -104z m7690
1 l50 -103 112 -16 c62 -9 114 -18 117 -21 2 -2 -32 -40 -77 -84 l-82 -80 18
-112 c10 -61 15 -114 12 -117 -3 -3 -50 18 -105 47 l-98 52 -107 -55 c-59 -31
-107 -53 -107 -50 0 4 9 57 19 117 l19 110 -85 87 -86 86 49 7 c27 4 81 12
119 19 l70 11 50 103 c27 56 52 102 56 102 3 0 29 -46 56 -103z m-6081 -73
c89 -42 196 -233 180 -319 -9 -48 -48 -90 -102 -111 -38 -14 -51 -15 -85 -5
-86 26 -189 178 -196 289 -5 69 17 111 74 141 47 25 84 26 129 5z m4441 -27
l53 -33 -111 -184 c-61 -102 -114 -189 -119 -194 -9 -9 -83 31 -76 42 2 4 43
72 91 152 48 80 89 148 91 152 3 4 -14 18 -37 31 l-42 25 39 21 c22 11 44 20
49 20 6 0 34 -14 62 -32z m-1942 -128 c816 -67 1621 -430 2220 -1001 141 -134
328 -339 318 -349 -4 -4 -33 -14 -63 -22 -331 -90 -784 -233 -876 -277 -266
-128 -414 -339 -377 -538 6 -31 33 -116 61 -187 104 -270 129 -403 98 -525
-45 -177 -163 -323 -331 -407 -85 -44 -145 -59 -299 -78 -131 -17 -136 -17
-167 1 -44 26 -99 177 -99 270 l0 63 -35 7 c-33 6 -44 18 -119 133 l-83 126
43 3 c25 2 44 8 44 15 0 6 -70 79 -155 162 -85 83 -155 156 -155 162 0 7 14 8
43 2 103 -20 120 1 44 55 -93 66 -422 282 -478 313 -173 99 -415 145 -483 92
-36 -28 -34 -71 4 -129 17 -25 39 -70 51 -100 11 -30 36 -89 55 -131 37 -84
52 -177 35 -221 -15 -39 -58 -37 -178 8 -97 36 -143 39 -143 8 0 -30 21 -54
95 -109 82 -60 111 -104 122 -182 3 -27 10 -56 15 -66 9 -17 11 -17 35 -2 13
9 27 14 30 12 8 -8 -19 -86 -35 -100 -8 -6 -60 -23 -116 -37 l-101 -25 -7 -65
c-15 -128 -28 -189 -54 -244 -29 -64 -21 -62 -172 -46 -334 36 -573 172 -685
389 -44 84 -58 145 -58 241 0 100 12 150 70 295 92 229 105 273 104 360 -1 97
-22 158 -87 249 -80 113 -191 190 -351 246 -130 45 -706 225 -902 281 -24 7
-43 15 -43 19 0 11 142 173 251 285 54 55 98 105 99 110 4 19 56 59 340 264
160 115 434 277 580 343 87 40 304 123 415 160 468 154 963 210 1480 167z
m-2839 -94 c45 -26 125 -124 155 -190 10 -22 19 -62 19 -89 0 -45 -4 -53 -44
-91 -94 -90 -191 -59 -295 95 -52 77 -64 112 -59 170 11 110 124 163 224 105z
m5153 -12 c78 -59 88 -141 30 -238 -47 -79 -126 -159 -172 -174 -49 -16 -98
-7 -137 26 -88 74 -77 168 37 307 82 101 172 130 242 79z m-5488 -390 c68 -76
135 -151 148 -167 l25 -29 -32 -28 -32 -29 -101 113 c-56 61 -112 123 -124
136 l-23 24 -33 -27 c-36 -30 -41 -27 -52 31 -6 29 -2 36 40 72 25 23 49 41
53 41 4 0 63 -62 131 -137z m5809 122 c47 -24 83 -80 83 -128 0 -58 -27 -110
-97 -184 -70 -76 -118 -103 -181 -103 -38 0 -51 6 -85 36 -92 83 -69 188 69
317 80 75 148 95 211 62z m-8148 -103 c123 -50 311 -130 418 -180 565 -259
1096 -453 1900 -693 1052 -315 1107 -335 1242 -470 101 -100 141 -179 146
-291 5 -86 -12 -147 -98 -358 -66 -161 -75 -201 -75 -315 1 -165 56 -287 188
-412 149 -141 343 -217 610 -239 48 -4 90 -11 93 -16 9 -14 -98 -111 -161
-145 -33 -17 -92 -40 -131 -51 -92 -26 -112 -37 -120 -67 -3 -14 -9 -25 -13
-25 -4 0 -40 11 -79 24 -281 96 -488 305 -618 623 -108 267 -140 548 -89 788
38 177 38 179 25 224 -24 89 -143 183 -301 237 -41 13 -326 88 -634 165 -734
185 -833 218 -1219 411 -503 252 -925 498 -1194 697 -110 82 -146 115 -178
165 l-18 29 41 -5 c23 -3 142 -47 265 -96z m10758 89 c0 -20 -51 -70 -164
-160 -386 -307 -1288 -794 -1781 -960 -146 -49 -377 -110 -773 -205 -199 -48
-602 -165 -643 -186 -58 -31 -121 -85 -145 -126 -31 -53 -34 -127 -9 -244 54
-257 31 -551 -62 -784 -129 -326 -357 -566 -621 -657 l-53 -18 -11 24 c-11 25
-30 35 -123 60 -92 26 -168 71 -236 142 -34 35 -60 66 -58 68 2 2 78 13 169
24 199 26 295 55 395 122 176 118 285 308 285 498 0 94 -39 247 -110 427 -76
193 -82 278 -27 398 29 63 128 172 201 220 145 96 202 116 916 326 638 187
907 271 1142 356 372 134 782 303 1148 474 140 65 444 190 495 203 34 8 65 7
65 -2z m-8732 -282 c65 -32 157 -122 188 -185 31 -64 28 -106 -13 -154 -81
-97 -190 -75 -320 63 -88 95 -104 165 -51 232 51 66 119 81 196 44z m6461 -50
c24 -27 42 -51 40 -53 -2 -1 -72 -63 -154 -137 -202 -180 -180 -166 -209 -132
-14 15 -26 31 -26 34 0 4 61 62 136 129 l135 123 -32 33 -32 33 29 6 c16 4 34
8 39 10 23 8 32 2 74 -46z m-7889 -423 c63 -24 205 -88 315 -143 316 -158 537
-251 865 -363 85 -29 161 -59 169 -65 18 -17 101 -215 90 -215 -21 0 -383 125
-523 181 -178 71 -461 208 -636 309 -259 149 -470 299 -470 335 0 17 92 -1
190 -39z m9425 -4 c-70 -68 -562 -360 -830 -495 -167 -83 -418 -182 -613 -242
-85 -26 -155 -45 -157 -43 -5 5 75 192 89 206 7 7 154 67 327 132 415 159 495
193 743 317 217 108 381 171 451 172 l40 1 -50 -48z m-207 -350 c5 -32 -108
-191 -187 -263 -150 -139 -369 -266 -632 -369 -139 -54 -729 -254 -734 -248
-2 2 27 75 64 163 l67 160 235 81 c291 100 343 122 703 296 285 138 451 210
471 204 6 -1 11 -12 13 -24z m-8758 -150 c406 -201 546 -258 860 -353 127 -38
157 -51 167 -70 22 -43 122 -303 119 -307 -6 -6 -339 96 -490 150 -595 213
-1009 492 -1053 709 l-6 32 49 -5 c37 -4 121 -40 354 -156z m-92 103 c-9 -20
-14 -22 -29 -14 -22 11 -23 14 -9 40 9 15 15 17 30 9 16 -9 18 -14 8 -35z
m8512 -32 c0 -20 -38 -15 -50 6 -8 16 -6 22 7 30 19 11 43 -9 43 -36z m-7202
-184 c50 -10 52 -12 52 -42 1 -53 45 -162 95 -233 l46 -67 -63 7 c-75 7 -157
45 -193 88 -26 30 -145 258 -145 277 0 8 60 -1 208 -30z m6097 -6 c-3 -21 -24
-74 -45 -118 -49 -103 -123 -176 -200 -199 -55 -16 -110 -21 -110 -10 0 4 18
39 39 78 22 39 48 103 60 142 28 94 48 112 147 128 123 21 117 22 109 -21z
m-7588 -180 c-149 -374 -256 -808 -302 -1224 -35 -314 -31 -775 11 -1109 169
-1360 950 -2607 2106 -3364 1319 -862 2951 -1014 4413 -410 827 342 1576 953
2087 1704 453 665 714 1381 804 2205 24 220 24 708 1 925 -49 452 -162 916
-310 1280 -25 60 -32 89 -25 98 6 6 14 12 19 12 10 0 96 -222 149 -385 476
-1485 201 -3104 -740 -4350 -188 -249 -423 -501 -668 -716 -895 -787 -2065
-1209 -3262 -1176 -918 26 -1765 294 -2525 800 -438 292 -848 679 -1167 1102
-557 739 -877 1595 -949 2535 -15 199 -6 676 16 865 53 455 181 947 345 1331
19 43 22 46 37 32 14 -15 10 -28 -40 -155z m2048 25 c4 -4 -5 -57 -21 -118
-25 -94 -32 -110 -49 -110 -36 0 -124 48 -175 96 -51 47 -110 149 -110 189 l0
22 174 -36 c96 -20 178 -39 181 -43z m5144 14 c-29 -87 -113 -177 -205 -218
-89 -39 -88 -40 -119 86 -15 61 -25 114 -21 117 3 4 84 23 179 43 189 40 188
40 166 -28z m2531 -26 c8 -36 10 -60 3 -68 -5 -7 -49 -61 -97 -120 -48 -59
-81 -106 -74 -103 25 8 226 65 231 65 8 0 30 -100 23 -107 -9 -10 -539 -162
-547 -158 -11 8 -31 98 -22 105 4 4 45 17 91 30 45 13 81 24 80 25 -2 2 -52
23 -113 48 l-109 45 -17 49 c-32 88 -34 88 128 22 81 -33 151 -58 154 -57 4 2
60 67 124 146 64 78 120 140 124 138 4 -3 14 -30 21 -60z m-5605 -66 c134 -34
221 -80 483 -254 140 -92 252 -169 250 -171 -2 -2 -35 2 -73 10 -39 7 -72 11
-74 10 -9 -10 55 -83 183 -210 l141 -140 -47 -3 c-27 -2 -48 -5 -48 -8 0 -2
22 -34 48 -71 52 -74 123 -186 119 -189 -2 -1 -147 26 -225 42 -20 4 -21 9
-16 43 7 45 -10 77 -54 103 l-29 18 17 -75 17 -75 -21 0 c-11 0 -67 7 -123 15
-133 19 -284 19 -408 1 -55 -8 -102 -13 -104 -10 -2 2 -9 0 -15 -5 -6 -4 -31
-11 -56 -15 -25 -4 -55 -9 -67 -13 -23 -5 -23 -5 -8 31 16 37 21 116 7 116 -4
0 -18 -7 -30 -16 -27 -19 -42 -11 -42 23 0 79 -55 157 -162 230 -31 21 -48 40
-48 53 0 11 3 20 8 20 4 0 48 -16 97 -35 120 -47 154 -52 185 -27 34 27 40
125 12 202 -35 99 -112 274 -132 301 -31 43 -35 74 -11 98 28 28 119 28 226 1z
m2612 -2 c76 -83 68 -179 -19 -224 -32 -17 -115 -44 -135 -44 -7 0 -17 19 -23
42 -23 83 28 194 103 229 52 23 49 23 74 -3z m-7407 -69 c135 -39 248 -74 252
-77 12 -12 -65 -272 -81 -272 -28 0 -91 24 -91 34 0 6 9 40 20 76 11 36 20 70
20 76 0 10 -89 40 -95 32 -1 -2 -12 -40 -24 -85 l-23 -81 -45 13 c-25 8 -48
16 -51 19 -3 3 4 41 16 83 12 43 18 81 14 84 -4 4 -56 21 -117 39 -60 17 -113
35 -116 38 -3 4 1 29 8 55 14 46 16 48 41 42 15 -3 137 -38 272 -76z m2942 56
c46 -31 79 -99 80 -163 0 -82 -7 -95 -50 -87 -50 9 -122 41 -149 67 -35 32
-45 83 -27 127 34 82 81 100 146 56z m-1908 -100 c16 -3 145 -58 285 -121 332
-150 553 -222 751 -246 21 -3 26 -11 37 -66 15 -75 51 -150 100 -210 l35 -43
-28 6 c-387 86 -972 387 -1179 607 -66 69 -79 93 -47 85 9 -3 30 -8 46 -12z
m8239 -16 c-21 -35 -134 -138 -238 -214 -188 -139 -357 -222 -735 -360 -80
-29 -183 -67 -229 -84 l-84 -32 42 46 c51 55 97 149 112 228 7 32 17 60 23 62
6 1 54 10 106 19 193 34 434 118 652 227 160 80 298 134 356 138 10 1 8 -7 -5
-30z m-1153 -83 c-25 -67 -92 -158 -149 -204 -44 -35 -139 -88 -146 -81 -2 3
15 70 39 149 l42 145 85 11 c46 6 98 18 114 27 17 8 31 14 33 12 1 -1 -7 -28
-18 -59z m-5887 30 c59 -13 109 -25 111 -27 8 -12 78 -268 75 -278 -8 -22
-111 39 -178 107 -78 78 -159 223 -123 222 4 0 56 -11 115 -24z m362 -76 c61
-17 116 -33 123 -35 10 -4 7 -13 -11 -35 -37 -44 -50 -93 -45 -171 l5 -69 -29
18 c-80 46 -171 187 -184 286 -6 44 -5 48 12 43 10 -3 69 -20 129 -37z m5202
-5 c-10 -91 -108 -243 -185 -288 l-31 -17 6 68 c6 78 -6 127 -44 172 -18 22
-21 31 -11 35 17 7 250 72 262 74 5 0 6 -19 3 -44z m-4843 -111 l46 -7 0 -155
0 -155 -37 6 c-99 16 -183 112 -191 216 -4 53 -2 59 23 79 29 23 73 27 159 16z
m4501 -16 c25 -20 27 -25 22 -75 -11 -109 -94 -204 -190 -220 l-37 -6 0 156 0
156 33 4 c93 11 144 7 172 -15z m-7576 -123 c146 -31 266 -57 268 -59 4 -3
-17 -97 -22 -104 -4 -5 -550 110 -558 117 -4 4 -3 30 3 58 9 40 15 50 28 47 9
-2 136 -29 281 -59z m10676 -16 c4 -37 3 -64 -3 -68 -54 -35 -199 -157 -189
-159 8 -2 62 6 120 16 l107 19 5 -31 c15 -88 38 -76 -220 -123 -126 -23 -255
-45 -286 -48 l-55 -7 -12 78 c-7 44 -12 105 -12 138 0 54 3 61 38 96 71 72
162 77 232 14 l39 -36 108 86 c59 47 111 85 115 86 3 0 9 -28 13 -61z m-8229
-19 l85 -39 -21 -42 c-12 -23 -24 -66 -27 -96 l-6 -54 -29 28 c-42 40 -98 157
-98 204 0 22 3 39 6 39 3 0 44 -18 90 -40z m5694 10 c0 -46 -34 -126 -82 -188
l-43 -57 -7 58 c-4 32 -17 76 -29 98 -13 21 -21 40 -18 42 9 7 164 76 172 77
4 0 7 -13 7 -30z m-6400 -112 c0 -4 -20 2 -45 14 -32 16 -45 28 -45 43 0 12 3
25 7 29 7 6 82 -72 83 -86z m-55 -5 c219 -103 423 -171 695 -233 93 -21 150
-40 184 -61 62 -38 378 -280 373 -286 -6 -5 -281 41 -406 68 -319 69 -653 203
-878 352 -80 53 -193 173 -193 205 0 47 46 38 225 -45z m7351 66 c8 -14 -57
-109 -106 -153 -96 -86 -286 -197 -468 -271 -149 -61 -315 -105 -617 -166
-137 -27 -258 -51 -267 -53 -10 -2 -18 -3 -18 -1 0 2 37 30 82 61 45 32 114
87 152 122 106 95 191 132 446 196 234 58 358 101 520 178 203 97 258 115 276
87z m-278 -45 c3 -18 -8 -26 -60 -47 -35 -14 -65 -23 -67 -21 -8 8 32 56 62
75 39 23 61 21 65 -7z m-6067 -24 c36 -16 97 -38 137 -48 l73 -18 -25 -23
c-41 -38 -58 -98 -54 -185 2 -49 -1 -76 -8 -76 -20 0 -99 57 -136 98 -52 58
-70 97 -75 167 -4 51 7 115 21 115 2 0 32 -13 67 -30z m5199 -22 c28 -103 -54
-241 -187 -315 l-40 -22 5 78 c5 88 -7 136 -45 182 -14 16 -24 31 -22 33 9 7
245 84 261 85 12 1 21 -12 28 -41z m-7685 -180 c4 -3 3 -28 -1 -55 l-6 -49
-199 -99 c-109 -55 -197 -100 -196 -101 1 -1 72 -9 157 -18 231 -25 213 -18
205 -79 -4 -29 -9 -53 -10 -55 -3 -3 -568 59 -573 63 -2 2 0 26 4 54 l7 52
196 99 c112 56 190 100 181 103 -8 3 -91 13 -183 23 l-168 17 6 51 c4 28 9 54
11 57 4 6 561 -55 569 -63z m2919 56 c9 -3 21 -25 26 -48 6 -22 25 -90 41
-149 17 -60 28 -111 25 -114 -2 -3 -29 3 -58 12 -170 56 -274 155 -246 235 16
47 61 70 135 70 34 0 68 -3 77 -6z m-1428 -66 c114 -71 279 -151 413 -198 68
-25 103 -43 128 -69 19 -20 30 -37 25 -39 -6 -2 -83 20 -171 48 -89 27 -176
50 -194 50 -36 0 -107 -18 -107 -27 0 -3 12 -27 26 -53 34 -63 146 -147 279
-211 117 -56 179 -76 338 -114 97 -23 123 -33 154 -61 41 -36 35 -40 -36 -23
-94 21 -195 29 -229 18 -27 -9 -32 -14 -26 -32 16 -53 46 -75 236 -169 195
-98 312 -142 455 -173 92 -21 118 -33 157 -74 l30 -31 -100 0 c-54 0 -128 -5
-164 -11 -130 -20 -132 -76 -6 -135 145 -67 339 -103 589 -109 141 -4 207 -15
207 -36 0 -5 -33 -9 -73 -9 -41 0 -149 -3 -241 -7 l-167 -6 21 -22 c81 -86
355 -144 688 -146 97 0 185 -4 195 -8 11 -4 33 -45 56 -101 45 -113 111 -245
157 -318 19 -29 34 -57 34 -62 0 -12 -106 -135 -175 -204 -28 -28 -113 -99
-190 -157 -161 -122 -190 -151 -181 -175 6 -14 18 -16 101 -9 52 4 95 5 95 2
0 -8 -67 -83 -136 -154 -79 -82 -103 -84 -224 -14 -118 68 -175 85 -260 78
-77 -6 -157 -37 -190 -73 -22 -22 -23 -29 -16 -115 8 -104 19 -138 30 -93 15
58 32 74 84 74 39 0 53 5 72 25 32 34 78 47 126 37 43 -10 45 -9 -77 -46 -104
-31 -160 -69 -183 -125 -21 -54 -20 -73 4 -121 19 -37 48 -64 115 -106 l30
-19 -23 38 c-28 48 -28 73 -1 87 11 7 27 28 36 48 18 44 48 79 85 98 29 15
108 19 108 6 0 -5 -14 -21 -31 -38 -46 -43 -89 -114 -89 -147 0 -21 14 -46 47
-85 50 -58 101 -87 135 -76 19 6 19 8 -11 37 l-31 32 32 15 c35 16 45 40 47
110 2 56 19 79 68 91 59 13 151 11 183 -6 16 -8 48 -43 71 -77 42 -61 42 -62
30 -106 -6 -25 -9 -47 -7 -50 3 -2 19 4 37 14 24 14 39 36 60 89 41 100 39
110 -31 175 -40 37 -60 63 -60 79 0 33 139 174 185 188 l35 11 0 -47 c0 -52
13 -71 48 -71 40 0 60 18 98 87 21 36 65 95 98 131 65 69 169 152 192 152 8 0
28 -13 46 -29 l32 -29 -39 -88 c-86 -192 -191 -332 -407 -544 -101 -99 -148
-153 -148 -168 0 -57 113 -54 253 8 76 33 87 36 70 17 -30 -33 -34 -53 -14
-71 38 -35 99 -15 191 63 54 45 54 45 30 11 -13 -19 -61 -80 -105 -135 -44
-55 -91 -114 -103 -132 l-22 -32 57 -7 c124 -15 274 46 385 156 l66 66 -29
-58 c-45 -89 -37 -158 18 -158 40 0 59 32 83 139 13 55 26 101 30 101 4 0 5
-44 2 -97 -5 -109 22 -249 58 -309 40 -64 146 -83 209 -37 55 42 82 102 95
219 l12 109 23 -43 c23 -45 51 -62 102 -62 27 0 29 2 29 40 0 24 -11 62 -26
92 l-26 51 50 -49 c54 -54 138 -108 204 -131 40 -14 183 -21 195 -9 13 13 -36
110 -132 261 -19 30 -19 30 1 13 39 -34 89 -49 124 -37 32 12 33 17 15 61 -6
16 -3 16 39 -2 64 -29 161 -37 209 -17 32 14 37 20 37 48 0 69 -34 110 -240
298 -197 179 -287 275 -337 359 l-26 43 49 44 c49 43 49 43 79 26 53 -29 200
-183 235 -246 47 -85 67 -105 107 -105 38 0 53 25 53 85 0 28 3 32 20 28 11
-3 59 -39 105 -80 104 -91 107 -104 36 -184 -26 -30 -52 -69 -55 -87 -11 -47
17 -120 68 -180 l44 -51 -6 64 c-4 62 -4 64 31 100 20 21 39 47 43 59 15 48
32 56 121 56 125 0 163 -26 163 -110 0 -47 21 -94 46 -104 15 -6 14 -10 -11
-36 -22 -23 -26 -33 -17 -42 23 -23 95 18 152 84 44 53 51 66 46 91 -6 30 -59
108 -95 141 -50 46 6 63 80 26 37 -20 99 -97 99 -125 0 -7 12 -17 26 -24 25
-11 26 -14 20 -64 l-7 -52 65 70 c47 50 65 78 66 98 0 41 -34 100 -81 141
l-43 36 31 0 c17 0 39 -3 48 -6 20 -8 45 -55 45 -86 0 -13 4 -18 9 -12 5 5 11
57 15 115 7 130 -2 146 -96 178 -115 39 -227 20 -357 -58 -87 -53 -119 -62
-157 -46 -29 12 -204 191 -204 208 0 5 44 7 98 5 91 -3 97 -2 100 17 2 16 -33
48 -165 147 -182 136 -254 200 -325 292 l-46 58 48 82 c26 44 63 110 81 146
l34 65 95 7 c118 9 260 45 363 91 127 59 102 86 -82 88 l-66 1 33 14 c19 8 90
21 159 29 68 8 163 24 211 36 112 27 225 77 217 96 -3 8 -5 18 -5 22 0 5 -56
10 -125 13 -69 3 -128 8 -132 12 -4 3 0 14 9 23 12 11 47 18 120 24 57 5 135
14 173 20 73 12 279 70 311 87 24 13 89 80 83 86 -29 26 -100 47 -179 53 -86
6 -90 7 -74 24 43 47 126 90 259 134 148 48 348 142 428 200 42 31 87 86 87
107 0 3 -15 12 -34 18 -28 9 -56 7 -148 -9 -137 -25 -131 -25 -118 -1 15 28
35 36 162 69 245 63 478 181 587 295 76 82 67 116 -31 116 -51 0 -138 -23
-298 -78 -8 -3 5 15 28 40 35 36 72 58 175 104 72 32 166 79 209 105 l78 47
10 -38 c16 -60 58 -343 70 -470 15 -171 12 -547 -5 -716 -90 -850 -464 -1634
-1070 -2239 -613 -614 -1382 -975 -2265 -1067 -151 -15 -554 -15 -705 1 -654
68 -1280 302 -1794 670 -893 640 -1442 1594 -1547 2691 -19 197 -14 604 10
795 24 184 69 425 80 425 2 0 48 -28 102 -62z m5834 49 c106 -36 54 -158 -101
-238 -57 -29 -150 -62 -156 -55 -6 6 55 261 69 289 7 14 22 17 81 16 40 0 88
-5 107 -12z m2000 -39 c54 -28 82 -72 82 -132 0 -111 -90 -172 -272 -183 -75
-4 -90 -2 -129 19 -52 27 -81 74 -81 131 0 82 56 136 171 167 102 26 174 26
229 -2z m-8369 -97 c30 -11 67 -34 82 -49 67 -70 51 -184 -32 -230 -52 -28
-92 -33 -179 -21 -93 13 -160 40 -198 80 -26 27 -29 38 -29 94 0 73 18 105 75
137 47 26 199 20 281 -11z m4385 -56 c94 -14 214 -34 267 -44 161 -34 147 -25
147 -90 0 -69 22 -176 49 -235 l19 -45 -41 18 c-23 10 -184 82 -357 161 -173
78 -329 144 -346 147 -25 4 -98 -25 -370 -147 -187 -84 -353 -159 -370 -166
l-31 -13 23 58 c13 33 29 94 35 137 6 44 13 93 15 111 3 19 12 34 22 37 23 7
373 73 447 84 118 18 311 13 491 -13z m5081 -93 c105 -54 163 -152 163 -272 0
-96 -27 -158 -99 -222 -60 -54 -122 -78 -201 -78 -81 0 -142 24 -204 81 -132
120 -135 307 -7 433 67 66 125 87 226 83 58 -3 92 -10 122 -25z m-8552 -42
c170 -54 340 -88 710 -145 146 -23 240 -42 257 -53 14 -9 88 -59 164 -111 75
-52 155 -105 178 -118 l41 -24 -95 6 c-393 26 -905 117 -1123 199 -184 69
-355 191 -379 271 -3 9 33 21 72 24 9 0 88 -22 175 -49z m6601 39 c17 -28 -96
-127 -229 -203 -164 -93 -391 -166 -637 -205 -139 -23 -742 -92 -748 -87 -2 2
51 34 118 71 67 37 137 84 156 105 39 42 109 83 169 99 22 5 117 20 210 31
283 35 502 83 731 159 120 40 216 52 230 30z m-5647 -64 c27 -13 81 -33 119
-44 116 -32 141 -50 207 -153 24 -36 60 -85 80 -107 21 -23 34 -41 30 -41 -23
0 -184 95 -265 157 -88 66 -244 213 -227 213 4 0 30 -11 56 -25z m4446 -20
c-48 -58 -181 -170 -270 -227 -78 -51 -168 -98 -186 -98 -6 0 17 30 52 68 50
53 98 125 125 190 1 1 44 14 95 28 52 14 121 38 154 54 33 15 61 29 63 29 2 1
-13 -19 -33 -44z m2179 -206 c4 -23 5 -43 3 -45 -2 -2 -88 -9 -191 -16 l-188
-12 4 -43 c2 -24 0 -43 -4 -43 -23 0 -77 36 -82 54 -11 40 -7 116 7 117 6 0
86 6 177 13 91 8 188 14 216 15 l51 1 7 -41z m-8449 -34 c29 -21 30 -25 27
-90 l-4 -67 -66 6 c-37 3 -140 9 -229 12 l-163 7 0 49 c0 27 3 47 8 45 4 -3
89 -8 190 -12 l182 -7 0 47 c0 50 1 50 55 10z m-1335 -138 l275 -102 3 -60 2
-60 -281 -113 c-155 -62 -283 -111 -286 -108 -3 3 -6 28 -7 57 l-1 53 55 21
55 21 3 119 3 120 -58 21 -58 20 -3 57 c-2 42 0 57 10 56 7 0 137 -46 288
-102z m2685 -22 c409 -83 681 -100 972 -64 l83 11 70 -47 c79 -53 163 -89 265
-115 66 -17 70 -19 73 -48 4 -36 6 -35 -143 -56 -423 -60 -749 -42 -1051 59
-202 67 -504 233 -504 276 0 17 113 9 235 -16z m5610 15 c16 -26 -138 -132
-290 -200 -110 -49 -290 -106 -405 -129 -264 -51 -594 -51 -882 0 l-68 12 0
43 c0 32 4 44 18 48 138 38 197 61 238 91 86 61 100 65 189 53 44 -6 166 -11
270 -11 213 -1 363 15 655 68 197 35 265 41 275 25z m-3976 -129 c6 -10 25
-24 42 -30 23 -8 331 -11 1046 -11 1078 0 1055 -1 1080 45 8 13 15 13 61 0
l52 -16 0 -124 c0 -289 -82 -625 -225 -917 -50 -105 -47 -103 -112 -82 -10 3
-13 102 -13 454 l0 450 24 0 c14 0 32 10 40 22 20 29 20 34 -4 58 -20 20 -33
20 -910 20 -877 0 -890 0 -910 -20 -11 -11 -20 -24 -20 -30 0 -17 39 -50 60
-50 20 0 20 -7 20 -455 l0 -455 -24 0 c-13 0 -31 -2 -38 -5 -14 -6 -43 45
-114 201 -104 230 -183 622 -172 864 l3 74 45 12 c61 16 58 16 69 -5z m5471
-96 c3 -2 4 -22 2 -46 l-4 -42 -189 5 -189 6 0 -44 c0 -24 -2 -44 -5 -44 -3 0
-21 10 -40 23 -34 22 -35 23 -35 90 l0 69 228 -7 c125 -4 229 -9 232 -10z
m846 -86 l276 -91 -4 -49 c-3 -27 -7 -52 -10 -55 -3 -3 -81 -19 -174 -36 -93
-17 -172 -34 -176 -37 -4 -4 69 -36 162 -71 l170 -65 0 -41 c0 -22 -6 -47 -12
-56 -10 -12 -76 -24 -268 -47 -140 -18 -270 -35 -288 -38 l-33 -5 7 53 c4 30
8 56 10 57 1 2 79 14 173 26 94 12 173 24 175 27 2 2 -72 33 -166 69 l-170 64
5 44 c2 23 7 45 9 48 3 2 85 19 182 36 97 18 173 35 169 38 -5 4 -81 30 -170
59 l-163 52 0 54 c0 30 4 55 10 55 5 0 134 -41 286 -91z m-9278 -30 l3 -66
-58 -6 c-32 -4 -136 -9 -230 -13 l-173 -7 0 46 0 45 168 7 c92 3 175 9 185 11
13 4 17 16 17 50 l0 45 43 -23 42 -23 3 -66z m3692 -474 l0 -455 -150 0 -150
0 0 455 0 455 150 0 150 0 0 -455z m500 0 l0 -455 -150 0 -150 0 0 455 0 455
150 0 150 0 0 -455z m508 3 l-3 -453 -152 -3 -153 -3 0 456 0 455 155 0 155 0
-2 -452z m-1876 171 c-14 -13 -223 -31 -428 -36 -320 -9 -541 23 -707 102 -82
39 -98 58 -64 76 23 13 60 15 507 29 299 9 410 20 582 58 l83 18 17 -120 c10
-66 14 -123 10 -127z m2554 216 c189 -35 335 -47 649 -52 380 -6 422 -15 360
-73 -28 -27 -205 -83 -339 -107 -146 -27 -739 -22 -784 6 -9 5 -8 28 3 92 8
46 15 99 15 117 0 37 -5 36 96 17z m-6432 -167 c3 -13 6 -37 6 -55 0 -29 -16
-46 -164 -175 -90 -79 -163 -144 -162 -145 1 -1 81 10 179 24 98 14 181 23
184 19 3 -3 8 -28 11 -55 4 -47 3 -49 -24 -55 -68 -13 -536 -77 -540 -73 -3 2
-7 27 -11 54 l-5 50 166 147 c92 80 165 146 163 146 -3 0 -84 -12 -182 -26
-97 -14 -179 -23 -181 -21 -9 9 -16 107 -8 108 5 0 122 17 259 38 308 47 301
47 309 19z m9231 -48 c121 -16 221 -30 223 -30 1 0 2 -15 2 -32 0 -62 1 -61
-196 -34 -194 27 -194 27 -194 -31 0 -40 -5 -40 -49 -4 l-34 28 7 66 c4 37 11
67 14 67 4 0 106 -14 227 -30z m-8207 6 c96 -50 116 -170 40 -243 -47 -46
-108 -65 -223 -70 -83 -4 -106 -1 -134 14 -50 26 -74 64 -79 125 -3 44 0 59
20 87 44 66 131 99 266 100 53 1 93 -4 110 -13z m2892 -138 c6 -24 16 -60 22
-81 6 -20 8 -40 4 -45 -13 -12 -405 -7 -491 7 -114 18 -257 57 -307 83 l-43
23 210 7 c191 6 544 34 554 44 2 2 12 4 22 4 13 0 21 -12 29 -42z m2535 17
c77 -8 242 -18 368 -22 125 -3 227 -9 227 -13 0 -21 -177 -77 -320 -102 -79
-14 -463 -18 -476 -5 -6 6 21 127 33 150 5 9 6 9 168 -8z m2780 -216 c54 -16
80 -32 114 -65 43 -43 43 -44 39 -102 -5 -69 -31 -112 -85 -137 -79 -38 -280
8 -351 79 -30 29 -36 43 -39 88 -6 70 18 115 78 146 37 20 54 23 109 18 36 -2
96 -15 135 -27z m-2637 -28 l57 -6 -72 -33 c-89 -41 -234 -78 -342 -88 -80 -6
-84 -6 -77 12 4 10 17 45 29 77 l22 58 163 -7 c89 -4 188 -9 220 -13z m3422 8
c39 -8 50 -15 46 -26 -3 -8 -9 -31 -12 -52 l-6 -37 223 -47 c123 -26 225 -49
227 -51 9 -9 -12 -98 -24 -103 -7 -3 -109 16 -226 41 -118 25 -216 46 -220 46
-4 0 -9 -15 -13 -32 -14 -68 -12 -67 -58 -57 -23 5 -47 11 -54 14 -14 4 46
315 61 315 3 0 28 -5 56 -11z m-8856 -81 c2 -2 10 -31 18 -66 16 -61 16 -62
-6 -68 -11 -3 -111 -29 -221 -58 l-200 -53 -13 30 c-24 55 -20 58 171 107 196
50 180 38 161 115 l-6 25 47 -14 c26 -8 48 -16 49 -18z m-1137 -199 c59 -29
138 -117 158 -180 24 -71 18 -188 -13 -251 l-23 -47 -42 26 c-23 14 -44 27
-46 29 -2 2 4 21 13 44 30 75 15 181 -32 233 -30 33 -89 57 -140 57 -197 0
-271 -222 -120 -354 l50 -44 -23 -41 c-12 -23 -28 -41 -35 -41 -7 0 -35 18
-62 40 -103 81 -148 241 -103 362 61 160 264 242 418 167z m9013 -84 c89 -24
177 -72 205 -113 59 -86 9 -210 -94 -233 -83 -18 -276 49 -325 114 -47 61 -46
132 1 188 49 58 111 71 213 44z m884 -57 c22 -6 42 -13 44 -14 3 -2 -42 -155
-55 -190 -2 -6 9 -14 24 -17 15 -4 38 -9 50 -13 20 -5 24 1 48 82 15 49 31 93
35 98 8 8 77 -9 93 -23 4 -4 -6 -50 -22 -102 l-29 -94 71 -22 c39 -12 72 -21
72 -20 1 1 15 44 30 95 19 62 33 92 43 92 22 0 92 -22 92 -29 0 -9 -89 -293
-92 -297 -4 -3 -548 163 -553 168 -6 8 90 298 98 298 5 0 28 -5 51 -12z
m-8653 -44 c14 -8 50 -117 42 -125 -14 -13 -415 -159 -424 -154 -9 6 -33 75
-27 79 1 2 79 31 173 65 93 34 171 64 172 65 2 2 -4 22 -12 46 l-15 43 42 -7
c22 -4 45 -9 49 -12z m2982 -33 l26 -38 -25 -12 c-14 -6 -35 -11 -47 -11 -13
0 -47 -18 -77 -41 -89 -66 -100 -79 -100 -117 0 -38 -38 -97 -67 -106 -10 -3
-37 -10 -60 -16 -32 -8 -43 -16 -43 -30 0 -15 7 -20 30 -20 34 0 52 -25 43
-60 -5 -20 -4 -22 13 -15 10 4 32 10 47 13 25 4 29 0 44 -37 17 -45 42 -53 77
-26 29 22 33 19 56 -26 l21 -41 62 68 c34 37 63 71 65 75 2 4 -6 21 -18 37
-27 36 -19 56 36 88 32 19 51 24 73 19 17 -3 39 -1 52 5 18 10 25 9 41 -7 19
-19 18 -20 -39 -58 -79 -52 -180 -158 -226 -238 -49 -83 -72 -105 -95 -90 -13
8 -16 23 -14 67 2 31 2 56 0 56 -2 0 -35 -9 -73 -20 -38 -11 -73 -20 -77 -20
-4 0 1 23 13 51 11 29 18 54 15 56 -2 2 -28 -3 -57 -11 -29 -9 -56 -16 -61
-16 -4 0 -8 18 -8 40 l0 41 -52 -6 c-151 -16 -150 -16 -146 -3 2 7 53 50 114
96 189 144 244 192 332 293 47 55 89 99 92 99 4 0 19 -17 33 -39z m1635 -3
l-26 -33 -760 -3 -761 -2 -20 29 c-12 16 -21 32 -21 35 0 3 363 6 806 6 l807
0 -25 -32z m212 -129 c43 -42 148 -129 234 -193 86 -64 156 -120 156 -124 0
-4 -39 -4 -87 0 -97 8 -113 2 -113 -43 0 -34 0 -34 -69 -13 -29 8 -55 13 -58
10 -3 -3 3 -25 12 -51 10 -25 16 -48 13 -50 -2 -3 -36 4 -74 15 -38 11 -70 20
-71 20 -2 0 -3 -27 -3 -60 0 -97 -46 -93 -100 9 -45 84 -126 178 -203 236
l-67 49 22 23 c32 34 69 29 122 -16 27 -23 70 -47 101 -57 37 -12 54 -22 51
-31 -5 -13 5 -17 32 -14 18 3 26 -27 12 -44 -22 -26 -50 -16 -104 35 -46 44
-55 48 -66 35 -10 -13 -10 -19 2 -33 8 -9 42 -49 75 -89 l61 -71 12 28 c19 45
36 55 66 41 37 -17 61 -5 74 34 9 28 17 35 36 35 14 0 34 -5 45 -10 17 -9 19
-7 19 18 0 50 29 61 97 35 10 -4 -5 21 -36 57 -29 36 -57 66 -61 68 -5 2 -11
-6 -15 -17 -17 -56 -85 -50 -115 9 l-20 39 62 3 61 3 -55 36 -55 37 -39 -34
c-54 -46 -93 -46 -128 0 -45 60 -29 83 35 50 30 -15 31 -15 24 7 -4 13 -9 41
-10 64 -1 22 -7 50 -14 63 -23 42 1 47 32 6 17 -21 65 -73 107 -115z m-170 55
c0 -8 -9 -14 -20 -14 -18 0 -19 3 -10 20 12 22 30 18 30 -6z m-1580 -68 c-6
-6 -18 -5 -34 4 -21 10 -23 16 -13 31 11 18 12 17 34 -3 16 -14 20 -25 13 -32z
m-161 -36 c-35 -58 -45 -66 -53 -45 -3 8 -15 15 -26 15 -26 0 -26 20 1 54 17
21 29 26 65 26 l44 0 -31 -50z m128 -10 c10 -26 8 -36 -11 -73 -12 -23 -34
-49 -49 -57 -25 -14 -28 -13 -54 12 l-26 27 28 41 c29 41 71 79 90 80 6 0 16
-14 22 -30z m113 -25 c16 -20 1 -38 -23 -29 -17 6 -22 25 -10 37 10 11 19 8
33 -8z m1451 -30 c24 -19 78 -46 119 -60 89 -30 95 -34 104 -62 5 -16 2 -25
-14 -33 -32 -17 -71 3 -99 49 -22 35 -27 38 -67 37 -61 -1 -126 49 -111 88 9
23 19 20 68 -19z m-251 -46 c-165 -153 -465 -349 -533 -349 -29 0 -218 107
-324 183 -95 69 -253 204 -253 217 0 6 222 10 588 10 l587 -1 -65 -60z m-1498
10 c14 -50 0 -83 -49 -112 -61 -35 -99 15 -47 64 14 13 34 38 45 56 12 18 25
33 29 33 5 0 15 -19 22 -41z m4736 -75 c92 -42 184 -85 204 -95 l38 -19 -17
-38 c-10 -20 -18 -38 -19 -40 -1 -2 -75 31 -165 72 -89 42 -168 76 -175 76 -7
0 -18 -16 -25 -35 -7 -19 -16 -35 -21 -35 -4 0 -18 16 -32 36 l-24 35 26 60
c14 32 29 59 34 59 5 0 84 -34 176 -76z m-7453 20 c15 -6 65 -92 65 -114 0 -9
-234 -131 -356 -185 l-54 -24 -21 40 -20 40 168 81 c92 44 169 82 171 83 2 2
-6 21 -18 44 l-20 41 34 0 c19 0 42 -3 51 -6z m-1090 -79 c9 -26 15 -48 13
-50 -12 -13 -523 -205 -533 -201 -7 2 -20 25 -28 50 l-15 45 146 57 c81 31
201 78 267 104 66 27 123 46 126 44 3 -2 14 -24 24 -49z m3905 27 c0 -10 -18
-35 -40 -57 l-40 -39 -35 17 c-19 9 -35 20 -35 25 0 9 118 70 138 71 6 1 12
-7 12 -17z m5751 -111 c138 -60 253 -112 256 -115 3 -3 -3 -27 -14 -53 l-19
-48 -206 -35 c-114 -19 -209 -38 -212 -41 -4 -3 65 -37 151 -74 87 -38 160
-70 162 -71 6 -5 -29 -93 -39 -99 -7 -5 -507 209 -523 224 -7 7 29 101 39 101
11 0 428 71 431 73 1 2 -74 36 -168 76 -93 40 -169 75 -169 76 0 15 42 95 50
95 5 0 123 -49 261 -109z m-5256 -35 c9 -70 -8 -146 -51 -237 -91 -190 -237
-328 -429 -406 -89 -36 -195 -46 -195 -19 0 8 75 90 168 183 192 193 267 293
356 474 l61 125 42 -33 c37 -30 43 -41 48 -87z m846 22 c45 -66 203 -229 406
-416 94 -87 125 -122 138 -157 14 -36 15 -48 5 -60 -30 -36 -136 -27 -235 21
-81 40 -159 106 -230 196 -102 130 -156 245 -173 377 -8 57 -7 65 11 82 12 10
24 19 29 19 4 0 26 -28 49 -62z m-1681 -75 c13 3 22 -3 30 -24 7 -16 16 -29
21 -29 17 0 8 -17 -36 -69 -64 -75 -63 -105 10 -168 78 -67 79 -72 12 -210
-15 -33 -24 -28 -19 11 3 27 -4 46 -38 96 -62 91 -84 102 -200 97 -139 -6
-157 -20 -168 -136 l-7 -66 -37 -3 c-33 -3 -38 -7 -38 -27 0 -13 8 -28 17 -34
10 -5 14 -13 10 -17 -10 -10 -106 94 -113 122 -7 29 14 67 80 142 31 35 56 67
56 72 0 12 -68 22 -103 15 -64 -14 -114 -52 -141 -107 -13 -29 -34 -59 -45
-66 -12 -7 -21 -23 -21 -37 0 -46 -37 -19 -65 49 -44 104 60 185 302 237 29 6
55 14 58 18 7 13 -151 43 -186 36 -17 -4 -45 -20 -63 -35 -27 -24 -40 -28 -80
-27 -35 2 -51 -2 -61 -16 -12 -16 -13 -16 -19 5 -11 45 -6 95 12 111 42 39
115 61 197 61 72 1 87 -3 145 -33 209 -110 197 -105 239 -92 29 8 60 34 118
95 l78 84 18 -30 c12 -21 24 -29 37 -25z m1530 53 c0 -3 -4 -8 -10 -11 -5 -3
-10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m1107 -177 c42 -13 76 -3 163
51 130 81 257 99 365 51 55 -24 65 -44 58 -114 -6 -55 -6 -56 -27 -42 -11 8
-36 15 -54 15 -24 0 -45 9 -67 29 -48 42 -94 46 -190 17 -89 -26 -89 -26 50
-46 132 -19 204 -61 246 -142 27 -53 24 -68 -20 -110 l-39 -37 -6 24 c-4 14
-15 29 -26 35 -11 6 -27 27 -35 48 -28 64 -79 106 -154 123 -39 9 -81 -4 -81
-25 0 -8 19 -38 43 -67 101 -125 101 -123 41 -191 -43 -48 -94 -88 -94 -72 0
3 11 15 25 26 30 23 32 38 6 38 -39 0 -62 32 -70 98 -6 49 -13 69 -34 90 -26
25 -34 27 -133 30 -98 4 -107 2 -130 -19 -13 -12 -26 -32 -30 -43 -3 -12 -20
-36 -38 -54 -17 -18 -35 -49 -41 -69 l-10 -36 -25 49 c-37 74 -31 115 25 175
41 44 65 84 65 110 0 6 -22 34 -50 63 -53 56 -58 66 -36 66 8 0 17 9 21 20 3
11 25 33 48 49 l42 30 79 -79 c45 -45 94 -84 113 -91z m-1682 138 c34 -53 58
-177 58 -307 0 -109 -3 -135 -26 -207 -32 -95 -90 -187 -155 -243 -86 -75
-261 -142 -326 -126 -14 3 -26 10 -26 14 0 4 46 63 101 132 195 244 302 469
334 708 8 57 17 64 40 29z m609 -204 c57 -115 148 -275 202 -358 100 -155 174
-280 174 -297 0 -6 -30 -9 -73 -7 -193 10 -381 191 -441 424 -33 130 -48 377
-23 407 10 13 44 36 54 38 2 0 50 -93 107 -207z m3271 132 c163 -79 221 -180
162 -278 -62 -102 -162 -103 -317 -3 -85 55 -122 106 -122 170 0 60 30 108 85
136 54 27 98 21 192 -25z m-3746 -15 c0 -8 -6 -31 -14 -50 -14 -37 -16 -35
-34 35 -17 64 -15 69 19 48 17 -9 30 -24 29 -33z m241 -11 c0 -11 -4 -17 -10
-14 -5 3 -10 13 -10 21 0 8 5 14 10 14 6 0 10 -9 10 -21z m-3604 -29 c34 -22
74 -90 74 -128 0 -68 -71 -149 -181 -207 -121 -63 -202 -56 -255 25 -59 89
-26 177 97 256 118 77 200 93 265 54z m3494 10 l38 0 36 -112 c62 -190 89
-342 90 -503 1 -186 -8 -243 -47 -302 -23 -35 -43 -52 -73 -62 -84 -29 -145
15 -174 128 -40 151 -38 182 32 649 25 170 34 212 46 207 7 -3 31 -5 52 -5z
m1835 -46 c21 -18 24 -24 10 -19 -11 4 -46 12 -77 19 -76 16 -83 26 -17 26 44
0 60 -5 84 -26z m-6433 -106 l25 -53 -204 -225 c-112 -124 -208 -226 -212
-228 -4 -1 -18 20 -31 48 l-24 50 30 33 c60 65 60 56 4 167 l-51 103 -65 -6
-65 -5 -25 50 c-14 28 -24 51 -22 53 2 3 505 56 598 64 10 0 26 -20 42 -51z
m5204 -199 c63 -56 119 -110 125 -121 13 -24 4 -38 -24 -38 -35 0 -82 35 -125
92 -45 60 -107 168 -97 168 3 0 58 -46 121 -101z m-1107 20 c-41 -81 -137
-183 -195 -205 -46 -18 -74 -14 -74 10 0 14 291 269 298 261 2 -2 -11 -31 -29
-66z m280 -260 c-17 -75 -39 -142 -48 -152 -23 -24 -51 -12 -51 22 0 24 119
275 127 267 2 -2 -10 -64 -28 -137z m517 -31 c45 -89 45 -108 -1 -108 -34 0
-37 3 -65 63 -26 53 -30 74 -30 142 l0 80 31 -55 c17 -30 46 -85 65 -122z
m4001 75 c3 -7 -4 -25 -14 -40 -11 -15 -23 -33 -26 -39 -4 -7 67 -59 183 -133
104 -68 192 -126 195 -131 7 -11 -43 -90 -57 -90 -4 0 -92 54 -194 120 -102
66 -189 120 -194 120 -4 0 -18 -16 -30 -35 -12 -19 -27 -35 -33 -35 -7 0 -29
12 -50 26 -36 24 -37 27 -24 47 8 12 47 73 86 135 l73 112 40 -22 c22 -13 43
-28 45 -35z m-8662 -19 c15 -25 23 -49 19 -54 -5 -4 -92 -60 -194 -125 -102
-64 -187 -119 -189 -121 -2 -2 16 -33 39 -69 24 -37 39 -69 34 -74 -5 -4 -25
-18 -45 -30 l-37 -21 -72 111 c-53 82 -69 114 -60 123 14 14 463 304 472 305
3 1 18 -20 33 -45z m7495 -293 l105 -99 -31 -32 -32 -32 -136 127 -137 128
-34 -33 c-25 -24 -36 -29 -39 -19 -2 8 -9 29 -15 47 -10 32 -8 36 36 83 l46
49 66 -60 c36 -32 113 -104 171 -159z m-6082 149 c70 -43 92 -132 52 -211 -27
-51 -131 -153 -184 -179 -89 -43 -171 -15 -214 72 -21 41 -22 51 -12 90 21 78
116 183 208 230 47 24 109 23 150 -2z m7152 -340 c120 -96 219 -177 219 -180
0 -3 -15 -23 -33 -44 l-34 -39 -173 17 c-96 9 -196 19 -224 23 l-50 6 147
-118 148 -118 -34 -41 c-18 -23 -36 -41 -39 -41 -3 0 -101 77 -218 170 -117
94 -217 173 -222 177 -10 6 2 29 40 74 l18 21 180 -18 c99 -10 200 -20 224
-23 l43 -6 -138 111 c-77 61 -143 114 -148 119 -10 10 51 93 65 88 5 -2 108
-82 229 -178z m-1454 109 c45 -27 140 -132 165 -181 43 -84 19 -172 -59 -216
-49 -27 -84 -28 -139 -1 -51 25 -154 134 -184 196 -72 148 78 288 217 202z
m-5387 -71 c44 -36 49 -44 38 -57 -8 -9 -76 -89 -151 -179 l-138 -163 -32 28
c-17 15 -32 29 -34 29 -1 1 48 62 111 135 62 74 116 140 121 147 4 8 -4 22
-24 38 -17 15 -28 29 -25 33 6 6 63 29 76 30 4 1 29 -18 58 -41z m-1263 -203
c115 -51 183 -150 192 -277 l5 -71 -33 6 c-18 4 -43 7 -56 7 -20 0 -24 5 -24
33 0 158 -157 265 -289 197 -178 -90 -126 -345 77 -376 l42 -6 0 -55 0 -56
-52 7 c-73 10 -129 37 -186 90 -74 69 -97 123 -97 226 0 101 17 142 85 210 98
98 214 121 336 65z m6334 -141 c69 -97 128 -182 133 -189 5 -9 -4 -20 -27 -34
-18 -12 -36 -21 -39 -21 -3 0 -53 67 -112 150 l-106 150 -37 -25 -37 -25 -6
27 c-14 58 -10 67 44 105 28 20 54 37 57 37 3 0 61 -79 130 -175z m-4756 113
c85 -47 101 -133 47 -239 -82 -163 -194 -231 -291 -179 -115 61 -102 209 31
355 80 87 140 105 213 63z m5936 -142 l34 -35 -72 -73 -72 -72 37 -38 38 -37
72 72 73 73 37 -38 38 -37 -73 -73 -72 -72 52 -53 53 -52 72 72 73 72 40 -39
40 -39 -112 -113 -113 -113 -202 202 c-112 112 -203 207 -203 212 0 9 210 215
221 216 3 0 20 -16 39 -35z m-1538 -40 c67 -45 147 -184 154 -269 4 -37 0 -61
-13 -86 -34 -66 -136 -99 -202 -66 -75 39 -175 207 -175 296 0 28 8 60 20 80
41 70 147 92 216 45z m-4037 -40 c83 -48 95 -134 35 -259 -64 -135 -155 -203
-238 -178 -44 13 -100 64 -108 99 -12 45 3 118 35 185 79 161 174 213 276 153z
m3641 -134 c76 -35 156 -177 162 -291 5 -82 -20 -125 -90 -154 -92 -38 -175
11 -235 141 -75 163 -57 269 52 308 52 18 62 18 111 -4z m-5120 -57 c93 -84
114 -117 114 -181 0 -40 -6 -59 -30 -91 -40 -56 -80 -75 -145 -70 l-51 4 -29
-130 c-15 -72 -31 -134 -34 -138 -4 -4 -26 10 -50 30 l-44 38 31 135 c16 74
29 135 27 137 -1 2 -38 -38 -81 -88 -43 -49 -81 -90 -84 -90 -3 0 -22 17 -42
38 l-37 37 187 217 c103 119 189 217 191 217 1 1 36 -29 77 -65z m1844 17 c19
-10 46 -33 58 -52 21 -30 23 -43 19 -101 -9 -129 -105 -285 -186 -301 -43 -9
-98 10 -139 48 -37 33 -37 34 -37 112 0 72 4 85 43 166 67 136 147 179 242
128z m2920 -130 c65 -51 128 -217 117 -309 -9 -78 -70 -133 -151 -134 -84 -2
-141 57 -181 185 -51 161 -23 254 86 286 47 13 88 4 129 -28z m2069 -156 c116
-108 211 -197 211 -199 0 -3 -18 -19 -40 -36 l-40 -32 -97 88 c-53 49 -113
104 -134 122 l-37 32 61 -176 61 -177 -34 -29 c-19 -15 -38 -28 -42 -28 -5 0
-77 46 -160 101 -83 56 -148 94 -145 85 4 -9 41 -81 82 -159 l76 -142 -38 -32
c-21 -18 -41 -33 -45 -33 -3 0 -68 116 -143 257 l-136 257 38 33 c21 18 41 32
46 33 4 0 82 -50 173 -111 91 -61 167 -109 169 -107 2 2 -26 88 -62 193 l-66
189 38 33 c21 18 41 33 45 33 4 0 102 -88 219 -195z m-4594 142 c79 -44 95
-134 49 -277 -20 -66 -34 -91 -73 -131 -44 -45 -51 -49 -95 -49 -59 0 -115 29
-142 73 -54 90 22 343 117 390 47 23 97 21 144 -6z m2065 -72 c0 -3 21 -99 46
-213 25 -114 46 -213 47 -221 2 -18 -73 -37 -83 -21 -3 5 -23 88 -44 184 l-38
173 -41 -9 c-22 -5 -42 -7 -44 -4 -3 3 1 24 9 48 14 41 17 43 69 54 65 14 79
16 79 9z m-1718 -20 c25 -4 49 -10 55 -13 7 -5 -5 -83 -35 -230 l-46 -224 -40
6 c-22 4 -42 9 -44 11 -2 2 13 83 32 182 20 98 36 181 36 184 0 4 -20 10 -46
14 l-45 6 33 39 c18 21 38 37 44 35 6 -2 32 -6 56 -10z m-2208 -88 l29 -24
-168 -229 c-93 -126 -170 -231 -171 -233 -2 -2 -23 12 -48 29 -28 20 -43 37
-38 45 4 6 81 111 171 233 177 240 163 230 225 179z m3576 17 c44 -38 69 -93
82 -183 24 -174 -30 -271 -152 -271 -113 0 -172 98 -171 287 0 87 11 122 53
159 31 28 43 32 105 33 42 1 57 -4 83 -25z m-911 0 c5 -4 -25 -341 -36 -416
-5 -37 -6 -38 -49 -38 -52 0 -50 -20 -26 212 l18 166 -43 7 c-24 3 -43 9 -43
12 0 3 12 22 26 40 26 35 26 35 88 28 33 -4 63 -9 65 -11z m-2360 -229 c4 -5
-11 -98 -34 -205 -23 -107 -41 -197 -39 -199 1 -2 72 58 157 132 l154 135 44
-25 c24 -13 46 -26 47 -28 4 -4 -196 -536 -204 -545 -5 -4 -94 41 -94 48 0 2
27 76 61 164 33 88 59 162 57 164 -2 1 -65 -51 -140 -116 l-136 -120 -41 23
c-22 12 -41 29 -41 37 0 8 16 89 35 179 19 90 34 166 32 168 -2 2 -55 -57
-117 -131 l-113 -134 -43 27 c-25 14 -42 31 -40 38 3 6 87 108 187 227 l182
216 39 -22 c22 -12 43 -27 47 -33z m5407 -10 c14 -25 25 -47 23 -49 -2 -1 -40
-24 -84 -50 -44 -27 -82 -50 -84 -51 -4 -5 41 -85 48 -85 3 0 44 23 90 50 46
28 85 49 86 48 12 -15 56 -91 53 -93 -2 -1 -40 -24 -85 -50 -46 -26 -83 -52
-83 -57 0 -16 64 -118 74 -118 7 0 85 44 152 87 20 13 23 11 48 -33 15 -26 23
-51 19 -55 -5 -4 -65 -41 -136 -82 l-127 -76 -145 243 c-80 133 -145 247 -145
252 0 9 247 163 262 164 4 0 19 -20 34 -45z m-435 -267 c44 -13 45 -16 23 -75
-15 -38 -20 -42 -38 -36 -12 5 -53 8 -92 8 -60 0 -77 -4 -112 -28 -155 -102
-83 -356 101 -357 87 0 158 44 192 121 21 45 25 46 83 19 l43 -19 -26 -50
c-56 -111 -151 -172 -282 -179 -74 -4 -88 -2 -137 22 -62 31 -104 74 -143 149
-23 44 -28 64 -28 132 0 67 5 88 27 131 39 74 118 140 198 165 39 12 149 10
191 -3z m-4288 -74 c73 -31 136 -60 141 -64 4 -5 0 -29 -9 -55 -21 -53 -14
-53 -132 -2 l-81 35 -19 -46 -20 -47 93 -40 c52 -22 94 -43 94 -46 0 -4 -9
-27 -20 -52 l-20 -45 -92 39 c-50 22 -95 36 -99 32 -4 -4 -19 -34 -33 -66
l-25 -58 62 -28 c34 -15 77 -33 95 -40 17 -8 32 -15 32 -16 0 -11 -42 -95 -48
-95 -18 -1 -282 116 -282 125 0 13 221 525 227 525 2 0 64 -25 136 -56z m467
-162 c19 -10 50 -40 68 -66 35 -53 38 -61 20 -69 -7 -3 -29 -12 -50 -21 l-36
-16 -21 35 c-23 40 -57 57 -83 43 -29 -14 -21 -45 31 -132 72 -120 81 -141 81
-188 0 -109 -102 -187 -208 -158 -78 21 -144 89 -154 159 -3 22 3 26 47 37 28
7 51 10 53 6 13 -39 42 -79 65 -92 24 -13 30 -13 55 0 43 25 39 52 -22 153
-65 109 -76 136 -76 188 0 85 57 139 145 139 30 0 65 -8 85 -18z m3149 -22
c90 -45 118 -175 54 -250 l-26 -32 102 -102 102 -102 -61 -19 -61 -19 -100
100 c-54 54 -99 96 -99 93 0 -3 16 -55 35 -115 30 -96 33 -110 19 -120 -17
-14 -84 -28 -91 -20 -4 4 -172 533 -173 543 0 3 153 49 185 56 43 10 77 6 114
-13z m-572 -151 c67 -32 128 -98 153 -167 28 -73 24 -186 -7 -247 -90 -173
-324 -220 -468 -93 -25 22 -58 64 -73 92 -23 44 -27 63 -27 141 0 75 4 97 23
133 42 79 120 140 207 163 50 13 141 3 192 -22z m-1829 -11 l143 -22 -7 -45
c-3 -25 -10 -50 -14 -55 -5 -5 -49 -2 -104 6 -53 8 -96 12 -96 9 -1 -3 -5 -25
-9 -49 -10 -48 -19 -43 109 -63 41 -6 78 -13 81 -15 7 -4 -7 -94 -15 -102 -3
-2 -46 3 -97 12 -51 8 -95 13 -99 11 -4 -3 -11 -30 -15 -61 -4 -32 -9 -63 -12
-69 -3 -9 21 -16 79 -24 135 -20 126 -14 116 -74 -6 -29 -11 -53 -12 -54 -4
-4 -298 46 -304 52 -3 3 14 131 38 285 36 229 46 280 59 280 9 0 80 -10 159
-22z m408 -52 c0 -1 50 -86 110 -189 l109 -188 6 73 c4 40 7 117 8 170 0 53 4
103 7 112 4 12 20 16 54 16 27 0 51 -4 54 -9 6 -9 -19 -569 -25 -575 -2 -2
-27 -2 -55 1 l-51 5 -109 184 c-81 138 -110 180 -116 167 -3 -10 -7 -57 -7
-104 -1 -47 -4 -120 -7 -162 l-7 -77 -53 0 -54 0 0 48 c0 75 21 516 25 529 3
9 20 10 57 6 29 -3 53 -6 54 -7z m828 -8 c3 -13 6 -37 6 -54 0 -30 -2 -31 -52
-38 -29 -3 -70 -6 -92 -6 -38 0 -38 -1 -32 -31 3 -17 6 -40 6 -51 0 -19 6 -20
88 -17 l87 4 3 -51 3 -51 -47 -7 c-26 -3 -65 -6 -87 -6 l-40 0 7 -129 7 -129
-51 -7 c-28 -4 -54 -5 -56 -2 -6 6 -43 570 -38 576 2 2 49 7 106 11 179 12
175 13 182 -12z"/>
<path d="M5410 11433 c0 -16 3 -45 7 -67 l6 -38 41 6 c58 10 86 31 86 65 0 42
-28 61 -88 61 -50 0 -52 -1 -52 -27z"/>
<path d="M6632 11299 c-60 -112 -64 -102 51 -114 35 -4 57 -3 57 3 0 9 -50
205 -54 209 0 1 -25 -43 -54 -98z"/>
<path d="M8425 10880 c-14 -27 -25 -53 -25 -58 0 -12 67 -42 95 -42 32 0 55
25 55 61 0 22 -9 36 -42 59 -22 16 -45 30 -49 30 -5 0 -20 -22 -34 -50z"/>
<path d="M5379 10301 c-22 -17 -24 -27 -23 -93 4 -154 53 -232 119 -184 41 30
27 225 -21 272 -28 29 -44 30 -75 5z"/>
<path d="M6573 10309 c-12 -4 -29 -21 -37 -38 -20 -38 -40 -165 -31 -199 15
-59 65 -81 99 -43 44 49 64 232 29 263 -28 24 -37 26 -60 17z"/>
<path d="M4125 9926 c-21 -32 -14 -67 31 -163 39 -82 74 -110 117 -94 35 14
40 49 17 125 -40 128 -124 195 -165 132z"/>
<path d="M7794 9915 c-30 -30 -83 -139 -91 -188 -4 -27 -1 -39 16 -56 28 -28
43 -27 82 6 38 32 99 154 99 199 0 61 -61 84 -106 39z"/>
<path d="M3748 9739 c-61 -35 57 -269 135 -269 35 0 60 38 51 76 -12 50 -66
144 -101 174 -35 31 -56 36 -85 19z"/>
<path d="M4969 7693 c-74 -63 -78 -185 -8 -259 l21 -22 40 39 c24 23 50 39 65
39 42 0 89 -27 112 -63 24 -40 38 -47 88 -47 19 0 50 -7 68 -16 57 -27 161
-151 143 -169 -3 -3 -40 11 -82 31 -51 24 -99 39 -144 44 -46 5 -99 21 -162
49 -51 23 -95 40 -96 38 -2 -2 8 -27 22 -55 53 -107 211 -198 254 -147 20 24
83 18 170 -16 106 -40 142 -46 160 -23 26 31 16 104 -29 203 -23 50 -41 97
-41 104 0 49 -114 219 -160 236 -46 19 -281 61 -335 61 -44 0 -59 -5 -86 -27z
m286 -30 c66 -13 127 -29 136 -34 36 -19 118 -150 133 -212 4 -16 25 -68 46
-117 44 -96 52 -156 23 -167 -10 -4 -63 9 -124 30 -116 40 -155 45 -185 22
-27 -20 -48 -19 -102 9 -44 22 -129 102 -120 112 2 2 40 -12 84 -31 54 -24 94
-35 128 -35 35 0 74 -11 139 -40 78 -35 93 -39 105 -26 12 12 11 20 -5 57 -43
95 -134 169 -210 169 -30 0 -48 6 -59 19 -53 63 -75 80 -111 91 -51 16 -79 9
-120 -30 -18 -16 -34 -30 -36 -30 -13 0 -37 74 -37 115 0 94 52 140 145 129
28 -3 104 -17 170 -31z"/>
<path d="M5307 7603 c-16 -16 -4 -43 27 -63 19 -11 37 -26 40 -33 2 -7 5 -1 5
12 1 13 -10 39 -25 58 -25 33 -35 39 -47 26z"/>
<path d="M3382 9498 c-7 -7 -12 -28 -12 -48 0 -48 82 -167 133 -194 33 -19 36
-19 61 -3 48 32 23 115 -63 207 -49 52 -91 66 -119 38z"/>
<path d="M8521 9464 c-48 -43 -99 -119 -107 -162 -8 -39 17 -72 53 -72 46 0
155 126 169 197 5 24 1 36 -15 52 -30 30 -55 26 -100 -15z"/>
<path d="M8815 9148 c-76 -77 -96 -119 -75 -157 25 -47 109 -16 174 64 75 91
78 156 7 163 -31 3 -41 -4 -106 -70z"/>
<path d="M2737 8912 c-10 -10 -17 -28 -17 -39 0 -40 87 -135 157 -172 36 -19
71 -13 89 14 23 35 7 70 -66 139 -80 77 -130 94 -163 58z"/>
<path d="M5606 7576 c-20 -17 -14 -36 13 -36 25 0 55 -37 76 -94 41 -111 107
-140 211 -92 34 16 65 24 77 20 17 -5 14 2 -14 42 -77 112 -114 137 -230 153
-40 6 -83 13 -94 16 -11 3 -28 -1 -39 -9z m282 -108 c34 -34 -9 -118 -60 -118
-34 0 -87 36 -94 64 -12 48 28 65 48 21 15 -33 63 -32 63 0 1 40 21 55 43 33z"/>
<path d="M6265 7160 c-3 -5 11 -48 32 -96 l38 -87 13 44 c15 54 9 85 -26 122
-27 28 -46 34 -57 17z"/>
<path d="M5974 7038 c63 -149 70 -161 77 -142 9 24 11 97 3 119 -6 13 -72 55
-89 55 -3 0 1 -15 9 -32z"/>
<path d="M5610 7003 c0 -5 15 -47 34 -94 l35 -84 15 37 c22 52 20 73 -11 107
-28 29 -73 50 -73 34z"/>
<path d="M6175 6948 c3 -13 12 -56 20 -97 9 -41 18 -77 20 -79 7 -7 45 80 45
103 0 28 -26 62 -61 80 -29 15 -29 15 -24 -7z"/>
<path d="M5840 6898 c5 -18 14 -60 20 -92 l12 -59 18 29 c36 57 36 80 -3 119
-44 44 -58 45 -47 3z"/>
<path d="M11082 6984 c-18 -12 -22 -25 -22 -66 l0 -50 58 7 c31 4 58 8 60 10
8 8 -21 91 -36 103 -21 16 -33 15 -60 -4z"/>
<path d="M10175 6730 c-94 -20 -135 -47 -135 -90 0 -53 55 -69 167 -50 82 13
130 39 139 76 14 56 -67 87 -171 64z"/>
<path d="M1732 6634 c-31 -21 -29 -67 3 -93 55 -43 229 -57 265 -21 27 27 25
52 -4 79 -47 44 -219 67 -264 35z"/>
<path d="M11245 6468 c-81 -30 -125 -92 -125 -179 0 -66 23 -114 73 -150 93
-68 237 -37 287 61 26 51 26 133 1 180 -40 76 -155 119 -236 88z"/>
<path d="M620 5930 c0 -38 3 -70 7 -70 15 0 173 65 173 72 0 5 -162 68 -176
68 -2 0 -4 -31 -4 -70z"/>
<path d="M1797 5365 c-61 -17 -87 -40 -87 -76 0 -47 36 -63 128 -57 146 11
221 69 162 128 -16 16 -33 20 -87 19 -38 0 -90 -7 -116 -14z"/>
<path d="M10020 5048 c-34 -18 -39 -69 -11 -95 25 -23 111 -52 180 -60 38 -4
57 -2 76 11 34 23 34 67 -1 95 -53 42 -201 71 -244 49z"/>
<path d="M9903 4650 c-27 -11 -39 -51 -23 -80 16 -30 133 -83 195 -88 66 -5
95 9 95 48 0 50 -49 85 -165 116 -57 16 -71 16 -102 4z"/>
<path d="M9542 3888 c-7 -7 -12 -27 -12 -44 0 -39 43 -79 134 -124 68 -34 106
-38 129 -12 27 30 20 62 -22 102 -74 71 -195 112 -229 78z"/>
<path d="M2441 3800 c-79 -44 -118 -76 -129 -107 -21 -62 24 -95 96 -70 62 22
152 87 169 123 15 30 15 35 -1 58 -22 35 -66 34 -135 -4z"/>
<path d="M1208 3673 c-27 -2 -48 -6 -48 -8 0 -3 14 -33 31 -68 l31 -63 52 55
c87 94 86 91 30 89 -27 -1 -70 -3 -96 -5z"/>
<path d="M3035 3118 c-141 -79 -211 -238 -105 -238 63 0 200 131 200 190 0 51
-47 75 -95 48z"/>
<path d="M8729 2896 c-54 -26 -24 -111 69 -198 60 -56 94 -68 122 -43 40 36
14 108 -67 187 -58 57 -91 71 -124 54z"/>
<path d="M3670 2584 c-29 -19 -84 -88 -111 -141 -35 -68 -18 -123 39 -123 52
0 162 147 162 217 0 31 -26 63 -51 63 -8 0 -25 -8 -39 -16z"/>
<path d="M8040 2405 c-20 -24 -14 -72 16 -133 58 -119 132 -165 170 -107 14
22 15 31 4 71 -15 54 -80 158 -109 173 -28 16 -66 13 -81 -4z"/>
<path d="M4000 2343 c-42 -47 -90 -149 -90 -189 0 -40 30 -69 64 -60 37 9 74
52 112 131 38 78 41 100 18 133 -24 35 -66 29 -104 -15z"/>
<path d="M7681 2224 c-20 -15 -23 -23 -19 -68 8 -94 83 -216 133 -216 64 0 71
83 16 194 -45 92 -86 120 -130 90z"/>
<path d="M2555 2119 l-40 -40 33 -30 c37 -33 71 -37 95 -11 25 28 21 55 -16
90 l-33 32 -39 -41z"/>
<path d="M4375 2166 c-35 -45 -75 -148 -75 -191 0 -36 28 -75 54 -75 47 0 136
152 136 233 0 70 -71 91 -115 33z"/>
<path d="M7290 2070 c-34 -34 -20 -134 34 -238 31 -61 93 -67 116 -13 30 74
-54 271 -116 271 -8 0 -23 -9 -34 -20z"/>
<path d="M4776 2037 c-49 -49 -87 -225 -56 -262 18 -22 66 -19 90 7 26 28 60
119 67 180 10 86 -47 129 -101 75z"/>
<path d="M6493 1900 c-30 -12 -37 -45 -31 -144 6 -91 26 -141 62 -157 73 -33
104 80 65 233 -5 20 -21 46 -36 57 -29 23 -29 23 -60 11z"/>
<path d="M7517 1098 c-24 -7 -36 -15 -33 -23 2 -7 10 -31 17 -54 14 -46 16
-47 66 -26 58 24 69 59 33 95 -22 22 -28 23 -83 8z"/>
<path d="M6875 956 c-69 -26 -125 -106 -125 -181 0 -70 56 -156 117 -181 82
-35 182 -5 230 68 38 57 40 154 4 211 -48 78 -144 113 -226 83z"/>
</g>
</svg>
    </>
  )
}
