import React from 'react';

const Iran = () => {
    return (
        <>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="100.000000pt" viewBox="0 0 1024.000000 1032.000000"
            preserveAspectRatio="xMidYMid meet" className='md:-mt-14 -mt-4'>

            <g transform="translate(0.000000,1032.000000) scale(0.100000,-0.100000)"
            fill="#ffffff" stroke="none">
            <path d="M4985 10148 c-53 -57 -172 -179 -264 -273 -91 -93 -265 -271 -386
            -395 l-219 -225 -765 -5 -766 -5 -3 -517 -2 -518 175 0 175 0 0 335 c0 320 1
            335 19 345 13 6 236 10 653 10 l633 1 428 426 429 426 76 -75 c42 -41 236
            -232 430 -424 l354 -349 646 -5 647 -5 3 -342 2 -343 175 0 175 0 0 520 0 520
            -764 0 -763 0 -123 128 c-310 320 -853 872 -860 872 -4 0 -51 -46 -105 -102z"/>
            <path d="M4823 8958 c-153 -152 -324 -320 -381 -374 l-103 -98 113 -113 c62
            -62 119 -113 126 -113 11 0 212 184 430 394 40 39 77 72 82 74 5 2 55 -39 111
            -90 56 -51 127 -115 158 -142 31 -27 100 -90 155 -140 54 -50 101 -92 105 -94
            4 -1 58 50 121 114 l115 116 -175 170 c-96 93 -266 260 -377 370 l-202 201
            -278 -275z"/>
            <path d="M3227 8553 c-4 -3 -7 -154 -7 -335 l0 -328 205 0 205 0 0 150 0 150
            260 0 260 0 -2 183 -3 182 -456 3 c-250 1 -459 -1 -462 -5z"/>
            <path d="M6040 8375 l0 -185 258 -2 257 -3 3 -147 3 -148 199 0 200 0 -2 333
            -3 332 -457 3 -458 2 0 -185z"/>
            <path d="M4819 7926 c-2 -3 -54 -10 -114 -16 -162 -17 -323 -51 -546 -114 -86
            -24 -221 -79 -364 -146 -407 -193 -853 -542 -1182 -925 -60 -71 -64 -53 52
            -210 207 -277 528 -598 762 -762 224 -158 444 -283 608 -348 39 -15 74 -31 80
            -36 5 -5 15 -9 23 -9 8 0 28 -7 45 -15 112 -51 425 -136 626 -170 46 -7 89
            -16 97 -19 12 -4 14 -69 14 -374 0 -203 3 -417 7 -476 l6 -106 171 2 171 3 -1
            140 c-1 77 -2 368 -3 646 l-1 507 -167 7 c-170 7 -204 12 -326 39 -139 32
            -374 113 -527 183 -94 42 -281 139 -330 171 -321 207 -407 278 -699 571 l-185
            185 65 59 c144 132 366 298 555 415 56 34 104 62 107 62 3 0 31 15 64 33 188
            106 491 215 728 262 188 38 214 40 490 40 275 0 304 -3 490 -40 364 -73 730
            -225 1100 -458 147 -92 485 -347 485 -366 0 -16 -145 -136 -260 -217 -47 -32
            -286 -174 -295 -174 -1 0 -45 -20 -96 -44 -96 -44 -204 -86 -339 -130 l-75
            -24 -3 -196 c-3 -223 -12 -206 93 -181 110 25 350 103 404 132 15 7 30 13 34
            13 17 0 246 119 327 170 228 142 433 318 569 490 68 85 141 186 141 194 0 10
            -297 280 -394 360 -384 313 -821 562 -1226 699 -194 66 -287 90 -500 133 -96
            19 -306 37 -476 40 -110 2 -203 2 -205 0z"/>
            <path d="M2129 7270 c-310 -314 -567 -577 -571 -584 -4 -7 112 -131 300 -321
            168 -171 400 -405 515 -521 l208 -211 -2 -734 -2 -734 527 -3 526 -2 0 590 0
            590 -177 -2 -178 -3 -5 -410 -5 -410 -160 4 c-88 1 -162 5 -165 8 -3 3 -5 140
            -5 306 0 166 -2 451 -3 632 l-2 330 -151 155 c-83 85 -269 274 -413 420 -144
            146 -270 275 -280 288 l-20 22 440 440 c241 242 440 446 442 453 1 6 -55 70
            -126 140 l-129 128 -564 -571z"/>
            <path d="M7385 7710 l-130 -130 55 -56 c30 -31 228 -232 440 -446 212 -215
            385 -396 385 -404 0 -7 -108 -122 -240 -256 -131 -134 -324 -329 -427 -434
            l-188 -190 0 -637 0 -637 -167 2 -168 3 0 405 0 405 -175 5 c-96 3 -178 4
            -182 2 -5 -2 -8 -268 -8 -593 l0 -589 532 0 531 0 -6 738 -6 737 278 280 c481
            484 746 756 746 765 0 4 -74 84 -165 177 -295 302 -955 969 -966 975 -6 4 -68
            -51 -139 -122z"/>
            <path d="M4940 7384 c-163 -32 -327 -120 -446 -239 -184 -182 -269 -428 -244
            -699 38 -393 362 -719 753 -755 105 -10 257 1 323 24 25 8 50 15 57 15 22 0
            171 82 227 125 159 123 252 256 311 445 21 66 24 94 24 240 0 143 -3 174 -22
            235 -91 286 -297 495 -579 585 -57 19 -96 23 -224 26 -85 1 -166 0 -180 -2z
            m-120 -221 c0 -14 -75 -123 -85 -123 -17 0 -55 30 -55 44 0 7 28 29 62 49 62
            37 78 43 78 30z m658 -46 c23 -15 42 -31 42 -35 -1 -13 -51 -44 -64 -39 -12 5
            -76 106 -76 121 0 9 50 -15 98 -47z m-458 -82 c0 -118 0 -118 -80 -95 -63 18
            -67 25 -46 62 25 43 106 138 117 138 5 0 9 -47 9 -105z m214 61 c38 -38 96
            -116 96 -130 0 -7 -91 -35 -125 -38 l-30 -3 -3 108 c-2 70 1 107 8 107 6 0 30
            -20 54 -44z m-612 -161 c26 -17 48 -38 48 -47 0 -10 -9 -44 -19 -75 -11 -32
            -22 -76 -25 -98 -4 -22 -9 -52 -12 -67 l-6 -28 -94 0 c-109 0 -105 -6 -73 109
            29 102 108 249 128 239 3 -2 27 -17 53 -33z m1078 -65 c12 -25 25 -53 30 -62
            13 -24 51 -178 46 -186 -2 -4 -46 -5 -97 -2 l-93 5 -12 75 c-7 41 -22 102 -33
            134 -12 33 -19 61 -17 63 2 2 28 19 57 38 l54 34 22 -27 c12 -15 31 -47 43
            -72z m-680 -172 l0 -77 -126 -3 c-69 -2 -127 -1 -129 2 -3 5 31 174 39 196 3
            8 36 4 110 -15 l106 -26 0 -77z m378 100 c9 -28 32 -146 32 -170 0 -5 -58 -8
            -130 -8 l-130 0 0 78 0 77 103 26 c56 14 104 27 107 27 4 1 11 -13 18 -30z
            m-790 -370 c6 -24 13 -63 17 -87 4 -25 16 -69 26 -99 10 -30 16 -58 14 -63 -6
            -9 -93 -69 -101 -69 -48 0 -172 319 -137 353 3 4 43 7 88 7 l83 0 10 -42z
            m412 -33 c0 -41 1 -78 3 -82 1 -5 -23 -13 -53 -20 -30 -6 -77 -18 -105 -27
            -46 -14 -51 -14 -62 0 -7 9 -15 37 -18 63 -4 25 -11 61 -16 80 -14 56 -3 61
            132 61 l119 0 0 -75z m406 60 c7 -17 -14 -175 -25 -194 -6 -10 -20 -9 -67 4
            -32 9 -78 21 -102 26 -24 5 -48 11 -53 14 -10 7 -13 148 -2 158 3 4 60 7 125
            7 96 0 120 -3 124 -15z m350 0 c7 -17 -30 -160 -51 -202 -8 -15 -15 -32 -15
            -36 0 -6 -46 -79 -66 -105 -5 -8 -114 63 -114 74 0 6 9 37 20 69 11 33 23 84
            27 114 11 102 10 101 108 101 67 0 87 -3 91 -15z m-754 -411 c2 -58 0 -108 -3
            -110 -7 -4 -74 66 -118 124 -39 50 -33 66 32 81 90 20 85 24 89 -95z m283 82
            l36 -16 -18 -31 c-26 -46 -123 -149 -139 -149 -11 0 -14 23 -14 111 l0 112 49
            -6 c27 -3 65 -13 86 -21z m-522 -145 c20 -33 37 -62 37 -65 0 -9 -44 10 -100
            45 l-53 33 29 23 c16 12 34 23 39 23 6 0 27 -27 48 -59z m717 39 l25 -20 -30
            -25 c-35 -29 -92 -58 -105 -53 -11 4 60 118 74 118 6 0 22 -9 36 -20z"/>
            <path d="M6039 4511 l-77 -6 -318 -310 c-516 -502 -535 -520 -547 -523 -7 -1
            -201 187 -432 418 l-420 420 -182 0 -183 0 0 -175 0 -175 105 0 106 0 497
            -500 c274 -275 502 -500 507 -500 6 0 235 225 510 500 l500 500 108 0 107 0
            -2 178 -3 177 -100 1 c-55 0 -134 -2 -176 -5z"/>
            <path d="M4653 3063 c-12 -27 -29 -64 -37 -83 -15 -33 -33 -111 -40 -170 -2
            -14 -9 -79 -16 -145 -13 -127 -29 -174 -79 -243 -29 -40 -35 -43 -64 -36 -49
            10 -82 39 -96 81 -13 39 -10 266 5 392 10 91 -21 47 -78 -109 -18 -48 -21 -89
            -27 -320 l-6 -265 -29 -53 c-35 -63 -83 -102 -143 -115 -61 -12 -69 -1 -39 54
            46 84 66 186 77 402 5 111 10 209 11 217 3 34 -2 113 -7 109 -7 -8 -66 -201
            -70 -232 -2 -15 -7 -32 -11 -38 -3 -6 -8 -99 -10 -207 l-4 -197 -35 -70 c-19
            -38 -35 -75 -35 -81 0 -6 19 -26 41 -44 34 -27 50 -33 87 -32 28 0 55 8 71 20
            46 33 95 109 124 189 50 137 42 126 77 108 79 -41 202 -27 290 34 104 71 226
            247 245 353 6 33 3 41 -23 68 -52 51 -106 38 -188 -46 -30 -30 -54 -49 -54
            -42 0 7 11 51 24 98 33 117 81 450 65 450 -2 0 -14 -21 -26 -47z m105 -529
            c12 -8 22 -21 22 -28 0 -19 -49 -62 -105 -92 -53 -28 -165 -46 -165 -26 0 6
            33 45 74 87 66 68 77 75 112 75 22 0 50 -7 62 -16z"/>
            <path d="M7923 2953 c-16 -27 -35 -64 -43 -83 -7 -19 -17 -40 -23 -47 -12 -16
            -4 -27 56 -75 l52 -40 36 41 c77 86 69 167 -23 232 l-26 19 -29 -47z"/>
            <path d="M3348 2811 c-57 -121 -67 -172 -102 -515 -18 -172 -15 -231 17 -289
            30 -54 72 -77 141 -77 95 0 188 62 264 175 40 58 116 215 108 222 -8 8 -46
            -26 -46 -42 0 -6 -17 -30 -38 -53 -33 -36 -45 -42 -79 -42 -28 0 -46 6 -61 23
            -31 33 -27 67 12 113 33 38 35 39 102 39 66 0 69 1 72 24 12 80 -133 60 -190
            -26 -26 -40 -88 -174 -88 -191 0 -5 -7 -15 -15 -22 -27 -22 -90 3 -109 44 -29
            61 -21 240 24 534 6 40 10 84 8 96 -3 21 -5 20 -20 -13z"/>
            <path d="M5216 2726 c-9 -14 -16 -28 -16 -30 0 -3 -11 -29 -23 -58 -42 -95
            -56 -142 -66 -228 -15 -122 -36 -233 -61 -323 -12 -43 -20 -83 -18 -89 10 -28
            106 149 136 249 11 37 28 129 37 203 9 74 21 171 26 214 5 43 8 81 6 83 -3 2
            -12 -8 -21 -21z"/>
            <path d="M7568 2698 c-57 -116 -86 -211 -97 -318 -10 -105 -24 -172 -62 -308
            -35 -124 -2 -104 70 44 67 136 72 162 106 473 9 79 14 148 13 153 -2 5 -15
            -15 -30 -44z"/>
            <path d="M6061 2601 c-22 -43 -41 -88 -41 -99 0 -22 79 -92 103 -92 22 0 76
            69 88 112 7 28 6 46 -4 71 -15 35 -70 87 -92 87 -7 0 -31 -36 -54 -79z"/>
            <path d="M2356 2627 c-14 -23 -35 -67 -48 -96 l-22 -54 35 -29 c73 -63 76 -64
            109 -33 16 16 37 48 46 71 24 62 13 105 -38 149 -23 19 -45 35 -49 35 -5 0
            -19 -19 -33 -43z"/>
            <path d="M5882 2658 c-18 -22 -82 -156 -82 -171 0 -21 98 -97 115 -91 21 9 61
            65 74 107 18 53 6 91 -42 132 -45 39 -50 41 -65 23z"/>
            <path d="M2127 2592 c-64 -122 -64 -118 -10 -168 27 -24 55 -44 64 -44 21 0
            60 52 82 108 15 37 15 47 3 76 -12 29 -80 96 -97 96 -4 0 -23 -31 -42 -68z"/>
            <path d="M8374 2488 c-30 -37 -81 -141 -90 -185 -4 -20 1 -36 16 -56 18 -22
            29 -27 65 -27 24 0 46 -4 49 -9 3 -4 -8 -29 -25 -55 -27 -41 -114 -114 -222
            -186 -66 -45 -41 -53 57 -19 109 38 143 57 180 102 20 24 36 47 36 51 0 3 9
            25 20 49 28 62 60 185 60 235 0 54 -22 97 -60 117 -42 22 -55 19 -86 -17z"/>
            <path d="M7180 2485 c-6 -14 -10 -29 -10 -33 0 -4 -19 -59 -42 -122 -46 -129
            -79 -177 -176 -260 -36 -30 -82 -70 -103 -89 -63 -54 -31 -67 75 -29 134 47
            169 87 226 258 17 51 46 180 57 256 6 40 -14 54 -27 19z"/>
            <path d="M8057 2468 c-8 -24 -29 -85 -47 -137 -47 -141 -83 -190 -213 -293
            -48 -39 -92 -79 -98 -89 -8 -17 -6 -19 20 -19 59 0 192 61 233 107 46 50 103
            223 128 387 13 82 0 107 -23 44z"/>
            <path d="M2980 2382 c0 -19 -85 -94 -132 -118 -57 -29 -173 -68 -233 -80 -22
            -4 -47 -9 -55 -10 -69 -16 -228 -25 -395 -21 -170 3 -210 7 -266 25 -72 24
            -104 51 -114 97 -20 89 -28 87 -48 -17 -34 -176 -32 -202 19 -254 57 -56 139
            -74 342 -74 388 0 715 101 815 253 58 87 102 207 78 207 -6 0 -11 -4 -11 -8z"/>
            <path d="M6722 2366 c-21 -46 -140 -123 -217 -142 -16 -4 -35 -10 -41 -15 -12
            -10 -146 -36 -254 -50 -41 -6 -169 -8 -285 -6 -197 3 -214 5 -278 29 -88 34
            -105 50 -113 109 -11 85 -30 60 -49 -66 -21 -139 -17 -177 27 -222 55 -57 132
            -73 343 -73 99 0 204 5 234 10 31 6 90 17 131 24 186 35 335 100 415 181 48
            49 60 71 122 233 9 23 -24 12 -35 -12z"/>
            <path d="M8104 1591 c-26 -12 -104 -99 -104 -118 0 -10 11 -6 37 12 31 20 49
            25 98 24 67 -1 107 -10 112 -24 2 -6 -33 -28 -76 -49 -91 -44 -129 -80 -186
            -173 -33 -55 -52 -74 -107 -107 -89 -55 -131 -96 -204 -204 -1 -2 -16 4 -33
            12 -69 36 -122 8 -190 -102 -27 -42 -58 -92 -70 -110 -12 -18 -21 -42 -21 -52
            0 -19 -19 -30 -50 -30 -13 0 -20 10 -25 36 -19 103 -95 147 -152 87 -36 -38
            -83 -141 -83 -180 0 -56 43 -82 123 -74 29 3 19 -27 -24 -74 -37 -40 -97 -83
            -233 -166 -26 -17 -46 -33 -43 -36 7 -6 147 26 162 37 6 4 25 11 42 15 49 10
            118 83 161 172 36 75 38 77 87 89 27 7 81 29 120 48 67 33 97 44 230 82 103
            30 202 154 180 224 -8 24 -60 39 -97 29 -75 -21 11 78 94 109 52 19 84 20 176
            6 36 -5 47 -3 67 16 47 44 40 104 -19 159 -47 44 -30 64 95 117 46 19 102 43
            124 52 50 21 115 79 115 102 0 23 -15 34 -75 53 -60 20 -202 31 -231 18z
            m-508 -733 c6 -19 -2 -31 -45 -69 -51 -45 -146 -98 -157 -87 -6 7 82 147 105
            166 8 6 32 12 52 12 30 0 39 -4 45 -22z m188 16 c26 -10 18 -41 -17 -68 -27
            -21 -178 -96 -193 -96 -11 0 0 21 40 76 56 77 117 108 170 88z"/>
            <path d="M5204 1489 c-20 -40 -29 -64 -62 -167 -7 -21 -10 -105 -9 -215 2
            -104 -2 -209 -8 -244 -14 -74 -51 -134 -105 -168 -44 -28 -110 -34 -110 -11 0
            8 4 17 9 20 15 9 49 118 65 206 8 47 18 179 21 295 l6 210 -20 -50 c-56 -147
            -72 -239 -72 -419 0 -140 -15 -220 -50 -264 -28 -36 -24 -57 19 -92 48 -40 74
            -46 116 -26 66 31 126 116 161 227 l18 56 64 -5 65 -5 71 77 c67 71 75 76 113
            76 34 0 46 6 72 34 17 19 40 37 51 41 26 8 79 88 87 131 9 51 0 61 -20 21 -11
            -24 -24 -37 -36 -37 -12 0 -30 -16 -45 -40 -23 -36 -29 -40 -67 -40 -32 0 -50
            -8 -84 -35 -51 -42 -81 -54 -125 -51 -86 7 -118 62 -119 202 0 50 5 127 11
            171 6 44 9 95 7 113 l-3 33 -21 -44z"/>
            <path d="M3725 1253 c-20 -43 -40 -86 -46 -96 -6 -10 -16 -64 -24 -120 -22
            -162 -34 -226 -60 -321 -37 -135 -18 -129 56 17 52 103 68 163 90 342 10 83
            21 174 25 203 4 28 4 52 0 52 -3 0 -22 -35 -41 -77z"/>
            <path d="M2895 1258 c-14 -35 -34 -78 -42 -96 -13 -30 -22 -76 -39 -196 -9
            -68 -31 -179 -52 -261 -33 -130 -11 -114 68 50 52 109 62 150 85 370 8 77 17
            152 20 168 8 47 -11 30 -40 -35z"/>
            <path d="M5939 1253 c-52 -116 -67 -165 -74 -248 -7 -85 -36 -231 -61 -303 -7
            -23 -14 -52 -14 -64 0 -20 1 -20 19 -4 25 23 87 150 111 229 10 34 24 105 30
            157 6 52 13 106 15 120 11 84 17 180 11 180 -4 0 -21 -30 -37 -67z"/>
            <path d="M4636 1285 c-16 -16 -76 -139 -76 -155 0 -6 5 -19 12 -29 10 -17 3
            -29 -64 -100 -89 -95 -132 -166 -149 -248 -17 -84 -5 -112 68 -149 30 -16 58
            -35 61 -42 12 -32 -21 -97 -81 -157 -51 -51 -74 -65 -127 -82 -119 -38 -241
            -24 -293 34 -32 36 -60 133 -54 188 6 58 44 177 87 270 19 40 50 130 50 144 0
            11 -3 11 -15 1 -8 -7 -15 -16 -15 -21 0 -5 -18 -48 -40 -95 -22 -47 -40 -88
            -40 -91 0 -3 -7 -19 -14 -36 -82 -177 -95 -395 -31 -510 69 -123 184 -147 343
            -70 113 55 190 151 256 317 37 92 38 97 43 202 l5 92 -35 26 c-18 14 -38 26
            -44 26 -15 0 -43 35 -43 54 0 31 90 114 181 167 131 77 181 139 165 204 -11
            43 -122 88 -150 60z"/>
            <path d="M6740 1233 c-61 -30 -209 -171 -278 -264 -67 -89 -104 -182 -110
            -269 -4 -69 -2 -77 19 -96 13 -11 45 -28 71 -38 25 -9 51 -23 57 -30 16 -19
            -4 -70 -51 -133 -74 -97 -157 -144 -268 -151 -84 -5 -141 7 -190 40 -106 72
            -93 205 52 532 48 108 56 136 39 136 -15 0 -75 -117 -162 -315 -83 -189 -96
            -436 -27 -523 94 -120 308 -116 455 9 117 99 223 345 223 517 0 75 -15 101
            -75 133 -66 35 -69 59 -17 125 80 102 199 177 242 154 21 -11 33 -3 55 39 18
            36 55 138 55 153 0 15 -35 8 -90 -19z"/>
            <path d="M3466 1153 c-19 -37 -51 -65 -106 -92 -71 -36 -99 -69 -152 -179 -55
            -113 -81 -143 -166 -199 -177 -115 -189 -142 -47 -113 56 12 96 26 119 44 33
            25 79 106 105 187 16 50 66 103 126 134 67 35 84 52 111 114 24 54 42 131 31
            131 -4 0 -13 -12 -21 -27z"/>
            <path d="M2585 1058 c-37 -80 -39 -89 -47 -233 -9 -138 -12 -154 -39 -206 -37
            -70 -110 -153 -162 -186 -144 -91 -366 -83 -436 16 -52 73 -40 225 29 349 19
            35 50 115 50 129 0 32 -27 -8 -84 -122 -141 -280 -139 -503 4 -605 66 -47 225
            -45 327 5 76 36 189 148 246 242 53 89 103 213 113 282 4 25 10 53 15 62 5 10
            13 79 19 155 5 77 13 151 16 167 11 53 -14 26 -51 -55z"/>
            <path d="M2197 913 c-21 -33 -67 -129 -67 -139 0 -2 25 -22 57 -45 63 -47 68
            -46 117 14 23 29 29 47 29 84 0 43 -3 50 -44 85 -24 21 -50 38 -56 38 -7 0
            -23 -17 -36 -37z"/>
            <path d="M8246 820 c-25 -50 -43 -96 -40 -103 9 -24 103 -89 116 -81 41 25 93
            132 82 167 -8 27 -83 107 -100 107 -7 0 -33 -41 -58 -90z"/>
            <path d="M6944 815 c-6 -11 -19 -45 -28 -75 -10 -30 -21 -64 -26 -75 -4 -11
            -15 -41 -24 -66 -33 -93 -99 -170 -240 -277 -81 -62 -73 -76 29 -48 134 37
            186 81 228 192 39 101 81 295 75 344 -3 24 -4 24 -14 5z"/>
            <path d="M3348 418 c-31 -60 -58 -119 -58 -128 0 -10 49 -59 78 -78 21 -14 25
            -13 52 13 36 34 65 105 56 138 -6 27 -73 97 -92 97 -8 0 -24 -19 -36 -42z"/>
            <path d="M3158 428 c-30 -46 -68 -128 -68 -146 0 -19 78 -92 97 -92 17 0 61
            54 68 83 4 15 11 29 15 32 18 11 -10 81 -44 113 -42 38 -49 39 -68 10z"/>
            </g>
            </svg>
        </>
    );
}

export default Iran;
