import React from 'react'

export default function Nasa() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 1024.000000 1024.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1024.000000) scale(0.100000,-0.100000)"
fill="#fff" stroke="none">
<path d="M4663 9899 c-175 -30 -290 -128 -329 -279 -19 -74 -14 -166 12 -243
22 -65 101 -149 172 -184 53 -26 66 -28 177 -28 102 0 129 4 183 24 69 26 82
41 82 96 l0 36 -55 -40 c-177 -126 -363 -39 -405 190 -37 200 64 369 220 369
48 0 116 -26 139 -53 18 -22 41 -22 41 1 0 9 3 34 6 54 6 36 5 37 -32 46 -62
16 -155 21 -211 11z"/>
<path d="M5130 9903 c0 -4 -7 -111 -15 -237 -19 -290 -11 -349 62 -418 58 -55
116 -73 243 -73 109 0 118 2 169 30 62 34 97 76 116 139 15 49 30 197 41 397
l7 126 -39 6 c-21 4 -39 5 -40 4 -1 -1 -10 -103 -18 -227 -17 -246 -25 -286
-65 -342 -34 -49 -76 -68 -146 -68 -67 0 -117 21 -146 61 -39 55 -43 115 -27
364 8 127 14 231 14 231 -4 6 -156 13 -156 7z"/>
<path d="M6005 9818 c-5 -14 -151 -631 -157 -662 -2 -16 9 -21 72 -36 41 -10
75 -18 76 -17 1 1 16 67 34 147 18 80 36 152 40 160 8 14 18 -14 88 -255 l30
-100 58 -12 c33 -6 72 -16 88 -23 16 -6 30 -9 32 -7 2 2 -22 78 -55 168 -32
90 -62 178 -65 195 -7 31 -6 32 38 38 60 9 106 42 134 96 41 82 22 170 -48
217 -33 21 -94 39 -338 98 -13 3 -24 0 -27 -7z m259 -130 c37 -45 6 -178 -49
-214 -26 -17 -112 -22 -125 -7 -6 7 38 215 55 260 6 19 96 -11 119 -39z"/>
<path d="M3935 9753 c-93 -25 -171 -46 -172 -47 -2 -2 163 -648 172 -673 3 -8
55 2 175 32 94 24 179 47 189 50 16 5 17 10 7 45 -5 22 -13 40 -16 40 -3 0
-52 -16 -110 -34 -75 -25 -107 -31 -111 -23 -12 23 -61 238 -56 243 7 8 148
44 172 44 38 1 45 13 34 54 -8 27 -15 36 -25 32 -30 -11 -188 -56 -199 -56
-13 0 -66 208 -54 216 5 3 55 14 111 25 56 11 103 21 105 22 1 2 0 20 -4 40
-4 30 -10 37 -27 36 -11 0 -97 -21 -191 -46z"/>
<path d="M6682 9613 c-24 -59 -231 -637 -229 -638 1 -1 27 -11 57 -22 30 -11
62 -23 71 -27 13 -6 33 42 114 266 54 150 108 301 121 335 l23 61 -57 20 c-31
11 -64 23 -73 26 -12 5 -19 -1 -27 -21z"/>
<path d="M3430 9611 c-86 -27 -158 -89 -180 -157 -27 -81 14 -200 84 -244 33
-20 58 -25 221 -49 59 -8 74 -15 98 -41 103 -115 -90 -253 -204 -144 -15 14
-29 21 -33 16 -9 -15 12 -125 26 -134 38 -26 194 23 261 82 90 79 109 192 50
289 -40 64 -92 88 -233 106 -127 17 -159 32 -174 83 -13 45 8 89 55 113 51 26
89 24 132 -6 20 -13 39 -20 41 -16 3 4 3 31 -1 60 l-6 51 -56 -1 c-31 0 -67
-4 -81 -8z"/>
<path d="M6987 9495 c-9 -20 -17 -37 -17 -39 0 -2 38 -19 85 -37 47 -18 85
-36 85 -40 0 -4 -63 -133 -140 -288 l-139 -281 70 -35 70 -35 139 282 c77 154
142 283 144 286 3 2 39 -17 82 -42 42 -26 77 -46 78 -44 1 2 9 20 18 40 l17
37 -236 115 c-129 64 -236 116 -237 116 -1 0 -10 -16 -19 -35z"/>
<path d="M7580 9222 c0 -9 -9 -93 -20 -187 -11 -93 -20 -188 -20 -210 0 -34
-14 -61 -87 -169 -79 -118 -85 -131 -68 -140 10 -6 38 -26 62 -44 25 -18 47
-32 49 -29 2 2 43 62 92 133 l88 129 193 97 c106 53 195 99 197 101 3 2 -13
16 -35 30 l-39 26 -128 -74 c-130 -76 -161 -91 -171 -81 -5 4 30 264 42 316 2
10 -26 34 -76 67 -61 41 -79 49 -79 35z"/>
<path d="M2510 9120 c-25 -16 -53 -35 -64 -42 -17 -13 -6 -33 168 -308 103
-162 192 -294 197 -292 8 2 304 186 318 198 4 4 -39 74 -46 74 -3 0 -24 -15
-47 -33 -73 -59 -138 -99 -145 -91 -5 5 -81 125 -171 267 -89 141 -163 257
-164 257 0 0 -21 -14 -46 -30z"/>
<path d="M4767 8949 c-531 -51 -1058 -215 -1512 -469 -774 -432 -1373 -1112
-1700 -1930 -459 -1143 -339 -2447 319 -3480 221 -347 531 -694 847 -946 863
-692 1988 -977 3079 -783 1381 247 2528 1243 2970 2580 207 627 246 1337 111
1990 -107 511 -325 1010 -632 1441 -122 171 -249 319 -418 488 -644 643 -1464
1027 -2371 1109 -133 13 -564 12 -693 0z m227 -1140 c148 -21 260 -78 297
-151 31 -60 57 -199 74 -388 9 -97 25 -203 40 -256 13 -50 21 -101 18 -112 -3
-12 4 -53 16 -91 12 -38 21 -82 21 -98 0 -15 11 -67 24 -115 13 -47 22 -89 18
-92 -3 -3 96 -6 221 -6 124 0 228 -4 231 -8 2 -4 13 -60 23 -123 25 -160 25
-561 -1 -702 -17 -93 -62 -264 -84 -317 -8 -18 -11 65 -11 298 l-1 322 -70 0
-70 0 0 -462 1 -463 -41 -55 c-22 -30 -58 -75 -80 -100 l-40 -44 0 562 0 562
-75 0 -75 0 0 -643 0 -643 -30 -29 c-17 -16 -29 -34 -29 -39 5 -45 -2 -66 -23
-67 -21 -1 -22 -1 -3 -7 16 -6 20 -17 22 -60 1 -37 -2 -52 -10 -49 -61 22
-149 48 -152 45 -3 -2 33 -16 79 -31 l84 -27 12 -81 c7 -44 10 -83 6 -86 -3
-3 -2 -11 3 -17 5 -6 12 -29 16 -50 6 -38 6 -39 -12 -26 -17 14 -17 14 -1 -5
9 -11 20 -45 23 -74 6 -52 5 -54 -12 -40 -17 13 -17 13 0 -8 15 -17 17 -30 12
-67 -5 -30 -3 -51 5 -60 6 -8 10 -23 9 -33 -2 -10 0 -31 4 -48 3 -16 9 -73 12
-125 4 -52 8 -97 10 -100 2 -4 5 -35 6 -71 1 -35 8 -78 15 -96 9 -19 15 -85
16 -176 l3 -145 33 25 c28 21 48 26 117 30 l83 6 3 -41 4 -40 -55 -17 c-80
-25 -146 -63 -186 -108 -32 -35 -39 -38 -69 -33 -44 9 -46 13 -30 60 8 21 9
34 4 29 -5 -5 -13 -24 -18 -41 -7 -24 -6 -36 5 -46 11 -11 34 -94 34 -122 0
-2 -146 -4 -323 -4 l-324 0 4 73 c4 61 1 77 -17 106 -12 18 -23 31 -26 28 -3
-3 4 -19 16 -35 31 -42 26 -149 -10 -187 -36 -38 -42 -30 -15 21 29 55 31 96
7 142 -30 55 -112 131 -177 163 -56 27 -66 29 -101 20 -54 -15 -127 -86 -121
-118 3 -17 0 -23 -14 -23 -11 0 -19 2 -19 5 0 21 48 99 81 131 l40 39 84 0
c82 0 85 -1 122 -35 20 -19 31 -27 24 -18 -13 18 -13 153 0 198 8 26 18 255
19 445 1 107 15 179 43 215 11 13 10 13 -7 0 -18 -14 -19 -13 -13 19 4 18 7
46 7 61 0 16 12 43 27 62 23 28 24 30 4 13 l-23 -20 6 45 c4 30 16 55 38 79
l33 36 -33 -30 -34 -30 7 26 c4 14 7 43 9 63 1 26 9 44 26 57 23 19 23 19 3 9
-39 -18 -29 23 10 44 29 15 20 15 -18 0 -10 -4 -15 0 -15 10 0 9 6 16 13 17
10 0 10 2 0 6 -19 7 -16 33 5 34 12 0 10 3 -8 10 -20 8 -22 12 -10 19 9 6 10
10 3 10 -6 0 -14 15 -18 33 -5 22 -38 64 -113 142 -59 60 -81 85 -49 55 l57
-54 0 637 0 637 -75 0 -75 0 0 -561 0 -560 -41 47 c-23 26 -59 71 -80 99 l-39
52 0 461 0 462 -70 0 -70 0 0 -317 c-1 -352 -1 -354 -54 -159 -32 118 -55 263
-61 393 -6 143 17 487 40 591 5 22 7 22 179 22 l173 0 42 100 c22 55 41 114
41 131 0 16 9 47 20 69 11 22 20 49 20 60 0 11 11 42 24 68 13 26 29 72 36
102 7 30 24 86 37 124 12 38 23 86 23 105 0 43 -30 97 -59 106 -12 3 -21 11
-21 16 0 6 -5 7 -12 3 -6 -4 -8 -3 -5 3 9 15 -42 59 -113 97 -64 34 -64 35
-35 42 58 15 100 6 208 -46 60 -28 112 -48 115 -44 4 4 2 9 -4 11 -7 2 -53 30
-103 62 -96 62 -139 76 -196 65 -37 -7 -75 -46 -91 -94 l-8 -25 -7 30 c-4 17
-7 66 -8 110 -2 167 60 209 283 190 84 -7 87 -8 103 -38 9 -18 17 -54 19 -82
1 -27 3 -52 3 -54 1 -2 8 2 16 9 24 20 18 73 -14 121 -16 24 -27 47 -24 52 3
5 19 15 37 23 32 14 83 12 220 -7z m-490 -364 c30 -14 60 -33 67 -42 10 -11
-2 -8 -36 9 -28 14 -71 33 -97 43 -26 10 -45 21 -42 26 5 9 30 1 108 -36z
m1357 -2172 c-12 -20 -14 -14 -5 12 4 9 9 14 11 11 3 -2 0 -13 -6 -23z m-1651
-28 c0 -5 -5 -3 -10 5 -5 8 -10 20 -10 25 0 6 5 3 10 -5 5 -8 10 -19 10 -25z
m1616 -54 c-15 -28 -28 -51 -31 -51 -3 0 7 25 22 55 15 30 29 53 31 51 2 -2
-8 -27 -22 -55z m-276 -384 c0 -1 -26 -27 -57 -57 l-58 -55 55 58 c50 53 60
62 60 54z m-1210 -1577 l38 -75 -32 -38 c-28 -34 -33 -36 -47 -22 -21 22 -85
31 -104 15 -27 -22 -17 -47 25 -64 22 -9 47 -16 55 -16 18 0 19 -11 3 -45 -13
-29 -22 -30 -61 -10 -16 8 -46 19 -66 25 l-38 10 25 77 c30 95 55 147 100 209
41 56 39 57 102 -66z m2694 68 l-19 -23 21 18 c21 18 22 17 42 -11 l21 -28
-24 -42 c-13 -23 -50 -76 -82 -118 -89 -116 -153 -207 -153 -216 0 -4 9 -8 19
-8 12 0 21 -8 24 -22 2 -13 5 -3 6 22 3 99 68 175 158 188 l48 7 7 54 c3 30
14 60 23 70 16 15 17 14 10 -14 -5 -16 -8 -55 -9 -85 l-1 -55 -42 3 c-88 8
-168 -70 -168 -163 0 -101 77 -171 179 -163 l51 4 0 -65 c0 -80 23 -119 90
-151 63 -30 122 -21 175 26 46 42 65 91 57 149 l-6 43 38 -5 c55 -7 104 12
145 57 48 54 57 113 25 177 -33 64 -67 87 -142 93 -61 5 -61 5 -69 40 -4 19
-10 56 -12 82 -2 26 -6 50 -9 55 -3 4 -8 24 -12 44 l-7 36 57 -10 c44 -8 62
-8 78 2 18 12 23 10 39 -10 10 -13 52 -93 94 -177 80 -160 106 -191 179 -216
l36 -13 -41 -12 c-63 -19 -99 -65 -182 -232 -112 -224 -190 -303 -324 -328
-163 -31 -395 127 -519 354 -42 77 -52 85 -107 85 -41 0 -48 3 -48 20 0 18 7
20 59 20 56 0 61 -2 91 -37 l32 -38 -21 42 c-26 52 -45 63 -110 63 l-51 0 0
100 0 100 49 0 c56 0 70 13 131 121 44 78 169 214 183 200 7 -7 4 -18 -9 -33z
m-1074 -76 c26 -27 52 -63 58 -79 10 -29 9 -32 -19 -44 -25 -10 -41 -9 -89 5
-76 21 -74 21 -53 -15 17 -30 80 -68 113 -69 8 0 28 7 44 15 15 8 30 15 31 15
7 0 15 -69 10 -82 -4 -10 -30 -20 -65 -27 -109 -22 -175 15 -226 124 -28 60
-31 124 -6 154 25 30 72 51 116 51 34 0 46 -7 86 -48z m282 -52 c35 -10 72
-26 83 -35 19 -15 19 -15 -5 -3 -27 14 -253 19 -266 6 -16 -16 11 -23 119 -29
62 -3 120 -9 130 -14 11 -4 27 -27 38 -51 18 -44 34 -55 42 -31 2 6 4 -3 5
-22 1 -43 12 -54 12 -13 l0 32 80 0 80 0 0 -100 0 -100 -84 0 -85 0 10 32 c5
18 8 49 8 68 -1 26 -4 21 -10 -22 -10 -69 -29 -109 -63 -132 -35 -23 -39 -20
-21 14 8 16 15 31 15 35 0 3 -69 5 -154 5 l-154 0 5 61 c5 45 12 68 29 86 l24
26 107 -12 c60 -7 111 -16 116 -20 4 -4 14 -36 21 -72 8 -35 15 -52 15 -38 1
14 -5 46 -13 72 -13 42 -12 46 5 50 15 4 21 -3 29 -31 5 -21 10 -50 11 -67 l1
-30 10 34 c5 19 6 51 2 70 -4 20 -7 39 -6 44 3 10 -16 9 -40 -4 -16 -9 -53 -8
-145 1 -121 12 -123 13 -129 39 -16 70 -27 108 -40 133 l-14 27 43 4 c95 8
128 6 189 -13z m-2199 -16 c48 -4 87 -8 87 -10 0 -2 -5 -14 -11 -28 -11 -24
-16 -25 -90 -25 -99 1 -183 -24 -229 -68 -30 -28 -40 -31 -65 -25 -16 5 -30
13 -32 18 -2 6 11 33 29 60 29 43 41 52 98 72 38 13 77 20 95 17 17 -3 69 -8
118 -11z m2616 -12 c5 -10 10 -103 11 -208 1 -181 0 -191 -20 -209 -20 -18
-24 -18 -48 -4 l-27 15 -2 197 c-2 164 1 199 13 212 21 20 61 19 73 -3z
m-2535 -73 c-10 -23 -19 -38 -21 -32 -5 14 25 88 33 81 2 -3 -3 -25 -12 -49z
m2266 -3 c0 -12 -5 -7 -19 24 -11 24 -11 24 3 6 9 -11 16 -24 16 -30z m-2300
-32 c0 -34 -49 -54 -160 -64 l-85 -7 67 -2 c40 0 89 6 125 17 l58 18 0 -93 0
-94 -130 3 -130 3 0 77 c0 97 13 113 106 127 36 6 83 17 105 25 21 8 40 15 42
15 1 1 2 -10 2 -25z m344 1 c26 -20 60 -19 118 2 34 12 53 14 69 7 21 -10 21
-10 -7 -29 -16 -11 -47 -22 -69 -26 l-40 -7 48 1 c41 1 52 -4 83 -34 43 -41
55 -69 48 -109 l-6 -30 -234 0 c-265 0 -247 -7 -222 86 9 31 20 49 34 54 12 5
24 16 28 25 5 14 14 16 49 11 23 -3 71 -8 107 -10 l65 -4 -68 7 c-80 9 -93 18
-71 49 17 25 41 28 68 7z m1340 -17 c15 -21 14 -22 -41 -40 -32 -9 -78 -29
-104 -43 -26 -14 -62 -25 -80 -25 l-32 0 23 25 c29 31 167 99 212 104 4 1 14
-9 22 -21z m-1086 -47 c37 -49 42 -95 17 -155 -9 -20 -13 -41 -10 -46 3 -5 -4
-14 -16 -20 -17 -10 -20 -9 -16 2 45 107 41 148 -19 205 -30 28 -34 36 -23 49
17 20 29 14 67 -35z m-1798 16 c0 -13 -7 -46 -15 -73 -8 -27 -15 -52 -15 -56
0 -11 68 -10 72 1 6 18 153 117 199 134 40 15 78 17 305 15 l259 -3 -2 -45 -1
-45 10 38 c7 27 15 37 27 35 12 -3 15 -13 13 -48 -1 -29 5 -59 18 -85 l19 -40
-5 56 c-8 72 1 99 29 99 21 0 22 -3 19 -72 -4 -84 14 -123 70 -159 l33 -21
-62 4 c-49 3 -68 10 -89 29 -33 31 -53 69 -64 122 -6 31 -8 35 -9 15 -1 -15 3
-38 9 -52 l10 -26 -489 0 -489 0 -31 26 c-39 32 -43 9 -5 -27 l26 -24 501 -3
c484 -2 502 -3 519 -22 19 -20 16 -20 -489 -19 -328 0 -501 4 -488 10 16 7 13
8 -13 4 -20 -3 -31 -1 -27 5 3 5 0 7 -8 4 -20 -7 -65 49 -73 91 -7 41 19 100
56 124 14 9 21 11 17 4 -6 -9 -3 -11 7 -7 9 3 13 10 10 15 -3 6 5 7 22 3 19
-5 25 -4 20 5 -5 8 9 11 48 11 52 0 56 -2 56 -23z m1259 -24 c-13 -36 -11
-119 5 -156 7 -18 28 -41 47 -51 l33 -20 -22 28 c-28 36 -36 82 -27 151 7 56
10 61 32 47 10 -6 9 -20 -3 -64 -20 -70 -14 -98 33 -146 19 -20 29 -28 22 -17
-8 11 -21 25 -29 32 -12 10 30 12 204 13 171 0 217 -3 213 -12 -21 -52 -21
-51 1 -39 12 7 22 9 22 6 0 -3 12 6 27 20 l28 25 357 0 c286 0 359 -3 365 -13
10 -16 84 -67 97 -67 6 0 -10 13 -35 29 -58 36 -87 73 -95 120 -3 20 -8 44
-11 54 -3 13 2 17 21 17 24 0 26 -4 26 -40 0 -44 23 -100 41 -100 7 0 8 7 4
18 -13 34 -17 72 -10 89 7 17 8 17 15 -2 7 -17 9 -17 9 -2 1 12 9 17 31 17 17
0 57 13 88 29 31 16 80 36 109 44 48 15 52 14 69 -4 9 -10 32 -24 50 -31 37
-13 54 -43 54 -93 l0 -35 -194 0 -195 0 -4 28 c-4 26 -4 26 -5 2 -4 -56 53
-110 114 -110 17 0 23 -4 18 -12 -4 -7 -3 -8 4 -4 22 13 12 26 -18 26 -29 0
-90 24 -90 35 0 3 79 5 175 5 173 0 175 0 163 -20 -10 -15 -24 -20 -58 -20
-25 0 -40 -4 -34 -8 6 -4 16 -16 22 -28 9 -17 5 -29 -29 -76 l-39 -56 -62 25
c-70 28 -55 35 -124 -52 l-32 -39 -61 26 -61 26 -47 -37 -47 -38 -59 23 -59
23 -87 -44 -87 -43 -82 44 -81 44 -53 -20 c-69 -26 -73 -26 -118 10 -45 35
-54 36 -111 10 -24 -11 -49 -20 -54 -20 -6 0 -30 25 -54 56 -24 30 -48 53 -55
51 -6 -3 -34 -14 -63 -25 l-51 -21 -36 52 c-38 54 -45 82 -23 90 6 2 -1 8 -18
11 -82 18 -125 79 -125 175 0 82 7 99 35 93 21 -3 22 -7 14 -29z m1871 -73 c0
-22 -6 -55 -14 -73 -13 -31 -78 -97 -96 -97 -4 0 -16 -3 -25 -6 -13 -5 -11 3
10 37 31 49 43 115 26 147 -10 19 -8 21 21 25 80 10 78 10 78 -33z m-1885
-130 c18 -20 17 -20 -106 -20 -108 0 -127 3 -149 20 l-25 20 131 0 c118 0 133
-2 149 -20z m2165 0 c-10 -19 -21 -20 -175 -20 -162 0 -165 0 -147 19 15 17
33 19 175 20 155 1 158 1 147 -19z m260 0 c0 -19 -7 -20 -110 -20 -109 0 -110
0 -93 20 15 17 30 20 110 20 86 0 93 -1 93 -20z m85 -56 c28 18 29 17 16 -17
-7 -18 -17 -24 -40 -25 -17 0 -34 4 -37 10 -5 7 -2 8 7 3 8 -5 7 0 -3 12 -26
28 -22 36 7 17 23 -15 27 -15 50 0z m-2945 -54 l0 -60 -50 0 -51 0 3 -67 3
-68 48 -3 47 -3 0 -90 0 -89 -195 0 -195 0 0 89 0 90 33 3 32 3 2 80 c1 44 0
74 -3 68 -4 -9 -37 -13 -115 -13 l-109 0 0 -70 0 -70 35 0 35 0 0 -90 0 -90
-199 0 -199 0 -1 89 -2 90 48 3 48 3 2 80 c1 44 0 74 -3 68 -3 -8 -24 -13 -50
-13 l-44 0 0 60 0 60 440 0 440 0 0 -60z"/>
<path d="M4405 7736 c-25 -25 -45 -82 -45 -130 0 -38 12 -25 35 38 9 26 31 63
47 82 28 32 29 34 9 34 -12 0 -33 -11 -46 -24z"/>
<path d="M4827 7649 c-9 -5 -24 -23 -32 -39 -14 -28 -14 -30 25 -69 28 -28 49
-41 68 -41 33 0 87 32 102 59 8 17 21 21 58 21 26 0 54 5 62 10 11 7 2 10 -33
10 -31 0 -73 11 -119 30 -74 32 -102 36 -131 19z m23 -54 c0 -32 41 -55 65
-35 8 7 15 19 15 27 0 17 31 14 37 -4 7 -19 -43 -63 -72 -63 -14 0 -37 11 -50
25 -28 27 -34 75 -10 75 9 0 15 -9 15 -25z"/>
<path d="M4658 7583 c-43 -17 -49 -32 -15 -34 61 -3 96 14 76 37 -14 17 -15
17 -61 -3z"/>
<path d="M4729 7510 c51 -38 80 -92 61 -115 -19 -23 -36 -19 -111 30 -38 24
-69 41 -68 37 0 -4 27 -28 59 -54 66 -53 113 -63 143 -30 35 39 13 86 -61 133
-60 38 -76 37 -23 -1z"/>
<path d="M4590 7435 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
-28 25 -3 0 5 -11 18 -25z"/>
<path d="M5324 7358 c2 -19 -1 -28 -10 -28 -17 0 -17 -6 0 -63 12 -39 12 -47
1 -47 -11 0 -10 -14 6 -70 23 -84 23 -90 4 -90 -19 0 -20 4 15 -73 34 -76 35
-77 16 -77 -10 0 -4 -23 20 -87 38 -99 40 -110 20 -115 -18 -4 61 -208 81
-208 9 0 6 18 -13 68 -47 122 -48 126 -37 129 7 3 -1 39 -19 95 -20 64 -26 93
-18 98 8 5 1 28 -22 74 -25 51 -30 69 -20 75 10 6 9 21 -3 75 -9 37 -13 76
-10 86 3 10 0 39 -6 65 -9 37 -9 49 1 55 10 6 10 14 1 36 l-11 29 4 -27z"/>
<path d="M4720 7303 c-2 -25 -20 -94 -34 -128 l-17 -40 20 25 c25 31 63 128
56 141 -7 11 -25 12 -25 2z"/>
<path d="M5041 6934 c-14 -10 -37 -15 -62 -12 -35 3 -38 2 -22 -10 36 -26 63
-26 91 1 30 31 26 44 -7 21z"/>
<path d="M4817 6924 c-20 -11 -42 -14 -70 -9 -40 5 -41 5 -18 -8 28 -17 85 -8
110 17 19 20 14 20 -22 0z"/>
<path d="M5266 6774 c-10 -32 -102 -114 -126 -114 -12 0 -30 5 -42 11 -11 6
-22 8 -25 6 -10 -11 9 -48 33 -64 23 -15 28 -14 64 2 51 23 120 130 108 167
-3 8 -7 5 -12 -8z"/>
<path d="M4939 6704 c-49 -50 -57 -55 -88 -50 -18 3 -42 13 -53 23 -19 17 -19
17 -12 -12 12 -51 33 -68 78 -60 22 3 46 11 53 17 17 14 86 128 81 133 -2 3
-29 -20 -59 -51z"/>
<path d="M4676 6695 c-21 -25 -45 -45 -55 -45 -21 0 -47 27 -54 55 -2 11 -5 1
-6 -22 -2 -60 6 -73 43 -73 25 0 39 10 74 51 35 43 53 79 38 79 -2 0 -20 -20
-40 -45z"/>
<path d="M5371 6535 c12 -19 28 -35 37 -35 11 0 6 10 -18 35 -19 19 -35 35
-37 35 -1 0 7 -16 18 -35z"/>
<path d="M4760 6530 l-45 -30 36 0 c28 0 41 6 53 25 30 45 18 47 -44 5z"/>
<path d="M5090 6525 l-23 -25 33 0 c30 0 59 21 60 43 0 17 -48 5 -70 -18z"/>
<path d="M4525 6520 c-16 -11 -24 -20 -17 -20 8 0 25 9 39 20 33 26 17 26 -22
0z"/>
<path d="M4788 5245 c-2 -479 0 -724 7 -723 13 3 125 -72 125 -83 0 -4 -29
-29 -65 -54 -36 -26 -65 -49 -65 -52 0 -7 134 89 138 99 2 4 8 6 13 2 5 -3 9
302 9 765 l0 771 -80 0 -80 0 -2 -725z"/>
<path d="M5100 5200 c0 -678 2 -770 15 -770 8 0 15 5 15 10 0 6 18 1 40 -10
22 -11 40 -18 40 -15 0 2 -14 12 -30 20 -17 9 -28 20 -25 25 4 6 17 3 31 -6
13 -9 27 -14 30 -11 3 3 -4 8 -15 12 -12 3 -21 10 -21 15 0 15 64 55 85 53 11
-2 14 0 8 3 -10 4 -13 159 -13 725 l0 719 -80 0 -80 0 0 -770z"/>
<path d="M5318 4513 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4788 4493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M5248 4493 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M4795 4465 l-30 -14 30 6 c17 3 37 9 45 14 27 15 -11 10 -45 -6z"/>
<path d="M4840 4450 c-19 -11 -31 -19 -27 -20 11 0 67 29 67 35 0 7 -1 7 -40
-15z"/>
<path d="M4885 4440 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M5240 4430 c8 -5 20 -10 25 -10 6 0 3 5 -5 10 -8 5 -19 10 -25 10 -5
0 -3 -5 5 -10z"/>
<path d="M4838 4414 c-38 -20 -36 -28 2 -9 17 9 30 18 30 20 0 7 -1 6 -32 -11z"/>
<path d="M5130 4427 c0 -2 21 -18 48 -36 35 -25 40 -26 17 -6 -30 26 -65 49
-65 42z"/>
<path d="M4861 4344 l-76 -77 83 74 c45 40 82 74 82 76 0 12 -22 -7 -89 -73z"/>
<path d="M5110 4407 c8 -8 40 -36 70 -62 l55 -49 -60 62 c-33 34 -64 62 -69
62 -6 0 -4 -6 4 -13z"/>
<path d="M4861 4290 c-56 -66 -100 -120 -98 -120 8 0 209 232 204 236 -2 2
-50 -50 -106 -116z"/>
<path d="M5055 4395 c-19 -8 -37 -10 -52 -4 -21 8 -30 -1 -106 -119 -101 -154
-99 -152 -94 -152 3 0 44 58 92 130 49 71 93 127 98 123 6 -3 5 -8 -1 -12 -6
-4 -2 -20 9 -41 18 -32 19 -33 20 -10 0 23 1 23 9 5 7 -15 9 -16 9 -4 1 9 7
22 15 29 8 6 12 18 9 26 -16 41 19 -4 128 -168 67 -101 126 -187 132 -193 16
-16 -1 11 -127 204 -65 100 -117 184 -114 186 2 2 32 -29 67 -70 35 -41 73
-82 84 -92 11 -10 -17 26 -62 80 -46 53 -83 97 -84 96 -1 0 -15 -7 -32 -14z"/>
<path d="M5220 4406 c0 -3 9 -10 20 -16 11 -6 20 -8 20 -6 0 3 -9 10 -20 16
-11 6 -20 8 -20 6z"/>
<path d="M5230 4356 c0 -2 14 -14 30 -26 17 -12 30 -19 30 -16 0 2 -13 14 -30
26 -16 12 -30 19 -30 16z"/>
<path d="M4759 4313 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M5300 4306 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M5280 4258 c41 -45 50 -53 50 -45 0 2 -21 23 -47 47 l-48 45 45 -47z"/>
<path d="M5270 4185 c13 -14 26 -25 28 -25 3 0 -5 11 -18 25 -13 14 -26 25
-28 25 -3 0 5 -11 18 -25z"/>
<path d="M5335 4110 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M5459 3093 c-13 -16 -12 -17 4 -4 16 13 21 21 13 21 -2 0 -10 -8 -17
-17z"/>
<path d="M4665 3090 c3 -5 8 -10 11 -10 2 0 4 5 4 10 0 6 -5 10 -11 10 -5 0
-7 -4 -4 -10z"/>
<path d="M4677 3058 c-2 -8 6 -19 20 -25 25 -12 25 -12 7 10 -10 12 -19 23
-20 25 -1 2 -4 -3 -7 -10z"/>
<path d="M6934 3198 l-19 -23 23 19 c12 11 22 21 22 23 0 8 -8 2 -26 -19z"/>
<path d="M7626 3167 c3 -10 9 -15 12 -12 3 3 0 11 -7 18 -10 9 -11 8 -5 -6z"/>
<path d="M7472 3130 c0 -14 2 -19 5 -12 2 6 2 18 0 25 -3 6 -5 1 -5 -13z"/>
<path d="M7660 3095 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M7508 3073 c6 -2 18 -2 25 0 6 3 1 5 -13 5 -14 0 -19 -2 -12 -5z"/>
<path d="M7575 3060 c27 -12 43 -12 25 0 -8 5 -22 9 -30 9 -10 0 -8 -3 5 -9z"/>
<path d="M7620 3046 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M6806 3015 c-11 -8 -15 -15 -9 -15 6 0 16 7 23 15 16 19 11 19 -14 0z"/>
<path d="M7711 2882 c-1 -47 -22 -106 -49 -136 -25 -27 -91 -56 -133 -56 l-37
0 -4 -59 c-7 -99 -66 -156 -178 -172 -52 -8 -51 -8 18 -5 89 4 105 14 182 115
62 80 193 204 205 193 5 -4 5 -1 1 5 -9 15 8 49 42 86 l26 27 -31 0 c-25 0
-33 5 -36 23 -2 12 -5 3 -6 -21z"/>
<path d="M7835 2870 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M7751 2825 c-42 -48 -40 -57 2 -11 19 20 37 33 42 29 4 -5 5 -2 1 5
-10 17 -10 17 -45 -23z"/>
<path d="M6910 2767 c0 -2 15 -16 33 -33 l32 -29 -29 33 c-28 30 -36 37 -36
29z"/>
<path d="M6791 2747 c2 -1 13 -9 24 -17 19 -14 19 -14 6 3 -7 9 -18 17 -24 17
-6 0 -8 -1 -6 -3z"/>
<path d="M7676 2695 c-9 -26 -7 -32 5 -12 6 10 9 21 6 23 -2 3 -7 -2 -11 -11z"/>
<path d="M7028 2683 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7111 2604 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M7627 2585 c-9 -19 -15 -35 -12 -35 7 0 37 61 33 66 -3 2 -12 -12
-21 -31z"/>
<path d="M6955 2550 c10 -11 20 -20 23 -20 3 0 -3 9 -13 20 -10 11 -20 20 -23
20 -3 0 3 -9 13 -20z"/>
<path d="M7140 2537 c0 -2 15 -16 33 -33 l32 -29 -29 33 c-28 30 -36 37 -36
29z"/>
<path d="M7025 2470 c21 -22 41 -40 44 -40 3 0 -13 18 -34 40 -21 22 -41 40
-44 40 -3 0 13 -18 34 -40z"/>
<path d="M7515 2418 l-40 -43 43 40 c39 36 47 45 39 45 -2 0 -21 -19 -42 -42z"/>
<path d="M7090 2416 c0 -2 8 -10 18 -17 15 -13 16 -12 3 4 -13 16 -21 21 -21
13z"/>
<path d="M7174 2356 c11 -9 24 -16 30 -16 12 0 7 5 -24 19 -24 11 -24 11 -6
-3z"/>
<path d="M7420 2350 c-9 -6 -10 -10 -3 -10 6 0 15 5 18 10 8 12 4 12 -15 0z"/>
<path d="M7258 2323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M7348 2323 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M6585 3130 c-3 -6 1 -7 9 -4 18 7 21 14 7 14 -6 0 -13 -4 -16 -10z"/>
<path d="M6630 3136 c0 -2 7 -7 16 -10 8 -3 12 -2 9 4 -6 10 -25 14 -25 6z"/>
<path d="M5583 2693 c9 -2 23 -2 30 0 6 3 -1 5 -18 5 -16 0 -22 -2 -12 -5z"/>
<path d="M5950 2861 c0 -22 -4 -51 -10 -66 -6 -15 -6 -24 -1 -20 15 9 44 93
38 110 -12 29 -27 15 -27 -24z"/>
<path d="M3113 2535 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M3573 2535 c0 -44 2 -61 4 -37 2 23 2 59 0 80 -2 20 -4 1 -4 -43z"/>
<path d="M2025 8770 l-29 -30 119 -365 c65 -201 121 -365 124 -365 3 0 20 14
38 29 l32 29 -39 99 c-22 54 -39 103 -39 108 0 11 158 146 178 153 7 2 55 -22
107 -53 l95 -57 57 53 c31 29 61 56 65 59 5 4 -110 69 -255 145 -145 76 -299
157 -344 181 l-81 44 -28 -30z m303 -290 c21 -15 20 -16 -49 -73 -39 -33 -72
-57 -73 -55 -5 5 -76 222 -76 231 0 8 155 -73 198 -103z"/>
<path d="M1617 8377 l-25 -33 179 -148 c98 -81 176 -152 173 -157 -3 -5 -38
-9 -77 -9 -40 0 -182 -7 -317 -15 l-245 -15 -32 -38 c-18 -20 -33 -39 -32 -42
0 -3 122 -106 271 -229 l272 -224 23 29 c12 16 23 32 23 35 0 4 -83 76 -185
160 -146 120 -182 155 -168 160 10 3 124 11 253 18 391 19 402 20 423 39 48
43 52 38 -215 259 -139 114 -262 216 -274 225 -21 17 -23 17 -47 -15z"/>
<path d="M8252 8275 c-195 -73 -355 -135 -358 -137 -2 -3 12 -19 31 -38 l34
-33 102 47 c83 38 104 45 114 34 6 -7 44 -48 84 -90 l73 -77 -54 -99 -54 -98
60 -67 61 -66 164 350 163 351 -28 29 c-15 16 -30 29 -33 28 -3 0 -165 -60
-359 -134z m169 -105 c-27 -55 -51 -105 -54 -112 -5 -13 -127 101 -127 119 0
8 199 91 222 92 4 1 -15 -44 -41 -99z"/>
<path d="M8790 7880 c-62 -13 -140 -53 -192 -99 -126 -111 -143 -294 -44 -471
34 -61 124 -169 174 -208 l40 -32 116 76 c64 42 115 80 114 83 -12 26 -74 120
-80 121 -4 0 -49 -27 -99 -60 -50 -33 -97 -60 -104 -60 -25 0 -73 50 -99 102
-32 63 -34 144 -7 203 25 56 109 131 189 168 84 39 180 48 247 23 99 -38 169
-166 148 -271 -6 -27 -9 -52 -8 -54 2 -2 27 3 54 10 60 15 62 22 31 120 -72
234 -285 389 -480 349z"/>
<path d="M1119 7607 c-96 -36 -170 -109 -224 -222 -114 -236 -17 -470 224
-545 137 -43 247 -16 347 84 69 69 116 157 134 253 33 167 -49 327 -207 405
-56 27 -79 32 -152 35 -55 2 -99 -2 -122 -10z m112 -143 c110 -32 224 -122
269 -213 18 -35 21 -57 18 -103 -12 -164 -172 -231 -349 -146 -102 48 -181
115 -213 180 -34 69 -34 121 2 189 22 43 38 59 81 81 62 32 112 36 192 12z"/>
<path d="M9120 7088 c-173 -77 -318 -143 -322 -147 -4 -3 22 -71 58 -151 36
-80 72 -159 79 -177 l14 -32 40 17 c23 9 40 19 38 22 -1 3 -26 51 -54 106
l-52 102 114 51 c64 28 119 51 124 51 7 0 71 -139 71 -154 0 -3 4 -16 10 -30
l9 -26 38 17 c21 10 39 18 40 18 0 0 -21 45 -48 100 -27 55 -49 103 -49 106 0
7 183 89 198 89 9 0 82 -178 82 -200 0 -13 6 -13 47 2 18 7 33 17 33 23 0 9
-146 345 -153 352 -1 1 -144 -61 -317 -139z"/>
<path d="M643 6783 c-11 -32 -23 -65 -27 -73 -5 -12 61 -40 320 -135 180 -66
330 -121 335 -123 4 -2 13 16 19 40 7 24 19 56 27 71 8 16 13 31 11 33 -5 4
-654 244 -661 244 -2 0 -13 -26 -24 -57z"/>
<path d="M9325 6578 c-181 -50 -331 -92 -332 -92 -6 -5 19 -76 27 -76 5 0 102
25 217 56 114 31 219 59 232 62 l25 5 -29 -49 c-28 -49 -160 -258 -273 -434
-61 -94 -61 -95 -50 -138 6 -24 16 -41 22 -39 6 2 160 44 341 92 182 49 332
90 334 91 1 2 -3 20 -10 41 l-14 39 -210 -57 c-115 -32 -220 -60 -232 -63 -20
-5 -13 8 40 91 35 54 114 180 176 281 l113 182 -12 50 c-7 28 -17 50 -23 49
-7 -1 -160 -42 -342 -91z"/>
<path d="M482 6278 c-35 -138 -59 -255 -55 -259 4 -4 23 -9 42 -11 l35 -3 13
84 c7 47 16 87 19 90 4 4 562 -129 609 -146 12 -4 48 138 37 148 -4 3 -141 39
-304 80 -246 61 -298 77 -298 91 0 9 12 49 26 88 15 39 25 72 23 74 -2 2 -22
6 -44 9 l-40 7 -63 -252z"/>
<path d="M732 5730 c-199 -59 -364 -109 -367 -111 -2 -2 -5 -21 -7 -42 l-3
-39 338 -180 c186 -99 340 -178 342 -176 2 1 5 23 6 47 l4 43 -100 46 c-55 25
-101 46 -102 47 -1 0 3 56 9 123 l12 121 111 26 112 26 6 87 c4 47 5 87 4 87
-1 1 -165 -46 -365 -105z m64 -177 c-3 -21 -8 -64 -11 -95 l-6 -58 -110 58
c-60 31 -109 59 -109 62 0 5 209 66 233 69 5 1 6 -16 3 -36z"/>
<path d="M9460 5816 c-112 -25 -188 -86 -237 -191 -26 -55 -28 -69 -28 -180 0
-105 3 -127 25 -180 24 -56 28 -60 68 -69 23 -5 45 -7 47 -4 3 3 -10 29 -30
57 -124 185 -29 366 208 400 200 28 358 -70 357 -221 0 -47 -30 -121 -61 -154
-25 -26 -19 -31 46 -35 l49 -4 12 40 c18 57 17 200 -2 270 -38 149 -141 247
-289 274 -71 13 -94 13 -165 -3z"/>
<path d="M665 5081 l-330 -26 3 -40 c2 -22 4 -41 6 -42 2 -3 336 23 426 32
l55 6 -66 -68 c-36 -37 -139 -148 -228 -245 l-163 -178 7 -50 c4 -27 12 -50
18 -50 7 0 154 11 327 25 173 14 327 25 343 25 26 0 27 1 21 43 -4 23 -7 43
-8 43 -1 1 -109 -7 -240 -17 -131 -11 -241 -17 -244 -15 -4 5 268 307 303 336
8 7 47 48 85 90 69 76 70 79 64 119 -5 34 -10 41 -28 40 -11 -1 -169 -13 -351
-28z"/>
<path d="M9710 4993 l-205 -95 -110 7 c-60 3 -126 8 -146 11 l-36 5 -6 -77
c-4 -43 -6 -78 -5 -80 2 -1 74 -7 161 -13 l158 -12 66 -40 c36 -22 119 -76
184 -120 65 -43 121 -79 124 -79 3 0 5 22 5 49 l0 48 -127 72 c-147 83 -167
96 -160 108 3 5 74 36 156 69 l151 59 0 40 c0 22 3 64 6 93 4 28 3 52 -2 51
-5 0 -101 -44 -214 -96z"/>
<path d="M9416 4010 c-21 -80 -43 -153 -48 -162 -7 -14 -22 -18 -67 -18 l-58
0 -66 -100 c-37 -55 -67 -102 -67 -105 0 -3 56 40 125 95 69 55 125 99 125 98
0 -2 -7 -27 -15 -57 l-14 -55 75 -93 c41 -51 76 -93 78 -93 2 0 -12 37 -30 83
-18 45 -44 112 -58 150 -28 74 -28 74 43 24 l35 -24 109 43 c101 40 150 64
102 51 -11 -3 -85 -8 -165 -12 l-145 -6 48 43 48 43 -8 120 -8 120 -39 -145z"/>
<path d="M830 4029 c0 -2 20 -69 45 -149 24 -80 45 -149 45 -153 0 -4 -21 8
-47 28 -26 20 -53 33 -60 31 -7 -3 -62 -20 -121 -38 l-107 -33 165 -5 165 -5
-47 -33 -48 -33 0 -122 1 -122 52 153 52 153 19 -58 18 -58 107 -34 c138 -44
140 -44 117 -29 -77 54 -246 180 -246 185 0 3 26 4 58 1 l57 -4 71 96 c71 95
84 121 41 83 -27 -24 -224 -163 -231 -163 -3 0 4 25 14 56 l20 56 -70 101
c-39 55 -70 99 -70 96z"/>
<path d="M9219 3924 c73 -47 135 -84 138 -81 3 4 -3 29 -13 57 -16 45 -22 51
-59 61 -22 6 -72 19 -111 30 -38 10 -74 19 -79 19 -4 0 52 -39 124 -86z"/>
<path d="M8829 3046 c-11 -23 1 -40 156 -235 92 -116 168 -211 171 -211 2 0
10 12 18 27 13 26 12 29 -39 88 l-53 60 41 74 40 73 46 -7 c127 -19 113 -22
144 35 16 29 27 53 25 55 -4 3 -499 65 -521 65 -9 0 -22 -11 -28 -24z m258
-111 l32 -6 -32 -54 c-17 -30 -34 -55 -37 -55 -4 1 -65 74 -98 119 -13 19 1
18 135 -4z"/>
<path d="M1305 2906 c-204 -134 -234 -177 -201 -288 34 -113 119 -188 212
-188 54 0 104 25 272 134 l102 66 -16 25 -16 24 -36 -23 c-21 -13 -84 -53
-141 -90 -121 -76 -166 -92 -216 -76 -69 23 -111 108 -85 171 16 37 62 75 210
169 63 40 117 74 119 76 2 2 -38 61 -58 85 -4 4 -69 -35 -146 -85z"/>
<path d="M8657 2759 c-138 -138 -142 -302 -12 -417 115 -101 249 -90 359 29
39 42 60 75 72 114 17 52 17 55 -1 75 -26 29 -35 25 -35 -18 0 -94 -88 -179
-177 -169 -115 12 -242 134 -243 232 0 67 71 145 131 145 37 0 36 9 -1 45
l-29 28 -64 -64z"/>
<path d="M1557 2395 c-103 -87 -187 -160 -187 -164 0 -3 7 -15 15 -25 16 -20
24 -15 215 148 36 30 66 51 68 47 3 -8 -39 -349 -53 -436 -4 -21 1 -37 15 -54
18 -21 22 -22 38 -9 9 8 91 77 182 153 91 76 169 142 173 145 9 8 -13 50 -26
50 -5 0 -64 -48 -133 -106 -68 -58 -124 -102 -124 -97 0 4 12 108 27 231 l27
223 -26 25 -25 25 -186 -156z"/>
<path d="M8382 2390 c-17 -21 -32 -41 -32 -44 0 -8 368 -316 377 -315 4 0 23
18 40 38 l33 38 -178 148 c-97 82 -184 154 -192 161 -13 11 -20 6 -48 -26z"/>
<path d="M8141 2125 l-96 -94 180 -175 180 -174 40 39 40 39 -85 80 c-47 43
-79 79 -72 80 7 0 67 -14 133 -30 l120 -31 43 48 44 48 -136 28 c-75 16 -138
30 -140 31 -1 2 3 24 9 48 15 55 2 95 -43 133 -22 19 -41 25 -75 25 -45 0 -49
-3 -142 -95z m160 -31 c41 -42 45 -92 11 -126 l-22 -22 -65 64 c-36 36 -64 71
-63 79 4 19 65 50 87 44 10 -3 34 -20 52 -39z"/>
<path d="M1930 1965 l-174 -175 39 -40 39 -40 24 23 c13 12 91 92 173 178
l149 157 -36 36 c-20 20 -37 36 -38 36 0 0 -80 -79 -176 -175z"/>
<path d="M2204 1999 c-18 -20 -18 -21 11 -42 16 -12 40 -31 53 -42 l23 -20
-146 -170 -146 -169 38 -33 c21 -18 41 -32 45 -33 3 0 70 74 148 165 78 91
145 165 150 165 4 0 26 -20 48 -44 l40 -44 20 23 20 23 -131 114 c-73 63 -137
117 -143 121 -6 4 -20 -3 -30 -14z"/>
<path d="M7901 1904 c-56 -48 -101 -91 -101 -96 0 -6 233 -286 305 -366 9 -10
30 4 101 64 128 109 119 97 98 128 l-17 27 -61 -60 -61 -60 -59 71 -60 71 35
31 c18 17 48 41 64 53 l30 22 -21 21 -21 21 -59 -55 -59 -56 -52 58 c-29 31
-52 59 -52 62 0 3 20 21 45 40 92 70 86 62 65 88 l-19 22 -101 -86z"/>
<path d="M2407 1560 c-76 -104 -140 -196 -143 -203 -3 -9 32 -41 101 -91 103
-74 108 -77 126 -60 10 9 19 20 19 24 0 4 -30 26 -66 49 -43 27 -64 46 -61 55
10 27 92 135 102 136 6 0 36 -20 68 -46 l58 -45 18 23 19 23 -64 43 c-35 23
-64 47 -64 53 0 12 80 119 89 119 4 0 33 -22 65 -49 l59 -50 17 22 c10 12 16
25 14 28 -4 7 -212 159 -217 159 -2 0 -65 -85 -140 -190z"/>
<path d="M7673 1712 l-22 -17 18 -120 c10 -66 22 -151 26 -188 7 -68 7 -69
-14 -57 -12 6 -86 49 -166 95 -138 80 -144 82 -167 67 -13 -8 -24 -16 -26 -17
-1 -1 48 -104 108 -228 l111 -227 25 16 c13 9 24 19 24 23 0 3 -16 33 -36 65
-49 82 -117 219 -106 215 4 -2 74 -42 155 -88 81 -46 153 -81 160 -78 11 4 9
34 -9 153 -13 82 -25 164 -28 182 -3 19 -1 30 5 27 10 -7 153 -176 185 -219
l21 -28 50 33 50 33 -86 96 c-47 52 -122 137 -165 188 -44 50 -82 92 -85 92
-3 0 -16 -8 -28 -18z"/>
<path d="M2702 1339 c-67 -116 -122 -214 -122 -218 0 -16 218 -131 265 -140
118 -22 225 36 281 154 44 92 27 192 -43 256 -23 22 -161 106 -239 147 -19 10
-29 -3 -142 -199z m264 79 c62 -41 87 -88 80 -146 -10 -78 -82 -184 -149 -219
-41 -21 -104 -12 -161 21 l-45 26 101 175 c55 96 106 175 113 175 7 0 35 -15
61 -32z"/>
<path d="M7043 1336 c-15 -7 -23 -19 -23 -36 0 -44 40 -513 44 -517 2 -2 16 2
30 8 24 11 26 17 22 48 -3 20 -8 56 -11 81 l-6 45 77 38 77 38 57 -56 56 -56
57 29 57 29 -178 155 c-245 215 -233 206 -259 194z m121 -214 l49 -50 -53 -27
c-29 -15 -56 -23 -60 -18 -4 4 -10 43 -13 85 -6 91 -3 92 77 10z"/>
<path d="M3434 1171 c-17 -11 -41 -40 -54 -66 -36 -74 -24 -110 76 -234 29
-37 35 -50 30 -75 -19 -100 -176 -76 -176 27 0 39 -11 38 -42 -4 -28 -39 -29
-42 -12 -60 28 -32 76 -57 128 -69 116 -26 215 52 207 163 -2 38 -20 70 -75
137 -61 72 -63 120 -8 149 49 26 122 -26 122 -86 0 -14 7 -11 32 14 l32 32
-24 27 c-57 61 -173 83 -236 45z"/>
<path d="M6516 1094 c-66 -24 -121 -46 -123 -48 -3 -3 156 -456 163 -465 3 -4
94 19 94 24 0 1 -18 52 -40 114 -22 61 -40 113 -40 115 0 2 38 16 85 32 47 16
85 32 85 37 0 5 -4 17 -10 27 -10 18 -13 17 -92 -11 -44 -17 -84 -26 -88 -22
-6 7 -50 120 -50 128 0 2 40 18 89 35 82 30 89 34 82 54 -5 11 -14 22 -22 23
-8 0 -68 -19 -133 -43z"/>
<path d="M3722 1078 c-9 -26 -8 -29 16 -34 75 -17 102 -25 102 -33 0 -4 -27
-97 -60 -207 -33 -109 -60 -204 -60 -209 0 -14 91 -39 100 -28 4 4 24 64 44
133 21 69 49 162 62 207 l25 82 52 -20 c70 -28 69 -28 77 3 8 27 7 28 -63 49
-40 12 -120 36 -178 53 l-106 33 -11 -29z"/>
<path d="M6033 971 c-112 -38 -184 -119 -198 -220 -11 -85 33 -196 100 -253
38 -33 113 -51 182 -45 198 19 320 176 270 348 -46 156 -192 226 -354 170z
m149 -56 c46 -31 86 -111 94 -190 11 -109 -30 -193 -106 -216 -121 -37 -214
72 -214 251 0 57 4 83 19 108 41 70 140 93 207 47z"/>
<path d="M4147 734 c-82 -124 -147 -227 -145 -229 2 -1 17 -5 33 -8 29 -5 32
-2 68 65 33 59 42 69 60 65 12 -3 50 -11 84 -17 68 -14 66 -11 78 -112 8 -62
7 -61 83 -73 32 -5 42 -3 42 7 0 7 -21 123 -46 258 -47 245 -55 270 -93 270
-10 0 -76 -92 -164 -226z m139 9 c21 -106 24 -101 -65 -82 l-50 10 47 73 c26
40 49 72 51 70 2 -2 10 -34 17 -71z"/>
<path d="M4548 881 l-5 -31 63 0 c35 0 65 -3 67 -7 3 -5 -5 -104 -16 -221 -11
-117 -17 -215 -15 -218 3 -2 28 -7 55 -10 l49 -6 18 198 c23 272 14 248 91
239 l65 -7 0 30 0 29 -142 12 c-79 6 -162 14 -184 17 -40 6 -42 5 -46 -25z"/>
<path d="M5370 861 c-51 -26 -80 -74 -80 -130 0 -77 25 -110 121 -159 92 -47
109 -63 109 -102 0 -87 -134 -108 -176 -27 -19 35 -29 27 -38 -31 -6 -39 -4
-43 20 -52 14 -6 53 -10 86 -10 116 0 188 57 196 154 6 74 -22 109 -128 166
-99 53 -121 80 -99 125 26 54 97 58 143 8 l25 -27 11 28 c16 43 12 53 -21 65
-49 17 -130 13 -169 -8z"/>
<path d="M4960 620 l0 -250 140 0 140 0 0 30 0 29 -85 -6 -85 -6 0 98 0 98 80
-6 80 -6 0 29 0 30 -80 0 -80 0 0 75 0 75 83 0 84 0 1 30 1 30 -140 0 -139 0
0 -250z"/>
</g>
</svg>
    </>
  )
}
