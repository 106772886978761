import React from 'react'

export default function USA() {
  return (
    <>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
        width="100.000000pt" height="100.000000pt" viewBox="0 0 2048.000000 2048.000000"
        preserveAspectRatio="xMidYMid meet">

        <g transform="translate(0.000000,2048.000000) scale(0.100000,-0.100000)"
        fill="#fff" stroke="none">
        <path d="M9900 20273 c-2178 -78 -4225 -833 -5913 -2181 -2127 -1697 -3470
        -4160 -3736 -6852 -38 -384 -45 -539 -45 -995 0 -457 8 -632 45 -1005 173
        -1743 790 -3386 1812 -4825 530 -746 1192 -1443 1928 -2031 1991 -1591 4524
        -2359 7079 -2149 1543 128 3008 600 4340 1399 1398 838 2597 2039 3442 3447
        1151 1917 1623 4145 1352 6384 -352 2912 -1980 5534 -4444 7160 -1731 1142
        -3795 1723 -5860 1648z m830 -73 c677 -35 1297 -127 1935 -286 2216 -554 4197
        -1874 5565 -3709 1067 -1432 1719 -3076 1919 -4845 47 -408 55 -582 55 -1120
        0 -538 -8 -706 -55 -1125 -221 -1973 -1038 -3848 -2335 -5360 -1674 -1951
        -4009 -3175 -6554 -3434 -390 -40 -516 -46 -1030 -45 -527 0 -705 9 -1115 55
        -3645 410 -6781 2808 -8145 6229 -404 1012 -634 2077 -691 3200 -13 253 -7
        953 11 1170 20 253 50 535 80 742 269 1866 1066 3620 2300 5058 888 1034 1974
        1874 3195 2471 1211 591 2507 927 3855 998 228 12 786 12 1010 1z"/>
        <path d="M9695 19889 c-1838 -102 -3617 -734 -5110 -1814 -641 -463 -1254
        -1034 -1757 -1635 -942 -1127 -1622 -2465 -1968 -3870 -192 -783 -280 -1510
        -280 -2324 0 -860 97 -1625 310 -2451 431 -1672 1355 -3259 2602 -4470 1573
        -1528 3585 -2474 5728 -2694 400 -41 506 -46 1030 -46 606 1 849 17 1370 91
        1270 180 2522 628 3625 1297 2087 1266 3630 3289 4290 5622 241 852 350 1604
        362 2500 6 434 -3 692 -37 1055 -270 2892 -1831 5506 -4256 7128 -1445 966
        -3087 1512 -4848 1612 -206 12 -842 11 -1061 -1z m722 -1071 c-4 -211 -7 -537
        -7 -725 l0 -343 -100 0 -100 0 0 243 c0 134 3 460 7 725 l6 482 100 0 100 0
        -6 -382z m1068 328 c215 -69 371 -226 410 -413 24 -112 28 -107 -67 -99 -108
        9 -118 13 -118 41 0 48 -42 135 -90 188 -120 132 -366 178 -532 100 -210 -98
        -318 -331 -306 -657 4 -106 10 -143 30 -197 61 -165 179 -253 379 -281 246
        -35 444 103 494 343 9 40 14 73 13 74 -2 1 -99 13 -216 26 -118 13 -218 26
        -223 29 -8 5 5 158 13 165 2 2 142 -14 312 -34 214 -25 311 -41 314 -49 5 -17
        -80 -745 -88 -755 -4 -5 -34 -5 -66 0 l-59 8 -6 45 c-4 25 -10 66 -14 92 l-6
        48 -51 -41 c-110 -86 -218 -121 -373 -121 -191 0 -336 54 -450 167 -181 179
        -245 401 -200 697 8 53 24 125 35 160 83 246 273 434 492 483 98 23 273 14
        373 -19z m-2142 -33 c3 -27 27 -311 53 -633 26 -322 48 -586 50 -588 2 -3 608
        44 685 54 l46 5 7 -86 c4 -48 6 -88 4 -90 -6 -6 -918 -78 -923 -73 -8 7 -125
        1432 -119 1438 3 3 36 8 72 11 130 11 119 15 125 -38z m-1108 -165 c3 -13 61
        -292 129 -621 68 -329 126 -600 128 -602 2 -2 163 30 358 70 195 41 355 73
        357 71 6 -5 36 -166 32 -169 -2 -2 -206 -45 -453 -96 -285 -59 -451 -89 -455
        -83 -11 19 -294 1402 -288 1408 5 5 141 38 179 43 4 1 10 -9 13 -21z m4626
        -124 c271 -80 495 -147 497 -149 6 -6 -42 -165 -50 -165 -4 0 -182 51 -395
        114 -214 63 -396 116 -407 118 -16 3 -26 -24 -77 -196 -32 -109 -59 -204 -59
        -210 0 -6 167 -60 370 -120 204 -60 373 -113 376 -118 10 -16 -38 -158 -53
        -158 -8 0 -174 47 -370 105 -195 58 -361 105 -368 105 -8 0 -16 -8 -19 -17 -3
        -10 -32 -112 -66 -227 -34 -114 -60 -209 -58 -210 2 -2 184 -56 405 -121 221
        -65 407 -121 414 -126 9 -5 7 -26 -11 -88 -12 -45 -24 -81 -26 -81 -9 0 -1017
        300 -1021 304 -9 8 402 1386 413 1386 7 0 234 -65 505 -146z m-5049 -41 c15
        -42 24 -79 19 -82 -5 -4 -186 -69 -403 -145 -216 -77 -394 -140 -395 -141 -1
        -1 31 -93 71 -206 49 -139 77 -204 87 -203 8 1 175 59 373 129 225 80 361 124
        365 117 12 -20 55 -151 51 -155 -3 -2 -167 -61 -365 -131 -198 -70 -363 -131
        -367 -135 -7 -6 139 -432 152 -445 3 -2 188 60 410 139 223 79 408 141 412
        137 9 -8 59 -154 55 -158 -13 -13 -1001 -354 -1007 -348 -8 10 -480 1335 -480
        1350 0 10 981 364 990 357 2 -2 17 -38 32 -80z m5957 -273 c84 -42 100 -54
        105 -77 2 -15 35 -319 73 -675 37 -357 70 -648 73 -648 3 0 118 232 254 515
        137 283 253 515 258 515 4 0 44 -18 88 -40 l80 -40 -21 -43 c-12 -23 -152
        -312 -311 -642 -159 -330 -292 -603 -297 -608 -5 -5 -51 12 -104 36 -82 39
        -96 48 -100 73 -4 16 -38 319 -78 674 -40 355 -75 648 -79 653 -3 4 -64 -113
        -135 -260 -71 -148 -184 -382 -251 -521 l-123 -253 -83 42 c-46 23 -84 42 -85
        43 -3 2 586 1228 616 1284 6 12 14 22 17 22 2 0 49 -22 103 -50z m-7130 -144
        c23 -40 41 -76 41 -79 0 -3 -96 -56 -212 -117 -117 -62 -214 -113 -215 -114
        -2 -1 130 -254 292 -561 162 -307 295 -561 295 -565 0 -8 -168 -96 -173 -90
        -2 3 -136 255 -297 560 -161 305 -294 556 -295 558 -2 2 -97 -46 -212 -107
        -115 -61 -214 -111 -219 -111 -5 0 -26 34 -46 75 l-37 74 37 21 c34 19 995
        528 999 529 1 1 20 -32 42 -73z m-805 -1159 c235 -318 426 -580 426 -582 0 -3
        -39 -34 -86 -69 l-86 -65 -36 14 c-21 8 -307 120 -636 249 -330 130 -602 234
        -604 231 -2 -2 146 -208 330 -457 184 -249 337 -458 341 -464 4 -7 -23 -34
        -69 -69 -42 -31 -77 -55 -79 -53 -13 15 -855 1160 -855 1163 0 3 41 36 92 73
        l92 69 621 -250 c341 -137 628 -252 638 -255 10 -2 -135 203 -322 457 -188
        254 -342 466 -344 471 -2 11 133 120 145 117 4 -1 198 -262 432 -580z m9505
        447 c296 -98 500 -381 463 -639 -11 -76 -48 -185 -63 -185 -9 0 -149 98 -149
        105 0 2 7 32 16 67 33 129 -5 249 -111 353 -67 65 -141 108 -226 130 -201 54
        -416 -57 -574 -294 -110 -166 -151 -287 -142 -418 7 -95 41 -167 113 -243 107
        -112 205 -156 333 -148 96 7 168 35 251 98 36 27 68 49 73 50 12 0 147 -101
        147 -110 0 -16 -134 -120 -200 -154 -87 -46 -166 -66 -262 -66 -100 0 -166 14
        -256 56 -372 170 -497 536 -311 907 80 159 172 277 287 369 190 151 400 193
        611 122z m1239 -1025 c205 -199 372 -367 372 -373 0 -6 -26 -37 -57 -68 l-58
        -57 -308 301 -307 300 -154 -158 -154 -159 237 -230 c130 -127 257 -250 283
        -274 l47 -43 -56 -64 c-31 -35 -60 -64 -63 -64 -4 0 -135 124 -290 275 -156
        151 -284 274 -284 273 -1 -2 -76 -80 -168 -173 l-168 -170 315 -305 315 -305
        -62 -62 -61 -62 -126 120 c-69 65 -243 233 -385 372 l-259 253 503 517 c277
        284 506 517 510 517 4 0 174 -163 378 -361z m-11823 -271 l488 -531 -69 -63
        c-38 -35 -73 -62 -79 -60 -8 2 -696 744 -913 985 l-66 74 68 63 c38 35 72 64
        76 64 4 0 227 -239 495 -532z m5771 331 c271 -56 418 -110 558 -203 266 -177
        347 -398 351 -951 1 -245 2 -252 28 -325 15 -41 50 -185 78 -320 86 -421 149
        -634 213 -727 23 -34 54 -58 116 -94 109 -62 184 -128 215 -190 29 -56 55
        -157 55 -213 0 -45 -3 -45 -91 15 -34 23 -84 49 -113 59 -54 19 -198 51 -204
        46 -1 -2 0 -15 3 -29 5 -20 14 -26 48 -30 81 -11 175 -48 249 -96 l75 -49 7
        -73 c12 -133 46 -214 97 -231 25 -8 -10 -38 -45 -38 -53 0 -213 38 -258 61
        -58 30 -171 148 -242 253 -67 98 -90 117 -114 92 -15 -15 -15 -19 1 -49 10
        -18 40 -62 68 -97 78 -101 89 -122 89 -166 0 -49 -30 -87 -87 -111 -48 -20
        -196 -29 -248 -14 l-40 10 3 49 c2 28 -5 83 -17 128 -11 43 -28 110 -36 148
        l-15 68 39 43 c21 23 47 54 57 69 15 24 23 27 53 23 20 -3 45 -9 56 -13 16 -6
        14 -1 -10 28 -16 20 -39 38 -49 41 -19 6 -76 -17 -76 -31 0 -4 -21 -31 -46
        -60 -59 -68 -85 -69 -142 -8 -23 24 -55 73 -73 108 -17 34 -33 65 -35 67 -1 2
        -39 -28 -84 -66 -111 -95 -179 -135 -241 -141 l-50 -5 -77 82 c-90 95 -114
        104 -188 71 -102 -45 -143 -111 -144 -229 0 -41 7 -103 16 -139 9 -35 13 -66
        10 -69 -9 -10 -113 58 -155 102 -55 55 -105 143 -136 234 -30 91 -49 121 -76
        121 -37 0 -56 -18 -162 -157 -165 -216 -198 -239 -212 -151 -20 120 -77 200
        -151 214 -52 10 -109 -6 -275 -75 -221 -92 -245 -92 -135 -2 42 34 63 57 59
        67 -7 19 -16 18 -92 -12 -60 -24 -74 -26 -176 -22 -89 4 -128 11 -202 36 -89
        30 -259 109 -251 117 2 2 46 12 98 23 256 52 407 160 640 457 149 189 121 164
        490 457 170 135 273 241 306 312 12 28 28 88 35 134 29 194 -45 373 -212 512
        l-60 50 32 0 c30 0 31 1 16 18 -15 16 -15 21 -3 37 13 17 15 18 37 3 13 -8 48
        -37 78 -64 l54 -49 148 0 c138 0 150 1 170 21 33 34 18 59 -59 95 -137 64
        -156 75 -181 112 -122 176 -166 233 -241 306 -101 99 -185 161 -271 200 l-63
        28 45 33 c78 57 160 93 324 143 95 29 112 31 325 37 281 8 322 5 496 -31z
        m-1176 -258 c148 -56 312 -211 463 -437 50 -74 83 -113 107 -126 55 -29 19
        -21 -123 26 -265 89 -414 174 -558 316 -88 87 -145 167 -164 229 l-7 24 113
        -5 c82 -4 130 -12 169 -27z m-308 -83 c33 -66 63 -104 143 -184 58 -58 130
        -119 172 -145 40 -24 73 -47 73 -50 0 -4 -138 -10 -307 -14 -339 -8 -366 -12
        -386 -65 -6 -16 -14 -30 -18 -30 -4 0 -26 57 -49 128 -37 108 -43 140 -44 212
        -1 72 2 91 22 121 37 60 122 87 334 107 12 2 30 -23 60 -80z m569 -464 c32
        -14 29 -15 -81 -10 -63 3 -178 7 -255 8 l-140 2 155 6 c285 11 286 11 321 -6z
        m-6014 -649 l496 -394 225 282 c124 155 228 283 232 285 8 3 130 -93 132 -104
        2 -11 -566 -723 -577 -723 -6 -1 -32 -37 -58 -80 -35 -59 -51 -78 -64 -75 -66
        16 -398 84 -408 84 -7 0 -79 -111 -160 -246 -127 -213 -145 -247 -133 -262 7
        -9 65 -80 128 -157 63 -77 121 -148 129 -157 12 -16 9 -26 -24 -80 -20 -35
        -52 -99 -71 -143 l-33 -80 -43 2 c-40 2 -159 38 -325 97 -77 28 -134 33 -180
        15 -35 -13 -90 -64 -111 -104 -33 -64 -200 -490 -194 -497 4 -3 129 -55 277
        -114 149 -59 274 -111 279 -115 9 -8 -52 -180 -64 -182 -3 -1 -307 119 -676
        266 l-672 268 139 346 c76 190 153 371 171 400 99 165 251 239 406 199 178
        -45 289 -151 316 -300 l7 -38 27 24 c55 51 82 62 151 62 55 -1 99 -12 260 -67
        106 -37 195 -67 197 -67 2 0 -199 254 -446 564 l-450 565 56 93 c31 51 57 94
        58 95 2 3 1379 -259 1429 -271 9 -2 17 -3 17 -1 0 2 -238 194 -530 426 -291
        232 -530 426 -530 431 0 10 109 148 117 148 3 0 228 -178 500 -395z m14216
        -106 c31 -50 57 -94 57 -98 0 -10 -908 -1225 -918 -1228 -4 -1 -32 38 -63 86
        l-56 87 133 170 c73 93 133 174 133 180 1 10 -288 457 -308 477 -4 4 -100 -15
        -213 -43 -114 -27 -211 -50 -217 -50 -17 0 -123 164 -109 169 11 4 1486 338
        1498 340 3 0 31 -40 63 -90z m332 -899 c209 -65 380 -251 442 -480 27 -100 25
        -276 -5 -361 -26 -77 -69 -148 -120 -202 -37 -38 -100 -83 -107 -76 -2 2 -19
        40 -38 84 l-36 80 45 48 c117 124 136 301 53 477 -40 86 -90 145 -161 190 -85
        55 -164 74 -290 67 -118 -6 -203 -29 -323 -89 -224 -112 -333 -243 -343 -412
        -6 -96 9 -168 54 -263 82 -173 255 -279 419 -257 61 8 172 41 180 54 3 5 -35
        100 -84 210 l-90 202 74 34 c40 19 76 34 78 34 6 0 257 -564 257 -576 0 -11
        -687 -317 -701 -312 -5 2 -20 27 -34 56 l-24 52 74 57 c69 53 73 59 52 66 -12
        5 -56 25 -97 45 -187 91 -327 318 -338 547 -10 203 97 419 270 550 110 83 274
        157 419 190 93 21 283 14 374 -15z m-8199 -522 c39 -117 83 -188 167 -271 78
        -78 79 -78 85 -139 4 -52 2 -63 -13 -75 -39 -28 -226 -3 -317 43 -58 29 -166
        130 -192 177 l-19 36 45 53 c25 29 78 97 118 151 45 60 79 97 88 95 9 -2 25
        -32 38 -70z m-1861 26 c29 -32 33 -50 39 -224 6 -161 18 -210 62 -257 38 -40
        41 -54 18 -77 -31 -32 -211 -8 -312 41 -63 30 -174 137 -216 206 -69 117 -130
        171 -151 136 -13 -20 5 -55 80 -154 43 -57 78 -114 81 -134 17 -86 -61 -143
        -204 -149 -95 -5 -162 3 -187 22 -15 10 -15 16 -5 33 20 32 6 69 -29 76 -20 4
        -35 17 -45 36 -24 46 -39 167 -33 266 8 141 32 185 101 185 61 0 163 -53 296
        -154 116 -87 152 -101 158 -61 1 11 -21 54 -53 99 -30 44 -55 85 -55 92 0 20
        48 25 179 19 95 -5 138 -3 176 8 70 20 73 20 100 -9z m1351 -65 c23 -21 40
        -66 64 -165 12 -50 42 -79 78 -76 20 1 34 -8 54 -35 81 -107 24 -197 -141
        -224 -56 -9 -177 1 -203 17 -4 3 -8 29 -8 58 0 29 -7 75 -15 103 -18 61 -65
        270 -65 290 0 24 126 65 179 58 19 -2 44 -14 57 -26z m9566 -1230 c70 -272
        128 -501 128 -510 0 -12 -21 -22 -80 -37 -44 -11 -82 -17 -84 -13 -2 3 -50
        186 -107 406 -56 220 -106 404 -109 408 -11 13 -420 -96 -420 -111 0 -7 43
        -179 96 -383 53 -204 94 -372 92 -374 -5 -6 -162 -44 -166 -41 -1 2 -46 170
        -98 372 -52 203 -96 374 -99 381 -2 8 -74 -7 -231 -47 -124 -33 -228 -61 -231
        -64 -2 -2 44 -191 103 -420 59 -229 105 -419 101 -422 -7 -7 -158 -46 -162
        -42 -7 8 -267 1029 -263 1033 7 7 1381 363 1393 361 5 -1 67 -225 137 -497z
        m-4612 441 c0 -5 -7 -10 -15 -10 -26 0 -147 -131 -191 -205 -72 -124 -97 -197
        -106 -320 -4 -60 -19 -1581 -33 -3380 -14 -1798 -27 -3324 -30 -3390 -3 -66
        -11 -150 -19 -186 -71 -332 -384 -641 -781 -773 -190 -62 -127 -59 -1235 -66
        -1075 -7 -1087 -7 -1250 -56 -172 -50 -316 -175 -403 -349 -20 -41 -61 -149
        -84 -225 -2 -8 -17 25 -33 73 -103 304 -271 465 -544 521 -146 31 -318 36
        -1211 36 -978 0 -982 0 -1172 61 -410 131 -719 432 -800 778 -19 81 -17 -100
        -53 3916 -27 3095 -28 3120 -48 3177 -54 151 -155 301 -242 364 -28 20 -50 38
        -50 40 0 2 1868 4 4150 4 2760 0 4150 -3 4150 -10z m-12582 -95 c2 -1 -22
        -108 -52 -236 -30 -129 -53 -236 -50 -238 2 -2 278 -68 615 -147 336 -78 613
        -145 615 -147 10 -9 -38 -187 -50 -187 -6 0 -281 63 -609 140 -329 77 -604
        140 -611 140 -8 0 -30 -78 -65 -227 -29 -126 -56 -231 -60 -236 -4 -4 -43 1
        -87 12 -66 15 -79 22 -77 37 2 18 248 1079 259 1115 4 15 12 14 87 -3 45 -11
        83 -21 85 -23z m17391 -1261 c6 -64 10 -118 9 -119 -2 -1 -247 -188 -546 -415
        -299 -226 -542 -413 -540 -415 2 -1 261 23 575 53 315 31 575 53 578 50 5 -4
        25 -181 22 -185 -1 0 -312 -31 -692 -67 -379 -36 -704 -68 -721 -70 l-32 -4
        -6 61 c-4 34 -9 85 -12 112 l-5 50 545 420 c299 231 543 421 542 423 -2 1
        -261 -22 -576 -53 -315 -30 -575 -54 -577 -51 -6 5 -23 179 -18 184 4 4 1351
        138 1411 141 l31 1 12 -116z m-17076 -85 l668 -53 -6 -106 c-6 -83 -11 -108
        -25 -120 -9 -8 -285 -154 -613 -325 -327 -170 -592 -311 -589 -312 7 -3 1119
        -93 1142 -93 10 0 12 -19 7 -91 -3 -50 -8 -94 -12 -97 -5 -5 -1436 106 -1442
        112 -3 3 17 230 20 232 1 1 272 140 602 309 330 169 604 311 610 316 5 5 -5 9
        -25 9 -58 1 -1122 88 -1126 93 -3 3 -2 46 2 97 l7 93 56 -6 c31 -3 357 -29
        724 -58z m16642 -1278 c101 -26 244 -97 311 -153 160 -133 232 -307 221 -532
        -12 -249 -122 -439 -308 -530 -65 -32 -211 -68 -222 -55 -9 9 16 184 26 191 7
        4 20 8 29 8 38 0 129 43 171 81 88 77 132 206 125 364 -3 80 -8 101 -38 161
        -85 173 -269 272 -539 290 -292 19 -487 -82 -562 -291 -32 -88 -32 -239 -1
        -316 41 -103 136 -188 268 -242 36 -14 58 -29 57 -38 -1 -8 -4 -49 -8 -91 -8
        -92 -10 -93 -113 -52 -209 84 -337 222 -387 419 -20 77 -19 251 1 335 45 193
        179 351 361 424 111 46 184 56 363 52 126 -3 184 -9 245 -25z m-16061 -43 c12
        -120 77 -1001 74 -1003 -3 -3 -1442 -114 -1445 -111 -2 2 -81 1051 -79 1052 0
        1 43 5 95 9 62 5 92 4 87 -3 -3 -5 8 -185 24 -398 16 -214 30 -401 30 -416 0
        -27 1 -28 58 -28 84 1 371 26 378 33 6 6 -41 685 -51 753 l-5 32 85 7 c48 4
        88 5 91 3 2 -3 17 -179 34 -393 17 -214 32 -390 33 -392 5 -4 467 33 472 38 3
        4 -47 711 -59 821 l-5 47 47 4 c26 2 66 4 88 5 l42 2 6 -62z m-181 -1187 c88
        -23 133 -44 205 -98 103 -78 189 -214 229 -363 23 -89 23 -255 -1 -335 -71
        -237 -276 -404 -591 -481 -428 -103 -769 61 -884 427 -93 298 -9 564 223 703
        33 20 67 36 75 36 12 0 22 -24 37 -90 l21 -90 -42 -25 c-59 -34 -130 -116
        -150 -173 -55 -162 -2 -379 122 -492 103 -94 204 -125 382 -117 141 6 243 31
        367 92 209 101 302 258 274 459 -13 94 -36 156 -80 213 -75 100 -176 151 -324
        161 l-89 7 -22 93 -22 92 97 0 c63 0 123 -7 173 -19z m16667 -173 c0 -7 -10
        -55 -22 -107 -21 -86 -25 -95 -53 -106 -16 -7 -189 -63 -382 -125 -194 -63
        -352 -116 -352 -119 1 -3 63 -65 138 -136 75 -72 204 -196 287 -276 l151 -146
        -25 -112 -26 -112 -365 365 -366 365 -265 61 c-146 34 -272 63 -281 66 -14 4
        -14 13 5 93 12 49 24 92 27 96 4 3 133 -24 287 -59 l281 -65 468 164 c257 90
        474 164 481 164 6 1 12 -5 12 -11z m-2055 -1764 c343 -45 718 -154 877 -255
        34 -23 66 -44 70 -48 4 -3 -21 -6 -56 -6 -127 0 -216 -95 -216 -231 l0 -64
        -57 26 c-278 123 -664 222 -891 228 -94 3 -118 7 -140 23 -44 33 -63 67 -68
        124 -4 44 -1 62 18 98 58 112 188 141 463 105z m-13485 -131 c53 -24 98 -66
        116 -110 23 -54 18 -174 -11 -259 -41 -122 -59 -151 -98 -158 -55 -10 -224
        -98 -312 -161 -108 -77 -186 -146 -338 -295 l-128 -125 7 66 c16 163 -80 294
        -243 330 -29 7 -50 16 -47 21 3 5 36 24 72 42 145 73 246 165 459 417 128 152
        186 209 248 243 43 24 212 17 275 -11z m14464 -277 c19 -14 39 -26 45 -26 7 0
        11 -24 11 -58 0 -185 -232 -701 -528 -1177 -870 -1397 -2192 -2528 -3742
        -3200 -1095 -475 -2323 -741 -3615 -785 -1418 -47 -2784 253 -4037 886 -1252
        634 -2392 1609 -3284 2809 -210 282 -481 703 -512 794 -26 75 -6 180 46 239
        27 30 27 31 90 21 62 -9 130 -37 166 -68 63 -55 78 -178 38 -308 -23 -76 -22
        -99 8 -108 28 -9 53 12 179 146 249 264 475 437 657 502 l71 26 58 -97 c86
        -144 289 -448 405 -607 1146 -1571 2766 -2571 4686 -2895 449 -75 704 -95
        1219 -95 422 0 462 2 845 41 400 41 950 156 1400 293 1898 577 3369 1654 4247
        3111 77 128 201 354 259 473 l38 77 51 -6 c256 -30 485 -93 811 -224 97 -39
        186 -70 198 -68 35 5 31 42 -9 85 -35 36 -36 40 -33 98 4 70 29 113 80 139 45
        23 109 15 152 -18z"/>
        <path d="M10277 16568 c-18 -43 -34 -55 -98 -75 -38 -11 -98 -16 -204 -17
        -133 -1 -155 -3 -190 -22 -56 -30 -74 -54 -90 -115 -8 -29 -13 -54 -12 -56 2
        -1 34 -7 73 -12 74 -11 137 -31 251 -80 85 -37 97 -38 143 -10 66 40 79 65 80
        150 l0 76 41 7 c23 4 55 14 73 22 43 22 96 74 96 95 0 26 -28 31 -51 8 -25
        -25 -89 -69 -100 -69 -5 0 3 17 17 37 28 41 29 86 2 91 -12 2 -22 -8 -31 -30z
        m-127 -169 c0 -32 -38 -87 -70 -104 -84 -44 -185 17 -152 90 12 25 28 28 170
        33 47 2 52 0 52 -19z"/>
        <path d="M10026 16374 c-27 -26 -12 -58 25 -52 39 5 36 67 -3 68 -4 0 -14 -7
        -22 -16z"/>
        <path d="M11730 13658 c0 -10 -12 -24 -27 -31 l-26 -14 26 -18 c33 -21 57 -14
        57 18 0 29 -29 73 -30 45z"/>
        <path d="M9290 16330 c0 -23 143 -210 160 -210 22 0 7 26 -88 150 -36 49 -72
        78 -72 60z"/>
        <path d="M2730 14677 c0 -3 94 -120 209 -261 l209 -255 106 177 c58 97 108
        184 111 192 4 12 -48 27 -292 79 -354 77 -343 75 -343 68z"/>
        <path d="M2238 13750 c-66 -36 -93 -83 -199 -347 -56 -140 -99 -256 -97 -259
        3 -2 105 -45 227 -94 217 -87 222 -88 231 -67 4 12 47 121 96 242 93 235 110
        311 85 388 -37 111 -241 193 -343 137z"/>
        <path d="M17295 14934 c-170 -41 -313 -75 -317 -77 -6 -2 209 -338 231 -360 7
        -7 411 492 411 508 0 7 11 10 -325 -71z"/>
        <path d="M10207 11978 c-3 -18 -63 -496 -132 -1063 -70 -566 -129 -1036 -131
        -1044 -4 -11 -654 1055 -667 1092 -4 13 -57 -11 -57 -25 0 -7 81 -292 179
        -633 99 -341 178 -621 176 -623 -3 -4 -1020 791 -1267 990 l-98 79 0 -45 c0
        -25 4 -48 10 -51 5 -3 238 -308 516 -678 279 -370 511 -677 516 -684 6 -8 -15
        -5 -59 8 -652 187 -1255 351 -1262 343 -18 -19 -22 -52 -6 -58 46 -17 1105
        -650 1098 -656 -6 -5 -1863 -262 -2141 -296 -29 -4 -54 -10 -57 -14 -2 -4 6
        -17 19 -29 21 -20 96 -29 1067 -136 574 -64 1057 -119 1073 -123 26 -6 -20
        -35 -488 -305 -284 -164 -534 -306 -556 -314 -42 -17 -50 -36 -27 -67 13 -18
        16 -18 73 3 58 21 1216 332 1220 328 3 -3 -992 -1227 -1026 -1262 -36 -37 -37
        -49 -9 -69 20 -14 25 -12 69 25 50 42 1343 979 1346 976 7 -7 -377 -1265 -399
        -1307 l-27 -51 43 3 c41 3 43 4 83 78 43 79 639 1048 655 1065 6 5 9 4 9 -4 0
        -17 289 -2118 296 -2153 5 -23 11 -28 35 -28 22 0 30 5 33 23 3 12 62 494 131
        1072 70 577 127 1051 129 1052 1 1 154 -252 340 -562 l338 -565 34 0 c39 0 38
        -2 20 36 -14 28 -378 1276 -373 1280 2 1 769 -601 1180 -926 136 -108 140
        -110 190 -110 l52 0 -33 26 c-17 14 -128 152 -245 307 -634 838 -826 1096
        -815 1092 7 -2 303 -86 657 -185 355 -100 648 -183 652 -185 7 -5 35 53 28 59
        -2 2 -116 69 -254 150 -745 439 -864 510 -858 516 5 4 481 71 1058 149 578 79
        1067 145 1088 148 33 5 37 9 37 34 0 16 -3 29 -7 29 -31 -1 -2140 254 -2146
        260 -4 3 238 152 538 329 531 315 545 324 539 349 -4 15 -8 28 -10 30 -1 2
        -289 -78 -639 -178 -351 -99 -639 -179 -640 -178 -1 2 231 297 517 657 l519
        653 -21 23 -22 23 -481 -360 c-265 -198 -574 -429 -687 -514 -113 -84 -206
        -150 -208 -146 -2 8 369 1304 382 1335 7 14 3 17 -28 17 -30 0 -38 -5 -52 -32
        -37 -73 -657 -1108 -661 -1104 -6 5 -283 2091 -283 2129 0 24 -4 27 -34 27
        -30 0 -34 -3 -39 -32z"/>
        <path d="M16542 5579 c-18 -27 -30 -55 -26 -61 4 -6 111 -161 239 -344 l233
        -334 24 33 c14 17 28 38 32 45 7 11 -37 82 -110 178 l-24 31 87 126 c47 70 92
        127 98 127 6 0 58 -13 116 -29 114 -32 114 -32 150 26 16 26 19 37 10 42 -7 4
        -182 52 -389 105 -207 54 -384 99 -392 101 -9 3 -27 -15 -48 -46z m435 -172
        c6 -6 -117 -187 -127 -187 -4 0 -49 60 -100 133 -52 72 -97 137 -102 143 -8
        11 317 -78 329 -89z"/>
        <path d="M3748 5190 c-247 -216 -270 -247 -270 -360 0 -184 200 -357 372 -321
        73 15 102 36 333 236 l196 169 -23 30 c-45 58 -38 62 -252 -124 -206 -178
        -252 -209 -317 -210 -116 0 -227 140 -197 250 15 55 58 100 274 285 96 83 175
        153 175 157 1 10 -59 78 -68 77 -3 0 -103 -85 -223 -189z"/>
        <path d="M16243 5139 c-78 -30 -155 -106 -199 -194 -34 -69 -36 -77 -33 -157
        5 -112 38 -179 133 -273 183 -181 391 -204 545 -60 97 91 138 219 106 331 -14
        53 -71 154 -86 154 -3 0 -19 -15 -37 -33 l-32 -33 32 -59 c26 -48 32 -70 32
        -120 0 -67 -10 -89 -62 -147 -106 -117 -259 -103 -411 37 -82 76 -116 142
        -115 225 0 79 23 128 87 187 41 38 96 63 138 63 9 0 35 -7 57 -14 l41 -15 31
        37 c16 20 30 40 30 44 0 14 -112 48 -158 47 -26 0 -71 -9 -99 -20z"/>
        <path d="M4207 4528 l-276 -283 37 -38 37 -39 222 229 221 228 -24 -115 c-13
        -63 -48 -227 -78 -365 l-54 -250 43 -44 44 -44 198 204 c109 112 233 240 275
        285 l78 80 -33 32 c-18 18 -37 32 -42 32 -4 0 -103 -97 -218 -215 -115 -119
        -212 -214 -214 -211 -3 3 30 163 72 356 l78 352 -45 45 -45 44 -276 -283z"/>
        <path d="M15747 4622 l-37 -37 278 -278 278 -277 37 38 37 37 -278 278 -278
        277 -37 -38z"/>
        <path d="M15562 4388 c-24 -4 -60 -17 -80 -30 -28 -17 -213 -170 -336 -279 -7
        -6 477 -578 505 -595 6 -3 27 9 47 29 l35 35 -19 24 c-10 13 -59 70 -108 126
        -49 57 -87 104 -85 106 2 2 47 41 99 86 179 156 184 156 320 25 54 -52 101
        -95 105 -95 15 0 105 82 99 91 -3 5 -17 9 -32 9 -20 0 -45 20 -107 84 -89 93
        -132 121 -177 112 l-29 -6 6 43 c11 74 -29 156 -102 207 -42 29 -88 38 -141
        28z m100 -147 c41 -44 54 -77 44 -118 -7 -28 -103 -128 -202 -210 l-49 -41
        -80 92 c-43 51 -80 97 -82 103 -5 13 188 180 236 205 49 26 88 17 133 -31z"/>
        <path d="M4794 4004 c-131 -152 -246 -285 -254 -296 -13 -19 -12 -23 25 -55
        37 -32 40 -33 56 -16 9 10 126 144 258 298 l242 279 -38 33 c-21 18 -41 32
        -44 33 -3 0 -114 -124 -245 -276z"/>
        <path d="M5136 4124 c-17 -21 -24 -39 -19 -43 66 -42 201 -157 194 -165 -249
        -320 -404 -521 -407 -531 -3 -6 14 -24 36 -40 35 -24 43 -26 54 -14 7 8 100
        127 206 264 106 138 196 253 199 257 3 4 53 -29 110 -74 l105 -81 28 34 c16
        18 27 37 26 41 -3 8 -490 388 -498 388 -3 0 -18 -16 -34 -36z"/>
        <path d="M14809 3833 c-124 -87 -227 -161 -229 -164 -3 -3 96 -150 219 -327
        l225 -320 85 60 c47 33 154 107 238 166 l152 106 -17 29 c-33 55 -30 56 -221
        -77 -98 -69 -186 -128 -193 -131 -10 -4 -36 25 -79 87 -36 51 -68 98 -72 104
        -4 7 62 59 168 133 l174 122 -26 40 c-31 46 -3 58 -227 -99 l-149 -105 -24 29
        c-38 48 -116 164 -112 168 2 1 86 60 187 130 100 70 182 132 182 137 0 12 -41
        69 -50 69 -3 0 -107 -71 -231 -157z"/>
        <path d="M5695 3718 c-9 -13 -68 -102 -131 -198 -64 -96 -153 -233 -200 -303
        l-84 -128 232 -152 c128 -85 238 -156 244 -160 7 -4 23 10 38 33 l26 40 -195
        128 c-107 70 -195 130 -195 135 0 11 132 207 140 207 4 0 81 -49 171 -108 90
        -59 169 -111 176 -115 10 -6 53 52 53 72 0 5 -78 59 -172 122 -95 62 -174 114
        -176 115 -5 3 122 194 130 194 4 0 93 -56 198 -125 l190 -124 26 40 c14 23 24
        42 22 43 -15 13 -456 300 -465 303 -6 2 -19 -6 -28 -19z"/>
        <path d="M14393 3554 c-35 -20 -63 -40 -63 -44 0 -5 27 -156 59 -336 33 -181
        58 -330 56 -332 -2 -2 -111 94 -242 214 -131 121 -245 225 -254 232 -14 11
        -27 7 -83 -24 l-66 -36 29 -52 c16 -28 95 -172 176 -321 81 -148 154 -280 162
        -293 l14 -22 42 21 c23 12 44 23 45 25 4 2 -202 382 -274 507 -19 32 -28 55
        -22 51 7 -5 118 -105 246 -224 129 -118 240 -216 247 -218 24 -6 94 45 89 65
        -8 33 -114 626 -114 637 0 6 67 -112 150 -263 82 -151 153 -278 157 -282 4 -4
        27 4 51 18 l42 25 -47 87 c-270 495 -329 600 -333 600 -3 0 -33 -16 -67 -35z"/>
        <path d="M6293 3357 c-26 -39 -361 -682 -358 -686 2 -4 82 -46 177 -95 170
        -87 175 -89 244 -89 95 0 166 33 242 111 102 104 158 241 150 366 -9 132 -65
        202 -234 291 -60 32 -132 70 -161 86 -39 21 -54 25 -60 16z m156 -173 c47 -25
        101 -57 119 -71 85 -64 96 -190 29 -326 -40 -81 -103 -154 -158 -183 -21 -10
        -56 -17 -90 -17 -48 0 -70 7 -167 58 -62 33 -111 62 -110 65 36 78 276 520
        282 520 5 0 48 -21 95 -46z"/>
        <path d="M13425 3069 c-22 -10 -43 -20 -46 -21 -7 -4 24 -825 32 -833 3 -3 27
        3 53 14 l46 20 -6 123 -6 123 143 59 143 58 36 -39 c19 -21 54 -60 78 -86 23
        -26 48 -47 55 -47 20 0 97 34 94 42 -7 22 -554 606 -567 606 -8 0 -33 -8 -55
        -19z m174 -254 c96 -107 109 -125 94 -134 -22 -11 -190 -81 -196 -81 -3 0 -8
        53 -11 118 -3 64 -8 143 -11 175 -3 34 -1 56 4 52 5 -3 59 -61 120 -130z"/>
        <path d="M7250 2881 c-103 -32 -173 -118 -174 -217 -1 -77 32 -119 161 -205
        150 -99 193 -142 193 -192 0 -44 -22 -92 -47 -102 -54 -22 -81 -25 -133 -14
        -104 21 -190 82 -206 145 -4 14 -4 47 -2 73 l5 47 -46 17 c-52 19 -57 15 -67
        -50 -19 -130 63 -242 224 -304 71 -27 176 -36 228 -19 90 29 154 116 154 212
        0 97 -24 126 -206 248 -146 98 -165 122 -145 183 26 77 102 105 201 72 97 -32
        160 -113 146 -188 -6 -31 -4 -33 41 -50 25 -9 49 -15 51 -12 15 15 13 128 -2
        170 -45 117 -257 222 -376 186z"/>
        <path d="M12538 2756 c-159 -45 -248 -76 -248 -84 0 -20 202 -726 211 -736 10
        -12 99 13 99 28 0 6 -21 82 -46 169 -25 88 -44 160 -42 162 2 2 81 26 177 54
        95 27 176 52 178 55 10 10 -8 77 -22 82 -7 3 -91 -17 -186 -45 -96 -27 -175
        -49 -176 -47 -2 1 -17 51 -35 110 -23 80 -28 110 -19 115 6 4 97 32 202 61
        105 30 193 60 196 67 7 18 -17 83 -30 82 -7 -1 -123 -33 -259 -73z"/>
        <path d="M7750 2682 c-5 -20 -10 -42 -10 -48 0 -6 56 -26 125 -44 69 -18 125
        -36 125 -39 0 -3 -40 -153 -89 -334 l-88 -327 50 -15 c28 -8 52 -13 54 -11 2
        1 42 147 89 322 46 175 86 324 89 330 2 8 43 1 127 -22 68 -19 126 -34 129
        -34 8 0 28 90 20 94 -3 2 -136 38 -296 80 -159 43 -295 79 -302 81 -7 2 -17
        -11 -23 -33z"/>
        <path d="M11817 2599 c-79 -12 -177 -60 -222 -110 -20 -23 -49 -67 -64 -98
        -24 -51 -26 -67 -26 -181 0 -123 0 -126 37 -202 43 -91 98 -154 167 -192 186
        -101 458 -4 530 188 65 174 16 384 -119 509 -83 78 -179 105 -303 86z m134
        -105 c78 -23 148 -97 179 -190 31 -92 33 -210 4 -274 -77 -168 -295 -210 -424
        -81 -70 70 -94 139 -95 271 0 120 15 162 81 219 74 65 158 83 255 55z"/>
        <path d="M8657 2479 c-51 -82 -408 -701 -405 -704 2 -2 26 -7 54 -10 l51 -7
        53 93 c29 52 57 99 62 105 6 8 52 4 160 -15 l152 -26 21 -120 20 -120 53 -8
        c30 -5 55 -7 58 -5 3 4 -124 723 -141 794 -6 25 -15 30 -78 39 -40 6 -47 5
        -60 -16z m77 -283 c15 -93 25 -170 23 -173 -3 -2 -53 5 -113 15 -80 14 -108
        22 -106 32 4 17 164 301 168 298 1 -2 14 -79 28 -172z"/>
        <path d="M10680 2435 c-117 -33 -179 -105 -187 -218 -4 -52 -1 -69 19 -107 35
        -67 97 -94 269 -118 167 -23 209 -41 228 -98 13 -36 13 -47 0 -80 -42 -114
        -313 -135 -382 -31 -9 13 -20 40 -25 58 -5 22 -14 34 -25 33 -9 0 -33 -1 -52
        -2 -33 -2 -35 -4 -29 -29 15 -71 38 -115 81 -152 65 -57 125 -75 237 -68 194
        11 306 100 306 245 0 79 -31 137 -93 176 -39 24 -71 32 -208 51 -197 29 -213
        37 -217 114 -4 65 14 96 71 122 50 22 153 25 200 5 40 -17 76 -55 86 -92 7
        -28 10 -29 57 -26 27 2 50 5 51 6 2 1 -4 26 -12 54 -20 65 -66 119 -124 145
        -59 26 -179 32 -251 12z"/>
        <path d="M9068 2386 l-3 -45 45 -5 c25 -3 85 -8 133 -12 l88 -6 -6 -57 c-8
        -81 -45 -571 -45 -602 0 -24 5 -27 47 -33 26 -3 50 -3 54 1 4 5 18 158 31 340
        14 183 27 335 30 338 2 3 60 1 128 -5 68 -6 125 -8 128 -6 2 3 7 25 9 51 5 42
        4 45 -18 45 -13 0 -139 9 -279 20 -140 11 -274 20 -296 20 l-42 0 -4 -44z"/>
        <path d="M9794 2379 c-3 -6 -3 -184 1 -395 l7 -384 289 0 289 0 0 50 0 50
        -235 0 -235 0 -1 100 c-1 55 -1 112 -2 128 l-1 27 212 3 212 2 0 52 0 51 -215
        -7 -215 -6 0 120 0 120 204 0 c112 0 218 3 235 6 29 6 31 9 31 50 l0 44 -284
        0 c-195 0 -287 -3 -292 -11z"/>
        </g>
    </svg>
    </>
  )
}
