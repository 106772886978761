import React from 'react'

export default function Sis() {
  return (
    <>
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="100.000000pt" height="100.000000pt" viewBox="0 0 880.000000 1276.000000"
 preserveAspectRatio="xMidYMid meet">

<g transform="translate(0.000000,1276.000000) scale(0.100000,-0.100000)"
fill="#ffffff" stroke="none">
<path d="M4225 12173 c-379 -9 -836 -93 -1245 -230 -299 -99 -451 -162 -750
-311 -242 -119 -281 -142 -326 -189 l-51 -52 -89 -11 c-219 -25 -381 0 -683
105 -57 20 -110 35 -118 33 -20 -4 35 -85 107 -158 81 -83 152 -126 263 -161
53 -16 97 -35 97 -41 0 -17 -507 -4 -600 16 -62 13 -77 14 -88 3 -25 -26 20
-53 233 -140 370 -151 534 -192 765 -191 138 0 164 3 241 27 47 14 124 44 172
66 47 23 97 41 111 41 39 0 75 17 122 59 100 87 485 233 809 306 660 148 1879
138 2500 -21 288 -74 623 -211 717 -295 38 -33 56 -42 95 -46 26 -3 93 -25
148 -49 166 -72 236 -87 415 -88 180 -1 249 11 465 81 155 50 490 186 518 211
26 23 21 42 -11 42 -16 0 -73 -7 -128 -15 -109 -16 -544 -21 -544 -6 0 4 33
18 73 30 161 49 250 106 344 225 90 115 87 124 -33 81 -313 -114 -510 -143
-737 -111 -89 12 -89 13 -135 64 -54 58 -136 107 -387 227 -330 159 -649 276
-982 360 -426 108 -808 149 -1288 138z m640 -78 c440 -51 836 -151 1246 -314
198 -78 235 -95 482 -214 106 -52 177 -92 177 -100 0 -8 -21 -32 -47 -53 -27
-21 -109 -94 -185 -161 -75 -68 -143 -123 -152 -123 -9 0 -43 13 -75 30 -198
99 -488 196 -766 254 -305 64 -550 86 -1005 93 -776 11 -1237 -44 -1710 -206
-131 -44 -320 -121 -345 -140 -5 -4 -29 -15 -52 -24 l-42 -16 -128 117 c-71
64 -154 139 -185 165 -32 27 -55 54 -52 61 6 14 355 187 524 259 502 213 1053
350 1555 387 166 12 603 4 760 -15z m-3707 -691 c71 -29 257 -74 357 -85 108
-12 198 -7 316 18 46 9 83 12 92 7 8 -5 46 -45 83 -90 38 -44 95 -110 127
-146 31 -36 57 -72 57 -81 0 -18 -116 -66 -240 -99 -99 -28 -305 -30 -435 -4
-171 33 -515 143 -515 164 0 4 82 6 182 4 165 -3 196 -1 311 22 122 25 257 70
257 86 0 4 -69 10 -152 13 -171 5 -241 22 -360 84 -67 35 -148 97 -148 114 0
13 26 11 68 -7z m6552 1 c0 -23 -110 -99 -195 -135 -103 -42 -204 -60 -341
-60 -118 0 -131 -6 -74 -35 35 -18 194 -60 285 -75 87 -14 245 -17 348 -6 50
5 77 4 77 -2 0 -25 -354 -138 -540 -173 -112 -21 -321 -17 -421 9 -92 24 -238
77 -253 93 -12 11 -11 11 148 194 l119 137 41 -5 c22 -3 88 -13 146 -22 109
-17 240 -15 345 7 78 16 227 59 255 74 34 18 60 17 60 -1z"/>
<path d="M4285 11927 c19 -60 17 -179 -6 -267 -3 -12 8 -16 50 -18 49 -3 53
-2 45 15 -5 10 -9 74 -9 143 l0 125 29 0 c26 0 31 -5 39 -35 14 -52 4 -219
-13 -230 -11 -7 -8 -10 12 -15 14 -4 35 -5 47 -3 20 3 21 9 24 154 l2 152
-113 0 c-112 0 -114 0 -107 -21z"/>
<path d="M4600 11932 c-26 -12 -36 -24 -38 -46 -6 -52 7 -75 63 -111 69 -45
81 -65 56 -96 -17 -21 -21 -22 -47 -10 -15 7 -34 21 -42 32 -20 28 -39 23 -46
-13 -3 -17 -4 -33 -1 -36 9 -10 109 -21 132 -16 23 6 83 67 83 84 0 30 -26 64
-73 94 -63 40 -80 59 -75 83 6 32 54 28 96 -8 l37 -33 3 34 c3 24 -2 38 -14
47 -25 18 -93 16 -134 -5z"/>
<path d="M3948 11933 c-43 -2 -78 -5 -78 -7 0 -2 7 -20 15 -39 10 -25 15 -72
15 -151 l0 -116 113 7 c61 3 115 9 120 13 4 3 1 23 -8 43 -19 45 -11 87 16 87
11 0 35 6 54 14 53 22 64 89 22 134 -22 23 -42 25 -269 15z m86 -29 c3 -9 9
-67 12 -129 7 -106 6 -113 -13 -123 -15 -8 -25 -8 -38 2 -14 10 -20 36 -28
123 -6 61 -7 118 -4 127 8 21 63 22 71 0z m126 -11 c23 -29 21 -66 -5 -92 -24
-24 -43 -6 -47 46 -3 26 -3 54 0 61 7 20 29 14 52 -15z"/>
<path d="M5007 11906 c-21 -8 -47 -22 -57 -31 -30 -27 -53 -99 -46 -139 20
-105 170 -172 257 -113 76 51 99 133 58 206 -41 73 -133 106 -212 77z m121
-51 c52 -59 55 -145 7 -198 -29 -30 -53 -34 -94 -12 -54 28 -77 118 -47 189
27 66 87 75 134 21z"/>
<path d="M3675 11901 c-44 -11 -70 -27 -99 -62 -21 -25 -26 -41 -26 -87 0
-100 59 -162 156 -162 69 0 154 42 154 76 0 34 -25 31 -71 -11 -49 -44 -79
-45 -125 -2 -65 59 -66 168 -1 207 45 28 88 26 117 -5 29 -30 50 -32 50 -4 0
45 -17 59 -73 58 -29 -1 -65 -4 -82 -8z"/>
<path d="M5304 11793 c-1 -64 -8 -104 -24 -146 -12 -32 -19 -61 -16 -64 3 -3
44 -11 91 -16 l86 -11 32 32 31 32 -17 46 c-16 45 -16 48 3 79 11 18 20 39 20
47 0 27 -48 61 -97 69 -26 4 -61 11 -78 14 l-30 6 -1 -88z m131 -7 c0 -25 -6
-42 -16 -49 -26 -16 -49 -1 -49 32 0 48 10 62 39 59 23 -3 26 -7 26 -42z m-31
-92 c41 -41 26 -104 -24 -104 -28 0 -30 3 -30 38 0 77 20 101 54 66z"/>
<path d="M3420 11856 c-9 -12 -7 -21 9 -43 12 -15 25 -49 30 -75 14 -78 8 -77
-110 12 -69 53 -100 70 -124 70 -43 0 -48 -8 -26 -44 10 -17 26 -75 36 -128
15 -79 16 -100 5 -113 -18 -21 -37 -19 -54 8 -18 27 -56 179 -56 225 0 33 -1
33 -32 27 -54 -12 -60 -22 -38 -65 10 -20 27 -75 36 -122 27 -128 7 -149 -71
-77 -44 40 -58 80 -35 94 32 20 -15 115 -57 115 -33 0 -163 -33 -163 -42 0 -5
9 -26 20 -47 25 -50 53 -175 48 -213 -5 -30 -5 -30 36 -24 62 10 70 22 41 60
-27 35 -33 76 -12 76 7 0 32 -20 54 -45 34 -38 46 -45 76 -45 20 0 51 6 69 14
18 8 69 22 113 32 44 9 84 20 89 25 5 5 2 19 -8 34 -24 37 -48 165 -31 165 7
0 59 -36 116 -80 134 -104 153 -105 123 -4 -9 31 -17 94 -18 140 -1 84 -1 84
-27 84 -15 0 -32 -6 -39 -14z m-508 -163 c38 -43 28 -107 -15 -101 -16 2 -25
15 -34 48 -7 25 -11 51 -8 58 7 18 39 15 57 -5z"/>
<path d="M5587 11800 c-53 -42 -41 -102 33 -160 29 -23 40 -39 40 -59 0 -60
-45 -67 -87 -14 -21 25 -35 34 -46 30 -42 -16 -3 -69 64 -87 49 -14 89 -1 119
38 33 42 26 68 -41 137 -48 50 -59 68 -54 84 8 26 42 27 69 2 12 -10 38 -29
59 -40 33 -19 44 -20 79 -10 25 6 44 7 50 1 13 -13 -30 -176 -58 -217 l-22
-34 46 -15 c25 -9 49 -16 54 -16 4 0 8 21 8 48 0 44 29 177 44 201 15 24 47
11 62 -25 18 -44 38 -40 42 9 4 42 -6 49 -93 67 -33 7 -77 19 -98 27 -33 14
-40 14 -62 -1 -23 -15 -25 -15 -35 4 -26 49 -126 66 -173 30z"/>
<path d="M2578 11638 c-32 -11 -58 -23 -58 -28 0 -5 9 -24 19 -42 33 -57 72
-184 65 -212 -7 -31 8 -34 61 -12 40 17 44 35 15 61 -11 10 -20 30 -20 46 0
22 9 32 45 53 41 25 45 30 45 67 0 29 -7 47 -25 64 -29 30 -66 31 -147 3z m94
-35 c37 -33 10 -112 -30 -86 -17 10 -43 82 -35 96 8 13 46 7 65 -10z"/>
<path d="M6204 11643 c-6 -2 -42 -39 -79 -83 -37 -44 -83 -90 -101 -102 -40
-27 -43 -44 -10 -60 31 -14 56 -4 56 21 0 11 12 29 26 40 26 21 28 21 58 3 22
-13 30 -25 29 -40 -2 -12 -4 -32 -5 -44 -3 -22 16 -36 71 -53 22 -7 23 -5 16
21 -4 16 -12 86 -19 156 -12 124 -19 150 -42 141z m-26 -120 c-3 -31 -34 -44
-44 -19 -7 19 12 46 33 46 10 0 13 -9 11 -27z"/>
<path d="M752 10682 c-10 -7 -12 -532 -9 -2698 l3 -2689 21 -142 c65 -425 165
-759 337 -1118 211 -441 506 -831 871 -1152 564 -498 1226 -799 1976 -898 73
-10 229 -19 379 -22 366 -7 630 22 970 107 1133 284 2080 1119 2513 2215 113
286 184 561 229 880 l23 160 2 2663 c2 1851 0 2669 -7 2682 -11 20 -41 20
-3653 20 -2003 0 -3648 -4 -3655 -8z m4008 -1647 c171 -37 410 -112 437 -137
29 -27 129 -336 227 -703 137 -514 244 -1000 361 -1640 25 -137 50 -269 55
-292 15 -61 13 -86 -4 -76 -8 4 -68 80 -134 169 -65 88 -127 164 -136 167 -33
13 -38 -36 -33 -285 2 -131 1 -238 -4 -238 -12 0 -45 44 -169 228 -78 114
-116 162 -130 162 -10 0 -21 -4 -24 -9 -8 -12 -23 -164 -32 -303 -8 -145 -20
-152 -70 -40 -151 331 -187 402 -206 402 -11 0 -23 -11 -29 -27 -15 -38 -99
-393 -99 -418 0 -11 -5 -27 -10 -35 -16 -25 -30 0 -80 147 -26 76 -52 149 -59
161 -19 36 -44 23 -63 -33 -32 -92 -127 -400 -139 -447 -18 -75 -36 -62 -69
52 -41 143 -134 425 -143 438 -25 33 -42 4 -97 -158 -59 -175 -77 -199 -93
-125 -44 208 -103 438 -115 443 -29 11 -46 -20 -221 -402 -45 -98 -60 -103
-67 -24 -30 378 -30 378 -54 378 -25 0 -46 -27 -175 -223 -60 -91 -116 -168
-122 -171 -10 -4 -13 51 -13 259 0 149 -4 266 -9 269 -17 11 -65 -40 -151
-159 -47 -65 -100 -134 -117 -154 l-32 -36 -1 30 c0 17 9 75 20 130 10 55 28
147 39 205 58 309 85 449 112 585 32 158 146 664 180 800 111 441 265 950 296
977 34 30 454 144 593 162 19 2 130 3 245 1 185 -2 225 -6 335 -30z"/>
<path d="M3576 8580 c-64 -72 -116 -138 -116 -147 0 -15 1 -15 85 -1 18 3 21
-4 27 -91 4 -52 12 -109 18 -127 16 -47 85 -121 126 -134 47 -16 76 -7 155 45
110 74 101 87 97 -142 l-3 -200 39 -59 c57 -86 138 -176 246 -272 52 -46 103
-99 113 -118 21 -41 41 -43 56 -6 6 15 72 85 146 155 80 76 158 161 192 210
l58 82 1 199 c0 123 4 201 11 203 5 2 43 -19 84 -47 95 -65 125 -70 185 -35
80 47 124 149 124 288 l0 47 55 -6 c76 -9 73 5 -21 108 -42 45 -95 104 -117
130 -22 27 -48 48 -59 48 -10 0 -44 -24 -76 -52 -177 -161 -285 -248 -306
-248 -13 0 -87 22 -164 49 l-140 50 -139 -49 c-76 -27 -149 -50 -162 -50 -16
0 -48 21 -90 58 -190 167 -281 242 -294 242 -9 0 -68 -59 -131 -130z m251
-102 c63 -61 80 -167 39 -254 -27 -58 -70 -94 -114 -94 -36 0 -87 46 -108 97
-18 43 -18 166 0 201 16 30 63 76 86 85 26 10 64 -4 97 -35z m1250 22 c43 -26
61 -55 74 -121 23 -117 -41 -249 -122 -249 -43 0 -103 61 -119 120 -26 97 14
220 82 253 42 21 47 21 85 -3z"/>
<path d="M3732 8433 c-23 -9 -42 -62 -42 -118 0 -46 4 -60 25 -80 29 -30 65
-32 96 -5 l24 19 -32 26 c-40 32 -43 66 -8 89 58 38 5 96 -63 69z"/>
<path d="M5001 8405 c-11 -15 -23 -42 -27 -59 -8 -43 12 -113 36 -126 26 -14
73 -12 95 4 17 12 17 15 -13 41 -39 35 -40 60 -5 97 34 36 23 62 -29 66 -29 2
-40 -2 -57 -23z"/>
<path d="M1225 3063 c-183 -169 -404 -363 -446 -391 -27 -18 -49 -37 -49 -42
0 -5 11 -35 25 -67 42 -93 112 -332 145 -490 92 -440 84 -958 -22 -1386 -33
-134 -5 -141 113 -29 137 129 244 298 299 472 30 94 48 107 67 48 29 -90 43
-356 24 -486 -10 -69 3 -76 47 -26 48 56 107 183 136 294 42 161 51 258 51
523 0 136 4 247 8 247 5 0 34 -20 66 -44 161 -124 478 -313 705 -421 494 -234
956 -363 1541 -431 204 -24 695 -24 915 -1 596 64 1110 214 1619 472 227 115
436 241 596 360 110 82 102 94 97 -143 -7 -321 27 -546 108 -719 39 -85 94
-168 114 -176 8 -3 11 57 11 217 0 212 10 316 37 369 14 28 26 12 49 -66 50
-169 140 -319 271 -454 95 -97 143 -127 159 -102 5 8 7 23 3 34 -19 67 -65
314 -80 432 -27 210 -25 568 4 778 39 275 87 466 179 708 25 65 42 121 39 124
-3 4 -16 1 -29 -4 -22 -10 -43 7 -276 218 -138 126 -254 229 -257 229 -3 0
-74 -66 -157 -146 -189 -182 -362 -322 -582 -469 -207 -138 -323 -205 -525
-303 -581 -281 -1191 -423 -1825 -425 -935 -2 -1850 309 -2605 886 -131 100
-208 167 -381 329 -75 71 -138 128 -140 128 -2 -1 -26 -22 -54 -47z m145 -109
c158 -160 436 -383 658 -528 630 -411 1303 -644 2042 -706 151 -13 469 -13
645 0 713 53 1417 297 2035 705 232 154 401 288 612 486 69 65 131 119 137
119 6 0 91 -73 189 -162 98 -90 202 -184 232 -210 l55 -47 -24 -33 c-102 -139
-358 -410 -541 -573 -161 -143 -286 -238 -484 -369 -620 -413 -1274 -649
-2056 -743 -205 -25 -743 -24 -955 1 -1106 130 -2062 594 -2805 1362 -142 146
-300 333 -300 353 0 10 15 28 33 41 30 23 264 231 372 332 27 26 57 48 65 48
9 0 49 -34 90 -76z m-397 -749 c120 -180 287 -351 493 -506 l97 -73 -6 -220
c-3 -122 -11 -252 -16 -291 -19 -127 -53 -246 -77 -274 l-23 -26 -1 113 c0
136 -19 237 -68 357 -42 102 -67 121 -77 58 -12 -72 -74 -258 -113 -338 -42
-86 -132 -217 -190 -277 -62 -65 -63 -62 -19 147 40 190 60 431 53 630 -9 258
-45 509 -101 695 -14 46 -25 86 -25 88 0 13 37 -29 73 -83z m6886 18 c-33
-113 -77 -335 -88 -450 -34 -333 -21 -621 40 -927 16 -82 28 -151 26 -153 -18
-17 -132 118 -205 242 -52 89 -119 264 -138 363 -8 42 -17 78 -20 81 -21 22
-80 -95 -115 -229 -11 -42 -23 -132 -27 -205 -7 -129 -8 -130 -24 -99 -51 93
-88 344 -88 592 l0 188 28 25 c15 14 62 52 106 84 161 121 352 321 442 464 72
112 91 119 63 24z"/>
<path d="M1163 2748 c-33 -36 -35 -61 -13 -117 25 -63 26 -109 3 -118 -22 -9
-53 31 -53 68 0 31 -9 36 -28 17 -22 -22 -12 -51 34 -95 43 -41 47 -43 67 -30
12 8 30 28 40 45 17 29 17 33 -2 107 -16 60 -18 82 -9 98 11 20 11 20 35 -9
13 -16 37 -42 53 -59 l29 -30 -33 -70 c-18 -38 -45 -85 -60 -102 -15 -18 -26
-37 -24 -42 6 -18 119 -111 134 -111 31 0 11 43 -26 55 -32 11 -35 16 -34 48
1 20 7 44 14 52 12 14 15 14 38 -1 25 -16 26 -16 45 7 l19 24 -31 3 c-27 3
-31 7 -31 31 0 37 34 69 54 51 9 -6 18 -20 21 -31 4 -10 15 -19 26 -19 18 0
18 2 7 24 -7 13 -39 46 -71 74 -32 27 -71 68 -87 90 -47 65 -83 77 -117 40z"/>
<path d="M7501 2678 c0 -29 16 -107 18 -90 3 29 38 10 114 -62 33 -31 48 -28
74 11 14 20 11 23 -39 56 -29 19 -73 50 -96 70 -50 43 -72 47 -71 15z"/>
<path d="M7352 2568 c-61 -51 -87 -94 -87 -147 1 -65 11 -96 46 -136 28 -31
37 -35 80 -35 27 0 58 -6 69 -12 29 -19 43 0 29 39 -14 42 8 85 61 118 45 28
47 35 24 70 -15 24 -15 38 0 76 6 13 0 18 -24 21 -31 5 -32 4 -25 -26 20 -96
20 -98 -12 -125 -17 -14 -37 -40 -46 -57 -34 -66 -77 -69 -122 -10 -66 86 -34
196 57 196 42 0 50 16 20 43 -24 22 -23 22 -70 -15z"/>
<path d="M1470 2499 c0 -21 -73 -184 -91 -205 -25 -27 -24 -40 6 -60 27 -17
45 -12 45 14 0 24 31 65 41 55 4 -4 4 -28 -2 -52 -11 -51 2 -95 32 -112 18 -9
19 -5 19 60 0 65 2 72 40 122 22 30 40 60 40 68 0 17 -102 121 -118 121 -7 0
-12 -5 -12 -11z m68 -71 c7 -7 12 -19 12 -27 0 -19 -39 -61 -57 -61 -18 0 -17
50 3 78 17 25 25 27 42 10z"/>
<path d="M7177 2423 c-12 -11 -8 -52 6 -60 8 -4 26 -29 41 -55 38 -65 20 -70
-82 -24 -44 20 -82 36 -85 36 -9 0 -37 -31 -37 -40 0 -5 10 -21 23 -37 26 -32
77 -153 77 -183 0 -10 -5 -22 -10 -25 -21 -13 -49 8 -115 86 -37 44 -73 78
-79 76 -6 -2 -12 -18 -14 -36 -3 -27 6 -41 62 -100 36 -37 66 -73 66 -79 0
-22 32 -12 70 21 20 17 45 37 56 43 16 10 17 15 7 30 -20 31 -83 144 -83 149
0 14 50 1 117 -30 118 -54 124 -48 68 62 -19 38 -41 93 -47 121 -12 48 -25 62
-41 45z"/>
<path d="M1637 2363 c-2 -5 -8 -77 -11 -160 -7 -139 -6 -153 9 -153 9 0 29 14
43 32 71 84 108 121 134 131 27 11 28 13 13 35 -22 30 -40 28 -55 -7 -15 -38
-77 -104 -89 -97 -10 7 -7 43 11 140 10 53 10 60 -7 72 -21 15 -41 18 -48 7z"/>
<path d="M1825 2128 c-16 -50 -37 -106 -47 -124 -24 -46 -23 -62 7 -76 14 -6
26 -9 28 -7 2 2 13 38 26 79 13 41 34 98 47 126 30 64 30 70 -4 82 l-28 9 -29
-89z"/>
<path d="M1959 2112 c-42 -22 -72 -80 -77 -153 -4 -48 0 -62 23 -97 21 -32 25
-48 20 -71 -10 -48 14 -47 91 1 l69 43 -63 3 c-62 3 -64 4 -79 39 -23 55 -10
135 29 179 16 18 36 34 42 34 17 0 63 -50 75 -82 14 -38 31 -35 31 5 0 34 -18
57 -70 88 -43 26 -57 28 -91 11z"/>
<path d="M6699 2077 c-19 -12 -48 -32 -63 -44 -16 -13 -34 -23 -41 -23 -7 0
-18 -6 -25 -14 -9 -12 -7 -21 10 -43 11 -16 28 -66 38 -111 16 -80 16 -82 -4
-109 -46 -61 -91 -15 -119 121 -15 69 -22 86 -36 86 -31 0 -61 -41 -40 -55 6
-3 22 -44 36 -91 42 -136 90 -166 161 -99 l37 35 -7 64 c-3 35 -13 87 -22 115
-16 55 -12 91 11 91 22 0 40 -37 65 -135 13 -49 26 -94 30 -98 5 -5 17 -4 29
2 24 14 27 47 6 75 -17 22 -20 56 -5 56 5 0 25 -13 43 -30 43 -37 55 -37 111
1 57 38 62 46 42 68 -18 20 -20 44 -8 69 6 12 2 27 -15 51 l-23 34 0 -95 c0
-103 -11 -129 -47 -110 -43 23 -56 50 -54 108 2 47 -2 61 -20 80 -27 29 -43
29 -90 1z m49 -36 c8 -4 16 -27 19 -50 7 -50 -14 -56 -46 -15 -38 49 -19 95
27 65z"/>
<path d="M2158 1965 c-77 -52 -80 -183 -7 -256 66 -66 117 -61 167 14 54 81
33 190 -47 244 -43 29 -68 29 -113 -2z m87 -11 c45 -18 59 -127 26 -201 -29
-63 -57 -73 -90 -30 -36 45 -28 171 14 217 20 22 25 23 50 14z"/>
<path d="M6290 1863 c-52 -35 -71 -59 -86 -110 -14 -48 -14 -58 1 -102 18 -56
59 -98 100 -106 24 -4 40 2 78 30 l48 36 -3 57 c-3 56 -4 57 -33 57 -32 0 -32
3 -19 -87 5 -30 2 -38 -16 -48 -46 -24 -110 56 -110 137 0 56 13 86 43 102 21
11 29 11 55 -3 36 -18 46 -9 32 28 -11 31 -50 34 -90 9z"/>
<path d="M2467 1853 c-4 -3 -7 -20 -7 -36 0 -34 -24 -133 -47 -195 -9 -23 -13
-45 -10 -49 13 -12 122 -53 143 -53 53 0 104 76 104 157 0 72 -25 112 -94 150
-59 33 -77 39 -89 26z m102 -71 c29 -41 35 -75 21 -131 -13 -57 -41 -91 -74
-91 -45 0 -50 21 -26 122 32 137 43 150 79 100z"/>
<path d="M6063 1750 c-65 -30 -75 -44 -47 -65 14 -10 37 -111 48 -210 2 -16 8
-31 13 -32 13 -5 118 45 133 62 7 8 9 22 6 31 -6 15 -10 14 -34 -9 -16 -15
-36 -27 -45 -27 -21 0 -37 30 -37 69 0 23 5 30 23 33 26 4 38 48 13 48 -8 0
-18 -4 -21 -10 -12 -20 -24 -9 -35 30 -14 50 -8 60 35 60 39 0 52 14 35 35
-15 19 -13 19 -87 -15z"/>
<path d="M2693 1697 c-1 -44 -21 -130 -48 -212 -5 -18 3 -24 65 -49 56 -22 74
-25 86 -15 14 12 20 74 7 67 -5 -2 -20 -10 -35 -17 -42 -21 -62 -8 -53 37 9
51 13 55 45 48 20 -3 30 -1 34 10 10 26 7 31 -23 37 -31 7 -43 31 -30 65 16
43 69 36 69 -10 0 -29 24 -16 28 15 3 24 -3 29 -54 52 -83 37 -89 35 -91 -28z"/>
<path d="M5882 1677 c-42 -43 -44 -92 -6 -131 15 -14 33 -26 40 -26 18 0 74
-49 74 -66 0 -25 -41 -33 -77 -16 -18 9 -36 12 -40 9 -10 -11 25 -77 41 -77
28 0 101 65 104 92 6 50 -22 94 -80 124 -68 37 -74 67 -13 72 48 4 63 16 45
37 -20 24 -54 18 -88 -18z"/>
<path d="M2937 1593 c-3 -43 -13 -109 -22 -146 -19 -74 -17 -81 29 -93 25 -6
28 -4 23 9 -9 25 22 228 40 255 8 13 13 25 11 27 -2 1 -13 7 -25 14 -44 23
-50 16 -56 -66z"/>
<path d="M3040 1623 c1 -34 25 -101 44 -127 14 -17 37 -62 52 -101 26 -68 49
-105 65 -105 4 0 13 54 20 121 10 98 16 122 31 130 34 18 40 1 33 -90 -3 -47
-11 -106 -17 -130 -10 -39 -9 -45 8 -58 36 -26 49 -13 49 52 l0 60 38 3 c30 3
37 7 35 20 -2 10 -14 18 -30 20 -33 4 -40 20 -27 66 11 41 36 47 64 16 23 -25
35 -25 35 -1 0 28 -21 40 -98 56 -39 8 -82 19 -95 24 -14 6 -28 7 -31 4 -3 -4
-6 -44 -6 -91 0 -78 -1 -83 -17 -71 -9 8 -34 52 -56 99 -22 47 -47 90 -56 97
-18 14 -41 17 -41 6z"/>
<path d="M5656 1614 c-66 -18 -70 -24 -50 -70 10 -24 18 -78 22 -143 l5 -103
38 5 c63 8 114 38 114 66 0 31 -21 39 -30 12 -6 -20 -50 -40 -61 -28 -3 3 -8
24 -11 47 -5 38 -4 41 26 53 44 16 42 47 -3 47 -36 0 -46 12 -46 57 0 24 2 25
41 20 44 -6 62 11 39 38 -14 17 -18 17 -84 -1z"/>
<path d="M5415 1548 c-27 -5 -54 -13 -59 -18 -4 -4 -1 -38 9 -75 9 -36 18
-101 20 -143 l3 -77 32 3 c63 5 130 42 151 83 34 69 23 158 -29 212 -28 29
-44 31 -127 15z m91 -53 c52 -57 33 -196 -27 -203 -33 -4 -36 3 -50 119 -8 70
-8 93 2 105 15 19 47 10 75 -21z"/>
<path d="M4744 1516 c-3 -8 -1 -23 5 -34 9 -16 5 -26 -23 -55 -52 -55 -67 -95
-57 -154 8 -52 39 -106 71 -123 53 -28 129 0 160 60 38 75 15 180 -49 228 -21
15 -28 27 -24 42 3 11 0 27 -7 35 -16 19 -69 20 -76 1z m100 -153 c21 -42 21
-141 0 -171 -18 -26 -55 -29 -76 -4 -29 33 -39 63 -41 121 -1 50 3 65 27 99
l28 41 23 -27 c12 -15 30 -41 39 -59z"/>
<path d="M3530 1513 c-32 -11 -81 -69 -91 -107 -32 -117 68 -244 162 -206 69
29 102 159 61 240 -23 46 -95 86 -132 73z m66 -58 c57 -87 20 -256 -50 -229
-9 4 -26 25 -38 47 -38 76 -9 207 47 207 15 0 31 -10 41 -25z"/>
<path d="M5157 1490 c-15 -12 -27 -28 -27 -35 0 -25 -21 -27 -44 -6 -20 18
-33 22 -82 19 -56 -3 -59 -4 -56 -28 2 -14 6 -83 9 -154 l5 -128 56 7 c31 4
66 11 80 16 13 5 46 9 73 9 39 0 55 5 74 24 55 55 41 115 -41 171 -78 54 -71
105 10 71 l37 -15 -3 32 c-3 28 -7 32 -34 35 -19 2 -40 -5 -57 -18z m-89 -56
c21 -4 34 -15 43 -36 6 -16 33 -45 58 -65 54 -44 71 -62 71 -80 0 -21 -44 -26
-85 -9 -37 16 -37 16 -66 -15 -19 -20 -36 -29 -51 -27 -20 3 -23 10 -26 50 -3
44 -2 47 25 50 37 4 40 41 3 47 -30 4 -40 17 -40 52 0 40 9 45 68 33z"/>
<path d="M3037 1480 c-4 -14 -7 -39 -7 -56 0 -17 -9 -43 -21 -58 l-20 -26 26
-12 c41 -18 47 -2 38 93 -6 60 -10 77 -16 59z"/>
<path d="M3717 1354 c-3 -66 -9 -134 -13 -151 -6 -32 -6 -33 30 -33 l36 0 -3
46 c-3 61 18 70 33 15 12 -46 44 -81 74 -81 12 0 30 -3 41 -5 34 -9 43 6 35
55 -5 35 -8 41 -14 25 -14 -37 -23 -50 -36 -50 -41 0 -79 127 -46 154 10 9 16
29 16 56 0 56 -24 77 -94 83 l-54 4 -5 -118z m103 36 c0 -41 -10 -60 -31 -60
-16 0 -19 8 -19 48 0 56 5 65 31 51 12 -6 19 -21 19 -39z"/>
<path d="M3934 1380 c4 -49 13 -80 27 -100 12 -16 27 -50 34 -76 7 -27 16 -49
21 -51 20 -6 35 16 40 64 6 44 4 51 -14 55 -15 4 -23 19 -31 64 -15 77 -31
108 -60 112 -24 4 -24 3 -17 -68z"/>
<path d="M4116 1425 c-23 -23 -29 -77 -16 -137 9 -39 10 -68 2 -101 -5 -26 -7
-49 -3 -51 3 -2 37 -5 76 -7 l69 -4 8 44 c14 81 58 92 73 18 13 -63 15 -67 46
-67 16 0 29 5 29 12 0 7 -13 51 -29 98 -15 47 -35 105 -42 130 -10 33 -19 45
-33 45 -25 0 -40 -49 -26 -90 8 -23 7 -31 -4 -37 -8 -5 -20 -25 -26 -46 -18
-55 -38 -82 -62 -82 -21 0 -21 2 -15 145 5 137 5 145 -13 145 -10 0 -25 -7
-34 -15z"/>
<path d="M4501 1422 c-46 -25 -71 -74 -71 -140 0 -67 19 -113 60 -142 18 -13
39 -39 47 -59 l15 -36 19 24 c10 13 19 29 19 35 0 7 14 25 30 41 32 30 39 55
17 55 -8 0 -24 -11 -37 -25 -14 -14 -33 -25 -44 -23 -46 7 -80 100 -66 178 14
77 65 103 110 55 31 -33 49 -32 42 0 -10 53 -78 71 -141 37z"/>
</g>
</svg>

    </>
  )
}
