import React from 'react';

const Canadian = () => {
    return (
        <>
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="200.000000pt" height="100.000000pt" viewBox="0 0 1920.000000 826.000000"
            preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,826.000000) scale(0.100000,-0.100000)"
            fill="#ffffff" stroke="none">
            <path d="M3222 7633 c32 -35 62 -93 48 -93 -6 0 -31 19 -55 41 l-45 42 0 -132
            c0 -72 3 -131 7 -131 4 0 21 14 39 31 46 45 59 32 23 -22 -16 -26 -33 -64 -36
            -85 -6 -40 -56 -124 -73 -124 -12 0 -13 -33 -1 -46 5 -5 14 -27 21 -49 7 -22
            23 -54 36 -71 20 -28 21 -31 5 -27 -12 3 -27 -6 -44 -26 -14 -17 -33 -31 -41
            -31 -22 0 -20 5 9 34 49 49 17 126 -53 126 -47 0 -75 -32 -70 -81 2 -21 2 -39
            0 -39 -2 0 -20 10 -41 22 l-38 22 31 21 c59 40 55 108 -9 137 -52 23 -117 -37
            -100 -93 6 -18 4 -21 -10 -15 -9 3 -28 9 -41 12 -29 7 -30 15 -4 39 28 25 26
            76 -3 103 -31 29 -77 29 -105 -1 -23 -24 -30 -81 -12 -92 24 -15 6 -25 -42
            -25 -40 0 -49 3 -40 12 19 19 14 76 -8 98 -30 30 -83 27 -109 -6 -28 -36 -26
            -70 5 -101 14 -14 24 -26 22 -27 -2 0 -17 -9 -35 -18 -40 -23 -46 -23 -38 2 7
            21 -9 58 -34 77 -23 18 -66 16 -90 -4 -52 -44 -24 -133 42 -133 l31 0 -44 -46
            c-42 -42 -45 -44 -48 -22 -10 63 -88 90 -130 45 -48 -51 -9 -133 60 -125 l34
            3 -20 -50 -21 -51 -20 24 c-29 34 -81 33 -112 -2 -30 -35 -29 -71 2 -101 28
            -29 61 -32 93 -9 22 15 22 15 22 -37 0 -50 -1 -51 -18 -36 -10 10 -32 17 -48
            17 -62 0 -97 -55 -70 -109 25 -46 75 -55 120 -20 18 14 26 16 26 7 0 -7 3 -23
            6 -35 6 -21 3 -23 -33 -23 -49 0 -73 -24 -73 -73 0 -27 7 -41 26 -56 15 -12
            34 -21 44 -21 12 0 28 -26 55 -90 37 -89 37 -90 20 -126 -14 -30 -15 -40 -4
            -65 13 -32 36 -49 66 -49 14 0 30 -31 72 -135 51 -129 54 -138 44 -182 l-11
            -45 40 -16 c30 -12 41 -22 45 -44 4 -21 11 -28 29 -28 12 0 26 5 29 11 4 6 19
            10 33 9 40 -3 49 0 27 9 -17 7 -14 10 20 20 22 6 55 11 73 11 18 0 31 4 28 9
            -8 12 67 25 94 16 15 -5 18 -3 14 9 -5 13 5 15 67 12 61 -3 70 -2 56 9 -15 11
            -9 12 35 10 29 -1 64 -5 78 -9 l25 -8 -25 22 -25 22 53 -6 c29 -3 62 -11 73
            -17 40 -21 30 0 -10 23 -46 26 -56 58 -17 58 16 0 27 7 31 20 8 26 -13 53 -37
            47 -26 -7 -24 30 2 38 13 4 20 15 20 32 0 16 -7 27 -20 30 -36 9 -24 41 18 49
            49 10 324 10 385 0 l47 -7 0 -49 c0 -27 -4 -52 -10 -55 -5 -3 -10 -14 -10 -24
            0 -14 -6 -17 -30 -13 -34 5 -51 -19 -33 -47 15 -24 46 -19 60 9 9 18 13 20 17
            10 10 -31 11 -100 1 -104 -28 -10 8 -13 69 -5 75 9 89 6 62 -14 -14 -10 -7
            -12 44 -12 33 0 60 2 60 6 0 3 12 4 27 1 27 -5 27 -5 5 -15 -32 -14 -27 -28 6
            -16 22 8 54 8 101 0 2 -1 -4 -11 -15 -23 -10 -13 -12 -17 -4 -10 17 14 96 16
            105 2 3 -5 -1 -10 -9 -10 -9 0 -16 -6 -16 -13 0 -9 11 -12 35 -9 19 2 51 1 71
            -2 l35 -7 -23 -19 -23 -19 32 9 c18 4 41 6 53 3 18 -5 19 -2 14 25 -5 24 -1
            36 18 55 13 13 30 26 38 29 11 3 12 12 1 49 -12 42 -10 50 22 139 77 211 77
            210 102 210 27 0 65 42 65 72 0 11 -8 33 -17 48 -16 27 -15 32 11 102 25 68
            25 74 9 85 -9 7 -23 13 -30 13 -15 0 -15 2 -7 24 5 12 10 10 28 -11 32 -36 81
            -36 113 1 26 30 28 44 12 80 -14 29 -59 50 -89 41 -19 -6 -20 -4 -14 22 9 38
            11 39 34 18 31 -28 79 -24 107 9 12 15 23 37 23 49 0 57 -68 94 -115 63 l-25
            -16 0 39 0 40 32 -13 c82 -35 146 57 83 119 -33 33 -70 32 -104 -3 l-26 -29
            -21 54 c-21 52 -21 53 -2 47 31 -9 75 15 89 48 15 38 -2 76 -44 96 -35 17 -76
            -1 -97 -44 l-15 -30 -34 38 -33 38 25 0 c40 0 71 32 71 73 0 47 -27 77 -69 77
            -47 0 -79 -29 -79 -73 l0 -35 -43 21 -42 21 24 22 c51 47 17 134 -52 134 -52
            0 -95 -84 -61 -118 9 -9 1 -12 -36 -12 -47 0 -47 0 -32 22 23 32 20 65 -9 93
            -65 65 -158 -5 -116 -88 l17 -34 -42 -15 -42 -15 5 31 c7 45 -27 86 -73 86
            -93 0 -102 -127 -11 -151 19 -5 15 -9 -30 -32 -29 -15 -54 -27 -56 -27 -1 0
            -1 15 1 34 3 27 -2 40 -22 60 -30 31 -64 33 -100 5 -36 -28 -36 -81 0 -114 15
            -14 21 -25 14 -25 -7 0 -23 11 -36 25 -13 14 -33 25 -44 25 -21 0 -21 1 3 30
            14 16 28 44 32 62 4 18 11 38 16 44 6 7 1 10 -14 10 -16 -1 -23 6 -25 23 -3
            21 -1 23 22 19 24 -5 25 -4 12 11 -49 52 -74 90 -81 121 -4 19 -18 52 -32 74
            -25 38 -21 39 29 4 14 -10 29 -18 32 -18 3 0 6 62 6 137 l0 137 -43 -42 c-23
            -23 -46 -42 -50 -42 -11 0 12 52 39 87 l25 33 -132 0 -133 0 26 -27z m179 -22
            c-12 -16 -24 -42 -27 -58 -5 -26 -9 -28 -37 -25 -27 3 -36 12 -59 58 l-26 54
            85 0 84 0 -20 -29z m-138 -90 c22 -9 26 -14 19 -28 -5 -10 -7 -24 -5 -30 3 -7
            0 -13 -5 -13 -5 0 -26 -11 -46 -25 l-36 -24 0 86 0 86 23 -21 c12 -11 35 -25
            50 -31z m207 -47 l0 -95 -38 27 c-35 25 -39 32 -39 71 0 31 5 44 17 48 10 3
            25 14 35 25 10 11 20 20 22 20 1 0 3 -43 3 -96z m-106 27 c20 -22 14 -56 -11
            -65 -47 -18 -81 35 -43 66 28 23 33 22 54 -1z m-12 -89 c16 2 27 -10 55 -61
            18 -35 31 -66 29 -69 -10 -9 -36 20 -36 39 0 11 -7 19 -15 19 -8 0 -15 4 -15
            9 0 18 -31 20 -65 3 -23 -11 -35 -24 -35 -38 0 -13 -10 -25 -26 -33 -25 -11
            -26 -10 -20 12 3 13 20 48 38 79 28 47 36 54 51 46 10 -5 27 -8 39 -6z m8 -82
            c0 -24 -26 -38 -42 -22 -16 16 -2 42 22 42 13 0 20 -7 20 -20z m-2 -63 c4 -20
            -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11 21 -21z m-88 -32 c0 -35 -1
            -35 -42 -35 l-43 1 37 34 c21 19 40 35 43 35 3 0 5 -16 5 -35z m201 -17 c8
            -16 5 -18 -24 -18 -34 0 -46 15 -47 58 0 17 58 -16 71 -40z m-113 -16 c3 -16
            -2 -22 -17 -22 -26 0 -36 17 -20 36 16 19 33 13 37 -14z m654 -9 c39 -35 7
            -98 -48 -91 -18 2 -34 13 -43 29 -13 24 -13 29 2 53 20 30 61 35 89 9z m-1457
            -18 c30 -29 31 -49 5 -75 -24 -24 -57 -26 -82 -3 -38 34 -11 97 45 102 4 1 19
            -10 32 -24z m213 13 c7 -7 12 -26 12 -43 0 -17 -5 -36 -12 -43 -7 -7 -26 -12
            -43 -12 -17 0 -36 5 -43 12 -7 7 -12 26 -12 43 0 17 5 36 12 43 7 7 26 12 43
            12 17 0 36 -5 43 -12z m1427 6 c27 -11 38 -53 21 -80 -21 -31 -52 -39 -81 -20
            -60 40 -8 126 60 100z m-1017 -57 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40
            10 -2 19 -11 21 -21z m60 0 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2
            19 -11 21 -21z m60 0 c4 -20 -25 -34 -40 -19 -15 15 -1 44 19 40 10 -2 19 -11
            21 -21z m57 2 c0 -7 -8 -15 -17 -17 -18 -3 -25 18 -11 32 10 10 28 1 28 -15z
            m65 2 c0 -26 -17 -36 -35 -21 -18 15 -8 40 16 40 12 0 19 -7 19 -19z m63 6 c8
            -20 -9 -41 -27 -34 -23 9 -21 47 3 47 10 0 21 -6 24 -13z m335 -15 c6 -9 12
            -25 12 -36 0 -52 -75 -76 -104 -34 -22 31 -20 45 9 74 27 27 59 26 83 -4z
            m-868 -12 c11 -11 20 -29 20 -40 0 -26 -34 -60 -60 -60 -26 0 -60 34 -60 60 0
            26 34 60 60 60 11 0 29 -9 40 -20z m1434 -21 c30 -23 33 -54 9 -81 -34 -38
            -103 -9 -103 43 0 16 44 59 60 59 4 0 19 -9 34 -21z m-2000 -11 c23 -32 20
            -54 -11 -77 -57 -46 -125 27 -76 81 23 25 68 23 87 -4z m1116 -29 c0 -25 -41
            -76 -79 -97 l-41 -23 6 31 c13 63 -67 98 -102 45 -14 -21 -15 -31 -5 -51 10
            -23 10 -24 -12 -13 -43 22 -97 86 -97 114 0 3 74 5 165 5 116 0 165 -3 165
            -11z m712 -32 c108 -53 177 -128 236 -255 24 -51 27 -68 26 -162 -1 -93 -4
            -115 -33 -192 -18 -49 -36 -90 -40 -92 -3 -2 -21 2 -38 9 l-32 14 23 53 c57
            130 60 247 9 358 -42 90 -100 152 -181 192 -60 30 -76 33 -152 33 l-85 0 -125
            -67 c-119 -63 -219 -100 -302 -112 l-38 -5 0 43 0 43 65 12 c87 16 155 43 244
            98 102 64 138 74 253 69 89 -3 105 -6 170 -39z m-1451 17 c30 -9 88 -35 129
            -59 105 -62 161 -85 238 -99 64 -12 67 -13 70 -43 l3 -30 -53 4 c-92 7 -151
            24 -228 66 -207 111 -257 124 -366 92 -249 -72 -353 -332 -238 -594 13 -29 24
            -55 24 -56 0 -8 -65 -24 -71 -18 -5 5 -20 40 -35 78 -31 83 -43 230 -25 314
            31 145 145 278 288 337 70 29 182 32 264 8z m347 -7 c17 -14 15 -63 -4 -82
            -24 -24 -76 -16 -90 14 -30 59 40 110 94 68z m536 4 c25 -27 17 -72 -15 -90
            -24 -13 -29 -13 -53 2 -20 13 -26 26 -26 52 0 53 59 75 94 36z m-274 -54 c0
            -43 -44 -63 -70 -32 -16 19 -5 62 18 68 27 6 52 -11 52 -36z m-584 -58 c38
            -20 101 -52 139 -73 65 -34 112 -46 238 -61 l47 -6 0 -101 0 -101 -58 7 c-59
            7 -209 40 -342 77 -111 30 -170 8 -170 -65 l0 -25 -42 15 c-52 19 -121 13
            -175 -14 -48 -25 -67 -46 -91 -101 l-20 -44 -12 56 c-17 75 -8 199 18 260 26
            59 103 142 157 170 115 59 206 60 311 6z m1376 12 c62 -30 130 -90 161 -144
            53 -90 70 -212 43 -302 l-11 -39 -14 44 c-16 55 -42 88 -89 117 -44 27 -119
            30 -163 7 -17 -8 -32 -14 -34 -12 -1 2 -10 19 -19 39 -34 72 -81 75 -260 15
            -125 -43 -302 -83 -313 -72 -3 3 -1 47 3 98 l9 93 55 6 c91 10 139 27 276 99
            72 37 139 70 150 73 43 13 159 0 206 -22z m358 9 c11 -11 20 -29 20 -40 0 -26
            -34 -60 -60 -60 -26 0 -60 34 -60 60 0 26 34 60 60 60 11 0 29 -9 40 -20z
            m-2282 -2 c7 -7 12 -26 12 -43 0 -39 -16 -55 -55 -55 -39 0 -55 16 -55 55 0
            39 16 55 55 55 17 0 36 -5 43 -12z m1273 2 c29 -16 25 -44 -6 -36 -39 10 -46
            -25 -52 -241 -6 -179 -7 -193 -24 -193 -18 0 -19 11 -19 183 0 200 8 246 47
            277 30 23 29 23 54 10z m-280 -26 c24 -25 24 -29 27 -235 3 -176 1 -209 -11
            -209 -12 0 -16 34 -22 183 -4 100 -12 198 -19 217 -10 31 -15 35 -41 32 -29
            -3 -29 -3 -11 17 25 28 47 26 77 -5z m179 -13 c-5 -11 -11 -30 -11 -43 -1 -20
            -2 -21 -6 -3 -3 11 -10 26 -15 32 -9 11 10 26 40 32 1 1 -2 -8 -8 -18z m-120
            0 c0 -17 -18 -21 -24 -6 -3 9 0 15 9 15 8 0 15 -4 15 -9z m75 -45 c0 -52 -63
            -62 -73 -12 -7 33 6 47 40 44 29 -3 33 -7 33 -32z m25 -62 c0 -22 -2 -25 -15
            -14 -12 10 -13 16 -4 26 16 20 19 18 19 -12z m-95 7 c3 -5 1 -12 -5 -16 -5 -3
            -10 1 -10 9 0 18 6 21 15 7z m70 -55 c0 -52 -63 -62 -73 -12 -7 33 6 47 40 44
            29 -3 33 -7 33 -32z m-1210 -11 c30 -29 31 -49 5 -75 -24 -24 -57 -26 -82 -3
            -24 21 -23 58 2 83 26 26 46 25 75 -5z m2449 9 c25 -25 20 -71 -10 -91 -24
            -15 -29 -15 -53 -2 -16 9 -27 25 -29 43 -6 53 55 87 92 50z m-1214 -60 c0 -22
            -2 -25 -15 -14 -12 10 -13 16 -4 26 16 20 19 18 19 -12z m-95 7 c3 -5 1 -12
            -5 -16 -5 -3 -10 1 -10 9 0 18 6 21 15 7z m70 -55 c0 -52 -63 -62 -73 -12 -7
            33 6 47 40 44 29 -3 33 -7 33 -32z m613 8 c26 -18 30 -65 7 -84 -27 -22 -10
            -21 59 5 85 32 139 33 160 3 18 -25 21 -90 6 -118 -11 -21 -60 -40 -103 -40
            -43 0 -23 -18 39 -35 l49 -13 -50 -1 c-35 -1 -62 6 -92 23 -27 16 -40 19 -36
            10 7 -20 64 -52 107 -61 45 -10 48 -25 6 -37 -26 -8 -34 -6 -42 7 -5 9 -27 25
            -48 36 -32 15 -40 16 -44 5 -3 -8 -13 -14 -23 -14 -16 0 -18 8 -15 70 4 73
            -13 175 -29 186 -17 10 -67 -46 -100 -110 -32 -65 -42 -76 -76 -76 -30 0 -29
            6 5 35 l27 25 -40 -16 c-47 -19 -119 -14 -143 10 -15 15 -12 16 31 16 49 0
            107 22 107 41 0 6 -7 8 -16 5 -97 -37 -144 -28 -108 21 16 22 50 38 155 75
            147 51 174 55 207 32z m-1121 -45 c121 -43 151 -58 158 -80 4 -11 1 -26 -5
            -34 -20 -24 7 -18 30 7 20 22 20 22 20 1 0 -33 -25 -46 -75 -41 -52 6 -51 -8
            1 -18 23 -4 49 -1 73 8 37 15 38 15 33 -4 -9 -35 -47 -51 -105 -45 -29 3 -61
            8 -72 12 -19 6 -19 6 -2 -8 18 -15 18 -15 -1 -21 -10 -4 -28 -15 -39 -25 -20
            -19 -20 -19 -31 29 -30 129 -142 270 -142 179 0 -11 -4 -17 -10 -14 -14 9 -13
            58 2 73 20 20 71 14 165 -19z m533 -24 c0 -22 -2 -24 -14 -13 -10 9 -11 17 -4
            26 15 17 18 15 18 -13z m-95 5 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10
            4 10 3 0 8 -4 11 -10z m1307 -17 c25 -23 23 -68 -4 -87 -32 -23 -54 -20 -77
            10 -26 33 -26 49 -1 74 24 24 57 26 82 3z m-1248 -6 c38 -27 -2 -95 -41 -71
            -25 16 -29 55 -7 71 10 7 21 13 24 13 3 0 14 -6 24 -13z m-1200 -13 c37 -37 3
            -98 -50 -92 -18 2 -34 13 -43 29 -13 24 -13 29 2 53 20 30 66 35 91 10z m482
            -1 l52 -16 4 -96 c2 -53 1 -100 -4 -105 -4 -5 -29 -11 -54 -12 l-47 -3 20 22
            c28 30 43 61 43 89 0 18 -5 14 -21 -20 -25 -49 -62 -82 -94 -82 l-22 0 19 39
            c15 30 17 44 9 58 -10 17 -10 17 -11 -2 0 -44 -56 -61 -92 -28 -22 20 -23 54
            -1 100 32 69 99 88 199 56z m156 -91 c30 -60 32 -74 36 -188 3 -68 0 -124 -4
            -124 -5 0 -28 40 -51 89 -49 102 -70 202 -59 274 7 48 8 48 27 31 11 -10 34
            -47 51 -82z m1144 10 c7 -58 -9 -144 -38 -197 -11 -22 -27 -61 -35 -88 -24
            -83 -38 -55 -41 87 -2 119 -1 132 23 184 15 31 36 67 48 80 21 23 21 23 29 3
            4 -12 10 -43 14 -69z m-546 18 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10
            11 10 2 0 4 -4 4 -10z m30 -82 c-16 -29 -30 -64 -32 -78 -3 -21 1 -25 22 -22
            14 1 42 16 62 33 l38 30 0 -117 0 -116 -35 31 c-19 17 -48 37 -64 46 -21 10
            -28 20 -24 34 12 48 -40 84 -82 57 -51 -33 -13 -119 44 -101 16 5 24 -4 46
            -47 80 -159 237 -212 333 -111 30 32 37 35 72 29 57 -9 90 -24 90 -40 0 -25
            79 -84 126 -94 48 -10 107 -2 153 22 41 22 112 87 105 97 -8 14 -70 11 -109
            -5 -19 -8 -35 -12 -35 -8 0 4 19 51 41 105 l42 98 17 -39 c18 -40 66 -86 81
            -76 10 6 2 82 -17 148 -8 27 -12 52 -9 54 2 3 42 -12 88 -33 l84 -37 -26 -69
            c-18 -50 -31 -69 -43 -69 -10 0 -18 -4 -18 -10 0 -5 11 -10 25 -10 15 0 31 -9
            40 -23 34 -52 -26 -120 -77 -88 -23 14 -36 7 -20 -11 14 -18 -60 -214 -79
            -210 -584 148 -633 156 -914 156 -271 0 -433 -30 -794 -144 -72 -23 -134 -39
            -139 -36 -5 3 -24 44 -42 90 -26 68 -30 89 -21 100 7 8 8 17 3 20 -6 3 -15 -1
            -22 -9 -30 -36 -90 1 -90 55 0 31 39 68 59 56 5 -4 12 -2 16 4 3 6 -4 10 -17
            10 -21 0 -29 12 -57 81 -18 44 -31 82 -30 84 10 8 152 65 156 62 2 -3 -1 -15
            -6 -29 -15 -40 -22 -132 -12 -148 8 -12 15 -10 40 14 16 16 35 41 41 57 7 16
            15 29 19 29 5 0 44 -88 90 -203 2 -5 -7 -6 -19 -2 -33 11 -101 8 -113 -4 -8
            -8 -2 -20 19 -42 106 -113 315 -81 349 53 6 22 16 29 56 38 48 12 49 12 75
            -19 37 -44 74 -61 134 -61 102 0 199 80 231 193 5 17 6 34 3 38 -6 10 -78 -24
            -106 -49 -17 -14 -18 -9 -18 99 0 104 1 112 15 92 20 -28 82 -66 95 -58 14 9
            2 74 -19 105 -10 14 -21 31 -25 38 -6 9 19 12 112 12 l120 0 -28 -52z m208 8
            c33 -11 33 -12 14 -30 -20 -17 -21 -17 -41 3 -21 21 -28 42 -13 40 4 0 22 -6
            40 -13z m924 -13 c10 -9 18 -27 18 -40 0 -28 -33 -63 -60 -63 -26 0 -60 34
            -60 60 0 26 34 60 62 60 12 0 30 -7 40 -17z m-2352 -23 c37 -37 14 -100 -37
            -100 -26 0 -63 32 -63 55 0 20 45 65 65 65 8 0 24 -9 35 -20z m930 10 c0 -20
            -49 -60 -72 -60 -13 0 -29 9 -36 20 -11 18 -10 20 15 20 16 1 42 7 58 15 17 7
            31 14 33 14 1 1 2 -4 2 -9z m639 -26 c31 -25 31 -27 31 -120 0 -56 -4 -94 -10
            -94 -5 0 -10 12 -10 28 0 15 -11 41 -24 57 -20 25 -28 28 -43 19 -9 -6 -20
            -24 -24 -40 -7 -36 -27 -74 -38 -74 -5 0 -7 9 -4 20 3 11 -2 40 -11 64 -44
            115 48 212 133 140z m-795 -5 c38 -18 51 -59 38 -124 -7 -33 -8 -65 -2 -84 5
            -17 5 -31 0 -31 -25 0 -41 16 -50 51 -14 51 -54 74 -76 44 -8 -10 -14 -29 -14
            -42 0 -13 -4 -23 -10 -23 -16 0 -12 127 5 160 16 30 51 60 72 60 7 0 23 -5 37
            -11z m-264 -39 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11 10 2 0 4 -4
            4 -10z m1352 -17 c13 -12 18 -30 18 -68 0 -44 -5 -59 -40 -105 -31 -41 -41
            -49 -46 -36 -3 9 -4 26 -1 37 7 25 -26 61 -47 53 -8 -3 -22 -17 -31 -30 -8
            -13 -22 -24 -31 -24 -36 0 18 133 69 168 38 26 83 29 109 5z m-1812 -8 c0 -8
            -2 -15 -4 -15 -2 0 -12 -3 -21 -6 -15 -6 -15 -4 -4 15 16 25 29 27 29 6z m295
            -5 c-3 -5 -16 -10 -28 -9 -21 0 -21 1 -2 9 28 12 37 12 30 0z m153 -5 c32 -13
            44 -26 67 -76 25 -51 27 -62 15 -69 -21 -13 -30 -12 -30 3 0 8 -9 22 -21 33
            -15 14 -24 16 -37 7 -13 -8 -16 -22 -14 -66 4 -56 -11 -85 -23 -45 -4 13 -24
            41 -45 63 -35 36 -60 80 -60 106 0 5 11 21 25 34 28 29 67 32 123 10z m660 3
            c17 -17 15 -43 -4 -59 -31 -26 -70 25 -44 56 15 18 32 19 48 3z m-445 -173
            c-1 -5 -24 -13 -50 -16 -26 -4 -60 -12 -76 -20 -29 -13 -56 -1 -43 20 16 26
            174 41 169 16z m936 4 c51 -11 81 -32 62 -43 -6 -4 -33 1 -61 10 -27 9 -63 14
            -80 12 -27 -4 -47 12 -33 26 9 9 57 7 112 -5z m-704 -290 c0 -7 -8 -15 -17
            -17 -18 -3 -25 18 -11 32 10 10 28 1 28 -15z m-115 -19 c0 -21 -24 -28 -35
            -10 -8 14 3 30 21 30 8 0 14 -9 14 -20z m653 14 c9 -9 -4 -34 -18 -34 -14 0
            -27 25 -18 34 4 3 12 6 18 6 6 0 14 -3 18 -6z m-66 -68 c-4 -9 -11 -16 -17
            -16 -17 0 -24 31 -9 41 18 11 34 -5 26 -25z m224 18 c24 -12 30 -21 27 -42 -2
            -22 -9 -28 -36 -30 -43 -4 -82 21 -82 52 0 38 41 47 91 20z m-940 -4 c44 -24
            5 -80 -57 -80 -41 0 -60 21 -44 50 18 34 66 48 101 30z m197 -7 c-2 -10 -10
            -18 -18 -18 -8 0 -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z m890 -6
            c2 -12 -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m-1253 -8 c0 -7
            -8 -15 -17 -17 -18 -3 -25 18 -11 32 10 10 28 1 28 -15z m816 -32 c-5 -5 -17
            -7 -26 -3 -14 5 -15 9 -5 21 10 12 16 13 27 4 9 -8 10 -16 4 -22z m-391 9 c0
            -8 -5 -18 -11 -22 -14 -8 -33 11 -25 25 10 16 36 13 36 -3z m940 -6 c0 -11 -6
            -20 -14 -20 -18 0 -29 16 -21 30 11 18 35 11 35 -10z m-1474 -1 c9 -15 -11
            -33 -30 -26 -9 4 -13 13 -10 22 7 17 30 20 40 4z m419 -9 c0 -8 -8 -16 -17
            -18 -13 -2 -18 3 -18 18 0 15 5 20 18 18 9 -2 17 -10 17 -18z m623 -3 c2 -12
            -3 -17 -17 -17 -12 0 -21 6 -21 13 0 31 32 34 38 4z m359 -31 c-4 -9 -11 -16
            -17 -16 -17 0 -24 31 -9 41 18 11 34 -5 26 -25z m-1330 8 c8 -20 -8 -36 -26
            -25 -15 10 -8 41 9 41 6 0 13 -7 17 -16z m1555 -47 c11 -35 10 -41 -7 -53 -27
            -20 -63 -16 -113 11 -1 1 4 17 11 36 8 19 17 42 20 51 5 14 11 15 42 6 29 -9
            37 -18 47 -51z m-1724 -9 l20 -53 -29 -13 c-24 -10 -35 -10 -63 2 -34 14 -34
            15 -24 54 9 37 33 59 64 61 6 1 20 -23 32 -51z m1430 29 c2 -10 -3 -17 -12
            -17 -18 0 -29 16 -21 31 9 14 29 6 33 -14z m-1190 -10 c2 -12 -3 -17 -17 -17
            -12 0 -21 6 -21 13 0 31 32 34 38 4z m1302 -21 c0 -8 -5 -18 -11 -22 -14 -8
            -33 11 -25 25 10 16 36 13 36 -3z m-1412 -13 c-2 -10 -10 -18 -18 -18 -8 0
            -16 8 -18 18 -2 12 3 17 18 17 15 0 20 -5 18 -17z"/>
            <path d="M3292 6133 c-50 -111 -136 -173 -238 -173 -68 0 -114 20 -165 74 -25
            26 -39 34 -39 24 0 -8 20 -34 44 -56 139 -128 369 -45 430 156 13 41 -10 23
            -32 -25z"/>
            <path d="M3350 6165 c0 -20 36 -91 66 -131 31 -40 109 -82 166 -90 86 -12 218
            49 218 101 0 12 -10 6 -34 -19 -113 -121 -297 -74 -380 97 -30 60 -36 67 -36
            42z"/>
            <path d="M2801 6018 c-46 -156 -226 -196 -374 -83 -51 39 -63 34 -19 -8 76
            -73 214 -94 307 -46 53 27 98 86 110 147 10 47 -10 39 -24 -10z"/>
            <path d="M3830 6032 c0 -4 13 -28 29 -53 37 -60 100 -90 198 -97 64 -4 79 -1
            128 22 30 15 64 40 75 57 26 35 6 40 -21 5 -11 -13 -40 -34 -65 -46 -116 -57
            -279 -4 -317 103 -6 17 -27 24 -27 9z"/>
            <path d="M3210 5780 l0 -80 130 0 130 0 0 80 0 80 -130 0 -130 0 0 -80z m235
            60 c-3 -6 -48 -10 -101 -10 l-94 0 0 -44 c0 -26 -6 -48 -15 -56 -13 -11 -15
            -4 -15 54 l0 66 116 0 c74 0 113 -4 109 -10z m-75 -71 l45 -14 -63 -3 c-38 -2
            -69 2 -78 9 -34 28 17 32 96 8z"/>
            <path d="M3516 5845 c-10 -26 -7 -34 13 -45 30 -15 53 -2 49 29 -2 20 -9 27
            -29 29 -17 2 -29 -3 -33 -13z m49 -16 c0 -7 -8 -15 -17 -17 -18 -3 -25 18 -11
            32 10 10 28 1 28 -15z"/>
            <path d="M3332 5649 c-14 -16 -18 -16 -45 -3 -16 9 -31 13 -33 11 -3 -3 12
            -13 33 -24 37 -19 39 -19 56 -1 19 20 42 25 31 6 -5 -8 -2 -9 12 -4 10 4 34
            10 52 13 53 9 36 21 -29 20 -47 0 -66 -5 -77 -18z"/>
            <path d="M3479 5643 c-13 -15 -11 -16 20 -10 18 4 42 7 52 7 11 0 19 5 19 10
            0 17 -76 12 -91 -7z"/>
            <path d="M3174 5643 c10 -10 86 -32 86 -26 0 3 -8 12 -17 19 -18 14 -82 20
            -69 7z"/>
            <path d="M917 5593 c-4 -3 -7 -201 -7 -438 l0 -432 563 -334 562 -334 3 -108
            c1 -59 1 -107 -2 -107 -2 0 -153 86 -335 190 l-331 191 -210 -192 -210 -191
            68 -87 c37 -48 139 -180 227 -294 l160 -206 318 -1 317 0 0 -68 0 -69 -217 -6
            c-120 -4 -263 -7 -319 -7 l-100 0 -222 -286 c-122 -157 -223 -289 -225 -294
            -4 -16 406 -381 423 -377 9 2 159 88 334 191 175 102 320 186 322 186 2 0 4
            -46 4 -103 l0 -102 -560 -335 -560 -335 3 -445 2 -445 458 -3 457 -2 33 52
            c18 29 163 272 323 540 l289 488 123 0 c67 0 122 -2 122 -5 0 -2 -70 -153
            -155 -336 l-155 -331 166 -209 c91 -115 169 -211 174 -214 4 -3 121 90 259
            206 l251 211 0 339 0 339 65 0 65 0 0 -338 0 -338 252 -212 c139 -117 254
            -212 258 -212 3 1 32 36 65 78 32 42 108 138 167 214 l108 136 -151 324 c-83
            177 -153 329 -156 336 -4 9 23 12 118 12 l124 0 292 -493 c161 -271 306 -514
            323 -540 l30 -47 457 2 458 3 0 445 0 445 -562 338 -563 338 0 105 0 105 23
            -12 c12 -7 163 -95 336 -196 172 -101 319 -183 326 -181 13 3 415 365 415 374
            0 3 -101 135 -225 295 l-226 289 -92 0 c-51 0 -197 3 -324 7 l-233 6 0 69 0
            68 324 0 323 0 223 286 c122 158 223 292 224 298 1 10 -106 111 -390 367 -20
            19 -24 17 -359 -176 -186 -107 -340 -195 -342 -195 -2 0 -3 49 -3 108 l0 108
            565 337 565 338 -2 437 -3 437 -461 3 -460 2 -319 -530 -318 -530 -94 0 c-51
            0 -93 4 -93 8 0 4 56 113 125 242 l124 235 -32 35 c-17 19 -72 76 -121 127
            l-89 91 82 7 c44 4 81 12 81 16 0 5 -10 10 -22 10 -79 4 -102 13 -78 30 11 7
            6 8 -17 4 -42 -8 -82 3 -62 17 10 7 6 8 -14 5 -15 -2 -49 0 -75 6 l-47 10 35
            14 c33 12 33 13 5 8 -99 -17 -108 -18 -101 -6 6 9 -1 11 -27 6 -44 -9 -86 2
            -66 17 9 6 -6 7 -44 3 -47 -5 -61 -3 -70 9 -11 14 -19 14 -69 2 -47 -11 -55
            -11 -51 1 4 11 0 11 -22 3 -32 -12 -85 -13 -85 -2 0 9 -105 -4 -124 -15 -6 -4
            -2 4 9 17 l20 23 -21 -11 c-12 -6 -40 -16 -63 -21 -35 -8 -40 -7 -30 5 14 17
            10 17 -32 0 -19 -7 -47 -16 -63 -20 -26 -6 -28 -5 -17 8 12 14 10 15 -20 2
            -19 -7 -50 -17 -69 -20 l-35 -7 25 16 25 16 -35 -6 c-19 -3 -46 -7 -60 -9 -46
            -5 -82 -18 -70 -26 8 -5 7 -9 -4 -13 -10 -4 -16 -1 -16 8 0 19 -2 19 -44 -6
            -20 -12 -36 -16 -36 -11 0 6 -17 3 -40 -6 -22 -9 -40 -22 -40 -29 0 -8 -18
            -11 -55 -10 -84 4 -71 -19 20 -34 41 -6 75 -15 75 -18 0 -3 -52 -58 -115 -121
            -63 -63 -115 -119 -115 -124 0 -5 55 -113 123 -240 67 -127 124 -234 125 -238
            2 -5 -40 -7 -95 -6 l-98 3 -316 525 -316 525 -465 3 c-255 1 -468 -1 -471 -5z
            m1395 -800 c259 -431 469 -785 467 -788 -3 -2 -15 -2 -27 2 -15 4 -96 123
            -280 417 -142 226 -355 563 -473 749 l-214 337 -377 0 -378 0 0 -369 0 -370
            780 -487 c568 -355 780 -493 780 -506 0 -10 -3 -18 -6 -18 -3 0 -370 216 -814
            480 -445 264 -816 483 -824 486 -14 5 -16 54 -16 430 l0 424 456 -2 455 -3
            471 -782z m3408 362 l-5 -425 -809 -486 c-537 -322 -813 -481 -819 -475 -7 7
            -6 15 1 23 6 8 360 232 786 498 l776 485 0 367 0 368 -384 0 -384 0 -468 -749
            c-387 -618 -473 -750 -494 -754 -14 -3 -26 -4 -28 -2 -1 1 210 356 470 789
            l473 786 445 0 445 0 -5 -425z m-2153 269 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6
            11 1 17 -2 13 -5z m-169 -22 l32 -4 0 -74 0 -74 -75 0 -75 0 0 79 c0 79 0 80
            28 84 15 2 34 2 42 -1 8 -3 30 -7 48 -10z m-538 4 c0 -2 -8 -10 -17 -17 -16
            -13 -17 -12 -4 4 13 16 21 21 21 13z m98 -245 l207 -139 3 -84 c1 -46 9 -271
            18 -502 10 -283 12 -422 5 -429 -16 -16 -18 8 -45 458 -15 231 -29 443 -32
            471 l-5 50 -180 123 c-100 67 -184 121 -188 119 -12 -5 -201 -225 -201 -234 0
            -5 111 -228 246 -497 135 -269 244 -491 241 -493 -16 -17 -57 49 -233 379
            -108 205 -230 432 -271 506 l-73 133 138 144 c79 84 142 142 150 139 7 -2 106
            -67 220 -144z m1127 9 l139 -139 -16 -33 c-46 -96 -521 -975 -533 -987 -47
            -48 -4 52 217 494 l247 493 -100 116 c-55 64 -103 116 -107 116 -4 0 -89 -55
            -189 -122 -170 -115 -182 -125 -187 -158 -3 -19 -17 -235 -32 -480 -25 -402
            -32 -475 -49 -459 -10 11 33 1001 44 1012 14 14 414 286 421 287 3 0 68 -63
            145 -140z m-750 65 c160 0 333 3 385 8 l95 7 -160 -106 c-88 -59 -161 -108
            -163 -109 -2 -2 -21 -415 -22 -473 0 -22 0 -23 -132 -20 l-133 3 -10 244 -10
            244 -153 102 c-84 56 -155 105 -159 109 -4 3 33 3 83 -1 49 -5 220 -8 379 -8z
            m-635 -734 c5 -11 10 -22 10 -25 0 -3 -40 -6 -88 -6 -85 0 -89 1 -100 25 l-12
            25 90 0 c78 0 91 -2 100 -19z m766 3 c-3 -9 -6 -20 -6 -25 0 -5 -56 -9 -125
            -9 l-125 0 0 25 0 25 131 0 c115 0 130 -2 125 -16z m692 -6 c-14 -21 -25 -23
            -107 -26 l-91 -3 12 26 c11 24 14 25 106 25 l94 0 -14 -22z m-1113 -188 c3 -5
            1 -10 -5 -10 -6 0 -9 -3 -7 -7 3 -5 -1 -14 -9 -21 -12 -11 -15 -10 -20 3 -11
            27 27 58 41 35z m615 0 c13 -8 12 -10 -3 -10 -10 0 -15 -4 -12 -10 3 -5 1 -10
            -4 -10 -6 0 -11 -7 -11 -16 0 -8 -4 -12 -10 -9 -14 9 -12 52 3 58 18 8 21 8
            37 -3z m738 -22 c7 -7 12 -16 12 -22 0 -5 -6 -1 -14 9 -13 18 -14 17 -21 -14
            -5 -26 -9 -30 -21 -20 -8 6 -14 17 -14 23 0 15 20 36 35 36 6 0 16 -5 23 -12z
            m-2083 -8 c4 -6 1 -9 -7 -8 -7 2 -12 -3 -10 -10 1 -8 -2 -11 -7 -8 -5 4 -11
            -2 -14 -11 -4 -17 -4 -17 -12 0 -13 30 33 65 50 37z m1308 -105 c-3 -8 -9 -15
            -14 -15 -5 0 -9 -7 -9 -16 0 -8 -4 -12 -10 -9 -16 10 -12 43 7 54 22 12 34 5
            26 -14z m-1636 -332 c458 -264 603 -352 603 -366 0 -13 -5 -17 -14 -14 -8 3
            -273 148 -588 321 -316 173 -579 316 -585 318 -5 2 -85 -59 -176 -135 l-166
            -138 200 -248 200 -248 352 -7 c194 -3 448 -9 565 -13 197 -6 212 -7 212 -24
            0 -18 -22 -19 -584 -19 l-584 0 -208 268 c-114 147 -213 274 -221 283 -13 15
            6 36 179 193 106 96 197 176 202 176 5 0 281 -156 613 -347z m1081 330 c-9
            -10 -18 -25 -21 -33 -4 -13 -6 -12 -12 3 -11 25 1 47 26 47 20 0 21 -2 7 -17z
            m2431 -162 c128 -116 188 -177 183 -186 -4 -7 -101 -135 -217 -283 l-210 -271
            -581 -1 c-503 0 -583 2 -587 15 -4 8 -3 16 2 19 4 3 254 10 557 16 302 6 554
            13 560 15 9 3 338 403 391 475 10 14 -10 35 -144 146 -86 71 -161 134 -167
            140 -8 7 -183 -84 -600 -313 -407 -224 -592 -321 -600 -313 -7 7 -6 14 2 22
            20 20 1197 697 1208 695 6 -1 98 -80 203 -176z m-3054 149 c4 -6 1 -9 -7 -8
            -7 2 -12 -3 -10 -10 1 -8 -2 -11 -7 -8 -5 4 -11 -2 -14 -11 -4 -17 -4 -17 -12
            0 -4 9 -5 24 -1 32 6 17 42 20 51 5z m1802 -20 c-10 -26 -13 -28 -26 -15 -12
            11 -12 17 -1 29 7 9 18 16 25 16 11 0 11 -6 2 -30z m-1289 -115 l22 -45 120 0
            120 0 0 45 0 45 114 0 115 0 3 -42 3 -43 120 0 120 0 23 43 23 42 80 0 c43 0
            79 -2 79 -5 0 -2 -9 -19 -20 -37 -11 -18 -20 -36 -20 -40 0 -5 45 -8 100 -8
            l100 0 0 -123 c0 -68 3 -126 6 -130 4 -3 19 0 35 8 15 8 30 15 34 15 3 0 5
            -44 5 -99 l0 -98 -40 -27 -41 -27 3 -122 3 -122 38 -3 37 -3 0 -70 0 -69 -40
            0 -40 0 0 -124 0 -125 40 -22 40 -23 0 -103 0 -104 -30 16 c-16 8 -34 15 -40
            15 -6 0 -10 -38 -10 -105 l0 -105 -116 0 -116 0 26 -44 c14 -25 26 -47 26 -50
            0 -3 -40 -6 -88 -6 l-89 0 -23 50 -23 50 -129 0 -128 0 0 -50 0 -50 -65 0 -65
            0 0 50 0 50 -129 0 -129 0 -21 -50 -22 -50 -89 0 c-50 0 -90 3 -90 6 0 3 12
            25 26 50 l26 44 -121 0 -121 0 0 105 c0 58 -3 105 -7 105 -5 0 -23 -9 -40 -20
            l-33 -20 0 103 0 102 45 27 45 28 0 125 0 125 -45 0 -45 0 0 70 0 70 45 0 45
            0 0 129 0 130 -42 23 -43 23 -3 103 c-1 56 1 102 5 102 4 0 22 -9 38 -20 17
            -11 33 -20 38 -20 4 0 7 59 7 130 l0 130 105 0 c58 0 105 3 105 6 0 3 -8 18
            -18 32 -37 54 -39 52 50 52 l84 0 22 -45z m-851 -17 l33 -23 0 -97 c0 -54 -3
            -98 -6 -98 -3 0 -21 7 -40 16 l-34 16 0 104 c0 57 3 104 7 104 4 0 22 -10 40
            -22z m2466 -205 c-15 -13 -32 -23 -36 -23 -5 0 -7 45 -5 101 3 98 4 102 33
            125 l30 24 3 -103 c3 -101 3 -102 -25 -124z m-2303 -318 c7 -9 10 -18 6 -22
            -3 -3 -6 -1 -6 5 0 23 -20 12 -26 -15 -6 -24 -8 -25 -20 -8 -26 36 18 74 46
            40z m2176 3 c4 -7 3 -8 -4 -4 -7 4 -12 2 -12 -4 0 -6 -7 -18 -15 -26 -13 -14
            -16 -14 -21 0 -12 31 35 62 52 34z m-2021 -38 c3 -5 2 -10 -3 -10 -5 0 -14
            -10 -19 -22 -10 -21 -12 -21 -18 -5 -12 28 25 62 40 37z m1865 -15 c0 -2 -6
            -2 -14 1 -9 3 -17 -3 -21 -15 -8 -26 -25 -28 -25 -3 0 28 20 43 42 31 10 -5
            18 -12 18 -14z m-2150 -255 l0 -70 -40 0 -40 0 0 70 0 70 40 0 40 0 0 -70z
            m2460 0 l0 -70 -35 0 -35 0 0 48 c0 75 8 92 41 92 l29 0 0 -70z m-2010 -103
            c0 -15 -103 -19 -808 -33 l-324 -7 -166 -206 c-91 -113 -181 -224 -199 -247
            l-32 -40 164 -142 c90 -78 169 -142 175 -142 9 0 592 322 1123 621 64 36 67
            37 67 16 0 -19 -96 -78 -606 -377 -333 -195 -608 -355 -610 -355 -2 0 -91 80
            -199 178 l-195 178 67 87 c38 48 135 174 217 280 l149 192 171 3 c94 1 359 4
            589 5 344 2 417 0 417 -11z m2477 4 l192 -6 217 -279 c210 -270 216 -279 198
            -296 -11 -10 -99 -90 -198 -179 -120 -109 -182 -159 -190 -153 -6 5 -274 164
            -596 352 -322 188 -591 349 -597 358 -16 19 -17 32 -2 32 8 0 1095 -602 1165
            -645 19 -13 33 -3 187 131 l166 144 -171 213 c-94 116 -185 228 -202 249 l-31
            36 -555 12 c-305 6 -558 14 -562 16 -33 20 27 24 390 23 218 -1 483 -4 589 -8z
            m-2650 -130 c0 -11 -4 -18 -8 -15 -4 2 -9 -4 -12 -13 -4 -17 -4 -17 -12 0 -4
            10 -2 23 5 32 17 21 29 19 27 -4z m1840 -10 c-11 -31 -27 -32 -27 -2 0 21 8
            31 26 31 9 0 10 -7 1 -29z m-1978 -44 c0 -9 -2 -9 -6 1 -8 20 -21 14 -30 -15
            -10 -28 -10 -28 -21 -6 -16 30 10 58 39 43 10 -6 19 -16 18 -23z m2148 -2
            c-21 -21 -37 -24 -37 -5 0 16 31 39 45 34 11 -5 9 -10 -8 -29z m-1849 -306
            c-2 -17 -185 -136 -780 -508 l-778 -486 3 -370 2 -370 385 -3 385 -2 489 780
            c280 446 495 780 503 780 11 0 7 -13 -14 -49 -15 -26 -233 -393 -483 -814
            l-455 -766 -447 -1 -448 0 0 428 1 427 812 487 c447 267 816 486 820 487 5 1
            7 -9 5 -20z m2328 -464 l809 -485 3 -430 2 -430 -442 0 -443 0 -483 813 c-434
            730 -481 812 -461 815 12 2 26 -2 30 -10 4 -7 221 -357 481 -778 l474 -765
            387 -3 387 -2 0 374 0 374 -779 486 c-510 319 -781 494 -784 507 -3 10 -2 19
            3 19 4 0 372 -218 816 -485z m-335 434 c29 -20 29 -21 29 -119 0 -90 -2 -99
            -17 -93 -47 16 -53 32 -53 135 0 54 3 98 6 98 3 0 19 -9 35 -21z m-2431 -82
            c0 -90 -1 -94 -27 -113 -55 -40 -53 -43 -53 69 l0 105 33 16 c17 8 35 16 40
            16 4 0 7 -42 7 -93z m920 -73 c0 -3 -113 -274 -251 -602 -139 -328 -254 -604
            -256 -613 -5 -17 212 -309 229 -309 5 0 98 73 207 162 l198 163 6 410 c4 226
            7 497 7 603 l0 192 25 0 25 0 0 -622 -1 -623 -238 -200 c-132 -110 -242 -199
            -246 -198 -9 3 -315 391 -315 400 0 7 349 758 516 1111 41 86 68 132 78 132 9
            0 16 -3 16 -6z m410 -262 c0 -148 3 -418 7 -601 l6 -332 205 -168 206 -168 21
            26 c12 14 64 83 117 152 l96 125 -258 615 c-210 498 -255 615 -242 618 9 2 18
            -1 19 -5 2 -5 133 -286 291 -624 l288 -615 -159 -200 c-87 -110 -161 -200
            -164 -199 -3 0 -113 90 -244 200 l-238 199 0 623 -1 622 25 0 25 0 0 -268z
            m-1043 90 c0 -10 -7 -24 -14 -31 -18 -17 -30 14 -13 34 17 20 29 19 27 -3z
            m1848 8 c3 -5 0 -7 -9 -4 -11 4 -17 -3 -22 -22 -7 -25 -8 -26 -20 -9 -10 14
            -11 22 -3 32 13 15 45 17 54 3z m-1155 -25 c11 -13 10 -14 -3 -9 -12 4 -18 -2
            -23 -20 -3 -14 -10 -23 -15 -20 -13 8 -11 52 4 57 20 9 24 8 37 -8z m470 2 c0
            -12 -39 -46 -44 -38 -10 16 5 41 25 41 10 0 19 -2 19 -3z m-1284 -95 c7 -5 3
            -15 -13 -30 -20 -19 -24 -21 -29 -7 -4 8 -1 22 6 30 14 17 20 18 36 7z m2080
            0 c7 -5 3 -15 -13 -30 -20 -19 -24 -21 -29 -7 -4 8 -1 22 6 30 14 17 20 18 36
            7z m-1306 -41 c-13 -26 -27 -34 -35 -21 -8 14 13 40 32 40 10 0 11 -4 3 -19z
            m545 9 c3 -5 0 -7 -8 -4 -10 3 -18 -4 -25 -23 -8 -24 -11 -25 -17 -10 -4 9 -5
            24 -1 32 6 17 42 20 51 5z m-857 -177 c-2 -5 -10 -20 -17 -35 l-14 -28 -114 0
            c-72 0 -113 4 -113 10 0 6 11 22 25 35 23 23 31 25 130 25 59 0 105 -3 103 -7z
            m625 -1 c3 -4 3 -19 0 -35 -5 -25 -8 -27 -59 -27 -53 0 -54 1 -54 28 0 16 3
            32 7 35 9 10 101 9 106 -1z m752 -22 c8 -16 15 -31 15 -35 0 -3 -51 -5 -113
            -5 l-114 0 -14 28 c-7 15 -15 30 -17 35 -2 4 48 7 112 7 l115 0 16 -30z"/>
            <path d="M3286 3845 c-32 -69 -65 -130 -72 -136 -17 -14 -44 -5 -90 32 -21 17
            -40 26 -42 20 -2 -6 3 -34 11 -63 16 -56 51 -223 61 -295 l6 -43 -35 0 c-40 0
            -82 37 -139 121 l-34 50 -11 -32 c-25 -73 -65 -85 -176 -50 -33 10 -61 16 -63
            11 -1 -4 13 -54 32 -111 37 -112 37 -116 -16 -149 l-26 -16 91 -74 c50 -41
            119 -96 153 -122 99 -76 111 -104 75 -184 l-19 -41 132 23 c72 13 150 28 173
            33 23 4 45 6 48 3 3 -3 2 -88 -2 -189 -4 -136 -3 -183 5 -183 9 0 12 52 12
            185 l0 185 23 0 c12 0 81 -9 152 -20 166 -25 179 -25 170 -2 -4 9 -12 39 -17
            65 -12 61 -12 60 165 217 75 67 137 125 137 129 0 3 -10 11 -22 16 -41 19 -43
            37 -15 126 26 86 39 139 35 139 -2 0 -32 -9 -66 -20 -35 -11 -79 -20 -98 -20
            -32 0 -36 4 -57 52 l-23 51 -67 -86 c-67 -86 -91 -107 -121 -107 -29 0 -26 44
            12 232 l39 187 -37 -29 c-82 -65 -106 -49 -176 111 -25 57 -47 105 -48 106 -1
            1 -28 -54 -60 -122z"/>
            <path d="M3370 5575 c-14 -8 -32 -12 -41 -9 -9 2 -34 -2 -55 -10 -22 -8 -48
            -17 -59 -19 -20 -5 38 -9 152 -11 60 -1 81 8 48 20 -25 10 -17 24 13 24 21 0
            24 2 12 10 -21 13 -40 12 -70 -5z"/>
            <path d="M3021 5550 c-13 -11 -35 -20 -50 -20 -14 0 -48 -12 -76 -26 l-50 -26
            70 7 c39 4 75 11 80 15 6 5 28 10 50 13 34 3 37 5 18 12 -26 8 -21 25 8 25 10
            0 19 5 19 10 0 17 -44 10 -69 -10z"/>
            <path d="M3620 5529 c-51 -6 -41 -12 44 -28 72 -13 147 -15 127 -2 -20 13 -3
            33 22 25 13 -4 18 -3 14 2 -10 10 -138 12 -207 3z"/>
            <path d="M2692 5485 c-16 -14 -37 -25 -45 -25 -8 0 -37 -15 -65 -34 -27 -19
            -53 -32 -58 -28 -4 4 -4 1 0 -6 6 -9 11 -10 20 -2 7 5 35 13 62 16 27 4 56 12
            64 19 8 7 30 16 49 20 28 6 31 9 18 17 -10 5 -23 6 -30 3 -6 -4 -2 2 11 14 33
            29 9 35 -26 6z"/>
            <path d="M3965 5449 c62 -31 155 -61 155 -50 0 4 -5 12 -12 19 -9 9 -8 12 4
            12 9 0 20 -4 23 -10 3 -5 11 -10 17 -10 6 0 3 9 -7 20 -11 13 -31 20 -52 20
            -19 0 -42 4 -51 9 -9 4 -44 11 -77 14 l-60 6 60 -30z"/>
            <path d="M2450 5452 c0 -13 25 -22 63 -22 18 0 27 4 22 9 -15 13 -85 23 -85
            13z"/>
            <path d="M4202 5452 c-21 -6 -33 -28 -14 -26 22 1 62 17 62 25 0 10 -14 10
            -48 1z"/>
            <path d="M4220 5410 c-8 -5 -25 -10 -38 -10 -14 0 -22 -5 -20 -12 6 -19 40
            -20 62 -4 22 18 19 40 -4 26z"/>
            <path d="M2455 5400 c3 -5 14 -10 23 -10 15 0 15 2 2 10 -20 13 -33 13 -25 0z"/>
            <path d="M2480 5350 c8 -5 30 -10 49 -10 45 0 37 6 -19 14 -31 4 -40 3 -30 -4z"/>
            <path d="M4195 5352 c-46 -5 -55 -22 -13 -22 20 0 40 5 43 11 9 14 6 15 -30
            11z"/>
            <path d="M7268 5050 c-113 -34 -176 -137 -185 -307 -14 -238 80 -392 241 -393
            95 0 173 59 201 154 12 39 12 45 -2 50 -35 14 -50 6 -62 -34 -6 -21 -26 -55
            -44 -74 -86 -91 -215 -31 -254 117 -18 71 -17 221 3 288 36 123 133 181 223
            134 28 -14 81 -102 81 -133 0 -9 6 -12 16 -8 9 3 22 6 30 6 18 0 18 28 -1 73
            -43 104 -147 157 -247 127z"/>
            <path d="M12865 5036 c-43 -18 -93 -79 -101 -121 -9 -52 1 -109 25 -144 12
            -17 49 -43 84 -59 34 -17 88 -42 120 -57 38 -17 66 -39 78 -59 39 -64 14 -147
            -54 -179 -46 -22 -72 -21 -124 4 -46 22 -78 59 -89 103 -6 25 -10 27 -37 22
            -40 -8 -41 -9 -27 -51 16 -49 66 -104 115 -126 79 -36 209 -17 257 37 48 54
            60 142 29 209 -25 55 -41 67 -163 124 -61 29 -114 61 -127 77 -62 78 21 199
            123 180 43 -8 90 -54 106 -101 10 -30 15 -35 33 -29 38 11 41 21 23 63 -21 46
            -70 96 -109 110 -39 15 -125 13 -162 -3z"/>
            <path d="M9280 4920 c0 -66 -4 -120 -8 -120 -5 0 -17 9 -27 20 -43 48 -131 49
            -185 3 -58 -49 -81 -115 -81 -236 0 -66 5 -90 26 -134 52 -105 167 -135 242
            -64 28 27 33 26 33 -4 0 -21 5 -25 30 -25 l30 0 0 340 0 340 -30 0 -30 0 0
            -120z m-61 -134 c52 -27 61 -54 61 -180 l0 -115 -38 -41 c-51 -53 -97 -57
            -144 -12 -36 33 -52 82 -52 163 0 151 77 234 173 185z"/>
            <path d="M9460 5005 c0 -33 2 -35 35 -35 33 0 35 2 35 35 0 33 -2 35 -35 35
            -33 0 -35 -2 -35 -35z"/>
            <path d="M14440 5005 c0 -32 2 -35 30 -35 28 0 30 3 30 35 0 32 -2 35 -30 35
            -28 0 -30 -3 -30 -35z"/>
            <path d="M11920 3090 c0 -1893 0 -1900 20 -1900 20 0 20 7 20 1900 0 1893 0
            1900 -20 1900 -20 0 -20 -7 -20 -1900z"/>
            <path d="M7750 4844 c-19 -8 -50 -29 -68 -47 l-33 -31 23 -17 c22 -17 24 -16
            60 17 32 28 46 34 83 34 59 0 98 -34 93 -82 -3 -29 -7 -32 -68 -50 -91 -25
            -151 -58 -178 -99 -20 -28 -23 -44 -20 -92 4 -49 9 -63 36 -90 29 -29 38 -32
            91 -32 51 0 64 4 99 32 l40 33 7 -30 c6 -26 11 -30 38 -30 l32 0 -3 172 c-3
            213 -5 230 -43 273 -46 53 -122 68 -189 39z m158 -291 l-3 -78 -49 -32 c-58
            -40 -98 -42 -131 -8 -36 35 -34 88 4 124 26 25 134 69 169 71 10 0 12 -19 10
            -77z"/>
            <path d="M8228 4840 c-20 -11 -40 -24 -43 -30 -11 -18 -25 -10 -25 15 0 21 -5
            25 -30 25 l-30 0 0 -245 0 -245 30 0 30 0 0 183 0 183 28 27 c40 38 95 59 126
            47 37 -14 46 -63 46 -262 l0 -178 30 0 30 0 0 195 c0 226 -9 267 -66 291 -45
            19 -82 17 -126 -6z"/>
            <path d="M8640 4844 c-19 -8 -50 -29 -68 -47 l-33 -31 23 -17 c22 -17 24 -16
            60 17 32 28 46 34 83 34 59 0 98 -34 93 -82 -3 -29 -7 -32 -68 -50 -142 -39
            -200 -91 -200 -177 0 -127 129 -183 231 -102 l39 32 6 -30 c6 -27 11 -31 38
            -31 l31 0 -3 187 c-3 164 -6 193 -23 230 -35 72 -129 102 -209 67z m160 -287
            c0 -81 -10 -97 -77 -131 -45 -23 -80 -20 -108 9 -36 35 -34 85 4 123 25 25
            132 70 169 72 8 0 12 -21 12 -73z"/>
            <path d="M9750 4844 c-19 -8 -50 -29 -68 -47 l-33 -31 23 -17 c22 -17 24 -16
            60 17 32 28 46 34 83 34 59 0 98 -34 93 -82 -3 -29 -7 -32 -68 -50 -142 -39
            -200 -91 -200 -177 0 -127 129 -183 231 -102 l39 32 6 -30 c6 -27 11 -31 38
            -31 l31 0 -3 187 c-3 164 -6 193 -23 230 -35 72 -129 102 -209 67z m160 -287
            c0 -81 -10 -97 -77 -131 -45 -23 -80 -20 -108 9 -36 35 -34 85 4 123 25 25
            132 70 169 72 8 0 12 -21 12 -73z"/>
            <path d="M10228 4840 c-20 -11 -40 -24 -43 -30 -11 -18 -25 -10 -25 15 0 21
            -5 25 -30 25 l-30 0 0 -245 0 -245 30 0 30 0 0 183 0 183 28 27 c15 14 44 33
            65 42 32 13 42 14 65 3 14 -7 29 -24 33 -38 4 -14 7 -109 8 -212 l1 -188 30 0
            30 0 0 195 c0 226 -9 267 -66 291 -45 19 -82 17 -126 -6z"/>
            <path d="M9467 4843 c-4 -3 -7 -114 -7 -245 l0 -238 35 0 35 0 0 245 0 245
            -28 0 c-16 0 -32 -3 -35 -7z"/>
            <path d="M13340 4828 c-21 -13 -47 -46 -63 -78 -27 -52 -28 -61 -25 -160 4
            -120 24 -172 80 -211 47 -34 140 -38 195 -10 35 17 93 77 82 84 -2 2 -12 9
            -22 16 -16 13 -22 10 -56 -23 -92 -90 -196 -40 -214 102 l-5 42 150 0 151 0
            -7 63 c-9 74 -33 133 -70 167 -22 20 -40 25 -94 28 -57 3 -72 0 -102 -20z
            m138 -44 c26 -18 47 -55 57 -101 l7 -33 -111 0 -111 0 0 25 c0 57 59 125 109
            125 15 0 37 -7 49 -16z"/>
            <path d="M13852 4830 c-18 -11 -43 -37 -55 -58 l-22 -37 -3 53 c-3 50 -4 52
            -33 52 l-29 0 0 -240 0 -240 30 0 30 0 0 136 c0 134 1 137 31 195 33 61 78 99
            118 99 17 0 21 6 21 30 0 26 -4 30 -27 30 -16 0 -43 -9 -61 -20z"/>
            <path d="M14699 4827 c-45 -30 -75 -77 -88 -138 -30 -137 5 -260 88 -316 49
            -33 133 -32 183 2 48 32 87 93 67 106 -25 16 -44 9 -62 -21 -46 -78 -142 -78
            -190 -1 -19 31 -22 50 -22 141 0 118 14 159 65 185 49 25 84 19 119 -20 17
            -20 31 -40 31 -45 0 -5 13 -5 30 1 l29 11 -20 39 c-27 55 -71 79 -140 79 -42
            0 -65 -6 -90 -23z"/>
            <path d="M15144 4832 c-68 -43 -94 -109 -94 -236 0 -160 55 -237 175 -244 73
            -5 130 19 165 68 12 17 21 32 19 33 -2 2 -12 9 -22 16 -16 13 -22 10 -56 -23
            -49 -48 -100 -57 -149 -26 -36 22 -56 61 -66 128 l-6 42 151 0 152 0 -7 63
            c-9 74 -33 133 -70 167 -37 34 -146 41 -192 12z m134 -48 c26 -18 47 -55 57
            -101 l7 -33 -111 0 -111 0 0 25 c0 57 59 125 109 125 15 0 37 -7 49 -16z"/>
            <path d="M14006 4818 c4 -13 35 -120 69 -238 l62 -215 50 -3 50 -3 63 223 c34
            123 65 231 68 241 3 13 -2 17 -26 17 -26 0 -32 -5 -40 -32 -50 -180 -106 -364
            -111 -371 -4 -4 -34 85 -67 198 l-59 205 -33 0 c-30 0 -33 -2 -26 -22z"/>
            <path d="M14440 4600 l0 -240 30 0 30 0 0 240 0 240 -30 0 -30 0 0 -240z"/>
            <path d="M7233 3989 c-78 -23 -132 -117 -118 -208 10 -64 57 -107 173 -155
            131 -54 160 -87 152 -175 -7 -81 -118 -129 -200 -86 -32 16 -74 67 -85 103 -7
            23 -38 29 -64 13 -13 -8 -10 -19 15 -67 22 -44 41 -66 78 -89 44 -27 58 -30
            125 -30 88 0 126 16 168 71 21 28 28 50 31 98 7 113 -36 166 -181 223 -90 35
            -147 87 -147 132 0 72 52 121 130 121 55 0 93 -28 116 -86 18 -42 21 -45 48
            -40 39 8 39 7 22 48 -46 109 -151 159 -263 127z"/>
            <path d="M9290 3949 c0 -39 1 -40 33 -37 29 3 32 6 35 41 3 36 3 37 -32 37
            -35 0 -36 -1 -36 -41z"/>
            <path d="M14780 3864 l0 -125 -35 26 c-98 75 -225 13 -254 -126 -33 -154 10
            -288 107 -331 56 -25 100 -18 147 24 l35 30 0 -26 c0 -22 4 -26 30 -26 l30 0
            0 340 0 340 -30 0 -30 0 0 -126z m-27 -164 c26 -29 27 -34 27 -146 0 -128 -11
            -159 -70 -189 -89 -46 -160 31 -160 173 0 110 38 193 95 204 34 7 79 -10 108
            -42z"/>
            <path d="M14960 3950 c0 -38 2 -40 30 -40 28 0 30 2 30 40 0 38 -2 40 -30 40
            -28 0 -30 -2 -30 -40z"/>
            <path d="M16557 3983 c-4 -3 -7 -57 -7 -120 0 -62 -2 -113 -5 -113 -2 0 -21
            11 -41 25 -62 42 -141 26 -195 -38 -51 -61 -70 -238 -35 -322 35 -82 116 -135
            183 -119 15 4 43 18 61 31 31 23 32 23 32 4 0 -17 6 -21 35 -21 l35 0 0 340 0
            340 -28 0 c-16 0 -32 -3 -35 -7z m-38 -272 l31 -29 0 -130 0 -130 -40 -36
            c-53 -47 -90 -48 -135 -3 -56 56 -69 186 -28 290 20 48 47 67 99 67 32 0 49
            -7 73 -29z"/>
            <path d="M9500 3865 l0 -75 -35 0 c-32 0 -35 -2 -35 -30 0 -27 3 -30 34 -30
            l34 0 4 -188 c2 -158 6 -192 20 -214 20 -31 69 -44 123 -34 28 6 35 11 35 31
            0 21 -4 24 -39 22 -69 -2 -71 3 -71 204 l0 179 55 0 c54 0 55 0 55 30 0 30 -1
            30 -55 30 l-55 0 0 75 0 75 -35 0 -35 0 0 -75z"/>
            <path d="M7724 3786 c-17 -8 -45 -31 -62 -52 -79 -95 -68 -320 20 -401 69 -65
            207 -53 267 22 24 31 23 43 -2 59 -13 8 -23 4 -49 -21 -44 -42 -78 -54 -120
            -42 -61 16 -98 74 -98 150 l0 39 146 0 147 0 -5 64 c-10 119 -76 196 -167 196
            -25 -1 -60 -7 -77 -14z m125 -59 c19 -16 51 -87 51 -114 0 -22 -3 -23 -110
            -23 -106 0 -110 1 -110 22 0 40 41 109 74 124 32 14 71 11 95 -9z"/>
            <path d="M8190 3789 c-36 -14 -69 -48 -93 -94 -25 -48 -35 -188 -18 -251 30
            -111 114 -169 216 -150 40 8 99 55 120 96 13 26 13 28 -13 39 -17 8 -28 9 -30
            2 -8 -25 -48 -65 -77 -77 -34 -14 -66 -10 -102 13 -59 39 -78 217 -32 309 43
            87 131 96 180 18 23 -38 27 -40 54 -20 18 13 18 14 -1 47 -41 69 -128 98 -204
            68z"/>
            <path d="M12829 3777 c-63 -34 -93 -96 -98 -205 -7 -171 46 -258 167 -278 56
            -9 124 25 160 78 25 39 26 41 7 54 -26 19 -32 18 -51 -15 -48 -81 -153 -81
            -195 1 -40 78 -33 235 12 292 11 14 38 31 59 37 33 10 43 9 66 -5 14 -10 33
            -31 42 -47 19 -32 30 -35 57 -15 19 13 18 15 -9 56 -47 71 -135 90 -217 47z"/>
            <path d="M13270 3781 c-19 -10 -46 -31 -60 -46 l-25 -27 23 -19 c24 -18 24
            -18 49 11 57 68 161 59 182 -16 6 -20 8 -40 6 -44 -3 -4 -42 -19 -87 -33 -46
            -15 -99 -37 -119 -51 -96 -66 -88 -199 15 -250 47 -23 89 -16 145 24 22 17 43
            30 46 30 3 0 5 -11 5 -25 0 -22 5 -25 33 -25 l32 0 -3 177 c-3 209 -10 242
            -58 280 -48 36 -132 42 -184 14z m180 -278 c0 -62 -2 -67 -35 -99 -62 -58
            -133 -64 -169 -12 -28 40 -14 94 33 125 32 20 153 62 164 56 4 -2 7 -34 7 -70z"/>
            <path d="M13755 3783 c-16 -8 -38 -22 -47 -31 -17 -14 -18 -13 -18 11 0 24 -4
            27 -35 27 l-35 0 0 -240 0 -240 35 0 35 0 0 184 0 185 37 30 c51 42 98 50 126
            22 21 -20 22 -32 25 -221 l3 -200 30 0 29 0 0 198 c0 168 -3 202 -18 232 -29
            58 -103 77 -167 43z"/>
            <path d="M14140 3781 c-19 -10 -46 -31 -60 -46 l-25 -27 23 -19 c24 -18 24
            -18 49 11 57 68 161 59 182 -16 6 -20 8 -40 6 -44 -3 -4 -42 -19 -87 -33 -46
            -15 -99 -37 -119 -51 -96 -66 -88 -199 15 -250 47 -23 89 -16 145 24 22 17 43
            30 46 30 3 0 5 -11 5 -25 0 -22 5 -25 33 -25 l32 0 -3 177 c-3 209 -10 242
            -58 280 -48 36 -132 42 -184 14z m180 -278 c0 -62 -2 -67 -35 -99 -62 -58
            -133 -64 -169 -12 -28 40 -14 94 33 125 32 20 153 62 164 56 4 -2 7 -34 7 -70z"/>
            <path d="M15235 3786 c-39 -17 -79 -64 -97 -113 -7 -22 -13 -79 -13 -133 0
            -80 4 -103 24 -143 54 -110 185 -137 288 -61 41 31 51 61 27 79 -17 12 -24 9
            -62 -27 -42 -38 -47 -40 -95 -35 -39 3 -56 11 -73 31 -25 28 -44 84 -44 129
            l0 27 145 0 145 0 0 49 c0 89 -39 164 -102 197 -34 17 -103 17 -143 0z m129
            -64 c23 -21 46 -73 46 -107 l0 -25 -110 0 c-120 0 -119 -1 -99 61 30 87 107
            121 163 71z"/>
            <path d="M15697 3770 l-47 -30 0 25 c0 22 -4 25 -35 25 l-35 0 0 -240 0 -240
            35 0 35 0 0 184 0 184 29 24 c65 55 128 60 149 13 8 -17 12 -92 12 -215 l0
            -190 30 0 30 0 0 200 c0 215 -5 241 -52 274 -36 26 -98 20 -151 -14z"/>
            <path d="M8530 3615 c0 -270 19 -320 124 -320 41 0 62 6 94 28 l42 27 0 -25
            c0 -22 4 -25 35 -25 l35 0 0 245 0 245 -35 0 -35 0 0 -189 0 -190 -33 -25
            c-42 -32 -85 -48 -109 -40 -39 12 -48 61 -48 259 l0 185 -35 0 -35 0 0 -175z"/>
            <path d="M8980 3545 l0 -245 30 0 30 0 0 140 c0 123 3 146 21 183 29 57 65 94
            106 108 25 8 33 16 33 35 0 21 -5 24 -36 24 -36 0 -68 -24 -106 -80 l-17 -25
            0 53 c-1 51 -2 52 -31 52 l-30 0 0 -245z"/>
            <path d="M9290 3545 l0 -245 35 0 35 0 0 245 0 245 -35 0 -35 0 0 -245z"/>
            <path d="M9710 3782 c0 -5 36 -112 81 -238 73 -209 79 -232 70 -265 -16 -52
            -54 -84 -102 -84 -23 0 -39 -4 -39 -11 0 -7 -3 -19 -6 -28 -10 -27 94 -22 136
            7 47 31 59 59 150 346 45 139 84 260 87 268 4 11 -2 14 -28 11 l-33 -3 -56
            -175 c-31 -96 -58 -181 -61 -189 -3 -8 -35 71 -71 175 -65 189 -65 189 -97
            192 -17 2 -31 -1 -31 -6z"/>
            <path d="M14960 3550 l0 -240 30 0 30 0 0 240 0 240 -30 0 -30 0 0 -240z"/>
            <path d="M16731 3598 c0 -123 4 -205 12 -227 27 -77 118 -100 195 -48 50 32
            52 33 52 7 0 -16 7 -20 30 -20 l30 0 0 240 0 240 -30 0 -30 0 0 -184 0 -184
            -35 -31 c-38 -33 -69 -45 -106 -39 -42 6 -49 38 -49 246 l0 192 -35 0 -35 0 1
            -192z"/>
            <path d="M7090 2590 l0 -340 35 0 35 0 0 340 0 340 -35 0 -35 0 0 -340z"/>
            <path d="M8390 2590 l0 -340 35 0 35 0 0 340 0 340 -35 0 -35 0 0 -340z"/>
            <path d="M8560 2590 l0 -340 35 0 35 0 0 340 0 340 -35 0 -35 0 0 -340z"/>
            <path d="M8730 2895 c0 -33 2 -35 35 -35 33 0 35 2 35 35 0 33 -2 35 -35 35
            -33 0 -35 -2 -35 -35z"/>
            <path d="M14760 2895 c0 -32 2 -35 30 -35 28 0 30 3 30 35 0 32 -2 35 -30 35
            -28 0 -30 -3 -30 -35z"/>
            <path d="M7720 2810 l0 -80 -40 0 c-38 0 -40 -2 -40 -30 0 -28 2 -30 40 -30
            l40 0 0 -185 c0 -119 4 -193 12 -210 14 -31 71 -50 122 -41 30 6 36 11 36 31
            0 23 -4 25 -45 25 -34 0 -47 5 -55 19 -6 12 -10 92 -10 190 l0 171 55 0 c54 0
            55 0 55 30 0 30 -1 30 -55 30 l-55 0 0 80 0 80 -30 0 -30 0 0 -80z"/>
            <path d="M17848 2813 l-3 -78 -37 -3 c-32 -3 -38 -7 -38 -27 0 -20 6 -24 38
            -27 l37 -3 5 -198 c5 -194 6 -199 29 -218 15 -12 39 -19 66 -19 59 0 75 8 75
            36 0 21 -3 23 -30 18 -19 -4 -38 -1 -55 10 l-25 16 0 180 0 180 55 0 c52 0 55
            1 55 25 0 24 -3 25 -55 25 l-55 0 0 80 0 80 -30 0 -29 0 -3 -77z"/>
            <path d="M9223 2730 c-38 -17 -46 -17 -70 -5 -42 22 -121 19 -167 -8 -77 -44
            -102 -157 -50 -232 l25 -38 -30 -31 c-44 -45 -39 -86 13 -114 18 -9 17 -11
            -12 -26 -84 -43 -66 -144 33 -181 66 -26 217 -18 267 13 62 38 74 125 25 169
            -38 35 -64 44 -174 58 -58 8 -110 18 -116 24 -7 7 -4 18 7 36 16 24 20 25 84
            19 55 -4 73 -2 102 14 72 39 103 123 76 202 -17 48 -11 60 32 60 18 0 22 6 22
            30 0 35 -9 37 -67 10z m-72 -69 c25 -25 29 -37 29 -83 -1 -106 -97 -156 -172
            -89 -30 27 -33 35 -33 86 0 51 3 59 33 86 45 41 102 41 143 0z m44 -415 c21
            -14 26 -24 23 -49 -2 -25 -11 -36 -38 -49 -43 -22 -147 -24 -194 -4 -56 23
            -60 82 -8 111 38 21 179 16 217 -9z"/>
            <path d="M13152 2737 c-44 -14 -98 -76 -113 -129 -7 -24 -12 -80 -12 -125 0
            -70 4 -90 29 -140 23 -46 38 -63 74 -81 84 -44 200 -16 244 59 15 25 14 27 -9
            39 -22 12 -26 10 -50 -18 -15 -18 -44 -37 -65 -43 -76 -23 -138 33 -155 138
            l-7 42 149 3 148 3 -3 55 c-3 71 -17 113 -50 152 -45 53 -107 69 -180 45z
            m115 -69 c26 -29 39 -55 46 -95 l7 -33 -114 0 -114 0 9 41 c10 45 33 85 58 99
            30 18 86 11 108 -12z"/>
            <path d="M13631 2740 c-13 -6 -36 -19 -51 -30 -35 -25 -40 -25 -40 0 0 16 -7
            20 -30 20 l-30 0 0 -240 0 -240 30 0 30 0 0 183 0 183 28 26 c38 36 65 48 106
            48 54 0 58 -16 60 -239 l1 -196 33 -3 32 -3 0 188 c0 203 -7 247 -44 282 -27
            26 -89 36 -125 21z"/>
            <path d="M14004 2739 c-18 -5 -46 -25 -63 -44 -25 -29 -31 -45 -31 -80 0 -64
            39 -105 133 -140 86 -31 117 -58 117 -97 0 -43 -16 -65 -55 -78 -47 -16 -90
            -4 -134 35 -36 32 -37 33 -59 16 l-23 -17 20 -26 c50 -64 166 -86 241 -48 49
            25 70 64 70 130 0 45 -4 56 -30 80 -17 16 -60 38 -96 50 -117 39 -158 102 -98
            149 45 35 136 20 163 -26 9 -17 12 -16 33 5 l23 23 -32 29 c-49 44 -113 58
            -179 39z"/>
            <path d="M14438 2741 c-79 -26 -125 -97 -135 -208 -10 -120 22 -217 89 -266
            62 -46 190 -28 243 35 28 33 28 46 3 61 -12 8 -24 2 -51 -25 -20 -20 -49 -39
            -65 -43 -34 -7 -89 13 -113 41 -21 24 -39 79 -39 115 l0 29 146 0 147 0 -5 69
            c-6 76 -35 141 -78 173 -29 21 -103 31 -142 19z m98 -66 c26 -18 54 -74 54
            -109 l0 -26 -110 0 c-98 0 -110 2 -110 18 1 30 37 99 61 116 29 20 78 21 105
            1z"/>
            <path d="M15043 2739 c-61 -18 -113 -93 -113 -165 0 -11 12 -44 26 -73 l26
            -52 -26 -28 c-39 -41 -32 -93 16 -115 10 -5 10 -7 1 -12 -92 -43 -92 -143 0
            -185 48 -21 186 -26 249 -9 82 23 115 117 62 179 -29 35 -91 56 -198 66 -85 8
            -96 12 -96 35 0 32 28 44 108 44 92 1 130 22 156 86 20 50 20 90 1 136 -15 38
            -13 40 35 46 19 2 26 10 28 31 3 22 -1 27 -18 27 -12 0 -37 -7 -55 -16 -30
            -14 -37 -14 -79 0 -52 18 -76 19 -123 5z m128 -65 c31 -28 44 -87 30 -134 -15
            -49 -48 -73 -103 -73 -34 0 -47 6 -70 32 -51 57 -32 163 36 192 29 12 85 3
            107 -17z m49 -423 c34 -25 36 -52 5 -81 -22 -21 -39 -25 -100 -28 -40 -2 -89
            1 -108 7 -61 21 -67 83 -10 116 38 21 177 13 213 -14z"/>
            <path d="M15558 2740 c-15 -4 -41 -18 -58 -30 l-30 -21 0 20 c0 17 -6 21 -35
            21 l-35 0 0 -240 0 -240 35 0 35 0 0 190 0 190 42 30 c33 25 51 30 82 28 61
            -5 66 -20 66 -245 l0 -193 31 0 30 0 -3 213 c-3 235 -8 255 -70 276 -35 12
            -52 13 -90 1z"/>
            <path d="M15948 2741 c-79 -26 -125 -97 -135 -208 -10 -120 22 -217 89 -266
            37 -28 117 -34 171 -13 52 20 102 68 94 91 -12 30 -35 27 -71 -8 -45 -45 -85
            -54 -134 -30 -43 21 -82 88 -82 140 l0 33 146 0 147 0 -5 69 c-6 76 -35 141
            -78 173 -29 21 -103 31 -142 19z m98 -66 c26 -18 54 -74 54 -109 l0 -26 -110
            0 c-98 0 -110 2 -110 18 1 30 37 99 61 116 29 20 78 21 105 1z"/>
            <path d="M16407 2736 c-21 -8 -47 -22 -58 -32 -18 -16 -19 -16 -19 4 0 18 -6
            22 -35 22 l-35 0 2 -237 3 -238 33 -3 32 -3 0 189 c0 183 1 189 23 209 35 32
            62 43 107 43 63 0 70 -26 70 -255 l0 -185 30 0 30 0 0 189 0 189 31 26 c36 30
            63 39 110 34 54 -5 59 -26 59 -245 l0 -193 30 0 30 0 0 195 c0 224 -9 263 -65
            289 -18 9 -44 16 -57 16 -26 0 -105 -34 -128 -55 -14 -12 -19 -11 -42 14 -24
            25 -67 42 -102 41 -6 -1 -28 -7 -49 -14z"/>
            <path d="M17078 2741 c-79 -26 -125 -97 -135 -208 -10 -120 22 -217 89 -266
            62 -46 190 -28 243 35 28 33 28 46 3 61 -12 8 -24 2 -51 -25 -46 -46 -85 -55
            -135 -31 -43 21 -82 88 -82 140 l0 33 146 0 147 0 -5 69 c-6 76 -35 141 -78
            173 -29 21 -103 31 -142 19z m98 -66 c26 -18 54 -74 54 -109 l0 -26 -110 0
            c-98 0 -110 2 -110 18 1 30 37 99 61 116 29 20 78 21 105 1z"/>
            <path d="M17534 2731 c-23 -10 -45 -24 -48 -30 -11 -18 -26 -13 -26 9 0 16 -7
            20 -35 20 l-35 0 0 -240 0 -240 35 0 35 0 0 183 c1 129 4 187 13 198 21 28 81
            59 114 59 58 0 63 -19 63 -242 l0 -198 36 0 36 0 -4 203 c-4 228 -12 257 -73
            283 -45 18 -61 17 -111 -5z"/>
            <path d="M7395 2723 c-16 -8 -38 -22 -47 -31 -17 -14 -18 -13 -18 11 0 24 -4
            27 -35 27 l-35 0 0 -240 0 -240 35 0 35 0 2 186 3 187 36 29 c49 39 100 47
            128 19 20 -20 21 -31 21 -221 l0 -200 36 0 36 0 -4 203 c-3 178 -5 206 -22
            234 -11 17 -30 36 -43 42 -34 16 -93 13 -128 -6z"/>
            <path d="M8060 2733 c-77 -29 -123 -117 -123 -237 -1 -109 16 -161 67 -212 73
            -74 182 -71 261 8 l40 40 -25 20 -24 20 -19 -27 c-38 -52 -110 -70 -166 -41
            -32 17 -60 75 -64 131 l-2 40 149 3 149 3 -7 62 c-14 128 -75 198 -170 196
            -28 0 -58 -3 -66 -6z m117 -65 c21 -17 40 -58 48 -101 l7 -37 -111 0 -111 0 0
            34 c0 39 35 95 70 114 27 14 75 9 97 -10z"/>
            <path d="M9490 2733 c-78 -29 -121 -114 -121 -236 -1 -114 14 -163 65 -213 73
            -74 182 -71 261 8 l40 40 -23 19 c-23 18 -23 18 -62 -21 -34 -34 -46 -40 -84
            -40 -73 0 -120 52 -127 140 l-4 45 149 3 149 3 -7 62 c-13 126 -75 198 -170
            196 -28 0 -58 -3 -66 -6z m117 -65 c21 -17 40 -58 48 -101 l7 -37 -112 0 -113
            0 6 42 c12 93 103 147 164 96z"/>
            <path d="M9955 2724 c-16 -8 -38 -23 -47 -31 -17 -15 -18 -14 -18 10 0 24 -4
            27 -35 27 l-35 0 0 -240 0 -240 35 0 35 0 0 184 0 184 34 30 c37 32 83 47 114
            38 36 -12 42 -50 42 -246 l0 -190 30 0 30 0 0 200 c0 215 -5 241 -52 274 -29
            20 -94 20 -133 0z"/>
            <path d="M10345 2726 c-39 -17 -79 -65 -96 -113 -8 -21 -13 -79 -13 -128 0
            -79 3 -98 27 -147 36 -74 80 -102 157 -103 64 0 111 25 148 80 27 39 27 42 1
            55 -18 10 -26 6 -63 -34 -38 -41 -47 -46 -85 -46 -79 0 -121 69 -121 199 0
            118 39 186 113 198 32 5 41 1 70 -28 19 -19 36 -40 38 -47 4 -11 10 -11 31 -1
            l27 12 -19 37 c-26 52 -75 80 -136 80 -27 -1 -63 -7 -79 -14z"/>
            <path d="M10770 2718 c-69 -47 -100 -117 -100 -229 0 -80 22 -158 56 -198 55
            -66 168 -78 247 -25 51 33 67 69 41 88 -17 13 -23 10 -61 -25 -36 -33 -49 -39
            -86 -39 -23 0 -53 7 -66 16 -30 22 -61 89 -61 137 l0 37 145 0 145 0 0 48 c0
            68 -25 133 -65 172 -31 30 -43 35 -98 38 -53 3 -67 0 -97 -20z m135 -50 c30
            -24 55 -74 55 -113 l0 -25 -110 0 c-106 0 -110 1 -110 22 0 36 40 107 68 123
            38 20 65 18 97 -7z"/>
            <path d="M12900 2733 c-23 -9 -51 -35 -71 -68 l-19 -30 0 48 0 47 -35 0 -35 0
            0 -241 0 -240 33 3 32 3 5 150 c5 142 7 153 33 197 28 48 69 78 107 78 16 0
            20 6 20 30 0 26 -3 30 -27 29 -16 0 -35 -3 -43 -6z"/>
            <path d="M8730 2490 l0 -240 35 0 35 0 0 240 0 240 -35 0 -35 0 0 -240z"/>
            <path d="M14760 2490 l0 -240 30 0 30 0 0 240 0 240 -30 0 -30 0 0 -240z"/>
            <path d="M7215 1863 c-46 -24 -66 -45 -86 -88 -24 -51 -24 -99 0 -151 21 -46
            55 -70 162 -114 40 -17 87 -42 105 -55 62 -47 63 -133 3 -191 -26 -25 -38 -29
            -87 -29 -72 0 -111 26 -144 94 -21 45 -25 49 -53 44 -40 -8 -39 -6 -25 -48 30
            -90 117 -148 221 -149 125 0 199 71 199 191 0 99 -42 144 -191 208 -100 42
            -129 68 -135 122 -10 76 66 143 145 128 42 -8 96 -61 106 -105 7 -33 11 -35
            50 -20 25 9 25 10 10 45 -37 90 -101 135 -190 135 -35 -1 -73 -7 -90 -17z"/>
            <path d="M13030 1754 l0 -126 -31 26 c-81 69 -184 40 -242 -69 -19 -34 -22
            -56 -22 -150 0 -102 2 -113 27 -157 59 -100 164 -119 246 -45 l22 20 0 -26 c0
            -23 4 -27 30 -27 l30 0 0 340 0 340 -30 0 -30 0 0 -126z m-76 -128 c64 -27 76
            -55 76 -182 l0 -114 -36 -39 c-42 -48 -80 -59 -124 -36 -51 26 -73 88 -68 194
            4 97 25 153 67 176 33 18 44 18 85 1z"/>
            <path d="M14497 1825 c-15 -31 -27 -58 -27 -60 0 -3 10 -5 23 -5 15 0 34 18
            64 60 l43 60 -38 0 c-36 0 -39 -3 -65 -55z"/>
            <path d="M15960 1845 c0 -32 2 -35 30 -35 28 0 30 3 30 35 0 32 -2 35 -30 35
            -28 0 -30 -3 -30 -35z"/>
            <path d="M16590 1876 c0 -2 -11 -29 -25 -60 l-25 -56 23 0 c17 0 34 14 58 47
            55 75 55 73 9 73 -22 0 -40 -2 -40 -4z"/>
            <path d="M8830 1835 c0 -33 2 -35 35 -35 33 0 35 2 35 35 0 33 -2 35 -35 35
            -33 0 -35 -2 -35 -35z"/>
            <path d="M16160 1760 l0 -80 -35 0 c-31 0 -35 -3 -35 -25 0 -22 4 -25 34 -25
            l34 0 4 -194 c3 -191 3 -195 28 -221 22 -23 31 -26 85 -23 54 3 60 5 63 26 3
            20 -1 22 -40 22 -24 0 -49 5 -56 12 -8 8 -12 66 -12 195 l0 183 55 0 c52 0 55
            1 55 25 0 24 -3 25 -55 25 l-55 0 0 80 0 80 -35 0 -35 0 0 -80z"/>
            <path d="M13289 1667 c-71 -48 -107 -146 -96 -264 14 -141 79 -214 192 -214
            58 0 105 19 141 58 29 32 30 49 3 63 -17 10 -23 7 -40 -15 -82 -103 -209 -56
            -226 84 l-6 51 148 0 148 0 -5 69 c-6 80 -36 142 -84 171 -45 29 -130 27 -175
            -3z m125 -39 c29 -13 66 -81 66 -121 l0 -28 -107 3 -108 3 3 34 c5 62 53 118
            104 120 9 1 28 -4 42 -11z"/>
            <path d="M13982 1673 c-76 -37 -89 -150 -23 -206 16 -13 59 -35 96 -48 115
            -40 144 -91 86 -150 -46 -46 -111 -39 -172 18 -23 21 -30 23 -48 14 -27 -15
            -26 -22 4 -55 52 -57 172 -77 236 -39 59 34 85 122 55 182 -19 35 -44 52 -124
            81 -86 32 -112 53 -112 95 0 24 7 39 26 54 43 34 99 26 151 -22 22 -20 23 -20
            43 -2 l20 18 -22 25 c-48 51 -149 68 -216 35z"/>
            <path d="M14425 1676 c-69 -30 -106 -111 -106 -231 -1 -171 61 -256 186 -256
            64 0 115 22 151 64 l26 31 -22 18 c-22 18 -23 18 -67 -23 -42 -38 -48 -41 -89
            -36 -70 7 -103 48 -119 145 l-7 42 152 0 152 0 -7 63 c-14 128 -73 197 -169
            197 -28 -1 -64 -7 -81 -14z m123 -52 c28 -20 49 -60 54 -104 l3 -35 -107 -3
            -108 -3 0 31 c0 91 94 159 158 114z"/>
            <path d="M14874 1676 c-68 -30 -107 -117 -107 -236 -1 -161 65 -251 182 -252
            70 0 126 34 158 96 12 24 13 30 1 37 -18 12 -48 11 -48 0 0 -5 -12 -23 -26
            -40 -52 -62 -144 -49 -179 26 -23 48 -31 164 -15 222 31 116 146 148 196 55
            21 -38 20 -38 51 -21 23 13 23 13 4 47 -38 73 -134 102 -217 66z"/>
            <path d="M15830 1683 c-41 -13 -63 -31 -86 -68 l-24 -40 0 53 0 52 -35 0 -35
            0 0 -240 0 -240 35 0 35 0 0 140 c0 130 2 144 25 189 27 55 66 90 107 98 23 4
            28 11 28 34 0 29 -13 35 -50 22z"/>
            <path d="M16483 1665 c-58 -35 -86 -96 -91 -201 -8 -153 43 -251 144 -272 68
            -15 139 6 181 52 39 42 40 47 11 65 -19 12 -23 11 -41 -13 -58 -80 -167 -71
            -208 17 -10 23 -19 59 -19 80 l0 37 146 0 147 0 -5 63 c-6 69 -32 129 -72 167
            -21 19 -40 25 -89 28 -53 2 -68 -1 -104 -23z m142 -47 c30 -24 55 -74 55 -113
            l0 -25 -110 0 c-103 0 -110 1 -110 20 0 39 33 102 64 121 40 25 67 24 101 -3z"/>
            <path d="M7711 1663 c-70 -35 -106 -132 -99 -266 7 -127 50 -193 141 -218 66
            -18 145 6 193 58 29 32 30 44 3 61 -18 11 -23 10 -41 -13 -42 -54 -130 -71
            -175 -33 -25 20 -53 89 -53 131 l0 37 145 0 145 0 0 43 c0 86 -34 161 -90 200
            -32 22 -123 22 -169 0z m134 -55 c30 -24 55 -74 55 -113 l0 -25 -110 0 -110 0
            0 28 c0 37 40 104 70 119 36 18 64 16 95 -9z"/>
            <path d="M8232 1663 c-18 -8 -44 -34 -57 -57 l-25 -41 0 53 0 52 -35 0 -35 0
            0 -240 0 -240 35 0 35 0 0 140 c0 123 3 146 21 183 30 59 67 96 106 103 28 5
            33 10 33 35 0 23 -4 29 -22 29 -13 0 -38 -8 -56 -17z"/>
            <path d="M9099 1657 c-49 -33 -75 -78 -89 -153 -28 -164 28 -294 141 -324 63
            -17 108 -3 162 50 51 52 59 75 25 83 -16 5 -31 -4 -63 -38 -46 -50 -82 -58
            -134 -30 -58 29 -87 158 -62 271 27 121 159 156 201 54 7 -17 14 -30 16 -30 9
            0 54 24 54 29 0 18 -44 74 -72 91 -47 29 -134 27 -179 -3z"/>
            <path d="M9560 1661 c-74 -39 -104 -118 -98 -256 3 -72 9 -99 28 -135 61 -110
            195 -130 291 -43 44 40 48 53 18 71 -18 11 -23 10 -41 -13 -85 -108 -228 -49
            -228 95 l0 40 146 0 147 0 -6 65 c-7 75 -33 132 -77 169 -26 21 -42 26 -88 26
            -37 0 -70 -7 -92 -19z m139 -57 c28 -23 51 -75 51 -116 0 -16 -12 -18 -110
            -18 l-110 0 0 25 c0 66 57 135 111 135 15 0 41 -12 58 -26z"/>
            <path d="M15221 1488 c0 -123 4 -205 12 -227 26 -74 110 -95 191 -47 54 31 56
            32 56 6 0 -16 7 -20 30 -20 l30 0 0 240 0 240 -30 0 -30 0 -2 -187 -3 -187
            -44 -34 c-34 -26 -52 -32 -80 -30 -57 5 -64 31 -69 251 l-4 187 -29 0 -29 0 1
            -192z"/>
            <path d="M15960 1440 l0 -240 30 0 30 0 0 240 0 240 -30 0 -30 0 0 -240z"/>
            <path d="M8442 1468 c32 -111 63 -219 69 -241 l10 -38 51 3 51 3 29 100 c15
            55 46 162 67 238 l39 137 -32 0 -31 0 -59 -210 c-33 -116 -63 -205 -67 -198
            -3 7 -32 102 -64 211 l-58 197 -32 0 -32 0 59 -202z"/>
            <path d="M8830 1430 l0 -240 35 0 35 0 0 240 0 240 -35 0 -35 0 0 -240z"/>
            </g>
        </svg>
        </>
    );
}

export default Canadian;
